/* eslint-disable avoma/use-rendericon-for-avomaicon */

/* eslint-disable avoma/use-svgr-over-svg */
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import AvomaHoverCard from 'components/Common/AvomaHoverCard';
import AvomaIcon from 'components/Common/AvomaIcon';
import OverflowingText from 'components/Common/OverflowingText';

import { isDesktop } from 'helpers/deviceCutoffs';

import ATTRIBUTE_PROVIDERS from 'constants/attributeProviders';

import KixieIcon from 'images/Kixie-Logo.png';
import HubspotIcon from 'images/ic_hubspot_sprocket.svg';
import SalesforceIcon from 'images/ic_salesforce_synced.svg';
import ZendesksellIcon from 'images/ic_zendesksell_logo.svg';
import AircallIcon from 'images/logo-aircall-small.svg';
import AvomaLogoIcon from 'images/logo-icon.png';
import OutreachIcon from 'images/logo-outreach-small.svg';
import RingcentralIcon from 'images/logo-ringcentral-small.svg';
import ZoomIcon from 'images/logo-zoom-small.svg';
import SalesloftIcon from 'images/saleslofticon.png';

import Tokens from 'styles/tokens';

const getProviderIcon = provider => {
  switch (provider.service) {
    case ATTRIBUTE_PROVIDERS.salesforce:
      return SalesforceIcon;
    case ATTRIBUTE_PROVIDERS.hubspot:
      return HubspotIcon;
    case ATTRIBUTE_PROVIDERS.zoom:
      return ZoomIcon;
    case ATTRIBUTE_PROVIDERS.outreach:
      return OutreachIcon;
    case ATTRIBUTE_PROVIDERS.aircall:
      return AircallIcon;
    case ATTRIBUTE_PROVIDERS.ringcentral:
      return RingcentralIcon;
    case ATTRIBUTE_PROVIDERS.zendesksell:
      return ZendesksellIcon;
    case ATTRIBUTE_PROVIDERS.salesloft:
      return SalesloftIcon;
    case ATTRIBUTE_PROVIDERS.kixie:
      return KixieIcon;
    default:
      return AvomaLogoIcon;
  }
};

const IndividualDetailValue = ({
  detail,
  showSeparator,
  separator,
  items,
  displaySuffix
}) => {
  if (!isEmpty(items)) {
    const itemByLookup = items?.find(p => p.label === detail);

    return (
      <React.Fragment>
        <div
          className='flex !h-2 items-center gap-x-1 overflow-hidden rounded p-2'
          style={{
            backgroundColor:
              itemByLookup?.backgroundColor || Tokens.colors.whiteSmoke,
            color: itemByLookup?.foregroundColor || Tokens.colors.gunmetal
          }}
        >
          <OverflowingText
            className='!text-xs font-bold uppercase'
            tooltip={itemByLookup?.label}
          >
            {itemByLookup?.label}
          </OverflowingText>
          {itemByLookup?.provider && (
            <AvomaIcon icon={getProviderIcon(itemByLookup.provider)} small />
          )}
        </div>
        {showSeparator && (
          <span className='shrink-0 text-xs uppercase text-silver'>
            {separator}
          </span>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className='flex items-center truncate rounded bg-whiteSmoke py-1 px-2'>
        <OverflowingText className='text-xs font-bold'>
          {detail}
          {displaySuffix && ` ${displaySuffix}`}
        </OverflowingText>
      </div>
      {showSeparator && (
        <span className='shrink-0 text-xs uppercase text-silver'>
          {separator}
        </span>
      )}
    </React.Fragment>
  );
};

IndividualDetailValue.propTypes = {
  detail: PropTypes.string,
  showSeparator: PropTypes.bool,
  separator: PropTypes.string,
  items: PropTypes.array,
  displaySuffix: PropTypes.string
};

const FilterSelectionViewer = ({
  label,
  details = [],
  render,
  separator = 'or',
  placeholder = 'Select',
  isRange = false,
  setTriggerRef,
  displaySuffix = undefined,
  items = [] // Used to lookup the label in cases where details refer to non-label data (e.g. Purposes details refers to UUIDs but we want the Purpose names)
}) => {
  const triggerRef = useRef(null);

  useEffect(() => {
    if (setTriggerRef && triggerRef?.current) {
      setTimeout(() => {
        setTriggerRef(triggerRef?.current);
      }, 250);
    }
  }, [triggerRef, setTriggerRef]);

  if (render) {
    return render({ label, details });
  }

  const isDesktopScreen = isDesktop();

  const detailLimit = isDesktopScreen ? 2 : 3;

  if (details.length === 0)
    return (
      <div className='w-full px-2 text-sm text-silver' ref={triggerRef}>
        {placeholder}
      </div>
    );

  if (isRange) {
    return (
      <div className='flex w-full items-center gap-2 px-2' ref={triggerRef}>
        <div className='flex items-center rounded bg-whiteSmoke px-2 py-1 text-sm font-bold'>
          {details[0]} - {details[1]}
        </div>
      </div>
    );
  }

  return (
    <div className='flex items-center gap-2 px-2' ref={triggerRef}>
      {!isEmpty(details) &&
        details
          ?.filter(d => d)
          .slice(0, detailLimit)
          .map((detail, i) => (
            <IndividualDetailValue
              key={i}
              detail={detail}
              showSeparator={i !== details.slice(0, detailLimit).length - 1}
              separator={separator}
              items={items}
              displaySuffix={displaySuffix}
            />
          ))}
      {details.length > detailLimit && (
        <AvomaHoverCard
          triggerComponent={
            <div className='flex shrink-0 items-center gap-2'>
              <span className='text-xs uppercase text-silver'>{separator}</span>
              <div className='flex-grow basis-[20%] shrink-0 items-center rounded bg-whiteSmoke px-2 py-1 text-xs font-bold'>
                +{details.length - detailLimit}
              </div>{' '}
            </div>
          }
          contentClasses='z-[999999] !max-w-[320px] !w-fit max-h-[300px] overflow-y-auto'
        >
          <div className='flex items-center flex-wrap gap-2'>
            {details?.slice(detailLimit).map((detail, i) => {
              if (!isEmpty(items)) {
                const itemByLookup = items?.find(p => p.label === detail);

                return (
                  <React.Fragment key={detail}>
                    <div
                      className='flex h-2.5 items-center gap-x-1 overflow-hidden rounded p-2'
                      style={{
                        backgroundColor:
                          itemByLookup?.backgroundColor ||
                          Tokens.colors.whiteSmoke,
                        color:
                          itemByLookup?.foregroundColor ||
                          Tokens.colors.gunmetal
                      }}
                    >
                      <OverflowingText
                        as='div'
                        className='!text-x2s !font-bold uppercase'
                        tooltip={itemByLookup?.label}
                      >
                        {itemByLookup?.label}
                      </OverflowingText>

                      {itemByLookup?.provider && (
                        <AvomaIcon
                          icon={getProviderIcon(itemByLookup.provider)}
                          small
                        />
                      )}
                    </div>
                    {i !== details.slice(detailLimit).length - 1 && (
                      <span className='text-center text-xs uppercase text-silver'>
                        {separator}
                      </span>
                    )}
                  </React.Fragment>
                );
              }

              return (
                <React.Fragment key={i}>
                  <div className='flex-grow basis-[20%] shrink-0 items-center rounded bg-whiteSmoke px-2 py-1 text-xs font-bold w-full'>
                    {detail}{' '}
                  </div>
                  {i !== details.slice(detailLimit).length - 1 && (
                    <span className='text-center text-xs uppercase text-silver'>
                      {separator}
                    </span>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </AvomaHoverCard>
      )}
    </div>
  );
};
FilterSelectionViewer.propTypes = {
  details: PropTypes.string,
  label: PropTypes.string,
  render: PropTypes.func,
  separator: PropTypes.string,
  placeholder: PropTypes.string,
  isRange: PropTypes.bool,
  items: PropTypes.array,
  setTriggerRef: PropTypes.func,
  displaySuffix: PropTypes.string
};

export default FilterSelectionViewer;
