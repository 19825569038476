import React from 'react';

import { BILLING_ADDONS, PLAN_NAMES } from 'constants/billing';

export const L10nAIWinLossAnalysis = /** @type {const} */ ({
  aiWinLossAnalysis: 'AI win/loss analysis',
  dealsTimeline: 'Deals Timeline',
  noDealsFound:
    'No deals found for the applied filters, try changing the filters to see results',
  thatsAllForDeals: "That's all the deals for the selected date range",
  viewComments: 'View Comments',
  generateAIAnalysis: 'Generate AI Analysis',
  clickToRefreshStatus: 'Click to refresh status',
  winlossAnalysis: 'Win/loss Analysis',
  closedDeals: 'Closed Deals',
  closedWon: 'Closed Won',
  closedLost: 'Closed Lost',
  winRatePercent: 'Win rate %',
  winRate: 'Win rate',
  averageWinRate: 'Average win rate',
  winlossAnalysisTooltip:
    'Win/loss % breakdown of closed deals for selected time period',
  winRateTooltip: 'Trend for deals won for selected time period',
  winLossTrend: 'Win/loss trend (last 12 months)',
  duration: 'Duration',
  dealsWon: 'Deals Won',
  dealsLost: 'Deals Lost',
  clickToViewWinLossAnalysisFor: 'Click to view win/loss analysis for',
  errors: {
    noEngagements: () =>
      'AI analysis is not generated as there is minimal or no engagement (meetings, calls and emails) on this deal.',
    noAssociatedDomain: () =>
      'AI analysis is not generated as there is no associated domain with the deal.',
    ownNoFeat: () => (
      <React.Fragment>
        AI analysis is <strong>not</strong> generated. The{' '}
        <strong>deal owner</strong> needs to be on a{' '}
        <strong>“{PLAN_NAMES.premium}”</strong> license with the{' '}
        <strong>“{BILLING_ADDONS.aiWinLossAnalysis}“</strong> add-on to generate
        AI analysis. <br />
        Please <strong>contact</strong> your{' '}
        <strong>Customer Success Manager</strong> for details about the add-on
        purchase.
      </React.Fragment>
    )
  }
});
