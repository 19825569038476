/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import Radium from 'radium';
import React, { Component } from 'react';
import stylePropType from 'react-style-proptype';

import 'styles/AvomaButton.css';
import Tokens from 'styles/tokens';

import Spinner from './Spinner';

class AvomaButton extends Component {
  state = {
    hovering: false
  };

  onMouseEnter = () => {
    const { onMouseEnter } = this.props;
    this.setState({ hovering: true });
    if (onMouseEnter) onMouseEnter();
  };

  onMouseLeave = () => {
    const { onMouseLeave } = this.props;
    this.setState({ hovering: false });
    if (onMouseLeave) onMouseLeave();
  };

  styleButton() {
    const classNames = ['avoma-button'];
    const {
      small,
      extraSmall,
      secondary,
      destructive,
      minimal,
      fullWidth,
      longWidth,
      black,
      smallPadding
    } = this.props;
    if (small) {
      classNames.push('small');
    } else if (extraSmall) {
      classNames.push('extraSmall');
    } else if (smallPadding) {
      classNames.push('smallPadding');
    } else {
      classNames.push('medium');
    }
    if (secondary) {
      classNames.push('secondary');
    } else if (black) {
      classNames.push('black');
    } else if (minimal) {
      classNames.push('minimal');
    } else {
      classNames.push('primary');
    }
    if (destructive) {
      classNames.push('destructive');
    }
    if (fullWidth) {
      classNames.push('full-width');
    } else if (longWidth) {
      classNames.push('long-width');
    }
    return classNames;
  }

  render() {
    const {
      label,
      icon,
      hoverIcon,
      iconImage,
      hoverIconImage,
      onClick,
      iconOnly,
      faIcon,
      iconStyles,
      className,
      tooltip,
      tooltipPosition,
      loading,
      loadingText,
      style,
      iconHoverStyles,
      labelHoverStyles,
      extraSmall,
      labelStyles,
      labelClassName,
      disabled,
      iconImageStyles,
      dataTestId,
      tabIndex
    } = this.props;
    const { hovering } = this.state;
    const buttonClasses = this.styleButton();
    buttonClasses.push(className);
    const iconClasses = ['icon'];
    if (iconOnly) {
      iconClasses.push('icon-only');
      buttonClasses.push('icon-button-tooltip');
    }

    if (tooltip && tooltip.trim().length > 0) {
      buttonClasses.push('tooltip');
      buttonClasses.push(tooltipPosition);
    }
    return (
      <button
        disabled={disabled}
        className={buttonClasses.join(' ')}
        style={style}
        onClick={loading ? () => {} : onClick}
        aria-label={tooltip}
        data-testid={dataTestId}
        {...((iconHoverStyles || labelHoverStyles) && {
          onMouseEnter: this.onMouseEnter,
          onMouseLeave: this.onMouseLeave,
          onMouseDown: this.onMouseDown,
          onMouseUp: this.onMouseUp
        })}
        tabIndex={tabIndex}
      >
        {icon && !(hovering && hoverIcon) && (
          <div
            className={iconClasses.join(' ')}
            style={{
              ...iconStyles,
              ...(hovering && iconHoverStyles)
            }}
          >
            <img
              src={icon}
              alt=''
              style={{
                width: Tokens.spacing.three,
                height: Tokens.spacing.three,
                ...(disabled && { opacity: '0.4' })
              }}
            />
          </div>
        )}
        {hoverIcon && hovering && (
          <div
            className={iconClasses.join(' ')}
            style={{
              ...iconStyles,
              ...(hovering && iconHoverStyles)
            }}
          >
            <img
              src={hoverIcon}
              alt=''
              style={{
                width: Tokens.spacing.three,
                height: Tokens.spacing.three,
                ...(disabled && { opacity: '0.4' })
              }}
            />
          </div>
        )}
        {iconImage && !(hovering && hoverIconImage) && (
          <div
            className={iconClasses.join(' ')}
            style={{
              ...iconStyles,
              ...(hovering && iconHoverStyles)
            }}
          >
            <img
              src={iconImage}
              alt={`${label} icon`}
              style={{
                height: extraSmall
                  ? Tokens.spacing.onehalf
                  : Tokens.spacing.two,
                ...iconImageStyles,
                ...(disabled && { opacity: '0.4' })
              }}
            />
          </div>
        )}
        {hoverIconImage && hovering && (
          <div
            className={iconClasses.join(' ')}
            style={{
              ...iconStyles,
              ...(hovering && iconHoverStyles)
            }}
          >
            <img
              src={hoverIconImage}
              alt={`${label} icon`}
              style={{
                height: extraSmall
                  ? Tokens.spacing.onehalf
                  : Tokens.spacing.two,
                ...iconImageStyles,
                ...(disabled && { opacity: '0.4' })
              }}
            />
          </div>
        )}
        {faIcon && (
          <div
            className={iconClasses.join(' ')}
            style={{
              ...iconStyles,
              ...(hovering && iconHoverStyles),
              ...(disabled && { opacity: '0.4' })
            }}
          >
            {faIcon}
          </div>
        )}
        {loading && (
          <div className='flex w-full items-center justify-center absolute gap-1'>
            <Spinner type='small' fadeIn='none' />
            {loadingText && <span>{loadingText}</span>}
          </div>
        )}
        {!iconOnly && (
          <span
            className={labelClassName ? `${labelClassName} label` : 'label'}
            style={{
              ...labelStyles,
              ...(hovering && labelHoverStyles),
              ...(loading && { opacity: '0' })
            }}
          >
            {label}
          </span>
        )}
      </button>
    );
  }
}
AvomaButton.propTypes = {
  secondary: PropTypes.bool,
  black: PropTypes.bool,
  destructive: PropTypes.bool,
  minimal: PropTypes.bool,
  small: PropTypes.bool,
  extraSmall: PropTypes.bool,
  smallPadding: PropTypes.bool,
  fullWidth: PropTypes.bool,
  longWidth: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  hoverIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  iconImage: PropTypes.string,
  hoverIconImage: PropTypes.string,
  iconOnly: PropTypes.bool,
  iconHoverStyles: stylePropType,
  labelHoverStyles: stylePropType,
  iconStyles: stylePropType,
  faIcon: PropTypes.node,
  disabled: PropTypes.bool,
  tooltipPosition: PropTypes.oneOf([
    'bottom',
    'top',
    'left',
    'right',
    'bottom-right',
    'bottom-left',
    'top-right',
    'top-left'
  ]),
  style: stylePropType,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  tooltip: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  tooltipStyle: PropTypes.object,
  labelStyles: stylePropType,
  labelClassName: PropTypes.string,
  iconImageStyles: stylePropType,
  dataTestId: PropTypes.string,
  tabIndex: PropTypes.string
};
AvomaButton.defaultProps = {
  tooltipStyle: {},
  secondary: false,
  black: false,
  destructive: false,
  minimal: false,
  fullWidth: false,
  longWidth: false,
  small: false,
  smallPadding: false,
  extraSmall: false,
  disabled: false,
  icon: null,
  hoverIcon: null,
  iconImage: null,
  hoverIconImage: null,
  iconOnly: false,
  tooltipPosition: 'bottom',
  onClick: null,
  className: '',
  label: '',
  tooltip: '',
  title: '',
  iconStyles: {},
  loading: false,
  iconHoverStyles: null,
  labelHoverStyles: null
};
export default Radium(AvomaButton);
