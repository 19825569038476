import PropTypes from 'prop-types';
import React from 'react';

import AvomaButton from 'components/Common/AvomaButton';
import AvomaFeatureGateDialog from 'components/Common/AvomaFeatureGateDialog';
import NotificationBanner from 'components/Common/NotificationBanner';

import L10n from 'helpers/L10n';
import { L10Common } from 'helpers/l10n/L10nCommon';
import { L10nFeatureGating } from 'helpers/l10n/L10nFeatureGating';
import { isAdmin } from 'helpers/user';

import { ReactComponent as PurchaseLicenseIcon } from 'images/ic_purchase_license.svg';

const PurchaseLicenseBanner = ({
  msg = `${L10Common.licenseNeeded}`,
  showButton = true,
  feature,
  truncateMessage = true,
  align = 'start',
  minimal = false,
  direction = 'row',
  className = ''
}) => {
  const isCurrentUserAdmin = isAdmin();

  const handleClick = () => {
    if (isCurrentUserAdmin) {
      window.open('/settings/licenses', '_self');
    }
  };

  const getButtonLabel = () => {
    if (isCurrentUserAdmin) {
      if (minimal) {
        return L10n.licenses.purchaseTitleShort;
      }

      return L10n.licenses.purchaseTitle;
    }

    if (minimal) {
      return L10n.licenses.requestTitleShort;
    }

    return L10n.licenses.requestTitle;
  };

  const renderTrigger = (onClick = null) => (
    <AvomaButton
      secondary
      small
      {...(onClick && { onClick })}
      faIcon={
        <PurchaseLicenseIcon className='w-3.5 h-3.5 flex flex-shrink-0' />
      }
      label={getButtonLabel()}
    />
  );

  const renderAdditionalAction = () => {
    if (isCurrentUserAdmin) {
      return renderTrigger(handleClick);
    }

    return (
      <AvomaFeatureGateDialog
        title={L10nFeatureGating.upgradeTitle}
        trigger={renderTrigger()}
        feature={feature}
      />
    );
  };

  return (
    <React.Fragment>
      <NotificationBanner
        sm={!minimal}
        xs={minimal}
        collapse={truncateMessage}
        type='lock'
        content={msg}
        align={align}
        additionalAction={showButton && renderAdditionalAction()}
        direction={direction}
        className={className}
      />
    </React.Fragment>
  );
};

PurchaseLicenseBanner.propTypes = {
  msg: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  showButton: PropTypes.bool,
  truncateMessage: PropTypes.bool,
  feature: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['start', 'center']),
  direction: PropTypes.oneOf(['row', 'column']),
  minimal: PropTypes.bool,
  className: PropTypes.string
};

export default PurchaseLicenseBanner;
