import { getAboveByType } from '../../Queries/getAboveByType';
import { getParent } from '../../Queries/getParent';
import isNodeTypeIn from '../../Queries/isNodeTypeIn';
import { TYPE_CHECKLIST_ITEM } from '../../Types';
import {
  TYPE_CHECKLIST,
  TYPE_LIST_ITEM,
  TYPE_ORDERED_LIST,
  TYPE_UNORDERED_LIST
} from '../types';

/**
 * Is the selection in li>p.
 * If true, return the node entries.
 */
export const isSelectionInListItem = editor => {
  if (
    editor.selection &&
    isNodeTypeIn(editor, [TYPE_LIST_ITEM, TYPE_CHECKLIST_ITEM])
  ) {
    const selectionParentEntry = getParent(editor, editor.selection);
    if (!selectionParentEntry) return;
    const [, paragraphPath] = selectionParentEntry;

    const listItemEntry = getAboveByType(editor, [
      TYPE_LIST_ITEM,
      TYPE_CHECKLIST_ITEM
    ]);
    getParent(editor, paragraphPath);
    if (!listItemEntry) return;
    const [listItemNode, listItemPath] = listItemEntry;

    if (![TYPE_LIST_ITEM, TYPE_CHECKLIST_ITEM].includes(listItemNode.type))
      return;

    const listEntry = getParent(editor, listItemPath);
    if (!listEntry) return;
    const [listNode, listPath] = listEntry;

    return {
      listNode,
      listPath,
      listItemNode,
      listItemPath
    };
  }
};

export const isSelectionInList = editor => {
  if (
    editor.selection &&
    isNodeTypeIn(editor, [
      TYPE_UNORDERED_LIST,
      TYPE_ORDERED_LIST,
      TYPE_CHECKLIST
    ])
  ) {
    const selectionParentEntry = getParent(editor, editor.selection);
    if (!selectionParentEntry) return;
    const [, paragraphPath] = selectionParentEntry;

    const listEntry =
      getAboveByType(editor, [
        TYPE_UNORDERED_LIST,
        TYPE_ORDERED_LIST,
        TYPE_CHECKLIST
      ]) || getParent(editor, paragraphPath);
    if (!listEntry) return;
    const [listNode, listPath] = listEntry;
    return {
      listNode,
      listPath
    };
  }
};
