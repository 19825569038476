import { isHotkey } from 'is-hotkey';
import { Node, Path, Range, Transforms } from 'slate';

import { BLOCK_KEY } from 'helpers/SlateDataHelper';

import { onKeyDownResetBlockType } from '../../Handlers/onKeyDownResetBlockType';
import { getAboveByType } from '../Queries/getAboveByType';
import { getParentForNode } from '../Queries/getParent';
import { isBlockAboveEmpty } from '../Queries/isBlockAboveEmpty';
import { isFirstChild } from '../Queries/isFirstChild';
import isNodeTypeIn from '../Queries/isNodeTypeIn';
import { isSelectionAtBlockStart } from '../Queries/isSelectionAtBlockStart';
import { wrapNodes } from '../Transforms/wrapNodes';
import { TYPE_CHECKLIST_ITEM, TYPE_PARAGRAPH } from '../Types';
import { BLOCK_UPDATED } from '../withAvoma';
import { isList } from './Queries/isList';
import { isSelectionInListItem } from './Queries/isSelectionInListItem';
import { insertListItem } from './Transforms/insertListItem';
import { moveListItemDown } from './Transforms/moveListItemDown';
import { moveListItemUp } from './Transforms/moveListItemUp';
import { moveParagraphUp } from './Transforms/moveParagraphUp';
import { toggleList } from './Transforms/toggleList';
import { unwrapList } from './Transforms/unwrapList';
import {
  TYPE_CHECKLIST,
  TYPE_LIST_ITEM,
  TYPE_ORDERED_LIST,
  TYPE_UNORDERED_LIST
} from './types';

/**
 *
 * @param {*} editor - The Slate editor
 * @param {*} additionalLeafTypes - List of valid leaf types so inserting
 * new list items works properly
 */
const withList = (editor, additionalLeafTypes = [], emptyTypes = []) => {
  const {
    normalizeNode,
    onKeyDown,
    insertBreak,
    deleteBackward,
    deleteFragment
  } = editor;

  const resetBlockTypesListRule = {
    types: [TYPE_LIST_ITEM, TYPE_CHECKLIST_ITEM],
    defaultType: TYPE_PARAGRAPH,
    onReset: _editor => unwrapList(_editor)
  };

  editor.insertBreak = () => {
    const paragraphEntry = getAboveByType(
      editor,
      additionalLeafTypes.concat([TYPE_PARAGRAPH])
    );

    if (!paragraphEntry) {
      insertBreak();
      return;
    }

    const [node, nodePath] = paragraphEntry;
    const [grandparent, _] = getParentForNode(editor, node);

    let res = null;
    let isListItemWithSublist = false;

    const isLastChild =
      nodePath[nodePath.length - 1] === grandparent.children.length - 1;

    if (isList(grandparent)) {
      res = { listItemNode: node, listItemPath: nodePath };
    } else {
      res = isSelectionInListItem(editor);

      if (
        grandparent.children.length === 2 &&
        isList(grandparent.children[1])
      ) {
        // If the parent only a paragraph and a list (this is a standard bullet with a subbullet)
        // the we consider the item to be a list item
        isListItemWithSublist = !!res;
      }
    }

    let moved;

    const isOnlyChild = grandparent.children.length === 1;
    const isItemEmpty = isBlockAboveEmpty(editor, emptyTypes);

    if (isLastChild && isOnlyChild && isItemEmpty && res) {
      editor.deleteBackward('character');
      return;
    }

    const isNodeTypeChecklist = isNodeTypeIn(editor, TYPE_CHECKLIST);

    if (res) {
      if (
        isListItemWithSublist ||
        (isLastChild && (editor.selection.anchor.offset > 0 || !isItemEmpty))
      ) {
        // Break up the list item into a new one
        insertListItem(
          editor,
          additionalLeafTypes,
          emptyTypes,
          isNodeTypeChecklist
        );
      } else {
        // Parent isnt a list item so just insert a break
        insertBreak();
      }

      if (editor.scrollIntoView) {
        editor.scrollIntoView();
      }

      return;
    }

    const didReset = onKeyDownResetBlockType({
      rules: [
        {
          ...resetBlockTypesListRule,
          predicate: () => !moved && isBlockAboveEmpty(editor, emptyTypes)
        }
      ]
    })(null, editor);

    if (didReset) return;

    /**
     * Add a new list item if selection is in a LIST_ITEM > p.type.
     */
    if (!moved) {
      const inserted = insertListItem(
        editor,
        additionalLeafTypes,
        emptyTypes,
        isNodeTypeChecklist
      );
      if (editor.scrollIntoView) {
        editor.scrollIntoView();
      }
      if (inserted) return;
    }
    if (insertBreak) {
      insertBreak();
    }
  };

  editor.deleteBackward = unit => {
    const res = isSelectionInListItem(editor);
    const listContainer = getAboveByType(editor, [
      TYPE_ORDERED_LIST,
      TYPE_UNORDERED_LIST,
      TYPE_CHECKLIST
    ]);

    const { selection } = editor;
    const { path } = selection.anchor;

    if (!res && listContainer) {
      unwrapList(editor);

      return;
    }

    // See if we need to move a paragraph up the list
    const moved = moveParagraphUp(editor, additionalLeafTypes);

    if (moved) {
      return;
    }

    const isSelectionAtStartAndFirstChild =
      path[path.length - 2] === 0 && res && isSelectionAtBlockStart(editor);

    const didReset = onKeyDownResetBlockType({
      rules: [
        {
          ...resetBlockTypesListRule,
          predicate: () => !moved && isSelectionAtStartAndFirstChild
        }
      ]
    })(null, editor);

    if (didReset) {
      return;
    }

    deleteBackward(unit);
  };

  editor.deleteFragment = () => {
    // TODO: the same bug described below happens when you select a bunch of
    // bullet points an type in or paste in text - this only gets called when
    // the user hits BACKSPACE so the fix only works in that scenario for now
    //
    // Extra logic to handle the sublists of a fragment delete disappearing
    // which appears to be a bug in slate
    // 1. First we get the siblings of the node at the end of the selection
    //    and put them in a list called 'siblings'
    const { selection } = editor;
    const end = Range.isBackward(editor.selection)
      ? selection.anchor
      : selection.focus;
    const node = Node.get(editor, end.path);
    let endNode = node;
    let endPath = end.path;
    if ('text' in node) {
      endPath = endPath.slice(0, -1);
      endNode = Node.get(editor, endPath);
    }
    let siblings = [];
    let listItemSiblings = [];
    if (endNode) {
      try {
        const parentNode = Node.get(editor, endPath.slice(0, -1));
        siblings = parentNode.children.slice(endPath[endPath.length - 1] + 1);
        // Sometimes the list item's siblings disappear
        if (endPath.length > 1) {
          const listItemParent = Node.get(editor, endPath.slice(0, -2));
          listItemSiblings = listItemParent.children.slice(
            endPath[endPath.length - 2] + 1
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
    // 2. Do the default delete
    deleteFragment();
    if (siblings.length > 0 || listItemSiblings.length > 0) {
      // 3. If we have siblings, check if there are still nodes in the document
      // that match either the block_updated or block_key data fields.
      // - if we find a match don't do anything
      // - if no match that means the node disappeared so re-add all the siblings
      const areSiblingsMissing = candidates => {
        if (candidates.length === 0) {
          return false;
        }
        let areMissing = false;
        let firstNode = candidates[0];
        let prevNode = firstNode;
        while (firstNode.children && firstNode.children.length > 0) {
          prevNode = firstNode;
          [firstNode] = firstNode.children;
        }
        if (prevNode.data) {
          const lastUpdated = prevNode.data[BLOCK_UPDATED];
          const blockKey = prevNode.data[BLOCK_KEY];
          const matchingKey =
            blockKey &&
            editor.findNodePath(n => n.data && n.data[BLOCK_KEY] === blockKey);
          if (!matchingKey) {
            const matchingLastUpdated = editor.findNodePath(
              n => n.data && n.data[BLOCK_UPDATED] === lastUpdated
            );
            if (!matchingLastUpdated) {
              areMissing = true;
            }
          }
        }
        return areMissing;
      };
      const shouldInsertSiblings = areSiblingsMissing(siblings);
      const shouldInsertListItemSiblings = areSiblingsMissing(listItemSiblings);
      // 4. We want to add the missing items as a sibling of the selected node
      let newSelectionPath = editor.selection.anchor.path;
      const selectedNode = Node.get(editor, newSelectionPath);
      if ('text' in selectedNode) {
        newSelectionPath = editor.selection.anchor.path.slice(0, -1);
      }
      const next = Path.next(newSelectionPath);
      if (shouldInsertSiblings) {
        Transforms.insertNodes(editor, siblings, { at: next });
      }
      if (shouldInsertListItemSiblings) {
        Transforms.insertNodes(editor, listItemSiblings, { at: next });
      }
    }
  };

  editor.onKeyDown = event => {
    if (isHotkey('mod+shift+7', event)) {
      event.preventDefault();
      toggleList(editor, { typeList: TYPE_ORDERED_LIST });
    } else if (isHotkey('mod+shift+8', event)) {
      event.preventDefault();
      toggleList(editor, { typeList: TYPE_UNORDERED_LIST });
    } else if (isHotkey('mod+shift+9', event)) {
      event.preventDefault();
      toggleList(editor, { typeList: TYPE_CHECKLIST });
    } else if (isHotkey('shift+enter', event)) {
      event.preventDefault();
      insertBreak();
    } else if (isHotkey('shift+tab', event)) {
      // unindent shift+tab
      const res = isSelectionInListItem(editor);
      if (res) {
        const [_, nodePath] = getAboveByType(
          editor,
          additionalLeafTypes.concat([TYPE_PARAGRAPH])
        );

        if (isFirstChild(nodePath)) {
          const { listNode, listPath, listItemNode, listItemPath } = res;

          if (listNode.type === TYPE_CHECKLIST) {
            return;
          }

          const moved = moveListItemUp(
            editor,
            listNode,
            listPath,
            listItemNode,
            listItemPath
          );

          if (moved) event.preventDefault();
        }
      }
    } else if (isHotkey('tab', event)) {
      const res = isSelectionInListItem(editor);

      if (!res) {
        return;
      }

      const blockAbove = getAboveByType(
        editor,
        additionalLeafTypes.concat([TYPE_PARAGRAPH])
      );

      // Possible that blockAbove can be undefined if there is no matching block
      if (!blockAbove) {
        return;
      }

      const [_, nodePath] = blockAbove;
      const { listNode, listItemPath } = res;

      event.preventDefault();

      // indent with tab
      const tab = !event.shiftKey;

      if (
        tab &&
        !isFirstChild(listItemPath) &&
        isFirstChild(nodePath) &&
        listNode.type !== TYPE_CHECKLIST
      ) {
        moveListItemDown(editor, listNode, listItemPath);
      }
    } else if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const removeRedundant = (node, path) => {
    if (node.children) {
      if (isList(node) && node.children[0] && isList(node.children[0])) {
        unwrapList(editor, { at: [...(path || []), 0] });
      }
      if (
        path.length > 0 &&
        (node.type === TYPE_LIST_ITEM || node.type === TYPE_CHECKLIST_ITEM) &&
        node.children[0] &&
        isList(node.children[0])
      ) {
        unwrapList(editor, { at: path });
      }
      node.children.forEach((nn, ii) => {
        if (nn) {
          removeRedundant(nn, [...(path || []), ii]);
        }
      });
    }
  };

  const mergeLists = (node, path) => {
    if (node.children) {
      let prevType = '';
      const mergable = [];
      node.children.forEach((nn, ii) => {
        if (
          nn.type === prevType &&
          [TYPE_ORDERED_LIST, TYPE_UNORDERED_LIST, TYPE_CHECKLIST].includes(
            prevType
          )
        ) {
          mergable.push(ii);
        }
        prevType = nn.type;
      });
      mergable.reverse().forEach(mm => {
        Transforms.mergeNodes(editor, { at: [...path, mm] });
      });
    }
  };

  const wrapText = (node, path) => {
    // Look for list_item nodes a text child and wrap the text child in a paragra[h]
    if (node.type === TYPE_LIST_ITEM || node.type === TYPE_CHECKLIST_ITEM) {
      if (
        node.children &&
        node.children.length === 1 &&
        !node.children[0].type
      ) {
        wrapNodes(
          editor,
          { type: TYPE_PARAGRAPH, children: [] },
          { at: [...path, 0] }
        );
      }
    }
    if (isList(node)) {
      node.children.forEach((nn, ii) => {
        wrapText(nn, [...path, ii]);
      });
    }
  };

  editor.normalizeNode = entry => {
    const [node, path] = entry;
    wrapText(node, path);
    mergeLists(node, path);
    removeRedundant(node, path);
    normalizeNode(entry);
  };

  return editor;
};

export default withList;
