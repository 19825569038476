/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

/* eslint-disable react/display-name */
import { forwardRef, useCallback, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { CHECKBOX_STATES } from './constants';
import { IAvomaCheckboxProps } from './types';

const AvomaCheckbox = forwardRef<HTMLInputElement, IAvomaCheckboxProps>(
  (props, ref) => {
    const {
      id,
      name,
      checked = false,
      onChange,
      style,
      value,
      label,
      labelStyle,
      containerStyle,
      optional = false,
      disabled = false,
      tooltip = '',
      tooltipPosition = 'bottom',
      isDisabled = false,
      disabledStyles,
      isMultiSelect = false,
      multiSelectState = 'empty',
      className = '',
      dataTestId = 'avoma-checkbox'
    } = props;

    const tooltipClasses: string[] = [];

    if (tooltip && tooltip.trim().length > 0) {
      tooltipClasses.push('tooltip icon-button-tooltip');
      tooltipClasses.push(tooltipPosition);
    }

    useEffect(() => {
      if (isMultiSelect && ref && 'current' in ref && ref.current) {
        if (multiSelectState === CHECKBOX_STATES.checked) {
          ref.current.checked = true;
          ref.current.indeterminate = false;
        } else if (multiSelectState === CHECKBOX_STATES.empty) {
          ref.current.checked = false;
          ref.current.indeterminate = false;
        } else if (multiSelectState === CHECKBOX_STATES.indeterminate) {
          ref.current.indeterminate = true;
          ref.current.checked = false;
        }
      }
    }, [isMultiSelect, multiSelectState, ref]);

    const handleChange = useCallback(
      (event): void => {
        event.stopPropagation();

        if (isDisabled) {
          return;
        }

        if (onChange) {
          onChange(event);
        }
      },
      [isDisabled, onChange]
    );

    return (
      <div
        className={twMerge(
          'flex flex-row items-start relative',
          tooltipClasses.join(' '),
          className
        )}
        style={containerStyle}
        aria-label={tooltip}
      >
        <input
          data-testid={dataTestId}
          className={twMerge(
            'h-4 w-4 shrink-0 cursor-pointer',
            isDisabled && 'cursor-not-allowed'
          )}
          type='checkbox'
          id={id}
          // onClick={handleChange}
          onChange={handleChange}
          name={name}
          value={value}
          checked={checked}
          style={{
            ...style,
            ...(isDisabled && { ...disabledStyles })
          }}
          ref={ref}
          disabled={disabled}
        />
        {label && (
          <label
            className={twMerge(
              'text-xxs ml-1 whitespace-nowrap text-ellipsis cursor-pointer',
              disabled ? 'cursor-default' : 'cursor-pointer'
            )}
            style={labelStyle}
            htmlFor={id}
          >
            {label}
            {optional && <span className='ml-4 text-silver'>(optional)</span>}
          </label>
        )}
      </div>
    );
  }
);

export default AvomaCheckbox;
