import { isWebexContext } from './webexApp';
import { isZoomContext } from './zoomApp';

export const getMeetingBasePath = () => {
  let basePath;
  if (isZoomContext()) {
    basePath = '/zoom/meetings';
  } else if (isWebexContext()) {
    basePath = '/webex/meetings';
  } else {
    basePath = '/meetings';
  }
  return basePath;
};

export const getTemplateBasePath = () => {
  let path;
  if (isZoomContext()) {
    path = '/zoom/templates/my';
  } else if (isWebexContext()) {
    path = '/webex/templates/my';
  } else {
    path = '/settings/templates/my';
  }
  return path;
};
