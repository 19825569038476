import isHotkey from 'is-hotkey';
import moment from 'moment';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import isNodeTypeIn from './Queries/isNodeTypeIn';

export const TYPE_MENTION = 'mention';

const withMentions = editor => {
  const { isInline, isVoid, deleteBackward, onKeyDown } = editor;

  editor.isInline = element =>
    element.type === TYPE_MENTION ? true : isInline(element);

  editor.isVoid = element =>
    element.type === TYPE_MENTION ? true : isVoid(element);

  editor.onKeyDown = event => {
    if (isHotkey('backspace', event)) {
      const isActive = isNodeTypeIn(editor, [TYPE_MENTION]);
      if (isActive) {
        deleteBackward('character');
        return;
      }
    }
    onKeyDown(event);
  };
  editor.insertMention = (
    selectedName,
    selectedIdentifier,
    loggedInUser,
    isTeam = false
  ) => {
    const mention = {
      type: TYPE_MENTION,
      data: {
        name: selectedName,
        ...(isTeam
          ? { team_uuid: selectedIdentifier }
          : { email: selectedIdentifier }),
        block_updated: moment().format(),
        created_by: loggedInUser.email
      },
      children: [{ text: `@${selectedName}` }]
    };

    Transforms.insertNodes(editor, mention);
    Transforms.move(editor);
    // moving the one space ahead after inserting mention user
    Transforms.insertText(editor, ' ');
    ReactEditor.focus(editor);
  };

  return editor;
};

export default withMentions;
