const initialState = {
  data: {
    accounts: [],
    currentlySelectedAccount: null
  },
  fetching: false,
  error: null
};

const tags = (state = initialState, action) => {
  switch (action.type) {
    case 'TAG_SELECTED':
      return {
        ...state,
        data: {
          ...state.data,
          currentlySelectedAccount: {
            name: action.payload.label,
            uuid: action.payload.value
          }
        }
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};

export default tags;
