/*
    Motivation is to build a tree like structure that has children, that are recursively rendered, with each parent
    expandable or collapsable with the appropriate chevron icons. Example: Folders having folders and files, with those
    children folders having files and folder infinitely nested.

    The container has no styling of its own since its expected to live inside a custom
    parent, like a popover or a dialog. Please use this inside one of those, and pass in the appropriate,
    styles to it

    type TNestedTree = {
        uuid: string -> the unique identifier of the folder, file, or space.
        label: string -> the name of the folder, file, or space.
        children: TNestedTree[] -> the children of the current node.
        icon?: React.ReactNode -> the icon to display next to the label.    
        isSelectable: boolean -> whether the node is selectable or not.
    }
*/

import { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import AvomaCheckbox from '../AvomaCheckbox';
import ButtonUnstyled from '../ButtonUnstyled';
import SearchField from '../search/SearchField';

import highlightSelection from 'helpers/highlightSelection';
import { ReactComponent as DownArrowIcon } from 'images/downArrow.svg';
import { ReactComponent as CheckGreenCircle } from 'images/ic_check_green_circle.svg';

const TreeButton = ({
  item,
  search = '',
  handleClick,
  handleCheckboxClick,
  selectedItem,
  roleType,
  checkedItems = [], // for checkbox roleType
  rowClassName = ''
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (search.length > 0) {
      setIsExpanded(true);
    }
  }, [search]);

  const hasChildren = item?.children?.length > 0;

  const handleButtonClick = () => {
    if (hasChildren) {
      setIsExpanded(!isExpanded);
      return;
    }

    if (item.isSelectable && handleClick && roleType === 'button') {
      handleClick(item.uuid);
    }
  };

  const isSelected = useMemo(() => {
    if (!selectedItem) return false;
    if (selectedItem === item.uuid) return true;
    return false;
  }, [selectedItem, item]);

  const handleCheckboxCtaClick = () => {
    if (hasChildren) {
      setIsExpanded(!isExpanded);
      return;
    }
    let isChecked = true;

    if (checkedItems.includes(item.uuid)) {
      isChecked = false;
    }

    handleCheckboxClick(item, isChecked);
  };

  return (
    <div className='flex flex-col'>
      <div className='flex hover:bg-snow'>
        <ButtonUnstyled
          noDefaultStyles
          className={twMerge(
            'mb-0.5 flex w-full cursor-pointer items-center gap-3 rounded bg-transparent px-1.5 py-1 hover:bg-snow',
            rowClassName
          )}
          onClick={
            roleType === 'button' ? handleButtonClick : handleCheckboxCtaClick
          }
        >
          <DownArrowIcon
            className={twMerge(
              'flex h-1.5  w-1.5 transition-all',
              isExpanded ? 'rotate-0' : '-rotate-90',
              hasChildren ? 'visible opacity-100' : 'invisible opacity-0'
            )}
          />
          {item?.icon}

          <div className='flex flex-col items-start'>
            <span className='text-sm font-normal'>
              {highlightSelection(item?.label, search)}
            </span>
            {item?.subtitle && (
              <span className='text-xs text-silver'>{item.subtitle}</span>
            )}
          </div>

          {isSelected && roleType === ' button' && (
            <CheckGreenCircle className='ml-auto mr-2 h-4 w-4' />
          )}
        </ButtonUnstyled>
        {roleType === 'checkbox' && (
          <AvomaCheckbox
            id={`${item.uuid}--checkbox`}
            onChange={e => handleCheckboxClick(item, e.target.checked)}
            checked={checkedItems.includes(item.uuid)}
            className='ml-auto'
          />
        )}
      </div>

      {hasChildren && isExpanded && (
        <div className='ml-6 flex flex-col'>
          {item.children.map(child => (
            <TreeButton
              key={child.uuid}
              item={child}
              search={search}
              handleClick={handleClick}
              selectedItem={selectedItem}
              roleType={roleType}
              checkedItems={checkedItems}
              handleCheckboxClick={handleCheckboxClick}
            />
          ))}
        </div>
      )}
    </div>
  );
};

TreeButton.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.object),
    icon: PropTypes.node,
    uuid: PropTypes.string.isRequired,
    isSelectable: PropTypes.bool
  }).isRequired,
  search: PropTypes.string,
  handleClick: PropTypes.func,
  handleCheckboxClick: PropTypes.func,
  selectedItem: PropTypes.string.isRequired,
  roleType: PropTypes.oneOf(['button', 'checkbox']),
  checkedItems: PropTypes.array,
  rowClassName: PropTypes.string
};

function filterNestedTree(tree, filterTerm) {
  return tree
    .map(item => {
      if (item.label.toLowerCase().includes(filterTerm.toLowerCase())) {
        return item;
      }

      if (item.children && item.children.length > 0) {
        const filteredChildren = filterNestedTree(item.children, filterTerm);
        if (filteredChildren.length === 0) return null;
        return { ...item, children: filteredChildren };
      }

      return null;
    })
    .filter(item => item !== null);
}

const AvomaNestedTree = ({
  data,
  height,
  showSearch,
  handleClick,
  selectedItem, // uuid of the selected item, for button roleType
  roleType = 'button',
  searchPlaceholder = 'Search',
  checkedItems = [], // for checkbox roleType
  handleCheckboxClick, // for checkbox roleType
  rowClassName = ''
}) => {
  const [search, setSearch] = useState('');
  const filteredTree = useMemo(
    () => filterNestedTree(data, search),
    [data, search]
  );

  return (
    <div
      className={twMerge(
        'flex flex-col overflow-y-auto overflow-x-hidden',
        height
      )}
    >
      {showSearch && (
        <div className='mb-4 px-2'>
          <SearchField
            placeholder={searchPlaceholder}
            instantSearch
            handleSearch={val => setSearch(val)}
            handleClear={() => setSearch('')}
          />
        </div>
      )}
      <div className='px-1'>
        {filteredTree?.map(item => (
          <TreeButton
            key={item.uuid}
            item={item}
            search={search}
            handleClick={handleClick}
            selectedItem={selectedItem}
            roleType={roleType}
            checkedItems={checkedItems}
            handleCheckboxClick={handleCheckboxClick}
            rowClassName={rowClassName}
          />
        ))}
      </div>
    </div>
  );
};

AvomaNestedTree.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.object),
      uuid: PropTypes.string.isRequired,
      isSelectable: PropTypes.bool
    })
  ).isRequired,
  height: PropTypes.string,
  showSearch: PropTypes.bool,
  handleClick: PropTypes.func,
  selectedItem: PropTypes.string.isRequired,
  roleType: PropTypes.oneOf(['button', 'checkbox']),
  searchPlaceholder: PropTypes.string,
  checkedItems: PropTypes.array,
  handleCheckboxClick: PropTypes.func,
  rowClassName: PropTypes.string
};

export default AvomaNestedTree;
