import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import map from 'lodash/map';

import { MEETINGS_VIEW_TYPES } from 'constants/meetings';

export const prepareQuery = (
  { queryType, value, addons },
  query,
  view,
  isAvoma
) => {
  const newQuery = query;
  switch (queryType) {
    case 'closeDateColumn':
    case 'accountNameColumn':
    case 'stageColumn':
    case 'lastContactedColumn':
    case 'ownerColumn':
    case 'modifiedSourceColumn':
    case 'amountColumn':
    case 'riskScoreColumn':
    case 'customOrder': {
      if (!value) {
        newQuery[queryType] = '';
        return newQuery;
      }
      newQuery[queryType] = `o=${value}&`;
      return newQuery;
    }
    case 'crmAccounts': {
      const accountsQuery = value.reduce((acc, el) => {
        let accu = acc;
        accu += `crm_accounts=${el.tagUuid}&`;
        return accu;
      }, '');
      newQuery.accounts = accountsQuery;
      return newQuery;
    }
    case 'accounts': {
      const accountsQuery = value.reduce((acc, el) => {
        let accu = acc;
        accu += `accounts=${el.uuid}&`;
        return accu;
      }, '');
      newQuery.accounts = accountsQuery;
      return newQuery;
    }
    case 'amountNow': {
      const min = value.min
        ? `crm_opportunity_amount_now__gte=${value.min}`
        : '';
      const max = value.max
        ? `${min ? '&' : ''}crm_opportunity_amount_now__lte=${value.max}`
        : '';
      newQuery.amountNow = min + max ? `${min}${max}&` : '';
      return newQuery;
    }
    case 'annualRevenue': {
      const min = value.min ? `annual_revenue__gte=${value.min}` : '';
      const max = value.max
        ? `${min ? '&' : ''}annual_revenue__lte=${value.max}`
        : '';
      newQuery.annualRevenue = min + max ? `${min}${max}&` : '';
      return newQuery;
    }
    case 'more': {
      const minText =
        value.minText && !Number.isNaN(value.minText)
          ? `recording_duration__gte=${parseInt(value.minText, 10) * 60}`
          : '';
      const maxDuration = parseInt(value.maxText, 10) * 60;
      const maxText =
        value.maxText && !Number.isNaN(value.maxText)
          ? `${minText ? '&' : ''}recording_duration__lte=${maxDuration}`
          : '';
      newQuery.meetingDuration =
        minText + maxText ? `${minText}${maxText}&` : '';
      if (
        value.showUnrecordedMeetings === true ||
        value.showUnrecordedMeetings === undefined
      ) {
        newQuery.unrecorded = '';
      } else if (isAvoma) {
        newQuery.unrecorded = 'recording_duration__gt=10&';
      } else {
        newQuery.unrecorded = 'recording_duration__isnull=false&';
      }

      if (value.includeInternalMeetings) {
        newQuery.internal = '';
      } else if (value.showInternalOnly) {
        newQuery.internal = 'is_internal=true&';
      } else {
        newQuery.internal = 'is_internal=false&';
      }
      if (value.sharedMeetingsOnly) {
        newQuery.shared = 'shared_meetings_only=true&';
      } else {
        newQuery.shared = '';
      }

      if (value.showRecordingEnabled) {
        newQuery.recordingEnabled = 'recording_enabled=enabled&';
      } else {
        newQuery.recordingEnabled = '';
      }

      if (value.isConference) {
        newQuery.isConference = 'is_conference=true&';
      } else {
        newQuery.isConference = '';
      }

      return newQuery;
    }
    case 'meetingsScored': {
      if (value) {
        newQuery.meetingsScored = 'has_scorecards=true&';
      } else {
        newQuery.meetingsScored = '';
      }

      return newQuery;
    }
    case 'addedToPlaylist': {
      if (value) {
        newQuery.addedToPlaylist = 'in_playlists=true&';
      } else {
        newQuery.addedToPlaylist = '';
      }

      return newQuery;
    }
    case 'meetingsHasSnippets': {
      if (value) {
        newQuery.meetingsHasSnippets = 'has_snippets=true&';
      } else {
        newQuery.meetingsHasSnippets = '';
      }

      return newQuery;
    }
    case 'meetingsHasComments': {
      if (value) {
        newQuery.meetingsHasComments = 'has_comments=true&';
      } else {
        newQuery.meetingsHasComments = '';
      }

      return newQuery;
    }
    case 'meetingPrivacy': {
      if (!isEmpty(value)) {
        newQuery.meetingPrivacy = `privacy=${value.join(',')}&`;
      } else {
        newQuery.meetingPrivacy = '';
      }

      return newQuery;
    }
    case 'videoConferencing': {
      if (!isEmpty(value)) {
        newQuery.videoConferencing = `conference_service=${value.join(',')}&`;
      } else {
        newQuery.videoConferencing = '';
      }

      return newQuery;
    }
    case 'stageDuring':
    case 'stageNow': {
      if (!value) {
        newQuery[queryType] = '';
        return newQuery;
      }
      if (addons) {
        if (queryType === 'stageDuring') {
          let stageQuery = '';
          Object.keys(value).forEach(key => {
            if (value[key] === true) {
              stageQuery += `crm_opportunity_stage_during=${addons[key].externalId}&`;
            }
          });
          newQuery.stageDuring = stageQuery;
        } else if (queryType === 'stageNow') {
          let stageQuery = '';
          Object.keys(value).forEach(key => {
            if (value[key] === true) {
              stageQuery += `crm_opportunity_stage_now=${addons[key].externalId}&`;
            }
          });
          newQuery.stageNow = stageQuery;
        }
      }
      return newQuery;
    }
    case 'dealStageNow': {
      if (!value) {
        newQuery[queryType] = '';
        return newQuery;
      }

      let stageNowQuery = '';
      Object.values(value).forEach(stage => {
        if (stage.isChecked) {
          stageNowQuery += `crm_opportunity_stage_now=${stage.externalId}&`;
        }
      });
      newQuery.dealStageNow = stageNowQuery;
      return newQuery;
    }
    case 'date': {
      if (!value) {
        newQuery[queryType] = '';
        return newQuery;
      }
      const dateQuery = `start_at__gte=${value.from}&end_at__lte=${value.to}&`;
      newQuery[queryType] = dateQuery;
      return newQuery;
    }
    case 'calendarDate':
    case 'calendarWeekDate': {
      if (!value) {
        newQuery[queryType] = '';
        return newQuery;
      }
      const dateQuery = `start_at__gte=${value.from}&end_at__lte=${value.to}&`;
      newQuery[queryType] = dateQuery;
      return newQuery;
    }
    case 'scorecardDateRange': {
      if (!value) {
        newQuery[queryType] = '';
        return newQuery;
      }

      const dateQuery =
        addons?.view === 'scorecards'
          ? `modified_gte=${value.from}&modified_lte=${value.to}&`
          : `meeting__start_at__gte=${value.from}&meeting__end_at__lte=${value.to}&`;

      newQuery[queryType] = dateQuery;
      return newQuery;
    }
    case 'pipeline': {
      if (!value) {
        newQuery[queryType] = '';
        return newQuery;
      }
      const dateQuery = `pipeline=${value}&`;
      newQuery[queryType] = dateQuery;
      return newQuery;
    }
    case 'closeDate': {
      if (!value) {
        newQuery[queryType] = '';
        return newQuery;
      }
      const dateSelectorValue = value.dateSelectorValue
        ? `closed_date_selector=${value.dateSelectorValue}`
        : '';
      const dateQuery = `close_date_start_at__gte=${value.from}&close_date_end_at__lte=${value.to}&${dateSelectorValue}&`;
      newQuery[queryType] = dateQuery;
      return newQuery;
    }
    case 'createdDate': {
      if (!value) {
        newQuery[queryType] = '';
        return newQuery;
      }
      const dateSelectorValue = value.dateSelectorValue
        ? `created_date_selector=${value.dateSelectorValue}`
        : '';
      const dateQuery = `created_date_start_at__gte=${value.from}&created_date_end_at__lte=${value.to}&${dateSelectorValue}&`;
      newQuery[queryType] = dateQuery;
      return newQuery;
    }
    case 'lastContactedDate': {
      if (!value) {
        newQuery[queryType] = '';
        return newQuery;
      }
      const dateSelectorValue = value.dateSelectorValue
        ? `last_contacted_selector=${value.dateSelectorValue}`
        : '';
      const dateQuery = `last_contacted__gte=${value.from}&last_contacted__lte=${value.to}&${dateSelectorValue}&`;
      newQuery[queryType] = dateQuery;
      return newQuery;
    }
    case 'nextMeetingDate': {
      if (!value) {
        newQuery[queryType] = '';
        return newQuery;
      }
      const dateSelectorValue = value.dateSelectorValue
        ? `next_meeting_date_selector=${value.dateSelectorValue}`
        : '';
      const dateQuery = `next_meeting_date__gte=${value.from}&next_meeting_date__lte=${value.to}&${dateSelectorValue}&`;
      newQuery[queryType] = dateQuery;
      return newQuery;
    }
    case 'dealStatus': {
      if (!value) {
        newQuery.dealStatus = '';
        return newQuery;
      }
      newQuery.dealStatus = value.map(val => `deal_status=${val}&`).join('');
      return newQuery;
    }
    case 'forecastCategory': {
      if (!value) {
        newQuery.forecastCategory = '';
        return newQuery;
      }
      newQuery.forecastCategory = value
        .map(val => `forecast_category=${val}&`)
        .join('');
      return newQuery;
    }
    case 'risk': {
      if (!value) {
        newQuery.risk = '';
        return newQuery;
      }
      const filterQuery = `risk=${value}&`;
      newQuery.risk = filterQuery;
      return newQuery;
    }
    case 'nextMeeting': {
      if (!value) {
        newQuery.nextMeeting = '';
        return newQuery;
      }
      const filterQuery = `next_meeting_exists=${value}&`;
      newQuery.nextMeeting = filterQuery;
      return newQuery;
    }
    case 'sortBy': {
      if (!value) {
        newQuery.sortBy = '';
        return newQuery;
      }
      const dateQuery = `o=${value}&`;
      newQuery.sortBy = dateQuery;
      return newQuery;
    }
    case 'transText': {
      const transTextQuery = value.reduce((acc, el) => {
        let accu = acc;
        accu += `trans_text=${encodeURIComponent(el.value)}&`;
        return accu;
      }, '');
      newQuery.transText = transTextQuery;
      return newQuery;
    }
    case 'team': {
      newQuery.team = '';
      if (value.users) {
        let usersQuery = value.users.reduce((acc, el) => {
          let accu = acc;
          accu += `${addons?.queryKey || 'user_email'}=${el}&`;
          return accu;
        }, '');
        // Adding this inside users since we want to accumulate the
        // result, since its a case of having both user and organizer email,
        // rather than or.
        if (value.organizers) {
          const organizersQuery = value.organizers.reduce((acc, el) => {
            let accu = acc;
            accu += `organizer_email=${el}&`;
            return accu;
          }, '');
          usersQuery += organizersQuery;
        }
        newQuery.team = usersQuery;
      }
      if (value.teams) {
        const teamsQuery = Object.keys(value.teams).reduce((acc, el) => {
          let accu = acc;
          if (value.teams[el]) {
            accu += `team_uuid=${el}&`;
          }
          return accu;
        }, '');
        newQuery.team += teamsQuery;
      }
      return newQuery;
    }
    case 'teams': {
      const teamsQuery = Object.keys(value.teams).reduce((acc, el) => {
        let accu = acc;
        if (value.teams[el]) {
          accu += `team_uuid=${el}&`;
        }
        return accu;
      }, '');
      newQuery.team += teamsQuery;
      return newQuery;
    }
    case 'users': {
      return value.users.reduce((acc, el) => {
        let accu = acc;
        accu += `${addons?.queryKey || 'user_email'}=${el}&`;
        return accu;
      }, '');
    }
    case 'organizers': {
      const organizersQuery = value.reduce((acc, el) => {
        let accu = acc;
        accu += `organizer_email=${el}&`;
        return accu;
      }, '');
      newQuery.organizers = organizersQuery;
      return newQuery;
    }
    case 'scorecardForTeamUuid': {
      if (value.users) {
        let usersQuery = value.users.flatMap(uuid => uuid.split(','));
        // Adding this inside users since we want to accumulate the
        // result, since its a case of having both user and organizer email,
        // rather than or.
        if (value.organizers) {
          const organizersQuery = value.organizers.flatMap(uuid =>
            uuid.split(',')
          );
          usersQuery += organizersQuery;
        }
        const scorecardForEmailQuery = `scorecard_for_email_in=${usersQuery}&`;
        newQuery[queryType] = scorecardForEmailQuery;
      }
      if (value.teams) {
        const teamsQuery = Object.keys(value.teams)
          .filter(uuid => value.teams[uuid] === true)
          .join(',');
        const scorecardForTeamUuidQuery = `scorecard_for_team_uuid_in=${teamsQuery}&`;
        newQuery[queryType] += scorecardForTeamUuidQuery;
      } else {
        newQuery[queryType] = '';
      }
      return newQuery;
    }
    case 'scorecardCreatedByTeamUuid': {
      if (value.users) {
        let usersQuery = value.users.flatMap(uuid => uuid.split(','));
        // Adding this inside users since we want to accumulate the
        // result, since its a case of having both user and organizer email,
        // rather than or.
        if (value.organizers) {
          const organizersQuery = value.organizers.flatMap(uuid =>
            uuid.split(',')
          );
          usersQuery += organizersQuery;
        }
        const scorecardCreatedByEmailQuery = `created_by_email_in=${usersQuery}&`;
        newQuery[queryType] = scorecardCreatedByEmailQuery;
      }
      if (value.teams) {
        const teamsQuery = Object.keys(value.teams)
          .filter(uuid => value.teams[uuid] === true)
          .join(',');
        const scorecardCreatedByTeamUuidQuery = `created_by_team_uuid_in=${teamsQuery}&`;
        newQuery[queryType] += scorecardCreatedByTeamUuidQuery;
      } else {
        newQuery[queryType] = '';
      }
      return newQuery;
    }
    case 'scorecardRequestedToEmail': {
      if (value.users) {
        let usersQuery = value.users.flatMap(uuid => uuid.split(','));
        // Adding this inside users since we want to accumulate the
        // result, since its a case of having both user and organizer email,
        // rather than or.
        if (value.organizers) {
          const organizersQuery = value.organizers.flatMap(uuid =>
            uuid.split(',')
          );
          usersQuery += organizersQuery;
        }
        const scorecardCreatedByEmailQuery = `created_by_email_in=${usersQuery}&`;
        newQuery[queryType] = scorecardCreatedByEmailQuery;
      }
      if (value.teams) {
        const teamsQuery = Object.keys(value.teams)
          .filter(uuid => value.teams[uuid] === true)
          .join(',');
        const scorecardCreatedByTeamUuidQuery = `created_by_team_uuid_in=${teamsQuery}&`;
        newQuery[queryType] += scorecardCreatedByTeamUuidQuery;
      } else {
        newQuery[queryType] = '';
      }
      return newQuery;
    }
    case 'scorecardRequestedByEmail': {
      if (value.users) {
        let usersQuery = value.users.flatMap(uuid => uuid.split(','));
        // Adding this inside users since we want to accumulate the
        // result, since its a case of having both user and organizer email,
        // rather than or.
        if (value.organizers) {
          const organizersQuery = value.organizers.flatMap(uuid =>
            uuid.split(',')
          );
          usersQuery += organizersQuery;
        }
        const scorecardRequestedByEmailQuery = `requested_by_email_in=${usersQuery}&`;
        newQuery[queryType] = scorecardRequestedByEmailQuery;
      }
      if (value.teams) {
        const teamsQuery = Object.keys(value.teams)
          .filter(uuid => value.teams[uuid] === true)
          .join(',');
        const scorecardRequestedByTeamUuidQuery = `requested_by_team_uuid_in=${teamsQuery}&`;
        newQuery[queryType] += scorecardRequestedByTeamUuidQuery;
      } else {
        newQuery[queryType] = '';
      }
      return newQuery;
    }
    case 'scorecardScoreRange': {
      const scoreRangeQuery = Object.keys(value)
        .filter(range => value[range] === true)
        .join(',');

      const scorecardQuery = `score_range_in=${scoreRangeQuery}&`;
      newQuery[queryType] = scorecardQuery;

      return newQuery;
    }
    case 'scorecardAverageScoreRange': {
      const scoreAverageRangeQuery = Object.keys(value)
        .filter(range => value[range] === true)
        .join(',');

      const scorecardQuery = `score_average_range_in=${scoreAverageRangeQuery}&`;
      newQuery[queryType] = scorecardQuery;

      return newQuery;
    }
    case 'scorecardTemplateUuid': {
      const templatesQuery = Object.keys(value)
        .filter(uuid => value[uuid] === true)
        .join(',');

      const scorecardQuery = `scorecard_template_uuid_in=${templatesQuery}&`;
      newQuery[queryType] = scorecardQuery;

      return newQuery;
    }
    case 'scorecardTemplateType': {
      let scorecardScoredByQuery = '';
      if (value.scorecardManualTemplatesOnly) {
        scorecardScoredByQuery += 'is_ai=false&';
      }
      if (value.scorecardAiTemplatesOnly) {
        scorecardScoredByQuery += 'is_ai=true&';
      } else {
        scorecardScoredByQuery += '';
      }
      newQuery[queryType] = scorecardScoredByQuery;

      return newQuery;
    }
    case 'scorecardMeetingTypeUuid': {
      const typesQuery = Object.keys(value)
        .filter(uuid => value[uuid] === true)
        .join(',');

      const scorecardMeetingTypeQuery = `meeting_type_uuid_in=${typesQuery}&`;
      newQuery[queryType] = scorecardMeetingTypeQuery;

      return newQuery;
    }
    case 'scorecardType': {
      let scorecardScoredByQuery = '';
      if (value.scorecardScoredByMembersOnly) {
        scorecardScoredByQuery += 'scored_by_ai_only=false&';
      }
      if (value.scorecardScoredByAiOnly) {
        scorecardScoredByQuery += 'scored_by_ai_only=true&';
      } else {
        scorecardScoredByQuery += '';
      }
      newQuery[queryType] = scorecardScoredByQuery;

      return newQuery;
    }
    case 'types': {
      const filteredUuids = filter(map(value, (v, uuid) => (v ? uuid : false)));
      const queryStrings = map(
        filteredUuids,
        uuid => `meeting_type_uuid=${uuid}`
      );
      newQuery.types =
        queryStrings.length > 0 ? `${queryStrings.join('&')}&` : '';
      return newQuery;
    }
    case 'outcomes': {
      const filteredUuids = filter(map(value, (v, uuid) => (v ? uuid : false)));
      const queryStrings = map(
        filteredUuids,
        uuid => `meeting_outcome_uuid=${uuid}`
      );
      newQuery.outcomes =
        queryStrings.length > 0 ? `${queryStrings.join('&')}&` : '';
      return newQuery;
    }
    case 'call': {
      let callQuery = '';
      if (value.callsOnly) {
        callQuery = 'is_call=true&';
      }
      if (value.meetingsOnly) {
        callQuery = 'is_call=false&';
      }
      if (value.inbound) {
        callQuery += 'call_direction=inbound&call_direction=Inbound&';
      }
      if (value.outbound) {
        callQuery += 'call_direction=outbound&call_direction=Outbound&';
      }
      if (value.answered) {
        callQuery += 'is_call_answered=true&';
      }
      newQuery.calls = callQuery;
      return newQuery;
    }
    case 'saidBy': {
      let saidByQuery = '';

      if (value.organization && value.otherParty) {
        saidByQuery =
          'include_rep_in_search=true&include_non_rep_in_search=true&';
      }

      if (value.organization && !value.otherParty) {
        saidByQuery =
          'include_rep_in_search=true&include_non_rep_in_search=false&';
      }

      if (!value.organization && value.otherParty) {
        saidByQuery =
          'include_rep_in_search=false&include_non_rep_in_search=true&';
      }

      newQuery.saidBy = saidByQuery;
      return newQuery;
    }
    // This is a combination of keywords and custom categories since they both are placed in
    // the same bucket for the global search (Categories show up as Keywords in the valueContainer)
    case 'customCategory': {
      if (Array.isArray(value) && value.length > 0) {
        const searchTermArr = value.filter(
          category => category.__isNew__ === true
        );
        const customCategoryArr = value.filter(
          category => category.__isNew__ === undefined
        );

        if (searchTermArr.length > 0) {
          const uuidArr = searchTermArr.map(searchTerms =>
            encodeURIComponent(searchTerms.value)
          );
          const queryStr = uuidArr.join('&search=');
          const finalQuery = `search=${queryStr}&`;
          newQuery.searchTerm = finalQuery;
        }

        if (customCategoryArr.length > 0) {
          const uuidArr = customCategoryArr.map(category => category.uuid);
          const queryStr = uuidArr.join('&custom_category=');
          const finalQuery = `custom_category=${queryStr}&`;
          newQuery.customCategory = finalQuery;
        } else {
          newQuery.customCategory = '';
        }
      } else {
        newQuery.customCategory = '';
      }

      return newQuery;
    }
    case 'searchFields': {
      if (Array.isArray(value) && value.length > 0) {
        const searchFieldsArr = value;

        if (searchFieldsArr.length > 0) {
          const queryStr = searchFieldsArr.join('&search_fields=');
          const finalQuery = `search_fields=${queryStr}&`;
          newQuery.searchFields = finalQuery;
        } else {
          newQuery.searchFields = '';
        }
      } else {
        newQuery.searchFields = '';
      }

      return newQuery;
    }
    case 'dealSearch': {
      if (value.length > 0) {
        const queryStr = `deal_name=${value}&`;
        newQuery.dealSearch = queryStr;
      } else {
        newQuery.dealSearch = '';
      }

      return newQuery;
    }
    case 'companySearch': {
      if (value.length > 0) {
        const queryStr = `company_name=${value}&`;
        newQuery.companySearch = queryStr;
      } else {
        newQuery.companySearch = '';
      }

      return newQuery;
    }
    // This will be called when keywords are a standalone filter use case (Eg: Smart Playlists), so that
    // when we wipe this clean, it won't affect any existing customCategory filters, since this now makes
    // it fall in a complete different bucket
    case 'keywords': {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          const uuidArr = value.map(searchTerms =>
            encodeURIComponent(searchTerms.value)
          );
          const queryStr = uuidArr.join('&search=');
          const finalQuery = `search=${queryStr}&`;
          newQuery.searchTerm = finalQuery;
        } else {
          newQuery.searchTerm = '';
        }
      }
      return newQuery;
    }
    case 'keywordsInTranscript': {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          const uuidArr = value
            .filter(term => typeof term === 'string')
            .map(searchTerm => encodeURIComponent(searchTerm.trim()));
          const queryStr = uuidArr.join('&search=');
          const finalQuery = `search=${queryStr}&`;
          newQuery.searchTerm = finalQuery;
        } else {
          newQuery.searchTerm = '';
        }
      }
      return newQuery;
    }
    default: {
      if (
        Object.prototype.hasOwnProperty.call(value, 'from') &&
        Object.prototype.hasOwnProperty.call(value, 'to')
      ) {
        if (value.from && value.to) {
          const dateQuery = `${queryType}__gte=${value.from}&${queryType}__lte=${value.to}&`;
          newQuery[queryType] = dateQuery;
        }
        return newQuery;
      }
      // `isArray` in `addons` is to indicate that the value is an array, which means we need multiple query params
      const queryStr = addons?.isArray
        ? value.reduce((acc, val) => `${acc}&${queryType}=${val}`, '')
        : `${queryType}=${value}`;
      newQuery[queryType] = queryStr;
      return newQuery;
    }
  }
};

export const viewMapping = {
  allMeetings: 'all_meetings'
};

export const viewMappingReserve = {
  all_meetings: 'allMeetings'
};

export const getTooltipPosition = filterId => {
  const parentEl = document.getElementById('filterContainer');
  const filterEl = document.getElementById(filterId);

  if (parentEl && filterEl) {
    const parentElLeft = parentEl.getBoundingClientRect().left;
    const filterElLeft = filterEl.getBoundingClientRect().left;

    if (filterElLeft - parentElLeft < 15) {
      return 'top-right';
    }
    return 'top';
  }
  return 'top';
};

export const constructQuery = queryObj => {
  const {
    date,
    event,
    meetingDuration,
    internal,
    unrecorded,
    shared,
    stageDuring,
    calls,
    types,
    outcomes,
    searchTerm,
    customCategory,
    team,
    accounts,
    stageNow,
    amountNow,
    dealStatus,
    forecastCategory,
    sortBy,
    closeDate,
    createdDate,
    lastContactedDate,
    nextMeetingDate,
    crmAccounts,
    pipeline,
    dealStageNow,
    amountColumn,
    closeDateColumn,
    lastContactedColumn,
    stageColumn,
    ownerColumn,
    accountNameColumn,
    saidBy,
    dealSearch,
    scorecardDateRange,
    scorecardForEmail,
    scorecardForTeamUuid,
    scorecardCreatedByEmail,
    scorecardCreatedByTeamUuid,
    scorecardRequestedToEmail,
    scorecardRequestedByEmail,
    scorecardScoreRange,
    scorecardAverageScoreRange,
    scorecardTemplateUuid,
    scorecardTemplateType,
    scorecardMeetingTypeUuid,
    scorecardType,
    riskScoreColumn,
    risk,
    nextMeeting,
    calendarDate,
    annualRevenue,
    ...rest
  } = queryObj;

  const baseQueries = [
    date,
    event,
    meetingDuration,
    internal,
    shared,
    unrecorded,
    stageNow,
    stageDuring,
    amountNow,
    accounts,
    team,
    calls,
    types,
    outcomes,
    customCategory,
    searchTerm,
    dealStatus,
    forecastCategory,
    sortBy,
    closeDate,
    createdDate,
    lastContactedDate,
    nextMeetingDate,
    crmAccounts,
    pipeline,
    dealStageNow,
    amountColumn,
    closeDateColumn,
    lastContactedColumn,
    stageColumn,
    ownerColumn,
    accountNameColumn,
    saidBy,
    dealSearch,
    scorecardDateRange,
    scorecardForEmail,
    scorecardForTeamUuid,
    scorecardCreatedByEmail,
    scorecardCreatedByTeamUuid,
    scorecardRequestedToEmail,
    scorecardRequestedByEmail,
    scorecardScoreRange,
    scorecardAverageScoreRange,
    scorecardTemplateUuid,
    scorecardTemplateType,
    scorecardMeetingTypeUuid,
    scorecardType,
    riskScoreColumn,
    risk,
    nextMeeting,
    calendarDate,
    annualRevenue,
    // Support custom filters
    ...Object.values(rest)
      .filter(val => {
        // So we don't include empty values or string Eg: CustomFilter= as it sends it in the payload
        const splitVal = val.split('=');
        return splitVal?.[1] && splitVal?.[1] !== '';
      })
      .map(val => `${val}&`)
  ];
  return baseQueries.join('');
};

export const deconstructFiltersFromUrl = queryString =>
  queryString
    .split('&')
    .filter(query => query)
    .map(query => query.split('='))
    .reduce((appliedFilters, [key, value]) => {
      // If the key already exists, push into the array (Eg: Multiple teams can be selected)
      if (appliedFilters[key] === undefined) {
        appliedFilters[key] = [];
      }
      appliedFilters[key].push(decodeURIComponent(value));
      return appliedFilters;
    }, {});

export const getMoreFilterCount = value => {
  let countNumber = 0;
  if (value.minText || value.maxText) countNumber += 1;
  if (
    (typeof value.includeInternalMeetings === 'boolean' &&
      !value.includeInternalMeetings) ||
    (typeof value.showInternalOnly === 'boolean' && value.showInternalOnly)
  )
    countNumber += 1;
  if (
    typeof value.showUnrecordedMeetings === 'boolean' &&
    !value.showUnrecordedMeetings
  )
    countNumber += 1;
  if (value.sharedMeetingsOnly) countNumber += 1;
  return countNumber;
};

export const getValueAndKeyFromMeetingFilters = (filters = {}) => {
  const filtersToBeConsidered = Object.entries(filters).filter(
    ([key]) => key !== 'date' && key !== 'keywordsInTranscript' // date filter is not required for alerts as alerts cannot be created for a specific date
  );
  const len = filtersToBeConsidered?.length - 1;
  return filtersToBeConsidered.map(([key, value], index) => {
    let val = value;
    if (
      value &&
      isObject(value) &&
      !Array.isArray(value) &&
      ![
        'amountNow',
        'more',
        'customCategory',
        'meetingDuration',
        'saidBy'
      ].includes(key) // amountNow and more are special cases where we need value as dict
    ) {
      val = Object.keys(value);
    }

    if (key === 'amountNow' && value) {
      val = [value.min, value.max];
    }

    if (key === 'meetingDuration' && value) {
      val = [value.min, value.max];
    }

    if (key === 'saidBy' && value) {
      val = value;
    }

    return { key, value: val, isLast: len === index, order: index };
  });
};

export const getValueAndKeyFromMeetingFiltersDrawer = (
  filters = {},
  filterOrder
) => {
  const filtersToBeConsidered = Object.entries(filters).filter(
    ([key]) => !['count', 'query', 'organizers'].includes(key)
  );

  return filtersToBeConsidered.map(([key, value]) => {
    let val = value;
    if (
      value &&
      isObject(value) &&
      !Array.isArray(value) &&
      ![
        'amountNow',
        'more',
        'meetingDuration',
        'saidBy',
        'keywordsInTranscript',
        'date',
        'teams'
      ].includes(key) // amountNow and more are special cases where we need value as dict
    ) {
      val = Object.keys(value);
    }

    if (key === 'amountNow' && value) {
      val = [value.min, value.max];
    }

    if (key === 'meetingDuration' || (key === 'saidBy' && value)) {
      val = value;
    }

    if (
      (key === 'keywordsInTranscript' || key === 'date' || key === 'teams') &&
      value
    ) {
      val = value;
    }

    return {
      key,
      value: val,
      isLast: filterOrder.length === filterOrder.indexOf(key) + 1,
      order: filterOrder.indexOf(key)
    };
  });
};

export const splitMeetingFiltersIntoIndividualKeys = (
  filters,
  view = 'allMeetings',
  filterToAdd = {},
  filterOrder,
  timelineFilter,
  appliedFilters = {}
) => {
  const newFilters = cloneDeep(filters);

  if (newFilters?.team?.teams) {
    newFilters.teams = newFilters?.team?.teams;
    filterOrder.splice(filterOrder.indexOf('team'), 1, 'teams');
  }

  if (!isEmpty(newFilters?.team?.users)) {
    if (newFilters.team.users.length === 0) {
      delete newFilters.teams;
      newFilters.users = [];
    } else {
      newFilters.users = newFilters.team.users || [];
      if (!isEmpty(newFilters.team?.organizers)) {
        newFilters.organizers = newFilters.team?.organizers || [];
      }
    }

    if (!filterOrder.includes('users')) {
      if (newFilters?.team?.teams) {
        filterOrder.splice(filterOrder.indexOf('teams') + 1, 0, 'users');
      } else if (filterOrder.indexOf('team')) {
        filterOrder.splice(filterOrder.indexOf('team'), 1, 'users');
      } else {
        filterOrder.push('users');
      }
    }
  }

  if (newFilters?.more?.minText || newFilters?.more?.maxText) {
    newFilters.meetingDuration = [
      newFilters.more?.minText || '',
      newFilters.more?.maxText || ''
    ];
  }

  if (newFilters?.date && isEmpty(newFilters?.date)) {
    delete newFilters.date;
  }

  if (
    (view === 'allMeetings' || view === 'globalSearch' || view === 'calls') &&
    !isEmpty(newFilters?.more)
  ) {
    moreFilterBooleanProperties[view].forEach(property => {
      if (Object.keys(newFilters?.more).includes(property)) {
        if (newFilters.more[property] === undefined) {
          newFilters[property] = newFilters.more[property];
        } else {
          newFilters[property] = newFilters.more[property];
        }
      }
    });

    const showInternalOnly = newFilters?.more?.showInternalOnly;

    let meetingType = '';
    if (showInternalOnly) {
      meetingType = 'internal';
    } else if (showInternalOnly === false) {
      meetingType = 'external';
    }

    if (!isEmpty(meetingType)) {
      delete newFilters.more.includeInternalMeetings;
      newFilters.meetingType = [meetingType];

      if (filterOrder.indexOf('meetingType') !== -1) {
        filterOrder.splice(filterOrder.indexOf('meetingType'), 1);
      }

      filterOrder.splice(
        filterOrder.indexOf('showInternalOnly'),
        1,
        'meetingType'
      );
      delete newFilters.showInternalOnly;
    } else {
      delete newFilters.more.showInternalOnly;
      delete newFilters.more.includeInternalMeetings;
    }
  }

  delete newFilters.team;
  delete newFilters.calendarDate; // we don't store calendar date in saved views
  delete newFilters.addons;

  ['count', 'addons', 'query'].forEach(key => {
    if (filterOrder.includes(key)) {
      filterOrder.splice(filterOrder.indexOf(key), 1);
    }
  });

  if (view !== 'calls') {
    delete newFilters.call; // for meeting, it'll always be meetingsOnly with no option to change
    if (filterOrder.includes('call')) {
      filterOrder.splice(filterOrder.indexOf('call'), 1);
    }
  }

  if (!isEmpty(filterToAdd) && newFilters[filterToAdd] === undefined) {
    if (filterToAdd === 'team') {
      newFilters.team = {};
    } else {
      newFilters[filterToAdd] = {};
    }
    filterOrder.push(filterToAdd);
  }

  if (timelineFilter === 'upcoming') {
    delete newFilters?.date;
  } else {
    delete newFilters?.more?.isConference;
    delete newFilters?.isConference;
  }

  return newFilters;
};

export const moreFilterBooleanProperties = {
  allMeetings: [
    'isConference',
    'showRecordingEnabled',
    'showUnrecordedMeetings',
    'sharedMeetingsOnly',
    'showInternalOnly'
  ],
  globalSearch: [
    'isConference',
    'showRecordingEnabled',
    'showUnrecordedMeetings',
    'sharedMeetingsOnly',
    'showInternalOnly'
  ],
  calls: ['sharedMeetingsOnly']
};

export const defaultMoreFiltersValue = {
  allMeetings: {
    showUnrecordedMeetings: false,
    includeInternalMeetings: true,
    showInternalOnly: undefined,
    sharedMeetingsOnly: true,
    isConference: true,
    showRecordingEnabled: true,
    maxText: '',
    minText: ''
  },
  globalSearch: {
    showUnrecordedMeetings: false,
    includeInternalMeetings: true,
    showInternalOnly: undefined,
    sharedMeetingsOnly: true,
    isConference: true,
    showRecordingEnabled: true,
    maxText: '',
    minText: ''
  },
  calls: {
    sharedMeetingsOnly: true
  }
};

export const filterOutEmptyValuesFromFilter = filterArr =>
  filterArr.filter(item => !['count', 'query', 'more'].includes(item.key));

export const SAID_BY_FILTER_OPTIONS = [
  { label: 'Avoma', value: 'organization' },
  { label: 'Other Party', value: 'otherParty' }
];

export const MEETING_TYPE_FILTER_OPTIONS = [
  { label: 'Internal Meetings', value: 'internal' },
  { label: 'External Meetings', value: 'external' }
];

export const MEETING_PRIVACY_FILTER_OPTIONS = [
  { label: 'Private', value: 'private' },
  { label: 'Organization', value: 'organization' },
  { label: 'Specific Teams', value: 'team' },
  { label: 'Public', value: 'public' }
];

export const getCalendarPayloadV3 = (calendarDate, filters) => {
  const payloadObj = {
    view_type: MEETINGS_VIEW_TYPES.CALENDAR,
    meeting_timeframe: {
      meeting_start_at__gte: calendarDate.from,
      meeting_end_at__lte: calendarDate.to
    }
  };

  return { ...payloadObj, ...filters };
};

export const LIST_OF_VIEWS_CUSTOM_FILTERS_TO_USE_LOCAL_TIMEZONE = [
  'meeting',
  'calls',
  'trackers'
];

export const LIST_OF_VIEWS_TO_USE_LOCAL_TIMEZONE = [
  'usage/activity',
  'usage',
  'usage/roi',
  'usage/member',
  'usage/recording_metrics',
  'usage/recording_metrics_members',
  'stats',
  'scheduler'
]; // 'membersPerformance','scorecardsPerformance','scorecards'
