import castArray from 'lodash/castArray';

import { getNodes } from './getNodes';

/**
 * Get the nodes with a type included in `types` at a location (default: selection).
 */
const getNodesByType = (editor, types, options = {}) => {
  const casted = castArray(types);

  return getNodes(editor, {
    match: n => casted.includes(n.type),
    ...options
  });
};

export default getNodesByType;
