import camelize from 'camelize';
// @ts-ignore TODO: Add types for decamelize-keys
import decamelizeKeys from 'decamelize-keys';
import { baseCreateApi } from 'services';
import { SubscriptionResponse } from 'types/subscription';

import { PLAN_NAMES } from 'constants/billing';

export const subscriptionApi = baseCreateApi.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getFeaturePlans: builder.query({
      providesTags: ['FeaturePlans'],
      query: ({
        feature,
        requireEntireData = false
      }: {
        feature: string;
        requireEntireData?: boolean;
      }) => ({
        url: `/api/v1/subscriptions/feature_plans/?feature_name=${feature}`,
        method: 'GET'
      }),
      transformResponse: (
        response: { [feature: string]: string[] },
        _,
        args: { feature: string; requireEntireData?: boolean }
      ) => {
        if (args.requireEntireData) {
          return camelize(response);
        }

        if (!response) return '';

        const planIds = response[args.feature as string] || [];
        const lowestPlan = planIds[planIds.length - 1]?.split('-')[0];

        if (Object.keys(PLAN_NAMES).includes(lowestPlan)) {
          return PLAN_NAMES[lowestPlan as keyof typeof PLAN_NAMES];
        }

        return '';
      }
    }),
    getActiveSubscriptions: builder.query<SubscriptionResponse[], void>({
      providesTags: ['ActiveSubscriptions'],
      query: () => ({
        url: '/api/v2/subscriptions/',
        method: 'GET'
      }),
      transformResponse: (response: unknown) =>
        camelize(response) as SubscriptionResponse[]
    }),
    activateAddon: builder.mutation({
      query: (payload: {
        addonUuids: string[];
        subscriptionUuid: string;
        userEmail: string;
      }) => ({
        url: '/api/v1/licenses/addons/activate/',
        method: 'POST',
        data: decamelizeKeys(payload)
      })
    }),
    deactivateAddon: builder.mutation({
      query: (payload: {
        addonUuids: string[];
        subscriptionUuid: string;
        userEmail: string;
      }) => ({
        url: '/api/v1/licenses/addons/deactivate/',
        method: 'POST',
        data: decamelizeKeys(payload)
      })
    })
  })
});

export const {
  useGetFeaturePlansQuery,
  useGetActiveSubscriptionsQuery,
  useActivateAddonMutation,
  useDeactivateAddonMutation
} = subscriptionApi;
