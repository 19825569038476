import PropTypes from 'prop-types';
import React, { useState } from 'react';

import SettingToggle from 'components/Account/UserSettings/SettingToggle';
import AvomaLink from 'components/Common/AvomaLink';
import NotificationBanner from 'components/Common/NotificationBanner';
import HelpPopover from 'components/Common/popovers/HelpPopover';

import L10n from 'helpers/L10n';

import { smartCategoryTabs } from 'constants/settings';

import Tokens from 'styles/tokens';

import CustomKeywords from './CustomKeywords';

const TrackTrends = ({
  view,
  category,
  loading,
  keywordNotesEnabled,
  toggleExtractNotes,
  restrictModification,
  isCategoryNew,
  noKeywordsPresent,
  noPhrasesPresent,
  isSmartTrackersEnabled,
  isUnifiedFlow = false,
  isAdminOrTopicOwner = false
}) => {
  const [shouldShowReprocessInfo, setShouldShowReprocessInfo] = useState(false);

  const isKeywordsToNotesEnabled = restrictModification
    ? true
    : category?.settings?.keywordNotesEnabled;

  // For filler words we'd want it toggled but disabled
  const isToggled = restrictModification ? true : keywordNotesEnabled;

  const handleAddOrModifyKeywordOrPhrase = () => {
    setShouldShowReprocessInfo(true);
  };

  const toggleTitle = (
    <div className='flex items-center gap-x-1'>
      <span>
        {isSmartTrackersEnabled
          ? L10n.customCategory.trackKeywordsAndPhrasesLabel
          : L10n.customCategory.trackKeywordsLabel}
      </span>
      <HelpPopover
        content={
          isSmartTrackersEnabled ? (
            <div>
              When enabled, Avoma AI will pull exact keyword matches and similar
              phrases from the transcript into notes; otherwise, these will only
              show up in the{' '}
              <AvomaLink to='/dashboard/trackers'>Trackers</AvomaLink> dashboard
            </div>
          ) : (
            <div>
              When enabled, Avoma AI will pull exact keyword matches from the
              transcript into notes; otherwise, these will only show up in the{' '}
              <AvomaLink to='/dashboard/trackers'>Trackers</AvomaLink> dashboard
            </div>
          )
        }
        buttonStyles={{
          paddingLeft: Tokens.spacing.half
        }}
        popoverWidth={300}
        popoverStyles={{ right: 0 }}
      />
    </div>
  );

  return (
    <div className='flex flex-col gap-y-2'>
      {view !== smartCategoryTabs.fillerWords && (
        <SettingToggle
          toggled={isToggled}
          onToggle={toggleExtractNotes}
          title={toggleTitle}
          titleStyle={{ ...Tokens.type.body, fontWeight: 'bold' }}
          style={styles.switch}
          disabled={
            (isCategoryNew && !isUnifiedFlow) ||
            restrictModification ||
            (noKeywordsPresent && noPhrasesPresent) ||
            !isAdminOrTopicOwner
          }
          orgPolicyIsSet
        />
      )}
      {view === smartCategoryTabs.fillerWords &&
        ((!isKeywordsToNotesEnabled && isToggled) || restrictModification) && (
          <CustomKeywords
            category={category}
            loading={loading}
            isKeywordsToNotesEnabled // since we want to be able to add keywords
            isFillerWords
            handleAddOrModifyKeywordOrPhrase={handleAddOrModifyKeywordOrPhrase}
            isAdminOrTopicOwner={isAdminOrTopicOwner}
          />
        )}

      {shouldShowReprocessInfo && (
        <div className='flex items-start'>
          <NotificationBanner
            content={L10n.customCategory.reprocessFillerWordsInfo}
          />
        </div>
      )}
    </div>
  );
};

const styles = {
  switch: {
    padding: 0,
    paddingBottom: Tokens.spacing.half
  },
  statusLink: {
    color: Tokens.colors.blue,
    fontWeight: 'bold',
    textDecoration: 'underline'
  }
};

TrackTrends.propTypes = {
  view: PropTypes.string,
  category: PropTypes.object,
  loading: PropTypes.bool,
  keywordNotesEnabled: PropTypes.bool.isRequired,
  restrictModification: PropTypes.bool,
  toggleExtractNotes: PropTypes.func.isRequired,
  isCategoryNew: PropTypes.bool,
  noKeywordsPresent: PropTypes.bool.isRequired,
  noPhrasesPresent: PropTypes.bool.isRequired,
  isSmartTrackersEnabled: PropTypes.bool,
  isUnifiedFlow: PropTypes.bool,
  isAdminOrTopicOwner: PropTypes.bool
};

export default TrackTrends;
