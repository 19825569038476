/* eslint-disable react/button-has-type */
import React, { forwardRef } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';

const ButtonUnstyled = (
  {
    onClick,
    children,
    style,
    className,
    tooltip,
    tooltipPosition,
    tooltipStyle,
    disabledStyles,
    disabled,
    dataTestId,
    noDefaultStyles = false, // use this when using tailwind classes so it doesn't clash with the default styles
    ...otherProps
  },
  ref
) => {
  const tooltipClasses = [];
  if (tooltip && tooltip.trim().length > 0) {
    tooltipClasses.push('tooltip icon-button-tooltip unstyled-tooltip');
    tooltipClasses.push(tooltipPosition);
  }

  return (
    <button
      onClick={onClick}
      data-testid={dataTestId}
      className={`${className} ${tooltipClasses.join(' ')}`}
      style={
        noDefaultStyles
          ? {}
          : {
              ...styles.btn,
              ...style,
              ...(disabled && { cursor: 'default' }),
              ...(disabled && disabledStyles)
            }
      }
      aria-label={tooltip}
      disabled={disabled}
      {...otherProps}
      ref={ref}
    >
      {children}
    </button>
  );
};

ButtonUnstyled.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  style: stylePropType,
  tooltipStyle: stylePropType,
  disabledStyles: stylePropType,
  disabled: PropTypes.bool,
  noDefaultStyles: PropTypes.bool,
  tooltipPosition: PropTypes.oneOf([
    'bottom',
    'top',
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right'
  ]),
  dataTestId: PropTypes.string
};

ButtonUnstyled.defaultProps = {
  style: {},
  className: '',
  tooltip: null,
  tooltipPosition: 'bottom',
  disabled: false
};

// There is some styling in buttonunstyled.css since the :focus CSS was
// behaving strangely on Chrome
const styles = {
  btn: {
    backgroundColor: 'transparent',
    borderStyle: 'none',
    paddingLeft: '0',
    paddingRight: '0',
    paddingTop: '0',
    paddingBottom: '0',
    cursor: 'pointer',
    position: 'relative'
  }
};

export default Radium(forwardRef(ButtonUnstyled));
