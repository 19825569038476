import capitalize from 'lodash/capitalize';
import React from 'react';

import AvomaLink from 'components/Common/AvomaLink';

import L10Scheduler from 'l10/scheduler';

import { EAutomationActionType } from 'constants/automation';
import { BILLING_ADDONS, PLAN_NAMES } from 'constants/billing';

const L10n = /** @type {const} */ ({
  home: {
    takeNotes: 'Take Notes',
    prepare: 'Prepare',
    review: 'Review',
    prepareTooltip: 'Prepare Meeting Agenda',
    reviewTooltip: 'Review Next Steps & Transcript',
    accountCard: {
      internal: 'Internal',
      external: 'External Individual'
    }
  },
  playlists: {
    accessRequired: 'Access required to view this Playlist',
    empty: 'No meeting playlists... yet!',
    createdBy: 'Created by',
    fetchPlaylistError: 'There was a problem fetching your playlists.',
    remove: 'Remove',
    makePlaylistPrivate: 'Private',
    makePlaylistPublic: 'Public',
    makePlaylistOrg: 'Organization',
    makePlaylistTeam: 'Specific Teams',
    privateText: 'Only you can view this playlist.',
    publicText: 'Only members of your organization and invited users can view.',
    headerTitle: 'Playlist Library',
    long: {
      organizationPlaylists: 'All Playlists',
      allPlaylists: "Teammates' Playlists",
      myPlaylists: 'My Playlists',
      subscribedPlaylists: 'Subscribed Playlists'
    },
    short: {
      organizationPlaylists: 'All',
      allPlaylists: "Teammates'",
      myPlaylists: 'My',
      subscribedPlaylists: 'Subscribed'
    },
    viewDetails: 'View Details',
    noResults: 'Oops! something went wrong. Please try again later.',
    noPlaylists: 'No Playlists',
    noRecordings: 'No Recordings',
    snippetAddedSuccessfully: 'Snippet added to playlist successfully',
    snippetRemovedSuccessfully: 'Snippet removed from playlist successfully',
    snippetsAddedSuccessfully: 'Snippets added to playlist successfully',
    snippetsRemovedSuccessfully: 'Snippets removed from playlist successfully',
    meetingAddedSuccessfully: 'Meeting added to playlist successfully',
    meetingRemovedSuccessfully: 'Meeting removed from playlist successfully',
    publicPrivacyText:
      'Anyone on the internet with the link and people with whom it is shared can view.',
    organizationPrivacyText:
      'Anyone in your organization and people with whom it is shared can view.',
    teamPrivacyText:
      'Specific teams with access and people with whom it is shared can view',

    privatePrivacyText:
      'Only meeting participants and people with whom it is shared can view.',
    changePrivacyPrivateTitle: 'Make playlist and its content Private',
    changePrivacyTeamTitle:
      'Make playlist and its content visible to specific teams',
    changePrivacyTeamText:
      'To ensure that the content of this playlist is accessible, the access permission of each meeting and snippet in this playlist that has stricter privacy will be changed to “Visible to specific teams”',
    confirmationText: 'Are you sure you want to make this change ?',
    changePrivacyOrganizationTitle:
      'Make playlist and its content visible to organization',
    changePrivacyPublicTitle: 'Make the playlist and its content public',
    sharingPrivateText: 'Only subscribers and people added above can access',
    sharingTeamText: `Anyone from playlist creator's primary team and people invited via Email can view the playlist. Signing in to Avoma, or requesting a one-time access code is required to view it.`,
    sharingTeamTextShort:
      'Specific teams with access and people with whom it is shared can view',
    sharingOrganizationText:
      'Anyone in your organization and people added above can access',
    sharingPublicText: 'Anyone with the link can access',
    noResultSubscribedPlaylists: 'You haven’t subscribed to any playlist yet.',
    noResultsPlaylists: 'No playlist created yet',
    linkExpired: 'This Link has expired',
    linkExpiredTitle: 'Playlist Link Expired',
    linkExpiredSubtitle:
      'Please contact the person who shared this playlist with you for access.',
    notSharedWithAnyone: "This playlist isn't shared with anyone.",
    updateExistingItemsCheckbox: {
      sentence1: 'Modify existing playlist items based on updated filters.',
      sentence2: 'Manually added/deleted conversations are not altered.'
    },
    smartPlaylist: {
      deactivatedOwnerNotification: () => (
        <span>
          The playlist owner has been <strong>deactivated</strong>.
          Conversations will no longer be added automatically to smart playlists
          belonging to deactivated members.
        </span>
      )
    }
  },
  createPlaylistModal: {
    editHeader: 'Edit Playlist',
    title: '',
    label: 'Playlist Name',
    placeholder: 'e.g. Sample Discovery Calls, Top 10 Deal Closers, etc.',
    descriptionLabel: 'Description',
    descriptionPlaceholder:
      'Tell the rest of your team what this playlist is about...',
    createStaticPlaylist: 'Create Static Playlist',
    createSmartPlaylist: 'Create Smart Playlist',
    updateStaticPlaylist: 'Update Static Playlist',
    updateSmartPlaylist: 'Update Smart Playlist',
    autoAddPastMeetingsTitle: 'Add conversations from',
    defaultSortingorder: 'Default sort order',
    isdefaultLabel: 'Default set by Admin/Owner',
    selectSortingorder: 'Select Sort order',
    defaultSortOrderHelpText:
      'The default order in which meetings and snippets are arranged in this playlist',
    allRecordings: 'All past & future conversations',
    futureRecordings: 'Only future conversations',
    futureRecordingsHelpSubtitle:
      'Conversations will be added from the date Smart Playlist is created',
    update: 'Update',
    cancel: 'Cancel',
    createPlaylistFailure: 'There was a problem creating your playlist',
    updatePlaylistFailure: 'There was a problem updating your playlist',
    createStaticPlaylistSuccess: 'Successfully created your static playlist',
    createSmartPlaylistSuccess: 'Successfully created your smart playlist',
    makePlaylistPrivate: 'Make Playlist Private',
    makePlaylistPublic: 'Make Playlist Public',
    playlistType: 'Playlist Type',
    staticPlaylist: 'Static Playlist',
    smartPlaylist: 'Smart Playlist',
    allPastAndFutureConv: 'All past & future conversations',
    howFiltersWorkLabel: 'How Filters work',
    staticPlaylistBanner:
      'Add recordings and Snippets to the playlist manually.',
    smartPlaylistBanner:
      'Add recordings to the playlist automatically based on filters selected.',
    smartRules: {
      title: 'Filters',
      helpTitle: 'Add Smart Playlist filters',
      helpContent:
        'Meeting and Call recordings that match the selected filters will be automatically added to the playlist.',
      noFilterSelectedWarning:
        'Select at least one smart rule to automatically add Meetings and Call Recordings.',
      filterSelectedNotification:
        'Meetings and call recordings will be added from the date Smart Playlist is created.'
    },
    static: 'Static',
    smart: 'Smart',
    autoAdded: 'Automatically added',
    autoAddedHeader: 'Automatically based on',
    changePlaylistModal: {
      title: 'Changing Playlist Type?',
      existing: 'Existing',
      new: 'New'
    },
    createDisabledTooltip: 'Add Mandatory fields to create the Playlist',
    createDisabledSmartPlaylistTooltip:
      'Your plan does not support updating Smart Playlists',
    illustrations: {
      title: 'Learn how smart playlist work',
      filterSelection: 'Filters selection',
      existingMeetings: 'For Meetings existing in the Smart Playlist',
      outsideMeetings: 'For Meetings outside the Smart Playlist',
      postPlaylistCreation: 'Post Playlist creation'
    }
  },
  playlistContent: {
    playlistContentAddSuccess: 'Successfully added to ',
    playlistContentAddFailure: 'There was a problem adding to ',
    playlistContentAddFailureGeneric:
      'There was a problem adding to the playlist',
    playlistContentAddFailurePrimaryTeamMismatch:
      'This action is not allowed because the meeting will be inaccessible to certain teams with whom the playlist is shared.',
    playlistContentDeleteSuccess: 'Successfully removed from ',
    playlistContentDeleteFailure: 'There was a problem removing from ',
    nowPlaying: 'Now Playing'
  },
  playlistMenus: {
    addToPlaylist: 'Add to Playlist',
    viewMeeting: 'View Meeting',
    viewCall: 'View Call',
    shareMeeting: 'Share Meeting',
    removeFromPlaylist: 'Remove from Playlist',
    edit: 'Edit Playlist Details',
    deletePlaylistCta: 'Delete',
    deletePlaylist: 'Delete the playlist?',
    deletePlaylistDescription:
      'Meetings and snippets in the playlist will NOT be deleted. \n\nOnce deleted, you will not be able to recover the playlist.',
    makeItDefault: 'Make it Default',
    sortingIconTooltip: 'Currently sorted by',
    defaultOrderChangeToast: 'Default playlist order changed successfully',
    contentAccessWarning: 'Content Access Warning'
  },
  playlist: {
    unsubscribe: 'Unsubscribe',
    subscribed: 'Subscribed',
    manageSubscriber: 'Manage Subscribers',
    recordingAccessForbidden:
      'You do not have permission to perform this action.',
    recordingForbidden: 'You do not have permission to view this recording.'
  },
  meetingsTable: {
    dateHeader: 'Meeting Date & Time',
    typeOutcomeHeader: 'Types/Outcomes',
    accountHeader: 'Account',
    duringHeader: 'During Meeting',
    nowHeader: 'Now'
  },
  meetingsList: {
    recordedOnly: 'Recorded meetings only',
    unrecordedOnly: 'Include unrecorded meetings',
    includeInternal: 'Include internal meetings',
    externalOnly: 'External meetings only',
    internalOnly: 'Internal meetings only',
    sharedOnly: 'Shared meetings only',
    sharedOnlyCalls: 'Shared calls only',
    meetingType: 'Meetings of type',
    showCalls: 'Show Calls',
    viewAINotes: 'View AI Notes',
    extractedByAI: 'Notes displayed here are extracted by Avoma AI',
    upcomingRecordingEnabledOnly: 'Meetings with recording enabled only',
    upcomingConfDetailsOnly: 'Meetings with conferencing details only'
  },
  meetingsViews: {
    allMeetings: 'All Meetings',
    myMeetings: 'My Meetings',
    teamMeetings: 'Team Meetings',
    sharedMeetings: 'Shared Meetings',
    recentMeetings: 'Recent',
    upcomingMeetings: 'Upcoming',
    todaysMeetings: "Today's Upcoming Meetings",
    todaysMeetingsEmpty:
      'There are no upcoming meetings on your schedule for today',
    happeningNow: 'Happening Now',
    noResults: 'There are no meetings for this view.',
    noResultsCalls: 'There are no calls for this view.',
    noTeams: "You're not added to any team yet.",
    noSharedMeetings:
      'No meetings are shared with you, meetings appear when they are shared with you.',
    noUpcomingMeetingsOnMy: 'There are no scheduled meetings on your calendar.',
    noUpcomingMeetingsOnAll:
      'There are no scheduled meetings on the calendars of any members in your organization.',
    createYourFirstMeeting: 'Create and record your first meeting in Avoma.',
    noneShared: 'Nothing shared yet!',
    noneSharedDetail:
      'When one of your team members shares a meeting with you, it will show up here.',
    short: {
      myMeetings: 'My Meetings',
      allMeetings: 'All',
      teamMeetings: 'Team',
      sharedMeetings: 'Shared'
    },
    Forbidden: 'Forbidden',
    meetingNotFound: 'This Meeting was not found',
    meetingForbidden: 'You do not have access to this meeting',
    noMatchesForFilter: 'No matching results for the applied filters.',
    availableToParticipants: 'Allowed for Meeting participants and Admins only',
    schedulerMeeting: 'Meeting was scheduled using Avoma Scheduler.',
    meetingNotFoundDetail:
      'This meeting might have been deleted. Please recheck the link and try again.',
    meetingNotFoundCTA: 'Go to meetings page',
    noResultsError: 'Oops! something went wrong. Please try again later.',
    accessCallRecordings: 'Access all your dialer call recordings in one place.'
  },
  meetingEmpty: {
    askAdminToCreateTeam: 'Ask Admin to create new team.',
    createYourFirstTeam: 'Create your first team in ',
    noTeamsCreated: 'There are no teams created for your organization',
    willNotSyncUpcoming: calProvider => (
      <React.Fragment>
        {calProvider} Calendar is disconnected! Avoma will not sync your
        <span className='font-bold'> upcoming</span> meetings.
      </React.Fragment>
    )
  },
  callsEmpty: {
    accessCallRecordings:
      'Access all your dialer call recordings in one place.',
    analyzeCalls:
      'Analyze your calls and get Transcript, Notes, Conversation Insights, and more.',
    connectYourDialers: 'Connect your dialers in ',
    noCallsProcessed: 'No dialer calls have been processed yet.',
    pleaseReview: 'Please review your configuration in ',
    checkSupportArticles: link => (
      <React.Fragment>
        or check these{' '}
        <AvomaLink className='font-bold' openNewWindow to={link}>
          support articles
        </AvomaLink>{' '}
        to ensure your dialers are properly set up.
      </React.Fragment>
    ),
    dialers: 'Dialers',
    dialerHelpLink: 'https://help.avoma.com/integrations#dialer'
  },
  meetingActions: {
    newImpromptu: 'New Impromptu Meeting',
    impromptu: 'Impromptu',
    review: 'Review',
    reviewMeeting: 'Review Meeting',
    reviewCall: 'Review Call',
    prepare: 'Prepare',
    prepareMeeting: 'Prepare for Meeting',
    join: 'Join',
    takeNotes: 'Take Notes',
    joinMeeting: 'Join Meeting',
    crmInfoButton: 'Company',
    shareButton: 'Share',
    viewDetails: 'View Details',
    viewNotes: 'View Action Items',
    viewComments: 'View Comments',
    viewSnippets: 'View Snippets',
    viewTranscript: 'View Transcript',
    viewScorecards: 'View Scorecards',
    openInNewTab: 'Open in a new tab',
    openMeeting: 'Open Meeting',
    copyLink: 'Copy Link',
    linkCopied: 'Link Copied to Clipboard',
    quickView: 'Quick View',
    deleteDisabled: 'Deleting conversations has been disabled by your admin.',
    downloadDisabled:
      'Downloading conversations has been disabled by your admin.'
  },
  meetingSidebar: {
    searchPlaceholder: 'Search for a meeting',
    viewMore: 'View More',
    today: 'Today',
    sharedEmpty1: "You don't have any meetings shared with you.",
    sharedEmpty2:
      'Meeting recordings and transcripts that are shared with you will show up here.'
  },
  meetingDashboard: {
    tabAnalysis: 'Analysis',
    tabTranscript: 'Transcript',
    tabLiveTranscript: 'Live Transcript',
    tabNotes: 'Notes',
    tabCopilot: 'Ask Avoma',
    tabQuestions: 'Questions',
    tabChapters: 'Auto Chapters',
    tabComments: 'Comments',
    tabSnippets: 'Snippets',
    tabKeywords: 'Keywords',
    tabScorecards: 'Scorecards',
    tabBattlecards: 'Answer Cards',
    tabActivityLog: 'Activity Log',
    scoreConversation: 'Score Conversation',
    scoreConversationOpen: 'Scorecards are already open',
    commentsOpen: 'Comments are already open',
    snippetsOpen: 'Snippets are already open',
    tabInsights: 'Insights',
    tabActivity: 'Activity Logs',
    meetingPrivate: 'Private',
    keywords: {
      popular: 'Popular Keywords',
      tracked: 'Tracked Keywords'
    },
    searchPlaceholder: 'Search in Transcript',
    relatedTranscriptHeader: 'Related Transcript',
    emptyTranscript: 'Transcript not available.',
    quickActions: 'Quick Actions',
    showHide: 'Show/Hide'
  },
  meetingDetails: {
    hideDetails: 'Hide details',
    showDetails: 'Show details'
  },
  recordingIndicator: {
    noSpeechDetected: 'No Speech Detected',
    past: {
      // these intentionally match bot state enum coming back from server
      not_present: 'Meeting not recorded',
      disabled: 'Meeting not recorded',
      wait: 'Meeting not recorded',
      dialing: 'Recording processing...', // TODO technically invalid
      dialed_in: 'Recording processing...',
      u_enabled: 'Recording scheduled', // TODO technically invalid
      dropping: 'Dropping', // TODO technically invalid
      rec_int: 'Recorded using ',
      rec_int_disabled: 'Meeting not automatically recorded by',
      transcribing: 'Transcription in progress...',
      transcription_error: 'Transcription error',
      transcription_available: 'Transcript available',
      recording_error: 'Recording error',
      error: 'Recording error',
      unknown: 'Recording error',
      l_disabled: 'Recording disabled'
    },
    upcoming: {
      // these intentionally match bot state enum coming back from server
      not_present: 'Not scheduled for recording',
      disabled: 'Recording disabled',
      wait: 'Recording scheduled',
      dialing: 'Connecting now',
      dialed_in: 'Recording now',
      u_enabled: 'Recording scheduled',
      dropping: 'Dropping',
      rec_int: 'Recording via',
      rec_int_disabled: 'Not enabled in',
      transcribing: 'Transcription in progress...', // TODO technically invalid
      transcription_error: 'Transcription error', // TODO technically invalid
      transcription_available: 'Transcript available', // TODO technically invalid
      recording_error: 'Recording error', // TODO technically invalid
      error: 'Recording error', // TODO technically invalid
      unknown: 'Recording error',
      l_disabled: 'Recording disabled'
    }
  },
  recordingStatus: {
    short: {
      // these intentionally match bot state enum coming back from server
      not_present: 'No Info',
      disabled: 'Disabled',
      wait: 'Scheduled',
      dialing: 'Connecting...',
      dialed_in: 'Recording',
      u_enabled: 'Scheduled',
      dropping: 'Dropping...',
      rec_int: 'Rec. via',
      rec_int_disabled: 'Disabled',
      transcribing: 'Transcribing...',
      transcription_error: 'Transcription error',
      transcription_available: 'Transcript ready',
      recording_error: 'Rec. Error',
      error: 'Dropped',
      unknown: 'Rec. Error'
    },
    long: {
      // these intentionally match bot state enum coming back from server
      not_present: 'Missing recording info',
      disabled: 'Recording disabled',
      wait: 'Recording scheduled',
      dialing: 'Connecting now...',
      dialed_in: 'Recording now',
      u_enabled: 'Recording scheduled',
      dropping: 'Dropping...',
      rec_int: 'Recording via',
      rec_int_disabled: 'Recording disabled',
      transcribing: 'Transcribing...',
      transcription_error: 'Transcription error',
      transcription_available: 'Transcript ready',
      recording_error: 'Recording error',
      error: 'Dropped',
      unknown: 'Recording error',
      l_disabled: 'Recording disabled'
    },

    errorBanner: 'Meeting recording and transcript will not be available',
    missingConfDetails: 'Missing conferencing details',
    unsupportedConfProvider: 'Unsupported conferencing provider',
    recLimitExceeded: 'Recording limits exceeded ',
    blacklistedDomain: 'Blacklisted domain',
    recLicenseMissing: 'Recording license missing',
    recDisabled: 'Recording disabled',
    recSettingDisabled: 'Recording setting disabled',
    meetingInviteNotAccepted: 'Meeting invite not accepted',
    parallelMeetings: 'Parallel meetings',

    stopBotRecording: 'Stop Recording Now',
    pauseBotRecording: 'Pause Recording',
    resumeBotRecording: 'Resume Recording',
    pauseBotRecordingError: 'Unable to Pause Recording. Please try again',
    resumeBotRecordingError: 'Unable to Resume Recording. Please try again',
    joinMeeting: 'Join meeting link',
    joinZoom: 'Join Zoom',
    joinMeetingBtn: 'Join meeting',
    recordImpromptu: 'Record Impromptu',
    recordImpromptuTooltip: 'Record an impromptu meeting',
    recordImpromptuSubtitle:
      'Request Avoma to join your meeting and start recording instantly.',
    addMeetingDetails: 'Add Meeting Details',
    startRecordingNow: 'Start Recording Now',
    saveDetails: 'Save Details',
    showSchedulingPages: 'Scheduler',
    showSchedulingPagesTooltip: 'View scheduling pages',
    record: 'Record',
    disableRecordingText:
      'You will not get a meeting transcript if you disable the recording of your meeting.',
    cancelDialin: 'Cancel Dial-in to',
    disableRecording: 'Disable recording of',
    disableZoomCloudRecording: 'Disable Zoom Cloud Recording of',
    disableZoomCloudRecordingText:
      'This meeting will not be recorded in Zoom and Avoma will not be able to process it.',
    disableMsCloudRecording: 'Disable Microsoft Cloud Recording of',
    disableMsCloudRecordingText:
      'This meeting will not be recorded in Microsoft and Avoma will not be able to process it.',
    disableGmCloudRecording: 'Disable Google Meet Cloud Recording of',
    disableGmCloudRecordingText:
      'This meeting will not be recorded in Google Meet and Avoma will not be able to process it.',
    enableZoomCloudRecording: 'Enable Zoom Cloud Recording of',
    enableZoomCloudRecordingText:
      'This meeting will be recorded in Zoom and Avoma will process it.',
    enableMsCloudRecording: 'Enable Microsoft Cloud Recording of',
    enableMsCloudRecordingText:
      'This meeting will be recorded in Microsoft and Avoma will process it.',
    enableGmCloudRecording: 'Enable Google Meet Cloud Recording of',
    enableGmCloudRecordingText:
      'This meeting will be recorded in Google Meet and Avoma will process it.',
    zoomCloudPmiWarning:
      'Because this is a PMI (Personal meeting ID), this change will be applied to all meetings with the same PMI.',
    zoomCloudRecurWarning:
      'Because this is a recurring meeting, this change will be applied to all meetings in the recurring series.',
    stopRecording: 'Stop recording of',
    startRecording: 'Start recording of',
    startDialinText:
      'Avoma will dial into your meeting and start recording now.',
    dropDialinText: 'Drop Avoma bot from this meeting',
    cancelDialinText: 'Avoma will not dial-in and record your meeting.',
    confirmStart: 'Start Recording',
    confirmDrop: 'Stop Recording',
    confirmCancelDialin: 'Cancel Dial-in',
    dialInNumbers: 'Dial-in Numbers',
    confirmDisableRecording: 'Yes, Disable',
    confirmEnableRecording: 'Yes, Enable',
    recordingIntegration: 'Recording via',
    recordingIntegrationDisabled: 'Auto-Record has been disabled',
    manageSettings: 'Settings can be managed in',
    enableRecordingConfirmTitle: 'Enable recording of',
    enableRecordingConfirmText:
      'Avoma will dial into your meeting and record it.',
    avomaIsRecordingMeeting: 'Avoma is currently recording this meeting.'
  },
  recurringConfirmModal: {
    recurringOptionPrompt: 'Select your preference for this recurring meeting:',
    recurringOptionOnly: 'Only this meeting',
    recurringOptionFuture: 'This and future meetings in this series',
    recurringOptionAll: 'All meetings in this series'
  },
  cancelRecordingModal: {
    title: 'Cancel Recording?',
    body: 'Avoma will not dial-in and record your meeting.',
    cta: 'Stop Recording',
    noActionTitle: 'Recording is Disabled'
  },
  audioControls: {
    genericMediaError: 'Error Loading media',
    playbackSpeed: 'Playback Speed',
    play: 'Play',
    pause: 'Pause',
    skipForward: 'Skip 10s Forward',
    skipBackward: 'Skip 10s Back',
    skipIntro: 'Skip Intro',
    mute: 'Mute',
    unMute: 'Unmute',
    nextSpeaker: 'Skip to next speaker',
    videoQuality: 'Video Quality',
    settings: 'Settings',
    chapters: 'Chapters',
    ccEnable: 'Enable closed captions',
    ccDisable: 'Disable closed captions',
    ccUnavailable: 'Captions Unavailable',
    hideVideo: 'Hide Video',
    showVideo: 'Show Video',
    fullscreenError:
      'There was an issue switching to Full Screen mode. Please refresh and try again or contact support.'
  },
  analysisPane: {
    topicBreakdown: 'Topics',
    speakerBreakdown: 'Speakers',
    sentiment: 'sentiment',
    questions: 'Questions',
    editSpeakerPlaceholder: 'Select Speaker',
    updateSpeakerFailureMessage:
      'There was a problem updating the speaker name. Please try again.',
    updatingSpeaker: 'Updating Speaker...',
    tabTopics: 'Analysis',
    keywordsTitle: 'Popular Keywords',
    occurencesTitle: 'Tracked Keywords',
    emptyQuestion: 'No questions found.',
    emptyKeywords: 'No keywords found.',
    neutralIconLabel: 'Neutral',
    positiveIconLabel: 'Positive',
    negativeIconLabel: 'Negative'
  },
  meetingInsights: {
    fillerWordsIdeal: 'Less than 2 words/min',
    fillerWordsOkay: '2-5 words/min',
    fillerWordsPoor: 'Above 5 words/min',
    talkSpeedIdeal: '100 - 180 words/min',
    talkSpeedOkay: 'Below 100 words/min',
    talkSpeedPoor: 'Above 180 words/min',
    patienceIdeal: 'Above 1.25 sec',
    patienceOkay: '1-1.25 sec',
    patiencePoor: 'Below 1 sec',
    ideal: 'Ideal',
    okay: 'Average',
    poor: 'Poor',
    tooSlow: 'Too Slow',
    tooFast: 'Too Fast',
    talkSpeed: 'Talk Speed',
    fillerWords: 'Filler Words',
    patience: 'Patience',
    talkTime: 'Talk Time',
    totalTalkTime: 'Total talk time',
    breakDownErrorShort: 'Speaker Breakdown Error:',
    breakDownErrorLong:
      'Oops… our team has been notified, and we’re working on it!',
    speakerPlayButtonTooltip: "Play speaker's conversation",
    fillerWordsHelpText:
      'Filler words are phrases or sounds used to fill empty spaces in conversations. These are counted by the number used per minute.',
    patienceHelpText:
      'Patience tells you how quickly you jump to talk after someone has finished speaking or if you interrupted them. If you see a lower number, that means you interrupted the conversation.',
    talkSpeedHelpText:
      'Talk speed is the pace at which a participant in the meeting speaks. It is calculated by words spoken per minute.'
  },
  createTeamModal: {
    createTeam: 'Create Team',
    createNewTeam: 'Create new team',
    teamNamePlaceHolder: 'e.g. Sales, Customer Success, etc.',
    teamHandlePlaceHolder: 'sales-team',
    teamName: 'Team Name',
    teamHandle: 'Team Handle',
    assignTeamManager: 'Team Manager',
    teamMembers: 'Team members',
    assignMe: 'Assign as me',
    teamManagerPlaceholder: 'Enter name or email to search',
    noTeamFound: 'No team found',
    editTeam: 'Edit Team',
    saveChanges: 'Save Changes'
  },
  createHighlightModal: {
    durationBannerMessage:
      'Avoma AI creates title and description when over 10 seconds.',
    createTitle: 'Create Snippet',
    createAndShareTitle: 'Create & Share Snippet',
    createCta: 'Create Snippet',
    createAndShareCta: 'Create & Share Snippet',
    createAndAddToPlaylistTitle: 'Create Snippet & Add to Playlist',
    createAndAddToPlaylistCta: 'Create & Add to Playlist',
    currentTime: 'Use Current Time',
    from: 'From',
    to: 'To',
    showCommentLink: 'Add Comment',
    hideCommentLink: 'Remove Comment',
    recordingLength: 'Recording length: ',
    description: 'Description',
    descriptionPlaceholder: 'What is this snippet about?',
    snippetTitle: 'Snippet Title',
    titlePlaceholder: 'Enter snippet title',
    comment: 'Comment',
    commentPlaceholder: 'e.g. Interesting price objection',
    startTooltip: 'Use current time for start time',
    endTooltip: 'Use current time for end time',
    create: 'Create',
    cancel: 'Cancel',
    createHighlightFailure: 'There was a problem creating your snippet',
    createHighlightSuccess: 'Snippet created successfully',
    showSpeakers: 'Show Speakers',
    hideSpeakers: 'Hide Speakers',
    startTime: 'Start Time',
    endTime: 'End Time',
    goBack: 'Go Back'
  },
  editHighlightModal: {
    title: 'Edit Snippet Details'
  },
  highlightsPane: {
    title: 'Snippets',
    create: 'Create Snippet',
    empty: 'No snippets yet!',
    emptyAction: 'Create a New Snippet',
    addToNotesTooltip: 'Add to Notes',
    removeFromNotesTooltip: 'Remove from Notes',
    getSharableLink: 'Get Sharable Link',
    linkTooltip: 'Add',
    shareTooltip: 'Share',
    deleteTooltip: 'Delete',
    deleteConfirmTitle: 'Delete the snippet?',
    deleteConfirmBody:
      'If this snippet is added in any Playlist, it will be removed from that playlist. \n\nOnce deleted, you will not be able to recover the snippet.',
    deleteConfirm: 'Delete',
    deleteSuccess: 'Snippet deleted.',
    deleteFailure: 'There was a problem deleting the snippet',
    snippetsSelected: 'Snippets Selected',
    selectAll: 'Select all',
    deselect: 'Deselect all',
    multiDeleteConfirmTitle: 'Delete the snippets?',
    multiDeleteConfirmBody:
      'If these snippets are added in any playlist, those will be removed from that playlist. \n\nOnce deleted, you will not be able to recover the snippets.',
    multiDeleteSuccess: 'Successfully deleted selected snippets',
    multiDeleteFailure: 'There was a problem deleting the selected snippets',
    noHighlightSelected: 'Select a Snippet first',
    noHighlightTooltip: 'Create a Snippet first',
    noDescription: 'No description added',
    thumbnailAlt: 'Snippet Thumbnail',
    addCommentTooltip: 'Add Comment',
    editSnippet: 'Edit Snippet',
    downloadSnippet: 'Download Snippet'
  },
  highlights: {
    titleShare: 'Share Snippet',
    titleDone: 'Snippet Shared',
    titleCreated: 'Snippet Created',
    deleteTitle: 'Delete Snippet?',
    deleteText:
      'If you see incorrect suggestions, delete the snippet and Avoma will learn from its mistakes',
    deleteTextUser: '',
    sharedWith: "You've shared the snippet with",
    shared: 'Snippet shared successfully',
    shareError: 'Failed to share snippet',
    addedToNotes: 'Selected text added to notes',
    addedToNotesFailed: 'There was a problem adding to notes',
    updatedNotes: 'Updated notes',
    createdSnippet: 'Snippet Created',
    createdSnippetFailed: 'There was a problem creating your snippet',
    noTranscriptAvailable: 'No Transcript Available!',
    copyCta: 'Copy to clipboard',
    copySuccess: 'Copied to clipboard',
    copyError: 'Error copying to clipboard',
    noteCta: 'Add to Notes',
    transcriptCta: 'Create Snippet',
    shareViaEmailCta: 'Share Snippet via Email',
    shareViaSlackCta: 'Share Snippet via Slack',
    emailCta: 'Send Email',
    shareViaEmailCtaShort: 'Share via Email',
    shareViaSlackCtaShort: 'Share via Slack',
    slackNotConnected: 'Slack not connected',
    commentCta: 'Comment',
    deleteCta: 'Delete Snippet',
    deleteCtaShort: 'Delete',
    categoriesMenuTranscriptTitle: 'Add to Notes with Topics',
    highlightCategoriesMenuTitle: 'Choose a Smart Topic',
    emptyHighlightCategories: 'No Categories',
    tooltip: 'You can edit these categories in ',
    noTranscript: 'No transcript available',
    untitledSnippet: 'Untitled Snippet'
  },
  augmentedNoteSettings: {
    names: 'Names',
    extractSmartNotes: 'Extract smart notes for following smart topics',
    breadcrumb: 'Smart Notes',
    policyLabel: 'Smart Topics',
    title: 'Smart Notes',
    cardTitle: 'Augmented Notes Categories',
    description: `Extract Augmented Notes by highlighting text in the transcript and assigning it a specific category. 
      Here you can customize which categories show up in the dropdown options when highlighting text in the transcript.`,
    showHideFailure:
      'There was a problem updating your highlight categories setting',
    adminDescriptionA:
      'Users can extract Augmented Notes by highlighting text in the transcript and assigning it a specific category.',
    adminDescriptionB:
      'Admins can Add or Delete the categories which show up in the dropdown options for your organization’s members when adding an Augmented Note.',
    admingAddCategoryCta: 'Add New Category',
    deleteCategoryTitle: 'Delete Augmented Note Category?',
    deleteCategoryText:
      'Are you sure you wish to delete this category for your Organization?',
    deleteConfirm: 'Delete',
    editCta: 'Edit',
    saveCta: 'Save',
    addCta: 'Add',
    deleteError: 'There was a problem deleting this highlight category.',
    updateError: 'Error updating Highlight Category. Duplicate category.',
    createError: 'Error creating Highlight Category. Duplicate category.',
    extractAINotesPopoverTitle: 'Extract Smart Notes for AI categories',
    extractAINotesPopoverDescription:
      'Extract Smart Notes by highlighting text in the transcript and assigning it a specific AI category. Here you can customize which AI categories show up in the dropdown options when highlighting text in the transcript.',
    extractCustomNotesPopoverTitle: 'Extract Smart Notes for Custom categories',
    extractCustomNotesPopoverDescription:
      'Extract Smart Notes by highlighting text in the transcript and assigning it a specific custom category. Here you can customize which custom categories show up in the dropdown options when highlighting text in the transcript.'
  },
  bookmarks: {
    placeholder: 'Bookmark Added',
    searchPlaceholder: 'Search bookmarks'
  },
  battlecards: {
    dismiss: 'Dismiss',
    toolbar: {
      searchPlaceholder: 'Search Answer Cards',
      emptyStates: {
        noCards: 'No answer cards have been triggered yet.',
        noCardsSearch: 'No matching answer cards found.'
      }
    }
  },
  listenDashboard: {
    organizedBy: 'Organized By',
    highlightsEmpty: 'No Snippets',
    highlightsEmptySubtitle:
      'No Snippets were shared. Perhaps they have been deleted?',
    snippet: 'Snippet',
    createdBy: 'created by',
    transcriptHeader: 'Transcript Preview',
    notReady:
      'This recording is still being processed. Please check back in a few minutes.',
    notFound:
      'Shared recording was not found. Please contact the recording owner.',
    noAccess: 'You do not have access to this recording',
    goToMeeting: 'View Full Meeting',
    linkExpired: 'This Link has expired',
    linkExpiredTitle: 'Recording Expired',
    linkExpiredSubtitle:
      'Please contact the person who shared this meeting with you for access.'
  },
  general: {
    clear: 'Clear',
    oldest: 'oldest',
    when: 'When',
    latest: 'latest',
    objects: 'Objects',
    deactivated: 'Deactivated',
    menu: 'Menu',
    others: 'Others',
    advancedSettings: 'Advanced Settings',
    saving: 'Saving...',
    help: 'Help',
    more: 'More',
    apps: 'Apps',
    enable: 'Enable',
    disable: 'Disable',
    replace: 'Replace',
    retry: 'Retry',
    rename: 'Rename',
    applyChanges: 'Apply changes',
    add: 'Add',
    save: 'Save',
    back: 'Back',
    next: 'Next',
    delete: 'Delete',
    embed: 'Embed',
    book: 'Book',
    bookAMeeting: 'Book a Meeting',
    confirm: 'Confirm',
    proceed: 'Proceed',
    expand: 'Expand',
    minimize: 'Minimize',
    cancel: 'Cancel',
    close: 'Close',
    clone: 'Clone',
    duplicate: 'Duplicate',
    submit: 'Submit',
    private: 'Private',
    orgOnly: 'Organization',
    public: 'Public',
    title: 'Avoma',
    settings: 'Settings',
    apply: 'Apply',
    send: 'Send',
    show: 'Show',
    hide: 'Hide',
    done: 'Done',
    skip: 'Skip',
    recurring: 'This is a recurring meeting',
    phone: 'This is a phone call',
    snippet: 'This is a snippet',
    calendar: 'This is a calendar event',
    refresh: 'Refresh',
    refreshing: 'Refreshing',
    on: 'On',
    off: 'Off',
    visibleToOrg: 'Visible to Organization',
    visibleToPrimaryTeam: 'Visible to Specific Teams',
    markPrimaryTeamSuccess: 'Primary team set successfully',
    markPrimaryTeamError: 'Error setting primary team',
    open: 'Open',
    loading: 'Loading...',
    disconnecting: 'Disconnecting...',
    viewAll: 'View All',
    createNewPlaylist: 'Create New Playlist',
    createNewTemplate: 'Create New Template',
    createNew: 'Create New',
    createNewSchedulingPage: 'Create Scheduling Page',
    createNewRoutingPage: 'Create Router',
    install: 'Install',
    edit: 'Edit',
    remove: 'Remove',
    update: 'Update',
    clickToUpdate: 'Click to update',
    backHome: 'Back to Home',
    copy: 'Copy',
    sendEmail: 'Send an email',
    sendEmailToAll: 'Send email to All Participants',
    copyEmails: 'Copy Emails',
    selectPrivacy: 'Select Privacy',
    forecast: 'Forecast',
    sortingTooltip: {
      oldestFirst: 'Sort by oldest first',
      newestFirst: 'Sort by newest first',
      ascending: 'Sort by ascending',
      descending: 'Sort by descending'
    },
    tryAgain: 'Try Again!',
    yes: 'Yes',
    no: 'No',
    locked: 'Locked',
    unlocked: 'Unlocked',
    additionalBenefits: 'Additional Benefits',
    reply: 'Reply',
    comment: 'Comment',
    copyAllEmailsSuccess: 'Emails successfully copied to clipboard'
  },
  fileUpload: {
    errorText: 'Upload Failed! Please check your internet & try again.',
    successLabel: 'Upload Successful!',
    uploadNewFileLabel: 'Upload new File',
    buttonPlaceholder: 'Click to browse'
  },
  uploadMeeting: {
    tooltip: 'Upload recording',
    header: 'Upload Recording',
    form: {
      sectionFileTitle: 'Upload Recording',
      sectionFileSubtitle:
        'Upload a recording to Avoma to get transcript, notes and more',
      fieldSubjectTitle: 'Meeting Name',
      fieldSubjectPh: 'Enter Meeting Name',
      fieldUploadSubtitle:
        'Supported formats: Video - mp4/avi/mkv | Audio - mp3/wav/m4a | Max size - 2GB',
      fieldPurposeOutcome: 'Purpose & Outcome',
      helpTextPurposeOutcome:
        'Selected Purpose and Outcome will be attached to uploaded meeting',
      successMessage: 'Successfully uploaded the meeting',
      fileUploadError: 'File upload failed, please try again'
    }
  },
  shareMeeting: {
    title: 'Share',
    titleHighlight: 'Share Snippet',
    titlePublic: 'Share Recording',
    titleDone: 'Meeting Shared',
    titlePublicDone: 'Recording Shared',
    headerByEmail: 'Share via Email',
    meetingAttendees: 'Meeting Attendees',
    others: 'Others with access',
    sharedWithLabel: 'Shared with',
    hide: 'Hide',
    shareCta: 'Share',
    sendCta: 'Send Email',
    shareWith: 'Invite via Email',
    sharedWith: "You've shared the meeting with",
    sharedBy: 'Shared by',
    added: 'Added',
    shared: 'Shared',
    selectAll: 'Select All',
    alreadyShared: 'Meeting was already shared with',
    select: 'Select',
    remove: 'Remove',
    notAccessed: 'Not yet accessed',
    notShared: 'Not shared',
    entireRecording: 'Full Recording',
    meetingHighlight: 'Snippet',
    shareMeetingFailure: 'There was a problem sharing your meeting',
    createHighlightFailure: 'There was a problem creating your snippet',
    shareHighlightFailure: 'There was a problem sharing your snippet',
    errorNoEmails: 'Please add a recipient',
    comment: 'Message',
    commentPlaceholder: 'e.g. Check out this transcript',
    publicShareOn: 'Link sharing is ON',
    addParticipants: 'Add Participants',
    disablePublicUrl: 'Disable Public Link',
    disabledPublicUrl: 'Public URL disabled',
    expiry: 'Shareable link expires in: ',
    linkSettings: 'Shareable Link Settings',
    notificationSettings: 'Notification Settings',
    notSharedWithAnyone: "This recording isn't shared with anyone.",
    accessPermissionUpdated: 'Access permissions updated',
    invalidEmail: 'Please enter a valid email address to add a new user'
  },
  participants: {
    copySuccess: 'Email Copied to Clipboard',
    addParticipantCta: 'Add Participants',
    participantsTextAreaPh: 'Search name or email to add',
    addParticipantsInfo:
      'Participants from your organization will receive this recording automatically and with full access.',
    warnings: {
      invalidEmail: 'Invalid email address',
      duplicateEmail: 'Already exists',
      shareMeeting:
        'This member will be notified via Email and will get access to this meeting in Avoma. No changes to calendar invite.'
    },
    allowedForParticipants: 'Only participants and Admins can invite'
  },
  allMeetings: {
    title: 'All Meetings',
    privateSettingSubtitle:
      'Only meeting participants and people with whom this is shared can view.',
    publicSeetingSubtitle: 'Anyone in the organization can view it.'
  },
  inviteUsers: {
    email: 'Email',
    role: 'Role',
    license: 'License',
    licensesAvailable: 'Licenses Available',
    rolesAvailable: 'Roles Available',
    inviteNewMember: 'Invite New Member',
    inviteMorePpl: 'Invite More People'
  },
  publicShare: {
    permissionTitle: 'Access Permission',
    shareableLinkLabel: 'Shareable Link',
    copyLink: 'Copy Link',
    noLink: 'Shareable link disabled',
    copySuccess: 'Link copied to clipboard',
    generate: 'Get shareable link',
    getLink: 'Get a link',
    privateLinkSubtitlePrivate:
      'Only meeting participants and invited users can view.',
    privateLinkSubtitleOrg:
      'Only members of your organization and invited users can view.',
    publicLinkSubtitle:
      'Anyone with the link can view. Sign-in to Avoma is NOT required.',
    orgLinkSubtitle: 'Only members of your organization can view.',
    publicLinkLabel: 'Public Sharable Link',
    publicLinkEnabled: 'Public Link Enabled',
    publicLinkDisabled: 'Public Link Disabled',
    codePromptTitle: 'Access required to view this recording',
    codePromptDescription:
      'Please log in or request an access code to be sent to your email address to view',
    requestCode: 'Request code for:',
    enterEmail: 'Enter email address',
    enterValidEmail: 'Please enter a valid email address',
    enterTokenTitle: 'Email sent: Please enter code',
    enterTokenTitleNoEmail: 'Please enter code',
    enterToken: 'Enter access code',
    submitToken: 'Submit Code',
    resendCode: 'Send code again',
    emailNoAccess: 'This email does not have access to this recording.',
    emailNoAccessSub:
      'Please contact the person who shared this recording with you.',
    redirectLoading: 'Redirecting to your meeting...',
    redirectError: 'Something went wrong',
    redirectExpiredLink: 'The link has expired',
    linkExpired:
      'This link has expired. Please update its expiration date in order to set it active once again',
    accessCodeError: 'Invalid access code'
  },
  impersonation: {
    loadingdata: 'Loading impersonation data',
    redirecting: 'Loading impersonated user',
    invalidToken: 'Invalid Token',
    alert: 'USER IMPERSONATION IS ACTIVE!',
    readOnly:
      'Read only impersonation of settings is active. Any changes you make will NOT take effect.'
  },
  notes: {
    addCategoryBlock: 'Add Note Category',
    changeCategoryBlock: 'Change Note Category',
    addSmartTopicBlock: 'Add a Smart Topic',
    changeSmartTopicBlock: 'Change Smart Topic',
    privateNotes: 'Private Notes',
    takePrivateNotes: 'Take private notes',
    triggerPromptTooltip:
      'Say these Trigger Prompts to extract notes automatically',
    placeholder: 'Start taking notes!',
    placeholderPrivate: 'Take private notes here that only you can see',
    placeholderPrivateMobile: 'Take private notes here',
    error:
      'There has been an error with the Notes Editor. Please refresh the page to reload the notes.',
    connectedTo: 'Connected to the',
    collabNotes: 'Collaborative Notes',
    connecting: 'Connecting...',
    offline: 'Offline',
    tagNoAccessMessage: `Since you don’t have sharing access, please ask one of the participants to share this meeting with the new member so they can view your notes.`,
    tagAccessMessage:
      'When you @mention someone, it shares the meeting with them.',
    tagAccessMessageTeam:
      'When you @mention a team, it shares the meeting with all members of the team.',
    smartCategoryPlaceholer:
      "Start typing here or press ' / ' for a Smart Topic... ",
    agendaPlaceholder: "Type '/' to add Agenda or a Smart Category",
    bookmarksTitle: 'Bookmarks',
    bookmarkTooltip:
      'Add a conversation bookmark by clicking one of the following categories',
    aiGeneratedSummary: 'This is a conversation summary generated by Avoma AI',
    triggerPrompts: 'Trigger Prompts',
    upgradeAINotes: 'Get Advanced AI Notes',
    upgradeAINotesMobile: 'AI Notes',
    organizerLimitReached: 'Organizer has reached AI Notes limit',
    connectionLost: 'Connecting...',
    limitReachedTooltipText1: `Advanced AI meeting notes are extracted based on the meeting host's license type and they are limited to only 5 meetings for each free user (Basic License) per month.`,
    limitReachedTooltipText2: `For unlimited Advanced AI notes upgrade to paid plan.`,
    toolbar: {
      h1: 'Heading 1',
      h2: 'Heading 2',
      h3: 'Heading 3',
      link: 'Insert link',
      bold: 'Bold',
      italic: 'Italic',
      underline: 'Underline',
      strikethrough: 'Strikethrough',
      orderedList: 'Numbered list',
      unorderedList: 'Bulleted list',
      checklist: 'Checklist',
      undo: 'Undo Changes',
      redo: 'Redo Changes',
      more: 'More Actions',
      clickupSelect: 'Select text first to Create ClickUp Task',
      clickupCreate: 'Create Clickup Task'
    }
  },
  shareButtonPopover: {
    titleMeeting: 'Meeting shared with',
    titleMeetingCall: 'Call shared with',
    titleHighlight: 'Snippet shared with',
    deleteConfirmation: 'Confirm Delete?',
    copyEmail: 'Copy Email'
  },
  playlistButtonPopover: {
    titleMeeting: 'Meeting added to',
    titleHighlight: 'Snippet added to',
    deleteConfirmation: 'Remove from Playlist?'
  },
  trends: {
    trends: 'Custom Trends',
    addKeyword: 'Add Keyword or Phrase',
    suggestions:
      'Search and compare how often certain keywords and phrases come up in your customer conversations. Begin by entering some keywords in the text box above',
    subtitle: 'Suggestions to get you started',
    sugDiscount: '"Are prospects asking for discounts?"',
    sugFiller: '"Are reps using too many filler words?"',
    sugFollow: '"Are reps pushing to close?"'
  },
  moreDetailsMenu: {
    moreDetails: 'More Details',
    makePrivate: 'Make Private',
    editMeetingSubject: 'Edit Title',
    continueRecording: 'Reconnect Bot',
    deleteMeeting: 'Delete',
    deleteMeetingTitle: 'Delete the meeting?',
    deleteMeetingText:
      'Everything, including recording, transcript, notes, analysis, and snippets will be deleted.\n\nOnce deleted, you will not be able to recover the meeting.',
    deleteMeetingCta: 'Delete',
    deleteMeetingError:
      'There was a problem deleting this meeting. Please try again.',
    downloadMeeting: 'Download',
    downloadMeetingTitle: 'Download a file',
    rateLimitedError:
      'You’ve exceeded the daily limit to download recordings. Please try again after 24 hours or contact Avoma support for assistance',
    downloadMeetingError:
      'There was a problem downloading this meeting. Please try again.',
    downloadMeetingSuccess: 'Download request submitted successfully.',
    downloadEmailNotification:
      'You will receive an email shortly with a link to download your files.',
    downloadMeetingParams: {
      video: 'Video',
      audio: 'Audio',
      transcript: 'Transcript',
      label:
        'Select what items you would like to download from this conversation'
    },
    makePrivateConfirmTitle: 'Make Meeting Private?',
    makePrivateConfirmText:
      'Only meeting participants and people invited can view.',
    makeOrgConfirmTitle: 'Make Meeting Available For Organization?',
    makeOrgConfirmText: 'Anyone from your organization can view the meeting.',
    makeTeamConfirmTitle: 'Make Meeting Available For Primary Team?',
    makeTeamConfirmText:
      'Anyone from your primary team and primary team of other participants can view the meeting.',
    makePublicConfirmTitle: 'Make Meeting Available For Public?',
    makePublicConfirmText: 'Anyone with the link can view the meeting.',
    downloadSnippetError:
      'There was a problem downloading this Snippet. Please try again.',
    downloadHighlightSuccessMessage:
      'You will receive an email with a link to download your files shortly.'
  },
  navigationBar: {
    homepage: 'Set as homepage',
    knowledgeBase: 'Knowledge Base',
    home: 'Conversations',
    homeTooltip: 'View Conversations',
    meetings: 'Meetings',
    calls: 'Calls',
    meetingsMyMeetings: 'My Meetings',
    meetingsShared: 'Shared Meetings',
    meetingsAllMeetings: 'All Meetings',
    meetingsTeamMeetings: 'Team Meetings',
    revenueInsights: 'Revenue Insights',
    conversationInsights: 'Conversation Insights',
    deals: 'Deals',
    dashboardsConversations: 'Interaction',
    dashboardsTrackers: 'Trackers',
    dashboardsTopics: 'Topics',
    dashboardsPopularTopics: 'Popular Topics',
    dashboardsTrends: 'Custom Trends',
    dashboardsActivity: 'Activity',
    dashboardsTalkToListen: 'Talk : Listen',
    myTeams: 'My Teams',
    accounts: 'Accounts',
    accountsFindAccount: 'Meetings by Company',
    allMembers: 'All Members',
    teamMembers: 'Team Members',
    teamMembersInvite: 'Invite New Member',
    settings: 'Settings',
    contactUs: 'Contact Us',
    playlists: 'Playlists',
    playlistsTooltip: 'View Playlists',
    expand: 'Expand Sidebar',
    collapse: 'Collapse Sidebar',
    logOut: 'Log Out',
    logOutTitle: 'Log Out?',
    logOutDescription: 'Are you sure you want to log out?',
    referralProgram: 'Refer and Earn',
    whatsNew: "What's New",
    quickStart: 'Quick Start',
    scheduler: 'Scheduler',
    scorecards: 'Coaching',
    onboardingCardTitle: 'Prefer to speak with an onboarding expert?',
    onboardingCardLink: 'Schedule a meeting',
    pipeline: 'Pipeline',
    companies: 'Companies',
    usageActivity: 'Activity',
    engagement: 'Engagement',
    usageInsights: 'Usage Insights',
    roi: 'ROI',
    recording: 'Recording Statistics',
    askAvoma: 'Ask Avoma'
  },
  dashboards: {
    trendsChartLabel: 'Trends',
    leaderboardChartLabel: 'Leaderboard',
    breakdownChartLabel: 'Breakdown',
    popularChartLabel: 'Popular',
    noData: 'No Results',
    noDataSubtitle: 'There were no results for the selected set of filters.'
  },
  trackersDashboard: {
    noKeywordsWarning: 'You have no keywords to track in this category',
    noCustomCategoryWarning:
      'Tracking is not enabled for any of the custom category',
    trackerNameColumnHeader: 'Tracker Name',
    meetingsColumnHeader: 'Number of Conversations with Mention',
    trendsGraphLabel: 'Trends',
    leaderboardGraphLabel: 'Leaderboard',
    checkboxSelectAll: 'Select All',
    totalInfoPopoverItems: [
      {
        term: 'Overview - ',
        description: 'Unique Meetings for entire Organization.'
      },
      {
        term: 'Team Members - ',
        description:
          'Meetings by team members, 1 meeting in organization can have 3 members.'
      },
      {
        term: 'Types - ',
        description: 'Meetings with purposes attached.'
      },
      { term: 'Outcomes - ', description: 'Meetings with outcomes attached.' },
      {
        term: 'Deal Stages - ',
        description: 'Meetings with selected deal stages.'
      },
      {
        term: 'Won vs Lost - ',
        description:
          'Meetings where the deal was won vs lost, a deal can have multiple meetings.'
      }
    ]
  },
  notifications: {
    breadcrumb: 'Notifications',
    title: 'Notification Settings',
    on: 'On',
    off: 'Off',
    yes: 'Yes',
    no: 'No',
    disabled: 'N/A',
    setSettingsFailure:
      'There was a problem updating your notification settings',
    emailSectionTitle: 'Email Notifications',
    defaultDisclaimerText:
      'This call will be recorded for note-taking and as a resource for both the host and the participants.',
    recordingDisclaimerHelpTitle: 'Recording Disclaimer',
    recordingDisclaimerHelpContent:
      'Certain countries require that all meeting participants be informed if a call is being recorded. The recording disclaimer ensures compliance with this regulation. ',
    recordingDisclaimerTitle: 'Recording Disclaimer Notifications',
    meetingReminderSelfTitle: 'Meeting Reminder to Yourself',
    meetingReminderSelfSubtitle:
      'Get a meeting reminder sent to your email before the meeting.',
    meetingReminderExternalTitle: 'Meeting Reminder to Participants',
    meetingReminderExternalSubtitle:
      'Select to whom you want to send meeting reminder emails and at what time before the meeting start time.',
    calendarInviteDisclaimer: 'Add disclaimer in calendar event description',
    meetingReminderDisclaimer: 'Add disclaimer in meeting reminder emails',
    recordingDisclaimerToggle: 'Enable Recording Disclaimer',
    recordingDisclaimerSubtitle:
      'When enabled, Avoma will include the following recording disclaimer in the description of your scheduled meetings.',
    transcriptReadyTitle: 'AI Generated Meeting summary and Notes Notification',
    transcriptReadySubtitle:
      "Get an email notification when your meeting's AI summary, notes & recording are ready for review.",
    participantTypeTitle: 'Participant Type',
    participantTypeSubtitle:
      'Get an email notification when based on participant type.',
    participantTypeHost: 'Meeting I was a Host',
    participantTypeParticipant: 'Meeting I was a Participant',
    meetingTypeTitle: 'Meeting Type',
    meetingTypeExternal: 'External Meetings',
    meetingTypeInternal: 'Internal Meetings',
    meetingTypeSubtitle:
      'Get an email notification when based on meeting type.',
    risksManagerTitle: 'Potential Risks Notifications',
    risksManagerSubtitle:
      "Get an email notification when potential risks arise in your team's conversations.",
    avomaNotesTitle: 'Avoma Notes',
    avomaNotesSubtitle:
      "Send my Avoma human-assisted notes to me via email when they're ready.",
    avomaNotesContactUsSubtitle:
      "if you're interested in human-assisted notes for your team",
    lockedReminderTooltip:
      'Setting is locked for all members of the organization',
    unlockedReminderTooltip:
      'Settings can be overridden by members of the organization',
    lockedReminderTooltipUser: 'This setting cannot be overridden by the user',
    unlockedReminderTooltipUser: 'This setting can be overridden by the user',
    notificationBannerContent:
      'All members in your organization will use the following settings by default.',
    notificationBannerContentBold:
      "If you don't want the settings below to be changed, you can lock the settings.",
    disableReminderModalTitle: 'Disable recording disclaimer?',
    disableReminderModalText: `Certain countries, including the United States, mandate that all meeting participants be informed if a call is being recorded. Avoma recommends including a disclaimer in the description of scheduled meetings that the meeting will be recorded, as a best practice.

Do you still wish to disable the addition of a recording disclaimer to your organization’s calendar events and meeting reminder emails?`,
    disableDisclaimerModalTitle: 'Disable recording disclaimer?',
    disableDisclaimerModalText:
      'Certain countries, including the United States, mandate that all meeting participants be informed if a call is being recorded. Avoma recommends including a disclaimer in the description of scheduled meetings that the meeting will be recorded, as a best practice.\n\nDo you still wish to disable the addition of a recording disclaimer to your organization’s calendar events and meeting reminder emails?',
    disableCta: 'Disable',
    lockedByAdmin: 'Locked by Admin',
    lockExternalReminderSettings: 'Lock "Meeting Reminder to Participants"?',
    unlockExternalReminderSettings:
      'Unlock "Meeting Reminder to Participants"?',
    lockSettingModalText:
      "The setting will be locked for all members of the organization and they won't be able to change any values.",
    unlockSettingModalText:
      'The setting values can be overridden by members of the organization.',
    lock: 'Lock',
    unlock: 'Unlock',
    aiScorecardsTitle: 'AI Scored Conversations Notification',
    aiScorecardsSubtitle:
      'Get a daily or weekly email digest of your conversations scored by Avoma AI.',
    aiScorecardsDaily: 'Daily',
    aiScorecardsWeekly: 'Weekly',
    weeklyUsageDigestLabel: 'Weekly Usage Digest',
    weeklyUsageDigestSubtext:
      'Get a weekly digest of your Avoma usage and key highlights'
  },
  licenses: {
    breadcrumb: 'Billing',
    title: 'Billing',
    loadingPlans: 'Loading Plans',
    billingCycle: 'Billing Cycle',
    manageUserLicenses: 'Assign User Licenses',
    manageSubscription: 'Manage Subscriptions',
    manageLicenses: 'Manage Licenses',
    manageBillingInfo: 'Manage Billing Info & Payment Method',
    changePaymentMethodTitle: 'Change Payment Method',
    viewBillingHistory: 'View Billing History',
    headerPlans: 'Plan Type',
    headerLicenses: 'Licenses Used',
    headerStatus: 'Current Status',
    headerStartDate: 'Start Date',
    headerBillDate: 'Next Billing Date',
    headerPrice: 'Plan Price',
    headerDiscountedPrice: 'Discounted Price',
    headerBillPrice: 'Next Billing Amount',
    headerTotal: 'Total',
    purchaseTitle: 'Purchase License',
    assignLicensesTitle: 'Assign Licenses',
    purchasedAddonsTitle: 'Purchased Addons',
    assignAddonsTitle: 'Assign Addons',
    purchaseTitleShort: 'Purchase',
    buyTitle: 'Buy License',
    buyAddonTitle: 'Buy Addon',
    setPrimaryTeam: 'Set as Primary Team',
    rolesAvailable: 'Roles Available',
    primary: 'Primary',
    requestTitle: 'Request License',
    requestTitleShort: 'Request',
    addLicenseTitle: 'Add Licenses',
    editAddonTitle: 'Edit Addon Quantity',
    removeLicenseTitle: 'Remove Licenses',
    assignLicenseTitle: 'Assign Licenses',
    addRemoveLicensesTitle: 'Add/Remove\nLicenses',
    upgradeToYearly: 'Upgrade\nto Yearly',
    downgradeToMonthly: 'Downgrade\nto Monthly',
    upgradeToHalfYearly: 'Upgrade\nto Half-Yearly',
    downgradeToHalfYearly: 'Downgrade\nto Half-Yearly',
    seeDetails: 'See All Features',
    currentPlansTitle: 'Current Plans',
    purchasePlansTitle: 'Available Plans',
    fetchingPlansErrorTitle: 'Unable to fetch plans',
    fetchingPlansErrorSubtitle: 'There was a problem fetching available plans',
    month: 'month',
    year: 'year',
    halfYear: 'half-year',
    monthly: 'Monthly',
    yearly: 'Yearly',
    halfYearly: 'Half-Yearly',
    quarterly: 'Quarterly',
    monthlySelection: 'Monthly',
    yearlySelection: 'Yearly (20% Off)',

    billingCycleLabel: 'Step 1: Select Billing Cycle',
    planSelectionLabel: 'Step 2: Select Plan',
    assignUserLabel: 'Step 3: Assign Licenses',
    perUser: ' user/month',
    unlimitedUsers: 'Unlimited Users',
    deactivateUserLicenseSuccess: 'Successfully deactivated user license',
    deactivateUserLicenseFailure:
      'There was a problem deactivating user license',
    assignedUserLicenseSuccess: 'Successfully assigned user license',
    assignedUserLicenseFailure: 'There was a problem assigning user license',
    assignedUserAddonSuccess: 'Successfully assigned user addon',
    assignedUserAddonFailure: 'There was a problem assigning user addon',
    deactivatedUserAddonSuccess: 'Successfully deactivated user addon',
    deactivatedUserAddonFailure: 'There was a problem deactivating user addon',
    cancelled: 'Cancelled',
    mostPopular: 'Most Popular',
    comingSoon: 'Coming Soon',
    productCategoryTitle: 'For Product, Design, Marketing, & Recruiting',
    freeCategoryTitle: 'For Collaborators',
    salesCategoryTitle: 'For Sales & Customer Success',
    supportCategoryTitle: 'For Sales Development',
    billingOptionsLabel: 'Billing Options',
    disallowedForMontlyBilling: 'Available Yearly',
    otherTeamsTabName: 'AI Meeting Assistant',
    purchasedYearly: 'Purchased Yearly',
    revenueTeamsTabName:
      'Conversation & Revenue Intelligence (For Revenue Teams)',
    descriptions: {
      'basic-monthly': 'Individuals',

      'lite-monthly':
        'Unlimited AI Meeting Assistant and Advanced Scheduler to automate Note taking and scheduling',
      'lite-yearly':
        'Unlimited AI Meeting Assistant and Advanced Scheduler to automate Note taking and scheduling',

      'plus-monthly':
        'Advanced Conversation Intelligence and AI Scorecards to automate Coaching and learning insights',
      'plus-yearly':
        'Advanced Conversation Intelligence and AI Scorecards to automate Coaching and learning insights',

      'premium-monthly':
        'Revenue Intelligence to streamline Pipeline reviews, Forecasting and to automate Win-Loss analysis',
      'premium-yearly':
        'Revenue Intelligence to streamline Pipeline reviews, Forecasting and to automate Win-Loss analysis'
    },
    detailed_descriptions: {
      'basic-monthly':
        'Limited AI Meeting Assistant and unlimited viewing of recorded meetings.',
      'lite-monthly':
        'Unlimited AI Meeting Assistant and Advanced Scheduler to automate Note taking and scheduling.',
      'lite-yearly':
        'Unlimited AI Meeting Assistant and Advanced Scheduler to automate Note taking and scheduling.',
      'plus-monthly':
        'Advanced Conversation Intelligence and AI Scorecards to automate Coaching and learning insights.',
      'plus-yearly':
        'Advanced Conversation Intelligence and AI Scorecards to automate Coaching and learning insights.',
      'premium-monthly':
        'Revenue Intelligence to streamline Pipeline reviews, Forecasting and automate Win-Loss analysis',
      'premium-yearly':
        'Revenue Intelligence to streamline Pipeline reviews, Forecasting and automate Win-Loss analysis',
      'premium-v2-monthly':
        'Can record, transcribe and analyze your meetings. Notes and meetings are syned to CRM records and conversation intelligence is available',
      'premium-v2-yearly':
        'Can record, transcribe and analyze your meetings. Notes and meetings are syned to CRM records and conversation intelligence is available',
      'in-trial':
        'Revenue Intelligence to streamline Pipeline reviews, Forecasting and automate Win-Loss analysis'
    },
    deactivate: 'Deactivate',
    alwaysAvailable: 'Always Available',
    freeTrial: 'Free Trial',
    free: 'FREE',
    noLicenses: 'You currently have no licenses',
    trialLicense: 'Unrestricted Trial',

    statuses: {
      future: 'Future',
      in_trial: 'Trialing',
      active: 'Active',
      non_renewing: 'Non Renewing',
      paused: 'Paused',
      cancelled: 'Cancelled',
      error: 'Unknown'
    },
    basic: 'Basic',
    noPermissions: 'You do not have permissions to access this page.',
    allAssignedLicenses: 'All licenses are assigned for your organization.',
    allAssignedAddons: 'All addons are assigned for your organization.',
    noAddonsForPlan: 'No addons for this plan',
    noAddonsPurchased: 'No addons purchased',
    noPlansPurchased: 'No plans purchased'
  },
  accountSettings: {
    breadcrumb: 'Profile',
    title: 'Profile Settings',
    profileSectionTitle: 'My Profile',
    accountSectionTitle: 'Account Details',
    userNotAddedToTeamMessage: '*Not added in any team yet',
    extremeMeasuresTitle: 'Extreme Measures',
    deactivationTitle: 'Deactivate your account',
    deactivationSubtitle:
      'If you deactivate your account, you’ll lose all your access and messages temporarily, but we’ll keep all your recording data in Avoma.',
    editProfileFirstNameLabel: 'First Name',
    editProfileLastNameLabel: 'Last Name',
    editProfileJobLabel: 'Job Title',
    editProfileTimezoneLabel: 'Time zone',
    saveChangesCta: 'Save Changes',
    jobTitlePlaceholder: 'e.g. SDR, Account Executive, CSM, etc.',
    max150CharsErrorMessage: 'Enter max 150 characters',
    max140CharsErrorMessage: 'Enter max 140 characters'
  },
  teamSettings: {
    addMembers: 'Add members',
    createNewTeam: 'Create new team',
    primaryTeamMember: 'Primary',
    extraTeamMember: 'Non-Primary',
    membershipType: 'Membership Type',
    membershipText1: 'Primary team members have this team as their main team.',
    membershipText2:
      'Non-Primary members have a different main or no primary team assigned to them at all.',
    membershipText3:
      'Conversations of Primary team members that are marked “visible to specific teams” can be seen by the rest of the team including non-primary members but not the other way round.'
  },
  calendarSettings: {
    breadcrumb: 'Calendar',
    title: 'Calendar Settings',
    googleTitle: 'Google Calendar Sync',
    microsoftTitle: 'Outlook Calendar Sync',
    googleOrMicrosoftTitle: 'Calendar Sync',
    syncedGoogle: 'Connected to Google Calendar',
    syncedMicrosoft: 'Connected to Outlook Calendar',
    disconnectGoogle: 'Disconnect Google Calendar',
    disconnectMicrosoft: 'Disconnect Outlook Calendar',
    stopMsExchange: 'Stop Microsoft Exchange Calendar Sync',
    syncedGoogleCalDescription:
      'If you no longer want Avoma to sync your upcoming meetings, you can disconnect Google calendar. Past meeting notes, recordings, transcripts, and insights will remain accessible.',
    syncedOutlookCalDescription:
      'If you no longer want Avoma to sync your upcoming meetings, you can disconnect Outlook calendar. Past meeting notes, recordings, transcripts, and insights will remain accessible.',
    msExchangeTitle: 'Microsoft Exchange Calendar Sync',
    msExchangeDescription:
      'Setting up Microsoft Exchange Calendar will start syncing meetings  from your exchange server account.',
    msExchangeServerInput: 'Server address',
    msExchangeServerInputPlaceholder: 'address',
    msExchangeUsernameInput: 'User name',
    msExchangeUsernameInputPlaceholder: 'user name',
    msExchangePasswordInput: 'Password',
    msExchangeSyncCta: 'Set Up Microsoft Exchange Calendar',
    msExchangeSaveCta: 'Save changes',
    msExchangeSyncingMessage: 'Saving...',
    msExchnageSyncFailedBanner:
      'Could not contact exchange server with given information. Please try again.',
    msExchnageSyncSuccessBanner:
      'Your Microsoft Exchange Calendar is set up successfully.'
  },
  conferenceSettings: {
    breadcrumb: 'Conferencing',
    title: 'Conferencing Settings',
    optionsCard:
      'Recording Preference — Native Integrations vs Avoma Assistant (Bot)',
    avomaAppCard: 'Native App Collaborative Note-taking',
    allConfAvomaAppHelpText:
      'In-app collaboration is currently not supported for other conferencing apps.',
    allConfAvomaAppTitle: 'For all other conferencing',
    zoomConfTitle: 'For Zoom',
    zoomConfAvomaAppTitle: 'For Zoom Desktop App',
    zoomConfAvomaAppHelpText:
      'Take notes and bookmark important moments with your team during the meeting from the Zoom desktop client.',
    notSupported: 'Currently not supported.',
    installAvomaAppText:
      'Install Avoma Zoom App for in-app collaborative note-taking experience',
    webexConfTitle: 'For Webex',
    webexNotificationBanner:
      'Please connect your Webex Account and Integrate your calendar',
    msftCloudConfTitle: 'For Microsoft Teams',
    msftCloudNotificationBanner:
      'Please connect your Microsoft Account and Integrate your calendar',
    gmeetCloudConfTitle: 'For Google Meet',
    turnOn: 'Turn on recording',
    goToConferenceSettings: 'conferencing settings',
    modifyRecordingPref: 'to modify below recording preferences'
  },
  zoom: {
    accSetupTitle: 'Update recording settings in Zoom',
    accSetupSubtitle:
      'Allow Avoma to enable a few Recording Settings in your Zoom account.',
    cloudRecording: 'Cloud recording',
    cloudRecordingHelpText:
      'Allow hosts to record and save the meeting / webinar in the cloud.',
    autoRecording: 'Automatic recording',
    autoRecordingHelpText:
      'Meetings/Webinars organized by the host will be recorded automatically.',
    activeSpeaker: 'Record Active Speaker view',
    activeSpeakerHelpText:
      'Improve speaker identification by enabling the Active Speaker recording in Zoom Cloud Recording',
    pmiAutoRecording: 'PMI cloud recording',
    pmiAutoRecordingHelpText:
      'The host can Record PMI (Personal Meeting ID) as it starts.',
    autoDeleteCmr: 'Auto deletion of cloud recording',
    autoDeleteCmrHelpText:
      'The host can allow zoom to automatically delete the recordings after a specific number of days.',
    notUsePmiForScheduledMeetings:
      'Do not use personal meeting ID (PMI) when scheduling a meeting',
    notUsePmiForScheduledMeetingsHelpText:
      'When scheduling a meeting, create a new Zoom meeting instead of using the PMI (Personal Meeting ID). With this enabled, Zoom generates unique meeting ID for each scheduled meeting.',
    autoDeleteCmrDays: 'Days',
    autoDeleteCmrDaysWarning: 'Please enter a value between 1 and 120.',
    autoDeleteCmrDaysHelpTxt: '(max 30 days)',
    activeSpeakerScreenShare: 'Record shared screen with Active Speaker View',
    activeSpeakerScreenShareHelpText:
      'To ensure your video is captured along with the shared screen.',
    audioTranscript: 'Audio Transcript',
    audioTranscriptHelpText:
      'Enabling audio transcript for cloud recordings in zoom improves speaker identification.',
    settingsChecklistCard1: 'Zoom Recording Settings',
    settingsChecklistCard2: 'Advanced Settings',
    settingsChecklistCard3:
      'Install Avoma Zoom App for in-app collaborative note-taking experience',
    savingZoomSettings: 'Saving...',
    settingEnabled: 'Enabled in Zoom',
    settingDisabled: 'Disabled in Zoom',
    requiredSettingDisabled: 'Disabled in Zoom! Required to be enabled',
    zoomAppDeskbotWarning: 'Cannot select if Avoma app is installed in Zoom',
    allowLiveStreaming: 'Allow livestreaming of meetings',
    allowLiveStreamingHelpText:
      'Allow meetings being livestreamed to Avoma for live bookmarking.',
    allowLiveStreamingCheckboxHelpDoc:
      'https://help.avoma.com/how-to-use-bookmarks',
    cloudRecordingForPaidPlan:
      'Cloud recording via Zoom is only available when you have a paid license for Zoom.',
    settingLockedByAdmin:
      'This setting is locked by the Zoom administrator. Please contact them for assistance.'
  },
  privacySettings: {
    confirmTitle: 'Change Privacy Settings',
    visibleToParticipants: 'visible only to participants',
    visibleToOrg: 'visible to your entire organization',
    visibleToPrimaryTeam: 'visible to members of primary team',
    pastMeetingsDisclaimer:
      'This change will only affect future meetings and not affect any of your past meetings.',
    internalMeetings: 'internal meetings',
    externalMeeting: 'external meetings',
    private: 'Private to Participants',
    team: 'Visible to Specific Teams',
    org: 'Visible to Organization',
    setPrivacySuccess: 'Privacy settings updated successfully',
    setPrivacyFailure: 'There was a problem updating your privacy settings',
    accessPermissions: 'Access Permissions',
    confirmModalHelpText1:
      'This change will apply not only to meetings where the user is the host but also to meetings where the user is a participant.',
    confirmModalHelpText2:
      'This change will apply only to the meetings where you are the host and are external meetings.',
    confirmModalInternalHelpText:
      'This change will apply only to the meetings where you are the host and are internal meetings.',
    confirmModalHelpText3:
      'Conversations marked as "Visible to Specific Teams" will be accessible to rest of the team members.',
    confirmModalDescription1:
      'This change will only apply to future meetings where you are the host. Past meetings will not be impacted by this change.',
    confirmModalDescription2: 'Would you like to continue?',
    removeTeamSelectionTooltip:
      "You must select at least one team to configure the 'Visible to specific teams' privacy policy.",
    teamSearchPlaceholder: 'Search by team name',
    teamSelectPlaceholder: 'Select team',
    teamSelectTitle: 'Select Teams to Grant Access',
    saveButtonLabel: 'Save Settings',
    noTeamSearchResults: 'No teams found matching with the search criteria',
    confirmationModalButtonText: 'Save & Apply settings',
    warningChipText: 'Primary Team',
    noValidTeamTooltip:
      'Please assign a valid team in the meeting privacy to select this option',
    noPrimaryTeamTooltip:
      'You have not been assigned to a Primary Team. Please contact your Avoma admin to assign you to a team.',
    disabledPrivacyTooltipForSnippet:
      'To make this snippet visible to specific teams, share them directly with the teams or change the privacy using the icon next to the snippet name',
    disabledPrivacyTooltipForPlaylist:
      'To make this playlist visible to specific teams, share it directly with the teams or change the privacy by either editing the playlist or clicking the icon next to the playlist name',
    disabledPrivacyTooltipForMeeting:
      'To make this meeting visible to specific teams, share it directly with the teams or change the privacy using the icon next to the meeting name on the meeting page',
    disabledPrivacyTooltipForPublic:
      'Marking this as public is disabled for your organization.',
    lockByAdminTooltip: 'Locked by Admin'
  },
  userPrivacySettings: {
    breadcrumb: 'Privacy',
    title: 'Privacy Settings',
    subTitle: 'Default Privacy Behavior for Your Meetings',
    toggleTitle: 'Mark all my future meetings as private',
    toggleSubTitle:
      'Avoma will mark all future meetings where you are participant as private.',
    orgPrivacySeleted:
      'Your organization has set all external meetings to be Visible to Organization by default',
    internalOrgPrivacySeleted:
      'Your organization has set all internal meetings to be Visible to Organization by default',
    orgDisabled:
      'Your organization has set all external meetings to be Private by default',
    teamPrivacyEnabled:
      'Your organization has set all external meetings to be Visible to Specific Teams by default',
    internalOrgDisabled:
      'Your organization has set all internal meetings to be Private by default',
    internalTeamPrivacyEnabled:
      'Your organization has set all internal meetings to be Visible to Specific Teams by default',
    teamBannerText: memberName => (
      <React.Fragment>
        {memberName ? `${memberName} has` : 'You have'} not been assigned
        a&nbsp;<b>Primary Team</b>. Please contact your Avoma admin to assign
        you to a team.
      </React.Fragment>
    )
  },
  orgPrivacySettings: {
    breadcrumb: 'Privacy Policies',
    title: 'Privacy Policies',
    subTitle: "Default Privacy Behavior for Your Organization's Meetings",
    internalTitle: 'Internal Meetings',
    internalDefault:
      'By default, all internal meetings are private to participants.',
    externalTitle: 'External Meetings',
    externalSubTitle: 'Avoma will mark all future external meetings as private.'
  },
  privacyTypes: {
    participants: 'participants',
    teams: 'teams',
    org: 'org'
  },
  recordingSettings: {
    title: 'Recording Settings',
    automaticRecCard: 'Avoma Assistant (Bot) Automatic Recording Preferences',
    setAutoRecFailure: 'There was a problem updating your recording settings',
    recordingAnnoucementDisabledTooltip:
      'Only Admins can modify this for the entire organization',
    recordingAnnouncementNotification:
      'This setting does not apply to Zoom meetings. Zoom will always notify meeting participants that a meeting is being recorded.',
    user: {
      externalMeetings: 'Record my external meetings',
      externalMeetingsSubText: 'when I am',
      externalMeetingsSubtitle1: 'Meetings with any one participant outside of',
      externalMeetingsSubtitle2: 'are',
      externalMeetingsSubtitle3: 'external meetings.',
      externalMeetingsHelpText:
        'If the host is an external participant and your participant recording setting is enabled, the meeting will be automatically recorded.',
      internalMeetings: 'Record my internal meetings',
      internalMeetingsSubText: 'when I am',
      internalMeetingsSubtitle1: 'Meetings with all',
      internalMeetingsSubtitle2: 'participants are',
      internalMeetingsSubtitle3: 'internal meetings.',
      internalMeetingsHelpText:
        'When there are two participants in a meeting from same organization, with different recording preferences, the hosts recording preferences will be considered.',
      organizerOnlyMessage: 'only when you are the host.',
      notOrganizerOnlyMessage:
        'even when you are not the host and are just a participant.'
    },
    admin: {
      externalMeetings: 'Record Organization’s external meetings',
      externalMeetingsSubText: 'when members are',
      externalMeetingsSubtitle1: 'Meetings with any one participant outside of',
      externalMeetingsSubtitle2: 'are',
      externalMeetingsSubtitle3: 'external meetings.',
      externalMeetingsSubtitle:
        'E.g. Sales Demos, Customer Meetings, Recruiting Interviews, etc.',
      externalMeetingsHelpText:
        'If the host is an external participant and your participant recording setting is enabled, the meeting will be automatically recorded.',
      internalMeetings: 'Record Organization’s internal meetings',
      internalMeetingsSubText: 'when members are',
      internalMeetingsSubtitle1: 'Meetings with all',
      internalMeetingsSubtitle2: 'participants are',
      internalMeetingsSubtitle3: 'internal meetings.',
      internalMeetingsHelpText:
        'When there are two participants in a meeting from same organization, with different recording preferences, the hosts recording preferences will be considered.',
      organizerSetting:
        'Record external meetings when user is a meeting participant and not the host',
      organizerSettingSubtitle:
        'Avoma will always record meetings when user is the host.',
      organizerOnlyMessage: 'only when user is the host.',
      notOrganizerOnlyMessage:
        'even when user is not the host and is just a participant.',
      doNotDialMeetings: 'Disable recording any meetings for all users?',
      doNotDialInternalMeetings:
        'Disable recording internal meetings for all users?',
      organizerMeetingsOnly:
        'Disable recording meetings when users are participants?',
      lockSettingModalText:
        "The setting will be locked for all members of the organization and they won't be able to make any change to their individual settings. This will also apply to all members whose recording settings are locked.",
      unlockSettingModalText:
        'The setting values can be overridden by members of the organization.',
      lockNotificationText:
        'If any of the preferences are locked, users will not be able to make any changes on their own.',
      userWillBeNotified: 'User will be notified via email with this change.',
      doNotDialLockedTooltip:
        'This is not allowed to be changed as "Not recording" any meetings is turned ON',
      lockedTooltip:
        'Admins can unlock these settings so that users can modify their Individual settings',
      unlockedTooltip:
        'Admins can lock these settings so that users cannot modify their Individual settings',
      lockDisabledTooltip:
        'Changes cannot be made since the changes are locked at the Organization level',
      lockDisabledUserTooltip:
        'Changes cannot be made since the changes are locked by admin',
      adminDisabledTooltip: 'Only Admins can change this setting'
    },
    modal: {
      externalMeetings: "Record user's all external meetings",
      externalMeetingsSubText: 'when user is',
      externalMeetingsSubtitle1: 'Meetings with any one participant outside of',
      externalMeetingsSubtitle2: 'are',
      externalMeetingsSubtitle3: 'external meetings.',
      externalMeetingsHelpText:
        'If the host is an external participant and your participant recording setting is enabled, the meeting will be automatically recorded.',
      internalMeetings: "Record user's all internal meetings",
      internalMeetingsSubText: 'when user is',
      internalMeetingsSubtitle1: 'Meetings with all',
      internalMeetingsSubtitle2: 'participants are',
      internalMeetingsSubtitle3: 'internal meetings.',
      internalMeetingsHelpText:
        'When there are two participants in a meeting from same organization, with different recording preferences, the hosts recording preferences will be considered.'
    },
    participantRecDisabledTooltip:
      'To access this setting, enable external meeting recording',
    doNotDialTitle: 'Enable automatic recording',
    externalOnlyMessage: 'Avoma will record external meetings.',
    internalOnlyMessage: 'Avoma will record internal meetings.',
    recordAllMessage: 'Avoma will record both external and internal meetings.',
    recordNoneMessage: 'Avoma will not record any meetings.',
    customerMeetingsDescription:
      'Avoma syncs to your calendar and will only dial-in and record meetings that include an external user in the calendar invite (someone not in the same company as you)',
    allMeetingsDescription:
      'Avoma syncs to your calendar and dials into to all meetings—both external customer and internal company meetings— that include online meeting details in the calendar invite.',
    noMeetingsDescription:
      'No calendar sync, and you’ll have to manually add assistant@avoma.com as a participant on all of your calendar invites to record and transcribe your meetings.',

    zoomTitle: 'Zoom Meeting Recording',
    zoomSettingsError: 'Zoom is not configured to record your meetings.',
    zoomSettingsOkay: 'Zoom is configured to record your meetings.',
    zoomLastUpdated: 'Last updated',
    zoomAllGood:
      'Your Zoom settings are good to go! Zoom will automatically record your meetings.',
    zoomTableSettingTitle: 'Zoom setting',
    zoomTableCurrentSettingTitle: 'Current value',

    cloudRecording: 'Cloud recording',
    autoRecording: 'Automatic recording',
    autoDelete: 'Auto-delete cloud recording',
    pmiAutoRecording: 'PMI automatic recording',
    enabledInZoom: 'Enabled in Zoom',
    disabledInZoom: 'Disabled in Zoom',
    assistantNameUpdated: 'Assistant name updated',
    announcementUpdated: 'Announcement message updated',
    chatPromptUpdated: 'Chat prompt message updated',
    connectedZoomAccount: 'Connected to account:',
    lockedByAdmin: 'Locked by Admin',
    starterLicenseOrAbove: `Allowed for users having ${PLAN_NAMES.lite} License or above`
  },
  dialers: {
    connectDialers: 'Connect your Dialer into Avoma',
    connectedDialers: 'Connected Dialers',
    breadcrumb: 'Recording',
    title: 'Recording Settings',
    recordingSettingsTitle: 'Meeting Recording Policy',
    connectBenefits: [
      'You can connect multiple dialers at once.',
      'Access all your Dialer call recordings in one place.',
      'Analyze your calls and get Transcript, Notes, Conversation Insights, and more.'
    ],
    connectToDialer: (userName, dialerName) => (
      <React.Fragment>
        Connected to <span className='text-blue'>{userName}</span> on{' '}
        {dialerName}
      </React.Fragment>
    ),
    connectedBy: 'Connected by',
    connectionStale: dialerName =>
      `Your connection with ${dialerName} was disconnected due to an expired access token.`,
    dialerDisconnectedContent: dialerName => (
      <React.Fragment>
        Your {dialerName} dialer integration with Avoma was disconnected. Please
        reconnect to continue importing calls from {dialerName}
      </React.Fragment>
    )
  },
  recordingAndConferencing: {
    conferencePreferences: 'Conferencing app preferences',
    recordingPreferences: 'Recording preferences',
    title: 'Recording & Conferencing',
    defaultPreferences: 'Default Preferences',
    botSettings: 'Bot Settings'
  },
  companySettings: {
    updatingSettings: 'Updating settings',
    allowAdminMeetingDeletionTitle:
      'Allow only admins to delete the recordings in Avoma',
    allowAdminMeetingDeletionSubtitle:
      'If enabled, Only admins in Avoma will be able to have an option to delete recordings.',
    companyNameTitle: 'Organization Name',
    language: {
      title: 'Multi-language Preferences',
      titleHelp:
        'Avoma AI identifies the language spoken during the call from the specified options below and automatically generates the transcript in that language.',
      help: 'How does language detection work',
      form: {
        profileTitle:
          'Add the most commonly spoken languages in your conversations',
        orgTitle:
          'Add the most commonly spoken languages in your organizations’ conversations',
        subtitle: '(Maximum 5 languages)',
        add: 'Add Language',
        select: 'Select a Language',
        searchPlaceholder: 'Search for a Language',
        minLimit:
          'At least one language is required to process and transcribe your conversations.',
        maxLimit: 'You cannot add more than 5 languages'
      },
      userInfoSingle: language =>
        `Your organization's language preference is set to ${language}. You can customize this by adding your own preferred languages.`,
      userInfoMultiple: languages =>
        `Your organization's language preferences are set to ${languages}. You can customize these by adding your own preferred languages.`
    },
    extremeMeasuresTitle: 'Extreme Measures',
    deleteAccountTitle: 'Delete your account',
    impersonationConsentTitle:
      'Allow Avoma employees to access your account for troubleshooting issues and support.',
    impersonationConsentSubtitle:
      'If enabled, Avoma employees will have access to the user accounts within your organization for troubleshooting issues and providing support.',
    security: 'Security',
    enableImpersonationConsentModal: {
      title: 'Allow access to Avoma employees?',
      body: [
        'Enabling this setting will allow Avoma support and account management team access to user accounts within your organization for troubleshooting issues and providing support.'
      ]
    },
    disableImpersonationConsentModal: {
      title: 'Disable access to Avoma employees?',
      body: [
        'Disabling this setting will block Avoma support and account management team from accessing any user account belonging to your organization for any reason whatsoever.',
        'If you disable this setting, you will need to enable it again if you want to allow the Avoma team to access your account.'
      ]
    },
    deleteAccountSubtitle:
      'Please note, this action is irreversible. Once your Organization account is deleted, you’ll lose access to all data – including recordings, transcriptions, notes, insights, members, etc.',
    recordingSettingsTitle: 'Organization Recording Settings',
    externalMeetingsSubtitle:
      'Only external customer meetings will be recorded automatically.\nDon’t record internal meetings.',
    doNotDialSubtitle:
      'Automatic meeting recording is disabled by default for all meetings.\nTo record, manually enable recording from the meeting list.',
    organizerMeetingsTitle:
      'Record only when the user is the meeting organizer',
    organizerMeetingsSubtitle:
      'Automatically record meetings only if the user is the organizer for the meeting, not if they are just a participant.',
    setOrganizationPolicySuccess: 'Updated recording policy settings',
    setOrganizationPolicyFailure:
      'There was a problem updating your recording policy settings',
    setUpdateRecordingDisclaimerMessageSuccess: 'Disclaimer message updated',
    setUpdateRecordingDisclaimerStatusSuccess: 'Disclaimer settings updated',
    setOrganizationSettingsSuccess: 'Updated organization settings',
    setOrganizationSettingsFailure:
      'There was a problem updating your organization settings',
    setNotificationPolicyFailure:
      'There was a problem updating your notification policy settings',
    settingDisabled: '(Disabled due to organization recording settings)',
    noPermissions: 'You do not have permissions to access this page.',
    generalSettingsTitle: 'General',
    generalSettingsBreadcrumb: 'General',
    adminRecordingSettingsTitle: 'Recording & Conferencing',
    adminRecordingSettingsBreadcrumb: 'Recording Policies',
    adminNotificationSettingsTitle: 'Notification Policies & Settings',
    adminNotificationSettingsBreadcrumb: 'Notification Policies',
    adminDialerSettingsTitle: 'Dialer Settings',
    adminDialerSettingsBreadcrumb: 'Dialer',
    recordingPrompt: 'Enable Recording Announcement',
    readRecordingPrompt: 'Recording Announcement',
    readRecordingDisclaimer: 'Recording Disclaimer',
    disclaimerDefaultMessage:
      'This meeting is being recorded for note taking purposes.',
    recordingPromptDescription:
      'If enabled, when the Avoma Bot joins the meeting it informs participants that the meeting is being recorded with a brief message. Certain states require 2-party consent, which means you need to inform participants that the meeting is being recorded.',
    chatPromptDescription:
      "If enabled, when the Avoma Bot joins a meeting, it will notify participants that the meeting is being recorded through the conference provider's chat",
    outcomesMenuItem: 'Purpose & Outcomes',
    forbiddenLettersMessage:
      'Bot name can only have alphanumeric or special characters like ()',
    chatPrompt: 'Chat Prompt',
    allowChatPrompt:
      'Notify participants about the recording disclaimer via chat'
  },
  typesAndOutcomes: {
    sidebar: 'Purposes & Outcomes',
    breadcrumb: 'Conversation Purpose & Outcomes',
    title: 'Conversation Purpose & Outcomes Settings',
    cardTitle: 'Customize Conversation Purpose & Outcomes',
    typesTitle: 'Filter by Conversation Purpose',
    outcomesTitle: 'Filter by Conversation Outcome',
    addTypeCta: 'Add Conversation Purpose',
    addOutcomeCta: 'Add Conversation Outcome',
    chooseAColor: 'Choose a color:',
    addCta: 'Add',
    editCta: 'Edit',
    save: 'Save',
    deleteMeetingTypeTitle: 'Delete Conversation Purpose?',
    deleteMeetingTypeText:
      'Are you sure you wish to delete this conversation purpose? Conversations currently assigned with this purpose will not be affected.',
    deleteMeetingTypeFailure:
      'There was a problem deleting this conversation purpose.',
    deleteConfirm: 'Delete',
    deleteMeetingOutcomeTitle: 'Delete Conversation Outcome?',
    deleteMeetingOutcomeText:
      'Are you sure you wish to delete this conversation outcome? Conversations currently assigned with this outcome will not be affected.',
    deleteMeetingOutcomeFailure:
      'There was a problem deleting this conversation outcome.',
    selectTypeCta: 'Select purpose',
    selectTypeCtaCompressed: 'Purpose',
    selectOutcomeCta: 'Select outcome',
    selectOutcomeCtaCompressed: 'Outcome',
    selectAttributeFailure: 'There was a problem assigning your conversation',
    deleteAttributeFailure: 'There was a problem removing your conversation',
    externalOutcomeTooltip: 'Conversation Outcome from',
    noMeetingTypes: 'No conversation purposes found',
    noMeetingOutcomes: 'No conversation outcomes found',
    purposeAdded: 'Purpose successfully added.',
    outcomeAdded: 'Outcome successfully added.',
    purposeAlreadyExists: 'Purpose with the same name exists already.',
    outcomeAlreadyExists: 'Outcome with the same name exists already.',
    noPurposeFound: 'Purpose does not exist!',
    noOutcomeFound: 'Outcome does not exist!',
    addPurpose: 'Add New Purpose',
    addOutcome: 'Add New Outcome',
    addPurposeText: 'Click to add new Purpose',
    addOutcomeText: 'Click to add new Outcome',
    contactAdminPurpose: 'Contact Admin to create new Purpose',
    contactAdminOutcome: 'Contact Admin to create new Outcome'
  },
  orgSettings: {
    assistantTitle: 'Avoma Assistant (Bot) Settings',
    assistantName: 'Avoma Assistant (Bot) Name',
    notificationMessage: 'Recording Announcement Message',
    disclaimerMessage: 'Disclaimer Message',
    voicePreference: 'Avoma Assistant (Bot) Voice Preference',
    freeMembers: 'For Free Members',
    basicAvomaNotetakerName: 'Avoma.com Notetaker',
    paidMembers: 'For Paid Members',
    recordingSettings: 'Recording Settings',
    chatPromptMessage: 'Chat Message'
  },
  usersSettings: {
    reactivateUserTitle: 'Reactivate User?',
    reactivateUserText: 'This account will be reactivated',
    reactivateUserConfirmText: 'Reactivate',
    reactivateUserSuccess: 'User was successfully reactivated',
    reactivateUserFailure: 'There was a problem reactivating the user',
    showInactive: 'Show Deactivated Users',
    hideInactive: 'Hide Deactivated Users',
    resendInviteTitle: 'Resend Invite?',
    deleteInvitedMemberTitle: 'Delete Invite?',
    resendInviteConfirmCTA: 'Resend',
    deleteInvitedMemberConfirmCTA: 'Delete',
    editPreferencesRecordingSubtitle:
      'Decide which meetings you would like to record automatically and how would you like to record it.',
    editPreferencesRecordingTitle: 'Recording Preferences',
    editPreferencesPrivacyTitle: 'Default Privacy Behavior',
    noMembers: 'No members',
    noInvitedMembers: 'No new invited members',
    noDeactivatedMembers: 'No deactivated members',
    editProfileTitle: 'Edit Profile',
    editPreferencesTitle: 'Edit Recording & Privacy Settings',
    editRecordPreferencesTitle: 'Edit Recording Settings',
    editPrivacyPreferencesTitle: 'Edit Privacy Settings',
    editPreferencesTitleCrm: 'Edit CRM notes sync settings',
    deactivateTitle: 'Deactivate',
    disableSaveTooltip: 'Make changes to the settings above to enable it',
    privacyBanner:
      'Any change made to the privacy behaviour will only apply to future meetings. Past meetings will not be affected.',
    saveChanges: 'Save Changes',
    cancel: 'Cancel',
    deletingInvite: 'Deleting invite'
  },
  scorecardsSettings: {
    title: 'Scorecards Settings',
    createNewScorecard: 'Create New Scorecard',
    createManualScorecard: 'Manual Scorecard',
    createAiScorecard: 'AI Scorecard',
    newManualScorecardTitle: 'Create a Manual Scorecard',
    newAiScorecardTitle: 'Create an AI Scorecard',
    editManualScorecardTitle: 'Edit Manual Scorecard',
    editAiScorecardTitle: 'Edit AI Scorecard',
    noDescription: 'No description',
    noScorecards: `Your organization hasn't created any scorecards yet`,
    noPurpose: 'No purposes assigned',
    noPurposeOrTeam: 'No purposes or teams assigned',
    assignTeam: 'Assign Team',
    selectedTeams: 'Selected Teams',
    addNewTeams: 'Add New Teams',
    addTeamSearchPlaceholder: 'Search for a team',
    addTagSearchPlaceholder: 'Search for a tag',
    noTagsFound: 'No matching tags found',
    allTagsAssigned: 'No tags found',
    tagCreatedAndAdded: 'Successfully created new tag',
    tagDeleted: 'Successfully deleted tag',
    tagCreateError: 'Something went wrong while creating tag',
    tagDeleteError: 'Something went wrong while deleting tag',
    tooltip: {
      noOfTimesUsed: 'Number of times this scorecard has been used for scoring',
      purpose:
        'Conversations that match the purpose(s) of this scorecard will get a scorecard recommendation automatically',
      aiPurpose:
        'Conversations that match the purpose(s) of this scorecard will be scored automatically',
      team: 'All team members assigned to this scorecard will be scored automatically',
      aiPurposeTitle: 'Auto-Scoring',
      noAiPurpose:
        'Please assign at least one purpose to enable auto-scoring with this AI scorecard. All conversations that match the purpose(s) will be auto-scored.',
      status:
        'Deactivating a scorecard will disable all future conversations from being scored with the scorecard',
      aiStatus:
        'Deactivating an AI scorecard will disable auto-scoring even if rules are assigned to the scorecard',
      deleteQuestion: 'Delete Question',
      duplicateQuestion: 'Duplicate Question',
      moveQuestionUp: 'Move Question Up',
      moveQuestionDown: 'Move Question Down',
      removeTeam: 'Remove Team',
      methodology:
        'Sales methodologies and qualification frameworks the questions are applicable to',
      alreadyImportedQuestion: 'This question has already been imported',
      deleteTag: 'Delete Tag',
      deleteRule: 'Delete Rule',
      removeCondition: 'Remove Condition',
      autoScoringRuleFilterError:
        'Select a condition or delete to save this scorecard',
      autoScoringRuleOperatorError: 'Select an operator for this condition',
      autoScoringRuleOperatorWarning: 'Select a condition to add an operator',
      autoScoringRuleOperatorErrorAndWarning:
        'First select a condition and then an operator',
      autoScoringRuleValueError: 'Add at least one value for this condition',
      autoScoringRuleValueWarning: 'Select an operator to add values',
      autoScoringRuleValueErrorAndWarning:
        'First select an operator and add at least one value for this condition',
      disableAddCondition: 'Complete the condition above to add a new one',
      connectCrm:
        'Please connect your CRM to use this condition for auto-scoring'
    },
    createForm: {
      infoStep: 'Scorecard Information',
      nameFieldTitle: 'Name',
      nameFieldPlaceholder: 'Scorecard Name',
      descriptionFieldTitle: 'Description',
      descriptionFieldPlaceholder: 'e.g. Customer Success',
      scorecardPurposeTitle: 'Conversation Purpose',
      teamsPopoverTitle: 'Teams',
      rulesStep: 'Auto-scoring Rules',
      questionsStep: 'Scorecard Questions',
      addNewQuestion: 'Add New Question',
      maxNumOfQuestions: 'You cannot add more than 25 questions to a scorecard',
      questionAlreadySelected:
        'This question has already been added to the scorecard',
      addNewQuestionMobile: 'Add',
      addCustomQuestion: 'Add Custom Question',
      ratingScale: 'Rating Scale',
      yesNo: 'Yes/No',
      libraryAiQuestion: 'Import from Library',
      libraryAiQuestionSubheading:
        'Choose from a list of pre-defined questions',
      customAiQuestion: 'Add Your Own Question',
      customAiQuestionSubheading: 'Create your own free-form question',
      customAiRatingScaleQuestionSubheading:
        'Create your own rating scale question',
      customAiYesNoQuestionSubheading: 'Create your own yes/no question',
      selectQuestionType: 'Select Question Type',
      questionFieldTitle: 'Question',
      questionFieldRatingScalePlaceholder:
        'How effectively did the rep set the meeting agenda?',
      questionFieldYesNoPlaceholder: 'Did the rep mention next steps?',
      questionFieldCustomAiPlaceholder: `Enter a question to verify if a particular topic or phrase was mentioned. For e.g - Did the rep talk about Salesforce integration?`,
      questionScoringCriteria:
        'Write scoring criteria for each option below so conversations can be objectively scored.',
      scoringFieldPlaceholder1: '\u2022 No agenda was set',
      scoringFieldPlaceholder2: `\u2022 Agenda is unclear and confusing\n\u2022 Purpose of call is unclear or not mentioned`,
      scoringFieldPlaceholder3: `\u2022 Agenda is stated, but feels overly scripted.\n\u2022 Agenda is too long or focused.\n\u2022 Purpose of call is briefly mentioned.`,
      scoringFieldPlaceholder4: `\u2022 Agenda is clear and feels natural.\n\u2022 Flow of call is understood by Contact.\n\u2022 Purpose of call is clear and includes next steps.`,
      scoringFieldPlaceholder5: `\u2022 Agenda is clear, concise, and customer focused–does not take away from flow.\n\u2022 Purpose of call is very clear. Next steps are precise.`,
      scoringFieldPlaceHolderYes: '\u2022 Yes, next steps were stated.',
      scoringFieldPlaceHolderNo: '\u2022 No, next steps were not discusssed.',
      questionsEmptyLive: 'Add at least one question to create this scorecard.',
      questionsEmptyDraft:
        'This scorecard can be saved as a draft without adding any questions.',
      generateAiChoicesLong: 'Generate answers with AI',
      generateAiChoicesShort: 'AI answers',
      questionLibraryTitle: 'Import Questions from Library',
      deselectAll: 'Deselect All Questions',
      searchTags: 'Search tags',
      noTagsFound: 'No tags found',
      questionsTitle: 'Question',
      questionTypeTitle: 'Question Type',
      usedInTitle: 'Used In',
      validQuestionsTitle: 'Examples of Valid Questions',
      validQuestions: 'Valid Questions',
      invalidQuestions: 'Invalid Questions',
      validQuestionsShort: 'Valid questions',
      validQuestionsLong: 'Examples of valid questions',
      ruleExplanation:
        'Automatically score conversations with this scorecard when:',
      addRuleBanner:
        'Add at least one rule to automatically score conversations using this scorecard.',
      ruleTriggerExplanationBanner:
        'Rules will be checked after meetings are processed and any time conversation attributes specified above are updated.',
      conditionExplanation: 'Add at least one condition or delete this rule.',
      addNewRule: 'Rule',
      addNewCondition: '+ Condition',
      addAdditionalCondition: '+ and',
      formErrors: {
        submitError:
          'There was an error creating the scorecard. Please try again.',
        blankNameError: 'This field may not be blank.',
        sameNameError: 'Scorecard with this name already exists.',
        aiError:
          'Unable to generate answer choices. Please update the question and try again.',
        customAiQuestionError:
          'One or more custom questions in this scorecard cannot be assessed by Avoma AI.'
      },
      nameValidation: {
        noName: 'Please enter a name'
      },
      purposeValidation: {
        noPurpose: 'Please select a purpose'
      },
      questionValidation: {
        noName: 'Please enter a question',
        noScore: 'Please enter scoring criteria for each choice'
      },
      selectConditionPlaceholder: 'Select Condition',
      selectOperatorPlaceholder: 'Select Operator',
      selectValuePlaceholder: 'Select Value'
    },
    readOnly:
      'This scorecard can be duplicated or used for scoring but cannot be edited or deleted',
    readOnlyAi:
      'This scorecard can be used to manually request an AI score but cannot be edited or deleted',
    templateNotFound: 'Scorecard not found',
    createTemplateModal: {
      confirm: 'Yes, Create',
      cancel: 'Cancel',
      title: 'Create',
      text: 'Once created, this scorecard will be available to your organization to use for scoring.',
      error:
        'Please complete/correct the required fields to create this scorecard.'
    },
    updateLiveTemplateModal: {
      confirm: 'Yes, Update',
      cancel: 'Cancel',
      title: 'Update',
      error:
        'Please complete or correct the required fields to update this scorecard.'
    },
    updateTemplateStateModal: {
      confirm: 'Yes, Disable',
      cancel: 'Cancel',
      title: 'Disable this scorecard?',
      text: 'Once disabled, this scorecard will no longer be available to your organization to use for scoring. You can enable this scorecard again at anytime in the future.'
    },
    updateAiTemplateStateModal: {
      confirm: 'Yes, Disable',
      cancel: 'Cancel',
      title: 'Disable this AI scorecard?',
      text: 'Once disabled, this scorecard will no longer be used for auto-scoring, even if purposes are assigned.\n\nFurthermore, this scorecard will also no longer be available for manually requesting an AI score.\n\nYou can enable this scorecard again at anytime in the future.'
    },
    deleteTemplateModal: {
      confirm: 'Yes, Delete',
      cancel: 'Cancel',
      title: 'Delete',
      text: 'Once deleted, you will not be able to recover this scorecard.',
      hasBeenUsedForScoringText:
        'Once deleted, you will not be able to recover this scorecard. All associated scores for conversations will also be removed and cannot be recovered.\n\nDo you wish to proceed?'
    },
    cancelTemplateModal: {
      confirmText: 'Close Without Saving',
      cancelText: 'Continue Editing',
      createTitle: 'Cancel creating a new scorecard without saving?',
      editTitle: 'Cancel editing scorecard without saving?',
      canelWithoutSaving:
        'You have unsaved changes. If you proceed without saving, the changes will be lost and you will not be able to recover them.'
    },
    deleteQuestionModal: {
      confirm: 'Yes, Delete',
      title: 'Delete',
      text: 'Once deleted, you will not be able to recover this question or answer choices. Do you wish to continue?'
    },
    updateQuestionChoicesModal: {
      confirm: 'Yes, Generate',
      title: 'Generate answer choices with AI?',
      text: 'This will overwrite all existing answer choices with answer choices generated by AI. Do you wish to continue?'
    },
    deleteQuestionTagModal: {
      confirm: 'Yes, Delete',
      title: 'Delete Tag?',
      text: 'Once deleted, this tag will not be available for future use. However, it will still remain assigned to questions previously tagged with it.',
      additionalText: 'Do you wish to continue?'
    },
    deleteRuleModal: {
      confirm: 'Yes, Delete',
      title: 'Delete Rule?',
      text: 'Once deleted, you will not be able to recover this rule or any of the defined conditions.',
      additionalText: 'Do you wish to continue?'
    },
    toastMessage: {
      createSuccess: 'Successfully created',
      createError: 'There was an error creating this scorecard',
      editSuccess: 'Successfully edited',
      editError: 'There was an error editing this scorecard',
      editPurposeSuccess: 'Successfully updated scorecard purpose',
      editPurposeError: 'There was an error updating this scorecard',
      editTeamError: 'There was an error updating this scorecard',
      editStateEnableSuccess: 'Successfully enabled scorecard',
      editStateDisableSuccess: 'Successfully disabled scorecard',
      duplicateSuccess: 'Successfully duplicated',
      duplicateError: 'There was an error duplicating this scorecard',
      deleteSuccess: 'Successfully deleted',
      deleteError: 'There was an error deleting this scorecard'
    }
  },
  crmIntegrated: {
    title: 'CRM Integration',
    settingsTitle: 'CRM Settings',
    connectedToSalesforce: 'Connected to Salesforce',
    connectedToHubspot: 'Connected to HubSpot',
    connectedToPipedrive: 'Connected to Pipedrive',
    connectedToZendesksell: 'Connected to Zendesk Sell',
    connectedToCopper: 'Connected to Copper',
    connectedToZoho: 'Connected to Zoho',
    connectedToFreshsales: 'Connected to Freshsales',
    salesforceLoginSuccess: 'Salesforce Successfully Connected',
    salesforceLoginFailure:
      'There was a problem connecting your Salesforce account',
    pipedriveLoginSuccess: 'Pipedrive Successfully Connected',
    pipedriveLoginFailure:
      'There was a problem connecting your Pipedrive account',
    zohoLoginSuccess: 'Zoho Successfully Connected',
    zohoLoginFailure: 'There was a problem connecting your Zoho account',
    hubspotLoginSuccess: 'HubSpot Successfully Connected',
    hubspotLoginFailure: 'There was a problem connecting your HubSpot account',
    breadcrumb: 'CRM',
    setCrmSettingsFailure: 'There was a problem updating your CRM settings',
    salesforceLoginUnauthorized:
      'You need to be a System Administrator in Salesforce to be able to connect to Avoma'
  },
  hubspotCard: {
    title: 'HubSpot Integration',
    description:
      'If you are a HubSpot Super Admin, connect Avoma to HubSpot. Connecting your HubSpot account will allow you to:',
    point1: 'Log your meeting activities to appropriate HubSpot records',
    point2: 'Sync your Notes to appropriate records for timely data-entry',
    connectToHubspot: 'Connect to HubSpot'
  },
  pipedriveCard: {
    title: 'Pipedrive Integration',
    description:
      'If you are a Pipedrive Admin, connect Avoma to Pipedrive. Connecting your Pipedrive account will allow you to:',
    point1: 'Log your meeting activities to appropriate Pipedrive records',
    point2: 'Sync your Notes to appropriate records for timely data-entry',
    connectToPipedrive: 'Connect to Pipedrive'
  },
  zohoCard: {
    title: 'Zoho Integration',
    description:
      'If you are a Zoho Admin, connect Avoma to Zoho. Connecting your Zoho account will allow you to:',
    point1: 'Log your meeting activities to appropriate Zoho records',
    point2: 'Sync your Notes to appropriate records for timely data-entry',
    connectToZoho: 'Connect to Zoho'
  },
  freshsalesCard: {
    title: 'Freshsales Integration',
    description:
      'If you are a Freshsales Admin, connect Avoma to Freshsales. Connecting your Freshsales account will allow you to:',
    point1: 'Log your meeting activities to appropriate Freshsales records',
    point2: 'Sync your Notes to appropriate records for timely data-entry',
    connectToPipedrive: 'Connect to Freshsales'
  },
  copperCard: {
    title: 'Copper Integration',
    description: 'Connecting your Copper account will allow you to:',
    point1: 'Log your meeting activities to appropriate records in Copper',
    point2: 'Sync your Notes to appropriate records for timely data-entry',
    connectToCopper: 'Contact us to enable integration'
  },
  zoomIntegration: {
    title: 'Zoom Integration',
    connect: 'Connect',
    connected: 'Connected to Zoom',
    setupTitle: 'Configure Meeting Recording Options',
    setupDescription:
      'You can set up your recording options for both your Zoom meetings and meetings from other conferencing solutions in the Recording Settings section',
    setup: 'Set up your Recording Settings Now',
    zoomAccountConnectedNoCloudRecording:
      'Your Zoom account is connected to Avoma. However, it looks like Cloud Recording is disabled or not available.',
    disconnect: 'Disconnect Zoom',
    point1: 'Organize and access all your Zoom meeting recordings in one place',
    point2:
      'Seamlessly analyze your Zoom calls without requiring the Avoma Bot to join the meeting',
    success: 'Zoom was successfully connected!',
    errorGeneric: 'Error linking your Zoom account',
    errorBadRequest:
      'The Zoom account used is already linked with another Avoma account.',
    unlinkTitle: 'Disconnect Zoom account from Avoma',
    unlinkText:
      'Are you sure you want to disconnect and unlink your Zoom account from Avoma?',
    unlinkAdditionalText:
      'With this, you will no longer be able to have Avoma process your Zoom Cloud Recordings.',
    yes: 'Yes',
    no: 'No',
    recordWithZoomPrompt:
      'Would you like to record your meetings with Zoom instead of Avoma?',
    recordWithZoomSubtitle: '*Most people choose “Yes” to record with Zoom'
  },
  aircallIntegration: {
    title: 'Aircall',
    subtitle:
      'Please note that admins can connect to the dialer but the calls will be processed only if call owners have a premium or higher plan within Avoma.',
    connect: 'Connect to Aircall',
    connected: 'Connected to Aircall',
    aircallAccountConnectedNoCloudRecording:
      'Your Aircall account is connected to Avoma. However, it looks like Cloud Recording is disabled or not available.',
    disconnect: 'Disconnect Aircall',
    intro: 'Connect your Aircall account to Avoma to:',
    point1: 'Access all your Aircall call recordings in one place.',
    point2:
      'Analyze your calls and get Transcript, Notes, Conversation Insights, and more.',
    errorGeneric: 'Error linking your Aircall account',
    errorBadRequest:
      'The Aircall account used is already linked with another Avoma account.',
    unlinkTitle: 'Disconnect Aircall account from Avoma',
    unlinkText:
      'If you no longer wish to have Avoma process your Aircall Cloud Recordings, click Confirm to disconnect your accounts.',
    yes: 'Yes',
    no: 'No',
    settings: 'Aircall Settings',
    syncTags: 'Sync Aircall Tags',
    syncTagsDescription: "Sync Avoma's automatically generated tags to Aircall",
    info: 'Please note that admins can connect to the dialer but the calls will be processed only if call owners have a premium or higher plan.',
    contactAdmin:
      "Please contact your administrator to connect your organization's Aircall account.",
    helpLink: 'https://help.avoma.com/avoma-integration-with-aircall'
  },
  salesloftIntegration: {
    title: 'Salesloft Integration',
    settingsTitle: 'Salesloft',
    connect: 'Connect to Salesloft',
    connected: 'Salesloft is now connected to your Avoma Account.',
    disconnect: 'Disconnect Salesloft',
    intro: 'Connect your Salesloft account to Avoma to:',
    point1: 'Access all your Salesloft call recordings in one place.',
    point2:
      'Analyze your calls and get Transcript, Notes, Conversation Insights, and more.',
    success: 'Salesloft successfully connected!',
    errorGeneric: 'There was a problem linking your Salesloft account',
    errorBadRequest:
      'The Salesloft account used is already linked with another Avoma account.',
    unlinkTitle: 'Disconnect Salesloft account from Avoma',
    unlinkCta: 'Unlink',
    unlinkText: 'Avoma will stop processing your Salesloft call recordings.',
    helpLink: 'https://help.avoma.com/avoma-integration-with-salesloft'
  },
  kixieIntegration: {
    title: 'Kixie Integration',
    settingsTitle: 'Kixie',
    connected_text_for_member: 'Connected to Kixie',
    confirmDisconnect: 'Disconnect',
    connected:
      'Please follow step by step instructions to setup your account in Kixie, Please ignore if already done.',
    connectCta: 'Connect to Kixie',
    disconnect: 'Disconnect Kixie',
    generateUrl: 'Generate Webhook URL',
    intro: 'Connect your Kixie account to Avoma to:',
    point1: 'Access all your Kixie call recordings in one place.',
    point2:
      'Analyze your calls and get Transcript, Notes, Conversation Insights, and more.',
    unlinkTitle: 'Disconnect Kixie',
    unlinkText:
      'This would disconnect all the connected members from Kixie. You would required to complete the integration process again for reconnection! \n\nDo you want to disconnect Kixie for all the members?',
    helpTooltip: 'Step-by-step instructions for Kixie integration',
    helpDocUrl: 'https://help.avoma.com/avoma-integration-with-kixie',
    contactAdmin:
      "Please contact your administrator to connect your organization's Kixie account.",
    connectedMsg:
      'Kixie is now connected to your Avoma Account. You can copy below link to reconnect Kixie if needed.'
  },
  koncertIntegration: {
    title: 'Koncert Integration',
    connectCta: 'Connect to Koncert',
    disconnect: 'Disconnect Koncert',
    intro: 'Connect your Koncert account to Avoma to:',
    point1: 'Access all your Koncert call recordings in one place.',
    point2:
      'Analyze your calls and get Transcript, Notes, Conversation Insights, and more.',
    success: 'Koncert was successfully connected!',
    errorGeneric: 'There was a problem linking your Koncert account',
    errorBadRequest:
      'The Koncert account used is already linked with another Avoma account.',
    connected: 'Connected to Koncert.',
    unlinkTitle: 'Unlink Koncert account from Avoma',
    unlinkCta: 'Unlink',
    unlinkText: 'Avoma will stop processing your Koncert call recordings.'
  },
  ringCentralIntegration: {
    title: 'RingCentral Integration',
    settingsTitle: 'RingCentral',
    connect: 'Connect to RingCentral',
    connected: 'RingCentral is now connected to your Avoma Account.',
    disconnect: 'Disconnect RingCentral',
    intro: 'Connect your RingCentral account to Avoma to:',
    point1: 'Access all your RingCentral call recordings in one place.',
    point2:
      'Analyze your calls and get Transcript, Notes, Conversation Insights, and more.',
    success: 'RingCentral was successfully connected!',
    errorGeneric: 'There was a problem linking your RingCentral account',
    errorBadRequest:
      'The RingCentral account used is already linked with another Avoma account.',
    unlinkTitle: 'Disconnect RingCentral account from Avoma',
    unlinkCta: 'Unlink',
    unlinkText: 'Avoma will stop processing your RingCentral call recordings.',
    helpLink: 'https://help.avoma.com/avoma-integration-with-ringcentral'
  },
  zoomPhoneIntegration: {
    settingsTitle: 'Zoom Phone',
    title: 'Zoom Phone Integration',
    connect: 'Connect to Zoom Phone',
    connected:
      'Once connected to your ZoomPhone account, please enable auto recording from your zoom account so that calls will be recorded automatically.',
    disconnect: 'Disconnect Zoom Phone',
    intro: 'Connect your Zoom Phone account to Avoma to:',
    info: 'Once connected your ZoomPhone account, please enable auto recording from your zoom account so that calls will be recorded automatically.',
    point1: 'Access all your Zoom Phone call recordings in one place.',
    point2:
      'Analyze your calls and get Transcript, Notes, Conversation Insights, and more.',
    success: 'Zoom Phone was successfully connected!',
    errorGeneric: 'There was a problem linking your Zoom Phone account',
    errorBadRequest:
      'The Zoom Phone account used is already linked with another Avoma account.',
    unlinkTitle: 'Disconnect Zoom Phone account from Avoma',
    unlinkCta: 'Unlink',
    unlinkText: 'Avoma will stop processing your Zoom Phone call recordings.',
    adminWarning:
      "Please contact your administrator to connect your organization's Zoom Phone account",
    helpLink: 'https://help.avoma.com/avoma-integration-with-zoom-phone',
    contactAdmin:
      "Please contact your administrator to connect your organization's Zoom Phone account."
  },
  zoomSdkIntegration: {
    title: 'Zoom Sdk Integration',
    connectCta: 'Provide Local Recording Token',
    disconnect: 'Revoke Local Recording Token',
    intro:
      'Allow Avoma Assistant to fetch Local Recording Token from your Zoom Meeting Sdk account to:',
    // info: 'Once connected your Zoom Meeting Sdk account, please enable auto recording from your zoom account so that calls will be recorded automatically.',
    // point1: 'Access all your Zoom Meeting Sdk call recordings in one place.',
    // point2:
    // 'Analyze your calls and get Transcript, Notes, Conversation Insights, and more.',
    success: 'Avoma Assistant is successfully connected to your zoom account',
    errorGeneric: 'There was a problem linking your Zoom Meeting Sdk account',
    errorBadRequest:
      'The Zoom Meeting Sdk account used is already linked with another Avoma account.',
    connected: 'Connected to Zoom Meeting Sdk.',
    unlinkTitle: 'Revoke Local Recording Token',
    unlinkCta: 'Unlink',
    unlinkText:
      'Avoma will stop passing Local Recording Token to you Avoma Assistant.',
    adminWarning:
      "Please contact your administrator to connect your organization's Zoom Meeting Sdk account"
  },
  slackIntegration: {
    goto: 'Go to',
    integrationMsg1: 'to connect with Slack for all members.',
    integrations: 'Integrations',
    title: 'Slack Integration',
    visit: 'Visit our',
    helpCenter: 'Help Center',
    helpCenterLink:
      'https://help.avoma.com/viewing-alerts-through-the-slack-integration',
    connectCta: 'Connect Slack',
    disconnectCta: 'Disconnect Slack',
    errorGeneric: 'There was a problem linking your Slack account',
    success: 'Slack was successfully connected!',
    adminBasicLicenseMsg: 'Purchase License to set instant Slack alerts.',
    userBasicLicenseMsg:
      'Contact Admin to purchase License to set instant Slack alerts.',
    notConnectedUserMsg:
      'Contact Admin to connect with Slack to set instant alerts',
    integrationInfoMsg:
      'Connect your Slack account so members can set instant alerts for saved views to selected channels.',
    disconnectSlackIntegration: 'Disconnect Slack Integration',
    disconnectSlackIntegrationDetails:
      'Once disconnected, members of your organization will not be able to set up alerts to Slack channels.',
    slackShare: 'Share to Slack'
  },
  clickupIntegration: {
    title: 'ClickUp',
    subtitle:
      'Connect your ClickUp account so members can set automations with ClickUp.',
    disconnectCta: 'Disconnect ClickUp',
    connectCta: 'Connect ClickUp',
    configureCta: 'Configure',
    errorGeneric: 'There was a problem linking your ClickUp account',
    success: 'ClickUp was successfully connected!',
    disconnectClickupIntegration: 'Disconnect ClickUp Integration',
    disconnectClickupIntegrationDetails:
      'Once disconnected, members of your organization will not be able to set up automations for ClickUp.',
    selectDays: 'Select Days',
    dueDateHelpText: 'Select no of days required to complete the task.',
    daysSelectPlaceholder: 'Select no of days in future',
    selectTemplate: 'Select Template',
    selectTemplatePlaceholder: 'Select a task template',
    workspaceTooltip: 'Please select a workspace first',
    deleteAction: 'Delete action',
    taskDescription: 'Task description',
    templateBannerText:
      "Fields like due date, description and assignee will be followed as per the preferences defined in selected ClickUp template. Hence you can't select them manually.",
    createTicketSuccess: 'ClickUp task created successfully',
    createTicketError: 'Error occurred while creating ClickUp task',
    createTicketInProgress: 'ClickUp task is being created..',
    createDialogTitle: 'Create New ClickUp Task',
    taskTitleLabel: 'Task title',
    taskTitlePlaceholder: 'Enter task title',
    workspace: 'Workspace',
    template: 'Template',
    assignees: 'Assignees',
    preferenceLabel: 'Fill the last saved preferences',
    daysInFuture: 'Select Days in Future',
    calendarDate: 'Calendar Date',
    selectDate: 'Select date',
    dueDate: 'Due date',
    hoverButtonLabel: 'Create clickup task',
    hoverButtonTooltip: 'Create clickup task from scratch',
    instantTaskLabel: 'Instant Clickup Task',
    instantTaskTooltip:
      'Task will be created based on the preferences of the previous task created for this meeting.',
    searchPlaceholder: 'Search Spaces and Lists',
    list: 'List',
    selectList: 'Select List',
    upgradeTitle: `Upgrade to ${PLAN_NAMES.lite} plan or above to create tasks in ClickUp`,
    listSyncSuccess: "Syncing lists from ClickUp. It'll take a few minutes.",
    listSyncFailure:
      'Failed to sync lists from ClickUp, please try again later.',
    selectEventLabel: 'Select trigger event',
    trigger: 'Trigger',
    eventLockedTooltip: 'This event is locked',
    searchPlaceholderEvents: 'Search event triggers',
    eventPopoverTitle: 'Triggers with Smart category'
  },
  hubspotTasks: {
    createDialogTitle: 'Create New HubSpot Task',
    hoverButtonLabel: 'Create HubSpot task',
    hoverButtonTooltip: 'Create HubSpot task from scratch'
  },
  automations: {
    addAction: 'Add Action',
    action: 'Action',
    create: 'Create',
    createNewAutomation: 'Create New Automation',
    automationNameLabel: 'Automation Name',
    fillRequiredFields: 'Please fill in all the required fields',
    infoBanner: `By default, this automation applies to all conversations, no matter their privacy level. To limit it to specific access levels, add a 'privacy' filter.`,
    automationNamePlaceholder: 'Create a task in ClickUp for each action item',
    includeConversationsWithAccessPermissions:
      'Include conversations with the following access permissions',
    disabledTooltip: {
      [EAutomationActionType.CLICKUP_TASK]:
        'Connect ClickUp in Settings > Integrations to automate Clickup tasks creation.',
      [EAutomationActionType.HUBSPOT_TASK]:
        'Connect HubSpot in Settings > CRM to automate HubSpot tasks creation.'
    },
    openIn: {
      clickup: 'View ticket in ClickUp',
      hubspot: 'View task in HubSpot'
    }
  },
  outreach: {
    settingsTitle: 'Outreach',
    intro: 'Connect your Outreach account to Avoma to:',
    item1: 'Automatically transcribe your recorded calls.',
    item2:
      'Capture key conversation insights such as speaker breakdown and talk-to-listen ratio.',
    item3: 'Extract automated meeting notes including Next Steps and more.',
    connect: 'Connect to Outreach',
    connected: 'Outreach is now connected to your Avoma Account.',
    contactUs: 'Contact us',
    disconnectOutreach: 'Disconnect Outreach',
    unlinkTitle: 'Disconnect Outreach account from Avoma',
    unlinkCta: 'Unlink',
    unlinkText: 'Avoma will stop processing your Outreach call recordings.',
    failedToDisconnect: 'Failed to disconnect Outreach',
    helpLink: 'https://help.avoma.com/integration-with-outreach'
  },
  crm: {
    configure: 'Configure',
    viewProperties: 'View Properties',
    fieldMappingFaq: 'CRM Field Mapping FAQs',
    connectCrm: 'Connect CRM to sync notes',
    reauthMessage:
      'CRM Authentication token has expired. Re-authenticate to continue syncing of notes to CRM',
    selectToSyncTooltip: 'Select ',
    selectToSyncTooltip2: 'to sync note',
    syncNoteTooltip: 'Sync notes to',
    syncingCta: 'Syncing',
    syncCta: 'Sync',
    synced: 'Synced',
    lastSyncTime: 'Last synced',
    notesNotSynced: 'Notes are not synced',
    notesNotSyncedTooltip:
      'Notes are not automatically saved to CRM records since this meeting is Private. Click on Sync button to start syncing notes automatically.',
    syncNoteSuccess: 'Successfully synced note to',
    licenseToSyncNotesRequired: `CRM connected but you need a ${PLAN_NAMES.lite} plan or above to sync notes.`,
    syncNoteFailure: 'There was a problem syncing note to',
    syncNoteFailureSalesforce:
      'In order to sync notes to any of your CRM records or objects please enable enhanced notes in your Salesforce account first.',
    autoSyncSettingsTitle: 'Automatically Sync Notes to notes object',
    autoSyncSettingsSubtitle1: 'Automatically sync notes to ',
    autoSyncSettingsSubtitle2:
      ' records after AI extracts notes and also when you make any edits.',
    autoSyncEventSettingsTitle: 'Automatically Sync Notes to event object',
    autoSyncEventSettingsSubtitle1:
      'Notes will be saved into Event object under Description field.  This will also be visible under Activity Timeline (Feed) for the respective records.',
    autoSyncTaskSettingsTitle: 'Automatically Sync Notes to task object',
    autoSyncTaskSettingsSubtitle:
      'Notes will be saved into Task object under comments field. This will also be visible under Activity Timeline (Feed) for the respective records.',
    autoSyncEventSettingsInfo:
      'By default, Avoma saves notes to Salesforce’s Enhanced Notes object associated to all appropriate records.',
    reAuthAutoSettingsTitle: 'Enable Auto Sync Email',
    reAuthAutoSyncSettingsSubtitle1: 'Automatically sync emails from ',
    reAuthAutoSyncSettingsSubtitle2: 'on update.',
    syncNotesButtonLabel: 'Sync',
    salesforce: {
      name: 'Salesforce',
      lead: 'Lead',
      account: 'Account',
      contact: 'Contact',
      opportunity: 'Opportunity'
    },
    hubspot: {
      name: 'HubSpot',
      lead: 'Contact',
      account: 'Company',
      opportunity: 'Deal'
    },
    pipedrive: {
      name: 'Pipedrive',
      lead: 'Person',
      account: 'Organization',
      opportunity: 'Deal'
    },
    zoho: {
      name: 'Zoho',
      lead: 'Lead',
      account: 'Account',
      opportunity: 'Deal'
    },
    freshsales: {
      name: 'Freshsales',
      lead: 'Lead',
      account: 'Account',
      opportunity: 'Deal'
    },
    zendesksell: {
      name: 'Zendesk Sell',
      lead: 'Lead',
      account: 'Company',
      opportunity: 'Deal'
    },
    // ! Temporary fix for accessing static crmProvider
    zsell: {
      name: 'Zendesk Sell',
      lead: 'Lead',
      account: 'Company',
      opportunity: 'Deal'
    },
    copper: {
      name: 'Copper',
      lead: 'Lead',
      account: 'Company',
      opportunity: 'Opportunity'
    },
    showClosedDeals: 'Show Closed Deals',
    closed: 'Closed',
    noDealsMessage: 'No deals.',
    noActiveDealsMessage: 'No active deals.',
    connectBenefits: [
      'Avoma will log your meeting activities to appropriate CRM records.',
      'Map CRM properties to Avoma Smart categories and properties to automatically update your CRM data',
      'Sync your Notes to appropriate records for timely data-entry.',
      'You can only connect 1 CRM at the moment.'
    ],
    crmSettingsTitle: {
      noConnected: 'Connect your CRM into Avoma',
      connected: 'Connected CRM'
    },
    onlyAdminsCanConnect: 'You need to be an admin to connect CRM',
    disconnectCrmPoint: 'To disconnect the CRM, please contact support.',
    onlySuperAdminConnect:
      'Only superadmin needs to connect and other members can access the data.',
    onlyAdminConnect:
      'Only admin needs to connect and other members can access the data.',
    individualUserConnect:
      'Individual users need to connect to access the data.',
    salesforceConnectNotification:
      'Someone from your organization has connected their account to Salesforce, connect to sync your data.',
    connectButtonDisabledTooltip: 'You can only connect 1 CRM at the moment',
    licenseFeatureGate: `Requires a ${PLAN_NAMES.premium} License.`,
    notSupportedPropertiesTooltip:
      'Some field formats like calculated fields are not supported for import.',
    allFieldsNotSupported: crmProvider =>
      `All fields from ${crmProvider} are NOT shown here.`,
    crmPropertyTooltip:
      'Properties or fields present in your CRM that you’ve imported.',
    avomaPropertyTooltip:
      'Select Avoma smart categories or properties to map to the CRM properties.',
    syncDirectionTooltip:
      'Set how properties can sync between your CRM and Avoma.',
    editAccessTooltip:
      'Set permissions to edit the properties from Avoma. This does not affect the edit access permissions in your CRM.',
    syncSettingsTooltip:
      'Set permissions to edit the properties from Avoma. This does not affect the edit access permissions in your CRM.',
    importedByTooltip:
      'User who imported the property in Avoma. Properties imported by Avoma are the default properties.',
    deleteCrmPropertyText:
      'Are you sure you want to remove selected property? This will remove them completely from Avoma and all data will be lost. You can import them back later without historical data.',
    deleteCrmPropertyTitle: 'Remove property?',
    mapAvomaPropertyTooltip:
      'Avoma properties extract the data related to your customer conversations. When you map CRM properties to Avoma Property, we will sync the extracted data to the CRM field.',
    mapSmartCategoryTooltip:
      'Smart Categories extract the smart notes based on Bookmarks, Trigger prompts and Keywords.When you map CRM properties to smart category, we will sync the extracted smart notes to the CRM field.',
    alreadyMappedPropertyTooltip:
      'This property is already mapped to an existing field',
    cannotMapToDefault:
      'Avoma flags this property as sensitive and prevents mapping it to a smart category to avoid unintended automatic updates',
    cannotMapToReadOnly:
      '‘Read-only’ properties cannot be mapped to an Avoma property or smart category.',
    mapAvomaProperty: 'Map Avoma Property',
    mapSmartCategory: 'Map Smart Topic',
    confirmPropertyMapping: 'Confirm Property Mapping',
    mapProperty: 'Map Property',
    propertyMappingConfimationModalTitle:
      'When you map a multi-select checkbox CRM field to an Avoma Smart Category, Avoma will:',
    propertyMappingConfimationModalBody: {
      firstPara:
        'Import all the values/keywords that you have configured for your CRM field.',
      secondPara:
        'Start monitoring your customer conversations for mentions of those keywords.'
    },
    propertyMappingConfimationModalExample:
      "let's say you have mapped the 'Competitor' CRM field to the 'Competitor' Smart Category on Avoma and 'ACME CORP' is one of your competitors. When Avoma detects a mention of 'ACME CORP' in your customer conversations, Avoma will automatically check/select 'ACME CORP'. This will be synced with your CRM.",
    multiSelectMappingExample:
      'If you have a multi-select checkbox CRM property named Competitors with values such as Zoom, WebEx, Google Meet, and if Avoma detects these keywords from Avoma smart category during conversations, the values in your CRM property dropdown will be selected automatically.',
    viewExample: 'View Example',
    example: 'Example',
    recordCreation: {
      header: 'CRM Record Settings',
      drawerHeader: 'Learn how additional rules work',
      autoCreateRecordTitle:
        'Automatically Create CRM Records for all Meetings with External Participants',
      createContactRecordTitle: 'Create Contact',
      createLeadRecordTitle: 'Create Lead',
      selectMeetingPurposeTitle: 'Select meeting purpose',
      selectMeetingPurposeSubtitle:
        'Avoma will only create CRM records for below meeting purposes. By default, it will be shown to all purposes if none selected.',
      selectTeamTitle: 'Select team',
      selectTeamSubtitle:
        'Avoma will only create CRM records for below teams. By default, it will be shown to all teams if none selected.',
      deleteRecordsTitle: 'Delete Records',
      deleteRecordsSubtitle:
        'Set records deletion rules for the meetings, If a meeting is deleted in the Google/Outlook calendar.',
      removeAllPurposesTitle: 'Remove all selected purposes?',
      removeAllPurposesSubtitle:
        "Removing all purposes will create CRM records for meetings of member's from all purposes, are you sure you want to remove all selected purposes?",
      removeAllTeamsTitle: 'Remove all selected teams?',
      removeAllTeamsSubtitle:
        "Removing all teams will create CRM records for meetings of member's from all teams, are you sure you want to remove all selected teams?",
      upgradeBannerText: `Please upgrade to a ${PLAN_NAMES.plus} License, to enable 'CRM Record Creation'`
    },
    configureRiskScore: 'Configure Risk Score',
    cantFindProperty: "Can't find the CRM property you're looking for?",
    importHere: 'Import Here',
    pleaseConnectYourCRM: 'Please connect your CRM',
    crmSessionExpired: 'CRM session expired',
    tokenTimeoutMessage:
      'This can happen due to token timeouts or password changes with your account.',
    noteSyncSettingsApplicationOnlyForAvoma:
      'Note sync settings below are applicable only for your Avoma account. Organization level note sync settings coming soon.',
    crmPropertyMappingColInfoTooltip:
      'Import standard or custom CRM fields and map them with Avoma properties to update the part of oma AI notes, other AI insights to CRM fields.',
    comingSoonTooltip:
      'You can import CRM properties from this object Avoma but property mapping is not supported for this project.',
    crmPropertyMappingSupported: 'CRM property mapping supported.',
    youCanImportCRMPropertiesMappingComingSoon:
      'You can import CRM properties for this object. CRM property mapping coming soon.',
    crmPropertyMapping: 'Update Avoma data to CRM fields'
  },
  customCategory: {
    stepByStep: 'Step-by-step',
    howItWorks: 'Show how it works?',
    emptyTitle: 'You have not created any custom categories yet!',
    emptyHelperText:
      'With Custom Categories, define your own trigger prompts and keywords to extract personalized notes that perfectly align with your needs.',
    error: 'Error updating Smart Category',
    updateError: 'Smart Category with this name already exists.',
    descriptionUpdateError: 'An error occurred while updating the description.',
    breadcrumb: 'Smart Categories',
    defaultAiCategories: 'Default AI Categories',
    customCategories: 'Custom Categories',
    fillerWords: 'Filler Words',
    addFillerWordsTitle: 'Add Filler Words to track trends',
    title: 'Smart Categories',
    expandCategorySidebar: 'Expand Category Sidebar',
    collapseCategorySidebar: 'Collapse Category Sidebar',
    keywordsPlaceholder: 'e.g. Email, Text Messaging, Phone Call',
    fillerWordsPlaceholder: 'e.g. You know, You see, Literally',
    fillerWordsBanner: 'Trends for Filler Words will be shown in the',
    viewAndEditPhrase: 'Click to view & edit phrase',
    addPhraseForSmartMatch: `Add phrase for "smart" match`,
    enterPhraseTitle: 'Enter a short phrase',
    enterExampleSentenceTitle:
      'Provide an example sentence from real conversations that reflects the idea of this phrase',
    saveTrackerToolip: 'Please enter a phrase and at least 5 example sentences',
    enterExampleSentencesTitle: `Add more example sentences`,
    exampleSentencesPopoverText:
      'Please add a total of at least 5 example sentences that reflect the phrase above. The more examples you provide, the better Avoma AI will understand the context of the phrase.',
    phrasesPlaceholder:
      'Enter a short phrase that denotes the idea you want to track. e.g. shorten sales cycle',
    exampleSentencePlaceholder: 'Example sentence',
    phrasesExampleSentencesInfo:
      'Use real sentences from conversations that reflect the idea of the phrase you want to track.',
    exampleSentencesTitle: 'Example Sentences',
    addExampleSentence: 'Add example sentence',
    addExampleSentences: 'Add example sentences',
    generateMoreExamplesWithAi: 'Generate more examples with AI',
    generateExamplesWithAiDisabledTooltip:
      'Please provide an example sentence above to generate more examples',
    maxExamplesReachedTooltip:
      'You have reached the maximum limit of 20 example sentences',
    generate: 'Generate',
    overrideExampleSentencesTitle: 'Override existing sentences?',
    descriptionCard:
      'Set up custom categories to extract Notes and track Keywords',
    categorySectionTitle: 'Note Category Details',
    augNotesSectionTitle: 'Augmented Notes',
    keywordsSectionTitle: 'Keyword Tracking',
    addCategoryCta: 'Add Custom Category',
    newCategory: 'New Category',
    newDescription: 'Add a description',
    categoryName: 'Category Name',
    colorPickerLabel: 'Select a Color',
    colorPickerDefaultLabel: 'Default Color',
    colorPickerDefaultTooltip: 'Default color cannot be modified',
    enterKeywordsTitle: 'Add keywords for "exact" match',
    enableNoteTaking: 'Extract Keyword mentions as Smart Notes',
    enableAugmentedNotesSubtitle:
      'This category will be shown as an option for "Live Bookmarks" during your meetings and ' +
      'in the "Add to Notes" menu when highlighting transcripts after meetings.',
    enableNoteTakingSubtitle:
      'Extract keywords from the transcript as smart notes based on keyword mention.',
    trackKeywordsAndPhrasesLabel:
      'Capture mentions of the specified keywords and phrases in notes',
    trackKeywordsLabel: 'Capture mentions of the specified keywords in notes',
    trackTrendsInfo:
      'Trends for Keywords and Phrases will be shown in the Trackers dashboard',
    enableAugmentedNotesLabel:
      'Enable this category for Bookmark and Highlight based Smart Notes',
    selectMeetingTypes: 'Select meeting purpose',
    selectMeetingTypesSubtitle:
      'Avoma will only extract notes from those conversation purposes',
    selectMeetingTypeInput: 'Select Purpose',
    noMeetingTypes: 'No Conversation purposes',
    clearMeetingTypes: 'Clear all',
    clearMeetingTypesDropdown: 'Clear',
    deleteCategoryButton: 'Delete Category',
    cancelButton: 'Cancel',
    saveChanges: 'Save Changes',
    empty: 'No Custom Keyword Categories',
    noNameError: 'Please enter a Category Name',
    extractNotesDisabledMessage:
      'Automatic extracting notes is disabled, Avoma will not track notes for this category',
    extractNotesEnabledMessage:
      'Avoma will automatically extract notes for note-taking',
    extractNotesWithoutMeetingTypesMessage:
      'Enabling note-taking without adding meeting type has no effect',
    augNotesSuccessMessage:
      'This category will show up as a "Bookmark" and "Add to Notes" option.',
    keywordsPopoverTitle: 'Keywords to Notes',
    keywordsPopoverDesc:
      'These notes are extracted only when the prospect & clients talks about the keywords.',
    deleteKeyword: 'Delete keyword',
    deleteKeywordWithVariations: 'Delete keyword and its variations',
    addMoreVariations: 'Add keyword variations',
    keywordVariations: 'Keyword Variations',
    addNewVariation: 'Add new variation',
    variationExists: 'Variation already exists.',
    primaryKeyword: 'Primary',
    makeItPrimary: 'Make it Primary',
    smartCategories: 'Smart Categories',
    addTriggerPromptTitle: 'Add trigger prompt phrases to detect topics',
    existingTriggerPromptTitle: 'Phrases as Trigger Prompt',
    triggerPromptsSubtitle:
      'The trigger prompt appears in the note editor as a cue for your users. When Avoma AI recognizes this prompt, relevant notes will be extracted from the surrounding text',
    keywordsMatchSubtitle:
      'Avoma AI will extract exact matches of these keywords from the transcript',
    phraseMatchSubtitle:
      'Avoma AI will find and extract sentences from the transcript that have the same meaning as the phrase you specify',
    addTriggerPromptVariationsBanner:
      'You can add more variations to this trigger prompt',
    triggerPromptsPlaceholder: 'e.g. What features are you interested in?',
    enableTriggerPromptForNotes:
      'Extract Smart Notes based on Trigger Prompt mention in the Transcript',
    enableTriggerPromptForNotesSubtitle:
      'Extract brief text from the transcript as smart notes based on trigger prompt mention ',
    triggerPromptsVariations: 'Trigger Prompt Variations',
    addMoretriggerPromptsVariations: 'Add trigger prompt variations',
    aiCategories: 'Default AI Categories',
    searchCategory: 'Search category',
    noCategoriesFound: 'No categories found',
    noCustomCategoriesFound: 'No custom categories found',
    noCustomCategoriesCreated: 'No custom categories',
    defaultColorTooltip: 'Default color can’t be edited',
    changeColorTooltip: 'Change color',
    deletePhrase: 'Delete cue phrase',
    deletePhraseWithVariations: 'Delete cue phrase and its variations',
    addPurposeDescription: 'Select at Least One Conversation Purpose',
    alwaysExtractedSmartNotes:
      'Smart Notes for this category will be always be extracted irrespective of the conversation purpose attached to the meeting.',
    conversationPurposeHelp: {
      extractAll:
        'Avoma will extract notes for this category for all the meetings irrespective of conversation purpose selected for the meetings.',
      extractIncluding:
        'Avoma will extract notes for this category only when conversations purpose to the meeting is as any one from below.',
      extractExcluding:
        'Avoma will extract notes for this category for all the meetings except for conversations purpose to the meeting is as any one from below.'
    },
    removeConversationPurposeTitle: 'Remove Conversation Purpose?',
    removeConversationPurposeDescription: `At least 1 conversation purpose must be selected, Removing this will change the settings to\n\n“Extract notes for All meetings irrespective of Conversation Purpose”`,
    cannotDeleteCRMKeywords: 'You cannot delete keywords imported from a CRM',
    addKeywordToSmartCategory:
      'Add at least one keyword to extract them as notes',
    reprocessKeywordsAndPhrasesInfo:
      'Avoma will reprocess conversations from up to 12 months ago to identify newly added, updated, or deleted keywords & smart phrases. Depending on the volume of conversations, it may take 24 to 48 hours for these changes to appear on the Trackers dashboard.',
    reprocessKeywordsInfo:
      'Avoma will reprocess conversations from up to 12 months ago to identify newly added, updated, or deleted keywords. Depending on the volume of conversations, it may take 24 to 48 hours for these changes to appear on the Trackers dashboard.',
    reprocessFillerWordsInfo:
      'Avoma will reprocess conversations from up to 12 months ago to identify newly added, updated, or deleted filler words. Depending on the volume of conversations, it may take 24 to 48 hours for these changes to appear on the Interactions dashboard.',
    createSmartCategoryTitle: 'Create New Smart Category',
    createSmartCategoryTitlePlaceholder: 'e.g. Onboarding Template',
    smartCategoryTitleEmptyError: 'Please enter a Smart Category name.',
    smartCategoryDefaultTitleTooltip: 'Default title cannot be edited',
    smartCategoryInformation: 'Smart Topic Information',
    smartCategoryInformationEmptyError: 'Please enter a Smart Category prompt.',
    prompt: 'Prompt',
    promptInfo:
      'This prompt helps Avoma AI understand what type of information to capture in the notes.',
    promptPlaceholder:
      'e.g. Information that describes the challenges that customers are facing with the current product.',
    generatePromptCta: 'Generate prompt with AI',
    examplePromptsCta: 'Example prompts',
    examplePromptsTitle: 'Examples of Good Prompts',
    aiExplanation:
      'Avoma AI uses prompts to provide additional context for each smart category.',
    promptInstruction:
      ' Think of the prompt as an instruction that helps Avoma AI extract relevant notes from conversations.',
    detailsReminder:
      'Be sure to include specific details that clearly define each smart category.',
    examplesIntro:
      'Below are examples of smart categories and their corresponding prompts:',
    examplePrompts: [
      {
        category: 'Business Impact',
        prompt: `Capture discussions about how the prospect’s challenges, needs, or opportunities impact their organization’s goals, revenue, costs, productivity, or overall performance.`
      },
      {
        category: 'Deliverables',
        prompt:
          'Identify key deliverables expected from the project, including product features, enhancements, and major outputs to be delivered.'
      },
      {
        category: 'Customer Goals',
        prompt: `Capture discussions about the prospect’s specific objectives, such as revenue targets, cost reduction, productivity improvements, and other desired outcomes.`
      },
      {
        category: 'Implementation Plan',
        prompt:
          'Summarize the plan outlining the steps, strategies, and timelines for deploying a solution within the prospect’s organization.'
      },
      {
        category: 'Roles and Responsibilities',
        prompt: `Summarize the prospect’s job title, primary responsibilities, involvement in decision-making, and key tasks or business outcomes they are accountable for.`
      }
    ],
    generating: 'Generating...',
    generateDisabledTooltip:
      'Please enter a Smart Category name to generate a prompt',
    createCustomCategorySuccess: 'Successfully created Smart Category',
    updateCustomCategorySuccess: 'Successfully updated Smart Category',
    generatePromptSuccess: 'Successfully generated prompt',
    generatePromptError: 'Error generating prompt',
    smartMatchPurchaseLicense: planName =>
      `Please upgrade to a ${planName} plan or above to track concepts through smart match.`,
    deleteCategoryConfirmTitle: categoryName =>
      `Delete "${categoryName}" category?`,
    deleteCategoryConfirmText: (categoryName, clickupIntegration) =>
      `Are you sure you want to delete "${categoryName}" category and all associated keywords?${
        clickupIntegration
          ? '\n\nThis will also permanently disable any ClickUp automations associated with this smart category.'
          : ''
      }`,
    defaultTopicEditRestrictionTooltip:
      'This is a default topic managed by admins. You cannot edit or delete it.',
    customTopicEditRestrictionTooltip:
      'This topic was created by another user and cannot be edited or deleted by you'
  },
  templates: {
    headerTitle: 'Templates',
    titlePlaceholder: 'Untitled Template',
    descriptionPlaceholder:
      'Add a description to explain what this template should be used for',
    private: 'Private',
    copyTemplate: 'Duplicate',
    templateNotFound: 'Template not found',
    deleteTemplate: 'Delete',
    deleteTemplateFailure: 'There was a problem deleting your template',
    deleteTemplateDescription:
      'Once deleted, you will not be able to recover this template.',
    deleteTemplateCta: 'Yes, Delete',
    templatePlaceholder: 'Create your note template here',
    changePrivacyCta: 'Change Privacy',
    changePrivacy: 'Change Template to Private?',
    changePrivacyDescription:
      'Changing the privacy of this template to Private will remove all existing purposes associated with the template.',
    privatePrivacyText: 'Only you can view and make changes to it',
    organizationPrivacyText:
      'Everyone in the organization can view it, but can not make changes to it.',
    readOnly: 'Read-Only',
    purposeTooltip: 'Automatically insert this template for selected purposes',
    purposeNotAllowedTooltip: 'Purposes not allowed for Private templates',
    modifiedByTooltip: 'Last modified at',
    ownedByTooltip: 'Owned by',
    readOnlyTooltip: 'You can read and use, but can not edit',
    useFor: 'Use for',
    insertTemplate: 'Insert Template',
    insertTemplateMobile: 'Templates',
    createTemplate: 'Create New',
    long: {
      allTemplates: 'All Templates',
      myTemplates: 'My Templates',
      organizationTemplates: 'Organization Templates'
    },
    short: {
      allTemplates: 'All',
      myTemplates: 'My',
      organizationTemplates: 'Organization'
    },
    noResults: 'Oops! something went wrong. Please try again later.',
    noResultMyTemplates: "You haven't created any templates yet",
    noResultAllTemplates: 'No templates created yet',
    noResultorganizationTemplates:
      "Everyone else hasn't shared any templates yet.",
    editTemplate: 'Edit',
    viewFullDescription: 'View Full Description',
    untitledTemplate: 'Untitled Template',
    sortBar: {
      name: 'Name',
      created: 'Created',
      modified: 'Last Modified',
      usedForPurpose: 'Used for Purpose'
    },
    modals: {
      createNewTemplate: 'Create a new Template',
      editDetails: 'Edit Details',
      title: 'Name',
      titlePlaceholder: 'eg. Onboarding Template (max 100 characters)',
      description: 'Description',
      descriptionPlaceholder:
        'e.g. Template for onboarding process (max 80 characters)',
      selectPurpose: 'Select the purpose to apply this Template'
    },
    purposeHelpPopoverTitle: 'Select Purposes for Templates',
    purposeHelpPopoverContent:
      'Select multiple purposes for templates, so you can use these templates in notes editor of meetings, having any of the selected purpose. Avoma will automatically insert the templates in meetings based on selected purposes.',
    accessPermission: 'Access Permission',
    toastMessage: {
      createSuccess: 'Successfully created template',
      createError: 'Error creating template',
      duplicateSuccess: 'Successfully duplicated template',
      duplicateError: 'Error duplicating tempalate',
      deleteSuccess: 'Successfully deleted template',
      deleteError: 'Error deleting template'
    }
  },
  onboarding: {
    withGoogle: 'with Google',
    withMicrosoft: 'with Outlook',
    usingSingleSignOn: 'Using Single Sign-On?',
    singleSignOnInputPlaceholder: 'Enter your email address',
    tosText: 'By signing up, I agree to the',
    tosLink: 'Terms of Use',
    privacyLink: 'Privacy Policy',
    and: 'and',
    skipOnly: 'Skip',
    skip: 'Skip & Proceed',
    skipStep: 'Skip for now',
    stepSkipped: 'Skipped!',
    stepCompleted: 'Complete',
    stepPending: 'Pending',
    continue: 'Continue',
    change: 'Change',
    welcome: 'Welcome!',
    welcomeDescription: 'Meeting intelligence with a built-in AI assistant',
    infoMeeting: 'Meeting preparation & Centralized Note-taking',
    infoVideo: 'Recordings, Transcripts, Summarized Notes & Insights',
    infoLine: 'Trends and Conversation Intelligence across your Team',
    welcomeLogin: 'Welcome back!',
    welcomeLoginDescription:
      'Prepare well, participate actively, follow-up promptly',
    infoAgenda: 'Set an agenda and plan ahead with Templates',
    infoAudio: 'Listen to recordings and review transcripts',
    infoShare: 'Share Snippets & collaborate with your team faster',
    dismiss: 'Dismiss',
    hideComplete: 'Hide completed task',
    congratulations: 'Congratulations! 👏',
    finishedSetup: 'You finished setting up Avoma.',
    dismissAll: 'Dismiss All Tasks',
    tour: 'Now how about we take a tour of Avoma?',
    connectGoogleCalendar: 'Connect Google calendar',
    connectMsftCalendar: 'Connect Outlook calendar',
    zoomConferencing: 'Zoom Conferencing',
    otherConferencing: 'Other Conferencing',
    avomaAssistantBot: 'Avoma Assistant (Bot)',
    otherConferencingInfo:
      'Avoma Assistant (Bot) automatically integrates with all other conferencing providers.',
    quickStartTitle: 'Your quick start guide',
    accordionVideoTitle: 'Watch a brief demo video',
    accordionVideoDescription:
      'Watch this brief demo video to learn the key functionalities and workflows of Avoma.',
    accordionCalendarDescriptionPoint1:
      'Record and transcribe your upcoming meetings',
    accordionCalendarDescriptionPoint2:
      'Capture and share conversation and customer insights with the rest of your team',
    accordionConferencingTitle: 'Select your conferencing preferences',
    accordionConferencingDescription:
      'Connect your conferencing app for a seamlessly integrated experience.',
    accordionRecordingTitle: 'Set up your recording settings',
    accordionConferencingZoomTitle:
      'Enable Zoom Cloud recording for Transcription',
    accordionRecordingDescription:
      'Decide which meetings you would like to record automatically.',
    accordionVoiceprintTitle: 'Add your voiceprint',
    accordionCrmTitle: 'Connect your CRM to sync notes',
    accordionCrmDescription:
      'Connect your CRM account to map your meeting activities with CRM records, automatically save your notes directly to appropriate records, and search for meetings and insights based on CRM properties (Deal stage, Amount, etc.)',
    calSyncSuccess: 'Calendar has been synced',
    calendarSyncGating: planName =>
      `New meetings from your calendar will not be recorded. Please upgrade to ${planName} plan or above to continue recording new meetings.`,
    voicePrintSuccess: 'Voiceprint has been created',
    quickstartStaticGreetTitle: 'Welcome to Avoma',
    quickstartStaticGreetSubtitle: 'Let’s get you set up in a few steps!',
    quickstartStaticGreetOwner: 'From Aditya, Co-founder at Avoma',
    disconnectCalendarText:
      'Your upcoming meetings which are not scheduled yet will not be synced with Avoma going forward, and won’t be recorded and transcribed.\n\nYour upcoming meetings which are already scheduled and synced with Avoma will still be recorded and transcribed if the recording is enabled.',
    disconnectZoomText:
      'Your Zoom Cloud Recordings won’t be recorded and transcribed.',
    conferencingOptions: 'Select conferencing preferences',
    conferencingZoomTitle: 'Zoom conferencing',
    conferencingZoomSubtitle: 'Zoom Cloud Recording based integration',
    conferencingOthersTitle:
      "For all other conferencing and Zoom conferencing (when I'm a participant)",
    conferencingOthersSubtitle1: '(Google Meet, Microsoft Teams, etc.)',
    conferencingOthersStatusMsg:
      'Avoma Assistant (Bot) will dial-in and record the meetings.',
    conferencingZoomWarning1: 'Connect to Zoom to enable Zoom Cloud Recording.',
    conferencingZoomWarning2:
      'Automatic transcription not available for your Free Zoom account.',
    zoomRadioTitle: 'Zoom Cloud',
    zoomPopoverTitle: 'Pros and Cons of Zoom Cloud Recording',
    zoomRecordingPro1: 'Recorded by Zoom Cloud',
    zoomRecordingPro2: 'No 3rd party bot as a participant',
    zoomRecordingPro3: 'Recording announcement for each participant',
    zoomRecordingCon1: 'Records only when you’re the Host',
    zoomRecordingCon2: 'No real-time transcript (coming soon)',
    avomaBotRadioTitle: 'Avoma Assistant (Bot)',
    avomaBotPopoverTitle: 'Pros and Cons of Avoma Assistant (Bot) Recording',
    avomaBotRecordingPro1: 'Recorded by Avoma’s Bot as a 3rd party participant',
    avomaBotRecordingPro2: 'Real-time transcript',
    avomaBotRecordingPro3: 'Records when you’re the Host and a participant too',
    avomaBotRecordingCon1: 'Recording announcement when the Bot joins',
    avomaBotRecordingCon2:
      'Participants who join after the Bot won’t hear the recording announcement',
    zoomSetupHelpLink: 'https://help.avoma.com/avoma-integration-with-zoom',
    otherConferencingRecordingTitle:
      'For all other conferencing (Google Meet, Microsoft Teams, etc.)',
    completedMessage: 'You are all set!',
    completedCtaLabel: 'Go to My Meetings',
    playlistCtaLabel: 'Watch Now',
    redirectHeading: 'Get the most out of Avoma!',
    playlistRedirectMessage:
      'Learn how to use Avoma with a "Getting Started" Playlist',
    playlistDurationMessage: '2 mins videos each',
    crmSalesforceDesc:
      'Sync your notes, transcripts, and conversation insights directly to Salesforce',
    crmHubspotDesc:
      'Sync your Notes to appropriate records for timely data-entry in HubSpot',
    crmCopperDesc:
      'Sync your Notes to appropriate records for timely data-entry in Copper',
    crmPipedriveDesc:
      'Sync your Notes to appropriate records for timely data-entry in Pipedrive',
    crmZohoDesc:
      'Sync your Notes to appropriate records for timely data-entry in Zoho',
    ssoConfigured:
      'Please use Single Sign-On (SSO) to log into your Avoma account. Other login methods are not permitted for organizations with SSO enabled.',
    ssoNotConfigured:
      "Single sign-on has not been configured for your domain yet. Please try a different login method or contact your organization's Avoma administrator",
    syncYourCalendar: calProvier =>
      `Syncing your ${capitalize(
        calProvier
      )} Calendar will make it much easier to:`,
    isCalendarSyncing:
      'Syncing meetings from Google Calendar, it will take a few mins to sync all your meetings.',
    connectCalendar: calProvider => (
      <React.Fragment>
        Sync your <span className='capitalize'>{calProvider}</span> Calendar
      </React.Fragment>
    )
  },
  onboardingWelcome: {
    title: 'Welcome to Avoma!',
    subtitle:
      'Avoma is an all-in-one meeting management solution with a built-in AI assistant.',
    valueTitle: 'Avoma has 3 core value propositions',
    value1: 'Meeting preparation & Collaborative note-taking',
    value2:
      'AI Summarized Notes, Transcripts, and Insights for Meetings & Calls',
    value3: 'Conversation Insights & Coaching Analytics for your teams',
    taskTitle: 'In the next few minutes, we will help you finish 3 tasks:',
    task1: 'Set up your account',
    task2: 'Explain how Avoma works',
    task3: 'Customize the experience for you',
    cta: "Let's Get Started"
  },
  onboardingFinish: {
    title: "Hi {0}, it's great to meet you",
    ctaHeader: 'How about we take a brief tour of Avoma?',
    cta: 'View Sample Meeting'
  },
  microsoftAuth: {
    success: 'Outlook Calendar successfully connected',
    failure: 'There was an error connecting ot Outlook Calendar'
  },
  onboardingProfile: {
    title: 'Tell us a bit about yourself',
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    jobFunctionLabel: 'What best describes what you do?',
    selectOne: 'Select One',
    jobTitleLabel: 'Job Title',
    companySizeLabel: 'How big is your company?',
    originLabel: 'How did you hear about Avoma?',
    organizationName: 'Company Name',
    companyFieldSubtitle: 'Set by your Admin when they created an account',
    createAccount: 'Create Account'
  },
  onboardingCalendar: {
    title: 'What do you want to accomplish with Avoma?',
    cta: 'Connect Calendar',
    optionTranscribe: 'Transcribe & analyze my conversations',
    optionNotesZoom: 'Take notes and prepare for Zoom meetings',
    optionReview: "Review my team's conversations and insights",
    calCta: 'Allow Calendar Access',
    titleNoCal:
      'In order to prepare for, transcribe, and analyze your meetings, Avoma needs access to your Calendar.'
  },
  onboardingAdmin: {
    title: 'Congratulations!',
    subtitle: 'You have been added to the',
    subtitleAdmin: 'You are now an Admin of the',
    admin1:
      'As an Admin, you can invite your team members, create teams, and a lot more',
    admin2: 'You can always make other team members Admins later from Settings',
    admin3: 'With great power comes great responsibility',
    nonAdmin1: 'The current Admins are:',
    nonAdmin2: 'You can reach out to them in case Admin assistance is required',
    cta: 'Got it'
  },
  onboardingCrm: {
    title: 'Connect your CRM',
    dropdownLabel: 'Which CRM does your team use?',
    description:
      'Keep your team updated on what’s happening with every customer by syncing meeting notes directly from Avoma to your CRM.',
    selectOne: 'Select One',
    connectedTitle: 'Connected!',
    connectedDescription:
      'was successfully connected! You will now be able to sync notes and details directly from Avoma to',
    connectedUsernameLabel: ' username',
    exclusiveCrmDescription: 'It looks like your organization uses'
  },
  onboardingIntegrations: {
    title: 'Connect More Tools',
    doThisLater: "I'll do this later from Settings",
    description:
      'Avoma also provides integrations with the following tools. Select all the following that you’d like to connect to Avoma:'
  },
  onboardingRecordingSettings: {
    title: 'Review Recording Settings',
    doThisLater: "I'll do this later from Settings",
    subtitle:
      'Which meetings do you want Avoma to automatically dial-in to and record?',
    zoomSubtitle:
      'Zoom Cloud Recording is enabled for Zoom meetings. For all other meetings (ie: Webex, GoToMeeting, etc), which meetings do you want Avoma to record?',
    customerMeetingsTitle: 'Customer meetings only (external only)',
    allMeetingsTitle:
      'All of my meetings (both external and internal company meetings)',
    noMeetingsTitle: 'None of my meetings',
    customerMeetingsDescription:
      'Avoma syncs to your calendar and will only dial-in and record meetings that include an external user in the calendar invite (someone not in the same company as you)',
    allMeetingsDescription:
      'Avoma syncs to your calendar and dials into to all meetings—both external customer and internal company meetings— that include online meeting details in the calendar invite.',
    noMeetingsDescription:
      'No calendar sync, and you’ll have to manually add assistant@avoma.com as a participant on all of your calendar invites to record and transcribe your meetings.',
    disabledSubtitle: '(Disabled by your administrator)',

    internalMeetingsTitle: 'Internal Meetings',
    internalMeetingsDescription:
      'Avoma syncs to your calendar and will dial into all internal company meetings.',

    cloudRecordingSettings: 'How would you like to record your Zoom meetings?',
    recordWithZoomLabel: 'Zoom Cloud Recording',
    recordWithZoomDescription:
      'Seamlessly analyze your Zoom calls without requiring the Avoma Assistant to join the meeting',
    recordWithAvomaLabel: 'Avoma Assistant',
    recordWithAvomaDescription:
      'Avoma Assistant will automatically dial-in and record your Zoom meetings as a silent participant',

    disabledDueToFeatureSubtitle: `(Requires ${PLAN_NAMES.plus} License)`
  },
  onboardingCustomKeywords: {
    title: 'Set up Custom Keywords',
    doThisLater: "I'll do this later from Settings",
    finish: 'Finish'
  },
  activityDashboard: {
    title: 'Activity Stats',
    chartTitle: 'Team members activity',
    numberOfMeetingsTitle: 'Number of Conversations',
    timeSpentTitle: 'Time Spent in Conversations',
    totalMeetings: 'Total Conversations',
    medianMeetingsPerUser: 'Median Conversations / User',
    avgMeetingsPerUser: 'Average Conversations / User',
    totalTimeSpent: 'Total Time Spent',
    medianTimePerUser: 'Median Time / User',
    avgTimePerUser: 'Average Time / User',
    teamMemberColumn: 'Team Member',
    meetingsColumnHeaderShort: '# of Convos',
    meetingsColumnHeaderLong: 'Number of Conversations',
    hoursColumnHeaderShort: 'Total Hours',
    hoursColumnHeaderLong: 'Total Hours Spent in Conversations'
  },
  topicsDashboard: {
    durationTabTooltip: 'Average topic duration',
    startTimeTabTooltip: 'Average start timing',
    tabDurationTitle: 'Topics Duration',
    tabTopQuestionsTitle: 'Top Questions',
    tabStartTimeTitle: 'Topics Start Time',
    tabDurationHeader: "What's the average time spent across all topics?",
    tabStartTimeHeader: "What's the average starting time for topics?",
    barChartDurationColumn: 'Average Topic Duration (mins)',
    barChartStartTimeColumn: 'Average Topic Starting Time (mins)',
    topicNameColumnHeader: 'Topic Name',
    checkboxSelectAll: 'Select All'
  },
  popularTopicsDashboard: {
    tabTopQuestionsTitle: 'Top Questions',
    topQuestions: {
      noSimilarQuestions: 'No similar questions found',
      timesAsked: 'Times asked in meetings',
      similarQuestions: 'Similar questions asked in this group',
      questionsTitle: 'Question',
      emptyMessage: 'No top questions found'
    }
  },
  dealsDashboard: {
    title: 'Deals',
    account: 'Account',
    accounts: 'Accounts',
    engagement: 'Engagement',
    name: 'Deal Name',
    meetings: 'Meetings',
    calls: 'Calls',
    email: 'Email',
    emails: 'Emails',
    us: 'Us',
    them: 'Them',
    meeting: 'Meeting',
    host: 'Host',
    outcomeAndPurpose: 'Purpose/Outcome',
    viewInCrm: crmName => `View in ${crmName}`,
    viewInHubSpot: 'View in HubSpot',
    viewInSalesforce: 'View in Salesforce',
    viewInZendesksell: 'View in Zendesksell',
    viewInPipedrive: 'View in Pipedrive',
    backToDeals: 'Back to Deals',
    viewInCopper: 'View in Copper',
    goBack: 'Go Back',
    sentBy: 'Sent By',
    viewDealInCopper: 'View Deal in Copper',
    viewDealInZendesksell: 'View Deal in Zendesksell',
    viewDealInSalesforce: 'View Deal in Salesforce',
    viewDealInHubSpot: 'View Deal in HubSpot',
    viewCompanyInCopper: 'View Company in Copper',
    viewCompanyInZendesksell: 'View Company in Zendesksell',
    viewCompanyInSalesforce: 'View Company in Salesforce',
    viewCompanyInHubSpot: 'View Company in HubSpot',
    noResults: 'No deals found for the applied filters',
    dataSyncPending:
      'Data will be fetched within 24hrs after onboarding, please check back here soon',
    stringCellShiftEnterPlaceholderText:
      'Click shift + enter to go to new line',
    pleaseAssociateACompany:
      'Please associate a company with this deal in your CRM',
    noCompanyAssociated: 'No company associated',
    companyName: 'Company Name',
    company: 'Company',
    amount: 'Amount',
    annualRevenue: 'Annual Revenue',
    closeDate: 'Close Date',
    dealStage: 'Deal Stage',
    dealOwner: 'Deal Owner',
    accountOwner: 'Account Owner',
    owner: 'Owner',
    aiWinLossAnalysis: {
      noAnalysisMessageForWonDeals:
        "AI win summary is not generated for this deal as it's an older deal, you can generate it manually below.",
      noAnalysisMessageForLostDeals:
        "AI loss summary is not generated for this deal as it's an older deal, you can generate it manually below.",
      noAnalysisOldDeals:
        'The AI analysis is not generated for the older deals, get in touch with your Avoma CS member to generate it for older deals.',
      whyWeWonComingSoon:
        'We currently do not provide AI analysis for closed won deals, it is coming soon.',
      aiIsGeneratingAnalysis: 'AI is generating analysis',
      whyWeLost: 'AI Summary - Why we Lost',
      whyWeWon: 'AI Summary - Why we Won',
      upgradeLicenseToAccessDealQuickView:
        'Upgrade License to access Deal Quick View',
      pleaseUpgradeDealQuickView: `Please upgrade to ${PLAN_NAMES.premium} plan to view Deal Quick View`,
      dealQuickViewWillHelpYouGetAccessTo:
        'Deal Quick View will help you get access to:',
      dealQuickViewUpgradePoints: [
        'Email, Meetings, Calls Activity view (Between you and customer)',
        'Deal Properties View',
        'Deal Engagement Details',
        'Deal Risk Score',
        'Deal Risk Alerts',
        'AI Deal Win Loss Analysis'
      ]
    }
  },
  companyDashboard: {
    name: 'Company Name',
    noResults: 'No companies found for the applied filters'
  },
  conversationDashboard: {
    title: 'Interaction Skills',
    breakdownMeeting: 'Meeting Breakdown',
    breakdownMeetingShort: 'Meetings',
    breakdownUser: 'User Leaderboard',
    breakdownUserShort: 'Leaderboard',
    tabTalkTime: 'Talk Time',
    tabMonologue: 'Longest Monologue',
    tabCustomerStory: 'Longest Customer Story',
    tabClientQuestions: 'Customer Questions',
    tabRepQuestions: 'Team Member Questions',
    tabFillerWords: 'Filler Words',
    tabTalkTimeHeader: "What's the Talk Time for each team member?",
    tabMonologueHeader: "What's the duration of the Longest Monologues?",
    tabCustomerStoryHeader: "What's the duration of Customer's Longest Story?",
    tabClientQuestionsHeader: 'How many Questions do Customers typically ask?',
    tabRepQuestionsHeader: 'How many Questions do Team Members typically ask?',
    tabFillerWordsHeader:
      'How many Filler Words do team members use on average?',
    tabStatPlaceholder: '--',
    noData: 'No Results',
    noDataSubtitle: 'There were no results for the selected set of filters.',
    headerTeamMember: 'Team Member',
    headerMaxLength: 'Recommended Max Length: ',
    headerLongestMonologue: 'Longest Monologue',
    headerCustomerStory: 'Longest Customer Story',
    headerRecommendedTalkTime: 'Recommended Talk Time Range: ',
    headerAverageTalktime: 'Average Talk Time %',
    headerAverageMonologueLength: 'Average Monologue Length: ',
    headerAverageCustomerStory: 'Average Longest Customer Story: ',
    headerAvgQuestions: 'Average Questions Asked: ',
    headerAvgTotalQuestions: 'Average Total Questions Asked: ',
    headerAvgQuestionsPerMeeting: 'Average Questions Per Conversation: ',
    headerTalktimeWithinRange: '% within Range',
    headerLongestTalktime: 'Longest Talk Time',
    headerTotalQuestions: 'Total Questions Asked',
    headerAvgFillerWords: 'Average Filler Words',
    headerAvgFillerWordsPerMetting: 'Average Filler Words Per Conversation',
    headerFillerWord: 'Filler Word',
    recommendedTalkTimeRange: '40 - 60%',
    subtitleCustomerEmail: 'Customer Email',
    subtitleTalk: 'Talk Time',
    subtitleListen: 'Listen Time',
    subtitleLongestMonologue: 'Longest Monologue Time',

    meetings: 'meetings',
    meetingNamePlaceholder: '...',
    monologueOverLimitTooltip: 'Over recommended monologue length',
    customerStoryOverLimitTooltip: 'Over recommended customer talk time',
    talkTimeUnderLimitTooltip: 'Under recommended talk time %',
    talkTimeOverLimitTooltip: 'Over recommended talk time %',

    talkTimeTabTooltip: 'Average team talk time %',
    longestMonologueTabTooltip: 'Average team longest monologue',
    longestCustomerTabTooltip: 'Average team longest customer story',
    clientQuestionsStatTabTooltip: 'Average customer question',
    repQuestionsStatTabTooltip: 'Average rep questions',
    fillersStatTabTooltip: 'Average filler words used'
  },
  conferencing: {
    integration: 'Integration',
    zoom: 'Zoom',
    aircall: 'aircall',
    webex: 'Webex',
    msteam: 'Microsoft Teams',
    googlemeet: 'Google Meet'
  },
  settings: {
    salesforceCardTitle: 'Salesforce Integration',
    teams: {
      removeFromTeamTitle: 'Remove from Team?',
      removeFromTeamInfo:
        'This does not remove the user from the organization.',
      remove: 'Remove from Team',
      removeSuccess: 'Successfully removed user from team',
      removeError: 'Error removing user from team',
      moreMenu: {
        edit: 'Edit Team',
        delete: 'Delete Team'
      }
    },
    breadcrumb: {
      integrations: 'Integrations',
      automations: 'Automations'
    },
    integrations: {
      title: 'Integrations',
      integratedApps: 'Integrated Apps',
      otherApps: 'Other Apps'
    },
    templates: {
      title: 'Templates'
    },
    automations: {
      title: 'Automations',
      createNewAutomation: 'Create New Automation',
      editAutomation: 'Edit Automation'
    }
  },
  featureControl: {
    salesforceTitle: `Salesforce Integration requires at least ${PLAN_NAMES.lite} License`,
    salesforcePoints: [
      'Log your meeting activities to appropriate Salesforce records',
      'Sync your Notes to appropriate records for timely data-entry'
    ],
    copperTitle: `Copper Integration requires at least ${PLAN_NAMES.lite} License`,
    zendesksellTitle: `Zendesk Sell Integration requires at least ${PLAN_NAMES.lite} License`,
    hubspotTitle: `HubSpot Integration requires at least ${PLAN_NAMES.lite} License`,
    pipedriveTitle: `Pipedrive Integration requires at least ${PLAN_NAMES.lite} License`,
    aircallTitle: `Aircall Integration requires at least ${PLAN_NAMES.plus} License`,
    connectLeaderTitle: `Koncert Integration requires at least ${PLAN_NAMES.plus} License`,
    ringCentralTitle: `RingCentral Integration requires at least ${PLAN_NAMES.plus} License`,
    zoomPhoneTitle: `Zoom Phone Integration requires at least ${PLAN_NAMES.plus} License`,
    outreachTitle: `Outreach Integration requires at least ${PLAN_NAMES.plus} License`,
    gotoconnectTitle: `GoToConnect Integration requires at least ${PLAN_NAMES.plus} License`,
    vonageTitle: `Vonage Integration requires at least ${PLAN_NAMES.plus} License`,
    ctaTitle: 'Purchase License',
    manageMembersCtaTitle: 'Manage Members',

    keywordsTitle: `Custom Keyword Tracking requires a ${PLAN_NAMES.plus} License`,
    keywordsPoints: [
      'Track custom keywords for every meeting',
      'See Trends of custom keywords and phrases over time on an interactive visual dashboard'
    ],
    keywordsFeature: 'Keywords Dashboard Feature',

    trackersTitle: `Tracking keywords requires a ${PLAN_NAMES.plus} License`,
    trackersPoints: [
      'Track keywords for every meeting',
      'See Trends of keywords over time on interactive visual dashboard',
      'See Leaderboard to understand which keyword has been used most',
      'See the data distribution by team members, meeting types/outcomes, deal stages, etc'
    ],
    trackersFeature: 'Trackers Dashboard Feature',

    topicsTitle: `Topics tracking requires a ${PLAN_NAMES.plus} License`,
    topicsPoints: [
      'Track topic duration and start time for every meeting',
      'See Leaderboard to understand which is topic has been discussed most',
      'See the data distribution by team members'
    ],
    topicsFeature: 'Topics Dashboard Feature',
    topQuestionsTitle: `Top Questions requires a ${PLAN_NAMES.premium} License`,

    popularTopicsTitle: `Popular Topics tracking requires a ${PLAN_NAMES.premium} License`,

    conversationInsightsFeature: 'Conversation Intelligence Dashboard Feature',
    conversationInsightsTitle: `Conversation Intelligence requires a ${PLAN_NAMES.lite} License`,
    interactionDashboardTitle: `Interaction Dashboard requires a ${PLAN_NAMES.plus} License`,
    conversationInsightsPoints: [
      'Analyze your talking habits to identify areas for improvement',
      'Track your talk-to-listen ratio, your longest monologues, and more insights to improve your conversations'
    ],

    dealIntelligenceTitle: `Deal Intelligence requires a ${PLAN_NAMES.premium} License`,
    dealIntelligencePoints: [
      'Get instant insight into the health of your deals',
      'See engagement activity to see which deals are on track and which are stalling',
      'Make data driven decisions with Deal Intelligence'
    ],
    dealIntelligenceTitleFeature: 'Deal Intelligence Feature',
    dealIntelligenceV2Title:
      'You are on a Basic plan, upgrade to view Revenue Intelligence!',
    dealIntelligenceV2PointsTitle: 'Revenue Insights help you achieve:',
    dealIntelligenceV2Points: [
      'Pipeline Management',
      'Deal Engagement Details',
      'Deal Risk Alerts',
      'Updating CRM properties',
      'Forecasting'
    ],
    crmNotConnectedCtaTitle: 'CRM Settings',

    dealIntelligenceCrmTitle:
      'Connect your CRM account to view Deal Intelligence',

    externalRecordingTitle: `Recording external meetings requires a ${PLAN_NAMES.plus} License`,
    externalRecordingPoints: [
      'Your current plan allows you to record internal meetings'
    ],
    recordingTitle: `Recording and Transcription requires a ${PLAN_NAMES.plus} License`,
    collabNotesReadOnlyGating: planName =>
      `Collaborative notes editing requires ${planName} plan or higher`,
    meetingUploadGateTitle: planName =>
      `Uploading meetings requires ${planName} plan or above`,
    meetingUploadGateDescription:
      'Upload and analyze meetings from all conferencing providers with audio or video and screen share support.',
    recordingImpromptuMeetingGateTitle: planName =>
      `Impromptu meetings requires ${planName} plan or above`,
    recordingImpromptuMeetingGateDescription:
      'Allow the Avoma bot to join your impromptu meetings and start recording instantly.',
    recordingPoints: [
      'Automatically record or manually upload meetings from all conferencing providers with audio or video and screen share support',
      'Playback the recording with different speeds and search in recording for faster consumption',
      'Share the whole recording or snippets of your recording with your team members or customers'
    ],
    scorecardsTitle: `Scorecards require a ${PLAN_NAMES.plus} plan or above.`,
    scorecardsMemberTitle: `The member you are attempting to score requires a ${PLAN_NAMES.plus} plan or above.`,
    scorecardsOrganizationTitle:
      'The member you are attempting to score is not a part of your Organization.',
    scorecardsRequestTitle: `The member you are attempting to request a score from requires a ${PLAN_NAMES.plus} plan or above.`,
    scorecardsPoints: [
      'Enhance coaching effectiveness by identifying key areas for improvement, boosting sales representative performance, and attaining desired business outcomes.',
      'Monitor progress and track team performance through insightful score trends.',
      'Utilize AI coaching to easily evaluate meetings and calls, enabling significant time savings.'
    ],
    coachingDashboardTitle: `Coaching requires a ${PLAN_NAMES.plus} plan or above.`,
    scorecardsFeature: 'Scorecards Feature',
    scorecardsAssignTeam: `Only members with a ${PLAN_NAMES.plus} plan or above will be auto-scored.`,
    inviteNewMember: 'Contact your Admin to invite a new Member.',
    requestLicenseForOthers:
      'Contact your Admin to request a License for this member.',
    upgrade: 'Upgrade License',
    pipelineViewUpgradeBanner: {
      headerTitle: 'Upgrade License to access Pipeline Views',
      title: `Please upgrade to ${PLAN_NAMES.premium} Plan to view Pipeline Views`,
      subtitlesHeading: 'Pipeline Views will help you get access to:',
      subtitlesPoints: [
        'Pipeline Management',
        'Deal Engagement Details',
        'Deal Risk Score',
        'Deal Risk Alerts',
        'Importing CRM properties',
        'In-line Editing the CRM properties'
      ]
    },
    forecastUpgradeBanner: {
      headerTitle: 'Upgrade License to access Forecast',
      title: `Please upgrade to ${PLAN_NAMES.premium} Plan to view Forecast`,
      subtitlesHeading: 'Forecast will help you get access to:',
      subtitlesPoints: [
        'Forecast by Deal Stage',
        'Forecast by Forecast Category',
        'Forecast with Deal Risk Score',
        'Forecast with Deal Risk Alerts',
        'Forecast Submission',
        'Forecast Submission History'
      ]
    },
    aiWinLossAnalysisUpgradeBanner: {
      headerTitle: 'Upgrade License to access AI analysis of deals win/loss',
      title: `You need to have ${BILLING_ADDONS.aiWinLossAnalysis} Add-on to view Win/Loss Analysis tab`,
      subtitlesHeading:
        'AI analysis of deals win/loss will help you get access to:',
      subtitlesPoints: [
        'AI analysis of why we lost the deal',
        'AI analysis of why we won the deal',
        'Deal win rate trend and performance across teams and members',
        'Deals win/loss analysis'
      ],
      cta: 'Please contact your Customer Success Manager for details about the add-on purchase.'
    },
    reportsUpgradeBanner: {
      headerTitle: 'Upgrade License to access Reports',
      title: `Please upgrade to ${PLAN_NAMES.premium} Plan to view Reports`,
      subtitlesHeading: 'Reports will help you get access to:',
      subtitlesPoints: [
        'Deals at Risk by Stages',
        'Pipeline Trend',
        'Pipeline Performance',
        'Deals Movement',
        'Activity'
      ]
    },
    connectYourCRM: 'Connect your CRM',
    connectYourCRMToViewPipeline: 'Connect your CRM to view Pipeline Views',
    connectYourCRMToViewForecasting: 'Connect your CRM to view Forecasting',
    connectYourCRMToViewReports: 'Connect your CRM to view Reports',
    renew: 'Renew License',
    purchase: 'Purchase License',

    zendeskSellPoint1:
      'Log your meeting activities to appropriate Zendesk Sell records',
    zendeskSellPoint2:
      'Sync your Notes to appropriate records for timely data-entry',
    copperPoint1:
      'Log your meeting activities to appropriate records in Copper',
    copperPoint2:
      'Sync your Notes to appropriate records for timely data-entry',
    usageInsightsTitle: `Usage Insights requires a ${PLAN_NAMES.premium} or higher License`,
    activityDashboardTitle: planName =>
      `Activity Dashboard requires ${planName} plan or higher`,
    reportsBannerText:
      'The reports are still shown based on standard deal amount and close date fields.',
    reportsTitle: 'Which deals are at risk based on engagement',
    usageInsightsPoints: [
      "Track member's usage of conversations (Meetings, calls) listened within Avoma",
      'See trends of the progress made over time',
      'See the leaderboard to understand which members are under performing',
      'Slide the data by teams, call types, external meetings etc.'
    ],
    usageInsightsFeature: 'Usage Insights Feature',
    postCommentGating: planName =>
      `Posting comments requires ${planName} plan or higher`,
    createSnippetGating: planName =>
      `Creating Snippets requires ${planName} plan or higher`,
    createSnippetAndShareGating: (planName, medium) =>
      `Sharing this snippet via ${medium} requires ${planName} or above`,
    createSnippetAndAddToPlaylistGating: planName =>
      `Adding this snippet to a playlist requires ${planName} or above`
  },
  auth: {
    signup: 'Sign up',
    login: 'Log in'
  },
  oauth: {
    reloading: 'Refreshing in 5 seconds',
    signupLogin: 'Sign Up / Log In to Avoma',
    signupLoginShort: 'Log In to Avoma',
    loginWorkSubtitle: 'Log in with your work email address.',
    signupWorkSubtitle: 'Sign up with your work email address.',
    login: 'Log In',
    startTrial: 'Try Avoma for free!',
    trialSignupLink:
      'utm_source=shared+meetings&utm_medium=avoma+app&utm_campaign=sign-up&utm_id=in-product-signups',
    closeWindow: 'You can now close this window',
    desktop: {
      redirect: {
        title: "You're signed in!",
        open: 'Open Avoma',
        launch: 'to launch the desktop app',
        closeNotice:
          "If you've already launched the app, you can now close this tab and return to the Avoma desktop app.",
        notWorking: 'App not working?',
        openInBrowser: 'Use Avoma in the browser'
      }
    }
  },
  setup: {
    title: 'Set Up Your Account',
    subtitle: ' completed out of ',
    integrationTitle: 'Get recordings from ',
    integrationCta: 'Connect ',
    calendarTitle: 'Connect your calendar to view your meetings',
    calendarSchPage: 'Connect your calendar to view your pages',
    calendarCta: 'Connect Calendar',
    recordingTitle: 'Enable and select your recording preferences',
    recordingCta: 'Turn ON Recording',
    crmTitle: 'Connect your CRM to sync notes',
    crmCta: 'Connect CRM',
    legalTitle: 'Configure 2-party consent notification requirement',
    legalCta: 'Review Notification',
    keywordsTitle: 'Customize Avoma for your business vocabulary',
    keywordsCta: 'Setup Keywords',
    templatesTitle: 'Create shared Note Templates and Agendas',
    templatesCta: 'Create Templates',
    invitesTitle: 'Invite your colleagues to collaborate',
    invitesCta: 'Invite Team'
  },
  filters: {
    moreFilters: 'More Filters',
    saidBy: 'Said by',
    calls: 'Call Types',
    callsOnly: 'Calls Only',
    meetingsOnly: 'Meetings Only',
    other: 'Other',
    isAnsweredFilter: 'Call was Answered',
    outboundFilter: 'Outbound',
    inboundFilter: 'Inbound',
    clear: 'Clear',
    typesFilter: 'Purposes',
    outcomesFilter: 'Outcomes',
    stageDuringFilter: 'Stages During',
    stageNowFilter: 'Stages Now',
    dealStageNowFilter: 'Deal Stage Now',
    amountNow: 'Amount Now',
    crmFilter: 'CRM Filters',
    amountFilterTooltip: 'Filter by Amount',
    typesFilterTooltip: 'Filter by Conversation Purposes',
    outcomesFilterTooltip: 'Filter by Conversation Outcomes',
    keywordsInTranscriptFilterTooltip: 'Filter by Keywords',
    callFilterTooltip: 'Filter by Call Type',
    saidByFilterTooltip: 'Filter by who said it',
    companyFilterTooltip: 'Filter by Companies',
    companyFilterNonCrmTooltip:
      "Companies displayed here are extracted by Avoma based on external participant's email domain",
    companyFilterCrmTooltip:
      "Companies displayed here are extracted by Avoma based on the external participant's domain and companies with CRM Icon are the company records present in CRM",
    moreFilterTooltip: 'Filter by More Options',
    stageNowFilterTooltip: 'Filter by Deal Stage Now',
    stageDuringFilterTooltip: 'Filter by Deal Stage During Meeting',
    crmFilterTooltip: 'Filter by CRM Filters',
    filterByStageDuring: 'Filter by Stages During',
    filterByStageNow: 'Filter by Stages Now',
    dealStatusTitle: 'Filter by Deal Status',
    sortByTitle: 'Sort by',
    teamFilterTooltip: 'Filter by Team',
    selectedTeam: 'Selected Team',
    selectedTeams: 'Selected Teams',
    memberFilterTooltip: 'Filter by Member',
    membersFilterTooltip: 'Filter by Members',
    selectedMember: 'Selected Member',
    selectedMembers: 'Selected Members',
    ownerFilterTooltip: 'Filter by Owner',
    smartCategoryFilterTooltip: 'Filter by Smart Category',
    clearAll: 'Clear All',
    disabledOrganizerText: 'Select the member first',
    disabledDealStageFilterTooltip: 'Select a pipeline first',
    scoredForTeamAndMemberFilterTooltip: 'Filter by Scored For Teams/Members',
    createdByTeamAndMemberFilterTooltip: 'Filter by Scored By Teams/Members',
    scoredByFilterTitle: 'Filter by Members or AI',
    scoredByMembersFilterTitle: 'Scored by Members only',
    scoredByAiFilterTitle: 'Scored by Avoma AI only',
    scoredByFilterTooltip: 'Filter by Members or AI',
    scoredByMembersAndAi: 'Members & AI',
    scoredByMembersOnly: 'Members Only',
    scoredByAiOnly: 'AI Only',
    manualTemplatesOnlyTitle: 'Manual Scorecards Only',
    aiTemplatesOnlyTitle: 'AI Scorecards Only',
    templateTypeTooltip: 'Filter by Scorecard Type',
    scorecardsTooltip: 'Filter by Scorecards',
    filterByDealRisk: 'Filter by Deal Risk',
    filterByNextMeetingExists: 'Filter by Next Meeting Exists',
    showDeactivatedUsers: 'Show deactivated members',
    filterByConversationType: 'Filter by Conversation Type',
    filterByCallDirection: 'Filter by Call Direction',
    scoreRangeTitles: {
      zeroToOne: '0 - 1',
      oneToTwo: '1 - 2',
      twoToThree: '2 - 3',
      threeToFour: '3 - 4',
      fourToFive: '4 - 5'
    },
    scoreAverageRangeTitles: {
      overall_zeroToOne: '0 - 1',
      members_zeroToOne: '0 - 1',
      self_zeroToOne: '0 - 1',
      ai_zeroToOne: '0 - 1',

      overall_oneToTwo: '1 - 2',
      members_oneToTwo: '1 - 2',
      self_oneToTwo: '1 - 2',
      ai_oneToTwo: '1 - 2',

      overall_twoToThree: '2 - 3',
      members_twoToThree: '2 - 3',
      self_twoToThree: '2 - 3',
      ai_twoToThree: '2 - 3',

      overall_threeToFour: '3 - 4',
      members_threeToFour: '3 - 4',
      self_threeToFour: '3 - 4',
      ai_threeToFour: '3 - 4',

      overall_fourToFive: '4 - 5',
      members_fourToFive: '4 - 5',
      self_fourToFive: '4 - 5',
      ai_fourToFive: '4 - 5'
    },
    scoreRangeTooltip: 'Filter by Score',
    averageScoreRangeTooltip: 'Filter by Average Score',
    requestedByFilterTooltip: 'Filter by Requested Member or Teams',
    errorFetchingUsers: 'Error in fetching users',
    searchForKeyword: 'Search for a keyword',
    forecastCategoryTitle: 'Filter by Forecast Category',
    startSearching: 'Start searching to show companies',
    noMembers: 'No members found',
    noOutcomes: 'No outcomes found',
    noScorecards: 'No scorecards found',
    atLeastOneFilterShouldBeSelected:
      'At least one filter value should be selected',
    scorecardsMembers: `Only members with a ${PLAN_NAMES.premium} or Enterprise License will be listed below`,
    scorecardsTeams: `Only team members with a ${PLAN_NAMES.premium} or Enterprise License will be automatically scored`,
    meetingDurationPlaceholder: 'Enter value in minutes',
    companySearchPlaceholder: 'Search for a company',
    dealSearchPlaceholder: 'Search for a deal'
  },
  savedFilters: {
    listCta: 'Saved Searches',
    listCtaShort: 'Searches',
    listTitle: 'Saved Searches',
    saveTitle: 'Save Search',
    nameLabel: 'Name',
    namePlaceholder: 'Name your Saved Search',
    visibilityLabel: 'Visibility',
    alertsLabel: 'Get Email Alerts',
    alertsDescription:
      'Avoma will send email alerts when new conversations match these filter selections.',
    saveCta: 'Save Search',
    visibilityOptionOne: 'Private',
    visibilityOptionTwo: 'Organization'
  },
  savedViews: {
    listCta: 'Saved Views',
    listCtaShort: 'Views',
    listTitle: 'Saved Views',
    saveTitle: 'Save View',
    createNew: 'Create New View',
    editView: 'Edit View',
    nameLabel: 'Name',
    namePlaceholder: 'Name your Saved View',
    visibilityLabel: 'Visibility',
    saveCta: 'Save View',
    visibilityOptionOne: 'Private',
    visibilityOptionTwo: 'Organization',
    standardViews: 'Standard Views',
    myViews: 'My Views',
    teammateViews: 'Teammates’ Views',
    standardViewsDescription:
      'Created by Avoma as default views. You can not delete these views.',
    myViewsDescription:
      'Created by you based on your custom selections. You can delete these views and can modify the privacy.',
    teammateViewsDescription:
      'Created by your teammates as per their custom selections. You cannot delete these views but If deleted by the teammate, it will be lost from your viewport.',
    viewPortDescription:
      'The views at the top is called the Viewport. You can add up to 20 views here.',
    helpPopoverTitle: 'Saved Views Help',
    helpPopoverSubTitle1: 'What are Standard/My/Teammates Views?',
    helpPopoverSubTitle2: 'What is a View port?',
    helpPopoverSubTitle3: 'Add View looks like',
    helpPopoverSubTitle4: 'Viewport Drag and Remove View looks like',
    removeFromPinnedViewsTooltip:
      "Remove view from Pinned views. This doesn't delete any data.",
    savedViewHoldingTooltip: 'Hold and drag view to the left and right',
    saveSelection: 'Save Selection',
    saveAsNew: 'Save as a New View',
    saveAs: 'Save As',
    readOnlyView: 'Read only View',
    readOnlyStandaedViewDescription:
      'This is a standard view in Avoma that is editable only by admins. Please save your modifications as a new view to preserve your changes.',
    readOnlyTeammatesViewDescription:
      'This view is owned by another user and cannot be modified. Please save your modifications as a new view to preserve your changes.',
    errorInFetching: 'Error in fetching saved views',
    noMyViewsCreated:
      'You haven’t created any views yet. Click on "Create View" button to create a new view.',
    noTeamViewsCreated: "Your teammates haven't created any views yet.",
    noResults: 'No results found.',
    duplicateName: 'Duplicate name, view name must be unique',
    error: 'Something went wrong. Please try again later.',
    createView: 'Create View',
    manageView: 'Manage View',
    manageViews: 'Manage Views',
    manageViewsMobile: 'Views',
    edit: 'Edit View',
    delete: 'Delete View',
    searchPlaceholder: 'Search',
    viewDeleted: 'View deleted successfully',
    viewCreated: 'View created successfully',
    viewUpdated: 'View updated successfully',
    pinAnother: 'Please pin another view before deleting',
    deleteConfirmation: {
      meeting:
        'Once deleted, you will not be able to recover this saved view. Email and/or Slack Alerts will be disabled for all subscribed members.',
      calls:
        'Once deleted, you will not be able to recover this saved view. Email and/or Slack Alerts will be disabled for all subscribed members.',
      trackers:
        'Once deleted, this saved view and all associated sub-views will be permanently lost. Do you wish to confirm?',
      deal: "Once you delete this saved view, all filters and column preferences you've set for the deal dashboard will be permanently lost.",
      company:
        "Once you delete this saved view, all filters and column preferences you've set for the company dashboard will be permanently lost.",
      scoredConversations:
        "Once you delete this saved view, all filters you've set for scored conversations will be permanently lost. Email alerts will be disabled for all subscribed members",
      memberAnalytics:
        "Once you delete this saved view, all filters you've set for scored conversations will be permanently lost. Email alerts will be disabled for all subscribed members",
      membersPerformance:
        "Once you delete this saved view, all filters you've set for scored conversations will be permanently lost. Email alerts will be disabled for all subscribed members",
      scorecardAnalytics:
        "Once you delete this saved view, all filters you've set for scored conversations will be permanently lost. Email alerts will be disabled for all subscribed members",
      scorecardsRequests:
        "Once you delete this saved view, all filters you've set for scored conversations will be permanently lost. Email alerts will be disabled for all subscribed members"
    },

    deleteSavedView: 'Delete this Saved View?',
    confirmDelete: 'Delete',
    lastModified: 'Latest Modified',
    createdBy: 'Created By',
    actions: 'Actions',
    nameRequired: 'Name is required',
    nameExists: 'Name already exists',
    oneTabNeeded: 'You need at least one pinned view on viewport',
    max20: 'You can pin up to 20 views.',
    pin: 'Pin',
    unpin: 'Unpin',
    helpPopoverTooltip: 'Learn more about Saved Views',
    dragIconTooltip: 'Hold and drag view to the left or right',
    addViewTooltip: 'You can pin up to 20 views',
    addViewBannner:
      "Newly created view won't be pinned automatically as you already have 20 views pinned",
    updateSuccessNotification: 'Filters saved to current Dashboard View',
    alerts: {
      weeklyTooltip:
        "You will get 'Weekly' Email Alerts when new conversations are matched.",
      dailyTooltip:
        "You will get 'Daily' Email Alerts when new conversations are matched.",
      instantTooltip:
        "You will get 'Instant' Email Alerts when new conversations are matched.",
      setAlerts:
        'Get Email or Slack Alerts when new conversations are matched.',
      setScorecardsAlerts: 'Get Email Alerts when new scorecards are matched.',
      setPreference: 'Set Alerts preference',
      editPreferences: 'Edit or disable Email Alerts preference',
      helpText:
        'Avoma will send Email Alerts when new conversations match these filter selections.',
      successMessage: 'Email Alerts enabled successfully',
      slackSuccessMessage: 'Slack Alert enabled successfully',
      slackAlertRemovedMessage: 'Slack Alerts disabled successfully',
      emailAlertsRemovedMessage: 'Email Alerts disabled successfully',
      emailCheckboxLabel: 'Get email Alerts',
      savePreference: 'Save',
      changePreferenceCta: 'Change Preferences',
      disableAlertsCta: 'Disable Alerts'
    },
    gatingTooltip: planName =>
      `Creating custom saved views requires ${planName} plan or above`
  },
  searchList: {
    savedSearchName: 'Saved Search Name',
    errorInFetching: 'Error in fetching saved searches',
    emptyPrivate: "You haven't saved any search selections yet.",
    emptyShared: "There aren't any shared search selections yet.",
    changePreferences: 'Change Preferences',
    disable: 'Disable',
    daily: 'Daily',
    weekly: 'Weekly',
    cancelButtonText: 'Cancel',
    submitButtonText: 'Get Alert'
  },
  slateEditor: {
    deleteTranscript: 'Delete',
    deleteTimestampTitle: 'Delete Automated Note?',
    deleteBookmarkTitle: 'Delete Bookmark?',
    deleteTimestampText:
      'This will remove the automated note entry from your notes.'
  },
  voiceprint: {
    breadcrumb: 'Voiceprint',
    title: 'Voiceprint Settings',
    cardTitle: 'My Voiceprint',
    recording: 'Recording Now...',
    recorded: 'Your voiceprint has been recorded.',
    descriptionTitle:
      'Help Avoma learn your voice, so you can be recognized in your recordings. ',
    voiceprintExistsSubtitle: 'Your voiceprint has been created',
    rerecordCta: 'I want to re-create my voiceprint',
    recordCta: 'Create My Voiceprint',
    noPermissionSubtitle:
      'Please allow Avoma to access your microphone when prompted.',
    voiceprintModalTitle: 'Create Your Voiceprint',
    tooShort:
      'Sorry, your voice recording was too short. Please try recording your voiceprint again.',
    uploadFailed:
      'Sorry, your voiceprint recording failed to upload. Please try recording your voiceprint again.',

    recordReminder:
      'Reminder: Try to speak as close to the microphone as you can with no background noise',
    recordingReminder: 'Reminder: Speak clearly and not too quickly.',
    saveRecordingCta: 'Save Your Recording',
    tryAgainCta: 'Record again',
    uploading: 'Uploading...',
    uploadError:
      'There was a problem uploading your recording. Please try again.',

    recordingComplete: 'Recording Complete!'
  },
  comments: {
    commentAction: 'Comment',
    commentActionTooltip: 'Add Comment',
    commentPlaceholder: 'Add a comment...',
    deleteCta: 'Delete Comment',
    editCta: 'Edit Comment',
    reply: 'Reply',
    replyPlaceholder: 'Reply...',
    comments: 'comments',
    comment: 'comment',
    submitInfo: 'CMD/CTRL + ENTER to post a comment',
    commentCreated: 'Comment Posted.',
    viewComments: 'View all Comments',
    commentDeleted: 'Comment deleted.',
    confirmDelete: 'Delete this comment?',
    confirmDeleteText:
      'The comment and all of the associated replies will be deleted. \n\nOnce deleted, you will not be able to recover the comment.',
    commentBoxPlaceholder:
      'Leave a comment or use @ to mention a team or a member. CMD/CTRL + ENTER to post a comment',
    errorCreation:
      'There was a problem posting your comment. Please try again.',
    errorDeletion:
      'There was a problem deleting your comment. Please try again.',
    warnings: {
      sharedWithOrgMember:
        'Members and teams tagged in the comment will get access to the meeting and will receive an email notification.',
      sharedOutsideOrg:
        "You've tagged someone from outside organization. Tagged people will get access to the meeting and will get a notification via email.",
      cannotShare:
        'You’ve tagged a member who does not have access to view this meeting. Since you don’t have sharing access, please ask one of the meeting participants to share this meeting with the new member so they can view your comments.',
      cannotShareTeams:
        'You’ve tagged a team whose members may not not have access to view this meeting. Since you don’t have sharing access, please ask one of the meeting participants to share this meeting with the new team so they can view your comments.',
      onlyMe:
        'Comment privacy access is set to “Only me”. Update the privacy settings to allow tagged members and/or teams to view this comment.',
      privateCommentsGating: `Certain tagged member(s) will not be able to reply to this private comment as they are not on a ${PLAN_NAMES.premium} plan.`
    },
    empty: 'No comments yet!',
    emptyInfo1: 'Be the first to post a comment.',
    emptyInfo2: 'Select the transcript to start commenting.',
    create: 'Post Comment'
  },
  sharingTabs: {
    analysis: 'Analysis',
    transcript: 'Transcript',
    snippets: 'Snippets'
  },
  zoomApp: {
    title: 'Avoma App for Zoom',
    settingsDesc:
      'If you have Zoom client app installed, install Avoma app inside it to:',
    settingsPoint1:
      'Take manual notes, bookmarks for on going meeting in Zoom client',
    settingsPoint2: 'Get access to all avoma meetings inside Zoom client.',
    install: 'Install Avoma Zoom App',
    uninstall: 'Uninstall Avoma Zoom App',
    uninstallTitle: 'Uninstall Avoma app from Zoom',
    uninstallDescription:
      'If you no longer wish to access Avoma app inside Zoom client, click confirm to uninstall it.',
    connected: 'Installed!',
    uninstalling: 'Uninstalling...',
    errorGeneric: 'Error installing Avoma App',
    clickToRedirect:
      'If you are not automatically redirected to Zoom, please click ',
    chooseMeeting: 'Select which meeting you are taking notes for',
    viewAllMeetings: 'View all meetings',
    confirmModalTitle: 'Install Avoma app in Zoom',
    confirmModalText:
      'Zoom Cloud Recording will be automatically selected for Zoom conferencing once Avoma app is installed in Zoom.\n\nDo you want to install Avoma app in Zoom?'
  },
  referralProgram: {
    breadcrumb: 'Referral Program'
  },
  sharing: {
    privateText: 'Only meeting participants and people added above can access',
    primaryTeamShareText:
      'Specific teams with access and people with whom it is shared can view',
    playlistPrimaryTeamShareText:
      'Specific teams with access and people with whom it is shared can view',
    playlistPrivateShareText:
      'Only subscribers and people with whom it is shared can access',
    playlistOrganizationShareText:
      'Anyone in the organization and people with whom it is shared can access',

    playlistPrimaryTeamText:
      'Primary team of owner and people with whom it is shared can access',
    organizationText:
      'Anyone in your organization and people added above can access',
    publicText: 'Anyone with the link can access',
    snippetsSharePublicText:
      'Anyone on the internet with the link & people invited via Email can view. To view the snippet, sign-in to Avoma or requesting a one-time access code is NOT required.',
    sharePrivateText:
      'Only meeting participants and people invited via Email can view it. To view the recording, a viewer needs to sign in to Avoma, or request a one-time access code if invited via Email.',
    sharePublicText:
      'Anyone on the internet with the link & people invited via Email can view. To view the recording, sign-in to Avoma or requesting a one-time access code is NOT required.',
    snippetCreatedSuccessfully: 'Snippet created successfully',
    shareMeeting: 'Share Meeting',
    shareCall: 'Share Call',
    shareNotAllowed:
      'Only meeting participants and admins can share this meeting',
    sharingNotAllowedOrg:
      'Meeting sharing is not allowed for your organization',
    snippetSharingNotAllowedOrg:
      'Snippet sharing is not allowed for your organization',
    playlistSharingNotAllowedOrg:
      'Playlist sharing is not allowed for your organization',
    sharingNotAllowedMeeting:
      'Sharing this meeting has been prohibited by your admin',
    sharingNotAllowedSnippet:
      'Sharing a snippet of this meeting has been prohibited by your admin',
    publicPrivacyNotAllowedMeeting:
      'Changing the privacy of this meeting to public has been prohibited by your admin',
    addParticipantsNotAllowedMeeting:
      'Adding participants to this meeting manually has been prohibited by your admin',
    addingToPlaylistNotAllowedMeeting:
      'Adding this meeting to a playlist has been prohibited by your admin',
    addingToPlaylistNotAllowedSnippet:
      'Adding a snippet of this meeting to a playlist has been prohibited by your admin',
    smartPlaylistOrganizerNotAllowed:
      'Adding this organizer filter to a smart playlist has been prohibited by your admin',
    autoAccess:
      'Automatically granted access as the user belongs to the same company as one of the shared users',
    tagUserAndGroup: "Add a message/type '@' to mention a slack user/group",
    slackCustomMessage: 'Add a custom message while sharing to Slack',
    selectSlackUser: 'Select Slack users/groups you want to give access/notify',
    addParticipants: 'Add Participants',
    allParticipantsAdded: 'All participants added',
    addEmailsPlaceholder:
      'Add members, teams or external users to give access and notify them',
    optionalMessagePlaceholder: 'Add an optional message',
    playlistSharedSuccessfully: 'Playlist shared'
  },
  transcript: {
    alreadyOpen: 'Transcript is already open',
    viewTranscript: 'View Transcript',
    hideTranscript: 'Hide Transcript',
    resumeAutoScroll: 'Resume Auto-Scroll',
    successTranscriptToNotes: 'Added successfully to the notes',
    speakersPendingBanner:
      'Speaker identification and enhanced transcription is in progress and will be available shortly. Editing the transcript is disabled until then.',
    transcriptionInProcess: 'Transcription is in process...',
    upgradeMessage: 'Upgrade to get enhanced transcripts'
  },
  coaching: {
    avomaAssistant: 'Avoma Assistant'
  },
  timezoneToast: {
    title: 'Looks like your timezone has changed',
    timezoneHeader: 'Your new timezone is,',
    timezoneText:
      'All your notification emails & reminders for your meetings will now be sent via this new timezone. If you wish to change it back, visit Settings > Profile and click on Edit',
    fineCta: "It's Fine",
    settingsCta: 'Go to Settings',
    footnote:
      'Avoma uses this timezone to send notification emails and meeting reminders.'
  },
  smartNotesIllustrations: {
    title: 'Get custom Smart Notes using 2 ways',
    point1Strong: '1. Live Bookmarks',
    point1Text: '– Use during the meeting',
    point1Subtitle:
      'Bookmark important moments during your live meeting and get Smart Notes once the transcript is available.',
    point2Strong: '2. Transcript Highlights',
    point2Text: '– Use after the meeting',
    point2Subtitle:
      'Highlight text in the transcript with a Smart Category to get Smart Notes.'
  },
  smartCategoryIllustrations: {
    bookmarkHighlights: {
      title: 'Live Bookmarks and Transcript Highlights',
      liveBookmarksTitle: 'Live Bookmarks',
      transcriptHighlightsTitle: 'Transcript Highlights'
    },
    notesExtraction: {
      title: 'AI generated Smart Notes',
      triggerPromptsReference: 'Trigger Prompts as reference',
      notesBasedTriggerPrompts: 'Notes Extraction based on Trigger Prompts',
      notesBasedKeywords: 'Notes Extraction based on Keywords'
    },
    keywordTrackers: {
      title: 'Keywords Trackers',
      trendsTrack: 'Keywords Trends Tracker in Conversation Intelligence'
    },
    howItWorks: {
      title: 'Smart Notes using Smart Categories',
      configure: 'Configure Smart Categories to take your Smart Notes',
      lookLike: 'Your Smart Notes will look like'
    },
    stepByStepModal: {
      sectionTitle: {
        configure: 'Create Smart Category and configure with',
        use: 'Use',
        add: 'Add',
        track: 'Track'
      }
    }
  },
  emailAgenda: {
    notificationEmail: 'notifications@avoma.com',
    fromNameTooltip: 'Recipients will receive email with this name.',
    fromEmailTooltip: 'Recipients will receive email from this email address.',
    replyToEmailTooltip:
      'The replies to email will be sent to this email address.',
    shareNotesModalTitle: 'Share Meeting Notes',
    shareAgendaModalTitle: 'Share Meeting Agenda',
    emailInfoCta: 'How email is sent',
    fromNameText: 'From Name',
    fromEmailText: 'From Email',
    replyToEmailText: 'Reply-to Email',
    allParticipants: 'All Participants',
    internalParticipants: 'Internal Participants',
    externalParticipants: 'External Participants',
    sendToText: 'Send to',
    subject: 'Subject',
    sendEmailCta: 'Send Email',
    shareAgendaEmail: 'Share agenda via email',
    shareNotesEmail: 'Share notes via email',
    learnAddAgenda: 'Learn to add Agenda',
    insertAgenda: 'Insert Agenda section',
    addAgenda: 'Add Agenda',
    shareAgenda: 'Share Agenda',
    learnAddAgendaIllustrationTitle: 'Learn how to add Agenda',
    noParticipantSelected: 'Select participants to send email to',
    howEmailIsSentTitle: 'Email Metadata Info',
    agendaNotPicked: 'The Agenda is not picked up automatically from notes.',
    learnToAddAgenda: 'Learn how to add agenda'
  },
  illustrationPills: {
    beforeMeeting: 'Before meeting',
    duringMeeting: 'During meeting',
    afterMeeting: 'After meeting'
  },
  toasts: {
    appUpdate: {
      title: 'A new version of Avoma is available',
      description: 'Refresh to get the latest features and fixes.'
    },
    crmAuth: {
      title: 'CRM Authentication token has expired',
      description: 'Re-authenticate to continue syncing of notes to CRM.'
    }
  },
  scheduler: L10Scheduler,
  publicPrivacyModal: {
    meetingAndSnippetNotification:
      'You can always change the permission back to "Private" or "Organization".',
    playlistNotification:
      'You can always change the permission of the playlist back to "Private" and "Organization", and accordingly, you will have to change the permission of each Meeting and Snippet individually.',
    modalTitles: {
      meeting: 'Make the Meeting Public?',
      snippet: 'Make the Snippet Public?',
      playlist: 'Make the Playlist Public?'
    },
    additionalDescriptionPoints: {
      meeting:
        'To view the Meeting, sign-in to Avoma or one-time access code is NOT required.',
      snippet:
        'To view the Snippet, sign-in to Avoma or one-time access code is NOT required.',
      playlist: {
        point1:
          'All Meetings and Snippets inside the playlist will also be made Public.',
        point2:
          'To view the Playlist, sign-in to Avoma or one-time access code is NOT required.'
      }
    },
    noPrimaryTeamTooltip:
      "Your organization has set all meetings to be visible to Specific Teams but you're not assigned to any team yet. Please ask your Avoma admin to add you to a team."
  },
  dealAssociation: {
    showSelectorTooltip: 'Click to View',
    domainIdentifiedTooltip:
      'Identified based on external participants domain. Click to select Company or Contact',
    noAssociationTooltip: 'Click to select Company or Contact',
    noAssociations: 'No Associations',
    deleteCompany: 'Delete Company',
    deleteContact: 'Delete Contact',
    searching: 'Searching...',
    noResults: 'No results found',
    title: 'Title',
    email: 'Email',
    searchedResults: 'Searched Results',
    ContactCreatedByAvoma: 'Contact created by Avoma',
    LeadCreatedByAvoma: 'Lead created by Avoma',
    views: {
      association: 'association',
      account: 'account',
      contact: 'contact',
      deal: 'deal',
      lead: 'lead'
    },
    searchAssociationPlaceholder: 'Search for a Company or Contact from CRM',
    searchAccountPlaceholder: 'Search for a Company from CRM',
    searchContactPlaceholder: 'Search for a Contact from CRM',
    searchLeadPlaceholder: 'Search for a Lead from CRM',
    searchDealPlaceholder: 'Search for deals associated with companies',
    noCompanySelected: 'No Company Selected',
    noContactSelected: 'No Contact Selected',
    noLeadSelected: 'No Lead Selected',
    noDealSelected: 'No Deal Selected.',
    navigateToDealView: 'Navigate to the deal tab to associate a deal',
    selectCompaniesForDeals: 'Select a Company to see associated Deals.',
    header: {
      deal: 'Deal',
      association: 'Associations',
      account: 'Company',
      contact: 'Contact',
      lead: 'Lead'
    },
    internalMeeting: 'Internal',
    associateDealCheckboxTooltip: 'Click to associate deal with meeting.',
    removeDealAssociatedCheckboxTooltip: 'Click to remove deal from meeting.',
    removeContactAssociationTooltip:
      'Remove this contact association from this meeting',
    removeAccountAssociationTooltip:
      'Remove this company association from this meeting',
    noPermissionTooltip: `You need to be on the ${PLAN_NAMES.lite} plan or above to edit this`,
    viewDealInAvoma: 'View Deal in Avoma',
    viewAccountInAvoma: 'View Company in Avoma',
    viewCompanyIn: 'View Company in ',
    viewCompanyInCRM: 'View Company in CRM',
    viewDealInCRM: 'View Deal in CRM',
    viewContactInCRM: 'View Contact in CRM',
    viewAllClosedDeals: 'View all closed deals',
    dealStage: 'Deal Stage:',
    viewMeetings: 'View Meetings',
    companyFilterMessage: 'Company filter updated'
  },
  webexIntegration: {
    title: 'WebEx Integration',
    connectCta: 'Connect',
    intro: 'Connect your WebEx account to Avoma to:',
    point1: 'Access all your WebEx call recordings in one place',
    point2:
      'Analyze your calls and get Transcript, Notes, Conversation Insights, and more',
    success: 'WebEx was successfully connected!',
    errorGeneric: 'There was a problem linking your WebEx account',
    errorBadRequest:
      'The WebEx account used is already linked with another Avoma account.',
    connected: 'Connected to WebEx',
    unlinkTitle: 'Disconnect WebEx account from Avoma',
    unlinkCta: 'Unlink',
    unlinkText:
      'Are you sure you want to disconnect and unlink your WebEx account from Avoma?',
    unlinkText2:
      'With this, you will no longer be able to have Avoma process your WebEx call recordings.',
    adminWarning:
      "Please contact your administrator to connect your organization's WebEx account",
    connectWebexWarning: 'Connect to WebEx to enable WebEx cloud recording.',
    webexCloud: 'WebEx Cloud Recording',
    connectedWebexAccount: 'Connected to the WebEx account belonging to',
    disconnect: 'Disconnect WebEx',
    webexHelpCardTitle: 'For Avoma to process your WebEx recordings',
    webexHelpCardBody: {
      point1:
        'You need to be on Meet, Meet + Call, Enterprise plan to enable Cloud Recording in WebEx.',
      point2:
        'To turn on auto recordings, you need to request your Admin to do so in WebEx Settings.'
    }
  },
  msftCldIntegration: {
    title: 'Microsoft Integration',
    connectCta: 'Connect',
    intro: 'Connect your Microsoft account to Avoma to:',
    point1: 'Access all your Microsoft Cloud recordings in one place',
    point2:
      'Analyze your calls and get Transcript, Notes, Conversation Insights, and more',
    success: 'Microsoft was successfully connected!',
    errorGeneric: 'There was a problem linking your Microsoft account',
    errorBadRequest:
      'The Microsoft account used is already linked with another Avoma account.',
    connected: 'Connected to Microsoft',
    unlinkTitle: 'Disconnect Microsoft account from Avoma',
    unlinkCta: 'Unlink',
    unlinkText:
      'Are you sure you want to disconnect and unlink your Microsoft account from Avoma?',
    unlinkText2:
      'With this, you will no longer be able to have Avoma process your Microsoft Cloud recordings.',
    adminWarning:
      "Please contact your administrator to connect your organization's Microsoft account",
    connectMsftCldWarning:
      'Connect to Microsoft to enable Microsoft cloud recording.',
    msftCldCloud: 'Microsoft Cloud Recording',
    connectedMsftCldAccount: 'Connected to the Microsoft account belonging to',
    disconnect: 'Disconnect Microsoft',
    msftCldHelpCardTitle: 'For Avoma to process your Microsoft recordings',
    msftCldHelpCardBody: {
      point1: `You need to be on ${PLAN_NAMES.premium} plan to enable Cloud Recording in Microsoft.`,
      point2:
        'Please turn on auto cloud recordings, or request your Admin to do so in Microsoft Settings.',
      point3: 'You should be connected to Outlook or Microsoft Calendar.'
    }
  },
  gmeetCldIntegration: {
    title: 'Google Meet Integration',
    connectCta: 'Connect',
    intro: 'Connect your Google account to Avoma to:',
    point1: 'Access all your Google Meet Cloud recordings in one place',
    point2:
      'Analyze your calls and get Transcript, Notes, Conversation Insights, and more',
    success: 'Google Meet was successfully connected!',
    errorGeneric: 'There was a problem linking your Google account',
    errorBadRequest:
      'The Google account used is already linked with another Avoma account.',
    connected: 'Connected to Google Meet',
    unlinkTitle: 'Disconnect Google Meet account from Avoma',
    unlinkCta: 'Unlink',
    unlinkText:
      'Are you sure you want to disconnect and unlink your Google Meet account from Avoma?',
    unlinkText2:
      'With this, you will no longer be able to have Avoma process your Google Meet Cloud recordings.',
    adminWarning:
      "Please contact your administrator to connect your organization's Google account",
    connectGmeetCldWarning:
      'Connect to Google to enable Google Meet cloud recording.',
    gmeetCldCloud: 'Google Meet Cloud Recording',
    connectedGmeetCldAccount: 'Connected to the Google account belonging to',
    disconnect: 'Disconnect Google',
    gmeetCldHelpCardTitle: 'For Avoma to process your Google Meet recordings',
    gmeetCldHelpCardBody: {
      point1: `You need to be on ${PLAN_NAMES.premium} plan to enable Cloud Recording in Google Meet.`,
      point2:
        'Please turn on auto cloud recordings, or request your Admin to do so in Google Settings.',
      point3: 'You should be connected to Google Calendar.'
    }
  },
  editableDealFields: {
    contactAdminTooltip:
      'Contact your CRM admin to get access to edit this field',
    contactAvomaAdminTooltip:
      'Contact your Avoma admin to get access to edit this field',
    hoverToUpdate: fieldName =>
      `You can update the ${fieldName}, hover on it to update.`,
    owner: {
      searchByOwner: 'Search for an owner',
      editTooltip: 'You can update the deal owner, hover on it to update.'
    },
    stage: {
      popoverTitle: 'Select deal stage',
      editTooltip: 'You can update the deal stage, hover on it to update.'
    },
    amount: {
      editTooltip: 'You can update the deal amount, hover on it to update.'
    },
    closeDate: {
      editTooltip: 'You can update the deal close date, hover on it to update.'
    }
  },
  globalErrorHandler: {
    heading: 'Aaah! Something went wrong',
    subHeadingButtonsInfo: 'You may hit refresh or go back to home.',
    subHeadingInfo:
      'We have noted this error and we are working hard to not let this happen again.'
  },
  scorecards: {
    scorecards: 'Scorecards',
    startScoringCta: 'Start Scoring',
    aiScoringCta: 'Start AI Scoring',
    startScoringTitle: 'Add Score for',
    editScoringTitle: 'Edit Score for',
    requestCoachingCta: 'Request Coaching',
    memberDropdownLabel: 'Member',
    memberLabel: 'Member',
    evaluatorDropdownLabel: 'Evaluator',
    scorecardDropdownLabel: 'Scorecard',
    scorecardDropdownTitle: 'Scorecards',
    scorecardLabel: 'Scorecard',
    memberDropdownHelpText: 'Select Member',
    scorecardDropdownHelpText: 'Select Scorecard',
    selfScoringMessage: 'You have selected yourself for self-scoring.',
    duplicateScoringMessage:
      'You have already added or started an evaluation for the selected member using the selected scorecard.',
    noTranscriptionMessage:
      'Meeting recording and transcript must be available to starting scoring a member.',
    featureNotAllowedMessage: `Please upgrade to a ${PLAN_NAMES.plus} plan or above to start using scorecards.`,
    noAnswerBannerContainer:
      'You must answer at least one question to submit this score.',
    evaluatorSelectionMessage:
      'Please note that the evaluator will be granted access to this private meeting.',
    answerCommentWarning: 'Score this question to leave a comment.',
    long: {
      allScorecards: 'All Scorecards',
      manualScorecards: 'Manual Scorecards',
      aiScorecards: 'AI Scorecards',
      scorecardQuestions: 'Scorecard Questions',
      membersPerformance: 'Members Performance'
    },
    short: {
      allScorecards: 'All',
      manualScorecards: 'Manual',
      aiScorecards: 'AI'
    },
    searchMemberLabel: 'Search by name or email',
    searchScorecardLabel: 'Search by scorecard name',
    tabHeaderLabel: 'Score This Meeting',
    noResultsMembers: 'No members found',
    noResultsScorecards: 'No scorecards found',
    overallFeedback: 'Overall Feedback',
    overallFeedbackTooltip: 'Add Overall Feedback',
    commentsPlaceholder: 'Add a comment for this answer',
    overallFeedbackPlaceholder: 'Add overall feedback for this score',
    memberDropdownHelp: 'Please select a member you want to score.',
    evaluatorDropdownHelp:
      'Please select a member you want to request a score from.',
    scorecardDropdownHelp: 'Please select a scorecard to use for scoring.',
    pastScoreHeading: 'Past Scores',
    viewScorecard: 'View',
    continueScoring: 'Continue',
    startRequestScoring: 'Start',
    deleteScorecard: 'Delete Score',
    deleteDraft: 'Delete Draft',
    deleteRequest: 'Delete Request',
    scorecardTitle: 'Scorecard',
    scoredByTitle: 'Scored by',
    editedByTitle: 'Edited by',
    scoreTitle: 'Score',
    draft: 'Draft',
    comment: 'Comment',
    comments: 'Comments',
    unansweredPopoverTitle: 'Unanswered Question',
    unansweredPopoverContent:
      'Unanswered questions are omitted from the final score average.',
    aiScoreOverallFeedbackPlaceholder:
      'Overall feedback is not provided by AI scoring.',
    noOverallFeedback: 'Overall feedback was not added.',
    noComments: 'Comments were not added',
    notAnswered: 'Not answered',
    editScoreModalTitle: 'Edit this AI Score?',
    editScoreModalText:
      'Please note that you are attempting to edit a scorecard that was scored by Avoma AI.',
    editModalYes: 'Proceed',
    deleteModalYes: 'Delete',
    deleteScoreModalTitle: 'Delete this Scorecard?',
    deleteScoreModalText:
      'Once deleted, you will not be able to recover this scorecard.',
    deleteRequestModalTitle: 'Delete this Request?',
    deleteRequestModalText:
      'Once deleted, you will not be able to recover this request.',
    cancelScorecardModal: {
      text1: 'Once canceled, you will not be able to recover this scorecard.',
      text2:
        'You can save the responses as a draft and continue scoring later.',
      title: 'Cancel Scoring?',
      continueCta: 'Continue Scoring',
      cancelCta: 'Yes, Cancel',
      saveAsDraft: 'Save as Draft'
    },
    cancelEditScorecardModal: {
      text1:
        'Once canceled, you will not be able to recover the edited scorecard.',
      title: 'Cancel Editing Score?',
      cancelCta: 'Yes, Cancel',
      continueEditing: 'Continue Editing'
    },
    aiScoreInProgress: 'Generating AI score...',
    aiScoreSuccess: 'AI scoring complete!',
    aiScoreError: 'Error occured while AI scoring',
    deleteScoreSuccess: 'Successfully deleted scorecard',
    deleteScoreError: 'Error occurred while deleting scorecard',
    scoreSubmitSuccess: 'Score successfully added',
    scoreSubmitError: 'Score submition failed',
    scoreEditSuccess: 'Score successfully edited',
    scoreEditError: 'Score edit failed',
    requestScoreSuccess: 'Request successfully sent',
    requestScoreError: 'Scoring request failed',
    requestDuplicateScoreError: 'Scorecard already exists for this evaluator',
    cancelScorecard: 'Scoring cancelled',
    next: 'Next',
    previous: 'Previous',
    submit: 'Submit',
    updateScore: 'Update Score',
    request: 'Request',
    cancel: 'Cancel',
    notallowedTitle: 'You do not access to Scorecards',
    notScoredTitle: 'No scores yet!',
    notScoredSubtitle: 'Be the first to post a score.',
    notScoredSubtitle2: 'Select a member and scorecard to start.',
    commentSubmitError: 'Error submitting comment',
    commentEditError: 'Error editing comment',
    dashboardTitle: 'Coaching',
    headerScorecardsPerformance: 'Scorecards Performance',
    headerMembersPerformance: 'Members Performance',
    headerScoredConversations: 'Scored Conversations',
    headerReceived: 'Scores Received',
    headerGiven: 'Scores Given',
    headerRequests: 'Requests',
    myScoresTab: 'My Scores',
    teamScoresTab: `Team Scores`,
    allScoresTab: 'All Scores',
    requestsReceivedTab: 'Requests Received',
    requestedByMeTab: 'Requested By Me',
    meetingHeaderTooltip: 'Meeting for which the member was scored for',
    viewScore: 'View Score',
    startScoring: 'Start Scoring',
    noPurposeSelected: 'No purposes assigned',
    noTeamsSelected: 'No teams assigned',
    noTeamsFound: 'No teams found',
    allTeamsSelected: 'All teams assigned',
    emptyScoreReceived: `No scores for any meetings yet!`,
    emptyScoreGiven: `No members have been scored yet!`,
    emptyScoringRequests: `No members have received any requests yet!`,
    noResultsMatchingFilters: 'No matching results for the applied filters',
    noReactions: 'No Reactions',
    emptyScoresReceivedHeading: 'No scores received for the applied filters',
    emptyScoresGivenHeading: 'No scores given for the applied filters',
    emptyRequestsReceivedHeading:
      'No requests received for the applied filters',
    emptyRequestedByMeHeading: 'No requests made for the applied filters',
    emptyScoresGivenSubheading: [
      'To score a member, go to a meeting and navigate to the Scorecards Tab',
      'Choose a member you want to score and select a Scorecard',
      "Click on 'Start Scoring' to get started!"
    ],
    emptyRequestedByMeSubheading: [
      'You can always request a peer or leader to score you for a meeting',
      'To request a score, go to a meeting and navigate to the Scorecards Tab',
      "Click on 'Request Coaching' to get started!"
    ],
    emptyRequestsReceivedSubheading: [
      'You will see all active requests by members of your organization',
      'Once requests appear, you can score them for the meeting'
    ],
    emptyMembersPerformanceHeading: 'No members found for the applied filters',
    emptyMemberAnalyticsHeading: 'No scorecards found for the applied filters',
    emptyScorecardAnalyticsHeading:
      'No scorecard questions found for the applied date range',
    goToMeetingsCta: 'Go to Meetings',
    manualScorecardTemplate: 'Manual Scorecard',
    manualScorecardTemplateBadge: 'Manual',
    aiScorecardTemplate: 'AI Scorecard',
    aiScorecardTemplateTitle: 'AI Scorecard',
    aiScorecardTemplateBadge: 'AI',
    scorecardsPerformanceTitle: 'Scorecards Performance Statistics',
    membersPerformanceTitle: 'Members Performance Statistics',
    memberInformationTitle: 'Member Statistics',
    scorecardInformationTitle: 'Scorecard Statistics',
    scoreDistributionPopoverTitle: 'Latest Conversations with Score',
    generatingAiScore: 'Generating score...',
    editedScorecardTitle: 'Outdated Score',
    editedScorecardWarning:
      'Please edit or delete the scorecard for accurate and up-to-date scores, as it may have been modified after use.',
    memberDidNotSpeak: 'This member did not speak during the conversation',
    goToScorecardDashboardCta: 'Go to Scorecard dashboard',
    manageScorecardLink: 'Manage Scorecard',
    viewScorecardLink: 'View Scorecard',
    viewPerformanceLink: 'View Performance',
    conversations: 'Conversations',
    conversation: 'Conversation',
    requested: 'Requested',
    for: 'Scored for',
    by: 'Scored by',
    editedBy: 'Edited by',
    tooltips: {
      goBack: 'Back to scorecards',
      goBackToMembersPerformance: 'Back to Members Performance',
      goBackToMember: 'Back to Member Details',
      moreActions: 'More Actions',
      expandView: 'Expand View',
      collapseView: 'Collapse View',
      reply: 'Reply',
      editScore: 'Edit Score',
      ai: 'Scored by Avoma AI',
      viewMembersPerformanceDashboard: 'View Members Performance dashboard'
    }
  },
  requestAdmin: {
    noAdminError: 'No Admin Found',
    admins: 'Admins',
    emailModalSubject: 'Request to access additional features in Avoma',
    emailModalTitle: 'Request Avoma license to Admins!',
    mailSentSuccess: 'Request mail sent successfully',
    requestModalText1: `Purchase Avoma license for ${PLAN_NAMES.lite} and above plans to use more Avoma features.`,
    requestModalText2:
      'To gain access to these features, please request a license from one of our Admins.',
    requestModalText3:
      'This will ensure that you have the necessary permission to use the app to its full potential.',
    features: {
      recordingMinutes: 'Increase Recording Minutes',
      uploadMeeting: 'Upload Meeting',
      recordImpromptu: 'Record Impromptu Meeting'
    }
  },
  emailIntroductions: {
    notesIntro:
      'Thanks again for your time today. Here are the brief notes from our meeting:',
    requestLicense: {
      text1:
        'I have been exploring Avoma features and have noticed that there is an additional feature set that I would like to use to enhance my experience on the platform. However, it appears that I do not have access to this feature at the moment.',
      text2: 'Could you please provide me with access to this feature?',
      text3: ' to access the billing page to assign a license or buy one.',
      featureRequested: 'Feature Requested: ',
      currentLicense: 'Current License: '
    }
  },
  gotoconnectIntegration: {
    title: 'GoTo Connect Integration',
    settingsTitle: 'GoTo Connect',
    subtitle: `Please note that admins can connect to the dialer but the calls will be processed only if call owners have a ${PLAN_NAMES.plus} or higher plan within Avoma.`,
    connectCta: 'Connect to GoTo Connect',
    disconnect: 'Disconnect GoTo Connect',
    intro: 'Connect your GoTo Connect account to Avoma to:',
    point1: 'Access all your GoTo Connect call recordings in one place.',
    point2:
      'Analyze your calls and get Transcript, Notes, Conversation Insights, and more.',
    success: 'GoTo Connect was successfully connected!',
    errorGeneric: 'There was a problem linking your GoTo Connect account',
    errorBadRequest:
      'The GoTo Connect account used is already linked with another Avoma account.',
    connected: 'Connected to GoTo Connect.',
    unlinkTitle: 'Disconnect GoTo Connect account from Avoma',
    unlinkCta: 'Unlink',
    unlinkText: 'Avoma will stop processing your GoTo Connect call recordings.',
    helpTooltip: 'Step-by-step instructions for GoTo Connect  integration',
    helpDocUrl: 'https://help.avoma.com/avoma-integration-with-goto-connect',
    contactAdmin:
      "Please contact your administrator to connect your organization's GoTo Connect account.",
    info: `Please note that admins can connect to the dialer but the calls will be processed only if call owners have a ${PLAN_NAMES.plus}  or higher plan.`
  },
  vonageIntegration: {
    title: 'Vonage Integration',
    settingsTitle: 'Vonage Business Communications',
    subtitle: `Please note that admins can connect to the dialer but the calls will be processed only if call owners have a ${PLAN_NAMES.plus} or higher plan within Avoma.`,
    connectCta: 'Connect to Vonage',
    disconnect: 'Disconnect Vonage',
    intro: 'Connect your Vonage account to Avoma to:',
    point1: 'Access all your Vonage call recordings in one place.',
    point2:
      'Analyze your calls and get Transcript, Notes, Conversation Insights, and more.',
    success: 'Vonage was successfully connected!',
    errorGeneric: 'There was a problem linking your Vonage account',
    errorBadRequest:
      'The Vonage account used is already linked with another Avoma account.',
    connected: 'Connected to Vonage.',
    unlinkTitle: 'Disconnect Vonage account from Avoma',
    unlinkCta: 'Unlink',
    unlinkText: 'Avoma will stop processing your Vonage call recordings.',
    helpTooltip: 'Step-by-step instructions for Vonage  integration',
    helpDocUrl:
      'https://help.avoma.com/avoma-integration-with-vonage-business-communications',
    contactAdmin:
      "Please contact your administrator to connect your organization's vonage account.",
    info: `Please note that admins can connect to the dialer but the calls will be processed only if call owners have a ${PLAN_NAMES.plus} or higher plan.`
  },
  chapters: {
    infoSubtitle:
      'Organize your recording into chapters for quick navigation and easier playback',
    addNewChapter: 'Add a new chapter',
    generateChapters: 'Generate Chapters with AI',
    generatingChapters: 'Generating chapters.',
    generatedChapters: 'Chapters generated by AI successfully.',
    noChapters: 'No chapters!',
    chapterPlaceholder: 'Chapter title',
    deleteChapterTitle: 'Delete this chapter?',
    deleteChapterText: 'Are you sure you want to delete this chapter?',
    deleteChapter: 'Delete chapter'
  },
  dealsQuickView: {
    commentDeleteConfirmationBody:
      'The comment will be deleted but all of the associated replies will be shown. \n\nOnce deleted, you will not be able to recover the comment.',
    deletedCommentInfo:
      'Message replies can be seen even if the primary message is deleted.'
  },
  errorMessages: {
    default: 'Aaah! Something went wrong'
  },
  privacyAuditTrail: {
    viewChangeLogCta: 'View Change Log',
    modalTitle: 'Change Log for "Privacy Behaviour for External Meetings"',
    modalTitleForInternalMeetings:
      'Change Log for "Privacy Behaviour for Internal Meetings"'
  },
  externalStoreCTA: {
    header: 'Download extension',
    chrome: 'Chrome extension',
    appStore: 'iOS app',
    playStore: 'Android app',
    tooltips: {
      chrome: 'Download Avoma Scheduler Chrome extension',
      appStore: 'Download Avoma iOS app',
      playStore: 'Download Avoma Android app'
    }
  },
  calendarView: {
    previousWeek: 'Previous Week',
    nextWeek: 'Next Week',
    previousDay: 'Previous Day',
    nextDay: 'Next Day',
    weekly: 'Weekly',
    daily: 'Daily',
    popoverTitle: 'Select default view'
  },
  crmNotesSyncConfirmationModal: {
    autoSyncCrmNotesOn:
      'Enable Automatic meeting note sync to CRM records for all users?',
    autoSyncCrmNotesOff:
      'Disable Automatic meeting note sync to CRM records for this user?',
    syncNotesToEventOn:
      'Enable Automatic meeting note sync to salesforce’s event object for all users?',
    syncNotesToEventOff:
      'Disable Automatic meeting note sync to salesforce’s event object for this user?',
    syncNotesToTaskOn:
      'Enable Automatic meeting note sync to salesforce’s task object for all users?',
    syncNotesToTaskOff:
      'Disable note sync settings to salesforce’s task object for all users of the org?',
    inviteUserCta: 'Invite Them',
    inviteUserCta2: 'Invite Users',
    inviteUserDialogTitle: 'Invite your CRM users to Avoma',
    inviteUserHelp: 'Need to add more users from CRM?',
    headerText: 'Notes sync settings',
    inviteSuccess: 'Invitation sent successfully',
    eventObjectTooltip:
      ' Notes will be saved into Event object under Description field. This will also be visible under Activity Timeline (Feed) for the respective records',
    taskObjectTooltip:
      'Notes will be saved into Task object under comments field. This will also be visible under Activity Timeline (Feed) for the respective records',
    inviteUserEmptyMessage:
      'Every user that exists in CRM is already invited to Avoma',
    crmErrorTooltip:
      'External meetings privacy is not set to Organization so meeting notes will not be synced automatically. You can modify the privacy or sync notes manually'
  },
  crmDealAmountField: {
    saveSuccess: 'Deal amount field updated successfully',
    saveSuccessCloseDate: 'Deal close date field updated successfully',
    confirmSaveText:
      "Once this change is made we run many changes in the background and hence you cannot undo the change once it's selected. You will have to contact Avoma in case you still want a change.",
    cardTitle: 'Customize deal information',
    cardSubText:
      'We are currently using the standard deal amount field from your CRM. If you use any different field, choose it below to display the selected deal amount field at deals pipeline, company views, forecast deal amount, deal win/loss, and all reports.',

    closeDateSubText:
      'Select field from CRM that you want to use to display close date for deals in pipeline, forecast, deal win/loss and all reports.',
    selectPlaceholder: 'Select deal amount field',
    selectPlaceholderCloseDate: 'Select deal close date field',
    confirmChanges: 'Confirm changes',
    confirmModalTitle: 'Confirm field change',
    cardSubTitle: 'Deal amount',
    dealCloseDate: 'Deal close date',
    infoTooltip:
      "Fields displayed in the dropdown are the one's that admins have imported in Avoma's CRM setting > Objects tab.",
    selectHelpText: 'Choose field from Deal object',
    bannerText:
      'The admin has modified this setting before. If you want to modify it again, '
  },
  meetingTranslation: {
    buttonLabel: 'Translate',
    dialogTitle: 'Translate Notes and Transcript?',
    preferencesLabel: 'Language Preferences',
    selectLabel: 'Select Language',
    selectTitle: 'Select a Language',
    searchPlaceholder: 'Search for a language',
    notification:
      'The translated notes and transcript will be visible only to you in a "Read only" format.',
    translateLabel: 'View meeting transcript and notes in a different language',
    notesErrorTitle: 'Unable to translate notes into',
    notesErrorMessage: language =>
      `There was an unexpected error translating notes into ${language}.  Please try again later.`,
    transcriptErrorTitle: 'Unable to translate the transcript into',
    transcriptErrorMessage: language =>
      `There was an unexpected error translating the transcript into ${language}.  Please try again later.`,
    errorSupport:
      'If this problem persists across multiple meetings, please contact Avoma support.',
    errorCTA: 'View in Original Language',
    translationInProgressTitle: 'Translation in progress...',
    translationInProgressMessage: language => (
      <React.Fragment>
        Your transcript and notes are being translated into{' '}
        <strong>{language}</strong>. Please wait for the translation to
        complete.
      </React.Fragment>
    ),
    alreadyTranslated: 'Translation available',
    originalLanguage: 'Original',
    limitReached: 'You have exhausted all 3 language translation attempts.'
  },
  regenerateNotes: {
    buttonLabel: 'Regenerate',
    refreshNotes: 'Refresh Notes',
    generatingBannerText:
      'Notes are being regenerated and will be ready in a minute. Editing is disabled until then. Previous notes can be accessed in the regeneration history.',
    dialog: {
      title: 'Regenerate Notes?',
      withoutTemplateText: 'Let AI identify topics without a template',
      withTemplateTooltip:
        'Generates key takeaways, action items and notes based on categories specified in the selected template',
      withoutTemplateTooltip:
        'Generates key takeaways, action items and notes based on topics identified by AI',
      useDifferentTemplateText: 'Use a different template',
      useTemplateText: 'Use a template',
      selectTemplate: 'Select a template',
      textReplaceBanner:
        'The existing notes will be replaced by new AI-generated notes and saved in version history.',
      upgradeBannerText:
        'Please upgrade your plan to use this feature for this meeting.',
      purchaseBannerText: `You have reached the limit for regenerating these notes. Please upgrade to a ${PLAN_NAMES.premium} plan to continue using this feature.`,
      remainingAttemptsText: 'attempts remaining'
    },
    successNotification: 'Your notes are being generated',
    noTemplateSelectedTooltip: 'Please select a notes template first',
    deactivatedUserDisabledReason:
      'Notes regeneration is unavailable for this meeting because the meeting owner has been deactivated.',
    notAllowedToRegenReason: 'Only participants and admins can regenerate notes'
  },
  privacyUpdatedSuccessMessage: 'Access permissions updated',
  privacyUpdatedErrorMessage: 'Error updating access permissions'
});

export default L10n;
