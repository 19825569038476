import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import stylePropType from 'react-style-proptype';

import AvomaButton from 'components/Common/AvomaButton';
import AvomaCount from 'components/Common/AvomaCount';
import AvomaPopover from 'components/Common/AvomaPopover';
import ButtonUnstyled from 'components/Common/ButtonUnstyled';
import TextField from 'components/Common/TextField';
import VariationGroup from 'components/SmartCategories/Common/VariationGroup';

import L10n from 'helpers/L10n';

import { ReactComponent as CloseIcon } from 'images/ic_cross.svg';
import { ReactComponent as DeleteIcon } from 'images/ic_delete.svg';

import Tokens from 'styles/tokens';

const VariationPopover = ({
  showPopover,
  handleClosePopover,
  variationCount,
  variations,
  word,
  isKeyword,
  onClickDelete,
  onClickDeleteVariation,
  onCreateVariation,
  variationExistsError,
  onUpdateLabel,
  onClickMakePrimary,
  popoverPositionStyles,
  variationPopoverTitle,
  variationExistsMessage,
  popoverWidth,
  isMakePrimaryActionAllowed = true,
  isUnifiedFlow = false,
  hasDuplicateKeyword = false,
  hasDuplicateVariation = false
}) => {
  const [text, setText] = useState('');
  // If the variation inline edit input is visible, disable the Add variation input field
  const [disableTextInput, setDisableTextInput] = useState(false);

  const handleTextChange = event => setText(event.target.value);

  const handleKeyDownAddButton = event => {
    switch (event.keyCode) {
      case 13: // Enter key
        handleCreateVariation();
        break;
      default:
        break;
    }
  };

  const handleCreateVariation = () => {
    onCreateVariation({
      label: text,
      parentUuid: word.uuid
    });
    setText('');
  };

  // Disable the add variation input if inline editing of variation label is in place
  const disableAddInput = value => {
    setDisableTextInput(value);
  };

  return (
    showPopover && (
      <AvomaPopover
        width={popoverWidth || 300}
        wrapperStyle={{
          ...styles.popover,
          ...popoverPositionStyles
        }}
        style={{ padding: 0 }}
        className='max-h-[40vh] overflow-y-auto overflow-x-hidden'
      >
        <p style={styles.title}>
          <div style={{ display: 'flex' }}>
            <span>{variationPopoverTitle}</span>
            <AvomaCount count={variationCount} style={styles.count} />
          </div>
          <div className='flex'>
            <ButtonUnstyled
              onClick={() => onClickDelete(word.uuid)}
              className='ml-1 flex cursor-pointer rounded bg-none hover:!bg-smoke disabled:hover:!bg-transparent'
              tooltip={
                isMakePrimaryActionAllowed
                  ? L10n.general.delete
                  : L10n.customCategory.cannotDeleteCRMKeywords
              }
              tooltipPosition={
                isMakePrimaryActionAllowed
                  ? 'bottom'
                  : 'bottom-left wrapped-small'
              }
              disabled={!isMakePrimaryActionAllowed}
              disabledStyles={{
                cursor: 'not-allowed'
              }}
            >
              <DeleteIcon
                className={`h-4 w-4 p-1 ${
                  isMakePrimaryActionAllowed
                    ? 'hover:opacity-70 opacity-50'
                    : 'opacity-30'
                }`}
              />
            </ButtonUnstyled>

            <ButtonUnstyled
              onClick={handleClosePopover}
              className='ml-1 flex cursor-pointer rounded bg-none hover:!bg-smoke disabled:opacity-50'
              tooltip={L10n.general.close}
            >
              <CloseIcon className='h-4 w-4 p-1 hover:brightness-[0.25] brightness-[0.6]' />
            </ButtonUnstyled>
          </div>
        </p>
        <div style={styles.textContainer}>
          <TextField
            placeholder={L10n.customCategory.addNewVariation}
            value={text}
            onChange={handleTextChange}
            onKeyDown={handleKeyDownAddButton}
            containerStyles={styles.textInput}
            userStyles={styles.textInput}
            disabled={disableTextInput}
          />
          <AvomaButton
            onClick={handleCreateVariation}
            label={L10n.general.add}
            style={{ marginLeft: Tokens.spacing.one }}
            small
            disabled={disableTextInput || isEmpty(text)}
          />
        </div>
        {(variationExistsError ||
          hasDuplicateKeyword ||
          hasDuplicateVariation) && (
          <div style={styles.error}>{variationExistsMessage}</div>
        )}
        <VariationGroup
          word={word}
          isKeyword={isKeyword}
          onClickDeleteVariation={onClickDeleteVariation}
          onUpdateLabel={onUpdateLabel}
          disableAddInput={disableAddInput}
          onClickMakePrimary={onClickMakePrimary}
          variations={variations}
          isMakePrimaryActionAllowed={isMakePrimaryActionAllowed}
          isUnifiedFlow={isUnifiedFlow}
        />
      </AvomaPopover>
    )
  );
};

const styles = {
  popover: {
    position: 'absolute',
    zIndex: 10,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    top: '24%',
    marginTop: Tokens.spacing.four,
    marginLeft: '-2px',
    paddingTop: Tokens.spacing.two,
    paddingBottom: Tokens.spacing.two,
    whiteSpace: 'nowrap'
  },
  title: {
    ...Tokens.type.bodyS,
    color: Tokens.colors.black,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${Tokens.spacing.two}`
  },
  textContainer: {
    display: 'flex',
    margin: Tokens.spacing.two
  },
  textInput: {
    height: Tokens.spacing.four,
    width: '100%',
    fontWeight: 'normal',
    paddingLeft: Tokens.spacing.half
  },
  variationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  variation: {
    ...Tokens.type.body,
    fontWeight: 'bold',
    color: Tokens.colors.blue,
    marginBottom: Tokens.spacing.one
  },
  count: {
    marginLeft: Tokens.spacing.one,
    minWidth: Tokens.spacing.two,
    height: '20px'
  },
  error: {
    ...Tokens.type.bodyS,
    color: Tokens.colors.avomaRed,
    marginLeft: Tokens.spacing.two,
    whiteSpace: 'break-spaces'
  }
};

VariationPopover.propTypes = {
  showPopover: PropTypes.bool.isRequired,
  variationCount: PropTypes.number,
  onCreateVariation: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickDeleteVariation: PropTypes.func,
  word: PropTypes.object,
  variationExistsError: PropTypes.bool,
  onUpdateLabel: PropTypes.func,
  onClickMakePrimary: PropTypes.func,
  popoverPositionStyles: stylePropType,
  handleClosePopover: PropTypes.func,
  variationPopoverTitle: PropTypes.string,
  variations: PropTypes.object,
  isKeyword: PropTypes.bool,
  variationExistsMessage: PropTypes.string,
  popoverWidth: PropTypes.number,
  isMakePrimaryActionAllowed: PropTypes.bool,
  isUnifiedFlow: PropTypes.bool,
  hasDuplicateKeyword: PropTypes.bool,
  hasDuplicateVariation: PropTypes.bool
};

export default VariationPopover;
