import { CoachingActions } from 'actions/actionTypes';

const initialState = {
  speakerChanges: [],
  userIds: {},
  screenNames: {}
};

const coaching = (state = initialState, action) => {
  switch (action.type) {
    case CoachingActions.SPEAKER_EVENT: {
      return {
        ...state,
        speakerChanges: [
          ...state.speakerChanges,
          {
            timestamp: action.timestamp,
            status: action.status,
            service: action.service
          }
        ]
      };
    }
    case CoachingActions.SET_USER_ID: {
      return {
        ...state,
        userIds: {
          ...state.userIds,
          [action.service]: action.userId
        }
      };
    }
    case CoachingActions.SET_SCREEN_NAME: {
      return {
        ...state,
        screenNames: {
          ...state.screenNames,
          [action.service]: action.screenName
        }
      };
    }
    default:
      return state;
  }
};

export default coaching;
