/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import _ from 'lodash';
import Radium from 'radium';
import Tokens from 'styles/tokens';
import OutsideClickHandler from 'react-outside-click-handler';
import { ReactComponent as DownArrowIcon } from 'images/downArrow.svg';

class AvomaDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      value: props.value
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value });
  }

  onCloseMenu = () => {
    this.setState({ isOpen: false });
  };

  toggleMenu = event => {
    if (!this.state.isOpen) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  select = item => event => {
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    if (this.state.value !== item.value) {
      this.setState({
        value: item.value
      });
      if (this.props.onSelect) {
        this.props.onSelect(item.value);
      }
    }
  };

  renderList = () => {
    const { items, dropDownStyles, type } = this.props;

    const listItems = _.map(items, item => (
      <li
        style={{
          ...dropDownStyles,
          ...styles.listItem,
          ...(item.disabled && styles.listItemDisabled),
          ...(item.destructive && styles.listItemDestructive)
        }}
        key={item.value}
        onMouseDown={this.select(item)}
        // TODO: not ideal to have onMouseDown vs click due to outsideClickHandler
      >
        <div>{item.label}</div>
        <span style={styles.infoText}>{item.infoText}</span>
      </li>
    ));
    return <ul style={styles.list}>{listItems}</ul>;
  };

  render() {
    const {
      placeholder, // if not selected
      menuHeader, //
      items,
      id,
      containerStyles,
      menuStyles,
      position,
      type,
      arrowStyles
    } = this.props;
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

    let selectedItem;
    if (![null, undefined].includes(this.state.value)) {
      selectedItem = _.find(items, { value: this.state.value });
    }
    return (
      <Fragment>
        <OutsideClickHandler onOutsideClick={this.onCloseMenu}>
          <div
            key={id}
            style={{ ...styles.container, ...containerStyles }}
            onMouseDown={this.toggleMenu}
            data-testid='avomaDropdown'
          >
            <span
              style={{
                ...styles.placeholder,
                ...(selectedItem && styles.selected),
                ...(selectedItem &&
                  selectedItem.destructive &&
                  styles.listItemDestructive)
              }}
            >
              {selectedItem ? selectedItem.label : placeholder}
            </span>

            <DownArrowIcon className='ml-3 h-2 w-2' />
            {this.state.isOpen && (
              <div
                style={{
                  ...styles.menu,
                  ...menuStyles,
                  ...(position === 'top'
                    ? styles.positionTop
                    : styles.positionBottom)
                }}
              >
                {menuHeader && (
                  <h6 style={styles.dropdownTitle}>{menuHeader}</h6>
                )}
                {this.renderList()}
              </div>
            )}
          </div>
        </OutsideClickHandler>
      </Fragment>
    );
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
  }
}

const styles = {
  container: {
    position: 'relative',
    width: '150px',
    height: '40px',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    border: `1px solid ${Tokens.colors.gainsboro}`,
    borderRadius: Tokens.spacing.half,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0 ${Tokens.spacing.one}`,
    marginRight: Tokens.spacing.one,
    color: Tokens.colors.gunmetal,
    ':hover': {
      backgroundColor: Tokens.colors.snow
    },
    cursor: 'pointer',
    fontWeight: 'normal'
  },
  selected: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: Tokens.colors.black
  },
  menu: {
    left: 0,
    width: '300px',
    maxHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    overflowY: 'auto',
    ...Tokens.shadows.medium,
    position: 'absolute',
    backgroundColor: Tokens.colors.white,
    border: `1px solid ${Tokens.colors.gainsboro}`,
    borderRadius: '3px',
    zIndex: '9999'
  },
  dropdownTitle: {
    padding: Tokens.spacing.two,
    ...Tokens.type.bodyS,
    fontWeight: '900',
    color: Tokens.colors.gunmetal,
    margin: '0',
    textTransform: 'capitalize'
  },
  list: {
    ...Tokens.type.body,
    margin: '0',
    padding: '0',
    listStyleType: 'none'
  },
  listItem: {
    padding: `${Tokens.spacing.one} ${Tokens.spacing.two}`,
    ':hover': {
      backgroundColor: Tokens.colors.snow
    }
  },
  listItemDisabled: {
    color: Tokens.colors.silver,
    opacity: '0.4',
    ':hover': {
      backgroundColor: Tokens.colors.white
    }
  },
  placeholder: {
    color: Tokens.colors.silverLight
  },
  listItemDestructive: {
    color: Tokens.colors.red
  },
  positionBottom: {
    top: Tokens.spacing.five
  },
  positionTop: {
    bottom: Tokens.spacing.five
  },
  infoText: {
    ...Tokens.type.bodyS,
    color: Tokens.colors.silver
  }
};

AvomaDropdown.propTypes = {
  items: PropTypes.array,
  onSelect: PropTypes.func,
  value: PropTypes.string,
  containerStyles: stylePropType,
  menuStyles: stylePropType,
  dropDownStyles: stylePropType,
  arrowStyles: stylePropType,
  type: PropTypes.string,
  position: PropTypes.object
};

export default Radium(AvomaDropdown);
