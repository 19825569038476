import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import AvomaCheckbox, {
  CHECKBOX_STATES
} from 'components/Common/AvomaCheckbox';
import AvomaPopover from 'components/Common/AvomaPopover';
import ProfileIcon from 'components/Common/ProfileIcon';

import L10n from 'helpers/L10n';

import Tokens from 'styles/tokens';

const AdminMembersPopover = ({
  admins = [],
  handleAdminsChecked,
  selectedAdmins,
  hidePopover,
  selectedAdminsNumber,
  totalAdmins,
  handleSelectAllAdmins
}) => {
  const isChecked = email => selectedAdmins.includes(email);
  const isSelectAllChecked = selectedAdminsNumber === totalAdmins;
  const checkboxRef = useRef(null);

  const multiSelectState = () => {
    if (isSelectAllChecked) return CHECKBOX_STATES.checked;
    if (selectedAdminsNumber === 0) return CHECKBOX_STATES.empty;
    return CHECKBOX_STATES.indeterminate;
  };

  const renderParticipants = participants => {
    if (participants.length === 0)
      return <div style={styles.center}>{L10n.requestAdmin.noAdminError}</div>;

    return participants.map(participant => (
      <AvomaCheckbox
        key={participant.email}
        label={
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <ProfileIcon
              profilePic={participant.profilePic}
              size={24}
              style={{ marginRight: Tokens.spacing.one }}
            />
            <div>
              <p style={{ ...styles.name, ...styles.details }}>
                {participant.personName}
              </p>
              <p style={{ ...styles.email, ...styles.details }}>
                {participant.email}
              </p>
            </div>
          </div>
        }
        name={participant.email}
        id={participant.email}
        containerStyle={{
          marginBottom: Tokens.spacing.one,
          height: Tokens.spacing.six
        }}
        checked={isChecked(participant.email)}
        onChange={handleAdminsChecked}
      />
    ));
  };

  const renderContent = () => (
    <React.Fragment>
      <div style={styles.title}>{L10n.requestAdmin.admins}</div>
      {renderParticipants(admins)}
    </React.Fragment>
  );

  const renderSelectAllCheckbox = () => (
    <AvomaCheckbox
      label={`${selectedAdminsNumber}/${totalAdmins} selected`}
      name='select-all-participants'
      id='select-all-participants'
      containerStyle={{
        marginBottom: Tokens.spacing.two
      }}
      labelStyle={styles.selectAllLabel}
      checked={isSelectAllChecked}
      onChange={handleSelectAllAdmins}
      ref={checkboxRef}
      isMultiSelect
      multiSelectState={multiSelectState()}
    />
  );
  return (
    <OutsideClickHandler onOutsideClick={hidePopover}>
      <AvomaPopover
        width={300}
        style={{ padding: Tokens.spacing.two }}
        wrapperStyle={styles.popoverWrapper}
      >
        {renderSelectAllCheckbox()}
        {renderContent()}
      </AvomaPopover>
    </OutsideClickHandler>
  );
};

const styles = {
  popoverWrapper: {
    top: Tokens.spacing.five,
    left: 0,
    maxHeight: '400px',
    overflowY: 'auto',
    zIndex: 9999999
  },
  title: {
    ...Tokens.type.bodyS,
    color: Tokens.colors.silver,
    fontWeight: 700,
    marginBottom: Tokens.spacing.one
  },
  email: {
    ...Tokens.type.bodyS,
    color: Tokens.colors.silver
  },
  name: {
    ...Tokens.type.bodyS,
    color: Tokens.colors.black
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...Tokens.type.bodyS,
    color: Tokens.colors.silverLight,
    margin: `${Tokens.spacing.two} 0`
  },
  details: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '200px'
  },
  selectAllLabel: {
    ...Tokens.type.bodyXS,
    color: Tokens.colors.silver,
    fontWeight: 400
  }
};

AdminMembersPopover.propTypes = {
  admins: PropTypes.array,
  handleAdminsChecked: PropTypes.func,
  selectedAdmins: PropTypes.array,
  hidePopover: PropTypes.func,
  selectedAdminsNumber: PropTypes.number,
  totalAdmins: PropTypes.number,
  handleSelectAllAdmins: PropTypes.func
};

export default AdminMembersPopover;
