import PropTypes from 'prop-types';
import React from 'react';
import { MdAdd as AddIcon } from 'react-icons/md';
import { connect } from 'react-redux';
import stylePropType from 'react-style-proptype';

import { trackSegmentEvents as trackSegmentEventsAction } from 'actions/segment';

import AvomaIconButton from 'components/Common/AvomaIconButton';
import CreateAndUpdateTopicModal from 'components/Editor/CreateAndUpdateTopicModal';
import HighlightCategoriesMenu from 'components/Editor/HighlightCategoriesMenu';

import L10n from 'helpers/L10n';

import Tokens from 'styles/tokens';

import { TYPE_LIST_ITEM } from '../Plugins/List/types';
import { TYPE_CHECKLIST_ITEM } from '../Plugins/Types';

class AddBlockButton extends React.PureComponent {
  state = {
    showMenu: false
  };

  componentDidUpdate(prevProps) {
    const { filter, isFocused, skipSlash } = this.props;
    const deleted = prevProps.filter.length > filter.length;
    const added = prevProps.filter.length < filter.length;
    const isInList = this.isInList();

    if (
      filter === 'http:/' ||
      filter === 'http://' ||
      filter === 'https:/' ||
      filter === 'https://'
    ) {
      this.hideCategories();
      return;
    }

    if (!isInList && skipSlash && added) {
      // this.showCategories();
    } else if (
      !isInList &&
      !prevProps.filter.endsWith('/') &&
      filter.endsWith('/') &&
      filter.length === 1 &&
      isFocused
    ) {
      this.showCategories();
    } else if (
      (deleted && prevProps.filter.endsWith('/') && !filter.endsWith('/')) ||
      (prevProps.isFocused && !isFocused)
    ) {
      this.hideCategories();
    }
  }

  onCategorySelected = category => {
    const { onCategorySelected } = this.props;
    this.hideCategories();
    if (onCategorySelected) {
      onCategorySelected(category);
    }
  };

  _onInsertAgendaSelected = () => {
    const { onInsertAgendaSelected } = this.props;
    this.hideCategories();
    if (onInsertAgendaSelected) {
      onInsertAgendaSelected();
    }
  };

  showCategories = buttonClicked => {
    const { onCategoriesShown } = this.props;
    this.setState({ showMenu: true });
    if (onCategoriesShown) {
      onCategoriesShown(buttonClicked);
    }
  };

  hideCategories = () => {
    const { onCategoriesHidden } = this.props;
    this.setState({ showMenu: false });
    if (onCategoriesHidden) {
      onCategoriesHidden();
    }
  };

  isInList = () => {
    const { parent } = this.props;
    return (
      parent &&
      (parent.type === TYPE_LIST_ITEM || parent.type === TYPE_CHECKLIST_ITEM)
    );
  };

  render() {
    const {
      show,
      filter,
      isCategory,
      skipSlash,
      style,
      highlightCategories,
      selectedCategoryUuid,
      seenCategories,
      onNoneSelected,
      showInsertAgendaButton,
      allHighlightedCategories,
      crmProvider,
      isTemplateFlow = false,
      updateCategoryUuid,
      setUpdateCategoryUuid,
      trackSegmentEvents,
      currentTemplate,
      userEmail,
      meetingUuid
    } = this.props;

    const augNotesEnabledCategories = Object.entries(
      allHighlightedCategories
    ).reduce((acc, [key, value]) => {
      if (value?.settings?.augNotesEnabled) {
        acc[key] = value;
      }
      return acc;
    }, {});

    const isInList = this.isInList();
    const { showMenu } = this.state;
    const slashIndex = filter.lastIndexOf('/');
    let strippedFilter = filter;
    if (slashIndex >= 0 || !skipSlash) {
      strippedFilter = slashIndex < 0 ? '' : filter.substring(slashIndex + 1);
    }
    let paddingRight = '0';
    if (!show) {
      if (isInList) {
        paddingRight = '4px';
      } else {
        paddingRight = '20px';
      }
    }
    let up = false;
    if (this.container) {
      const { y } = this.container.getBoundingClientRect();
      up = y > window.innerHeight / (isTemplateFlow ? 1.34 : 1.4);
    }
    if (isInList) {
      return null;
    }
    // Weird cursor bug if you add a bullet the cursor goes to this div which should
    // not be editable
    // let left = '0px';
    // if (show) {
    //   left = !show && isInList ? '-46px' : '-26px';
    // }
    let left = 0;
    const buffer = isInList ? 46 : 26;
    if (!show) {
      left = buffer;
    }

    // since the agenda is a header, it is assumed by the code as a filter/query and then tries to check it against existing categories
    // and then falls short of not finding, and doesn't show the highlightCategoriesMenu.
    // This takes care of making it seem to be a regular string
    // FIXME: This seems to be the case for any Header which causes clicking on the Add Button to not show the categories (in prod)
    // We may want to think if we want to show the Add Button altogether or tweak the logic for showing the popover
    strippedFilter = strippedFilter === 'Agenda' ? '' : strippedFilter;

    return (
      <div
        style={{
          ...styles.buttonsContainer,
          left: `-${buffer}px`,
          paddingLeft: `${left}px`,
          paddingRight,
          ...style
        }}
        contentEditable={false}
        className='disable-select'
        ref={el => {
          this.container = el;
        }}
      >
        {show && !showMenu && !isInList && (
          <AvomaIconButton
            faIcon={<AddIcon size={20} style={styles.button} />}
            onClick={this.showCategories}
          />
        )}
        {showMenu && !isInList && (
          <HighlightCategoriesMenu
            title={
              isCategory
                ? L10n.notes.changeSmartTopicBlock
                : L10n.notes.addSmartTopicBlock
            }
            onMouseEnter={this.showCategories}
            onClickOutside={this.hideCategories}
            highlightCategories={Object.values(augNotesEnabledCategories || {})}
            position={{
              left: '29px',
              ...(up ? { bottom: '30px' } : { top: '30px' })
            }}
            onSelectHighlightCategory={this.onCategorySelected}
            filter={isCategory ? '' : strippedFilter}
            showEmpty={!skipSlash}
            addUnselected={skipSlash || filter.includes('/')}
            seenCategories={seenCategories}
            selectedHighlightCategoryUuid={selectedCategoryUuid}
            onNoneSelected={onNoneSelected}
            showInsertAgendaButton={showInsertAgendaButton}
            onInsertAgendaSelected={this._onInsertAgendaSelected}
            isShowCategoriesInTabs
            crmProvider={crmProvider}
            trackSegmentEvents={trackSegmentEvents}
            currentTemplate={currentTemplate}
            userEmail={userEmail}
            meetingUuid={meetingUuid}
          />
        )}
        {updateCategoryUuid && (
          <CreateAndUpdateTopicModal
            isOpen
            handleOnClose={() => {
              setUpdateCategoryUuid(false);
            }}
            updateCategoryUuid={updateCategoryUuid}
          />
        )}
      </div>
    );
  }
}

AddBlockButton.propTypes = {
  onCategorySelected: PropTypes.func.isRequired,
  highlightCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  parent: PropTypes.shape({
    type: PropTypes.string
  }).isRequired,
  show: PropTypes.bool,
  filter: PropTypes.string,
  isFocused: PropTypes.bool,
  skipSlash: PropTypes.bool,
  isCategory: PropTypes.bool,
  selectedCategoryUuid: PropTypes.string,
  seenCategories: PropTypes.object,
  onNoneSelected: PropTypes.func,
  onCategoriesShown: PropTypes.func,
  onCategoriesHidden: PropTypes.func,
  style: stylePropType,
  showInsertAgendaButton: PropTypes.bool,
  onInsertAgendaSelected: PropTypes.bool,
  allHighlightedCategories: PropTypes.object,
  crmProvider: PropTypes.string,
  isTemplateFlow: PropTypes.bool,
  updateCategoryUuid: PropTypes.bool,
  setUpdateCategoryUuid: PropTypes.func,
  trackSegmentEvents: PropTypes.func,
  currentTemplate: PropTypes.object,
  userEmail: PropTypes.string,
  meetingUuid: PropTypes.string
};

AddBlockButton.defaultProps = {
  show: false,
  isFocused: false,
  filter: '',
  skipSlash: false,
  isCategory: false,
  onCategoriesShown: null,
  onCategoriesHidden: null
};

const styles = {
  buttonsContainer: {
    position: 'absolute',
    top: '0px',
    minHeight: Tokens.spacing.three,
    minWidth: Tokens.spacing.three
  },
  button: {
    width: Tokens.spacing.three
  }
};

const ConnectedAddBlockButton = connect(state => ({
  highlightCategories: state.highlights.highlightCategoriesForUser,
  allHighlightedCategories: state.insights.customCategories,
  crmProvider: state.user.crmProvider,
  userEmail: state.user.email,
  trackSegmentEvents: trackSegmentEventsAction
}))(AddBlockButton);

export default ConnectedAddBlockButton;
