import camelize from 'camelize';
import isEmpty from 'lodash/isEmpty';

import {
  BattlecardActions,
  BookmarkActions,
  CopilotActions,
  HighlightActions,
  MeetingActions,
  MeetingTranslationActions,
  RealtimeTranscriptFetchActions,
  ScorecardsActions,
  WebsocketActions
} from 'actions/actionTypes';

import env from 'helpers/env';
import logError from 'helpers/logError';

const getMessageForAction = (store, action, wsAction) => {
  if (
    action.streamType === WebsocketActions.TYPE_COPILOT &&
    wsAction === 'send'
  ) {
    return JSON.stringify({
      msg: 'stream',
      stream: action.streamType,
      user_email: store.getState().user.email,
      db_object: {
        uuid: action.chatUuid,
        entity_key: action.entityKey
      },
      data: {
        message: action.message,
        data_json: {
          context_type: action.contextType,
          context_uuid: action.contextUuid
        }
      }
    });
  }

  return JSON.stringify({
    msg: 'stream',
    stream: action.streamType,
    action: wsAction,
    db_object: {
      uuid: action.uuid,
      entity_key: action.entityKey
    },
    meta: {
      ts: new Date().toISOString(),
      user_email: store.getState().user.email
    }
  });
};

const socketMiddleware = () => {
  let socket = null;
  let connecting = false;
  let pending = null;
  let processingSendMessage = false;

  const onOpen = store => event => {
    connecting = false;

    store.dispatch({
      type: WebsocketActions.CONNECTED,
      host: event.target.url
    });

    if (pending && socket && socket?.readyState === WebSocket.OPEN) {
      pending.forEach(action => {
        socket.send(getMessageForAction(store, action, 'sub'));
        store.dispatch({
          ...action,
          type: WebsocketActions.SUBSCRIBED
        });
      });

      pending = null;
    }
  };

  const onClose = store => () => {
    connecting = false;

    store.dispatch({ type: WebsocketActions.DISCONNECTED });

    socket = null;
  };

  const onMessage = store => event => {
    const payload = camelize(JSON.parse(event.data));

    switch (payload.stream) {
      case WebsocketActions.TYPE_BATTLECARDS:
        if (isEmpty(payload.data)) {
          logError(event.data, payload);
        } else {
          store.dispatch({
            type: BattlecardActions.FETCH_BATTLECARD_SUCCESS,
            card: payload.data
          });
        }

        break;

      case WebsocketActions.TYPE_MEETING_REALTIME_TRANSCRIPTIONS:
        if (isEmpty(payload.data)) {
          logError(event.data, payload);
        } else if (payload.data.type === 'notes') {
          // Handle the translated notes here
          store.dispatch({
            type: MeetingTranslationActions.FETCH_MEETING_TRANSLATION_NOTES_SUCCESS,
            meetingUuid: payload.data.meetingUuid,
            data: payload.data
          });
        } else if (payload.data.type === 'transcript') {
          // Handle the translated transcript here
          store.dispatch({
            type: MeetingTranslationActions.FETCH_MEETING_TRANSLATION_TRANSCRIPT_SUCCESS,
            meetingUuid: payload.data.meetingUuid,
            data: payload.data
          });
        } else {
          // Handle real time transcript message here
          store.dispatch({
            type: RealtimeTranscriptFetchActions.FETCH_REALTIME_TRANSCRIPTION_SUCCESS,
            transcript: payload.data
          });
        }

        break;

      case WebsocketActions.TYPE_BOOKMARKS:
        if (isEmpty(payload.data)) {
          logError(event.data, payload);
        } else {
          store.dispatch({
            type: BookmarkActions.FETCH_BOOKMARK_SUCCESS,
            bookmark: payload.data
          });
        }

        break;

      case WebsocketActions.TYPE_MEETING_META:
        if (isEmpty(payload.dbObject)) {
          logError(event.data, payload);
        } else {
          if (payload.data?.scorecardUuid) {
            store.dispatch({
              type: ScorecardsActions.SET_SCORECARD_UUID_TO_FETCH,
              response: payload.data?.scorecardUuid
            });
          }

          if (
            payload.data &&
            'title' in payload.data &&
            'summary' in payload.data
          ) {
            store.dispatch({
              type: HighlightActions.SET_HIGHLIGHT_SUMMARIZATION,
              response: payload.data
            });
          }

          store.dispatch({
            type: MeetingActions.FETCH_META_SUCCESS,
            meetingId: payload.dbObject.uuid,
            metadata: payload.data
          });

          store.dispatch({
            type: MeetingActions.SET_ONLINE_USERS,
            meetingUuid: payload.dbObject.uuid,
            onlineUsers: payload.data.subscribedUsers
          });
        }

        break;

      case WebsocketActions.TYPE_COPILOT:
        if (isEmpty(payload.dbObject)) {
          logError(event.data, payload);
        } else {
          store.dispatch({
            type: CopilotActions.RECEIVE_COPILOT_MESSAGE,
            data: {
              ...payload.data,
              chatUuid: payload.dbObject.uuid
            }
          });
        }

        break;

      default:
        break;
    }
  };

  const connect = store => {
    connecting = true;

    const host = env.websocketUrl;

    // connect to the remote host
    socket = new WebSocket(host);

    // websocket handlers
    socket.onmessage = onMessage(store);
    socket.onclose = onClose(store);
    socket.onopen = onOpen(store);
  };

  // the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case WebsocketActions.CONNECT: {
        if (socket !== null) {
          socket.close();
        }

        if (!connecting) {
          connect(store);
        }

        break;
      }

      case WebsocketActions.DISCONNECT:
        if (socket !== null) {
          socket.close();
        }

        socket = null;

        break;

      case WebsocketActions.SUBSCRIBE: {
        const message = getMessageForAction(store, action, 'sub');

        if (socket && socket?.readyState === WebSocket.OPEN) {
          socket.send(message);
          store.dispatch({
            ...action,
            type: WebsocketActions.SUBSCRIBED
          });
        } else {
          if (!connecting) {
            connect(store);
          }

          if (!pending) {
            pending = [];
          }

          pending.push(action);
        }

        break;
      }

      case WebsocketActions.UNSUBSCRIBE: {
        const message = getMessageForAction(store, action, 'unsub');

        if (socket && socket?.readyState === WebSocket.OPEN) {
          socket.send(message);

          store.dispatch({
            ...action,
            type: WebsocketActions.UNSUBSCRIBED
          });
        } else {
          if (!connecting) {
            connect(store);
          }

          if (pending && socket && socket?.readyState === WebSocket.OPEN) {
            // Removing if we have any pending `sub` for same meeting uuid as of `unsub`
            pending = pending.filter(
              pendingAction =>
                !(
                  action &&
                  pendingAction &&
                  action.streamType === pendingAction.streamType &&
                  action.uuid === pendingAction.uuid &&
                  action.entityKey === pendingAction.entityKey
                )
            );
          }
        }

        break;
      }

      case CopilotActions.SEND_COPILOT_MESSAGE: {
        if (
          !processingSendMessage &&
          socket &&
          socket?.readyState === WebSocket.OPEN
        ) {
          processingSendMessage = true;

          try {
            store.dispatch({
              type: CopilotActions.RECEIVE_COPILOT_MESSAGE,
              data: {
                uuid: null,
                author: 1,
                created: new Date().toISOString(),
                message: action.message,
                chatUuid: action.chatUuid
              }
            });

            socket.send(getMessageForAction(store, action, 'send'));
          } catch (error) {
            logError('Error sending receiving message from copilot', error);
          } finally {
            // Do not dispatch the action again
            processingSendMessage = false;
          }
        }
        break;
      }

      default:
        return next(action);
    }

    return next(action);
  };
};

export default socketMiddleware();
