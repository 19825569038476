import { EventTypes } from 'redux-segment';

import { CURRENT_SEGMENT_EVENT_VERSION } from 'helpers/segmentV2';

export const viewedSetting = view_name => ({
  type: 'SETTINGS_VIEWED',
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        properties: {
          view_name
        }
      }
    }
  }
});

export const viewedMeetingListing = view_name => ({
  type: 'MEETING_LISTED',
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        properties: {
          view_name
        }
      }
    }
  }
});

export const viewedCallListing = view_name => ({
  type: 'CALL_LISTED',
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        properties: {
          view_name
        }
      }
    }
  }
});

export const trackMeetingRelatedEvents = (type, meeting_id) => ({
  type,
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        properties: {
          meeting_id
        }
      }
    }
  }
});

export const trackViewRelatedEvents = (type, view_name) => ({
  type,
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        properties: {
          view_name
        }
      }
    }
  }
});

export const trackSegmentEvents = ({ type, ...properties }) =>
  // Dispatch to segment
  ({
    type,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          properties: {
            ...properties,
            version: CURRENT_SEGMENT_EVENT_VERSION
          }
        }
      }
    }
  });

export const identifySegmentEvent = eventPayload => {
  window?._hsq?.push([
    'identify',
    {
      email: eventPayload?.email
    }
  ]);
  return {
    type: 'LOGIN',
    meta: {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload: { ...eventPayload }
      }
    }
  };
};

// Rev Intel Segment Trackers

export const dealViewCreated = ({ viewName, viewId }) => ({
  type: 'DEAL_VIEW_CREATED',
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        properties: {
          view_name: viewName,
          view_id: viewId
        }
      }
    }
  }
});

export const dealViewed = ({ dealId }) => ({
  type: 'DEAL_VIEWED',
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        properties: {
          deal_id: dealId
        }
      }
    }
  }
});

export const dealListed = ({ viewId, viewName, filters }) => ({
  type: 'DEAL_LISTED',
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        properties: {
          view_id: viewId,
          view_name: viewName,
          filters
        }
      }
    }
  }
});

export const dealQuickViewTabViewed = ({ type }) => ({
  type,
  meta: { analytics: { eventType: EventTypes.track } }
});
