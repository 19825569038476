import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';

import Tokens from 'styles/tokens';

const AvomaTextField = props => {
  const {
    placeholder,
    label,
    name,
    type,
    value,
    userStyles,
    optional,
    containerStyles,
    labelStyle,
    leftAttachment,
    rightAttachment,
    disabled,
    className,
    error,
    collapseErrorDiv,
    helperComponent,
    noAsterisk = false,
    inputClassName = '',
    ...rest
  } = props;
  const newVal = value !== null ? value : '';

  return (
    <div
      className={`field ${className}`}
      style={{ ...styles.container, ...containerStyles }}
    >
      {label && (
        <label htmlFor={name} className='textfield-label' style={labelStyle}>
          {label}
          {!optional ? (
            <span style={{ color: Tokens.colors.avomaRed }}>
              {!noAsterisk && '*'}
            </span>
          ) : (
            <span style={styles.optional}>(optional)</span>
          )}
          {helperComponent}
        </label>
      )}
      <div style={styles.inputContainer}>
        {leftAttachment}
        <input
          type={type}
          style={{
            ...styles.textfield,
            ...(leftAttachment && styles.attachedLeft),
            ...(rightAttachment && styles.attachedRight),
            ...userStyles
          }}
          placeholder={placeholder}
          className={`avoma-input ${inputClassName}`}
          name={name}
          value={newVal}
          disabled={disabled}
          autoComplete='off'
          {...rest}
        />
        {rightAttachment}
      </div>
      {(error || !collapseErrorDiv) && (
        <span style={{ ...styles.error, ...Tokens.type.bodyXS }}>{error}</span>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  textfield: {
    height: '40px',
    boxSizing: 'border-box',
    padding: `2px ${Tokens.spacing.onehalf}`,
    color: Tokens.colors.gunmetal,
    backgroundColor: Tokens.colors.snow,
    fontSize: '1rem',
    letterSpacing: '0.3px',
    borderRadius: Tokens.spacing.borderRadius,
    minWidth: '0',
    width: '100%',
    border: `1px solid ${Tokens.colors.gainsboro}`,
    ':focus': {
      outline: 'none',
      border: `1px solid ${Tokens.colors.blue}`
    },
    ':disabled': {
      cursor: 'not-allowed',
      opacity: '0.4'
    }
  },
  optional: {
    marginLeft: Tokens.spacing.half,
    color: Tokens.colors.silver,
    fontWeight: 'normal'
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  attachedLeft: {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0'
  },
  attachedRight: {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0'
  },
  error: {
    color: Tokens.colors.avomaRed,
    height: Tokens.spacing.onehalf,
    marginTop: Tokens.spacing.half
  }
};

AvomaTextField.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  userStyles: PropTypes.objectOf(PropTypes.string),
  containerStyles: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string,
  optional: PropTypes.bool,
  labelStyle: PropTypes.objectOf(PropTypes.string),
  leftAttachment: PropTypes.bool,
  rightAttachment: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  collapseErrorDiv: PropTypes.bool,
  helperComponent: PropTypes.node,
  noAsterisk: PropTypes.bool,
  inputClassName: PropTypes.string,
  autoFocus: PropTypes.bool
};

AvomaTextField.defaultProps = {
  placeholder: null,
  label: '',
  name: '',
  containerStyles: {},
  userStyles: {},
  value: null,
  type: 'text',
  optional: false,
  labelStyle: {},
  collapseErrorDiv: false,
  helperComponent: false,
  autoFocus: false
};

export default Radium(AvomaTextField);
