import camelize from 'camelize';

import { SettingActions, UserActions } from 'actions/actionTypes';

import { isZoomContext } from 'helpers/zoomApp';

export const initialState = {
  impersonationActive: false,
  impersonationType: '',
  accessToken: null,
  username: null,
  email: null,
  profilePic: null,
  isAuthenticated: null,
  settingsUrl: null,
  loading: false,
  connectingOutreach: false,
  error: null,
  microsoftLoginError: null,
  calIntegration: null,
  fetchingSettings: true,
  errorFetchingSettings: null,
  authProvider: null,
  calProvider: null,
  organization: null,
  orgRole: null,
  zoomIntegration: false,
  zoomError: null,
  zoomErrorTime: null,
  zoomLoading: false,
  zoomUser: null,
  zoomSettings: null,
  zoomAppLoading: false,
  zoomAppError: null,
  aircallIntegration: false,
  connectleaderIntegration: false,
  connectLeaderError: null,
  connectLeaderErrorTime: null,
  connectleaderUser: null,
  connectLeaderLoading: false,
  aircallError: null,
  aircallErrorTime: null,
  aircallLoading: false,
  aircallUser: null,
  salesloftIntegration: false,
  salesloftError: null,
  salesloftErrorTime: null,
  salesloftUser: null,
  salesloftLoading: false,
  kixieIntegration: false,
  kixieError: null,
  kixieErrorTime: null,
  kixieUser: null,
  kixieLoading: false,
  kixieResponse: {},
  hubspotIntegration: false,
  hubspotUsername: null,
  zendesksellIntegration: false,
  zendesksellUsername: null,
  pipedriveIntegration: false,
  pipedriveUsername: null,
  copperIntegration: false,
  copperUsername: null,
  zohoIntegration: false,
  zohoUsername: null,
  zoomphoneIntegration: false,
  zoomphoneUsername: null,
  zoomPhoneError: null,
  crm: null,
  lastSyncedCalendar: null,
  calendarSyncRetryCount: 0,
  timezone: '',
  lastUpdatedTimezone: null,
  notifications: {},
  features: [],
  flags: [],
  userLicense: null,
  ringcentralIntegration: null,
  ringcentralUsername: null,
  ringCentralError: null,
  ringCentralLoading: false,
  voiceprints: {},
  voiceprintsFetching: false,
  msExchangeConfig: {},
  fetchingMsExchangeConfig: false,
  // Referrals
  referral: {},
  fetchReferral: {
    loading: false,
    error: false
  },
  updateReferral: {
    loading: false,
    error: false
  },
  customReminders: {},
  canEditDeals: null,
  gotoconnectIntegration: false,
  gotoconnectError: null,
  gotoconnectErrorTime: null,
  gotoconnectUser: null,
  gotoconnectLoading: false,
  vonageLoading: false,
  vonageBcIntegration: false,
  vonageBcUsername: null,
  vonageError: null,
  vonageErrorTime: null
};

const fetchingUserPublicProfile = (state, action) => {
  switch (action.type) {
    case 'FETCH_USER_PROFILE_SUCCESS': {
      return {
        ...state,
        ...camelize(action.profile)
      };
    }
    case 'FETCH_USER_PROFILE_FAILURE': {
      return {
        ...state,
        error: action.error
      };
    }
    case 'UPDATE_USER_PROFILE':
      return {
        ...state,
        jobFunction: action.profile.job_function // slightly hacky here to get instant feedback
      };
    case 'UPDATE_USER_PROFILE_SUCCESS': {
      return {
        ...state,
        ...camelize(action.profile.user),
        timezone: action.profile.timezone,
        autoSetTz: action.profile.auto_set_tz
      };
    }
    case 'UPDATE_USER_PROFILE_FAILURE': {
      return {
        ...state,
        error: action.error
      };
    }
    default:
      return state;
  }
};

/*
  IMPORTANT: this "user" redux key is persisted to localStorage. see reduxStore.js
*/
const user = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'LOGIN_SUCCESS': {
      if (action.username !== state.username && action.email !== state.email) {
        localStorage.removeItem('reduxState');
      }

      return {
        accessToken: action.accessToken,
        username: action.username,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        profilePic: action.profilePic,
        isAuthenticated: true,
        settingsUrl: action.settingsUrl,
        crmProviderUrl: action.crmProviderUrl,
        outReachUserUrl: action.outReachUserUrl,
        onboardingStatusUrl: action.onboardingStatusUrl,
        onboardingShown: action.onboardingShown,
        authProvider: action.authProvider,
        calProvider: action.calProvider,
        orgRole: action.orgRole, // this is an incomplete orgrole, can be null
        loading: false,
        error: null,
        ...(action.impersonationActive && {
          impersonationActive: action.impersonationActive,
          impersonationType: action.impersonationType
        })
      };
    }
    case 'LOGIN_CANCELLED':
      return {
        ...state,
        error: 'cancelled',
        loading: false
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case 'MSFT_LOGIN_FAILURE':
      return {
        ...state,
        microsoftLoginError: action.error
      };
    // fetch public profile
    case 'FETCH_USER_PROFILE':
    case 'FETCH_USER_PROFILE_SUCCESS':
    case 'FETCH_USER_PROFILE_FAILURE':
    case 'UPDATE_USER_PROFILE':
    case 'UPDATE_USER_PROFILE_SUCCESS':
    case 'UPDATE_USER_PROFILE_FAILURE':
      return fetchingUserPublicProfile(state, action);
    case 'FETCH_USER_SETTINGS_REQUEST':
      return {
        ...state,
        fetchingSettings: true,
        errorFetchingSettings: null
      };
    case 'FETCH_USER_SETTINGS_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetchingSettings: false
      };
    }
    case 'FETCH_USER_SETTINGS_FAILURE':
      return {
        ...state,
        fetchingSettings: false,
        errorFetchingSettings: action.error
      };
    case SettingActions.FETCH_DIALER_SETTINGS_REQUEST:
      return {
        ...state,
        fetchingDialerSettings: true,
        errorFetchingDialerSettings: null
      };
    case SettingActions.FETCH_DIALER_SETTINGS_SUCCESS: {
      return {
        ...state,
        ...action.response,
        fetchingDialerSettings: false
      };
    }
    case SettingActions.FETCH_DIALER_SETTINGS_FAILURE:
      return {
        ...state,
        fetchingDialerSettings: false,
        errorFetchingDialerSettings: action.error
      };
    case 'FETCH_USER_INFO_SUCCESS': {
      const { firstName, lastName, position, jobFunction, upn } = camelize(
        action.user
      );
      return {
        ...state,
        settingsUrl: action.settingsUrl,
        onboardingStatusUrl: action.onboardingStatusUrl,
        orgRole: action.orgRole,
        firstName,
        onFreeMsAccount: action.onFreeMsAccount,
        lastName,
        position,
        jobFunction,
        upn,
        userLicense: action.userLicense
      };
    }
    case 'TOGGLE_ORGANIZER_MEETINGS_REQUEST':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'TOGGLE_ORGANIZER_MEETINGS_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        organizerMeetingsOnly: action.organizerMeetingsOnly
      };
    case 'TOGGLE_ORGANIZER_MEETINGS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case 'TOGGLE_CAL_INTEGRATION_REQUEST':
      return {
        ...state,
        calIntegration: action.calIntegration,
        onboardingSteps: {
          ...state.onboardingSteps,
          completed: {
            ...state.onboardingSteps.completed,
            calendar: action.calIntegration
          }
        }
      };
    case 'TOGGLE_CAL_INTEGRATION_SUCCESS':
      return {
        ...state,
        calIntegration: action.calIntegration,
        calProvider: action.calProvider,
        onboardingSteps: {
          ...state.onboardingSteps,
          completed: {
            ...state.onboardingSteps.completed,
            calendar: action.calIntegration
          }
        }
      };
    case 'TOGGLE_CAL_INTEGRATION_FAILURE':
      return {
        ...state,
        calIntegration: !state.calIntegration,
        onboardingSteps: {
          ...state.onboardingSteps,
          completed: {
            ...state.onboardingSteps?.completed,
            calendar: !action.calIntegration
          }
        }
      };
    case UserActions.UPDATE_ONBOARDING_STEPS:
      return {
        ...state,
        onboardingSteps: {
          ...state.onboardingSteps,
          completed: {
            ...state.onboardingSteps?.completed,
            ...action.completed
          },
          skipped: {
            ...state.onboardingSteps?.skipped,
            ...action.skipped
          }
        }
      };
    case 'ENABLE_CRM_INTEGRATION':
      return {
        ...state,
        crmIntegration: true,
        crmProviderUrl: action.crmProviderUrl
      };
    case 'OUTREACH_INTEGRATION_REQUEST':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'OUTREACH_INTEGRATION_SUCCESS':
      return {
        ...state,
        loading: false,
        outReachUserUrl: action.outReachUserUrl,
        error: null
      };
    case 'OUTREACH_INTEGRATION_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case 'UPDATE_ONBOARDING_STATE_SUCCESS':
      return {
        ...state,
        onboardingShown: action.onboarding_shown
      };
    case 'LOGOUT':
      return initialState;
    case UserActions.SET_NOTIFICATION_REQUEST: {
      const previousController = state.notifications?.metadata?.controller;
      if (previousController) {
        previousController.abort();
      }
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...action.newNotifications,
          metadata: { controller: action.controller }
        }
      };
    }
    case UserActions.SET_NOTIFICATION_FAILURE:
      return {
        ...state,
        notifications: action.previousNotifications
      };
    case UserActions.SET_NOTIFICATION_SUCCESS:
      return state;
    case SettingActions.ENABLE_ZOOM_INTEGRATION:
      return {
        ...state,
        zoomIntegration: action.zoomIntegration,
        zoomSettings: {
          ...state.zoomSettings,
          oauthAppInstalled: true
        },
        zoomError: null
      };
    case SettingActions.ENABLE_ZOOM_INTEGRATION_FAILURE:
      return {
        ...state,
        zoomError: action.error,
        zoomErrorTime: new Date()
      };
    case SettingActions.DISABLE_ZOOM_INTEGRATION_REQUEST:
      return {
        ...state,
        zoomLoading: true,
        zoomError: null
      };
    case SettingActions.DISABLE_ZOOM_INTEGRATION_SUCCESS:
      return {
        ...state,
        zoomIntegration: false,
        zoomSettings: {
          ...state.zoomSettings,
          oauthAppInstalled: false
        },
        zoomLoading: false,
        zoomError: null
      };
    case SettingActions.ENABLE_WEBEX_INTEGRATION_SUCCESS:
      return {
        ...state,
        webexError: null,
        webexIntegration: true
      };
    case SettingActions.ENABLE_WEBEX_INTEGRATION_FAILURE:
      return {
        ...state,
        webexError: action.error,
        webexIntegration: false
      };
    case SettingActions.DISABLE_WEBEX_INTEGRATION_SUCCESS:
      return {
        ...state,
        webexIntegration: false,
        webexUsername: null
      };
    case SettingActions.ENABLE_MICROSOFT_CLOUD_INTEGRATION_SUCCESS:
      return {
        ...state,
        msftCldError: null,
        msftCldIntegration: true
      };
    case SettingActions.ENABLE_MICROSOFT_CLOUD_INTEGRATION_FAILURE:
      return {
        ...state,
        msftCldError: action.error,
        msftCldIntegration: false
      };
    case SettingActions.DISABLE_MICROSOFT_CLOUD_INTEGRATION_SUCCESS:
      return {
        ...state,
        msftCldIntegration: false,
        msftCldUsername: null
      };
    case SettingActions.ENABLE_GOOGLE_CLOUD_INTEGRATION_SUCCESS:
      return {
        ...state,
        gmCldError: null,
        gmeetCldIntegration: true
      };
    case SettingActions.ENABLE_GOOGLE_CLOUD_INTEGRATION_FAILURE:
      return {
        ...state,
        gmCldError: action.error,
        gmeetCldIntegration: false
      };
    case SettingActions.DISABLE_GOOGLE_CLOUD_INTEGRATION_SUCCESS:
      return {
        ...state,
        gmeetCldIntegration: false,
        gmeetCldUsername: null
      };
    case SettingActions.ENABLE_ZOOM_PHONE_INT_SUCCESS:
      return {
        ...state,
        zoomPhoneError: null
      };
    case SettingActions.ENABLE_ZOOM_PHONE_INT_FAILURE:
      return {
        ...state,
        zoomPhoneError: action.error
      };
    case SettingActions.DISABLE_ZOOM_PHONE_INT_SUCCESS:
      return {
        ...state,
        zoomphoneIntegration: false,
        zoomphoneUsername: null
      };
    case SettingActions.DISABLE_ZOOM_INTEGRATION_FAILURE:
      return {
        ...state,
        zoomLoading: false,
        zoomError: action.error,
        zoomErrorTime: new Date()
      };
    case SettingActions.INSTALL_ZOOM_APP_REQUEST:
      return {
        ...state,
        zoomAppLoading: true
      };
    case SettingActions.INSTALL_ZOOM_APP_SUCCESS:
      return {
        ...state,
        zoomAppLoading: false,
        zoomSettings: {
          ...state.zoomSettings,
          zoomAppInstalled: true
        }
      };
    case SettingActions.INSTALL_ZOOM_APP_ERROR:
      return {
        ...state,
        zoomAppLoading: false,
        zoomAppError: action.error
      };
    case SettingActions.INSTALL_ZOOM_SDK_REQUEST:
      return {
        ...state,
        zoomSdkLoading: true
      };
    case SettingActions.INSTALL_ZOOM_SDK_SUCCESS:
      return {
        ...state,
        zoomSdkLoading: false,
        zoomSettings: {
          ...state.zoomSettings,
          zoomMeetSdkInstalled: true
        }
      };
    case SettingActions.INSTALL_ZOOM_SDK_ERROR:
      return {
        ...state,
        zoomSdkLoading: false,
        zoomSdkError: action.error
      };
    case SettingActions.UNINSTALL_ZOOM_APP_REQUEST:
      return {
        ...state,
        zoomAppLoading: true
      };
    case SettingActions.UNINSTALL_ZOOM_APP_SUCCESS:
      return {
        ...state,
        zoomAppLoading: false,
        zoomSettings: {
          ...state.zoomSettings,
          zoomAppInstalled: false
        }
      };
    case SettingActions.UNINSTALL_ZOOM_APP_ERROR:
      return {
        ...state,
        zoomAppLoading: false,
        zoomAppError: action.error
      };
    case SettingActions.ENABLE_AIRCALL_INTEGRATION:
      return {
        ...state,
        aircallIntegration: action.aircallIntegration,
        aircallError: null
      };
    case SettingActions.ENABLE_AIRCALL_INTEGRATION_FAILURE:
      return {
        ...state,
        aircallError: action.error,
        aircallErrorTime: new Date()
      };
    case SettingActions.ENABLE_CONNECT_LEADER_INTEGRATION:
      return {
        ...state,
        connectleaderIntegration: action.connectleaderIntegration,
        connectLeaderError: null
      };
    case SettingActions.ENABLE_CONNECT_LEADER_INTEGRATION_FAILURE:
      return {
        ...state,
        connectLeaderError: action.error,
        connectLeaderErrorTime: new Date()
      };
    case SettingActions.DISABLE_CONNECT_LEADER_INTEGRATION_SUCCESS:
      return {
        ...state,
        connectleaderIntegration: false,
        connectleaderUser: null,
        connectLeaderLoading: false,
        connectLeaderError: null
      };

    case SettingActions.DISABLE_CONNECT_LEADER_INTEGRATION_REQUEST:
      return {
        ...state,
        connectLeaderLoading: true,
        connectLeaderError: null
      };

    case SettingActions.DISABLE_CONNECT_LEADER_INTEGRATION_FAILURE:
      return {
        ...state,
        connectLeaderLoading: false,
        connectLeaderError: action.error,
        connectLeaderErrorTime: new Date()
      };
    // SALESLOFT
    case SettingActions.ENABLE_SALESLOFT_INTEGRATION_REQUEST:
      return {
        ...state,
        salesloftLoading: true
      };
    case SettingActions.ENABLE_SALESLOFT_INTEGRATION_SUCCESS:
      return {
        ...state,
        salesloftIntegration: action.salesloftIntegration,
        salesloftError: null,
        salesloftLoading: false
      };
    case SettingActions.ENABLE_SALESLOFT_INTEGRATION_FAILURE:
      return {
        ...state,
        salesloftError: action.error,
        salesloftErrorTime: new Date(),
        salesloftLoading: false
      };
    case SettingActions.DISABLE_SALESLOFT_INTEGRATION_SUCCESS:
      return {
        ...state,
        salesloftIntegration: false,
        salesloftUser: null,
        salesloftLoading: false,
        salesloftError: null
      };

    case SettingActions.DISABLE_SALESLOFT_INTEGRATION_REQUEST:
      return {
        ...state,
        salesloftLoading: true,
        salesloftError: null
      };

    case SettingActions.DISABLE_SALESLOFT_INTEGRATION_FAILURE:
      return {
        ...state,
        salesloftLoading: false,
        salesloftError: action.error,
        salesloftErrorTime: new Date()
      };
    // Gotoconnect
    case SettingActions.ENABLE_GOTOCONNECT_INTEGRATION_REQUEST:
      return {
        ...state,
        gotoconnectLoading: true
      };
    case SettingActions.ENABLE_GOTOCONNECT_INTEGRATION_SUCCESS:
      return {
        ...state,
        gotoconnectIntegration: action.gotoconnectIntegration,
        gotoconnectError: null,
        gotoconnectLoading: false
      };
    case SettingActions.ENABLE_GOTOCONNECT_INTEGRATION_FAILURE:
      return {
        ...state,
        gotoconnectError: action.error,
        gotoconnectErrorTime: new Date(),
        gotoconnectLoading: false
      };
    case SettingActions.DISABLE_GOTOCONNECT_INTEGRATION_SUCCESS:
      return {
        ...state,
        gotoconnectIntegration: false,
        gotoconnectUser: null,
        gotoconnectLoading: false,
        gotoconnectError: null
      };

    case SettingActions.DISABLE_GOTOCONNECT_INTEGRATION_REQUEST:
      return {
        ...state,
        gotoconnectLoading: true,
        gotoconnectError: null
      };

    case SettingActions.DISABLE_GOTOCONNECT_INTEGRATION_FAILURE:
      return {
        ...state,
        gotoconnectLoading: false,
        gotoconnectError: action.error,
        gotoconnectErrorTime: new Date()
      };

    // Vonage
    case SettingActions.ENABLE_VONAGE_INTEGRATION_REQUEST:
      return {
        ...state,
        vonageLoading: true
      };
    case SettingActions.ENABLE_VONAGE_INTEGRATION_SUCCESS:
      return {
        ...state,
        vonageBcIntegration: action.vonageBcIntegration,
        vonageBcUsername: action.vonageBcUsername,
        vonageError: null,
        vonageLoading: false
      };
    case SettingActions.ENABLE_VONAGE_INTEGRATION_FAILURE:
      return {
        ...state,
        vonageError: action.error,
        vonageErrorTime: new Date(),
        vonageLoading: false
      };

    case SettingActions.DISABLE_VONAGE_INTEGRATION_REQUEST:
      return {
        ...state,
        vonageLoading: true,
        vonageError: null
      };
    case SettingActions.DISABLE_VONAGE_INTEGRATION_SUCCESS:
      return {
        ...state,
        vonageBcIntegration: false,
        vonageBcUser: null,
        vonageLoading: false,
        vonageError: null
      };

    case SettingActions.DISABLE_VONAGE_INTEGRATION_FAILURE:
      return {
        ...state,
        vonageLoading: false,
        vonageError: action.error,
        vonageErrorTime: new Date()
      };

    // Kixie
    case SettingActions.ENABLE_KIXIE_INTEGRATION_REQUEST:
      return {
        ...state,
        kixieLoading: true
      };
    case SettingActions.ENABLE_KIXIE_INTEGRATION_SUCCESS:
      return {
        ...state,
        kixieUser: {
          ...state.kixieUser,
          integrationReq: {
            ...action.response
          }
        },
        kixieError: null,
        kixieLoading: false,
        kixieIntegration: true
      };
    case SettingActions.ENABLE_KIXIE_INTEGRATION_FAILURE:
      return {
        ...state,
        kixieError: action.error,
        kixieErrorTime: new Date(),
        kixieLoading: false
      };
    case SettingActions.DISABLE_KIXIE_INTEGRATION_SUCCESS:
      return {
        ...state,
        kixieIntegration: false,
        kixieUser: null,
        kixieLoading: false,
        kixieError: null
      };

    case SettingActions.DISABLE_KIXIE_INTEGRATION_REQUEST:
      return {
        ...state,
        kixieLoading: true,
        kixieError: null
      };

    case SettingActions.DISABLE_KIXIE_INTEGRATION_FAILURE:
      return {
        ...state,
        kixieLoading: false,
        kixieError: action.error,
        kixieErrorTime: new Date()
      };

    case SettingActions.ENABLE_RINGCENTRAL_INTEGRATION_REQUEST:
      return {
        ...state,
        ringCentralLoading: true,
        ringCentralError: null
      };
    case SettingActions.ENABLE_RINGCENTRAL_INTEGRATION_SUCCESS:
      return {
        ...state,
        ringCentralLoading: false,
        ringcentralIntegration: action.ringcentralIntegration,
        ringCentralError: null
      };
    case SettingActions.ENABLE_RINGCENTRAL_INTEGRATION_FAILURE:
      return {
        ...state,
        ringCentralLoading: false,
        ringCentralError: action.error
      };
    case SettingActions.DISABLE_RINGCENTRAL_INTEGRATION_REQUEST:
      return {
        ...state,
        ringCentralLoading: true
      };
    case SettingActions.DISABLE_RINGCENTRAL_INTEGRATION_SUCCESS:
      return {
        ...state,
        ringCentralLoading: false,
        ringcentralIntegration: null,
        ringCentralError: action.error
      };
    case SettingActions.DISABLE_RINGCENTRAL_INTEGRATION_FAILURE:
      return {
        ...state,
        ringCentralLoading: false,
        ringCentralError: action.error
      };

    case SettingActions.DISABLE_OUTREACH_INTEGRATION_REQUEST:
      return {
        ...state,
        disablingOutreach: true
      };
    case SettingActions.DISABLE_OUTREACH_INTEGRATION_SUCCESS:
      return {
        ...state,
        disablingOutreach: false,
        outreachIntegration: null,
        outreachUsername: null
      };
    case SettingActions.DISABLE_OUTREACH_INTEGRATION_FAILURE:
      return {
        ...state,
        disablingOutreach: false
      };

    case SettingActions.DISABLE_AIRCALL_INTEGRATION_REQUEST:
      return {
        ...state,
        aircallLoading: true,
        aircallError: null
      };
    case SettingActions.DISABLE_AIRCALL_INTEGRATION_SUCCESS:
      return {
        ...state,
        aircallIntegration: false,
        aircallUser: null,
        aircallLoading: false,
        aircallError: null
      };
    case SettingActions.DISABLE_AIRCALL_INTEGRATION_FAILURE:
      return {
        ...state,
        aircallLoading: false,
        aircallError: action.error,
        aircallErrorTime: new Date()
      };

    case SettingActions.ENABLE_AIRCALL_SYNC_TAGS_FAILURE:
      return {
        ...state,
        aircallUser: {
          ...state.aircallUser,
          syncTags: action.sync
        }
      };
    case SettingActions.ENABLE_AIRCALL_SYNC_TAGS_SUCCESS:
      return {
        ...state,
        aircallUser: action.aircallUser
      };
    case SettingActions.ENABLE_HUBSPOT_INTEGRATION_REQUEST:
      return {
        ...state
      };
    case SettingActions.ENABLE_HUBSPOT_INTEGRATION_SUCCESS:
      return {
        ...state,
        hubspotIntegration: true
      };
    case SettingActions.ENABLE_HUBSPOT_INTEGRATION_FAILURE:
      return {
        ...state,
        hubspotIntegration: false
      };
    case UserActions.FETCH_CALENDAR_STATE_SUCCESS: {
      return {
        ...state,
        lastSyncedCalendar: action.lastSyncedCalendar,
        calendarSyncRetryCount: (state.calendarSyncRetryCount || 0) + 1
      };
    }
    case UserActions.FETCH_CALENDAR_STATE_FAILURE: {
      return {
        ...state,
        calendarSyncRetryCount: (state.calendarSyncRetryCount || 0) + 1
      };
    }
    case UserActions.FETCH_ZOOM_SETTINGS: {
      return {
        ...state,
        zoomSettings: action.zoomSettings
      };
    }
    case UserActions.UPDATE_ZOOM_SETTINGS: {
      return {
        ...state,
        zoomSettings: action.zoomSettings
      };
    }
    case UserActions.UPDATE_ZOOM_NATIVE_SETTINGS_REQUEST:
      return {
        ...state,
        zoomUser: {
          ...state.zoomUser,
          loading: true
        }
      };

    case UserActions.UPDATE_ZOOM_NATIVE_SETTINGS_SUCCESS:
      return {
        ...state,
        zoomUser: {
          ...action.response,
          loading: false
        }
      };
    case UserActions.UPDATE_ZOOM_NATIVE_SETTINGS_ERROR:
      return {
        ...state,
        zoomUser: {
          ...state.zoomUser,
          loading: false
        }
      };
    case SettingActions.TOGGLE_AUTO_SYNC_CRM_NOTES_REQUEST: {
      return {
        ...state,
        fetchingSettings: true
      };
    }
    case SettingActions.TOGGLE_AUTO_SYNC_CRM_NOTES_SUCCESS: {
      return {
        ...state,
        fetchingSettings: false,
        autoSyncCrmNotes: action.autoSyncCrmNotes
      };
    }
    case SettingActions.TOGGLE_AUTO_SYNC_CRM_NOTES_FAILURE: {
      return {
        ...state,
        fetchingSettings: false
      };
    }

    case SettingActions.UPDATE_USER_SETTINGS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case SettingActions.UPDATE_USER_SETTINGS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null
      };
    }

    case SettingActions.UPDATE_USER_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }

    case UserActions.PATCH_RECORDING_SETTINGS_REQUEST: {
      return {
        ...state,
        ...action.patchObject,
        fetchingSettings: true
      };
    }
    case UserActions.PATCH_RECORDING_SETTINGS_SUCCESS: {
      return {
        ...state,
        ...action.externalMeetings,
        ...action.internalMeetings,
        fetchingSettings: false
      };
    }
    case UserActions.PATCH_RECORDING_SETTINGS_FAILURE: {
      return {
        ...state,
        fetchingSettings: false
      };
    }
    case UserActions.REFRESH_ZOOM_USER_SUCCESS: {
      return {
        ...state,
        zoomUser: {
          ...state.zoomUser,
          ...action.zoomUser
        }
      };
    }

    case UserActions.FETCH_USER_VOICEPRINT_REQUEST:
      return {
        ...state,
        voiceprintsFetching: true
      };
    case UserActions.FETCH_USER_VOICEPRINT_SUCCESS:
      return {
        ...state,
        voiceprints: action.voiceprints,
        voiceprintsFetching: false
      };
    case UserActions.FETCH_USER_VOICEPRINT_FAILURE:
      return {
        ...state,
        voiceprintsFetching: false
      };
    case UserActions.CREATE_USER_VOICEPRINT_SUCCESS:
      return {
        ...state,
        voiceprints: {
          ...state.voiceprints,
          voicePrints: (state.voiceprints || {}).voicePrints
            ? state.voiceprints.voicePrints + 1
            : 1
        }
      };
    case UserActions.FECTH_MS_EXCHANGE_CONFIG_REQUEST:
      return {
        ...state,
        fetchingMsExchangeConfig: true
      };
    case UserActions.FECTH_MS_EXCHANGE_CONFIG_SUCCESS:
      return {
        ...state,
        msExchangeConfig: action.msExchangeConfig,
        fetchingMsExchangeConfig: false
      };
    case UserActions.FECTH_MS_EXCHANGE_CONFIG_FAILURE:
      return {
        ...state,
        fetchingMsExchangeConfig: false
      };
    case UserActions.UPDATE_MS_EXCHANGE_CONFIG_SUCCESS:
      return {
        ...state,
        msExchangeConfig: action.msExchangeConfig
      };
    case UserActions.DELETE_MS_EXCHANGE_CONFIG_SUCCESS:
      return {
        ...state,
        msExchangeConfig: {}
      };

    // Referral Logic
    case SettingActions.FETCH_REFERRALS_REQUEST:
      return {
        ...state,
        fetchReferral: {
          loading: true,
          error: false
        }
      };
    case SettingActions.FETCH_REFERRALS_SUCCESS:
      return {
        ...state,
        referral: action.referrals,
        fetchReferral: {
          loading: false,
          error: false
        }
      };
    case SettingActions.FETCH_REFERRALS_FAILURE:
      return {
        ...state,
        fetchReferral: {
          loading: false,
          error: true
        }
      };
    case SettingActions.FETCH_USER_CUSTOM_REMINDERS_REQUEST:
      return {
        ...state,
        customReminders: {
          ...state.customReminders,
          loading: true,
          error: false
        }
      };
    case SettingActions.FETCH_USER_CUSTOM_REMINDERS_SUCCESS:
      return {
        ...state,
        customReminders: {
          ...state.customReminders,
          reminders: action.reminders,
          loading: false,
          error: false
        }
      };
    case SettingActions.FETCH_USER_CUSTOM_REMINDERS_FAILURE:
      return {
        ...state,
        customReminders: {
          ...state.customReminders,
          loading: false,
          error: true
        }
      };

    case UserActions.FETCH_CAN_USER_EDIT_DEALS_SUCCESS:
      return {
        ...state,
        canEditDeals: action.status
      };
    case UserActions.FETCH_CAN_USER_EDIT_DEALS_FAILURE:
      return {
        ...state,
        canEditDeals: false
      };

    case UserActions.FETCH_USER_SUBSCRIPTION_LIMITS_SUCCESS: {
      const { limits } = action;
      const { features, flags, ...subscriptionDetails } = limits;
      return {
        ...state,
        features,
        flags,
        subscriptionLimits: {
          ...state.subscriptionLimits,
          ...subscriptionDetails
        }
      };
    }
    case UserActions.DISABLE_CLICKUP_INT_SUCCESS: {
      return {
        ...state,
        clickupIntegration: false
      };
    }
    default:
      return state;
  }
};

export default user;
