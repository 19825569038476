export const TemplateViewApiRoutes = {
  my: 'me',
  all: 'all',
  team: 'all_but_mine'
};

export const TEMPLATE_VIEW_TYPES = {
  MY_TEMPLATES: 'myTemplates',
  ORGANIZATION_TEMPLATES: 'organizationTemplates',
  AVOMA_TEMPLATES: 'avomaTemplates',
  ALL_TEMPLATES: 'allTemplates'
};

export const NEW_TEMPLATE_UUID = 'create_new';

export const AVOMA_DEFAULT_TEMPLATE = {
  name: 'Avoma Default',
  user: {
    email: 'ai@avoma.com',
    firstName: '',
    lastName: '',
    profilePic: null,
    position: null,
    isActive: true,
    jobFunction: null,
    upn: null
  },
  description: 'Avoma AI will auto-detect topics and generate notes',
  text: '',
  modifiedBy: null,
  textSlate: '',
  uuid: 'avoma-default',
  meetingTypes: [],
  isDefault: true,
  created: null,
  modified: null,
  privacy: 'organization'
};

export const ACTION_ITEM_SLATE_ELEMENT = {
  type: 'header-2',
  data: {
    highlight_notes_title: true,
    block_key: 'highlight_category_action_item'
  },
  children: [
    {
      text: 'Action Items'
    }
  ]
};
