import React from 'react';

import PropTypes from 'prop-types';
import { MdPerson as PersonIcon } from 'react-icons/md';
import stylePropType from 'react-style-proptype';

import { primaryRed } from 'helpers/colors';
import { mobile } from 'helpers/deviceCutoffs';

const ProfileIcon = ({
  profilePic = null,
  size = 30,
  style = {},
  dataTestId = '',
  firstNameLetter = null
}) => {
  const accountBtnStyle = styles.accountBtn;
  accountBtnStyle.width = `${size}px`;
  accountBtnStyle.minWidth = `${size}px`;
  accountBtnStyle.height = `${size}px`;
  accountBtnStyle.borderRadius = `${size / 2}px`;
  accountBtnStyle.backgroundImage = 'none';
  if (profilePic) accountBtnStyle.backgroundImage = `url('${profilePic}')`;
  return (
    <div data-testid={dataTestId} style={{ ...accountBtnStyle, ...style }}>
      {!profilePic && firstNameLetter && (
        <span className='bg-lightRed flex items-center justify-center text-base text-white'>
          {firstNameLetter}
        </span>
      )}
      {!profilePic && !firstNameLetter && (
        <PersonIcon className='bg-lightRed text-white' />
      )}
    </div>
  );
};

const styles = {
  accountBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: primaryRed,
    backgroundSize: 'contain',
    fontSize: '1.4em',
    [`@media (maxWidth: ${mobile}px)`]: {
      width: '23px',
      minWidth: '23px',
      height: '23px',
      fontSize: '.9em'
    }
  }
};

ProfileIcon.propTypes = {
  profilePic: PropTypes.string,
  size: PropTypes.number,
  style: stylePropType,
  dataTestId: PropTypes.string,
  firstNameLetter: PropTypes.string
};

export default ProfileIcon;
