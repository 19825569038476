import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import AvomaIcon from 'components/Common/AvomaIcon';
import AvomaTooltip from 'components/Common/AvomaTooltip';
import ButtonUnstyled from 'components/Common/ButtonUnstyled';

import L10n from 'helpers/L10n';

import Tokens from 'styles/tokens';

const EditorToolbarButton = props => {
  const {
    onMouseEnter,
    onMouseLeave,
    Icon = null,
    customIcon = null,
    onClick,
    active,
    text = '',
    tooltip,
    disabled = false,
    isPrivateNote = false,
    iconFilter = Tokens.filters.silver,
    dataTestId
  } = props;

  const isMoreActions = useMemo(
    () => tooltip === L10n.notes.toolbar.more,
    [tooltip]
  );

  const handleMouseEnter = e => {
    if (onMouseEnter) {
      onMouseEnter(e);
    }
  };

  const handleMouseLeave = e => {
    if (onMouseLeave) {
      onMouseLeave(e);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <AvomaTooltip
        tooltip={tooltip}
        tooltipPosition={isMoreActions ? 'top-right' : 'top-center'}
      >
        <ButtonUnstyled
          noDefaultStyles
          className={twMerge(
            'flex cursor-pointer items-center justify-center rounded bg-transparent p-1 text-silver hover:bg-f1 hover:text-gunmetal',
            active && !isPrivateNote && 'bg-gainsboro text-gunmetal',
            active &&
              isPrivateNote &&
              'text-silverDark bg-yellow hover:bg-yellow-lighter hover:text-silver-dark',
            disabled && 'cursor-not-allowed opacity-50'
          )}
          onMouseDown={e => {
            e.preventDefault();
            e.stopPropagation();
            if (onClick) {
              onClick();
            }
          }}
          dataTestId={dataTestId}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => {}}
          disabled={disabled}
        >
          {text && (
            <span className='relative box-border truncate p-0 text-xs font-bold'>
              {text}
            </span>
          )}
          {customIcon && (
            // eslint-disable-next-line avoma/use-rendericon-for-avomaicon
            <AvomaIcon
              icon={customIcon}
              small
              iconImageStyle={{
                filter: iconFilter
              }}
            />
          )}
          {Icon && (
            <AvomaIcon
              RenderIcon={Icon}
              small
              iconImageStyle={{
                filter: iconFilter
              }}
            />
          )}
        </ButtonUnstyled>
      </AvomaTooltip>
    </div>
  );
};

EditorToolbarButton.propTypes = {
  Icon: PropTypes.func,
  onClick: PropTypes.func,
  active: PropTypes.bool.isRequired,
  customIcon: PropTypes.object,
  text: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  isPrivateNote: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  iconFilter: PropTypes.string,
  dataTestId: PropTypes.string
};

export default EditorToolbarButton;
