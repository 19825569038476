import React, { lazy } from 'react';
import env from 'helpers/env';
import SuspenseWrapper from './SuspenseWrapper';

const SchedulerRoutes = lazy(() => import('./scheduler'));
const AppRoutes = lazy(() => import('./app'));

const Routes = () => {
  const currentHostname = window.location.hostname;
  const schedulerUrl = new URL(env.avomaSchedulerUrl);
  const isSchedulerHost = schedulerUrl.hostname === currentHostname;

  if (isSchedulerHost)
    return (
      <SuspenseWrapper>
        <SchedulerRoutes />
      </SuspenseWrapper>
    );

  return (
    <SuspenseWrapper>
      <AppRoutes />
    </SuspenseWrapper>
  );
};

export default Routes;
