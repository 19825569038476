import { validURL } from 'helpers';
import isHotkey from 'is-hotkey';
import { Editor, Range } from 'slate';

export const onKeyUpUtils = (event, editor) => {
  const { selection } = editor;

  if (isHotkey('space', event)) {
    if (selection && Range.isCollapsed(selection)) {
      // Get the cursor position
      const [start] = Range.edges(selection);

      // Get the points for entire line from start
      const pointsForLineBeforeCursor = Editor.before(editor, start, {
        unit: 'line'
      });

      const customStart = {
        ...start,
        offset: start.offset - 1
      };

      // If the pointsForLineBeforeCursor undefined then user is on the first line, this is an existing bug in collab notes
      // https://github.com/ianstormtaylor/slate/issues/4032
      if (!pointsForLineBeforeCursor) {
        const word1Pos = Editor.before(editor, start, {
          unit: 'word'
        });
        if (!word1Pos) {
          return;
        }
        const word2Pos = Editor.before(editor, word1Pos, {
          unit: 'word'
        });
        if (!word2Pos) {
          return;
        }
        const word3Pos = Editor.before(editor, word2Pos, {
          unit: 'word'
        });
        if (!word3Pos) {
          return;
        }
        const lastThreeWordsRange = Editor.range(editor, word3Pos, customStart);
        const lastThreeWordsText = Editor.string(editor, lastThreeWordsRange);

        const word4Pos = Editor.before(editor, word3Pos, {
          unit: 'word'
        });
        if (!word4Pos) {
          return;
        }
        const lastFourWordsRange = Editor.range(editor, word4Pos, customStart);
        const lastFourWordsText = Editor.string(editor, lastFourWordsRange);

        if (validURL(lastFourWordsText)) {
          editor.addLinkOnSpace(lastFourWordsText, lastFourWordsRange);
        } else if (validURL(lastThreeWordsText)) {
          editor.addLinkOnSpace(lastThreeWordsText, lastThreeWordsRange);
        }
        return;
      }

      // Get range of pointsForLineBeforeCursor and current cursor customStart
      const startEndRange = Editor.range(
        editor,
        pointsForLineBeforeCursor,
        customStart
      );

      // Get text of range got from previous steps
      const lineText = Editor.string(editor, startEndRange);

      if (!lineText || !lineText.trim()) return;

      const arr = lineText.split(' ');
      const lengthOfLine = arr[arr.length - 1].length;

      if (lengthOfLine === 0) return;

      const pointsForWordBeforeStart = Editor.before(editor, customStart, {
        distance: lengthOfLine
      });

      // Get range of pointsForWordBeforeStart and current cursor customStart
      const cursorAndBeforeWordRange = Editor.range(
        editor,
        pointsForWordBeforeStart,
        customStart
      );

      // Get text of range got from previous steps
      const lastWordText = Editor.string(editor, cursorAndBeforeWordRange);

      if (!lastWordText || !lastWordText.trim()) return;

      if (validURL(lastWordText))
        editor.addLinkOnSpace(lastWordText, cursorAndBeforeWordRange);
    }
  }

  if (event.key === 'Enter') {
    if (selection && Range.isCollapsed(selection)) {
      // Get the cursor position
      const [start] = Range.edges(selection);

      // Get the points of line before a cursor position start
      const customStart = Editor.before(editor, start, {
        unit: 'line'
      });

      const pointsForLineBeforeCursor = Editor.before(editor, customStart, {
        unit: 'line'
      });

      // https://github.com/ianstormtaylor/slate/issues/4032
      if (!pointsForLineBeforeCursor) {
        const word1Pos = Editor.before(editor, customStart, {
          unit: 'word'
        });
        if (!word1Pos) return;

        const word2Pos = Editor.before(editor, word1Pos, {
          unit: 'word'
        });

        const word3Pos = Editor.before(editor, word2Pos, {
          unit: 'word'
        });

        const lastThreeWordsRange = Editor.range(editor, word3Pos, start);
        const lastThreeWordsText = Editor.string(editor, lastThreeWordsRange);

        const word4Pos = Editor.before(editor, word3Pos, {
          unit: 'word'
        });

        const lastFourWordsRange = Editor.range(editor, word4Pos, start);
        const lastFourWordsText = Editor.string(editor, lastFourWordsRange);

        if (validURL(lastFourWordsText)) {
          editor.addLinkOnEnterForFirstLine(
            lastFourWordsText,
            lastFourWordsRange
          );
        } else if (validURL(lastThreeWordsText)) {
          editor.addLinkOnEnterForFirstLine(
            lastThreeWordsText,
            lastThreeWordsRange
          );
        }
        return;
      }

      const startEndRange = Editor.range(
        editor,
        pointsForLineBeforeCursor,
        customStart
      );

      const lineText = Editor.string(editor, startEndRange);

      if (!lineText || !lineText.trim()) return;

      const arr = lineText.split(' ');
      const lineTextLength = arr[arr.length - 1].length;

      if (lineTextLength === 0) return;

      const pointsForWordBeforeStart = Editor.before(editor, customStart, {
        distance: lineTextLength
      });

      const cursorAndBeforeWordRange = Editor.range(
        editor,
        pointsForWordBeforeStart,
        start
      );

      const lastWordText = Editor.string(editor, cursorAndBeforeWordRange);

      if (validURL(lastWordText))
        editor.addLinkOnEnter(lastWordText, cursorAndBeforeWordRange);
    }
  }
};
