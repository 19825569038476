/* avoma colors */
export const primaryRed = '#ff5740';
export const primaryRedHover = '#e04c38';
export const negativeRed = '#f44336';
export const negativeRedHover = '#c53323';
export const lightRed = '#ffe5e1';
export const lightRedHover = '#ffddd8';
export const coolBlue = '#009ddc'; /* looks like the gatorade flavor */
export const linkBlue = '#0070d2';
export const coolBlueLight = '#eef9fd';
export const turquoise = '#17BEBB';
export const turquoiseLight = 'rgba(23, 190, 187, 0.3)';
export const turquoiseExtraLight = 'rgba(23, 190, 187, 0.15)';
export const lightRedHighlight = 'rgba(255, 87, 64, 0.3)';
export const greenLight = 'rgba(76, 175, 80, 0.3)';
export const greenExtraLight = 'rgba(76, 175, 80, 0.15)';
export const yellow = '#FFC107';
export const yellowLight = '#FFF8E6';
export const primaryBlack = '#1b2733';
export const salesforceBlack = '#203242';
export const azure = '#00a1e0';
export const magenta = '#e91e63';
export const lightMagenta = 'rgba(233, 30, 99, 0.15)';

/* greyscale */
export const transparent = '#00000000';
export const white = '#FFFFFF';
export const ghost = '#FAFAFA';
export const snow = '#F9F9F9';
export const vapor = '#F6F6F6';
export const whiteSmoke = '#F5F5F5';
export const silverSmoke = '#E3E3E3';
export const silver = '#868e96';
export const smoke = '#EEEEEE';
export const gainsboro = '#DDDDDD';
export const iron = '#CCCCCC';
export const base = '#AAAAAA';
export const aluminum = '#999999';
export const jumbo = '#888888';
export const monsoon = '#777777';
export const steel = '#666666';
export const steelCharcoal = '#5E5E5E';
export const charcoal = '#555555';
export const tuatara = '#3D3D3D';
export const oil = '#333333';
export const jet = '#222222';
export const black = '#000000';
export const blue = '#2196F3';
export const green = '#4CAF50';
export const deepPurple = '#673AB7';
export const lightPurple = '#D9D0E8';
export const amber = '#FFC107';
export const coolGrey = '#b6bbc0';
export const greyWhite = '#fbfbfb';
