const colors = /** @type {const} */ ({
  white: '#FFF',
  whiteSmoke: '#F5F5F5',
  black: '#000',
  gainsboro: '#DDDDDD',
  monsoon: '#777777',
  f1: '#F1F1F1', // ***
  snow: '#F9F9F9',
  smoke: '#EEE',
  transparent: '#00000000',
  lightoverlay: '#00000007',
  drawerOverlay: 'rgba(0, 0, 0, .25)',
  overlay: '#00000077',
  lightGray: '#E9E9E9',
  lightGrey: '#FBFBFB',
  basicMercury: '#E5E5E5',
  lightGray2: '#E8E8E8',
  faintGrey: '#FFF9E6',

  gunmetalDarker: '#0D1319',
  gunmetalDark: '#141D26',
  gunmetal: '#1B2733',
  gunmetalLight: '#545D66',
  gunmetalLighter: '#8D9399',

  silverDarker: '#42464A',
  silverDark: '#646A70',
  silver: '#868E96',
  silverLight: '#A4AAB0',
  silverLighter: '#C2C6CA',

  blueDarker: '#003768',
  blueDark: '#00539D',
  blue: '#0070D2',
  blueLight: '#66A9E4',
  blueLighter: '#99C6ED', // ***
  blueLightest: '#CCE2F6',
  blueWhite: '#F2F8FD',
  blueWhiter: '#E4F0FE',
  blueWhitest: '#EEF6FE',
  lightOpaque: '#313C47',

  avomaRedDarker: '#7F2B1F',
  avomaRedDark: '#BF412F',
  avomaRed: '#FF5740',
  avomaRedMiddle: '#F19F90',
  avomaRedLight: '#F67268',
  avomaRedLighter: '#FFF0F0',
  avomaRedExtraLight: '#FFAB9F',
  avomaRedChart: '#E0625D',

  redDarker: '#79211A',
  redDark: '#B63228',
  red: '#F44336',
  redMiddle: '#F7654D',
  redLight: '#F88E86',
  redLighter: '#FDD9D7', // ***
  redLightest: '#FEECEB',

  redBold: '#aF171B',
  redClear: '#F9D2D0',

  tealDarker: '#0B5E5D',
  tealDark: '#118E8C',
  teal: '#17BEBB',
  tealLight: '#51CECC',
  tealLighter: '#88DEDD', // ***
  tealLightest: '#D0F2F1',

  purpleMiddle: '#A489D4',
  purple: '#673AB7',
  purpleLight: '#7F5AC3',
  purpleLighter: '#E0D7F0', // ***
  purpleExtraLight: '#F0EBF8',

  azure: '#00A1E0',
  azureLight: '#66C7EC',
  azureLighter: '#CCEDF9', // ***
  azureLightest: '#E5F6FC',
  azureMoreLight: '#B3E2F5',

  greenCheck: '#CFEAD7',
  limeGreen: '#CFF02B',
  greenYellow: '#A5A600',
  greenYellowMiddle: '#60B939',

  greenDarkest: '#155724',
  greenDarker: '#14A35B',
  greenDark: '#15A25B',
  green: '#1ACB72',
  greenMiddle: '#C5EDD6',
  greenLight: '#76E0AA',
  greenLighter: '#D1F5E3', // ***
  greenLightest: '#E8FAF1',

  yellowMoreDark: '#CC9A06',
  yellowDarker: '#856404',
  yellowDark: '#D9A404',
  yellow: '#FFC107',
  yellowMedium: '#FFE69A',
  yellowMiddle: '#FFD66A',
  yellowLight: '#FFDD66',
  yellowLighter: '#FFF3CE', // ***
  yellowLightest: '#FFFAEE', // ***
  yellowFaded: 'rgba(248, 199, 55, 0.06)',
  yellowLightScale: '#FFF3CD',

  magenta: '#E91E63',
  magentaLight: '#F278A1',
  magentaLighter: '#FBD2E0', // ***

  gallery: '#EAEAEA',
  gray: '#CCCCCC',
  grayLight: '#DDDDDD',
  greyLight: '#FDFDFD',

  orangeLight: '#F1AC25',
  orange: '#F46E35',
  orangeRed: '#CF9A43',
  orangeRedDark: '#493831',

  brown: '#785B57',
  brownLight: '#E4DEDD',
  brownLighter: '#F1EFEE',
  brownExtraDark: '#604946',

  charts: {
    BrownFaded: '#E9E1D8',
    BrownDefault: '#98745A',
    RedFaded: '#FFDDDE',
    RedDefault: '#FF262A',
    RustFaded: '#FFEBD5',
    RustDefault: '#F96616',
    OrangeFaded: '#FFF2D3',
    OrangeDefault: '#FFA732',
    LimeFaded: '#FDFFE7',
    LimeDefault: '#E8D300',
    MintFaded: '#EFFFC7',
    MintDefault: '#74BD00',
    GreenFaded: '#E7FDF2',
    GreenDefault: '#1ACB72',
    TealFaded: '#EFFEFD',
    TealDefault: '#17BEBB',
    AzureFaded: '#E0F4FE',
    AzureDefault: '#00A1E0',
    UltraFaded: '#D3E8FF',
    UltraDefault: '#2852FF',
    PurpleFaded: '#EDEAFD',
    PurpleDefault: '#885FF2',
    OrchidFaded: '#F6E9FE',
    OrchidDefault: '#C14FE6',
    MagentaFaded: '#FEE5F0',
    MagentaDefault: '#F83C85'
  },

  // *** When updating the colors with this comment, be sure to update HighlightCategoryColors
  // since we use these colors as keys. Not ideal obviously but that's the way it is

  smartIllustrations: {
    skyBlue: '#E5F6FC',
    green: '#E8FAF1',
    purple: '#F0EBF8',
    pink: '#FDE8EF',
    teal: '#D1F2F1'
  },

  speakerLabelColors: [
    '#5951ff',
    '#5dabff',
    '#11cc79',
    '#eb3747',
    '#f8d257',
    '#635d74',
    '#5951ff',
    '#5dabff',
    '#01a45d',
    '#b43038'
  ]
});

const borders = /** @type {const} */ ({
  commentDash: {
    borderLeft: `1px dashed ${colors.blue}`
  },
  standard: `1px solid ${colors.gainsboro}`,
  light: `1px solid ${colors.smoke}`,
  selected: `1px solid ${colors.blueLighter}`,
  transparent: `1px solid transparent`
});

const Tokens = /** @type {const} */ ({
  spacing: {
    half: '4px',
    one: '8px',
    onehalf: '12px',
    two: '16px',
    twohalf: '20px',
    three: '24px',
    threehalf: '28px',
    four: '32px',
    fourhalf: '36px',
    five: '40px',
    fivehalf: '44px',
    six: '48px',
    seven: '56px',
    eight: '64px',
    nine: '72px',
    ten: '80px',
    xlarge: '60px',
    borderRadius: '3px'
  },
  highlight: {
    underline: '2px'
  },
  colors,
  filters: {
    // Use https://codepen.io/sosuke/pen/Pjoqqp to generate filters for a given color.
    // Make sure you add `brightness(0) saturate(100%)` to generated value which presets icon color to black before applying filter
    // try this as well https://angel-rs.github.io/css-color-filter-generator/
    gunmetal:
      'brightness(0) saturate(100%) invert(9%) sepia(8%) saturate(4025%) hue-rotate(169deg) brightness(92%) contrast(85%)',
    silver:
      'brightness(0) saturate(100%) invert(59%) sepia(6%) saturate(485%) hue-rotate(169deg) brightness(93%) contrast(91%)',
    silverDark:
      'brightness(0) saturate(100%) invert(43%) sepia(10%) saturate(336%) hue-rotate(169deg) brightness(89%) contrast(85%)',
    silverLighter:
      'brightness(0) saturate(100%) invert(93%) sepia(15%) saturate(69%) hue-rotate(169deg) brightness(85%) contrast(86%)',
    gainsboro:
      'brightness(0) saturate(100%) invert(99%) sepia(5%) saturate(100%) hue-rotate(280deg) brightness(113%) contrast(73%)',
    black: 'brightness(0) saturate(100%)',
    white:
      'brightness(0) saturate(100%) invert(100%) sepia(59%) saturate(0%) hue-rotate(149deg) brightness(104%) contrast(105%)',
    avomaRed:
      'brightness(0) saturate(100%) invert(58%) sepia(100%) saturate(4218%) hue-rotate(336deg) brightness(104%) contrast(101%)',
    avomaRedDark:
      'brightness(0) saturate(100%) invert(33%) sepia(82%) saturate(1059%) hue-rotate(335deg) brightness(84%) contrast(93%)',
    avomaRedDarker:
      'brightness(0) saturate(100%) invert(18%) sepia(26%) saturate(5000%) hue-rotate(346deg) brightness(91%) contrast(87%)',
    blue: 'brightness(0) saturate(100%) invert(32%) sepia(94%) saturate(1131%) hue-rotate(184deg) brightness(90%) contrast(112%)',
    blueDark:
      'brightness(0) saturate(100%) invert(14%) sepia(86%) saturate(3386%) hue-rotate(195deg) brightness(106%) contrast(103%)',
    blueLighter:
      'brightness(0) saturate(100%) invert(72%) sepia(23%) saturate(434%) hue-rotate(166deg) brightness(96%) contrast(110%)',
    greenDark:
      'brightness(0) saturate(100%) invert(44%) sepia(100%) saturate(442%) hue-rotate(99deg) brightness(92%) contrast(84%)',
    greenDarkest:
      'brightness(0) saturate(100%) invert(21%) sepia(97%) saturate(386%) hue-rotate(81deg) brightness(99%) contrast(94%)',
    purple:
      'brightness(0) saturate(100%) invert(19%) sepia(97%) saturate(2130%) hue-rotate(252deg) brightness(93%) contrast(85%)'
  },
  shadows: {
    minimal: {
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)'
    },
    small: {
      // typically used for close stuff like buttons
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)'
    },
    medium: {
      // typically used for floating stuff like menus
      boxShadow:
        '0 0 25px 0 rgba(40, 47, 60, 0.05), 0 3px 4px 0 rgba(40, 47, 60, 0.05)'
    },
    large: {
      // typically used for floating stuff like modals
      boxShadow: '0 46px 100px 0 rgba(0, 0, 0, 0.15)'
    }
  },
  type: {
    header1: {
      fontSize: '1.75rem', //  28px
      fontWeight: 'normal',
      lineHeight: '1.57',
      letterSpacing: '0.8px'
    },
    header2: {
      fontSize: '1.625rem', //  26px
      fontWeight: 'normal',
      lineHeight: '1.3'
    },
    header3: {
      fontSize: '1.375rem', //  22px
      fontWeight: 'normal',
      lineHeight: '1.59',
      letterSpacing: '0.5px'
    },
    header4: {
      fontSize: '1.25rem', //  20px
      fontWeight: 'normal',
      lineHeight: '1.6',
      letterSpacing: '0.5px'
    },
    header5: {
      fontSize: '1.125rem', //  18px
      fontWeight: 'normal',
      lineHeight: '1.56',
      letterSpacing: '0.4px'
    },
    header6: {
      fontSize: '0.5625rem', //  9px
      fontWeight: 'bold',
      lineHeight: '1.56',
      letterSpacing: '1px',
      textTransform: 'uppercase'
    },
    header7: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      lineHeight: '1.56',
      letterSpacing: '2px',
      textTransform: 'uppercase'
    },
    body: {
      fontSize: '1rem', // 16 px
      fontWeight: 'normal',
      lineHeight: '1.625',
      letterSpacing: '0.4px'
    },
    bodyS: {
      fontSize: '0.875rem', //  14px
      fontWeight: 'normal',
      lineHeight: '1.57',
      letterSpacing: '0.4px'
    },
    bodyXS: {
      fontSize: '0.75rem', //  12px
      fontWeight: 'normal',
      lineHeight: '1.5',
      letterSpacing: '0.6px'
    },
    timestamp: {
      fontSize: '0.5625rem', //  9px
      fontWeight: 'bold',
      lineHeight: '1.333', // 12px
      letterSpacing: '1px'
    },
    popupTitle: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      lineHeight: '1.43',
      letterSpacing: '0.1px'
    },
    font: 'Lato, sans-serif',
    extraBold: 900
  },
  borders
});

export default Tokens;
