import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import useFeature from 'hooks/useFeature';

import { useCreateManualTicketMutation } from 'services/ticketing';

import PurchaseLicenseBanner from 'components/Account/Billing/PurchaseLicenseBanner';
import AvomaButton from 'components/Common/AvomaButton';
import AvomaDialog from 'components/Common/AvomaDialog';
import AvomaTextField from 'components/Common/AvomaTextField';
import AvomaVariableTextArea from 'components/Common/AvomaVariableTextArea';
import NotificationBanner from 'components/Common/NotificationBanner';
import ClickupIntegrationAutomationsSelectList from 'components/Tasks/Clickup/Automated/CreateTask/SelectList';
import ClickupIntegrationAutomationsTaskTemplate from 'components/Tasks/Clickup/Automated/CreateTask/TaskTemplate';
import ClickupIntegrationAutomationsWorkspaceList from 'components/Tasks/Clickup/Automated/CreateTask/Workspace';
import AutomationAssigneeClickupMembers from 'components/Tasks/Clickup/Members';
import ManualFormTaskDueDate from 'components/Tasks/generic/DueDate/TaskDueDate';
import {
  errorNotification,
  successNotification
} from 'components/ToastNotifications';

import L10n from 'helpers/L10n';
import Licenses from 'helpers/Licenses';

import Tokens from 'styles/tokens';

import { DESCRIPTION_ACTION_DATA } from './constants';

const ClickupIntegrationManualCreateDialog = ({
  open,
  setOpen,
  selectedText,
  meetingUuid,
  editor,
  refetchPreferences,
  clickupTaskPreference = {}
}) => {
  const [taskDescription, setTaskDescription] = useState(selectedText);
  const [taskTitle, setTaskTitle] = useState(selectedText);
  const [workspace, setWorkspace] = useState(undefined);
  const [selectedList, setSelectedList] = useState('');
  const [selectedTaskTemplate, setSelectedTaskTemplate] = useState(undefined);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const { uuid: ticketingProviderUuid } = useSelector(
    state => state.user?.ticketingProviders?.clickup || {}
  );

  const isFeatureClickupIntegrationEnabled = useFeature(
    Licenses.FEATURES.clickupIntegration
  );

  const [dueDateOption, setDueDateOption] = useState('1');
  const [dueDateDaysInFuture, setDueDateDaysInFuture] = useState('1');
  const [dueDateCalendarValue, setDueDateCalendarValue] = useState(null);

  const descriptionAction = DESCRIPTION_ACTION_DATA;

  const [createManualTicketFn, { isLoading }] = useCreateManualTicketMutation();

  const innerModalRef = useRef(null);

  const handleCloseDialog = () => {
    setTaskDescription('');
    setTaskTitle('');
    setWorkspace(undefined);
    setSelectedList('');
    setSelectedTaskTemplate(undefined);
    setSelectedAssignees([]);
    setDueDateOption('1');
    setDueDateDaysInFuture('1');
    setDueDateCalendarValue(null);
  };

  const handleCreateTicket = async () => {
    const payload = {
      name: taskTitle,
      description: taskDescription,
      assignees_external_id: selectedAssignees,
      collection: selectedList,
      meeting_uuid: meetingUuid,
      status: null,
      task_template_uuid: selectedTaskTemplate,
      due_date_option: parseInt(dueDateOption),
      due_date_days_in_future: dueDateDaysInFuture,
      due_date_calendar_value: dueDateCalendarValue?.toISOString(),
      workspace_uuid: workspace,
      ticket_type: 'new_ticket'
    };

    const res = await createManualTicketFn({
      payload,
      providerUuid: ticketingProviderUuid
    });

    if (res.data?.ticketUrl) {
      editor.insertLink(res.data.ticketUrl, selectedText);
    }

    if (res.error) {
      errorNotification({
        message: L10n.clickupIntegration.createTicketError
      });
    } else {
      successNotification({
        message: L10n.clickupIntegration.createTicketSuccess
      });
    }

    setOpen(false);
    handleCloseDialog();
    refetchPreferences();
  };

  const isCreateDisabled = !taskTitle || !workspace || !selectedList;

  const handleDateChange = date => {
    setDueDateCalendarValue(date);
  };

  const fillTaskPreference = () => {
    setWorkspace(clickupTaskPreference.team);
    setSelectedList(clickupTaskPreference.collection);
    setSelectedTaskTemplate(clickupTaskPreference.template);
    setDueDateOption(clickupTaskPreference.dueDateOptionType.toString());
    setDueDateDaysInFuture(
      clickupTaskPreference.dueDateDaysInFuture.toString()
    );
    setDueDateCalendarValue(clickupTaskPreference.dueDateCalendarValue);
    setSelectedAssignees(clickupTaskPreference.assignees);
  };

  return (
    <AvomaDialog
      open={open}
      setOpen={setOpen}
      controlled
      title={L10n.clickupIntegration.createDialogTitle}
      contentClasses='w-[850px] max-w-[850px] max-h-[800px]'
      closeCleanupHandler={handleCloseDialog}
      childrenClasses='max-h-[810px]'
    >
      {!isFeatureClickupIntegrationEnabled && (
        <PurchaseLicenseBanner
          msg={L10n.clickupIntegration.upgradeTitle}
          feature='clickupIntegration'
        />
      )}
      <div
        className={twMerge(
          'flex flex-col',
          !isFeatureClickupIntegrationEnabled &&
            'blur-sm pointer-events-none mt-2'
        )}
      >
        <AvomaTextField
          label={L10n.clickupIntegration.taskTitleLabel}
          placeholder={L10n.clickupIntegration.taskTitlePlaceholder}
          labelStyle={styles.label}
          inputClassName='!bg-white'
          value={taskTitle}
          onChange={e => setTaskTitle(e.target.value)}
        />
        <div
          className='mb-4 flex items-center justify-between gap-4'
          ref={innerModalRef}
        >
          <ClickupIntegrationAutomationsWorkspaceList
            action={{
              label: L10n.clickupIntegration.workspace
            }}
            clientValue={workspace}
            handleValueChange={setWorkspace}
          />
          <ClickupIntegrationAutomationsSelectList
            workspaceUuid={workspace}
            clientValue={selectedList}
            handleValueChange={setSelectedList}
            portalContainer={innerModalRef.current}
          />
        </div>
        <div
          className='mb-4 flex items-center justify-between gap-4'
          ref={innerModalRef}
        >
          <ClickupIntegrationAutomationsTaskTemplate
            key='task-template'
            workspaceUuid={workspace}
            action={{
              label: L10n.clickupIntegration.template
            }}
            clientValue={selectedTaskTemplate}
            handleValueChange={setSelectedTaskTemplate}
            containerClassName='flex-1'
          />
          <ManualFormTaskDueDate
            dueDateOption={dueDateOption}
            dueDateDaysInFuture={dueDateDaysInFuture}
            dueDateCalendarValue={dueDateCalendarValue}
            handleDueDateOptionChange={setDueDateOption}
            handleDueDateDaysInFutureChange={setDueDateDaysInFuture}
            handleDueDateCalendarValueChange={handleDateChange}
            disabled={selectedTaskTemplate}
          />
        </div>
        {selectedTaskTemplate && (
          <NotificationBanner
            content={L10n.clickupIntegration.templateBannerText}
            style={{ ...Tokens.type.bodyS }}
          />
        )}

        <div
          className={twMerge(
            'pointer-events-auto mt-4 flex w-full flex-col gap-2',
            selectedTaskTemplate && 'pointer-events-none opacity-40'
          )}
        >
          <p className='text-sm font-bold text-gunmetal'>
            {L10n.clickupIntegration.taskDescription}
          </p>
          <AvomaVariableTextArea
            placeholder={`Enter ${descriptionAction.label}`}
            variables={descriptionAction.optionChoices}
            variableTitle={descriptionAction.optionTitle}
            value={taskDescription}
            onChange={setTaskDescription}
            dropdownContentClassname='pointer-events-auto'
            wrapperStyle={{ padding: 0 }}
            portalContainer={innerModalRef.current}
          />
        </div>
        <div
          className={twMerge(
            'mt-4 flex w-full flex-col gap-2',
            selectedTaskTemplate && 'pointer-events-none opacity-40'
          )}
        >
          <p className='text-sm font-bold text-gunmetal'>
            {L10n.clickupIntegration.assignees}
          </p>
          <AutomationAssigneeClickupMembers
            workspaceUuid={workspace}
            clientValue={selectedAssignees}
            handleValueChange={setSelectedAssignees}
            triggerClasses='border rounded py-2 h-12 items-center flex'
            portalContainer={innerModalRef.current}
          />
        </div>
        <div className='flex items-baseline justify-between'>
          <div>
            {clickupTaskPreference &&
              Object.keys(clickupTaskPreference).length > 0 && (
                <AvomaButton
                  minimal
                  label={L10n.clickupIntegration.preferenceLabel}
                  style={{ marginRight: Tokens.spacing.three }}
                  small
                  onClick={fillTaskPreference}
                />
              )}
          </div>
          <div className='mt-6 flex items-center justify-end gap-4'>
            <AvomaButton
              black
              small
              onClick={() => setOpen(false)}
              label={L10n.general.cancel}
            />
            <AvomaButton
              small
              onClick={handleCreateTicket}
              label={L10n.createHighlightModal.create}
              disabled={isCreateDisabled}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </AvomaDialog>
  );
};

const styles = {
  label: {
    ...Tokens.type.bodyS,
    fontWeight: 'bold',
    marginBottom: Tokens.spacing.half
  }
};

ClickupIntegrationManualCreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedText: PropTypes.string.isRequired,
  meetingUuid: PropTypes.string.isRequired,
  editor: PropTypes.object.isRequired,
  refetchPreferences: PropTypes.func,
  clickupTaskPreference: PropTypes.object
};

export default ClickupIntegrationManualCreateDialog;
