import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import BotState from 'helpers/botState';
// eslint-disable-next-line import/no-cycle
import store, { denormalize } from 'helpers/reduxStore';

import { getMeetingBasePath } from './IntegrationHelper';
// eslint-disable-next-line import/no-cycle
import { getManagedMembers } from './getManagedMembers';
import { formattedTime } from './getPrettyTimeFromSeconds';
import MeetingState, {
  isMeetingEnded,
  isMeetingProcessing
} from './meetingState';

export const isMeetingDashboardUrl = url => {
  const regex = new RegExp('/meetings/[0-9A-Fa-f-]{36}.*', 'g');
  return Boolean(regex.exec(url));
};

export const isCallsDashboardUrl = url => {
  const regex = new RegExp('/calls/[0-9A-Fa-f-]{36}.*', 'g');
  return Boolean(regex.exec(url)) || url.includes('?type=call');
};

export const isGlobalSearchUrl = url => url.includes('?type=search');

export const currentMeetingsData = state => {
  // we should return combined data and searchData
  const { data } = state.meetings;
  return {
    ...data
  };
};

export const isMeetingSharable = (meeting, user, members, teams) => {
  const { organizerEmail } = meeting || {};
  const attendees = flattenMeetingAttendees(meeting);
  const { email: userEmail, orgRole: userRole } = user || {};
  if (userRole && userRole.name === 'guest') return false;
  const isAdmin = userRole && userRole.name === 'admin';

  // To combat cyclic dependency we aren't using the helper/organization.js
  // Disable sharing either for the entire org, or when we selective ban users from sharing
  const isSharingDisabledForOrg =
    store.getState().members?.organization?.orgSettingsData?.disableSharing;

  const isSharingDisabledForMeeting = meeting?.disableSharing;

  // Whether given user is manager of attendee
  const managedMembers = getManagedMembers({
    email: userEmail,
    members,
    teams
  });
  const managedEmails = _.map(managedMembers, 'user.email');
  const attendeeEmails = _.map(attendees, 'email');
  const isUserManagerOfAttendee = Boolean(
    _.size(_.intersection(managedEmails, attendeeEmails)) > 0
  );

  // Whether given user is in meetings attendees
  const participantsEmails = _.uniq(
    (attendees || []).map(attendee => attendee.email).concat(organizerEmail)
  );
  const isUserAttendee =
    _.findIndex(
      participantsEmails,
      email => email?.toLowerCase() === userEmail?.toLowerCase()
    ) > -1;

  return (
    !isSharingDisabledForOrg &&
    !isSharingDisabledForMeeting &&
    (isUserAttendee ||
      isAdmin ||
      isUserManagerOfAttendee ||
      (meeting && meeting.uuid === store.getState().onboarding.demoMeetingUuid))
  );
};

export const getRecurringMeetingsQuery = (meeting, updateFuture, updateAll) => {
  // By default filter only given meeting
  let query = m =>
    m.recurringMasterId === meeting.recurringMasterId &&
    m.uuid === meeting.uuid;
  if (updateAll) {
    query = m => m.recurringMasterId === meeting.recurringMasterId;
  } else if (updateFuture) {
    query = m =>
      m.recurringMasterId === meeting.recurringMasterId &&
      new Date(m.startAt) >= new Date(meeting.startAt);
  }
  return query;
};

export const isMeetingPast = meeting => {
  // Determines if the meeting is over or not
  if (!meeting) return false;

  const { states } = meeting;
  let processing = false;
  if (!_.isEmpty(states)) {
    processing = isMeetingProcessing(states);
  }

  return (
    new Date() >= new Date(meeting.endAt) ||
    meeting.state === 'ended' ||
    processing
  );
};

// Determines if the meeting is over or not. With a 30 min buffer as the last condition
export const isMeetingOver = meeting => {
  if (isEmpty(meeting)) {
    return false;
  }

  let processing = false;
  let ended = false;
  if (!isEmpty(meeting.states)) {
    processing = isMeetingProcessing(meeting.states);
    ended = isMeetingEnded(meeting.states);
  }

  const current = moment();
  const endDate = moment(meeting.endAt).add(30, 'm');

  return (
    current.isAfter(endDate) || ended || processing || meeting.state === 'ended'
  );
};

export const isWithinMeetingTime = (meeting, strict) => {
  // Determines the current time is within the
  // scheduled meeting time
  const now = new Date();
  const buffer = strict ? 0 : 10 * 60 * 1000;
  if (meeting) {
    const { botState, states } = meeting;
    let meetingState;
    if (!_.isEmpty(states)) {
      meetingState = states.meeting && states.meeting.key;
    }

    // TODO: THIS IS A HACK ADDED FOR ZOOM MEETING BY INT AND SHOULD BE REMOVED ASAP.
    let isZoomMtgWithinTime = false;
    if (
      meeting.conferenceData &&
      meeting.conferenceData.service &&
      meeting.conferenceData.service.toLowerCase() === 'zoom' &&
      meeting.botState === BotState.recordingUsingIntegration
    ) {
      isZoomMtgWithinTime =
        now >= new Date(meeting.startAt) &&
        now <= moment(meeting.endAt).add(15, 'm').toDate();
    }
    return (
      botState === BotState.dialedIn ||
      botState === BotState.dropping ||
      meetingState === MeetingState.botRecStart ||
      meetingState === MeetingState.botRecStop ||
      meetingState === MeetingState.botDropping ||
      meetingState === MeetingState.botKicked ||
      meetingState === MeetingState.zoomMeetingStart ||
      meetingState === MeetingState.zoomMeetingEnd ||
      meetingState === MeetingState.zoomRecStart ||
      meetingState === MeetingState.zoomRecStop ||
      (now >= new Date(meeting.startAt) - buffer &&
        now <= new Date(meeting.endAt)) ||
      isZoomMtgWithinTime
    );
  }
  return false;
};

export const isMeetingHappening = (meeting, strict) => {
  // Determines if the meeting is currently happening,
  // so if the meeting is in 'ended' state we consider
  // it done

  if (!meeting) {
    return false;
  }

  // Strict: there is a delay in zoom processing because of which the meeting state does not change to ended

  // Check on meeting states
  const { states: meetingState = {} } = meeting;
  if (!_.isEmpty(meetingState) && isMeetingEnded(meetingState)) {
    return false;
  }

  const ended = meeting.state === 'ended';
  if (meeting) {
    if (strict) {
      return (
        !ended &&
        (meeting.state === 'inprogress' || isWithinMeetingTime(meeting, true))
      );
    }
    return !ended && isWithinMeetingTime(meeting);
  }
  return false;
};

export const isMeetingInProgress = meeting => {
  const start = moment(meeting.startAt).subtract(10, 'm');
  const now = moment();

  const ended = isMeetingOver(meeting);

  return !ended && now.isAfter(start);
};

export const meetingEndedRecently = meeting => {
  // Determine if the meeting starts within the half hour
  if (meeting) {
    const HALF_HOUR = 30 * 60 * 1000;
    return new Date() - new Date(meeting.endAt) < HALF_HOUR;
  }
  return false;
};

export const meetingStartsSoon = meeting => {
  // Determine if the meeting ended less than an hour ago
  if (meeting) {
    const HALF_HOUR = 30 * 60 * 1000;
    return new Date(meeting.startAt) - new Date() < HALF_HOUR;
  }
  return false;
};

export const getMeetingUrl = meeting => {
  const basePath = getMeetingBasePath();
  return `${basePath}/${meeting.uuid}`;
};

export const canEditMeeting = (meeting, email) => {
  if (!meeting || !email) {
    return false;
  }

  if (meeting.organizerEmail?.toLowerCase() === email?.toLowerCase()) {
    return true;
  }

  const meetingAttendees = flattenMeetingAttendees(meeting);

  if (
    meetingAttendees &&
    _.findIndex(
      meetingAttendees,
      attendee => attendee.email.toLowerCase() === email.toLowerCase()
    ) >= 0
  ) {
    return true;
  }
  return false;
};

export const canEditMeetingWithPrivacyCheck = (meeting, email) => {
  if (!meeting || !email) {
    return false;
  }

  if (meeting.privacy === 'organization') {
    return true;
  }

  if (meeting.organizerEmail?.toLowerCase() === email?.toLowerCase()) {
    return true;
  }
  const meetingAttendees = flattenMeetingAttendees(meeting);
  if (
    meetingAttendees &&
    _.findIndex(
      meetingAttendees,
      attendee => attendee.email.toLowerCase() === email.toLowerCase()
    ) >= 0
  ) {
    return true;
  }
  return false;
};

export const getSearchIfPresent = (data, baseObj) => {
  let result;
  if (baseObj.search && baseObj.search.meetings) {
    result = _.clone(baseObj.search);
    result.meetings = denormalize(data, baseObj.search.meetings);
  } else {
    result = _.clone(baseObj);
    if (baseObj.meetings) {
      result.meetings = denormalize(data, baseObj.meetings);
    }
  }
  if (result.meetings) {
    result.meetings = result.meetings.filter(meeting => {
      if (!meeting.__deleted) return meeting;
      return null;
    });
    for (let i = 0; i < result.meetings.length; i += 1) {
      const meeting = result.meetings[i];
      if (i === 0) {
        meeting.showTime = true;
      } else {
        const prev = result.meetings[i - 1];
        const start = moment(meeting.startAt).startOf('day');
        const prevStart = moment(prev.startAt).startOf('day');
        const delta = start.diff(prevStart, 'days');
        meeting.showTime = delta !== 0;
      }
    }
  }
  return result;
};

export const getMeetingDateDurationString = meeting => {
  if (!meeting) return {};
  const startMoment = moment(meeting.startAt);
  const endMoment = moment(meeting.endAt);
  const showEnd = meeting.duration > 0;
  const startTimeString = startMoment.format('h:mma').replace(':00', '');
  const endTimeString = endMoment.format('h:mma').replace(':00', '');
  let timeString = '';
  let durationString = '';
  const isCurYear = startMoment.year() === moment().year();
  let dateString = startMoment.format(isCurYear ? 'MMM Do' : 'MMM Do, YYYY');
  const days = moment()
    .startOf('day')
    .diff(moment(meeting.startAt).startOf('day'), 'days');
  if (days === 0) {
    dateString = 'Today';
  } else if (days === 1) {
    dateString = 'Yesterday';
  } else if (days === -1) {
    dateString = 'Tomorrow';
  }

  if (showEnd) {
    // start & duration
    durationString = `${formattedTime(meeting.duration)}`;
    timeString = `${startTimeString}`;
  } else {
    // start - end
    timeString = `${startTimeString} - ${endTimeString}`;
  }

  return { dateString, durationString, timeString };
};

export const flattenMeetingAttendees = meeting => {
  const attendeesWithOrganization = meeting?.attendeesPerOrg?.usersWOrg || {};
  const attendeesWithoutOrganization =
    meeting?.attendeesPerOrg?.usersWoOrg || [];

  const flattenArrayWithOrgAttendees = Object.values(
    attendeesWithOrganization
  ).flat();

  return [...flattenArrayWithOrgAttendees, ...attendeesWithoutOrganization];
};

export const groupMeetingsByDate = meetings =>
  meetings.reduce((acc, meeting) => {
    // Use moment.js to format the date part from the ISO string
    const date = moment(meeting.startAt).format('YYYY-MM-DD');

    // If this date is not yet a key in the accumulator, add it with an empty array
    if (!acc[date]) {
      acc[date] = [];
    }

    // Push the current meeting into the array for this date
    acc[date].push(meeting);

    return acc;
  }, {});

export const isCloudMeetingNotAutoRecording = meeting => {
  const { conferenceData, zoomMeetingInfo, msMeetingInfo, gmeetMeetingInfo } =
    meeting;
  return (
    conferenceData &&
    conferenceData.service &&
    ((conferenceData.service.toLowerCase() === 'zoom' &&
      (!zoomMeetingInfo || zoomMeetingInfo.autoRecording !== 'cloud')) ||
      (conferenceData.service.toLowerCase() === 'msteam' &&
        (!msMeetingInfo || !msMeetingInfo.autoRecording)) ||
      (conferenceData.service.toLowerCase() === 'googlemeet' &&
        (!gmeetMeetingInfo || !gmeetMeetingInfo.autoRecording)))
  );
};
