import React from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';

import Tokens from 'styles/tokens';

class CardSection extends React.Component {
  render() {
    const { title, children, userStyles, titleStyles, contentStyles } =
      this.props;
    return (
      <div
        style={{
          ...userStyles
        }}
      >
        <div style={{ ...styles.header, ...titleStyles }}>{title}</div>
        <div style={{ ...styles.content, ...contentStyles }}>{children}</div>
      </div>
    );
  }
}

CardSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  userStyles: stylePropType,
  titleStyles: stylePropType,
  contentStyles: stylePropType
};

const styles = {
  header: {
    ...Tokens.type.header5,
    fontWeight: 'bold'
  },
  content: {
    padding: Tokens.spacing.two
  }
};

export default CardSection;
