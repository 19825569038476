import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef, useState } from 'react';
import {
  BiBold as BoldIcon,
  BiItalic as ItalicIcon,
  BiLink as LinkIcon,
  BiRedo as RedoIcon,
  BiStrikethrough as StrikethroughIcon,
  BiUnderline as UnderlinedIcon,
  BiUndo as UndoIcon
} from 'react-icons/bi';
import {
  MdFormatListBulleted as BulletedListIcon,
  MdFormatListNumbered as NumberedListIcon
} from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Editor, Transforms } from 'slate';
import { twMerge } from 'tailwind-merge';

import useFeature from 'hooks/useFeature';

import AvomaPopoverControlled from 'components/Common/AvomaPopoverControlled';
import EditorToolbarButton from 'components/Editor/EditorToolbarButton';
import {
  TYPE_HEADER1,
  TYPE_HEADER2,
  TYPE_HEADER3,
  TYPE_PARAGRAPH
} from 'components/Notes/Slate/Plugins/Types';
import {
  BOLD,
  ITALIC,
  STRIKETHROUGH,
  UNDERLINE
} from 'components/Notes/Slate/Plugins/withMarks';

import L10n from 'helpers/L10n';
import Licenses from 'helpers/Licenses';

import { ReactComponent as ClickupCreateIcon } from 'images/ic_clickup_integration.svg';
import { ReactComponent as ChecklistIcon } from 'images/ic_editor_checklist.svg';
import { ReactComponent as MoreIcon } from 'images/ic_more_vertical.svg';

import Tokens from 'styles/tokens';

import LinkEditPopover from './Elements/Link/EditPopover';
import { isBlockActive } from './Plugins/List/Queries/getActiveListType';
import {
  TYPE_CHECKLIST,
  TYPE_ORDERED_LIST,
  TYPE_UNORDERED_LIST
} from './Plugins/List/types';
import isNodeTypeIn from './Plugins/Queries/isNodeTypeIn';

const Toolbar = ({
  editor,
  isPrivateNote,
  roundedToolbar,
  handleClickupToolbarButton,
  isCollab
}) => {
  const [shouldShowLinkInput, setShouldShowLinkInput] = useState(false);
  const [selectionRange, setSelectionRange] = useState(null);

  const [linkInput, setLinkInput] = useState('');
  const [titleInput, setTitleInput] = useState('');

  const [toolbarWidth, setToolbarWidth] = useState(null);
  const containerRef = useRef(null);
  const isFeatureClickupIntegrationEnabled = useFeature(
    Licenses.FEATURES.clickupIntegration
  );

  const clickupIntegration = useSelector(
    state => state.user.ticketingProviders?.clickup?.clickupIntegration
  );

  const showLinkInput = () => {
    setShouldShowLinkInput(true);
    setSelectionRange(editor.selection);
  };

  const hideLinkInput = () => {
    Transforms.select(editor, selectionRange);
    setShouldShowLinkInput(false);
    setSelectionRange(null);
    setLinkInput('');
    setTitleInput('');
  };

  const toggleHeaderType = headerType => () => {
    if (isNodeTypeIn(editor, headerType)) {
      editor.setType(TYPE_PARAGRAPH);
    } else {
      editor.setType(headerType);
    }
  };

  const handleCreateLink = () => {
    editor.insertLink(linkInput, titleInput);
    hideLinkInput();
  };

  useLayoutEffect(() => {
    if (!containerRef.current) return;

    const debouncedHandleResize = debounce(entry => {
      if (entry?.target) {
        setToolbarWidth(entry.contentRect.width);
      }
    }, 100);

    const observer = new ResizeObserver(entries => {
      const entry = entries[0];
      if (entry) {
        window.requestAnimationFrame(() => {
          debouncedHandleResize(entry);
        });
      }
    });

    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
      debouncedHandleResize.cancel(); // Cancel any pending debounced calls
    };
  }, []);

  const buttons = [
    <EditorToolbarButton
      key={TYPE_HEADER1}
      text='H1'
      active={isNodeTypeIn(editor, TYPE_HEADER1)}
      onClick={toggleHeaderType(TYPE_HEADER1)}
      tooltip={L10n.notes.toolbar.h1}
      isPrivateNote={isPrivateNote}
    />,
    <EditorToolbarButton
      key={TYPE_HEADER2}
      text='H2'
      active={isNodeTypeIn(editor, TYPE_HEADER2)}
      onClick={toggleHeaderType(TYPE_HEADER2)}
      tooltip={L10n.notes.toolbar.h2}
      isPrivateNote={isPrivateNote}
    />,
    <EditorToolbarButton
      key={TYPE_HEADER3}
      text='H3'
      active={isNodeTypeIn(editor, TYPE_HEADER3)}
      onClick={toggleHeaderType(TYPE_HEADER3)}
      tooltip={L10n.notes.toolbar.h3}
      isPrivateNote={isPrivateNote}
    />,
    <hr
      key='divider-1'
      className={twMerge(
        'mx-1 h-6 w-px border-none bg-gainsboro',
        isPrivateNote && 'bg-yellow'
      )}
    />,
    <div key='link-block' className='relative'>
      <EditorToolbarButton
        Icon={LinkIcon}
        onClick={showLinkInput}
        tooltip={L10n.notes.toolbar.link}
        disabled={editor.selection === null}
        isPrivateNote={isPrivateNote}
        active={shouldShowLinkInput}
      />
      {shouldShowLinkInput && (
        <LinkEditPopover
          onOutsideClick={hideLinkInput}
          handleLinkChange={value => setLinkInput(value)}
          handleTitleChange={value => setTitleInput(value)}
          isCreate
          onClickCreate={handleCreateLink}
          style={{ marginTop: Tokens.spacing.one, position: 'fixed' }}
          url={linkInput}
          title={titleInput}
          isTextSelectedForLink={
            !!(editor?.selection && Editor.string(editor, editor.selection))
          }
        />
      )}
    </div>,
    <EditorToolbarButton
      key={BOLD}
      Icon={BoldIcon}
      active={editor.isMarkActive(BOLD)}
      onClick={() => editor.toggleMark(BOLD)}
      tooltip={L10n.notes.toolbar.bold}
      isPrivateNote={isPrivateNote}
    />,
    <EditorToolbarButton
      key={ITALIC}
      Icon={ItalicIcon}
      active={editor.isMarkActive(ITALIC)}
      onClick={() => editor.toggleMark(ITALIC)}
      tooltip={L10n.notes.toolbar.italic}
      isPrivateNote={isPrivateNote}
    />,
    <EditorToolbarButton
      key={UNDERLINE}
      Icon={UnderlinedIcon}
      active={editor.isMarkActive(UNDERLINE)}
      onClick={() => editor.toggleMark(UNDERLINE)}
      tooltip={L10n.notes.toolbar.underline}
      isPrivateNote={isPrivateNote}
    />,
    <EditorToolbarButton
      key={STRIKETHROUGH}
      Icon={StrikethroughIcon}
      active={editor.isMarkActive(STRIKETHROUGH)}
      onClick={() => editor.toggleMark(STRIKETHROUGH)}
      tooltip={L10n.notes.toolbar.strikethrough}
      isPrivateNote={isPrivateNote}
    />,
    <hr
      key='divider-2'
      className={twMerge(
        'mx-1 h-6 w-px border-none bg-gainsboro',
        isPrivateNote && 'bg-yellow'
      )}
    />,
    <EditorToolbarButton
      key={TYPE_ORDERED_LIST}
      Icon={NumberedListIcon}
      iconStyles={{ fontSize: '1.9em' }}
      active={isBlockActive(editor, TYPE_ORDERED_LIST)}
      onClick={() => editor.toggleList(TYPE_ORDERED_LIST)}
      tooltip={L10n.notes.toolbar.orderedList}
      isPrivateNote={isPrivateNote}
    />,
    <EditorToolbarButton
      key={TYPE_UNORDERED_LIST}
      Icon={BulletedListIcon}
      iconStyles={{ fontSize: '1.9em' }}
      active={isBlockActive(editor, TYPE_UNORDERED_LIST)}
      onClick={() => editor.toggleList(TYPE_UNORDERED_LIST)}
      tooltip={L10n.notes.toolbar.unorderedList}
      isPrivateNote={isPrivateNote}
    />,
    <EditorToolbarButton
      key={TYPE_CHECKLIST}
      Icon={ChecklistIcon}
      active={isBlockActive(editor, TYPE_CHECKLIST)}
      onClick={() => editor.toggleList(TYPE_CHECKLIST)}
      tooltip={L10n.notes.toolbar.checklist}
      isPrivateNote={isPrivateNote}
    />,
    <EditorToolbarButton
      key='undo-button'
      Icon={UndoIcon}
      onClick={() => editor.undo()}
      tooltip={L10n.notes.toolbar.undo}
      isPrivateNote={isPrivateNote}
    />,
    <EditorToolbarButton
      key='redo-button'
      Icon={RedoIcon}
      onClick={() => editor.redo()}
      tooltip={L10n.notes.toolbar.redo}
      isPrivateNote={isPrivateNote}
    />,
    <React.Fragment key='create-clickup-task-button'>
      {isFeatureClickupIntegrationEnabled && clickupIntegration && isCollab && (
        <EditorToolbarButton
          key='create-clickup-task-button'
          Icon={ClickupCreateIcon}
          onClick={handleClickupToolbarButton}
          tooltip={
            !(editor?.selection && Editor.string(editor, editor.selection))
              ? L10n.notes.toolbar.clickupSelect
              : L10n.notes.toolbar.clickupCreate
          }
          disabled={
            !(editor?.selection && Editor.string(editor, editor.selection))
          }
          isPrivateNote={isPrivateNote}
          iconStyles={{
            stroke: 'none',
            width: Tokens.spacing.onehalf,
            height: Tokens.spacing.onehalf
          }}
        />
      )}
    </React.Fragment>
  ];

  let toolbarButtons = buttons;
  let overflowButtons = [];

  if (toolbarWidth) {
    // toolbar width minus flex gap spacing divided by width of button plus consideration for two dividers
    const possibleToolbarElementsCount =
      Math.floor((toolbarWidth - buttons.length * 4) / 26) + 2;

    if (possibleToolbarElementsCount < buttons.length) {
      toolbarButtons = buttons.slice(0, possibleToolbarElementsCount);
      overflowButtons = buttons.slice(possibleToolbarElementsCount);
    }
  }

  return (
    <div
      ref={containerRef}
      className={twMerge(
        'sticky top-0 left-0 z-[4] box-border flex w-full items-center gap-1 overflow-hidden border-b border-b-gainsboro bg-white py-1 px-4',
        roundedToolbar && 'rounded-tl rounded-tr',
        isPrivateNote && 'mt-2 border-y border-y-yellow bg-yellow-lightest'
      )}
    >
      {toolbarButtons}
      {!isEmpty(overflowButtons) && (
        <AvomaPopoverControlled
          position='bottom'
          align='end'
          contentClasses='!p-2 flex flex-row gap-2'
          sideOffset={8}
          dropdownTrigger={() => (
            <EditorToolbarButton
              key='overflow-menu-button'
              Icon={MoreIcon}
              tooltip={L10n.notes.toolbar.more}
              isPrivateNote={isPrivateNote}
            />
          )}
        >
          {overflowButtons}
        </AvomaPopoverControlled>
      )}
    </div>
  );
};

Toolbar.propTypes = {
  editor: PropTypes.object.isRequired,
  isPrivateNote: PropTypes.bool,
  roundedToolbar: PropTypes.bool,
  handleClickupToolbarButton: PropTypes.func,
  isCollab: PropTypes.bool
};

Toolbar.defaultProps = {
  isPrivateNote: false,
  roundedToolbar: false
};

export default Toolbar;
