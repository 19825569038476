// this converts a seconds value such as 95.25 to 00:01:35

// turns a single digit number like 5 into "05"
const fillTensDigit = num => (num < 10 ? `0${num}` : num);

export const hoursMinutesSeconds = seconds => {
  const wholeSeconds = Math.floor(seconds);

  const prettyHours = fillTensDigit(Math.floor(wholeSeconds / 3600));
  const prettyMinutes = fillTensDigit(
    Math.floor((wholeSeconds - prettyHours * 3600) / 60)
  );
  const prettySeconds = fillTensDigit(wholeSeconds % 60);

  return `${prettyHours}:${prettyMinutes}:${prettySeconds}`;
};

export const minutesSeconds = seconds => {
  const wholeSeconds = Math.floor(seconds);

  const hours = Math.floor(wholeSeconds / 60 / 60);
  const prettyMinutes = fillTensDigit(Math.floor(wholeSeconds / 60) % 60);
  const prettySeconds = fillTensDigit(wholeSeconds % 60);

  return `${hours > 0 ? `${hours}:` : ''}${prettyMinutes}:${prettySeconds}`;
};

export const humanReadableMinutesSeconds = seconds => {
  if (seconds == null) {
    // if seconds is null or undefined, return undefined
    return undefined;
  }

  const wholeSeconds = Math.floor(seconds);
  const prettyMinutes = Math.floor(wholeSeconds / 60);
  if (wholeSeconds > 0) {
    const prettySeconds = wholeSeconds % 60;
    return `${prettyMinutes}m ${prettySeconds}s`;
  }
  return `${prettyMinutes}m`;
};

export const prettyMinuteSeconds = seconds => {
  const wholeSeconds = Math.floor(seconds).toString();
  const prettySeconds = (wholeSeconds % 60).toString();
  const prettyMinutes = Math.floor(wholeSeconds / 60).toString();
  return `${prettyMinutes.padStart(2, 0)}:${prettySeconds.padStart(2, 0)}`;
};

export const timeToMinutes = seconds => {
  const wholeSeconds = Math.floor(seconds);

  const prettyMinutes = Math.round(wholeSeconds / 60);

  return prettyMinutes;
};

export const formattedTime = seconds => {
  const wholeSeconds = Math.floor(seconds);
  const hours = Math.floor(wholeSeconds / 3600);
  const minutes = Math.round((wholeSeconds - hours * 3600) / 60);
  const prettyMinutes = minutes;
  const minString =
    prettyMinutes === 1 ? `${prettyMinutes} min` : `${prettyMinutes} mins`;
  if (hours > 0) {
    const hourString = hours === 1 ? `${hours} hr` : `${hours} hrs`;

    if (hours === 1 && minutes === 0) {
      return hourString;
    }
    if (hours === 1) {
      return `${hourString} ${minString}`;
    }
    if (hours > 1 && minutes === 0) {
      return hourString;
    }
    return `${hourString} ${minString}`;
  }
  if (minutes > 0 && wholeSeconds >= 60) {
    return minString;
  }
  const prettySeconds = wholeSeconds % 60;
  return prettySeconds === 1 ? `${prettySeconds} sec` : `${prettySeconds} secs`;
};

export const minutesOrSecondsFormat = seconds => {
  const wholeSeconds = Math.floor(seconds);
  const minutes = Math.round(wholeSeconds / 60);

  if (minutes > 0 && wholeSeconds > 60) {
    return `${minutes} min`;
  }

  return `${wholeSeconds} sec`;
};
