const BotState = {
  notPresent: 'not_present',
  disabled: 'disabled',
  wait: 'wait',
  dialing: 'dialing',
  dialedIn: 'dialed_in',
  enabled: 'u_enabled',
  dropping: 'dropping',
  recordingUsingIntegration: 'rec_int',
  transcribing: 'transcribing',
  transcriptionError: 'transcription_error',
  transcriptionAvailable: 'transcription_available',
  recordingError: 'recording_error',
  error: 'error',
  limitExhausted: 'l_disabled'
};

export default BotState;
