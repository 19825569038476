export const DESCRIPTION_ACTION_DATA = {
  label: 'Task description',
  description: 'Task description',
  uid: 'task_description',
  isRequired: false,
  optionTitle: 'Add to customize description',
  optionChoices: [
    {
      label: 'Meeting Title',
      description: 'Include the meeting title in the task description',
      value: 'meeting_title'
    },
    {
      label: 'Meeting Purpose',
      description: 'Include the meeting purpose in the task description',
      value: 'meeting_purpose'
    },
    {
      label: 'Meeting Date',
      description: 'Include the meeting date in the task description',
      value: 'meeting_date'
    },
    {
      label: 'Meeting Host Full Name',
      description: 'Include the meeting host full name in the task description',
      value: 'meeting_host_full_name'
    },
    {
      label: 'External Invitee Email or Name',
      description:
        'Include the external invitee email or name in the task description',
      value: 'external_invitee_email_or_name'
    },
    {
      label: 'Meeting Link',
      description: 'Include the meeting link in the task description',
      value: 'meeting_link'
    },
    {
      label: 'Meeting Notes',
      description: 'Include the entire meeting notes in the task description',
      value: 'meeting_notes'
    }
  ],
  defaultValue: null,
  apiUrl: null
};
