import PropTypes from 'prop-types';
import Radium from 'radium';
import React, { useEffect, useRef, useState } from 'react';
import { MdClear as ClearIcon } from 'react-icons/md';
import stylePropType from 'react-style-proptype';

import ButtonUnstyled from 'components/Common/ButtonUnstyled';

import { ReactComponent as SearchIcon } from 'images/ic_search.svg';

import Tokens from 'styles/tokens';

const SearchField = props => {
  const {
    searchTitle,
    handleClear,
    instantSearch,
    size,
    style,
    disabled,
    placeholder,
    handleSearch,
    fieldStyles,
    onToggleFocus,
    focusOnClear,
    componentName,
    focusOnLoad,
    className = '',
    searchButtonClassName = '',
    onKeyDown,
    dataTestId = 'search-field'
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(searchTitle || '');

  const previousSearchTitle = useRef(searchTitle);
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (focusOnLoad) {
      inputRef?.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (previousSearchTitle.current !== searchTitle) {
      setValue(searchTitle);
    }

    previousSearchTitle.current = searchTitle;
  }, [searchTitle]);

  const toggleFocus = () => {
    setIsFocused(!isFocused);
    if (onToggleFocus) onToggleFocus(!isFocused);
  };

  const clearSearch = () => {
    setValue('');

    if (focusOnClear && value) {
      inputRef.current.focus();
    }
    // clear search meetings
    if (handleClear) handleClear();
  };

  const handleChange = event => {
    const { value: inputValue } = event.target;

    if (!inputValue) {
      clearSearch();
      return;
    }
    if (instantSearch) {
      _handleSearch(inputValue);
    }

    setValue(inputValue);
  };

  const handleKeyDown = event => {
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
        event.preventDefault();
        break;

      case 'ArrowLeft':
      case 'ArrowRight':
        event.stopPropagation();
        break;

      case 'Backspace':
        break;

      case 'Enter':
        _handleSearch();
        break;

      case 'Escape':
        handleEscape();
        break;

      default:
        break;
    }

    onKeyDown?.(event);
  };

  const _handleSearch = inputValue => {
    if (inputValue) {
      handleSearch(inputValue.trim());
    } else {
      handleSearch(value.trim());
    }
  };

  const _handleOnClick = () => {
    _handleSearch(value);
  };

  const handleEscape = () => {
    inputRef.current.blur();
    containerRef.current.blur();
  };

  let height = Tokens.spacing.four;
  const fontSize = '0.875rem';
  if (size === 'large') {
    height = '46px';
  } else if (size === 'medium') {
    height = '40px';
  }

  const isSearchDisabled = !instantSearch && value.trim() === '';

  return (
    <div style={{ ...style, height }} className={className}>
      <div style={{ ...styles.field, ...fieldStyles }} ref={containerRef}>
        <input
          style={{ ...styles.input, fontSize }}
          type='text'
          className='placeholder:font-normal placeholder:text-gunmetal-lighter'
          ref={inputRef}
          key='search-input'
          value={value}
          maxLength={255}
          onChange={handleChange}
          onFocus={toggleFocus}
          onBlur={toggleFocus}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          size={1} // needed otherwise min width is too wide
          disabled={disabled}
          data-testid={dataTestId}
        />
        {value !== '' && (
          <ButtonUnstyled
            style={styles.clearButton}
            onClick={clearSearch}
            data-testid='clear-search-btn'
          >
            <ClearIcon style={styles.toolsIcon} />
          </ButtonUnstyled>
        )}
        <ButtonUnstyled
          className={searchButtonClassName}
          style={
            instantSearch
              ? styles.searchButton
              : { ...styles.searchButton, ...styles.clickableSearchButton }
          }
          key={instantSearch ? 'search-btn' : 'clickable-search-btn'}
          onClick={instantSearch ? null : _handleOnClick}
          disabled={isSearchDisabled || disabled}
          disabledStyles={styles.disabledStyles}
          data-testid='search-btn'
        >
          <SearchIcon
            key={`${componentName}-search-field-icon`}
            style={{
              ...styles.searchIcon,
              ...(!instantSearch && styles.clickableSearchIcon),
              ...(disabled && styles.disabledStyles)
            }}
          />
        </ButtonUnstyled>
      </div>
    </div>
  );
};

SearchField.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['default', 'large', 'medium']),
  handleSearch: PropTypes.func.isRequired,
  style: stylePropType,
  handleClear: PropTypes.func,
  searchTitle: PropTypes.string,
  instantSearch: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldStyles: stylePropType,
  componentName: PropTypes.string.isRequired,
  onToggleFocus: PropTypes.func,
  focusOnClear: PropTypes.bool,
  focusOnLoad: PropTypes.bool,
  className: PropTypes.string,
  searchButtonClassName: PropTypes.string,
  onKeyDown: PropTypes.func,
  dataTestId: PropTypes.string
};

SearchField.defaultProps = {
  autoFocus: false,
  placeholder: 'Search hint text',
  size: 'default',
  handleClear: null,
  searchTitle: '',
  instantSearch: false,
  disabled: false,
  onToggleFocus: null,
  focusOnClear: true,
  focusOnLoad: false,
  onKeyDown: null
};

const styles = {
  field: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
    alignItems: 'center',
    borderRadius: '3px',
    border: `1px solid ${Tokens.colors.gainsboro}`,
    backgroundColor: Tokens.colors.white,
    ':focus': {
      border: `1px solid ${Tokens.colors.gray}`
    }
  },
  input: {
    border: 0,
    boxSizing: 'border-box',
    margin: 0,
    padding: `${Tokens.spacing.half} ${Tokens.spacing.one}`,
    outline: 0,
    color: Tokens.colors.gunmetal,
    backgroundColor: Tokens.colors.white,
    borderRadius: Tokens.spacing.borderRadius,
    flex: '1 0',
    height: '100%'
  },
  clearButton: {
    color: Tokens.colors.silver,
    backgroundColor: 'transparent',
    fontSize: '16px',
    border: 0,
    display: 'flex',
    marginRight: Tokens.spacing.one,
    cursor: 'pointer',
    ':hover': {
      color: Tokens.colors.silverDark
    }
  },
  searchIcon: {
    filter: Tokens.filters.silver
  },
  clickableSearchIcon: {
    ':hover': {
      filter: Tokens.filters.silverDark
    }
  },
  searchButton: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `0 ${Tokens.spacing.one}`,
    cursor: 'default'
  },
  clickableSearchButton: {
    backgroundColor: Tokens.colors.whiteSmoke,
    cursor: 'pointer',
    borderRadius: '0 3px 3px 0',
    ':hover': {
      backgroundColor: Tokens.colors.lightGray
    }
  },
  disabledStyles: {
    cursor: 'not-allowed',
    opacity: '0.4'
  }
};

export default Radium(SearchField);
