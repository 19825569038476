import PropTypes from 'prop-types';
import React from 'react';
import stylePropType from 'react-style-proptype';
import { twMerge } from 'tailwind-merge';

import AvomaButton from 'components/Common/AvomaButton';
import AvomaModal from 'components/Common/AvomaModal';

import L10n from 'helpers/L10n';

import Tokens from 'styles/tokens';

import Spinner from './Spinner';

const ConfirmModal = ({
  title,
  text,
  confirmText,
  cancelText,
  isOpen,
  onConfirm,
  onCancel,
  positive,
  loading,
  loadingLabel,
  confirmDisabled,
  width,
  hideCancel,
  additionalActions,
  confirmTooltip,
  containerStyles,
  contentStyles,
  additionalText = '',
  confirmLoading = false,
  childrenClassName = '',
  dataTestId = ''
}) => (
  <AvomaModal
    isOpen={isOpen}
    onCancel={onCancel}
    title={title}
    containerStyles={{
      ...(width && { maxWidth: `${width}px` }),
      ...containerStyles
    }}
    contentStyles={{ ...contentStyles }}
    dataTestId={dataTestId}
  >
    <div className={twMerge('mb-8 whitespace-pre-wrap', childrenClassName)}>
      {loading ? (
        <section style={styles.loader}>
          <Spinner />
          <br />
          {loadingLabel}
        </section>
      ) : (
        <React.Fragment>
          <span>{text}</span>
          {additionalText !== '' && (
            <p className='mt-2 text-base'>{additionalText}</p>
          )}
        </React.Fragment>
      )}
    </div>
    <div style={styles.modalFooter}>
      {additionalActions}
      {!hideCancel && (
        <AvomaButton onClick={onCancel} label={cancelText} black small />
      )}
      <AvomaButton
        onClick={onConfirm}
        label={confirmText}
        className='ml-4'
        destructive={!positive}
        disabled={loading || confirmDisabled}
        tooltip={confirmTooltip}
        tooltipPosition='bottom-left'
        small
        loading={confirmLoading}
      />
    </div>
  </AvomaModal>
);

const styles = {
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  loader: {
    ...Tokens.type.bodyS,
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    color: Tokens.colors.silver
  },
  disabledButton: {
    cursor: 'not-allowed',
    opacity: '0.4'
  }
};

ConfirmModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  confirmText: PropTypes.string,
  positive: PropTypes.bool,
  loading: PropTypes.bool,
  loadingLabel: PropTypes.string,
  confirmDisabled: PropTypes.bool,
  width: PropTypes.string,
  cancelText: PropTypes.string,
  hideCancel: PropTypes.bool,
  additionalActions: PropTypes.node,
  confirmTooltip: PropTypes.string,
  containerStyles: stylePropType,
  contentStyles: stylePropType,
  additionalText: PropTypes.string,
  confirmLoading: PropTypes.bool,
  childrenClassName: PropTypes.string,
  dataTestId: PropTypes.string
};

ConfirmModal.defaultProps = {
  title: '',
  text: '',
  cancelText: L10n.general.cancel,
  confirmText: L10n.general.confirm,
  positive: false,
  loading: false,
  confirmDisabled: false,
  hideCancel: false
};

export default ConfirmModal;
