import env from 'helpers/env';

import { AnalyticsBrowser } from '@segment/analytics-next';

function initializeSegment() {
  window.segmentV2 = AnalyticsBrowser.load(
    {
      writeKey: env.segmentV2WriteKey
    },
    {
      integrations: {
        'Segment.io': {
          apiHost: 'sega.avoma.com/v1'
        }
      }
    }
  );
}

window.addEventListener('DOMContentLoaded', initializeSegment);
