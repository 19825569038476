import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import {
  TYPE_CHECKLIST,
  TYPE_LIST_ITEM,
  TYPE_ORDERED_LIST,
  TYPE_UNORDERED_LIST
} from 'components/Notes/Slate/Plugins/List/types';

import CheckListItemElement from './Elements/ChecklistItem';
import {
  TYPE_CHECKLIST_ITEM,
  TYPE_HEADER1,
  TYPE_HEADER2,
  TYPE_HEADER3
} from './Plugins/Types';

const Element = ({
  attributes,
  children,
  element,
  readOnly,
  shouldHideEmptyElement
}) => {
  switch (element.type) {
    case 'link':
      return (
        <a {...attributes} href={element.href}>
          {children}
        </a>
      );
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case TYPE_HEADER1:
      return <h1 {...attributes}>{children}</h1>;
    case TYPE_HEADER2:
      return <h2 {...attributes}>{children}</h2>;
    case TYPE_HEADER3:
      return <h3 {...attributes}>{children}</h3>;

    case TYPE_LIST_ITEM:
      return <li {...attributes}>{children}</li>;
    case TYPE_UNORDERED_LIST:
      return <ul {...attributes}>{children}</ul>;
    case TYPE_CHECKLIST:
      return (
        <ul className='list-none p-0' {...attributes}>
          {children}
        </ul>
      );
    case TYPE_CHECKLIST_ITEM:
      return (
        <CheckListItemElement
          attributes={attributes}
          element={element}
          readOnly={readOnly}
        >
          {children}
        </CheckListItemElement>
      );
    case TYPE_ORDERED_LIST:
      return (
        <ol type={['1', 'a', 'i'][(element.depth || 0) % 3]} {...attributes}>
          {children}
        </ol>
      );
    default: {
      return (
        <p
          className={twMerge(
            'relative text-base',
            shouldHideEmptyElement && 'hidden'
          )}
          {...attributes}
        >
          {children}
        </p>
      );
    }
  }
};

Element.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node,
  element: PropTypes.node,
  readOnly: PropTypes.bool
};

Element.defaultProps = {
  readOnly: false
};

export default Element;
