import L10n from './L10n';
import { isZoomContext, isZoomApp, isZoomAppAgent } from './zoomApp';

import { errorNotification } from 'components/ToastNotifications';

export const API_FAILURE = 'API Failure';
export const BAD_REQUEST = 'Bad Request';
export const NOT_FOUND = 'Not Found';
export const FORBIDDEN = 'Forbidden';
export const CONFLICT = 'Conflict';
export const UNAUTHORIZED = 'Unauthorized';
export const JSON_PARSE_ERROR = 'Json Parse Error';
export const SERVER_ERROR = 'Internal Server Error';

const LOGOUT_LIST = ['/api/v1/userprofile', '/api/v1/meetings/'];
const isUrlInLogoutList = url => {
  for (let ii = 0; ii < LOGOUT_LIST.length; ii += 1) {
    const uu = LOGOUT_LIST[ii];
    if (url.includes(uu)) {
      return true;
    }
  }
  return false;
};
async function handleResponse(response, thunkApi, options = {}) {
  let json = {};
  const { ignoreErrorNotification = false } = options;
  if (response.status === 401) {
    if (isUrlInLogoutList(response.url)) {
      thunkApi.dispatch({ type: 'LOGOUT' });
    }
    if (response.url.includes('/public/')) {
      // window.location.href = '/signup';
    } else {
      // remove the redux state item so that there isn't a flicker glitch
      // in firefox when the token is expired
      localStorage.removeItem('reduxState');
      thunkApi.dispatch({ type: 'LOGOUT' });
      window.location.href = isZoomContext()
        ? '/zoom/signup?error=401'
        : '/signup?error=401';
    }
    const e = new Error(UNAUTHORIZED);
    e.name = UNAUTHORIZED;
    e.status = response.status;
    throw e;
  } else if (response.ok) {
    if (response.status !== 204) {
      try {
        json = await response.json();
      } catch (e) {
        const err = new Error(e);
        err.name = JSON_PARSE_ERROR;
        err.status = response.status;
        throw err;
      }
    }
    return json;
  } else {
    let statusText = API_FAILURE;
    if (response.status === 403) {
      statusText = FORBIDDEN;
    } else if (response.status === 400) {
      statusText = BAD_REQUEST;
    } else if (response.status === 404) {
      statusText = NOT_FOUND;
    } else if (response.status === 409) {
      statusText = CONFLICT;
    } else if (response.status === 500) {
      statusText = SERVER_ERROR;
    }
    const e = new Error(statusText);
    e.name = statusText;
    e.status = response.status;
    try {
      json = await response.json();
    } catch (err) {
      // do nothing
    } finally {
      e.data = json;
    }
    if (!ignoreErrorNotification) {
      const message = e?.data?.detail || L10n.globalErrorHandler.heading;
      errorNotification({ message });
    }
    throw e;
  }
}
// We want the zoom app to use bearer token , while we want our app.avoma to user credentials include, hence this logic in the file

export const get = async ({ url, thunkApi, options }) => {
  const headers = {};

  const fetchOptions = {
    method: 'GET',
    headers,
    ...options
  };

  fetchOptions.credentials = 'include';
  const response = await fetch(url, fetchOptions);
  return handleResponse(response, thunkApi, options);
};

export const post = async ({ url, body, thunkApi, options }) => {
  const headers = {};

  const fetchOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    },
    body: body ? JSON.stringify(body) : '{}',
    ...options
  };

  fetchOptions.credentials = 'include';
  const response = await fetch(url, fetchOptions);
  return handleResponse(response, thunkApi, options);
};

export const deleteMethod = async ({ url, body, thunkApi, options }) => {
  const headers = {};

  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    },
    body: body ? JSON.stringify(body) : '{}',
    ...options
  };

  fetchOptions.credentials = 'include';
  const response = await fetch(url, fetchOptions);
  return handleResponse(response, thunkApi, options);
};

export const put = async ({ url, body, thunkApi, options }) => {
  const headers = {};

  const fetchOptions = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    },
    body: body ? JSON.stringify(body) : '{}',
    ...options
  };

  fetchOptions.credentials = 'include';
  const response = await fetch(url, fetchOptions);
  return handleResponse(response, thunkApi, options);
};

export const patch = async ({ url, body, thunkApi, options }) => {
  const headers = {};

  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    },
    body: body ? JSON.stringify(body) : '{}',
    ...options
  };

  fetchOptions.credentials = 'include';
  const response = await fetch(url, fetchOptions);
  return handleResponse(response, thunkApi, options);
};
