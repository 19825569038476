/* eslint-disable camelcase */
import camelize from 'camelize';
import { EditorState, convertToRaw } from 'draft-js';
import { EventTypes } from 'redux-segment';

import { TemplateActions } from 'actions/actionTypes';

import { DEFAULT_SLATE } from 'components/Notes/Slate/CollabEditor';

import SlateHelper from 'helpers/SlateHelper';
import debounceAction from 'helpers/debounceAction';
import env from 'helpers/env';
import history from 'helpers/history';
import { deleteMethod, get, patch, post } from 'helpers/http';

import { AVOMA_DEFAULT_TEMPLATE } from 'constants/templates';

export const NEW_TEMPLATE = 'create_new';

const NEW_TEMPLATE_OBJECT = {
  text: JSON.stringify(
    convertToRaw(EditorState.createEmpty().getCurrentContent())
  ),
  text_slate: SlateHelper.NEW_DEFAULT,
  name: 'New Template',
  description: '',
  privacy: 'organization'
};

export const fetchTemplates = () => dispatch => {
  dispatch({ type: 'FETCH_TEMPLATES_REQUEST' });
  // get my own templates
  get(`${env.avomaApiUrl}/api/v1/templates/`)
    .then(response => {
      const { results } = response;
      dispatch({
        type: 'FETCH_TEMPLATES_SUCCESS',
        templates: results
      });
    })
    .catch(err => {
      dispatch({
        type: 'FETCH_TEMPLATES_FAILURE',
        error: err.message
      });
      throw err;
    });

  // get shared templates
};

export const fetchNextTemplatesPage = (type, url) => dispatch => {
  dispatch({ type: 'FETCH_TEMPLATES_REQUEST' });

  if (!url) {
    throw Error('[avoma] requested next page without specifying a url');
  }

  if (!type && type !== 'page' && type !== 'upcoming') {
    throw Error('[avoma] requested next page without specifying a page');
  }

  get(url)
    .then(page => {
      const { results, next } = page;

      const baseDispatchPayload = {
        templates: results
      };

      const dispatchPayload = {
        ...baseDispatchPayload,
        type: 'FETCH_TEMPLATES_SUCCESS',
        nextPastPageUrl: next
      };
      dispatch(dispatchPayload);
    })
    .catch(err => {
      dispatch({
        type: 'FETCH_TEMPLATES_FAILURE',
        error: err.message
      });
      throw err;
    });
};

export const fetchCurrentlySelectedTemplate = uuid => dispatch => {
  if (!uuid) {
    throw Error('[avoma] requested template without specifying a uuid');
  }

  dispatch({ type: 'FETCH_TEMPLATE_REQUEST', uuid });

  return get(`${env.avomaApiUrl}/api/v1/templates/${uuid}/`)
    .then(template => {
      dispatch({
        type: 'FETCH_TEMPLATE_SUCCESS',
        template
      });

      return template;
    })
    .catch(err => {
      dispatch({
        type: 'FETCH_TEMPLATE_FAILURE',
        error: err.message,
        uuid
      });

      throw err;
    });
};

// update template in editor
export const updateTemplate =
  ({ text, name, description, privacy, uuid, is_default_for_user }) =>
  (dispatch, getState) => {
    if (!uuid) {
      throw Error('[avoma] no uuid provided to saveTemplate');
    }

    const { user } = getState().user;

    dispatch({
      type: TemplateActions.UPDATE_TEMPLATE_REQUEST,
      uuid,
      meta: {
        analytics: {
          eventType: EventTypes.track,
          eventPayload: { text, name, description, privacy, uuid }
        }
      }
    });

    patch(`${env.avomaApiUrl}/api/v1/templates/${uuid}/`, {
      text,
      name,
      description,
      privacy,
      uuid,
      is_default_for_user
    })
      .then(template => {
        dispatch({
          type: TemplateActions.UPDATE_TEMPLATE_SUCCESS,
          template,
          user
        });
      })
      .catch(err => {
        dispatch({
          type: TemplateActions.UPDATE_TEMPLATE_FAILURE,
          error: err.message,
          uuid
        });
        throw err;
      });
  };
const debouncedUpdateTemplate = debounceAction(updateTemplate, 1000);

// TODO: deprecate
export const createTemplate =
  ({
    text,
    textSlate,
    name,
    description,
    privacy,
    meeting_type_uuids,
    is_default_for_user,
    redirect = true,
    setUuidParam,
    handleModeChange
  }) =>
  dispatch => {
    dispatch({
      type: 'CREATE_TEMPLATE_REQUEST',
      meta: {
        analytics: {
          eventType: EventTypes.track,
          eventPayload: { text, name, description, privacy, meeting_type_uuids }
        }
      }
    });

    return post(`${env.avomaApiUrl}/api/v1/templates/`, {
      name,
      description,
      privacy: privacy || 'organization',
      meeting_type_uuids,
      is_default_for_user,
      // Deprecated
      text:
        text ||
        JSON.stringify(
          convertToRaw(EditorState.createEmpty().getCurrentContent())
        ),
      text_slate: textSlate || JSON.stringify(DEFAULT_SLATE)
    })
      .then(template => {
        dispatch({
          type: 'CREATE_TEMPLATE_SUCCESS',
          hadContent: !!text,
          template
        });

        if (redirect) {
          const newTemplateURL = `/settings/templates/${template.uuid}`;
          history.push(newTemplateURL);
        }

        if (setUuidParam) {
          setUuidParam(template.uuid);
        }

        if (handleModeChange) {
          handleModeChange('edit');
        }

        return template;
      })
      .catch(err => {
        dispatch({
          type: 'CREATE_TEMPLATE_FAILURE',
          error: err.message
        });
        throw err;
      });
  };

const debouncedCreateTemplate = debounceAction(createTemplate, 1000);

// TODO: deprecate
export const setTemplateEditorState =
  (uuid, editorState, isNew, save) => (dispatch, getState) => {
    if (!uuid) throw Error('[avoma] no uuid provided');
    if (getState().app.templateAndTranscriptLinkedAt) {
      dispatch({ type: 'CLEAR_TEMPLATE_AND_TRANSCRIPT_LINKED' });
    }
    if (save) {
      const name =
        (getState().templates.data[uuid] &&
          getState().templates.data[uuid].name) ||
        'Untitled Template';
      if (isNew) {
        dispatch(
          debouncedCreateTemplate({
            text: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            name
          })
        );
      } else {
        dispatch(
          debouncedUpdateTemplate({
            uuid,
            text: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            name
          })
        );
      }
    }
    dispatch({
      type: 'SET_TEMPLATE_EDITOR_STATE',
      uuid,
      editorState
    });
  };

export const saveTemplate =
  ({
    uuid,
    name,
    text,
    description,
    privacy,
    isNew,
    meeting_type_uuids,
    is_default_for_user,
    redirect = true,
    setUuidParam,
    handleModeChange
  }) =>
  dispatch => {
    if (!uuid) throw Error('[avoma] no uuid provided');

    dispatch({
      type: TemplateActions.SET_TEMPLATE_METADATA,
      uuid,
      name,
      text,
      description,
      privacy,
      meeting_type_uuids,
      is_default_for_user
    });

    if (isNew) {
      dispatch(
        debouncedCreateTemplate({
          name,
          description,
          text,
          privacy,
          meeting_type_uuids,
          is_default_for_user,
          redirect,
          setUuidParam,
          handleModeChange
        })
      );
    } else {
      dispatch(
        debouncedUpdateTemplate({
          uuid,
          name,
          text,
          privacy,
          description,
          is_default_for_user
        })
      );
    }
  };

export const migrateDraftToSlateTemplate = template => async dispatch => {
  const { uuid, text } = template;

  const slateValue = SlateHelper.fromDraftJs(text);
  if (slateValue) {
    const textSlate = JSON.stringify(slateValue.toJSON());
    // do we need ot save?
    dispatch({
      type: TemplateActions.MIGRATE_DRAFT_TEMPLATE,
      uuid,
      textSlate,
      meta: {
        analytics: {
          eventType: EventTypes.track
        }
      }
    });
  }

  // dispatch save maybe
};

// update template in editor
export const deleteTemplate = uuid => async dispatch => {
  if (!uuid) {
    throw Error('[avoma] no uuid provided to deleteTemplate');
  }
  dispatch({
    type: 'DELETE_TEMPLATE_REQUEST',
    templateUuid: uuid,
    meta: {
      analytics: EventTypes.track
    }
  });
  try {
    await deleteMethod(`${env.avomaApiUrl}/api/v1/templates/${uuid}/`);
    dispatch({
      type: 'DELETE_TEMPLATE_SUCCESS',
      templateUuid: uuid
    });
  } catch (err) {
    dispatch({
      type: 'DELETE_TEMPLATE_FAILURE',
      error: err.message,
      templateUuid: uuid
    });
  }
};

export const setCurrentTemplateId = uuid => dispatch => {
  dispatch({ type: 'SET_CURRENT_TEMPLATE_ID', uuid });
};

export const trackInsertTemplate = () => dispatch => {
  dispatch({
    type: TemplateActions.TRACK_INSERT_TEMPLATE,
    meta: {
      analytics: {
        eventType: EventTypes.track
      }
    }
  });
};

export const createTemplateSlate =
  ({ textSlate }) =>
  async dispatch => {
    dispatch({
      type: TemplateActions.CREATE_SLATE_TEMPLATE_REQUEST,
      meta: {
        analytics: {
          eventType: EventTypes.track
        }
      }
    });
    try {
      const template = await post(`${env.avomaApiUrl}/api/v1/templates/`, {
        ...NEW_TEMPLATE_OBJECT,
        text_slate: textSlate
      });
      dispatch({
        type: TemplateActions.CREATE_SLATE_TEMPLATE_SUCCESS,
        template: camelize(template)
      });
    } catch (err) {
      dispatch({
        type: TemplateActions.CREATE_SLATE_TEMPLATE_FAILURE,
        error: err.message
      });
      throw err;
    }
  };

export const updateTemplateSlate =
  ({ text, textSlate, name, description, privacy, uuid }) =>
  async dispatch => {
    if (!uuid) {
      throw Error('[avoma] no uuid provided to saveTemplate');
    }
    if (uuid === NEW_TEMPLATE) {
      dispatch(
        createTemplateSlate({
          textSlate: JSON.stringify(textSlate)
        })
      );
      return;
    }
    dispatch({
      type: TemplateActions.UPDATE_TEMPLATE_REQUEST,
      uuid,
      meta: {
        analytics: {
          eventType: EventTypes.track,
          eventPayload: { text, name, description, privacy, uuid }
        }
      }
    });

    try {
      const template = await patch(
        `${env.avomaApiUrl}/api/v1/templates/${uuid}/`,
        {
          text,
          text_slate: JSON.stringify(textSlate),
          name,
          description,
          privacy,
          uuid
        }
      );
      dispatch({
        type: TemplateActions.UPDATE_TEMPLATE_SUCCESS,
        template
      });
    } catch (err) {
      dispatch({
        type: TemplateActions.UPDATE_TEMPLATE_FAILURE,
        error: err.message,
        uuid
      });
      throw err;
    }
  };

export const debouncedUpdateTemplateSlate = debounceAction(
  updateTemplateSlate,
  1000
);

// Adding/Removing Purposes associated with a Template
export const updateTemplatePurpose =
  (templateUuid, purposes) => async dispatch => {
    dispatch({ type: TemplateActions.UPDATE_TEMPLATE_TYPE_REQUEST });
    try {
      let response = await post(
        `${env.avomaApiUrl}/api/v1/templates/${templateUuid}/attach_type/`,
        {
          meeting_type_uuids: purposes
        }
      );
      response = camelize(response);
      dispatch({
        type: TemplateActions.UPDATE_TEMPLATE_TYPE_SUCCESS,
        templateUuid,
        meetingTypes: response.meetingTypes
      });
    } catch (err) {
      dispatch({ type: TemplateActions.UPDATE_TEMPLATE_TYPE_FAILURE });
    }
  };

export const fetchCurrentlyUsedTemplateTypes = () => async dispatch => {
  dispatch({ type: TemplateActions.FETCH_TEMPLATE_TYPES_REQUEST });
  try {
    await get(`${env.avomaApiUrl}/api/v1/templates/meeting_types/`).then(
      results => {
        dispatch({
          type: TemplateActions.FETCH_TEMPLATE_TYPES_SUCCESS,
          templateTypes: results
        });
      }
    );
  } catch (err) {
    dispatch({
      type: TemplateActions.FETCH_TEMPLATE_TYPES_FAILURE,
      error: err.message
    });
  }
};

export const fetchAllTemplateList = query => async (dispatch, getState) => {
  const state = getState();
  const {
    templates: { allTemplates }
  } = state;

  let URL;

  if (!allTemplates || query) {
    URL = `${env.avomaApiUrl}/api/v1/templates/all/?page=1&page_size=15${
      query ? `&${query}` : ''
    }`;
  } else if (allTemplates?.data?.next) {
    URL = allTemplates.data.next;
  }

  if (!URL) return;

  dispatch({
    type: TemplateActions.FETCH_ALL_TEMPLATES_REQUEST,
    query,
    meta: {
      analytics: EventTypes.track
    }
  });

  try {
    let response = await get(URL);
    response = camelize(response);
    const results = response.results.map(template => template.uuid);
    const data = {};
    response.results.forEach(template => {
      data[template.uuid] = template;
    });
    response.results = results;

    dispatch({
      type: TemplateActions.FETCH_ALL_TEMPLATES_SUCCESS,
      response,
      data,
      query
    });
  } catch (error) {
    dispatch({
      type: TemplateActions.FETCH_ALL_TEMPLATES_FAILURE,
      error,
      query
    });
  }
};

export const fetchMyTemplateList = query => async (dispatch, getState) => {
  const state = getState();
  const {
    templates: { myTemplates }
  } = state;

  let URL;

  if (!myTemplates || query) {
    URL = `${env.avomaApiUrl}/api/v1/templates/me/?page=1&page_size=15${
      query ? `&${query}` : ''
    }`;
  } else if (myTemplates?.data?.next) {
    URL = myTemplates.data.next;
  }

  if (!URL) return;

  dispatch({
    type: TemplateActions.FETCH_MY_TEMPLATES_REQUEST,
    query,
    meta: {
      analytics: EventTypes.track
    }
  });

  try {
    let response = await get(URL);
    response = camelize(response);
    const results = response.results.map(template => template.uuid);
    const data = {};
    response.results.forEach(template => {
      data[template.uuid] = template;
    });
    response.results = results;

    dispatch({
      type: TemplateActions.FETCH_MY_TEMPLATES_SUCCESS,
      response,
      data,
      query
    });
  } catch (error) {
    dispatch({
      type: TemplateActions.FETCH_MY_TEMPLATES_FAILURE,
      error,
      query
    });
  }
};

export const fetchOrganizationTemplateList =
  query => async (dispatch, getState) => {
    const state = getState();
    const {
      templates: { organizationTemplates }
    } = state;

    let URL;

    if (!organizationTemplates || query) {
      URL = `${
        env.avomaApiUrl
      }/api/v1/templates/all_but_mine/?page=1&page_size=15&is_default=false${
        query ? `&${query}` : ''
      }`;
    } else if (organizationTemplates?.data?.next) {
      URL = organizationTemplates.data.next;
    }

    if (!URL) return;

    dispatch({
      type: TemplateActions.FETCH_ORGANIZATION_TEMPLATES_REQUEST,
      query,
      meta: {
        analytics: EventTypes.track
      }
    });

    try {
      let response = await get(URL);
      response = camelize(response);
      const results = response.results.map(template => template.uuid);
      const data = {};
      response.results.forEach(template => {
        data[template.uuid] = template;
      });
      response.results = results;

      dispatch({
        type: TemplateActions.FETCH_ORGANIZATION_TEMPLATES_SUCCESS,
        response,
        data,
        query
      });
    } catch (error) {
      dispatch({
        type: TemplateActions.FETCH_ORGANIZATION_TEMPLATES_FAILURE,
        error,
        query
      });
    }
  };

export const fetchAvomaTemplateList = query => async (dispatch, getState) => {
  const state = getState();
  const {
    templates: { avomaTemplates }
  } = state;

  let URL;

  if (!avomaTemplates || query) {
    URL = `${
      env.avomaApiUrl
    }/api/v1/templates/all/?page=1&page_size=15&is_default=true${
      query ? `&${query}` : ''
    }`;
  } else if (avomaTemplates?.data?.next) {
    URL = avomaTemplates.data.next;
  }

  if (!URL) return;

  dispatch({
    type: TemplateActions.FETCH_AVOMA_TEMPLATES_REQUEST,
    query,
    meta: {
      analytics: EventTypes.track
    }
  });

  try {
    let response = await get(URL);
    response = camelize(response);

    // Prepend Avoma Default template uuid to the results
    const results = response.results.map(template => template.uuid);

    let data = {};

    const urlParams = new URLSearchParams(query);
    const searchTerm = urlParams.get('search');

    // If search query is present, do not prepend default template
    if (searchTerm && searchTerm?.trim() !== '') {
      response.results.forEach(template => {
        data[template.uuid] = template;
      });

      response.results = results;
    } else {
      results.unshift(AVOMA_DEFAULT_TEMPLATE.uuid);

      // Map templates by uuid
      data = [AVOMA_DEFAULT_TEMPLATE, ...response.results].reduce(
        (acc, template) => {
          acc[template.uuid] = template;
          return acc;
        },
        {}
      );

      // Update response with new results and count
      response.results = results;
      response.count += 1;
    }

    dispatch({
      type: TemplateActions.FETCH_AVOMA_TEMPLATES_SUCCESS,
      response,
      data,
      query
    });
  } catch (error) {
    dispatch({
      type: TemplateActions.FETCH_AVOMA_TEMPLATES_FAILURE,
      error,
      query
    });
  }
};

export const setTemplatesSearchText = search => dispatch => {
  dispatch({
    type: TemplateActions.SET_TEMPLATES_SEARCH_TEXT,
    searchTemplatesText: search
  });
};

export const setTemplatesView =
  ({ view }) =>
  dispatch => {
    dispatch({
      type: TemplateActions.SET_TEMPLATES_VIEW,
      view
    });
  };
