import camelize from 'camelize';
import { EventTypes } from 'redux-segment';

import { OnboardingActions } from 'actions/actionTypes';

import env from 'helpers/env';
import { get, patch } from 'helpers/http';

export const setSkipCal = skip => dispatch => {
  dispatch({
    type: 'SET_SKIP_CAL_SYNC',
    skip
  });
};

export const goToNextStep = () => ({
  type: 'GO_TO_NEXT_ONBOARDING_STEP'
});

export const updateOnboardingState = (url, saveData) => async dispatch => {
  dispatch({
    type: OnboardingActions.UPDATE_ONBOARDING_STATE,
    data: camelize(saveData)
  });

  try {
    const response = await patch(url, saveData);
    dispatch({
      type: OnboardingActions.UPDATE_ONBOARDING_STATE_SUCCESS,
      data: camelize(response)
    });
  } catch (error) {
    dispatch({
      type: OnboardingActions.UPDATE_ONBOARDING_STATE_FAILURE,
      error: error.message
    });
  }
};

export const moveToNextOnboarding = increment => async (dispatch, getState) => {
  const state = getState();
  const onboardingURL = state.user.onboardingStatusUrl;
  const onboardingShown =
    state.onboarding.currentStep === state.onboarding.numSteps &&
    state.onboarding.onboarded;

  const onboardingData = {
    current_step: increment
      ? state.onboarding.currentStep + 1
      : state.onboarding.currentStep - 1,
    onboarding_shown: onboardingShown
  };
  if (
    onboardingData.current_step >= 0 &&
    onboardingData.current_step <= state.onboarding.numSteps
  ) {
    try {
      const response = await patch(onboardingURL, onboardingData);
      return dispatch({
        type: OnboardingActions.UPDATE_ONBOARDING_STATE_SUCCESS,
        data: camelize(response)
      });
    } catch (error) {
      dispatch({
        type: OnboardingActions.UPDATE_ONBOARDING_STATE_FAILURE,
        error: error.message
      });
      throw error;
    }
  }
};

export const confirmOnboarding = () => (dispatch, getState) => {
  // update onboarding status
  const state = getState();
  const onboardingURL = state.user.onboardingStatusUrl;
  const onboardingData = {
    current_step: 4, // Onboarding completed
    onboarded: true,
    onboarding_shown: false
  };
  dispatch({
    type: OnboardingActions.ONBOARDING_DONE,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          properties: {
            category: OnboardingActions.CATEGORY
          }
        }
      }
    }
  });
  return dispatch(updateOnboardingState(onboardingURL, onboardingData));
};

export const fetchOnboardingState = () => dispatch => {
  get(`${env.avomaApiUrl}/api/v1/onboarding/me/`)
    .then(res => {
      dispatch({
        type: 'FETCH_ONBOARDING_STATE_SUCCESS',
        data: camelize(res)
      });
    })
    .catch(err => {
      dispatch({
        type: 'FETCH_ONBOARDING_STATE_FAILURE',
        err
      });
    });
};

export const updateOnboardingTile =
  (tile, dismissed, done) => async (dispatch, getState) => {
    // assumes onboarding is downloaded
    // code smell but right now user onboarding status is downloaded
    // with every user profile call
    const state = getState();
    const onboardingURL = state.user.onboardingStatusUrl;
    // maybe ignore if onboarding state is not there
    const existingTiles = state.onboarding.homeTiles;
    const updatedOnboardingTiles = {
      ...existingTiles,
      [tile]: {
        dismissed,
        done
      }
    };
    const onboardingData = {
      home_tiles: JSON.stringify(updatedOnboardingTiles)
    };
    return dispatch(updateOnboardingState(onboardingURL, onboardingData));
  };
