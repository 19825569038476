import { Nullable } from './utils';

export const enum CrmProvider {
  salesforce = 'salesforce',
  hubspot = 'hubspot',
  pipedrive = 'pipedrive',
  zoho = 'zoho'
}

export interface ICrmSettings {
  uuid: string;
  advancedForecastSubmissionEnabled: boolean;
  defaultAccountTimezone: string;
}

export interface AppExchangeApiKey {
  uuid: string;
  clientId: string;
  apiKey: string;
  apiKeyModifiedBy: string;
  apiKeyModifiedAt: string;
  apiKeyConnectedEmail: Nullable<string>;
}

export interface CrmPipeline {
  uuid: string;
  externalId: string;
  label: string;
  displayOrder: number;
  stages: CrmPipelineStage[];
}

export interface CrmPipelineStage {
  uuid: string;
  externalId: string;
  apiName: string;
  defaultProbability: number;
  isActive: boolean;
  isClosed: boolean;
  isWon: boolean;
  masterLabel: string;
  sortOrder: number;
  forecastCategory: Nullable<string>;
  forecastCategoryName: Nullable<string>;
  pipelineId: string;
  pipelineLabel: string;
}

export interface CrmUser {
  email: string;
  firstName: string;
  lastName: string;
  uuid: string;
  externalId: string;
  createdBySource: string;
  modifiedBySource: string;
  profilePic: Nullable<string>;
  isActive: boolean;
}

export interface IMappedPlaybook {
  playbookUuid: string;
  playbookLabel: string;
  playbookKey: string;
  isDefault: boolean;
  playbookSettingUuid: string;
  isActive: boolean;
}

export interface IPipeline {
  uuid: string;
  externalId: string;
  label: string;
  stages: CrmPipelineStage[];
  mappedPlaybooks: IMappedPlaybook[];
}

export const enum ECrmFieldEditAccess {
  everyone = 'everyone',
  adminOnly = 'admin_only',
  viewOnly = 'view_only'
}

export const CrmFieldEditAccessList = [
  ECrmFieldEditAccess.everyone,
  ECrmFieldEditAccess.adminOnly,
  ECrmFieldEditAccess.viewOnly
];

export interface ICrmAvomaField {
  uuid: string;
  fieldId: string;
  fieldLabel: string;
  dataType: string;
  fieldType: string;
  fieldClass: string;
  sortOrder: number;
  backgroundColor: string;
  optionsJson: string[];
  groupName: string;
  isMapped: boolean;
}

export interface ICrmField {
  uuid: string;
  externalId: string;
  fieldLabel: string;
  fieldType: string;
  fieldTypeLabel: string;
  dataType: string;
  optionsJson: {
    label: string;
    value: string;
    hidden: boolean;
    displayOrder: number;
  }[];
  sortOrder: number;
  isDefault: boolean;
  isAvomaDefined: boolean;
  isReadOnly: boolean;
  syncDirection: string;
  syncSetting: string;
  isCustomInCrm: boolean;
  isCalculated: boolean;
  createdByAtSource: string;
  editableBy: string;
}

export interface ICrmFieldMapping {
  uuid: string;
  avomaField: Nullable<ICrmAvomaField>;
  contentType: number;
  crmField: ICrmField;
  objectType: string;
  createdBy: string;
  groupName: string;
  isMappable: boolean;
}
