import { Suspense } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Common/Spinner';

const SuspenseWrapper = ({ children }) => (
  <Suspense
    fallback={
      <div className='flex h-full w-full items-center justify-center'>
        <Spinner />
      </div>
    }
  >
    {children}
  </Suspense>
);
SuspenseWrapper.propTypes = {
  children: PropTypes.func
};

export default SuspenseWrapper;
