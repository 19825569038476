import { createBrowserHistory } from 'history';

import { sendPageEvent } from './tracking';

const customHistory = createBrowserHistory();

export const isMeetingDashboardUrl = url => {
  const regex = new RegExp('/meetings/[0-9A-Fa-f-]{36}.*', 'g');
  const regexZoom = new RegExp('/zoom/meetings/[0-9A-Fa-f-]{36}.*', 'g');
  return Boolean(regex.exec(url)) || Boolean(regexZoom.exec(url));
};

const uuidRegex = '.*[0-9A-Fa-f-]{36}.*';
const hasUuid = url => Boolean(new RegExp(uuidRegex, 'g').exec(url));

export const isTemplateDashboardUrl = url => url.includes('/templates');

export const goToLastNonTemplateUrl = historyStack => {
  const index = historyStack
    .slice()
    .reverse()
    .findIndex(url => !isTemplateDashboardUrl(url));
  if (index > -1) customHistory.go(-index);
};

export const canGoBack = () => window.history.length > 1;

export const historyHasMeeting = historyStack => {
  const index = historyStack
    .slice()
    .reverse()
    .findIndex(url => !isTemplateDashboardUrl(url));
  return hasUuid(historyStack[index]);
};

export const historyHasMeetingDetails = historyStack => {
  const index = historyStack
    .slice()
    .reverse()
    .findIndex(
      url => url.includes('/meetings') && !url.includes('/meetings/views')
    );
  return index > -1;
};

export const historyHasSearch = historyStack => {
  const index = historyStack
    .slice()
    .reverse()
    .findIndex(url => url.includes('/search'));
  return index > -1;
};

const cleanPathname = pathname => {
  const uuidMatcher =
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g;
  return pathname.replace(uuidMatcher, ':uuid');
};

customHistory.listen(({ pathname }) => {
  // Replace uuids in the path with :uuid
  const cleanedPathname = cleanPathname(pathname);

  sendPageEvent(cleanedPathname);
});

export default customHistory;
