import { unwrapNodesByType } from '../../Transforms/unwrapNodesByType';
import { TYPE_CHECKLIST_ITEM } from '../../Types';
import {
  TYPE_CHECKLIST,
  TYPE_LIST_ITEM,
  TYPE_ORDERED_LIST,
  TYPE_UNORDERED_LIST
} from '../types';

export const unwrapList = (editor, options = {}) => {
  unwrapNodesByType(editor, [TYPE_LIST_ITEM, TYPE_CHECKLIST_ITEM], options);
  unwrapNodesByType(
    editor,
    [TYPE_UNORDERED_LIST, TYPE_ORDERED_LIST, TYPE_CHECKLIST],
    {
      split: true,
      ...options
    }
  );
};
