import camelize from 'camelize';
import _ from 'lodash';

import { AccountActions } from 'actions/actionTypes';

const initialState = {
  data: [],
  dataMap: {},
  navList: [],
  count: 0,
  searchTitle: null,
  searching: 0,
  next: null,
  previous: null,
  fetching: 0,
  error: null
};

const setAccount = (state, action) => {
  const newAccounts = _.uniqBy(
    [...state.data, { uuid: action.uuid, company: { name: action.name } }],
    'uuid'
  );
  const acctMap = {};
  newAccounts.forEach(acct => {
    acctMap[acct.uuid] = acct;
  });
  return {
    ...state,
    data: newAccounts,
    dataMap: acctMap
  };
};

const viewAccount = (state, action) => {
  switch (action.type) {
    case 'VIEW_ACCOUNT':
      if (action.payload.userId) {
        const fetchedAccount = _.find(
          state.data,
          account => account.uuid === action.payload.userId
        );
        if (fetchedAccount) {
          const filteredAccounts = _.take(
            _.orderBy(
              state.data,
              [account => account.lastMeetingDatetime],
              ['desc']
            ),
            5
          );
          let accountsPool;
          if (
            _.findIndex(
              [...state.navList],
              account => account.uuid === action.payload.userId
            ) > -1
          ) {
            accountsPool = [...state.navList, ...filteredAccounts];
          } else {
            accountsPool = [
              fetchedAccount,
              ...state.navList,
              ...filteredAccounts
            ];
          }

          const aggregated = _.take(_.uniqBy(accountsPool, 'uuid'), 5);
          return {
            ...state,
            navList: aggregated
          };
        }
      }
      return state;
    default:
      return state;
  }
};

const fetchingAccounts = (state, action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNTS_REQUEST':
      return {
        ...state,
        fetching: state.fetching + 1,
        searchTitle: '',
        searching: 0
      };
    case 'FETCH_ACCOUNTS_SUCCESS': {
      const newAccounts = _.uniqBy(
        [...state.data, ...camelize(action.accounts)],
        'uuid'
      );
      const acctMap = {};
      newAccounts.forEach(acct => {
        acctMap[acct.uuid] = acct;
      });
      const filteredAccounts = _.take(
        _.orderBy(
          newAccounts,
          [account => account.lastMeetingDatetime],
          ['desc']
        ),
        5
      );
      const aggregated = _.take(
        _.uniqBy([...state.navList, ...filteredAccounts], 'uuid'),
        5
      );
      return {
        ...state,
        fetching: state.fetching - 1,
        data: newAccounts,
        dataMap: acctMap,
        next: action.next,
        navList: aggregated,
        previous: action.previous,
        count: action.count
      };
    }
    case 'FETCH_ACCOUNTS_FAILURE': {
      return {
        ...state,
        fetching: state.fetching - 1,
        error: action.error
      };
    }
    default:
      return state;
  }
};

const searchingAccounts = (state, action) => {
  switch (action.type) {
    case 'SEARCH_ACCOUNTS_REQUEST':
      return {
        ...state,
        searching: state.searching + 1,
        searchTitle: action.keyword
      };
    case 'SEARCH_ACCOUNTS_SUCCESS': {
      const newAccounts = _.uniqBy(
        [...state.data, ...camelize(action.accounts)],
        'uuid'
      );
      const acctMap = {};
      newAccounts.forEach(acct => {
        acctMap[acct.uuid] = acct;
      });
      return {
        ...state,
        data: newAccounts,
        dataMap: acctMap,
        searching: state.fetching - 1,
        next: action.next,
        previous: action.previous,
        count: action.count
      };
    }
    case 'SEARCH_ACCOUNTS_FAILURE': {
      return {
        ...state,
        searching: state.searching - 1,
        error: action.error
      };
    }
    default:
      return state;
  }
};

const clearSearchAccounts = state => ({
  ...state,
  data: []
});

const lookupAccounts = (state, action) => {
  switch (action.type) {
    case AccountActions.LOOKUP_ACCOUNTS_REQUEST:
      return {
        ...state,
        lookup: true
      };
    case AccountActions.LOOKUP_ACCOUNTS_SUCCESS: {
      const newAccounts = _.uniqBy(
        [...state.data, ...camelize(action.accounts)],
        'uuid'
      );
      const acctMap = {};
      newAccounts.forEach(acct => {
        acctMap[acct.uuid] = acct;
      });
      return {
        ...state,
        data: newAccounts,
        dataMap: acctMap,
        lookup: false
      };
    }
    case AccountActions.LOOKUP_ACCOUNTS_FAILURE:
      return {
        ...state,
        lookup: false,
        error: action.error
      };
    default:
      return state;
  }
};

const accounts = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACCOUNT_NAME':
      return setAccount(state, action);
    // fetch accounts
    case 'FETCH_ACCOUNTS_REQUEST':
    case 'FETCH_ACCOUNTS_SUCCESS':
    case 'FETCH_ACCOUNTS_FAILURE':
    case 'FETCH_ACCOUNT_SUCCESS':
      return fetchingAccounts(state, action);
    case 'SEARCH_ACCOUNTS_REQUEST':
    case 'SEARCH_ACCOUNTS_SUCCESS':
    case 'SEARCH_ACCOUNTS_FAILURE':
      return searchingAccounts(state, action);
    case 'CLEAR_SEARCH_ACCOUNTS':
      return clearSearchAccounts(state);
    case AccountActions.LOOKUP_ACCOUNTS_REQUEST:
    case AccountActions.LOOKUP_ACCOUNTS_SUCCESS:
    case AccountActions.LOOKUP_ACCOUNTS_FAILURE:
      return lookupAccounts(state, action);
    case 'VIEW_ACCOUNT':
      return viewAccount(state, action);
    default:
      return state;
  }
};

export default accounts;
