import camelize from 'camelize';
import { baseCreateApi } from 'services';

export const meetingsApi = baseCreateApi.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getMeetingChapters: builder.query({
      providesTags: ['MeetingChapters'],
      query: ({ meetingUuid }) => ({
        url: `/api/v1/meeting/${meetingUuid}/chapters/`,
        method: 'GET'
      }),
      transformResponse: response => camelize(response)
    }),
    updateMeetingChapter: builder.mutation({
      invalidatesTags: ['MeetingChapters'],
      query: ({ meetingUuid, chapterUuid, payload }) => ({
        url: `/api/v1/meeting/${meetingUuid}/chapters/${chapterUuid}/`,
        method: 'PATCH',
        data: payload
      }),
      transformResponse: response => camelize(response)
    }),
    createMeetingChapter: builder.mutation({
      invalidatesTags: ['MeetingChapters'],
      query: ({ meetingUuid, payload }) => ({
        url: `/api/v1/meeting/${meetingUuid}/chapters/`,
        method: 'POST',
        data: payload
      }),
      transformResponse: response => camelize(response)
    }),
    createMeetingChaptersWithAi: builder.mutation({
      invalidatesTags: ['MeetingChapters'],
      query: ({ meetingUuid }) => ({
        url: `/api/v1/meeting/${meetingUuid}/chapters/generate/`,
        method: 'POST'
      }),
      transformResponse: response => camelize(response)
    }),
    deleteMeetingChapter: builder.mutation({
      invalidatesTags: ['MeetingChapters'],
      query: ({ meetingUuid, chapterUuid }) => ({
        url: `/api/v1/meeting/${meetingUuid}/chapters/${chapterUuid}/`,
        method: 'DELETE'
      }),
      transformResponse: response => camelize(response)
    }),
    getMeetingFollowUpEmail: builder.query({
      providesTags: ['MeetingFollowUpEmail'],
      query: ({ meetingUuid }) => ({
        url: `/api/v1/meeting/${meetingUuid}/collabnotes/follow_up_email/`,
        method: 'GET'
      }),
      transformResponse: response => camelize(response)
    }),
    getMeetingTypes: builder.query({
      providesTags: ['MeetingTypes'],
      query: () => ({
        url: '/api/v1/meetingtype/',
        method: 'GET'
      }),
      transformResponse: response => camelize(response)
    }),
    getMeetingInsights: builder.query({
      providesTags: ['MeetingInsights'],
      query: ({ meetingUuid }) => ({
        url: `/api/v2/insights/${meetingUuid}/`,
        method: 'GET'
      }),
      transformResponse: response => camelize(response)
    }),
    getMeetingSpeakingInsights: builder.query({
      providesTags: ['MeetingSpeakingInsights'],
      query: ({ meetingUuid }) => ({
        url: `/api/v2/insights/speaking_insights/${meetingUuid}/`,
        method: 'GET'
      }),
      transformResponse: response => camelize(response)
    }),
    getMeetingAssociatedTemplate: builder.query({
      providesTags: ['MeetingAssociatedTemplate'],
      query: ({ meetingUuid, meetingType }) => ({
        url: `/api/v1/meeting/${meetingUuid}/collabnotes/associated-template/`,
        method: 'GET'
      }),
      transformResponse: response => camelize(response?.[0])
    }),
    changeMeetingAssociatedTemplate: builder.mutation({
      invalidatesTags: ['MeetingAssociatedTemplate'],
      query: ({ meetingUuid, templateUuid }) => ({
        url: `/api/v1/meeting/${meetingUuid}/collabnotes/change_associated_template/`,
        method: 'POST',
        data: {
          template_uuid: templateUuid
        }
      }),
      transformResponse: response => camelize(response)
    }),
    getMeetingCollabNotesSnapshotHistory: builder.query({
      providesTags: ['MeetingCollabNotesSnapshotHistory'],
      query: ({ meetingUuid }) => ({
        url: `/api/v1/meeting/${meetingUuid}/collabnotes/snapshot_history/`,
        method: 'GET'
      }),
      transformResponse: response => camelize(response)
    }),
    getMeetingCollabNotesSnapshot: builder.query({
      providesTags: ['MeetingCollabNotesSnapshot'],
      query: ({ meetingUuid, snapshotUuid }) => ({
        url: `/api/v1/meeting/${meetingUuid}/collabnotes/snapshot_history/?snapshot_uuid=${snapshotUuid}`,
        method: 'GET'
      }),
      transformResponse: response => camelize(response)
    }),
    regenerateMeetingNotes: builder.mutation({
      invalidatesTags: [
        'MeetingCollabNotesSnapshotHistory',
        'MeetingAssociatedTemplate',
        'RegenerateNotesLimits',
        'MeetingCollabNotesPreviewHistory'
      ],
      query: ({ meetingUuid, templateUuid }) => ({
        url: `/api/v1/meeting/${meetingUuid}/collabnotes/generate_note/`,
        method: 'POST',
        data: {
          template_uuid: templateUuid || null
        }
      }),
      transformResponse: response => camelize(response)
    }),
    saveNotesSnapshot: builder.mutation({
      invalidatesTags: ['MeetingCollabNotesSnapshotHistory'],
      query: ({ meetingUuid }) => ({
        url: `/api/v1/meeting/${meetingUuid}/collabnotes/create_snapshot/`,
        method: 'POST'
      }),
      transformResponse: response => camelize(response)
    }),
    getRegenerateNotesLimits: builder.query({
      providesTags: ['RegenerateNotesLimits'],
      query: ({ meetingUuid }) => ({
        url: `/api/v1/meeting/${meetingUuid}/collabnotes/regeneration_limits/`,
        method: 'GET'
      }),
      transformResponse: response => camelize(response)
    }),
    acceptNotePreview: builder.mutation({
      invalidatesTags: ['MeetingCollabNotesPreviewHistory'],
      query: ({ meetingUuid, previewUuid }) => ({
        url: `/api/v1/meeting/${meetingUuid}/collabnotes/accept_note_preview/`,
        method: 'POST',
        data: { preview_uuid: previewUuid }
      }),
      transformResponse: response => camelize(response)
    })
  })
});

export const {
  useGetMeetingChaptersQuery,
  useCreateMeetingChapterMutation,
  useCreateMeetingChaptersWithAiMutation,
  useDeleteMeetingChapterMutation,
  useUpdateMeetingChapterMutation,
  useLazyGetMeetingFollowUpEmailQuery,
  useGetMeetingInsightsQuery,
  useGetMeetingSpeakingInsightsQuery,
  useGetMeetingAssociatedTemplateQuery,
  useChangeMeetingAssociatedTemplateMutation,
  useGetMeetingCollabNotesSnapshotHistoryQuery,
  useGetMeetingCollabNotesSnapshotQuery,
  useRegenerateMeetingNotesMutation,
  useGetRegenerateNotesLimitsQuery,
  useSaveNotesSnapshotMutation,
  useAcceptNotePreviewMutation
} = meetingsApi;
