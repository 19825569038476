import { Editor } from 'slate';

import { getBlockAbove } from './getBlockAbove';

/**
 * Is the selection focus at the start of its parent block.
 */
export const isSelectionAtBlockStart = editor => {
  const [, path] = getBlockAbove(editor);
  return (
    editor.selection && Editor.isStart(editor, editor.selection.focus, path)
  );
};
