import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import { useOverflowDetector } from 'hooks/useOverflowDetector';

import AvomaTooltip from 'components/Common/AvomaTooltip';

const OverflowingText = ({
  as: Tag = 'span',
  children,
  tooltip = '',
  tooltipPosition = 'bottom',
  tooltipStyles = {},
  ...rest
}) => {
  const [ref, isOverflowing] = useOverflowDetector();
  const { className, ...tagProps } = rest;

  return (
    <AvomaTooltip
      tooltip={isOverflowing ? tooltip ?? children : undefined}
      tooltipPosition={tooltipPosition}
      userStyles={tooltipStyles}
    >
      <Tag className={twMerge('truncate', className)} ref={ref} {...tagProps}>
        {children}
      </Tag>
    </AvomaTooltip>
  );
};

OverflowingText.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  tooltipStyles: PropTypes.object,
  rest: PropTypes.object
};

export default OverflowingText;
