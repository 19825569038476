import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import ThirdPartyScriptLoader from 'components/Common/ThirdPartyScriptLoader';

const BeamerScriptLoader = () => {
  const user = useSelector(state => state.user);
  const [readyForInit, setReadyForInit] = useState(false);

  useEffect(() => {
    // Initialize only once
    if (user?.uuid && !readyForInit) {
      window.beamer_config = {
        ...window.beamer_config,
        // User Information
        user_id: user.uuid
      };

      setReadyForInit(true);
    }
  }, [user.uuid, readyForInit]);

  if (!readyForInit) {
    return null;
  }

  return (
    <ThirdPartyScriptLoader
      src='https://app.getbeamer.com/js/beamer-embed.js'
      defer
      id='beamer-loader'
      delay={1000}
      onloadCallback={() => {
        setTimeout(() => {
          if (window.Beamer) {
            window.Beamer.init();
          }
        }, 2000);
      }}
    />
  );
};

export default BeamerScriptLoader;
