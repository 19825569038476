import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  createTriggerPrompt as createTriggerPromptAction,
  createTriggerPromptVariation as createTriggerPromptVariationAction,
  deleteTriggerPrompt as deleteTriggerPromptAction,
  deleteTriggerPromptVariation as deleteTriggerPromptVariationAction,
  makeTriggerPromptVariationPrimary as makeTriggerPromptVariationPrimaryAction,
  updateTriggerPromptLabel as updateTriggerPromptLabelAction
} from 'actions/insights';
import { trackSegmentEvents } from 'actions/segment';

import AvomaButton from 'components/Common/AvomaButton';
import NotificationBanner from 'components/Common/NotificationBanner';
import Spinner from 'components/Common/Spinner';
import TextField from 'components/Common/TextField';
import HelpPopover from 'components/Common/popovers/HelpPopover';
import Prompt from 'components/SmartCategories/CategoryDetails/Prompt';
import CardSection from 'components/SmartCategories/Common/CardSection';

import L10n from 'helpers/L10n';

import { TOPIC_EVENTS } from 'constants/trackingEvents';

import Tokens from 'styles/tokens';

import { createId } from '@paralleldrive/cuid2';

const TriggerPrompts = props => {
  const {
    category,
    createTriggerPrompt,
    loading,
    restrictModification,
    phraseVariationExistsError,
    phraseVariationExistsMessage,
    deleteTriggerPrompt,
    createTriggerPromptVariation,
    deleteTriggerPromptVariation,
    updateTriggerPromptLabel,
    makeTriggerPromptVariationPrimary,
    isUnifiedFlow = false,
    prompt,
    setPrompt,
    isAdminOrTopicOwner = false
  } = props;

  const dispatch = useDispatch();

  const isCategoryNew = category.uuid === 'new';

  const [textValue, setTextValue] = useState('');
  // To be used for showing the error message in case of duplicates
  const phrasesContainerRef = useRef();
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [hasDuplicateVariation, setHasDuplicateVariation] = useState(false);

  const isPromptCreated = useMemo(() => {
    if (!isCategoryNew) {
      return Object.values(category?.prompts).length > 0;
    }

    if (isUnifiedFlow) {
      return !isEmpty(prompt?.label);
    }
  }, [category?.prompts, isCategoryNew, isUnifiedFlow, prompt?.label]);

  const promptToDisplay = useMemo(() => {
    if (isUnifiedFlow) {
      return prompt;
    }

    if (isPromptCreated) {
      return Object.values(category?.prompts)[0];
    }
  }, [category?.prompts, isPromptCreated, isUnifiedFlow, prompt]);

  const onDeleteTriggerPrompt = async uuid => {
    await deleteTriggerPrompt({
      categoryUuid: category.uuid,
      promptUuid: uuid
    });
  };

  const onDeleteTriggerPromptVariation = async ({
    variationUuid,
    parentUuid
  }) => {
    await deleteTriggerPromptVariation({
      categoryUuid: category.uuid,
      variationUuid,
      parent: parentUuid
    });
  };

  const handleTextChange = event => setTextValue(event.target.value);

  const handleDeleteTriggerPromptVariation = async ({ variationUuid }) => {
    const updatedVariations = prompt?.variations || {};

    setPrompt({
      ...prompt,
      variations: {
        ...updatedVariations.filter(
          variation => variation.uuid !== variationUuid
        )
      }
    });
  };

  const handleAddTriggerPromptVariation = newVariationLabel => {
    if (
      prompt.label.toLowerCase() === newVariationLabel.toLowerCase() ||
      Object.keys(prompt.variations).includes(newVariationLabel)
    ) {
      setHasDuplicateVariation(true);
      return;
    }

    const updatedVariations = prompt?.variations || {};
    updatedVariations.push({
      uuid: createId(),
      tempUuid: newVariationLabel,
      label: newVariationLabel,
      isPrimary: false
    });

    setPrompt({
      ...prompt,
      variations: [...updatedVariations]
    });

    dispatch(
      trackSegmentEvents({
        type: TOPIC_EVENTS.TOPIC_PROMPT_ADDED,
        name: newVariationLabel
      })
    );

    setTextValue('');
  };

  const handleAddTriggerPrompt = () => {
    setPrompt({
      label: textValue.trim(),
      uuid: null,
      variations: [],
      isPrimary: true
    });

    setTextValue('');
  };

  const handleKeyDown = event => {
    switch (event.keyCode) {
      case 13: // Enter key
        if (textValue.trim().length !== 0) {
          if (isUnifiedFlow) {
            handleAddTriggerPrompt();
          } else {
            handleCreateTriggerPrompt();
          }
        }
        break;
      default:
        break;
    }
  };

  const handleCreateTriggerPrompt = async () => {
    // Split the keyword if it contains commas, and batch the requests
    setShowButtonLoader(true);

    await createTriggerPrompt({
      label: textValue.trim(),
      categoryUuid: category.uuid
    });

    setShowButtonLoader(false);
    setTextValue('');
  };

  const onCreateTriggerPromptVariation = async ({ label, parentUuid }) => {
    await createTriggerPromptVariation({
      label,
      parent: parentUuid,
      categoryUuid: category.uuid
    });
  };

  const handleUpdateTriggerPromptLabel = async ({ word, label }) => {
    await updateTriggerPromptLabel({
      categoryUuid: category.uuid,
      promptUuid: word.uuid,
      label,
      isPrimary: word.isPrimary,
      parent: word.parent
    });
  };

  const onClickMakePrimary = async ({ uuid, parentUuid }) => {
    await makeTriggerPromptVariationPrimary({
      categoryUuid: category.uuid,
      promptUuid: uuid,
      parent: parentUuid
    });
  };

  const renderPromptsHeader = () => (
    <div style={styles.header}>
      {isPromptCreated
        ? L10n.customCategory.existingTriggerPromptTitle
        : L10n.customCategory.addTriggerPromptTitle}
      <HelpPopover
        content={L10n.customCategory.triggerPromptsSubtitle}
        buttonStyles={{ paddingLeft: Tokens.spacing.one }}
        popoverWidth={260}
        popoverStyles={{ right: 0 }}
      />
    </div>
  );

  const renderAddPromptTextField = () => {
    const isButtonDisabled = textValue.trim().length === 0;
    return (
      <div style={styles.textField}>
        <TextField
          placeholder={L10n.customCategory.triggerPromptsPlaceholder}
          value={textValue}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          darkerPlaceholder
          containerStyles={styles.inputContainer}
          userStyles={styles.input}
          disabled={(isCategoryNew && !isUnifiedFlow) || !isAdminOrTopicOwner}
        />
        <AvomaButton
          onClick={
            isUnifiedFlow ? handleAddTriggerPrompt : handleCreateTriggerPrompt
          }
          label={L10n.general.add}
          small
          disabled={isButtonDisabled}
          loading={showButtonLoader}
        />
        {loading && <Spinner type='small' />}
      </div>
    );
  };

  return (
    <React.Fragment>
      {!restrictModification && (
        <CardSection contentStyles={{ padding: 0 }}>
          {renderPromptsHeader()}
          {!isPromptCreated && renderAddPromptTextField()}

          {isPromptCreated && (
            <div className='flex flex-col'>
              <div ref={phrasesContainerRef}>
                <Prompt
                  key={promptToDisplay.uuid}
                  prompt={promptToDisplay}
                  variations={promptToDisplay.variations}
                  onDeleteTriggerPrompt={uuidToBeDeleted => {
                    if (isUnifiedFlow) {
                      setPrompt({});
                    } else {
                      onDeleteTriggerPrompt(uuidToBeDeleted);
                    }
                  }}
                  onDeleteTriggerPromptVariation={deleteVariationObj => {
                    if (isUnifiedFlow) {
                      handleDeleteTriggerPromptVariation(deleteVariationObj);
                    } else {
                      onDeleteTriggerPromptVariation(deleteVariationObj);
                    }
                  }}
                  onCreateTriggerPromptVariation={variationObj => {
                    if (isUnifiedFlow) {
                      handleAddTriggerPromptVariation(variationObj.label);
                    } else {
                      onCreateTriggerPromptVariation(variationObj);
                    }
                  }}
                  variationExistsError={phraseVariationExistsError}
                  onUpdateLabel={labelObj => {
                    if (isUnifiedFlow) {
                      if (prompt.label === labelObj.word.label) {
                        setPrompt({ ...prompt, label: labelObj.label });
                      } else {
                        const updatedVariations = prompt?.variations?.map(
                          variation => {
                            if (variation.label === labelObj.word.label) {
                              return labelObj;
                            }

                            return variation;
                          }
                        );

                        setPrompt({
                          ...prompt,
                          variations: updatedVariations
                        });
                      }
                    } else {
                      handleUpdateTriggerPromptLabel(labelObj);
                    }
                  }}
                  categoryUuid={category.uuid}
                  onClickMakePrimary={onClickMakePrimary}
                  containerWidth={phrasesContainerRef?.current?.offsetWidth}
                  variationPopoverTitle={
                    L10n.customCategory.triggerPromptsVariations
                  }
                  addMoreVariationsTooltip={
                    L10n.customCategory.addMoretriggerPromptsVariations
                  }
                  variationExistsMessage={
                    isUnifiedFlow
                      ? 'Variation already exists'
                      : phraseVariationExistsMessage
                  }
                  isUnifiedFlow={isUnifiedFlow}
                  hasDuplicateVariation={hasDuplicateVariation}
                  isAdminOrTopicOwner={isAdminOrTopicOwner}
                />
              </div>
              <NotificationBanner
                content={L10n.customCategory.addTriggerPromptVariationsBanner}
                style={{ marginTop: Tokens.spacing.one }}
              />
            </div>
          )}
        </CardSection>
      )}
    </React.Fragment>
  );
};

const styles = {
  header: {
    ...Tokens.type.body,
    fontWeight: 'bold',
    marginBottom: Tokens.spacing.half,
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center'
  },
  inputContainer: {
    flex: 1,
    marginRight: Tokens.spacing.two
  },
  switch: {
    padding: 0,
    paddingBottom: Tokens.spacing.two
  },
  input: {
    ...Tokens.type.bodyS,
    flex: 1,
    fontWeight: 'normal',
    height: Tokens.spacing.four,
    padding: `${Tokens.spacing.half} ${Tokens.spacing.one}`
  },
  error: {
    ...Tokens.type.bodyS,
    color: Tokens.colors.avomaRed,
    margin: `${Tokens.spacing.one} 0`
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${Tokens.spacing.three}`
  },
  optionTitle: {
    ...Tokens.type.body,
    fontWeight: 'bold'
  },
  radio: {
    ...Tokens.type.body,
    color: Tokens.colors.gunmetal
    // margin: `${Tokens.spacing.two} 0`
  },
  radioSubtitle: {
    color: Tokens.colors.silver
  },
  checkboxLabel: {
    ...Tokens.type.body
  },
  checkboxContainer: {
    marginLeft: Tokens.spacing.three,
    marginTop: Tokens.spacing.one,
    marginBottom: Tokens.spacing.one
  },
  betaText: {
    fontStyle: 'normal',
    ...Tokens.type.timestamp,
    color: Tokens.colors.white,
    marginLeft: Tokens.spacing.one,
    borderRadius: Tokens.spacing.borderRadius,
    padding: Tokens.spacing.half,
    backgroundColor: Tokens.colors.silver,
    textTransform: 'uppercase'
  },
  radioLabel: {
    padding: `${Tokens.spacing.half} ${Tokens.spacing.two} ${Tokens.spacing.half} 0`
  },
  disabled: { opacity: '0.4', cursor: 'not-allowed' }
};

TriggerPrompts.propTypes = {
  category: PropTypes.object,
  createTriggerPrompt: PropTypes.func,
  loading: PropTypes.bool,
  restrictModification: PropTypes.bool,
  phraseVariationExistsError: PropTypes.bool,
  phraseVariationExistsMessage: PropTypes.string,
  deleteTriggerPrompt: PropTypes.func,
  createTriggerPromptVariation: PropTypes.func,
  deleteTriggerPromptVariation: PropTypes.func,
  updateTriggerPromptLabel: PropTypes.func,
  makeTriggerPromptVariationPrimary: PropTypes.func,
  isUnifiedFlow: PropTypes.bool,
  prompt: PropTypes.object,
  setPrompt: PropTypes.func,
  isAdminOrTopicOwner: PropTypes.bool
};

const mapStateToProps = state => ({
  phraseVariationExistsError: state.insights.phraseVariationExistsError,
  phraseVariationExistsMessage: state.insights.phraseVariationExistsMessage
});

const actionCreators = {
  createTriggerPrompt: createTriggerPromptAction,
  createTriggerPromptVariation: createTriggerPromptVariationAction,
  deleteTriggerPrompt: deleteTriggerPromptAction,
  deleteTriggerPromptVariation: deleteTriggerPromptVariationAction,
  updateTriggerPromptLabel: updateTriggerPromptLabelAction,
  makeTriggerPromptVariationPrimary: makeTriggerPromptVariationPrimaryAction
};

const ConnectedTriggerPrompts = connect(
  mapStateToProps,
  actionCreators
)(TriggerPrompts);

export default ConnectedTriggerPrompts;
