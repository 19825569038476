import PropTypes from 'prop-types';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { useGetAutomationActionRulesQuery } from 'services/events';
import { useCreateManualTicketMutation } from 'services/ticketing';

import AvomaButton from 'components/Common/AvomaButton';
import AvomaDialog from 'components/Common/AvomaDialog';
import Spinner from 'components/Common/Spinner';
import AutomationFormTaskAssignee from 'components/Tasks/generic/Assignee';
import ManualFormTaskDueDate from 'components/Tasks/generic/DueDate/TaskDueDate';
import AutomationFormGenericSelect from 'components/Tasks/generic/Select';
import AutomationFormTaskDescription from 'components/Tasks/generic/TaskDescription';
import AutomationFormTaskTitle from 'components/Tasks/generic/TaskTitle';
import {
  errorNotification,
  successNotification
} from 'components/ToastNotifications';

import L10n from 'helpers/L10n';

import {
  EAutomationActionRules,
  EAutomationActionType
} from 'constants/automation';
import { NOTES_CREATED_EVENT } from 'constants/events';

const HubspotTasksManualCreateDialog = ({
  open,
  setOpen,
  selectedText,
  meetingUuid,
  editor
}) => {
  const [clientValue, setClientValue] = useState({});

  const [dueDateOption, setDueDateOption] = useState('1');
  const [dueDateDaysInFuture, setDueDateDaysInFuture] = useState('1');
  const [dueDateCalendarValue, setDueDateCalendarValue] = useState(null);
  const { uuid: ticketingProviderUuid } = useSelector(
    state => state.user?.ticketingProviders?.hubspot || {}
  );

  const { data = [], isFetching } = useGetAutomationActionRulesQuery(
    {
      actionGroupUid: EAutomationActionType.HUBSPOT_TASK
    },
    {
      skip: !open
    }
  );

  useEffect(() => {
    if (selectedText) {
      setClientValue({
        [EAutomationActionRules.TASK_TITLE]: selectedText,
        [EAutomationActionRules.TASK_DESCRIPTION]: selectedText
      });
    }
  }, [selectedText]);

  const [createManualTicketFn, { isLoading }] = useCreateManualTicketMutation();

  const handleCloseDialog = () => {
    setClientValue({});
    setDueDateOption('1');
    setDueDateDaysInFuture('1');
    setDueDateCalendarValue(null);
  };

  const handleClientObjectChange = ({ actionUid, value }) => {
    setClientValue(prev => ({
      ...prev,
      [actionUid]: value
    }));
  };

  const isCreateDisabled = useMemo(
    () =>
      !clientValue?.[EAutomationActionRules.TASK_TITLE] ||
      !clientValue?.[EAutomationActionRules.TASK_TYPE] ||
      !clientValue?.[EAutomationActionRules.TASK_PRIORITY],
    [clientValue]
  );

  const handleCreateTicket = async () => {
    const payload = {
      name: clientValue?.[EAutomationActionRules.TASK_TITLE] || '',
      description: clientValue?.[EAutomationActionRules.TASK_DESCRIPTION] || '',
      assignees_external_id:
        clientValue?.[EAutomationActionRules.TASK_ASSIGNEE] || [],
      meeting_uuid: meetingUuid,
      status: null,
      task_type: clientValue?.[EAutomationActionRules.TASK_TYPE] || null,
      priority: clientValue?.[EAutomationActionRules.TASK_PRIORITY] || null,
      due_date_option: parseInt(dueDateOption),
      due_date_days_in_future: dueDateDaysInFuture,
      due_date_calendar_value: dueDateCalendarValue?.toISOString(),
      ticket_type: 'new_ticket'
    };

    const res = await createManualTicketFn({
      payload,
      providerUuid: ticketingProviderUuid
    });

    if (res.data?.ticketUrl) {
      editor.insertLink(res.data.ticketUrl, selectedText);
    }

    if (res.error) {
      errorNotification({
        message: L10n.clickupIntegration.createTicketError
      });
    } else {
      successNotification({
        message: L10n.clickupIntegration.createTicketSuccess
      });
    }

    setOpen(false);
    handleCloseDialog();
  };

  const handleDateChange = date => {
    setDueDateCalendarValue(date);
  };

  return (
    <AvomaDialog
      open={open}
      setOpen={setOpen}
      controlled
      title={L10n.hubspotTasks.createDialogTitle}
      contentClasses='w-[850px] max-w-[850px] max-h-[800px]'
      closeCleanupHandler={handleCloseDialog}
      childrenClasses='max-h-[810px]'
    >
      {isFetching ? (
        <div className='h-[400px] justify-center flex items-center'>
          <Spinner />
        </div>
      ) : (
        <Fragment>
          <div className='flex flex-col gap-4'>
            {data
              ?.slice()
              ?.sort((a, b) => {
                if (a.order > b.order) {
                  return 1;
                }
                if (a.order < b.order) {
                  return -1;
                }
                return 0;
              })
              ?.map(action => {
                switch (action.uid) {
                  case EAutomationActionRules.TASK_TITLE:
                    return (
                      <AutomationFormTaskTitle
                        key={`${action.uid}-task-title`}
                        action={action}
                        clientValue={clientValue?.[action.uid]}
                        handleValueChange={val =>
                          handleClientObjectChange({
                            actionUid: action.uid,
                            value: val
                          })
                        }
                        selectedEvent={NOTES_CREATED_EVENT}
                        isRequired={action?.isRequired}
                      />
                    );
                  case EAutomationActionRules.TASK_DESCRIPTION:
                    return (
                      <AutomationFormTaskDescription
                        key={`${action.uid}-task-desc`}
                        action={action}
                        clientValue={clientValue?.[action.uid]}
                        handleValueChange={val =>
                          handleClientObjectChange({
                            actionUid: action.uid,
                            value: val
                          })
                        }
                        isRequired={action?.isRequired}
                      />
                    );
                  case EAutomationActionRules.TASK_ASSIGNEE:
                    return (
                      <AutomationFormTaskAssignee
                        key={`${action.uid}-task-assignee`}
                        action={action}
                        clientValue={clientValue?.[action.uid] || []}
                        handleValueChange={val =>
                          handleClientObjectChange({
                            actionUid: action.uid,
                            value: val
                          })
                        }
                        groupUid={EAutomationActionType.HUBSPOT_TASK}
                        isRequired={action?.isRequired}
                        showSelect={false}
                      />
                    );
                  case EAutomationActionRules.TASK_DUE_DATE:
                    return (
                      <ManualFormTaskDueDate
                        dueDateOption={dueDateOption}
                        dueDateDaysInFuture={dueDateDaysInFuture}
                        dueDateCalendarValue={dueDateCalendarValue}
                        handleDueDateOptionChange={setDueDateOption}
                        handleDueDateDaysInFutureChange={setDueDateDaysInFuture}
                        handleDueDateCalendarValueChange={handleDateChange}
                        datePickerClasses='top-[464px]'
                      />
                    );
                  case EAutomationActionRules.TASK_TYPE:
                  case EAutomationActionRules.TASK_PRIORITY:
                    return (
                      <AutomationFormGenericSelect
                        key={action.uid}
                        action={action}
                        clientValue={clientValue?.[action.uid]}
                        handleValueChange={val =>
                          handleClientObjectChange({
                            actionUid: action.uid,
                            value: val
                          })
                        }
                        isRequired={action?.isRequired}
                      />
                    );
                  default:
                    return null;
                }
              })}
          </div>

          <div className='mt-6 flex items-center justify-end gap-4'>
            <AvomaButton
              black
              small
              onClick={() => setOpen(false)}
              label={L10n.general.cancel}
            />
            <AvomaButton
              small
              onClick={handleCreateTicket}
              label={L10n.createHighlightModal.create}
              disabled={isCreateDisabled}
              loading={isLoading}
            />
          </div>
        </Fragment>
      )}
    </AvomaDialog>
  );
};

HubspotTasksManualCreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedText: PropTypes.string.isRequired,
  meetingUuid: PropTypes.string.isRequired,
  editor: PropTypes.object.isRequired
};

export default HubspotTasksManualCreateDialog;
