import isHotkey from 'is-hotkey';
import { Editor } from 'slate';

export const UNDERLINE = 'underline';
export const ITALIC = 'italic';
export const BOLD = 'bold';
export const STRIKETHROUGH = 'strikethrough';

const withMarks = editor => {
  const { onKeyDown } = editor;

  editor.toggleMark = format => {
    const isActive = editor.isMarkActive(format);
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  editor.isMarkActive = format => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  editor.onKeyDown = event => {
    if (isHotkey('enter', event)) {
      // Editor.removeMark(editor, UNDERLINE);
      // Editor.removeMark(editor, ITALIC);
      // Editor.removeMark(editor, BOLD);
    } else if (isHotkey('mod+b', event)) {
      editor.toggleMark(BOLD);
      event.preventDefault();
    } else if (isHotkey('mod+i', event)) {
      editor.toggleMark(ITALIC);
      event.preventDefault();
    } else if (isHotkey('mod+u', event)) {
      editor.toggleMark(UNDERLINE);
      event.preventDefault();
    } else if (isHotkey('mod+shift+x', event)) {
      editor.toggleMark(STRIKETHROUGH);
      event.preventDefault();
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  return editor;
};

export default withMarks;
