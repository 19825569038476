/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import Radium from 'radium';
import React, { Component } from 'react';
import stylePropType from 'react-style-proptype';

import Tokens from 'styles/tokens';

class AvomaIcon extends Component {
  state = {
    hovered: false
  };

  onMouseEnter = () => {
    const { onMouseEnter } = this.props;
    this.setState({ hovered: true });

    onMouseEnter();
  };

  onMouseLeave = () => {
    const { onMouseLeave } = this.props;
    this.setState({ hovered: false });
    onMouseLeave();
  };

  render() {
    const {
      style,
      iconStyle,
      iconHoverStyles,
      icon,
      Icon,
      large,
      small,
      extraSmall,
      tooltip,
      tooltipPosition,
      width,
      height,
      className,
      onClick,
      RenderIcon,
      iconClassName,
      iconImageStyle,
      renderIconClassName,
      dataTestId = 'avoma-icon'
    } = this.props;

    const { hovered } = this.state;

    const tooltipClasses = [];
    if (tooltip && tooltip.trim().length > 0) {
      tooltipClasses.push('tooltip');
      tooltipClasses.push(tooltipPosition);
    }

    return (
      <div
        style={{
          ...styles.container,
          ...style
        }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        aria-label={tooltip}
        className={`${tooltipClasses.join(' ')} ${className}`}
        onClick={onClick}
        data-testid={dataTestId}
      >
        {Icon && Icon}
        {icon && (
          <div
            style={{
              ...styles.iconContainer,
              ...iconStyle,
              ...(hovered && iconHoverStyles)
            }}
            className={iconClassName}
          >
            <img
              src={icon}
              alt={`${tooltip}`}
              style={{
                ...styles.mediumSize,
                ...(small && styles.smallSize),
                ...(large && styles.largeSize),
                ...(extraSmall && styles.extraSmall),
                ...(width && { width }),
                ...(height && { height }),
                ...iconImageStyle
              }}
            />
          </div>
        )}
        {RenderIcon && (
          <div
            style={{
              ...styles.iconContainer,
              ...iconStyle,
              ...(hovered && iconHoverStyles)
            }}
            className={iconClassName}
          >
            <RenderIcon
              style={{
                ...styles.mediumSize,
                ...(small && styles.smallSize),
                ...(large && styles.largeSize),
                ...(extraSmall && styles.extraSmall),
                ...(width && { width }),
                ...(height && { height }),
                ...iconImageStyle
              }}
              className={renderIconClassName}
            />
          </div>
        )}
      </div>
    );
  }
}
const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {},
  iconContainer: {
    fontSize: '0',
    lineHeight: '0',
    color: Tokens.colors.gainsboro,
    fill: Tokens.colors.gainsboro
  },
  mediumSize: {
    width: Tokens.spacing.three,
    height: Tokens.spacing.three
  },
  largeSize: {
    width: Tokens.spacing.four,
    height: Tokens.spacing.four
  },
  smallSize: {
    width: Tokens.spacing.two,
    height: Tokens.spacing.two
  },
  extraSmall: {
    width: Tokens.spacing.onehalf,
    height: Tokens.spacing.onehalf
  }
};

AvomaIcon.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  Icon: PropTypes.node,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  tooltip: PropTypes.string,
  large: PropTypes.bool,
  small: PropTypes.bool,
  extraSmall: PropTypes.bool,
  style: stylePropType,
  tooltipPosition: PropTypes.oneOf([
    'bottom',
    'top',
    'left',
    'right',
    'bottom-right',
    'bottom-left',
    'top-right',
    'top-left'
  ]),
  iconStyle: stylePropType,
  iconHoverStyles: stylePropType,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  RenderIcon: PropTypes.node,
  iconClassName: PropTypes.string,
  renderIconClassName: PropTypes.string,
  iconImageStyle: stylePropType,
  dataTestId: PropTypes.string
};

AvomaIcon.defaultProps = {
  icon: null,
  large: false,
  small: false,
  tooltip: '',
  style: null,
  iconStyle: null,
  tooltipPosition: 'bottom',
  className: '',
  onMouseEnter: () => {},
  onMouseLeave: () => {}
};

export default Radium(AvomaIcon);
