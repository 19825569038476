export const PlaylistActions = {
  FETCH_PLAYLIST_REQUEST: 'FETCH_PLAYLIST_REQUEST',
  FETCH_PLAYLIST_SUCCESS: 'FETCH_PLAYLIST_SUCCESS',
  FETCH_PLAYLIST_FAILURE: 'FETCH_PLAYLIST_FAILURE',
  CREATE_PLAYLIST_REQUEST: 'CREATE_PLAYLIST_REQUEST',
  CREATE_PLAYLIST_SUCCESS: 'CREATE_PLAYLIST_SUCCESS',
  CREATE_PLAYLIST_FAILURE: 'CREATE_PLAYLIST_FAILURE',
  UPDATE_PLAYLIST_REQUEST: 'UPDATE_PLAYLIST_REQUEST',
  UPDATE_PLAYLIST_PRIVACY_REQUEST: 'UPDATE_PLAYLIST_PRIVACY_REQUEST',
  UPDATE_PLAYLIST_PRIVACY_SUCCESS: 'UPDATE_PLAYLIST_PRIVACY_SUCCESS',
  UPDATE_PLAYLIST_PRIVACY_ERROR: 'UPDATE_PLAYLIST_PRIVACY_ERROR',
  UPDATE_PLAYLIST_SUCCESS: 'UPDATE_PLAYLIST_SUCCESS',
  UPDATE_PLAYLIST_FAILURE: 'UPDATE_PLAYLIST_FAILURE',
  DELETE_PLAYLIST_REQUEST: 'DELETE_PLAYLIST_REQUEST',
  DELETE_PLAYLIST_SUCCESS: 'DELETE_PLAYLIST_SUCCESS',
  DELETE_PLAYLIST_FAILURE: 'DELETE_PLAYLIST_FAILURE',
  FETCH_PLAYLISTCONTENT_REQUEST: 'FETCH_PLAYLISTCONTENT_REQUEST',
  FETCH_PLAYLISTCONTENT_SUCCESS: 'FETCH_PLAYLISTCONTENT_SUCCESS',
  FETCH_PLAYLISTCONTENT_FAILURE: 'FETCH_PLAYLISTCONTENT_FAILURE',
  CREATE_PLAYLISTCONTENT_REQUEST: 'PLAYLIST_CONTENT_ADD',
  CREATE_PLAYLISTCONTENT_SUCCESS: 'CREATE_PLAYLISTCONTENT_SUCCESS',
  CREATE_PLAYLISTCONTENT_FAILURE: 'CREATE_PLAYLISTCONTENT_FAILURE',
  DELETE_PLAYLISTCONTENT_REQUEST: 'PLAYLIST_CONTENT_REMOVE',
  DELETE_PLAYLISTCONTENT_SUCCESS: 'DELETE_PLAYLISTCONTENT_SUCCESS',
  DELETE_PLAYLISTCONTENT_FAILURE: 'DELETE_PLAYLISTCONTENT_FAILURE',
  UPDATE_PLAYLISTCONTENT_ORDER_REQUEST: 'PLAYLIST_CONTENT_REORDER',
  UPDATE_PLAYLISTCONTENT_ORDER_SUCCESS: 'UPDATE_PLAYLISTCONTENT_ORDER_SUCCESS',
  UPDATE_PLAYLISTCONTENT_ORDER_FAILURE: 'UPDATE_PLAYLISTCONTENT_ORDER_FAILURE',
  FETCH_PLAYLIST_RECORDING_REQUEST: 'FETCH_PLAYLIST_RECORDING_REQUEST',
  FETCH_PLAYLIST_RECORDING_SUCCESS: 'FETCH_PLAYLIST_RECORDING_SUCCESS',
  FETCH_PLAYLIST_RECORDING_FAILURE: 'FETCH_PLAYLIST_RECORDING_FAILURE',

  SUBSCRIBE_PLAYLIST_REQUEST: 'PLAYLIST_SUBSCRIBE_REQUEST',
  SUBSCRIBE_PLAYLIST_SUCCESS: 'PLAYLIST_SUBSCRIBE_SUCCESS',
  SUBSCRIBE_PLAYLIST_ERROR: 'PLAYLIST_SUBSCRIBE_ERROR',

  UNSUBSCRIBE_PLAYLIST_REQUEST: 'UNSUBSCRIBE_PLAYLIST_REQUEST',
  UNSUBSCRIBE_PLAYLIST_SUCCESS: 'UNSUBSCRIBE_PLAYLIST_SUCCESS',
  UNSUBSCRIBE_PLAYLIST_ERROR: 'UNSUBSCRIBE_PLAYLIST_ERROR',

  REMOVE_SUBSCRIBER_REQUEST: 'REMOVE_SUBSCRIBER_REQUEST',
  REMOVE_SUBSCRIBER_SUCCESS: 'REMOVE_SUBSCRIBER_SUCCESS',
  REMOVE_SUBSCRIBER_ERROR: 'REMOVE_SUBSCRIBER_ERROR',

  FETCH_MY_PLAYLISTS_REQUEST: 'FETCH_MY_PLAYLISTS_REQUEST',
  FETCH_MY_PLAYLISTS_SUCCESS: 'FETCH_MY_PLAYLISTS_SUCCESS',
  FETCH_MY_PLAYLISTS_ERROR: 'FETCH_MY_PLAYLISTS_ERROR',

  FETCH_ALL_PLAYLISTS_REQUEST: 'FETCH_ALL_PLAYLISTS_REQUEST',
  FETCH_ALL_PLAYLISTS_SUCCESS: 'FETCH_ALL_PLAYLISTS_SUCCESS',
  FETCH_ALL_PLAYLISTS_ERROR: 'FETCH_ALL_PLAYLISTS_ERROR',

  FETCH_SUBSCRIBED_PLAYLISTS_REQUEST: 'FETCH_SUBSCRIBED_PLAYLISTS_REQUEST',
  FETCH_SUBSCRIBED_PLAYLISTS_SUCCESS: 'FETCH_SUBSCRIBED_PLAYLISTS_SUCCESS',
  FETCH_SUBSCRIBED_PLAYLISTS_ERROR: 'FETCH_SUBSCRIBED_PLAYLISTS_ERROR',

  FETCH_ORGANIZATION_PLAYLISTS_REQUEST: 'FETCH_ORGANIZATION_PLAYLISTS_REQUEST',
  FETCH_ORGANIZATION_PLAYLISTS_SUCCESS: 'FETCH_ORGANIZATION_PLAYLISTS_SUCCESS',
  FETCH_ORGANIZATION_PLAYLISTS_ERROR: 'FETCH_ORGANIZATION_PLAYLISTS_ERROR',

  RESET_PLAYLIST_CONTENT: 'RESET_PLAYLIST_CONTENT',

  CREATE_PLAYLIST_SHARES_REQUEST: 'CREATE_PLAYLIST_SHARES_REQUEST',
  CREATE_PLAYLIST_SHARES_SUCCESS: 'CREATE_PLAYLIST_SHARES_SUCCESS',
  CREATE_PLAYLIST_SHARES_ERROR: 'CREATE_PLAYLIST_SHARES_ERROR',

  ADD_EMAIL_PUBLIC_SHARE_PLAYLIST_REQUEST:
    'ADD_EMAIL_PUBLIC_SHARE_PLAYLIST_REQUEST',
  ADD_EMAIL_PUBLIC_SHARE_PLAYLIST_SUCCESS:
    'ADD_EMAIL_PUBLIC_SHARE_PLAYLIST_SUCCESS',
  ADD_EMAIL_PUBLIC_SHARE_PLAYLIST_ERROR:
    'ADD_EMAIL_PUBLIC_SHARE_PLAYLIST_ERROR',

  ADD_TEAM_PUBLIC_SHARE_PLAYLIST_REQUEST:
    'ADD_TEAM_PUBLIC_SHARE_PLAYLIST_REQUEST',
  ADD_TEAM_PUBLIC_SHARE_PLAYLIST_SUCCESS:
    'ADD_TEAM_PUBLIC_SHARE_PLAYLIST_SUCCESS',
  ADD_TEAM_PUBLIC_SHARE_PLAYLIST_FAILURE:
    'ADD_TEAM_PUBLIC_SHARE_PLAYLIST_FAILURE',

  REMOVE_PLAYLIST_SHARE_EMAIL_REQUEST: 'REMOVE_PLAYLIST_SHARE_EMAIL_REQUEST',
  REMOVE_PLAYLIST_SHARE_EMAIL_SUCCESS: 'REMOVE_PLAYLIST_SHARE_EMAIL_SUCCESS',
  REMOVE_PLAYLIST_SHARE_EMAIL_ERROR: 'REMOVE_PLAYLIST_SHARE_EMAIL_ERROR',

  REMOVE_PLAYLIST_SHARE_TEAM_REQUEST: 'REMOVE_PLAYLIST_SHARE_TEAM_REQUEST',
  REMOVE_PLAYLIST_SHARE_TEAM_SUCCESS: 'REMOVE_PLAYLIST_SHARE_TEAM_SUCCESS',
  REMOVE_PLAYLIST_SHARE_TEAM_ERROR: 'REMOVE_PLAYLIST_SHARE_TEAM_ERROR',

  REQUEST_PLAYLIST_SHARE_REQUEST: 'REQUEST_PLAYLIST_SHARE_REQUEST',
  REQUEST_PLAYLIST_SHARE_SUCCESS: 'REQUEST_PLAYLIST_SHARE_SUCCESS',
  REQUEST_PLAYLIST_SHARE_ERROR: 'REQUEST_PLAYLIST_SHARE_ERROR',

  SET_CHANGE_PERMISSION_INFORMATION: 'SET_CHANGE_PERMISSION_INFORMATION',
  SET_ADD_TO_PLAYLIST_INFORMATION: 'SET_ADD_TO_PLAYLIST_INFORMATION',

  UPDATE_PLAYLIST_CONTENTS_PRIVACY: 'UPDATE_PLAYLIST_CONTENTS_PRIVACY',

  UPDATE_PLAYLIST_CONTENT_PRIVACY: 'UPDATE_PLAYLIST_CONTENT_PRIVACY',

  UPDATE_PLAYLIST_SHARE_REQUEST: 'UPDATE_PLAYLIST_SHARE_REQUEST',
  UPDATE_PLAYLIST_SHARE_SUCCESS: 'UPDATE_PLAYLIST_SHARE_SUCCESS',
  UPDATE_PLAYLIST_SHARE_ERROR: 'UPDATE_PLAYLIST_SHARE_ERROR',

  FETCH_PLAYLIST_SHARE_DATA_REQUEST: 'FETCH_PLAYLIST_SHARE_DATA_REQUEST',
  FETCH_PLAYLIST_SHARE_DATA_SUCCESS: 'FETCH_PLAYLIST_SHARE_DATA_SUCCESS',
  FETCH_PLAYLIST_SHARE_DATA_ERROR: 'FETCH_PLAYLIST_SHARE_DATA_ERROR',

  FETCH_PUBLIC_PLAYLIST_CONTENT_REQUEST:
    'FETCH_PUBLIC_PLAYLIST_CONTENT_REQUEST',
  FETCH_PUBLIC_PLAYLIST_CONTENT_SUCCESS:
    'FETCH_PUBLIC_PLAYLIST_CONTENT_SUCCESS',
  FETCH_PUBLIC_PLAYLIST_CONTENT_FAILURE:
    'FETCH_PUBLIC_PLAYLIST_CONTENT_FAILURE',

  PLAYLIST_SEND_OTP_REQUEST: 'PLAYLIST_SEND_OTP_REQUEST',
  PLAYLIST_SEND_OTP_SUCCESS: 'PLAYLIST_SEND_OTP_SUCCESS',
  PLAYLIST_SEND_OTP_ERROR: 'PLAYLIST_SEND_OTP_ERROR',

  SET_PLAYLISTS_SEARCH_TEXT: 'SET_PLAYLISTS_SEARCH_TEXT'
};

export const SegmentActions = {
  update: {
    request: 'MEETING_SEGMENT_UPDATE_REQUEST',
    success: 'MEETING_SEGMENT_UPDATE_SUCCESS',
    failure: 'MEETING_SEGMENT_UPDATE_FAILURE'
  }
};

export const MachineNoteActions = {
  update: {
    request: 'MACHINE_NOTE_UPDATE_REQUEST',
    success: 'MACHINE_NOTE_UPDATE_SUCCESS',
    failure: 'MACHINE_NOTE_UPDATE_FAILURE'
  }
};

export const AppActions = {
  MOUNT_CREATE_HIGHLIGHT: 'MOUNT_CREATE_HIGHLIGHT',
  UNMOUNT_CREATE_HIGHLIGHT: 'UNMOUNT_CREATE_HIGHLIGHT',
  SHOW_MULTI_HIGHLIGHT_MODAL: 'SHOW_MULTI_HIGHLIGHT_MODAL',
  CREATE_HIGHLIGHT_UPDATE_TIME: 'CREATE_HIGHLIGHT_UPDATE_TIME',
  CREATE_HIGHLIGHT_UPDATE_COMMENT: 'CREATE_HIGHLIGHT_UPDATE_COMMENT',
  EXPAND_NAVBAR: 'EXPAND_NAVBAR',
  COLLAPSE_NAVBAR: 'COLLAPSE_NAVBAR',
  CLEAR_LOGIN_REDIRECT: 'CLEAR_LOGIN_REDIRECT',
  SET_ZOOM_OAUTH_CODE: 'SET_ZOOM_OAUTH_CODE',
  SET_ZOOM_PHONE_OAUTH_CODE: 'SET_ZOOM_PHONE_OAUTH_CODE',
  CLEAR_ZOOM_OAUTH_CODE: 'CLEAR_ZOOM_OAUTH_CODE',
  SET_AIRCALL_OAUTH_CODE: 'SET_AIRCALL_OAUTH_CODE',
  SET_CONNECT_LEADER_OAUTH_CODE: 'SET_CONNECT_LEADER_OAUTH_CODE',
  SET_GOTOCONNECT_OAUTH_CODE: 'SET_GOTOCONNECT_OAUTH_CODE',
  SET_VONAGE_OAUTH_CODE: 'SET_VONAGE_OAUTH_CODE',
  CLEAR_VONAGE_OAUTH_CODE: 'CLEAR_VONAGE_OAUTH_CODE',
  CLEAR_AIRCALL_OAUTH_CODE: 'CLEAR_AIRCALL_OAUTH_CODE',
  CLEAR_CONNECT_LEADER_OAUTH_CODE: 'CLEAR_CONNECT_LEADER_OAUTH_CODE',
  SHOW_NOTIFICATION_BANNER: 'SHOW_NOTIFICATION_BANNER',
  HIDE_NOTIFICATION_BANNER: 'HIDE_NOTIFICATION_BANNER',
  GET_BUILD_VERSION: 'GET_BUILD_VERSION',
  GET_BUILD_VERSION_FAILURE: 'GET_BUILD_VERSION_FAILURE',
  SET_APP_STALE: 'SET_APP_STALE',
  SET_APP_FRESH: 'SET_APP_FRESH',
  SKIP_TIMEZONE_CHECK: 'SKIP_TIMEZONE_CHECK',
  UPDATE_MEETING_DETAILS_SECONDARY_PANEL_WIDTH:
    'UPDATE_MEETING_DETAILS_SECONDARY_PANEL_WIDTH',
  UPDATE_MEETING_DETAILS_LAYOUT: 'UPDATE_MEETING_DETAILS_LAYOUT',
  TOGGLE_MEETING_CALENDAR_VIEW: 'TOGGLE_MEETING_CALENDAR_VIEW',
  UPDATE_MEETING_LIST_TIMELINE_FILTER: 'UPDATE_MEETING_LIST_TIMELINE_FILTER',

  FETCH_APP_HOMEPAGE_ROUTE_REQUEST: 'FETCH_APP_HOMEPAGE_ROUTE_REQUEST',
  FETCH_APP_HOMEPAGE_ROUTE_SUCCESS: 'FETCH_APP_HOMEPAGE_ROUTE_SUCCESS',
  FETCH_APP_HOMEPAGE_ROUTE_FAILURE: 'FETCH_APP_HOMEPAGE_ROUTE_FAILURE',

  UPDATE_APP_HOMEPAGE_ROUTE_REQUEST: 'UPDATE_APP_HOMEPAGE_ROUTE_REQUEST',
  UPDATE_APP_HOMEPAGE_ROUTE_SUCCESS: 'UPDATE_APP_HOMEPAGE_ROUTE_SUCCESS',
  UPDATE_APP_HOMEPAGE_ROUTE_FAILURE: 'UPDATE_APP_HOMEPAGE_ROUTE_FAILURE',

  DISMISS_TEMPORARY_BANNER: 'DISMISS_TEMPORARY_BANNER',
  DISPLAY_TEMPORARY_BANNER: 'DISPLAY_TEMPORARY_BANNER',

  UPDATE_CALENDAR_VIEW_FORMAT: 'UPDATE_CALENDAR_VIEW_FORMAT',

  UPDATE_CONTENT_SHARING_PREFERENCES: 'UPDATE_CONTENT_SHARING_PREFERENCES',
  SAVE_COMMENT_DRAFT: 'SAVE_COMMENT_DRAFT',
  REMOVE_COMMENT_DRAFT: 'REMOVE_COMMENT_DRAFT'
};

export const HighlightActions = {
  CREATE_HIGHLIGHT_REQUEST: 'CREATE_HIGHLIGHT_REQUEST',
  CREATE_HIGHLIGHT_SUCCESS: 'CREATE_HIGHLIGHT_SUCCESS',
  CREATE_HIGHLIGHT_FAILURE: 'CREATE_HIGHLIGHT_FAILURE',
  DELETE_HIGHLIGHT_REQUEST: 'DELETE_HIGHLIGHT_REQUEST',
  DELETE_HIGHLIGHT_SUCCESS: 'DELETE_HIGHLIGHT_SUCCESS',
  DELETE_HIGHLIGHT_FAILURE: 'DELETE_HIGHLIGHT_FAILURE',
  FETCH_HIGHLIGHTS_REQUEST: 'FETCH_HIGHLIGHTS_REQUEST',
  FETCH_HIGHLIGHTS_SUCCESS: 'FETCH_HIGHLIGHTS_SUCCESS',
  FETCH_HIGHLIGHTS_FAILURE: 'FETCH_HIGHLIGHTS_FAILURE',

  FETCH_HIGHLIGHT_CATEGORY_OVERRIDES: 'Highlight Category Overrides',
  FETCH_HIGHLIGHT_CATEGORY_OVERRIDES_SUCCESS:
    'Highlight Category Overrides Success',
  FETCH_HIGHLIGHT_CATEGORY_OVERRIDES_FAILURE:
    'Highlight Category Overrides Failure',
  REORDER_HIGHLIGHT_CATEGORY_OVERRIDE_REQUEST:
    'Reorder Highlight Category Override',
  REORDER_HIGHLIGHT_CATEGORY_OVERRIDE_SUCCESS:
    'Reorder Highlight Category Override Success',
  REORDER_HIGHLIGHT_CATEGORY_OVERRIDE_FAILURE:
    'Reorder Highlight Category Override Failure',

  UPDATE_HIGHLIGHT_REQUEST: 'UPDATE_HIGHLIGHT_REQUEST',
  UPDATE_HIGHLIGHT_PRIVACY_REQUEST: 'UPDATE_HIGHLIGHT_PRIVACY_REQUEST',
  UPDATE_HIGHLIGHT_PRIVACY_SUCCESS: 'UPDATE_HIGHLIGHT_PRIVACY_SUCCESS',
  UPDATE_HIGHLIGHT_PRIVACY_FAILURE: 'UPDATE_HIGHLIGHT_PRIVACY_FAILURE',
  UPDATE_HIGHLIGHT_SUCCESS: 'UPDATE_HIGHLIGHT_SUCCESSs',
  UPDATE_HIGHLIGHT_FAILURE: 'UPDATE_HIGHLIGHT_FAILURE',
  UPDATE_HIGHLIGHTS_SUCCESS: 'UPDATE_HIGHLIGHTS_SUCCESS',

  HIDE_HIGHLIGHT_CATEGORY_REQUEST: 'HIDE_HIGHLIGHT_CATEGORY_REQUEST',
  HIDE_HIGHLIGHT_CATEGORY_SUCCESS: 'HIDE_HIGHLIGHT_CATEGORY_SUCCESS',
  HIDE_HIGHLIGHT_CATEGORY_FAILURE: 'HIDE_HIGHLIGHT_CATEGORY_FAILURE',

  SHOW_HIGHLIGHT_CATEGORY_REQUEST: 'SHOW_HIGHLIGHT_CATEGORY_REQUEST',
  SHOW_HIGHLIGHT_CATEGORY_SUCCESS: 'SHOW_HIGHLIGHT_CATEGORY_SUCCESS',
  SHOW_HIGHLIGHT_CATEGORY_FAILURE: 'SHOW_HIGHLIGHT_CATEGORY_FAILURE',

  FETCH_HIGHLIGHT_RECORDING_REQUEST: 'FETCH_HIGHLIGHT_RECORDING_REQUEST',
  FETCH_HIGHLIGHT_RECORDING_SUCCESS: 'FETCH_HIGHLIGHT_RECORDING_SUCCESS',
  FETCH_HIGHLIGHT_RECORDING_FAILURE: 'FETCH_HIGHLIGHT_RECORDING_FAILURE',

  CREATE_HIGHLIGHT_SHARES_REQUEST: 'CREATE_HIGHLIGHT_SHARES_REQUEST',
  CREATE_HIGHLIGHT_SHARES_SUCCESS: 'CREATE_HIGHLIGHT_SHARES_SUCCESS',
  CREATE_HIGHLIGHT_SHARES_ERROR: 'CREATE_HIGHLIGHT_SHARES_ERROR',
  REMOVE_HIGHLIGHT_SHARES: 'REMOVE_HIGHLIGHT_SHARES',

  SHARE_HIGHLIGHT_REQUEST: 'SHARE_HIGHLIGHT_REQUEST',
  SHARE_HIGHLIGHT_SUCCESS: 'SHARE_HIGHLIGHT_SUCCESS',
  SHARE_HIGHLIGHT_ERROR: 'SHARE_HIGHLIGHT_ERROR',

  UNSHARE_HIGHLIGHT_REQUEST: 'UNSHARE_HIGHLIGHT_REQUEST',
  UNSHARE_HIGHLIGHT_SUCCESS: 'UNSHARE_HIGHLIGHT_SUCCESS',
  UNSHARE_HIGHLIGHT_ERROR: 'UNSHARE_HIGHLIGHT_ERROR',

  UPDATE_HIGHLIGHT_SHARES_REQUEST: 'UPDATE_HIGHLIGHT_SHARES_REQUEST',
  UPDATE_HIGHLIGHT_SHARES_SUCCESS: 'UPDATE_HIGHLIGHT_SHARES_SUCCESS',
  UPDATE_HIGHLIGHT_SHARES_ERROR: 'UPDATE_HIGHLIGHT_SHARES_ERROR',

  SHARE_HIGHLIGHT_TO_SLACK_REQUEST: 'SHARE_HIGHLIGHT_TO_SLACK_REQUEST',
  SHARE_HIGHLIGHT_TO_SLACK_SUCCESS: 'SHARE_HIGHLIGHT_TO_SLACK_SUCCESS',
  SHARE_HIGHLIGHT_TO_SLACK_ERROR: 'SHARE_HIGHLIGHT_TO_SLACK_ERROR',

  REQUEST_HIGHLIGHT_SHARE_REQUEST: 'REQUEST_HIGHLIGHT_SHARE_REQUEST',
  REQUEST_HIGHLIGHT_SHARE_SUCCESS: 'REQUEST_HIGHLIGHT_SHARE_SUCCESS',
  REQUEST_HIGHLIGHT_SHARE_ERROR: 'REQUEST_HIGHLIGHT_SHARE_ERROR',

  DOWNLOAD_HIGHLIGHT_REQUEST: 'DOWNLOAD_HIGHLIGHT_REQUEST',
  DOWNLOAD_HIGHLIGHT_SUCCESS: 'DOWNLOAD_HIGHLIGHT_SUCCESS',
  DOWNLOAD_HIGHLIGHT_FAILURE: 'DOWNLOAD_HIGHLIGHT_FAILURE',

  ADD_HIGHLIGHT_TO_PLAYLIST_REQUEST: 'ADD_HIGHLIGHT_TO_PLAYLIST_REQUEST',
  ADD_HIGHLIGHT_TO_PLAYLIST_SUCCESS: 'ADD_HIGHLIGHT_TO_PLAYLIST_SUCCESS',
  ADD_HIGHLIGHT_TO_PLAYLIST_FAILURE: 'ADD_HIGHLIGHT_TO_PLAYLIST_FAILURE',

  REMOVE_HIGHLIGHT_FROM_PLAYLIST_REQUEST:
    'REMOVE_HIGHLIGHT_FROM_PLAYLIST_REQUEST',
  REMOVE_HIGHLIGHT_FROM_PLAYLIST_SUCCESS:
    'REMOVE_HIGHLIGHT_FROM_PLAYLIST_SUCCESS',
  REMOVE_HIGHLIGHT_FROM_PLAYLIST_FAILURE:
    'REMOVE_HIGHLIGHT_FROM_PLAYLIST_FAILURE',

  VIEWED_HIGHLIGHT_REQUEST: 'VIEWED_HIGHLIGHT_REQUEST',
  VIEWED_HIGHLIGHT_SUCCESS: 'VIEWED_HIGHLIGHT_SUCCESS',
  VIEWED_HIGHLIGHT_FAILURE: 'VIEWED_HIGHLIGHT_FAILURE',

  SET_HIGHLIGHT_SUMMARIZATION: 'SET_HIGHLIGHT_SUMMARIZATION',
  EMPTY_HIGHLIGHT_SUMMARIZATION: 'EMPTY_HIGHLIGHT_SUMMARIZATION'
};

export const AudioPlayerActions = {
  AUDIO_PLAYER_MOUNT: 'AUDIO_PLAYER_MOUNT',
  AUDIO_PLAYER_UNMOUNT: 'AUDIO_PLAYER_UNMOUNT',
  AUDIO_PLAYER_PLAY: 'AUDIO_PLAYER_PLAY',
  AUDIO_PLAYER_PAUSE: 'AUDIO_PLAYER_PAUSE',
  AUDIO_PLAYER_UPDATE_TIME: 'AUDIO_PLAYER_UPDATE_TIME',
  QUEUE_HIGHLIGHT: 'QUEUE_HIGHLIGHT',
  HIGHLIGHT_SEEKED: 'HIGHLIGHT_SEEKED',
  REMOVE_PLAY_HIGHLIGHT: 'REMOVE_PLAY_HIGHLIGHT',
  PLAYBACK_RATE: 'Set Playback Rate'
};

export const DashboardActions = {
  SEARCHED_KEYWORD: 'TRENDS_SEARCHED_QUERY',
  CLEAR: 'TRENDS_CLEAR',
  SET_TIME_GROUP: 'SET_TIME_GROUP',
  SET_SEARCH: 'SET_SEARCH',
  SET_FILTER: 'SET_FILTER',
  RESET_FILTER: 'RESET_FILTER',
  SET_ALL_FILTERS: 'SET_ALL_FILTERS',
  SET_BATCHED_FILTERS: 'SET_BATCHED_FILTERS',

  FETCH_INSIGHTS_REQUEST: 'FETCH_INSIGHTS_REQUEST',
  FETCH_INSIGHTS_SUCCESS: 'FETCH_INSIGHTS_SUCCESS',
  FETCH_INSIGHTS_FAILURE: 'FETCH_INSIGHTS_FAILURE',

  FETCH_FILLER_WORDS_REQUEST: 'FETCH_FILLER_WORDS_REQUEST',
  FETCH_FILLER_WORDS_SUCCESS: 'FETCH_FILLER_WORDS_SUCCESS',
  FETCH_FILLER_WORDS_FAILURE: 'FETCH_FILLER_WORDS_FAILURE',

  FETCH_ACTIVITY_STATS_REQUEST: 'Activity Stats Fetch',
  FETCH_ACTIVITY_STATS_SUCCESS: 'Activity Stats Fetched',
  FETCH_ACTIVITY_STATS_FAILURE: 'Activity Stats Fetch Failure',

  FETCH_TRACKERS_COUNT_REQUEST: 'FETCH_TRACKERS_COUNT_REQUEST',
  FETCH_TRACKERS_COUNT_SUCCESS: 'FETCH_TRACKERS_COUNT_SUCCESS',
  FETCH_TRACKERS_COUNT_FAILURE: 'FETCH_TRACKERS_COUNT_FAILURE',

  FETCH_TRACKERS_COUNT_BY_DATE_REQUEST: 'FETCH_TRACKERS_COUNT_BY_DATE_REQUEST',
  FETCH_TRACKERS_COUNT_BY_DATE_SUCCESS: 'FETCH_TRACKERS_COUNT_BY_DATE_SUCCESS',
  FETCH_TRACKERS_COUNT_BY_DATE_FAILURE: 'FETCH_TRACKERS_COUNT_BY_DATE_FAILURE',

  CLEAR_TRACKERS: 'CLEAR_TRACKERS',

  FETCH_TOPICS_REQUEST: 'FETCH_TOPICS_REQUEST',
  FETCH_TOPICS_SUCCESS: 'FETCH_TOPICS_SUCCESS',
  FETCH_TOPICS_FAILURE: 'FETCH_TOPICS_FAILURE',

  FETCH_TOP_QUESTIONS_REQUEST: 'FETCH_TOP_QUESTIONS_REQUEST',
  FETCH_TOP_QUESTIONS_SUCCESS: 'FETCH_TOP_QUESTIONS_SUCCESS',
  FETCH_TOP_QUESTIONS_FAILURE: 'FETCH_TOP_QUESTIONS_FAILURE',

  FETCH_QUESTION_ASSOCIATED_MEETINGS_REQUEST:
    'FETCH_QUESTION_ASSOCIATED_MEETINGS_REQUEST',
  FETCH_QUESTION_ASSOCIATED_MEETINGS_SUCCESS:
    'FETCH_QUESTION_ASSOCIATED_MEETINGS_SUCCESS',
  FETCH_QUESTION_ASSOCIATED_MEETINGS_FAILURE:
    'FETCH_QUESTION_ASSOCIATED_MEETINGS_FAILURE'
};

export const savedViewsActions = {
  CREATE_SAVED_VIEW_REQUEST: 'CREATE_SAVED_VIEW_REQUEST',
  CREATE_SAVED_VIEW_SUCCESS: 'CREATE_SAVED_VIEW_SUCCESS',
  CREATE_SAVED_VIEW_FAILURE: 'CREATE_SAVED_VIEW_FAILURE',
  UPDATE_SAVED_VIEW_REQUEST: 'UPDATE_SAVED_VIEW_REQUEST',
  UPDATE_SAVED_VIEW_SUCCESS: 'UPDATE_SAVED_VIEW_SUCCESS',
  UPDATE_SAVED_VIEW_FAILURE: 'UPDATE_SAVED_VIEW_FAILURE',
  DELETE_SAVED_VIEW_REQUEST: 'DELETE_SAVED_VIEW_REQUEST',
  DELETE_SAVED_VIEW_SUCCESS: 'DELETE_SAVED_VIEW_SUCCESS',
  DELETE_SAVED_VIEW_FAILURE: 'DELETE_SAVED_VIEW_FAILURE',
  FETCH_PINNED_SAVED_VIEWS_REQUEST: 'FETCH_PINNED_SAVED_VIEWS_REQUEST',
  FETCH_PINNED_SAVED_VIEWS_SUCCESS: 'FETCH_PINNED_SAVED_VIEWS_SUCCESS',
  FETCH_PINNED_SAVED_VIEWS_FAILURE: 'FETCH_PINNED_SAVED_VIEWS_FAILURE',
  FETCH_STANDARD_SAVED_VIEWS_REQUEST: 'FETCH_STANDARD_SAVED_VIEWS_REQUEST',
  FETCH_STANDARD_SAVED_VIEWS_SUCCESS: 'FETCH_STANDARD_SAVED_VIEWS_SUCCESS',
  FETCH_STANDARD_SAVED_VIEWS_FAILURE: 'FETCH_STANDARD_SAVED_VIEWS_FAILURE',
  CLEAR_SAVED_VIEWS_REQUEST: 'CLEAR_SAVED_VIEWS_REQUEST',
  FETCH_MY_SAVED_VIEWS_REQUEST: 'FETCH_MY_SAVED_VIEWS_REQUEST',
  FETCH_MY_SAVED_VIEWS_SUCCESS: 'FETCH_MY_SAVED_VIEWS_SUCCESS',
  FETCH_MY_SAVED_VIEWS_FAILURE: 'FETCH_MY_SAVED_VIEWS_FAILURE',
  FETCH_TEAMMATE_SAVED_VIEWS_REQUEST: 'FETCH_TEAMMATE_SAVED_VIEWS_REQUEST',
  FETCH_TEAMMATE_SAVED_VIEWS_SUCCESS: 'FETCH_TEAMMATE_SAVED_VIEWS_SUCCESS',
  FETCH_TEAMMATE_SAVED_VIEWS_FAILURE: 'FETCH_TEAMMATE_SAVED_VIEWS_FAILURE',
  FETCH_SEARCHED_SAVED_VIEWS_REQUEST: 'FETCH_SEARCHED_SAVED_VIEWS_REQUEST',
  FETCH_SEARCHED_SAVED_VIEWS_SUCCESS: 'FETCH_SEARCHED_SAVED_VIEWS_SUCCESS',
  FETCH_SEARCHED_SAVED_VIEWS_FAILURE: 'FETCH_SEARCHED_SAVED_VIEWS_FAILURE',
  UPDATE_CURRENT_VIEW_UUID: 'UPDATE_CURRENT_VIEW_UUID',
  UPDATE_CURRENT_DASHBOARD_VIEW_UUID: 'UPDATE_CURRENT_DASHBOARD_VIEW_UUID',
  PIN_VIEW_REQUEST: 'PIN_VIEW_REQUEST',
  PIN_VIEW_SUCCESS: 'PIN_VIEW_SUCCESS',
  PIN_VIEW_FAILURE: 'PIN_VIEW_FAILURE',
  UNPIN_VIEW_REQUEST: 'UNPIN_VIEW_REQUEST',
  UNPIN_VIEW_SUCCESS: 'UNPIN_VIEW_SUCCESS',
  UNPIN_VIEW_FAILURE: 'UNPIN_VIEW_FAILURE',
  REORDER_PINNED_VIEWS_REQUEST: 'REORDER_PINNED_VIEWS_REQUEST',
  REORDER_PINNED_VIEWS_SUCCESS: 'REORDER_PINNED_VIEWS_SUCCESS',
  REORDER_PINNED_VIEWS_FAILURE: 'REORDER_PINNED_VIEWS_FAILURE',
  SET_SELECTED_SAVED_VIEW: 'SET_SELECTED_SAVED_VIEW',
  UPDATE_SAVED_VIEW_HELP_OPEN: 'UPDATE_SAVED_VIEW_HELP_OPEN',
  SET_ALERTS_MODAL_STATE: 'SET_ALERTS_MODAL_STATE',

  SUBSCRIBE_SAVED_VIEW_REQUEST: 'SUBSCRIBE_SAVED_VIEW_REQUEST',
  SUBSCRIBE_SAVED_VIEW_SUCCESS: 'SUBSCRIBE_SAVED_VIEW_SUCCESS',
  SUBSCRIBE_SAVED_VIEW_FAILURE: 'SUBSCRIBE_SAVED_VIEW_FAILURE',
  UNSUBSCRIBE_SAVED_VIEW_REQUEST: 'UNSUBSCRIBE_SAVED_VIEW_REQUEST',
  UNSUBSCRIBE_SAVED_VIEW_SUCCESS: 'UNSUBSCRIBE_SAVED_VIEW_SUCCESS',
  UNSUBSCRIBE_SAVED_VIEW_FAILURE: 'UNSUBSCRIBE_SAVED_VIEW_FAILURE',

  CREATE_SLACK_ALERT_VIEW: 'CREATE_SLACK_ALERT_VIEW',
  REMOVE_SLACK_ALERT_VIEW: 'REMOVE_SLACK_ALERT_VIEW',

  UPDATE_SAVED_VIEW_SUB_VIEWS_REQUEST: 'UPDATE_SAVED_VIEW_SUB_VIEWS_REQUEST'
};

export const UserActions = {
  SET_NOTIFICATION_REQUEST: 'SET_NOTIFICATION_REQUEST',
  SET_NOTIFICATION_FAILURE: 'SET_NOTIFICATION_FAILURE',
  SET_NOTIFICATION_SUCCESS: 'SET_NOTIFICATION_SUCCESS',
  FETCH_CALENDAR_STATE_SUCCESS: 'FETCH_CALENDAR_STATE_SUCCESS',
  FETCH_CALENDAR_STATE_FAILURE: 'FETCH_CALENDAR_STATE_FAILURE',
  FETCH_ZOOM_SETTINGS: 'FETCH_ZOOM_SETTINGS',
  UPDATE_ZOOM_SETTINGS: 'UPDATE_ZOOM_SETTINGS',
  UPDATE_USER_ORIGIN: 'UPDATE_USER_ORIGIN',

  UPDATE_ZOOM_NATIVE_SETTINGS_REQUEST: 'UPDATE_ZOOM_NATIVE_SETTINGS_REQUEST',
  UPDATE_ZOOM_NATIVE_SETTINGS_SUCCESS: 'UPDATE_ZOOM_NATIVE_SETTINGS_SUCCESS',
  UPDATE_ZOOM_NATIVE_SETTINGS_ERROR: 'UPDATE_ZOOM_NATIVE_SETTINGS_ERROR',

  PATCH_RECORDING_SETTINGS_REQUEST: 'PATCH_RECORDING_SETTINGS_REQUEST',
  PATCH_RECORDING_SETTINGS_SUCCESS: 'PATCH_RECORDING_SETTINGS_SUCCESS',
  PATCH_RECORDING_SETTINGS_FAILURE: 'PATCH_RECORDING_SETTINGS_FAILURE',

  REFRESH_ZOOM_USER_SUCCESS: 'REFRESH_ZOOM_USER_SUCCESS',

  FETCH_USER_VOICEPRINT_REQUEST: 'FETCH_USER_VOICEPRINT_REQUEST',
  FETCH_USER_VOICEPRINT_SUCCESS: 'FETCH_USER_VOICEPRINT_SUCCESS',
  FETCH_USER_VOICEPRINT_FAILURE: 'FETCH_USER_VOICEPRINT_FAILURE',

  CREATE_USER_VOICEPRINT_REQUEST: 'CREATE_USER_VOICEPRINT_REQUEST',
  CREATE_USER_VOICEPRINT_SUCCESS: 'CREATE_USER_VOICEPRINT_SUCCESS',
  CREATE_USER_VOICEPRINT_FAILURE: 'CREATE_USER_VOICEPRINT_FAILURE',

  FECTH_MS_EXCHANGE_CONFIG_REQUEST: 'FETCH_MS_EXCHANGE_CONFIG_REQUEST',
  FECTH_MS_EXCHANGE_CONFIG_SUCCESS: 'FETCH_MS_EXCHANGE_CONFIG_SUCCESS',
  FECTH_MS_EXCHANGE_CONFIG_FAILURE: 'FETCH_MS_EXCHANGE_CONFIG_FAILURE',

  UPDATE_MS_EXCHANGE_CONFIG_REQUEST: 'UPDATE_MS_EXCHANGE_CONFIG_REQUEST',
  UPDATE_MS_EXCHANGE_CONFIG_SUCCESS: 'UPDATE_MS_EXCHANGE_CONFIG_SUCCESS',
  UPDATE_MS_EXCHANGE_CONFIG_FAILURE: 'UPDATE_MS_EXCHANGE_CONFIG_FAILURE',

  DELETE_MS_EXCHANGE_CONFIG_REQUEST: 'DELETE_MS_EXCHANGE_CONFIG_REQUEST',
  DELETE_MS_EXCHANGE_CONFIG_SUCCESS: 'DELETE_MS_EXCHANGE_CONFIG_SUCCESS',
  DELETE_MS_EXCHANGE_CONFIG_FAILURE: 'DELETE_MS_EXCHANGE_CONFIG_FAILURE',

  FETCH_CAN_USER_EDIT_DEALS_REQUEST: 'FETCH_CAN_USER_EDIT_DEALS_REQUEST',
  FETCH_CAN_USER_EDIT_DEALS_SUCCESS: 'FETCH_CAN_USER_EDIT_DEALS_SUCCESS',
  FETCH_CAN_USER_EDIT_DEALS_FAILURE: 'FETCH_CAN_USER_EDIT_DEALS_FAILURE',

  FETCH_USER_SUBSCRIPTION_LIMITS_REQUEST:
    'FETCH_USER_SUBSCRIPTION_LIMITS_REQUEST',
  FETCH_USER_SUBSCRIPTION_LIMITS_SUCCESS:
    'FETCH_USER_SUBSCRIPTION_LIMITS_SUCCESS',
  FETCH_USER_SUBSCRIPTION_LIMITS_FAILURE:
    'FETCH_USER_SUBSCRIPTION_LIMITS_FAILURE',

  ENABLE_CLICKUP_INT_REQUEST: 'ENABLE_CLICKUP_INT_REQUEST',
  ENABLE_CLICKUP_INT_SUCCESS: 'ENABLE_CLICKUP_INT_SUCCESS',
  ENABLE_CLICKUP_INT_FAILURE: 'ENABLE_CLICKUP_INT_FAILURE',

  DISABLE_CLICKUP_INT_REQUEST: 'DISABLE_CLICKUP_INT_REQUEST',
  DISABLE_CLICKUP_INT_SUCCESS: 'DISABLE_CLICKUP_INT_SUCCESS',
  DISABLE_CLICKUP_INT_FAILURE: 'DISABLE_CLICKUP_INT_FAILURE',

  UPDATE_ONBOARDING_STEPS: 'UPDATE_ONBOARDING_STEPS'
};

export const UsersActions = {
  FETCH_ACTIVE_USERS_REQUEST: 'FETCH_ACTIVE_USERS_REQUEST',
  FETCH_ACTIVE_USERS_SUCCESS: 'FETCH_ACTIVE_USERS_SUCCESS',
  FETCH_ACTIVE_USERS_FAILURE: 'FETCH_ACTIVE_USERS_FAILURE',
  FETCH_INACTIVE_USERS_REQUEST: 'FETCH_INACTIVE_USERS_REQUEST',
  FETCH_INACTIVE_USERS_SUCCESS: 'FETCH_INACTIVE_USERS_SUCCESS',
  FETCH_INACTIVE_USERS_FAILURE: 'FETCH_INACTIVE_USERS_FAILURE',
  FETCH_INVITED_USERS_REQUEST: 'FETCH_INVITED_USERS_REQUEST',
  FETCH_INVITED_USERS_SUCCESS: 'FETCH_INVITED_USERS_SUCCESS',
  FETCH_INVITED_USERS_FAILURE: 'FETCH_INVITED_USERS_FAILURE',
  FETCH_ACTIVE_USERS_MINIFIED_REQUEST: 'FETCH_ACTIVE_USERS_MINIFIED_REQUEST',
  FETCH_ACTIVE_USERS_MINIFIED_SUCCESS: 'FETCH_ACTIVE_USERS_MINIFIED_SUCCESS',
  FETCH_ACTIVE_USERS_MINIFIED_FAILURE: 'FETCH_ACTIVE_USERS_MINIFIED_FAILURE',
  FETCH_MINIFIED_RECENT_MEETINGS_DATA_SUCCESS:
    'FETCH_MINIFIED_RECENT_MEETINGS_DATA_SUCCESS',
  FETCH_USERS_MINIFIED_UPDATE_DATA_SUCCESS:
    'FETCH_USERS_MINIFIED_UPDATE_DATA_SUCCESS',
  FETCH_INACTIVE_USERS_MINIFIED_REQUEST:
    'FETCH_INACTIVE_USERS_MINIFIED_REQUEST',
  FETCH_INACTIVE_USERS_MINIFIED_SUCCESS:
    'FETCH_INACTIVE_USERS_MINIFIED_SUCCESS',
  FETCH_INACTIVE_USERS_MINIFIED_FAILURE:
    'FETCH_INACTIVE_USERS_MINIFIED_FAILURE',
  FETCH_INVITED_USERS_MINIFIED_REQUEST: 'FETCH_INVITED_USERS_MINIFIED_REQUEST',
  FETCH_INVITED_USERS_MINIFIED_SUCCESS: 'FETCH_INVITED_USERS_MINIFIED_SUCCESS',
  FETCH_INVITED_USERS_MINIFIED_FAILURE: 'FETCH_INVITED_USERS_MINIFIED_FAILURE',
  FETCH_USERS_BY_SEARCH_REQUEST: 'FETCH_USERS_BY_SEARCH_REQUEST',
  FETCH_USERS_BY_SEARCH_SUCCESS: 'FETCH_USERS_BY_SEARCH_SUCCESS',
  FETCH_USERS_BY_SEARCH_FAILURE: 'FETCH_USERS_BY_SEARCH_FAILURE',
  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  REACTIVATE_USER_REQUEST: 'REACTIVATE_USER_REQUEST',
  REACTIVATE_USER_SUCCESS: 'REACTIVATE_USER_SUCCESS',
  REACTIVATE_USER_FAILURE: 'REACTIVATE_USER_FAILURE',
  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',
  INVITE_USER_REQUEST: 'INVITE_USER_REQUEST',
  INVITE_USER_SUCCESS: 'INVITE_USER_SUCCESS',
  INVITE_USER_FAILURE: 'INVITE_USER_FAILURE',
  SET_USERS_SEARCH_TEXT: 'SET_USERS_SEARCH_TEXT',
  SET_MEMBER_PAGE_FILTERING: 'SET_MEMBER_PAGE_FILTERING',
  RESET_MEMBER_PAGE_FILTERING: 'RESET_MEMBER_PAGE_FILTERING',
  FETCH_ORGANIZATION_USERS_REQUEST: 'FETCH_ORGANIZATION_USERS_REQUEST',
  FETCH_ORGANIZATION_USERS_SUCCESS: 'FETCH_ORGANIZATION_USERS_SUCCESS',
  FETCH_ORGANIZATION_USERS_FAILURE: 'FETCH_ORGANIZATION_USERS_FAILURE',
  RESEND_INVITE_USER_REQUEST: 'RESEND_INVITE_USER_REQUEST',
  RESEND_INVITE_USER_SUCCESS: 'RESEND_INVITE_USER_SUCCESS',
  RESEND_INVITE_USER_FAILURE: 'RESEND_INVITE_USER_FAILURE',
  DELETE_INVITED_USER_REQUEST: 'DELETE_INVITED_USER_REQUEST',
  DELETE_INVITED_USER_SUCCESS: 'DELETE_INVITED_USER_SUCCESS',
  DELETE_INVITED_USER_FAILURE: 'DELETE_INVITED_USER_FAILURE',
  UPDATE_USER_SETTINGS_PREFERENCE_REQUEST:
    'UPDATE_USER_SETTINGS_PREFERENCE_REQUEST',
  UPDATE_USER_SETTINGS_PREFERENCE_SUCCESS:
    'UPDATE_USER_SETTINGS_PREFERENCE_SUCCESS',
  UPDATE_USER_SETTINGS_PREFERENCE_FAILURE:
    'UPDATE_USER_SETTINGS_PREFERENCE_FAILURE',
  UPDATE_USER_SETTINGS_PRIVACY_PREFERENCE_REQUEST:
    'UPDATE_USER_SETTINGS_PRIVACY_PREFERENCE_REQUEST',
  UPDATE_USER_SETTINGS_PRIVACY_PREFERENCE_SUCCESS:
    'UPDATE_USER_SETTINGS_PRIVACY_PREFERENCE_SUCCESS',
  UPDATE_USER_SETTINGS_PRIVACY_PREFERENCE_FAILURE:
    'UPDATE_USER_SETTINGS_PRIVACY_PREFERENCE_FAILURE',
  FETCH_USER_DEFAULT_TEMPLATE_REQUEST: 'FETCH_USER_DEFAULT_TEMPLATE_REQUEST',
  FETCH_USER_DEFAULT_TEMPLATE_SUCCESS: 'FETCH_USER_DEFAULT_TEMPLATE_SUCCESS',
  FETCH_USER_DEFAULT_TEMPLATE_FAILURE: 'FETCH_USER_DEFAULT_TEMPLATE_FAILURE'
};

export const AuthActions = {
  SEND_MICROSOFT_AUTH_CODE: 'SEND_MICROSOFT_AUTH_CODE',
  SEND_GOOGLE_AUTH_CODE: 'SEND_GOOGLE_AUTH_CODE',
  ACCOUNT_CREATED: 'SIGNUP',
  ACCOUNT_LOGIN: 'LOGIN',
  CATEGORY: 'Authentication'
};

export const SettingActions = {
  ENABLE_ZOOM_INTEGRATION: 'ENABLE_ZOOM_INTEGRATION',
  ENABLE_ZOOM_INTEGRATION_FAILURE: 'ENABLE_ZOOM_INTEGRATION_FAILURE',
  ENABLE_AIRCALL_INTEGRATION: 'ENABLE_AIRCALL_INTEGRATION',
  ENABLE_AIRCALL_INTEGRATION_FAILURE: 'ENABLE_AIRCALL_INTEGRATION_FAILURE',
  ENABLE_HUBSPOT_INTEGRATION_REQUEST: 'ENABLE_HUBSPOT_INTEGRATION_REQUEST',
  ENABLE_HUBSPOT_INTEGRATION_SUCCESS: 'ENABLE_HUBSPOT_INTEGRATION_SUCCESS',
  ENABLE_HUBSPOT_INTEGRATION_FAILURE: 'ENABLE_HUBSPOT_INTEGRATION_FAILURE',
  ENABLE_PIPEDRIVE_INTEGRATION_REQUEST: 'ENABLE_PIPEDRIVE_INTEGRATION_REQUEST',
  ENABLE_PIPEDRIVE_INTEGRATION_SUCCESS: 'ENABLE_PIPEDRIVE_INTEGRATION_SUCCESS',
  ENABLE_PIPEDRIVE_INTEGRATION_FAILURE: 'ENABLE_PIPEDRIVE_INTEGRATION_FAILURE',
  ENABLE_ZOHO_INTEGRATION_REQUEST: 'ENABLE_ZOHO_INTEGRATION_REQUEST',
  ENABLE_ZOHO_INTEGRATION_SUCCESS: 'ENABLE_ZOHO_INTEGRATION_SUCCESS',
  ENABLE_ZOHO_INTEGRATION_FAILURE: 'ENABLE_ZOHO_INTEGRATION_FAILURE',
  DISABLE_ZOOM_INTEGRATION_REQUEST: 'DISABLE_ZOOM_INTEGRATION_REQUEST',
  DISABLE_ZOOM_INTEGRATION_SUCCESS: 'DISABLE_ZOOM_INTEGRATION_SUCCESS',
  DISABLE_ZOOM_INTEGRATION_FAILURE: 'DISABLE_ZOOM_INTEGRATION_FAILURE',
  ENABLE_ZOOM_PHONE_INT_REQUEST: 'ENABLE_ZOOM_PHONE_INT_REQUEST',
  ENABLE_ZOOM_PHONE_INT_SUCCESS: 'ENABLE_ZOOM_PHONE_INT_SUCCESS',
  ENABLE_ZOOM_PHONE_INT_FAILURE: 'ENABLE_ZOOM_PHONE_INT_FAILURE',
  DISABLE_ZOOM_PHONE_INT_REQUEST: 'DISABLE_ZOOM_PHONE_INT_REQUEST',
  DISABLE_ZOOM_PHONE_INT_SUCCESS: 'DISABLE_ZOOM_PHONE_INT_SUCCESS',
  DISABLE_ZOOM_PHONE_INT_FAILURE: 'DISABLE_ZOOM_PHONE_INT_FAILURE',
  DISABLE_AIRCALL_INTEGRATION_REQUEST: 'DISABLE_AIRCALL_INTEGRATION_REQUEST',
  DISABLE_AIRCALL_INTEGRATION_SUCCESS: 'DISABLE_AIRCALL_INTEGRATION_SUCCESS',
  DISABLE_AIRCALL_INTEGRATION_FAILURE: 'DISABLE_AIRCALL_INTEGRATION_FAILURE',
  ENABLE_AIRCALL_SYNC_TAGS_REQUEST: 'ENABLE_AIRCALL_SYNC_TAGS_REQUEST',
  ENABLE_AIRCALL_SYNC_TAGS_SUCCESS: 'ENABLE_AIRCALL_SYNC_TAGS_SUCCESS',
  ENABLE_AIRCALL_SYNC_TAGS_FAILURE: 'ENABLE_AIRCALL_SYNC_TAGS_FAILURE',
  DISABLE_CONNECT_LEADER_INTEGRATION_REQUEST:
    'DISABLE_CONNECT_LEADER_INTEGRATION_REQUEST',
  DISABLE_CONNECT_LEADER_INTEGRATION_FAILURE:
    'DISABLE_CONNECT_LEADER_INTEGRATION_FAILURE',
  DISABLE_CONNECT_LEADER_INTEGRATION_SUCCESS:
    'DISABLE_CONNECT_LEADER_INTEGRATION_SUCCESS',
  ENABLE_CONNECT_LEADER_INTEGRATION: 'ENABLE_CONNECT_LEADER_INTEGRATION',
  ENABLE_CONNECT_LEADER_INTEGRATION_FAILURE:
    'ENABLE_CONNECT_LEADER_INTEGRATION_FAILURE',

  ENABLE_RINGCENTRAL_INTEGRATION_REQUEST:
    'ENABLE_RINGCENTRAL_INTEGRATION_REQUEST',
  ENABLE_RINGCENTRAL_INTEGRATION_SUCCESS:
    'ENABLE_RINGCENTRAL_INTEGRATION_SUCCESS',
  ENABLE_RINGCENTRAL_INTEGRATION_FAILURE:
    'ENABLE_RINGCENTRAL_INTEGRATION_FAILURE',
  DISABLE_RINGCENTRAL_INTEGRATION_REQUEST:
    'DISABLE_RINGCENTRAL_INTEGRATION_REQUEST',
  DISABLE_RINGCENTRAL_INTEGRATION_SUCCESS:
    'DISABLE_RINGCENTRAL_INTEGRATION_SUCCESS',
  DISABLE_RINGCENTRAL_INTEGRATION_FAILURE:
    'DISABLE_RINGCENTRAL_INTEGRATION_FAILURE',
  // Salesloft
  DISABLE_SALESLOFT_INTEGRATION_REQUEST:
    'DISABLE_SALESLOFT_INTEGRATION_REQUEST',
  DISABLE_SALESLOFT_INTEGRATION_FAILURE:
    'DISABLE_SALESLOFT_INTEGRATION_FAILURE',
  DISABLE_SALESLOFT_INTEGRATION_SUCCESS:
    'DISABLE_SALESLOFT_INTEGRATION_SUCCESS',
  ENABLE_SALESLOFT_INTEGRATION_SUCCESS: 'ENABLE_SALESLOFT_INTEGRATION_SUCCESS',
  ENABLE_SALESLOFT_INTEGRATION_FAILURE: 'ENABLE_SALESLOFT_INTEGRATION_FAILURE',
  ENABLE_SALESLOFT_INTEGRATION_REQUEST: 'ENABLE_SALESLOFT_INTEGRATION_REQUEST',

  // Kixie
  DISABLE_KIXIE_INTEGRATION_REQUEST: 'DISABLE_KIXIE_INTEGRATION_REQUEST',
  DISABLE_KIXIE_INTEGRATION_FAILURE: 'DISABLE_KIXIE_INTEGRATION_FAILURE',
  DISABLE_KIXIE_INTEGRATION_SUCCESS: 'DISABLE_KIXIE_INTEGRATION_SUCCESS',
  ENABLE_KIXIE_INTEGRATION_SUCCESS: 'ENABLE_KIXIE_INTEGRATION_SUCCESS',
  ENABLE_KIXIE_INTEGRATION_FAILURE: 'ENABLE_KIXIE_INTEGRATION_FAILURE',
  ENABLE_KIXIE_INTEGRATION_REQUEST: 'ENABLE_KIXIE_INTEGRATION_REQUEST',

  // Gotoconnect
  ENABLE_GOTOCONNECT_INTEGRATION_REQUEST:
    'ENABLE_GOTOCONNECT_INTEGRATION_REQUEST',
  ENABLE_GOTOCONNECT_INTEGRATION_SUCCESS:
    'ENABLE_GOTOCONNECT_INTEGRATION_SUCCESS',
  ENABLE_GOTOCONNECT_INTEGRATION_FAILURE:
    'ENABLE_GOTOCONNECT_INTEGRATION_FAILURE',
  DISABLE_GOTOCONNECT_INTEGRATION_REQUEST:
    'DISABLE_GOTOCONNECT_INTEGRATION_REQUEST',
  DISABLE_GOTOCONNECT_INTEGRATION_SUCCESS:
    'DISABLE_GOTOCONNECT_INTEGRATION_SUCCESS',
  DISABLE_GOTOCONNECT_INTEGRATION_FAILURE:
    'DISABLE_GOTOCONNECT_INTEGRATION_FAILURE',

  // Vonage
  ENABLE_VONAGE_INTEGRATION_REQUEST: 'ENABLE_VONAGE_INTEGRATION_REQUEST',
  ENABLE_VONAGE_INTEGRATION_SUCCESS: 'ENABLE_VONAGE_INTEGRATION_SUCCESS',
  ENABLE_VONAGE_INTEGRATION_FAILURE: 'ENABLE_VONAGE_INTEGRATION_FAILURE',
  DISABLE_VONAGE_INTEGRATION_REQUEST: 'DISABLE_VONAGE_INTEGRATION_REQUEST',
  DISABLE_VONAGE_INTEGRATION_SUCCESS: 'DISABLE_VONAGE_INTEGRATION_SUCCESS',
  DISABLE_VONAGE_INTEGRATION_FAILURE: 'DISABLE_VONAGE_INTEGRATION_FAILURE',

  TOGGLE_AUTO_SYNC_CRM_NOTES_REQUEST: 'TOGGLE_AUTO_SYNC_CRM_NOTES_REQUEST',
  TOGGLE_AUTO_SYNC_CRM_NOTES_SUCCESS: 'TOGGLE_AUTO_SYNC_CRM_NOTES_SUCCESS',
  TOGGLE_AUTO_SYNC_CRM_NOTES_FAILURE: 'TOGGLE_AUTO_SYNC_CRM_NOTES_FAILURE',

  UPDATE_USER_SETTINGS_REQUEST: 'UPDATE_USER_SETTINGS_REQUEST',
  UPDATE_USER_SETTINGS_SUCCESS: 'UPDATE_USER_SETTINGS_SUCCESS',
  UPDATE_USER_SETTINGS_FAILURE: 'UPDATE_USER_SETTINGS_FAILURE',

  FETCH_REFERRALS_REQUEST: 'FETCH_REFERRALS_REQUEST',
  FETCH_REFERRALS_SUCCESS: 'FETCH_REFERRALS_SUCCESS',
  FETCH_REFERRALS_FAILURE: 'FETCH_REFERRALS_FAILURE',

  // User Level Custom Reminders
  FETCH_USER_CUSTOM_REMINDERS_REQUEST: 'FETCH_USER_CUSTOM_REMINDERS_REQUEST',
  FETCH_USER_CUSTOM_REMINDERS_SUCCESS: 'FETCH_USER_CUSTOM_REMINDERS_SUCCESS',
  FETCH_USER_CUSTOM_REMINDERS_FAILURE: 'FETCH_USER_CUSTOM_REMINDERS_FAILURE',

  UPDATE_USER_CUSTOM_REMINDERS_REQUEST: 'UPDATE_USER_CUSTOM_REMINDERS_REQUEST',
  UPDATE_USER_CUSTOM_REMINDERS_SUCCESS: 'UPDATE_USER_CUSTOM_REMINDERS_SUCCESS',
  UPDATE_USER_CUSTOM_REMINDERS_FAILURE: 'UPDATE_USER_CUSTOM_REMINDERS_FAILURE',

  INSTALL_ZOOM_APP_REQUEST: 'INSTALL_ZOOM_APP_REQUEST',
  INSTALL_ZOOM_APP_SUCCESS: 'INSTALL_ZOOM_APP_SUCCESS',
  INSTALL_ZOOM_APP_ERROR: 'INSTALL_ZOOM_APP_ERROR',

  UNINSTALL_ZOOM_APP_REQUEST: 'UNINSTALL_ZOOM_APP_REQUEST',
  UNINSTALL_ZOOM_APP_SUCCESS: 'UNINSTALL_ZOOM_APP_SUCCESS',
  UNINSTALL_ZOOM_APP_ERROR: 'UNINSTALL_ZOOM_APP_ERROR',

  // Zoom Meeting Sdk
  INSTALL_ZOOM_SDK_REQUEST: 'INSTALL_ZOOM_SDK_REQUEST',
  INSTALL_ZOOM_SDK_SUCCESS: 'INSTALL_ZOOM_SDK_SUCCESS',
  INSTALL_ZOOM_SDK_ERROR: 'INSTALL_ZOOM_SDK_ERROR',

  UNINSTALL_ZOOM_SDK_REQUEST: 'UNINSTALL_ZOOM_SDK_REQUEST',
  UNINSTALL_ZOOM_SDK_SUCCESS: 'UNINSTALL_ZOOM_SDK_SUCCESS',
  UNINSTALL_ZOOM_SDK_ERROR: 'UNINSTALL_ZOOM_SDK_ERROR',

  // Webex
  ENABLE_WEBEX_INTEGRATION_REQUEST: 'ENABLE_WEBEX_INTEGRATION_REQUEST',
  ENABLE_WEBEX_INTEGRATION_SUCCESS: 'ENABLE_WEBEX_INTEGRATION_SUCCESS',
  ENABLE_WEBEX_INTEGRATION_FAILURE: 'ENABLE_WEBEX_INTEGRATION_FAILURE',

  DISABLE_WEBEX_INTEGRATION_REQUEST: 'DISABLE_WEBEX_INTEGRATION_REQUEST',
  DISABLE_WEBEX_INTEGRATION_SUCCESS: 'DISABLE_WEBEX_INTEGRATION_SUCCESS',
  DISABLE_WEBEX_INTEGRATION_FAILURE: 'DISABLE_WEBEX_INTEGRATION_FAILURE',

  // Microsoft cloud
  ENABLE_MICROSOFT_CLOUD_INTEGRATION_REQUEST:
    'ENABLE_MICROSOFT_CLOUD_INTEGRATION_REQUEST',
  ENABLE_MICROSOFT_CLOUD_INTEGRATION_SUCCESS:
    'ENABLE_MICROSOFT_CLOUD_INTEGRATION_SUCCESS',
  ENABLE_MICROSOFT_CLOUD_INTEGRATION_FAILURE:
    'ENABLE_MICROSOFT_CLOUD_INTEGRATION_FAILURE',

  DISABLE_MICROSOFT_CLOUD_INTEGRATION_REQUEST:
    'DISABLE_MICROSOFT_CLOUD_INTEGRATION_REQUEST',
  DISABLE_MICROSOFT_CLOUD_INTEGRATION_SUCCESS:
    'DISABLE_MICROSOFT_CLOUD_INTEGRATION_SUCCESS',
  DISABLE_MICROSOFT_CLOUD_INTEGRATION_FAILURE:
    'DISABLE_MICROSOFT_CLOUD_INTEGRATION_FAILURE',

  // Google cloud recording
  ENABLE_GOOGLE_CLOUD_INTEGRATION_REQUEST:
    'ENABLE_GOOGLE_CLOUD_INTEGRATION_REQUEST',
  ENABLE_GOOGLE_CLOUD_INTEGRATION_SUCCESS:
    'ENABLE_GOOGLE_CLOUD_INTEGRATION_SUCCESS',
  ENABLE_GOOGLE_CLOUD_INTEGRATION_FAILURE:
    'ENABLE_GOOGLE_CLOUD_INTEGRATION_FAILURE',

  DISABLE_GOOGLE_CLOUD_INTEGRATION_REQUEST:
    'DISABLE_GOOGLE_CLOUD_INTEGRATION_REQUEST',
  DISABLE_GOOGLE_CLOUD_INTEGRATION_SUCCESS:
    'DISABLE_GOOGLE_CLOUD_INTEGRATION_SUCCESS',
  DISABLE_GOOGLE_CLOUD_INTEGRATION_FAILURE:
    'DISABLE_GOOGLE_CLOUD_INTEGRATION_FAILURE',

  // Outreach
  DISABLE_OUTREACH_INTEGRATION_REQUEST: 'DISABLE_OUTREACH_INTEGRATION_REQUEST',
  DISABLE_OUTREACH_INTEGRATION_SUCCESS: 'DISABLE_OUTREACH_INTEGRATION_SUCCESS',
  DISABLE_OUTREACH_INTEGRATION_FAILURE: 'DISABLE_OUTREACH_INTEGRATION_FAILURE',

  FETCH_DIALER_SETTINGS_REQUEST: 'FETCH_DIALER_SETTINGS_REQUEST',
  FETCH_DIALER_SETTINGS_SUCCESS: 'FETCH_DIALER_SETTINGS_SUCCESS',
  FETCH_DIALER_SETTINGS_FAILURE: 'FETCH_DIALER_SETTINGS_FAILURE'
};

export const MemberActions = {
  FETCH_MEMBERS_REQUEST: 'FETCH_MEMBERS_REQUEST',
  FETCH_MEMBERS_SUCCESS: 'FETCH_MEMBERS_SUCCESS',
  FETCH_MEMBERS_FAILURE: 'FETCH_MEMBERS_FAILURE',

  FETCH_ORG_SETTINGS_REQUEST: 'FETCH_ORG_SETTINGS_REQUEST',
  FETCH_ORG_SETTINGS_SUCCESS: 'FETCH_ORG_SETTINGS_SUCCESS',
  FETCH_ORG_SETTINGS_FAILURE: 'FETCH_ORG_SETTINGS_FAILURE',

  FETCH_ORGANIZATION_POLICY_REQUEST: 'FETCH_ORGANIZATION_POLICY_REQUEST',
  FETCH_ORGANIZATION_POLICY_SUCCESS: 'FETCH_ORGANIZATION_POLICY_SUCCESS',
  FETCH_ORGANIZATION_POLICY_FAILURE: 'FETCH_ORGANIZATION_POLICY_FAILURE',
  SET_ORGANIZATION_POLICY_REQUEST: 'SET_ORGANIZATION_POLICY_REQUEST',
  SET_ORGANIZATION_POLICY_SUCCESS: 'SET_ORGANIZATION_POLICY_SUCCESS',
  SET_ORGANIZATION_POLICY_FAILURE: 'SET_ORGANIZATION_POLICY_FAILURE',
  FETCH_AUDIO_PROMPT_REQUEST: 'FETCH_AUDIO_PROMPT_REQUEST',
  FETCH_AUDIO_PROMPT_SUCCESS: 'FETCH_AUDIO_PROMPT_SUCCESS',
  FETCH_AUDIO_PROMPT_FAILURE: 'FETCH_AUDIO_PROMPT_FAILURE',
  UPDATE_AUDIO_PROMPT_REQUEST: 'UPDATE_AUDIO_PROMPT_REQUEST',
  UPDATE_AUDIO_PROMPT_SUCCESS: 'UPDATE_AUDIO_PROMPT_SUCCESS',
  UPDATE_AUDIO_PROMPT_FAILURE: 'UPDATE_AUDIO_PROMPT_FAILURE',
  FETCH_ASSISTANT_NAME_REQUEST: 'FETCH_ASSISTANT_NAME_REQUEST',
  FETCH_ASSISTANT_NAME_SUCCESS: 'FETCH_ASSISTANT_NAME_SUCCESS',
  FETCH_ASSISTANT_NAME_FAILURE: 'FETCH_ASSISTANT_NAME_FAILURE',
  UPDATE_ASSISTANT_NAME_REQUEST: 'UPDATE_ASSISTANT_NAME_REQUEST',
  UPDATE_ASSISTANT_NAME_SUCCESS: 'UPDATE_ASSISTANT_NAME_SUCCESS',
  UPDATE_ASSISTANT_NAME_FAILURE: 'UPDATE_ASSISTANT_NAME_FAILURE',
  UPDATE_IMPERSONATION_CONSENT_REQUEST: 'UPDATE_IMPERSONATION_CONSENT_REQUEST',
  UPDATE_IMPERSONATION_CONSENT_SUCCESS: 'UPDATE_IMPERSONATION_CONSENT_SUCCESS',
  UPDATE_IMPERSONATION_CONSENT_FAILURE: 'UPDATE_IMPERSONATION_CONSENT_FAILURE',

  UPDATE_ORG_POLICIES_REQUEST: 'UPDATE_ORG_POLICIES_REQUEST',
  UPDATE_ORG_POLICIES_SUCCESS: 'UPDATE_ORG_POLICIES_SUCCESS',
  UPDATE_ORG_POLICIES_FAILURE: 'UPDATE_ORG_POLICIES_FAILURE',

  UPDATE_ORG_SETTINGS_REQUEST: 'UPDATE_ORG_SETTINGS_REQUEST',
  UPDATE_ORG_SETTINGS_SUCCESS: 'UPDATE_ORG_SETTINGS_SUCCESS',
  UPDATE_ORG_SETTINGS_FAILURE: 'UPDATE_ORG_SETTINGS_FAILURE',

  // Admin Level Custom Reminders
  FETCH_ADMIN_CUSTOM_REMINDERS_REQUEST: 'FETCH_ADMIN_CUSTOM_REMINDERS_REQUEST',
  FETCH_ADMIN_CUSTOM_REMINDERS_SUCCESS: 'FETCH_ADMIN_CUSTOM_REMINDERS_SUCCESS',
  FETCH_ADMIN_CUSTOM_REMINDERS_FAILURE: 'FETCH_ADMIN_CUSTOM_REMINDERS_FAILURE',

  UPDATE_ADMIN_CUSTOM_REMINDER_REQUEST: 'UPDATE_ADMIN_CUSTOM_REMINDER_REQUEST',
  UPDATE_ADMIN_CUSTOM_REMINDER_SUCCESS: 'UPDATE_ADMIN_CUSTOM_REMINDER_SUCCESS',
  UPDATE_ADMIN_CUSTOM_REMINDER_FAILURE: 'UPDATE_ADMIN_CUSTOM_REMINDER_FAILURE',

  SET_LOCK_ADMIN_REMINDER_REQUEST: 'SET_LOCK_ADMIN_REMINDER_REQUEST',
  SET_LOCK_ADMIN_REMINDER_SUCCESS: 'SET_LOCK_ADMIN_REMINDER_SUCCESS',
  SET_LOCK_ADMIN_REMINDER_FAILURE: 'SET_LOCK_ADMIN_REMINDER_FAILURE',

  GET_LOCK_ADMIN_REMINDER_REQUEST: 'GET_LOCK_ADMIN_REMINDER_REQUEST',
  GET_LOCK_ADMIN_REMINDER_SUCCESS: 'GET_LOCK_ADMIN_REMINDER_SUCCESS',
  GET_LOCK_ADMIN_REMINDER_FAILURE: 'GET_LOCK_ADMIN_REMINDER_FAILURE',

  // temporary dispatch to fix stale data being in the members slice when users slice has a deleted member
  REMOVE_INVITED_MEMBER: 'REMOVE_INVITED_MEMBER',

  ENABLE_SLACK_INT_REQUEST: 'ENABLE_SLACK_INT_REQUEST',
  ENABLE_SLACK_INT_SUCCESS: 'ENABLE_SLACK_INT_SUCCESS',
  ENABLE_SLACK_INT_FAILURE: 'ENABLE_SLACK_INT_FAILURE',

  DISABLE_SLACK_INT_REQUEST: 'DISABLE_SLACK_INT_REQUEST',
  DISABLE_SLACK_INT_SUCCESS: 'DISABLE_SLACK_INT_SUCCESS',
  DISABLE_SLACK_INT_FAILURE: 'DISABLE_SLACK_INT_FAILURE',

  FETCH_ORGANIZATION_DISABLED_SHARING_EMAILS_REQUEST:
    'FETCH_ORGANIZATION_DISABLED_SHARING_EMAILS_REQUEST',
  FETCH_ORGANIZATION_DISABLED_SHARING_EMAILS_SUCCESS:
    'FETCH_ORGANIZATION_DISABLED_SHARING_EMAILS_SUCCESS',
  FETCH_ORGANIZATION_DISABLED_SHARING_EMAILS_FAILURE:
    'FETCH_ORGANIZATION_DISABLED_SHARING_EMAILS_FAILURE'
};

export const AccountActions = {
  LOOKUP_ACCOUNTS_REQUEST: 'LOOKUP_ACCOUNTS_REQUEST',
  LOOKUP_ACCOUNTS_SUCCESS: 'LOOKUP_ACCOUNTS_SUCCESS',
  LOOKUP_ACCOUNTS_FAILURE: 'LOOKUP_ACCOUNTS_FAILURE'
};
export const CustomCategoryActions = {
  FETCH_CUSTOM_CATEGORY_REQUEST: 'FETCH_CUSTOM_CATEGORY_REQUEST',
  FETCH_CUSTOM_CATEGORY_SUCCESS: 'FETCH_CUSTOM_CATEGORY_SUCCESS',
  FETCH_CUSTOM_CATEGORY_FAILURE: 'FETCH_CUSTOM_CATEGORY_FAILURE',
  PATCH_CUSTOM_CATEGORY_REQUEST: 'PATCH_CUSTOM_CATEGORY_REQUEST',
  PATCH_CUSTOM_CATEGORY_SUCCESS: 'PATCH_CUSTOM_CATEGORY_SUCCESS',
  PATCH_CUSTOM_CATEGORY_ERROR: 'PATCH_CUSTOM_CATEGORY_ERROR',
  CREATE_CUSTOM_CATEGORY_REQUEST: 'CREATE_CUSTOM_CATEGORY_REQUEST',
  CREATE_CUSTOM_CATEGORY_SUCCESS: 'CREATE_CUSTOM_CATEGORY_SUCCESS',
  CREATE_CUSTOM_CATEGORY_FAILURE: 'CREATE_CUSTOM_CATEGORY_FAILURE',
  DELETE_CUSTOM_CATEGORY_REQUEST: 'DELETE_CUSTOM_CATEGORY_REQUEST',
  DELETE_CUSTOM_CATEGORY_SUCCESS: 'DELETE_CUSTOM_CATEGORY_SUCCESS',
  DELETE_CUSTOM_CATEGORY_FAILURE: 'DELETE_CUSTOM_CATEGORY_FAILURE',
  REORDER_CUSTOM_CATEGORY_REQUEST: 'REORDER_CUSTOM_CATEGORY_REQUEST',
  REORDER_CUSTOM_CATEGORY_SUCCESS: 'REORDER_CUSTOM_CATEGORY_SUCCESS',
  REORDER_CUSTOM_CATEGORY_FAILURE: 'REORDER_CUSTOM_CATEGORY_FAILURE',
  CREATE_CUSTOM_KEYWORD_REQUEST: 'CREATE_CUSTOM_KEYWORD_REQUEST',
  CREATE_CUSTOM_KEYWORD_SUCCESS: 'CREATE_CUSTOM_KEYWORD_SUCCESS',
  CREATE_CUSTOM_KEYWORD_FAILURE: 'CREATE_CUSTOM_KEYWORD_FAILURE',
  DELETE_CUSTOM_KEYWORD_REQUEST: 'DELETE_CUSTOM_KEYWORD_REQUEST',
  DELETE_CUSTOM_KEYWORD_SUCCESS: 'DELETE_CUSTOM_KEYWORD_SUCCESS',
  DELETE_CUSTOM_KEYWORD_FAILURE: 'DELETE_CUSTOM_KEYWORD_FAILURE',
  CREATE_KEYWORD_VARIATION_REQUEST: 'CREATE_KEYWORD_VARIATION_REQUEST',
  CREATE_KEYWORD_VARIATION_SUCCESS: 'CREATE_KEYWORD_VARIATION_SUCCESS',
  CREATE_KEYWORD_VARIATION_FAILURE: 'CREATE_KEYWORD_VARIATION_FAILURE',
  DELETE_KEYWORD_VARIATION_REQUEST: 'DELETE_KEYWORD_VARIATION_REQUEST',
  DELETE_KEYWORD_VARIATION_SUCCESS: 'DELETE_KEYWORD_VARIATION_SUCCESS',
  DELETE_KEYWORD_VARIATION_FAILURE: 'DELETE_KEYWORD_VARIATION_FAILURE',
  UPDATE_KEYWORD_LABEL_REQUEST: 'UPDATE_KEYWORD_LABEL_REQUEST',
  UPDATE_KEYWORD_LABEL_SUCCESS: 'UPDATE_KEYWORD_LABEL_SUCCESS',
  UPDATE_KEYWORD_LABEL_FAILURE: 'UPDATE_KEYWORD_LABEL_FAILURE',
  MAKE_KEYWORD_VARIATION_PRIMARY_REQUEST:
    'MAKE_KEYWORD_VARIATION_PRIMARY_REQUEST',
  MAKE_KEYWORD_VARIATION_PRIMARY_SUCCESS:
    'MAKE_KEYWORD_VARIATION_PRIMARY_SUCCESS',
  MAKE_KEYWORD_VARIATION_PRIMARY_FAILURE:
    'MAKE_KEYWORD_VARIATION_PRIMARY_FAILURE',
  CREATE_TRIGGER_PROMPT_REQUEST: 'CREATE_TRIGGER_PROMPT_REQUEST',
  CREATE_TRIGGER_PROMPT_SUCCESS: 'CREATE_TRIGGER_PROMPT_SUCCESS',
  CREATE_TRIGGER_PROMPT_FAILURE: 'CREATE_TRIGGER_PROMPT_FAILURE',
  DELETE_TRIGGER_PROMPT_REQUEST: 'DELETE_TRIGGER_PROMPT_REQUEST',
  DELETE_TRIGGER_PROMPT_SUCCESS: 'DELETE_TRIGGER_PROMPT_SUCCESS',
  DELETE_TRIGGER_PROMPT_FAILURE: 'DELETE_TRIGGER_PROMPT_FAILURE',
  CREATE_TRIGGER_PROMPT_VARIATION_REQUEST:
    'CREATE_TRIGGER_PROMPT_VARIATION_REQUEST',
  CREATE_TRIGGER_PROMPT_VARIATION_SUCCESS:
    'CREATE_TRIGGER_PROMPT_VARIATION_SUCCESS',
  CREATE_TRIGGER_PROMPT_VARIATION_FAILURE:
    'CREATE_TRIGGER_PROMPT_VARIATION_FAILURE',
  DELETE_TRIGGER_PROMPT_VARIATION_REQUEST:
    'DELETE_TRIGGER_PROMPT_VARIATION_REQUEST',
  DELETE_TRIGGER_PROMPT_VARIATION_SUCCESS:
    'DELETE_TRIGGER_PROMPT_VARIATION_SUCCESS',
  DELETE_TRIGGER_PROMPT_VARIATION_FAILURE:
    'DELETE_TRIGGER_PROMPT_VARIATION_FAILURE',
  UPDATE_TRIGGER_PROMPT_LABEL_REQUEST: 'UPDATE_TRIGGER_PROMPT_LABEL_REQUEST',
  UPDATE_TRIGGER_PROMPT_LABEL_SUCCESS: 'UPDATE_TRIGGER_PROMPT_LABEL_SUCCESS',
  UPDATE_TRIGGER_PROMPT_LABEL_FAILURE: 'UPDATE_TRIGGER_PROMPT_LABEL_FAILURE',
  MAKE_TRIGGER_PROMPT_VARIATION_PRIMARY_REQUEST:
    'MAKE_TRIGGER_PROMPT_VARIATION_PRIMARY_REQUEST',
  MAKE_TRIGGER_PROMPT_VARIATION_PRIMARY_SUCCESS:
    'MAKE_TRIGGER_PROMPT_VARIATION_PRIMARY_SUCCESS',
  MAKE_TRIGGER_PROMPT_VARIATION_PRIMARY_FAILURE:
    'MAKE_TRIGGER_PROMPT_VARIATION_PRIMARY_FAILURE',
  CLEAR_VARIATION_ERROR_MESSAGE: 'CLEAR_VARIATION_ERROR_MESSAGE',

  FETCH_SINGLE_CUSTOM_CATEGORY_REQUEST: 'FETCH_SINGLE_CUSTOM_CATEGORY_REQUEST',
  FETCH_SINGLE_CUSTOM_CATEGORY_SUCCESS: 'FETCH_SINGLE_CUSTOM_CATEGORY_SUCCESS',
  FETCH_SINGLE_CUSTOM_CATEGORY_FAILURE: 'FETCH_SINGLE_CUSTOM_CATEGORY_FAILURE',

  UPDATE_CUSTOM_CATEGORY_WHITELISTED_TYPES_REQUEST:
    'UPDATE_CUSTOM_CATEGORY_WHITELISTED_TYPES_REQUEST',
  UPDATE_CUSTOM_CATEGORY_WHITELISTED_TYPES_SUCCESS:
    'UPDATE_CUSTOM_CATEGORY_WHITELISTED_TYPES_SUCCESS',
  UPDATE_CUSTOM_CATEGORY_WHITELISTED_TYPES_FAILURE:
    'UPDATE_CUSTOM_CATEGORY_WHITELISTED_TYPES_FAILURE',

  UPDATE_CUSTOM_CATEGORY_BLACKLISTED_TYPES_REQUEST:
    'UPDATE_CUSTOM_CATEGORY_BLACKLISTED_TYPES_REQUEST',
  UPDATE_CUSTOM_CATEGORY_BLACKLISTED_TYPES_SUCCESS:
    'UPDATE_CUSTOM_CATEGORY_BLACKLISTED_TYPES_SUCCESS',
  UPDATE_CUSTOM_CATEGORY_BLACKLISTED_TYPES_FAILURE:
    'UPDATE_CUSTOM_CATEGORY_BLACKLISTED_TYPES_FAILURE',

  CREATE_PHRASE_REQUEST: 'CREATE_PHRASE_REQUEST',
  CREATE_PHRASE_SUCCESS: 'CREATE_PHRASE_SUCCESS',
  CREATE_PHRASE_FAILURE: 'CREATE_PHRASE_FAILURE',

  UPDATE_PHRASE_REQUEST: 'UPDATE_PHRASE_REQUEST',
  UPDATE_PHRASE_SUCCESS: 'UPDATE_PHRASE_SUCCESS',
  UPDATE_PHRASE_FAILURE: 'UPDATE_PHRASE_FAILURE',

  DELETE_PHRASE_REQUEST: 'DELETE_PHRASE_REQUEST',
  DELETE_PHRASE_SUCCESS: 'DELETE_PHRASE_SUCCESS',
  DELETE_PHRASE_FAILURE: 'DELETE_PHRASE_FAILURE',

  GENERATE_SENTENCE_VARIANTS_REQUEST: 'GENERATE_SENTENCE_VARIANTS_REQUEST',
  GENERATE_SENTENCE_VARIANTS_SUCCESS: 'GENERATE_SENTENCE_VARIANTS_SUCCESS',
  GENERATE_SENTENCE_VARIANTS_FAILURE: 'GENERATE_SENTENCE_VARIANTS_FAILURE',

  CREATE_SMART_TOPIC_REQUEST: 'CREATE_SMART_TOPIC_REQUEST',
  CREATE_SMART_TOPIC_SUCCESS: 'CREATE_SMART_TOPIC_SUCCESS',
  CREATE_SMART_TOPIC_FAILURE: 'CREATE_SMART_TOPIC_FAILURE'
};

export const TranscriptActions = {
  BLUR_EDITOR: 'BLUR_EDITOR',
  SAVE_SCROLL_TOP: 'SAVE_TRANSCRIPT_SCROLL',
  SET_SCROLLER: 'SET_SCROLLER',
  AUTO_SCROLLER_ON: 'AUTO_SCROLLER_ON',
  AUTO_SCROLLER_OFF: 'AUTO_SCROLLER_OFF',
  OPTIMISTIC_UPDATE_FOR_TRANSLATION_COUNT:
    'OPTIMISTIC_UPDATE_FOR_TRANSLATION_COUNT'
};

export const TemplateActions = {
  SET_TEMPLATE_METADATA: 'SET_TEMPLATE_METADATA',
  UPDATE_TEMPLATE_REQUEST: 'UPDATE_TEMPLATE_REQUEST',
  UPDATE_TEMPLATE_SUCCESS: 'UPDATE_TEMPLATE_SUCCESS',
  UPDATE_TEMPLATE_FAILURE: 'UPDATE_TEMPLATE_FAILURE',
  TRACK_INSERT_TEMPLATE: 'TRACK_INSERT_TEMPLATE',
  MIGRATE_DRAFT_TEMPLATE: 'MIGRATE_DRAFT_TEMPLATE',
  CREATE_SLATE_TEMPLATE_REQUEST: 'CREATE_SLATE_TEMPLATE_REQUEST',
  CREATE_SLATE_TEMPLATE_SUCCESS: 'CREATE_SLATE_TEMPLATE_SUCCESS',
  CREATE_SLATE_TEMPLATE_FAILURE: 'CREATE_SLATE_TEMPLATE_FAILURE',
  FETCH_TEMPLATE_TYPES_REQUEST: 'FETCH_TEMPLATE_TYPES_REQUEST',
  FETCH_TEMPLATE_TYPES_SUCCESS: 'FETCH_TEMPLATE_TYPES_SUCCESS',
  FETCH_TEMPLATE_TYPES_FAILURE: 'FETCH_TEMPLATE_TYPES_FAILURE',
  UPDATE_TEMPLATE_TYPE_SUCCESS: 'UPDATE_TEMPLATE_TYPE_SUCCESS',
  UPDATE_TEMPLATE_TYPE_REQUEST: 'UPDATE_TEMPLATE_TYPE_REQUEST',
  UPDATE_TEMPLATE_TYPE_FAILURE: 'UPDATE_TEMPLATE_TYPE_FAILURE',
  FETCH_MY_TEMPLATES_REQUEST: 'FETCH_MY_TEMPLATES_REQUEST',
  FETCH_MY_TEMPLATES_SUCCESS: 'FETCH_MY_TEMPLATES_SUCCESS',
  FETCH_MY_TEMPLATES_FAILURE: 'FETCH_MY_TEMPLATES_FAILURE',
  FETCH_ORGANIZATION_TEMPLATES_REQUEST: 'FETCH_ORGANIZATION_TEMPLATES_REQUEST',
  FETCH_ORGANIZATION_TEMPLATES_SUCCESS: 'FETCH_ORGANIZATION_TEMPLATES_SUCCESS',
  FETCH_ORGANIZATION_TEMPLATES_FAILURE: 'FETCH_ORGANIZATION_TEMPLATES_FAILURE',
  SET_TEMPLATES_SEARCH_TEXT: 'SET_TEMPLATES_SEARCH_TEXT',
  FETCH_ALL_TEMPLATES_REQUEST: 'FETCH_ALL_TEMPLATES_REQUEST',
  FETCH_ALL_TEMPLATES_SUCCESS: 'FETCH_ALL_TEMPLATES_SUCCESS',
  FETCH_ALL_TEMPLATES_FAILURE: 'FETCH_ALL_TEMPLATES_FAILURE',
  FETCH_AVOMA_TEMPLATES_REQUEST: 'FETCH_AVOMA_TEMPLATES_REQUEST',
  FETCH_AVOMA_TEMPLATES_SUCCESS: 'FETCH_AVOMA_TEMPLATES_SUCCESS',
  FETCH_AVOMA_TEMPLATES_FAILURE: 'FETCH_AVOMA_TEMPLATES_FAILURE',
  FETCH_DEFAULT_TEMPLATE_REQUEST: 'FETCH_DEFAULT_TEMPLATE_REQUEST',
  FETCH_DEFAULT_TEMPLATE_SUCCESS: 'FETCH_DEFAULT_TEMPLATE_SUCCESS',
  FETCH_DEFAULT_TEMPLATE_FAILURE: 'FETCH_DEFAULT_TEMPLATE_FAILURE',
  SET_TEMPLATES_VIEW: 'SET_TEMPLATES_VIEW'
};

export const MeetingActions = {
  MEETING_SUBJECT_UPDATED: 'MEETING_SUBJECT_UPDATED',
  LOOKUP_MEETINGS_REQUEST: 'LOOKUP_MEETINGS_REQUEST',
  LOOKUP_MEETINGS_SUCCESS: 'LOOKUP_MEETINGS_SUCCESS',
  LOOKUP_MEETINGS_FAILURE: 'LOOKUP_MEETINGS_FAILURE',
  DELETE_MEETING_REQUEST: 'DELETE_MEETING_REQUEST',
  DELETE_MEETING_SUCCESS: 'DELETE_MEETING_SUCCESS',
  DELETE_MEETING_FAILURE: 'DELETE_MEETING_FAILURE',

  SET_REFETCH_NEEDED: 'SET_REFETCH_NEEDED',
  FETCH_META_REQUEST: 'FETCH_MEETING_META_REQUEST',
  FETCH_META_SUCCESS: 'FETCH_MEETING_META_SUCCESS',
  FETCH_META_FAILURE: 'FETCH_MEETING_META_FAILURE',
  UPDATE_LOCAL_MEETINGS: 'UPDATE_LOCAL_MEETINGS',

  FETCH_PARTICIPANTS_REQUEST: 'FETCH_PARTICIPANTS_REQUEST',
  FETCH_PARTICIPANTS_SUCCESS: 'FETCH_PARTICIPANTS_SUCCESS',
  FETCH_PARTICIPANTS_FAILURE: 'FETCH_PARTICIPANTS_FAILURE',

  CREATE_PARTICIPANTS_REQUEST: 'CREATE_PARTICIPANTS_REQUEST',
  CREATE_PARTICIPANTS_SUCCESS: 'CREATE_PARTICIPANTS_SUCCESS',
  CREATE_PARTICIPANTS_FAILURE: 'CREATE_PARTICIPANTS_FAILURE',

  DELETE_PARTICIPANTS_REQUEST: 'DELETE_PARTICIPANTS_REQUEST',
  DELETE_PARTICIPANTS_SUCCESS: 'DELETE_PARTICIPANTS_SUCCESS',
  DELETE_PARTICIPANTS_FAILURE: 'DELETE_PARTICIPANTS_FAILURE',

  DOWNLOAD_MEETING_REQUEST: 'DOWNLOAD_MEETING_REQUEST',
  DOWNLOAD_MEETING_SUCCESS: 'DOWNLOAD_MEETING_SUCCESS',
  DOWNLOAD_MEETING_FAILURE: 'DOWNLOAD_MEETING_FAILURE',

  SHOW_SHARING_MODAL: 'SHOW_SHARING_MODAL',
  HIDE_SHARING_MODAL: 'HIDE_SHARING_MODAL',

  SHOW_CREATE_SNIPPET_MODAL: 'SHOW_CREATE_SNIPPET_MODAL',
  HIDE_CREATE_SNIPPET_MODAL: 'HIDE_CREATE_SNIPPET_MODAL',

  UPDATE_MEETING_PRIVACY_REQUEST: 'UPDATE_MEETING_PRIVACY_REQUEST',
  UPDATE_MEETING_PRIVACY_SUCCESS: 'UPDATE_MEETING_PRIVACY_SUCCESS',
  UPDATE_MEETING_PRIVACY_ERROR: 'UPDATE_MEETING_PRIVACY_ERROR',

  UPDATE_MEETING_SHARE_REQUEST: 'UPDATE_MEETING_SHARE_REQUEST',
  UPDATE_MEETING_SHARE_SUCCESS: 'UPDATE_MEETING_SHARE_SUCCESS',
  UPDATE_MEETING_SHARE_ERROR: 'UPDATE_MEETING_SHARE_ERROR',

  SHARE_MEETING_REQUEST: 'SHARE_MEETING_REQUEST',
  SHARE_MEETING_SUCCESS: 'SHARE_MEETING_SUCCESS',
  SHARE_MEETING_ERROR: 'SHARE_MEETING_ERROR',
  SHARE_MEETING_LOCAL: 'SHARE_MEETING_LOCAL',

  UNSHARE_MEETING_REQUEST: 'UNSHARE_MEETING_REQUEST',
  UNSHARE_MEETING_SUCCESS: 'UNSHARE_MEETING_SUCCESS',
  UNSHARE_MEETING_ERROR: 'UNSHARE_MEETING_ERROR',

  SHARE_MEETING_TO_SLACK_REQUEST: 'SHARE_MEETING_TO_SLACK_REQUEST',
  SHARE_MEETING_TO_SLACK_SUCCESS: 'SHARE_MEETING_TO_SLACK_SUCCESS',
  SHARE_MEETING_TO_SLACK_ERROR: 'SHARE_MEETING_TO_SLACK_ERROR',

  REQUEST_MEETING_SHARE_REQUEST: 'REQUEST_MEETING_SHARE_REQUEST',
  REQUEST_MEETING_SHARE_SUCCESS: 'REQUEST_MEETING_SHARE_SUCCESS',
  REQUEST_MEETING_SHARE_ERROR: 'REQUEST_MEETING_SHARE_ERROR',

  SET_MEETING: 'SET_MEETING',

  FETCH_MEETING_ASSOCIATED_TEMPLATE_REQUEST:
    'FETCH_MEETING_ASSOCIATED_TEMPLATE_REQUEST',
  FETCH_MEETING_ASSOCIATED_TEMPLATE_SUCCESS:
    'FETCH_MEETING_ASSOCIATED_TEMPLATE_SUCCESS',
  FETCH_MEETING_ASSOCIATED_TEMPLATE_FAILURE:
    'FETCH_MEETING_ASSOCIATED_TEMPLATE_FAILURE',
  SET_ONLINE_USERS: 'SET_ONLINE_USERS',

  ENABLE_ZOOM_CLOUD_REC_REQUEST: 'ENABLE_ZOOM_CLOUD_REC_REQUEST',
  ENABLE_ZOOM_CLOUD_REC_SUCCESS: 'ENABLE_ZOOM_CLOUD_REC_SUCCESS',
  ENABLE_ZOOM_CLOUD_REC_ERROR: 'ENABLE_ZOOM_CLOUD_REC_ERROR',

  DISABLE_ZOOM_CLOUD_REC_REQUEST: 'DISABLE_ZOOM_CLOUD_REC_REQUEST',
  DISABLE_ZOOM_CLOUD_REC_SUCCESS: 'DISABLE_ZOOM_CLOUD_REC_SUCCESS',
  DISABLE_ZOOM_CLOUD_REC_ERROR: 'DISABLE_ZOOM_CLOUD_REC_ERROR',

  ENABLE_MS_CLOUD_REC_REQUEST: 'ENABLE_MS_CLOUD_REC_REQUEST',
  ENABLE_MS_CLOUD_REC_SUCCESS: 'ENABLE_MS_CLOUD_REC_SUCCESS',
  ENABLE_MS_CLOUD_REC_ERROR: 'ENABLE_MS_CLOUD_REC_ERROR',

  DISABLE_MS_CLOUD_REC_REQUEST: 'DISABLE_MS_CLOUD_REC_REQUEST',
  DISABLE_MS_CLOUD_REC_SUCCESS: 'DISABLE_MS_CLOUD_REC_SUCCESS',
  DISABLE_MS_CLOUD_REC_ERROR: 'DISABLE_MS_CLOUD_REC_ERROR',

  FETCH_MEETING_UPLOAD_URL_REQUEST: 'FETCH_MEETING_UPLOAD_URL_REQUEST',
  FETCH_MEETING_UPLOAD_URL_SUCCESS: 'FETCH_MEETING_UPLOAD_URL_SUCCESS',
  FETCH_MEETING_UPLOAD_URL_FAILURE: 'FETCH_MEETING_UPLOAD_URL_FAILURE',

  UPLOAD_MEETING_REQUEST: 'UPLOAD_MEETING_REQUEST',
  UPLOAD_MEETING_SUCCESS: 'UPLOAD_MEETING_SUCCESS',
  UPLOAD_MEETING_FAILURE: 'UPLOAD_MEETING_FAILURE',

  FETCH_MEETING_COUNT_REQUEST: 'FETCH_MEETING_COUNT_REQUEST',
  FETCH_MEETING_COUNT_SUCCESS: 'FETCH_MEETING_COUNT_SUCCESS',
  FETCH_MEETING_COUNT_FAILURE: 'FETCH_MEETING_COUNT_FAILURE',

  ADD_PLAYLIST_TO_MEETING_REQUEST: 'ADD_PLAYLIST_TO_MEETING_REQUEST',
  ADD_PLAYLIST_TO_MEETING_SUCCESS: 'ADD_PLAYLIST_TO_MEETING_SUCCESS',
  ADD_PLAYLIST_TO_MEETING_FAILURE: 'ADD_PLAYLIST_TO_MEETING_FAILURE',

  REMOVE_PLAYLIST_FROM_MEETING_REQUEST: 'REMOVE_MEETING_FROM_PLAYLIST_REQUEST',
  REMOVE_PLAYLIST_FROM_MEETING_SUCCESS: 'REMOVE_MEETING_FROM_PLAYLIST_SUCCESS',
  REMOVE_PLAYLIST_FROM_MEETING_FAILURE: 'REMOVE_MEETING_FROM_PLAYLIST_FAILURE',

  FETCH_MEETING_ATTRIBUTED_USER_DETAILS_REQUEST:
    'FETCH_MEETING_ATTRIBUTED_USER_DETAILS_REQUEST',
  FETCH_MEETING_ATTRIBUTED_USER_DETAILS_SUCCESS:
    'FETCH_MEETING_ATTRIBUTED_USER_DETAILS_SUCCESS',
  FETCH_MEETING_ATTRIBUTED_USER_DETAILS_FAILURE:
    'FETCH_MEETING_ATTRIBUTED_USER_DETAILS_FAILURE',

  IMPROMPTU_MEETING_REQUEST: 'IMPROMPTU_MEETING_REQUEST',
  IMPROMPTU_MEETING_SUCCESS: 'IMPROMPTU_MEETING_SUCCESS',
  IMPROMPTU_MEETING_FAILURE: 'IMPROMPTU_MEETING_FAILURE',

  SET_BOT_STATE: 'SET_BOT_STATE',
  SET_BOT_STATE_SUCCESS: 'SET_BOT_STATE_SUCCESS',
  SET_BOT_STATE_FAILURE: 'SET_BOT_STATE_FAILURE',

  VIEWED_MEETING_REQUEST: 'VIEWED_MEETING_REQUEST',
  VIEWED_MEETING_SUCCESS: 'VIEWED_MEETING_SUCCESS',
  VIEWED_MEETING_FAILURE: 'VIEWED_MEETING_FAILURE',

  TOGGLE_MEETING_CALENDAR_VIEW: 'TOGGLE_MEETING_CALENDAR_VIEW',

  FETCH_CALENDAR_VIEW_MEETINGS_REQUEST: 'FETCH_CALENDAR_VIEW_MEETINGS_REQUEST',
  FETCH_CALENDAR_VIEW_MEETINGS_SUCCESS: 'FETCH_CALENDAR_VIEW_MEETINGS_SUCCESS',
  FETCH_CALENDAR_VIEW_MEETINGS_FAILURE: 'FETCH_CALENDAR_VIEW_MEETINGS_FAILURE',

  FETCH_MEETING_PURPOSE_OUTCOMES_REQUEST:
    'FETCH_MEETING_PURPOSE_OUTCOMES_REQUEST',
  FETCH_MEETING_PURPOSE_OUTCOMES_SUCCESS:
    'FETCH_MEETING_PURPOSE_OUTCOMES_SUCCESS',
  FETCH_MEETING_PURPOSE_OUTCOMES_FAILURE:
    'FETCH_MEETING_PURPOSE_OUTCOMES_FAILURE',

  FETCH_MEETING_CRM_ASSOCIATIONS_REQUEST:
    'FETCH_MEETING_CRM_ASSOCIATIONS_REQUEST',
  FETCH_MEETING_CRM_ASSOCIATIONS_SUCCESS:
    'FETCH_MEETING_CRM_ASSOCIATIONS_SUCCESS',
  FETCH_MEETING_CRM_ASSOCIATIONS_FAILURE:
    'FETCH_MEETING_CRM_ASSOCIATIONS_FAILURE',

  FETCH_MEETING_COUNTS_REQUEST: 'FETCH_MEETING_COUNTS_REQUEST',
  FETCH_MEETING_COUNTS_SUCCESS: 'FETCH_MEETING_COUNTS_SUCCESS',
  FETCH_MEETING_COUNTS_FAILURE: 'FETCH_MEETING_COUNTS_FAILURE',

  FETCH_MEETING_SHARES_DATA_REQUEST: 'FETCH_MEETING_SHARES_DATA_REQUEST',
  FETCH_MEETING_SHARES_DATA_SUCCESS: 'FETCH_MEETING_SHARES_DATA_SUCCESS',
  FETCH_MEETING_SHARES_DATA_FAILURE: 'FETCH_MEETING_SHARES_DATA_FAILURE',

  FETCH_MEETING_PLAYLIST_DATA_REQUEST: 'FETCH_MEETING_PLAYLIST_DATA_REQUEST',
  FETCH_MEETING_PLAYLIST_DATA_SUCCESS: 'FETCH_MEETING_PLAYLIST_DATA_SUCCESS',
  FETCH_MEETING_PLAYLIST_DATA_FAILURE: 'FETCH_MEETING_PLAYLIST_DATA_FAILURE',

  FETCH_MEETING_ROW_RECORDING_DATA_REQUEST:
    'FETCH_MEETING_ROW_RECORDING_DATA_REQUEST',
  FETCH_MEETING_ROW_RECORDING_DATA_SUCCESS:
    'FETCH_MEETING_ROW_RECORDING_DATA_SUCCESS',
  FETCH_MEETING_ROW_RECORDING_DATA_FAILURE:
    'FETCH_MEETING_ROW_RECORDING_DATA_FAILURE',

  FETCH_MEETING_STATES_REQUEST: 'FETCH_MEETING_STATES_REQUEST',
  FETCH_MEETING_STATES_SUCCESS: 'FETCH_MEETING_STATES_SUCCESS',
  FETCH_MEETING_STATES_FAILURE: 'FETCH_MEETING_STATES_FAILURE',

  FETCH_MEETING_CONFERENCE_DATA_REQUEST:
    'FETCH_MEETING_CONFERENCE_DATA_REQUEST',
  FETCH_MEETING_CONFERENCE_DATA_SUCCESS:
    'FETCH_MEETING_CONFERENCE_DATA_SUCCESS',
  FETCH_MEETING_CONFERENCE_DATA_FAILURE:
    'FETCH_MEETING_CONFERENCE_DATA_FAILURE',

  FETCH_MEETING_ADDITIONAL_DETAILS_REQUEST:
    'FETCH_MEETING_ADDITIONAL_DETAILS_REQUEST',
  FETCH_MEETING_ADDITIONAL_DETAILS_SUCCESS:
    'FETCH_MEETING_ADDITIONAL_DETAILS_SUCCESS',
  FETCH_MEETING_ADDITIONAL_DETAILS_FAILURE:
    'FETCH_MEETING_ADDITIONAL_DETAILS_FAILURE',
  SUGGESTIVE_ACTION_SEARCH: 'SUGGESTIVE_ACTION_SEARCH',
  GLOBAL_SEARCH_FILTERS: 'GLOBAL_SEARCH_FILTERS',

  SHOW_TRANSCRIPT_TIMESTAMP_SEARCH: 'SHOW_TRANSCRIPT_TIMESTAMP_SEARCH',

  HIDE_TRANSCRIPT_TIMESTAMP_SEARCH: 'HIDE_TRANSCRIPT_TIMESTAMP_SEARCH',

  UPDATE_CALENDAR_DATE: 'UPDATE_CALENDAR_DATE',
  UPDATE_CALENDAR_WEEK_DATE: 'UPDATE_CALENDAR_WEEK_DATE',
  UPDATE_MEETING_COUNTS: 'UPDATE_MEETING_COUNTS'
};

export const OnboardingActions = {
  UPDATE_ONBOARDING_STATE: 'UPDATE_ONBOARDING_STATE',
  UPDATE_ONBOARDING_STATE_SUCCESS: 'UPDATE_ONBOARDING_STATE_SUCCESS',
  UPDATE_ONBOARDING_STATE_FAILURE: 'UPDATE_ONBOARDING_STATE_FAILURE',
  ACCOUNT_INFO_SETUP: 'Account Info Submitted',
  ONBOARDING_DONE: 'Onboarding Done',
  CATEGORY: 'Onboarding'
};

export const NotesActions = {
  SET_SLATE_EDITOR_STATE: 'SET_SLATE_EDITOR_STATE',
  UPDATE_SLATE_NOTE_REQUEST: 'UPDATE_SLATE_NOTE_REQUEST',
  UPDATE_SLATE_NOTE_SUCCESS: 'UPDATE_SLATE_NOTE_SUCCESS',
  UPDATE_SLATE_NOTE_FAILURE: 'UPDATE_SLATE_NOTE_FAILURE',
  SET_NOTE_CURSOR_LOCATION: 'SET_NOTE_CURSOR_LOCATION',
  LOCAL_UPDATE_SLATE_NOTE: 'LOCAL_UPDATE_SLATE_NOTE',
  SET_NOTE_DIRTY: 'SET_NOTE_DIRTY',
  UNSET_NOTE_DIRTY: 'UNSET_NOTE_DIRTY',
  BLUR_NOTES: 'BLUR_NOTES',
  BLUR_NOTES_DONE: 'BLUR_NOTES_DONE',
  SET_SHOW_PRIVATE_NOTES: 'SET_SHOW_PRIVATE_NOTES',
  SET_REGENERATED_SLATE_NOTE: 'SET_REGENERATED_SLATE_NOTE',
  CLEAR_REGENERATED_SLATE_NOTE: 'CLEAR_REGENERATED_SLATE_NOTE'
};

export const InsightsActions = {
  FETCH_QUESTIONS_REQUEST: 'FETCH_QUESTIONS_REQUEST',
  FETCH_QUESTIONS_SUCCESS: 'FETCH_QUESTIONS_SUCCESS',
  FETCH_QUESTIONS_FAILURE: 'FETCH_QUESTIONS_FAILURE'
};

export const PublicActions = {
  FETCH_PUBLIC_HIGHLIGHT_REQUEST: 'FETCH_PUBLIC_HIGHLIGHT_REQUEST',
  FETCH_PUBLIC_HIGHLIGHT_SUCCESS: 'FETCH_PUBLIC_HIGHLIGHT_SUCCESS',
  FETCH_PUBLIC_HIGHLIGHT_FAILURE: 'FETCH_PUBLIC_HIGHLIGHT_FAILURE',
  ACCESS_CODE_REQUESTED: 'Public Access Code Requested',
  ACCESS_CODE_REQUEST_FAILED: 'Public Access Code Request Failed',
  SUBMIT_ACCESS_CODE: 'Public Access Code Submitted',
  FETCH_PUBLIC_HIGHLIGHT_VIDEO: 'FETCH_PUBLIC_HIGHLIGHT_VIDEO',
  PUBLIC_SEEK_TO_TIME: 'PUBLIC_SEEK_TO_TIME',
  FETCH_REDIRECT_LEGACY_LINK_REQUEST: 'FETCH_REDIRECT_LEGACY_LINK_REQUEST',
  FETCH_REDIRECT_LEGACY_LINK_SUCCESS: 'FETCH_REDIRECT_LEGACY_LINK_SUCCESS',
  FETCH_REDIRECT_LEGACY_LINK_FAILURE: 'FETCH_REDIRECT_LEGACY_LINK_FAILURE',
  SET_PUBLIC_CODE: 'SET_PUBLIC_CODE',

  REQUEST_MEETING_PUBLIC_ACCESS_CODE_REQUEST:
    'REQUEST_MEETING_PUBLIC_ACCESS_CODE_REQUEST',
  REQUEST_MEETING_PUBLIC_ACCESS_CODE_SUCCESS:
    'REQUEST_MEETING_PUBLIC_ACCESS_CODE_SUCCESS',
  REQUEST_MEETING_PUBLIC_ACCESS_CODE_FAILURE:
    'REQUEST_MEETING_PUBLIC_ACCESS_CODE_FAILURE',

  REQUEST_HIGHLIGHT_PUBLIC_ACCESS_CODE_REQUEST:
    'REQUEST_HIGHLIGHT_PUBLIC_ACCESS_CODE_REQUEST',
  REQUEST_HIGHLIGHT_PUBLIC_ACCESS_CODE_SUCCESS:
    'REQUEST_HIGHLIGHT_PUBLIC_ACCESS_CODE_SUCCESS',
  REQUEST_HIGHLIGHT_PUBLIC_ACCESS_CODE_FAILURE:
    'REQUEST_HIGHLIGHT_PUBLIC_ACCESS_CODE_FAILURE',

  REQUEST_PLAYLIST_PUBLIC_ACCESS_CODE_REQUEST:
    'REQUEST_PLAYLIST_PUBLIC_ACCESS_CODE_REQUEST',
  REQUEST_PLAYLIST_PUBLIC_ACCESS_CODE_SUCCESS:
    'REQUEST_PLAYLIST_PUBLIC_ACCESS_CODE_SUCCESS',
  REQUEST_PLAYLIST_PUBLIC_ACCESS_CODE_FAILURE:
    'REQUEST_PLAYLIST_PUBLIC_ACCESS_CODE_FAILURE',

  PUBLIC_MEETING_VIEWED_REQUEST: 'PUBLIC_MEETING_VIEWED_REQUEST',
  PUBLIC_MEETING_VIEWED_SUCCESS: 'PUBLIC_MEETING_VIEWED_SUCCESS',
  PUBLIC_MEETING_VIEWED_FAILURE: 'PUBLIC_MEETING_VIEWED_FAILURE',

  PUBLIC_HIGHLIGHT_VIEWED_REQUEST: 'PUBLIC_HIGHLIGHT_VIEWED_REQUEST',
  PUBLIC_HIGHLIGHT_VIEWED_SUCCESS: 'PUBLIC_HIGHLIGHT_VIEWED_SUCCESS',
  PUBLIC_HIGHLIGHT_VIEWED_FAILURE: 'PUBLIC_HIGHLIGHT_VIEWED_FAILURE'
};

export const LicenseActions = {
  FETCH_PLANS_REQUEST: 'FETCH_PLANS_REQUEST',
  FETCH_PLANS_SUCCESS: 'FETCH_PLANS_SUCCESS',
  FETCH_PLANS_FAILURE: 'FETCH_PLANS_FAILURE',
  FETCH_LICENSES_REQUEST: 'FETCH_LICENSES_REQUEST',
  FETCH_LICENSES_SUCCESS: 'FETCH_LICENSES_SUCCESS',
  FETCH_LICENSES_FAILURE: 'FETCH_LICENSES_FAILURE',
  SEND_REQUEST_ADMIN_EMAIL_REQUEST: 'SEND_REQUEST_ADMIN_EMAIL_REQUEST',
  SEND_REQUEST_ADMIN_EMAIL_SUCCESS: 'SEND_REQUEST_ADMIN_EMAIL_SUCCESS',
  SEND_REQUEST_ADMIN_EMAIL_FAILURE: 'SEND_REQUEST_ADMIN_EMAIL_FAILURE'
};

export const CrmActions = {
  CRM_NOTE_SYNC_REQUEST: 'CRM_NOTE_SYNC_REQUEST',
  CRM_NOTE_SYNC_SUCCESS: 'CRM_NOTE_SYNC_SUCCESS',
  CRM_NOTE_SYNC_FAILED: 'CRM_NOTE_SYNC_FAILED',
  FETCH_ACTIVE_CRM_USERS_REQUEST: 'FETCH_ACTIVE_CRM_USERS_REQUEST',
  FETCH_ACTIVE_CRM_USERS_SUCCESS: 'FETCH_ACTIVE_CRM_USERS_SUCCESS',
  FETCH_ACTIVE_CRM_USERS_FAILURE: 'FETCH_ACTIVE_CRM_USERS_FAILURE',
  FETCH_INACTIVE_CRM_USERS_REQUEST: 'FETCH_INACTIVE_CRM_USERS_REQUEST',
  FETCH_INACTIVE_CRM_USERS_SUCCESS: 'FETCH_INACTIVE_CRM_USERS_SUCCESS',
  FETCH_INACTIVE_CRM_USERS_FAILURE: 'FETCH_INACTIVE_CRM_USERS_FAILURE',

  FETCH_CRM_DETAILS_REQUEST: 'FETCH_CRM_DETAILS_REQUEST',
  FETCH_CRM_DETAILS_SUCCESS: 'FETCH_CRM_DETAILS_SUCCESS',
  FETCH_CRM_DETAILS_ERROR: 'FETCH_CRM_DETAILS_ERROR',

  FETCH_OPPORTUNITY_STAGES_REQUEST: 'FETCH_OPPORTUNITY_STAGES_REQUEST',
  FETCH_OPPORTUNITY_STAGES_SUCCESS: 'FETCH_OPPORTUNITY_STAGES_SUCCESS',
  FETCH_OPPORTUNITY_STAGES_FAILURE: 'FETCH_OPPORTUNITY_STAGES_FAILURE',

  FETCH_OPPORTUNITIES_REQUEST: 'FETCH_OPPORTUNITIES_REQUEST',
  FETCH_OPPORTUNITIES_SUCCESS: 'FETCH_OPPORTUNITIES_SUCCESS',
  FETCH_OPPORTUNITIES_FAILURE: 'FETCH_OPPORTUNITIES_FAILURE',

  FETCH_CRM_AVAILABLE_PROPERTIES_REQUEST:
    'FETCH_CRM_AVAILABLE_PROPERTIES_REQUEST',
  FETCH_CRM_AVAILABLE_PROPERTIES_SUCCESS:
    'FETCH_CRM_AVAILABLE_PROPERTIES_SUCCESS',
  FETCH_CRM_AVAILABLE_PROPERTIES_FAILURE:
    'FETCH_CRM_AVAILABLE_PROPERTIES_FAILURE',

  FETCH_CRM_OBJECT_COUNT_REQUEST: 'FETCH_CRM_OBJECT_COUNT_REQUEST',
  FETCH_CRM_OBJECT_COUNT_SUCCESS: 'FETCH_CRM_OBJECT_COUNT_SUCCESS',
  FETCH_CRM_OBJECT_COUNT_FAILURE: 'FETCH_CRM_OBJECT_COUNT_FAILURE',

  FETCH_SYNCED_CRM_OBJECT_PROPERTIES_REQUEST:
    'FETCH_SYNCED_CRM_OBJECT_PROPERTIES_REQUEST',
  FETCH_SYNCED_CRM_OBJECT_PROPERTIES_SUCCESS:
    'FETCH_SYNCED_CRM_OBJECT_PROPERTIES_SUCCESS',
  FETCH_SYNCED_CRM_OBJECT_PROPERTIES_FAILURE:
    'FETCH_SYNCED_CRM_OBJECT_PROPERTIES_FAILURE',

  SYNC_IMPORTED_PROPERTIES_OBJECT_REQUEST:
    'SYNC_IMPORTED_PROPERTIES_OBJECT_REQUEST',
  SYNC_IMPORTED_PROPERTIES_OBJECT_SUCCESS:
    'SYNC_IMPORTED_PROPERTIES_OBJECT_SUCCESS',
  SYNC_IMPORTED_PROPERTIES_OBJECT_FAILURE:
    'SYNC_IMPORTED_PROPERTIES_OBJECT_FAILURE',

  UPDATE_CRM_FIELD_PROPERTY_REQUEST: 'UPDATE_CRM_FIELD_PROPERTY_REQUEST',
  UPDATE_CRM_FIELD_PROPERTY_SUCCESS: 'UPDATE_CRM_FIELD_PROPERTY_SUCCESS',
  UPDATE_CRM_FIELD_PROPERTY_FAILURE: 'UPDATE_CRM_FIELD_PROPERTY_FAILURE',

  DELETE_CRM_FIELD_PROPERTY_REQUEST: 'DELETE_CRM_FIELD_PROPERTY_REQUEST',
  DELETE_CRM_FIELD_PROPERTY_SUCCESS: 'DELETE_CRM_FIELD_PROPERTY_SUCCESS',
  DELETE_CRM_FIELD_PROPERTY_FAILURE: 'DELETE_CRM_FIELD_PROPERTY_FAILURE',

  FETCH_CRM_SMART_CATEGORY_REQUEST: 'FETCH_CRM_SMART_CATEGORY_REQUEST',
  FETCH_CRM_SMART_CATEGORY_SUCCESS: 'FETCH_CRM_SMART_CATEGORY_SUCCESS',
  FETCH_CRM_SMART_CATEGORY_FAILURE: 'FETCH_CRM_SMART_CATEGORY_FAILURE',

  FETCH_CRM_AVOMA_PROPERTY_REQUEST: 'FETCH_CRM_AVOMA_PROPERTY_REQUEST',
  FETCH_CRM_AVOMA_PROPERTY_SUCCESS: 'FETCH_CRM_AVOMA_PROPERTY_SUCCESS',
  FETCH_CRM_AVOMA_PROPERTY_FAILURE: 'FETCH_CRM_AVOMA_PROPERTY_FAILURE',

  CREATE_OR_FETCH_CRM_RECORD_SETTINGS_REQUEST:
    'CREATE_OR_FETCH_CRM_RECORD_SETTINGS_REQUEST',
  CREATE_OR_FETCH_CRM_RECORD_SETTINGS_SUCCESS:
    'CREATE_OR_FETCH_CRM_RECORD_SETTINGS_SUCCESS',
  CREATE_OR_FETCH_CRM_RECORD_SETTINGS_FAILURE:
    'CREATE_OR_FETCH_CRM_RECORD_SETTINGS_FAILURE',

  UPDATE_CRM_RECORD_SETTINGS_REQUEST: 'UPDATE_CRM_RECORD_SETTINGS_REQUEST',
  UPDATE_CRM_RECORD_SETTINGS_SUCCESS: 'UPDATE_CRM_RECORD_SETTINGS_SUCCESS',
  UPDATE_CRM_RECORD_SETTINGS_FAILURE: 'UPDATE_CRM_RECORD_SETTINGS_FAILURE'
};

export const RecordingActions = {
  FETCH_SPEAKERS_REQUEST: 'FETCH_SPEAKERS_REQUEST',
  FETCH_SPEAKERS_SUCCESS: 'FETCH_SPEAKERS_SUCCESS',
  FETCH_SPEAKERS_FAILURE: 'FETCH_SPEAKERS_FAILURE',
  UPDATE_SPEAKERS_REQUEST: 'UPDATE_SPEAKERS_REQUEST',
  UPDATE_SPEAKERS_SUCCESS: 'UPDATE_SPEAKERS_SUCCESS',
  UPDATE_SPEAKERS_FAILURE: 'UPDATE_SPEAKERS_FAILURE',
  DELETE_SPEAKERS_REQUEST: 'DELETE_SPEAKERS_REQUEST',
  DELETE_SPEAKERS_SUCCESS: 'DELETE_SPEAKERS_SUCCESS',
  DELETE_SPEAKERS_FAILURE: 'DELETE_SPEAKERS_FAILURE',
  FETCH_SPEAKER_INSIGHTS_REQUEST: 'FETCH_SPEAKER_INSIGHTS_REQUEST',
  FETCH_SPEAKER_INSIGHTS_SUCCESS: 'FETCH_SPEAKER_INSIGHTS_SUCCESS',
  FETCH_SPEAKER_INSIGHTS_FAILURE: 'FETCH_SPEAKER_INSIGHTS_FAILURE'
};
export const OutcomeActions = {
  FETCH_ATTRIBUTES_REQUEST: 'FETCH_ATTRIBUTES_REQUEST',
  FETCH_ATTRIBUTES_SUCCESS: 'FETCH_ATTRIBUTES_SUCCESS',
  FETCH_ATTRIBUTES_FAILURE: 'FETCH_ATTRIBUTES_FAILURE',
  FETCH_TYPES_REQUEST: 'FETCH_TYPES_REQUEST',
  FETCH_TYPES_SUCCESS: 'FETCH_TYPES_SUCCESS',
  FETCH_TYPES_FAILURE: 'FETCH_TYPES_FAILURE',
  FETCH_TYPES_FOR_ORG_REQUEST: 'FETCH_TYPES_FOR_ORG_REQUEST',
  FETCH_TYPES_FOR_ORG_SUCCESS: 'FETCH_TYPES_FOR_ORG_SUCCESS',
  FETCH_TYPES_FOR_ORG_FAILURE: 'FETCH_TYPES_FOR_ORG_FAILURE',
  CREATE_TYPES_REQUEST: 'CREATE_TYPES_REQUEST',
  CREATE_TYPES_SUCCESS: 'CREATE_TYPES_SUCCESS',
  CREATE_TYPES_FAILURE: 'CREATE_TYPES_FAILURE',
  UPDATE_TYPES_REQUEST: 'UPDATE_TYPES_REQUEST',
  UPDATE_TYPES_SUCCESS: 'UPDATE_TYPES_SUCCESS',
  UPDATE_TYPES_FAILURE: 'UPDATE_TYPES_FAILURE',
  DELETE_TYPES_REQUEST: 'DELETE_TYPES_REQUEST',
  DELETE_TYPES_SUCCESS: 'DELETE_TYPES_SUCCESS',
  DELETE_TYPES_FAILURE: 'DELETE_TYPES_FAILURE',
  FETCH_OUTCOMES_REQUEST: 'FETCH_OUTCOMES_REQUEST',
  FETCH_OUTCOMES_SUCCESS: 'FETCH_OUTCOMES_SUCCESS',
  FETCH_OUTCOMES_FAILURE: 'FETCH_OUTCOMES_FAILURE',
  CREATE_OUTCOMES_REQUEST: 'CREATE_OUTCOMES_REQUEST',
  CREATE_OUTCOMES_SUCCESS: 'CREATE_OUTCOMES_SUCCESS',
  CREATE_OUTCOMES_FAILURE: 'CREATE_OUTCOMES_FAILURE',
  UPDATE_OUTCOMES_REQUEST: 'UPDATE_OUTCOMES_REQUEST',
  UPDATE_OUTCOMES_SUCCESS: 'UPDATE_OUTCOMES_SUCCESS',
  UPDATE_OUTCOMES_FAILURE: 'UPDATE_OUTCOMES_FAILURE',
  DELETE_OUTCOMES_REQUEST: 'DELETE_OUTCOMES_REQUEST',
  DELETE_OUTCOMES_SUCCESS: 'DELETE_OUTCOMES_SUCCESS',
  DELETE_OUTCOMES_FAILURE: 'DELETE_OUTCOMES_FAILURE',

  ASSIGN_MEETING_TYPE_REQUEST: 'ASSIGN_MEETING_TYPE_REQUEST',
  ASSIGN_MEETING_OUTCOME_REQUEST: 'ASSIGN_MEETING_OUTCOME_REQUEST',
  UNASSIGN_MEETING_TYPE_REQUEST: 'UNASSIGN_MEETING_TYPE_REQUEST',
  UNASSIGN_MEETING_OUTCOME_REQUEST: 'UNASSIGN_MEETING_OUTCOME_REQUEST'
};

export const WebsocketActions = {
  CONNECT: 'WS_CONNECT',
  CONNECTING: 'WS_CONNECTING',
  CONNECTED: 'WS_CONNECTED',
  DISCONNECT: 'WS_DISCONNECT',
  DISCONNECTING: 'WS_DISCONNECT',
  DISCONNECTED: 'WS_DISCONNECTED',
  SUBSCRIBE: 'WS_SUBSCRIBE',
  SUBSCRIBED: 'WS_SUBSCRIBED',
  UNSUBSCRIBE: 'WS_UNSUBSCRIBE',
  UNSUBSCRIBED: 'WS_UNSUBSCRIBED',
  TYPE_BOOKMARKS: 'meeting_bookmarks',
  TYPE_MEETING_META: 'meeting_meta',
  TYPE_BATTLECARDS: 'meeting_battle_cards',
  TYPE_MEETING_REALTIME_TRANSCRIPTIONS: 'meeting_transcript',
  TYPE_COPILOT: 'copilot',
  KEY_MEETING: 'meeting',
  KEY_DEAL: 'deal',
  KEY_COPILOT_CHAT: 'copilot_chat'
};

export const CopilotActions = {
  SEND_COPILOT_MESSAGE: 'SEND_COPILOT_MESSAGE',
  RECEIVE_COPILOT_MESSAGE: 'RECEIVE_COPILOT_MESSAGE',

  FETCH_COPILOT_CHAT_REQUEST: 'FETCH_COPILOT_CHAT_REQUEST',
  FETCH_COPILOT_CHAT_SUCCESS: 'FETCH_COPILOT_CHAT_SUCCESS',
  FETCH_COPILOT_CHAT_FAILURE: 'FETCH_COPILOT_CHAT_FAILURE',

  CREATE_COPILOT_CHAT_REQUEST: 'CREATE_COPILOT_CHAT_REQUEST',
  CREATE_COPILOT_CHAT_SUCCESS: 'CREATE_COPILOT_CHAT_SUCCESS',
  CREATE_COPILOT_CHAT_FAILURE: 'CREATE_COPILOT_CHAT_FAILURE',

  MESSAGE_TIMEOUT: 'MESSAGE_TIMEOUT',

  SET_GLOBAL_COPILOT_CHAT_OPEN: 'SET_GLOBAL_COPILOT_CHAT_OPEN',
  SET_GLOBAL_COPILOT_CHAT_CLOSED: 'SET_GLOBAL_COPILOT_CHAT_CLOSED'
};

export const BookmarkActions = {
  CREATE_BOOKMARK_REQUEST: 'Create Bookmark',
  CREATE_BOOKMARK_SUCCESS: 'Create Bookmark Success',
  CREATE_BOOKMARK_FAILURE: 'Create Bookmark Failed',
  DELETE_BOOKMARK_REQUEST: 'Delete Bookmark',
  DELETE_BOOKMARK_SUCCESS: 'Delete Bookmark Success',
  DELETE_BOOKMARK_FAILURE: 'Delete Bookmark Failed',
  FETCH_BOOKMARK_SUCCESS: 'Fetch Bookmark Success',
  FETCH_BOOKMARKS_REQUEST: 'Fetch Bookmarks Request',
  FETCH_BOOKMARKS_SUCCESS: 'Fetch Bookmarks Success',
  FETCH_BOOKMARKS_FAILED: 'Fetch Bookmarks Failed',
  UDPATE_BOOKMARK_REQUEST: 'Update Bookmark Request',
  UDPATE_BOOKMARK_SUCCESS: 'Update Bookmark Success',
  UDPATE_BOOKMARK_FAILURE: 'Update Bookmark Failure'
};

export const BattlecardActions = {
  FETCH_BATTLECARD_SUCCESS: 'Fetch Battlecard Success',
  RESET_BATTLECARD: 'Reset Battlecard'
};

export const RealtimeTranscriptFetchActions = {
  FETCH_EXISTING_REALTIME_TRANSCRIPTION_SUCCESS:
    'FETCH_EXISTING_REALTIME_TRANSCRIPTION_SUCCESS',
  FETCH_REALTIME_TRANSCRIPTION_SUCCESS: 'FETCH_REALTIME_TRANSCRIPTION_SUCCESS',
  RESET_REALTIME_TRANSCRIPTION: 'RESET_REALTIME_TRANSCRIPTION'
};

export const MeetingTranslationActions = {
  FETCH_MEETING_TRANSLATION: 'FETCH_MEETING_TRANSLATION',
  FETCH_MEETING_TRANSLATION_NOTES_SUCCESS:
    'FETCH_MEETING_TRANSLATION_NOTES_SUCCESS',
  FETCH_MEETING_TRANSLATION_TRANSCRIPT_SUCCESS:
    'FETCH_MEETING_TRANSLATION_TRANSCRIPT_SUCCESS',
  FETCH_MEETING_TRANSLATION_ERROR: 'FETCH_MEETING_TRANSLATION_ERROR'
};

export const CommentActions = {
  CREATE_COMMENT_REQUEST: 'CREATE_COMMENT_REQUEST',
  CREATE_COMMENT_SUCCESS: 'CREATE_COMMENT_SUCCESS',
  CREATE_COMMENT_FAILURE: 'CREATE_COMMENT_FAILURE',
  REPLY_COMMENT_REQUEST: 'REPLY_COMMENT_REQUEST',
  REPLY_COMMENT_SUCCESS: 'REPLY_COMMENT_SUCCESS',
  REPLY_COMMENT_FAILURE: 'REPLY_COMMENT_FAILURE',
  DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
  DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
  DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',
  FETCH_COMMENT_REQUEST: 'FETCH_COMMENT_REQUEST',
  FETCH_COMMENT_SUCCESS: 'FETCH_COMMENT_SUCCESS',
  FETCH_COMMENT_FAILURE: 'FETCH_COMMENT_FAILURE',
  UPDATE_COMMENT_REQUEST: 'UPDATE_COMMENT_REQUEST',
  UPDATE_COMMENT_SUCCESS: 'UPDATE_COMMENT_SUCCESS',
  UPDATE_COMMENT_FAILURE: 'UPDATE_COMMENT_FAILURE',
  NEW_RECORDING_COMMENT: 'NEW_RECORDING_COMMENT'
};

export const filterActions = {
  SET_APPLIED_FILTER: 'SET_APPLIED_FILTER',
  RESET_APPLIED_FILTER: 'RESET_APPLIED_FILTER',

  SET_SAVEDVIEWS_FORM_TYPES_VISIBILITY: 'SET_SAVEDVIEWS_FORM_TYPES_VISIBILITY',
  SET_SAVEDVIEWS_FORM_TYPE: 'SET_SAVEDVIEWS_FORM_TYPE',
  SET_SAVEDVIEWS_FORM_VISIBILITY: 'SET_SAVEDVIEWS_FORM_VISIBILITY',

  ADD_HOOK_FILTER: 'ADD_HOOK_FILTER',
  UPDATE_HOOK_FILTER: 'UPDATE_HOOK_FILTER',
  CLEAR_HOOK_FILTER: 'CLEAR_HOOK_FILTER'
};

export const DealActions = {
  FETCH_DEALS_REQUEST: 'FETCH_DEALS_REQUEST',
  FETCH_DEALS_SUCCESS: 'FETCH_DEALS_SUCCESS',
  FETCH_DEALS_FAILURE: 'FETCH_DEALS_FAILURE',
  FETCH_MORE_DEALS_REQUEST: 'FETCH_MORE_DEALS_REQUEST',
  FETCH_MORE_DEALS_SUCCESS: 'FETCH_MORE_DEALS_SUCCESS',
  FETCH_MORE_DEALS_FAILURE: 'FETCH_MORE_DEALS_FAILURE',
  FETCH_ENGAGEMENT_REQUEST: 'Fetch Deal Engagement Requests',
  FETCH_ENGAGEMENT_SUCCESS: 'Fetched Deal Engagement',
  FETCH_ENGAGEMENT_FAILURE: 'Fetch Deal Engagement Failure',

  SEARCH_CRM_ACCOUNTS_REQUEST: 'SEARCH_CRM_ACCOUNTS_REQUEST',
  SEARCH_CRM_ACCOUNTS_SUCCESS: 'SEARCH_CRM_ACCOUNTS_SUCCESS',
  SEARCH_CRM_ACCOUNTS_FAILURE: 'SEARCH_CRM_ACCOUNTS_FAILURE',

  FETCH_TIMELINE_DETAILS_REQUEST: 'Fetch timeline details request',
  FETCH_TIMELINE_DETAILS_SUCCESS: 'Fetch timeline details success',
  FETCH_TIMELINE_DETAILS_ERROR: 'Fetch timeline details error',

  SET_TIMELINE_DETAILS_HOVER: 'Set time line details hover',

  FETCH_PIPELINE_REQUEST: 'FETCH_PIPELINE_REQUEST',
  FETCH_PIPELINE_SUCCESS: 'FETCH_PIPELINE_SUCCESS',
  FETCH_PIPELINE_ERROR: 'FETCH_PIPELINE_ERROR',

  FETCH_DEAL_REQUEST: 'FETCH_DEAL_REQUEST',
  FETCH_DEAL_SUCCESS: 'FETCH_DEAL_SUCCESS',
  FETCH_DEAL_FAILURE: 'FETCH_DEAL_FAILURE',

  FETCH_DEAL_ENGAGEMENT_DETAILS_REQUEST:
    'FETCH_DEAL_ENGAGEMENT_DETAILS_REQUEST',
  FETCH_DEAL_ENGAGEMENT_DETAILS_SUCCESS:
    'FETCH_DEAL_ENGAGEMENT_DETAILS_SUCCESS',
  FETCH_DEAL_ENGAGEMENT_DETAILS_FAILURE:
    'FETCH_DEAL_ENGAGEMENT_DETAILS_FAILURE',

  RESET_DEAL: 'RESET_DEAL',

  FETCH_DEAL_STAGES_REQUEST: 'FETCH_DEAL_STAGES_REQUEST',
  FETCH_DEAL_STAGES_SUCCESS: 'FETCH_DEAL_STAGES_SUCCESS',
  FETCH_DEAL_STAGES_ERROR: 'FETCH_DEAL_STAGES_ERROR',

  FETCH_DEAL_ENGAGEMENT_BUBBLE_DETAILS_REQUEST:
    'FETCH_DEAL_ENGAGEMENT_BUBBLE_DETAILS_REQUEST',
  FETCH_DEAL_ENGAGEMENT_BUBBLE_DETAILS_SUCCESS:
    'FETCH_DEAL_ENGAGEMENT_BUBBLE_DETAILS_SUCCESS',
  FETCH_DEAL_ENGAGEMENT_BUBBLE_DETAILS_ERROR:
    'FETCH_DEAL_ENGAGEMENT_BUBBLE_DETAILS_ERROR',

  FETCH_DEAL_COMMENTS_REQUEST: 'FETCH_DEAL_COMMENTS_REQUEST',
  FETCH_DEAL_COMMENTS_SUCCESS: 'FETCH_DEAL_COMMENTS_SUCCESS',
  FETCH_DEAL_COMMENTS_ERROR: 'FETCH_DEAL_COMMENTS_ERROR',

  REMOVE_DEAL_COMMENTS: 'REMOVE_DEAL_COMMENTS',

  POST_COMMENT_REQUEST: 'POST_COMMENT_REQUEST',
  POST_COMMENT_SUCCESS: 'Posted Deal Comments',
  POST_COMMENT_ERROR: 'POST_COMMENT_ERROR',

  EDIT_COMMENT_REQUEST: 'EDIT_COMMENT_REQUEST',
  EDIT_COMMENT_SUCCESS: 'EDIT_COMMENT_SUCCESS',
  EDIT_COMMENT_ERROR: 'EDIT_COMMENT_ERROR',

  DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
  DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
  DELETE_COMMENT_ERROR: 'DELETE_COMMENT_ERROR',

  FETCH_CRM_ACCOUNTS_REQUEST: 'FETCH_CRM_ACCOUNTS_REQUEST',
  FETCH_CRM_ACCOUNTS_SUCCESS: 'FETCH_CRM_ACCOUNTS_SUCCESS',
  FETCH_CRM_ACCOUNTS_FAILURE: 'FETCH_CRM_ACCOUNTS_FAILURE',

  UPDATE_DEAL_DETAILS_IN_CRM_REQUEST: 'UPDATE_DEAL_DETAILS_IN_CRM_REQUEST',
  UPDATE_DEAL_DETAILS_IN_CRM_SUCCESS: 'UPDATE_DEAL_DETAILS_IN_CRM_SUCCESS',
  UPDATE_DEAL_DETAILS_IN_CRM_FAILURE: 'UPDATE_DEAL_DETAILS_IN_CRM_FAILURE',

  UPDATE_DEAL_DETAILS_IN_CRM_V2_REQUEST:
    'UPDATE_DEAL_DETAILS_IN_CRM_V2_REQUEST',
  UPDATE_DEAL_DETAILS_IN_CRM_V2_SUCCESS:
    'UPDATE_DEAL_DETAILS_IN_CRM_V2_SUCCESS',
  UPDATE_DEAL_DETAILS_IN_CRM_V2_FAILURE:
    'UPDATE_DEAL_DETAILS_IN_CRM_V2_FAILURE',

  FETCH_CURRENT_DASHBOARD_COLUMN_HEADERS_REQUEST:
    'FETCH_CURRENT_DASHBOARD_COLUMN_HEADERS_REQUEST',
  FETCH_CURRENT_DASHBOARD_COLUMN_HEADERS_SUCCESS:
    'FETCH_CURRENT_DASHBOARD_COLUMN_HEADERS_SUCCESS',
  FETCH_CURRENT_DASHBOARD_COLUMN_HEADERS_FAILURE:
    'FETCH_CURRENT_DASHBOARD_COLUMN_HEADERS_FAILURE',

  FETCH_DEALS_V2_REQUEST: 'FETCH_DEALS_V2_REQUEST',
  FETCH_DEALS_V2_SUCCESS: 'FETCH_DEALS_V2_SUCCESS',
  FETCH_DEALS_V2_FAILURE: 'FETCH_DEALS_V2_FAILURE',

  FETCH_MORE_DEALS_V2_REQUEST: 'FETCH_MORE_DEALS_V2_REQUEST',
  FETCH_MORE_DEALS_V2_SUCCESS: 'FETCH_MORE_DEALS_V2_SUCCESS',
  FETCH_MORE_DEALS_V2_FAILURE: 'FETCH_MORE_DEALS_V2_FAILURE',

  UPDATE_DEAL_DASHBOARD_COLUMNS_ORDER_REQUEST:
    'UPDATE_DEAL_DASHBOARD_COLUMNS_ORDER_REQUEST',
  UPDATE_DEAL_DASHBOARD_COLUMNS_ORDER_SUCCESS:
    'UPDATE_DEAL_DASHBOARD_COLUMNS_ORDER_SUCCESS',
  UPDATE_DEAL_DASHBOARD_COLUMNS_ORDER_FAILURE:
    'UPDATE_DEAL_DASHBOARD_COLUMNS_ORDER_FAILURE',

  CREATE_DASHBOARD_COLUMN_REQUEST: 'CREATE_DASHBOARD_COLUMN_REQUEST',
  CREATE_DASHBOARD_COLUMN_SUCCESS: 'CREATE_DASHBOARD_COLUMN_SUCCESS',
  CREATE_DASHBOARD_COLUMN_FAILURE: 'CREATE_DASHBOARD_COLUMN_FAILURE',

  DELETE_DASHBOARD_COLUMN_REQUEST: 'DELETE_DASHBOARD_COLUMN_REQUEST',
  DELETE_DASHBOARD_COLUMN_SUCCESS: 'DELETE_DASHBOARD_COLUMN_SUCCESS',
  DELETE_DASHBOARD_COLUMN_FAILURE: 'DELETE_DASHBOARD_COLUMN_FAILURE',

  FETCH_FORECAST_CATEGORIES_REQUEST: 'FETCH_FORECAST_CATEGORIES_REQUEST',
  FETCH_FORECAST_CATEGORIES_SUCCESS: 'FETCH_FORECAST_CATEGORIES_SUCCESS',
  FETCH_FORECAST_CATEGORIES_FAILURE: 'FETCH_FORECAST_CATEGORIES_FAILURE',

  FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_REQUEST:
    'FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_REQUEST',
  FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_SUCCESS:
    'FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_SUCCESS',
  FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_FAILURE:
    'FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_FAILURE',

  REFRESH_DASHBOARD_DEAL_DETAILS: 'REFRESH_DASHBOARD_DEAL_DETAILS',

  CLICK_COLUMN_CHOOSER_DRAWER_BUTTON: 'CLICK_COLUMN_CHOOSER_DRAWER_BUTTON',

  FETCH_DEAL_HEALTH_SCORE_REQUEST: 'FETCH_DEAL_HEALTH_SCORE_REQUEST',
  FETCH_DEAL_HEALTH_SCORE_SUCCESS: 'FETCH_DEAL_HEALTH_SCORE_SUCCESS',
  FETCH_DEAL_HEALTH_SCORE_FAILURE: 'FETCH_DEAL_HEALTH_SCORE_FAILURE',

  FETCH_DEAL_CHANGE_INDICATOR_REQUEST: 'FETCH_DEAL_CHANGE_INDICATOR_REQUEST',
  FETCH_DEAL_CHANGE_INDICATOR_SUCCESS: 'FETCH_DEAL_CHANGE_INDICATOR_SUCCESS',
  FETCH_DEAL_CHANGE_INDICATOR_FAILURE: 'FETCH_DEAL_CHANGE_INDICATOR_FAILURE'
};

export const CompanyActions = {
  FETCH_ENGAGEMENT_REQUEST: 'Fetch Company Engagement Requests',
  FETCH_ENGAGEMENT_SUCCESS: 'Fetched Company Engagement',
  FETCH_ENGAGEMENT_FAILURE: 'Fetch Company Engagement Failure',

  FETCH_TIMELINE_DETAILS_REQUEST: 'Fetch company timeline details request',
  FETCH_TIMELINE_DETAILS_SUCCESS: 'Fetch company timeline details success',
  FETCH_TIMELINE_DETAILS_FAILURE: 'Fetch company timeline details failure',

  FETCH_COMPANY_ENGAGEMENT_DETAILS_REQUEST:
    'FETCH_COMPANY_ENGAGEMENT_DETAILS_REQUEST',
  FETCH_COMPANY_ENGAGEMENT_DETAILS_SUCCESS:
    'FETCH_COMPANY_ENGAGEMENT_DETAILS_SUCCESS',
  FETCH_COMPANY_ENGAGEMENT_DETAILS_FAILURE:
    'FETCH_COMPANY_ENGAGEMENT_DETAILS_FAILURE',

  SET_TIMELINE_DETAILS_HOVER: 'Set company time line details hover',

  FETCH_CURRENT_COMPANY_DASHBOARD_COLUMN_HEADERS_REQUEST:
    'FETCH_CURRENT_COMPANY_DASHBOARD_COLUMN_HEADERS_REQUEST',
  FETCH_CURRENT_COMPANY_DASHBOARD_COLUMN_HEADERS_SUCCESS:
    'FETCH_CURRENT_COMPANY_DASHBOARD_COLUMN_HEADERS_SUCCESS',
  FETCH_CURRENT_COMPANY_DASHBOARD_COLUMN_HEADERS_FAILURE:
    'FETCH_CURRENT_COMPANY_DASHBOARD_COLUMN_HEADERS_FAILURE',

  FETCH_COMPANIES_REQUEST: 'FETCH_COMPANIES_REQUEST',
  FETCH_COMPANIES_SUCCESS: 'FETCH_COMPANIES_SUCCESS',
  FETCH_COMPANIES_FAILURE: 'FETCH_COMPANIES_FAILURE',

  FETCH_MORE_COMPANIES_REQUEST: 'FETCH_MORE_COMPANIES_REQUEST',
  FETCH_MORE_COMPANIES_SUCCESS: 'FETCH_MORE_COMPANIES_SUCCESS',
  FETCH_MORE_COMPANIES_FAILURE: 'FETCH_MORE_COMPANIES_FAILURE',

  UPDATE_COMPANY_DASHBOARD_COLUMNS_ORDER_REQUEST:
    'UPDATE_COMPANY_DASHBOARD_COLUMNS_ORDER_REQUEST',
  UPDATE_COMPANY_DASHBOARD_COLUMNS_ORDER_SUCCESS:
    'UPDATE_COMPANY_DASHBOARD_COLUMNS_ORDER_SUCCESS',
  UPDATE_COMPANY_DASHBOARD_COLUMNS_ORDER_FAILURE:
    'UPDATE_COMPANY_DASHBOARD_COLUMNS_ORDER_FAILURE',

  CREATE_COMPANY_DASHBOARD_COLUMN_REQUEST:
    'CREATE_COMPANY_DASHBOARD_COLUMN_REQUEST',
  CREATE_COMPANY_DASHBOARD_COLUMN_SUCCESS:
    'CREATE_COMPANY_DASHBOARD_COLUMN_SUCCESS',
  CREATE_COMPANY_DASHBOARD_COLUMN_FAILURE:
    'CREATE_COMPANY_DASHBOARD_COLUMN_FAILURE',

  DELETE_COMPANY_DASHBOARD_COLUMN_REQUEST:
    'DELETE_COMPANY_DASHBOARD_COLUMN_REQUEST',
  DELETE_COMPANY_DASHBOARD_COLUMN_SUCCESS:
    'DELETE_COMPANY_DASHBOARD_COLUMN_SUCCESS',
  DELETE_COMPANY_DASHBOARD_COLUMN_FAILURE:
    'DELETE_COMPANY_DASHBOARD_COLUMN_FAILURE',

  FETCH_FORECAST_CATEGORIES_REQUEST: 'FETCH_FORECAST_CATEGORIES_REQUEST',
  FETCH_FORECAST_CATEGORIES_SUCCESS: 'FETCH_FORECAST_CATEGORIES_SUCCESS',
  FETCH_FORECAST_CATEGORIES_FAILURE: 'FETCH_FORECAST_CATEGORIES_FAILURE',

  FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_REQUEST:
    'FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_REQUEST',
  FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_SUCCESS:
    'FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_SUCCESS',
  FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_FAILURE:
    'FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_FAILURE',

  REFRESH_DASHBOARD_DEAL_DETAILS: 'REFRESH_DASHBOARD_DEAL_DETAILS',

  CLICK_COMPANY_COLUMN_CHOOSER_DRAWER_BUTTON:
    'CLICK_COMPANY_COLUMN_CHOOSER_DRAWER_BUTTON',

  UPDATE_COMPANY_PIVOT_DETAILS_IN_CRM_REQUEST:
    'UPDATE_COMPANY_PIVOT_DETAILS_IN_CRM_REQUEST',
  UPDATE_COMPANY_PIVOT_DETAILS_IN_CRM_SUCCESS:
    'UPDATE_COMPANY_PIVOT_DETAILS_IN_CRM_SUCCESS',
  UPDATE_COMPANY_PIVOT_DETAILS_IN_CRM_FAILURE:
    'UPDATE_COMPANY_PIVOT_DETAILS_IN_CRM_FAILURE',

  FETCH_DASHBOARD_VIEW_SUMMARY_REQUEST: 'FETCH_DASHBOARD_VIEW_SUMMARY_REQUEST',
  FETCH_DASHBOARD_VIEW_SUMMARY_SUCCESS: 'FETCH_DASHBOARD_VIEW_SUMMARY_SUCCESS',
  FETCH_DASHBOARD_VIEW_SUMMARY_FAILURE: 'FETCH_DASHBOARD_VIEW_SUMMARY_FAILURE',

  FETCH_COMPANY_ENGAGEMENT_BUBBLE_DETAILS_REQUEST:
    'FETCH_COMPANY_ENGAGEMENT_BUBBLE_DETAILS_REQUEST',
  FETCH_COMPANY_ENGAGEMENT_BUBBLE_DETAILS_SUCCESS:
    'FETCH_COMPANY_ENGAGEMENT_BUBBLE_DETAILS_SUCCESS',
  FETCH_COMPANY_ENGAGEMENT_BUBBLE_DETAILS_FAILURE:
    'FETCH_COMPANY_ENGAGEMENT_BUBBLE_DETAILS_FAILURE'
};

export const CoachingActions = {
  SET_USER_ID: 'SET_USER_ID',
  SET_SCREEN_NAME: 'SET_SCREEN_NAME',
  SPEAKER_EVENT: 'COACHING_SPEAKER_EVENT'
};

export const ZoomAppActions = {
  SET_ZOOM_MEETING_UUID: 'SET_ZOOM_MEETING_UUID'
};

export const WebexAppActions = {
  SET_WEBEX_MEETING_UUID: 'SET_WEBEX_MEETING_UUID'
};

export const TeamActions = {
  MARK_PRIMARY_TEAM_REQUEST: 'MARK_PRIMARY_TEAM_REQUEST',
  MARK_PRIMARY_TEAM_SUCCESS: 'MARK_PRIMARY_TEAM_SUCCESS',
  MARK_PRIMARY_TEAM_FAILURE: 'MARK_PRIMARY_TEAM_FAILURE'
};

export const SchedulerActions = {
  CAL_SCHEDULER_REQUEST: 'CAL_SCHEDULER_REQUEST',
  CAL_SCHEDULER_SUCCESS: 'CAL_SCHEDULER_SUCCESS',
  CAL_SCHEDULER_FAILURE: 'CAL_SCHEDULER_FAILURE',

  FETCH_SCHEDULING_PAGES_REQUEST: 'FETCH_SCHEDULING_PAGES_REQUEST',
  FETCH_SCHEDULING_PAGES_SUCCESS: 'FETCH_SCHEDULING_PAGES_SUCCESS',
  FETCH_SCHEDULING_PAGES_FAILURE: 'FETCH_SCHEDULING_PAGES_FAILURE',

  FETCH_TEAM_SCHEDULING_PAGES_REQUEST: 'FETCH_TEAM_SCHEDULING_PAGES_REQUEST',
  FETCH_TEAM_SCHEDULING_PAGES_SUCCESS: 'FETCH_TEAM_SCHEDULING_PAGES_SUCCESS',
  FETCH_TEAM_SCHEDULING_PAGES_FAILURE: 'FETCH_TEAM_SCHEDULING_PAGES_FAILURE',

  FETCH_ALL_SCHEDULING_PAGES_REQUEST: 'FETCH_ALL_SCHEDULING_PAGES_REQUEST',
  FETCH_ALL_SCHEDULING_PAGES_SUCCESS: 'FETCH_ALL_SCHEDULING_PAGES_SUCCESS',
  FETCH_ALL_SCHEDULING_PAGES_FAILURE: 'FETCH_ALL_SCHEDULING_PAGES_FAILURE',

  FETCH_SHARED_SCHEDULING_PAGES_REQUEST:
    'FETCH_SHARED_SCHEDULING_PAGES_REQUEST',
  FETCH_SHARED_SCHEDULING_PAGES_SUCCESS:
    'FETCH_SHARED_SCHEDULING_PAGES_SUCCESS',
  FETCH_SHARED_SCHEDULING_PAGES_FAILURE:
    'FETCH_SHARED_SCHEDULING_PAGES_FAILURE',

  CREATE_SCHEDULING_PAGE_REQUEST: 'CREATE_SCHEDULING_PAGE_REQUEST',
  CREATE_SCHEDULING_PAGE_SUCCESS: 'CREATE_SCHEDULING_PAGE_SUCCESS',
  CREATE_SCHEDULING_PAGE_FAILURE: 'CREATE_SCHEDULING_PAGE_FAILURE',

  RETRIEVE_SCHEDULING_PAGE_REQUEST: 'RETRIEVE_SCHEDULING_PAGE_REQUEST',
  RETRIEVE_SCHEDULING_PAGE_SUCCESS: 'RETRIEVE_SCHEDULING_PAGE_SUCCESS',
  RETRIEVE_SCHEDULING_PAGE_FAILURE: 'RETRIEVE_SCHEDULING_PAGE_FAILURE',

  CALL_SCH_ROUTER: 'CALL_SCH_ROUTER',
  CALL_SCH_ROUTER_SUCCESS: 'CALL_SCH_ROUTER_SUCCESS',
  CALL_SCH_ROUTER_FAILURE: 'CALL_SCH_ROUTER_FAILURE',

  RETRIEVE_PUBLIC_SCH_PAGE_REQUEST: 'RETRIEVE_PUBLIC_SCH_PAGE_REQUEST',
  RETRIEVE_PUBLIC_SCH_PAGE_SUCCESS: 'RETRIEVE_PUBLIC_SCH_PAGE_SUCCESS',
  RETRIEVE_PUBLIC_SCH_PAGE_FAILURE: 'RETRIEVE_PUBLIC_SCH_PAGE_FAILURE',

  CREATE_SCHEDULED_EVENT_REQUEST: 'CREATE_SCHEDULED_EVENT_REQUEST',
  CREATE_SCHEDULED_EVENT_SUCCESS: 'CREATE_SCHEDULED_EVENT_SUCCESS',
  CREATE_SCHEDULED_EVENT_FAILURE: 'CREATE_SCHEDULED_EVENT_FAILURE',
  CREATE_SCHEDULED_EVENT_RESET: 'CREATE_SCHEDULED_EVENT_RESET',

  RETRIEVE_SCHEDULED_EVENT_REQUEST: 'RETRIEVE_SCHEDULED_EVENT_REQUEST',
  RETRIEVE_SCHEDULED_EVENT_SUCCESS: 'RETRIEVE_SCHEDULED_EVENT_SUCCESS',
  RETRIEVE_SCHEDULED_EVENT_FAILURE: 'RETRIEVE_SCHEDULED_EVENT_FAILURE',

  DELETE_SCHEDULED_EVENT_REQUEST: 'DELETE_SCHEDULED_EVENT_REQUEST',
  DELETE_SCHEDULED_EVENT_SUCCESS: 'DELETE_SCHEDULED_EVENT_SUCCESS',
  DELETE_SCHEDULED_EVENT_FAILURE: 'DELETE_SCHEDULED_EVENT_FAILURE',

  RESCHEDULE_EVENT_REQUEST: 'RESCHEDULE_EVENT_REQUEST',
  RESCHEDULE_EVENT_SUCCESS: 'RESCHEDULE_EVENT_SUCCESS',
  RESCHEDULE_EVENT_FAILURE: 'RESCHEDULE_EVENT_FAILURE',

  UPDATE_SCHEDULING_PAGE_REQUEST: 'UPDATE_SCHEDULING_PAGE_REQUEST',
  UPDATE_SCHEDULING_PAGE_SUCCESS: 'UPDATE_SCHEDULING_PAGE_SUCCESS',
  UPDATE_SCHEDULING_PAGE_FAILURE: 'UPDATE_SCHEDULING_PAGE_FAILURE',

  DELETE_MY_SCHEDULING_PAGE_REQUEST: 'DELETE_MY_SCHEDULING_PAGE_REQUEST',
  DELETE_MY_SCHEDULING_PAGE_SUCCESS: 'DELETE_MY_SCHEDULING_PAGE_SUCCESS',
  DELETE_MY_SCHEDULING_PAGE_FAILURE: 'DELETE_MY_SCHEDULING_PAGE_FAILURE',

  DELETE_TEAM_SCHEDULING_PAGE_REQUEST: 'DELETE_TEAM_SCHEDULING_PAGE_REQUEST',
  DELETE_TEAM_SCHEDULING_PAGE_SUCCESS: 'DELETE_TEAM_SCHEDULING_PAGE_SUCCESS',
  DELETE_TEAM_SCHEDULING_PAGE_FAILURE: 'DELETE_TEAM_SCHEDULING_PAGE_FAILURE',

  DELETE_SHARED_SCHEDULING_PAGE_REQUEST:
    'DELETE_SHARED_SCHEDULING_PAGE_REQUEST',
  DELETE_SHARED_SCHEDULING_PAGE_SUCCESS:
    'DELETE_SHARED_SCHEDULING_PAGE_SUCCESS',
  DELETE_SHARED_SCHEDULING_PAGE_FAILURE:
    'DELETE_SHARED_SCHEDULING_PAGE_FAILURE',

  DELETE_ALL_SCHEDULING_PAGE_REQUEST: 'DELETE_ALL_SCHEDULING_PAGE_REQUEST',
  DELETE_ALL_SCHEDULING_PAGE_SUCCESS: 'DELETE_ALL_SCHEDULING_PAGE_SUCCESS',

  CONNECTED_CALENDAR_AT_SCHEDULER: 'CONNECTED_CALENDAR_AT_SCHEDULER',

  UPDATE_SCHEDULING_PAGE_STATUS_REQUEST:
    'UPDATE_SCHEDULING_PAGE_STATUS_REQUEST',
  UPDATE_SCHEDULING_PAGE_STATUS_SUCCESS:
    'UPDATE_SCHEDULING_PAGE_STATUS_SUCCESS',
  UPDATE_SCHEDULING_PAGE_STATUS_FAILURE:
    'UPDATE_SCHEDULING_PAGE_STATUS_FAILURE',

  SET_SCHEDULER_PAGE_SEARCH_TEXT: 'SET_SCHEDULER_PAGE_SEARCH_TEXT',

  SET_SCHEDULER_SORTING: 'SET_SCHEDULER_SORTING',
  SET_ROUTER_SORTING: 'SET_ROUTER_SORTING',
  SET_ROUTER_SEARCH: 'SET_ROUTER_SEARCH',
  UPDATE_EVENT_VIEW_COUNT_REQUEST: 'UPDATE_EVENT_VIEW_COUNT_REQUEST',
  UPDATE_EVENT_VIEW_COUNT_SUCCESS: 'UPDATE_EVENT_VIEW_COUNT_SUCCESS',
  UPDATE_EVENT_VIEW_COUNT_FAILURE: 'UPDATE_EVENT_VIEW_COUNT_FAILURE',

  FETCH_TEAM_USERS_SCHEDULER_REQUEST: 'FETCH_TEAM_USERS_SCHEDULER_REQUEST',
  FETCH_TEAM_USERS_SCHEDULER_SUCCESS: 'FETCH_TEAM_USERS_SCHEDULER_SUCCESS',
  FETCH_TEAM_USERS_SCHEDULER_FAILURE: 'FETCH_TEAM_USERS_SCHEDULER_FAILURE',

  FETCH_SCHEDULER_USERS_INFO_REQUEST: 'FETCH_SCHEDULER_USERS_INFO_REQUEST',
  FETCH_SCHEDULER_USERS_INFO_SUCCESS: 'FETCH_SCHEDULER_USERS_INFO_SUCCESS',
  FETCH_SCHEDULER_USERS_INFO_FAILURE: 'FETCH_SCHEDULER_USERS_INFO_FAILURE',

  SET_SELECTED_EVENT_DATE: 'SET_SELECTED_EVENT_DATE',

  FETCH_SCHEDULER_PUBLIC_LINK_REQUEST: 'FETCH_SCHEDULER_PUBLIC_LINK_REQUEST',
  FETCH_SCHEDULER_PUBLIC_LINK_SUCCESS: 'FETCH_SCHEDULER_PUBLIC_LINK_SUCCESS',
  FETCH_SCHEDULER_PUBLIC_LINK_FAILURE: 'FETCH_SCHEDULER_PUBLIC_LINK_FAILURE'
};

export const ScorecardsActions = {
  FETCH_SCORECARD_TEMPLATES_REQUEST: 'FETCH_SCORECARD_TEMPLATES_REQUEST',
  FETCH_SCORECARD_TEMPLATES_SUCCESS: 'FETCH_SCORECARD_TEMPLATES_SUCCESS',
  FETCH_SCORECARD_TEMPLATES_FAILURE: 'FETCH_SCORECARD_TEMPLATES_FAILURE',

  FETCH_SCORECARD_AI_QUESTION_LIBRARY_REQUEST:
    'FETCH_SCORECARD_AI_QUESTION_LIBRARY_REQUEST',
  FETCH_SCORECARD_AI_QUESTION_LIBRARY_SUCCESS:
    'FETCH_SCORECARD_AI_QUESTION_LIBRARY_SUCCESS',
  FETCH_SCORECARD_AI_QUESTION_LIBRARY_FAILURE:
    'FETCH_SCORECARD_AI_QUESTION_LIBRARY_FAILURE',

  FETCH_SCORECARD_TEMPLATES_QUESTIONS_REQUEST:
    'FETCH_SCORECARD_TEMPLATES_QUESTIONS_REQUEST',
  FETCH_SCORECARD_TEMPLATES_QUESTIONS_SUCCESS:
    'FETCH_SCORECARD_TEMPLATES_QUESTIONS_SUCCESS',
  FETCH_SCORECARD_TEMPLATES_QUESTIONS_FAILURE:
    'FETCH_SCORECARD_TEMPLATES_QUESTIONS_FAILURE',

  FETCH_SINGLE_SCORECARD_TEMPLATE_REQUEST:
    'FETCH_SINGLE_SCORECARD_TEMPLATE_REQUEST',
  FETCH_SINGLE_SCORECARD_TEMPLATE_SUCCESS:
    'FETCH_SINGLE_SCORECARD_TEMPLATE_SUCCESS',
  FETCH_SINGLE_SCORECARD_TEMPLATE_FAILURE:
    'FETCH_SINGLE_SCORECARD_TEMPLATE_FAILURE',

  FETCH_UNUSED_MEETING_TYPES_REQUEST: 'FETCH_UNUSED_MEETING_TYPES_REQUEST',
  FETCH_UNUSED_MEETING_TYPES_SUCCESS: 'FETCH_UNUSED_MEETING_TYPES_SUCCESS',
  FETCH_UNUSED_MEETING_TYPES_FAILURE: 'FETCH_UNUSED_MEETING_TYPES_FAILURE',

  CREATE_SCORECARD_TEMPLATE_REQUEST: 'CREATE_SCORECARD_TEMPLATE_REQUEST',
  CREATE_SCORECARD_TEMPLATE_SUCCESS: 'CREATE_SCORECARD_TEMPLATE_SUCCESS',
  CREATE_SCORECARD_TEMPLATE_FAILURE: 'CREATE_SCORECARD_TEMPLATE_FAILURE',

  CREATE_AI_SCORECARD_TEMPLATE_REQUEST: 'CREATE_AI_SCORECARD_TEMPLATE_REQUEST',
  CREATE_AI_SCORECARD_TEMPLATE_SUCCESS: 'CREATE_AI_SCORECARD_TEMPLATE_SUCCESS',
  CREATE_AI_SCORECARD_TEMPLATE_FAILURE: 'CREATE_AI_SCORECARD_TEMPLATE_FAILURE',

  CLONE_SCORECARD_TEMPLATE_REQUEST: 'CLONE_SCORECARD_TEMPLATE_REQUEST',
  CLONE_SCORECARD_TEMPLATE_SUCCESS: 'CLONE_SCORECARD_TEMPLATE_SUCCESS',
  CLONE_SCORECARD_TEMPLATE_FAILURE: 'CLONE_SCORECARD_TEMPLATE_FAILURE',

  UPDATE_SCORECARD_TEMPLATE_REQUEST: 'UPDATE_SCORECARD_TEMPLATE_REQUEST',
  UPDATE_SCORECARD_TEMPLATE_SUCCESS: 'UPDATE_SCORECARD_TEMPLATE_SUCCESS',
  UPDATE_SCORECARD_TEMPLATE_FAILURE: 'UPDATE_SCORECARD_TEMPLATE_FAILURE',

  UPDATE_AI_SCORECARD_TEMPLATE_REQUEST: 'UPDATE_AI_SCORECARD_TEMPLATE_REQUEST',
  UPDATE_AI_SCORECARD_TEMPLATE_SUCCESS: 'UPDATE_AI_SCORECARD_TEMPLATE_SUCCESS',
  UPDATE_AI_SCORECARD_TEMPLATE_FAILURE: 'UPDATE_AI_SCORECARD_TEMPLATE_FAILURE',

  UPDATE_SCORECARD_TEMPLATE_PURPOSE_REQUEST:
    'UPDATE_SCORECARD_TEMPLATE_PURPOSE_REQUEST',
  UPDATE_SCORECARD_TEMPLATE_PURPOSE_SUCCESS:
    'UPDATE_SCORECARD_TEMPLATE_PURPOSE_SUCCESS',
  UPDATE_SCORECARD_TEMPLATE_PURPOSE_FAILURE:
    'UPDATE_SCORECARD_TEMPLATE_PURPOSE_FAILURE',

  UPDATE_SCORECARD_TEMPLATE_STATE_REQUEST:
    'UPDATE_SCORECARD_TEMPLATE_STATE_REQUEST',
  UPDATE_SCORECARD_TEMPLATE_STATE_SUCCESS:
    'UPDATE_SCORECARD_TEMPLATE_STATE_SUCCESS',
  UPDATE_SCORECARD_TEMPLATE_STATE_FAILURE:
    'UPDATE_SCORECARD_TEMPLATE_STATE_FAILURE',

  UPDATE_SCORECARD_TEMPLATE_TEAM_REQUEST:
    'UPDATE_SCORECARD_TEMPLATE_TEAM_REQUEST',
  UPDATE_SCORECARD_TEMPLATE_TEAM_SUCCESS:
    'UPDATE_SCORECARD_TEMPLATE_TEAM_SUCCESS',
  UPDATE_SCORECARD_TEMPLATE_TEAM_FAILURE:
    'UPDATE_SCORECARD_TEMPLATE_TEAM_FAILURE',

  FETCH_SCORECARDS_REQUEST: 'FETCH_SCORECARDS_REQUEST',
  FETCH_SCORECARDS_SUCCESS: 'FETCH_SCORECARDS_SUCCESS',
  FETCH_SCORECARDS_FAILURE: 'FETCH_SCORECARDS_FAILURE',

  FETCH_SINGLE_SCORECARD_REQUEST: 'FETCH_SINGLE_SCORECARD_REQUEST',
  FETCH_SINGLE_SCORECARD_SUCCESS: 'FETCH_SINGLE_SCORECARD_SUCCESS',
  FETCH_SINGLE_SCORECARD_FAILURE: 'FETCH_SINGLE_SCORECARD_FAILURE',

  CREATE_SCORECARD_REQUEST: 'CREATE_SCORECARD_REQUEST',
  CREATE_SCORECARD_SUCCESS: 'CREATE_SCORECARD_SUCCESS',
  CREATE_SCORECARD_FAILURE: 'CREATE_SCORECARD_FAILURE',

  CREATE_AI_SCORECARD_REQUEST: 'CREATE_AI_SCORECARD_REQUEST',
  CREATE_AI_SCORECARD_SUCCESS: 'CREATE_AI_SCORECARD_SUCCESS',
  CREATE_AI_SCORECARD_FAILURE: 'CREATE_AI_SCORECARD_FAILURE',

  SET_CURRENT_SCORECARD_TEMPLATE_UUID: 'SET_CURRENT_SCORECARD_TEMPLATE_UUID',
  SET_CURRENT_SCORECARD: 'SET_CURRENT_SCORECARD',
  SET_SCORECARD_UUID_TO_FETCH: 'SET_SCORECARD_UUID_TO_FETCH',

  EMPTY_SCORECARDS: 'EMPTY_SCORECARDS',
  EMPTY_SCORECARD_QUESTIONS_ANSWERS: 'EMPTY_SCORECARD_QUESTIONS_ANSWERS',
  EMPTY_SCORECARDS_BY_QUESTION_CHOICE_UUID:
    'EMPTY_SCORECARDS_BY_QUESTION_CHOICE_UUID',
  EMPTY_AUTO_GENERATED_QUESTION_CHOICES:
    'EMPTY_AUTO_GENERATED_QUESTION_CHOICES',
  EMPTY_SCORECARD_UUID_TO_FETCH: 'EMPTY_SCORECARD_UUID_TO_FETCH',
  EMPTY_SCORECARD_TEMPLATE_ERRORS: 'EMPTY_SCORECARD_TEMPLATE_ERRORS',

  DELETE_SCORECARD_TEMPLATE_REQUEST: 'DELETE_SCORECARD_TEMPLATE_REQUEST',
  DELETE_SCORECARD_TEMPLATE_SUCCESS: 'DELETE_SCORECARD_TEMPLATE_SUCCESS',
  DELETE_SCORECARD_TEMPLATE_FAILURE: 'DELETE_SCORECARD_TEMPLATE_FAILURE',

  FETCH_AUTO_GENERATED_QUESTION_CHOICES_REQUEST:
    'FETCH_AUTO_GENERATED_QUESTION_CHOICES_REQUEST',
  FETCH_AUTO_GENERATED_QUESTION_CHOICES_SUCCESS:
    'FETCH_AUTO_GENERATED_QUESTION_CHOICES_SUCCESS',
  FETCH_AUTO_GENERATED_QUESTION_CHOICES_FAILURE:
    'FETCH_AUTO_GENERATED_QUESTION_CHOICES_FAILURE',

  ANSWER_SCORECARD_QUESTION_REQUEST: 'ANSWER_SCORECARD_QUESTION_REQUEST',
  ANSWER_SCORECARD_QUESTION_SUCCESS: 'ANSWER_SCORECARD_QUESTION_SUCCESS',
  ANSWER_SCORECARD_QUESTION_FAILURE: 'ANSWER_SCORECARD_QUESTION_FAILURE',

  FETCH_SCORECARD_ANSWERS_REQUEST: 'FETCH_SCORECARD_ANSWERS_REQUEST',
  FETCH_SCORECARD_ANSWERS_SUCCESS: 'FETCH_SCORECARD_ANSWERS_SUCCESS',
  FETCH_SCORECARD_ANSWERS_FAILURE: 'FETCH_SCORECARD_ANSWERS_FAILURE',

  SCORECARD_FINAL_SUBMIT_REQUEST: 'SCORECARD_FINAL_SUBMIT_REQUEST',
  SCORECARD_FINAL_SUBMIT_SUCCESS: 'SCORECARD_FINAL_SUBMIT_SUCCESS',
  SCORECARD_FINAL_SUBMIT_FAILURE: 'SCORECARD_FINAL_SUBMIT_FAILURE',

  EDIT_SCORECARD_REQUEST: 'EDIT_SCORECARD_REQUEST',
  EDIT_SCORECARD_SUCCESS: 'EDIT_SCORECARD_SUCCESS',
  EDIT_SCORECARD_FAILURE: 'EDIT_SCORECARD_FAILURE',

  DELETE_SCORECARD_REQUEST: 'DELETE_SCORECARD_REQUEST',
  DELETE_SCORECARD_SUCCESS: 'DELETE_SCORECARD_SUCCESS',
  DELETE_SCORECARD_FAILURE: 'DELETE_SCORECARD_FAILURE',

  REQUEST_SCORE_REQUEST: 'REQUEST_SCORE_REQUEST',
  REQUEST_SCORE_SUCCESS: 'REQUEST_SCORE_SUCCESS',
  REQUEST_SCORE_FAILURE: 'REQUEST_SCORE_FAILURE',

  POST_SCORE_OVERALL_FEEDBACK_REQUEST: 'POST_SCORE_OVERALL_FEEDBACK_REQUEST',
  POST_SCORE_OVERALL_FEEDBACK_SUCCESS: 'POST_SCORE_OVERALL_FEEDBACK_SUCCESS',
  POST_SCORE_OVERALL_FEEDBACK_FAILURE: 'POST_SCORE_OVERALL_FEEDBACK_FAILURE',

  POST_SCORE_ANSWER_COMMENT_REQUEST: 'POST_SCORE_ANSWER_COMMENT_REQUEST',
  POST_SCORE_ANSWER_COMMENT_SUCCESS: 'POST_SCORE_ANSWER_COMMENT_SUCCESS',
  POST_SCORE_ANSWER_COMMENT_FAILURE: 'POST_SCORE_ANSWER_COMMENT_FAILURE',

  UPDATE_SCORE_OVERALL_FEEDBACK_REQUEST:
    'UPDATE_SCORE_OVERALL_FEEDBACK_REQUEST',
  UPDATE_SCORE_OVERALL_FEEDBACK_SUCCESS:
    'UPDATE_SCORE_OVERALL_FEEDBACK_SUCCESS',
  UPDATE_SCORE_OVERALL_FEEDBACK_FAILURE:
    'UPDATE_SCORE_OVERALL_FEEDBACK_FAILURE',

  UPDATE_SCORE_ANSWER_COMMENT_REQUEST: 'UPDATE_SCORE_ANSWER_COMMENT_REQUEST',
  UPDATE_SCORE_ANSWER_COMMENT_SUCCESS: 'UPDATE_SCORE_ANSWER_COMMENT_SUCCESS',
  UPDATE_SCORE_ANSWER_COMMENT_FAILURE: 'UPDATE_SCORE_ANSWER_COMMENT_FAILURE',

  DELETE_SCORE_OVERALL_FEEDBACK_REQUEST:
    'DELETE_SCORE_OVERALL_FEEDBACK_REQUEST',
  DELETE_SCORE_OVERALL_FEEDBACK_SUCCESS:
    'DELETE_SCORE_OVERALL_FEEDBACK_SUCCESS',
  DELETE_SCORE_OVERALL_FEEDBACK_FALIURE:
    'DELETE_SCORE_OVERALL_FEEDBACK_FAILURE',

  DELETE_SCORE_ANSWER_COMMENT_REQUEST: 'DELETE_SCORE_ANSWER_COMMENT_REQUEST',
  DELETE_SCORE_ANSWER_COMMENT_SUCCESS: 'DELETE_SCORE_ANSWER_COMMENT_SUCCESS',
  DELETE_SCORE_ANSWER_COMMENT_FAILURE: 'DELETE_SCORE_ANSWER_COMMENT_FAILURE',

  POST_OVERALL_FEEDBACK_REACTION_REQUEST:
    'POST_OVERALL_FEEDBACK_REACTION_REQUEST',
  POST_OVERALL_FEEDBACK_REACTION_SUCCESS:
    'POST_OVERALL_FEEDBACK_REACTION_SUCCESS',
  POST_OVERALL_FEEDBACK_REACTION_FAILURE:
    'POST_OVERALL_FEEDBACK_REACTION_FAILURE',

  POST_COMMENT_REACTION_REQUEST: 'POST_COMMENT_REACTION_REQUEST',
  POST_COMMENT_REACTION_SUCCESS: 'POST_COMMENT_REACTION_SUCCESS',
  POST_COMMENT_REACTION_FAILURE: 'POST_COMMENT_REACTION_FAILURE',

  DELETE_COMMENT_REACTION_REQUEST: 'DELETE_COMMENT_REACTION_REQUEST',
  DELETE_COMMENT_REACTION_SUCCESS: 'DELETE_COMMENT_REACTION_SUCCESS',
  DELETE_COMMENT_REACTION_FAILURE: 'DELETE_COMMENT_REACTION_FAILURE',

  FETCH_USER_SCORES_REQUEST: 'FETCH_USER_SCORES_REQUEST',
  FETCH_USER_SCORES_SUCCESS: 'FETCH_USER_SCORES_SUCCESS',
  FETCH_USER_SCORES_FAILURE: 'FETCH_USER_SCORES_FAILURE',

  FETCH_USER_REQUESTS_REQUEST: 'FETCH_USER_REQUESTS_REQUEST',
  FETCH_USER_REQUESTS_SUCCESS: 'FETCH_USER_REQUESTS_SUCCESS',
  FETCH_USER_REQUESTS_FAILURE: 'FETCH_USER_REQUESTS_FAILURE',

  FETCH_SCORECARDS_BY_QUESTION_CHOICE_UUID_REQUEST:
    'FETCH_SCORECARDS_BY_QUESTION_CHOICE_UUID_REQUEST',
  FETCH_SCORECARDS_BY_QUESTION_CHOICE_UUID_SUCCESS:
    'FETCH_SCORECARDS_BY_QUESTION_CHOICE_UUID_SUCCESS',
  FETCH_SCORECARDS_BY_QUESTION_CHOICE_UUID_FAILURE:
    'FETCH_SCORECARDS_BY_QUESTION_CHOICE_UUID_FAILURE',

  SET_CURRENT_PERFORMANCE_PAGE: 'SET_CURRENT_PERFORMANCE_PAGE',
  SET_CURRENT_PERFORMANCE_MEMBER_UUID: 'SET_CURRENT_PERFORMANCE_MEMBER_UUID',
  SET_CURRENT_PERFORMANCE_SCORECARD_UUID:
    'SET_CURRENT_PERFORMANCE_SCORECARD_UUID'
};

export const SlackActions = {
  FETCH_AVAILABLE_SLACK_CHANNELS_REQUEST:
    'FETCH_AVAILABLE_SLACK_CHANNELS_REQUEST',
  FETCH_AVAILABLE_SLACK_CHANNELS_SUCCESS:
    'FETCH_AVAILABLE_SLACK_CHANNELS_SUCCESS',
  FETCH_AVAILABLE_SLACK_CHANNELS_FAILURE:
    'FETCH_AVAILABLE_SLACK_CHANNELS_FAILURE',

  SYNC_SLACK_CHANNELS_REQUEST: 'SYNC_SLACK_CHANNELS_REQUEST',
  SYNC_SLACK_CHANNELS_SUCCESS: 'SYNC_SLACK_CHANNELS_SUCCESS',
  SYNC_SLACK_CHANNELS_FAILURE: 'SYNC_SLACK_CHANNELS_FAILURE',

  CREATE_SLACK_ALERT_REQUEST: 'CREATE_SLACK_ALERT_REQUEST',
  CREATE_SLACK_ALERT_SUCCESS: 'CREATE_SLACK_ALERT_SUCCESS',
  CREATE_SLACK_ALERT_FAILURE: 'CREATE_SLACK_ALERT_FAILURE',

  REMOVE_SLACK_ALERT_REQUEST: 'REMOVE_SLACK_ALERT_REQUEST',
  REMOVE_SLACK_ALERT_SUCCESS: 'REMOVE_SLACK_ALERT_SUCCESS',
  REMOVE_SLACK_ALERT_FAILURE: 'REMOVE_SLACK_ALERT_FAILURE'
};

export const ForecastActions = {
  FETCH_FORECAST_ACCESS_CONTROL_SETTINGS_REQUEST:
    'FETCH_FORECAST_ACCESS_CONTROL_SETTINGS_REQUEST',
  FETCH_FORECAST_ACCESS_CONTROL_SETTINGS_SUCCESS:
    'FETCH_FORECAST_ACCESS_CONTROL_SETTINGS_SUCCESS',
  FETCH_FORECAST_ACCESS_CONTROL_SETTINGS_FAILURE:
    'FETCH_FORECAST_ACCESS_CONTROL_SETTINGS_FAILURE',

  UPDATE_FORECAST_ACCESS_CONTROL_SETTINGS_REQUEST:
    'UPDATE_FORECAST_ACCESS_CONTROL_SETTINGS_REQUEST',
  UPDATE_FORECAST_ACCESS_CONTROL_SETTINGS_SUCCESS:
    'UPDATE_FORECAST_ACCESS_CONTROL_SETTINGS_SUCCESS',
  UPDATE_FORECAST_ACCESS_CONTROL_SETTINGS_FAILURE:
    'UPDATE_FORECAST_ACCESS_CONTROL_SETTINGS_FAILURE',

  UPDATE_FORECAST_ACCESS_CONTROL_SETTING_TEAMS_REQUEST:
    'UPDATE_FORECAST_ACCESS_CONTROL_SETTING_TEAMS_REQUEST',
  UPDATE_FORECAST_ACCESS_CONTROL_SETTING_TEAMS_SUCCESS:
    'UPDATE_FORECAST_ACCESS_CONTROL_SETTING_TEAMS_SUCCESS',
  UPDATE_FORECAST_ACCESS_CONTROL_SETTING_TEAMS_FAILURE:
    'UPDATE_FORECAST_ACCESS_CONTROL_SETTING_TEAMS_FAILURE'
};

export const DownloadActions = {
  ADD_NEW_DOWNLOADS: 'ADD_NEW_DOWNLOADS',

  DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'DOWNLOAD_FAILURE',

  CANCEL_DOWNLOADS: 'CANCEL_DOWNLOADS'
};
