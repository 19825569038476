import camelize from 'camelize';
import { baseCreateApi } from 'services';

export const smartCategoriesApi = baseCreateApi.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getSmartCategories: builder.query({
      providesTags: ['SmartCategories'],
      query: () => ({
        method: 'GET',
        url: '/api/v1/custom_category/'
      }),
      transformResponse: camelize
    }),
    // This endpoint is used to generate a prompt for a new smart category.
    // Since it does not update any state, it does not need to invalidate any tags.
    createSmartCategoryPrompt: builder.mutation({
      query: ({ payload }) => ({
        url: '/api/v1/custom_category/generate_description/',
        method: 'POST',
        data: payload
      }),
      transformResponse: response => camelize(response)
    }),
    createUnifiedSmartCategory: builder.mutation({
      invalidatesTags: ['SmartCategories'],
      query: ({ payload }) => ({
        url: '/api/v1/custom_category/create_with_associations/',
        method: 'POST',
        data: payload
      }),
      transformResponse: response => camelize(response)
    }),
    updateUnifiedSmartCategory: builder.mutation({
      invalidatesTags: ['SmartCategories'],
      query: ({ categoryUuid, payload }) => ({
        url: `/api/v1/custom_category/${categoryUuid}/update_with_associations/`,
        method: 'PATCH',
        data: payload
      }),
      transformResponse: response => camelize(response)
    })
  })
});

export const {
  useGetSmartCategoriesQuery,
  useCreateSmartCategoryPromptMutation,
  useCreateUnifiedSmartCategoryMutation,
  useUpdateUnifiedSmartCategoryMutation
} = smartCategoriesApi;
