import { ReactElement, useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';

import AvomaLink from 'components/Common/AvomaLink';

import L10n from 'helpers/L10n';
import { snakeCaseToCapitalizedString } from 'helpers/formatting';
import { L10nFeatureGating } from 'helpers/l10n/L10nFeatureGating';
import { isAdmin } from 'helpers/user';

import { PLAN_NAMES } from 'constants/billing';

import { ReactComponent as UpgradeIcon } from 'images/ic_upgrade_sparkle.svg';
import { ReactComponent as RequestLicenseIllustration } from 'images/request_license.svg';

import AvomaButton from '../AvomaButton';
import AvomaDialog from '../AvomaDialog';
import ButtonUnstyled from '../ButtonUnstyled';
import EmailStep from './EmailStep';

type AvomaFeatureGateDialogProps = {
  title: string;
  trigger: ReactElement;
  feature?: string;
  upgradeImage?: string;
  license?: string;
};

type DialogStep = 'base' | 'request' | 'email';

const AvomaFeatureGateDialog = ({
  title,
  trigger,
  feature = 'this feature',
  upgradeImage,
  license = PLAN_NAMES.premium
}: AvomaFeatureGateDialogProps) => {
  const isCurrentUserAdmin = isAdmin();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogStep, setDialogStep] = useState<DialogStep>('base');

  useEffect(() => {
    if (!upgradeImage) {
      setDialogStep('request');
    }
  }, [upgradeImage]);

  const handleToggleDialog = (): void => {
    setDialogOpen(!dialogOpen);
  };

  const handleCloseDialog = (): void => {
    setDialogOpen(false);
    handleResetSteps();
  };

  const handleResetSteps = (): void => {
    if (!upgradeImage) {
      setDialogStep('request');
    } else {
      setDialogStep('base');
    }
  };

  const getDialogTitle = (): string => {
    switch (dialogStep) {
      case 'base':
        return title;
      case 'request':
        return L10nFeatureGating.requestTitle;
      case 'email':
        return L10nFeatureGating.emailTitle;
      default:
        return `Upgrade license to access ${feature}`;
    }
  };

  const renderTitle = (): JSX.Element => (
    <div className='flex items-center gap-x-2'>
      {upgradeImage && dialogStep !== 'base' && (
        <ButtonUnstyled
          noDefaultStyles
          className='flex cursor-pointer appearance-none items-center justify-center rounded bg-transparent p-2 hover:bg-gainsboro focus:outline-none'
          onClick={handleResetSteps}
        >
          <MdArrowBack className='h-4 w-4 text-lg' />
        </ButtonUnstyled>
      )}
      {getDialogTitle()}
    </div>
  );

  const renderFooter = (): JSX.Element | null => {
    if (dialogStep === 'request') {
      return (
        <div className='flex w-full items-center justify-end mb-2 gap-x-4'>
          <AvomaButton
            label={L10n.general.cancel}
            onClick={handleCloseDialog}
            secondary
            small
          />
          <AvomaButton
            label={L10nFeatureGating.requestLicenseCta}
            onClick={() => {
              setDialogStep('email');
            }}
            primary
            small
          />
        </div>
      );
    }

    return null;
  };

  const renderLicenseCta = (): JSX.Element => {
    // If the current user is an admin, show a button to navigate to the licenses page
    if (isCurrentUserAdmin) {
      return (
        <AvomaLink to='/settings/licenses'>
          <AvomaButton
            primary
            label={L10n.featureControl.ctaTitle}
            onClick={handleCloseDialog}
          />
        </AvomaLink>
      );
    }

    // If the current user is not an admin, go through the request flow
    return (
      <AvomaButton
        label={
          <div className='flex items-center gap-x-2'>
            <UpgradeIcon />
            {L10n.licenses.requestTitle}
          </div>
        }
        onClick={() => {
          setDialogStep('request');
        }}
        className='w-fit'
        primary
      />
    );
  };

  const renderBaseStep = (): JSX.Element => (
    <div className='flex flex-col sm:flex-row gap-x-4 items-center'>
      <div className='w-full sm:w-auto'>
        <img
          src={upgradeImage}
          alt={L10nFeatureGating.upgradeTitle}
          className='w-full border border-gainsboro rounded-md pointer-events-none'
        />
      </div>
      <div className='mt-2 sm:mt-0 sm:ml-4 flex flex-col gap-y-7'>
        <span className='text-h5 font-bold'>
          Please upgrade to a {license} Plan to start using {feature}
        </span>
        {renderLicenseCta()}
      </div>
    </div>
  );

  const renderRequestStep = (): JSX.Element => (
    <div className='flex flex-col sm:flex-row gap-4 items-center'>
      <div className='w-full sm:w-auto'>
        <RequestLicenseIllustration className='pointer-events-none w-full min-w-[340px]' />
      </div>
      <div className='mt-2 sm:mt-0 sm:ml-4 flex flex-col gap-y-5'>
        <span>{L10n.requestAdmin.requestModalText1}</span>
        <span>{L10n.requestAdmin.requestModalText2}</span>
        <span>{L10n.requestAdmin.requestModalText3}</span>
      </div>
    </div>
  );

  return (
    <AvomaDialog
      controlled
      open={dialogOpen}
      setOpen={handleToggleDialog}
      closeCleanupHandler={handleResetSteps}
      trigger={trigger}
      title={renderTitle()}
      footer={renderFooter()}
      contentClasses='max-w-[860px]'
    >
      {dialogStep === 'base' && renderBaseStep()}
      {dialogStep === 'request' && renderRequestStep()}
      {dialogStep === 'email' && (
        <EmailStep
          requestedFeature={snakeCaseToCapitalizedString(feature)}
          handleCancel={handleCloseDialog}
        />
      )}
    </AvomaDialog>
  );
};

export default AvomaFeatureGateDialog;
