import { ECrmFieldEditAccess } from 'types/crm';

export const L10nSettings = /** @type {const} */ ({
  zoomSettingNoAccess:
    'You do not have access to modify the settings in Zoom, get in touch with your Zoom admins to modify the same.',
  crmFAQs: 'CRM FAQs',
  recordingAndConferencing: {
    auditTrail: {
      lastUpdatedBy: 'Last updated by',
      on: 'on'
    },
    saveAndApplySettings: 'Save & Apply settings',
    avomaBot: 'Avoma Assistant (Bot)',
    successMsg: 'Settings saved successfully.',
    orgPoliciesSuccessMsg: 'Settings saved successfully, users notified.',
    errorMsg: 'Something went wrong. Please try again later.',
    forBasicFreeLicense: 'For "Basic" (Free) license holders',
    forPaidLicense: 'For "Paid" license holders',
    connected: 'connected',
    googleMeet: 'Google Meet',
    goToMeeting: 'GoTo Meeting',
    microsoftTeams: 'Microsoft Teams',
    willRecordYourConversation: 'will record your conversations.',
    recordingSettingsDisclaimer: {
      avoma: 'Avoma',
      will: 'will',
      notRecord: 'not record',
      settingOff: 'as recording settings are turned off.'
    },
    zoomCard: {
      zoomHelpCardTitle: 'Using Zoom Conferencing with Avoma',
      zoomCloud: 'Zoom Cloud Recording',
      avomaZoomApp: 'Avoma Zoom App',
      helperText1:
        'Using Zoom Cloud recording you can avoid Avoma Assistant (Bot) to be joining to record the meetings.',
      helperText2:
        'Install Avoma Zoom App for in-app collaborative note-taking experience.',
      helperText3:
        'Take notes and bookmark important moments with your team during the meeting from the Zoom desktop client.',
      recordVia: 'Record via',
      cloud: 'Zoom Cloud',
      install: 'Install',
      avZoomApp: 'Avoma Zoom App',
      zoomClient: 'to take notes in Zoom Client ?',
      zoomCloudIsOff: 'Zoom Cloud recording is turned OFF.',
      zoomAppInstalled: 'Avoma Zoom app installed for in-app note taking.',
      zoomAppNotInstalled: 'Avoma Zoom app is not installed.'
    },
    webExCard: {
      title: 'WebEx Conferencing',
      recordViaWebex: 'Record via WebEx Cloud?',
      isNotSupported: 'is not supported.'
    },
    msCloudCard: {
      title: 'Microsoft Cloud Conferencing',
      recordViaMsCloud: 'Record via Microsoft Cloud?',
      isSupported: 'will record your conversations.'
    },
    gmCloudCard: {
      title: 'Google Meet Cloud Conferencing',
      recordViaGmeetCloud: 'Record via Google Meet Cloud?',
      isSupported: 'will record your conversations.'
    },
    host: 'Host',
    participant: 'Participant',
    botVsCloudModal: {
      bot: 'Avoma Assistant (Bot)',
      cloud: 'Cloud Recording',
      title: 'Avoma Assistant (Bot) vs Cloud Recording',
      recordedBy: 'Recorded by',
      thirdPartyBot: '3rd party bot as participant',
      no: 'No',
      both: 'both',
      recordingAnnouncement: 'Recording announcement',
      recordsOnlyWhen: 'Records only when you are the',
      recordsWhen: 'Records only when you are the',
      cloudRecordingPoints: {
        confAppCloud: 'Conferencing app cloud',
        confAppCloudSubText:
          'Meetings will be recorded by Zoom or WebEx Cloud and they will be sent over to Avoma for processing',
        forEachParticipant: 'for each participant',
        willRecord: 'will record when you’re participant.'
      },
      avomaBotPoints: {
        andA: 'and a',
        whenBotJoins: 'when Bot joins',
        whenBotJoinsSubText:
          'i.e. Participant who join after the Bot won’t hear the recording announcement.',
        avomaBot: 'Avoma’s Bot'
      }
    },
    orgRecordingSettings: {
      leavingModal: {
        title: 'Continue without saving settings?',
        text: 'You have unsaved changes and if you proceed without saving them they will be lost forever.'
      },
      title: 'Recording settings for all users of the organization',
      bannerText:
        'If below organization recording settings are not locked then users can modify their individual recording settings.',
      toggleConfirmationModal: {
        changeWillApply:
          'This change will apply to all users in your organization and',
        all: 'ALL',
        any: 'ANY',
        not: 'NOT',
        meetingWill: 'meeting will',
        beRecorded:
          'be recorded and users will be notified via email with this change.',
        recordedWhen: 'meetings will be recorded when they are',
        informedViaEmail:
          'of the meetings and users will be notified via email with this change.',
        ifYouUndo:
          'If you UNDO this change, User’s previous recording settings will be reverted.',
        ifLocked:
          'If any of the recording settings are locked, users will not be able to make any changes on their own.'
      },
      lockConfirmationModal: {
        thisChange:
          'This change will apply to all users in your organization and',
        any: 'ANY',
        meetingWill: 'meeting will',
        not: 'NOT',
        notifiedViaEmail:
          'be recorded and users will be notified via email with this change.',
        ifLocked:
          'If any of the recording settings are locked, users will not be able to make any changes on their own.'
      },
      unLockConfirmationModal: {
        parah1:
          'You’re locking the recording preference for all users of the organization and they will not be able to modify them on their own.',
        applyToAll:
          'These preferences will apply to ALL users in your organization and',
        all: 'ALL',
        recordedWhen: 'meetings will be recorded when they are',
        notifiedViaEmail:
          'of the meetings and users will be notified via email with this change.'
      }
    },
    editUserModal: {
      banner: {
        avomaWill: 'Avoma will',
        record: 'record',
        notRecord: 'not record',
        all: "user's all",
        internalExternal: 'internal/external',
        conversations: 'conversations.',
        external: 'external',
        internal: 'internal'
      }
    },
    maleVoice: 'Male Voice',
    femaleVoice: 'Female Voice',
    goToMyZoomSeetings: 'Go to my Zoom settings'
  },
  timezone: {
    label: 'Time zone',
    description:
      "Setting your time zone helps you localize your deals and related dates to your company's location and timezone. The filters applied on date fields in deals, pipeline and forecast dashboard will use this timezone.",
    updatedSuccessfully: 'Timezone updated successfully'
  },
  crm: {
    accessPermissions: 'Access Permissions',
    editAccess: {
      [ECrmFieldEditAccess.everyone]: 'Everyone can view and edit',
      [ECrmFieldEditAccess.adminOnly]: 'Only Admins can edit',
      [ECrmFieldEditAccess.viewOnly]: 'Read only'
    },
    crmReadOnlyPropertyHelpText:
      'This property is marked read-only in the CRM. Access permissions for such properties cannot be modified.',
    avomaMappedFieldAlwaysSynced:
      'If a CRM property is mapped to an Avoma property it is always synced back to CRM.',
    avomaReadOnlyPropertyHelpText:
      'This property is marked read-only in Avoma. Access permissions for such properties cannot be modified.',
    readOnly: 'Read only',
    makeReadOnlyConfirmText: {
      cannotBeMapped:
        "'Read-only' properties cannot be mapped to an Avoma property or smart category.",
      willRemoveMapping:
        "Changing a property to 'Read-only' will remove the mapping, and no further updates will be sent to this CRM property.",
      doYouWishToContinue: 'Do you wish to continue?'
    },
    smartTopicExtraction: {
      label: 'Smart Topic Extraction',
      toggleLabel: 'Summarize Smart Topic notes in the deals dashboard',
      helpText:
        'When enabled, notes from various meetings and emails under each Smart Topic will be consolidated into a single summary. However, date sensitive Smart Topics like Action-items and Follow-up meetings will never be summarized.',
      updatedSuccessfully: enabled =>
        `Summarization ${enabled ? 'enabled' : 'disabled'}.`,
      confirmTitle: 'Enable Summarization ?',
      confirmText1:
        'Enabling summarization won’t automatically update historical data under AI methodology and Smart Topics in the deals dashboard.',
      confirmText2:
        'However, summarization will be triggered when there are newly extracted insights from meetings and emails related to a deal.'
    },
    changeAccessPermissionToReadOnly: 'Change access permission to Read Only?',
    saveChangesAndStartSyncing: 'Save Changes & Start Syncing'
  },
  settings: 'Settings',
  yourAccount: 'Your Account',
  organization: 'Organization',
  collapseSidebar: 'Collapse Settings sidebar',
  expandSidebar: 'Expand Settings sidebar'
});
