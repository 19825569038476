import { Editor, Node, Path, Range, Transforms } from 'slate';

import { getAboveByType } from '../../Queries/getAboveByType';
import { getParent } from '../../Queries/getParent';
import { isBlockTextEmptyAfterSelection } from '../../Queries/isBlockTextEmptyAfterSelection';
import { isFirstChild } from '../../Queries/isFirstChild';
import {
  BLANK_CHECKLIST,
  TYPE_CHECKLIST_ITEM,
  TYPE_PARAGRAPH
} from '../../Types';
import { TYPE_LIST_ITEM } from '../types';

/**
 * Insert list item if selection in li>p.
 */
export const insertListItem = (
  editor,
  additionalTypes = [],
  emptyTypes = [],
  isChecklist = false
) => {
  if (editor.selection) {
    let paragraphEntry = getAboveByType(editor, TYPE_PARAGRAPH);

    if (!paragraphEntry) {
      for (let i = 0; i < additionalTypes.length; i += 1) {
        paragraphEntry = getAboveByType(editor, additionalTypes[i]);
        if (paragraphEntry) {
          break;
        }
      }
    }

    if (!paragraphEntry) {
      return;
    }

    const [paragraphNode, paragraphPath] = paragraphEntry;

    const listItemEntry = getParent(editor, paragraphPath);

    if (!listItemEntry) {
      return;
    }

    const [listItemNode, listItemPath] = listItemEntry;

    if (![TYPE_LIST_ITEM, TYPE_CHECKLIST_ITEM].includes(listItemNode.type)) {
      return;
    }

    if (!Range.isCollapsed(editor.selection)) {
      Transforms.delete(editor);
    }

    const isStart =
      Editor.isStart(editor, editor.selection.focus, paragraphPath) &&
      !emptyTypes.includes(paragraphNode.type) &&
      isFirstChild(paragraphPath);
    const isEnd = isBlockTextEmptyAfterSelection(editor);

    const nextParagraphPath = Path.next(paragraphPath);
    const nextListItemPath = Path.next(listItemPath);

    /**
     * If start, insert a list item before
     */
    const blankListItem = {
      type: isChecklist ? TYPE_CHECKLIST_ITEM : TYPE_LIST_ITEM,
      children: [{ type: TYPE_PARAGRAPH, children: [{ text: '' }] }]
    };

    if (isStart) {
      Transforms.insertNodes(editor, blankListItem, { at: listItemPath });

      return true;
    }

    /**
     * If not end, split nodes, wrap a list item on the new paragraph and move it to the next list item
     */
    if (!isEnd) {
      Transforms.splitNodes(editor, { at: editor.selection });
      const listItem = isChecklist
        ? {
            type: TYPE_CHECKLIST_ITEM,
            children: []
            // checked: false
          }
        : {
            type: TYPE_LIST_ITEM,
            children: []
          };
      Transforms.wrapNodes(editor, listItem, { at: nextParagraphPath });
      Transforms.moveNodes(editor, {
        at: nextParagraphPath,
        to: nextListItemPath
      });
    } else {
      /**
       * If end, insert a list item after and select it
       */
      Transforms.insertNodes(editor, blankListItem, { at: nextListItemPath });
      Transforms.select(editor, nextListItemPath);
    }

    /**
     * If there is a list in the list item, move it to the next list item
     */
    let nodeDoesntExist = false;

    try {
      Node.get(editor, nextParagraphPath);
    } catch (e) {
      nodeDoesntExist = true;
    }

    if (!nodeDoesntExist && listItemNode.children.length > 1) {
      Transforms.moveNodes(editor, {
        at: nextParagraphPath,
        to: nextListItemPath.concat(1)
      });
    }

    return true;
  }
};
