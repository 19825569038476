import PropTypes from 'prop-types';
import React from 'react';
import stylePropType from 'react-style-proptype';
import { twMerge } from 'tailwind-merge';

import AvomaSelect from 'components/Common/AvomaSelect';
import AvomaTooltip from 'components/Common/AvomaTooltip';
import ButtonUnstyled from 'components/Common/ButtonUnstyled';
import Spinner from 'components/Common/Spinner';
import Switch from 'components/Common/Switch';
import HelpPopover from 'components/Common/popovers/HelpPopover';

import { ReactComponent as LockIcon } from 'images/ic_lock_blue.svg';
import { ReactComponent as UnlockIcon } from 'images/ic_unlock_grey.svg';

import Tokens from 'styles/tokens';

const SettingToggle = ({
  title,
  subtext,
  subtitle,
  toggled = false,
  disabled = false,
  onToggle,
  style,
  titleStyle,
  className,
  showLock = false,
  onClickLockIcon,
  isLocked, // on which variable should the lock/unlock toggle happen,
  isLockDisabled,
  lockTooltip,
  unlockTooltip,
  disabledLockTooltip,
  lockTooltipPosition,
  lockIconStyle,
  lockContainerStyle,
  switchTooltip = '',
  showPopoverLabel = true,
  customPopoverIcon,
  helpPopoverTitle,
  helpPopoverContent,
  subtitleStyles,
  meetingOption = '',
  setMeetingOption,
  recordingPreferencesOptions = [],
  isSmallToggle = false,
  loading = false
}) => (
  <section
    className={twMerge('flex justify-center gap-4', className)}
    style={style}
  >
    <div className={twMerge('flex justify-end', meetingOption && 'mt-1.5')}>
      <Switch
        medium={!isSmallToggle}
        small={isSmallToggle}
        toggled={toggled}
        onToggle={onToggle}
        color={Tokens.colors.blue}
        disabled={disabled}
        tooltip={switchTooltip}
        wrapperClassName='items-start mt-1'
        tooltipPosition='bottom-right'
      />
    </div>

    <div className='flex w-full flex-1 flex-col items-start justify-center'>
      <div className='flex items-center'>
        <div className='text-base font-bold text-gunmetal' style={titleStyle}>
          {title}
        </div>
        {loading && <Spinner type='small' />}

        {meetingOption && (
          <React.Fragment>
            <span className='px-1'>{subtext}</span>

            <AvomaSelect
              items={recordingPreferencesOptions}
              value={meetingOption}
              onValueChange={value => setMeetingOption(value)}
              triggerClassName='w-auto mx-1 font-bold'
              contentPosition='popper'
              disabled={!toggled || disabled}
            />
          </React.Fragment>
        )}

        {helpPopoverTitle && helpPopoverContent && (
          <HelpPopover
            showLabel={showPopoverLabel}
            customIcon={customPopoverIcon}
            title={helpPopoverTitle}
            content={helpPopoverContent}
            buttonStyles={{ paddingLeft: Tokens.spacing.one }}
          />
        )}
      </div>
      <div
        className='w-full whitespace-pre-line text-sm text-silver'
        style={subtitleStyles}
      >
        {subtitle}
      </div>
    </div>

    {showLock && (
      <AvomaTooltip
        tooltipPosition={lockTooltipPosition}
        {...(isLockDisabled
          ? { tooltip: disabledLockTooltip }
          : { tooltip: isLocked ? lockTooltip : unlockTooltip })}
      >
        <ButtonUnstyled
          onClick={!isLockDisabled ? onClickLockIcon : undefined}
          disabled={isLockDisabled}
          style={{
            ...styles.lockContainer,
            ...lockContainerStyle,
            ...(isLockDisabled && { cursor: 'not-allowed', opacity: 0.5 })
          }}
        >
          {isLocked ? (
            <LockIcon className='h-6 w-6' style={lockIconStyle} />
          ) : (
            <UnlockIcon className='h-6 w-6' style={lockIconStyle} />
          )}
        </ButtonUnstyled>
      </AvomaTooltip>
    )}
  </section>
);

const styles = {
  lockContainer: {
    marginLeft: Tokens.spacing.two,
    cursor: 'pointer'
  }
};

SettingToggle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLockDisabled: PropTypes.bool,
  toggled: PropTypes.bool,
  showPopoverLabel: PropTypes.bool,
  showNALabel: PropTypes.bool,
  style: PropTypes.object,
  titleStyle: PropTypes.object,
  orgPolicyIsSet: PropTypes.bool,
  showLock: PropTypes.bool,
  isLocked: PropTypes.bool,
  onClickLockIcon: PropTypes.func,
  lockTooltip: PropTypes.string,
  unlockTooltip: PropTypes.string,
  disabledLockTooltip: PropTypes.string,
  lockTooltipPosition: PropTypes.string,
  lockIconStyle: stylePropType,
  lockContainerStyle: stylePropType,
  subtitleStyles: stylePropType,
  className: PropTypes.string,
  switchTooltip: PropTypes.string,
  customPopoverIcon: PropTypes.node,
  helpPopoverTitle: PropTypes.string,
  helpPopoverContent: PropTypes.string,
  yesNoLabel: PropTypes.bool,
  meetingOption: PropTypes.string,
  setMeetingOption: PropTypes.func,
  recordingPreferencesOptions: PropTypes.array,
  subtext: PropTypes.string,
  isSmallToggle: PropTypes.bool,
  loading: PropTypes.bool
};

export default SettingToggle;
