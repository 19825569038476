import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  clearVariationErrorMessage as clearVariationErrorMessageAction,
  createCustomKeyword as createCustomKeywordAction,
  createKeywordVariation as createKeywordVariationAction,
  deleteCustomKeyword as deleteCustomKeywordAction,
  deleteKeywordVariation as deleteKeywordVariationAction,
  makeKeywordVariationPrimary as makeKeywordVariationPrimaryAction,
  updateKeywordLabel as updateKeywordLabelAction
} from 'actions/insights';
import { trackSegmentEvents } from 'actions/segment';

import AvomaButton from 'components/Common/AvomaButton';
import AvomaLink from 'components/Common/AvomaLink';
import NotificationBanner from 'components/Common/NotificationBanner';
import Spinner from 'components/Common/Spinner';
import TextField from 'components/Common/TextField';
import HelpPopover from 'components/Common/popovers/HelpPopover';
import CardSection from 'components/SmartCategories/Common/CardSection';

import ConversationDashboardHelper from 'helpers/ConversationDashboardHelper';
import L10n from 'helpers/L10n';

import { TOPIC_EVENTS } from 'constants/trackingEvents';

import Tokens from 'styles/tokens';

import Keyword from '../Keyword';
import { createId } from '@paralleldrive/cuid2';

const CustomKeywords = props => {
  const {
    category,
    createCustomKeyword,
    loading,
    variationExistsError,
    deleteCustomKeyword,
    createKeywordVariation,
    deleteKeywordVariation,
    updateKeywordLabel,
    makeKeywordVariationPrimary,
    clearVariationErrorMessage,
    variationExistsMessage,
    isKeywordsToNotesEnabled,
    showWarning = true,
    isFillerWords = false,
    handleAddOrModifyKeywordOrPhrase,
    customStyles = {},
    isUnifiedFlow = false,
    keywords,
    setKeywords,
    isAdminOrTopicOwner = false
  } = props;

  const dispatch = useDispatch();

  const [textValue, setTextValue] = useState('');
  // To be used for showing the error message in case of duplicates
  const [sentKeyword, setSentKeyword] = useState('');
  const [keywordExistsParentLabel, setKeywordExistsParentLabel] = useState('');
  const [keywordExistsError, setKeywordExistsError] = useState(false);
  const keywordsContainerRef = useRef();
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [hasDuplicateKeyword, setHasDuplicateKeyword] = useState(false);

  const isCategoryNew = category.uuid === 'new';

  const handleDeleteKeyword = uuid => {
    const { [uuid]: removed, ...remainingKeywords } = keywords;
    setKeywords({ ...remainingKeywords });
  };

  const onDeleteKeyword = async uuid => {
    await deleteCustomKeyword({
      categoryUuid: category.uuid,
      keywordUuid: uuid
    });
    handleAddOrModifyKeywordOrPhrase?.();
  };

  const handleDeleteKeywordVariation = ({ variationUuid, parentUuid }) => {
    const updatedKeywords = keywords.map(keyword => {
      if (keyword.uuid === parentUuid) {
        return {
          ...keyword,
          variations: keyword.variations.filter(
            variation => variation.uuid !== variationUuid
          )
        };
      }
      return keyword;
    });

    setKeywords([...updatedKeywords]);
  };

  const onDeleteKeywordVariation = async ({ variationUuid, parentUuid }) => {
    await deleteKeywordVariation({
      categoryUuid: category.uuid,
      variationUuid,
      parentKeywordUuid: parentUuid
    });
    handleAddOrModifyKeywordOrPhrase?.();
  };

  const handleTextChange = event => setTextValue(event.target.value);

  const handleKeyDown = event => {
    switch (event.keyCode) {
      case 13: // Enter key
        if (textValue.trim().length !== 0) {
          if (isUnifiedFlow) {
            handleAddCustomKeyword();
          } else {
            handleCreateCustomKeyword();
          }
        }
        break;
      default:
        break;
    }
  };

  const handleAddCustomKeyword = () => {
    const splitKeywords = textValue.split(',');
    const keywordsToAdd = [];

    let isKeywordExists = false;

    Object.values(keywords).forEach(keyword => {
      if (
        splitKeywords.some(
          word => word.toLowerCase() === keyword.label.toLowerCase()
        )
      ) {
        isKeywordExists = true;
      }
    });

    if (isKeywordExists) {
      setHasDuplicateKeyword(true);
      return;
    }

    splitKeywords.forEach(keyword => {
      const keywordUuid = createId();
      keywordsToAdd.push({
        label: keyword.trim(),
        variations: [],
        tempUuid: keywordUuid,
        uuid: null,
        isPrimary: true
      });
    });

    setKeywords([...keywords, ...keywordsToAdd]);

    dispatch(
      trackSegmentEvents({
        type: TOPIC_EVENTS.TOPIC_KEYWORD_ADDED,
        name: keywordsToAdd.map(keyword => keyword.label).join(', ')
      })
    );

    setTextValue('');
    setHasDuplicateKeyword(false);
  };

  const handleCreateCustomKeyword = async () => {
    setKeywordExistsError(false);
    // Split the keyword if it contains commas, and batch the requests
    const splitKeywords = textValue.split(',');
    setShowButtonLoader(true);
    splitKeywords.map(async keyword => {
      try {
        await createCustomKeyword({
          label: keyword.trim(),
          categoryUuid: category.uuid
        });
      } catch (e) {
        setKeywordExistsError(true);
        setKeywordExistsParentLabel(keyword.trim());
        setSentKeyword(keyword);
      }
    });
    setShowButtonLoader(false);
    setTextValue('');
    handleAddOrModifyKeywordOrPhrase?.();
  };

  const handleAddKeywordVariation = async ({ label, parentUuid }) => {
    const variationUuid = createId();

    const newVariation = [
      {
        label,
        uuid: null,
        tempUuid: variationUuid,
        isPrimary: false,
        parent: parentUuid
      }
    ];

    const updatedKeywords = keywords.map(keyword => {
      if (keyword.uuid === parentUuid) {
        return {
          ...keyword,
          variations: [...keyword.variations, ...newVariation]
        };
      }
      return keyword;
    });

    setKeywords([...updatedKeywords]);
  };

  const onCreateKeywordVariation = async ({ label, parentUuid }) => {
    setKeywordExistsError(false);
    try {
      await createKeywordVariation({
        label,
        parentKeywordUuid: parentUuid,
        categoryUuid: category.uuid
      });
    } catch (e) {
      setKeywordExistsError(true);
      setKeywordExistsParentLabel(category?.keywords[parentUuid].label);
      setSentKeyword(label);
    }
    handleAddOrModifyKeywordOrPhrase?.();
  };

  const handleModifyKeywordLabel = ({ word, label }) => {
    const updatedVariation = keywords[word.parent].variations[word.tempUuid];
    updatedVariation.label = label;

    setKeywords({
      ...keywords,
      [word.parent]: {
        ...keywords[word.parent],
        variations: {
          ...keywords[word.parent].variations,
          ...updatedVariation
        }
      }
    });
  };

  const handleUpdateKeywordLabel = async ({ word, label }) => {
    setKeywordExistsError(false);
    try {
      await updateKeywordLabel({
        categoryUuid: category.uuid,
        keywordUuid: word.uuid,
        label,
        isPrimary: word.isPrimary,
        parentKeywordUuid: word.parent
      });
    } catch (e) {
      setKeywordExistsError(true);
      setKeywordExistsParentLabel(label);
      setSentKeyword(label);
    }
    handleAddOrModifyKeywordOrPhrase?.();
  };

  const onClickMakePrimary = async ({ uuid, parentUuid }) => {
    await makeKeywordVariationPrimary({
      categoryUuid: category.uuid,
      keywordUuid: uuid,
      parentKeywordUuid: parentUuid
    });
    handleAddOrModifyKeywordOrPhrase?.();
  };

  const getHeaderText = () => {
    if (isFillerWords) {
      return (
        <div className='flex items-center'>
          <span>{L10n.customCategory.addFillerWordsTitle}</span>

          <HelpPopover
            content={
              <div>
                Avoma will automatically track trends for these filler words in
                the{' '}
                <AvomaLink
                  to={`/dashboard/interaction/?tab=${ConversationDashboardHelper.TABS.fillers}`}
                >
                  Interaction
                </AvomaLink>{' '}
                dashboard
              </div>
            }
            buttonStyles={{ paddingLeft: Tokens.spacing.one }}
            popoverWidth={280}
            popoverStyles={{ right: 0 }}
          />
        </div>
      );
    }
    return (
      <div className='flex items-center'>
        <span>
          Add keywords for <span className='text-blue'>"exact"</span> match
        </span>

        <HelpPopover
          content={L10n.customCategory.keywordsMatchSubtitle}
          buttonStyles={{ paddingLeft: Tokens.spacing.one }}
          popoverWidth={280}
          popoverStyles={{ right: 0 }}
        />
      </div>
    );
  };

  const renderKeywordExistsErrorText = () => {
    // if the written word and the error responded parent keyword label are the same, then the error should signify
    // its a higher level keyword, else tell which keyword the variation belongs to
    const isExistingKeywordParent = sentKeyword === keywordExistsParentLabel;
    const errorText = isExistingKeywordParent
      ? `A keyword with the name "${sentKeyword}" already exists.`
      : `A variation with the name "${sentKeyword}" exists under the keyword "${keywordExistsParentLabel}".`;
    return (
      <div style={styles.error}>
        {errorText} Try adding a different keyword.
      </div>
    );
  };

  const renderAddKeywordsMessage = useCallback(() => {
    const noKeywordsPresent =
      Object.values(isUnifiedFlow ? keywords : category?.keywords).length === 0;

    if (noKeywordsPresent && isKeywordsToNotesEnabled)
      return (
        <NotificationBanner
          content={L10n.customCategory.addKeywordToSmartCategory}
          type='warning'
        />
      );
  }, [category?.keywords, isKeywordsToNotesEnabled, isUnifiedFlow, keywords]);

  const isAddButtonDisabled =
    textValue.trim().length === 0 || !isKeywordsToNotesEnabled;

  return (
    <CardSection
      userStyles={{ marginBottom: Tokens.spacing.three, ...customStyles }}
      contentStyles={{ padding: 0 }}
    >
      <div
        style={{
          ...styles.header,
          ...(!isKeywordsToNotesEnabled && { opacity: '0.4' })
        }}
      >
        {getHeaderText()}
      </div>
      <div style={styles.textField}>
        <TextField
          placeholder={
            isFillerWords
              ? L10n.customCategory.fillerWordsPlaceholder
              : L10n.customCategory.keywordsPlaceholder
          }
          value={textValue}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          darkerPlaceholder
          containerStyles={styles.inputContainer}
          userStyles={{
            ...styles.input,
            ...(!isKeywordsToNotesEnabled && styles.disabled)
          }}
          disabled={
            (!isKeywordsToNotesEnabled ||
              isCategoryNew ||
              !isAdminOrTopicOwner) &&
            !isUnifiedFlow
          }
        />
        <AvomaButton
          onClick={
            isUnifiedFlow ? handleAddCustomKeyword : handleCreateCustomKeyword
          }
          label={L10n.general.add}
          small
          disabled={isAddButtonDisabled || !isAdminOrTopicOwner}
          loading={showButtonLoader}
        />
        {loading && <Spinner type='small' />}
      </div>
      {(keywordExistsError || hasDuplicateKeyword) &&
        renderKeywordExistsErrorText()}

      <div className='mt-2 flex flex-wrap gap-2' ref={keywordsContainerRef}>
        {(isUnifiedFlow ? keywords : category?.keywords) &&
          Object.values(isUnifiedFlow ? keywords : category?.keywords)?.map(
            keyword => (
              <Keyword
                key={keyword.uuid || keyword.tempUuid}
                keyword={keyword}
                variations={keyword.variations}
                onDeleteKeyword={
                  isUnifiedFlow ? handleDeleteKeyword : onDeleteKeyword
                }
                onDeleteKeywordVariation={
                  isUnifiedFlow
                    ? handleDeleteKeywordVariation
                    : onDeleteKeywordVariation
                }
                onCreateKeywordVariation={
                  isUnifiedFlow
                    ? handleAddKeywordVariation
                    : onCreateKeywordVariation
                }
                variationExistsError={
                  variationExistsError || hasDuplicateKeyword
                }
                onUpdateLabel={
                  isUnifiedFlow
                    ? handleModifyKeywordLabel
                    : handleUpdateKeywordLabel
                }
                categoryUuid={category.uuid}
                onClickMakePrimary={onClickMakePrimary}
                containerWidth={keywordsContainerRef?.current?.offsetWidth}
                variationPopoverTitle={L10n.customCategory.keywordVariations}
                addMoreVariationsTooltip={
                  isAdminOrTopicOwner && L10n.customCategory.addMoreVariations
                }
                clearVariationErrorMessage={clearVariationErrorMessage}
                variationExistsMessage={
                  isUnifiedFlow
                    ? 'Variation already exists'
                    : variationExistsMessage
                }
                disabled={!isKeywordsToNotesEnabled || !isAdminOrTopicOwner}
                isCRMKeyword={keyword.source === 'crm_field'}
                isUnifiedFlow={isUnifiedFlow}
                hasDuplicateKeyword={hasDuplicateKeyword}
                isAdminOrTopicOwner={isAdminOrTopicOwner}
              />
            )
          )}
      </div>
      {showWarning && renderAddKeywordsMessage()}
    </CardSection>
  );
};

const styles = {
  header: {
    ...Tokens.type.body,
    fontWeight: 'bold',
    marginBottom: Tokens.spacing.half
  },
  textField: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center'
  },
  inputContainer: {
    flex: 1,
    marginRight: Tokens.spacing.two
  },
  switch: {
    padding: 0,
    paddingBottom: Tokens.spacing.three
  },
  statusLink: {
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  input: {
    ...Tokens.type.bodyS,
    flex: 1,
    fontWeight: 'normal',
    height: Tokens.spacing.four,
    padding: `${Tokens.spacing.half} ${Tokens.spacing.one}`
  },
  error: {
    ...Tokens.type.bodyS,
    color: Tokens.colors.avomaRed,
    margin: `${Tokens.spacing.one} 0`
  },
  disabled: {
    opacity: '0.4',
    cursor: 'not-allowed'
  }
};

CustomKeywords.propTypes = {
  category: PropTypes.object,
  createCustomKeyword: PropTypes.func,
  loading: PropTypes.bool,
  isKeywordsToNotesEnabled: PropTypes.bool,
  variationExistsError: PropTypes.bool,
  deleteCustomKeyword: PropTypes.func,
  createKeywordVariation: PropTypes.func,
  deleteKeywordVariation: PropTypes.func,
  updateKeywordLabel: PropTypes.func,
  makeKeywordVariationPrimary: PropTypes.func,
  clearVariationErrorMessage: PropTypes.func,
  variationExistsMessage: PropTypes.string,
  showWarning: PropTypes.bool,
  isFillerWords: PropTypes.bool,
  handleAddOrModifyKeywordOrPhrase: PropTypes.func,
  customStyles: PropTypes.object,
  isUnifiedFlow: PropTypes.bool,
  keywords: PropTypes.object,
  setKeywords: PropTypes.func,
  isAdminOrTopicOwner: PropTypes.bool
};

const mapStateToProps = state => ({
  variationExistsError: state.insights.variationExistsError,
  variationExistsMessage: state.insights.variationExistsMessage
});

const actionCreators = {
  createCustomKeyword: createCustomKeywordAction,
  deleteCustomKeyword: deleteCustomKeywordAction,
  createKeywordVariation: createKeywordVariationAction,
  deleteKeywordVariation: deleteKeywordVariationAction,
  updateKeywordLabel: updateKeywordLabelAction,
  makeKeywordVariationPrimary: makeKeywordVariationPrimaryAction,
  clearVariationErrorMessage: clearVariationErrorMessageAction
};

const ConnectedCustomKeywords = connect(
  mapStateToProps,
  actionCreators
)(CustomKeywords);

export default ConnectedCustomKeywords;
