import env from 'helpers/env';
import { deleteMethod, get, patch, post, put } from 'helpers/http2';

import { createApi } from '@reduxjs/toolkit/query/react';

export const customBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, params, options = {} }, thunkApi) => {
    try {
      let result = {};
      switch (method) {
        case 'GET': {
          const urlWithParams = new URL(`${baseUrl}${url}`);
          if (params) {
            urlWithParams.search = new URLSearchParams(params).toString();
          }
          result = await get({ url: urlWithParams, thunkApi, options });
          return { data: result };
        }
        case 'POST':
          result = await post({
            url: `${baseUrl}${url}`,
            thunkApi,
            body: data,
            options
          });
          return { data: result };
        case 'PUT':
          result = await put({
            url: `${baseUrl}${url}`,
            thunkApi,
            body: data,
            options
          });
          return { data: result };
        case 'PATCH':
          result = await patch({
            url: `${baseUrl}${url}`,
            thunkApi,
            body: data,
            options
          });
          return { data: result };
        case 'DELETE':
          result = await deleteMethod({
            url: `${baseUrl}${url}`,
            thunkApi,
            body: data,
            options
          });
          return { data: result };
        default:
          break;
      }
    } catch (err) {
      return {
        error: {
          status: err?.status,
          data: err?.data
        }
      };
    }
  };

export const baseApiUrl = env.avomaApiUrl;

export const baseCreateApi = createApi({
  baseQuery: customBaseQuery({
    baseUrl: baseApiUrl
  }),
  tagTypes: [
    'ForecastSettings',
    'TargetSubmissions',
    'TargetSubmissionsMinified',
    'TargetSubmissionStatus',
    'ForecastSubmissionOpportunities',
    'ForecastOverview',
    'ForecastSubmissionUserList',
    'ForecastSubmission',
    'ForecastSubmissionTeamList',
    'AllForecastSubmissionOpportunities',
    'ForecastSubmissionHistory',
    'OrganizationUsersMinified',
    'ManagedSchedulingPages',
    'ManagedSchedulingPagesSingle',
    'GroupSync',
    'Alerts',
    'getRouterPages',
    'getRouterPageSingle',
    'getFormsForRouter',
    'getSchedulerReports',
    'Automation',
    'Automations',
    'AutomationFilters',
    'getFormsForRouter',
    'getRouterReports',
    'Teams',
    'SchPageList',
    'SmartCategories',
    'MeetingChapters',
    'hubspotFormFieldsForRouter',
    'getformProvider',
    'getHubspotSyncStatusForRouter',
    'RecentMeetings',
    'DEAL_COMMENTS',
    'DealComments',
    'PropertyGroupValues',
    'DealChangeIndicators',
    'routerReportsSankeyData',
    'routerReportsTotalCounts',
    'MembersPerformance',
    'ScorecardQuestionTags',
    'MeetingFollowUpEmail',
    'MeetingNotesEmail',
    'MeetingTypes',
    'MonthlyDistribution',
    'RoundRobinQueue',
    'RoundRobinMeetingCredits',
    'AccountDetails',
    'DealDetails',
    'BattlecardsAudit',
    'RecordingDisclaimerAuditLogs',
    'BattlecardsSettings',
    'DashboardFilters',
    'DashboardFiltersAccounts',
    'DashboardFiltersDeals',
    'DashboardFiltersGlobalSearch',
    'DealHealth',
    'DashboardAccountsCount',
    'Reactions',
    'ForecastAccessControl',
    'SchedulerReports',
    'getSchedulerReports',
    'PropertyGroupFields',
    'GroupMappedProperties',
    'GroupProperties',
    'CompanyMeetingsInfo',
    'CompanyDealsCount',
    'CompanyDealsDetails',
    'DealMeetingsInfo',
    'CrmOrgSettings',
    'LanguageSettings',
    'Languages',
    'MeetingInsights',
    'MeetingSpeakingInsights',
    'Templates',
    'MeetingCollabNotesSnapshotHistory',
    'MeetingCollabNotesPreviewHistory',
    'MeetingCollabNotesSnapshot',
    'MeetingAssociatedTemplate',
    'RecordingStats',
    'FeaturePlans',
    'Playbook',
    'PlaybookSettings',
    'PlaybookSettingsList',
    'DealPlaybook',
    'DealPlaybookDetails',
    'DealPlaybookDetailsForField',
    'RegenerateNotesLimits',
    'AggregatedForecastCategories',
    'ReservedViewFilteredDeals',
    'ActiveSubscriptions',
    'Copilot',
    'CopilotSavedPrompts',
    'Bot'
  ],
  endpoints: () => ({})
});
