import { LicenseActions } from 'actions/actionTypes';

export const initialState = {
  plans: [],
  fetchingPlans: false,
  fetchingPlansFailed: false,
  licenses: [],
  fetchingLicenses: false,
  fetchingLicensesFailed: false,
  requestEmail: {
    sending: false,
    error: null
  }
};

/*
  IMPORTANT: this "user" redux key is persisted to localStorage. see reduxStore.js
*/
const licenses = (state = initialState, action) => {
  switch (action.type) {
    case LicenseActions.FETCH_PLANS_REQUEST:
      return {
        ...state,
        fetchingPlans: true,
        fetchingPlansFailed: false
      };
    case LicenseActions.FETCH_PLANS_SUCCESS:
      return {
        ...state,
        plans: action.plans,
        fetchingPlans: false,
        fetchingPlansFailed: false
      };
    case LicenseActions.FETCH_PLANS_FAILURE:
      return {
        ...state,
        fetchingPlans: false,
        fetchingPlansFailed: true
      };
    case LicenseActions.FETCH_LICENSES_REQUEST:
      return {
        ...state,
        fetchingLicenses: true,
        fetchingLicensesFailed: false
      };
    case LicenseActions.FETCH_LICENSES_SUCCESS:
      return {
        ...state,
        licenses: action.licenses,
        fetchingLicenses: false,
        fetchingLicensesFailed: false
      };
    case LicenseActions.FETCH_LICENSES_FAILURE:
      return {
        ...state,
        fetchingLicenses: false,
        fetchingLicensesFailed: true
      };
    case LicenseActions.SEND_REQUEST_ADMIN_EMAIL_REQUEST:
      return {
        ...state,
        requestEmail: {
          sending: true,
          error: null
        }
      };
    case LicenseActions.SEND_REQUEST_ADMIN_EMAIL_SUCCESS:
      return {
        ...state,
        requestEmail: {
          sending: false,
          error: null
        }
      };
    case LicenseActions.SEND_REQUEST_ADMIN_EMAIL_FAILURE:
      return {
        ...state,
        requestEmail: {
          sending: false,
          error: action.err
        }
      };
    default:
      return state;
  }
};

export default licenses;
