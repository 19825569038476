import _ from 'lodash';

/**
 * @param {object} obj FE payload with camelCase keys
 * @returns {object} BE-ready payload with snake_case keys. Use this in place of decamelize-keys if deeply nested decamelizing is required as the library is not able to accommodate this need.
 */
export const camelToSnakeRecursively = obj =>
  _.transform(obj, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.snakeCase(key);

    acc[camelKey] = _.isObject(value) ? camelToSnakeRecursively(value) : value;
  });

/**
 * @param {string} snake case text
 * @returns {string} Capitalized text 'this_is_a_snake_case_text' > "This Is A Snake Case Text"
 */
export const snakeCaseToCapitalizedString = text =>
  _.startCase(_.toLower(text));

/**
 * @param {node} element that is being truncated by css
 * @returns {boolean} if the element has been truncated
 */
export function isEllipsisActive(e) {
  if (e) return e.offsetWidth < e.scrollWidth;
}

export function strippedPlanName(planName, isAddon = false) {
  if (!planName) return '';

  const wordsToStrip = ['Half Yearly', 'Quarterly', 'Yearly', 'Monthly'];
  if (isAddon) {
    wordsToStrip.push('Addon');
  }
  let strippedName = planName;

  wordsToStrip.forEach(word => {
    strippedName = strippedName.replace(
      new RegExp(`\\b${word.trim()}\\b`, 'g'),
      ''
    );
  });

  return strippedName.trim();
}
