import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import Tokens from 'styles/tokens';

const Radium = require('radium');

export type AvomaTooltipPosition =
  | 'top'
  | 'top-right'
  | 'top-left'
  | 'top-center'
  | 'right'
  | 'right-top'
  | 'right-bottom'
  | 'right-center'
  | 'bottom'
  | 'bottom-left'
  | 'bottom-right'
  | 'bottom-center'
  | 'left'
  | 'left-top'
  | 'left-bottom'
  | 'left-center';

interface IProps {
  tooltip?: string;
  userStyles?: React.CSSProperties;
  className?: string;
  anchorClassName?: string;
  children: React.ReactNode;
  tooltipPosition?: AvomaTooltipPosition;
  containerClasses?: string;
}

const AvomaTooltip = ({
  tooltip = '',
  userStyles,
  children,
  className = '',
  anchorClassName = '',
  tooltipPosition = 'bottom',
  containerClasses = ''
}: IProps) => {
  const [position, setPosition] = useState<React.CSSProperties | null>(null);

  const anchorRef = useRef<HTMLSpanElement>(null);

  interface MouseEventWithCurrentTarget extends React.MouseEvent<HTMLElement> {
    currentTarget: EventTarget & HTMLElement;
  }

  const handleMouseOver = (e: MouseEventWithCurrentTarget) => {
    const bounds = e.currentTarget.getBoundingClientRect();
    switch (tooltipPosition) {
      case 'top':
      case 'top-left': {
        return setPosition({
          left: bounds.left,
          bottom: `calc(100vh - ${bounds.top}px)`,
          marginBottom: Tokens.spacing.one
        });
      }
      case 'top-right': {
        return setPosition({
          right: `calc(100vw - ${bounds.right}px)`,
          bottom: `calc(100vh - ${bounds.top}px)`,
          marginBottom: Tokens.spacing.one
        });
      }
      case 'top-center': {
        return setPosition({
          right: `calc(100vw - ${bounds.right}px)`,
          bottom: `calc(100vh - ${bounds.top}px)`,
          marginBottom: Tokens.spacing.one,
          transform: `translateX(calc(50% - ${bounds.width / 2}px))`
        });
      }
      case 'right':
      case 'right-top': {
        return setPosition({
          left: bounds.right,
          top: bounds.top,
          marginLeft: Tokens.spacing.one
        });
      }
      case 'right-bottom': {
        return setPosition({
          left: bounds.right,
          bottom: `calc(100vh - ${bounds.bottom}px)`,
          marginLeft: Tokens.spacing.one
        });
      }
      case 'right-center': {
        return setPosition({
          left: bounds.right,
          bottom: `calc(100vh - ${bounds.bottom}px)`,
          marginLeft: Tokens.spacing.one,
          transform: `translateY(calc(50% - ${bounds.height / 2}px))`
        });
      }
      case 'bottom':
      case 'bottom-left': {
        return setPosition({
          left: bounds.left,
          top: bounds.bottom,
          marginTop: Tokens.spacing.one
        });
      }
      case 'bottom-right': {
        return setPosition({
          right: `calc(100vw - ${bounds.right}px)`,
          top: bounds.bottom,
          marginTop: Tokens.spacing.one
        });
      }
      case 'bottom-center': {
        return setPosition({
          right: `calc(100vw - ${bounds.right}px)`,
          top: bounds.bottom,
          marginTop: Tokens.spacing.one,
          transform: `translateX(calc(50% - ${bounds.width / 2}px))`
        });
      }
      case 'left':
      case 'left-top': {
        return setPosition({
          right: `calc(100vw - ${bounds.left}px)`,
          top: bounds.top,
          marginRight: Tokens.spacing.one
        });
      }
      case 'left-bottom': {
        return setPosition({
          right: `calc(100vw - ${bounds.left}px)`,
          bottom: `calc(100vh - ${bounds.bottom}px)`,
          marginRight: Tokens.spacing.one
        });
      }
      case 'left-center': {
        return setPosition({
          right: `calc(100vw - ${bounds.left}px)`,
          bottom: `calc(100vh - ${bounds.bottom}px)`,
          marginRight: Tokens.spacing.one,
          transform: `translateY(calc(50% - ${bounds.height / 2}px))`
        });
      }
      default: {
        // bottom-left
        setPosition({
          left: bounds.left,
          top: bounds.bottom,
          marginTop: Tokens.spacing.one
        });
      }
    }
  };

  const handleMouseOut = useCallback(() => setPosition(null), []);

  useEffect(() => {
    // Remove tooltip on click to prevent it from persisting when new data is loaded

    const anchorElement = anchorRef.current;
    if (anchorElement?.isConnected) {
      anchorElement.addEventListener('click', handleMouseOut);
    }

    return () => {
      if (anchorElement?.isConnected) {
        anchorElement.removeEventListener('click', handleMouseOut);
      }
    };
  }, [handleMouseOut]);

  const anchorProps = {
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
    ref: anchorRef
  };

  const anchor = React.isValidElement(children) ? (
    React.cloneElement(children, anchorProps)
  ) : (
    <span {...anchorProps} className={anchorClassName}>
      {children}
    </span>
  );

  return (
    <React.Fragment>
      {anchor}
      {position &&
        !isEmpty(tooltip) &&
        ReactDOM.createPortal(
          <div
            style={{ ...styles.container, ...position } as React.CSSProperties}
            aria-label={tooltip}
            className={containerClasses}
          >
            <div
              style={
                { ...styles.tooltip, ...userStyles } as React.CSSProperties
              }
              className={className}
            >
              {tooltip}
            </div>
          </div>,
          document.body
        )}
    </React.Fragment>
  );
};

const styles = {
  container: {
    position: 'absolute',
    zIndex: 99999999
  },
  tooltip: {
    ...Tokens.type.bodyXS,
    position: 'relative',
    padding: `${Tokens.spacing.half} ${Tokens.spacing.one}`,
    backgroundColor: Tokens.colors.gunmetal,
    borderRadius: Tokens.spacing.half,
    color: Tokens.colors.white,
    fontWeight: 'bold',
    whiteSpace: 'wrap',
    borderStyle: 'none',
    userSelect: 'none'
    // maxWidth: '168px'
  }
};

AvomaTooltip.propTypes = {
  tooltip: PropTypes.string,
  userStyles: PropTypes.object,
  className: PropTypes.string,
  anchorClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  tooltipPosition: PropTypes.oneOf([
    'top',
    'top-right',
    'top-left',
    'top-center',
    'right',
    'right-top',
    'right-bottom',
    'right-center',
    'bottom',
    'bottom-left',
    'bottom-right',
    'bottom-center',
    'left',
    'left-top',
    'left-bottom',
    'left-center'
  ])
};

export default Radium(AvomaTooltip);
