import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tokens from 'styles/tokens';

const Caret = ({ color, isForward, name }) => {
  const [hovering, setHover] = useState();
  const opacity = hovering ? 1.0 : 0.65;
  const cursorStyles = {
    ...cursorStyleBase,
    background: color,
    left: isForward ? '100%' : '-1px',
    opacity
  };
  const caretStyles = {
    ...caretStyleBase,
    background: color,
    left: isForward ? '100%' : '0%',
    opacity
  };
  const circleStyle = {
    ...style.circle,
    background: color
  };
  caretStyles[isForward ? 'bottom' : 'top'] = 0;

  const showToolTip = () => {
    setHover(true);
  };

  const hideToolTip = () => {
    setHover(false);
  };

  return (
    <React.Fragment>
      <div
        contentEditable={false}
        style={caretStyles}
        onMouseEnter={showToolTip}
        onMouseLeave={hideToolTip}
      >
        <span style={{ position: 'relative' }}>
          {!hovering && <div style={circleStyle} />}
          {hovering && (
            <span
              className='disable-select'
              contentEditable={false}
              style={cursorStyles}
            >
              {name}
            </span>
          )}
        </span>
      </div>
    </React.Fragment>
  );
};

Caret.propTypes = {
  color: PropTypes.string,
  isForward: PropTypes.bool,
  name: PropTypes.string
};

export default Caret;

const style = {
  circle: {
    position: 'absolute',
    top: '-2px',
    left: '-2px',
    width: '6px',
    height: '6px',
    borderRadius: '3px'
  }
};

const cursorStyleBase = {
  ...Tokens.type.bodyXS,
  position: 'absolute',
  top: 2,
  // pointerEvents: 'none',
  userSelect: 'none',
  transform: 'translateY(-100%)',
  color: Tokens.colors.white,
  fontWeight: 'bold',
  padding: Tokens.spacing.half,
  borderRadius: Tokens.spacing.borderRadius,
  background: 'palevioletred',
  whiteSpace: 'nowrap'
};

const caretStyleBase = {
  position: 'absolute',
  // pointerEvents: 'none',
  userSelect: 'none',
  height: '1.2em',
  width: 2,
  background: 'palevioletred',
  zIndex: 2
};
