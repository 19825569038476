import { Editor, Path, Transforms } from 'slate';

import { TYPE_CHECKLIST_ITEM } from '../../Types';
import { isList } from '../Queries/isList';
import { TYPE_CHECKLIST } from '../types';

export const moveListItemDown = (editor, listNode, listItemPath) => {
  // Previous sibling is the new parent
  const previousSiblingItem = Editor.node(editor, Path.previous(listItemPath));
  if (previousSiblingItem) {
    const [previousNode, previousPath] = previousSiblingItem;
    const lastChildIndexOfPrevSib = previousNode.children.length - 1;
    const lastChildOfPrevSib = previousNode.children[lastChildIndexOfPrevSib];
    const sublist = isList(lastChildOfPrevSib) && lastChildOfPrevSib;
    const newPath = previousPath.concat(
      sublist
        ? [lastChildIndexOfPrevSib, sublist.children.length]
        : [previousNode.children.length]
    );
    if (!sublist) {
      // Create new sublist if there is no sublist in the previous sibling
      // and move the current list item to the new sublist
      // If the type of the list item is checklist item, create a checklist
      // instead of a normal list
      const newListType =
        listNode.type === TYPE_CHECKLIST_ITEM ? TYPE_CHECKLIST : listNode.type;

      Transforms.wrapNodes(
        editor,
        {
          type: newListType,
          children: [],
          depth: (newPath.length - 1) / 2
        },
        { at: listItemPath, split: false }
      );
      Transforms.moveNodes(editor, {
        at: listItemPath,
        to: newPath
      });
    }
  }
};
