/* eslint-disable */

import env from 'helpers/env';

// global window
window.global = window;

if (window === window.parent) {
  // Hubspot Tracker code
  window._hsq = window._hsq = window._hsq || [];
  // Beamer config
  window.beamer_config = {
    product_id: env.beamerProductId,
    selector: '.beamerTrigger',
    lazy: true
  };
}

if (window === window.parent) {
  // Segment init
  window.initSegment(env.segmentWriteKey);
}
