import HighlightCategoryColors from 'styles/HighlightCategoryColors';

export const DEFAULT_NEW_CATEGORY = {
  uuid: 'new',
  name: '',
  description: '',
  keywords: [],
  backgroundColor:
    HighlightCategoryColors[Object.keys(HighlightCategoryColors)[0]].background,
  settings: {}
};

export const DEFAULT_EXAMPLE_SENTENCES = [
  { sentence: '' },
  { sentence: '' },
  { sentence: '' },
  { sentence: '' },
  { sentence: '' },
  { sentence: '' }
];

export const ERROR_MESSAGES = {
  dupeName: 'Tracker with this label already exists',
  dupeSentence: 'Duplicate sentences found'
};
