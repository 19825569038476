import env from './env';
import { loadStateFromLocalStorage } from './reduxStore';

const waitForClose = (newWindow, nextPath, loginCancelled) => {
  let trackId = null;
  trackId = setInterval(() => {
    if (newWindow.closed) {
      const persistedState = {
        ...(loadStateFromLocalStorage() || {})
      };
      if (persistedState.user.outReachUserUrl) {
        if (nextPath) {
          window.location.href = nextPath;
        } else {
          window.location.reload();
        }
      } else if (loginCancelled) {
        loginCancelled();
      }
      clearInterval(trackId);
    }
  }, 3000);
};

export const embedLogin = (nextPath, loginCancelled) => {
  const newWindow = window.open(
    `${window.location.protocol}//${window.location.host}/embed/avoma_login`,
    'avomaauth',
    'toolbar=1,status=1,width=500,height=600,centerscreen=1'
  );
  waitForClose(newWindow, nextPath, loginCancelled);
};

const getOutreachUrl = () =>
  `https://api.outreach.io/oauth/authorize?response_type=code&client_id=${env.outreachClientId}&redirect_uri=${env.outreachRedirectUrl}&scope=${env.outreachScope}`;

export const outreachLogin = () => {
  window.location = getOutreachUrl();
};

export const outreachLoginPopup = (nextPath, loginCancelled) => {
  const newWindow = window.open(
    getOutreachUrl(),
    'avomaauth',
    'toolbar=1,status=1,width=500,height=600,centerscreen=1'
  );
  waitForClose(newWindow, nextPath, loginCancelled);
};

export const isPathInsideOutreach = () => {
  if (window !== window.parent) {
    if (window.location.ancestorOrigins) {
      return Object.values(window.location.ancestorOrigins).includes(
        'https://web.outreach.io'
      );
    }
    return false;
  }

  return false;
};

export const isOutreachAuthState = () =>
  new URLSearchParams(window.location.search)
    .get('state')
    ?.includes('connect_outreach');
