import camelize from 'camelize';

import {
  MachineNoteActions,
  RecordingActions,
  TranscriptActions
} from 'actions/actionTypes';

import { cleanUUID } from 'helpers/draft-js/machineNotes';

const initialState = {
  data: {},
  fetching: false,
  errorFetching: null,
  saving: false,
  errorSaving: null,
  scrolling: false,
  autoScrollActive: true,
  savedScrollState: {
    scroller: 'transcript'
  },
  searchQueue: {
    keyword: null
  },
  search: {
    keyword: null,
    matches: []
  },
  doBlur: false
};

const updateMachineNote = (state, action) => {
  const transcription = state.data[action.transcriptionId];
  if (!transcription) {
    return state;
  }
  const machinesummary = [...(transcription.machinesummary || [])];
  let id = -1;
  machinesummary.forEach((summary, i) => {
    if (cleanUUID(summary.uuid) === cleanUUID(action.uuid)) {
      id = i;
    }
  });
  if (id >= 0) {
    machinesummary[id] = {
      ...machinesummary[id],
      userDeleted: action.userDeleted
    };
  }
  return {
    ...state,
    data: {
      ...state.data,
      [action.transcriptionId]: {
        ...state.data[action.transcriptionId],
        machinesummary
      }
    }
  };
};

const fetchingTranscription = (state, action) => {
  switch (action.type) {
    case 'FETCH_TRANSCRIPTION_REQUEST':
      return {
        ...state,
        data: {
          ...state.data,
          [action.transcriptionId]: {
            ...state.data[action.transcriptionId],
            fetching: true,
            errorFetching: null
          }
        }
      };
    case 'FETCH_TRANSCRIPTION_SUCCESS': {
      return {
        ...state,
        data: {
          ...state.data,
          [action.transcription.uuid]: {
            ...state.data[action.transcription.uuid],
            ...camelize(action.transcription),
            fetching: false,
            errorFetching: null
          }
        }
      };
    }
    case 'FETCH_TRANSCRIPTION_FAILURE': {
      return {
        ...state,
        data: {
          ...state.data,
          [action.transcriptionId]: {
            ...state.data[action.transcriptionId],
            fetching: false,
            errorFetching: action.error
          }
        }
      };
    }
    default:
      return state;
  }
};

const savingEditedTranscript = (state, action) => {
  switch (action.type) {
    case 'SAVE_EDITED_TRANSCRIPT_REQUEST':
      return {
        ...state,
        data: {
          ...state.data,
          [action.transcriptionId]: {
            ...state.data[action.transcriptionId],
            saving: true,
            errorSaving: null
          }
        }
      };
    case 'SAVE_EDITED_TRANSCRIPT_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          [action.transcription.uuid]: {
            ...camelize(action.transcription),
            editorState: state.data[action.transcription.uuid].editorState,
            saving: false,
            errorSaving: null
          }
        }
      };
    case 'SAVE_EDITED_TRANSCRIPT_FAILURE':
      return {
        ...state,
        data: {
          ...state.data,
          [action.transcriptionId]: {
            ...state.data[action.transcriptionId],
            saving: false,
            errorSaving: action.error
          }
        }
      };
    default:
      return state;
  }
};

const transcriptions = (state = initialState, action) => {
  switch (action.type) {
    case TranscriptActions.AUTO_SCROLLER_ON:
      return {
        ...state,
        autoScrollActive: true
      };
    case TranscriptActions.AUTO_SCROLLER_OFF:
      return {
        ...state,
        autoScrollActive: false
      };

    case 'SET_SCROLLING':
      return {
        ...state,
        scrolling: action.scrolling
      };
    case TranscriptActions.BLUR_EDITOR:
      return {
        ...state,
        doBlur: action.blur
      };
    case TranscriptActions.OPTIMISTIC_UPDATE_FOR_TRANSLATION_COUNT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.transcriptionId]: {
            ...state.data[action.transcriptionId],
            ...action.translationCountData
          }
        }
      };
    case 'FETCH_TRANSCRIPTION_REQUEST':
    case 'FETCH_TRANSCRIPTION_SUCCESS':
    case 'FETCH_TRANSCRIPTION_FAILURE':
    case 'CLEAR_FETCH_TRANSCRIPTION_ERROR':
      return fetchingTranscription(state, action);
    case 'SAVE_EDITED_TRANSCRIPT_REQUEST':
    case 'SAVE_EDITED_TRANSCRIPT_SUCCESS':
    case 'SAVE_EDITED_TRANSCRIPT_FAILURE':
      return savingEditedTranscript(state, action);
    case 'SET_TRANSCRIPTION_EDITOR_STATE':
      return {
        ...state,
        data: {
          ...state.data,
          [action.transcriptionId]: {
            ...state.data[action.transcriptionId],
            editorState: action.editorState,
            errorParsing: null
          }
        }
      };
    case 'SET_ERROR_PARSING_TRANSCRIPT':
      return {
        ...state,
        data: {
          ...state.data,
          [action.transcriptionId]: {
            ...state.data[action.transcriptionId],
            editorState: null,
            errorParsing: action.error
          }
        }
      };
    case 'SET_TRANSCRIPTION_SEARCH_RESULTS':
      return {
        ...state,
        search: {
          matches: action.matches,
          keyword: action.keyword
        }
      };
    case 'SET_TRANSCRIPTION_SEARCH_QUEUE':
      return {
        ...state,
        searchQueue: {
          ...state.searchQueue,
          keyword: action.keyword
        }
      };
    case 'LOGOUT':
      return initialState;
    case 'MACHINE_SUMMARY_UPDATE_REQUEST':
    case 'MACHINE_SUMMARY_UPDATE_FAILED':
      return {
        ...state,
        data: {
          ...state.data,
          [action.transcriptionId]: {
            ...state.data[action.transcriptionId],
            machinesummary: action.machinesummary
          }
        }
      };
    case MachineNoteActions.update.request:
    case MachineNoteActions.update.success:
    case MachineNoteActions.update.error:
      return updateMachineNote(state, action);
    case RecordingActions.FETCH_SPEAKERS_SUCCESS:
    case RecordingActions.DELETE_SPEAKERS_SUCCESS:
    case RecordingActions.UPDATE_SPEAKERS_SUCCESS:
      // sort of hack, because transcriptions pulls its own speaker map
      return {
        ...state,
        data: {
          ...state.data,
          [action.transcriptionUuid]: {
            ...state.data[action.transcriptionUuid],
            speakers: camelize(action.speakerMap)
          }
        }
      };
    case TranscriptActions.SAVE_SCROLL_TOP: {
      return {
        ...state,
        savedScrollState: {
          ...state.savedScrollState,
          meetingId: action.meetingId,
          scrollTime: action.scrollTime
        }
      };
    }
    case TranscriptActions.SET_SCROLLER: {
      return {
        ...state,
        savedScrollState: {
          ...state.savedScrollState,
          scroller: action.scroller
        }
      };
    }
    default:
      return state;
  }
};

export default transcriptions;
