import getNodesByType from './getNodesByType';

/**
 * Is there a node with a type included in `types` at a location (default: selection).
 */
const isNodeTypeIn = (editor, types, options = {}) => {
  const [match] = getNodesByType(editor, types, options);

  return !!match;
};

export default isNodeTypeIn;
