import { Transforms } from 'slate';

import { unhangRange } from './unhangRange';

/**
 * {@link Transforms.wrapNodes} with more options.
 */
export const wrapNodes = (editor, element, options = {}) => {
  unhangRange(editor, options);

  Transforms.wrapNodes(editor, element, options);
};
