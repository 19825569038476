import camelize from 'camelize';
import isNil from 'lodash/isNil';

import { CrmActions } from 'actions/actionTypes';

// NOTE: THIS MANIFESTS UNDER state.opportunities

const initialState = {
  data: {
    crmDetails: {},
    leads: {},
    search: {}
  },
  accounts: {},
  syncing: false,
  quickSearch: {},
  crmDetails: {
    loading: false,
    error: null
  },
  objects: {},
  availableProperties: {},
  smartCategories: {},
  enrichment: {}
};

const fetchActiveInactiveCrmUsers = (state, action, isActive) => {
  const key = isActive ? 'activeUsers' : 'inactiveUsers';
  switch (action.type) {
    case CrmActions.FETCH_ACTIVE_CRM_USERS_REQUEST:
    case CrmActions.FETCH_INACTIVE_CRM_USERS_REQUEST: {
      const hasCrmUsers = Boolean(state[key]);
      return {
        ...state,
        [key]: {
          ...(hasCrmUsers && !action.query && state[key]),
          fetching: true,
          error: false
        }
      };
    }
    case CrmActions.FETCH_ACTIVE_CRM_USERS_SUCCESS:
    case CrmActions.FETCH_INACTIVE_CRM_USERS_SUCCESS: {
      const hasCrmUsers = Boolean(state[key].data);
      if (hasCrmUsers && !action.query) {
        return {
          ...state,
          [key]: {
            ...state[key],
            fetching: false,
            error: false,
            data: {
              ...state[key].data,
              ...action.response,
              results: { ...state[key].data.results, ...action.data }
            }
          }
        };
      }
      return {
        ...state,
        [key]: {
          fetching: false,
          error: false,
          data: { ...action.response, results: action.data }
        }
      };
    }
    case CrmActions.FETCH_ACTIVE_CRM_USERS_FAILURE:
    case CrmActions.FETCH_INACTIVE_CRM_USERS_FAILURE:
      return {
        ...state,
        [key]: { fetching: false, error: true }
      };
    default:
      return state;
  }
};

const fetchOpportunities = (state, action) => {
  switch (action.type) {
    case CrmActions.FETCH_OPPORTUNITIES_REQUEST: {
      const hasOpportunities = Boolean(state.opportunities);

      if (hasOpportunities) {
        return {
          ...state,
          opportunities: {
            ...state.opportunities,
            [action.accountUuid]: {
              ...state.opportunities[action.accountUuid],
              fetching: true
            }
          }
        };
      }

      return {
        ...state,
        opportunities: {
          [action.accountUuid]: {
            fetching: true
          }
        }
      };
    }
    case CrmActions.FETCH_OPPORTUNITIES_SUCCESS: {
      const hasOpportunities = Boolean(
        state.opportunities[action.accountUuid].data
      );

      if (hasOpportunities) {
        return {
          ...state,
          opportunities: {
            ...state.opportunities,
            [action.accountUuid]: {
              fetching: false,
              data: {
                ...state.opportunities[action.accountUuid].data,
                ...action.response,
                results: {
                  ...state.opportunities[action.accountUuid].data.results,
                  ...action.data
                }
              }
            }
          }
        };
      }

      return {
        ...state,
        opportunities: {
          ...state.opportunities,
          [action.accountUuid]: {
            fetching: false,
            error: false,
            data: {
              ...action.response,
              results: action.data
            }
          }
        }
      };
    }

    case CrmActions.FETCH_OPPORTUNITIES_FAILURE:
      return {
        ...state,
        opportunities: {
          ...state.opportunities,
          [action.accountUuid]: {
            fetching: false,
            error: true
          }
        }
      };
    default:
      return state;
  }
};

const fetchOpportunityStages = (state, action) => {
  switch (action.type) {
    case CrmActions.FETCH_OPPORTUNITY_STAGES_REQUEST: {
      const hasOpportunityStages = Boolean(state.stages);

      if (hasOpportunityStages && !action.query) {
        return {
          ...state,
          stages: {
            ...state.stages,
            fetching: true
          }
        };
      }

      return {
        ...state,
        stages: {
          fetching: true
        }
      };
    }

    case CrmActions.FETCH_OPPORTUNITY_STAGES_SUCCESS: {
      const hasOpportunityStages = Boolean(state.stages.data);

      if (hasOpportunityStages && !action.query) {
        return {
          ...state,
          stages: {
            ...state.stages,
            fetching: false,
            dataMap: {
              ...state?.stages?.dataMap,
              ...action.data
            },
            data: {
              ...state.stages.data,
              ...action.response,
              results: {
                ...state.stages.data.results,
                ...action.data
              }
            }
          }
        };
      }
      return {
        ...state,
        stages: {
          fetching: false,
          error: false,
          dataMap: {
            ...state?.stages?.dataMap,
            ...action.data
          },
          data: {
            ...action.response,
            results: action.data
          }
        }
      };
    }

    case CrmActions.FETCH_OPPORTUNITY_STAGES_FAILURE:
      return {
        ...state,
        stages: {
          fetching: false,
          error: true
        }
      };
    default:
      return state;
  }
};

const fetchCrmAvailableProperties = (state, action) => {
  switch (action.type) {
    case CrmActions.FETCH_CRM_AVAILABLE_PROPERTIES_REQUEST:
      return {
        ...state,
        availableProperties: {
          ...state.availableProperties,
          [action.objectType]: {
            fetching: true
          }
        }
      };

    case CrmActions.FETCH_CRM_AVAILABLE_PROPERTIES_SUCCESS:
      return {
        ...state,
        availableProperties: {
          ...state.availableProperties,
          [action.objectType]: {
            fetching: false,
            error: false,
            data: action.response.properties
          }
        }
      };

    case CrmActions.FETCH_CRM_AVAILABLE_PROPERTIES_FAILURE:
      return {
        ...state,
        availableProperties: {
          ...state.availableProperties,
          [action.objectType]: {
            fetching: false,
            error: true
          }
        }
      };
    default:
      return state;
  }
};

const fetchCrmObjectCounts = (state, action) => {
  switch (action.type) {
    case CrmActions.FETCH_CRM_OBJECT_COUNT_REQUEST:
      return {
        ...state,
        objects: {
          counts: {
            fetching: true
          }
        }
      };
    case CrmActions.FETCH_CRM_OBJECT_COUNT_SUCCESS: {
      return {
        ...state,
        objects: {
          counts: {
            fetching: false,
            data: action.response
          }
        }
      };
    }
    case CrmActions.FETCH_CRM_OBJECT_COUNT_FAILURE:
      return {
        ...state,
        objects: {
          counts: {
            fetching: false,
            error: true
          }
        }
      };
    default:
      return state;
  }
};

const fetchSyncedCrmPropertiesForObject = (state, action) => {
  switch (action.type) {
    case CrmActions.FETCH_SYNCED_CRM_OBJECT_PROPERTIES_REQUEST: {
      return {
        ...state,
        objects: {
          ...state.objects,
          syncedProperties: {
            ...state.objects.syncedProperties,
            [action.objectType]: {
              fetching: true
            }
          }
        }
      };
    }
    case CrmActions.FETCH_SYNCED_CRM_OBJECT_PROPERTIES_SUCCESS: {
      return {
        ...state,
        objects: {
          ...state.objects,
          syncedProperties: {
            ...state.objects.syncedProperties,
            [action.objectType]: {
              fetching: false,
              data: {
                ...action.result
              }
            }
          }
        }
      };
    }
    case CrmActions.FETCH_SYNCED_CRM_OBJECT_PROPERTIES_FAILURE: {
      return {
        ...state,
        objects: {
          ...state.objects,
          syncedProperties: {
            ...state.objects.syncedProperties,
            [action.objectType]: {
              fetching: false,
              error: true
            }
          }
        }
      };
    }
    default:
      return state;
  }
};

const syncNewImportedPropertiesForObject = (state, action) => {
  switch (action.type) {
    case CrmActions.SYNC_IMPORTED_PROPERTIES_OBJECT_REQUEST: {
      return {
        ...state,
        objects: {
          ...state.objects,
          syncedProperties: {
            ...state.objects.syncedProperties,
            [action.objectType]: {
              ...state.objects.syncedProperties[action.objectType],
              updating: true
            }
          }
        }
      };
    }
    case CrmActions.SYNC_IMPORTED_PROPERTIES_OBJECT_SUCCESS: {
      return {
        ...state,
        objects: {
          ...state.objects,
          syncedProperties: {
            ...state.objects.syncedProperties,
            [action.objectType]: {
              updating: false,
              data: {
                ...state.objects.syncedProperties[action.objectType].data,
                ...action.result
              }
            }
          }
        }
      };
    }
    case CrmActions.SYNC_IMPORTED_PROPERTIES_OBJECT_FAILURE: {
      return {
        ...state,
        objects: {
          ...state.objects,
          syncedProperties: {
            ...state.objects.syncedProperties,
            [action.objectType]: {
              ...state.objects.syncedProperties[action.actionType],
              updating: false,
              error: true
            }
          }
        }
      };
    }
    default:
      return state;
  }
};

const updateCrmFieldProperties = (state, action) => {
  switch (action.type) {
    case CrmActions.UPDATE_CRM_FIELD_PROPERTY_REQUEST: {
      return {
        ...state,
        objects: {
          ...state.objects,
          syncedProperties: {
            ...state.objects.syncedProperties,
            [action.objectType]: {
              ...state.objects.syncedProperties[action.objectType],
              updating: true
            }
          }
        }
      };
    }
    case CrmActions.UPDATE_CRM_FIELD_PROPERTY_SUCCESS: {
      return {
        ...state,
        // Update cache for avomaProperties and smartCategories after api call
        avomaProperties: {
          ...state.avomaProperties,
          data: state.avomaProperties.data.map(property => {
            for (const item of Object.values(action.result)) {
              if (item.avomaField?.uuid === property.uuid) {
                return { ...property, avFieldMapping: item };
              }
              if (
                item.uuid === property.avFieldMapping?.uuid &&
                isNil(item.avomaField)
              ) {
                return { ...property, avFieldMapping: null };
              }
            }

            return property;
          })
        },
        smartCategories: {
          ...state.smartCategories,
          data: state.smartCategories.data.map(property => {
            for (const item of Object.values(action.result)) {
              if (item.avomaField?.uuid === property.uuid) {
                return { ...property, avFieldMapping: item };
              }
              if (
                item.uuid === property.avFieldMapping?.uuid &&
                isNil(item.avomaField)
              ) {
                return { ...property, avFieldMapping: null };
              }
            }

            return property;
          })
        },
        objects: {
          ...state.objects,
          syncedProperties: {
            ...state.objects.syncedProperties,
            [action.objectType]: {
              updating: false,
              data: {
                ...state.objects.syncedProperties[action.objectType].data,
                ...action.result
              }
            }
          }
        }
      };
    }
    case CrmActions.UPDATE_CRM_FIELD_PROPERTY_FAILURE: {
      return {
        ...state,
        objects: {
          ...state.objects,
          syncedProperties: {
            ...state.objects.syncedProperties,
            [action.objectType]: {
              ...state.objects.syncedProperties[action.actionType],
              updating: false,
              error: true
            }
          }
        }
      };
    }
    default:
      return state;
  }
};

const deleteCrmFieldProperty = (state, action) => {
  switch (action.type) {
    case CrmActions.DELETE_CRM_FIELD_PROPERTY_REQUEST: {
      return {
        ...state,
        objects: {
          ...state.objects,
          syncedProperties: {
            ...state.objects.syncedProperties,
            [action.objectType]: {
              ...state.objects.syncedProperties[action.objectType],
              deleting: true
            }
          }
        }
      };
    }
    case CrmActions.DELETE_CRM_FIELD_PROPERTY_SUCCESS: {
      const { [action.propertyUuid]: value, ...remainingProperties } =
        state.objects.syncedProperties[action.objectType].data;

      return {
        ...state,
        objects: {
          ...state.objects,
          syncedProperties: {
            ...state.objects.syncedProperties,
            [action.objectType]: {
              deleting: false,
              data: remainingProperties
            }
          }
        }
      };
    }
    case CrmActions.DELETE_CRM_FIELD_PROPERTY_FAILURE: {
      return {
        ...state,
        objects: {
          ...state.objects,
          syncedProperties: {
            ...state.objects.syncedProperties,
            [action.objectType]: {
              ...state.objects.syncedProperties[action.actionType],
              deleting: false,
              errorDeleting: true
            }
          }
        }
      };
    }
    default:
      return state;
  }
};

const fetchCrmSmartCategories = (state, action) => {
  switch (action.type) {
    case CrmActions.FETCH_CRM_SMART_CATEGORY_REQUEST: {
      return {
        ...state,
        smartCategories: {
          fetching: true
        }
      };
    }
    case CrmActions.FETCH_CRM_SMART_CATEGORY_SUCCESS: {
      return {
        ...state,
        smartCategories: {
          fetching: false,
          data: action.response
        }
      };
    }
    case CrmActions.FETCH_CRM_SMART_CATEGORY_FAILURE: {
      return {
        ...state,
        smartCategories: {
          fetching: false,
          error: action.err
        }
      };
    }
    default:
      return state;
  }
};

const fetchCrmAvomaProperties = (state, action) => {
  switch (action.type) {
    case CrmActions.FETCH_CRM_AVOMA_PROPERTY_REQUEST: {
      return {
        ...state,
        avomaProperties: {
          fetching: true
        }
      };
    }
    case CrmActions.FETCH_CRM_AVOMA_PROPERTY_SUCCESS: {
      return {
        ...state,
        avomaProperties: {
          fetching: false,
          data: action.response
        }
      };
    }
    case CrmActions.FETCH_CRM_AVOMA_PROPERTY_FAILURE: {
      return {
        ...state,
        avomaProperties: {
          fetching: false,
          error: action.err
        }
      };
    }
    default:
      return state;
  }
};

const createOrFetchCrmEnrichment = (state, action) => {
  switch (action.type) {
    case CrmActions.CREATE_OR_FETCH_CRM_RECORD_SETTINGS_REQUEST: {
      return {
        ...state,
        enrichment: {
          fetching: true,
          error: false
        }
      };
    }
    case CrmActions.CREATE_OR_FETCH_CRM_RECORD_SETTINGS_SUCCESS: {
      return {
        ...state,
        enrichment: {
          fetching: false,
          data: action.response
        }
      };
    }
    case CrmActions.CREATE_OR_FETCH_CRM_RECORD_SETTINGS_FAILURE: {
      return {
        ...state,
        enrichment: {
          fetching: false,
          error: true
        }
      };
    }
    default:
      return state;
  }
};

const updateCrmEnrichment = (state, action) => {
  switch (action.type) {
    case CrmActions.UPDATE_CRM_RECORD_SETTINGS_REQUEST: {
      return {
        ...state,
        enrichment: {
          ...state.enrichment,
          updating: true,
          error: false
        }
      };
    }
    case CrmActions.UPDATE_CRM_RECORD_SETTINGS_SUCCESS: {
      return {
        ...state,
        enrichment: {
          ...state.enrichment,
          updating: false,
          data: {
            ...state.enrichment.data,
            ...action.response
          }
        }
      };
    }
    case CrmActions.UPDATE_CRM_RECORD_SETTINGS_FAILURE: {
      return {
        ...state,
        enrichment: {
          ...state.enrichment,
          updating: false,
          error: true
        }
      };
    }
    default:
      return state;
  }
};

const crms = (state = initialState, action) => {
  switch (action.type) {
    case CrmActions.CRM_NOTE_SYNC_REQUEST:
      return {
        ...state,
        syncing: true
      };
    case CrmActions.CRM_NOTE_SYNC_SUCCESS:
      return {
        ...state,
        syncing: false,
        data: {
          ...state.data,
          crmDetails: {
            ...state.data.crmDetails,
            [action.meetingUuid]: action.crmDetails
          }
        }
      };
    case CrmActions.CRM_NOTE_SYNC_FAILED:
      return {
        ...state,
        syncing: false
      };
    case CrmActions.FETCH_OPPORTUNITY_STAGES_REQUEST:
    case CrmActions.FETCH_OPPORTUNITY_STAGES_SUCCESS:
    case CrmActions.FETCH_OPPORTUNITY_STAGES_FAILURE:
      return fetchOpportunityStages(state, action);
    case CrmActions.FETCH_OPPORTUNITIES_REQUEST:
    case CrmActions.FETCH_OPPORTUNITIES_SUCCESS:
    case CrmActions.FETCH_OPPORTUNITIES_FAILURE:
      return fetchOpportunities(state, action);

    case 'FETCH_CRM_ACCOUNTS_SUCCESS':
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [action.payload.accountUuid]: camelize(action.payload.accounts)
        }
      };
    case 'FETCH_CRM_LEADS_SUCCESS':
      return {
        ...state,
        leads: {
          ...state.leads,
          [action.payload.accountUuid]: camelize(action.payload.leads)
        }
      };

    case CrmActions.FETCH_CRM_DETAILS_REQUEST:
      return {
        ...state,
        crmDetails: {
          loading: true
        }
      };

    case CrmActions.FETCH_CRM_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          crmDetails: {
            ...state.data.crmDetails,
            [action.meetingUuid]: camelize(action.crmDetails)
          }
        },
        crmDetails: {
          loading: false
        }
      };

    case CrmActions.FETCH_CRM_DETAILS_ERROR:
      return {
        ...state,
        crmDetails: {
          loading: false,
          error: action.error
        }
      };

    case 'SEARCH_CRM_LEADS_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          search: {
            ...state.data.search,
            [action.payload.meetingId]: camelize(action.payload.leads)
          }
        }
      };
    case 'SEARCH_CRM_SUCCESS':
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          fetching: false,
          [action.payload.meetingId]: camelize(action.payload.results)
        }
      };
    case 'SEARCH_CRM_REQUEST':
      return {
        ...state,
        quickSearch: {
          fetching: true
        }
      };
    case 'SEARCH_CRM_FAILURE':
      return {
        ...state,
        quickSearch: {
          fetching: false
        },
        error: action.error
      };
    case 'FETCH_CRM_LEADS_DETAILS_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          leads: {
            ...state.data.leads,
            [action.payload.meetingId]: camelize(action.payload.crmDetails)
          }
        }
      };
    case CrmActions.FETCH_ACTIVE_CRM_USERS_REQUEST:
    case CrmActions.FETCH_ACTIVE_CRM_USERS_SUCCESS:
    case CrmActions.FETCH_ACTIVE_CRM_USERS_FAILURE:
      return fetchActiveInactiveCrmUsers(state, action, true);
    case CrmActions.FETCH_INACTIVE_CRM_USERS_REQUEST:
    case CrmActions.FETCH_INACTIVE_CRM_USERS_SUCCESS:
    case CrmActions.FETCH_INACTIVE_CRM_USERS_FAILURE:
      return fetchActiveInactiveCrmUsers(state, action, false);
    case CrmActions.FETCH_CRM_AVAILABLE_PROPERTIES_REQUEST:
    case CrmActions.FETCH_CRM_AVAILABLE_PROPERTIES_SUCCESS:
    case CrmActions.FETCH_CRM_AVAILABLE_PROPERTIES_FAILURE:
      return fetchCrmAvailableProperties(state, action);
    case CrmActions.FETCH_CRM_OBJECT_COUNT_REQUEST:
    case CrmActions.FETCH_CRM_OBJECT_COUNT_SUCCESS:
    case CrmActions.FETCH_CRM_OBJECT_COUNT_FAILURE:
      return fetchCrmObjectCounts(state, action);
    case CrmActions.FETCH_SYNCED_CRM_OBJECT_PROPERTIES_REQUEST:
    case CrmActions.FETCH_SYNCED_CRM_OBJECT_PROPERTIES_SUCCESS:
    case CrmActions.FETCH_SYNCED_CRM_OBJECT_PROPERTIES_FAILURE:
      return fetchSyncedCrmPropertiesForObject(state, action);
    case CrmActions.SYNC_IMPORTED_PROPERTIES_OBJECT_REQUEST:
    case CrmActions.SYNC_IMPORTED_PROPERTIES_OBJECT_SUCCESS:
    case CrmActions.SYNC_IMPORTED_PROPERTIES_OBJECT_FAILURE:
      return syncNewImportedPropertiesForObject(state, action);
    case CrmActions.UPDATE_CRM_FIELD_PROPERTY_REQUEST:
    case CrmActions.UPDATE_CRM_FIELD_PROPERTY_SUCCESS:
    case CrmActions.UPDATE_CRM_FIELD_PROPERTY_FAILURE:
      return updateCrmFieldProperties(state, action);
    case CrmActions.DELETE_CRM_FIELD_PROPERTY_REQUEST:
    case CrmActions.DELETE_CRM_FIELD_PROPERTY_SUCCESS:
    case CrmActions.DELETE_CRM_FIELD_PROPERTY_FAILURE:
      return deleteCrmFieldProperty(state, action);
    case CrmActions.FETCH_CRM_SMART_CATEGORY_REQUEST:
    case CrmActions.FETCH_CRM_SMART_CATEGORY_SUCCESS:
    case CrmActions.FETCH_CRM_SMART_CATEGORY_FAILURE:
      return fetchCrmSmartCategories(state, action);
    case CrmActions.FETCH_CRM_AVOMA_PROPERTY_REQUEST:
    case CrmActions.FETCH_CRM_AVOMA_PROPERTY_SUCCESS:
    case CrmActions.FETCH_CRM_AVOMA_PROPERTY_FAILURE:
      return fetchCrmAvomaProperties(state, action);
    case CrmActions.CREATE_OR_FETCH_CRM_RECORD_SETTINGS_REQUEST:
    case CrmActions.CREATE_OR_FETCH_CRM_RECORD_SETTINGS_SUCCESS:
    case CrmActions.CREATE_OR_FETCH_CRM_RECORD_SETTINGS_FAILURE:
      return createOrFetchCrmEnrichment(state, action);
    case CrmActions.UPDATE_CRM_RECORD_SETTINGS_REQUEST:
    case CrmActions.UPDATE_CRM_RECORD_SETTINGS_SUCCESS:
    case CrmActions.UPDATE_CRM_RECORD_SETTINGS_FAILURE:
      return updateCrmEnrichment(state, action);
    default:
      return state;
  }
};

export default crms;
