export const TEXT = 'text';
export const BLOCK_KEY = 'block_key';
export const HIGHLIGHT_NOTES_TITLE = 'highlight_notes_title';
export const TIME = 'time';
export const UUID = 'uuid';

class SlateDataHelper {
  static createTextNode = text => ({
    object: TEXT,
    text,
    marks: []
  });

  static nodesToChildren = node => {
    if (node.nodes) {
      node.nodes.forEach(nn => SlateDataHelper.nodesToChildren(nn));
      node.children = node.nodes;
      delete node.nodes;
      return node;
    }
    return node;
  };

  static reformatMarks = node => {
    if (node.marks) {
      node.marks.forEach(mm => {
        if (mm.type) {
          node[mm.type] = true;
        }
      });
      delete node.marks;
    }
    if (node.children) {
      node.children.forEach(nn => SlateDataHelper.reformatMarks(nn));
    }
  };

  static convertFormat = oldSlate => {
    let converted;
    if (oldSlate.document) {
      const doc = oldSlate.document.nodes;
      converted = doc.map(nn => SlateDataHelper.nodesToChildren(nn));
    } else {
      converted = oldSlate.map(nn => SlateDataHelper.nodesToChildren(nn));
    }
    converted.forEach(nn => SlateDataHelper.reformatMarks(nn));
    return converted;
  };

  static isEmptySlateDoc = doc => {
    if (!doc || doc.length === 0) {
      return true;
    }
    if (doc.length === 1) {
      if (doc[0].children[0] && !doc[0].children[0].text) {
        return true;
      }
      if (!doc[0].children && !doc[0].text) {
        return true;
      }
    }
    return false;
  };
}

export default SlateDataHelper;
