import React from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import Tokens from 'styles/tokens';

const AvomaCount = ({ count, style, type, className = '' }) => (
  <div
    style={{
      ...styles.indicator,
      backgroundColor:
        type === 'blue' ? Tokens.colors.blue : Tokens.colors.silverLighter,
      ...style
    }}
    className={className}
    data-testid='avoma-count'
  >
    {count}
  </div>
);

AvomaCount.propTypes = {
  count: PropTypes.number,
  style: stylePropType,
  type: PropTypes.oneOf(['blue', 'gray']),
  className: PropTypes.string
};

AvomaCount.defaultProps = {
  count: 0,
  style: {},
  type: 'gray'
};

const styles = {
  indicator: {
    ...Tokens.type.bodyXS,
    fontWeight: 'bold',
    boxSizing: 'border-box',
    borderRadius: Tokens.spacing.borderRadius,
    backgroundColor: Tokens.colors.silverLighter,
    color: Tokens.colors.white,
    padding: `1px ${Tokens.spacing.half}`,
    minWidth: '18px',
    textAlign: 'center'
  }
};

export default AvomaCount;
