import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import Tokens from 'styles/tokens';
import { primaryBlack, gainsboro, ghost, linkBlue } from 'helpers/colors';
import { AiOutlineCloseCircle as CrossIcon } from 'react-icons/ai';

const TextField = props => {
  const {
    placeholder,
    name,
    type,
    value,
    userStyles,
    darkerPlaceholder,
    showQuickClear,
    onClear,
    containerStyles,
    ...rest
  } = props;
  const newVal = value !== null ? value : '';
  const classNames = ['avoma-input'];
  if (darkerPlaceholder) {
    classNames.push('darker');
  }
  return (
    <div style={{ ...styles.container, ...containerStyles }}>
      <input
        type={type}
        style={{ ...styles.textfield, ...userStyles }}
        placeholder={placeholder}
        className={classNames.join(' ')}
        name={name}
        value={newVal}
        {...rest}
      />
      {newVal && showQuickClear && (
        <CrossIcon style={styles.removeIcon} onClick={onClear} />
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: Tokens.spacing.borderRadius,
    border: `1px solid ${gainsboro}`,
    backgroundColor: ghost,
    ':focus': {
      outline: 'none',
      border: `1px solid ${Tokens.colors.blue}`
    }
  },
  textfield: {
    height: '40px', // should be 40 but border is 1px
    boxSizing: 'border-box',
    padding: `${Tokens.spacing.one} ${Tokens.spacing.two}`,
    color: primaryBlack,
    fontSize: '1rem',
    letterSpacing: '0.3px',
    backgroundColor: ghost,
    border: 'none',
    outline: 'none',
    borderRadius: Tokens.spacing.borderRadius
  },
  removeIcon: {
    fontSize: Tokens.spacing.two,
    color: Tokens.colors.silver,
    margin: `0 ${Tokens.spacing.one}`,
    cursor: 'pointer'
  }
};

TextField.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  userStyles: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string,
  darkerPlaceholder: PropTypes.bool,
  onClear: PropTypes.func,
  showQuickClear: PropTypes.bool,
  containerStyles: stylePropType
};

TextField.defaultProps = {
  placeholder: null,
  name: '',
  userStyles: {},
  value: null,
  type: 'text',
  darkerPlaceholder: false,
  onClear: Function.prototype,
  showQuickClear: false
};

export default Radium(TextField);
