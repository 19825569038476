/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import ProfileIcon from 'components/Common/ProfileIcon';
import { ReactComponent as MembersIcon } from 'images/settings/ic_settings_members.svg';
import useHover from '../../../hooks/useHover';
import Portal from './Portal';

const NotesMentionDropdown = ({
  dropDownContainerRef,
  onSelectUser,
  onSelectTeam,
  currentRowIndex,
  users,
  teams,
  width,
  entityEls,
  scrollRef
}) => (
  <Portal>
    <div
      className={`absolute left-1/2 z-[1000] -translate-x-1/2 whitespace-nowrap rounded border border-gainsboro bg-white shadow-sm w-[${width}px]`}
      ref={dropDownContainerRef}
    >
      <div className='border-b border-b-silver-lighter px-3 py-2 text-xs text-silver-dark'>
        @mention to share this meeting
      </div>
      <div
        className='max-h-72 overflow-y-auto overflow-x-hidden p-0.5'
        ref={scrollRef}
      >
        {users.map((user, index) => (
          <User
            onSelectUser={onSelectUser}
            user={user}
            key={index}
            index={index}
            currentRowIndex={currentRowIndex}
            width={width}
            els={entityEls}
          />
        ))}
        {teams.map((team, index) => (
          <Team
            team={team}
            key={users.length + index}
            index={users.length + index}
            els={entityEls}
            onSelectTeam={onSelectTeam}
            currentRowIndex={currentRowIndex}
            width={width}
          />
        ))}
      </div>
    </div>
  </Portal>
);

NotesMentionDropdown.propTypes = {
  dropDownContainerRef: PropTypes.object.isRequired,
  scrollRef: PropTypes.object.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
  onSelectTeam: PropTypes.func.isRequired,
  currentRowIndex: PropTypes.number.isRequired,
  entityEls: PropTypes.object.isRequired,
  teams: PropTypes.object.isRequired
};

const User = ({ onSelectUser, user, index, currentRowIndex, els }) => {
  const [hovered, eventHandlers] = useHover();
  const { firstName, lastName, profilePic, email: userEmail } = user;
  const name = `${firstName} ${lastName}`.trim();

  const addToRefs = el => {
    els.current[index] = el;
  };

  return (
    <button
      {...eventHandlers}
      type='button'
      className={`mb-0.5 box-border flex w-full cursor-pointer items-center rounded border-none p-2 text-gunmetal ${
        hovered || currentRowIndex === index
          ? 'bg-smoke'
          : 'bg-white hover:bg-smoke'
      }`}
      onClick={() => onSelectUser(user)}
      ref={addToRefs}
    >
      <div className='mr-2 flex'>
        <ProfileIcon profilePic={profilePic} size={24} />
      </div>
      <div className='flex flex-col items-start'>
        <div className='text-xs font-bold text-gunmetal-darker'>{name}</div>
        <div className='truncate text-xs text-silver-darker'>{userEmail}</div>
      </div>
    </button>
  );
};

User.propTypes = {
  onSelectUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  currentRowIndex: PropTypes.number.isRequired,
  els: PropTypes.object.isRequired
};

const Team = ({ onSelectTeam, team, index, currentRowIndex, els }) => {
  const [hovered, eventHandlers] = useHover();
  const { name, userCount } = team;

  const addToRefs = el => {
    els.current[index] = el;
  };

  return (
    <button
      {...eventHandlers}
      type='button'
      className={`mb-0.5 box-border flex w-full cursor-pointer items-center rounded border-none p-2 text-gunmetal ${
        hovered || currentRowIndex === index
          ? 'bg-smoke'
          : 'bg-white hover:bg-smoke'
      }`}
      onClick={() => onSelectTeam(team)}
      ref={addToRefs}
    >
      <div className='mr-2 flex'>
        <MembersIcon className='h-6 w-6' />
      </div>
      <div className='flex flex-col items-start'>
        <div className='text-xs font-bold text-gunmetal-darker'>{name}</div>
        <div className='text-xs text-silver-darker'>{userCount} members</div>
      </div>
    </button>
  );
};

Team.propTypes = {
  onSelectTeam: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  currentRowIndex: PropTypes.number.isRequired,
  els: PropTypes.object.isRequired
};

export default NotesMentionDropdown;
