import { Path, Transforms } from 'slate';

import { getAboveByType } from '../../Queries/getAboveByType';
import { getParent } from '../../Queries/getParent';
import { TYPE_PARAGRAPH } from '../../Types';
import { TYPE_LIST_ITEM } from '../types';

/**
 * Moves a paragraph node in a list up if possible.
 * Only has an effect if the selected node is:
 * 1. A paragraph
 * 2. A child of a list-item
 * 3. Not the first child of a list-item
 * 4. Is the list child of a list-item
 * 5. The parent list-item is also the last child of the list
 *
 * @param {SlateEditor} editor
 * @returns {boolean} Whether or not the paragraph was moved
 */
export const moveParagraphUp = (editor, additionalLeafTypes = []) => {
  const leafEntry = getAboveByType(
    editor,
    additionalLeafTypes.concat([TYPE_PARAGRAPH])
  );
  if (leafEntry) {
    const [_, path] = leafEntry;
    const leafIndex = path[path.length - 1];
    if (path[path.length - 1] > 0) {
      const leafParent = getParent(editor, path);
      if (leafParent) {
        const [parentNode, parentPath] = leafParent;
        if (
          parentNode.type === TYPE_LIST_ITEM &&
          leafIndex === parentNode.children.length - 1
        ) {
          const listEntry = getParent(editor, parentPath);
          const parentIndex = parentPath[parentPath.length - 1];
          if (parentIndex === listEntry[0].children.length - 1) {
            Transforms.moveNodes(editor, {
              at: path,
              to: Path.next(listEntry[1])
            });
            return true;
          }
        }
      }
    }
  }
  return false;
};
