import camelize from 'camelize';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import omitBy from 'lodash/omitBy';
import { EventTypes } from 'redux-segment';

import { CustomCategoryActions, InsightsActions } from 'actions/actionTypes';

import env from 'helpers/env';
import { deleteMethod, get, patch, post } from 'helpers/http';

export const fetchMeetingInsights = meetingId => dispatch => {
  if (!meetingId) {
    throw Error('[avoma] requested next page without specifying a meetingId');
  }

  dispatch({ type: 'FETCH_A_INSIGHTS_REQUEST' });
  dispatch({
    type: 'MEETING_INSIGHTS_VIEWED',
    meta: {
      analytics: EventTypes.track
    }
  });

  get(`${env.avomaApiUrl}/api/v1/insights/${meetingId}/`)
    .then(insights => {
      dispatch({
        type: 'FETCH_A_INSIGHTS_SUCCESS',
        insights,
        meetingId
      });
    })
    .catch(err => {
      dispatch({
        type: 'FETCH_A_INSIGHTS_FAILURE',
        error: err.message
      });
      throw err;
    });
};

export const fetchQuestions = meetingId => async dispatch => {
  dispatch({
    type: InsightsActions.FETCH_QUESTIONS_REQUEST,
    meetingId
  });
  try {
    const result = await get(
      `${env.avomaApiUrl}/api/v1/machinenote/?meeting_uuid=${meetingId}&note_type=question`
    );
    const questions = camelize(result)
      .sort((a, b) => a.start - b.start)
      .map(rr => {
        try {
          if (rr.metadata) {
            return {
              ...rr,
              metadata: JSON.parse(rr.metadata)
            };
          }
        } catch (err) {
          // do nothing
        }
        return rr;
      });
    dispatch({
      type: InsightsActions.FETCH_QUESTIONS_SUCCESS,
      meetingId,
      questions
    });
  } catch (error) {
    dispatch({
      type: InsightsActions.FETCH_QUESTIONS_FAILURE,
      meetingId,
      error
    });
  }
};

export const fetchCustomCategories = () => async dispatch => {
  dispatch({ type: CustomCategoryActions.FETCH_CUSTOM_CATEGORY_REQUEST });
  try {
    const response = await get(`${env.avomaApiUrl}/api/v1/custom_category/`);
    const customCategories = map(camelize(response), category => {
      const { keywords } = category;
      const keywordCount = keywords.length;

      return {
        ...category,
        keywords,
        keywordCount
      };
    });
    dispatch({
      type: CustomCategoryActions.FETCH_CUSTOM_CATEGORY_SUCCESS,
      customCategories
    });
  } catch (error) {
    dispatch({
      type: CustomCategoryActions.FETCH_CUSTOM_CATEGORY_FAILURE
    });
    throw error;
  }
};

export const patchCustomCategory =
  ({
    uuid,
    name,
    description,
    keywords,
    backgroundColor,
    augNotesEnabled,
    keywordNotesEnabled,
    keywordTrackingEnabled,
    promptNotesEnabled,
    promptExtractStrategy,
    promptExtractLength,
    meetingTypeRule,
    formatting_rules
  }) =>
  async dispatch => {
    const patchObject = omitBy(
      {
        name,
        description,
        keywords,
        background_color: backgroundColor,
        formatting_rules,
        settings: omitBy(
          {
            aug_notes_enabled: augNotesEnabled,
            keyword_notes_enabled: keywordNotesEnabled,
            keyword_tracking_enabled: keywordTrackingEnabled,
            prompt_notes_enabled: promptNotesEnabled,
            prompt_extract_strategy: promptExtractStrategy,
            prompt_extract_length: promptExtractLength,
            meeting_type_rule: meetingTypeRule
          },
          isNil
        )
      },
      isNil
    );

    dispatch({
      type: CustomCategoryActions.PATCH_CUSTOM_CATEGORY_REQUEST,
      uuid,
      meta: {
        analytics: {
          eventType: EventTypes.track,
          eventPayload: { properties: patchObject }
        }
      }
    });

    try {
      const customCategory = await patch(
        `${env.avomaApiUrl}/api/v1/custom_category/${uuid}/`,
        patchObject
      );
      const camelized = camelize(customCategory);
      const keywordCount = customCategory.keywords.length;

      dispatch({
        type: CustomCategoryActions.PATCH_CUSTOM_CATEGORY_SUCCESS,
        uuid,
        customCategory: camelized,
        keywordCount
      });
    } catch (error) {
      dispatch({
        type: CustomCategoryActions.PATCH_CUSTOM_CATEGORY_ERROR,
        error
      });
      throw error;
    }
  };

export const createCustomCategory =
  ({ name, backgroundColor, description }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.CREATE_CUSTOM_CATEGORY_REQUEST,
      meta: {
        analytics: {
          eventType: EventTypes.track,
          eventPayload: { properties: { name, backgroundColor, description } }
        }
      }
    });
    try {
      const customCategories = await post(
        `${env.avomaApiUrl}/api/v1/custom_category/`,
        {
          name,
          background_color: backgroundColor,
          description
        }
      );
      const camelized = camelize(customCategories);
      dispatch({
        type: CustomCategoryActions.CREATE_CUSTOM_CATEGORY_SUCCESS,
        category: camelized
      });
      return camelized;
    } catch (error) {
      dispatch({
        type: CustomCategoryActions.CREATE_CUSTOM_CATEGORY_FAILURE
      });
      throw error;
    }
  };

export const deleteCustomCategory =
  ({ uuid }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.DELETE_CUSTOM_CATEGORY_REQUEST,
      uuid,
      meta: {
        analytics: { eventType: EventTypes.track }
      }
    });
    try {
      const customCategory = await deleteMethod(
        `${env.avomaApiUrl}/api/v1/custom_category/${uuid}/`
      );
      dispatch({
        type: CustomCategoryActions.DELETE_CUSTOM_CATEGORY_SUCCESS,
        uuid
      });
      return customCategory;
    } catch (error) {
      dispatch({
        type: CustomCategoryActions.DELETE_CUSTOM_CATEGORY_FAILURE
      });
      throw error;
    }
  };

export const reorderCustomCategory =
  ({ uuid, order }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.REORDER_CUSTOM_CATEGORY_REQUEST,
      meta: {
        analytics: EventTypes.track
      }
    });
    try {
      await post(
        `${env.avomaApiUrl}/api/v1/custom_category/${uuid}/change_order/`,
        {
          order
        }
      );
      dispatch({
        type: CustomCategoryActions.REORDER_CUSTOM_CATEGORY_SUCCESS
      });
    } catch (e) {
      dispatch({ type: CustomCategoryActions.REORDER_CUSTOM_CATEGORY_FAILURE });
      throw e;
    }
  };

export const createCustomKeyword =
  ({ categoryUuid, label }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.CREATE_CUSTOM_KEYWORD_REQUEST,
      meta: {
        analytics: {
          eventType: EventTypes.track,
          eventPayload: { properties: { label } }
        }
      }
    });

    // Not adding a try/catch block here so that we can handle the keyword exists error
    // in local state, to preserve the error when a comma separated input in added in, since
    // it results in a batched request, and handling it here means the error gets wiped when
    // a successful addition occurs right after a 400 one.
    let result = await post(
      `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/keyword/`,
      { label }
    );
    result = camelize(result);
    dispatch({
      type: CustomCategoryActions.CREATE_CUSTOM_KEYWORD_SUCCESS,
      result,
      categoryUuid
    });
  };

export const createKeywordVariation =
  ({ categoryUuid, label, parentKeywordUuid }) =>
  async dispatch => {
    dispatch({ type: CustomCategoryActions.CREATE_KEYWORD_VARIATION_REQUEST });

    try {
      let result = await post(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/keyword/`,
        {
          label,
          parent_keyword_uuid: parentKeywordUuid
        }
      );
      result = camelize(result);
      dispatch({
        type: CustomCategoryActions.CREATE_KEYWORD_VARIATION_SUCCESS,
        result,
        categoryUuid,
        parentKeywordUuid
      });
    } catch (e) {
      const err = e.message;

      dispatch({
        type: CustomCategoryActions.CREATE_KEYWORD_VARIATION_FAILURE,
        err: err?.label[0]
      });
    }
  };

export const deleteCustomKeyword =
  ({ categoryUuid, keywordUuid }) =>
  async dispatch => {
    dispatch({ type: CustomCategoryActions.DELETE_CUSTOM_KEYWORD_REQUEST });

    try {
      await deleteMethod(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/keyword/${keywordUuid}/`
      );
      dispatch({
        type: CustomCategoryActions.DELETE_CUSTOM_KEYWORD_SUCCESS,
        categoryUuid,
        keywordUuid
      });
    } catch (e) {
      dispatch({ type: CustomCategoryActions.DELETE_CUSTOM_KEYWORD_FAILURE });
    }
  };

export const deleteKeywordVariation =
  ({ categoryUuid, parentKeywordUuid, variationUuid }) =>
  async dispatch => {
    dispatch({ type: CustomCategoryActions.DELETE_KEYWORD_VARIATION_REQUEST });

    try {
      await deleteMethod(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/keyword/${variationUuid}/`
      );
      dispatch({
        type: CustomCategoryActions.DELETE_KEYWORD_VARIATION_SUCCESS,
        categoryUuid,
        variationUuid,
        parentKeywordUuid
      });
    } catch (e) {
      dispatch({
        type: CustomCategoryActions.DELETE_KEYWORD_VARIATION_FAILURE
      });
    }
  };

export const updateKeywordLabel =
  ({ categoryUuid, keywordUuid, label, isPrimary, parentKeywordUuid }) =>
  async dispatch => {
    dispatch({ type: CustomCategoryActions.UPDATE_KEYWORD_LABEL_REQUEST });

    let result = await patch(
      `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/keyword/${keywordUuid}/`,
      {
        label
      }
    );
    result = camelize(result);
    dispatch({
      type: CustomCategoryActions.UPDATE_KEYWORD_LABEL_SUCCESS,
      result,
      categoryUuid,
      keywordUuid,
      isPrimary,
      parentKeywordUuid
    });
  };

export const makeKeywordVariationPrimary =
  ({ categoryUuid, keywordUuid, parentKeywordUuid }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.MAKE_KEYWORD_VARIATION_PRIMARY_REQUEST
    });
    try {
      let result = await patch(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/keyword/${keywordUuid}/make_primary/`
      );
      result = camelize(result);
      dispatch({
        type: CustomCategoryActions.MAKE_KEYWORD_VARIATION_PRIMARY_SUCCESS,
        result,
        parentKeywordUuid,
        categoryUuid,
        keywordUuid
      });
    } catch (e) {
      dispatch({
        type: CustomCategoryActions.MAKE_KEYWORD_VARIATION_PRIMARY_FAILURE
      });
    }
  };

export const createTriggerPrompt =
  ({ categoryUuid, label }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.CREATE_TRIGGER_PROMPT_REQUEST,
      meta: {
        analytics: {
          eventType: EventTypes.track,
          eventPayload: { properties: { label } }
        }
      }
    });
    try {
      let result = await post(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/prompt/`,
        { label }
      );
      result = camelize(result);
      dispatch({
        type: CustomCategoryActions.CREATE_TRIGGER_PROMPT_SUCCESS,
        result,
        categoryUuid
      });
    } catch (e) {
      dispatch({
        type: CustomCategoryActions.CREATE_TRIGGER_PROMPT_FAILURE
      });
    }
  };

export const createTriggerPromptVariation =
  ({ categoryUuid, label, parent }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.CREATE_TRIGGER_PROMPT_VARIATION_REQUEST
    });

    try {
      let result = await post(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/prompt/`,
        {
          label,
          parent
        }
      );
      result = camelize(result);
      dispatch({
        type: CustomCategoryActions.CREATE_TRIGGER_PROMPT_VARIATION_SUCCESS,
        result,
        categoryUuid,
        parent
      });
    } catch (e) {
      const err = e.message;
      dispatch({
        type: CustomCategoryActions.CREATE_TRIGGER_PROMPT_VARIATION_FAILURE,
        err: err?.prompt[0]
      });
    }
  };

export const deleteTriggerPrompt =
  ({ categoryUuid, promptUuid }) =>
  async dispatch => {
    dispatch({ type: CustomCategoryActions.DELETE_TRIGGER_PROMPT_REQUEST });

    try {
      await deleteMethod(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/prompt/${promptUuid}/`
      );
      dispatch({
        type: CustomCategoryActions.DELETE_TRIGGER_PROMPT_SUCCESS,
        categoryUuid,
        promptUuid
      });
    } catch (e) {
      dispatch({ type: CustomCategoryActions.DELETE_TRIGGER_PROMPT_FAILURE });
    }
  };

export const deleteTriggerPromptVariation =
  ({ categoryUuid, parent, variationUuid }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.DELETE_TRIGGER_PROMPT_VARIATION_REQUEST
    });

    try {
      await deleteMethod(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/prompt/${variationUuid}/`
      );
      dispatch({
        type: CustomCategoryActions.DELETE_TRIGGER_PROMPT_VARIATION_SUCCESS,
        categoryUuid,
        variationUuid,
        parent
      });
    } catch (e) {
      dispatch({
        type: CustomCategoryActions.DELETE_TRIGGER_PROMPT_VARIATION_FAILURE
      });
    }
  };

export const updateTriggerPromptLabel =
  ({ categoryUuid, promptUuid, label, isPrimary, parent }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.UPDATE_TRIGGER_PROMPT_LABEL_REQUEST
    });

    try {
      let result = await patch(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/prompt/${promptUuid}/`,
        {
          label
        }
      );
      result = camelize(result);
      dispatch({
        type: CustomCategoryActions.UPDATE_TRIGGER_PROMPT_LABEL_SUCCESS,
        result,
        categoryUuid,
        promptUuid,
        isPrimary,
        parent
      });
    } catch (e) {
      dispatch({
        type: CustomCategoryActions.UPDATE_TRIGGER_PROMPT_LABEL_FAILURE
      });
    }
  };

export const makeTriggerPromptVariationPrimary =
  ({ categoryUuid, promptUuid, parent }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.MAKE_TRIGGER_PROMPT_VARIATION_PRIMARY_REQUEST
    });
    try {
      let result = await patch(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/prompt/${promptUuid}/make_primary/`
      );
      result = camelize(result);
      dispatch({
        type: CustomCategoryActions.MAKE_TRIGGER_PROMPT_VARIATION_PRIMARY_SUCCESS,
        result,
        parent,
        categoryUuid,
        promptUuid
      });
    } catch (e) {
      dispatch({
        type: CustomCategoryActions.MAKE_TRIGGER_PROMPT_VARIATION_PRIMARY_FAILURE
      });
    }
  };

export const clearVariationErrorMessage = () => async dispatch => {
  dispatch({ type: CustomCategoryActions.CLEAR_VARIATION_ERROR_MESSAGE });
};

// Used after updating meeting types whitelisted/blacklisted to update the redux store without the
// need to refetch the entire list of custom categories
export const fetchCustomCategory =
  ({ categoryUuid }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.FETCH_SINGLE_CUSTOM_CATEGORY_REQUEST
    });

    try {
      let response = await get(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/`
      );

      response = camelize(response);

      dispatch({
        type: CustomCategoryActions.FETCH_SINGLE_CUSTOM_CATEGORY_SUCCESS,
        categoryUuid,
        response
      });
    } catch (err) {
      dispatch({
        type: CustomCategoryActions.FETCH_SINGLE_CUSTOM_CATEGORY_FAILURE
      });
    }
  };

export const updateCategoryWhitelistedMeetingTypes =
  ({ categoryUuid, meetingTypeUuids }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.UPDATE_CUSTOM_CATEGORY_WHITELISTED_TYPES_REQUEST
    });

    try {
      await post(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/settings/whitelist_mt/`,
        meetingTypeUuids
      );

      dispatch({
        type: CustomCategoryActions.UPDATE_CUSTOM_CATEGORY_WHITELISTED_TYPES_SUCCESS
      });
    } catch (err) {
      dispatch({
        type: CustomCategoryActions.UPDATE_CUSTOM_CATEGORY_WHITELISTED_TYPES_FAILURE,
        err
      });
    }
  };

export const updateCategoryBlacklistedMeetingTypes =
  ({ categoryUuid, meetingTypeUuids }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.UPDATE_CUSTOM_CATEGORY_BLACKLISTED_TYPES_REQUEST
    });

    try {
      await post(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/settings/blacklist_mt/`,
        meetingTypeUuids
      );

      dispatch({
        type: CustomCategoryActions.UPDATE_CUSTOM_CATEGORY_BLACKLISTED_TYPES_SUCCESS
      });
    } catch (err) {
      dispatch({
        type: CustomCategoryActions.UPDATE_CUSTOM_CATEGORY_BLACKLISTED_TYPES_FAILURE,
        err
      });
    }
  };

export const createPhrase =
  ({ categoryUuid, payload }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.CREATE_PHRASE_REQUEST
    });

    try {
      let result = await post(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/tracker/`,
        payload
      );
      result = camelize(result);
      dispatch({
        type: CustomCategoryActions.CREATE_PHRASE_SUCCESS,
        result,
        categoryUuid
      });
    } catch (err) {
      dispatch({
        type: CustomCategoryActions.CREATE_PHRASE_FAILURE,
        err
      });

      throw err;
    }
  };

export const updatePhrase =
  ({ categoryUuid, phraseUuid, payload }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.UPDATE_PHRASE_REQUEST
    });

    try {
      let result = await patch(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/tracker/${phraseUuid}/`,
        payload
      );
      result = camelize(result);
      dispatch({
        type: CustomCategoryActions.UPDATE_PHRASE_SUCCESS,
        result,
        categoryUuid,
        phraseUuid
      });
    } catch (err) {
      dispatch({
        type: CustomCategoryActions.UPDATE_PHRASE_FAILURE,
        err
      });

      throw err;
    }
  };

export const deletePhrase =
  ({ categoryUuid, phraseUuid }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.DELETE_PHRASE_REQUEST
    });

    try {
      await deleteMethod(
        `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/tracker/${phraseUuid}/`
      );
      dispatch({
        type: CustomCategoryActions.DELETE_PHRASE_SUCCESS,
        categoryUuid,
        phraseUuid
      });
    } catch (err) {
      dispatch({
        type: CustomCategoryActions.DELETE_PHRASE_FAILURE,
        err
      });

      throw err;
    }
  };

export const generateSentenceVariants =
  ({ categoryUuid, payload, isUnifiedFlow = false }) =>
  async dispatch => {
    dispatch({
      type: CustomCategoryActions.GENERATE_SENTENCE_VARIANTS_REQUEST
    });

    try {
      let response = !isUnifiedFlow
        ? await post(
            `${env.avomaApiUrl}/api/v1/custom_category/${categoryUuid}/tracker/generate_variants/`,
            payload
          )
        : await post(
            `${env.avomaApiUrl}/api/v1/automation/generate_smart_phrases/`,
            payload
          );
      response = camelize(response);

      // Return the response to be used in stateful component
      return response;
    } catch (err) {
      dispatch({
        type: CustomCategoryActions.GENERATE_SENTENCE_VARIANTS_FAILURE,
        error: err.message
      });

      // Return error to be used in stateful component
      return { error: err.message };
    }
  };

export const createSmartTopic = payload => async dispatch => {
  dispatch({
    type: CustomCategoryActions.CREATE_SMART_TOPIC_REQUEST
  });

  try {
    let response = await post(
      `${env.avomaApiUrl}/api/v1/custom_category/create_with_associations/`,
      payload
    );
    response = camelize(response);
    dispatch({
      type: CustomCategoryActions.CREATE_SMART_TOPIC_SUCCESS,
      response
    });
  } catch (err) {
    dispatch({
      type: CustomCategoryActions.CREATE_SMART_TOPIC_FAILURE,
      error: err.message
    });

    // Return error to be used in stateful component
    return { error: err.message };
  }
};
