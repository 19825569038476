import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';
import { connect } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import {
  createSmartTopic as createSmartTopicAction,
  fetchCustomCategory as fetchCustomCategoryAction,
  patchCustomCategory as patchCustomCategoryAction,
  updateCategoryBlacklistedMeetingTypes as updateCategoryBlacklistedMeetingTypesAction,
  updateCategoryWhitelistedMeetingTypes as updateCategoryWhitelistedMeetingTypesAction
} from 'actions/insights';
import { updateOnboardingTile as updateOnboardingTileAction } from 'actions/onboarding';
import { fetchMeetingTypes as fetchMeetingTypesAction } from 'actions/outcomes';

import AvomaAccordion from 'components/Common/AvomaAccordion';
import AvomaDropdown from 'components/Common/AvomaDropdown';
import AvomaIcon from 'components/Common/AvomaIcon';
import NotificationBanner from 'components/Common/NotificationBanner';
import TrackTrends from 'components/SmartCategories/CategoryDetails/Tabs/TrackTrends';
import TriggerPrompts from 'components/SmartCategories/CategoryDetails/Tabs/TriggerPrompts';
import { errorNotification } from 'components/ToastNotifications';

import L10n from 'helpers/L10n';

import { DEFAULT_NEW_CATEGORY } from 'constants/smartCategories';

import { ReactComponent as ExpandIcon } from 'images/downArrow.svg';
import { ReactComponent as CalendarIcon } from 'images/ic_calendar.svg';
import { ReactComponent as ChecklistIcon } from 'images/ic_checklist.svg';
import { ReactComponent as NumberIcon } from 'images/ic_number.svg';
import { ReactComponent as TextIcon } from 'images/ic_text.svg';

import CustomKeywords from './Tabs/CustomKeywords';
import CustomPhrasesV2 from './Tabs/CustomPhrasesV2';
import { createId } from '@paralleldrive/cuid2';

export const isFillersCategory = name =>
  (name || '').toLowerCase().trim() === 'filler words';

class CategoryDetailsV2 extends React.Component {
  state = {
    category: DEFAULT_NEW_CATEGORY,
    shouldShowReprocessInfo: false,
    prompts: [],
    keywords: [],
    trackers: [],
    keywordNotesEnabled: false,
    selectedDataTypeOption: 'text',
    selectedFormatOption: 'bullet',
    selectedLengthOption: 'medium',
    openAccordionUuids: []
  };

  componentDidMount() {
    const {
      meetingTypes,
      fetchMeetingTypes,
      fetchingMeetingTypes,
      category,
      keywords,
      prompts,
      trackers,
      keywordNotesEnabled,
      selectedDataTypeOption,
      selectedFormatOption,
      selectedLengthOption
    } = this.props;

    if (!isEmpty(category?.uuid !== 'new')) {
      this.setState({
        category,
        shouldShowReprocessInfo: false,
        prompts: prompts[0] ?? {},
        keywords:
          (keywords || [])?.map(keyword => ({
            id: createId(),
            ...keyword
          })) ?? {},
        trackers: trackers ?? [],
        keywordNotesEnabled: keywordNotesEnabled ?? false,
        selectedDataTypeOption: selectedDataTypeOption ?? 'text',
        selectedFormatOption: selectedFormatOption ?? 'bullet',
        selectedLengthOption: selectedLengthOption ?? 'short'
      });
    }

    if (isEmpty(meetingTypes) && !fetchingMeetingTypes) {
      fetchMeetingTypes();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      error,
      category,
      isShowCustomPhraseDetails,
      keywords,
      trackers,
      prompts,
      keywordNotesEnabled,
      selectedDataTypeOption,
      selectedFormatOption,
      selectedLengthOption
    } = this.props;

    if (prevProps.error !== error && error) {
      errorNotification({ message: L10n.customCategory.error });
    }

    if (prevProps.category.uuid !== category.uuid) {
      this.setState({
        shouldShowReprocessInfo: false
      });
    }

    if (prevProps.keywordNotesEnabled !== keywordNotesEnabled) {
      this.setState({
        keywordNotesEnabled
      });
    }

    if (prevProps.selectedDataTypeOption !== selectedDataTypeOption) {
      this.setState({
        selectedDataTypeOption
      });
    }

    if (prevProps.selectedFormatOption !== selectedFormatOption) {
      this.setState({
        selectedFormatOption
      });
    }

    if (prevProps.selectedLengthOption !== selectedLengthOption) {
      this.setState({
        selectedLengthOption
      });
    }

    if (!isEqual(prevProps.keywords, keywords)) {
      this.setState({
        keywords
      });
    }

    if (!isEqual(prevProps.trackers, trackers)) {
      this.setState({
        trackers
      });
    }

    if (!isEqual(prevProps.prompts[0], prompts[0])) {
      this.setState({
        prompts: prompts[0]
      });
    }
  }

  toggleExtractNotes = () => {
    const { keywordNotesEnabled } = this.state;
    const { setIsKeywordNotesEnabled } = this.props;

    this.setState({ keywordNotesEnabled: !keywordNotesEnabled });
    setIsKeywordNotesEnabled(!keywordNotesEnabled);
  };

  handleAddOrModifyKeywordOrPhrase = () => {
    this.setState({ shouldShowReprocessInfo: true });
  };

  handleAccordionChange = uuids => {
    this.setState({
      openAccordionUuids: [...uuids]
    });
  };

  render() {
    const {
      view,
      loading,
      isSmartTrackersFlagEnabled,
      setPrompts,
      setKeywords,
      setTrackers,
      setSelectedDataTypeOption,
      setSelectedFormatOption,
      setSelectedLengthOption,
      setIsShowCustomPhraseDetails,
      isShowCustomPhraseDetails
    } = this.props;

    const {
      category,
      shouldShowReprocessInfo,
      prompts,
      keywords,
      trackers,
      keywordNotesEnabled,
      selectedDataTypeOption,
      selectedFormatOption,
      selectedLengthOption,
      openAccordionUuids
    } = this.state;

    const isCategoryNew = category?.uuid === 'new';
    const noKeywordsPresent = Object.values(keywords ?? {}).length === 0;
    const noPhrasesPresent = Object.values(trackers ?? {}).length === 0;

    const options = {
      dataTypeOptions: [
        {
          label: (
            <div className='flex items-center'>
              <AvomaIcon className='mr-3' RenderIcon={TextIcon} small />
              <span>Text</span>
            </div>
          ),
          value: 'text'
        },
        {
          label: (
            <div className='flex items-center'>
              <AvomaIcon className='mr-3' RenderIcon={NumberIcon} small />
              <span>Number</span>
              <div className='ml-2 items-center justify-center p-1 uppercase text-x3s bg-azure text-white font-bold rounded'>
                Coming Soon!
              </div>
            </div>
          ),
          disabled: true,
          value: 'number'
        },
        {
          label: (
            <div className='flex items-center'>
              <AvomaIcon className='mr-3' RenderIcon={ChecklistIcon} small />
              <span>Checklist</span>
              <div className='ml-2 items-center justify-center p-1 uppercase text-x3s bg-azure text-white font-bold rounded'>
                Coming Soon!
              </div>
            </div>
          ),
          disabled: true,
          value: 'checklist'
        },
        {
          label: (
            <div className='flex items-center'>
              <AvomaIcon className='mr-3' RenderIcon={CalendarIcon} small />
              <span>Date</span>
              <div className='ml-2 items-center justify-center p-1 uppercase text-x3s bg-azure text-white font-bold rounded'>
                Coming Soon!
              </div>
            </div>
          ),
          disabled: true,
          value: 'date'
        }
      ],
      formatOptions: [
        {
          label: 'Bullet points',
          value: 'bullet'
        },
        {
          label: (
            <div className='flex items-center'>
              <span>Paragraph</span>
              <div className='ml-2 items-center justify-center p-1 uppercase text-x3s bg-azure text-white font-bold rounded'>
                Coming Soon!
              </div>
            </div>
          ),
          disabled: true,
          value: 'paragraph'
        }
      ],
      lengthOptions: [
        {
          label: 'Short',
          value: 'short'
        },
        {
          label: 'Medium',
          value: 'medium'
        },
        {
          label: 'Long',
          value: 'long'
        }
      ]
    };

    return (
      <div
        className={twMerge(
          'flex flex-col min-w-[600px] space-y-4 px-4',
          !isShowCustomPhraseDetails && 'pt-6',
          isShowCustomPhraseDetails && 'py-0 h-full'
        )}
      >
        {!isShowCustomPhraseDetails && (
          <AvomaAccordion
            allowMultipleExpanded
            allowZeroExpanded
            style={{ margin: 0 }}
            onChange={this.handleAccordionChange}
            preExpanded={openAccordionUuids}
          >
            <AccordionItem>
              <AccordionItemState>
                {state => (
                  <React.Fragment>
                    <AccordionItemHeading>
                      <AccordionItemButton className='flex cursor-pointer items-center'>
                        <div className='flex w-full items-center gap-x-2'>
                          <AvomaIcon
                            RenderIcon={ExpandIcon}
                            iconStyle={{
                              transitionDuration: '0.3s',
                              ...(!state.expanded && {
                                transform: 'rotate(-90deg)'
                              })
                            }}
                            extraSmall
                          />
                          <div className='text-base font-bold'>
                            Change formatting rules{' '}
                            <span className='font-normal text-silver'>
                              (optional)
                            </span>
                          </div>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className='p-5 flex items-center gap-x-4'>
                        <div>
                          <p className='text-sm font-bold mb-1'>Data Type</p>
                          <AvomaDropdown
                            items={options.dataTypeOptions}
                            value={selectedDataTypeOption}
                            onSelect={val => {
                              this.setState({ selectedDataTypeOption: val });
                              setSelectedDataTypeOption(val);
                            }}
                            containerStyles={{
                              width: '180px',
                              fontSize: '16px'
                            }}
                            menuStyles={{ width: '240px' }}
                          />
                        </div>

                        <div>
                          <p className='text-sm font-bold mb-1'>Format</p>
                          <AvomaDropdown
                            items={options.formatOptions}
                            value={selectedFormatOption}
                            onSelect={val => {
                              this.setState({ selectedFormatOption: val });
                              setSelectedFormatOption(val);
                            }}
                            containerStyles={{
                              width: '180px',
                              fontSize: '16px'
                            }}
                            menuStyles={{ width: '240px' }}
                          />
                        </div>

                        <div>
                          <p className='text-sm font-bold mb-1'>Length</p>
                          <AvomaDropdown
                            items={options.lengthOptions}
                            value={selectedLengthOption}
                            onSelect={val => {
                              this.setState({ selectedLengthOption: val });
                              setSelectedLengthOption(val);
                            }}
                            containerStyles={{
                              width: '180px',
                              fontSize: '16px'
                            }}
                            menuStyles={{ width: '200px' }}
                          />
                        </div>
                      </div>
                    </AccordionItemPanel>
                  </React.Fragment>
                )}
              </AccordionItemState>
            </AccordionItem>

            <AccordionItem className='!mt-6'>
              <AccordionItemState>
                {state => (
                  <React.Fragment>
                    <AccordionItemHeading>
                      <AccordionItemButton className='flex cursor-pointer items-center'>
                        <div className='flex w-full items-center gap-x-2'>
                          <AvomaIcon
                            RenderIcon={ExpandIcon}
                            iconStyle={{
                              transitionDuration: '0.3s',
                              ...(!state.expanded && {
                                transform: 'rotate(-90deg)'
                              })
                            }}
                            extraSmall
                          />
                          <div className='text-base font-bold'>
                            Add cue questions{' '}
                            <span className='font-normal text-silver'>
                              (optional)
                            </span>
                          </div>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className='p-5'>
                        <TriggerPrompts
                          category={category}
                          togglePromptNotes={this.togglePromptNotes}
                          loading={loading}
                          isFillersCategory={isFillersCategory}
                          setTriggerPromptExtractLength={
                            this.setTriggerPromptExtractLength
                          }
                          setTriggerPromptExtractStrategy={
                            this.setTriggerPromptExtractStrategy
                          }
                          isUnifiedFlow
                          prompt={prompts}
                          setPrompt={updatedPrompt => {
                            setPrompts([{ ...updatedPrompt }]);
                          }}
                        />
                      </div>
                    </AccordionItemPanel>
                  </React.Fragment>
                )}
              </AccordionItemState>
            </AccordionItem>

            <AccordionItem className='!mt-6' uuid='keywords-panel'>
              <AccordionItemState>
                {state => (
                  <React.Fragment>
                    <AccordionItemHeading>
                      <AccordionItemButton className='flex cursor-pointer items-center'>
                        <div className='flex w-full items-center gap-x-2'>
                          <AvomaIcon
                            RenderIcon={ExpandIcon}
                            iconStyle={{
                              transitionDuration: '0.3s',
                              ...(!state.expanded && {
                                transform: 'rotate(-90deg)'
                              })
                            }}
                            extraSmall
                          />
                          <div className='text-base font-bold'>
                            Keywords and Smart Trackers{' '}
                            <span className='font-normal text-silver'>
                              (optional)
                            </span>
                          </div>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className='px-5 pt-5 flex flex-col gap-y-4'>
                        <CustomKeywords
                          category={category}
                          loading={loading}
                          isKeywordsToNotesEnabled // since we want to be able to add keywords
                          handleAddOrModifyKeywordOrPhrase={
                            this.handleAddOrModifyKeywordOrPhrase
                          }
                          customStyles={{ margin: '0' }}
                          isUnifiedFlow
                          keywords={keywords}
                          setKeywords={updatedKeywords => {
                            setKeywords([...updatedKeywords]);
                          }}
                        />

                        <TrackTrends
                          view={view}
                          category={category}
                          loading={loading}
                          keywordNotesEnabled={keywordNotesEnabled}
                          toggleExtractNotes={this.toggleExtractNotes}
                          isCategoryNew={isCategoryNew}
                          noKeywordsPresent={noKeywordsPresent}
                          noPhrasesPresent={noPhrasesPresent}
                          isSmartTrackersEnabled={isSmartTrackersFlagEnabled}
                          isUnifiedFlow
                        />

                        {/* <div className='mt-3'>
                          <CustomPhrases
                            category={category}
                            loading={loading}
                            handleAddOrModifyKeywordOrPhrase={
                              this.handleAddOrModifyKeywordOrPhrase
                            }
                            trackers={trackers}
                            setTrackers={updatedTrackers => {
                              this.setState({ trackers: [...updatedTrackers] });
                              setTrackers([...updatedTrackers]);
                            }}
                            isUnifiedFlow
                            isShowCustomPhraseDetails={
                              isShowCustomPhraseDetails
                            }
                            setIsShowCustomPhraseDetails={
                              setIsShowCustomPhraseDetails
                            }
                          />
                        </div> */}

                        {shouldShowReprocessInfo && (
                          <div className='mt-5 flex items-start'>
                            <NotificationBanner
                              content={
                                isSmartTrackersFlagEnabled
                                  ? L10n.customCategory
                                      .reprocessKeywordsAndPhrasesInfo
                                  : L10n.customCategory.reprocessKeywordsInfo
                              }
                            />
                          </div>
                        )}
                      </div>
                    </AccordionItemPanel>
                  </React.Fragment>
                )}
              </AccordionItemState>
            </AccordionItem>
          </AvomaAccordion>
        )}

        {openAccordionUuids?.includes('keywords-panel') && (
          <div
            className={twMerge(
              'mt-0 h-full',
              !isShowCustomPhraseDetails && 'px-6'
            )}
          >
            <CustomPhrasesV2
              category={category}
              loading={loading}
              handleAddOrModifyKeywordOrPhrase={
                this.handleAddOrModifyKeywordOrPhrase
              }
              trackers={trackers}
              setTrackers={updatedTrackers => {
                this.setState({ trackers: [...updatedTrackers] });
                setTrackers([...updatedTrackers]);
              }}
              isUnifiedFlow
              isShowCustomPhraseDetails={isShowCustomPhraseDetails}
              setIsShowCustomPhraseDetails={setIsShowCustomPhraseDetails}
            />
          </div>
        )}
      </div>
    );
  }
}

CategoryDetailsV2.propTypes = {
  view: PropTypes.string,
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    keywords: PropTypes.object,
    trackers: PropTypes.object,
    settings: PropTypes.shape({
      augNotesEnabled: PropTypes.bool.isRequired,
      keywordNotesEnabled: PropTypes.bool.isRequired,
      keywordTrackingEnabled: PropTypes.bool.isRequired,
      promptNotesEnabled: PropTypes.bool.isRequired,
      promptExtractLength: PropTypes.string.isRequired,
      meetingTypeRule: PropTypes.oneOf([1, 2, 3]),
      meetingTypesBlacklisted: PropTypes.array,
      meetingTypesWhitelisted: PropTypes.array
    }),
    backgroundColor: PropTypes.string,
    keywordCount: PropTypes.number,
    cuePhrasesCount: PropTypes.number,
    uuid: PropTypes.string,
    isDefault: PropTypes.bool
  }),
  loading: PropTypes.bool,
  error: PropTypes.string,
  meetingTypes: PropTypes.object.isRequired,
  keywordsOnboarded: PropTypes.shape({
    dismissed: PropTypes.bool,
    done: PropTypes.bool
  }).isRequired,
  fetchMeetingTypes: PropTypes.func.isRequired,
  fetchingMeetingTypes: PropTypes.bool.isRequired,
  isSmartTrackersFlagEnabled: PropTypes.bool,
  setTrackers: PropTypes.func,
  setKeywords: PropTypes.func,
  setPrompts: PropTypes.func,
  setIsKeywordNotesEnabled: PropTypes.func,
  setSelectedDataTypeOption: PropTypes.func,
  setSelectedFormatOption: PropTypes.func,
  isShowCustomPhraseDetails: PropTypes.bool,
  setIsShowCustomPhraseDetails: PropTypes.func,
  setSelectedLengthOption: PropTypes.func,
  keywords: PropTypes.array,
  prompts: PropTypes.array,
  trackers: PropTypes.array,
  keywordNotesEnabled: PropTypes.bool,
  selectedDataTypeOption: PropTypes.string,
  selectedFormatOption: PropTypes.string,
  selectedLengthOption: PropTypes.string
};

CategoryDetailsV2.defaultProps = {
  error: null
};

const ConnectedCategoryDetailsV2 = connect(
  state => {
    const keywordsOnboarded = state.onboarding.homeTiles.keywords || {};
    const featureFlags = state.user?.flags;
    const isSmartTrackersFlagEnabled = featureFlags.includes('smart_trackers');

    return {
      keywordsOnboarded,
      meetingTypes: state.outcomes.types,
      fetchingMeetingTypes: state.outcomes.fetchingTypes,
      features: state.user.features,
      isSmartTrackersFlagEnabled
    };
  },
  {
    patchCustomCategory: patchCustomCategoryAction,
    updateOnboardingTile: updateOnboardingTileAction,
    fetchMeetingTypes: fetchMeetingTypesAction,
    updateCategoryWhitelistedMeetingTypes:
      updateCategoryWhitelistedMeetingTypesAction,
    updateCategoryBlacklistedMeetingTypes:
      updateCategoryBlacklistedMeetingTypesAction,
    fetchCustomCategory: fetchCustomCategoryAction,
    createSmartTopic: createSmartTopicAction
  }
)(Radium(CategoryDetailsV2));

export default ConnectedCategoryDetailsV2;
