import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import { createEditor, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';
import EditorFrame from 'components/Notes/Slate/EditorFrame';
import Paragraph, {
  onKeyDown as onKeyDownParagraph
} from 'components/Notes/Slate/Elements/Paragraph';
import Header from 'components/Notes/Slate/Elements/Header';
import {
  TYPE_HEADER1,
  TYPE_HEADER2,
  TYPE_HEADER3,
  TYPE_PARAGRAPH
} from 'components/Notes/Slate/Plugins/Types';
import Toolbar from 'components/Notes/Slate/Toolbar';
import Link from 'components/Notes/Slate/Elements/Link';
import withLink, { TYPE_LINK } from 'components/Notes/Slate/Plugins/withLink';
import { onKeyUpUtils } from 'helpers/SlateHelper/onKeyUpUtils';
import { agendaFinderRegex } from 'helpers/findAgendaRegex';
import withList from '../Slate/Plugins/List/withList';
import withMarkdown from '../Slate/Plugins/withMarkdown';
import withAvoma from '../Slate/Plugins/withAvoma';
import withMarks from '../Slate/Plugins/withMarks';
import withMentions, { TYPE_MENTION } from '../Slate/Plugins/withMentions';
import Mention from '../Slate/Elements/Mention';

/* 
  A lightweight editor based on CollabEditor code, but without the collaborative features/User mentions/trigger prompt displays.
  Would be used to create Email Agenda and Note sharing text to be sent via emails.
*/

const ModalNoteEditor = ({
  initialValue,
  onChangeNoteValue,
  editorStyle,
  className,
  noteValue,
  hasMeetingConcluded,
  currentUserName,
  isModalOpen,
  handleAgendaAvailable,
  editorId
}) => {
  const [value, setValue] = useState(initialValue);

  const extractAgendaPoints = async () => {
    const agendaBlockIndex = await noteValue?.findIndex(node =>
      agendaFinderRegex.test(node.children[0].text?.toLowerCase())
    );

    const blankLine = {
      type: 'paragraph',
      children: [{ text: '' }]
    };

    // If Agenda Block doesn't exists
    if (agendaBlockIndex === -1) {
      handleAgendaAvailable(false);
      return [blankLine];
    }

    // If nothing exists after the Agenda Header
    if (agendaBlockIndex === noteValue.length - 1) {
      handleAgendaAvailable(false);
      return [blankLine];
    }

    handleAgendaAvailable(true);

    const postAgendaBlockNodes = noteValue.slice(agendaBlockIndex + 1);
    const nextHeaderNode = postAgendaBlockNodes.findIndex(
      node =>
        node.type === 'header-1' ||
        node.type === 'header-2' ||
        node.type === 'header-3'
    );

    const extractedAgenda = postAgendaBlockNodes.slice(
      0,
      nextHeaderNode === -1 ? undefined : nextHeaderNode
    );

    return extractedAgenda;
  };

  const emailFooter = [
    {
      type: 'paragraph',
      children: [{ text: '' }]
    },
    {
      type: 'paragraph',
      children: [{ text: 'Talk soon,' }]
    },
    {
      type: 'paragraph',
      children: [{ text: currentUserName }]
    }
  ];

  const insertIntoEditor = extractedValue => {
    // note value is an array so would need to spread it
    Transforms.insertNodes(editor, [...extractedValue, ...emailFooter]);
  };

  useEffect(() => {
    async function extractAndAssignAgendaPoints() {
      const result = await extractAgendaPoints();
      await insertIntoEditor(result);
    }

    async function extractAndAssignNotes() {
      await insertIntoEditor(noteValue);
    }

    if (noteValue) {
      if (hasMeetingConcluded) {
        extractAndAssignNotes();
      } else {
        extractAndAssignAgendaPoints();
      }
    }
  }, [isModalOpen]);

  // If not set to true, it doesn't bubble to `withAvoma` and from there to `withMarks`
  const onKeyDownMention = () => true;

  const editor = useMemo(() => {
    const keyDowns = [onKeyDownParagraph, onKeyDownMention];

    const editorRef = createEditor();

    return withLink(
      withMentions(
        withList(
          withMarkdown(
            withAvoma(withMarks(withReact(withHistory(editorRef))), keyDowns)
          )
        )
      )
    );
  }, []);

  const onChange = newValue => {
    const cats = {};
    if (!editor.children) {
      return;
    }

    const toDeHighlight = [];
    // If there are duplicates, keep the on key that was there first OR the first one
    Object.keys(cats).forEach(blockKey => {
      const existingKey = cats && cats[blockKey];
      const keys = cats[blockKey];
      if (existingKey && keys.includes(existingKey)) {
        keys.forEach(kk => {
          if (kk !== existingKey) {
            toDeHighlight.push(kk);
          }
        });
        cats[blockKey] = existingKey;
      } else {
        // eslint-disable-next-line prefer-destructuring
        cats[blockKey] = keys[0];
        keys.slice(1).forEach(kk => {
          toDeHighlight.push(kk);
        });
      }
    });
    setValue(newValue);
    if (onChangeNoteValue) {
      onChangeNoteValue(newValue);
    }
  };

  const onKeyUp = event => {
    onKeyUpUtils(event, editor);
  };

  const renderElement = elementProps => {
    const { children, element, attributes } = elementProps;
    switch (element.type) {
      case TYPE_PARAGRAPH:
        return (
          <Paragraph
            element={element}
            editor={editor}
            hideBlockButton
            {...attributes}
          >
            {children}
          </Paragraph>
        );
      case TYPE_HEADER1:
      case TYPE_HEADER2:
      case TYPE_HEADER3:
        return (
          <Header
            element={element}
            size={element.type}
            hideBlockButton
            showCategoryPrompt={false}
            colorCategoryBorders={false}
            {...attributes}
          >
            {children}
          </Header>
        );
      case TYPE_MENTION:
        return <Mention {...elementProps} />;

      case TYPE_LINK:
        return (
          <Link
            elementProps={elementProps}
            editor={editor}
            editorId={editorId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ position: 'relative' }} id='modal-note-editor-div'>
      <Toolbar editor={editor} roundedToolbar />
      <EditorFrame
        editor={editor}
        value={value}
        onChange={onChange}
        style={editorStyle}
        className={className}
        renderElement={renderElement}
        readOnly={false}
        onKeyDownForMention={() => {}}
        onKeyUp={onKeyUp}
        // onMouseDown={onMouseDown}
        // onMouseUp={onMouseUp}
      />
    </div>
  );
};

ModalNoteEditor.propTypes = {
  initialValue: PropTypes.array,
  onChangeNoteValue: PropTypes.func,
  noteValue: PropTypes.array,
  editorStyle: stylePropType,
  className: PropTypes.string,
  hasMeetingConcluded: PropTypes.bool,
  currentUserName: PropTypes.string,
  isModalOpen: PropTypes.bool,
  handleAgendaAvailable: PropTypes.func,
  editorId: PropTypes.string
};

export default ModalNoteEditor;
