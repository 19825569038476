/* eslint-disable react/no-deprecated */
import PropTypes from 'prop-types';
import Radium from 'radium';
import React, { Component } from 'react';
import stylePropType from 'react-style-proptype';
import { twMerge } from 'tailwind-merge';

import Tokens from 'styles/tokens';

class AvomaNavTabs extends Component {
  state = {
    selectedTabId: null
  };

  componentDidMount() {
    const { defaultTabId } = this.props;
    this.setState({ selectedTabId: defaultTabId });
  }

  // For cases where the tabs are switched dynamically due to a condition rather than onClick. Eg being when adding a
  // new category, if the user was on keywords, we want them to switch to details and also take the styles with it
  componentDidUpdate(prevProps) {
    const { selectedTab } = this.props;
    if (prevProps.selectedTab !== selectedTab) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedTabId: selectedTab });
    }
  }

  onClick = selectedTabId => {
    const { onTabSelected } = this.props;
    this.setState({ selectedTabId });
    onTabSelected(selectedTabId);
  };

  renderItem = item => {
    const { selectedTabId } = this.state;
    const { itemStyles, itemClassName } = this.props;
    const style = {};
    if (item.id === selectedTabId) {
      style.color = Tokens.colors.black;
      style.borderBottom = `2px solid ${Tokens.colors.red}`;
    }
    // Setting the type as button compared to previously it being a div, since div tags don't allow disabled states to be used even if
    // the role is of button. Changing it to a button with some css changes, allow us to manipulate certain tabs to be disabled, till an action is completed
    // or condition is met. (Example usage would be Disabling the tab for Keywords/Cue Phrases in Smart Categories till the Category hasn't been created)
    return (
      <button
        type='button'
        key={item.id}
        onClick={() => this.onClick(item.id)}
        tabIndex='0'
        className={itemClassName}
        style={{
          ...styles.item,
          ...itemStyles,
          ...style,
          ...(item.disabled && { ...styles.disabled })
        }}
        disabled={item.disabled}
      >
        {item.nameNode}
      </button>
    );
  };

  render() {
    const { items, containerStyles, containerClassName } = this.props;

    return (
      <div
        className={twMerge(
          'flex pt-4 sticky top-0 w-full bg-snow',
          containerClassName
        )}
        style={{ ...containerStyles }}
      >
        {items.map(item => this.renderItem(item))}
      </div>
    );
  }
}

AvomaNavTabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      nameNode: PropTypes.node,
      id: PropTypes.string,
      disabled: PropTypes.bool
    })
  ),
  containerClassName: PropTypes.string,
  defaultTabId: PropTypes.string.isRequired,
  onTabSelected: PropTypes.func.isRequired,
  containerStyles: stylePropType,
  itemStyles: stylePropType,
  itemClassName: PropTypes.string,
  selectedTab: PropTypes.string
};

AvomaNavTabs.defaultProps = {
  items: {
    disabled: false
  },
  selectedTab: '',
  itemClassName: ''
};

const styles = {
  items: {
    display: 'flex',
    paddingTop: Tokens.spacing.two,
    position: 'sticky',
    top: 0,
    width: '100%',
    backgroundColor: Tokens.colors.snow
  },
  item: {
    ...Tokens.type.body,
    lineHeight: '17px',
    fontWeight: 'bold',
    color: Tokens.colors.silver,
    cursor: 'pointer',
    outline: 'none',
    marginLeft: Tokens.spacing.two,
    marginRight: Tokens.spacing.two,
    paddingBottom: Tokens.spacing.one,
    background: 'none',
    border: 'none',
    borderBottom: `2px solid ${Tokens.colors.white}`,
    ':hover': {
      color: Tokens.colors.black
    }
  },
  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed'
  }
};

export default Radium(AvomaNavTabs);
