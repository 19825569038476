import L10n from 'helpers/L10n';
import env from 'helpers/env';

export const agendaIntro = (meetingTitle, salutation) => [
  {
    type: 'paragraph',
    children: [{ text: `Hi ${salutation},` }]
  },
  {
    type: 'paragraph',
    children: [{ text: '' }]
  },
  {
    type: 'paragraph',
    children: [
      {
        text: `Here's the agenda for our upcoming meeting "${meetingTitle}" for you to review. Let me know if you want me to add anything to the agenda.`
      }
    ]
  }
];
export const notesIntro = salutation => [
  {
    type: 'paragraph',
    children: [{ text: `Hi ${salutation},` }]
  },
  {
    type: 'paragraph',
    children: [{ text: '' }]
  },
  {
    type: 'paragraph',
    children: [
      {
        text: L10n.emailIntroductions.notesIntro
      }
    ]
  }
];

export const requestLicenseIntro = (
  salutation,
  featureName,
  currentLicense = null
) => {
  const data = [
    {
      type: 'paragraph',
      children: [{ text: `Hi ${salutation},` }]
    },
    {
      type: 'paragraph',
      children: [{ text: '' }]
    },
    {
      type: 'paragraph',
      children: [
        {
          text: L10n.emailIntroductions.requestLicense.text1
        }
      ]
    },
    {
      type: 'paragraph',
      children: [
        {
          text: L10n.emailIntroductions.requestLicense.text2
        }
      ]
    },
    {
      type: 'paragraph',
      children: [{ text: '' }]
    }
  ];
  if (featureName) {
    data.push({
      type: 'paragraph',
      children: [
        {
          text: L10n.emailIntroductions.requestLicense.featureRequested,
          bold: true
        },
        {
          text: featureName
        }
      ]
    });
  }
  if (currentLicense) {
    data.push({
      type: 'paragraph',
      children: [
        {
          text: L10n.emailIntroductions.requestLicense.currentLicense,
          bold: true
        },
        {
          text: currentLicense
        }
      ]
    });
    data.push({
      type: 'paragraph',
      children: [{ text: '' }]
    });
  }
  data.push({
    type: 'paragraph',
    children: [
      {
        text: 'Click '
      },
      {
        type: 'link',
        url: `${env.avomaFrontendUrl}/settings/licenses`,
        children: [
          {
            text: 'here'
          }
        ]
      },
      {
        text: L10n.emailIntroductions.requestLicense.text3
      }
    ]
  });
  return data;
};
