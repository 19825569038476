// eslint-disable-next-line import/no-cycle
import { validURL } from 'helpers';
import {
  Editor,
  Path,
  Range,
  Element as SlateElement,
  Transforms
} from 'slate';
import { ReactEditor } from 'slate-react';

export const TYPE_LINK = 'link';

const withLink = editor => {
  const { insertData, isInline, insertText } = editor;

  // Link is inline element
  editor.isInline = element =>
    element.type === 'link' ? true : isInline(element);

  editor.insertLink = (url, title = '') => {
    if (editor.selection) {
      const urlText = editor.addHttpInUrl(url);
      editor.wrapLink(urlText, title);
    }
  };

  // If text is URL then call wrapLink method
  editor.insertData = data => {
    const text = data.getData('text/plain');

    if (text && validURL(text)) {
      // When user copy paste any URL, then wrapLink code executes
      editor.wrapLink(text);
    } else {
      insertData(data);
    }
  };

  editor.wrapLink = (url, title = '') => {
    if (editor.isLinkActive(editor)) {
      editor.unwrapLink(editor);
    }

    const { selection } = editor;

    // If isCollapsed is true then both cursor start and end position are same
    // If isCollapsed is true then make the text = url
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
      type: 'link',
      url,
      children: isCollapsed ? [{ text: title || url }] : []
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: 'end' });
    }
  };

  editor.isLinkActive = () => {
    const [link] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
    });
    return !!link;
  };

  editor.unwrapLink = () => {
    Transforms.unwrapNodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
    });
  };

  editor.removeLink = element => {
    const path = ReactEditor.findPath(editor, element);
    /*
      This method is called whan user want to remove hyperlink.
      Do not remove the setTimeout condition, it will cause error if we remove setTimeout.
    */
    setTimeout(() => {
      Transforms.unwrapNodes(editor, { at: path });
    }, 100);
  };

  editor.updateLink = (element, link) => {
    const path = ReactEditor.findPath(editor, element);
    const nextPath = Path.next(path);
    Transforms.insertNodes(editor, link, { at: nextPath });
    setTimeout(() => {
      Transforms.removeNodes(editor, { at: path });
    }, 200);
  };

  editor.addLinkOnSpace = (url, range) => {
    Transforms.select(editor, range);
    editor.insertLink(url);

    // Move the cursor to the position after the space
    const endOfRange = Editor.end(editor, range);
    const nextPoint = Editor.after(editor, endOfRange, { unit: 'character' });

    // Move the cursor to the next point
    Transforms.select(editor, nextPoint);

    ReactEditor.focus(editor);
  };

  editor.addLinkOnEnter = (url, range) => {
    Transforms.select(editor, range);
    editor.insertLink(url);
  };

  editor.addLinkOnEnterForFirstLine = (url, range) => {
    Transforms.select(editor, range);
    editor.insertLink(url);
    Transforms.deselect(editor, range);
    ReactEditor.focus(editor);
  };

  editor.addHttpInUrl = url => {
    const isUrlHasHttp =
      url.indexOf('http://') === 0 || url.indexOf('https://') === 0;
    if (!isUrlHasHttp) return `http://${url}`;
    return url;
  };

  // Override insertText to handle typing beside a link
  editor.insertText = text => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [link] = Editor.nodes(editor, {
        match: n =>
          !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
      });

      if (link) {
        const [, path] = link;
        const end = Editor.end(editor, path);

        // This allows us to type beside a link and not have it be part of the link title
        if (Range.includes(selection, end)) {
          Transforms.move(editor, { distance: 1, unit: 'offset' });
          Transforms.insertText(editor, text);
          return;
        }
      }
    }

    insertText(text);
  };

  return editor;
};

export default withLink;
