class SlateHelper {
  static OLD_DEFAULT =
    '{"object": "value", "document": {"object": "document", "data": {}, "nodes": []}}';

  static NEW_DEFAULT =
    '{"object":"value","document":{"object":"document","data":{},"nodes":[{"object":"block","type":"paragraph","data":{"className":null},"nodes":[{"object":"text","text":"","marks":[]}]}]}}';

  static fromDraftJs = draftText => {
    const json = JSON.parse(draftText);
    if (json) {
      // const draftJsHtml = draftToHtml(json);
      // const html = new Html({ rules });
      // const value = html.deserialize(draftJsHtml);
      // return value;
      return '';
    }
    return null;
  };

  static getTextFromNode = node => {
    if (node.children) {
      return node.children.map(x => x.text).join('');
    }
  };

  static clearHistory = editor => {
    editor.history = {
      redos: [],
      undos: []
    };
  };
}

export default SlateHelper;
