export const L10nTemplates = /** @type {const} */ ({
  templates: 'Templates',
  changeTemplate: 'Change Template',
  currentTemplate: 'Current template',
  current: 'Current',
  myTemplates: 'My templates',
  organizationTemplates: `Organization templates`,
  avomaTemplates: 'Avoma templates',
  allTemplates: 'All templates',
  createANewTemplate: 'Create a New Template',
  noTemplatesFound: 'No templates found',
  searchPlaceholder: 'Search for a template..',
  collapseSidebar: 'Collapse sidebar',
  expandSidebar: 'Expand sidebar',
  seeMore: 'See more',
  currentlyInUse: 'Currently in use',
  renameTemplateName: 'Update title',
  renameTemplateDescription: 'Update description',
  edit: 'Edit',
  doneEditing: 'Save and apply template',
  applyTemplate: 'Apply template',
  previewNotes: 'Preview notes',
  previewTemplate: 'Preview template',
  templatePreview: 'Template Preview',
  createdBy: 'Created by:',
  by: 'By',
  lastModified: 'Last modified',
  assignedTo: 'Assigned to:',
  noPurposesAssigned: 'No purposes assigned',
  defaultTemplateSwitchTitle: 'Set as my default template',
  promptTitle: 'Prompt',
  notesFormatTitle: 'Notes format:',
  crmMappingTitle: 'CRM Mapping',
  backToTemplate: 'Back to template',
  selectDifferentMeeting: 'Select a different meeting',
  notesPreviewTitle: 'Notes preview for this conversation',
  emptyTemplateMessageWithAuthority:
    'No Smart Topics have been added to this template. Please edit to add Smart Topics.',
  emptyTemplateMessageWithoutAuthority: 'Notes template is empty.',
  noAccessMessage:
    'You do not have permission to access this private template.',
  notAvailable: 'N/A',
  readOnly: 'Read-only',
  readOnlyTooltip:
    'This template can be duplicated or used for meetings but cannot be edited or deleted',
  setAsDefaultTemplateTooltip:
    'This template will be set as the default for all your meetings that do not already have an assigned template',
  participants: 'Participants',
  participantsDescription: 'List of participants from the conversation.',
  aiDetectedTopics: 'AI Detected Topics',
  aiDetectedTopicsDescription:
    'Avoma AI will generate notes for all the relevant topics detected in the conversation.',
  purposeNotAllowedTooltipForAvomaDefault:
    'Purposes not allowed for Avoma Default template',
  generatingNotes: 'Generating notes...',
  applyTemplateSuccess: 'Template applied and notes generated successfully',
  associatedTemplateSuccess: 'Template applied successfully',
  applyTemplateError: 'Failed to apply template to meeting',
  generateNotesError: 'Failed to generate notes for this meeting',
  errorGeneratingNotesMessage:
    'There was an error generating notes for this meeting. Please try again.',
  noPromptPlaceholder:
    'No prompt added. Enhance this topic by providing a prompt.',
  regenerationHistoryTooltip: 'View notes regeneration history',
  searchTopicPlaceholder: 'Search for a topic',
  createNewTopic: '+ Create New Topic',
  noCategoriesFound: 'No Categories found matching search criteria',
  outdatedTemplate:
    'This template has been updated. Regenerate notes to apply these changes.',
  regenerateNotes: 'Regenerate notes',
  regeneratingNotes: 'Regenerating notes...'
});
