import { CopilotActions } from 'actions/actionTypes';

import { MESSAGE_STATUS_OPTIONS } from 'constants/copilot';

const initialCopilotState = {
  sessions: {
    data: {}
  },
  fetching: false,
  loading: false,
  error: null,
  globalChatOpen: false
};

const copilot = (state = initialCopilotState, action) => {
  switch (action.type) {
    case CopilotActions.FETCH_COPILOT_CHAT_REQUEST: {
      return {
        ...state,
        fetching: true,
        loading: true,
        error: null
      };
    }
    case CopilotActions.FETCH_COPILOT_CHAT_SUCCESS: {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          data: {
            ...state.sessions.data,
            [action.response.uuid]: action.response
          }
        },
        fetching: false,
        loading: false,
        error: null
      };
    }
    case CopilotActions.CREATE_COPILOT_CHAT_REQUEST: {
      return {
        ...state,
        fetching: false,
        loading: true,
        error: null
      };
    }
    case CopilotActions.CREATE_COPILOT_CHAT_SUCCESS: {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          data: {
            ...state.sessions.data,
            [action.response.uuid]: {
              ...action.response,
              contextType: action.contextType,
              contextUuid: action.contextUuid
            }
          }
        },
        fetching: false,
        loading: false,
        error: null
      };
    }
    case CopilotActions.FETCH_COPILOT_CHAT_FAILURE:
    case CopilotActions.CREATE_COPILOT_CHAT_FAILURE: {
      return {
        ...state,
        fetching: false,
        loading: false,
        error: action.error
      };
    }
    case CopilotActions.RECEIVE_COPILOT_MESSAGE: {
      const { chatUuid, uuid } = action.data || {};

      const existingSession = state.sessions.data[chatUuid] || {};
      const messages = existingSession.messages || [];

      const messageIndex = messages.findIndex(
        message => message && message.uuid === uuid
      );

      const isStreamingMessage =
        action.data.status === MESSAGE_STATUS_OPTIONS.IN_PROGRESS;

      let newMessages;

      if (uuid) {
        if (messageIndex === -1) {
          // If id exists and the message is not found, add it to the end
          newMessages = [...messages, action.data];
        } else {
          // If id exists and message is found, update all properties and append message content
          newMessages = [
            ...messages.slice(0, messageIndex),
            {
              ...action.data,
              message:
                (messages[messageIndex].message || '') +
                (action.data.message || '')
            },
            ...messages.slice(messageIndex + 1)
          ];
        }
      } else {
        // If id does not exist, just add the message to the end
        newMessages = [...messages, action.data];
      }

      return {
        ...state,
        sessions: {
          ...state.sessions,
          data: {
            ...state.sessions.data,
            [chatUuid]: {
              ...existingSession, // Preserve other session properties
              messages: newMessages
            }
          }
        },
        loading: isStreamingMessage
      };
    }
    case CopilotActions.MESSAGE_TIMEOUT: {
      return {
        ...state,
        fetching: false,
        loading: false
      };
    }
    case CopilotActions.SET_GLOBAL_COPILOT_CHAT_OPEN: {
      return {
        ...state,
        globalChatOpen: true
      };
    }
    case CopilotActions.SET_GLOBAL_COPILOT_CHAT_CLOSED: {
      return {
        ...state,
        globalChatOpen: false
      };
    }
    default:
      return state;
  }
};

export default copilot;
