export const timeValues = [
  '12AM',
  '1AM',
  '2AM',
  '3AM',
  '4AM',
  '5AM',
  '6AM',
  '7AM',
  '8AM',
  '9AM',
  '10AM',
  '11AM',
  '12PM',
  '1PM',
  '2PM',
  '3PM',
  '4PM',
  '5PM',
  '6PM',
  '7PM',
  '8PM',
  '9PM',
  '10PM',
  '11PM'
];

export const calendarViewUserColors = [
  {
    background: '#E9E1D8',
    foreground: '#98745A'
  },
  {
    background: '#FFEBD5',
    foreground: '#F96616'
  },
  {
    background: '#FFDDDE',
    foreground: '#FF262A'
  },
  {
    background: '#FFF2D3',
    foreground: '#FFA732'
  },
  {
    background: '#FAFFC1',
    foreground: '#E8D300'
  },
  {
    background: '#EFFFC7',
    foreground: '#74BD00'
  },
  {
    background: '#E7FDF2',
    foreground: '#1ACB72'
  },
  {
    background: '#EFFEFD',
    foreground: '#17BEBB'
  },
  {
    background: '#E0F4FE',
    foreground: '#00A1E0'
  },
  {
    background: '#D3E8FF',
    foreground: '#2852FF'
  },
  {
    background: '#EDEAFD',
    foreground: '#885FF2'
  },
  {
    background: '#F6E9FE',
    foreground: '#C14FE6'
  },
  {
    background: '#FEE5F0',
    foreground: '#F83C85'
  }
];

export const calendarViewCurrentUserColor = {
  background: '#e6f1fb',
  foreground: '#0070d2'
};

export const CALENDAR_VIEW_VALUES = {
  DAILY: 'daily',
  WEEKLY: 'week',
  FIVE_DAY: '5 day week',
  SIX_DAY: '6 day week',
  SEVEN_DAY: '7 day week'
};

export const CALENDAR_VIEW_TYPES = [
  {
    label: 'Daily',
    value: CALENDAR_VIEW_VALUES.DAILY
  },
  {
    label: 'Weekly',
    value: CALENDAR_VIEW_VALUES.WEEKLY,
    subMenuItems: {
      label: 'Select preference',
      items: [
        {
          label: '5-day work week (Mon-Fri)',
          value: CALENDAR_VIEW_VALUES.FIVE_DAY
        },
        {
          label: '6-day work week (Mon-Sat)',
          value: CALENDAR_VIEW_VALUES.SIX_DAY
        },
        {
          label: 'All days (Sun-Sat)',
          value: CALENDAR_VIEW_VALUES.SEVEN_DAY
        }
      ]
    }
  }
];
