import { filterActions } from 'actions/actionTypes';

export const initialState = {
  data: null,
  loading: false,
  error: null,
  showSavedViewForm: false,
  savedViewFormType: '',
  showSavedViewsFormTypes: false,
  allMeetings: {
    totalUserFilters: 0,
    privateFilters: {
      data: null,
      loading: false,
      error: null
    },
    sharedFilters: {
      data: null,
      loading: false,
      error: null
    },
    deleteFilter: {
      loading: false,
      error: null
    },
    updateFilter: {
      loading: false,
      error: null
    },
    selectedFilter: null,
    selectedFilterDelete: null,
    appliedFilter: null
  },
  calls: {
    totalUserFilters: 0,
    privateFilters: {
      data: null,
      loading: false,
      error: null
    },
    sharedFilters: {
      data: null,
      loading: false,
      error: null
    },
    deleteFilter: {
      loading: false,
      error: null
    },
    updateFilter: {
      loading: false,
      error: null
    },
    selectedFilter: null,
    selectedFilterDelete: null,
    appliedFilter: null
  },
  deals: {
    totalUserFilters: 0,
    privateFilters: {
      data: null,
      loading: false,
      error: null
    },
    sharedFilters: {
      data: null,
      loading: false,
      error: null
    },
    deleteFilter: {
      loading: false,
      error: null
    },
    updateFilter: {
      loading: false,
      error: null
    },
    selectedFilter: null,
    selectedFilterDelete: null,
    appliedFilter: null
  },
  company: {
    totalUserFilters: 0,
    privateFilters: {
      data: null,
      loading: false,
      error: null
    },
    sharedFilters: {
      data: null,
      loading: false,
      error: null
    },
    deleteFilter: {
      loading: false,
      error: null
    },
    updateFilter: {
      loading: false,
      error: null
    },
    selectedFilter: null,
    selectedFilterDelete: null,
    appliedFilter: null
  },
  trackers: {
    totalUserFilters: 0,
    privateFilters: {
      data: null,
      loading: false,
      error: null
    },
    sharedFilters: {
      data: null,
      loading: false,
      error: null
    },
    deleteFilter: {
      loading: false,
      error: null
    },
    updateFilter: {
      loading: false,
      error: null
    },
    selectedFilter: null,
    selectedFilterDelete: null,
    appliedFilter: null
  },
  scorecards: {
    totalUserFilters: 0,
    privateFilters: {
      data: null,
      loading: false,
      error: null
    },
    sharedFilters: {
      data: null,
      loading: false,
      error: null
    },
    deleteFilter: {
      loading: false,
      error: null
    },
    updateFilter: {
      loading: false,
      error: null
    },
    selectedFilter: null,
    selectedFilterDelete: null,
    appliedFilter: null
  },
  showSaveFilterForm: false,
  showSaveAsNewFilterForm: false,
  subscribe: {
    loading: false,
    error: null,
    data: null
  },
  updateSubscription: {
    loading: false,
    error: null,
    data: null
  },
  unsubscribe: {
    loading: false,
    error: null,
    data: null
  }
};

const filter = (state = initialState, { type, data, error, view }) => {
  switch (type) {
    case filterActions.SET_SELECTED_FILTER_DELETE:
      return {
        ...state,
        [view]: {
          ...state[view],
          selectedFilterDelete: data
        }
      };

    case filterActions.RESET_SELECTED_FILTER_DELETE:
      return {
        ...state,
        [view]: {
          ...state[view],
          selectedFilterDelete: null
        }
      };

    case filterActions.SET_APPLIED_FILTER:
      return {
        ...state,
        [view]: {
          ...state[view],
          appliedFilter: data
        }
      };

    case filterActions.RESET_APPLIED_FILTER:
      return {
        ...state,
        [view]: {
          ...state[view],
          appliedFilter: null
        }
      };

    case filterActions.SET_SAVEDVIEWS_FORM_TYPES_VISIBILITY:
      return {
        ...state,
        showSavedViewsFormTypes: data
      };

    case filterActions.SET_SAVEDVIEWS_FORM_TYPE:
      return {
        ...state,
        savedViewFormType: data
      };

    case filterActions.SET_SAVEDVIEWS_FORM_VISIBILITY:
      return {
        ...state,
        showSavedViewForm: data
      };
    default:
      return state;
  }
};

export default filter;
