import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as DownArrowIcon } from 'images/downArrow.svg';
import { ReactComponent as CheckGreenCircle } from 'images/ic_check_green_circle.svg';
import { ReactComponent as ChevronIcon } from 'images/ic_chevron_right.svg';
import { ReactComponent as CrossIconIcon } from 'images/ic_cross.svg';

import * as Select from '@radix-ui/react-select';
import AvomaTooltip from './AvomaTooltip';

/*
items: TItem[];

type TItem = {
  label: string;
  value: string;
  disabled?: boolean;
};
*/

const AvomaSelect = ({
  placeholder = 'Select',
  label,
  items,
  value,
  controlled,
  open,
  setOpen,
  onValueChange,
  closeCleanupHandler,
  contentProps,
  triggerProps,
  triggerLabel,
  triggerClassName = '',
  contentClassName = '',
  selectItemClassName = '',
  _key, // To reset the select since changing it to undefined doesn't reset it https://github.com/radix-ui/primitives/issues/1569
  portalContainer, // useful for when the select is to be added over another portal like drawer/modal. Pass the parent ref here
  contentPosition = 'popper', // item-aligned works like default macOS select, use 'popper' to make it like a dropdown,
  disabled = false,
  disabledTooltip,
  labelComponent, // Useful for adding help component hovers
  selectItemContainerClassName = '',
  showClearCta = false,
  additionalAction // Useful for adding additional actions inside trigger (e.g. delete item where select is used)
}) => {
  const [internalPopoverState, setInternalPopoverState] = useState(false);
  const popoverState = controlled ? open : internalPopoverState;
  const setPopoverState = controlled ? setOpen : setInternalPopoverState;

  const handleClearCta = event => {
    event.stopPropagation();
    onValueChange(undefined);
    setPopoverState(false);
  };

  return (
    <Select.Root
      open={popoverState}
      value={value}
      onValueChange={onValueChange}
      onOpenChange={() => {
        if (popoverState && typeof closeCleanupHandler === 'function') {
          closeCleanupHandler();
        }
        setPopoverState(!popoverState);
      }}
      key={_key}
    >
      <Select.Trigger
        aria-label={label}
        className={twMerge(
          'relative flex w-[200px] cursor-pointer items-center justify-between rounded border border-gainsboro bg-snow py-2 px-3 text-sm text-gunmetal data-[disabled]:pointer-events-none data-[placeholder]:text-silver data-[disabled]:opacity-40',
          triggerClassName
        )}
        disabled={disabled}
        {...triggerProps}
      >
        <Select.Value placeholder={placeholder}>{triggerLabel}</Select.Value>
        {showClearCta && value ? (
          <div className='flex'>
            <div className='pointer-events-auto mt-0.5'>
              <Select.Icon>
                <CrossIconIcon
                  className='filter-silverDark ml-3 h-3 w-3'
                  onClick={handleClearCta}
                />
              </Select.Icon>
            </div>

            <Select.Icon>
              <DownArrowIcon className='ml-3 flex-shrink-0 h-2 w-2' />
            </Select.Icon>
          </div>
        ) : (
          <div className='right-2 flex items-center space-x-1'>
            <div className='pointer-events-auto'>{additionalAction}</div>
            <Select.Icon>
              <DownArrowIcon className='h-2 w-2 ml-3 flex-shrink-0' />
            </Select.Icon>
          </div>
        )}
      </Select.Trigger>
      <Select.Portal container={portalContainer}>
        <Select.Content
          position={contentPosition}
          className={twMerge(
            'z-[999999] mt-1 max-h-[400px] w-[var(--radix-select-trigger-width)] min-w-[250px] overflow-y-auto rounded border border-gainsboro bg-white shadow-lg',
            contentClassName
          )}
          {...contentProps}
        >
          <Select.ScrollUpButton className='flex h-6 cursor-default items-center justify-center bg-white text-silver'>
            <ChevronIcon className='ml-3 h-2 w-4 rotate-[270deg]' />
          </Select.ScrollUpButton>
          <Select.Viewport className='p-1'>
            <Select.Group>
              {label && (
                <Select.Label className='flex items-center p-2 text-sm font-bold text-gunmetal'>
                  {label} {labelComponent}
                </Select.Label>
              )}

              {items?.map(item => (
                <SelectItem
                  key={item.value}
                  value={item.value}
                  disabled={item.disabled}
                  disabledTooltip={item.disabledTooltip}
                  tooltipClasses={item.tooltipClasses}
                  description={item.description}
                  icon={item.icon}
                  selectItemClassName={selectItemClassName}
                  selectItemContainerClassName={selectItemContainerClassName}
                >
                  {item.label}
                </SelectItem>
              ))}
            </Select.Group>
          </Select.Viewport>
          <Select.ScrollDownButton className='flex h-6 cursor-default items-center justify-center bg-white text-silver'>
            <ChevronIcon className='ml-3 h-2 w-2 rotate-90' />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

const SelectItem = forwardRef(({ children, ...props }, forwardedRef) => (
  <Select.Item
    className={twMerge(
      'flex cursor-pointer select-none items-center rounded bg-transparent py-2 px-3 text-sm text-gunmetal data-[disabled]:cursor-not-allowed data-[highlighted]:bg-snow data-[disabled]:opacity-40',
      props.selectItemContainerClassName
    )}
    {...props}
    ref={forwardedRef}
  >
    <AvomaTooltip
      tooltip={props.disabled && props.disabledTooltip}
      className={props.tooltipClasses ?? ''}
    >
      <div className={twMerge('flex  gap-4', props.selectItemClassName)}>
        {props?.icon}
        <div className='flex flex-col'>
          <Select.ItemText>{children}</Select.ItemText>
          <span className='text-silver'>{props?.description}</span>
        </div>
      </div>
    </AvomaTooltip>
    <Select.ItemIndicator className='absolute right-0 inline-flex w-6 items-center'>
      <CheckGreenCircle className='h-4 w-4' />
    </Select.ItemIndicator>
  </Select.Item>
));

AvomaSelect.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      description: PropTypes.string,
      icon: PropTypes.string
    })
  ),
  value: PropTypes.string,
  controlled: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onValueChange: PropTypes.func,
  closeCleanupHandler: PropTypes.func,
  contentProps: PropTypes.object,
  triggerProps: PropTypes.object,
  triggerClassName: PropTypes.string,
  portalContainer: PropTypes.object,
  contentPosition: PropTypes.oneOf(['item-aligned', 'popper']),
  _key: PropTypes.string,
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
  triggerLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  contentClassName: PropTypes.string,
  selectItemClassName: PropTypes.string,
  withDescAndIcon: PropTypes.bool,
  SelectOptionComponent: PropTypes.elementType,
  labelComponent: PropTypes.node,
  selectItemContainerClassName: PropTypes.string,
  showClearCta: PropTypes.bool,
  additionalAction: PropTypes.node
};

SelectItem.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  selectItemClassName: PropTypes.string,
  selectItemContainerClassName: PropTypes.string,
  tooltipClasses: PropTypes.string
};

SelectItem.displayName = 'SelectItem';

export default AvomaSelect;
