import _ from 'lodash';

import Tokens from 'styles/tokens';

class Dahshboard {
  static chartTypes = {
    trends: 'trends',
    leaderboard: 'leaderboard',
    breakdown: 'breakdown',
    popular: 'popular'
  };

  static colors = [
    Tokens.colors.blue,
    Tokens.colors.teal,
    Tokens.colors.purple,
    Tokens.colors.yellowDark,
    Tokens.colors.magenta,
    Tokens.colors.azure,
    Tokens.colors.avomaRed,
    '#785B57',
    Tokens.colors.silver
  ];

  static aggOptions = {
    members: 'members',
    types: 'types',
    outcomes: 'outcomes',
    dealStages: 'dealStages'
  };

  static getTranslationForAggKeys = ({
    members,
    types,
    outcomes,
    dealStages
  }) => {
    const result = {};
    if (members) {
      const userMapping = {};
      members.forEach(user => {
        userMapping[user.email] = `${user.firstName} ${user.lastName}`;
      });
      result.members = userMapping;
    }
    if (types) {
      result.types = _.mapValues(types, 'label');
    }
    if (outcomes) {
      result.outcomes = _.mapValues(outcomes, 'label');
    }
    if (dealStages) {
      result.dealStages = _.mapValues(
        _.keyBy(_.values(dealStages || {}), 'externalId'),
        'masterLabel'
      );
      result.lostWonStages = {};
      _.values(dealStages).forEach(obj => {
        if (obj.isWon) {
          result.lostWonStages[obj.externalId] = 'Won';
        } else if (obj.isClosed) {
          result.lostWonStages[obj.externalId] = 'Lost';
        }
      });
    }
    return result;
  };
}

export default Dahshboard;
