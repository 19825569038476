import { AppActions } from 'actions/actionTypes';

import { ALL_MEETINGS, MY_MEETINGS } from 'helpers/meetingCategory';

import { CALENDAR_VIEW_VALUES } from 'constants/calendarView';

export const initialAppState = {
  navbarExpanded: true,
  sidebarVisible: true,
  noteAndTranscriptLinkedAt: null,
  meetingCategory: MY_MEETINGS,
  meetingUserId: null, // either team member id or account user id
  currentMeetingId: null, // currenly selected meeting id, set from dashboard
  historyStack: [],
  editorHeightChanged: false,
  unrecordedMyMeetings: false,
  internalMyMeetings: false,
  callsOnlyMyMeetings: false,
  meetingsOnlyMyMeetings: false,
  scrollToTranscriptLink: false,
  createHighlight: {
    show: false,
    meetingUuid: null,
    startTime: null,
    endTime: null,
    duration: null,
    comment: null,
    creating: false
  },
  login: {
    redirect: null
  },
  notificationBanner: {
    visible: false,
    dismissTime: null
  },
  buildVersion: {},
  staleApp: false,
  buildFetchError: null,
  skipTimezoneCheck: false,
  secondaryPanelWidth: null,
  layoutId: 'layout-equal',
  isCalendarView: false,
  calendarViewFormat: {
    value: CALENDAR_VIEW_VALUES.SEVEN_DAY,
    options: {
      value: CALENDAR_VIEW_VALUES.SEVEN_DAY
    }
  },
  timelineFilter: 'recent',
  homepage: 1,
  userSettingUuid: null,
  visibleTemporaryBanners: {
    callsMigration: undefined,
    showZoomCalendarWarning: true
  },
  contentSharingPreferences: {
    autoAllowSameDomainAccess: true,
    notifySharerWhenLinkViewed: true,
    shareUntil: null,
    showTranscript: true,
    showNotes: false,
    showSnippets: false,
    showInsights: true,
    showKeywords: false,
    showQuestions: false
  },
  comments: {}
};

/*
  IMPORTANT: this "app" redux key is persisted to localStorage. see index.js
*/
const app = (state = initialAppState, action) => {
  switch (action.type) {
    case 'SET_MEETING_LIST_VISIBILITY':
      return {
        ...state,
        sidebarVisible: action.visible
      };
    case 'SET_CURRENT_MEETING_CATEGORY': {
      const meetingCategory = action.payload.title;
      return {
        ...state,
        meetingCategory,
        sidebarVisible:
          meetingCategory !== MY_MEETINGS &&
          meetingCategory !== ALL_MEETINGS &&
          state.sidebarVisible,
        meetingUserId: action.payload.userId
      };
    }
    case 'SET_CURRENT_MEETING_ID':
      return {
        ...state,
        currentMeetingId: action.payload.meetingId
      };
    case 'UNRECORDED_MY_MEETINGS_FILTER_UPDATED':
      return {
        ...state,
        unrecordedMyMeetings: action.data
      };
    case 'INTERNAL_MY_MEETINGS_FILTER_UPDATED':
      return {
        ...state,
        internalMyMeetings: action.data
      };
    case 'CALLS_ONLY_MY_MEETINGS_FILTER_UPDATED':
      return {
        ...state,
        callsOnlyMyMeetings: action.data
      };
    case 'MEETINGS_ONLY_MY_MEETINGS_FILTER_UPDATED':
      return {
        ...state,
        meetingsOnlyMyMeetings: action.data
      };
    case 'SET_NOTE_AND_TRANSCRIPT_LINKED':
      return {
        ...state,
        noteAndTranscriptLinkedAt: action.time,
        noteAndTranscriptLinkedUuid: action.uuid
      };
    case 'CLEAR_NOTE_AND_TRANSCRIPT_LINKED':
      return {
        ...state,
        noteAndTranscriptLinkedAt: null,
        noteAndTranscriptLinkedUuid: null
      };
    case 'UPDATE_HISTORY': {
      const { historyStack } = state;
      // Clone historyStack to avoid mutating state.
      let historyState = historyStack.slice();

      if (action.action === 'PUSH') {
        historyState.push(action.location.pathname);
      } else if (action.action === 'POP') {
        // Simply historyState.pop doesn't work because history.go(-4) is also called with 'POP'.
        const index = historyState
          .slice()
          .reverse()
          .findIndex(url => url === action.location.pathname);
        if (index > 0) {
          historyState.splice(-index);
        } else if (index < 0) {
          historyState = [action.location.pathname];
        }
      }
      return {
        ...state,
        historyStack: historyState
      };
    }
    case 'EDITOR_HEIGHT_CHANGED':
      return {
        ...state,
        editorHeightChanged: action.value
      };
    case 'SCROLL_TO_TRANSCRIPT_LINK':
      return {
        ...state,
        scrollToTranscriptLink: action.value
      };
    case AppActions.MOUNT_CREATE_HIGHLIGHT:
      return {
        ...state,
        createHighlight: {
          ...action.params
        }
      };
    case AppActions.UNMOUNT_CREATE_HIGHLIGHT:
      return {
        ...state,
        createHighlight: {}
      };
    case AppActions.SHOW_MULTI_HIGHLIGHT_MODAL:
      return {
        ...state,
        createHighlight: {
          ...action.params,
          showModal: true
        }
      };
    case AppActions.CREATE_HIGHLIGHT_UPDATE_TIME:
      return {
        ...state,
        createHighlight: {
          ...state.createHighlight,
          startTime: action.startTime,
          endTime: action.endTime
        }
      };
    case AppActions.CREATE_HIGHLIGHT_UPDATE_COMMENT:
      return {
        ...state,
        createHighlight: {
          ...state.createHighlight,
          comment: action.comment
        }
      };
    case AppActions.EXPAND_NAVBAR:
      return {
        ...state,
        navbarExpanded: true
      };
    case AppActions.COLLAPSE_NAVBAR:
      return {
        ...state,
        navbarExpanded: false
      };
    case AppActions.SET_ZOOM_OAUTH_CODE:
      return {
        ...state,
        login: {
          ...state.login,
          zoomOauthCode: action.code
        }
      };
    case AppActions.SET_ZOOM_PHONE_OAUTH_CODE:
      return {
        ...state,
        login: {
          ...state.login,
          zoomPhoneOauthCode: action.code
        }
      };
    case AppActions.CLEAR_ZOOM_OAUTH_CODE:
      return {
        ...state,
        login: {
          ...state.login,
          zoomOauthCode: null
        }
      };
    case AppActions.SET_AIRCALL_OAUTH_CODE:
      return {
        ...state,
        login: {
          ...state.login,
          aircallOauthCode: action.code
        }
      };
    case AppActions.CLEAR_AIRCALL_OAUTH_CODE:
      return {
        ...state,
        login: {
          ...state.login,
          aircallOauthCode: null
        }
      };
    case AppActions.SET_CONNECT_LEADER_OAUTH_CODE:
      return {
        ...state,
        login: {
          ...state.login,
          connectLeaderOauthCode: action.code
        }
      };
    case AppActions.SET_GOTOCONNECT_OAUTH_CODE:
      return {
        ...state,
        login: {
          ...state.login,
          connectLeaderOauthCode: action.code
        }
      };

    case AppActions.SET_VONAGE_OAUTH_CODE:
      return {
        ...state,
        login: {
          ...state.login,
          vonageOauthCode: action.code
        }
      };

    case AppActions.CLEAR_VONAGE_OAUTH_CODE:
      return {
        ...state,
        login: {
          ...state.login,
          vonageOauthCode: null
        }
      };

    case AppActions.CLEAR_CONNECT_LEADER_OAUTH_CODE:
      return {
        ...state,
        login: {
          ...state.login,
          connectLeaderOauthCode: null
        }
      };
    case AppActions.SET_SALESLOFT_OAUTH_CODE:
      return {
        ...state,
        login: {
          ...state.login,
          salesloftOauthCode: action.code
        }
      };
    case AppActions.CLEAR_SALESLOFT_OAUTH_CODE:
      return {
        ...state,
        login: {
          ...state.login,
          salesloftOauthCode: null
        }
      };

    case AppActions.SHOW_NOTIFICATION_BANNER:
      return {
        ...state,
        notificationBanner: {
          ...state.notificationBanner,
          visible: true,
          dismissTime: null
        }
      };
    case AppActions.HIDE_NOTIFICATION_BANNER:
      return {
        ...state,
        notificationBanner: {
          ...state.notificationBanner,
          visible: false,
          dismissTime: action.time
        }
      };
    case AppActions.GET_BUILD_VERSION:
      return {
        ...state,
        buildVersion: action.version
      };
    case AppActions.GET_BUILD_VERSION_FAILURE:
      return {
        ...state,
        buildFetchError: action.err
      };
    case AppActions.SET_APP_STALE:
      return {
        ...state,
        staleApp: true
      };
    case AppActions.SET_APP_FRESH:
      return {
        ...state,
        staleApp: false,
        buildVersion: {},
        buildFetchError: null
      };

    case AppActions.SKIP_TIMEZONE_CHECK:
      return {
        ...state,
        skipTimezoneCheck: true
      };
    case AppActions.UPDATE_MEETING_DETAILS_SECONDARY_PANEL_WIDTH:
      return {
        ...state,
        secondaryPanelWidth: action.width
      };
    case AppActions.UPDATE_MEETING_DETAILS_LAYOUT:
      return {
        ...state,
        layoutId: action.layoutId
      };
    case AppActions.TOGGLE_MEETING_CALENDAR_VIEW:
      return {
        ...state,
        isCalendarView: !state.isCalendarView
      };

    case AppActions.UPDATE_CALENDAR_VIEW_FORMAT: {
      if (action.format === CALENDAR_VIEW_VALUES.DAILY) {
        const newDailyCalendarViewFormat = {
          value: action.format,
          options: {
            value:
              state?.calendarViewFormat?.options?.value ||
              CALENDAR_VIEW_VALUES.FIVE_DAY
          }
        };

        return {
          ...state,
          calendarViewFormat: newDailyCalendarViewFormat
        };
      }

      if (action.format === CALENDAR_VIEW_VALUES.WEEKLY) {
        const value =
          state?.calendarViewFormat?.options?.value ||
          CALENDAR_VIEW_VALUES.FIVE_DAY;

        const newWeeklyCalendarViewFormat = {
          value,
          options: {
            value
          }
        };

        return {
          ...state,
          calendarViewFormat: newWeeklyCalendarViewFormat
        };
      }

      const newCalendarViewFormat = {
        value: action.format,
        options: {
          value: action.format
        }
      };

      return {
        ...state,
        calendarViewFormat: newCalendarViewFormat
      };
    }

    case AppActions.UPDATE_MEETING_LIST_TIMELINE_FILTER:
      return {
        ...state,
        timelineFilter: action.value
      };
    case AppActions.FETCH_APP_HOMEPAGE_ROUTE_SUCCESS:
      return {
        ...state,
        homepage: action.response.defaultHomepage,
        userSettingUuid: action.response.uuid
      };
    case AppActions.UPDATE_APP_HOMEPAGE_ROUTE_REQUEST:
      return {
        ...state,
        updatingHomepage: true
      };
    case AppActions.UPDATE_APP_HOMEPAGE_ROUTE_SUCCESS:
      return {
        ...state,
        updatingHomepage: false,
        homepage: action.response.defaultHomepage
      };
    case AppActions.UPDATE_APP_HOMEPAGE_ROUTE_FAILURE:
      return {
        ...state,
        updatingHomepage: false
      };
    case AppActions.DISMISS_TEMPORARY_BANNER:
      return {
        ...state,
        visibleTemporaryBanners: {
          ...state.visibleTemporaryBanners,
          [action.banner]: false
        }
      };
    case AppActions.DISPLAY_TEMPORARY_BANNER:
      return {
        ...state,
        visibleTemporaryBanners: {
          ...state.visibleTemporaryBanners,
          [action.banner]: true
        }
      };
    case AppActions.UPDATE_CONTENT_SHARING_PREFERENCES: {
      return {
        ...state,
        contentSharingPreferences: {
          ...state.contentSharingPreferences,
          ...action.payload
        }
      };
    }

    case AppActions.SAVE_COMMENT_DRAFT: {
      const { meetingUuid, text, parentCommentUuid } = action.payload;
      return {
        ...state,
        comments: {
          ...state.comments,
          [meetingUuid]: {
            ...(state.comments?.[meetingUuid] || {}),
            [parentCommentUuid]: {
              text
            }
          }
        }
      };
    }

    case AppActions.REMOVE_COMMENT_DRAFT: {
      const { meetingUuid, parentCommentUuid } = action.payload;
      const meetingComments = state.comments?.[meetingUuid] || {};

      if (parentCommentUuid && parentCommentUuid in meetingComments) {
        const { [parentCommentUuid]: _c, ...remainingComments } =
          meetingComments;
        return {
          ...state,
          comments: {
            ...state.comments,
            [meetingUuid]: remainingComments
          }
        };
      }

      const { [meetingUuid]: _m, ...newComments } = state.comments;
      return {
        ...state,
        comments: newComments
      };
    }

    default:
      return state;
  }
};

export default app;
