/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { Slate, Editable } from 'slate-react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';

import Element from './Element';
import Leaf from './Leaf';

const styles = {
  paragraph: {
    fontSize: 'inherit',
    position: 'relative'
  },
  editorFrame: {
    outline: 'none'
  },
  checklist: {
    listStyleType: 'none'
  }
};
class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    // Display fallback UI
    console.log(error, info);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node)
};

const EditorFrame = ({
  editor,
  value,
  decorate,
  onChange,
  onFocus,
  style,
  className,
  renderElement,
  readOnly,
  onMouseDown,
  onMouseUp,
  onKeyUp,
  onKeyDownForMention
}) => {
  const renderLeaf = useCallback(props => <Leaf {...props} />, [decorate]);
  const renderElementWrapper = props => {
    const element = renderElement && renderElement(props);
    if (element) {
      return element;
    }
    return <Element {...props} />;
  };
  /*
    note: do not change the order of method invocation in onKeyDown callback
  */

  return (
    <Slate editor={editor} value={value} onChange={onChange}>
      <ErrorBoundary>
        <Editable
          renderElement={renderElementWrapper}
          renderLeaf={renderLeaf}
          onKeyDown={event => {
            editor.onKeyDown(event);
            onKeyDownForMention(event);
          }}
          onFocus={onFocus}
          decorate={decorate}
          style={{ ...styles.editorFrame, ...style }}
          className={className}
          readOnly={readOnly}
          onKeyUp={onKeyUp}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        />
      </ErrorBoundary>
    </Slate>
  );
};

EditorFrame.propTypes = {
  editor: PropTypes.object,
  value: PropTypes.object,
  decorate: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  style: stylePropType,
  className: PropTypes.string,
  renderParagraph: PropTypes.func,
  renderHeader: PropTypes.func,
  renderElement: PropTypes.func,
  readOnly: PropTypes.bool,
  onKeyUp: PropTypes.func,
  onKeyDownForMention: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseDown: PropTypes.func
};

EditorFrame.defaultProps = {
  readOnly: false
};

export default EditorFrame;
