import camelize from 'camelize';

import store from 'helpers/reduxStore';

import Licenses from './Licenses';

export const isAdmin = () => {
  const roleInOrganization = store.getState()?.user?.orgRole?.name;

  return roleInOrganization === 'admin';
};

export const isManager = () => {
  const roleInOrganization = store.getState()?.user?.orgRole?.name;

  return roleInOrganization === 'manager';
};

export const isCurrentUser = email => email === store.getState()?.user?.email;

export const isAvoma = () => {
  const orgDomain = store?.getState()?.members?.organization?.domain;
  return ['avoma.com', 'avoma.app'].includes(orgDomain);
};

export const getDomainQuarterStartMonth = domain =>
  // TODO: Remove hardcoding once backend handles this
  ['orumhq.com', 'rocketlane.com'].includes(domain) ? 1 : 0;

// input= { a: ['b','c']} --> output = "&a=b&a=c"
export const transformMemberPageFilterQueryParams = obj => {
  if (typeof obj !== 'object') {
    return '';
  }
  return Object.entries(obj)
    .map(([key, value]) => {
      if (key === 'sort') {
        const { sortColumn, sortOrder } = value;
        let result = '';
        if (sortOrder === 'ascending') {
          result = `${sortColumn}`;
        } else if (sortOrder === 'descending') {
          result = `-${sortColumn}`;
        }
        return result ? `&o=${result}` : '';
      }
      if (key === 'searchText') {
        return `&search=${value ?? ''}`;
      }
      if (!Array.isArray(value)) {
        return transformMemberPageFilterQueryParams(value);
      }
      if (key === 'subscription') {
        return value
          .map(
            subscriptionName => Licenses.PLANS[subscriptionName.toLowerCase()]
          )
          .flat()
          .map(v => `&${key}=${v}`);
      }
      return value.map(v => `&${key}=${v}`);
    })
    .flat()
    .join('');
};

// type getUserTitle = (user: { firstName?: string, lastName?: string } | { first_name?: string, last_name?: string } | null, fallback: string) => string
export const getUserTitle = (user, fallback = user?.email) => {
  const { firstName = '', lastName = '' } = camelize(user ?? {});
  const fullName = `${firstName} ${lastName}`.trim();
  return fullName.trim() || fallback || '-';
};
