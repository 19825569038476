import PropTypes from 'prop-types';
import React, { useState } from 'react';
import stylePropType from 'react-style-proptype';
import { twMerge } from 'tailwind-merge';

import { useOverflowDetector } from 'hooks/useOverflowDetector';

import { ReactComponent as SuccessIcon } from 'images/ic_available_check.svg';
import { ReactComponent as JustificationIcon } from 'images/ic_avoma_ai.svg';
import { ReactComponent as ErrorIcon } from 'images/ic_error.svg';
import { ReactComponent as InfoIcon } from 'images/ic_info_blue.svg';
import { ReactComponent as LockIcon } from 'images/ic_minimal_lock_purple.svg';
import { ReactComponent as WarningIcon } from 'images/ic_warning.svg';

import Tokens from 'styles/tokens';

import ButtonUnstyled from './ButtonUnstyled';

const ICON_MAP = {
  info: InfoIcon,
  error: ErrorIcon,
  success: SuccessIcon,
  warning: WarningIcon,
  justification: JustificationIcon,
  lock: LockIcon
};

const NotificationBanner = ({
  content,
  type = 'info',
  style,
  className = '',
  align = 'start',
  collapse = false,
  xs = false,
  sm = false,
  additionalAction, // Useful for adding CTAs (e.g. purchase/request license)
  direction = 'row'
}) => {
  const containerStyle = styles[type];
  const iconStyle = styles[`${type}Color`];
  const BannerIcon = ICON_MAP[type];

  const [messageRef, isMessageOverflowing] = useOverflowDetector();

  const [isCollapsed, setIsCollapsed] = useState(true);

  let finalClassName = className;

  if (xs) {
    finalClassName += ' text-xs';
  } else if (sm) {
    finalClassName += ' text-sm';
  }

  return (
    <div
      data-testid='notification-banner'
      role='alert'
      className={twMerge(
        'flex items-center rounded p-2',
        align === 'start' && 'justify-between',
        align === 'center' && 'justify-center',
        direction === 'row' ? 'flex-row' : 'flex-col',
        finalClassName
      )}
      style={{
        ...containerStyle,
        ...(type === 'justification' && { padding: 0 }),
        ...style
      }}
    >
      <div
        className={twMerge(
          'flex items-center w-full',
          align === 'center' && 'w-fit justify-center'
        )}
      >
        {BannerIcon && (
          <div className='h-full self-center flex items-center justify-center'>
            <BannerIcon style={{ ...iconStyle, ...styles.icon }} />
          </div>
        )}
        {collapse ? (
          <div className='flex gap-x-2 items-center'>
            <span
              ref={messageRef}
              className={`${isCollapsed ? 'line-clamp-1' : ''}`}
            >
              {content}
            </span>

            <ButtonUnstyled
              className={twMerge(
                'shrink-0 !font-bold hidden text-[inherit] underline [font:inherit]',
                isMessageOverflowing && isCollapsed && 'flex',
                !isCollapsed && 'flex'
              )}
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              See {isCollapsed ? 'more' : 'less'}
            </ButtonUnstyled>
          </div>
        ) : (
          <div className='flex w-full items-center self-center'>{content}</div>
        )}
      </div>

      <div
        className={twMerge(
          'h-full ml-2 self-center flex w-fit items-center justify-end pointer-events-auto',
          direction === 'column' &&
            'w-full justify-start pl-8 ml-0 mt-2 box-border'
        )}
      >
        {additionalAction}
      </div>
    </div>
  );
};

const styles = {
  error: {
    color: Tokens.colors.redDark,
    backgroundColor: Tokens.colors.redLighter
  },
  warning: {
    color: Tokens.colors.yellowDarker,
    backgroundColor: Tokens.colors.yellowLighter
  },
  info: {
    color: Tokens.colors.blue,
    backgroundColor: Tokens.colors.blueWhiter
  },
  justification: {
    color: Tokens.colors.silver,
    backgroundColor: Tokens.colors.transparent
  },
  success: {
    color: Tokens.colors.greenDark,
    backgroundColor: Tokens.colors.greenLightest
  },
  lock: {
    color: Tokens.colors.purple,
    backgroundColor: Tokens.colors.purpleLighter
  },
  icon: {
    display: 'inline-block',
    position: 'relative',
    minWidth: Tokens.spacing.two,
    width: Tokens.spacing.two,
    height: Tokens.spacing.two,
    margin: '0px 12px 0px 4px'
  },
  errorColor: {
    color: Tokens.colors.red,
    fill: Tokens.colors.red
  },
  warningColor: {
    color: Tokens.colors.yellowDarker,
    fill: Tokens.colors.yellowDarker
  },
  successColor: {
    color: Tokens.colors.green,
    fill: Tokens.colors.green
  },
  justificationColor: {
    color: Tokens.colors.gunmetal,
    fill: Tokens.colors.gunmetal,
    filter: Tokens.filters.silver
  }
};

NotificationBanner.propTypes = {
  content: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  style: stylePropType,
  className: PropTypes.string,
  align: PropTypes.oneOf(['start', 'center']),
  direction: PropTypes.oneOf(['row', 'column']),
  collapse: PropTypes.bool,
  sm: PropTypes.bool,
  xs: PropTypes.bool,
  additionalAction: PropTypes.node
};

export default NotificationBanner;
