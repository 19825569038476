import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import stylesPropType from 'react-style-proptype';
import Tokens from 'styles/tokens';
import { Accordion } from 'react-accessible-accordion';

const AvomaAccordion = ({
  children,
  allowMultipleExpanded,
  allowZeroExpanded,
  style,
  key,
  preExpandedArray,
  ...rest
}) => (
  <Accordion
    style={{ ...styles.accordion, ...style }}
    allowMultipleExpanded={allowMultipleExpanded}
    allowZeroExpanded={allowZeroExpanded}
    key={key}
    preExpanded={preExpandedArray}
    {...rest}
  >
    {children}
  </Accordion>
);

AvomaAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  allowMultipleExpanded: PropTypes.bool,
  allowZeroExpanded: PropTypes.bool,
  style: stylesPropType,
  key: PropTypes.any,
  // Useful when you want a certain uuid to be expanded on accordion mount
  // Used in OnboardingSetup to programmatically open the next accordion item
  // on completion of the current task
  preExpandedArray: PropTypes.array
};

AvomaAccordion.defaultProps = {
  allowMultipleExpanded: false,
  allowZeroExpanded: false,
  preExpandedArray: []
};

const styles = {
  accordion: {
    marginTop: Tokens.spacing.one
  }
};

export default Radium(AvomaAccordion);
