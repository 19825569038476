import camelize from 'camelize';
import _ from 'lodash';

import { MeetingActions, SegmentActions } from 'actions/actionTypes';

import env from 'helpers/env';
import MeetingState from 'helpers/meetingState';

const isActionValid = (state, action) => action?.requestTs >= state?.requestTs;

const initialState = {
  myMeetings: {
    upcoming: {
      meetings: [],
      count: 0,
      fetching: false,
      fetchingNext: false
    },
    past: {
      meetings: [],
      count: 0,
      fetching: false,
      fetchingNext: false
    }
  },
  teamMeetings: {
    past: {
      meetings: [],
      count: 0,
      fetching: false,
      fetchingNext: false
    },
    upcoming: {
      meetings: [],
      count: 0,
      fetching: false,
      fetchingNext: false
    }
  },
  allMeetings: {
    upcoming: {
      meetings: [],
      count: 0,
      fetching: false,
      fetchingNext: false
    },
    past: {
      meetings: [],
      count: 0,
      fetching: false,
      fetchingNext: false
    }
  },
  sharedMeetings: {
    past: {
      meetings: [],
      count: 0,
      fetching: false,
      fetchingNex: false
    }
  },
  data: {}, // fetch meetings
  metadata: {}, // meeting metadata
  refetchNeeded: false,
  searchData: {}, // search results
  fetching: 0, // fetching all meetings
  fetchingAMeeting: 0, // fetching a single meeting ( current )
  sharing: 0,
  searchingInviteeCandidates: 0,
  error: null,
  errorDetail: null,
  lastFetched: '',
  deletingMeeting: false,
  participants: {
    creating: false,
    createError: null,
    deleting: false,
    deleteError: null,
    fetching: false,
    fetchError: null
  },
  requestingDownload: false,
  requestDownloadError: null,
  sharingModal: {
    show: false
  },
  updateMeetingPrivacy: {
    loading: false,
    error: false
  },
  updateMeetingShare: {
    loading: false,
    error: false
  },
  shareMeeting: {
    loading: false,
    error: false
  },
  unshareMeeting: {
    loading: false,
    error: false
  },
  addMeetingToPlaylist: {
    loading: false,
    error: false
  },
  removeMeetingFromPlaylist: {
    loading: false,
    error: false
  },
  createSnippetModalData: {
    show: false
  },
  onlineUsers: null,
  zoomCloudRec: {
    updating: false,
    error: null
  },
  msCloudRec: {
    updating: false,
    error: null
  },
  meetingUpload: {
    url: {
      fetching: false,
      error: null,
      data: {}
    },
    meetings: {
      fetching: false,
      error: null,
      // Mapping between S3Key and Meeting object
      data: {}
    }
  },
  meetingCount: {
    query: '',
    meetingUuids: [],
    fetching: false,
    error: null
  },
  metering: {},
  fetchingCalView: false,
  forceRefetch: false,
  meetingEntityFetching: {},
  globalSearchText: '',
  transcriptTimestampSearch: {
    show: false
  }
};

const deleteMeetingTag = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    [action.meetingUuid]: {
      ...state.data[action.meetingUuid],
      tags: _.reject(state.data[action.meetingUuid].tags, {
        data: {
          uuid: action.uuid
        }
      })
    }
  }
});

const updateMeetingTag = (state, action) => ({
  ...state,
  fetching: false,
  data: {
    ...state.data,
    [action.payload.meeting.uuid]: {
      ...action.payload.meeting,
      tags: camelize(action.payload.tags)
    }
  }
});

const updateBotState = (state, uuid, botState, mState = null) => ({
  ...state,
  data: {
    ...state.data,
    [uuid]: {
      ...state.data[uuid],
      botState,
      ...(mState && { states: mState })
    }
  },
  ...(mState && {
    metadata: {
      ...state.metadata,
      [uuid]: {
        ...state.metadata[uuid],
        states: mState
      }
    }
  })
});

const creatingImpromptuMeeting = (state, meeting) => ({
  ...state,
  data: {
    ...state.data,
    [meeting.uuid]: {
      ...meeting
    }
  }
});

const getMeetingObjectForData = (
  data,
  oldIds = null,
  next = false,
  payload = {}
) => {
  const homeMeetings = camelize(data.results);
  const ids = homeMeetings.map(meeting => meeting.uuid);
  let idList = ids;
  if (oldIds) {
    const newIDs = ids.filter(id => !oldIds.includes(id));
    idList = oldIds.concat(newIDs);
  }
  return {
    [next ? 'fetchingNext' : 'fetching']: false,
    meetings: idList,
    count: data.count,
    next: data.next,
    previous: data.previous,
    lastFetched: new Date(),
    isResultsEmpty: data.results.length === 0,
    payload
  };
};

const updatedMeetingsData = (state, results) => {
  // The v2 apis don't send this value, so we manually need to assign it `null` so that
  // stale values don't remain due to the merge
  const resultsWithHighlightedSearchText = results.map(result => ({
    ...result,
    highlighted_search_text:
      result.highlighted_search_text !== undefined
        ? result.highlighted_search_text
        : null
  }));

  // Only root level values need to be retained in `data`. everything else inside/nested should be
  // replaced with the new values
  return _.mergeWith(
    {},
    state.data,
    _.keyBy(camelize(resultsWithHighlightedSearchText), 'uuid'),
    (objValue, srcValue, key, object, source, stack) => {
      if (stack.size === 0) {
        return undefined; // let _.mergeWith handle the merge at root level
      }
      return srcValue; // replace nested objects
    }
  );
};

const getMeetingsRequestObject = (state, action, next = false) => {
  const result = { ...state };
  const catObj = { ...state[action.category] };
  const previousController = state[action.category]?.[action.time]?.controller;
  if (previousController) {
    previousController.abort();
  }

  const mtg = {
    ...catObj[action.time],
    [next ? 'fetchingNext' : 'fetching']: true,
    error: null,
    requestTs: action.requestTs,
    url: action.url,
    controller: action.controller,
    ...(!next && { meetings: [] }),
    ...(action.isFilterChanged && { meetings: [] }),
    payload: action.payload
  };
  catObj[action.time] = mtg;
  result[action.category] = catObj;
  return result;
};

const getMeetingsResultsObject = (
  state,
  action,
  merge = false,
  next = false
) => {
  const result = {
    ...state,
    data: updatedMeetingsData(state, action.data.results)
  };
  const catObj = { ...state[action.category] };
  if (!isActionValid(catObj[action.time], action) && !action.forceRefetch)
    return state;

  catObj[action.time] = {
    ...catObj[action.time],
    ...getMeetingObjectForData(
      action.data,
      merge && catObj[action.time] ? catObj[action.time].meetings : null,
      next,
      action.payload
    ),
    ...{ message: action.data.message ?? null }
  };
  result[action.category] = catObj;
  return result;
};

const getMeetingsFailureObject = (state, action, next = false) => {
  const result = { ...state };
  const catObj = { ...state[action.category] };
  if (!isActionValid(catObj[action.time], action)) return state;

  catObj[action.time] = {
    ...catObj[action.time],
    [next ? 'fetchingNext' : 'fetching']: false,
    error: action.error,
    payload: action.payload
  };
  result[action.category] = catObj;
  return result;
};

const getSingleMeetingSuccess = (state, action) => ({
  ...state,
  data: updatedMeetingsData(state, [action.meeting]),
  fetchingAMeeting: state.fetchingAMeeting - 1
});

const fetchingAMeeting = (state, action) => {
  switch (action.type) {
    case 'FETCH_MEETINGS_REQUEST':
      return getMeetingsRequestObject(state, action);
    case 'FETCH_MEETINGS_SUCCESS':
      return getMeetingsResultsObject(state, action);
    case 'FETCH_MEETINGS_FAILURE':
      return getMeetingsFailureObject(state, action);
    case 'FETCH_A_MEETING_REQUEST':
      return {
        ...state,
        fetchingAMeeting: state.fetchingAMeeting + 1,
        error: null,
        errorDetail: null
      };
    case 'FETCH_A_MEETING_SUCCESS':
      return getSingleMeetingSuccess(state, action);
    case 'FETCH_A_MEETING_FAILURE': {
      return {
        ...state,
        fetchingAMeeting: state.fetchingAMeeting - 1,
        error: action.error,
        errorDetail: action.errorDetail?.detail
      };
    }
    default:
      return state;
  }
};

const fetchMeetingMeta = (state, action) => {
  switch (action.type) {
    case MeetingActions.FETCH_META_SUCCESS:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          [action.meetingId]: action.metadata
        }
      };
    default:
      return state;
  }
};

const fetchingPage = (state, action) => {
  switch (action.type) {
    case 'FETCH_NEXT_MEETINGS_REQUEST':
      return getMeetingsRequestObject(state, action, true);
    case 'FETCH_NEXT_MEETINGS_SUCCESS':
      return getMeetingsResultsObject(state, action, true, true);
    case 'FETCH_NEXT_MEETINGS_FAILURE':
      return getMeetingsFailureObject(state, action, true);
    default:
      return state;
  }
};

const getCreateNoteObj = (state, action, creating, error) => ({
  ...state,
  data: {
    ...state.data,
    [action.meetingId]: {
      ...state.data[action.meetingId],
      creatingNote: creating,
      errorCreatingNote: error
    }
  }
});

const creatingNote = (state, action) => {
  switch (action.type) {
    case 'CREATE_NOTE_REQUEST':
      return getCreateNoteObj(state, action, true, null);
    case 'CREATE_NOTE_SUCCESS': {
      const result = getCreateNoteObj(state, action, false, null);
      result.data[
        action.note.meeting
      ].note = `${env.avomaApiUrl}/api/v1/notes/${action.note.uuid}/`;
      return result;
    }
    case 'CREATE_NOTE_FAILURE':
      return getCreateNoteObj(state, action, false, action.err);
    default:
      return state;
  }
};

const updateSegment = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    [action.meetingId]: {
      ...state.data[action.meetingId],
      segmentLabels: action.segments
    }
  }
});

const updateMeetingSubject = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    [action.meetingUuid]: {
      ...state.data[action.meetingUuid],
      subject: action.subject
    }
  }
});

const lookupMeetings = (state, action) => {
  switch (action.type) {
    case MeetingActions.LOOKUP_MEETINGS_REQUEST:
      return {
        ...state,
        lookup: true
      };
    case MeetingActions.LOOKUP_MEETINGS_SUCCESS: {
      const meetingsByUuidMap = new Map(action.meetings.map(m => [m.uuid, m]));
      const dataForRequestedUuids = action.requestedUuids.reduce(
        (acc, uuid) => {
          acc[uuid] = meetingsByUuidMap.get(uuid) ?? { uuid };
          return acc;
        },
        {}
      );
      return {
        ...state,
        data: { ...state.data, ...dataForRequestedUuids },
        lookup: false
      };
    }
    case MeetingActions.LOOKUP_MEETINGS_FAILURE:
      return {
        ...state,
        lookup: false,
        error: action.error
      };
    default:
      return state;
  }
};

const removeMeetingFromMeetingField = (meetingsField, meetingUuid) => {
  const result = {};
  if (meetingsField.upcoming) {
    result.upcoming = {
      ...meetingsField.upcoming,
      meetings: _.reject(
        meetingsField.upcoming.meetings,
        id => id === meetingUuid
      )
    };
  }
  if (meetingsField.past) {
    result.past = {
      ...meetingsField.past,
      meetings: _.reject(meetingsField.past.meetings, id => id === meetingUuid)
    };
  }
  return result;
};

const deleteMeeting = (state, action) => {
  switch (action.type) {
    case MeetingActions.DELETE_MEETING_REQUEST:
      return {
        ...state,
        deletingMeeting: true
      };
    case MeetingActions.DELETE_MEETING_SUCCESS: {
      return {
        ...state,
        deletingMeeting: false,
        myMeetings: removeMeetingFromMeetingField(
          state.myMeetings,
          action.meetingUuid
        ),
        allMeetings: removeMeetingFromMeetingField(
          state.allMeetings,
          action.meetingUuid
        ),
        sharedMeetings: removeMeetingFromMeetingField(
          state.sharedMeetings,
          action.meetingUuid
        ),
        teamMeetings: removeMeetingFromMeetingField(
          state.teamMeetings,
          action.meetingUuid
        ),
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            __deleted: true
          }
        }
      };
    }
    case MeetingActions.DELETE_MEETING_FAILURE:
    default:
      return {
        ...state,
        deletingMeeting: false
      };
  }
};

const createParticipants = (state, action) => {
  switch (action.type) {
    case MeetingActions.CREATE_PARTICIPANTS_REQUEST:
      return {
        ...state,
        participants: {
          ...state.participants,
          creating: true
        }
      };
    case MeetingActions.CREATE_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: {
          ...state.participants,
          creating: false
        }
      };
    case MeetingActions.CREATE_PARTICIPANTS_FAILURE:
      return {
        ...state,
        participants: {
          ...state.participants,
          creating: false,
          createError: action.error
        }
      };
    default:
      return state;
  }
};

const deleteParticipant = (state, action) => {
  switch (action.type) {
    case MeetingActions.DELETE_PARTICIPANTS_REQUEST: {
      const currentMeeting = { ...state.data[action.meetingUuid] };
      const filterDeletedMeeting = a => a.uuid !== action.participantUuid;
      currentMeeting.attendees = _.filter(
        currentMeeting.attendees,
        filterDeletedMeeting
      );
      const orgAttendees =
        currentMeeting.attendeesPerOrg &&
        currentMeeting.attendeesPerOrg.usersWOrg;
      Object.keys(orgAttendees || {}).forEach(key => {
        orgAttendees[key] = _.filter(
          Object.values(orgAttendees[key]),
          filterDeletedMeeting
        );
      });
      currentMeeting.attendeesPerOrg.usersWoOrg = _.filter(
        currentMeeting.attendeesPerOrg.usersWoOrg,
        filterDeletedMeeting
      );
      return {
        ...state,
        data: updatedMeetingsData(state, [currentMeeting]),
        participants: {
          ...state.participants,
          deleting: true
        }
      };
    }
    case MeetingActions.DELETE_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: {
          ...state.participants,
          deleting: false
        }
      };
    case MeetingActions.DELETE_PARTICIPANTS_FAILURE:
      return {
        ...state,
        participants: {
          ...state.participants,
          deleting: false,
          deleteError: action.error
        }
      };
    default:
      return state;
  }
};

const fetchParticipants = (state, action) => {
  switch (action.type) {
    case MeetingActions.FETCH_PARTICIPANTS_REQUEST:
      return {
        ...state,
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            attendees: {
              fetching: true,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    case MeetingActions.FETCH_PARTICIPANTS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            attendeesPerOrg: action.response
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            attendees: {
              fetching: false,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_PARTICIPANTS_FAILURE:
      return {
        ...state,
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            attendees: {
              fetching: false,
              error: true,
              requestTs: action.requestTs
            }
          }
        }
      };
    default:
      return state;
  }
};

const downloadMeeting = (state, action) => {
  switch (action.type) {
    case MeetingActions.DOWNLOAD_MEETING_REQUEST:
      return {
        ...state,
        requestingDownload: true
      };
    case MeetingActions.DOWNLOAD_MEETING_SUCCESS:
      return {
        ...state,
        requestingDownload: false
      };
    case MeetingActions.DOWNLOAD_MEETING_FAILURE:
      return {
        ...state,
        requestingDownload: false,
        requestDownloadError: action.error
      };
    default:
      return state;
  }
};

const enableZmCloudRec = (state, action) => {
  switch (action.type) {
    case MeetingActions.ENABLE_ZOOM_CLOUD_REC_REQUEST:
      return {
        ...state,
        zoomCloudRec: {
          ...state.zoomCloudRec,
          updating: true
        }
      };
    case MeetingActions.ENABLE_ZOOM_CLOUD_REC_SUCCESS:
      return {
        ...state,
        data: updatedMeetingsData(state, [action.meeting]),
        zoomCloudRec: {
          ...state.zoomCloudRec,
          updating: false
        }
      };
    case MeetingActions.ENABLE_ZOOM_CLOUD_REC_ERROR:
      return {
        ...state,
        zoomCloudRec: {
          ...state.zoomCloudRec,
          updating: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};

const disableZmCloudRec = (state, action) => {
  switch (action.type) {
    case MeetingActions.DISABLE_ZOOM_CLOUD_REC_REQUEST:
      return {
        ...state,
        zoomCloudRec: {
          ...state.zoomCloudRec,
          updating: true
        }
      };
    case MeetingActions.DISABLE_ZOOM_CLOUD_REC_SUCCESS:
      return {
        ...state,
        data: updatedMeetingsData(state, [action.meeting]),
        zoomCloudRec: {
          ...state.zoomCloudRec,
          updating: false
        }
      };
    case MeetingActions.DISABLE_ZOOM_CLOUD_REC_ERROR:
      return {
        ...state,
        zoomCloudRec: {
          ...state.zoomCloudRec,
          updating: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};

const enableMsCloudRec = (state, action) => {
  switch (action.type) {
    case MeetingActions.ENABLE_MS_CLOUD_REC_REQUEST:
      return {
        ...state,
        msCloudRec: {
          ...state.msCloudRec,
          updating: true
        }
      };
    case MeetingActions.ENABLE_MS_CLOUD_REC_SUCCESS:
      return {
        ...state,
        data: updatedMeetingsData(state, [action.meeting]),
        msCloudRec: {
          ...state.msCloudRec,
          updating: false
        }
      };
    case MeetingActions.ENABLE_MS_CLOUD_REC_ERROR:
      return {
        ...state,
        msCloudRec: {
          ...state.msCloudRec,
          updating: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};

const disableMsCloudRec = (state, action) => {
  switch (action.type) {
    case MeetingActions.DISABLE_MS_CLOUD_REC_REQUEST:
      return {
        ...state,
        msCloudRec: {
          ...state.msCloudRec,
          updating: true
        }
      };
    case MeetingActions.DISABLE_MS_CLOUD_REC_SUCCESS:
      return {
        ...state,
        data: updatedMeetingsData(state, [action.meeting]),
        msCloudRec: {
          ...state.msCloudRec,
          updating: false
        }
      };
    case MeetingActions.DISABLE_MS_CLOUD_REC_ERROR:
      return {
        ...state,
        msCloudRec: {
          ...state.msCloudRec,
          updating: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};

const meetings = (state = initialState, action) => {
  const droppingMState = {
    meeting: { key: MeetingState.botRecStopping, value: 'Dropping' }
  };

  switch (action.type) {
    case MeetingActions.GLOBAL_SEARCH_FILTERS:
      return {
        ...state,
        globalSearchFilters: {
          ...(state.globalSearchFilters || {}),
          ...action.data
        }
      };
    case MeetingActions.SUGGESTIVE_ACTION_SEARCH:
      return {
        ...state,
        suggestiveActionSearch: action.data
      };
    case MeetingActions.UPDATE_CALENDAR_DATE:
      return {
        ...state,
        calendarDate: action.data
      };
    case MeetingActions.UPDATE_CALENDAR_WEEK_DATE:
      return {
        ...state,
        calendarWeekDate: action.data
      };
    case 'FETCH_MEETINGS_REQUEST':
    case 'FETCH_MEETINGS_SUCCESS':
    case 'FETCH_MEETINGS_FAILURE':
    case 'FETCH_A_MEETING_REQUEST':
    case 'FETCH_A_MEETING_SUCCESS':
    case 'FETCH_A_MEETING_FAILURE':
      return fetchingAMeeting(state, action);
    case 'FETCH_NEXT_MEETINGS_REQUEST':
    case 'FETCH_NEXT_MEETINGS_SUCCESS':
    case 'FETCH_NEXT_MEETINGS_FAILURE':
      return fetchingPage(state, action);
    case MeetingActions.LOOKUP_MEETINGS_REQUEST:
    case MeetingActions.LOOKUP_MEETINGS_SUCCESS:
    case MeetingActions.LOOKUP_MEETINGS_FAILURE:
      return lookupMeetings(state, action);
    case MeetingActions.FETCH_META_REQUEST:
    case MeetingActions.FETCH_META_SUCCESS:
    case MeetingActions.FETCH_META_FAILURE:
      return fetchMeetingMeta(state, action);
    case 'SET_BOT_STATE_SUCCESS':
      return updateBotState(state, action.uuid, action.conference.state);
    case 'CREATE_NOTE_SUCCESS':
    case 'CREATE_NOTE_REQUEST':
    case 'CREATE_NOTE_FAILURE':
      return creatingNote(state, action);
    case 'LOGOUT':
      return initialState;
    case 'MEETING_TAG_UPDATED':
      return updateMeetingTag(state, action);
    case 'MEETING_TAG_DELETED':
      return deleteMeetingTag(state, action);
    case 'MEETING_DIALING_IN':
      return updateBotState(state, action.uuid, 'dialing');
    case 'MEETING_BOT_DROPPING':
      return updateBotState(state, action.uuid, 'dropping', droppingMState);
    case 'MEETING_DIALED_IN':
    case 'MEETING_BOT_DROPPED':
    case 'MEETING_BOT_PAUSED':
    case 'MEETING_BOT_RESUMED':
      return updateBotState(
        state,
        action.uuid,
        action.botState,
        action.meetingStates
      );
    case MeetingActions.IMPROMPTU_MEETING_SUCCESS:
      return creatingImpromptuMeeting(state, action.meeting);
    case SegmentActions.update.request:
    case SegmentActions.update.success:
    case SegmentActions.update.failure:
      return updateSegment(state, action);
    case MeetingActions.MEETING_SUBJECT_UPDATED:
      return updateMeetingSubject(state, action);
    case MeetingActions.DELETE_MEETING_REQUEST:
    case MeetingActions.DELETE_MEETING_SUCCESS:
    case MeetingActions.DELETE_MEETING_FAILURE:
      return deleteMeeting(state, action);
    case MeetingActions.CREATE_PARTICIPANTS_REQUEST:
    case MeetingActions.CREATE_PARTICIPANTS_SUCCESS:
    case MeetingActions.CREATE_PARTICIPANTS_FAILURE:
      return createParticipants(state, action);
    case MeetingActions.DELETE_PARTICIPANTS_REQUEST:
    case MeetingActions.DELETE_PARTICIPANTS_SUCCESS:
    case MeetingActions.DELETE_PARTICIPANTS_FAILURE:
      return deleteParticipant(state, action);
    case MeetingActions.FETCH_PARTICIPANTS_REQUEST:
    case MeetingActions.FETCH_PARTICIPANTS_SUCCESS:
    case MeetingActions.FETCH_PARTICIPANTS_FAILURE:
      return fetchParticipants(state, action);
    case MeetingActions.DOWNLOAD_MEETING_REQUEST:
    case MeetingActions.DOWNLOAD_MEETING_SUCCESS:
    case MeetingActions.DOWNLOAD_MEETING_FAILURE:
      return downloadMeeting(state, action);
    case MeetingActions.ENABLE_ZOOM_CLOUD_REC_REQUEST:
    case MeetingActions.ENABLE_ZOOM_CLOUD_REC_SUCCESS:
    case MeetingActions.ENABLE_ZOOM_CLOUD_REC_ERROR:
      return enableZmCloudRec(state, action);
    case MeetingActions.DISABLE_ZOOM_CLOUD_REC_REQUEST:
    case MeetingActions.DISABLE_ZOOM_CLOUD_REC_SUCCESS:
    case MeetingActions.DISABLE_ZOOM_CLOUD_REC_ERROR:
      return disableZmCloudRec(state, action);
    case MeetingActions.ENABLE_MS_CLOUD_REC_REQUEST:
    case MeetingActions.ENABLE_MS_CLOUD_REC_SUCCESS:
    case MeetingActions.ENABLE_MS_CLOUD_REC_ERROR:
      return enableMsCloudRec(state, action);
    case MeetingActions.DISABLE_MS_CLOUD_REC_REQUEST:
    case MeetingActions.DISABLE_MS_CLOUD_REC_SUCCESS:
    case MeetingActions.DISABLE_MS_CLOUD_REC_ERROR:
      return disableMsCloudRec(state, action);
    case MeetingActions.SET_REFETCH_NEEDED:
      return {
        ...state,
        refetchNeeded: action.refetchNeeded
      };
    case MeetingActions.UPDATE_LOCAL_MEETINGS:
      return {
        ...state,
        data: {
          ...state.data,
          ..._.keyBy(
            Object.values(state.data)
              .filter(action.query)
              .map(m => ({ ...m, ...action.value })),
            'uuid'
          )
        }
      };

    case MeetingActions.SHOW_SHARING_MODAL: {
      return {
        ...state,
        sharingModal: {
          ...action.response,
          show: true
        }
      };
    }
    case MeetingActions.HIDE_SHARING_MODAL:
      return {
        ...state,
        sharingModal: {
          show: false
        },
        shareMeeting: {
          loading: false,
          error: false
        }
      };

    case MeetingActions.SHOW_CREATE_SNIPPET_MODAL: {
      return {
        ...state,
        createSnippetModalData: {
          ...state.createSnippetModalData,
          ...action.response,
          show: true
        }
      };
    }

    case MeetingActions.HIDE_CREATE_SNIPPET_MODAL: {
      return {
        ...state,
        createSnippetModalData: {
          show: false
        }
      };
    }

    case MeetingActions.UPDATE_MEETING_PRIVACY_REQUEST: {
      return {
        ...state,
        updateMeetingPrivacy: {
          loading: true
        }
      };
    }

    case MeetingActions.UPDATE_MEETING_PRIVACY_SUCCESS: {
      const { meetingUuid, response } = action;
      return {
        ...state,
        updateMeetingPrivacy: {
          loading: false
        },
        data: {
          ...state.data,
          [meetingUuid]: {
            ...state.data[meetingUuid],
            ...response
          }
        }
      };
    }

    case MeetingActions.UPDATE_MEETING_PRIVACY_ERROR: {
      return {
        ...state,
        updateMeetingPrivacy: {
          loading: false,
          error: true
        }
      };
    }

    case MeetingActions.UPDATE_MEETING_SHARE_REQUEST: {
      return {
        ...state,
        updateMeetingShare: {
          loading: true
        }
      };
    }

    case MeetingActions.UPDATE_MEETING_SHARE_SUCCESS: {
      const { meetingUuid, response } = action;
      return {
        ...state,
        updateMeetingShare: {
          loading: false
        },
        data: {
          ...state.data,
          [meetingUuid]: {
            ...state.data[meetingUuid],
            share: response
          }
        }
      };
    }

    case MeetingActions.UPDATE_MEETING_SHARE_ERROR: {
      return {
        ...state,
        updateMeetingShare: {
          loading: false,
          error: true
        }
      };
    }

    case MeetingActions.SHARE_MEETING_REQUEST: {
      return {
        ...state,
        shareMeeting: {
          loading: true
        }
      };
    }

    case MeetingActions.SHARE_MEETING_SUCCESS: {
      const { meetingUuid, share } = action;
      return {
        ...state,
        shareMeeting: {
          loading: false
        },
        data: {
          ...state.data,
          [meetingUuid]: {
            ...state.data[meetingUuid],
            share
          }
        }
      };
    }
    case MeetingActions.SHARE_MEETING_LOCAL: {
      const { meetingUuid, sharedUsers, sharedTeams } = action;
      return {
        ...state,
        shareMeeting: {
          loading: false
        },
        data: {
          ...state.data,
          [meetingUuid]: {
            ...state.data[meetingUuid],
            share: {
              ...state.data[meetingUuid].share,
              emails: [
                ...(state.data[meetingUuid].share?.emails || []),
                ...sharedUsers
              ],
              teams: [
                ...(state.data[meetingUuid].share?.teams || []),
                ...sharedTeams
              ]
            }
          }
        }
      };
    }

    case MeetingActions.SHARE_MEETING_ERROR: {
      return {
        ...state,
        shareMeeting: {
          loading: false,
          error: true
        }
      };
    }

    case MeetingActions.UNSHARE_MEETING_REQUEST:
      return {
        ...state,
        unshareMeeting: {
          loading: true
        }
      };

    case MeetingActions.UNSHARE_MEETING_SUCCESS: {
      const { meetingUuid, share } = action;
      return {
        ...state,
        data: {
          ...state.data,
          [meetingUuid]: {
            ...state.data[meetingUuid],
            share
          }
        },
        unshareMeeting: {
          loading: false
        }
      };
    }
    case MeetingActions.UNSHARE_MEETING_ERROR:
      return {
        ...state,
        unshareMeeting: {
          loading: false,
          error: true
        }
      };

    case MeetingActions.ADD_PLAYLIST_TO_MEETING_REQUEST:
      return {
        ...state,
        addMeetingToPlaylist: {
          loading: true
        }
      };

    case MeetingActions.ADD_PLAYLIST_TO_MEETING_SUCCESS: {
      const { meetingUuid, playlist, playlistContentUuid } = action;

      if (!playlist) {
        return state;
      }

      const playlistObj = {
        uuid: playlist.uuid,
        name: playlist.name,
        privacy: playlist.privacy,
        playlistContentUuid
      };

      return {
        ...state,
        data: {
          ...state.data,
          [meetingUuid]: {
            ...state.data[meetingUuid],
            playlists: [
              ...(state.data[meetingUuid]?.playlists || []),
              playlistObj
            ]
          }
        }
      };
    }

    case MeetingActions.ADD_PLAYLIST_TO_MEETING_FAILURE:
      return {
        ...state,
        addMeetingToPlaylist: {
          loading: false,
          error: true
        }
      };

    case MeetingActions.REMOVE_PLAYLIST_FROM_MEETING_REQUEST:
      return {
        ...state,
        removeMeetingFromPlaylist: {
          loading: true
        }
      };

    case MeetingActions.REMOVE_PLAYLIST_FROM_MEETING_SUCCESS: {
      const { playlistUuid, meetingUuid } = action;

      const meetingPlaylists = [...state.data[meetingUuid].playlists];
      const updatedPlaylists = meetingPlaylists.filter(
        playlist => playlist.uuid !== playlistUuid
      );

      return {
        ...state,
        data: {
          ...state.data,
          [meetingUuid]: {
            ...state.data[meetingUuid],
            playlists: updatedPlaylists
          }
        }
      };
    }

    case MeetingActions.REMOVE_PLAYLIST_FROM_MEETING_FAILURE:
      return {
        ...state,
        removeMeetingFromPlaylist: {
          loading: false,
          error: true
        }
      };

    case MeetingActions.SET_MEETING: {
      const meetingUuid = action.meeting.uuid;
      if (state.data[meetingUuid]) return state;

      return {
        ...state,
        data: {
          ...state.data,
          [meetingUuid]: action.meeting
        }
      };
    }

    case MeetingActions.FETCH_MEETING_ASSOCIATED_TEMPLATE_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            associatedTemplate: {
              loading: true,
              error: false
            }
          }
        }
      };

    case MeetingActions.FETCH_MEETING_ASSOCIATED_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            associatedTemplate: {
              loading: false,
              error: false,
              ...action.associatedTemplate
            }
          }
        }
      };

    case MeetingActions.FETCH_MEETING_ASSOCIATED_TEMPLATE_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            associatedTemplate: {
              loading: false,
              error: true
            }
          }
        }
      };
    case MeetingActions.SET_ONLINE_USERS: {
      const { meetingUuid, onlineUsers } = action;

      return {
        ...state,
        onlineUsers: {
          ...state.onlineUsers,
          [meetingUuid]: onlineUsers
        }
      };
    }
    case MeetingActions.FETCH_MEETING_UPLOAD_URL_REQUEST: {
      return {
        ...state,
        meetingUpload: {
          ...state.meetingUpload,
          url: {
            fetching: true
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_UPLOAD_URL_SUCCESS: {
      const { response } = action;
      return {
        ...state,
        meetingUpload: {
          ...state.meetingUpload,
          url: {
            fetching: false,
            data: {
              url: response.upload_url,
              fieldList: response.field_list,
              filename: response.filename,
              canUpload: response.can_upload
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_UPLOAD_URL_FAILURE: {
      return {
        ...state,
        meetingUpload: {
          ...state.meetingUpload,
          url: {
            error: action.error,
            fetching: false
          }
        }
      };
    }
    case MeetingActions.UPLOAD_MEETING_REQUEST: {
      return {
        ...state,
        meetingUpload: {
          ...state.meetingUpload,
          meetings: {
            ...state.meetingUpload.meetings,
            fetching: true
          }
        }
      };
    }
    case MeetingActions.UPLOAD_MEETING_SUCCESS: {
      const response = camelize(action.response);
      return {
        ...state,
        meetingUpload: {
          ...state.meetingUpload,
          meetings: {
            ...state.meetingUpload.meetings,
            fetching: false,
            data: {
              ...state.meetingUpload.meetings.data,
              [response.s3Key]: response.meeting
            }
          }
        }
      };
    }
    case MeetingActions.UPLOAD_MEETING_FAILURE: {
      return {
        ...state,
        meetingUpload: {
          ...state.meetingUpload,
          meetings: {
            ...state.meetingUpload.meetings,
            error: action.error,
            fetching: false
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_COUNT_REQUEST: {
      return {
        ...state,
        meetingCount: {
          ...state.meetingCount,
          query: action.query,
          fetching: true
        }
      };
    }
    case MeetingActions.FETCH_MEETING_COUNT_SUCCESS: {
      return {
        ...state,
        meetingCount: {
          ...state.meetingCount,
          fetching: false,
          ...action.response
        }
      };
    }
    case MeetingActions.FETCH_MEETING_COUNT_FAILURE: {
      return {
        ...state,
        meetingCount: {
          ...state.meetingCount,
          fetching: false,
          error: action.error
        }
      };
    }
    case MeetingActions.FETCH_MEETING_ATTRIBUTED_USER_DETAILS_REQUEST: {
      return {
        ...state,
        metering: {
          ...state.metering,
          [action.meetingUuid]: {
            ...state.metering[action.meetingUuid],
            fetching: true,
            error: false
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_ATTRIBUTED_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            attributedUserData: action.response
          }
        },
        metering: {
          ...state.metering,
          [action.meetingUuid]: {
            ...state.metering[action.meetingUuid],
            fetching: false,
            error: false,
            data: action.response
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_ATTRIBUTED_USER_DETAILS_FAILURE: {
      return {
        ...state,
        metering: {
          ...state.metering,
          [action.meetingUuid]: {
            ...state.metering[action.meetingUuid],
            fetching: false,
            error: true
          }
        }
      };
    }

    case MeetingActions.FETCH_CALENDAR_VIEW_MEETINGS_REQUEST:
      return {
        ...state,
        fetchingCalView: true,
        data: {},
        payload: action.payload
      };
    case MeetingActions.FETCH_CALENDAR_VIEW_MEETINGS_SUCCESS:
      return {
        ...state,
        fetchingCalView: false,
        data: action.data,
        message: action.message,
        payload: action.payload
      };
    case MeetingActions.FETCH_CALENDAR_VIEW_MEETINGS_FAILURE:
      return {
        ...state,
        fetchingCalView: false,
        data: [],
        error: action.error
      };

    case MeetingActions.FETCH_MEETING_CRM_ASSOCIATIONS_REQUEST: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            tags: []
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            crmAssociations: {
              fetching: true,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_CRM_ASSOCIATIONS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            tags: action.response
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            crmAssociations: {
              fetching: false,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_CRM_ASSOCIATIONS_FAILURE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            tags: []
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            crmAssociations: {
              fetching: false,
              error: true,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_COUNTS_REQUEST: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            counts: {}
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            counts: {
              fetching: true,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_COUNTS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            counts: action.response
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            counts: {
              fetching: false,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_COUNTS_FAILURE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            counts: {}
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            counts: {
              fetching: false,
              error: true,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_SHARES_DATA_REQUEST: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            share: {}
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            share: {
              fetching: true,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_SHARES_DATA_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            share: action.response
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            share: {
              fetching: false,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_SHARES_DATA_FAILURE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            share: {}
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            share: {
              fetching: false,
              error: true,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_PLAYLIST_DATA_REQUEST: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            playlists: []
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            playlists: {
              fetching: true,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_PLAYLIST_DATA_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            playlists: action.response
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            playlists: {
              fetching: false,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_PLAYLIST_DATA_FAILURE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            playlists: []
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            playlists: {
              fetching: false,
              error: true,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_PURPOSE_OUTCOMES_REQUEST: {
      return {
        ...state,
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            attributes: {
              fetching: true,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_PURPOSE_OUTCOMES_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            ...action.response
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            attributes: {
              fetching: false,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_PURPOSE_OUTCOMES_FAILURE: {
      return {
        ...state,
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            attributes: {
              fetching: false,
              error: true,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_ROW_RECORDING_DATA_REQUEST: {
      return {
        ...state,
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            recording: {
              fetching: true,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_ROW_RECORDING_DATA_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            ...action.response
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            recording: {
              fetching: false,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_ROW_RECORDING_DATA_FAILURE: {
      return {
        ...state,
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            recording: {
              fetching: false,
              error: true,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_STATES_REQUEST: {
      return {
        ...state,
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            states: {
              fetching: true,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_STATES_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            ...action.response
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            states: {
              fetching: false,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_STATES_FAILURE: {
      return {
        ...state,
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            states: {
              fetching: false,
              error: true,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_CONFERENCE_DATA_REQUEST: {
      return {
        ...state,
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            conference: {
              fetching: true,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_CONFERENCE_DATA_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            ...action.response
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            conference: {
              fetching: false,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_CONFERENCE_DATA_FAILURE: {
      return {
        ...state,
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            conference: {
              fetching: false,
              error: true,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_ADDITIONAL_DETAILS_REQUEST: {
      return {
        ...state,
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            links: {
              fetching: true,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_ADDITIONAL_DETAILS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data[action.meetingUuid],
            ...action.response
          }
        },
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            links: {
              fetching: false,
              error: false,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.FETCH_MEETING_ADDITIONAL_DETAILS_FAILURE: {
      return {
        ...state,
        meetingEntityFetching: {
          ...state.meetingEntityFetching,
          [action.meetingUuid]: {
            ...state.meetingEntityFetching[action.meetingUuid],
            links: {
              fetching: false,
              error: true,
              requestTs: action.requestTs
            }
          }
        }
      };
    }
    case MeetingActions.SHOW_TRANSCRIPT_TIMESTAMP_SEARCH: {
      return {
        ...state,
        transcriptTimestampSearch: {
          ...action.response,
          show: true
        }
      };
    }
    case MeetingActions.HIDE_TRANSCRIPT_TIMESTAMP_SEARCH: {
      return {
        ...state,
        transcriptTimestampSearch: {
          show: false
        }
      };
    }
    case MeetingActions.UPDATE_MEETING_COUNTS: {
      const { meetingUuid, requestType, entity, count } = action;
      // If count is not provided, we assume it is 1
      const changedCount = count || 1;
      return {
        ...state,
        data: {
          ...state.data,
          [meetingUuid]: {
            ...state.data[meetingUuid],
            counts: {
              ...state.data[meetingUuid].counts,
              [entity]:
                requestType === 'add'
                  ? state.data[meetingUuid].counts[entity] + changedCount
                  : state.data[meetingUuid].counts[entity] - changedCount
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

export default meetings;
