/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import stylePropType from 'react-style-proptype';

import { minutesSeconds } from 'helpers/getPrettyTimeFromSeconds';

import Tokens from 'styles/tokens';

import AvomaIcon from '../AvomaIcon';

const TimestampLink = ({
  time,
  onClick,
  onHide,
  show,
  showHide,
  isLinked,
  Icon,
  svg,
  style,
  iconTooltip,
  timestampStyle
}) => (
  <div
    style={{
      ...styles.timestampContainer,
      ...style,
      ...((show || isLinked) && styles.show)
    }}
    contentEditable={false}
    className='disable-select'
    dataType='timestamp'
  >
    {Icon && <Icon className='disable-select' style={styles.iconStyle} />}
    {svg && (
      <AvomaIcon
        className='disable-select'
        icon={svg}
        alt='timestamp'
        style={styles.iconStyle}
        tooltip={iconTooltip}
        tooltipPosition='bottom'
        small
      />
    )}
    {isLinked && showHide && (
      <div
        style={styles.hideBtn}
        className='disable-select'
        contentEditable={false}
        onClick={onHide}
      >
        Hide
      </div>
    )}

    <div
      onClick={onClick}
      className='disable-select'
      style={{
        ...styles.timestamp,
        ...(onClick && styles.timestampClickable),
        ...(isLinked && styles.linked),
        ...timestampStyle
      }}
    >
      {minutesSeconds(time)}
    </div>
  </div>
);

TimestampLink.propTypes = {
  time: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  showHide: PropTypes.bool,
  Icon: PropTypes.node,
  svg: PropTypes.string,
  isLinked: PropTypes.bool,
  style: stylePropType,
  iconTooltip: PropTypes.string,
  timestampStyle: stylePropType
};

TimestampLink.defaultProps = {
  show: true,
  onClick: null,
  isLinked: false
};

const styles = {
  timestampContainer: {
    ...Tokens.type.bodyS,
    position: 'absolute',
    top: '2px',
    right: 0,
    visibility: 'hidden',
    opacity: '0',
    zIndex: '3',
    transition: 'visibility 0s linear 300ms, opacity 100ms',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  hideBtn: {
    marginRight: Tokens.spacing.one,
    padding: `0 ${Tokens.spacing.one}`,
    border: `1px solid ${Tokens.colors.silver}`,
    backgroundColor: Tokens.colors.white,
    color: Tokens.colors.silver,
    borderRadius: Tokens.spacing.borderRadius,
    cursor: 'pointer'
    // ':hover': {
    //   backgroundColor: Tokens.colors.silver,
    //   color: Tokens.colors.white,
    // },
  },
  timestamp: {
    padding: `0 ${Tokens.spacing.half}`,
    backgroundColor: Tokens.colors.white,
    color: Tokens.colors.silver,
    borderRadius: Tokens.spacing.borderRadius
  },
  timestampClickable: {
    padding: `0 ${Tokens.spacing.one}`,
    border: `1px solid ${Tokens.colors.silver}`,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: Tokens.colors.silver,
      color: Tokens.colors.white
    }
  },
  show: {
    visibility: 'visible',
    opacity: '1',
    transition: 'visibility 0s, opacity 0s'
  },
  linked: {
    visibility: 'visible',
    opacity: '1',
    border: `1px solid ${Tokens.colors.blue}`,
    color: Tokens.colors.blue,
    backgroundColor: Tokens.colors.white,
    ':hover': {
      color: Tokens.colors.white,
      backgroundColor: Tokens.colors.blue
    }
  },
  iconStyle: {
    width: Tokens.spacing.two,
    height: Tokens.spacing.two,
    color: Tokens.colors.silver,
    marginRight: Tokens.spacing.half
  }
};

export default Radium(TimestampLink);
