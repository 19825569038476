export const sanitizeGeneratedNote = beResponse => {
  let parsedData;

  try {
    parsedData = JSON.parse(beResponse);
  } catch (error) {
    console.error('Error parsing BE response:', error);
    return [];
  }

  const traverse = (node, parent = null) => {
    if (node.object === 'block' && node.children) {
      if (node.children.length === 0) {
        node.children = [{ object: 'text', text: '' }];
      } else {
        node.children.forEach(child => traverse(child, node)); // Pass the parent node for better context
      }

      // Prevent duplicate blocks by checking if the parent already has a similar block
      if (parent) {
        const duplicateBlock = parent.children?.some(child => child === node);

        if (duplicateBlock) {
          return;
        }
      }
    }

    if (node.object === 'block' && node.type === 'list-item' && node.children) {
      if (node.children.length === 0) {
        node.children = [{ object: 'text', text: '' }];
      } else {
        node.children.forEach(child => traverse(child, node));
      }
    }

    if (node.children) {
      node.children.forEach(child => traverse(child, node));
    }
  };

  parsedData.forEach(node => traverse(node));

  return parsedData;
};
