import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Editor, Range } from 'slate';
import { ReactEditor } from 'slate-react';

import EditorToolbarButton from 'components/Editor/EditorToolbarButton';
import Portal from './Portal';

const FloatingMenu = ({ editor, actions, showMenu = false }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const el = menuRef.current;
    const { selection } = editor;
    const focused = ReactEditor.isFocused(editor);
    if (!el || !showMenu) {
      return;
    }

    if (
      !selection ||
      !focused ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style');
      return;
    }

    const timeout = setTimeout(() => {
      const domSelection = window.getSelection();
      const domRange = domSelection.getRangeAt(0);
      const rect = domRange.getBoundingClientRect();
      el.style.opacity = '1';
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
      el.style.left = `${
        rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
      }px`;
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <Portal>
      <div
        role='menu'
        ref={menuRef}
        tabIndex={0}
        className='transition-opacity-[0.75s] opacity-1 borger-gainsboro absolute -top-[10000px] -left-[10000px] z-50 -mt-3 flex gap-2 rounded-lg border border-silver-lighter bg-white p-3 shadow-standard'
        onMouseDown={e => {
          // prevent toolbar from taking focus away from editor
          e.preventDefault();
        }}
      >
        {actions?.map(action => (
          <EditorToolbarButton
            Icon={action.icon}
            onClick={action.onClick}
            active={false}
            tooltip={action.tooltip}
            iconFilter={null}
          />
        ))}
      </div>
    </Portal>
  );
};

FloatingMenu.propTypes = {
  editor: PropTypes.object.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired
    })
  ).isRequired,
  showMenu: PropTypes.bool
};

export default FloatingMenu;
