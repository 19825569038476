export const L10Common = /** @type {const} */ ({
  addVariable: 'Add Variable',
  addNew: 'Add New',
  role: 'Role',
  license: 'License',
  team: 'Team',
  dialer: 'Dialer',
  reset: 'Reset',
  searchTeams: 'Search teams',
  select: 'Select',
  selectValue: 'Select a Value',
  close: 'Close',
  cancel: 'Cancel',
  connect: 'Connect',
  delete: 'Delete',
  type: 'Type',
  search: 'Search',
  name: 'Name',
  fullName: 'Full Name',
  standardViews: 'Standard Views',
  myViews: 'My Views',
  teammatesViews: `Teammates' Views`,
  successMessage: 'Changes saved successfully!',
  you: 'You',
  save: 'Save',
  hi: 'Hi',
  welcome: 'Welcome',
  firstName: 'First Name',
  lastName: 'Last Name',
  company: 'Company',
  continue: 'Continue',
  selectATitle: 'Select Job title',
  back: 'Back',
  noResultsFound: 'No Results Found',
  click: 'Click',
  other: 'Other',
  meetings: 'Meetings',
  companyName: 'Company Name',
  startNow: 'Start Now',
  disconnect: 'Disconnect',
  disconnected: 'Disconnected',
  disconnectZoom: 'Disconnect Zoom?',
  goBack: 'Go Back',
  scheduleAMeeting: 'Schedule a Meeting',
  edit: 'Edit',
  noSpecialCharactersAllowed: 'No special characters allowed',
  writeHere: 'Write here',
  member: 'Member',
  organizer: 'Organizer',
  members: 'Members',
  types: 'Types',
  riskScore: 'Risk Score',
  viewInCRM: 'View in CRM',
  viewDeal: 'View Deal',
  scheduler: 'Scheduler',
  uploadImage: 'Upload Image',
  slack: 'Slack',
  hubspot: 'HubSpot',
  salesForce: 'Salesforce',
  marketo: 'Marketo',
  menu: 'Menu',
  removeImage: 'Remove Image',
  dealRisk: 'Deal Risk',
  nextMeetingExists: 'Next Meeting',
  dealRiskScore: 'Deal Risk Score',
  duration: 'Duration',
  assign: 'Assign',
  logo: 'Logo',
  from: 'From',
  to: 'To',
  owner: 'Owner',
  helpDocs: 'Help Docs',
  alerts: 'Alerts',
  private: 'Private',
  organization: 'Organization',
  selectUsers: 'Select Users',
  notifications: 'Notifications',
  companies: 'Companies',
  email: 'Email',
  date: 'Date',
  call: 'Call',
  calls: 'Calls',
  more: 'More',
  next: 'Next',
  yesProceed: 'Yes, Proceed',
  saveAndNext: 'Save & Next',
  saveAndCreate: 'Save & Create',
  copyCode: 'Copy Code',
  copyEmail: 'Copy Email',
  copy: 'Copy',
  copied: 'Copied!',
  legend: 'Legend',
  or: 'OR',
  on: 'On',
  off: 'Off',
  add: 'Add',
  public: 'Public',
  sortKeys: 'Sort Keys',
  makePublic: 'Make Public',
  Beta: 'Beta',
  viewDetails: 'View Details',
  meetingDetails: 'Meeting Details',
  savePreferences: 'Save Preferences',
  configure: 'Configure content',
  preview: 'Preview',
  done: 'Done',
  standard: 'Standard',
  my: 'My',
  teamMates: `Teammates'`,
  coaching: 'Coaching',
  systemNotifications: 'System Notifications',
  teams: 'Teams',
  deleteTeam: 'Delete Team',
  deleteTeamCheck: 'Delete Team?',
  yourTeams: 'Your teams',
  otherTeams: 'Other teams',
  proceed: 'Proceed',
  textCopied: 'Text copied to clipboard',
  copyText: 'Copy to clipboard',
  copyErrorMessage:
    'Unable to copy text to clipboard. This may be due to insufficient copy permissions or clipboard access permissions.',
  seconds: 'seconds',
  licenseNeeded: 'Please upgrade to higher plan to access this feature.',
  allMembers: 'All Members',
  yes: 'Yes',
  no: 'No',
  comingSoon: 'Coming Soon',
  viewMore: 'View More',
  new: 'New',
  disconnectCta: 'Yes, Disconnect',
  share: 'Share',
  updateName: 'Update Name',
  learnHow: 'Learn How',
  submit: 'Submit',
  send: 'Send',
  description: 'Description',
  recommended: 'Recommended',
  dismiss: 'Dismiss',
  learnMore: 'Learn More',
  completed: 'Completed',
  settings: 'settings',
  purchaseLicense: 'Purchase/Assign License',
  configuration: 'Configuration',
  deactivated: 'Deactivated',
  configureCta: 'Configure',
  lastModifiedDate: 'Last Modified Date',
  user: 'User',
  avomaUser: 'Avoma User',
  usage: 'Usage',
  searchUser: 'Search User',
  none: 'None',
  selectFromAvomaUsers: 'Select from Avoma Users',
  howItWorks: 'How it works',
  createdBy: 'Created By',
  modifiedBy: 'Modified By',
  status: 'Status',
  disable: 'Disable',
  enable: 'Enable',
  saveAsDraft: 'Save as Draft',
  alreadyMappedTo: name => `Already mapped to ${name}`,
  optional: 'Optional',
  additionalInformation: 'Additional Information',
  removeBadge: 'Remove Badge',
  confirm: 'Confirm',
  seeDetails: 'See Details',
  undo: 'Undo',
  readOnly: 'Read Only',
  others: 'Others',
  successfullyUpdated: 'Successfully Updated',
  clickToEdit: 'Click to Edit',
  doYouWishToProceed: 'Do you wish to proceed?',
  reconnect: 'Reconnect'
});
