import PropTypes from 'prop-types';
import React from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';
import stylePropType from 'react-style-proptype';

import ButtonUnstyled from 'components/Common/ButtonUnstyled';

import ATTRIBUTE_PROVIDERS from 'constants/attributeProviders';

import KixieIcon from 'images/Kixie-Logo.png';
import { ReactComponent as HubspotIcon } from 'images/ic_hubspot_sprocket.svg';
import { ReactComponent as SalesforceIcon } from 'images/ic_salesforce_synced.svg';
import { ReactComponent as ZendesksellIcon } from 'images/ic_zendesksell_logo.svg';
import { ReactComponent as AircallIcon } from 'images/logo-aircall-small.svg';
import AvomaLogoIcon from 'images/logo-icon.png';
import { ReactComponent as OutreachIcon } from 'images/logo-outreach-small.svg';
import { ReactComponent as RingcentralIcon } from 'images/logo-ringcentral-small.svg';
import { ReactComponent as ZoomIcon } from 'images/logo-zoom-small.svg';
import SalesloftIcon from 'images/saleslofticon.png';

import Tokens from 'styles/tokens';

import AvomaIcon from './AvomaIcon';

/* Allows the tags to be removed and doesn't necessarily need to be a part of AttributeSelector
   Common component that should be usable in 
    1. TemplatePurposeSelector
    2. Types & Outcome Settings
    3. Types & Outcome Selections in Meeting Row/Header
 */
const AvomaAttribute = ({
  attribute,
  editable,
  style,
  onRemove,
  className,
  onClick,
  hideRemoveIcon = false
}) => {
  const renderProviderIcon = () => {
    if (attribute.provider) {
      switch (attribute.provider.service) {
        case ATTRIBUTE_PROVIDERS.salesforce:
          return <SalesforceIcon />;
        case ATTRIBUTE_PROVIDERS.hubspot:
          return <HubspotIcon />;
        case ATTRIBUTE_PROVIDERS.zoom:
          return <ZoomIcon />;
        case ATTRIBUTE_PROVIDERS.outreach:
          return <OutreachIcon />;
        case ATTRIBUTE_PROVIDERS.aircall:
          return <AircallIcon />;
        case ATTRIBUTE_PROVIDERS.ringcentral:
          return <RingcentralIcon />;
        case ATTRIBUTE_PROVIDERS.zendesksell:
          return <ZendesksellIcon />;
        case ATTRIBUTE_PROVIDERS.salesloft:
          return <AvomaIcon small icon={SalesloftIcon} />;
        case ATTRIBUTE_PROVIDERS.kixie:
          return <AvomaIcon small icon={KixieIcon} />;
        default:
          return <AvomaIcon small icon={AvomaLogoIcon} />;
      }
    }
    return <AvomaIcon small icon={AvomaLogoIcon} />; // avoma icon
  };

  const containerHandler = () => {
    if (editable && !hideRemoveIcon) return undefined;

    if (!editable && hideRemoveIcon) {
      return () => {
        onClick();
        onRemove(attribute.uuid);
      };
    }

    if (hideRemoveIcon && editable) {
      return () => onRemove(attribute.uuid);
    }

    if (!editable && !hideRemoveIcon) return onClick;
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={className}
      onClick={containerHandler()}
      style={{
        ...styles.tag,
        ...(attribute.foregroundColor && {
          color: attribute.foregroundColor
        }),
        ...(attribute.backgroundColor && {
          backgroundColor: attribute.backgroundColor
        }),
        ...style
      }}
    >
      <div className='truncate'>{attribute.label}</div>
      {attribute?.provider && (
        <div style={styles.providerIconContainer}>
          <div className='h-4 w-4'>{renderProviderIcon()}</div>
        </div>
      )}
      {editable && !hideRemoveIcon ? (
        <ButtonUnstyled
          style={styles.closeButton}
          onClick={() => onRemove(attribute.uuid)}
          data-testid={`remove-${attribute.uuid}`}
        >
          <CloseIcon style={styles.closeIcon} />
        </ButtonUnstyled>
      ) : null}
    </div>
  );
};

const styles = {
  tag: {
    ...Tokens.type.header6,
    fontWeight: '900',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'transparent',
    borderRadius: Tokens.spacing.borderRadius,
    padding: Tokens.spacing.half,
    cursor: 'pointer'
  },
  button: {
    display: 'flex',
    alignSelf: 'center',
    backgroundColor: 'transparent',
    fontSize: '10px',
    border: 'none',
    cursor: 'pointer'
  },
  iconStyles: {
    color: Tokens.colors.silver
  },
  closeIcon: {
    width: Tokens.spacing.onehalf,
    height: Tokens.spacing.onehalf
  },
  closeButton: {
    marginLeft: Tokens.spacing.half,
    borderRadius: Tokens.spacing.borderRadius,
    fontSize: '0'
  },
  providerIconContainer: {
    padding: `0 ${Tokens.spacing.half}`,
    marginLeft: Tokens.spacing.half,
    borderRadius: `0 ${Tokens.spacing.borderRadius} ${Tokens.spacing.borderRadius} 0`,
    fontSize: '0',
    textTransform: 'capitalize'
  }
};

AvomaAttribute.propTypes = {
  editable: PropTypes.bool,
  className: PropTypes.string,
  attribute: PropTypes.shape({
    label: PropTypes.string.isRequired,
    foregroundColor: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    provider: PropTypes.object
  }), // eslint-disable-line
  style: stylePropType,
  onRemove: PropTypes.func,
  onClick: PropTypes.func,
  hideRemoveIcon: PropTypes.bool
};

AvomaAttribute.defaultProps = {
  attribute: null,
  editable: false
};

export default AvomaAttribute;
