import isEmpty from 'lodash/isEmpty';

declare global {
  interface Window {
    desktop?: {
      mode: string;
      openLinkInBrowser: (url: string) => void;
      triggerSystemNotification?: (options: Notification) => void;
      handleSystemNotificationAction?: (
        callback: (notification: Notification, action: number) => void
      ) => void;
      handleSystemNotificationClick?: (
        callback: (notification: Notification) => void
      ) => void;
      removeSystemNotificationListeners?: () => void;
      changeMode?: (mode: string, initialPath?: string) => void;
    };
  }
}

export interface Notification {
  id: string;
  title: string;
  subtitle?: string;
  body?: string;
  actions: Array<{ type: string; text: string }>;
}

export const desktopAppModes = {
  ZEN_MODE: 'zen',
  FULL_MODE: 'full'
};

export const isDesktopContext = () => !isEmpty(window.desktop);

export const isFullMode = (): boolean =>
  window.desktop?.mode === desktopAppModes.FULL_MODE;

export const isZenMode = (): boolean =>
  window.desktop?.mode === desktopAppModes.ZEN_MODE;
