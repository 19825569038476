import isHotkey from 'is-hotkey';
import { Transforms, Range } from 'slate';

import isNodeTypeIn from '../Plugins/Queries/isNodeTypeIn';

export const onKeyDownResetBlockType =
  ({ rules }) =>
  (event, editor) => {
    let reset;
    if (editor.selection && Range.isCollapsed(editor.selection)) {
      rules.forEach(({ types, defaultType, hotkey, predicate, onReset }) => {
        if (!event || (hotkey && isHotkey(hotkey, event))) {
          if (predicate(editor) && isNodeTypeIn(editor, types)) {
            if (event) {
              event.preventDefault();
            }

            Transforms.setNodes(editor, { type: defaultType });
            if (onReset) {
              onReset(editor);
            }
            reset = true;
          }
        }
      });
    }

    return reset;
  };
