/* eslint-disable avoma/use-svgr-over-svg */
import { L10nAIWinLossAnalysis } from 'helpers/l10n/L10nAIWinLoss';

import copilotIcon from 'images/ic_ai_copilot.svg';
import aiMethodologyIcon from 'images/ic_ai_methodology.svg';
import commentIcon from 'images/ic_comment_gray.svg';
import dealHealthIcon from 'images/ic_deal_health.svg';
import propertiesIcon from 'images/ic_properties.svg';
import aiAnalysisIcon from 'images/ic_smart_playlists_grey.svg';
import activityIcon from 'images/ic_tab_activity.svg';

import Tokens from 'styles/tokens';

export const COMING_SOON_FIELDS = [];
export const HIDDEN_FIELDS = ['crmtaskfield'];
export const MAPPING_DISABLED_FIELDS = ['crmcontactfield', 'crmleadfield'];

export const DEAL_HEALTH_COLUMN = {
  fieldType: 'dealHealth',
  isReadOnly: true,
  isEditable: false
};

export const AI_METHODOLOGY_COLUMN = {
  fieldType: 'aiMethodology',
  isReadOnly: true,
  isEditable: false
};

export const DEAL_RISK_COLUMN = {
  fieldType: 'dealRisk',
  isReadOnly: true,
  isEditable: false
};

export const CUSTOM_CATEGORY = 'custom category';
export const SMART_CATEGORY = 'Smart Topic';

export const ENGAGEMENT_COLUMN_TIME_OPTIONS = [
  { label: 'D', value: 'day', tooltip: 'Day view' },
  { label: 'W', value: 'week', tooltip: 'Week view' },
  { label: 'M', value: 'month', tooltip: 'Month view' }
];

export const PIPELINE_DEALS_VIEW_FIXED_COLUMNS = [
  'Deal Name',
  'Risk Score',
  'Deal Health',
  'Engagement'
];

export const PIPELINE_COMPANIES_VIEW_FIXED_COLUMNS = [
  'Company Name',
  'Engagement',
  'Deals'
];

export const DEAL_QUICKVIEW_TABS = [
  { label: 'Activity', value: 'activity', iconPath: activityIcon },
  { label: 'AI Analysis', value: 'ai-analysis', iconPath: aiAnalysisIcon },
  { label: 'Properties', value: 'properties', iconPath: propertiesIcon },
  { label: 'Comments', value: 'comments', iconPath: commentIcon },
  { label: 'Deal Health', value: 'alerts', iconPath: dealHealthIcon },
  {
    label: 'Ask Avoma',
    value: 'ask-avoma',
    iconPath: copilotIcon
  }
];

export const DEAL_QUICKVIEW_AI_METHODOLOGY_TAB = {
  label: 'AI Methodology',
  value: 'ai-methodology',
  iconPath: aiMethodologyIcon
};

export const COMPANY_QUICKVIEW_TABS = [
  { label: 'Activity', value: 'activity', iconPath: activityIcon },
  { label: 'Properties', value: 'properties', iconPath: propertiesIcon }
];

export const DEAL_QUICKVIEW_TRACKER_EVENT_PER_TAB = {
  activity: 'DEAL_QUICKVIEW_ACTIVITY_TAB_VIEWED',
  properties: 'DEAL_QUICKVIEW_PROPERTIES_TAB_VIEWED',
  comments: 'DEAL_QUICKVIEW_COMMENTS_TAB_VIEWED',
  alerts: 'DEAL_QUICKVIEW_DEAL_HEALTH_TAB_VIEWED',
  'ai-analysis': 'DEAL_QUICKVIEW_AI_ANALYSIS_TAB_VIEWED',
  'ask-avoma': 'DEAL_QUICKVIEW_ASK_AVOMA_TAB_VIEWED'
};

export const COMPANY_QUICKVIEW_TRACKER_EVENT_PER_TAB = {
  activity: 'COMPANY_QUICKVIEW_ACTIVITY_TAB_VIEWED',
  properties: 'COMPANY_QUICKVIEW_PROPERTIES_TAB_VIEWED'
};

export const SEVERITY_TO_STYLES = {
  low: {
    background: Tokens.colors.greenLightest,
    color: Tokens.colors.greenDark
  },
  medium: {
    background: Tokens.colors.yellowLightest,
    color: Tokens.colors.yellowMoreDark
  },
  high: { background: Tokens.colors.redLightest, color: Tokens.colors.redDark }
};

export const AI_WIN_LOSS_ANALYSIS_STATES = {
  NOT_CREATED: 'not_created',
  CREATED: 'created',
  IN_PROGRESS: 'inprogress',
  DONE: 'done',
  ERROR: 'error'
};

export const AI_WIN_LOSS_ANALYSIS_MESSAGES_FOR_ERROR_STATES = {
  no_engagements: L10nAIWinLossAnalysis.errors.noEngagements(),
  no_associated_domain: L10nAIWinLossAnalysis.errors.noAssociatedDomain(),
  own_no_feat: L10nAIWinLossAnalysis.errors.ownNoFeat()
};
