/* eslint-disable avoma/use-svgr-over-svg */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import isNan from 'lodash/isNaN';
import PropTypes from 'prop-types';
import Radium from 'radium';
import React, { useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import AvomaCount from 'components/Common/AvomaCount';
import AvomaPopover from 'components/Common/AvomaPopover';
import ButtonUnstyled from 'components/Common/ButtonUnstyled';
import ConfirmModal from 'components/Common/ConfirmModal';
import VariationPopover from 'components/SmartCategories/Common/VariationPopover';

import L10n from 'helpers/L10n';

import deleteIcon from 'images/ic_delete_red.svg';
import editIcon from 'images/ic_edit_pen.svg';
import moreIcon from 'images/ic_more_vertical.svg';
import plusIcon from 'images/ic_plus.svg';

import Tokens from 'styles/tokens';

const Prompt = props => {
  const {
    onDeleteTriggerPrompt,
    onDeleteTriggerPromptVariation,
    prompt,
    variations,
    onCreateTriggerPromptVariation,
    variationExistsError,
    variationExistsMessage,
    onUpdateLabel,
    onClickMakePrimary,
    containerWidth,
    variationPopoverTitle,
    addMoreVariationsTooltip,
    isUnifiedFlow = false,
    hasDuplicateVariation = false,
    isAdminOrTopicOwner = false
  } = props;

  const variationsCount = Object.keys(variations)?.length;
  // total count to be shown on the prompt or popover would be variations count in addition to the base prompt
  const totalCount = variationsCount + 1;
  const [showPopover, setShowPopover] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [showDeleteTriggerModal, setShowDeleteTriggerModal] = useState(false);
  const promptRef = useRef();

  // Determine if the popover is to be shown right/left aligned (left being default) based on client width to avoid cutoffs
  const calculatePopoverPosition = () => {
    // If for some reason the container width is 0, return out else the popover default to right and goes out of bounds
    if (
      !containerWidth ||
      containerWidth === 0 ||
      isNan(promptRef?.current?.offsetLeft)
    ) {
      return;
    }

    if (containerWidth - promptRef?.current?.offsetLeft < 200) {
      return { right: 0 };
    }
  };

  const handleClosePopover = () => setShowPopover(false);

  const handleClickPopover = () => {
    setShowPopover(true);
    setShowMoreDetails(false);
  };

  const handleDeleteConfirm = () => {
    onDeleteTriggerPrompt(prompt.uuid);
  };

  const handleDeletePrompt = () => {
    setShowDeleteTriggerModal(true);
  };

  const promptTooltip = () => {
    if (variationsCount === 0) {
      return 'Click to add variations';
    }
    if (variationsCount === 1) {
      return 'Click to view & edit 1 variation';
    }
    return `Click to view & edit ${variationsCount} variations`;
  };

  const renderMoreDetailsPopover = () => (
    <OutsideClickHandler onOutsideClick={() => setShowMoreDetails(false)}>
      <AvomaPopover
        width={100}
        style={styles.moreDetailsPopover}
        wrapperStyle={{ top: Tokens.spacing.five, right: 0 }}
      >
        <ButtonUnstyled
          type='button'
          style={{ ...styles.menuButton, color: Tokens.colors.gunmetal }}
          onClick={handleClickPopover}
        >
          <img
            src={editIcon}
            alt='edit'
            style={styles.deleteIcon}
            key={`edit-icon-${prompt.uuid}`}
          />
          {L10n.general.edit}
        </ButtonUnstyled>
        <ButtonUnstyled
          type='button'
          style={{ ...styles.menuButton, color: Tokens.colors.avomaRed }}
          onClick={handleDeletePrompt}
        >
          <img
            src={deleteIcon}
            alt='delete'
            style={styles.deleteIcon}
            key={`delete-icon-${prompt.uuid}`}
          />
          {L10n.general.delete}
        </ButtonUnstyled>
      </AvomaPopover>
    </OutsideClickHandler>
  );

  const deleteTooltip =
    totalCount > 1
      ? L10n.customCategory.deletePromptWithVariations
      : L10n.customCategory.deletePrompt;

  const deletePromptDescriptionVariation = () => (
    <span>
      Do you want to delete trigger prompt <b>“{prompt.label}”</b>? This will
      also delete all the trigger prompt variations associated with this trigger
      prompt.{' '}
    </span>
  );

  const deletePromptDescription = () => (
    <span>
      Do you want to delete trigger prompt <b>“{prompt.label}”</b>?
    </span>
  );

  const confirmModalTitle =
    totalCount > 1
      ? `Delete prompt "${prompt.label}"  and its variations?`
      : `Delete prompt "${prompt.label}"?`;

  const confirmModalDescription =
    totalCount > 1
      ? deletePromptDescriptionVariation()
      : deletePromptDescription();

  return (
    <OutsideClickHandler onOutsideClick={handleClosePopover}>
      <div
        className='group relative float-left flex items-center rounded border border-blue-light text-blue hover:border-blue-dark hover:text-blue-dark'
        key={prompt.uuid}
        ref={promptRef}
      >
        <div className='flex rounded-l border-r border-blue-light bg-blue-white p-2 group-hover:border-blue-dark group-hover:bg-blue-whiter'>
          <ButtonUnstyled
            className='text-sm font-bold text-inherit'
            onClick={handleClickPopover}
            tooltip={
              !(showMoreDetails || showPopover) &&
              isAdminOrTopicOwner &&
              promptTooltip()
            }
            tooltipPosition='bottom-right'
            disabled={!isAdminOrTopicOwner}
          >
            {prompt.label}
          </ButtonUnstyled>

          {variationsCount > 0 && (
            <AvomaCount count={totalCount} style={styles.variationsCount} />
          )}
        </div>

        <div className='flex py-2 pr-2'>
          <ButtonUnstyled
            className='relative ml-1.5 flex w-full cursor-pointer items-center justify-center rounded bg-transparent p-0 hover:bg-f1 disabled:cursor-not-allowed disabled:bg-transparent disabled:opacity-50'
            onClick={handleClickPopover}
            tooltip={
              !(showMoreDetails || showPopover) && addMoreVariationsTooltip
            }
            tooltipStyle={styles.tooltip}
            disabled={showPopover || !isAdminOrTopicOwner}
            noDefaultStyles
          >
            <img
              src={plusIcon}
              alt='add'
              style={{
                ...(showPopover
                  ? { ...styles.addIcon }
                  : { ...styles.iconHover })
              }}
              key={`add-icon-${prompt.uuid}`}
            />
          </ButtonUnstyled>

          <ButtonUnstyled
            style={styles.moreButton}
            tooltip={
              !(showMoreDetails || showPopover) &&
              L10n.moreDetailsMenu.moreDetails
            }
            onClick={() => setShowMoreDetails(true)}
            tooltipPosition='bottom'
            tooltipStyle={styles.tooltip}
            key={`more-button-${prompt.uuid}`}
            disabled={showPopover || !isAdminOrTopicOwner}
          >
            <img
              src={moreIcon}
              alt='more'
              style={{
                ...styles.moreIcon,
                ...(!showPopover && { ...styles.iconHover })
              }}
              key={`more-icon-${prompt.uuid}`}
            />
          </ButtonUnstyled>
        </div>

        {showMoreDetails && isAdminOrTopicOwner && renderMoreDetailsPopover()}

        <VariationPopover
          showPopover={showPopover}
          handleClosePopover={handleClosePopover}
          variationCount={totalCount}
          variations={prompt.variations}
          word={prompt}
          onClickDelete={handleDeletePrompt}
          onClickDeleteVariation={onDeleteTriggerPromptVariation}
          onCreateVariation={onCreateTriggerPromptVariation}
          variationExistsError={variationExistsError}
          onUpdateLabel={onUpdateLabel}
          onClickMakePrimary={onClickMakePrimary}
          popoverPositionStyles={calculatePopoverPosition()}
          variationPopoverTitle={variationPopoverTitle}
          deleteTooltip={deleteTooltip}
          isKeyword={false}
          variationExistsMessage={variationExistsMessage}
          isMakePrimaryActionAllowed={!isUnifiedFlow}
          isUnifiedFlow={isUnifiedFlow}
          hasDuplicateVariation={hasDuplicateVariation}
          isAdminOrTopicOwner={isAdminOrTopicOwner}
        />
      </div>
      <ConfirmModal
        title={confirmModalTitle}
        text={confirmModalDescription}
        isOpen={showDeleteTriggerModal}
        confirmText={L10n.general.delete}
        onCancel={() => setShowDeleteTriggerModal(false)}
        onConfirm={handleDeleteConfirm}
      />
    </OutsideClickHandler>
  );
};

Prompt.propTypes = {
  onDeleteTriggerPrompt: PropTypes.func.isRequired,
  onDeleteTriggerPromptVariation: PropTypes.func.isRequired,
  variations: PropTypes.array,
  prompt: PropTypes.object,
  onCreateTriggerPromptVariation: PropTypes.func,
  variationExistsError: PropTypes.bool,
  onUpdateLabel: PropTypes.func,
  onClickMakePrimary: PropTypes.func,
  containerWidth: PropTypes.number,
  variationPopoverTitle: PropTypes.string,
  addMoreVariationsTooltip: PropTypes.string,
  variationExistsMessage: PropTypes.string,
  isUnifiedFlow: PropTypes.bool,
  hasDuplicateVariation: PropTypes.bool,
  isAdminOrTopicOwner: PropTypes.bool
};

const styles = {
  menuButton: {
    padding: Tokens.spacing.one,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: Tokens.colors.gunmetal,
    background: 'none',
    ...Tokens.type.bodyS,
    fontWeight: 700,
    ':hover': {
      background: Tokens.colors.f1
    }
  },
  moreDetailsPopover: {
    padding: 0
  },
  variationsCount: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: Tokens.spacing.one,
    padding: `0 ${Tokens.spacing.half}`,
    minWidth: Tokens.spacing.two
  },
  moreButton: {
    marginLeft: '6px',
    display: 'flex',
    background: 'none',
    borderRadius: Tokens.spacing.borderRadius,
    ':hover': {
      background: Tokens.colors.gainsboro
    },
    ':disabled': {
      cursor: 'not-allowed',
      opacity: 0.5
    }
  },
  moreIcon: {
    background: 'none',
    padding: Tokens.spacing.half
  },
  addIcon: {
    ':hover': {
      filter: 'none'
    }
  },
  iconHover: {
    ':hover': {
      filter: Tokens.filters.silverDark
    }
  },
  deleteIcon: {
    marginRight: Tokens.spacing.one,
    padding: Tokens.spacing.half,
    height: '14px',
    width: '14px'
  },
  tooltip: {
    bottom: `-${Tokens.spacing.five}`
  }
};

export default Radium(Prompt);
