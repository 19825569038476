import { getBlockAbove } from './getBlockAbove';
import { isAncestorEmpty } from './isAncestorEmpty';

/**
 * Is the block above the selection empty.
 */
export const isBlockAboveEmpty = (editor, emptyTypes = []) => {
  const blockEntry = getBlockAbove(editor);
  const [block] = blockEntry;
  if (emptyTypes.includes(block.type)) {
    return false;
  }
  return isAncestorEmpty(editor, block);
};
