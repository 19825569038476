const env = {
  name: import.meta.env.VITE_ENV,
  avomaApiUrl: '',
  googleLoginClientId: '',
  sentryDsn: '',
  beamerProductId: import.meta.env.VITE_BEAMER_PRODUCT_ID,
  chargebeeSiteId: import.meta.env.VITE_CHARGEBEE_SITE_ID_DEV,
  avomaFrontendUrl: ''
};

if (env.name === 'dev') {
  env.avomaApiUrl = import.meta.env.VITE_DEV_AVOMA_API_URL;
  env.avomaFrontendUrl = import.meta.env.VITE_DEV_FRONTEND_URL;
  env.avomaSchedulerUrl = import.meta.env.VITE_SCH_DEV_FRONTEND_URL;
  env.googleLoginClientId = import.meta.env.VITE_DEV_GOOGLE_LOGIN_CLIENT_ID;
  env.googleLoginRedirectUrl =
    import.meta.env.VITE_DEV_GOOGLE_LOGIN_REDIRECT_URL;
  env.oktaOidcRedirectUrl = import.meta.env.VITE_DEV_OKTA_OIDC_REDIRECT_URL;
  env.sentryDsn = import.meta.env.VITE_INT_SENTRY_DSN;
  env.salesforceClientId = import.meta.env.VITE_DEV_SALESFORCE_CLIENT_ID;
  env.salesforceRedirectUrl = import.meta.env.VITE_DEV_SALESFORCE_REDIRECT_URL;
  env.microsoftClientId = import.meta.env.VITE_DEV_MICROSOFT_CLIENT_ID;
  env.microsoftRedirectUrl = import.meta.env.VITE_DEV_MICROSOFT_REDIRECT_URL;
  env.outreachClientId = import.meta.env.VITE_DEV_OUTREACH_LOGIN_CLIENT_ID;
  env.outreachRedirectUrl = import.meta.env.VITE_DEV_OUTREACH_REDIRECT_URL;
  env.outreachSettingsRedirectUrl =
    import.meta.env.VITE_DEV_OUTREACH_SETTINGS_REDIRECT_URL;
  env.outreachScope = import.meta.env.VITE_OUTREACH_SCOPE;
  env.zoomAppClientId = import.meta.env.VITE_DEV_ZOOM_APP_CLIENT_ID;
  env.zoomAppRedirectUrl = import.meta.env.VITE_DEV_ZOOM_APP_REDIRECT_URL;
  env.zoomClientId = import.meta.env.VITE_DEV_ZOOM_CLIENT_ID;
  env.zoomRedirectUrl = import.meta.env.VITE_DEV_ZOOM_REDIRECT_URL;
  env.zoomPhoneClientId = import.meta.env.VITE_DEV_ZOOM_PHONE_CLIENT_ID;
  env.zoomPhoneRedirectUrl = import.meta.env.VITE_DEV_ZOOM_PHONE_REDIRECT_URL;
  env.aircallClientId = import.meta.env.VITE_DEV_AIRCALL_LOGIN_CLIENT_ID;
  env.aircallRedirectUrl = import.meta.env.VITE_DEV_AIRCALL_REDIRECT_URL;
  env.hubspotClientId = import.meta.env.VITE_DEV_HUBSPOT_CLIENT_ID;
  env.hubspotRedirectUrl = import.meta.env.VITE_DEV_HUBSPOT_REDIRECT_URL;
  env.pipedriveClientId = import.meta.env.VITE_DEV_PIPEDRIVE_CLIENT_ID;
  env.pipedriveRedirectUrl = import.meta.env.VITE_DEV_PIPEDRIVE_REDIRECT_URL;
  env.zohoClientId = import.meta.env.VITE_DEV_ZOHO_CLIENT_ID;
  env.zohoRedirectUrl = import.meta.env.VITE_DEV_ZOHO_REDIRECT_URL;
  env.chargebeeSiteId = import.meta.env.VITE_CHARGEBEE_SITE_ID_DEV;
  env.ringCentralServerUrl = import.meta.env.VITE_DEV_RINGCENTRAL_SERVER_URL;
  env.ringCentralClientId = import.meta.env.VITE_DEV_RINGCENTRAL_CLIENT_ID;
  env.ringCentralRedirectUrl =
    import.meta.env.VITE_DEV_RINGCENTRAL_REDIRECT_URL;
  env.websocketUrl = import.meta.env.VITE_DEV_WEBSOCKET;
  env.connectLeaderClientId = import.meta.env.VITE_DEV_CONNECT_LEADER_CLIENT_ID;
  env.connectLeaderRedirectURI =
    import.meta.env.VITE_DEV_CONNECT_LEADER_REDIRECT_URL;
  env.connectLeaderOAuthURL = import.meta.env.VITE_DEV_CONNECT_LEADER_OAUTH_URL;
  env.salesloftClientId = import.meta.env.VITE_DEV_SALESLOFT_CLIENT_ID;
  env.salesloftRedirectURI = import.meta.env.VITE_DEV_SALESLOFT_REDIRECT_URL;
  env.nodeURI = import.meta.env.VITE_DEV_NODE;
  env.nylasWebhookUrl = import.meta.env.VITE_DEV_NYLAS_WEBHOOK_URL;
  env.nylasApiUrl = import.meta.env.VITE_NYLAS_API;
  env.webexClientId = import.meta.env.VITE_DEV_WEBEX_CLIENT_ID;
  env.webexRedirectUrl = import.meta.env.VITE_DEV_WEBEX_REDIRECT_URL;
  env.msftCloudClientId = import.meta.env.VITE_DEV_MICROSOFT_CLOUD_CLIENT_ID;
  env.msftCloudRedirectUrl =
    import.meta.env.VITE_DEV_MICROSOFT_CLOUD_REDIRECT_URL;
  env.googleCloudRedirectUrl =
    import.meta.env.VITE_DEV_GOOGLE_CLOUD_REDIRECT_URL;
  env.extensionId = import.meta.env.VITE_DEV_BROWSER_EXTENSION_ID;
  env.slackClientId = import.meta.env.VITE_DEV_SLACK_CLIENT_ID;
  env.slackRedirectUrl = import.meta.env.VITE_DEV_SLACK_REDIRECT_URL;
  env.hubspotTrackingCode = import.meta.env.VITE_DEV_HUBSPOT_TRACKING_CODE;
  env.cookieYesId = import.meta.env.VITE_DEV_COOKIE_YES_ID;
  env.sdkURL = import.meta.env.VITE_DEV_SDK_URL;
  env.clickupClientId = import.meta.env.VITE_DEV_CLICKUP_CLIENT_ID;
  env.clickupRedirectUrl = import.meta.env.VITE_DEV_CLICKUP_REDIRECT_URL;
  env.segmentWriteKey = import.meta.env.VITE_DEV_SEGMENT_WRITE_KEY;
  env.segmentV2WriteKey = import.meta.env.VITE_DEV_SEGMENTV2_WRITE_KEY;
  env.amplitudeWriteKey = import.meta.env.VITE_DEV_AMPLITUDE_WRITE_KEY;
  env.gotoconnectClientId = import.meta.env.VITE_DEV_GOTOCONNECT_CLIENT_ID;
  env.gotoconnectRedirectUrl =
    import.meta.env.VITE_DEV_GOTOCONNECT_REDIRECT_URL;
  env.vonageClientId = import.meta.env.VITE_DEV_VONAGE_CLIENT_ID;
  env.vonageRedirectUrl = import.meta.env.VITE_DEV_VONAGE_REDIRECT_URL;
  env.microsoftClaritySchedulerProjectId =
    import.meta.env.VITE_INT_SCH_MICROSOFT_CLARITY_PROJECT_ID;
} else if (env.name === 'int') {
  env.avomaApiUrl = import.meta.env.VITE_INT_AVOMA_API_URL;
  env.avomaFrontendUrl = import.meta.env.VITE_INT_FRONTEND_URL;
  env.avomaSchedulerUrl = import.meta.env.VITE_SCH_INT_FRONTEND_URL;
  env.googleLoginClientId = import.meta.env.VITE_INT_GOOGLE_LOGIN_CLIENT_ID;
  env.googleLoginRedirectUrl =
    import.meta.env.VITE_INT_GOOGLE_LOGIN_REDIRECT_URL;
  env.oktaOidcRedirectUrl = import.meta.env.VITE_INT_OKTA_OIDC_REDIRECT_URL;
  env.sentryDsn = import.meta.env.VITE_INT_SENTRY_DSN;
  env.salesforceClientId = import.meta.env.VITE_INT_SALESFORCE_CLIENT_ID;
  env.salesforceRedirectUrl = import.meta.env.VITE_INT_SALESFORCE_REDIRECT_URL;
  env.microsoftClientId = import.meta.env.VITE_INT_MICROSOFT_CLIENT_ID;
  env.microsoftRedirectUrl = import.meta.env.VITE_INT_MICROSOFT_REDIRECT_URL;

  env.outreachClientId = import.meta.env.VITE_INT_OUTREACH_LOGIN_CLIENT_ID;
  env.outreachRedirectUrl = import.meta.env.VITE_INT_OUTREACH_REDIRECT_URL;
  env.outreachSettingsRedirectUrl =
    import.meta.env.VITE_INT_OUTREACH_SETTINGS_REDIRECT_URL;
  env.outreachScope = import.meta.env.VITE_OUTREACH_SCOPE;
  env.zoomAppClientId = import.meta.env.VITE_INT_ZOOM_APP_CLIENT_ID;
  env.zoomAppRedirectUrl = import.meta.env.VITE_INT_ZOOM_APP_REDIRECT_URL;
  env.zoomClientId = import.meta.env.VITE_INT_ZOOM_CLIENT_ID;
  env.zoomRedirectUrl = import.meta.env.VITE_INT_ZOOM_REDIRECT_URL;
  env.zoomPhoneClientId = import.meta.env.VITE_INT_ZOOM_PHONE_CLIENT_ID;
  env.zoomPhoneRedirectUrl = import.meta.env.VITE_INT_ZOOM_PHONE_REDIRECT_URL;
  env.aircallClientId = import.meta.env.VITE_INT_AIRCALL_LOGIN_CLIENT_ID;
  env.aircallRedirectUrl = import.meta.env.VITE_INT_AIRCALL_REDIRECT_URL;
  env.hubspotClientId = import.meta.env.VITE_INT_HUBSPOT_CLIENT_ID;
  env.hubspotRedirectUrl = import.meta.env.VITE_INT_HUBSPOT_REDIRECT_URL;
  env.pipedriveClientId = import.meta.env.VITE_INT_PIPEDRIVE_CLIENT_ID;
  env.pipedriveRedirectUrl = import.meta.env.VITE_INT_PIPEDRIVE_REDIRECT_URL;
  env.zohoClientId = import.meta.env.VITE_INT_ZOHO_CLIENT_ID;
  env.zohoRedirectUrl = import.meta.env.VITE_INT_ZOHO_REDIRECT_URL;
  env.chargebeeSiteId = import.meta.env.VITE_CHARGEBEE_SITE_ID_DEV;
  env.ringCentralServerUrl = import.meta.env.VITE_INT_RINGCENTRAL_SERVER_URL;
  env.ringCentralClientId = import.meta.env.VITE_INT_RINGCENTRAL_CLIENT_ID;
  env.ringCentralRedirectUrl =
    import.meta.env.VITE_INT_RINGCENTRAL_REDIRECT_URL;
  env.websocketUrl = import.meta.env.VITE_INT_WEBSOCKET;
  env.connectLeaderClientId = import.meta.env.VITE_INT_CONNECT_LEADER_CLIENT_ID;
  env.connectLeaderRedirectURI =
    import.meta.env.VITE_INT_CONNECT_LEADER_REDIRECT_URL;
  env.connectLeaderOAuthURL = import.meta.env.VITE_INT_CONNECT_LEADER_OAUTH_URL;
  env.salesloftClientId = import.meta.env.VITE_INT_SALESLOFT_CLIENT_ID;
  env.salesloftRedirectURI = import.meta.env.VITE_INT_SALESLOFT_REDIRECT_URL;
  env.nodeURI = import.meta.env.VITE_INT_NODE;
  env.nylasWebhookUrl = import.meta.env.VITE_INT_NYLAS_WEBHOOK_URL;
  env.nylasApiUrl = import.meta.env.VITE_NYLAS_API;
  env.webexClientId = import.meta.env.VITE_INT_WEBEX_CLIENT_ID;
  env.webexRedirectUrl = import.meta.env.VITE_INT_WEBEX_REDIRECT_URL;
  env.msftCloudClientId = import.meta.env.VITE_INT_MICROSOFT_CLOUD_CLIENT_ID;
  env.msftCloudRedirectUrl =
    import.meta.env.VITE_INT_MICROSOFT_CLOUD_REDIRECT_URL;
  env.googleCloudRedirectUrl =
    import.meta.env.VITE_INT_GOOGLE_CLOUD_REDIRECT_URL;
  env.extensionId = import.meta.env.VITE_INT_BROWSER_EXTENSION_ID;
  env.slackClientId = import.meta.env.VITE_INT_SLACK_CLIENT_ID;
  env.slackRedirectUrl = import.meta.env.VITE_INT_SLACK_REDIRECT_URL;
  env.hubspotTrackingCode = import.meta.env.VITE_INT_HUBSPOT_TRACKING_CODE;
  env.cookieYesId = import.meta.env.VITE_INT_COOKIE_YES_ID;
  env.sdkURL = import.meta.env.VITE_INT_SDK_URL;
  env.clickupClientId = import.meta.env.VITE_INT_CLICKUP_CLIENT_ID;
  env.clickupRedirectUrl = import.meta.env.VITE_INT_CLICKUP_REDIRECT_URL;
  env.segmentWriteKey = import.meta.env.VITE_INT_SEGMENT_WRITE_KEY;
  env.segmentV2WriteKey = import.meta.env.VITE_INT_SEGMENTV2_WRITE_KEY;
  env.amplitudeWriteKey = import.meta.env.VITE_INT_AMPLITUDE_WRITE_KEY;
  env.zoomSdkClientId = import.meta.env.VITE_INT_ZOOM_SDK_CLIENT_ID;
  env.zoomSdkRedirectUrl = import.meta.env.VITE_INT_ZOOM_SDK_REDIRECT_URL;
  env.gotoconnectClientId = import.meta.env.VITE_INT_GOTOCONNECT_CLIENT_ID;
  env.gotoconnectRedirectUrl =
    import.meta.env.VITE_INT_GOTOCONNECT_REDIRECT_URL;
  env.vonageClientId = import.meta.env.VITE_INT_VONAGE_CLIENT_ID;
  env.vonageRedirectUrl = import.meta.env.VITE_INT_VONAGE_REDIRECT_URL;
  env.microsoftClarityProjectId =
    import.meta.env.VITE_INT_MICROSOFT_CLARITY_PROJECT_ID;
  env.microsoftClaritySchedulerProjectId =
    import.meta.env.VITE_INT_SCH_MICROSOFT_CLARITY_PROJECT_ID;
} else if (env.name === 'prod') {
  env.avomaApiUrl = import.meta.env.VITE_PROD_AVOMA_API_URL;
  env.avomaFrontendUrl = import.meta.env.VITE_PROD_FRONTEND_URL;
  env.avomaSchedulerUrl = import.meta.env.VITE_SCH_PROD_FRONTEND_URL;
  env.googleLoginClientId = import.meta.env.VITE_PROD_GOOGLE_LOGIN_CLIENT_ID;
  env.googleLoginRedirectUrl =
    import.meta.env.VITE_PROD_GOOGLE_LOGIN_REDIRECT_URL;
  env.oktaOidcRedirectUrl = import.meta.env.VITE_PROD_OKTA_OIDC_REDIRECT_URL;
  env.sentryDsn = import.meta.env.VITE_PROD_SENTRY_DSN;
  env.salesforceClientId = import.meta.env.VITE_PROD_SALESFORCE_CLIENT_ID;
  env.salesforceRedirectUrl = import.meta.env.VITE_PROD_SALESFORCE_REDIRECT_URL;
  env.microsoftClientId = import.meta.env.VITE_PROD_MICROSOFT_CLIENT_ID;
  env.microsoftRedirectUrl = import.meta.env.VITE_PROD_MICROSOFT_REDIRECT_URL;
  env.outreachClientId = import.meta.env.VITE_PROD_OUTREACH_LOGIN_CLIENT_ID;
  env.outreachRedirectUrl = import.meta.env.VITE_PROD_OUTREACH_REDIRECT_URL;
  env.outreachSettingsRedirectUrl =
    import.meta.env.VITE_PROD_OUTREACH_SETTINGS_REDIRECT_URL;
  env.outreachScope = import.meta.env.VITE_OUTREACH_SCOPE;
  env.zoomAppClientId = import.meta.env.VITE_PROD_ZOOM_APP_CLIENT_ID;
  env.zoomAppRedirectUrl = import.meta.env.VITE_PROD_ZOOM_APP_REDIRECT_URL;
  env.zoomClientId = import.meta.env.VITE_PROD_ZOOM_CLIENT_ID;
  env.zoomRedirectUrl = import.meta.env.VITE_PROD_ZOOM_REDIRECT_URL;
  env.zoomPhoneClientId = import.meta.env.VITE_PROD_ZOOM_PHONE_CLIENT_ID;
  env.zoomPhoneRedirectUrl = import.meta.env.VITE_PROD_ZOOM_PHONE_REDIRECT_URL;
  env.aircallClientId = import.meta.env.VITE_PROD_AIRCALL_LOGIN_CLIENT_ID;
  env.aircallRedirectUrl = import.meta.env.VITE_PROD_AIRCALL_REDIRECT_URL;
  env.hubspotClientId = import.meta.env.VITE_PROD_HUBSPOT_CLIENT_ID;
  env.hubspotRedirectUrl = import.meta.env.VITE_PROD_HUBSPOT_REDIRECT_URL;
  env.pipedriveClientId = import.meta.env.VITE_PROD_PIPEDRIVE_CLIENT_ID;
  env.pipedriveRedirectUrl = import.meta.env.VITE_PROD_PIPEDRIVE_REDIRECT_URL;
  env.zohoClientId = import.meta.env.VITE_PROD_ZOHO_CLIENT_ID;
  env.zohoRedirectUrl = import.meta.env.VITE_PROD_ZOHO_REDIRECT_URL;
  env.chargebeeSiteId = import.meta.env.VITE_CHARGEBEE_SITE_ID_PROD;
  env.ringCentralServerUrl = import.meta.env.VITE_PROD_RINGCENTRAL_SERVER_URL;
  env.ringCentralClientId = import.meta.env.VITE_PROD_RINGCENTRAL_CLIENT_ID;
  env.ringCentralRedirectUrl =
    import.meta.env.VITE_PROD_RINGCENTRAL_REDIRECT_URL;
  env.websocketUrl = import.meta.env.VITE_PROD_WEBSOCKET;
  env.connectLeaderClientId =
    import.meta.env.VITE_PROD_CONNECT_LEADER_CLIENT_ID;
  env.connectLeaderRedirectURI =
    import.meta.env.VITE_PROD_CONNECT_LEADER_REDIRECT_URL;
  env.connectLeaderOAuthURL =
    import.meta.env.VITE_PROD_CONNECT_LEADER_OAUTH_URL;
  env.salesloftClientId = import.meta.env.VITE_PROD_SALESLOFT_CLIENT_ID;
  env.salesloftRedirectURI = import.meta.env.VITE_PROD_SALESLOFT_REDIRECT_URL;
  env.nodeURI = import.meta.env.VITE_PROD_NODE;
  env.hubspotPortalId = import.meta.env.VITE_HUBSPOT_PORTAL_ID;
  env.hubspotFormId = import.meta.env.VITE_HUBSPOT_FORM_ID;
  env.nylasWebhookUrl = import.meta.env.VITE_PROD_NYLAS_WEBHOOK_URL;
  env.nylasApiUrl = import.meta.env.VITE_NYLAS_API;
  env.webexClientId = import.meta.env.VITE_PROD_WEBEX_CLIENT_ID;
  env.webexRedirectUrl = import.meta.env.VITE_PROD_WEBEX_REDIRECT_URL;
  env.msftCloudClientId = import.meta.env.VITE_PROD_MICROSOFT_CLOUD_CLIENT_ID;
  env.msftCloudRedirectUrl =
    import.meta.env.VITE_PROD_MICROSOFT_CLOUD_REDIRECT_URL;
  env.googleCloudRedirectUrl =
    import.meta.env.VITE_PROD_GOOGLE_CLOUD_REDIRECT_URL;
  env.extensionId = import.meta.env.VITE_PROD_BROWSER_EXTENSION_ID;
  env.slackClientId = import.meta.env.VITE_PROD_SLACK_CLIENT_ID;
  env.slackRedirectUrl = import.meta.env.VITE_PROD_SLACK_REDIRECT_URL;
  env.hubspotTrackingCode = import.meta.env.VITE_PROD_HUBSPOT_TRACKING_CODE;
  env.cookieYesId = import.meta.env.VITE_STAGING_COOKIE_YES_ID;
  env.sdkURL = import.meta.env.VITE_PROD_SDK_URL;
  env.clickupClientId = import.meta.env.VITE_PROD_CLICKUP_CLIENT_ID;
  env.clickupRedirectUrl = import.meta.env.VITE_PROD_CLICKUP_REDIRECT_URL;
  env.segmentWriteKey = import.meta.env.VITE_PROD_SEGMENT_WRITE_KEY;
  env.segmentV2WriteKey = import.meta.env.VITE_PROD_SEGMENTV2_WRITE_KEY;
  env.amplitudeWriteKey = import.meta.env.VITE_PROD_AMPLITUDE_WRITE_KEY;
  env.chargebeeSiteId = import.meta.env.VITE_CHARGEBEE_SITE_ID_PROD;
  env.gotoconnectClientId = import.meta.env.VITE_PROD_GOTOCONNECT_CLIENT_ID;
  env.gotoconnectRedirectUrl =
    import.meta.env.VITE_PROD_GOTOCONNECT_REDIRECT_URL;
  env.vonageClientId = import.meta.env.VITE_PROD_VONAGE_CLIENT_ID;
  env.vonageRedirectUrl = import.meta.env.VITE_PROD_VONAGE_REDIRECT_URL;
  env.microsoftClarityProjectId =
    import.meta.env.VITE_PROD_MICROSOFT_CLARITY_PROJECT_ID;
  env.microsoftClaritySchedulerProjectId =
    import.meta.env.VITE_PROD_SCH_MICROSOFT_CLARITY_PROJECT_ID;
} else if (env.name === 'staging') {
  env.avomaApiUrl = import.meta.env.VITE_STAGING_AVOMA_API_URL;
  env.avomaFrontendUrl = import.meta.env.VITE_STAGING_FRONTEND_URL;
  env.avomaSchedulerUrl = import.meta.env.VITE_SCH_STAGING_FRONTEND_URL;
  env.googleLoginClientId = import.meta.env.VITE_STAGING_GOOGLE_LOGIN_CLIENT_ID;
  env.googleLoginRedirectUrl =
    import.meta.env.VITE_STAGING_GOOGLE_LOGIN_REDIRECT_URL;
  env.oktaOidcRedirectUrl = import.meta.env.VITE_STAGING_OKTA_OIDC_REDIRECT_URL;
  env.sentryDsn = import.meta.env.VITE_STAGING_SENTRY_DSN;
  env.salesforceClientId = import.meta.env.VITE_STAGING_SALESFORCE_CLIENT_ID;
  env.salesforceRedirectUrl =
    import.meta.env.VITE_STAGING_SALESFORCE_REDIRECT_URL;
  env.microsoftClientId = import.meta.env.VITE_STAGING_MICROSOFT_CLIENT_ID;
  env.microsoftRedirectUrl =
    import.meta.env.VITE_STAGING_MICROSOFT_REDIRECT_URL;
  env.outreachClientId = import.meta.env.VITE_INT_OUTREACH_LOGIN_CLIENT_ID;
  env.outreachRedirectUrl = import.meta.env.VITE_INT_OUTREACH_REDIRECT_URL;
  env.outreachSettingsRedirectUrl =
    import.meta.env.VITE_INT_OUTREACH_SETTINGS_REDIRECT_URL;
  env.outreachScope = import.meta.env.VITE_OUTREACH_SCOPE;
  env.zoomAppClientId = import.meta.env.VITE_STAGING_ZOOM_APP_CLIENT_ID;
  env.zoomAppRedirectUrl = import.meta.env.VITE_STAGING_ZOOM_APP_REDIRECT_URL;
  env.zoomClientId = import.meta.env.VITE_STAGING_ZOOM_CLIENT_ID;
  env.zoomRedirectUrl = import.meta.env.VITE_STAGING_ZOOM_REDIRECT_URL;
  env.zoomPhoneClientId = import.meta.env.VITE_PROD_ZOOM_PHONE_CLIENT_ID;
  env.zoomPhoneRedirectUrl = import.meta.env.VITE_PROD_ZOOM_PHONE_REDIRECT_URL;
  env.aircallClientId = import.meta.env.VITE_STATTING_AIRCALL_LOGIN_CLIENT_ID;
  env.aircallRedirectUrl = import.meta.env.VITE_STATTING_AIRCALL_REDIRECT_URL;
  env.hubspotClientId = import.meta.env.VITE_STAGING_HUBSPOT_CLIENT_ID;
  env.hubspotRedirectUrl = import.meta.env.VITE_STAGING_HUBSPOT_REDIRECT_URL;
  env.pipedriveClientId = import.meta.env.VITE_STAGING_PIPEDRIVE_CLIENT_ID;
  env.pipedriveRedirectUrl =
    import.meta.env.VITE_STAGING_PIPEDRIVE_REDIRECT_URL;
  env.zohoClientId = import.meta.env.VITE_STAGING_ZOHO_CLIENT_ID;
  env.zohoRedirectUrl = import.meta.env.VITE_STAGING_ZOHO_REDIRECT_URL;
  env.chargebeeSiteId = import.meta.env.VITE_CHARGEBEE_SITE_ID_STAGING;
  env.ringCentralServerUrl =
    import.meta.env.VITE_STAGING_RINGCENTRAL_SERVER_URL;
  env.ringCentralClientId = import.meta.env.VITE_STAGING_RINGCENTRAL_CLIENT_ID;
  env.ringCentralRedirectUrl =
    import.meta.env.VITE_STAGING_RINGCENTRAL_REDIRECT_URL;
  env.websocketUrl = import.meta.env.VITE_STAGING_WEBSOCKET;
  env.connectLeaderClientId =
    import.meta.env.VITE_STAGING_CONNECT_LEADER_CLIENT_ID;
  env.connectLeaderRedirectURI =
    import.meta.env.VITE_STAGING_CONNECT_LEADER_REDIRECT_URL;
  env.connectLeaderOAuthURL =
    import.meta.env.VITE_STAGING_CONNECT_LEADER_OAUTH_URL;
  env.salesloftClientId = import.meta.env.VITE_PROD_SALESLOFT_CLIENT_ID;
  env.salesloftRedirectURI = import.meta.env.VITE_PROD_SALESLOFT_REDIRECT_URL;
  env.nodeURI = import.meta.env.VITE_STAGING_NODE;
  env.nylasWebhookUrl = import.meta.env.VITE_STAGING_NYLAS_WEBHOOK_URL;
  env.nylasApiUrl = import.meta.env.VITE_NYLAS_API;
  env.webexClientId = import.meta.env.VITE_STAGING_WEBEX_CLIENT_ID;
  env.webexRedirectUrl = import.meta.env.VITE_STAGING_WEBEX_REDIRECT_URL;
  env.msftCloudClientId =
    import.meta.env.VITE_STAGING_MICROSOFT_CLOUD_CLIENT_ID;
  env.msftCloudRedirectUrl =
    import.meta.env.VITE_STAGING_MICROSOFT_CLOUD_REDIRECT_URL;
  env.googleCloudRedirectUrl =
    import.meta.env.VITE_STAGING_GOOGLE_CLOUD_REDIRECT_URL;
  env.extensionId = import.meta.env.VITE_STAGING_BROWSER_EXTENSION_ID;
  env.slackClientId = import.meta.env.VITE_STAGING_SLACK_CLIENT_ID;
  env.slackRedirectUrl = import.meta.env.VITE_STAGING_SLACK_REDIRECT_URL;
  env.hubspotTrackingCode = import.meta.env.VITE_STAGING_HUBSPOT_TRACKING_CODE;
  env.cookieYesId = import.meta.env.VITE_PROD_COOKIE_YES_ID;
  env.sdkURL = import.meta.env.VITE_PROD_SDK_URL;
  env.clickupClientId = import.meta.env.VITE_STAGING_CLICKUP_CLIENT_ID;
  env.clickupRedirectUrl = import.meta.env.VITE_STAGING_CLICKUP_REDIRECT_URL;
  env.segmentWriteKey = import.meta.env.VITE_PROD_SEGMENT_WRITE_KEY;
  env.segmentV2WriteKey = import.meta.env.VITE_STAGING_SEGMENTV2_WRITE_KEY;
  env.amplitudeWriteKey = import.meta.env.VITE_STAGING_AMPLITUDE_WRITE_KEY;
  env.chargebeeSiteId = import.meta.env.VITE_CHARGEBEE_SITE_ID_PROD;
  env.gotoconnectClientId = import.meta.env.VITE_STAGING_GOTOCONNECT_CLIENT_ID;
  env.gotoconnectRedirectUrl =
    import.meta.env.VITE_STAGING_GOTOCONNECT_REDIRECT_URL;
  env.vonageClientId = import.meta.env.VITE_STAGING_VONAGE_CLIENT_ID;
  env.vonageRedirectUrl = import.meta.env.VITE_STAGING_VONAGE_REDIRECT_URL;
  env.microsoftClarityProjectId =
    import.meta.env.VITE_STAGING_MICROSOFT_CLARITY_PROJECT_ID;
  env.microsoftClaritySchedulerProjectId =
    import.meta.env.VITE_STAGING_SCH_MICROSOFT_CLARITY_PROJECT_ID;
} else {
  throw Error('Your environment name is invalid');
}

export default env;
