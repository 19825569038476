import { ZoomAppActions } from 'actions/actionTypes';

const initialState = {};

const zoom = (state = initialState, action) => {
  switch (action.type) {
    case ZoomAppActions.SET_ZOOM_MEETING_UUID: {
      return {
        ...state,
        meetingUuid: action.meetingUuid
      };
    }
    default:
      return state;
  }
};

export default zoom;
