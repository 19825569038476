import { twMerge } from 'tailwind-merge';

interface IProps {
  height?: number | string;
  width?: number | string;
  fullRounded?: boolean;
  containerClasses?: string;
  className?: string;
}

const AvomaSkeleton = ({
  height = 10,
  width = '100%',
  fullRounded = false,
  containerClasses,
  className
}: IProps) => (
  <div
    className={twMerge('flex w-full items-center', containerClasses)}
    data-testid='avoma-skeleton'
  >
    <span
      className={twMerge(
        'animate-pulse rounded-md bg-gainsboro leading-none',
        fullRounded && 'rounded-full',
        className
      )}
      style={{ height, width }}
    >
      &zwnj;
    </span>
    <br />
  </div>
);

export default AvomaSkeleton;
