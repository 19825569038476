import { PLAN_NAMES } from 'constants/billing';

export const L10nFeatureGating = /** @type {const} */ ({
  upgradeTitle: 'Upgrade license to access this feature',
  requestTitle: `Request a ${PLAN_NAMES.premium} License from your admin`,
  emailTitle: 'Send a licenese request to your admin',
  requestLicenseCta: 'Request License',
  privateComments: 'Private Comments',
  enhancedTranscripts: 'Enhanced Transcripts',
  advancedAiNotes: 'Advanced AI Notes',
  fullHistorySearch: 'Full History Search',
  enhancedSmartPlaylists: 'Enhanced Smart Playlists',
  schedulerExternalRedirect: 'Scheduler external redirect',
  schedulerCustomBranding: 'Scheduler custom branding',
  scorecards: 'Scorecards',
  smartTrackers: 'Smart Trackers',
  schedulerManagedPages: 'Scheduler managed pages',
  schedulerGroupScheduling: 'Scheduler group scheduling',
  crmRecordSettings: 'CRM Record Settings'
});
