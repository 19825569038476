import { findKey, includes } from 'lodash';

class Licenses {
  static DEFAULT = 'basic-monthly';

  static PLANS = {
    /* Follows the below format:
        plan: [monthly-planId, half-yearly-planId, yearly-planId]
      */

    basic: ['basic-monthly', 'basic-monthly', 'basic-monthly', 'basic-monthly'],
    lite: ['lite-monthly', 'lite-quarterly', 'lite-half-yearly', 'lite-yearly'],
    starter: [
      'lite-monthly',
      'lite-quarterly',
      'lite-half-yearly',
      'lite-yearly'
    ],
    plus: ['plus-monthly', 'plus-quarterly', 'plus-half-yearly', 'plus-yearly'],
    // pro: ['pro-monthly', 'pro-half-yearly', 'pro-yearly'],
    'premium-v2': [
      'premium-v2-monthly',
      'premium-v2-quarterly',
      'premium-v2-half-yearly',
      'premium-v2-yearly'
    ],
    premium: [
      'premium-monthly',
      'premium-quarterly',
      'premium-half-yearly',
      'premium-yearly'
    ],
    manager: [
      'manager-monthly',
      'manager-quarterly',
      'manager-half-yearly',
      'manager-yearly'
    ],
    dialer: [
      'dialer-monthly',
      'dialer-quarterly',
      'dialer-half-yearly',
      'dialer-yearly'
    ],
    enterprise: [
      'enterprise-monthly',
      'enterprise-quarterly',
      'enterprise-half-yearly',
      'enterprise-yearly'
    ]
  };

  static GROUPS = {
    currentPlans: {
      lite: ['lite-monthly', 'lite-half-yearly', 'lite-yearly'],
      plus: ['plus-monthly', 'plus-half-yearly', 'plus-yearly'],
      business: ['premium-monthly', 'premium-half-yearly', 'premium-yearly']
    },
    otherTeams: {
      basic: ['basic-monthly', 'basic-monthly', 'basic-monthly'],
      lite: ['lite-monthly', 'lite-half-yearly', 'lite-yearly'],
      plus: ['plus-monthly', 'plus-half-yearly', 'plus-yearly']
    },
    revenueTeams: {
      premium: [
        'premium-v2-monthly',
        'premium-v2-half-yearly',
        'premium-v2-yearly'
      ],
      business: ['premium-monthly', 'premium-half-yearly', 'premium-yearly'],
      enterprise: [
        'enterprise-monthly',
        'enterprise-half-yearly',
        'enterprise-yearly'
      ]
    }
  };

  static CYCLE = {
    monthly: 'monthly',
    yearly: 'yearly',
    halfYearly: 'half-yearly'
  };

  static FEATURES = {
    calScheduling: 'cal_scheduling',
    calendarIntegration: 'calendar_integration',
    callMonitoring: 'call_monitoring',
    conferencingIntegration: 'conferencing_integration',
    conversationInsightsDashboard: 'conversation_insights_dashboard',
    trendsDashboard: 'trends_dashboard',
    activityDashboard: 'activity_dashboard',
    trackersDashboard: 'trackers_dashboard',
    smartTracker: 'smart_tracker',
    topicsDashboard: 'topics_dashboard',
    crmIntegration: 'crm_integration',
    dialerIntegration: 'dialer_integration',
    keywordTracking: 'keyword_tracking',
    noteTaking: 'note_taking',
    recording: 'recording',
    externalMeetings: 'external_meetings',
    internalMeetings: 'internal_meetings',
    transcription: 'transcription',
    userManagement: 'user_mgmt',
    zoomCloudRecordingIntegration: 'zoom_cloud_recording_integration',
    realTimeTranscription: 'real_time_transcription',
    DealIntelligence: 'deal_intelligence',
    salesforceIntegration: 'crm_integration_salesforce',
    scorecards: 'scorecards',
    participantRecording: 'participant_recording',
    interactionDashboardWithoutFilters: 'interaction_dashboard_without_filters',
    interactionDashboardWithFilters: 'interaction_dashboard_with_filters',
    topQuestionsDashboard: 'top_questions_dashboard',
    slackIntegration: 'slack_integration',
    schedulerEventTitleCustomization: 'scheduler_event_title_customization',
    crmRecordSettings: 'crm_record_settings',
    clickupIntegration: 'clickup_integration',
    schedulerRouter: 'scheduler_smart_router',
    autoChapters: 'autochapters',
    engagementDashboard: 'engagement_dashboard',
    winLossAnalysis: 'win_loss_analysis',
    copilot: 'copilot',
    manualNoteSyncToCrm: 'note_to_crm_record_manual',
    smartPlaylists: 'playlists_smart',
    notesRegen: 'notes_regen',
    meetingCrmAssociation: 'meeting_crm_association',
    customSavedViews: 'custom_saved_views',
    downloadRecording: 'download_recording',
    comments: 'comments',
    snippets: 'snippets',
    playlists: 'playlists',
    schedulerOneOnOnePage: 'scheduler_one_on_one',
    schedulerGroupPage: 'scheduler_group',
    schedulerRoundRobinPage: 'scheduler_round_robin',
    schedulerManagedPages: 'scheduler_managed_pages',
    forecasting: 'forecasting',
    schedulerCrmIntegration: 'crm_contact_creation',
    dealCopilot: 'deal_copilot'
  };

  static isExternalRecordingAllowed = features =>
    features && includes(features, Licenses.FEATURES.externalMeetings);

  static isInternalRecordingAllowed = features =>
    features && includes(features, Licenses.FEATURES.internalMeetings);

  static isRecordingAllowed = ({ features, meeting }) => {
    if (meeting) {
      if (meeting.isInternal) {
        return Licenses.isInternalRecordingAllowed(features);
      }
      return Licenses.isExternalRecordingAllowed(features);
    }
    return false;
  };

  static getPlanName = planId => {
    const plan = findKey(Licenses.PLANS, plans => includes(plans, planId));
    return plan === 'lite' ? 'starter' : plan;
  };
}
export default Licenses;
