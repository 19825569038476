export const TYPE_PARAGRAPH = 'paragraph';
export const TYPE_HEADER1 = 'header-1';
export const TYPE_HEADER2 = 'header-2';
export const TYPE_HEADER3 = 'header-3';
export const TYPE_BOLD = 'bold';
export const TYPE_ITALIC = 'italic';
export const TYPE_UNDERLINE = 'underline';
export const TYPE_STRIKETHROUGH = 'strikethrough';

export const TYPE_CHECKLIST_ITEM = 'check-list-item';

export const BLANK_CHECKLIST = {
  type: 'check-list-item',
  checked: false,
  children: [{ type: TYPE_PARAGRAPH, children: [{ text: '' }] }]
};
