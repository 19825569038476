/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import Radium from 'radium';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import stylePropType from 'react-style-proptype';

import { isDesktopContext } from 'helpers/desktopApp';
import { isZoomApp } from 'helpers/zoomApp';

import Tokens from 'styles/tokens';

const RLink = Radium(Link);

class AvomaLink extends PureComponent {
  render() {
    const {
      onClick,
      style,
      className,
      to,
      children,
      openNewWindow,
      ...otherProps
    } = this.props;

    const isDesktop = isDesktopContext();
    const isZoom = isZoomApp();
    const openInExternalBrowser = isDesktop || isZoom;

    if (onClick) {
      return (
        <span
          onClick={onClick}
          style={{
            ...styles.link,
            ...style
          }}
          className={className}
          {...otherProps}
        >
          {children}
        </span>
      );
    }

    if (to.startsWith('http') || to.startsWith('https')) {
      const redirectToExternalBrowser = () => {
        if (isZoom) {
          window.zoomSdk.openUrl({ url: to });
        } else {
          window.desktop.openLinkInBrowser(to);
        }
      };

      return (
        <a
          style={{
            ...styles.link,
            ...style
          }}
          className={className}
          target={openNewWindow ? '_blank' : '_self'}
          rel='noreferrer'
          href={openInExternalBrowser ? '#' : to}
          onClick={
            openInExternalBrowser ? redirectToExternalBrowser : undefined
          }
          {...otherProps}
        >
          {children}
        </a>
      );
    }

    return (
      <RLink
        style={{
          ...styles.link,
          ...style
        }}
        className={className}
        to={to}
        {...(openNewWindow && { target: '_blank' })}
        {...otherProps}
      >
        {children}
      </RLink>
    );
  }
}

AvomaLink.propTypes = {
  onClick: PropTypes.func,
  style: stylePropType,
  to: PropTypes.string,
  children: PropTypes.node,
  openNewWindow: PropTypes.bool,
  className: PropTypes.string
};

AvomaLink.defaultProps = {
  openNewWindow: false
};

const styles = {
  link: {
    color: Tokens.colors.blue,
    textDecoration: 'none',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline'
    }
  }
};

export default Radium(AvomaLink);
