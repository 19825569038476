export const MeetingAPIExceptions = {
  crossOrgSharingError: 'accessing_cross_org_resource'
};

export const PAST_MEETINGS_PAGE_SIZE = 10;
export const PAGE_SIZE = 5;

export const searchResultsForArray = [
  {
    label: 'Meeting Details',
    value: 'meeting_details',
    disabled: true,
    isChecked: true
  },
  {
    label: 'Transcript',
    value: 'transcripts',
    disabled: false,
    isChecked: true
  },
  {
    label: 'Notes',
    value: 'notes',
    disabled: false,
    isChecked: true
  }
];

export const meetingStateStatuses = {
  inProgress: 'inProgress',
  success: 'success',
  error: 'error'
};

export const meetingPrivacyOptions = {
  private: 'private',
  team: 'team',
  organization: 'organization',
  public: 'public'
};

export const MEETINGS_VIEW_TYPES = {
  DEFAULT: 'default',
  CALENDAR: 'calendar'
};

export const RegenerateNotesErrorStates = {
  user_not_attributed_or_admin:
    'You do not have the necessary permissions to regenerate notes for this meeting',
  snapshot_limit_exceeded:
    'The maximum number of note versions for this meeting has been reached.',
  rate_limit_exceeded:
    'Notes generation in progress. Please wait for it to finish before starting a new one.'
};

export const TRIGGER_REGENERATION_STATES = {
  PROCESSING: 'PROCESSING',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED'
};
