/* eslint-disable react/jsx-props-no-spreading */
import { useHover } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import AvomaTooltip from 'components/Common/AvomaTooltip';
import ButtonUnstyled from 'components/Common/ButtonUnstyled';
import LinkEditPopover from 'components/Notes/Slate/Elements/Link/EditPopover';
import Portal from 'components/Notes/Slate/Portal';
import { successNotification } from 'components/ToastNotifications';

import L10n from 'helpers/L10n';

import { ReactComponent as ClickupIcon } from 'images/ic_clickup_integration.svg';
import { ReactComponent as EditIcon } from 'images/ic_edit_pen.svg';
import { ReactComponent as HubspotIcon } from 'images/ic_hubspot_sprocket.svg';
import { ReactComponent as LinkIcon } from 'images/ic_link.svg';

import Tokens from 'styles/tokens';

const BUFFER = 150;
const RIGHT_BUFFER = 64;

const Link = ({
  elementProps,
  editor,
  editorId = 'editor-container',
  readOnly
}) => {
  const { children, element } = elementProps;
  const linkRef = useRef();
  const inputContainerRef = useRef();
  const clickupIntegration = useSelector(
    state => state.user?.ticketingProviders?.clickup?.clickupIntegration
  );
  const hubspotTaskIntegration = useSelector(
    state => state.user?.ticketingProviders?.hubspot?.hubspotIntegration
  );
  const [showEditPopover, setShowEditPopover] = useState(false);
  const [hovered, eventHandlers] = useHover(100, 300);
  const [popoverStyle, setPopoverStyle] = useState({});
  const [linkInput, setLinkInput] = useState(element.url);
  const [titleInput, setTitleInput] = useState(element.children?.[0]?.text);

  const onUpdateChange = () => {
    const urlText = editor.addHttpInUrl(linkInput);
    const link = {
      ...element,
      url: urlText,
      children: [
        {
          ...element.children[0],
          text: titleInput
        }
      ]
    };
    editor.updateLink(element, link);
  };

  // this useEffect is setting up the postion of input container inside the collab editor
  useEffect(() => {
    // if there is isHovered show the input
    if (hovered) {
      // editor container element
      const editorContainerEl = document.getElementById(editorId);
      const containerRect = editorContainerEl.getBoundingClientRect();

      const el = inputContainerRef.current;

      const selectionRect = linkRef.current.getBoundingClientRect();

      el.style.left = `${selectionRect.left + window.pageXOffset}px`;
      el.style.top = `${selectionRect.top + window.pageYOffset + 24}px`;

      const availableSpaceOnRightSide =
        containerRect.right - selectionRect.right;
      const availableSpaceOnBottomSide =
        containerRect.bottom - selectionRect.bottom;

      if (availableSpaceOnBottomSide < BUFFER) {
        setPopoverStyle({ bottom: Tokens.spacing.three });
      }

      if (availableSpaceOnBottomSide > BUFFER) {
        setPopoverStyle({});
      }

      if (availableSpaceOnRightSide < BUFFER) {
        el.style.left = 'auto';
        el.style.right = `${RIGHT_BUFFER}px`;
        setPopoverStyle({ right: Tokens.spacing.six });
      }
    }
  }, [hovered]);

  const onCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(element.url).then(() => {
        successNotification({ message: L10n.publicShare.copySuccess });
      });
    }
  };

  const onClickRemove = () => {
    editor.removeLink(element);
    setShowEditPopover(false);
  };

  const linkOrTitleUpdated =
    titleInput !== element.children?.[0]?.text || linkInput !== element.url;

  const isUrlClickup =
    clickupIntegration && element?.url?.startsWith('https://app.clickup.com/');

  const isUrlHubspotTask =
    hubspotTaskIntegration &&
    element?.url?.startsWith('https://app.hubspot.com/');

  const renderInput = () => (
    <Portal>
      <div
        ref={inputContainerRef}
        className='absolute z-[999999] min-w-[120px] max-w-[280px] rounded'
        contentEditable={false}
      >
        {hovered && !showEditPopover && (
          <div className='relative box-border flex w-full items-center rounded border border-gainsboro bg-white px-3 py-2 shadow-md'>
            {isUrlClickup && <ClickupIcon className='mr-1 h-4 w-4' />}
            {isUrlHubspotTask && <HubspotIcon className='mr-1 h-4 w-4' />}
            <span className='box-border w-full truncate rounded text-sm text-gunmetal'>
              {element.url}
            </span>

            <AvomaTooltip tooltip='Copy Link'>
              <ButtonUnstyled
                noDefaultStyles
                className='ml-2 flex cursor-pointer rounded bg-transparent p-1 hover:bg-gainsboro'
                onClick={onCopy}
                key='copy-link-btn'
              >
                <LinkIcon className='h-4 w-4 text-silver-dark' />
              </ButtonUnstyled>
            </AvomaTooltip>
            {!readOnly && (
              <AvomaTooltip tooltip='Edit Link'>
                <ButtonUnstyled
                  noDefaultStyles
                  className='link-btn ml-1 flex cursor-pointer rounded bg-transparent p-1 hover:bg-gainsboro'
                  tooltip='Edit'
                  onClick={() => {
                    setShowEditPopover(true);
                  }}
                  key='edit-link-btn'
                >
                  <EditIcon className='h-4 w-4 text-silver-dark' />
                </ButtonUnstyled>
              </AvomaTooltip>
            )}
          </div>
        )}

        {showEditPopover && (
          <LinkEditPopover
            url={linkInput}
            onOutsideClick={() => setShowEditPopover(false)}
            handleLinkChange={value => setLinkInput(value)}
            handleTitleChange={value => setTitleInput(value)}
            onClickRemove={onClickRemove}
            onClickCreate={onUpdateChange}
            title={titleInput}
            style={popoverStyle}
            updateDisabled={!linkOrTitleUpdated}
          />
        )}
      </div>
    </Portal>
  );

  const handleOnClick = () => {
    if (window.zoomSdk) {
      window.zoomSdk.openUrl({ url: element.url });
    }

    window.open(element.url, '_blank');
  };

  return (
    <span {...eventHandlers} style={{ position: 'relative' }}>
      <a
        ref={linkRef}
        href={element.url}
        data-target-href={element.url}
        rel='noreferrer nofollow noopener'
        className={twMerge(
          'underline hover:underline',
          isUrlClickup &&
            'decoration-none !bg-[#f0ebf8] !text-purple-700 no-underline hover:no-underline',
          isUrlHubspotTask &&
            'decoration-none !bg-[#f3e8e1] !text-amber-700 no-underline hover:no-underline'
        )}
        onClick={handleOnClick}
      >
        {children}
      </a>
      {renderInput()}
    </span>
  );
};

Link.displayName = 'Link';

Link.propTypes = {
  elementProps: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  editorId: PropTypes.string,
  readOnly: PropTypes.bool
};

export default Link;
