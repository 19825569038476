import { BattlecardActions } from 'actions/actionTypes';

export const battlecardsInitialState = {
  cards: []
};

const battlecards = (state = battlecardsInitialState, action) => {
  switch (action.type) {
    case BattlecardActions.FETCH_BATTLECARD_SUCCESS:
      return { ...state, cards: [...state.cards, action.card] };
    case BattlecardActions.RESET_BATTLECARD:
      return battlecardsInitialState;
    default:
      return state;
  }
};

export default battlecards;
