import { EditorState, Modifier, SelectionState } from 'draft-js';

const removeItem = (item, contentState) => {
  const { uuid: key, cleaned, context } = item;
  const prev = contentState.getBlockBefore(key);
  const block = contentState.getBlockForKey(key);
  if (block) {
    const text = block.getText();
    if (
      cleaned.toLowerCase() === text.toLowerCase() ||
      (context && context.toLowerCase() === text.toLowerCase())
    ) {
      let selection = SelectionState.createEmpty(key);
      if (prev) {
        selection = selection.merge({
          anchorKey: prev.getKey(),
          anchorOffset: prev.getLength()
        });
      }
      selection = selection.merge({
        focusKey: key,
        focusOffset: block.getLength()
      });
      return Modifier.removeRange(contentState, selection, 'forward');
    }
  }
  return contentState;
};

export const removeNoteWithKey = (key, editorState) => {
  let contentState = editorState.getCurrentContent();
  const prev = contentState.getBlockBefore(key);
  const block = contentState.getBlockForKey(key);
  if (block) {
    let selection = SelectionState.createEmpty(key);
    if (prev) {
      selection = selection.merge({
        anchorKey: prev.getKey(),
        anchorOffset: prev.getLength()
      });
    }
    selection = selection.merge({
      focusKey: key,
      focusOffset: block.getLength()
    });
    contentState = Modifier.removeRange(contentState, selection, 'forward');
    if (contentState !== editorState.getCurrentContent()) {
      return EditorState.set(editorState, {
        currentContent: contentState
      });
    }
  }
  return null;
};

export const removeMachineNotes = (items, editorState) => {
  let contentState = editorState.getCurrentContent();
  items.forEach(item => {
    contentState = removeItem(item, contentState);
  });
  if (contentState === editorState.getCurrentContent()) {
    return null;
  }
  return EditorState.set(editorState, {
    currentContent: contentState
  });
};

export const cleanUUID = uuid => uuid.replace(/-/g, '');
