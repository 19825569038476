/* eslint-disable no-shadow */

/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable default-case */

/* eslint-disable no-case-declarations */
import * as Y from 'yjs';
import {
  getSharedTeams,
  getSharedUsersEmail,
  getSortedUsers,
  shouldScrollToNextUser,
  shouldScrollToPrevUser
} from 'helpers';
import isHotkey from 'is-hotkey';
import PropTypes from 'prop-types';
import randomColor from 'randomcolor';
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import stylePropType from 'react-style-proptype';
import { Editor, Range, Transforms, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { ReactEditor, withReact } from 'slate-react';
import { useCursors, withCursor, withYjs } from 'slate-yjs';

import {
  clearRegeneratedSlateNote,
  setRegeneratedSlateNote
} from 'actions/notes';

import useFeature from 'hooks/useFeature';

import {
  useAcceptNotePreviewMutation,
  useGetMeetingAssociatedTemplateQuery,
  useRegenerateMeetingNotesMutation
} from 'services/meetings';
import { useGetFeaturePlansQuery } from 'services/subscription';
import {
  useCreateManualTicketMutation,
  useGetTicketingTaskPreferenceQuery
} from 'services/ticketing';

import PurchaseLicenseBanner from 'components/Account/Billing/PurchaseLicenseBanner';
import ButtonUnstyled from 'components/Common/ButtonUnstyled';
import NotificationBanner from 'components/Common/NotificationBanner';
import Spinner from 'components/Common/Spinner';
import Header from 'components/Notes/Slate/Elements/Header';
import Link from 'components/Notes/Slate/Elements/Link';
import Paragraph, {
  onKeyDown as onKeyDownParagraph
} from 'components/Notes/Slate/Elements/Paragraph';
import Toolbar from 'components/Notes/Slate/Toolbar';
import ClickupIntegrationManualCreateDialog from 'components/Tasks/Clickup/Manual/CreateDialog';
import HubspotTasksManualCreateDialog from 'components/Tasks/Hubspot/Manual/CreateDialog';
import {
  errorNotification,
  successNotification
} from 'components/ToastNotifications';
import {
  toastMessageInfo,
  toastMessageSuccess
} from 'components/ToastNotifications/messageToasts';

import L10n from 'helpers/L10n';
import Licenses from 'helpers/Licenses';
import { BLOCK_KEY, HIGHLIGHT_NOTES_TITLE } from 'helpers/SlateDataHelper';
import SlateHelper from 'helpers/SlateHelper';
import { onKeyUpUtils } from 'helpers/SlateHelper/onKeyUpUtils';
import env from 'helpers/env';
import { agendaFinderRegex } from 'helpers/findAgendaRegex';
import { L10nTemplates } from 'helpers/l10n/L10nTemplates';
import { isMeetingSharable } from 'helpers/meetingsData';
import { sanitizeGeneratedNote } from 'helpers/notes';

import { ReactComponent as ClickupIconInstant } from 'images/clickup_create_instant.svg';
import { ReactComponent as ClickupIconNew } from 'images/clickup_create_new.svg';
import { ReactComponent as HubspotIcon } from 'images/ic_hubspot_sprocket.svg';

import Tokens from 'styles/tokens';

import EditorFrame from './EditorFrame';
import Mention from './Elements/Mention';
import FloatingMenu from './FloatingMenu';
import NotesMentionDropdown from './MentionDropdown';
import withList from './Plugins/List/withList';
import Transcript from './Plugins/Transcript/Transcript';
import { TYPE_BOOKMARK, TYPE_TRANSCRIPT } from './Plugins/Transcript/types';
import {
  TYPE_HEADER1,
  TYPE_HEADER2,
  TYPE_HEADER3,
  TYPE_PARAGRAPH
} from './Plugins/Types';
import withAvoma from './Plugins/withAvoma';
import withLink, { TYPE_LINK } from './Plugins/withLink';
import withMarkdown from './Plugins/withMarkdown';
import withMarks from './Plugins/withMarks';
import withMentions, { TYPE_MENTION } from './Plugins/withMentions';
import { WebsocketProvider } from './YWebsocket/y-websocket';

const dropDownWidth = 240;
const dropDownHeight = 245;

export const DEFAULT_SLATE = [
  {
    type: 'paragraph',
    children: [
      {
        text: ''
      }
    ]
  }
];

const CollabEditor = React.forwardRef(
  (
    {
      id,
      name,
      style,
      className,
      recordingStart,
      recordingDuration,
      transcriptionReady,
      meetingHappening,
      onDeleteTranscriptNode,
      onDeleteBookmarkNode,
      onClickTranscriptTimestamp,
      onChangeNoteValue,
      onFocus,
      onConnect,
      onDisconnect,
      onLoaded,
      defaultValue = DEFAULT_SLATE,
      collab,
      placeholder,
      readOnly,
      meeting,
      shareMeetingSuccess,
      loggedInUser,
      showCategoryPrompt,
      isPrivateNote,
      handleOnlineStatus,
      roundedToolbar,
      setMountKey,
      isTemplateFlow = false,
      mode = '',
      currentTemplate
    },
    ref
  ) => {
    const dispatch = useDispatch();

    const [value, setValue] = useState(defaultValue);
    const [categories, setCategories] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [showClickupManualDialog, setShowClickupManualDialog] =
      useState(false);
    const [showHubspotManualDialog, setShowHubspotManualDialog] =
      useState(false);

    const { email, ticketingProviders } = useSelector(state => state.user);

    const organizationId = useSelector(
      state => state.user.orgUser?.organization
    );
    const regeneratedData = useSelector(
      state =>
        state.notes?.regeneratedData?.[`${organizationId}:${meeting.uuid}`]
    );

    const isFeatureClickupIntegrationEnabled = useFeature(
      Licenses.FEATURES.clickupIntegration
    );
    const isEditingCollabNotesAllowed = useFeature(Licenses.FEATURES.recording);

    const { data: minimumRequiredPlan } = useGetFeaturePlansQuery({
      feature: Licenses.FEATURES.recording
    });

    const { data: taskPreference, refetch } =
      useGetTicketingTaskPreferenceQuery(
        {
          meetingUuid: meeting.uuid
        },
        {
          skip:
            !meeting.uuid ||
            !ticketingProviders?.clickup?.clickupIntegration ||
            !isFeatureClickupIntegrationEnabled
        }
      );

    const [createManualTicketFn] = useCreateManualTicketMutation();

    const {
      data: associatedTemplate,
      isFetching: isFetchingMeetingAssociatedTemplate,
      refetch: refetchMeetingAssociatedTemplate
    } = useGetMeetingAssociatedTemplateQuery(
      {
        meetingUuid: meeting?.uuid,
        meetingType: meeting?.meetingType?.uuid
      },
      {
        skip: !meeting.uuid
      }
    );

    const [
      generateNotesFn,
      { isLoading: isLoadingGenerateNotes, isSuccess: isSuccessGenerateNotes }
    ] = useRegenerateMeetingNotesMutation();

    const [acceptNotePreviewFn] = useAcceptNotePreviewMutation();

    const handleGenerateNotes = async () => {
      try {
        const generateNotesResponse = await generateNotesFn({
          meetingUuid: meeting.uuid,
          templateUuid: associatedTemplate?.template?.uuid
        });

        if (generateNotesResponse?.data) {
          const noteId = `${organizationId}:${meeting.uuid}`;
          const previewUuid = generateNotesResponse.data?.previewUuid;
          const slateJson = generateNotesResponse.data?.generatedNoteSlate
            ? sanitizeGeneratedNote(
                generateNotesResponse.data?.generatedNoteSlate
              )
            : null;

          if (slateJson) {
            return {
              selectedTemplateUuid: associatedTemplate?.template?.uuid,
              noteId,
              slateJson,
              previewUuid
            };
          }
        }
      } catch (error) {
        errorNotification({ message: L10nTemplates.generateNotesError });

        return null;
      }
    };

    const handleApplyTemplateToMeeting = async () => {
      const applyTemplate = async ({ noteId, slateJson, previewUuid }) => {
        await dispatch(
          setRegeneratedSlateNote({
            noteUuid: noteId,
            textSlate: slateJson
          })
        );

        const acceptResponse = await acceptNotePreviewFn({
          meetingUuid: meeting.uuid,
          previewUuid
        });

        if (acceptResponse.data) {
          successNotification({
            message: L10nTemplates.applyTemplateSuccess
          });
        }

        await refetchMeetingAssociatedTemplate();
      };

      try {
        const generatedNote = await handleGenerateNotes();

        if (!generatedNote?.slateJson) {
          errorNotification({
            message: L10nTemplates.generateNotesError
          });
          return;
        }

        await applyTemplate(generatedNote);
      } catch (error) {
        console.error('Failed to apply template:', error);
        errorNotification({
          message: L10nTemplates.applyTemplateError
        });
      }
    };

    const color = useMemo(
      () =>
        randomColor({
          luminosity: 'dark',
          format: 'rgba',
          alpha: 1
        }),
      []
    );

    const [sharedType, provider] = useMemo(() => {
      const doc = new Y.Doc();
      const sharedType = doc.getArray('content');
      const provider = new WebsocketProvider(env.nodeURI, id, doc, {
        connect: false,
        params: {
          docId: id,
          name,
          isStaging: env.name === 'staging',
          userEmail: email
        }
      });

      return [sharedType, provider];
    }, [id]);

    // Check to understand if the connection is present
    useEffect(() => {
      const readyState = provider?.ws?.readyState;
      if (readyState === 1) {
        handleOnlineStatus(true);
      } else if (readyState === 2 || readyState === 3 || readyState === 0) {
        handleOnlineStatus(false);
      }
    }, [provider?.ws?.readyState]);

    const [mouseIsDown, setMouseDown] = useState();
    const [lastMouseUp, setLastMouseUp] = useState();
    const [target, setTarget] = useState();
    const [search, setSearch] = useState('');
    const [index, setIndex] = useState(0);
    const dropDownContainerRef = useRef();
    const scrollRef = useRef();
    const entityEls = useRef({});

    const allMembers = useSelector(state => state.members.data);
    const activeUsers = useMemo(
      () => allMembers.filter(user => user.active),
      [allMembers]
    );
    const teams = useSelector(state => state.teams);

    // getting the user list filter by search string
    const users = getSortedUsers(search, activeUsers);

    const searchedTeams = Object.values(teams?.allTeams?.data || {}).filter(
      team => team.name?.toLowerCase()?.includes(search?.toLowerCase())
    );

    // Only show if the agenda has already not been added in
    const agendaAlreadyExists = useMemo(
      () =>
        value?.some(node =>
          agendaFinderRegex.test(node.children[0].text?.toLowerCase())
        ),
      [value]
    );

    const showInsertAgendaButton = !agendaAlreadyExists;

    useImperativeHandle(ref, () => ({
      insertTemplate: editor.insertTemplate,
      addSnippet: editor.addSnippet,
      removeSnippet: editor.removeSnippet,
      addBookmark: editor.addBookmark,
      setBookmarkUuid: editor.setBookmarkUuid,
      setBookmarkText: editor.setBookmarkText,
      addCopilotMessage: editor.addCopilotMessage
    }));

    const deleteTranscriptNode = element => {
      if (onDeleteTranscriptNode) {
        onDeleteTranscriptNode(element);
      }
      const path = ReactEditor.findPath(editor, element);
      editor.removeNode(path);
    };

    const deleteBookmarkNode = element => {
      if (onDeleteBookmarkNode) {
        onDeleteBookmarkNode(element);
      }
      const path = ReactEditor.findPath(editor, element);
      editor.removeNode(path);
    };

    /*
      onkeyUp event is needed for check for if user want to tag any user and show the users popup
        why is it not done in onchange?
        It is not done in onChange, becasue if first user write @d then popup will comes, its ok
        But if another user click on @d[cursor] then it shows the popup for that user as well which is wrong
        
        Why is it not done in onKeyDown
        there is different in onKeyDown and onKeyUp, if user press @, then
        onKeyDown, we will get location of cursor before the user types @,
        Example if user type @, we will get location when key downs and we want to show popup when user relases the key after
        typing @
        on change it will get correct locations with one bug


        But onKeyUp, we will get correct locations, because it eceute once the user pressDown + write Key + PressUp
        so we will get correct position
    */

    const onKeyUp = event => {
      const { selection } = editor;
      // handle space/enter hotkey presses
      onKeyUpUtils(event, editor);
      /*
        this if block solve one important issue
        Example - when user type @ , we show popup
        when user type @d we show popup

        but if user hit enter after @d [enter], then we hides the popup
        now on backspace, we do not want to open popup again

        so, if there is backspace and popup is open (is there is target it means popup is open) - 
          then execute the showing and setting target code block

        and if there is backspace and popup is not open
          then return from here, we do not want to show the popup in this case
      */
      if (event.key === 'Escape') {
        hideUsersDropDown();
        return;
      }
      if (event.key === 'Backspace' && !target) {
        return;
      }

      // mention user related code
      // if Range.isCollapsed(selection) is true then cursor start and end range is same
      // if collab is true then only run the following code
      if (selection && Range.isCollapsed(selection) && collab) {
        // getting the start location of cursor
        const [start] = Range.edges(selection);

        // getting just one before location for only @
        // if user type only @ then - before will be [start range - 1]
        const myCustomBefore = { ...start, offset: start.offset - 1 };

        // getting range using start and myCustomBefore
        // example if user type @, then range could be { anchor: { offset: 0, path [3, 0]  }, focus: { offset: 1, path: [3,0] } }, because there is only 1 character
        const myCustomRange = Editor.range(editor, myCustomBefore, start);

        // getting the text by range, so for the above range, it will be @ if user is typing @
        const myCustomrText = Editor.string(editor, myCustomRange);

        // if it is @ then show the popup and return
        if (myCustomrText === '@') {
          setTarget(myCustomRange);
          setSearch('');
          return;
        }

        // getting the word before location
        const wordBefore = Editor.before(editor, start, { unit: 'word' });

        // getting the before location of @d
        const before = wordBefore && Editor.before(editor, wordBefore);

        // getting the before range of @d
        const beforeRange = before && Editor.range(editor, before, start);

        // getting the text, in this case it will be @d
        const beforeText = beforeRange && Editor.string(editor, beforeRange);

        // checking for if text has @d in it
        const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);

        const after = Editor.after(editor, start);
        const afterRange = Editor.range(editor, start, after);
        const afterText = Editor.string(editor, afterRange);
        const afterMatch = afterText.match(/^(\s|$)/);

        // if user has types @mention then set the target to that point
        // target value is { anchor: { offset: 0, path : [], focus: { offset: 0, path: [] } } }
        // this method fires for keyup event, even if the popup is open
        // we do not want to set index to 0 if popup is open and user enter arrow down keys
        // so added a check for - search text should be different then only go inside if block
        if (beforeMatch && afterMatch && search !== beforeMatch[1]) {
          setTarget(beforeRange);
          setSearch(beforeMatch[1]);
          setIndex(0);
          if (scrollRef.current) scrollRef.current.scrollTop = 0;
        }

        // hides the dropdown if user removes @ using backspace
        if (!beforeMatch && !myCustomrText) {
          hideUsersDropDown();
        }
      } else {
        hideUsersDropDown();
      }
    };

    const onKeyDownForMention = event => {
      if (target) {
        const collectiveEntities = [...users, ...searchedTeams];
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();

            const nextIndex =
              index >= collectiveEntities.length - 1 ? 0 : index + 1;

            const shouldScrollDownToNextUser = shouldScrollToNextUser(
              nextIndex,
              entityEls.current,
              scrollRef.current
            );
            const offsetHeightOfEl = entityEls.current[nextIndex].offsetHeight;
            if (shouldScrollDownToNextUser) {
              scrollRef.current.scrollTop += offsetHeightOfEl;
            }

            // if the index is 0 then scroll to 0th index
            // example when user reaches the end of scroll and hit down key again
            if (nextIndex === 0) {
              entityEls.current[nextIndex].scrollIntoView();
            }

            setIndex(nextIndex);
            break;
          case 'ArrowUp':
            event.preventDefault();
            const prevIndex =
              index <= 0 ? collectiveEntities.length - 1 : index - 1;

            const shouldScrollUpToPrevUser = shouldScrollToPrevUser(
              prevIndex,
              entityEls.current,
              scrollRef.current
            );
            if (shouldScrollUpToPrevUser) {
              scrollRef.current.scrollTop -=
                entityEls.current[prevIndex].offsetHeight;
            }

            // if the index is last element then scroll to last element index
            // example when user at the first index and user hit up arrow key then go to last element
            if (prevIndex === collectiveEntities.length - 1) {
              entityEls.current[prevIndex].scrollIntoView();
            }

            setIndex(prevIndex);
            break;
          case 'Tab':
          case 'Enter':
            event.preventDefault();
            const isTeam = !!collectiveEntities[index]?.uuid;
            if (isTeam) {
              onSelectTeam(collectiveEntities[index]);
            } else if (collectiveEntities[index]) {
              onSelectUser(collectiveEntities[index]);
            } else {
              // If there is no user or team in the select popup that matches the text,
              // Simply hide the user dropdown and keep it as text
              hideUsersDropDown();
            }
            break;
          case 'Escape':
            event.preventDefault();
            hideUsersDropDown();
            break;
        }
      }

      if (isHotkey('space', event)) {
        hideUsersDropDown();
      }
    };

    /*
      this is needed so only taggign enter code works
      if we remove this code then on enter tagging user works fine but
      it also add one additional line which is a bug
    */
    const onKeyDownMention = event => {
      if (isHotkey('enter', event)) {
        hideUsersDropDown();
        return false;
      }
      return true;
    };

    const onMouseDown = () => {
      setMouseDown(true);
      setLastMouseUp(null);
    };

    const onMouseUp = event => {
      setMouseDown(false);
      setLastMouseUp([event.clientX, event.clientY]);
    };

    const editor = useMemo(() => {
      const keyDowns = [onKeyDownParagraph, onKeyDownMention];

      let editor;

      if (collab) {
        editor = withCursor(
          withYjs(createEditor(), sharedType),
          provider.awareness
        );
      } else {
        editor = createEditor();
      }

      return withLink(
        withMentions(
          withList(
            withMarkdown(
              withAvoma(withMarks(withReact(withHistory(editor))), keyDowns)
            ),
            [TYPE_TRANSCRIPT, TYPE_BOOKMARK],
            [TYPE_BOOKMARK]
          )
        )
      );
    }, [sharedType, provider, collab]);

    useEffect(() => {
      if (!collab) {
        provider.destroy();
        return () => null;
      }

      provider.on('status', ({ status }) => {
        if (status === 'disconnected') {
          if (onDisconnect) onDisconnect();
        }
      });
      provider.on('sync', isSynced => {
        // Wipe out slate history so that ctrl+z does not end up removing initial content
        if (isSynced) setTimeout(() => SlateHelper.clearHistory(editor), 100);
      });

      provider.awareness.setLocalState({
        name,
        color,
        alphaColor: `${color.slice(0, -2)}0.2)`
      });

      provider.connect();

      return () => {
        provider.disconnect();
      };
    }, [provider]);

    const onCategorySelected = (category, element) => {
      editor.addCategory(category, element);
    };

    const onInsertAgendaSelected = element => {
      editor.insertAgenda(element);
    };

    const onNoCategorySelected = () => {};

    useEffect(() => {
      let isMounted = true;

      const insertAndClear = async () => {
        if (regeneratedData?.textSlate) {
          await editor.insertRegeneratedNote(regeneratedData.textSlate);

          dispatch(
            clearRegeneratedSlateNote({
              noteUuid: `${organizationId}:${meeting.uuid}`
            })
          );

          if (isMounted) {
            setMountKey(new Date().toISOString());
          }
        }
      };

      insertAndClear();

      return () => {
        isMounted = false;
      };
    }, [
      dispatch,
      editor,
      meeting.uuid,
      organizationId,
      regeneratedData,
      setMountKey
    ]);

    const renderElement = elementProps => {
      const { children, element, attributes } = elementProps;

      switch (element.type) {
        case TYPE_PARAGRAPH:
          return (
            <Paragraph
              meetingHappening={meetingHappening}
              recordingStart={recordingStart}
              recordingDuration={recordingDuration}
              transcriptionReady={transcriptionReady}
              onCategorySelected={onCategorySelected}
              onNoneSelected={onNoCategorySelected}
              onClickTranscriptTimestamp={onClickTranscriptTimestamp}
              seenCategories={categories}
              element={element}
              editor={editor}
              showInsertAgendaButton={showInsertAgendaButton}
              onInsertAgendaSelected={onInsertAgendaSelected}
              placeholder={!(collab && !loaded) && placeholder}
              isCollab={collab}
              isTemplateFlow={isTemplateFlow}
              currentTemplate={currentTemplate}
              meetingUuid={meeting.uuid}
              {...attributes}
            >
              {children}
            </Paragraph>
          );
        case TYPE_HEADER1:
        case TYPE_HEADER2:
        case TYPE_HEADER3:
          return (
            <Header
              element={element}
              size={element.type}
              onCategorySelected={onCategorySelected}
              onNoneSelected={onNoCategorySelected}
              seenCategories={categories}
              showCategoryPrompt={showCategoryPrompt}
              showInsertAgendaButton={showInsertAgendaButton}
              onInsertAgendaSelected={onInsertAgendaSelected}
              isTemplateFlow={isTemplateFlow}
              mode={mode}
              currentTemplate={currentTemplate}
              meetingUuid={meeting.uuid}
              {...attributes}
            >
              {children}
            </Header>
          );
        case TYPE_TRANSCRIPT:
          return (
            <Transcript
              element={element}
              editor={editor}
              onDeleteTranscriptNode={deleteTranscriptNode}
              onClickTranscriptTimestamp={onClickTranscriptTimestamp}
              meetingHappening={meetingHappening}
              {...attributes}
            >
              {children}
            </Transcript>
          );
        case TYPE_BOOKMARK:
          return (
            <Transcript
              element={element}
              editor={editor}
              onDeleteTranscriptNode={deleteBookmarkNode}
              onClickTranscriptTimestamp={onClickTranscriptTimestamp}
              meetingHappening={meetingHappening}
              bookmark
              {...attributes}
            >
              {children}
            </Transcript>
          );
        case TYPE_MENTION:
          return <Mention {...elementProps} />;
        case TYPE_LINK:
          return <Link elementProps={elementProps} editor={editor} />;
        default:
          return null;
      }
    };

    // Since non-collab editors don't have awareness
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const cursor = editor.awareness && useCursors(editor);

    const onChange = newValue => {
      const cats = {};

      if (!editor.children) {
        return;
      }

      if (!loaded && editor.children.length > 0) {
        setLoaded(true);
        if (onLoaded) onLoaded();
        if (onConnect) onConnect();
      }

      // Get all the highlight categories including duplicates
      editor.children.forEach((bb, ii) => {
        if (bb.data && bb.data[HIGHLIGHT_NOTES_TITLE]) {
          if (!cats[bb.data[BLOCK_KEY]]) {
            cats[bb.data[BLOCK_KEY]] = [];
          }
          cats[bb.data[BLOCK_KEY]].push(ii);
        }
      });

      const toDeHighlight = [];

      // If there are duplicates, keep the on key that was there first OR the first one
      Object.keys(cats).forEach(blockKey => {
        const existingKey = cats && cats[blockKey];
        const keys = cats[blockKey];
        if (existingKey && keys.includes(existingKey)) {
          keys.forEach(kk => {
            if (kk !== existingKey) {
              toDeHighlight.push(kk);
            }
          });
          cats[blockKey] = existingKey;
        } else {
          // eslint-disable-next-line prefer-destructuring
          cats[blockKey] = keys[0];
          keys.slice(1).forEach(kk => {
            toDeHighlight.push(kk);
          });
        }
      });

      if (JSON.stringify(cats) !== JSON.stringify(categories))
        setCategories(cats);

      // Convert duplicate categories into vanilla headers
      // (must happen delated otherwise infinitel oop)
      setTimeout(() => {
        toDeHighlight.forEach(bb => {
          Transforms.setNodes(editor, { data: {} }, { at: [bb] });
        });
      }, 100);

      setValue(newValue);

      if (onChangeNoteValue) {
        onChangeNoteValue(newValue);
      }
    };

    // make it true only after postion of drop down set correctly
    const [drodownVisibility, setDrodownVisibility] = useState('hidden');

    // this useEffect is setting up the postion of dropdown inside the collab editor
    useEffect(() => {
      // if there is target value then user trying to mention user
      // so open the dropdown list
      if (target && (users.length > 0 || searchedTeams.length > 0)) {
        // editor container element
        const editorContainerEl = document.getElementById('editor-container');
        const containerRect = editorContainerEl.getBoundingClientRect();

        const el = dropDownContainerRef.current;
        const domRange = ReactEditor.toDOMRange(editor, target);

        // this is the position of where user want to mention user
        // example if user type @d then rect position is for [this location]@d and not the dropdown
        // by editor container and above postion we decide the postion of dropdown user list
        const rect = domRange.getBoundingClientRect();

        el.style.left = `${rect.left + window.pageXOffset + 90}px`;
        el.style.top = `${rect.top + window.pageYOffset + 24}px`;

        if (rect.right + dropDownWidth > containerRect.right) {
          el.style.left = `${rect.left + window.pageXOffset - 60}px`;
        }

        if (
          rect.bottom + dropDownContainerRef.current.clientHeight >
          containerRect.bottom
        ) {
          el.style.top = `${
            rect.top +
            window.pageYOffset -
            dropDownContainerRef.current.clientHeight
          }px`;
        }

        // when the position of drop down set then only show the users dropdown
        setDrodownVisibility('visible');
      }
    }, [users.length, editor, search, target]);

    const showNotification = selectedUser => {
      const { firstName, lastName, email: selectedUserEmail } = selectedUser;
      const selectedUserName = `${firstName} ${lastName}`.trim();
      let titleOfMessage = selectedUserName;
      if (!selectedUserName) titleOfMessage = selectedUserEmail;
      const sharedUsersEmail = getSharedUsersEmail(meeting);

      // show notification if mention user does not present in participant + shared list
      if (!sharedUsersEmail.includes(selectedUserEmail)) {
        const isSharable = isMeetingSharable(
          meeting,
          loggedInUser,
          activeUsers,
          teams
        );
        if (meeting?.disableSharing) return;
        // check if meeting is sharable for logged in user
        if (isSharable) {
          const title = `${titleOfMessage} will be notified`;
          const message = L10n.notes.tagAccessMessage;
          toastMessageSuccess(title, message);

          const sharedUser = {
            commonName: selectedUserName,
            email: selectedUserEmail,
            profilePic: selectedUser.profilePic
          };
          shareMeetingSuccess(meeting.uuid, [sharedUser]);
        } else {
          const title = `${titleOfMessage} does not have access to the meeting`;
          const message = L10n.notes.tagNoAccessMessage;
          toastMessageInfo(title, message);
        }
      }
    };

    const showNotificationTeam = selectedTeam => {
      const { name, uuid } = selectedTeam;

      const sharedTeams = getSharedTeams(meeting);

      // show notification if mention user does not present in participant + shared list
      if (!sharedTeams.includes(uuid)) {
        const isSharable = isMeetingSharable(
          meeting,
          loggedInUser,
          activeUsers,
          teams
        );
        if (meeting?.disableSharing) return;
        // check if meeting is sharable for logged in user
        if (isSharable) {
          const title = `Members of ${name} will be notified`;
          const message = L10n.notes.tagAccessMessageTeam;
          toastMessageSuccess(title, message);

          shareMeetingSuccess(meeting.uuid, [], [{ team: selectedTeam }]);
        } else {
          const title = `Members of ${name} do not have access to the meeting`;
          const message = L10n.notes.tagNoAccessMessage;
          toastMessageInfo(title, message);
        }
      }
    };

    // calls when user select any user from dropdown
    const onSelectUser = selectedUser => {
      const { firstName, lastName, email: selectedUserEmail } = selectedUser;
      const selectedUserName = `${firstName} ${lastName}`.trim();
      Transforms.select(editor, target);
      // insert mention from dropdown
      editor.insertMention(selectedUserName, selectedUserEmail, loggedInUser);
      // show notification
      showNotification(selectedUser);
      // hide the popup after selecting uer
      hideUsersDropDown();
    };

    // calls when user select any user from dropdown
    const onSelectTeam = selectedTeam => {
      const { name, uuid } = selectedTeam;

      Transforms.select(editor, target);
      // insert mention from dropdown
      editor.insertMention(name, uuid, loggedInUser, true);
      // show notification
      showNotificationTeam(selectedTeam);
      // hide the popup after selecting uer
      hideUsersDropDown();
    };

    const hideUsersDropDown = () => {
      setTarget(null);
      setDrodownVisibility('hidden');
      setIndex(0);
    };

    // this code handle the case when user click outside of portal/dropdown
    // it hides the dropdown
    useEffect(() => {
      // add when mounted
      document.addEventListener('mousedown', event => {
        if (
          dropDownContainerRef.current &&
          dropDownContainerRef.current.contains(event.target)
        ) {
          // inside click
          return;
        }

        hideUsersDropDown();
      });
      // return function to be called when unmounted
      return () => {
        document.removeEventListener('mousedown', () => {});
      };
    }, []);

    const createInstantClickupTask = async () => {
      successNotification({
        message: L10n.clickupIntegration.createTicketInProgress
      });
      const payload = {
        name: editor?.selection
          ? Editor.string(editor, editor.selection, {
              voids: true
            })
          : '',
        description: editor?.selection
          ? Editor.string(editor, editor.selection, {
              voids: true
            })
          : '',
        assignees_external_id: taskPreference?.assignees,
        collection: taskPreference?.collection,
        meeting_uuid: meeting.uuid,
        status: null,
        task_template_uuid: taskPreference?.template,
        due_date_option: taskPreference?.dueDateOptionType,
        due_date_days_in_future: taskPreference?.dueDateDaysInFuture,
        due_date_calendar_value: taskPreference?.dueDateCalendarValue,
        workspace_uuid: taskPreference?.team,
        ticket_type: 'instant_ticket'
      };

      const res = await createManualTicketFn({
        payload,
        providerUuid: ticketingProviders?.clickup?.uuid
      });

      if (res.data?.ticketUrl) {
        editor.insertLink(res.data.ticketUrl, payload.description);
      }

      if (res.error) {
        errorNotification({
          message: L10n.clickupIntegration.createTicketError
        });
      } else {
        successNotification({
          message: L10n.clickupIntegration.createTicketSuccess
        });
      }
    };

    const floatingMenuActions = [
      ...(ticketingProviders?.clickup?.clickupIntegration
        ? [
            {
              label: L10n.clickupIntegration.hoverButtonLabel,
              icon: ClickupIconNew,
              onClick: () => setShowClickupManualDialog(true),
              tooltip: L10n.clickupIntegration.hoverButtonTooltip,
              tooltipPosition: 'top'
            }
          ]
        : []),
      ...(ticketingProviders?.hubspot?.hubspotIntegration
        ? [
            {
              label: L10n.hubspotTasks.hoverButtonLabel,
              icon: HubspotIcon,
              onClick: () => setShowHubspotManualDialog(true),
              tooltip: L10n.hubspotTasks.hoverButtonTooltip,
              tooltipPosition: 'top'
            }
          ]
        : [])
    ];
    // check if taskPreference is empty
    if (
      ticketingProviders?.clickup?.clickupIntegration &&
      taskPreference &&
      Object.keys(taskPreference).length > 0
    ) {
      floatingMenuActions.push({
        label: L10n.clickupIntegration.instantTaskLabel,
        icon: ClickupIconInstant,
        onClick: () => createInstantClickupTask(),
        tooltip: L10n.clickupIntegration.instantTaskTooltip,
        tooltipPosition: 'right wrapped-small'
      });
    }

    const renderClickupCreateDialog = () => {
      if (showClickupManualDialog) {
        return (
          <ClickupIntegrationManualCreateDialog
            open={showClickupManualDialog}
            setOpen={setShowClickupManualDialog}
            selectedText={
              editor?.selection
                ? Editor.string(editor, editor.selection, {
                    voids: true
                  })
                : ''
            }
            meetingUuid={meeting.uuid}
            editor={editor}
            refetchPreferences={refetch}
            clickupTaskPreference={taskPreference}
          />
        );
      }
    };

    const renderHubspotCreateDialog = () => {
      if (showHubspotManualDialog) {
        return (
          <HubspotTasksManualCreateDialog
            open={showHubspotManualDialog}
            setOpen={setShowHubspotManualDialog}
            selectedText={
              editor?.selection
                ? Editor.string(editor, editor.selection, {
                    voids: true
                  })
                : ''
            }
            meetingUuid={meeting.uuid}
            editor={editor}
            refetchPreferences={refetch}
          />
        );
      }
    };

    const handleClickupToolbarButton = () => setShowClickupManualDialog(true);

    const purchaseBanner = useMemo(() => {
      if (!isEditingCollabNotesAllowed) {
        return (
          <PurchaseLicenseBanner
            className='m-2'
            msg={L10n.featureControl.collabNotesReadOnlyGating(
              minimumRequiredPlan
            )}
            feature='Collaborative Notes'
          />
        );
      }
    }, [isEditingCollabNotesAllowed, minimumRequiredPlan]);

    const renderOutdatedTemplateBanner = () => {
      if (
        loaded &&
        !isFetchingMeetingAssociatedTemplate &&
        !isSuccessGenerateNotes &&
        associatedTemplate?.outdated
      ) {
        return (
          <NotificationBanner
            className='m-2'
            type={isLoadingGenerateNotes ? 'info' : 'warning'}
            content={
              isLoadingGenerateNotes
                ? L10nTemplates.regeneratingNotes
                : L10nTemplates.outdatedTemplate
            }
            additionalAction={
              isLoadingGenerateNotes ? (
                <div className='flex items-center justify-center py-1'>
                  <Spinner type='small' />
                </div>
              ) : (
                <ButtonUnstyled
                  onClick={handleApplyTemplateToMeeting}
                  className='flex whitespace-nowrap justify-center rounded border border-yellow-darker text-yellow-darker px-2 py-1.5 bg-yellow-lightest cursor-pointer font-bold text-sm hover:bg-yellow-lightest/50'
                  disabled={isLoadingGenerateNotes}
                  noDefaultStyles
                >
                  {L10nTemplates.regenerateNotes}
                </ButtonUnstyled>
              )
            }
          />
        );
      }
    };

    return (
      <div style={{ position: 'relative' }} id='editor-div'>
        <Toolbar
          editor={editor}
          isPrivateNote={isPrivateNote}
          roundedToolbar={roundedToolbar}
          meetingUuid={meeting?.uuid}
          handleClickupToolbarButton={handleClickupToolbarButton}
          isCollab={collab}
        />

        {ticketingProviders?.clickup?.clickupIntegration && (
          <FloatingMenu
            editor={editor}
            showMenu={collab}
            actions={floatingMenuActions}
          />
        )}

        {purchaseBanner}

        {/* Show banner to regenerate notes if template is outdated */}
        {renderOutdatedTemplateBanner()}

        <EditorFrame
          editor={editor}
          value={value}
          decorate={cursor?.decorate}
          onChange={onChange}
          onFocus={onFocus}
          style={style}
          className={className}
          renderElement={renderElement}
          onKeyDownForMention={onKeyDownForMention}
          onKeyUp={onKeyUp}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        />
        {target && (users.length > 0 || searchedTeams.length > 0) && (
          <NotesMentionDropdown
            dropDownContainerRef={dropDownContainerRef}
            scrollRef={scrollRef}
            onSelectUser={onSelectUser}
            onSelectTeam={onSelectTeam}
            users={users}
            teams={searchedTeams}
            style={{ visibility: drodownVisibility }}
            width={dropDownWidth}
            height={dropDownHeight}
            currentRowIndex={index}
            entityEls={entityEls}
          />
        )}
        {collab && !loaded && (
          <div style={styles.spinnerContainer}>
            <Spinner style={{ margin: '0 auto' }} />
          </div>
        )}
        {renderClickupCreateDialog()}
        {renderHubspotCreateDialog()}
      </div>
    );
  }
);

CollabEditor.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  style: stylePropType,
  className: PropTypes.string,
  meetingStart: PropTypes.string,
  meetingHappening: PropTypes.bool,
  defaultValue: PropTypes.string,
  onDeleteTranscriptNode: PropTypes.func,
  onDeleteBookmarkNode: PropTypes.func,
  onClickTranscriptTimestamp: PropTypes.func,
  onChangeNoteValue: PropTypes.func,
  onFocus: PropTypes.func,
  onConnect: PropTypes.func,
  onDisconnect: PropTypes.func,
  onLoaded: PropTypes.func,
  collab: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  transcriptionReady: PropTypes.bool,
  recordingStart: PropTypes.number,
  recordingDuration: PropTypes.number,
  activeUsers: PropTypes.array.isRequired,
  meeting: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  teams: PropTypes.array.isRequired,
  shareMeetingSuccess: PropTypes.func.isRequired,
  showCategoryPrompt: PropTypes.bool,
  isPrivateNote: PropTypes.bool,
  handleOnlineStatus: PropTypes.func,
  setMountKey: PropTypes.func,
  roundedToolbar: PropTypes.bool,
  isTemplateFlow: PropTypes.bool,
  mode: PropTypes.string,
  currentTemplate: PropTypes.object
};

CollabEditor.defaultProps = {
  readOnly: false,
  showCategoryPrompt: true
};

const styles = {
  spinnerContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconStyle: {
    width: '20px',
    height: '20px',
    color: Tokens.colors.silver
  }
};

CollabEditor.displayName = 'CollabEditor';

export default CollabEditor;
