import { UsersActions } from 'actions/actionTypes';

const initialState = {
  // users data
  count: 0,
  next: null,
  previous: null,
  data: {},
  fetching: false,
  error: null,
  updating: 0,
  inviting: false,
  inviteError: null,
  searchUsersText: '',
  memberPageFiltering: {},
  minified: {},
  deletingInvitedUser: false
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case UsersActions.FETCH_USERS_BY_SEARCH_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null
      };
    case UsersActions.FETCH_USERS_BY_SEARCH_SUCCESS:
      return {
        ...state,
        searched: {
          fetching: false,
          data: action.response
        },
        data: {
          ...state.data,
          ...action.data
        }
      };
    case UsersActions.FETCH_USERS_BY_SEARCH_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    case UsersActions.FETCH_ORGANIZATION_USERS_REQUEST: {
      const hasUsers = Boolean(state.organizationUsers);

      if (hasUsers && !action.query) {
        return {
          ...state,
          organizationUsers: {
            ...state.organizationUsers,
            fetching: true
          }
        };
      }

      return {
        ...state,
        organizationUsers: {
          fetching: true
        }
      };
    }
    case UsersActions.FETCH_ORGANIZATION_USERS_SUCCESS: {
      const hasUsers = Boolean(state.organizationUsers.data);

      if (hasUsers && !action.query) {
        return {
          ...state,
          organizationUsers: {
            ...state.organizationUsers,
            fetching: false,
            data: {
              ...state.organizationUsers.data,
              ...action.response,
              results: [
                ...state.organizationUsers.data.results,
                ...action.response.results
              ]
            }
          },
          data: {
            ...state.data,
            ...action.data
          }
        };
      }
      return {
        ...state,
        organizationUsers: {
          fetching: false,
          data: action.response
        },
        data: {
          ...state.data,
          ...action.data
        }
      };
    }
    case UsersActions.FETCH_ORGANIZATION_USERS_FAILURE:
      return {
        ...state,
        organizationUsers: {
          fetching: false,
          error: true
        }
      };

    case UsersActions.FETCH_ACTIVE_USERS_REQUEST: {
      const hasActiveUsers = Boolean(state.active);

      if (hasActiveUsers && !action.query) {
        return {
          ...state,
          active: {
            ...state.active,
            fetching: true
          }
        };
      }

      return {
        ...state,
        active: {
          fetching: true
        }
      };
    }
    case UsersActions.FETCH_ACTIVE_USERS_SUCCESS: {
      const hasActiveUsers = Boolean(state.active.data);

      if (hasActiveUsers && !action.query) {
        return {
          ...state,
          active: {
            ...state.active,
            fetching: false,
            data: {
              ...state.active.data,
              ...action.response,
              results: [
                ...state.active.data.results,
                ...action.response.results
              ]
            }
          },
          data: {
            ...state.data,
            ...action.data
          }
        };
      }
      return {
        ...state,
        active: {
          fetching: false,
          data: action.response
        },
        data: {
          ...state.data,
          ...action.data
        }
      };
    }
    case UsersActions.FETCH_ACTIVE_USERS_FAILURE:
      return {
        ...state,
        active: {
          fetching: false,
          error: true
        }
      };

    case UsersActions.FETCH_ACTIVE_USERS_MINIFIED_REQUEST: {
      const hasActiveUsers = Boolean(state.minified.active);

      if (hasActiveUsers && !action.query) {
        return {
          ...state,
          minified: {
            ...state.minified,
            active: {
              ...state.minified.active,
              requestTime: action.requestTime,
              fetching: true
            }
          }
        };
      }

      return {
        ...state,
        minified: {
          ...state.minified,
          active: {
            fetching: true,
            requestTime: action.requestTime
          }
        }
      };
    }
    case UsersActions.FETCH_USERS_MINIFIED_UPDATE_DATA_SUCCESS: {
      return {
        ...state,
        minified: {
          ...state.minified,
          data: {
            ...state.minified.data,
            ...action.data
          }
        }
      };
    }
    case UsersActions.FETCH_ACTIVE_USERS_MINIFIED_SUCCESS: {
      const hasActiveUsers = Boolean(state.minified.active?.data);

      if (hasActiveUsers && !action.query) {
        return {
          ...state,
          minified: {
            ...state.minified,
            active: {
              ...state.minified.active,
              requestTime: state.minified.active.requestTime,
              fetching: false,
              data: {
                ...state.minified.active.data,
                ...action.response,
                results: [
                  ...(action.forceRefetch
                    ? []
                    : state.minified.active.data.results),
                  ...action.response.results
                ]
              }
            },
            data: {
              ...state.minified.data,
              ...action.data
            }
          }
        };
      }
      return {
        ...state,
        minified: {
          ...state.minified,
          active: {
            fetching: false,
            requestTime: state.minified.active.requestTime,
            data: action.response
          },
          data: {
            ...state.minified.data,
            ...action.data
          }
        }
      };
    }
    case UsersActions.FETCH_ACTIVE_USERS_MINIFIED_FAILURE:
      return {
        ...state,
        minified: {
          ...state.minified,
          active: {
            fetching: false,
            error: true
          }
        }
      };

    case UsersActions.FETCH_INACTIVE_USERS_REQUEST: {
      const hasInactiveUsers = Boolean(state.inactive);

      if (hasInactiveUsers && !action.query) {
        return {
          ...state,
          inactive: {
            ...state.inactive,
            fetching: true
          }
        };
      }

      return {
        ...state,
        inactive: {
          fetching: true
        }
      };
    }
    case UsersActions.FETCH_INACTIVE_USERS_SUCCESS: {
      const hasInactiveUsers = Boolean(state.inactive.data);

      if (hasInactiveUsers && !action.query) {
        return {
          ...state,
          inactive: {
            ...state.inactive,
            fetching: false,
            data: {
              ...state.inactive.data,
              ...action.response,
              results: [
                ...state.inactive.data.results,
                ...action.response.results
              ]
            }
          },
          data: {
            ...state.data,
            ...action.data
          }
        };
      }
      return {
        ...state,
        inactive: {
          fetching: false,
          data: action.response
        },
        data: {
          ...state.data,
          ...action.data
        }
      };
    }
    case UsersActions.FETCH_INACTIVE_USERS_FAILURE:
      return {
        ...state,
        inactive: {
          fetching: false,
          error: true
        }
      };

    case UsersActions.FETCH_INACTIVE_USERS_MINIFIED_REQUEST: {
      const hasInactiveUsers = Boolean(state.minified.inactive);

      if (hasInactiveUsers && !action.query) {
        return {
          ...state,
          minified: {
            ...state.minified,
            inactive: {
              ...state.minified.inactive,
              requestTime: action.requestTime,
              fetching: true
            }
          }
        };
      }

      return {
        ...state,
        minified: {
          ...state.minified,
          inactive: {
            fetching: true,
            requestTime: action.requestTime
          }
        }
      };
    }

    case UsersActions.FETCH_INACTIVE_USERS_MINIFIED_SUCCESS: {
      const hasInactiveUsers = Boolean(state.minified.inactive?.data);

      if (hasInactiveUsers && !action.query) {
        return {
          ...state,
          minified: {
            ...state.minified,
            inactive: {
              ...state.minified.inactive,
              fetching: false,
              requestTime: state.minified.inactive.requestTime,
              data: {
                ...state.minified.inactive.data,
                ...action.response,
                results: [
                  ...state.minified.inactive.data.results,
                  ...action.response.results
                ]
              }
            },
            data: {
              ...state.minified.data,
              ...action.data
            }
          }
        };
      }
      return {
        ...state,
        minified: {
          ...state.minified,
          inactive: {
            fetching: false,
            requestTime: state.minified.inactive.requestTime,
            data: action.response
          },
          data: {
            ...state.minified.data,
            ...action.data
          }
        }
      };
    }
    case UsersActions.FETCH_INACTIVE_USERS_MINIFIED_FAILURE:
      return {
        ...state,
        minified: {
          ...state.minified,
          inactive: {
            fetching: false,
            error: true
          }
        }
      };

    case UsersActions.FETCH_INVITED_USERS_REQUEST: {
      const hasInvitedUsers = Boolean(state.invited);

      if (hasInvitedUsers && !action.query) {
        return {
          ...state,
          invited: {
            ...state.invited,
            fetching: true
          }
        };
      }

      return {
        ...state,
        invited: {
          fetching: true
        }
      };
    }
    case UsersActions.FETCH_INVITED_USERS_SUCCESS: {
      const hasInvitedUsers = Boolean(state.invited.data);

      if (hasInvitedUsers && !action.query) {
        return {
          ...state,
          invited: {
            ...state.invited,
            fetching: false,
            data: {
              ...state.invited.data,
              ...action.response,
              results: [
                ...state.invited.data.results,
                ...action.response.results
              ]
            }
          },
          data: {
            ...state.data,
            ...action.data
          }
        };
      }
      return {
        ...state,
        invited: {
          fetching: false,
          data: action.response
        },
        data: {
          ...state.data,
          ...action.data
        }
      };
    }
    case UsersActions.FETCH_INVITED_USERS_FAILURE:
      return {
        ...state,
        invited: {
          fetching: false,
          error: true
        }
      };

    case UsersActions.FETCH_INVITED_USERS_MINIFIED_REQUEST: {
      const hasInvitedUsers = Boolean(state.minified.invited);

      if (hasInvitedUsers && !action.query) {
        return {
          ...state,
          minified: {
            ...state.minified,
            invited: {
              ...state.minified.invited,
              fetching: true
            }
          }
        };
      }

      return {
        ...state,
        minified: {
          ...state.minified,
          invited: {
            fetching: true
          }
        }
      };
    }

    case UsersActions.FETCH_INVITED_USERS_MINIFIED_SUCCESS: {
      const hasInvitedUsers = Boolean(state.minified.invited?.data);

      if (hasInvitedUsers && !action.query) {
        return {
          ...state,
          minified: {
            ...state.minified,
            invited: {
              ...state.minified.invited,
              fetching: false,
              data: {
                ...state.minified.invited.data,
                ...action.response,
                results: [
                  ...state.minified.invited.data.results,
                  ...action.response.results
                ]
              }
            },
            data: {
              ...state.minified.data,
              ...action.data
            }
          }
        };
      }
      return {
        ...state,
        minified: {
          ...state.minified,
          invited: {
            fetching: false,
            data: action.response
          },
          data: {
            ...state.minified.data,
            ...action.data
          }
        }
      };
    }
    case UsersActions.FETCH_INVITED_USERS_MINIFIED_FAILURE:
      return {
        ...state,
        minified: {
          ...state.minified,
          invited: {
            fetching: false,
            error: true
          }
        }
      };

    case UsersActions.UPDATE_USER_REQUEST:
      return {
        ...state,
        updating: state.updating + 1
      };
    case UsersActions.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        updating: state.updating - 1,
        data: {
          ...state.data,
          [action.userUuid]: {
            ...state.data[action.userUuid],
            ...action.user
          }
        }
      };
    }
    case UsersActions.REACTIVATE_USER_REQUEST:
      return {
        ...state,
        updating: state.updating + 1
      };
    case UsersActions.REACTIVATE_USER_SUCCESS: {
      const results = state.inactive.data.results.filter(
        uuid => uuid !== action.userUuid
      );
      return {
        ...state,
        updating: state.updating - 1,
        inactive: {
          ...state.inactive,
          data: {
            count: state.inactive.data.count - 1,
            results
          }
        }
      };
    }
    case UsersActions.REACTIVATE_USER_FAILURE:
      return {
        ...state,
        updating: state.updating - 1
      };
    case UsersActions.DELETE_USER_REQUEST:
      return {
        ...state,
        updating: state.updating + 1
      };
    case UsersActions.DELETE_USER_SUCCESS: {
      const results = state.active.data.results.filter(
        uuid => uuid !== action.userUuid
      );

      return {
        ...state,
        active: {
          ...state.active,
          data: {
            count: state.active.data.count - 1,
            results
          }
        }
      };
    }
    case UsersActions.DELETE_USER_FAILURE:
      return {
        ...state,
        updating: state.updating - 1,
        error: action.error
      };

    case UsersActions.DELETE_INVITED_USER_REQUEST:
      return {
        ...state,
        deletingInvitedUser: true
      };
    case UsersActions.DELETE_INVITED_USER_SUCCESS: {
      const results = state.invited.data.results.filter(
        uuid => uuid !== action.userUuid
      );

      return {
        ...state,
        deletingInvitedUser: false,
        invited: {
          ...state.invited,
          data: {
            count: state.invited.data.count - 1,
            results
          }
        }
      };
    }
    case UsersActions.DELETE_INVITED_USER_FAILURE:
      return {
        ...state,
        deletingInvitedUser: false,
        error: action.error
      };

    case UsersActions.INVITE_USER_REQUEST:
      return {
        ...state,
        inviting: state.inviting + 1,
        inviteError: null
      };
    case UsersActions.INVITE_USER_SUCCESS: {
      const hasInvitedUsers = Boolean(state.invited?.data);

      if (hasInvitedUsers) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.userUuid]: {
              ...action.results
            }
          },
          invited: {
            ...state.invited,
            data: {
              ...state.invited.data,
              count: state.invited.data.count + 1,
              results: [...state.invited.data.results, action.userUuid]
            }
          },
          inviting: false,
          inviteError: null
        };
      }

      return {
        ...state,
        inviting: false,
        inviteError: null
      };
    }

    case UsersActions.INVITE_USER_FAILURE: {
      return {
        ...state,
        inviting: false,
        inviteError: action.error
      };
    }
    case UsersActions.RESEND_INVITE_USER_REQUEST:
      return {
        ...state,
        inviting: state.inviting + 1,
        inviteError: null
      };
    case UsersActions.RESEND_INVITE_USER_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.userUuid]: {
            ...action.results
          }
        },
        inviting: false,
        inviteError: null
      };
    }
    case UsersActions.RESEND_INVITE_USER_FAILURE: {
      return {
        ...state,
        inviting: false,
        inviteError: action.error
      };
    }
    case UsersActions.SET_USERS_SEARCH_TEXT:
      return {
        ...state,
        searchUsersText: action.searchUsersText
      };
    case UsersActions.SET_MEMBER_PAGE_FILTERING:
      return {
        ...state,
        memberPageFiltering: {
          ...state.memberPageFiltering,
          ...action.memberPageFiltering
        }
      };
    case UsersActions.RESET_MEMBER_PAGE_FILTERING:
      return {
        ...state,
        memberPageFiltering: {}
      };

    case UsersActions.UPDATE_USER_SETTINGS_PREFERENCE_SUCCESS: {
      const [updatedUser] = Object.values(state.data).filter(
        user => user.settingDetails.uuid === action.userUuid
      );

      const updatedUserUuid = updatedUser.uuid;

      return {
        ...state,
        data: {
          ...state.data,
          [updatedUserUuid]: {
            ...state.data[updatedUserUuid],
            settingDetails: {
              ...state.data[updatedUserUuid].settingDetails,
              ...action.response
            }
          }
        }
      };
    }

    case UsersActions.UPDATE_USER_SETTINGS_PREFERENCE_FAILURE:
    case UsersActions.UPDATE_USER_SETTINGS_PRIVACY_PREFERENCE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case UsersActions.UPDATE_USER_SETTINGS_PRIVACY_PREFERENCE_SUCCESS: {
      const [updatedUser] = Object.values(state.data).filter(
        user => user.settingDetails.uuid === action.userUuid
      );

      const updatedUserUuid = updatedUser.uuid;

      return {
        ...state,
        data: {
          ...state.data,
          [updatedUserUuid]: {
            ...state.data[updatedUserUuid],
            settingDetails: {
              ...state.data[updatedUserUuid].settingDetails,
              meetingPrivacySettings: {
                ...state.data[updatedUserUuid].settingDetails.meetingPrivacySettings,
                ...action.response?.meetingPrivacySettings
              }
            }
          }
        }
      };
    }

    default:
      return state;

  }
};

export default users;
