/* eslint-disable no-empty */
import { Editor } from 'slate';
import { ReactEditor } from 'slate-react';

/**
 * See {@link Editor.parent}.
 * Returns undefined if there is no parent.
 */
export const getParent = (editor, at, options) => {
  try {
    return Editor.parent(editor, at, options);
  } catch (err) {}
};

/**
 * See {@link Editor.parent}.
 * Returns undefined if there is no parent.
 */
export const getParentForNode = (editor, node, options) => {
  try {
    const path = ReactEditor.findPath(editor, node);
    return Editor.parent(editor, path, options);
  } catch (err) {}
};
