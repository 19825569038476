const highlightSelection = (text, filter, styles = {}) => {
  if (!filter) {
    return text;
  }

  return text.split(new RegExp(`(${filter})`, 'i')).map((segment, i) =>
    i % 2 ? (
      <mark key={i} style={styles}>
        {segment}
      </mark>
    ) : (
      segment
    )
  );
};

export default highlightSelection;
