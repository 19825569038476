import React from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import AvomaPopover from 'components/Common/AvomaPopover';
import Tokens from 'styles/tokens';
import OutsideClickHandler from 'react-outside-click-handler';
import AvomaButton from 'components/Common/AvomaButton';
import isEmpty from 'lodash/isEmpty';
import ButtonUnstyled from 'components/Common/ButtonUnstyled';
import crossIcon from 'images/ic_cross.svg';
import AvomaIcon from 'components/Common/AvomaIcon';
import LinkInput from '../../LinkInput';

const LinkEditPopover = ({
  isCreate = false,
  url = '',
  title = '',
  onOutsideClick,
  onClickRemove,
  onClickCreate,
  style,
  handleLinkChange = undefined,
  handleTitleChange = undefined,
  isTextSelectedForLink = false,
  updateDisabled = false
}) => (
  <OutsideClickHandler onOutsideClick={onOutsideClick}>
    <AvomaPopover
      wrapperStyle={{
        border: `1px solid ${Tokens.colors.gainsboro}`,
        marginTop: 0,
        ...Tokens.shadows.medium,
        ...style
      }}
      width={250}
      style={{ padding: Tokens.spacing.two }}
    >
      <ButtonUnstyled style={styles.closeBtn} onClick={onOutsideClick}>
        <AvomaIcon icon={crossIcon} extraSmall />
      </ButtonUnstyled>
      <section style={{ ...styles.section, marginBottom: Tokens.spacing.two }}>
        <p style={styles.title}>URL</p>
        <LinkInput inputValue={url} handleChange={handleLinkChange} />
      </section>
      {!isTextSelectedForLink && (
        <section style={styles.section}>
          <p style={styles.title}>Title</p>
          <LinkInput
            inputValue={title}
            placeholder={url || 'Enter link title'}
            handleChange={handleTitleChange}
          />
        </section>
      )}
      <div style={styles.buttonContainer}>
        {!isCreate ? (
          <div style={styles.alignCenter}>
            <AvomaButton
              destructive
              secondary
              small
              style={styles.button}
              key='remove-link'
              onClick={onClickRemove}
              label='Remove Link'
            />{' '}
            <AvomaButton
              style={{ ...styles.button, marginLeft: Tokens.spacing.one }}
              key='update-link'
              onClick={onClickCreate}
              label='Update'
              small
              disabled={isEmpty(url) || updateDisabled}
            />
          </div>
        ) : (
          <div style={styles.alignCenter}>
            <AvomaButton
              style={styles.button}
              key='cancel-link'
              onClick={onOutsideClick}
              label='Cancel'
              small
              black
            />
            <AvomaButton
              style={{ ...styles.button, marginLeft: Tokens.spacing.one }}
              key='save-link'
              onClick={onClickCreate}
              label='Insert Link'
              small
              disabled={isEmpty(url)}
            />
          </div>
        )}
      </div>
    </AvomaPopover>
  </OutsideClickHandler>
);

const styles = {
  section: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    ...Tokens.type.bodyXS,
    color: Tokens.colors.gunmetal,
    marginBottom: Tokens.spacing.half,
    fontWeight: 600
  },
  button: {
    textAlign: 'center',
    padding: Tokens.spacing.half,
    ...Tokens.type.bodyXS,
    fontWeight: 600,
    width: '100%'
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonContainer: {
    marginTop: Tokens.spacing.two
  },
  closeBtn: {
    position: 'absolute',
    right: Tokens.spacing.onehalf,
    top: Tokens.spacing.onehalf
  }
};

LinkEditPopover.propTypes = {
  isCreate: PropTypes.bool,
  url: PropTypes.string,
  title: PropTypes.string,
  onOutsideClick: PropTypes.func,
  onClickRemove: PropTypes.func,
  onClickCreate: PropTypes.func,
  style: stylePropType,
  handleLinkChange: PropTypes.func,
  handleTitleChange: PropTypes.func,
  isTextSelectedForLink: PropTypes.bool,
  updateDisabled: PropTypes.bool
};

export default LinkEditPopover;
