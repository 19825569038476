/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import stylePropType from 'react-style-proptype';

import Tokens from 'styles/tokens';

import * as Popover from '@radix-ui/react-popover';
import AvomaTooltip from './AvomaTooltip';

const AvomaPopoverControlled = ({
  align,
  position,
  dropdownTrigger,
  wrapperStyle,
  triggerStyle,
  children,
  contentProps,
  triggerProps,
  controlled,
  open,
  setOpen,
  closeCleanupHandler,
  triggerContainerProps,
  contentClasses,
  triggerClasses,
  portalContainer = document.body,
  triggerTooltipProps,
  sideOffset = 6,
  onOpenChange
}) => {
  const [internalPopoverState, setInternalPopoverState] = useState(false);
  const popoverState = controlled ? open : internalPopoverState;
  const setPopoverState = controlled ? setOpen : setInternalPopoverState;

  return (
    <Popover.Root
      open={popoverState}
      onOpenChange={newOpen => {
        if (popoverState && typeof closeCleanupHandler === 'function') {
          closeCleanupHandler();
        }
        if (onOpenChange) {
          onOpenChange(newOpen);
        }
        setPopoverState(!popoverState);
      }}
    >
      {dropdownTrigger && (
        <AvomaTooltip {...triggerTooltipProps}>
          <Popover.Trigger
            asChild
            style={{ ...triggerStyle }}
            {...triggerProps}
          >
            <div className={triggerClasses} {...triggerContainerProps}>
              {dropdownTrigger()}
            </div>
          </Popover.Trigger>
        </AvomaTooltip>
      )}
      {children && (
        <Popover.Portal container={portalContainer}>
          <Popover.Content
            align={align}
            side={position}
            sideOffset={sideOffset}
            collisionPadding={8}
            className={contentClasses}
            style={{
              ...(children && styles.dropdownWrapper),
              ...wrapperStyle
            }}
            {...contentProps}
          >
            {children}
          </Popover.Content>
        </Popover.Portal>
      )}
    </Popover.Root>
  );
};

const styles = {
  dropdownWrapper: {
    ...Tokens.shadows.medium,
    backgroundColor: Tokens.colors.white,
    border: `1px solid ${Tokens.colors.gainsboro}`,
    borderRadius: Tokens.spacing.borderRadius,
    padding: Tokens.spacing.two,
    zIndex: 999999
  }
};

AvomaPopoverControlled.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  align: PropTypes.string,
  style: PropTypes,
  wrapperStyle: stylePropType,
  triggerStyle: stylePropType,
  width: PropTypes.number,
  mobileFullWidth: PropTypes.bool,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  isRightAlign: PropTypes.bool,
  dropdownTrigger: PropTypes.func,
  portalProps: PropTypes.object,
  contentProps: PropTypes.object,
  triggerProps: PropTypes.object,
  controlled: PropTypes.bool,
  closeCleanupHandler: PropTypes.func,
  triggerContainerProps: PropTypes.object,
  contentClasses: PropTypes.string,
  triggerClasses: PropTypes.string,
  portalContainer: PropTypes.element,
  triggerTooltipProps: PropTypes.shape({
    tooltip: PropTypes.string.isRequired,
    tooltipPosition: PropTypes.string
  }),
  sideOffset: PropTypes.number,
  onOpenChange: PropTypes.func
};

AvomaPopoverControlled.defaultProps = {
  children: null,
  position: 'bottom',
  isRightAlign: false,
  align: 'start'
};

export default AvomaPopoverControlled;
