import camelize from 'camelize';
import decamelizeKeys from 'decamelize-keys';
import { convertToRaw } from 'draft-js';
import { EventTypes } from 'redux-segment';

import { MachineNoteActions, NotesActions } from 'actions/actionTypes';

import debounceAction from 'helpers/debounceAction';
import env from 'helpers/env';
import getLastUrlParam from 'helpers/getLastUrlParam';
import { get, patch, post } from 'helpers/http';

export const fetchNote = noteUrl => async (dispatch, getState) => {
  if (!noteUrl) throw Error('[avoma] no noteUrl provided');
  const noteUuid = getLastUrlParam(noteUrl);
  dispatch({ type: 'FETCH_NOTE_REQUEST', noteUuid });
  if (getState().app.noteAndTranscriptLinkedAt) {
    dispatch({ type: 'CLEAR_NOTE_AND_TRANSCRIPT_LINKED' });
  }

  try {
    const note = await get(noteUrl);

    dispatch({
      type: 'FETCH_NOTE_SUCCESS',
      note
    });
  } catch (err) {
    dispatch({
      type: 'FETCH_NOTE_FAILURE',
      error: err.message,
      noteUuid
    });
    throw err;
  }
};

export const updateNote =
  ({ content, noteId }) =>
  (dispatch, getState) => {
    if (!noteId) throw Error('[avoma] no noteId provided to saveNote');
    const { userModified } = getState().notes.data[noteId] || {};
    if (getState().app.noteAndTranscriptLinkedAt) {
      dispatch({ type: 'CLEAR_NOTE_AND_TRANSCRIPT_LINKED' });
    }

    if (!userModified) {
      dispatch({
        type: 'MEETING_NOTES_UPDATED',
        meta: {
          analytics: EventTypes.track
        }
      });
    }
    dispatch({ type: 'UPDATE_NOTE_REQUEST', noteId });
    patch(`${env.avomaApiUrl}/api/v1/notes/${noteId}/`, {
      text: content,
      uuid: noteId
    })
      .then(note => {
        dispatch({
          type: 'UPDATE_NOTE_SUCCESS',
          note
        });
      })
      .catch(err => {
        dispatch({
          type: 'UPDATE_NOTE_FAILURE',
          error: err.message,
          noteId
        });
        throw err;
      });
  };
const debouncedUpdateNote = debounceAction(updateNote, 1000);

// TODO: deprecate
export const setNoteEditorState =
  (noteId, editorState, dontSave) => dispatch => {
    if (!noteId) throw Error('[avoma] no noteId provided');
    if (!dontSave) {
      dispatch(
        debouncedUpdateNote({
          noteId,
          content: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        })
      );
    }

    dispatch({
      type: 'SET_NOTE_EDITOR_STATE',
      noteId,
      editorState
    });
  };

export const updateMachineNote =
  (transcriptionId, uuid, userDeleted) => async dispatch => {
    dispatch({
      type: MachineNoteActions.update.request,
      transcriptionId,
      uuid,
      userDeleted
    });
    try {
      const result = await patch(
        `${env.avomaApiUrl}/api/v1/machinenote/${uuid}/`,
        {
          user_deleted: userDeleted
        }
      );
      const camelizedResult = camelize(result);
      dispatch({
        type: MachineNoteActions.update.success,
        transcriptionId,
        uuid,
        userDeleted: camelizedResult.userDeleted
      });
    } catch (error) {
      dispatch({
        type: MachineNoteActions.update.failure,
        transcriptionId,
        uuid,
        userDeleted: !userDeleted
      });
    }
  };

export const updateMachineSummary =
  (transcriptionId, payload) => (dispatch, getState) => {
    dispatch({
      type: 'MACHINE_SUMMARY_UPDATE_REQUEST',
      transcriptionId,
      machinesummary: payload
    });
    patch(`${env.avomaApiUrl}/api/v1/machinesummary/${transcriptionId}/`, {
      text: JSON.stringify(payload.map(obj => decamelizeKeys(obj)))
    }).catch(() => {
      dispatch({
        type: 'MACHINE_SUMMARY_UPDATE_FAILED',
        transcriptionId,
        machinesummary:
          getState().transcriptions.data[transcriptionId].machinesummary
      });
    });
  };

export const updateSlateNote =
  ({ textSlate, noteUuid }) =>
  async (dispatch, getState) => {
    if (!noteUuid) {
      throw Error('[avoma] no noteUuid provided to updateSlateNote');
    }

    const { userModified } = getState().notes.data[noteUuid] || {};
    if (!userModified) {
      dispatch({
        type: NotesActions.UPDATE_SLATE_NOTE_REQUEST,
        meta: {
          analytics: EventTypes.track
        }
      });
    }
    dispatch({
      type: NotesActions.UPDATE_SLATE_NOTE_REQUEST,
      noteUuid
    });

    try {
      const note = await patch(`${env.avomaApiUrl}/api/v1/notes/${noteUuid}/`, {
        text_slate: JSON.stringify(textSlate),
        uuid: noteUuid
      });
      dispatch({
        type: NotesActions.UPDATE_SLATE_NOTE_SUCCESS,
        note
      });
    } catch (err) {
      dispatch({
        type: NotesActions.UPDATE_SLATE_NOTE_FAILURE,
        error: err.message,
        noteUuid
      });
      throw err;
    }
  };

export const debouncedUpdateSlateNote = debounceAction(updateSlateNote, 2000);

export const setNoteCursorLocation =
  ({ point, noteUuid }) =>
  dispatch => {
    dispatch({
      type: NotesActions.SET_NOTE_CURSOR_LOCATION,
      noteUuid,
      point
    });
  };

export const localUpdateSlateNote =
  ({ textSlate, noteUuid, focusPoint }) =>
  dispatch => {
    dispatch({
      type: NotesActions.LOCAL_UPDATE_SLATE_NOTE,
      textSlate: JSON.stringify(textSlate),
      noteUuid,
      focusPoint
    });
  };

export const setNoteDirty =
  ({ noteUuid }) =>
  dispatch => {
    dispatch({
      type: NotesActions.SET_NOTE_DIRTY,
      noteUuid
    });
  };

export const unsetNoteDirty =
  ({ noteUuid }) =>
  dispatch => {
    dispatch({
      type: NotesActions.UNSET_NOTE_DIRTY,
      noteUuid
    });
  };

export const blurNotes = () => dispatch => {
  // Some reason Notes pane steals focus when clicking on other elements
  // so call this to steal it back if needed
  dispatch({
    type: NotesActions.BLUR_NOTES
  });
};

export const blurNotesDone = () => dispatch => {
  dispatch({
    type: NotesActions.BLUR_NOTES_DONE
  });
};

export const setShowPrivateNotes = data => dispatch => {
  dispatch({
    type: NotesActions.SET_SHOW_PRIVATE_NOTES,
    data
  });
};

export const setRegeneratedSlateNote =
  ({ noteUuid, textSlate }) =>
  dispatch => {
    dispatch({
      type: NotesActions.SET_REGENERATED_SLATE_NOTE,
      noteUuid,
      textSlate
    });
  };

export const clearRegeneratedSlateNote =
  ({ noteUuid }) =>
  dispatch => {
    dispatch({
      type: NotesActions.CLEAR_REGENERATED_SLATE_NOTE,
      noteUuid
    });
  };
