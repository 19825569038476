export const TOPIC_EVENTS = {
  TOPIC_PROMPT_ADDED: 'TOPIC_PROMPT_ADDED',
  TOPIC_KEYWORD_ADDED: 'TOPIC_TRACKERS_ADDED',
  TOPIC_PHRASE_ADDED: 'TOPIC_PHRASE_ADDED',
  CREATE_SMART_TOPIC_REQUEST: 'CREATE_SMART_TOPIC_REQUEST',
  UPDATE_SMART_TOPIC_REQUEST: 'UPDATE_SMART_TOPIC_REQUEST'
};

export const TEMPLATE_EVENTS = {
  CHANGE_TEMPLATE_CLICKED: 'CHANGE_TEMPLATE_CLICKED',
  EXISTING_TEMPLATE_VIEWED: 'EXISTING_TEMPLATE_VIEWED',
  EXISTING_TEMPLATE_APPLIED: 'EXISTING_TEMPLATE_APPLIED',
  EXISTING_TEMPLATE_UPDATED: 'EXISTING_TEMPLATE_UPDATED',
  EXISTING_TEMPLATE_EDITED: 'EXISTING_TEMPLATE_EDITED',
  NEW_TEMPLATE_CREATION_INITIATED: 'NEW_TEMPLATE_CREATION_INITIATED',
  NEW_TEMPLATE_CREATED: 'NEW_TEMPLATE_CREATED',
  NEW_TEMPLATE_EXISTING_TOPIC_SELECTED: 'NEW_TEMPLATE_EXISTING_TOPIC_SELECTED',
  NEW_TEMPLATE_NEW_TOPIC: 'NEW_TEMPLATE_NEW_TOPIC',
  PREVIEW_TEMPLATE: 'PREVIEW_TEMPLATE',
  TOPIC_TRACKERS_ADDED: 'TOPIC_TRACKERS_ADDED',
  TOPIC_PROMPT_ADDED: 'TOPIC_PROMPT_ADDED'
};
