import castArray from 'lodash/castArray';
import { Editor } from 'slate';

/**
 * Get the block above a location (default: selection) by type.
 */
export const getAboveByType = (editor, types, options = {}) => {
  const casted = castArray(types);
  return Editor.above(editor, {
    match: n => casted.includes(n.type),
    ...options
  });
};
