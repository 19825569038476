export const MEETING_ALERT_TYPE = 'meeting_filter_alert';
export const MEETING_ALERTS_TAB_KEY = {
  teamMate: 'teamMate',
  my: 'my',
  standard: 'standard'
};
export const INITIAL_FILTERS = {
  more: {
    includeInternalMeetings: true,
    showInternalOnly: false,
    sharedMeetingsOnly: false,
    showUnrecordedMeetings: true
  }
};
