// This file is for logging non-fatal errors to the console
// and to sentry when appropriate

import * as Sentry from '@sentry/react';

import env from './env';

const logError = (message, extras) => {
  if (['int', 'prod', 'staging'].includes(env.name)) {
    Sentry.withScope(scope => {
      Object.keys(extras).forEach(key => {
        scope.setExtra(key, extras[key]);
      });
      Sentry.captureException(Error(message));
    });
  }
};

export default logError;
