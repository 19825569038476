import { Range, Node, Editor, Element as SlateElement } from 'slate';

import { isFirstChild } from '../../Queries/isFirstChild';
import { TYPE_CHECKLIST_ITEM } from '../../Types';
import { TYPE_LIST_ITEM } from '../types';

/**
 *
 * @param {SlateEditor} editor
 * @returns {string} Type of the list
 */
export const getActiveListType = editor => {
  const { selection } = editor;
  if (!selection) return false;

  const { anchor, focus } = selection;
  const selectionPath = Range.isBackward(selection) ? focus.path : anchor.path;
  let curNode = Node.get(editor, selectionPath);
  let curPath = selectionPath;
  // Get the non-text node
  while ((!curNode.type || curNode.type === 'text') && curPath.length > 0) {
    curPath = selectionPath.slice(0, -1);
    curNode = Node.get(editor, curPath);
  }
  // Get the parent
  if (curPath.length > 2) {
    const parentPath = curPath.slice(0, -1);
    const parentNode = Node.get(editor, parentPath);
    if (
      parentNode.type === TYPE_LIST_ITEM ||
      parentNode.type === TYPE_CHECKLIST_ITEM
    ) {
      const listPath = parentPath.slice(0, -1);
      const listNode = Node.get(editor, listPath);
      // Only consider it actively part of a list if its the paragraph is the first
      // child of a list node. If the paragraph is not the first child then it can
      // turn into a list item thus it is not active
      if (isFirstChild(curPath)) {
        return listNode.type;
      }
    }
  }
};

export const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format
    })
  );

  return !!match;
};

export const getBlockType = editor => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n)
    })
  );

  return match?.[0]?.type;
};

export const isSelectionChecklist = editor => {
  const { selection } = editor;
  if (!selection) return false;

  const { anchor, focus } = selection;
  const selectionPath = Range.isBackward(selection) ? focus.path : anchor.path;
  const parentNode = Node.get(editor, selectionPath.slice(0, -1));

  if (parentNode.type === TYPE_CHECKLIST_ITEM) {
    return true;
  }

  return false;
};
