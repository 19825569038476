/* eslint-disable @typescript-eslint/indent */
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useCreateSmartCategoryPromptMutation } from 'services/smartCategories';

import AvomaButton from 'components/Common/AvomaButton';
import AvomaTextArea from 'components/Common/AvomaTextArea';
import AvomaTextField from 'components/Common/AvomaTextField';
import AvomaTooltip from 'components/Common/AvomaTooltip';
import NotificationBanner from 'components/Common/NotificationBanner';
import {
  errorNotification,
  successNotification
} from 'components/ToastNotifications';

import L10n from 'helpers/L10n';
import { L10Common } from 'helpers/l10n/L10nCommon';

import { ReactComponent as AiIcon } from 'images/ic_ai.svg';

import Tokens from 'styles/tokens';

interface ICategoryInformationProps {
  category: {
    uuid: string;
    name: string;
    backgroundColor: string;
    description: string;
    isDefault: boolean;
  };
  setUnifiedSmartCategoryDescription: (description: string) => void;
  setUnifiedSmartCategoryName: (name: string) => void;
  setUnifiedSmartCategoryColor: (color: string) => void;
  unifiedSmartCategoryName: string;
  unifiedSmartCategoryDescription: string;
}

interface ICreatePromptResult {
  data?: {
    description: string;
  };
  error?: string;
}

const CategoryInformationV2 = ({
  category,
  unifiedSmartCategoryName,
  unifiedSmartCategoryDescription,
  setUnifiedSmartCategoryDescription,
  setUnifiedSmartCategoryName
}: ICategoryInformationProps) => {
  const [createSmartCategoryPrompt, { isLoading: isGeneratingPrompt }] =
    useCreateSmartCategoryPromptMutation();

  const { isNewSmartCategory, isDefaultCategory } = useMemo(
    () => ({
      isNewSmartCategory: category?.uuid === 'new',
      isDefaultCategory: category?.isDefault
    }),
    [category]
  );

  const onChangeSmartCategoryName = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setUnifiedSmartCategoryName(event.target.value as string);
  };

  const onChangeSmartCategoryDescription = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setUnifiedSmartCategoryDescription(event.target.value as string);
  };

  const handleGeneratePrompt = async (): Promise<void> => {
    try {
      const payload = { name: unifiedSmartCategoryName ?? '' };
      const result = (await createSmartCategoryPrompt({
        payload
      })) as ICreatePromptResult;

      if (result.data?.description) {
        setUnifiedSmartCategoryDescription(result.data.description);

        successNotification({
          message: L10n.customCategory.generatePromptSuccess
        });
      } else {
        errorNotification({
          message: L10n.customCategory.generatePromptError
        });
      }
    } catch (error) {
      errorNotification({
        message: L10n.customCategory.generatePromptError
      });
    }
  };

  return (
    <div className={twMerge('flex flex-col p-6 min-w-[600px]')}>
      <div className='flex flex-col gap-y-4'>
        <div className='flex flex-col gap-y-3'>
          <AvomaTooltip
            tooltip={
              isDefaultCategory &&
              L10n.customCategory.smartCategoryDefaultTitleTooltip
            }
          >
            <AvomaTextField
              data-testid='smart-category-name'
              label={L10Common.name}
              value={unifiedSmartCategoryName ?? ''}
              placeholder={
                L10n.customCategory.createSmartCategoryTitlePlaceholder
              }
              onChange={(inputEvent: {
                target: { value: React.SetStateAction<string> };
              }) => {
                onChangeSmartCategoryName(inputEvent);
              }}
              disabled={isDefaultCategory}
              userStyles={{
                ':disabled': {
                  cursor: 'not-allowed',
                  opacity: '1'
                }
              }}
            />
          </AvomaTooltip>
        </div>

        <div className='flex flex-col gap-y-3'>
          <div>
            <AvomaTextArea
              data-testid='smart-category-description'
              label={L10n.customCategory.prompt}
              value={unifiedSmartCategoryDescription ?? ''}
              placeholder={L10n.customCategory.promptPlaceholder}
              onChange={(inputEvent: {
                target: { value: React.SetStateAction<string> };
              }) => onChangeSmartCategoryDescription(inputEvent)}
              userStyles={styles.textArea}
              labelStyle={styles.label}
              disabled={isGeneratingPrompt}
            />
            {!isGeneratingPrompt ? (
              <AvomaTooltip
                tooltip={
                  isEmpty(unifiedSmartCategoryName) &&
                  L10n.customCategory.generateDisabledTooltip
                }
              >
                <div>
                  <AvomaButton
                    faIcon={
                      <AiIcon className='h-3.5 w-3.5 !stroke-none flex-shrink-0 -ml-1 mr-1' />
                    }
                    label={L10n.customCategory.generatePromptCta}
                    onClick={handleGeneratePrompt}
                    className='!rounded-tl-none !rounded-tr-none -mt-px hover:z-[1]'
                    disabled={isEmpty(unifiedSmartCategoryName)}
                    secondary
                    small
                  />
                </div>
              </AvomaTooltip>
            ) : (
              <div className='flex items-center gap-x-2 px-3 py-1.5 -mt-px ml-px'>
                <AiIcon className='h-3.5 w-3.5 !stroke-none flex-shrink-0' />
                <span className='text-silver-darker text-sm'>
                  {L10n.customCategory.generating}
                </span>
              </div>
            )}
          </div>

          {!isNewSmartCategory && isEmpty(unifiedSmartCategoryDescription) && (
            <NotificationBanner
              type='error'
              content={L10n.customCategory.smartCategoryInformationEmptyError}
              sm
            />
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  textArea: {
    paddingTop: Tokens.spacing.one,
    paddingLeft: Tokens.spacing.onehalf,
    paddingRight: Tokens.spacing.one,
    borderBottomLeftRadius: '0',
    zIndex: 1
  },
  label: {
    marginTop: Tokens.spacing.half
  }
};

export default CategoryInformationV2;
