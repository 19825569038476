import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { twMerge } from 'tailwind-merge';
import { IAutomationTicketingProviderTeamsAPI } from 'types/automation';

import { useGetTicketingTeamsQuery } from 'services/ticketing';

import AutomationMemberViewer from 'components/Automations/Rules/Filters/Viewers/AutomationMemberViewer';
import AvomaCheckbox from 'components/Common/AvomaCheckbox';
import AvomaPopoverControlled from 'components/Common/AvomaPopoverControlled';
import SearchField from 'components/Common/search/SearchField';

import Tokens from 'styles/tokens';

interface IProps {
  workspaceUuid: string;
  handleValueChange: (value: string[]) => void;
  clientValue?: string[];
  triggerClasses?: string;
  portalContainer?: HTMLElement;
}

const AutomationAssigneeClickupMembers = ({
  workspaceUuid,
  handleValueChange,
  clientValue = [],
  triggerClasses = '',
  portalContainer = document.body
}: IProps) => {
  const { uuid: ticketingProviderUuid } = useSelector(
    (state: RootState) => state.user?.ticketingProviders?.clickup || {}
  );
  const [search, setSearch] = useState('');
  const { data } = useGetTicketingTeamsQuery<{
    data: IAutomationTicketingProviderTeamsAPI[];
  }>(
    {
      providerUuid: ticketingProviderUuid
    },
    {
      skip: !workspaceUuid || !ticketingProviderUuid
    }
  );

  const selectedWorkspace = data?.find(
    workspace => workspace.uuid === workspaceUuid
  );

  const members = selectedWorkspace?.members;

  const handleSearch = (searchQueryTerm: string) => {
    setSearch(searchQueryTerm);
  };

  const filteredUsers = useMemo(() => {
    if (!search) return members;

    return (
      members?.filter(
        member =>
          member.name?.toLowerCase().includes(search.toLowerCase()) ||
          member.email?.toLowerCase().includes(search.toLowerCase())
      ) || []
    );
  }, [search, members]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    uuid: string
  ) => {
    if (e.target.checked) {
      handleValueChange([...clientValue, uuid]);
    } else {
      handleValueChange(clientValue.filter(id => id !== uuid));
    }
  };

  return (
    <AvomaPopoverControlled
      dropdownTrigger={() => (
        // @ts-ignore TODO: Types
        <AutomationMemberViewer value={clientValue} members={members} />
      )}
      triggerClasses={twMerge(
        'w-full rounded-t-none box-border bg-white hover:bg-snow py-3 px-1 rounded-b border-t-0 border-b border-l border-r border-gainsboro',
        triggerClasses
      )}
      contentClasses='!p-1 max-h-[300px] overflow-y-auto w-[300px]'
      portalContainer={portalContainer}
    >
      <SearchField
        handleSearch={handleSearch}
        handleClear={() => setSearch('')}
        placeholder='Search users'
        style={styles.search}
        instantSearch
      />
      {filteredUsers?.map(member => (
        <AvomaCheckbox
          key={member?.uuid}
          id={member?.uuid}
          containerStyle={styles.checkbox}
          className='avoma-checkbox-list-item'
          onChange={e => handleChange(e, member?.uuid)}
          label={
            <div>
              <div>{member.name}</div>
              <div className='text-left text-sm text-silver'>
                {member.email}
              </div>
            </div>
          }
          labelStyle={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}
          checked={clientValue.includes(member.uuid)}
        />
      ))}
    </AvomaPopoverControlled>
  );
};

const styles = {
  checkbox: {
    padding: Tokens.spacing.half,
    margin: Tokens.spacing.half,
    backgroundColor: 'transparent',
    borderRadius: Tokens.spacing.borderRadius
  },
  search: {
    width: 'auto',
    padding: Tokens.spacing.onehalf
  }
};

export default AutomationAssigneeClickupMembers;
