import PropTypes from 'prop-types';

import FilterSelectionViewer from 'components/Filters/Common/Viewers/SelectionViewer';

import { L10Common } from 'helpers/l10n/L10nCommon';
import { getUserTitle } from 'helpers/user';

const AutomationMemberViewer = ({
  value = [],
  members = [],
  render,
  type = 'clickup_member',
  identifier = 'uuid',
  ...props
}) => {
  const userDetails = value
    .filter(val => val !== type)
    .map(val => {
      const member = members.find(u => u?.[identifier] === val);
      if (member) {
        if (type === 'hubspot_member') {
          return getUserTitle(member);
        }
        return member?.name ?? member?.email;
      }

      return undefined;
    });

  return (
    <FilterSelectionViewer
      label={L10Common.member}
      details={userDetails}
      separator='and'
      placeholder='Select Assignee'
      {...props}
    />
  );
};

AutomationMemberViewer.propTypes = {
  render: PropTypes.func,
  value: PropTypes.array,
  members: PropTypes.array,
  type: PropTypes.oneOf(['clickup_member', 'hubspot_member']),
  identifier: PropTypes.oneOf(['uuid', 'externalId'])
};

export default AutomationMemberViewer;
