import debounce from 'lodash.debounce';

export default function debounceAction(action, wait, options) {
  const debounced = debounce(
    (dispatch, actionArgs) => dispatch(action(...actionArgs)),
    wait,
    options
  );

  const thunk =
    (...actionArgs) =>
    dispatch =>
      debounced(dispatch, actionArgs);

  // provide hook to _.debounce().cancel() to cancel any trailing invocations
  thunk.cancel = debounced.cancel;

  return thunk;
}
