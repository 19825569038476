import camelize from 'camelize';

import { DashboardActions } from 'actions/actionTypes';

import { prepareQuery } from 'helpers/filters';

const isActionValid = (state, action) => action.requestTs >= state.requestTs;

export const initialFiltersQuery = {
  team: '',
  date: '',
  event: '',
  accounts: '',
  meetingDuration: '',
  internal: '',
  stageNow: '',
  stageDuring: '',
  searchTerm: '',
  amountNow: '',
  transText: '',
  unrecorded: '',
  shared: '',
  calls: '',
  types: '',
  outcomes: '',
  customCategory: '',
  saidBy: '',
  keywordsInTranscript: '',
  meetingsScored: '',
  addedToPlaylist: '',
  meetingsHasSnippets: '',
  meetingsHasComments: '',
  meetingPrivacy: '',
  videoConferencing: ''
};

export const initialDealFiltersQuery = {
  closeDate: '',
  team: '',
  crmAccounts: '',
  amountNow: '',
  createdDate: '',
  dealStatus: '',
  forecastCategory: '',
  sortBy: '',
  pipeline: '',
  dealStageNow: '',
  amount: '',
  closeDateColumn: '',
  name: '',
  dealSearch: ''
};

export const initialCompanyFiltersQuery = {
  team: '',
  createdDate: '',
  sortBy: '',
  name: '',
  search: '',
  annualRevenue: ''
};

export const initialScorecardFiltersQuery = {
  scorecardDateRange: 'date_range=lastThirty&',
  scorecardForTeamUuid: [],
  scorecardForEmail: [],
  scorecardCreatedByTeamUuid: [],
  scorecardCreatedByEmail: [],
  scorecardRequestedToEmail: [],
  scorecardRequestedByEmail: [],
  scorecardScoreRange: [],
  scorecardAverageScoreRange: [],
  scorecardTemplateUuid: [],
  scorecardTemplateType: '',
  scorecardMeetingTypeUuid: [],
  scorecardType: '',
  types: '',
  outcomes: '',
  calls: '',
  meetingDuration: ''
};

export const initialFilterCount = {
  date: 0,
  team: 0,
  user: 0,
  organizer: 0,
  crmAccounts: 0,
  types: 0,
  outcomes: 0,
  stageDuring: 0,
  stageNow: 0,
  call: 0,
  amountNow: 0,
  more: 0,
  dealStatus: 0,
  forecastCategory: 0,
  sortBy: 0,
  closeDate: 0,
  createdDate: 0,
  saidBy: 0,
  customCategory: 0,
  keywords: 0,
  keywordsInTranscript: 0,
  meetingsScored: 0,
  addedToPlaylist: 0,
  meetingsHasSnippets: 0,
  meetingsHasComments: 0,
  meetingPrivacy: 0,
  videoConferencing: 0
};

export const initialDealsCount = {
  pipeline: 0,
  date: 0,
  createdDate: 0,
  team: 0,
  user: 0,
  crmAccounts: 0,
  amountNow: 0,
  dealStatus: 0,
  forecastCategory: 0,
  sortBy: 0,
  dealStageNow: 0,
  dealSearch: 0
};

export const initialCompaniesCount = {
  createdDate: 0,
  team: 0,
  user: 0,
  companySearch: 0,
  annualRevenue: 0
};

export const initialScorecardCount = {
  scorecardDateRange: 1,
  scorecardForTeamUuid: 0,
  scorecardForEmail: 0,
  scorecardCreatedByTeamUuid: 0,
  scorecardCreatedByEmail: 0,
  scorecardRequestedToEmail: 0,
  scorecardRequestedByEmail: 0,
  scorecardScoreRange: 0,
  scorecardAverageScoreRange: 0,
  scorecardTemplateUuid: 0,
  scorecardTemplateType: 0,
  scorecardMeetingTypeUuid: 0,
  scorecardType: 0,
  outcomes: 0,
  call: 0,
  more: 0
};

export const initialState = {
  // dashboard data
  fetching: false,
  error: null,
  overallStats: null,
  perUserStats: null,
  talkToListen: null,
  trends: {},
  trackers: {},
  tab: 'stats',
  filter: {
    allMeetings: {
      query: initialFiltersQuery,
      count: { ...initialFilterCount },
      more: {
        showUnrecordedMeetings: undefined,
        includeInternalMeetings: true,
        showInternalOnly: undefined,
        sharedMeetingsOnly: undefined,
        isConference: undefined,
        showRecordingEnabled: undefined
      }
    },
    calls: {
      query: initialFiltersQuery,
      count: { ...initialFilterCount },
      customCategory: [],
      more: {
        showUnrecordedMeetings: undefined,
        includeInternalMeetings: true,
        showInternalOnly: undefined,
        sharedMeetingsOnly: undefined
      }
    },
    stats: {
      query: initialFiltersQuery,
      count: initialFilterCount,
      more: {
        showUnrecordedMeetings: true,
        includeInternalMeetings: true,
        showInternalOnly: false
      }
    },
    usage: {
      query: initialFiltersQuery,
      count: initialFilterCount,
      more: {
        showUnrecordedMeetings: true,
        includeInternalMeetings: true
      }
    },
    'usage/member': {
      query: initialFiltersQuery,
      count: initialFilterCount,
      more: {
        showUnrecordedMeetings: true,
        includeInternalMeetings: true
      }
    },
    'usage/activity': {
      query: initialFiltersQuery,
      count: initialFilterCount,
      more: {
        showUnrecordedMeetings: true,
        includeInternalMeetings: true,
        showInternalOnly: false
      }
    },
    'usage/roi': {
      query: initialFiltersQuery,
      count: initialFilterCount,
      more: {
        showUnrecordedMeetings: true,
        includeInternalMeetings: true,
        showInternalOnly: false
      }
    },
    'usage/recording_metrics': {
      query: initialFiltersQuery,
      count: initialFilterCount
    },
    'usage/recording_metrics_members': {
      query: initialFiltersQuery,
      count: initialFilterCount
    },
    deals: {
      query: initialDealFiltersQuery,
      count: initialDealsCount,
      dealStatus: '',
      forecastCategory: '',
      sortBy: '',
      pipeline: '',
      dealStageNow: '',
      dealSearch: ''
    },
    company: {
      query: initialCompanyFiltersQuery,
      count: initialCompaniesCount
    },
    trackers: {
      query: initialFiltersQuery,
      count: initialFilterCount
    },
    'ai-analysis': {
      query: initialFiltersQuery,
      count: initialFilterCount,
      more: {
        showUnrecordedMeetings: true,
        includeInternalMeetings: true,
        showInternalOnly: false
      }
    },
    engagement: {
      query: {},
      counts: {}
    },
    smartPlaylist: {
      query: prepareQuery(
        {
          queryType: 'more',
          value: {
            showUnrecordedMeetings: false,
            includeInternalMeetings: true,
            showInternalOnly: false
          },
          addons: null
        },
        initialFiltersQuery
      ),
      count: { ...initialFilterCount, more: 1 },
      customCategory: [],
      more: {
        showUnrecordedMeetings: false,
        includeInternalMeetings: true,
        showInternalOnly: false
      }
    },
    scheduler: {
      query: initialFiltersQuery,
      count: { ...initialFilterCount },
      customCategory: [],
      more: {
        showUnrecordedMeetings: true,
        includeInternalMeetings: true,
        showInternalOnly: false
      }
    },
    membersPerformance: {
      query: initialScorecardFiltersQuery,
      count: { ...initialScorecardCount },
      customCategory: [],
      more: {
        showMeetingsFilter: false,
        showUnrecordedFilter: false,
        showSharedOnlyFilter: false
      }
    },
    memberAnalytics: {
      query: initialScorecardFiltersQuery,
      count: { ...initialScorecardCount },
      customCategory: [],
      more: {
        showMeetingsFilter: false,
        showUnrecordedFilter: false,
        showSharedOnlyFilter: false
      }
    },
    scorecardAnalytics: {
      query: initialScorecardFiltersQuery,
      count: { ...initialScorecardCount },
      customCategory: [],
      more: {
        showMeetingsFilter: false,
        showUnrecordedFilter: false,
        showSharedOnlyFilter: false
      }
    },
    scorecardsPerformance: {
      query: initialScorecardFiltersQuery,
      count: { ...initialScorecardCount },
      customCategory: [],
      more: {
        showMeetingsFilter: false,
        showUnrecordedFilter: false,
        showSharedOnlyFilter: false
      }
    },
    scorecardsPerformanceQuestions: {
      query: initialScorecardFiltersQuery,
      count: { ...initialScorecardCount },
      customCategory: [],
      more: {
        showMeetingsFilter: false,
        showUnrecordedFilter: false,
        showSharedOnlyFilter: false
      }
    },
    scorecardsPerformanceMembers: {
      query: initialScorecardFiltersQuery,
      count: { ...initialScorecardCount },
      customCategory: [],
      more: {
        showMeetingsFilter: false,
        showUnrecordedFilter: false,
        showSharedOnlyFilter: false
      }
    },
    scorecards: {
      query: initialScorecardFiltersQuery,
      count: { ...initialScorecardCount },
      customCategory: [],
      more: {
        showMeetingsFilter: false,
        showUnrecordedFilter: false,
        showSharedOnlyFilter: false
      }
    },
    globalSearch: {
      query: initialFiltersQuery,
      count: initialFilterCount,
      more: {
        showUnrecordedMeetings: undefined,
        includeInternalMeetings: true
      }
    }
  },
  conversation: {
    data: {},
    error: null,
    fetching: null
  },
  activityStats: null,
  fetchingActivityStats: false,
  topics: {}
};

const fillerWordsState = (state, action) => {
  switch (action.type) {
    case DashboardActions.FETCH_FILLER_WORDS_REQUEST:
      return {
        ...state,
        conversation: {
          ...state.conversation,
          fetchingFillerWords: true
        }
      };
    case DashboardActions.FETCH_FILLER_WORDS_SUCCESS:
      return {
        ...state,
        conversation: {
          ...state.conversation,
          data: {
            ...state.conversation.data,
            ...action.data,
            agg_data: {
              ...state.conversation.data.agg_data,
              ...action.data.agg_data,
              [action.facet]: action.data.agg_data[action.facet]
            }
          },
          fetchingFillerWords: false
        }
      };

    case DashboardActions.FETCH_FILLER_WORDS_FAILURE:
      return {
        ...state,
        conversation: {
          ...state.conversation,
          fetchingFillerWords: false,
          fillerWordsError: action.error
        }
      };

    default:
      return state;
  }
};

const insightsState = (state, action) => {
  switch (action.type) {
    case DashboardActions.FETCH_INSIGHTS_REQUEST:
      return {
        ...state,
        conversation: {
          ...state.conversation,
          fetching: true,
          requestTs: action.requestTs
        }
      };
    case DashboardActions.FETCH_INSIGHTS_SUCCESS:
      if (isActionValid(state.conversation, action)) {
        return {
          ...state,
          conversation: {
            ...state.conversation,
            data: {
              ...state.conversation.data,
              ...action.data,
              agg_data: {
                ...state.conversation.data.agg_data,
                ...action.data.agg_data
              }
            },
            fetching: false
          }
        };
      }
      return state;

    case DashboardActions.FETCH_INSIGHTS_FAILURE:
      if (isActionValid(state.conversation, action)) {
        return {
          ...state,
          conversation: {
            ...state.conversation,
            fetching: false,
            error: action.error
          }
        };
      }
      return state;

    default:
      return state;
  }
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case DashboardActions.FETCH_ACTIVITY_STATS_REQUEST:
      return {
        ...state,
        fetchingActivityStats: true,
        error: null
      };
    case DashboardActions.FETCH_ACTIVITY_STATS_SUCCESS:
      return {
        ...state,
        fetchingActivityStats: false,
        activityStats: camelize(action.results),
        error: null
      };
    case DashboardActions.FETCH_ACTIVITY_STATS_FAILURE:
      return {
        ...state,
        fetchingActivityStats: false,
        error: action.error
      };
    case 'FETCH_PER_USER_STATS_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      };
    case 'FETCH_PER_USER_STATS_SUCCESS':
      return {
        ...state,
        perUserStats: camelize(action.stats),
        fetching: false,
        error: null
      };
    case 'FETCH_PER_USER_STATS_FAILURE':
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case 'FETCH_TALK_TO_LISTEN_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      };
    case 'FETCH_TALK_TO_LISTEN_SUCCESS':
      return {
        ...state,
        talkToListen: camelize(action.talkToListen),
        fetching: false,
        error: null
      };
    case 'FETCH_TALK_TO_LISTEN_FAILURE':
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case 'FETCH_TRENDS_REQUEST':
      return {
        ...state,
        trends: {
          ...state.trends,
          fetching: true,
          error: null
        }
      };
    case 'FETCH_TRENDS_SUCCESS':
      return {
        ...state,
        trends: {
          ...state.trends,
          ...camelize(action.trends),
          fetching: false,
          error: null
        }
      };
    case 'FETCH_TRENDS_FAILURE':
      return {
        ...state,
        trends: {
          ...state.trends,
          fetching: false,
          error: action.error
        }
      };
    case DashboardActions.FETCH_TRACKERS_COUNT_REQUEST:
      return {
        ...state,
        trackers: {
          ...state.trackers,
          fetching: true,
          error: null,
          query: action.query
        }
      };
    case DashboardActions.FETCH_TRACKERS_COUNT_SUCCESS:
      return {
        ...state,
        trackers: {
          ...state.trackers,
          ...action.aggData,
          userData: action.userData,
          fetching: false,
          error: null,
          query: null
        }
      };

    case DashboardActions.FETCH_TRACKERS_COUNT_FAILURE:
      return {
        ...state,
        trackers: {
          ...state.trackers,
          fetching: false,
          error: action.error,
          query: null
        }
      };

    case DashboardActions.FETCH_TRACKERS_COUNT_BY_DATE_REQUEST:
      return {
        ...state,
        trackers: {
          ...state.trackers,
          fetching: true,
          error: null,
          query: action.query
        }
      };
    case DashboardActions.FETCH_TRACKERS_COUNT_BY_DATE_SUCCESS:
      return {
        ...state,
        trackers: {
          ...state.trackers,
          ...action.aggData,
          fetching: false,
          error: null,
          query: null
        }
      };

    case DashboardActions.FETCH_TRACKERS_COUNT_BY_DATE_FAILURE:
      return {
        ...state,
        trackers: {
          ...state.trackers,
          fetching: false,
          error: action.error,
          query: null
        }
      };

    case 'FETCH_TRENDS_DATE_REQUEST':
      return {
        ...state,
        trendsByDate: {
          ...state.trendsByDate,
          fetching: true,
          error: null
        }
      };
    case 'FETCH_TRENDS_DATE_SUCCESS':
      return {
        ...state,
        trendsByDate: {
          ...camelize(action.trends),
          fetching: false,
          error: null
        }
      };
    case 'FETCH_TRENDS_DATE_FAILURE':
      return {
        ...state,
        trendsByDate: {
          ...state.trendsByDate,
          fetching: false,
          error: action.error
        }
      };
    case 'FETCH_TOPICS_REQUEST':
      return {
        ...state,
        topics: {
          ...state.topics,
          fetching: true,
          error: null
        }
      };
    case 'FETCH_TOPICS_SUCCESS':
      return {
        ...state,
        topics: {
          ...action.aggData,
          userData: action.userData,
          fetching: false,
          error: null
        }
      };
    case 'FETCH_TOPICS_FAILURE':
      return {
        ...state,
        topics: {
          ...state.topics,
          fetching: false,
          error: action.error
        }
      };
    case DashboardActions.FETCH_TOP_QUESTIONS_REQUEST:
      return {
        ...state,
        popularTopics: {
          ...state.popularTopics,
          topQuestions: {
            fetching: true,
            error: null
          }
        }
      };
    case DashboardActions.FETCH_TOP_QUESTIONS_SUCCESS:
      return {
        ...state,
        popularTopics: {
          ...state.popularTopics,
          topQuestions: {
            fetching: false,
            error: null,
            data: {
              [action.group]: {
                ...action.response
              }
            }
          }
        }
      };
    case DashboardActions.FETCH_TOP_QUESTIONS_FAILURE:
      return {
        ...state,
        popularTopics: {
          ...state.popularTopics,
          topQuestions: {
            fetching: false,
            error: action.err
          }
        }
      };

    case DashboardActions.FETCH_QUESTION_ASSOCIATED_MEETINGS_REQUEST:
      return {
        ...state,
        popularTopics: {
          ...state.popularTopics,
          topQuestions: {
            ...state.popularTopics.topQuestions,
            associatedMeetings: {
              ...state.popularTopics.topQuestions.associatedMeetings,
              [action.cluster]: {
                fetching: true
              }
            }
          }
        }
      };

    case DashboardActions.FETCH_QUESTION_ASSOCIATED_MEETINGS_SUCCESS:
      return {
        ...state,
        popularTopics: {
          ...state.popularTopics,
          topQuestions: {
            ...state.popularTopics.topQuestions,
            associatedMeetings: {
              ...state.popularTopics.topQuestions.associatedMeetings,
              [action.cluster]: {
                fetching: false,
                error: null,
                data: action.response
              }
            }
          }
        }
      };

    case DashboardActions.FETCH_QUESTION_ASSOCIATED_MEETINGS_FAILURE:
      return {
        ...state,
        popularTopics: {
          ...state.popularTopics,
          topQuestions: {
            ...state.popularTopics.topQuestions,
            associatedMeetings: {
              ...state.popularTopics.topQuestions.associatedMeetings,
              [action.cluster]: {
                fetching: false,
                error: action.err
              }
            }
          }
        }
      };

    case DashboardActions.SET_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.view]: {
            ...state.filter[action.view],
            [action.queryType]: action.value,
            count: { ...state.filter[action.view].count, ...action.count },
            query: prepareQuery(
              action,
              { ...state.filter[action.view].query },
              action.view,
              action.isAvoma
            )
          }
        }
      };
    }

    case DashboardActions.SET_BATCHED_FILTERS: {
      const { payload } = action;
      const { count, query, addons, ...filters } = payload;

      return {
        ...state,
        filter: {
          ...state.filter,
          [action.view]: {
            // ...state.filter[action.view],
            ...action.payload,
            // count: { ...state.filter[action.view].count, ...action.count },
            query: Object.entries(filters).reduce(
              (acc, [key, value]) =>
                prepareQuery(
                  { queryType: key, value, addons: addons?.[key] },
                  acc
                ),
              {}
            )
          }
        }
      };
    }

    case DashboardActions.RESET_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.view]: initialState.filter[action.view]
        }
      };
    }

    case DashboardActions.SET_ALL_FILTERS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.view]: {
            ...action.payload
          }
        }
      };
    }

    case DashboardActions.CLEAR:
      return {
        ...state,
        trendsByDate: {},
        trends: {
          searchHistory: !state.trends ? {} : { ...state.trends.searchHistory }
        }
      };
    case DashboardActions.CLEAR_TRACKERS:
      return {
        ...state,
        trackers: {}
      };
    case DashboardActions.SET_TIME_GROUP:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.view]: {
            ...state.filter[action.view],
            date: {
              ...state.filter[action.view].date,
              groupBy: action.groupBy
            }
          }
        }
      };
    case DashboardActions.SEARCHED_KEYWORD:
      return {
        ...state,
        trends: {
          ...state.trends,
          searchHistory: {
            ...(state.trends ? state.trends.searchHistory : {}),
            [action.keyword]: true
          }
        }
      };
    case DashboardActions.FETCH_INSIGHTS_REQUEST:
    case DashboardActions.FETCH_INSIGHTS_SUCCESS:
    case DashboardActions.FETCH_INSIGHTS_FAILURE:
      return insightsState(state, action);
    case DashboardActions.FETCH_FILLER_WORDS_REQUEST:
    case DashboardActions.FETCH_FILLER_WORDS_SUCCESS:
    case DashboardActions.FETCH_FILLER_WORDS_FAILURE:
      return fillerWordsState(state, action);
    default:
      return state;
  }
};

export default dashboard;
