import { RealtimeTranscript } from '../types/realtimeTranscriptions';

/**
 * Merge two transcript blocks into one by splicing them at word level
 *
 * @param currentBlock
 * @param newBlock
 */
export const mergeTranscript = (
  currentBlock: RealtimeTranscript,
  newBlock: RealtimeTranscript
) => {
  // In the data structure, the Timestamps array has times matching the occurrence of very word in the transcript
  // So each timestamp corresponds to a word in the transcript.
  // Use this property to sort the two arrays in parallel

  // Create a parallel array of the times and words.
  const newTimes = [...currentBlock.timestamps, ...newBlock.timestamps];
  const newWords = [
    ...currentBlock.transcript.split(' '),
    ...newBlock.transcript.split(' ')
  ];

  // Build an index order of times
  const sortedTimeIndexes = [...newTimes]
    .sort()
    .map(time => newTimes.indexOf(time));

  // Sort the original arrays
  return [newTimes, newWords].map(subArray =>
    subArray.map((_, index) => subArray[sortedTimeIndexes[index]])
  );
};
