import camelize from 'camelize';
import { EventTypes } from 'redux-segment';

import { OutcomeActions } from 'actions/actionTypes';

import env from 'helpers/env';
import { deleteMethod, get, patch, post } from 'helpers/http';

export const fetchMeetingAttributes = organizationUuid => async dispatch => {
  if (!organizationUuid) {
    throw Error('[avoma] delete meeting outcome uuid missing');
  }
  dispatch({ type: OutcomeActions.FETCH_ATTRIBUTES_REQUEST });
  try {
    const response = await get(
      `${env.avomaApiUrl}/api/v1/organizations/${organizationUuid}/meeting_attributes/`
    );
    dispatch({
      type: OutcomeActions.FETCH_ATTRIBUTES_SUCCESS,
      types: camelize(response.meeting_types),
      outcomes: camelize(response.meeting_outcomes)
    });
  } catch (e) {
    dispatch({ type: OutcomeActions.FETCH_ATTRIBUTES_FAILURE });
  }
};
// return meetingType(Purposes) unordered.
export const fetchMeetingTypesForOrg =
  (query = '') =>
  async dispatch => {
    dispatch({ type: OutcomeActions.FETCH_TYPES_FOR_ORG_REQUEST });
    try {
      const response = await get(
        `${env.avomaApiUrl}/api/v1/meetingtype/for_org/${query}`
      );
      dispatch({
        type: OutcomeActions.FETCH_TYPES_FOR_ORG_SUCCESS,
        meetingTypes: camelize(response)
      });
      return camelize(response);
    } catch (e) {
      dispatch({ type: OutcomeActions.FETCH_TYPES_FOR_ORG_FAILURE });
      throw e;
    }
  };

// return meetingType(Purposes) ordered based on usage.
export const fetchMeetingTypes = () => async dispatch => {
  dispatch({ type: OutcomeActions.FETCH_TYPES_REQUEST });
  try {
    const response = await get(`${env.avomaApiUrl}/api/v1/meetingtype/`);
    dispatch({
      type: OutcomeActions.FETCH_TYPES_SUCCESS,
      meetingTypes: camelize(response)
    });
    return camelize(response);
  } catch (e) {
    dispatch({ type: OutcomeActions.FETCH_TYPES_FAILURE });
    throw e;
  }
};

export const createMeetingType =
  ({ label, backgroundColor, foregroundColor }) =>
  async dispatch => {
    dispatch({
      type: OutcomeActions.CREATE_TYPES_REQUEST
    });
    try {
      const response = await post(`${env.avomaApiUrl}/api/v1/meetingtype/`, {
        label,
        background_color: backgroundColor,
        foreground_color: foregroundColor
      });
      dispatch({
        type: OutcomeActions.CREATE_TYPES_SUCCESS,
        meetingType: camelize(response),
        meta: {
          analytics: EventTypes.track
        }
      });
      return camelize(response);
    } catch (e) {
      dispatch({ type: OutcomeActions.CREATE_TYPES_FAILURE });
      throw e;
    }
  };

export const updateMeetingType =
  ({ uuid, label, backgroundColor, foregroundColor }) =>
  async dispatch => {
    if (!uuid) {
      throw Error('[avoma] update meeting type uuid missing');
    }
    dispatch({
      type: OutcomeActions.UPDATE_TYPES_REQUEST
    });
    try {
      const response = await patch(
        `${env.avomaApiUrl}/api/v1/meetingtype/${uuid}/`,
        {
          label,
          background_color: backgroundColor,
          foreground_color: foregroundColor
        }
      );
      dispatch({
        type: OutcomeActions.UPDATE_TYPES_SUCCESS,
        uuid,
        meetingType: camelize(response)
      });
      return camelize(response);
    } catch (e) {
      dispatch({ type: OutcomeActions.UPDATE_TYPES_FAILURE });
      throw e;
    }
  };

export const deleteMeetingType = uuid => async dispatch => {
  if (!uuid) {
    throw Error('[avoma] delete meeting type uuid missing');
  }
  dispatch({
    type: OutcomeActions.DELETE_TYPES_REQUEST,
    meta: {
      analytics: EventTypes.track
    }
  });
  try {
    await deleteMethod(`${env.avomaApiUrl}/api/v1/meetingtype/${uuid}/`);
    dispatch({
      type: OutcomeActions.DELETE_TYPES_SUCCESS,
      uuid
    });
    return;
  } catch (e) {
    dispatch({ type: OutcomeActions.DELETE_TYPES_FAILURE });
    throw e;
  }
};

export const fetchMeetingOutcomes =
  (query = '') =>
  async dispatch => {
    dispatch({ type: OutcomeActions.FETCH_OUTCOMES_REQUEST });
    try {
      const response = await get(
        `${env.avomaApiUrl}/api/v1/meetingoutcome/${query}`
      );
      dispatch({
        type: OutcomeActions.FETCH_OUTCOMES_SUCCESS,
        meetingOutcomes: camelize(response)
      });
      return camelize(response);
    } catch (e) {
      dispatch({ type: OutcomeActions.FETCH_OUTCOMES_FAILURE });
      throw e;
    }
  };

export const createMeetingOutcome =
  ({ label, backgroundColor, foregroundColor }) =>
  async dispatch => {
    dispatch({
      type: OutcomeActions.CREATE_OUTCOMES_REQUEST
    });
    try {
      const response = await post(`${env.avomaApiUrl}/api/v1/meetingoutcome/`, {
        label,
        background_color: backgroundColor,
        foreground_color: foregroundColor
      });
      dispatch({
        type: OutcomeActions.CREATE_OUTCOMES_SUCCESS,
        meetingOutcome: camelize(response)
      });
      return camelize(response);
    } catch (e) {
      dispatch({ type: OutcomeActions.CREATE_OUTCOMES_FAILURE });
      throw e;
    }
  };

export const updateMeetingOutcome =
  ({ uuid, label, backgroundColor, foregroundColor }) =>
  async dispatch => {
    if (!uuid) {
      throw Error('[avoma] update meeting outcome uuid missing');
    }
    dispatch({
      type: OutcomeActions.UPDATE_OUTCOMES_REQUEST
    });
    try {
      const response = await patch(
        `${env.avomaApiUrl}/api/v1/meetingoutcome/${uuid}/`,
        {
          label,
          background_color: backgroundColor,
          foreground_color: foregroundColor
        }
      );
      dispatch({
        type: OutcomeActions.UPDATE_OUTCOMES_SUCCESS,
        uuid,
        meetingOutcome: camelize(response)
      });
      return camelize(response);
    } catch (e) {
      dispatch({ type: OutcomeActions.UPDATE_OUTCOMES_FAILURE });
      throw e;
    }
  };

export const deleteMeetingOutcome = uuid => async dispatch => {
  if (!uuid) {
    throw Error('[avoma] delete meeting outcome uuid missing');
  }
  dispatch({
    type: OutcomeActions.DELETE_OUTCOMES_REQUEST,
    meta: {
      analytics: EventTypes.track
    }
  });
  try {
    await deleteMethod(`${env.avomaApiUrl}/api/v1/meetingoutcome/${uuid}/`);
    dispatch({
      type: OutcomeActions.DELETE_OUTCOMES_SUCCESS,
      uuid
    });
    return;
  } catch (e) {
    dispatch({ type: OutcomeActions.DELETE_OUTCOMES_FAILURE });
    throw e;
  }
};

export const assignMeetingTypeToMeeting =
  ({ uuid, meetingUuid, updateFuture, updateAll }) =>
  async dispatch => {
    if (!uuid) {
      throw Error('[avoma] assignMeetingTypeToMeeting uuid missing');
    }
    if (!meetingUuid) {
      throw Error('[avoma] assignMeetingTypeToMeeting meetingUuid missing');
    }
    dispatch({
      type: OutcomeActions.ASSIGN_MEETING_TYPE_REQUEST,
      meta: {
        analytics: EventTypes.track
      }
    });
    await post(
      `${env.avomaApiUrl}/api/v1/meetingtype/${uuid}/attach_to_meeting/`,
      {
        meeting: meetingUuid,
        update_future: updateFuture,
        update_all: updateAll
      }
    );
  };

export const assignMeetingOutcomeToMeeting =
  ({ uuid, meetingUuid }) =>
  async dispatch => {
    if (!uuid) {
      throw Error('[avoma] assignMeetingOutcomeToMeeting uuid missing');
    }
    if (!meetingUuid) {
      throw Error('[avoma] assignMeetingOutcomeToMeeting meetingUuid missing');
    }
    dispatch({
      type: OutcomeActions.ASSIGN_MEETING_OUTCOME_REQUEST,
      meta: {
        analytics: EventTypes.track
      }
    });
    await post(
      `${env.avomaApiUrl}/api/v1/meetingoutcome/${uuid}/attach_to_meeting/`,
      {
        meeting: meetingUuid
      }
    );
  };

export const unassignMeetingTypeToMeeting =
  ({ uuid, meetingUuid, updateFuture, updateAll }) =>
  async dispatch => {
    if (!uuid) {
      throw Error('[avoma] unassignMeetingTypeToMeeting uuid missing');
    }
    if (!meetingUuid) {
      throw Error('[avoma] unassignMeetingTypeToMeeting meetingUuid missing');
    }
    dispatch({
      type: OutcomeActions.UNASSIGN_MEETING_TYPE_REQUEST
    });
    await post(
      `${env.avomaApiUrl}/api/v1/meetingtype/${uuid}/detach_from_meeting/`,
      {
        meeting: meetingUuid,
        update_future: updateFuture,
        update_all: updateAll
      }
    );
  };

export const unassignMeetingOutcomeToMeeting =
  ({ uuid, meetingUuid }) =>
  async dispatch => {
    if (!uuid) {
      throw Error('[avoma] unassignMeetingOutcomeToMeeting uuid missing');
    }
    if (!meetingUuid) {
      throw Error(
        '[avoma] unassignMeetingOutcomeToMeeting meetingUuid missing'
      );
    }
    dispatch({
      type: OutcomeActions.UNASSIGN_MEETING_OUTCOME_REQUEST
    });
    await post(
      `${env.avomaApiUrl}/api/v1/meetingoutcome/${uuid}/detach_from_meeting/`,
      {
        meeting: meetingUuid
      }
    );
  };
