import { PLAN_NAMES } from 'constants/billing';

const L10Scheduler = /** @type {const} */ ({
  schedulerReports: {
    title: 'Event History',
    evnetDetailsTooltip: 'Details of the events created',
    inviteeTooltip:
      'Details of the prospect with whom the meeting has been booked',
    assignedToTooltip: 'Details of whom this event has been booked with',
    bookedByTooltip: 'Details of the person that booked the event',
    newTabTooltip: 'Open in new tab',
    columns: {
      schedulingPageName: 'Scheduling Page Name',
      dateCreated: 'Date created',
      eventDetails: 'Event Details',
      lead: 'Lead',
      organizer: 'Organizer',
      booker: 'Booker',
      status: 'Status'
    },
    meetingActivity: 'Meeting Activity',
    meetingActivityInfo: 'Status of meetings booked via scheduling pages',
    bookingInfo: {
      name: "Booker's Name – If a team member booked the event with the lead.",
      lead: 'Booked by Lead – If the event was booked directly by the lead themselves.'
    }
  },
  routerHeader: {
    lastEditedOn: 'Last edited on'
  },
  loaderText: {
    title: 'Booking your meeting...',
    subTitle: 'Please wait while we confirm your meeting.'
  },
  schPageCreationOptions: {
    oneOnOne: {
      name: 'One-on-One',
      desc: 'Invitees can schedule a meeting with one host.',
      helper: 'Good for : 1:1 interviews, 1:1 mentorship sessions etc.'
    },
    group: {
      name: 'Group',
      desc: 'Invitees can schedule a meeting with multiple hosts.',
      helper: 'Good for : CS checkins, Onboarding sessions etc.'
    },
    roundRobin: {
      name: 'Round-Robin',
      desc: 'Meetings are assigned to hosts in a rotating fashion.',
      helper: 'Good for : Distributing inbound leads etc.'
    },
    managedPage: {
      name: 'Managed Page (One-on-One)',
      desc: 'Create a scheduling page template and bulk assign it to members.',
      helper: 'Good for : Providing a consistent scheduling experience.'
    }
  },
  filter: {
    filterByLocation: 'Filter by Location',
    location: 'Location',
    pageType: 'Page Type',
    filterByPageType: 'Filter by Page Type'
  },
  schedulerPageDoesNotExist: 'Scheduling page does not exist.',
  schedulerPageAccessDenied: 'You do not have access to this scheduling page.',
  adminManaged: 'ADMIN MANAGED',
  inactiveManagedPage: 'You cannot change the status of an admin managed page',
  searchBarPlaceHolder: 'Search for a Scheduling page',
  assignMembersModal: {
    title: 'Assign managed page to newly added member(s)?',
    text: 'A new page will be added to the My Pages section for all the individual members to whom this page is assigned. An email confirmation will be sent to these members.',
    confirmText: 'Confirm',
    additionalText:
      'If assigned members have a page with a conflicting scheduling link, the existing link will be replaced with a number appended to the existing link.'
  },
  deleteMembersModal: {
    title: 'Unassign the managed page for this member?',
    text: 'Are you sure you want to unassign the managed page for this member?',
    confirmText: 'Yes, Unassign',
    additionalText:
      'This will also delete the scheduler page created for this member using this managed page.'
  },
  lockModal: {
    title: 'Lock this section?',
    text: 'Locking this section will override any changes that members may have done to their instance of this managed page.',
    confirmText: 'Yes, Proceed',
    additionalText: 'Do you wish to proceed?'
  },
  syncModal: {
    title: 'Sync changes?',
    text: 'These changes will be synced with the instances of this managed page assigned to members.',
    confirmText: 'Yes, Proceed',
    additionalText: 'Do you wish to proceed?'
  },
  learnMore: 'Learn more',
  crmPage:
    'Enter your email address and we will connect you with the right person!',
  gateCreateScheduler: minimumRequiredPlan =>
    `You are currently on a basic plan. To create scheduling pages, please upgrade to the ${minimumRequiredPlan} plan or above.`,
  basicPlanBanner: ({ minimumPlanType }) =>
    `You are currently on a basic plan. To create and manage your scheduling pages, please upgrade to the ${minimumPlanType} plan or above.`,
  basicManagedPagesPlanBanner: minimumPlanType =>
    `You are currently on a basic plan. To use Managed Pages, please upgrade to the ${minimumPlanType} plan or above.`,
  planGatingMessage: ({ currentPlan = 'basic', pageType, minimumPlanType }) =>
    `You are currently on a ${currentPlan} plan. To create ${pageType} scheduling pages, please upgrade to the ${minimumPlanType} plan or above.`,
  basicPlanManagedPagesBanner:
    'You are on a Basic plan. Please upgrade to use Managed Pages.',
  eventTitleBanner:
    'You are on a Basic plan, upgrade to activate “Event Title” customization.',
  eventDescBanner:
    'You are on a Basic plan, upgrade to activate “Event Description customization.',
  crmPermissionDenied: ({ currentPlan, minimumRequiredPlan }) =>
    `You are on a ${currentPlan} plan. Please upgrade to ${minimumRequiredPlan} or higher plan to create contacts and meetings in CRM through scheduler.`,
  schedulerPermissionDenied: {
    createPermissionDeniedLabel: ({
      currentPlan,
      pageName,
      minimumRequiredPlan
    }) =>
      `You are currently on a ${currentPlan} plan. To create ${pageName} scheduling pages, please upgrade to the ${minimumRequiredPlan} plan or above`,
    backToScheduler: 'Back to Scheduler'
  },
  list: {
    columns: {
      name: 'Name',
      created: 'Created On',
      location: 'Location',
      lastDate: 'Last Date'
    }
  },
  team: 'Team',
  member: 'Member',
  deactivatedMemberWarning: `Member is on a Basic plan. Please upgrade license to ${PLAN_NAMES.plus} or higher plan or remove member from the page.`,
  defaultPage: {
    heading: 'Allow us a moment to create the scheduler pages for you!',
    subheading: 'Meanwhile, you can create your own scheduler pages!'
  },
  noDescription: 'No Description',
  eventTitleUpgrade: 'Scheduler event title customization',
  crmIntegrationUpgrade: 'CRM integration',
  eventDescUpgrade: 'Scheduler description title customization',
  noResultsSchedulingPages: 'No pages created yet',
  zoomIntegration: {
    notConnected: 'Zoom account not connected.',
    connectToZoom:
      'You need to connect your Zoom account to enable the scheduling page.',
    goToSettings: 'Go to settings',
    toConnect: 'to connect.'
  },
  webexIntegration: {
    notConnected: 'WebEx account not connected.',
    connectToWebEx:
      'You need to connect your WebEx account to enable the scheduling page.',
    goToSettings: 'Go to settings',
    toConnect: 'to connect.'
  },
  actions: {
    copyTooltip: 'Copy link',
    goToLink: 'View scheduling page',
    goToLinkShort: 'View page',
    filterBy: 'Filter by:',
    inactive: 'Scheduling page is inactive'
  },
  headerTitle: 'Scheduler',
  popoverTitle: 'Scheduling Pages',
  navTitleMy: 'My Pages',
  navTitleTeam: "Teammates' Pages",
  navTitleRouter: 'Routers',
  navTitleReports: 'Reports',
  navTitleSchedulingPageTemplates: 'Managed Pages',
  navTitleScheduledPages: 'Scheduling Pages',
  navTitleShared: 'Shared Pages',
  navTitleManaged: 'Managed Pages',
  navTitleAll: 'All Pages',
  openScheduleEditor: 'Open Schedule Editor',
  getStarted: 'Get Started',
  reports: {
    dateRange: 'dateRange',
    startDate: 'startDate',
    endDate: 'endDate',
    meetingDateRange: 'meetingDateRange',
    meetingStartDate: 'meetingStartDate',
    meetingEndDate: 'meetingEndDate',
    meetingDate: 'Meeting Date',
    bookingDate: 'Booking Date',
    navTitleSchedulingReports: 'Scheduling Pages',
    navTitleRouterReports: 'Router'
  },
  deleteModal: {
    confirm: 'Yes, Delete',
    title: 'Delete',
    text: 'Once deleted, you will not be able to recover this scheduling page',
    routerText: 'Once deleted, you will not be able to recover this router',
    textForManagedPagesOnDeletion: 'Upon deletion,',
    textForManagedPagesRest:
      'will be unassigned from all users it was previously assigned to and the pages created using this template will be removed. Meetings scheduled already will continue to appear on the calendar.\nDo you wish to continue?'
  },
  noManagedPages: {
    heading: "Your organisation doesn't have any managed pages!",
    subHeading:
      'With Managed Pages, you can save time by creating and bulk assigning standard scheduling pages to members across your organization to provide a consistent scheduling experience.'
  },
  noRouters: {
    heading: "Your organization doesn't have any routers",
    subHeading:
      'With Routers, you can setup rules and logic to route your prospects and customers to the right scheduling page. Routers help you enhance your efficiency and optimize the process of connecting high value leads and customers with the right person on your team.'
  },
  cancelModal: {
    confirmText: 'Yes, Cancel',
    cancelText: 'No, Continue',
    title: 'Cancel "Scheduling Page Creation"?',
    text: 'Once canceled, you will not be able to recover the details added. You will be redirected to main page.'
  },
  routerCancelModal: {
    confirmText: 'Yes, Cancel',
    cancelText: 'No, Continue',
    title: 'Cancel creation of Router?',
    text: 'Once canceled, you will not be able to recover the details added.'
  },
  routerEditCancelModal: {
    confirmText: 'Yes, Cancel',
    cancelText: 'No, Continue',
    title: 'Cancel editing of Router?',
    text: 'Any unsaved changes you may have made to this router will be lost and you will be redirected to the routers listing page.'
  },
  routerEditFormChangeModal: {
    confirmText: 'Yes, Continue',
    cancelText: 'No, Cancel',
    title: 'Replace HubSpot form?',
    text: 'Replacing the HubSpot form you initially imported will delete the rules you’ve set in the routing rules section on those form fields. This action cannot be undone. Do you wish to continue?'
  },
  form: {
    crmHubspotDesc:
      'Create a new contact or update an existing one in HubSpot using the invitee information',
    crmSalesforceDesc:
      'Create a new lead/contact or update an existing one in Salesforce using the invitee information',
    crmDefaultDesc: 'Create a contact in CRM from the invitee information',
    addADesc: 'Add a description...',
    partialLockedManagedPageBanner:
      'Parts of this section have been locked by your Admin. If you want to request a change, contact your admin.',
    lockedManagedPageBanner:
      'This section have been locked by your Admin. If you want to request a change, contact your admin.',
    managedPageAvaialablityBanner:
      'Availability hours will be set in the time zone of members assigned to this page',
    lockAccordion: {
      pageInfoStepSubText:
        'Locking this section will prevent members to whom this page is assigned from making edits to any field except Location. Any updates you make will automatically sync.',
      subText:
        'Locking this section will prevent members to whom this page is assigned from making edits to any field. Any updates you make will automatically sync.',
      pageInfoAccordionText: 'Parts of this section are read only',
      accordionText: 'This section is read only',
      expandCollapse: 'Expand/Collapse'
    },
    urlValidation: 'Please enter a valid Url',
    durationsValidation: 'Please select a duration',
    organizer: 'Organizer',
    makeOrganizer: 'Make Organizer',
    crmIntegrationBanner: {
      connect: 'Connect',
      toCRM: 'to CRM to enable this setting.'
    },
    eventTitleDropdown: {
      addNewTooltip: 'Add variables to customize event title from here',
      organizerNameSubText:
        "Include the calendar event organizer's name in the event title.",
      companyNameSubText: "Include the invitee's company name in the title.",
      contactNameSubText: ' Include the invitee contact name in the title.'
    },
    selectVariable: 'Select to customize title',
    addToCustomizeDesc: 'Add to customize description',
    companyName: 'Company Name',
    contactName: 'Contact Name',
    organizerName: 'Organizer Name',
    nameHelperText:
      'This will be shown to users who visit your scheduling page to book a meeting.',
    internalNameHelperText:
      'The internal name will be used to identify this scheduling page internally. Invitees will not see this name.',
    eventTitle: 'Event Title',
    calendarDescription: 'Calendar Description',
    calendarTitle: 'Calendar Title',
    pageInformation: 'Page Information',
    calendarEventInfo: 'Calendar event information',
    pageName: 'Page Name',
    pageInternalName: 'Page Internal Name',
    pageDescription: 'Scheduling Page Description',
    previewTitle: 'Calendar Event Preview',
    eventTitlePlaceholder: 'e.g. Demo and discovery meeting with John Doe',
    namePlaceholder: 'Demo and discovery meeting',
    eventTitleHelperText:
      'This is the name of the calendar event you share with your prospect or customer. For example,',
    groupAvailableSlots:
      'Intersection of all available time slots for the members is considered for scheduling.',
    roundRobinAvailableSlots:
      'Union of all available time slots for the members is considered for scheduling.',
    approxSlots: 'Total available slots:',
    newUserDropdownText: 'Select the new user',
    calendarConnectHelperText:
      'Only the members with calendar connected will receive the calendar invite.',
    addPeopleBannerText:
      'Add Team members so a meeting can be scheduled with multiple people.',
    addPeopleRoundRobinBannerText:
      'Add members so a meeting can be scheduled with them one by one.',
    assignMembersBannerText:
      'A new page will be added to the accounts of assigned members',
    groupMembers: 'Group Members',
    calendarConnected: 'Calendar Connected?',
    calConnected: 'Calendar Connected',
    status: 'Status',
    booker: 'Booker',
    routerType: 'Router Type',
    schedulingPage: 'Scheduling Page',
    dailyCapacity: 'Daily capacity',
    dailyCapacityHelper:
      'Maximum number of daily meetings that can be scheduled for members.',
    weightage: 'Weightage',
    activeMemberWarningTooltip: 'At least one member must be active',
    weightageHelper:
      'Percentage of total leads members would receive. Sum of all weights should be equal to 100%',
    sumofAllMoreThanWarning:
      'Sum of weightages for enabled members should not be more than 100%',
    sumofAllLessThanWarning:
      'Sum of weightages for enabled members should not be less than 100%',
    queueInvalidMsg:
      'The Assignee queue has changed. Kindly save changes to update the queue',
    oneOnOne: 'One-on-One',
    inbound: 'Inbound',
    inboundSubtitle: 'Route inbound leads to the right reps.',
    handoff: 'Handoff',
    handoffSubtitle:
      'Book meetings on behalf of leads to hand them off to reps.',
    group: 'Group',
    roundRobin: 'Round Robin',
    currentQueue: 'Assignee queue',
    forNext10: '(For next 10 meetings)',
    fetchingUserError: 'Error while fetching users',
    licenseError: `Requires a ${PLAN_NAMES.plus}  or above license`,
    submitError:
      'There was error while creating the scheduling page. Try creating again.',
    webConf: 'Web Conference',
    setLoc: 'Set Location',
    cancelReason: 'Reason for Cancellation',
    optional: '(Optional)',
    schedulingPageName: 'Scheduling Page Name',
    schedulingPageInternalName: 'Scheduling Page Internal Name',
    name: 'Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    invalidEmail: 'Please enter a valid email address.',
    eventDescription: 'Event Description',
    eventDescriptionPlaceholder:
      'e.g Proposed agenda \n- Discuss your challenges and goals \n- Provide an overview of our solution',
    eventDescriptionHelpText:
      'Description of the event that is added to the calendar invitation sent to invitees when they schedule a meeting.',
    eventDescriptionHelperText:
      'Use the this field to specify the default agenda for the meeting. You can add a  summary of the details that invitees should know about the event. This will be included in the calendar invitation sent to invitees.',
    location: 'Location',
    locationType: {
      zoom: 'Zoom',
      gmeet: 'Google',
      phoneCall: 'Phone call',
      inPersonMtg: 'In Person',
      microsoft: 'Microsoft',
      webex: 'WebEx'
    },
    inviteeInformation: {
      fieldName: 'Field Name',
      mandatory: 'Mandatory for guests?',
      name: 'First Name/Last Name (required)',
      email: 'Email (required)',
      description: 'Agenda',
      role: 'Role',
      phone: 'Phone',
      company: 'Company',
      additionalGuests: 'Additional Guests'
    },
    placeholder: {
      email: 'Enter your email',
      name: 'Enter your name',
      firstName: 'Enter your first name',
      lastName: 'Enter your last name'
    },
    start: 'Start Time',
    end: 'End Time',
    locationHelpText: 'Location or conferencing details',
    phoneNumber: 'Phone Number',
    selectLocation: 'Select Location',
    slug: 'Scheduling Link',
    slugChange:
      'We recommend updating the URL at all places post edit. Old URL will be discarded.',
    slugHelp:
      'Public link which will be used by the invitees to schedule a meeting.',
    managedPageSlugHelp:
      'Public link which will be used by the invitees to schedule a meeting. The <username> in the link will be replaced by the username of the assigned member.',
    availability: 'Availability',
    availableHours: 'Available Hours',
    availabilityPreferences: 'Availability Preferences',
    distributionPreferences: 'Distribution Preferences',
    roundRobinReschedulePreferences: 'Reschedule Preferences',
    availabilityPreferencesHelper: {
      twoWays:
        'There are two ways you can manage the available hours for this event type:',
      sameHours:
        'Add hours that are applicable for all members part of this group. Members can not modify them and only organizer can change them.',
      differentHours:
        'Add different hours for each members. Members can also modify them as per their choice.',
      subText:
        'In both cases, setting availability hours for the members will affect their availability for this scheduling page type only and the available hours will be shown to the invitee as per the members time zone.',
      same: 'Same hours for all members:',
      different: 'Different hours for each member:'
    },
    availabilityPreferencesHelperText:
      'Determine how to select availability hours for all the members.',
    selectHow: 'Select how you want to set capacity',
    distributionHelperText:
      'Determine how meetings will be distributed amongst members.',
    roundRobinRescheduleHelperText:
      'If an invitee reschedules an event, who should it be assigned to',
    availabilityHelp:
      'Time slots with already marked events will be dropped automatically.',
    availableDaysInFuture: 'How far in the future can events be booked?',
    addAvailability: 'Add New',
    showMonthlyDistribution: 'Show Monthly Distribution',
    addMeetingCredits: 'Add Meeting Credits',
    monthlyDistribution: 'Monthly Distribution',
    countReset: '(*Count resets every month)',
    duration: 'Duration',
    durationHelp:
      'Define how long your event will be. It can be as long as 6 hours.',
    durationHeder: 'Duration',
    crmIntegration: 'Assign events to CRM record owners?',
    crmIntegrationHelp:
      'Check the invitee email in CRM & assign events to member who owns respective CRM record (Company/ Contact).',
    minBookingNotice: 'Minimum scheduling notice',
    setTimeIncrements: 'Set start time increments',
    minBookingNoticeHelp:
      'Set a minimum time for invitees before scheduling the new meeting.',
    setFreq: 'Show slot start times in the increments of',
    bufferTimeBefore: 'Buffer time before event',
    bufferTimeAfter: 'Buffer time after event',
    bufferTimeHelp: 'Give yourself time to prepare',
    bufferTimeAfterHelp: 'Give yourself wind-down time',
    futureLimit: 'Invitees can schedule into the future up to',
    futureLimitHelp: 'Set a future limit for the invitees.',
    futureLimitCount: 'Future Limit Count',
    futureLimitUnit: 'Future Limit Unit',
    days: 'Days',
    timing: 'Timing',
    until: 'until',
    inviteQuestion1: 'What info should guests provide?',

    inviteFieldName: 'First Name/Last Name (required)',
    inviteFieldEmail: 'Email (required)',
    inviteFieldAgenda: 'Agenda (optional)',
    inviteFieldAddEmail: 'Allow invitees to add additional guests',
    managedInviteInfoItem1:
      'All the meetings scheduled by external participants are automatically accepted.',
    inviteInfoItem1:
      'All the meetings scheduled by external participants are automatically accepted for you.',
    inviteInfoItem2:
      'The confirmation emails are sent to communicate new bookings and cancellations.',
    inviteInfoItem3:
      'Reminders to all meeting participants (Internal/External) will be sent via email as per the the preferences selected in Settings > Notifications >',
    inviteInfoLink: 'Email Notifications.',
    saveNext: 'Save & Next',
    saveClose: 'Save & Close',
    saveCreate: 'Save & Create',
    phoneLengthInstrustions:
      'Please enter a phone number that is 7 to 12 digits long',
    phoneLengthError: 'Enter a number that is 7-12 digits long',
    eventTitleValidation: 'This field may not be blank.',
    nameValidation: {
      noName: 'Please enter a name',
      sameName:
        'Scheduler with this name already exists! Try adding different name.'
    },
    slugValidation: {
      noSlug: 'Please enter a URL',
      sameSlug:
        'Scheduler with this link already exists! Try adding different link name.'
    },
    locaitionValidation: 'Please choose a location.',
    formValidationErrors: {
      blank: 'This field may not be blank.',
      notValidLocationType: '"" is not a valid choice.'
    },
    groupNotificationText: {
      calNotConnected:
        'Members need to connect their calendar to be part of the group scheduling and receive the new invites.'
    },
    availabilityHoursHeader: 'Availability Hours for All Members',
    availabilityHoursHelperTitle: 'Availability Hours',
    availabilityHoursHelperContent:
      'Time-slots with already marked events will be dropped automatically. Calls are scheduled based on the available time slot for each member.',
    managed: 'Managed',
    router: 'Router'
  },

  headerTooltips: {
    created: 'Date from which a meeting can be scheduled.',
    purpose:
      'Conversation purpose is set automatically for the meetings which are scheduled through this page.',
    status:
      'Deactivating a page will mean the invitees can not schedule new events with you'
  },
  errorPages: {
    pastEventHeader: 'Aaah! Scheduled time is in the past!',
    deletedEventHeader: "Aaah! Event doesn't exist!",
    pageNotFoundHeader: 'This page does not exist',
    modify: 'modify',
    cancel: 'cancel',
    scheduleAnotherMeeting: 'Schedule another meeting',
    noPagesFound: 'No pages found!',
    subHeadings: {
      youCannot: 'You can not',
      pastEvent: 'the meeting which is scheduled in the past!',
      deletedEvent: 'the meeting as the event does not exist.',
      eventNotFound: "Sorry, that scheduling page doesn't exist anymore."
    },
    noSlotsAvailableHeader: 'Sorry, there are no slots available!',
    noSlotsAvailableSubHeader: 'Please contact the scheduler page admin.',
    noTimeSlotsAvailableHeader: 'Looks like there are no time slots available!',
    noSlotsAvailableMonthCheck:
      'Please check back later or contact the host for alternative arrangements.'
  },
  freeMsSupport: 'Scheduler for Microsoft Free Accounts Coming Soon!',
  eventNotFound: 'Meeting does not exist',
  permissionDenied: 'You do not have access to Avoma Scheduler',
  mySchedulingPage: 'My Scheduling Page',
  mySchedulingPages: 'My Scheduling Pages',
  teamSchedulingPage: "Teammates' Scheduling Page",
  teamSchedulingPages: "Teammates' Scheduling Pages",
  sharedSchedulingPage: 'Shared Scheduling Page',
  sharedSchedulingPages: 'Shared Scheduling Pages',
  managedSchedulingPage: 'Managed Scheduling Page',
  managedSchedulingPages: 'Managed Scheduling Pages',
  noSchedulingPagesFound: 'No Scheduling Pages Found',
  routerSchedulingPage: 'Router',
  routerSchedulingPages: 'Routers',
  reschedulePage: 'Reschedule the Meeting',
  schedulePageFetch: 'Error occurred while fetching scheduling pages',
  cancelPage: 'Cancel the Meeting',
  cancelSuccess: 'Successfully cancelled the event.',
  schedulePage: 'Schedule Meeting',
  scheduleSuccess: 'Successfully scheduled the event.',
  youAreMeetingWith: 'You are meeting with',
  scheduledEventFor: 'Successfully scheduled the event for',
  scheduleCancelSuccess: 'Successfully canceled the event.',
  scheduleEventUnSuccessful: 'Could not schedule the event.',
  scheduleEventCancelUnSuccessful: 'Could not cancel the event.',
  scheduleEventSlotNotAvailable:
    'The time slot you selected is no longer available',
  createSchedulingPage: 'Create Scheduling Page',
  emptySchedulingPage:
    'Create your personalized scheduling page now and step up your scheduling game!',
  createManagedSchedulingPage: 'Create Managed Page (One-on-One)',
  oneOnOneCreateSchedulingPageHeader: 'Create One-on-One Scheduling Page',
  groupCreateSchedulingPageHeader: 'Create Group Scheduling Page',
  roundRobinCreateSchedulingPageHeader: 'Create Round Robin Scheduling Page',
  managedCreateSchedulingPageHeader: 'Create a Managed Page',
  HubspotRoutingCreateSchedulingPageHeader: 'HubSpot Routing Page',
  schedulerEventUnSuccessfulBanner:
    'Error occured while performing the action. Please try again!',
  createForm: {
    infoStep: 'Event Information',
    scheduleStep: 'Scheduling Preferences',
    inviteStep: 'Invitee Information',
    assignMembersStep: 'Members Assigned',
    redirectStep: 'Confirmation Page Preferences',
    groupMemberStep: 'Group Members Information',
    roundRobinMemberStep: 'Round Robin Members Information',
    crmSettingsStep: 'CRM Settings'
  },
  crmSettings: {
    setAssignedRepAsContactOwner: 'Set the meeting host as the contact owner',
    createMeetingInCrm: 'Create a meeting in ',
    yesProceed: 'Yes, Proceed',
    routerWarning:
      'If this page is added as a route in Router, the CRM settings in Router will override the above settings for bookings made through Router.'
  },
  schedulerEmbedType:
    'How do you want to show Avoma Scheduler on your website?',
  schedulerEmbedInline: 'Embed inline on a page',
  schedulerEmbedPopup: 'Show in a popup on button click',
  copyPasteHelperText:
    'Copy and paste this code into your website to seamlessly integrate your scheduler.',
  locationHelperPart1: `Use the “Location” field to specify how and where both parties will connect at the scheduled time.`,
  locationHelperPart2: `The location entered here will appear on the calendar event added to both you and your invitee's calendars.`,
  newToAvoma: 'New to Avoma?',
  trialEnrollment: 'Use it for free with unlimited events',
  trialEnrollmentMobile: 'Use it for free!',
  agendaHelperText:
    'Add agenda you want to discuss. This agenda will be shared via invitation email.',
  webConfInfo: 'Web conferencing details provided upon confirmation',
  inPersonConfInfo:
    'Web conferencing details are not applicable for in-person meetings',
  schedulingNotificationMsg:
    "You'll receive the calendar invitation after scheduling.",
  reschedulingNotificationMsg:
    "You'll receive the calendar invitation after re-scheduling.",
  successCalendarEventMsg:
    'A calendar invitation has been sent to your email address:',
  successHandoffEventMsg:
    "A calendar event has been sent to prospect's email address",
  conversationPurpose: 'Conversation Purpose',
  confirmationPagePreferences: {
    redirect: {
      afterConfirmationLabel: 'After the booking is confirmed ',
      radioOptions: {
        defaultPage: 'Display booking confirmation page',
        externalLink:
          'Display booking confirmation and redirect to an external page '
      },
      urlTextBoxPlaceHolder: 'Enter URL',
      urlValidationError: 'Please enter a valid URL eg: https://www.avoma.com',
      passEventInformationCheckbox:
        'Pass event information to the external page.',
      disabledTooltip: 'Licence unavailable',
      featureNotAllowedMessage:
        'You are on a Basic plan. Upgrade to redirect invitees to an external site.',
      redirectingText: 'Redirecting in '
    }
  },
  router: {
    reports: {
      filterByBookingStatus: 'Filter by status',
      filterByPageType: 'Filter by Router Type',
      filterBySchedulingPage: 'Filter by Scheduling Page',
      filterByBooker: 'Filter by Booker',
      viewSchPage: 'View scheduling page',
      goto: 'Go to',
      copyEmailSuccess: 'Email copied to clipboard',
      viewReport: 'View Report',
      routerReport: 'Router report:',
      noMeetingsBooked: 'Lead did not book a meeting',
      copyMeetingUrl: 'Copy meeting URL',
      leadDistribution: 'Lead Distribution',
      formDistruibution: 'Form Distribution',
      formDistruibutionInfo:
        'How were the leads from form submissions distributed among different reps?',
      bookingActivity: 'Booking Activity',
      leadDistributionInfo:
        'How are leads from form submissions being distributed among different reps?',
      formSubmissions: 'Form Submissions',
      formSubmissionsInfo: 'Status of bookings made via routers',
      meetingsStatus: 'Meetings’ Status',
      schedulerMeetingsStatusInfo:
        'Status of meetings booked via scheduling pages',
      meetingsStatusInfo: 'Status of meetings booked via routers',
      inviteeHistory: 'Invitee History',
      inviteeDetails: 'Invitee Details',
      emptySankey:
        'No leads have been distributed yet. Please check back later.',
      na: 'N.A',
      deleteRecord: 'Delete Record'
    },
    notAllowedToBook:
      'You are not listed as a Booker in this Handoff Router. Only Bookers added to the Handoff Router can schedule meetings on behalf of prospects.',
    featureGate: {
      adminGating:
        'You need admin privileges to create a router. Please contact your',
      title: 'Upgrade your license to access Router',
      contentTitle: `Upgrade to ${PLAN_NAMES.premium} plan to access Router.`,
      subtitlesHeading: 'With access to Router, you will be able to:',
      subtitlesPoints: [
        'Connect HubSpot form to set up routing conditions',
        'Define rules to route leads to appropriate reps',
        'Set up ownership conditions to ',
        'View reports to monitor how leads are being distributed'
      ],
      altText: 'Upgrade your license to access Router preview image'
    },
    advancedSettings: {
      createEventInHubspot: 'Create a meeting in HubSpot',
      createEventInSalesForce: 'Create a meeting in Salesforce',
      abandonBookings: 'Abandoned Bookings',
      emailHelperText: 'Enter email addresses with comma separator',
      abandonBookingHelperText:
        'If a prospect does not book meeting within set time after the scheduling page is shown, Avoma will consider the booking as abandoned.',
      formPreview: 'Form Preview',
      advancedSettings: 'Advanced Settings',
      whenBookersBookAMeeting: 'When bookers book a meeting',
      whenProspectBooks: 'When prospects book a meeting',
      setOwnerHubspot: 'Set the assigned rep as the contact owner in',
      setOwnerSalesforce: 'Set the assigned rep as the lead/contact owner in',
      prospectAbandons: 'When prospects abandon the booking process',
      prospectTimeout: "When prospects don't book a meeting within",
      doTheFoll: 'mins, do the following :',
      distributeLeads:
        'Distribute the leads to appropriate reps in Avoma based on routing rules',
      sendEmail: 'Send an email to the rep with the details of the prospect',
      ccTo: 'Also, CC the email addresses specified below',
      setAssignedOwnerHubspot:
        'Set the assigned rep as the contact owner in HubSpot even if prospects leave without booking a meeting.',
      setAssignedOwnerSalesforce:
        'Set the assigned rep as the lead/contact owner in Salesforce even if prospects leave without booking a meeting',
      setAssignedOwnerUnknown:
        'Set the assigned rep as the lead/contact owner in CRM even if prospects leave without booking a meeting',
      notSupported: 'These settings are not supported for your CRM',
      confirmPromptHubspot:
        "Enabling the 'Create a meeting in HubSpot' setting will generate a meeting engagement in HubSpot for each booking through Router.",
      confirmPromptSalesforce:
        "Enabling the 'Create a meeting event in Salesforce' setting will generate a meeting event in Salesforce for each booking through Router."
    },
    noDataForFilters: 'No data available for the selected filters',
    reportListViewHelperText: {
      created: 'The date on which an invitee makes a form submission',
      rulesTriggered:
        'The criteria that the form submission satisfied to route the invitee appropriately',
      routedTo: 'The destination the invitee was routed to',
      assignedTo:
        "Details of the assignee to whom the invitee's meeting was assigned",
      eventDetails:
        "Details of the meeting when invitee books an event on the assignee's calendar"
    },
    reportsEventDetails: {
      what: 'What',
      where: 'Where',
      dateTime: 'Date / Time',
      invitee: 'Invitee',
      meetingLink: 'Meeting Link',
      viewMeeting: 'View meeting'
    },
    defaultCustomMessage:
      'Thanks for your interest. We are unable to schedule any meetings for you at the moment.',
    ruleHeader: {
      ifGoto: 'If ... go to ...',
      thenGoto: 'then go to',
      deletedValue: '[Deleted]',
      deletedType: 'Deleted'
    },
    formError: {
      formDeleted:
        'The HubSpot form you previously imported has been deleted. All the routing conditions set on the form fields have been deleted as well. Please select another form',
      formFieldDeleted:
        'One or more form fields were deleted from the HubSpot form you imported. The conditions that you set on those form fields are now invalid. Please update the condition in this routing rule.',
      ruleActionPageDeleted:
        'The scheduling page you had previously set in this routing rule does not exist anymore. Please update the route in the routing rule'
    },
    multiInputValue: {
      placeholder: 'Enter comma separated keywords',
      tooltip: 'Click to update or delete'
    },
    createNewRouter: 'Create new router',
    crmConditions: 'CRM Conditions',
    formFields: 'Form Fields',
    crmConditonsDisabledTooltip:
      'You cannot add both form field & CRM condition in 1 rule. Create a new rule to add CRM condition.',
    selectValues: 'Select Values',
    enterValue: 'Enter Value',
    routeTo: 'ROUTE TO',
    routerDetails: 'Router Details',
    routerName: 'Router Name',
    createRouterButton: 'Import from HubSpot',
    createInboundRouter: 'Create Inbound Router',
    createHandoffRouter: 'Create Handoff Router',

    createRouterSubtext:
      'Set routing rules on form fields imported from HubSpot',
    namePlaceholder: 'Enter router name',
    addbookers: 'Add bookers who will be able to relay the lead',
    selectHubspotForm: 'Select HubSpot Form',
    selectMarketoForm: 'Select Marketo Form',
    refreshFormSync: 'Sync forms',
    refreshFormsToolipLoading: 'Syncing forms data ...',
    refreshFormsToolip: 'Sync forms',
    noFormsFound: 'No forms found',
    hubspot: {
      disconnectHubspot:
        'Disconnect HubSpot first to connect and import Marketo forms in Avoma routers',
      connectYourHubspot:
        "Connect your HubSpot account to import forms and set up lead routing with Avoma's Lead Router.",
      connectedHsThrough: 'Connected to HubSpot through'
    },
    marketo: {
      createBtnTitle: 'Import from Marketo',
      createBtnSubTitle:
        'Set routing rules on form fields imported from Marketo',
      disconnectMarketoTemplate: 'Disconnect Marketo',
      connectMarketo:
        "Connect your Marketo account to import forms and set up lead routing with Avoma's Lead Router.",
      disconnectMarketo:
        'Disconnect Marketo first to connect and import HubSpot forms in Avoma routers',
      connectedMarketoThrough: 'Connected to Marketo account with ID : ',
      connectToMarketo: 'Enter your Marketo credentials',
      connectingToMarketo: 'Connecting to Marketo',
      clientSecret: 'Client Secret',
      clientId: 'Client ID',
      munchkinId: 'Munchkin ID',
      clientIdPlaceholder: 'e.g. 213jghseh-98912fkkisdf-689ksndfk',
      clientSecretPlaceholder: 'e.g. hkahskuyehkjsfkjhefhkhewkhjksdhklejf',
      munchkinIdPlaceholder: '889-JWl-889',
      disconnectMarketoModalTitle: 'Disconnect Marketo',
      disconnectMarketoModalDescription:
        'Are you sure you want to disconnect Marketo? This will stop syncing forms from Marketo to Avoma.'
    },
    noCrmConnected: 'No CRM connected',
    formPreview: 'Form Preview',
    hubspotFormLinkWarningText:
      'You must connect to HubSpot CRM to import an existing HubSpot form.',
    connectHubspot: 'Connect HubSpot',
    addRule: '+ Rule ',
    enterExternalUrl: 'Enter External URL',
    selectPage: 'Select a Page',
    routerCreated:
      'Router created. Embed this router on your website to start routing invitees to the right scheduling pages.',
    selectField: 'Select Field',
    selectOperator: 'Select Operator',
    selectValue: 'Select Value',
    embedRouterModalTitle: 'Embed Router',
    embedCodeSnippet: 'Embed Code Snippet',
    embedInstructionInline:
      'Place this code on the page on your website where you want this scheduling page to appear.',
    embedInstructionPopup:
      "Insert the code below into your HTML to display a button. When clicked, this button will open the scheduling page in a popup. Customize the button's appearance by adjusting the CSS style.",
    triggerOptionsTitle: 'When do you want the router to be displayed ?',
    afterFromSubmission: 'After form submission',
    onButtonClick: 'On button click',
    embedHelpText:
      'Copy and paste the below script onto your website after the HubSpot form code. After submitting the HubSpot form, Avoma will execute routing logic and show the appropriate scheduler in a modal on your website.',
    embedHelpTextMarketo: `Copy and paste the below script onto your website after the Marketo form code. After submitting the Marketo form, Avoma will execute routing logic and show the appropriate scheduler in a modal on your website.`,
    ruleAnd: '+ and',
    ruleAndToolTipForCrm: 'You can add only one CRM condition in a rule',
    routerRules: 'Router Rules',
    routerRulesPopoverText:
      'Set routing rules on form fields and CRM conditions to route invitees to the right scheduling pages. The rules are executed in the same order as specified',
    managedPageNotification:
      'We will route the invitee to the owner of the matching CRM record only if this managed page is assigned to the owner of the CRM record.',
    formSubmissions: 'Form submissions',
    bookingsdone: 'Bookings done',
    bookingsAbandoned: 'Bookings abandoned',
    meetingsCompleted: 'Meetings completed',
    meetingsCanceled: 'Meetings canceled',
    meetingsNoShow: 'No show',
    bookingsCancelled: 'Bookings canceled'
  },
  memberDeactivated: 'Member Deactivated',
  pageStatus: {
    activateTooltip: 'Reactivate',
    deactivateTooltip: 'Deactivate',
    deactivateTooltipPageMember: 'Only page owners can change the status',
    disabledCtaTooltip: 'Activate Scheduling page',
    confirmModal: {
      routerTitle: 'Deactivate Router?',
      routerDescription:
        'Deactivating the router will stop routing the invitees to the right scheduling pages based on the rules configured in this router.',
      title: 'Deactivate Scheduling page?',
      description:
        'You can reactivate this scheduling page at any time in the future. Note that for group scheduling pages, all members will be notified via email of this change.',
      confirmCta: 'Yes, Deactivate'
    },
    managedConfirmModal: {
      title: 'Deactivate this managed page ?',
      description:
        'Deactivating this managed page will also deactivate the instances of this managed page assigned to members. Do you wish to proceed?',
      confirmCta: 'Yes, Proceed'
    },
    active: 'Active',
    inactive: 'Inactive',
    successfulActivation: 'Scheduling page is active.'
  },
  selectTime: 'Select Time',
  selectDuration: 'Select Duration',
  editSuccessfulMessage: 'Changes saved successfully!',
  editFailureMessage: 'Changes not saved',
  errorGettingSchedulerPageInformation:
    'Error getting scheduler page information',
  errorGettingSlotsCount: 'Error slots count for scheduler page',
  errorGettingSlots: 'Error slots for scheduler page',
  createFailureMessage: 'Could not create scheduling page',
  noDaysSelectedBanner: 'Please select available days to proceed.',
  crmIntegrationBanner: {
    mainText: 'Members will be assigned according to',
    distributionPreferences: '"Distribution Preferences"',
    if: 'If,',
    not: 'not',
    inviteeEmail: 'Invitee email is',
    matchWithCRM: 'matched with CRM record (Company/ Contact).',
    crmRecordOwner: 'CRM Record owner is',
    partOfRoundRobin: 'a part of this Round Robin event.'
  },
  multiDuration: {
    multipleDurations: 'Multiple durations',
    default: 'Default',
    hours: 'hours',
    minutes: 'minutes',
    makeItDefault: 'Make it Default'
  },
  schedulerSettings: {
    imageSizeError: 'File too large! Max size 2MB.',
    brandingPreference: 'Branding Preferences',
    displayYourOwnLogo: 'Display your own logo',
    displayYourOwnLogoDescription: `Upload your company logo to have it displayed at the top-left
    corner of the scheduling pages and all scheduler related email
    notifications.`,
    supportedFormats: 'Supported formats - PNG, JPG, JPEG',
    maximumDimensions: 'Maximum dimensions - 160px x 40px | Max size 2MB',
    ctaToggleHeader: 'Remove Avoma branding',
    ctaToggleHeaderDescription:
      "Enable this setting to remove Avoma branding from your organization's scheduling pages.",
    purchaseLicensebanner:
      'Please upgrade to change the branding preferences for scheduling pages.'
  },
  handoffBookingTitle: `Fill out prospect's information to schedule a meeting`
});

export default L10Scheduler;
