export const enum EAutomationActionType {
  CLICKUP_TASK = 'action.create.clickup.task',
  HUBSPOT_TASK = 'action.create.hubspot.task',
  MEETING_PURPOSE = 'action.set.meeting.purpose'
}

export const enum EAutomationActionRules {
  TASK_TITLE = 'task_title',
  TASK_DESCRIPTION = 'task_description',
  TASK_WORKSPACE = 'task_workspace',
  TASK_LIST = 'task_spaces_and_list',
  TASK_TEMPLATE = 'task_template',
  TASK_DUE_DATE = 'task_due_date',
  TASK_ASSIGNEE = 'task_assignee',
  TASK_TYPE = 'hs_task_type',
  TASK_PRIORITY = 'hs_task_priority',
  MEETING_PURPOSE = 'meeting_purpose',
  MEETING_OUTCOME = 'meeting_outcome'
}

export const enum EAutomationActionAssigneeOptions {
  MEETING_HOST = 'meeting_host',
  RESP_USER_FOR_NOTES_EXT = 'resp_user_for_notes_ext',
  CLICKUP_MEMBER = 'clickup_member',
  HUBSPOT_MEMBER = 'hubspot_member'
}
