import React from 'react';
import PropTypes from 'prop-types';
import { useSlateStatic, ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import Tokens from 'styles/tokens';

const CheckListItemElement = ({ attributes, children, element, readOnly }) => {
  const editor = useSlateStatic();
  const { checked } = element;
  return (
    <li style={styles.container} {...attributes}>
      <span contentEditable={false}>
        <input
          type='checkbox'
          checked={checked}
          onChange={event => {
            const path = ReactEditor.findPath(editor, element);
            const newProperties = {
              checked: event.target.checked
            };
            Transforms.setNodes(editor, newProperties, { at: path });
          }}
          contentEditable={false}
          className='disable-select'
          style={styles.checkbox}
          disabled={readOnly}
          // tabIndex='-1'
        />
      </span>
      <span
        contentEditable
        suppressContentEditableWarning
        style={{
          ...styles.content,
          ...(checked
            ? { opacity: 0.4, textDecoration: 'line-through' }
            : { opacity: 1, textDecoration: 'none' })
        }}
      >
        {children}
      </span>
    </li>
  );
};

const styles = {
  container: {
    display: 'flex',
    marginLeft: `-${Tokens.spacing.half}`
  },
  checkboxContainer: {
    marginRight: Tokens.spacing.one
  },
  content: {
    flex: 1,
    outline: 'none',
    marginLeft: Tokens.spacing.half
  },
  checkbox: {
    width: Tokens.spacing.two,
    height: Tokens.spacing.two,
    flexShrink: 0,
    cursor: 'pointer',
    userSelect: 'none'
  }
};

CheckListItemElement.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  element: PropTypes.shape({
    checked: PropTypes.bool
  }),
  readOnly: PropTypes.bool
};

export default CheckListItemElement;
