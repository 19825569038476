/* eslint-disable avoma/use-svgr-over-svg */
import React, { PureComponent } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import Tokens from 'styles/tokens';
import AvomaIcon from 'components/Common/AvomaIcon';

import Crm from 'helpers/Crm';
import HubspotContactsIcon from 'images/ic_hubspot_contacts.svg';
import HubspotCompaniesIcon from 'images/ic_hubspot_company.svg';
import HubspotDealsIcon from 'images/ic_hubspot_deals.svg';
import HubspotIcon from 'images/ic_hubspot_sprocket.svg';

import SalesforceAccountsIcon from 'images/ic_sfdc_account_square.svg';
import SalesforceLeadsIcon from 'images/ic_sfdc_lead_square.svg';
import SalesforceOpportunityIcon from 'images/ic_sfdc_opp_square.svg';
import SalesforceIcon from 'images/ic_salesforce_blue.svg';
import salesforceContactIcon from 'images/salesforceContact.svg';

import ZendesksellIcon from 'images/ic_zendesk_logo.svg';
import ZendesksellDealsIcon from 'images/zendesksell_deal.svg';
import ZendesksellCompaniesIcon from 'images/zendesksell_company.svg';
import ZendesksellLeadIcon from 'images/zendesksell_lead.svg';

import CopperIcon from 'images/copper_icon.svg';
import CopperLeadIcon from 'images/copper_lead.svg';
import CopperDealIcon from 'images/copper_deal.svg';
import CopperAccountIcon from 'images/copper_account.svg';

import PipedriveIcon from 'images/Pipedrive_letter_logo_light.svg';
import PipedriveLeadIcon from 'images/pipedrive_contact.png';
import PipedriveDealIcon from 'images/pipedrive_deal.png';
import PipedriveAccountIcon from 'images/pipedrive_org.png';

import ZohoIcon from 'images/ic_zoho_logo.svg';

class CrmIcon extends PureComponent {
  selectHubspotIcon = type => {
    switch (type) {
      case Crm.TAGS.lead:
        return HubspotContactsIcon;
      case Crm.TAGS.account:
        return HubspotCompaniesIcon;
      case Crm.TAGS.oppo:
      case Crm.TAGS.oppo_h:
        return HubspotDealsIcon;
      default:
        return HubspotIcon;
    }
  };

  selectZendesksellIcon = type => {
    switch (type) {
      case Crm.TAGS.lead:
        return ZendesksellLeadIcon;
      case Crm.TAGS.account:
        return ZendesksellCompaniesIcon;
      case Crm.TAGS.oppo:
      case Crm.TAGS.oppo_h:
        return ZendesksellDealsIcon;
      default:
        return ZendesksellIcon;
    }
  };

  selectCopperIcon = type => {
    switch (type) {
      case Crm.TAGS.lead:
        return CopperLeadIcon;
      case Crm.TAGS.account:
        return CopperAccountIcon;
      case Crm.TAGS.oppo:
      case Crm.TAGS.oppo_h:
        return CopperDealIcon;
      default:
        return CopperIcon;
    }
  };

  selectPipedriveIcon = type => {
    switch (type) {
      case Crm.TAGS.lead:
        return PipedriveLeadIcon;
      case Crm.TAGS.account:
        return PipedriveAccountIcon;
      case Crm.TAGS.oppo:
      case Crm.TAGS.oppo_h:
        return PipedriveDealIcon;
      default:
        return PipedriveIcon;
    }
  };

  selectZohoIcon = () => ZohoIcon;

  selectSalesforceIcon = type => {
    switch (type) {
      case Crm.TAGS.lead:
        return SalesforceLeadsIcon;
      case Crm.TAGS.account:
        return SalesforceAccountsIcon;
      case Crm.TAGS.oppo:
      case Crm.TAGS.oppo_h:
        return SalesforceOpportunityIcon;
      case Crm.TAGS.contact:
        return salesforceContactIcon;
      default:
        return SalesforceIcon;
    }
  };

  render() {
    const { crm, type, style, className = '' } = this.props;
    if (crm === null) {
      return null;
    }
    let icon = SalesforceIcon;
    switch (crm) {
      case Crm.ENUM.pipedrive:
        icon = this.selectPipedriveIcon(type);
        break;
      case Crm.ENUM.copper:
        icon = this.selectCopperIcon(type);
        break;
      case Crm.ENUM.zoho:
        icon = ZohoIcon;
        break;
      case Crm.ENUM.zsell:
        icon = this.selectZendesksellIcon(type);
        break;
      case Crm.ENUM.hubspot:
        icon = this.selectHubspotIcon(type);
        break;
      case Crm.ENUM.salesforce:
      default:
        icon = this.selectSalesforceIcon(type);
        break;
    }

    return (
      <AvomaIcon
        style={{
          ...styles.icon,
          ...(type ? {} : { marginLeft: '-2px' }),
          ...style
        }}
        small
        icon={icon}
        className={className}
      />
    );
  }
}

const styles = {
  icon: {
    width: Tokens.spacing.two,
    height: Tokens.spacing.two
  }
};

CrmIcon.propTypes = {
  crm: PropTypes.string,
  type: PropTypes.string.isRequired,
  style: stylePropType,
  className: PropTypes.string
};

CrmIcon.defaultProps = {
  crm: null
};

export default Radium(CrmIcon);
