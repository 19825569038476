import { createBrowserHistory } from 'history';
import React from 'react';
import { createTracker } from 'redux-segment';
import thunk from 'redux-thunk';

import {
  initialCompaniesCount,
  initialCompanyFiltersQuery,
  initialDealFiltersQuery,
  initialDealsCount,
  initialFilterCount,
  initialFiltersQuery
} from 'reducers/dashboard';

import { baseCreateApi } from 'services/index';

import socketMiddleware from 'helpers/websockets';

import * as Sentry from '@sentry/react';
import packageInfo from '../../package.json';
// eslint-disable-next-line import/no-cycle
import reducers from '../reducers';
import env from './env';
// eslint-disable-next-line import/no-cycle
import { UNAUTHORIZED } from './http';
import { MY_MEETINGS } from './meetingCategory';
import { configureStore } from '@reduxjs/toolkit';

const tracker = createTracker();
// eslint-disable-next-line no-console
console.log(packageInfo.version);
if (['int', 'prod', 'staging'].includes(env.name)) {
  let projectName = 'frontend';
  if (env.name === 'int') {
    projectName = 'frontend-int';
  } else if (env.name === 'staging') {
    projectName = 'frontend-staging';
  }

  const history = createBrowserHistory();

  const integrations = [
    Sentry.reactRouterV4BrowserTracingIntegration({
      useEffect: React.useEffect,
      history
    })
  ];

  if (env.name === 'prod') {
    integrations.push(
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true
      })
    );
  }

  Sentry.init({
    dsn: env.sentryDsn,
    release: `${projectName}@${packageInfo.version}`,
    allowUrls: [env.avomaSchedulerUrl, env.avomaFrontendUrl],
    denyUrls: ['pagead2.googlesyndication.com'],
    replaysOnErrorSampleRate: 1.0,
    integrations,
    tracesSampleRate: 0.2,
    tracePropagationTargets: [env.avomaApiUrl],
    ignoreErrors: [
      // This is to filter out this very specific error. This error is actually not an error, but a buggy microsoft "safety" service.
      // Microsoft does a safety check on links in microsoft products such as outlook and teams, which involves verifying the link in azure dc
      // The code they use does not handle things properly, so they just verify in their datacenters and throw this error for some reason
      // See this sentry sdk issue for reference - https://github.com/getsentry/sentry-javascript/issues/3440
      /^Non-Error promise rejection captured with value: Object Not Found Matching Id:[0-9], MethodName:update, ParamCount:[0-9]/
    ],
    beforeSend(event) {
      // Modify or drop the event here

      if (
        Array.isArray(event?.exception?.values) &&
        event.exception.values.some(value => value.type === UNAUTHORIZED)
      ) {
        return null;
      }

      const breadcrumbs = event?.breadcrumbs ?? [];
      const isGtmRelatedError = breadcrumbs.some(crumb =>
        crumb?.data?.url?.includes('https://pagead2.googlesyndication.com')
      );
      if (isGtmRelatedError) {
        return null;
      }
      return event;
    }
  });
}

export const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('reduxState');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return null;
  }
};

export const saveStateToLocalStorage = state => {
  const serializedState = JSON.stringify(state);
  try {
    localStorage.setItem('reduxState', serializedState);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Could not access Localstorage');
  }
};

// eslint-disable-next-line no-shadow
const saveDefaultStatesToLocalStorage = store => {
  saveStateToLocalStorage({
    // Save reinitialized state
    user: store.getState().user,
    onboarding: store.getState().onboarding,
    app: store.getState().app,
    accounts: store.getState().accounts,
    audioPlayer: {
      playbackRate: store.getState().audioPlayer.playbackRate
    },
    members: {
      data: [],
      disabledSharingEmails: [],
      customReminders: {
        allowUserLevelSettings:
          store.getState().members?.customReminders?.allowUserLevelSettings ||
          true
      },
      organization: {
        orgSettingsData: {
          wasDialerConnected:
            store.getState().members?.organization?.orgSettingsData
              ?.wasDialerConnected
        }
      }
    },
    // Just saving the code to localStorage and an empty object for data
    // because saving the entire data may cause it to have stale data if the user
    // hasn't logged in while a patch has been deployed with changes revolving around public view
    publics: {
      code: store.getState().publics.code,
      data: {}
    }
    // meetings: store.getState().meetings,
  });
};

let store = null; // eslint-disable-line import/no-mutable-exports

if (!store) {
  const localStorageState = loadStateFromLocalStorage();
  const persistedState = {
    // TODO: these we're left here temporarily for people who still had the state
    // in their localStorage. If you're reading this, the next 4 lines can be deleted
    app: {
      sidebarVisible: true,
      navbarExpanded: true,
      noteAndTranscriptLinkedAt: null,
      noteAndTranscriptLinkedUuid: null,
      meetingCategory: MY_MEETINGS,
      currentMeetingId: null,
      createHighlight: {},
      login: {},
      notificationBanner: {},
      layoutId: localStorageState?.app?.layoutId || 'layout-equal',
      secondaryPanelWidth: localStorageState?.app?.secondaryPanelWidth || null,
      homepage: localStorageState?.app?.homepage || 1,
      visibleTemporaryBanners: {
        callsMigration: undefined
      }
    },
    ...(localStorageState || {})
  };

  persistedState.app.historyStack = [];
  persistedState.app.meetingCategory = '';
  persistedState.app.currentMeetingId = '';
  persistedState.app.scrollToTranscriptLink = false;
  persistedState.app.noteAndTranscriptLinkedAt = null;
  persistedState.app.noteAndTranscriptLinkedUuid = null;
  persistedState.app.createHighlight = {};
  persistedState.app.login = {};
  persistedState.app.skipTimezoneCheck = false;
  if (!persistedState.app.homeTiles) {
    persistedState.app.homeTiles = {
      recording: false,
      crm: false,
      keywords: false,
      templates: false,
      invites: false
    };
  }

  // If the users filter follows the older datastructure of Array of Objects
  // loop and only include emails
  if (
    typeof localStorageState?.dashboard?.filter?.allMeetings?.team
      ?.users?.[0] === 'object'
  ) {
    const filteredUserEmails =
      localStorageState.dashboard.filter.allMeetings.team.users.map(
        user => user.email
      );

    persistedState.dashboard.filter.allMeetings.team.users = filteredUserEmails;
  }

  if (
    typeof localStorageState?.dashboard?.filter?.allMeetings?.team
      ?.organizers?.[0] === 'object'
  ) {
    const filteredOrganizersEmails =
      localStorageState.dashboard.filter.allMeetings.team.organizers.map(
        organizer => organizer.email
      );

    persistedState.dashboard.filter.allMeetings.team.organizers =
      filteredOrganizersEmails;
  }

  // Reset some state that keeps track of requests
  if (persistedState.meetings) {
    persistedState.meetings.error = null;
    persistedState.meetings.fetching = 0;
    persistedState.meetings.fetchingAMeeting = 0;
    persistedState.meetings.sharing = 0;
    persistedState.meetings.searchData = {};
    persistedState.meetings.allMeetings = {};
    persistedState.meetings.searchingInviteeCandidates = 0;
    if (!persistedState.meetings.account) {
      persistedState.meetings.account = {};
    }
    if (!persistedState.meetings.member) {
      persistedState.meetings.member = {};
    }
    if (!persistedState.meetings.team) {
      persistedState.meetings.team = {};
    }
    if (!persistedState.meetings.home) {
      persistedState.meetings.home = {
        past: {},
        upcoming: {}
      };
    } else if (!persistedState.meetings.home.past) {
      persistedState.meetings.home.past = {};
    }
  }
  if (persistedState.accounts) {
    persistedState.accounts.searching = 0;
    persistedState.accounts.fetching = 0;
    persistedState.accounts.data = persistedState.accounts.navList.slice();
    persistedState.accounts.searchData = [];
  }
  if (persistedState.user) {
    persistedState.user.error = null;
    persistedState.user.microsoftLoginError = null;
    persistedState.user.loading = null;
    persistedState.user.zoomLoading = null;
    persistedState.user.zoomAppLoading = null;
    persistedState.user.subscriptionLimits = {};
    // Hide zoom errors if its older than a minute
    const zoomErrorTime = new Date(persistedState.user.zoomErrorTime);
    const now = new Date();
    if (zoomErrorTime && (now - zoomErrorTime) / 1000 > 60) {
      persistedState.user.zoomError = null;
      persistedState.user.zoomErrorTime = null;
    }
    persistedState.user.calendarSyncRetryCount = 0;
  }
  if (persistedState.dashboard) {
    persistedState.dashboard.fetching = false;
    persistedState.dashboard.error = null;
    persistedState.dashboard.overallStats = null;
    persistedState.dashboard.perUserStats = null;
    persistedState.dashboard.talkToListen = null;
    persistedState.dashboard.trends = {};
    persistedState.dashboard.trackers = {};
    persistedState.dashboard.tab = 'stats';
    persistedState.dashboard.conversation = {
      data: {},
      error: null,
      fetching: null
    };
    persistedState.dashboard.activityStats = null;
    persistedState.dashboard.fetchingActivityStats = false;
    persistedState.dashboard.topics = {};
    persistedState.dashboard.filter = {
      ...persistedState.dashboard.filter,
      stats: {
        query: initialFiltersQuery,
        count: initialFilterCount,
        more: {
          showUnrecordedMeetings: true,
          includeInternalMeetings: true,
          showInternalOnly: false
        }
      },
      deals: {
        query: initialDealFiltersQuery,
        count: initialDealsCount,
        dealStatus: '',
        forecastCategory: '',
        sortBy: '',
        pipeline: '',
        dealStageNow: ''
      },
      company: {
        query: initialCompanyFiltersQuery,
        count: initialCompaniesCount,
        sortBy: ''
      },
      engagement: {
        query: {},
        counts: {}
      },
      smartPlaylist: {
        query: initialFiltersQuery,
        count: { ...initialFilterCount },
        customCategory: [],
        more: {
          showUnrecordedMeetings: true,
          includeInternalMeetings: true,
          showInternalOnly: false
        }
      },
      scheduler: {
        query: initialFiltersQuery,
        count: { ...initialFilterCount },
        customCategory: [],
        more: {
          showUnrecordedMeetings: true,
          includeInternalMeetings: true,
          showInternalOnly: false
        }
      }
    };
  }
  // end reset

  const sentryReduxEnhancer = Sentry.createReduxEnhancer();

  if (['int', 'staging'].includes(env.name)) {
    store = configureStore({
      reducer: reducers,
      preloadedState: persistedState,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          immutableCheck: false,
          serializableCheck: false
        }).concat(baseCreateApi.middleware, thunk, tracker, socketMiddleware),
      enhancers: [sentryReduxEnhancer],
      devTools: true
    });
  } else if (env.name === 'prod') {
    store = configureStore({
      reducer: reducers,
      preloadedState: persistedState,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          immutableCheck: false,
          serializableCheck: false
        }).concat(baseCreateApi.middleware, thunk, tracker, socketMiddleware),
      enhancers: [sentryReduxEnhancer],
      devTools: false
    });
  } else {
    store = configureStore({
      reducer: reducers,
      preloadedState: persistedState,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          immutableCheck: false,
          serializableCheck: false
        }).concat(baseCreateApi.middleware, thunk, socketMiddleware),
      enhancers: [sentryReduxEnhancer],
      devTools: true
    });
  }

  saveDefaultStatesToLocalStorage(store);

  let sentryUserContextSet = false;
  let clarityUserContextSet = false;
  store.subscribe(() => {
    const currState = store.getState();

    if (!clarityUserContextSet && currState?.user?.isAuthenticated) {
      // add user to clarity
      window.clarity?.(
        'identify',
        currState.user?.email,
        '',
        '',
        `${currState.user?.firstName} ${currState.user?.lastName}`
      );

      // set custom tags
      window.clarity?.('set', 'Email', currState.user?.email);
      window.clarity?.(
        'set',
        'Plan',
        currState.user?.userLicense?.subscription?.planName
      );
      window.clarity?.(
        'set',
        'Admin',
        currState.user?.orgUser?.isAdmin ? 'true' : 'false'
      );
      window.clarity?.('set', 'AuthType', currState.user?.authProvider);
      window.clarity?.('set', 'OrgUUID', currState.members?.organization?.uuid);
      window.clarity?.('set', 'Position', currState.user?.position);
      window.clarity?.('set', 'Team Size', currState.user?.teamSize);
      window.clarity?.(
        'set',
        'In Trail',
        currState.user?.userLicense?.subscription?.inTrial
      );
      window.clarity?.(
        'set',
        'Total Seats',
        currState.user?.userLicense?.subscription?.quantity
      );
      window.clarity?.(
        'set',
        'Used Seats',
        currState.user?.userLicense?.subscription?.quantityUsed
      );
      window.clarity?.('set', 'Crm', currState.user?.crm);
      window.clarity?.('set', 'Timezone', currState.user?.timezone);
      window.clarity?.(
        'set',
        'Zoom Integrated',
        currState.user?.zoomIntegration
      );
      window.clarity?.(
        'set',
        'Zoom Phone Integration',
        currState.user?.zoomphoneIntegration
      );
      window.clarity?.(
        'set',
        'Kixie Integration',
        currState.user?.kixieIntegration
      );
      window.clarity?.(
        'set',
        'GoTo Connect Integration',
        currState.user?.gotoconnectIntegration
      );
      window.clarity?.(
        'set',
        'Vonage BC Integration',
        currState.user?.vonageBcIntegration
      );
      window.clarity?.(
        'set',
        'ClickUp Integration',
        currState.user?.ticketingProviders?.clickup?.clickupIntegration
      );
      window.clarity?.(
        'set',
        'AirCall Integration',
        currState.user?.aircallIntegration
      );

      clarityUserContextSet = true;
    }

    if (!sentryUserContextSet && currState?.user?.isAuthenticated) {
      Sentry.setUser({
        email: currState.user.email,
        username: currState.user.username
      });
      sentryUserContextSet = true;
    }

    saveDefaultStatesToLocalStorage(store);
  });
}

export default store;

export const denormalize = (data, ids) => {
  if (ids) {
    return ids.map(uuid => data[uuid]).filter(el => !!el);
  }
  return [];
};
