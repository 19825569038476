/* eslint-disable jsx-a11y/no-static-element-interactions */
import { filter, isEmpty, map, size } from 'lodash';
import PropTypes from 'prop-types';
import Radium from 'radium';
import React, { Component } from 'react';
import { FaQuestionCircle as HelpIcon } from 'react-icons/fa';
import OutsideClickHandler from 'react-outside-click-handler';
import { twMerge } from 'tailwind-merge';

import AvomaButton from 'components/Common/AvomaButton';
import AvomaHoverCard from 'components/Common/AvomaHoverCard';
import AvomaNavTabs from 'components/Common/AvomaNavTabs';
import AvomaTooltip from 'components/Common/AvomaTooltip';
import CrmIcon from 'components/Common/CrmIcon';
import HighlightCategoryTag from 'components/Common/HighlightCategoryTag';
import NotificationBanner from 'components/Common/NotificationBanner';
import OverflowingText from 'components/Common/OverflowingText';
import SearchField from 'components/Common/search/SearchField';

import L10n from 'helpers/L10n';
import history from 'helpers/history';
import { L10nTemplates } from 'helpers/l10n/L10nTemplates';

import { TEMPLATE_EVENTS } from 'constants/trackingEvents';

import agendaIcon from 'images/ic_agenda_grey.svg';

import Tokens from 'styles/tokens';

import CreateAndUpdateTopicModal from './CreateAndUpdateTopicModal';

const AGENDA_CATEGORY = {
  backgroundColor: '#DDDDDD',
  key: 'insert_agenda',
  name: L10n.emailAgenda.insertAgenda,
  uuid: 'insert_agenda',
  visible: true
};

class HighlightCategoriesMenu extends Component {
  constructor(props) {
    super(props);
    this.items = {};
  }

  state = {
    showInfoTooltip: false,
    selectedIndex: -1,
    showCreateAndUpdateTopicModal: false,
    selectedTab: 'allCategories',
    searchTerm: ''
  };

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = e => {
    const { selectedIndex } = this.state;
    const {
      addUnselected,
      seenCategories,
      onNoneSelected,
      onInsertAgendaSelected,
      isShowCategoriesInTabs
    } = this.props;
    if (e.keyCode === 40) {
      const filteredHighlightCategories = isShowCategoriesInTabs
        ? this.getCategorizedFilteredCategories().allCategories
        : this.getFilteredCategories();
      let index = selectedIndex + 1;
      while (
        index < filteredHighlightCategories.length &&
        filteredHighlightCategories[index] &&
        seenCategories[filteredHighlightCategories[index].key]
      ) {
        index += 1;
      }
      if (index > filteredHighlightCategories.length - 1) {
        index = selectedIndex;
      }
      this.setIndex(index);
    } else if (e.keyCode === 38) {
      const filteredHighlightCategories = isShowCategoriesInTabs
        ? this.getCategorizedFilteredCategories().allCategories
        : this.getFilteredCategories();
      let index = selectedIndex - 1;
      while (
        index >= 0 &&
        filteredHighlightCategories[index] &&
        seenCategories[filteredHighlightCategories[index].key]
      ) {
        index -= 1;
      }
      if (index < 0) {
        index = selectedIndex;
      }
      this.setIndex(index);
    } else if (
      e.keyCode === 13 &&
      (addUnselected || (!addUnselected && selectedIndex >= 0))
    ) {
      const filteredHighlightCategories = isShowCategoriesInTabs
        ? this.getCategorizedFilteredCategories().allCategories
        : this.getFilteredCategories();
      if (filteredHighlightCategories.length === 0 && onNoneSelected) {
        onNoneSelected();
      }
      let index = selectedIndex >= 0 ? selectedIndex : 0;
      while (
        index < filteredHighlightCategories.length &&
        filteredHighlightCategories[index] &&
        seenCategories[filteredHighlightCategories[index].key]
      ) {
        index += 1;
      }
      if (filteredHighlightCategories[index]) {
        if (filteredHighlightCategories[index].key === AGENDA_CATEGORY.key) {
          onInsertAgendaSelected();
        } else {
          this.selectCategory(filteredHighlightCategories[index]);
        }
      }
    }
  };

  setIndex = index => {
    if (this.items[index]) {
      const { offsetTop, offsetHeight } = this.items[index];
      const scrollTop = this.list.offsetTop + this.list.scrollTop;
      const scrollEnd =
        this.list.offsetTop + this.list.offsetHeight + this.list.scrollTop;
      if (offsetTop < scrollTop) {
        const delta = offsetTop - scrollTop;
        this.list.scrollTop += delta;
      } else if (offsetTop + offsetHeight > scrollEnd) {
        const delta = offsetTop - scrollEnd + offsetHeight;
        this.list.scrollTop += delta;
      }
    }
    this.setState({ selectedIndex: index });
  };

  getFilteredCategories = () => {
    const { searchTerm } = this.state;

    const {
      highlightCategories,
      filter: currentFilter,
      showInsertAgendaButton
    } = this.props;
    let updatedHighlightCategories = [];

    if (showInsertAgendaButton) {
      updatedHighlightCategories = [AGENDA_CATEGORY, ...highlightCategories];
    } else {
      updatedHighlightCategories = highlightCategories;
    }

    return filter(updatedHighlightCategories, highlightCategory =>
      highlightCategory.name
        .toLowerCase()
        .includes(
          currentFilter.toLowerCase().trim() || searchTerm.toLowerCase().trim()
        )
    );
  };

  getCategorizedFilteredCategories = () => {
    const { highlightCategories, filter: currentFilter } = this.props;
    const { searchTerm } = this.state;

    const categoryList = {
      allCategories: [],
      defaultCategories: [],
      customCategories: []
    };

    for (const category of highlightCategories) {
      if (
        category.settings?.augNotesEnabled &&
        category.name
          .toLowerCase()
          .includes(
            searchTerm.toLowerCase().trim() ||
              currentFilter?.toLowerCase().trim()
          )
      ) {
        if (category.isDefault) {
          categoryList.defaultCategories.push(category);
        } else {
          categoryList.customCategories.push(category);
        }

        categoryList.allCategories.push(category);
      }
    }

    return categoryList;
  };

  selectCategory = highlightCategory => {
    const { onSelectHighlightCategory } = this.props;
    onSelectHighlightCategory(highlightCategory);
  };

  showInfoTooltip = () => {
    clearTimeout(this.showInfoTooltipTimeout);
    this.setState({ showInfoTooltip: true });
  };

  hideInfoTooltip = () => {
    this.setState({ showInfoTooltip: false });
  };

  delayedHideInfoTooltip = () => {
    clearTimeout(this.showInfoTooltipTimeout);
    this.showInfoTooltipTimeout = setTimeout(this.hideInfoTooltip, 300);
  };

  goHighlightCategoriesSettings = () => {
    history.push('/settings/smart_categories');
  };

  getFormattedCategories = (
    filteredHighlightCategories,
    seenCategories,
    selectedCategoryUuid,
    selectedIndex,
    tabId
  ) => {
    const {
      crmProvider,
      trackSegmentEvents,
      currentTemplate,
      userEmail,
      meetingUuid
    } = this.props;

    return (
      <div>
        {map(filteredHighlightCategories[tabId], (highlightCategory, index) => {
          const selected = selectedCategoryUuid === highlightCategory.uuid;
          const seen = highlightCategory.key in seenCategories;

          const handleClick = () => {
            if (currentTemplate?.isNew) {
              trackSegmentEvents({
                type: TEMPLATE_EVENTS.NEW_TEMPLATE_EXISTING_TOPIC_SELECTED,
                template_uuid: currentTemplate.uuid,
                meeting_uuid: meetingUuid,
                user_email: userEmail,
                timestamp: new Date().toISOString()
              });
            }
            this.selectCategory(highlightCategory);
          };

          return (
            <AvomaTooltip
              tooltip={
                seen &&
                'This Smart Topic has already been added to the template'
              }
              tooltipPosition='top-center'
              userStyles={{ maxWidth: '180px' }}
            >
              <li
                style={{
                  ...styles.categoryListItem
                }}
                onMouseEnter={() => {
                  this.setState({ selectedIndex: index });
                }}
                ref={el => {
                  this.items[index] = el;
                }}
                key={`highlight-category-${highlightCategory.uuid}`}
                className={twMerge(
                  'hover:bg-snow',
                  seen && '[&_*]:opacity-80',
                  index === selectedIndex && !seen && 'bg-snow'
                )}
              >
                <a
                  style={{
                    ...(selected && { cursor: 'pointer' })
                  }}
                  key={`highlight-category-${highlightCategory.uuid}`}
                  // this is hacky
                  onMouseDown={
                    !seen &&
                    (e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleClick();
                    })
                  }
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className='w-full flex items-center px-4 py-1 min-w-0'
                >
                  <div className='w-full py-2 flex justify-between items-center group'>
                    <OverflowingText
                      tooltip={highlightCategory.name}
                      tooltipStyles={{ overflowWrap: 'anywhere' }}
                      as='p'
                      className='w-[70%] text-base font-bold text-black'
                    >
                      <div className='flex items-center gap-x-2'>
                        <span>{highlightCategory.name}</span>
                        {!isEmpty(highlightCategory.avFieldMapping) && (
                          <CrmIcon crm={crmProvider} />
                        )}
                      </div>

                      {(!isEmpty(highlightCategory.modifiedBy?.firstName) ||
                        !isEmpty(highlightCategory.modifiedBy?.lastName)) && (
                        <OverflowingText
                          tooltip={highlightCategory.modifiedBy}
                          tooltipStyles={{ overflowWrap: 'anywhere' }}
                          as='p'
                          className='text-silver text-sm w-full'
                        >
                          Last modified by:
                          {`${highlightCategory.modifiedBy.firstName} ${highlightCategory.modifiedBy.lastName}`}
                        </OverflowingText>
                      )}
                    </OverflowingText>

                    <AvomaHoverCard
                      contentStyles={{
                        zIndex: 10000
                      }}
                      triggerComponent={
                        <p className='text-blue text-sm cursor-pointer invisible group-hover:visible'>
                          View Prompt
                        </p>
                      }
                      title={highlightCategory.name}
                    >
                      <p className='text-sm font-bold mt-1'>Prompt</p>
                      <div className='text-sm'>
                        {isEmpty(highlightCategory.description) ? (
                          <NotificationBanner
                            className='my-2'
                            content={L10nTemplates.noPromptPlaceholder}
                          />
                        ) : (
                          highlightCategory.description
                        )}
                      </div>
                    </AvomaHoverCard>
                  </div>
                </a>
              </li>
            </AvomaTooltip>
          );
        })}
      </div>
    );
  };

  renderHighlightCategories = filteredHighlightCategories => {
    const { selectedCategoryUuid, seenCategories, onInsertAgendaSelected } =
      this.props;
    const { selectedIndex } = this.state;
    let categoryListItems = [
      <li style={styles.emptyCategoryListItem}>
        {L10n.highlights.emptyHighlightCategories}
      </li>
    ];
    if (size(filteredHighlightCategories)) {
      categoryListItems = map(
        filteredHighlightCategories,
        (highlightCategory, index) => {
          const selected = selectedCategoryUuid === highlightCategory.uuid;
          const seen = highlightCategory.key in seenCategories;
          const isInsertAgendaButton =
            highlightCategory.key === AGENDA_CATEGORY.key;

          const handleClick = () => {
            if (isInsertAgendaButton) {
              onInsertAgendaSelected();
            } else {
              this.selectCategory(highlightCategory);
            }
          };

          return (
            <li
              style={{
                ...styles.categoryListItem,
                ...(index === selectedIndex && !seen && styles.focused)
              }}
              onMouseEnter={() => {
                this.setState({ selectedIndex: index });
              }}
              onMouseLeave={() => {
                this.setState({ selectedIndex: -1 });
              }}
              ref={el => {
                this.items[index] = el;
              }}
            >
              <a
                style={{
                  ...styles.categoryListItemAnchor,
                  ...((seen || selected) &&
                    styles.categoryListItemAnchorInactive),
                  ...(selected && { cursor: 'pointer' })
                }}
                key={`highlight-category-${highlightCategory.uuid}`}
                // this is hacky
                onMouseDown={
                  !seen &&
                  (e => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClick();
                  })
                }
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {isInsertAgendaButton ? (
                  this.renderAddAgendaButton()
                ) : (
                  <HighlightCategoryTag
                    highlightCategory={highlightCategory}
                    isChecked={seen || selected}
                  />
                )}
              </a>
            </li>
          );
        }
      );
    }

    return (
      <ul
        style={styles.categoryList}
        ref={el => {
          this.list = el;
        }}
      >
        {categoryListItems}
      </ul>
    );
  };

  renderHighlightCategoriesInTabs = filteredHighlightCategories => {
    const { selectedCategoryUuid, seenCategories } = this.props;
    const { selectedIndex, selectedTab } = this.state;
    let categoryListItems = [
      <li className='text-center p-8 text-silver text-sm'>
        {L10nTemplates.noCategoriesFound}
      </li>
    ];

    if (size(filteredHighlightCategories[selectedTab])) {
      categoryListItems = (
        <React.Fragment>
          {selectedTab === 'allCategories' &&
            this.getFormattedCategories(
              filteredHighlightCategories,
              seenCategories,
              selectedCategoryUuid,
              selectedIndex,
              'allCategories'
            )}

          {selectedTab === 'defaultCategories' &&
            this.getFormattedCategories(
              filteredHighlightCategories,
              seenCategories,
              selectedCategoryUuid,
              selectedIndex,
              'defaultCategories'
            )}

          {selectedTab === 'customCategories' &&
            this.getFormattedCategories(
              filteredHighlightCategories,
              seenCategories,
              selectedCategoryUuid,
              selectedIndex,
              'customCategories'
            )}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <AvomaNavTabs
          items={[
            {
              id: 'allCategories',
              nameNode: (
                <div style={{ display: 'flex', alignItems: 'center' }}>All</div>
              )
            },
            {
              id: 'defaultCategories',
              nameNode: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Default
                </div>
              )
            },
            {
              id: 'customCategories',
              nameNode: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Custom
                </div>
              )
            }
          ]}
          defaultTabId='allCategories'
          selectedTab={selectedTab}
          containerClassName='border-b border-b-gainsboro bg-white'
          onTabSelected={tabId => {
            this.setState({ selectedTab: tabId });
          }}
        />
        <ul
          style={styles.categoryList}
          ref={el => {
            this.list = el;
          }}
        >
          {categoryListItems}
        </ul>
      </React.Fragment>
    );
  };

  getTooltipPosition = () => {
    if (!this.ref) return 'center';
    const { right, left } = this.ref.getBoundingClientRect();
    if (window.innerWidth - right < 130) return 'left';
    if (left < 130) return 'right';
    return 'center';
  };

  getTooltipStyles = () => {
    const tooltipStyles = { ...styles.infoTooltip };
    switch (this.getTooltipPosition()) {
      case 'left':
        tooltipStyles.right = 0;
        break;
      case 'right':
        tooltipStyles.left = 0;
        break;
      default:
        tooltipStyles.marginLeft = '-125px';
        break;
    }
    return tooltipStyles;
  };

  renderAddAgendaButton = () => {
    const { onInsertAgendaSelected } = this.props;
    return (
      <div style={styles.agendaButtonContainer}>
        <AvomaButton
          secondary
          iconImage={agendaIcon}
          label={L10n.emailAgenda.insertAgenda}
          style={{ width: '100%' }}
          iconStyles={{ marginRight: Tokens.spacing.one }}
          small
          onClick={onInsertAgendaSelected}
        />
      </div>
    );
  };

  openCreateAndUpdateTopicModal = () => {
    this.setState({ showCreateAndUpdateTopicModal: true });
  };

  render() {
    const {
      showEmpty,
      onMouseEnter,
      onMouseLeave,
      onClickOutside,
      position,
      title,
      isShowCategoriesInTabs = false,
      updateCategoryUuid,
      trackSegmentEvents,
      meetingUuid,
      userEmail,
      currentTemplate
    } = this.props;

    const { showInfoTooltip, showCreateAndUpdateTopicModal } = this.state;
    const filteredHighlightCategories = isShowCategoriesInTabs
      ? this.getCategorizedFilteredCategories()
      : this.getFilteredCategories();

    if (!showEmpty && isEmpty(filteredHighlightCategories)) {
      return null;
    }

    return (
      <React.Fragment>
        <div
          style={{
            ...styles.container,
            ...position
          }}
          ref={ref => (this.ref = ref)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className='category-highlights-menu-container'
        >
          <OutsideClickHandler
            onOutsideClick={
              !showCreateAndUpdateTopicModal ? () => onClickOutside() : () => {}
            }
          >
            <div className='flex justify-between items-center p-4'>
              <p className='text-base font-bold'>{title}</p>
              {isShowCategoriesInTabs && (
                <AvomaButton
                  secondary
                  small
                  label={L10nTemplates.createNewTopic}
                  className='text-xs'
                  onClick={this.openCreateAndUpdateTopicModal}
                />
              )}

              {!isShowCategoriesInTabs && (
                <div
                  style={styles.infoIconContainer}
                  onMouseEnter={this.showInfoTooltip}
                  onMouseLeave={this.delayedHideInfoTooltip}
                >
                  <HelpIcon style={styles.helpIcon} />
                  {showInfoTooltip && (
                    <div style={this.getTooltipStyles()}>
                      {L10n.highlights.tooltip}
                      <span
                        style={styles.infoTooltipLink}
                        onMouseDown={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.goHighlightCategoriesSettings();
                        }}
                        onClick={() => {}}
                      >
                        Smart Topic Settings
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>

            <SearchField
              focusOnLoad={isShowCategoriesInTabs}
              handleSearch={text => {
                this.setState({ searchTerm: text });
              }}
              handleClear={() => {
                this.setState({ searchTerm: '' });
              }}
              placeholder={L10nTemplates.searchTopicPlaceholder}
              instantSearch
              className='mb-2 mx-4'
            />
            {isShowCategoriesInTabs
              ? this.renderHighlightCategoriesInTabs(
                  filteredHighlightCategories
                )
              : this.renderHighlightCategories(filteredHighlightCategories)}
          </OutsideClickHandler>
        </div>
        <CreateAndUpdateTopicModal
          isOpen={showCreateAndUpdateTopicModal}
          handleOnClose={data => {
            if (data) {
              if (currentTemplate?.isNew) {
                trackSegmentEvents({
                  type: TEMPLATE_EVENTS.NEW_TEMPLATE_NEW_TOPIC,
                  template_uuid: currentTemplate.uuid,
                  meeting_uuid: meetingUuid,
                  user_email: userEmail,
                  timestamp: new Date().toISOString()
                });
              }
              this.selectCategory(data);
            }
            onClickOutside();
          }}
        />
      </React.Fragment>
    );
  }
}

HighlightCategoriesMenu.propTypes = {
  highlightCategories: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  selectedCategoryUuid: PropTypes.string,
  onSelectHighlightCategory: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func,
  onClickOutside: PropTypes.func,
  onNoneSelected: PropTypes.func,
  title: PropTypes.string,
  filter: PropTypes.string,
  showEmpty: PropTypes.bool,
  position: PropTypes.number,
  addUnselected: PropTypes.bool,
  seenCategories: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
  showInsertAgendaButton: PropTypes.bool,
  onInsertAgendaSelected: PropTypes.bool,
  isShowCategoriesInTabs: PropTypes.bool,
  crmProvider: PropTypes.string,
  updateCategoryUuid: PropTypes.string,
  trackSegmentEvents: PropTypes.func,
  currentTemplate: PropTypes.object,
  userEmail: PropTypes.string,
  meetingUuid: PropTypes.string
};

HighlightCategoriesMenu.defaultProps = {
  title: L10n.highlights.highlightCategoriesMenuTitle,
  selectedCategoryUuid: null,
  onMouseLeave: null,
  onClickOutside: null,
  onNoneSelected: null,
  filter: '',
  showEmpty: true,
  addUnselected: true,
  seenCategories: {},
  showInsertAgendaButton: false
};

const styles = {
  container: {
    ...Tokens.shadows.medium,
    color: Tokens.colors.gunmetal,
    position: 'absolute',
    zIndex: 1800,
    width: '350px',
    overflow: 'visible',
    backgroundColor: Tokens.colors.white,
    borderRadius: Tokens.spacing.borderRadius,
    border: `1px solid ${Tokens.colors.gainsboro}`,
    padding: '0'
  },
  header: {
    ...Tokens.type.header6,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: '900',
    margin: '0',
    padding: `${Tokens.spacing.two} ${Tokens.spacing.two} ${Tokens.spacing.one} ${Tokens.spacing.two}`
  },
  categoryList: {
    listStyleType: 'none',
    margin: '0',
    maxHeight: '260px',
    overflowY: 'auto',
    padding: `0`
  },
  categoryListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  emptyCategoryListItem: {
    ...Tokens.type.header6,
    padding: `${Tokens.spacing.one} ${Tokens.spacing.two}`,
    color: Tokens.colors.silver
  },
  categoryListItemAnchor: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    padding: `${Tokens.spacing.half} ${Tokens.spacing.two}`,
    ':hover': {
      backgroundColor: Tokens.colors.snow
    }
  },
  categoryListItemAnchorInactive: {
    ':hover': {
      backgroundColor: Tokens.colors.transparent
    },
    cursor: 'default'
  },
  focused: {
    backgroundColor: Tokens.colors.blueLightest
  },
  infoIconContainer: {
    cursor: 'pointer',
    position: 'relative'
  },
  helpIcon: {
    width: '12px',
    height: '12px',
    color: Tokens.colors.gunmetal,
    fill: Tokens.colors.gunmetal
  },
  infoTooltip: {
    ...Tokens.type.bodyXS,
    ...Tokens.shadows.small,
    textTransform: 'none',
    position: 'absolute',
    top: Tokens.spacing.two,
    padding: Tokens.spacing.one,
    width: '250px',
    fontWeight: 'bold',
    zIndex: '2',
    backgroundColor: Tokens.colors.gunmetal,
    color: Tokens.colors.white,
    textAlign: 'center',
    borderRadius: Tokens.spacing.borderRadius
  },
  infoTooltipLink: {
    color: Tokens.colors.blueLight,
    textDecoration: 'underline',
    ':hover': {
      textDecoration: 'none'
    }
  },
  agendaButtonContainer: {
    width: '100%'
  },
  addAgenda: {
    ...Tokens.type.bodyS,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    padding: `${Tokens.spacing.half} ${Tokens.spacing.one}`,
    background: Tokens.colors.snow,
    color: Tokens.colors.silver,
    width: '100%',
    borderRadius: Tokens.spacing.borderRadius,

    ':hover': {
      background: Tokens.colors.gainsboro,
      color: Tokens.colors.gunmetal
    }
  },
  agendaIcon: {
    marginRight: Tokens.spacing.one
  }
};

export default Radium(HighlightCategoriesMenu);
