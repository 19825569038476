import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import Tokens from 'styles/tokens';

const LinkInput = forwardRef(
  ({ inputValue, placeholder = 'Enter link URL', handleChange }, ref) => {
    const [value, setValue] = useState(inputValue);

    const onChange = event => {
      setValue(event.target.value);
      if (handleChange) {
        handleChange(event.target.value);
      }
    };

    return (
      <input
        placeholder={placeholder}
        ref={ref}
        value={value}
        onChange={onChange}
        style={styles.input}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
    );
  }
);

LinkInput.displayName = 'LinkInput';

LinkInput.propTypes = {
  inputValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func
};

const styles = {
  input: {
    color: Tokens.colors.gunmetal,
    ...Tokens.type.bodyXS,
    width: '100%',
    borderRadius: Tokens.spacing.borderRadius,
    border: Tokens.borders.standard,
    padding: Tokens.spacing.half,
    background: Tokens.colors.white,
    boxSizing: 'border-box'
  }
};

export default LinkInput;
