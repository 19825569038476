export const ALL_MEETINGS = 'all_meetings';
export const MY_MEETINGS = 'my_meetings';
export const SHARED_MEETINGS = 'shared_meetings';
export const TEAM_MEETINGS = 'team_meetings';
export const PLAYLISTS = 'playlists';

export const meetingCategoryUrls = {
  [MY_MEETINGS]: '/meetings/views/my/',
  [SHARED_MEETINGS]: '/meetings/views/shared/',
  [ALL_MEETINGS]: '/meetings/views/all/',
  [TEAM_MEETINGS]: '/meetings/views/team/'
};
