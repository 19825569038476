import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import {
  createPhrase,
  deletePhrase,
  generateSentenceVariants,
  updatePhrase
} from 'actions/insights';
import { trackSegmentEvents } from 'actions/segment';

import useFeature from 'hooks/useFeature';

import { useGetFeaturePlansQuery } from 'services/subscription';

import PurchaseLicenseBanner from 'components/Account/Billing/PurchaseLicenseBanner';
import AvomaButton from 'components/Common/AvomaButton';
import AvomaDialog from 'components/Common/AvomaDialog';
import AvomaTooltip from 'components/Common/AvomaTooltip';
import Spinner from 'components/Common/Spinner';
import TextField from 'components/Common/TextField';
import HelpPopover from 'components/Common/popovers/HelpPopover';
import CardSection from 'components/SmartCategories/Common/CardSection';

import L10n from 'helpers/L10n';
import Licenses from 'helpers/Licenses';
import { L10nFeatureGating } from 'helpers/l10n/L10nFeatureGating';

import {
  DEFAULT_EXAMPLE_SENTENCES,
  ERROR_MESSAGES
} from 'constants/smartCategories';
import { TOPIC_EVENTS } from 'constants/trackingEvents';

import { ReactComponent as AiYellowSparkleIcon } from 'images/ic_ai.svg';
import { ReactComponent as DeleteIcon } from 'images/ic_delete.svg';
import { ReactComponent as AiSparkleIcon } from 'images/ic_upgrade_sparkle.svg';

import Tokens from 'styles/tokens';

import Phrase from '../Phrase';
import { createId } from '@paralleldrive/cuid2';

const CustomPhrasesV2 = ({
  category,
  loading,
  handleAddOrModifyKeywordOrPhrase,
  isUnifiedFlow = false,
  trackers,
  setTrackers,
  isShowCustomPhraseDetails,
  setIsShowCustomPhraseDetails
}) => {
  const dispatch = useDispatch();

  const isSmartMatchFeatureAllowed = useFeature(Licenses.FEATURES.smartTracker);

  const [phraseUuid, setPhraseUuid] = useState(null);
  const [phraseValue, setPhraseValue] = useState('');
  const [exampleSentences, setExampleSentences] = useState(
    DEFAULT_EXAMPLE_SENTENCES
  );
  const [showCreateUpdateDialog, setShowCreateUpdateDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [phraseErrorMessage, setPhraseErrorMessage] = useState('');
  const [sentenceErrorMessage, setSentenceErrorMessage] = useState('');
  const [generatingSentencesErrorMessage, setGeneratingSentencesErrorMessage] =
    useState('');
  const [isFetchingGeneratedSentences, setIsFetchingGeneratedSentences] =
    useState(false);

  const { data: minimumRequiredPlan } = useGetFeaturePlansQuery({
    feature: Licenses.FEATURES.smartTracker
  });

  const hasEmptyExampleSentences = exampleSentences.some(
    obj => obj.sentence.trim().length === 0
  );

  const isMaxNumberOfSentences = exampleSentences.length >= 21;

  const shouldDisableSaveOrUpdate =
    phraseValue.trim().length === 0 || hasEmptyExampleSentences;

  const shouldDisableGenerateWithAI =
    phraseValue.trim().length === 0 ||
    exampleSentences[0]?.sentence?.trim().length === 0;

  const emptySentencesCount = useMemo(
    () => exampleSentences.filter(item => item.sentence === '').length,
    [exampleSentences]
  );

  useEffect(() => {
    if (!isEmpty(isUnifiedFlow ? trackers : category?.trackers) && phraseUuid) {
      const tracker = isUnifiedFlow
        ? trackers.find(tr => (tr.uuid || tr.tempUuid) === phraseUuid)
        : category?.trackers[phraseUuid];

      if (tracker) {
        setPhraseValue(tracker.label);
        setExampleSentences(Object.values(tracker.sentences));
      }
    } else {
      setPhraseValue('');
      setExampleSentences(DEFAULT_EXAMPLE_SENTENCES);
    }
  }, [category?.trackers, isUnifiedFlow, phraseUuid, trackers]);

  const handlePhraseValueChange = event => {
    setPhraseValue(event.target.value);
    setPhraseErrorMessage('');
  };

  const handleToggleCreateUpdateDialog = selectedPhraseUuid => {
    setIsShowCustomPhraseDetails(true);
    if (selectedPhraseUuid) {
      setPhraseUuid(selectedPhraseUuid);
    }
    setShowCreateUpdateDialog(!showCreateUpdateDialog);
  };

  const handleToggleDeleteDialog = selectedPhraseUuid => {
    if (selectedPhraseUuid) {
      setPhraseUuid(selectedPhraseUuid);
    }
    setShowDeleteDialog(!showDeleteDialog);
  };

  const handleKeyDown = event => {
    if (event.keyCode === 13 && phraseValue.trim().length !== 0) {
      event.preventDefault();
      handleToggleCreateUpdateDialog();
    }
  };

  const handleClickGenerateExampleSentences = async ({
    exampleSentenceText,
    numOfSentences
  }) => {
    setShowButtonLoader(true);
    setIsFetchingGeneratedSentences(true);
    setGeneratingSentencesErrorMessage('');

    const currentExampleSentences = exampleSentences
      .slice(1) // Skip the seed sentence (first sentence)
      .map(item => item.sentence)
      .filter(sentence => sentence !== ''); // Filter out empty sentences;

    const payload = {
      tracker_name: phraseValue,
      seed_sentence: exampleSentenceText,
      existing_examples: currentExampleSentences,
      num_to_generate: emptySentencesCount > 0 ? numOfSentences : 5
    };

    const response = await dispatch(
      generateSentenceVariants({
        categoryUuid: category.uuid,
        isUnifiedFlow: true,
        payload
      })
    );

    if (response) {
      if (response.error) {
        setGeneratingSentencesErrorMessage(response.error.detail);
        setIsFetchingGeneratedSentences(false);
        setShowButtonLoader(false);
      } else {
        let newExampleSentences = [...exampleSentences];

        // Check if there's any object with an empty string
        const hasEmptyString = newExampleSentences.some(
          sentenceObj => sentenceObj.sentence === ''
        );

        // If no object has an empty string, append a new object with an empty string
        if (!hasEmptyString) {
          const sentencesToAdd = Math.min(5, 21 - newExampleSentences.length);

          newExampleSentences = [
            ...newExampleSentences,
            ...Array.from({ length: sentencesToAdd }, () => ({ sentence: '' }))
          ];

          setExampleSentences(newExampleSentences);
        }

        let responseIndex = 0;

        const updatedSentences = newExampleSentences.map(sentenceObj => {
          if (
            sentenceObj.sentence === '' &&
            responseIndex < response.smartPhrases.length
          ) {
            const updatedSentenceObj = {
              ...sentenceObj,
              sentence: response.smartPhrases[responseIndex]
            };
            responseIndex += 1;
            return updatedSentenceObj;
          }
          return sentenceObj;
        });

        setExampleSentences(updatedSentences);
        setShowButtonLoader(false);
        setIsFetchingGeneratedSentences(false);
      }
    }
  };

  const handleAddCustomPhrase = () => {
    const existingPhrases = trackers.map(tracker => tracker.label);

    if (existingPhrases.includes(phraseValue)) {
      setPhraseErrorMessage('This phrase already exists.');
      return;
    }

    const uniqueSentences = new Set(
      exampleSentences.map(s => s.sentence.toLowerCase().trim())
    );

    if (uniqueSentences.size !== exampleSentences.length) {
      setSentenceErrorMessage('All sentences must be unique.');
      return;
    }

    const updatedUuid = createId();
    const updatedSentenceUuid = createId();

    setTrackers([
      ...trackers,
      {
        uuid: updatedUuid,
        tempUuid: updatedUuid,
        label: phraseValue,
        sentences: exampleSentences.map(sentenceObj => ({
          ...sentenceObj,
          uuid: updatedSentenceUuid,
          tempUuid: updatedSentenceUuid
        }))
      }
    ]);

    dispatch(
      trackSegmentEvents({
        type: TOPIC_EVENTS.TOPIC_PHRASE_ADDED,
        name: phraseValue
      })
    );

    handleCloseCreateUpdateDialog();
  };

  const handleModifyCustomPhrase = () => {
    const existingPhrases = trackers.map(tracker => tracker.label);

    if (existingPhrases.includes(phraseValue) && !phraseUuid) {
      setPhraseErrorMessage('This phrase already exists.');
      return;
    }

    const uniqueSentences = new Set(
      exampleSentences.map(s => s.sentence.toLowerCase().trim())
    );

    if (uniqueSentences.size !== exampleSentences.length) {
      setSentenceErrorMessage('All sentences must be unique.');
      return;
    }

    const updatedTrackers = trackers.filter(
      tracker => (tracker.uuid || tracker.tempUuid) !== phraseUuid
    );

    const updatedUuid = createId();

    setTrackers([
      ...updatedTrackers,
      {
        uuid: phraseUuid,
        label: phraseValue,
        sentences: exampleSentences.map(sentenceObj => ({
          ...sentenceObj,
          tempUuid: updatedUuid,
          uuid: updatedUuid
        }))
      }
    ]);
    handleCloseCreateUpdateDialog();
  };

  const handleRemoveCustomPhrase = () => {
    setTrackers([
      ...trackers.filter(
        tracker => (tracker.uuid || tracker.tempUuid) !== phraseUuid
      )
    ]);
    handleCloseDeleteDialog();
  };

  const handleRemoveCustomPhraseDialog = uuid => {
    setTrackers([
      ...trackers.filter(tracker => (tracker.uuid || tracker.tempUuid) !== uuid)
    ]);
    handleCloseDeleteDialog();
  };

  const handleCreateCustomPhrase = async () => {
    setShowButtonLoader(true);

    try {
      await dispatch(
        createPhrase({
          categoryUuid: category.uuid,
          payload: { label: phraseValue, sentences: exampleSentences }
        })
      );
      handleCloseCreateUpdateDialog();
    } catch (error) {
      const errorMessage = error.message;

      if (errorMessage.includes(ERROR_MESSAGES.dupeName)) {
        setPhraseErrorMessage('A phrase with this name already exists.');
      }

      if (errorMessage.includes(ERROR_MESSAGES.dupeSentence)) {
        setSentenceErrorMessage('All sentences must be unique.');
      }
    } finally {
      setShowButtonLoader(false);
      handleAddOrModifyKeywordOrPhrase();
    }
  };

  const handleUpdateCustomPhrase = async () => {
    setShowButtonLoader(true);

    try {
      await dispatch(
        updatePhrase({
          categoryUuid: category.uuid,
          phraseUuid,
          payload: { label: phraseValue, sentences: exampleSentences }
        })
      );
      handleCloseCreateUpdateDialog();
    } catch (error) {
      const errorMessage = error.message;

      if (errorMessage.includes(ERROR_MESSAGES.dupeSentence)) {
        setSentenceErrorMessage('All sentences must be unique.');
      }
    } finally {
      setShowButtonLoader(false);
      handleAddOrModifyKeywordOrPhrase();
    }
  };

  const handleDeleteCustomPhrase = async () => {
    setShowButtonLoader(true);

    try {
      await dispatch(
        deletePhrase({
          categoryUuid: category.uuid,
          phraseUuid
        })
      );
      handleCloseDeleteDialog();
    } catch (error) {
      setShowButtonLoader(false);
    } finally {
      handleAddOrModifyKeywordOrPhrase();
    }
  };

  const handleAddExampleSentence = () => {
    setExampleSentences([...exampleSentences, { sentence: '' }]);
  };

  const handleDeleteExampleSentence = sentenceIndex => {
    setExampleSentences(
      exampleSentences.filter((_sentence, index) => index !== sentenceIndex)
    );
  };

  const handleEditExampleSentence = (sentenceIndex, inputEvent) => {
    const updatedText = inputEvent.target.value;
    setExampleSentences(
      exampleSentences.map((sentence, index) =>
        index === sentenceIndex ? { sentence: updatedText } : sentence
      )
    );
  };

  const handleCloseCreateUpdateDialog = () => {
    handleReset();

    setShowButtonLoader(false);
    setShowCreateUpdateDialog(false);
    setIsShowCustomPhraseDetails(false);
  };

  const handleCloseDeleteDialog = () => {
    handleReset();

    setShowButtonLoader(false);
    setShowDeleteDialog(false);
  };

  const handleReset = () => {
    setPhraseUuid(null);
    setPhraseValue('');
    setPhraseErrorMessage('');
    setSentenceErrorMessage('');
    setGeneratingSentencesErrorMessage('');
  };

  const getDisabledGenerateWithAiCtaTooltip = () => {
    if (shouldDisableGenerateWithAI) {
      return L10n.customCategory.generateExamplesWithAiDisabledTooltip;
    }
    if (isMaxNumberOfSentences) {
      return L10n.customCategory.maxExamplesReachedTooltip;
    }
    return '';
  };

  const renderCardHeader = () => (
    <div className='mb-1 text-base font-bold'>
      <div className='flex items-center gap-x-1'>
        Add phrase for <span className='text-blue'>"smart"</span> match
        <AiYellowSparkleIcon className='ml-1' />
        <HelpPopover
          content={L10n.customCategory.phraseMatchSubtitle}
          buttonStyles={{
            paddingLeft: Tokens.spacing.half
          }}
          popoverWidth={300}
          popoverStyles={{ right: 0 }}
        />
      </div>
    </div>
  );

  // If user is not on Business or Enterprise license, show the purchase license banner
  if (!isSmartMatchFeatureAllowed) {
    return (
      <CardSection
        userStyles={{
          marginBottom: isShowCustomPhraseDetails ? '0' : Tokens.spacing.three
        }}
        contentStyles={{ padding: 0 }}
      >
        {renderCardHeader()}
        <PurchaseLicenseBanner
          msg={L10n.customCategory.smartMatchPurchaseLicense(
            minimumRequiredPlan
          )}
          feature={L10nFeatureGating.smartTrackers}
        />
      </CardSection>
    );
  }

  return (
    <CardSection
      userStyles={{
        marginBottom: isShowCustomPhraseDetails ? '0' : Tokens.spacing.three,
        height: isShowCustomPhraseDetails ? '100%' : 'auto'
      }}
      contentStyles={{
        padding: 0,
        height: isShowCustomPhraseDetails ? '100%' : 'auto'
      }}
    >
      {!isShowCustomPhraseDetails && renderCardHeader()}

      {!isShowCustomPhraseDetails && (
        <div className='relative flex items-center'>
          <TextField
            placeholder={L10n.customCategory.phrasesPlaceholder}
            value={
              showCreateUpdateDialog || showDeleteDialog
                ? L10n.customCategory.phrasesPlaceholder
                : phraseValue
            }
            onChange={handlePhraseValueChange}
            onKeyDown={handleKeyDown}
            darkerPlaceholder
            containerStyles={styles.inputContainer}
            userStyles={{
              ...styles.input,
              ...((showCreateUpdateDialog || showDeleteDialog) &&
                styles.disabled)
            }}
            disabled={showCreateUpdateDialog || showDeleteDialog}
          />
          <AvomaButton
            dataTestId='add-example-sentences-button'
            onClick={() => handleToggleCreateUpdateDialog()}
            label={L10n.general.add}
            small
            disabled={
              (!showCreateUpdateDialog && phraseValue.trim().length === 0) ||
              showCreateUpdateDialog ||
              showDeleteDialog
            }
          />
          {loading && <Spinner data-testId='spinner' type='small' />}
        </div>
      )}

      {!isEmpty(isUnifiedFlow ? trackers : category?.trackers) &&
        !isShowCustomPhraseDetails && (
          <div className='mt-2 flex flex-wrap gap-2'>
            {Object.values(isUnifiedFlow ? trackers : category?.trackers).map(
              (phraseObj, index) => (
                <Phrase
                  key={phraseObj?.uuid}
                  phraseObj={phraseObj}
                  phraseIndex={index}
                  handleToggleCreateUpdateDialog={
                    handleToggleCreateUpdateDialog
                  }
                  handleToggleDeleteDialog={
                    isUnifiedFlow
                      ? handleRemoveCustomPhraseDialog
                      : handleToggleDeleteDialog
                  }
                />
              )
            )}
          </div>
        )}

      {isShowCustomPhraseDetails && (
        <React.Fragment>
          <div className='flex flex-col space-y-6 h-[85%] overflow-y-auto overflow-x-hidden'>
            <div className='space-y-2 font-bold'>
              <span>{L10n.customCategory.enterPhraseTitle}</span>
              <span className='text-avoma-red'>*</span>
              <TextField
                placeholder={L10n.customCategory.phrasesPlaceholder}
                value={phraseValue}
                onChange={handlePhraseValueChange}
                onKeyDown={handleKeyDown}
                darkerPlaceholder
                containerStyles={{ flex: 1 }}
                userStyles={styles.input}
              />
              {phraseErrorMessage && (
                <div className='mt-2 text-sm text-red'>
                  {phraseErrorMessage}
                </div>
              )}
            </div>
            <div className='space-y-2 font-bold'>
              <span>{L10n.customCategory.enterExampleSentenceTitle}</span>
              <span className='text-avoma-red'>*</span>
              <TextField
                placeholder={L10n.customCategory.exampleSentencePlaceholder}
                value={exampleSentences[0].sentence}
                onChange={inputEvent =>
                  handleEditExampleSentence(0, inputEvent)
                }
                darkerPlaceholder
                containerStyles={{ flex: 1 }}
                userStyles={styles.input}
              />
              {generatingSentencesErrorMessage && (
                <div className='text-sm text-red'>
                  {generatingSentencesErrorMessage}
                </div>
              )}
            </div>
            <div className='space-y-2 font-bold'>
              <div className='flex items-center justify-between gap-x-4'>
                <div className='flex flex-1 items-center gap-x-1'>
                  <span>{L10n.customCategory.enterExampleSentencesTitle}</span>
                  <HelpPopover
                    title={L10n.customCategory.exampleSentencesTitle}
                    content={L10n.customCategory.exampleSentencesPopoverText}
                    buttonStyles={{
                      paddingLeft: Tokens.spacing.half
                    }}
                    popoverWidth={300}
                    popoverStyles={{ left: 0 }}
                  />
                </div>
                <AvomaButton
                  dataTestId='generate-more-examples-with-ai-button'
                  label={L10n.customCategory.generateMoreExamplesWithAi}
                  onClick={() =>
                    handleClickGenerateExampleSentences({
                      exampleSentenceText: exampleSentences[0].sentence,
                      numOfSentences: emptySentencesCount
                    })
                  }
                  faIcon={<AiSparkleIcon className='mr-1 h-3 w-3' />}
                  className='h-7 w-fit'
                  loading={isFetchingGeneratedSentences}
                  disabled={
                    shouldDisableGenerateWithAI || isMaxNumberOfSentences
                  }
                  tooltip={getDisabledGenerateWithAiCtaTooltip()}
                  tooltipPosition='bottom-left wrapped-small'
                  extraSmall
                />
              </div>

              {exampleSentences?.slice(1).map((sentence, index) => (
                <div
                  key={`example-sentence-${index}`}
                  className={twMerge(
                    'group flex items-center',
                    exampleSentences.length > 5 && '-mr-8'
                  )}
                >
                  <TextField
                    placeholder={`Example sentence ${index + 1}`}
                    value={
                      isFetchingGeneratedSentences && !sentence.sentence
                        ? 'Generating...'
                        : sentence.sentence
                    }
                    onChange={inputEvent =>
                      handleEditExampleSentence(index + 1, inputEvent)
                    }
                    darkerPlaceholder
                    containerStyles={{
                      flex: 1,
                      width: '100%',
                      marginRight: Tokens.spacing.one
                    }}
                    userStyles={{
                      ...styles.input,
                      ...(isFetchingGeneratedSentences &&
                        !sentence.sentence && {
                          cursor: 'wait',
                          opacity: 0.4
                        })
                    }}
                  />

                  {exampleSentences.length > 5 && (
                    <AvomaTooltip
                      tooltip={`Delete example sentence ${index + 1}`}
                      tooltipPosition='bottom-right'
                    >
                      <div className='sticky right-0 rounded p-1 group-hover:bg-snow'>
                        <DeleteIcon
                          onClick={() => handleDeleteExampleSentence(index + 1)}
                          className='filter-silverDark hover:filter-avomaRed h-4 w-4 cursor-pointer opacity-0 group-hover:opacity-100'
                        />
                      </div>
                    </AvomaTooltip>
                  )}
                </div>
              ))}

              <div className='flex w-full items-center gap-x-4'>
                <AvomaButton
                  label={L10n.customCategory.addExampleSentence}
                  onClick={handleAddExampleSentence}
                  disabled={
                    isMaxNumberOfSentences || isFetchingGeneratedSentences
                  }
                  extraSmall
                  secondary
                />

                {sentenceErrorMessage && (
                  <div className='text-sm text-red'>{sentenceErrorMessage}</div>
                )}
              </div>
            </div>
          </div>
          <div className='flex w-full items-center justify-end gap-x-4 mt-3'>
            <AvomaButton
              onClick={handleCloseCreateUpdateDialog}
              label={L10n.general.cancel}
              small
              black
            />
            <AvomaButton
              dataTestId='save-update-button'
              onClick={
                phraseUuid
                  ? isUnifiedFlow
                    ? handleModifyCustomPhrase
                    : handleUpdateCustomPhrase
                  : isUnifiedFlow
                  ? handleAddCustomPhrase
                  : handleCreateCustomPhrase
              }
              label={phraseUuid ? 'Update Smart Tracker' : 'Add Smart Tracker'}
              disabled={
                shouldDisableSaveOrUpdate || isFetchingGeneratedSentences
              }
              loading={showButtonLoader}
              tooltip={
                shouldDisableSaveOrUpdate &&
                L10n.customCategory.saveTrackerToolip
              }
              tooltipPosition='top-left wrapped-small'
              small
            />
          </div>
        </React.Fragment>
      )}

      {/* Delete phrase dialog */}
      {phraseUuid && (
        <AvomaDialog
          controlled
          open={showDeleteDialog}
          setOpen={handleToggleDeleteDialog}
          closeCleanupHandler={() => handleCloseDeleteDialog()}
          title={`Delete phrase "${
            (isUnifiedFlow ? trackers : category?.trackers)[phraseUuid] &&
            (isUnifiedFlow ? trackers : category?.trackers)[phraseUuid].label
          }"?`}
          contentClasses='max-w-[480px]'
          footer={
            <div className='flex w-full items-center justify-end gap-x-4'>
              <AvomaButton
                onClick={handleCloseDeleteDialog}
                label={L10n.general.cancel}
                small
                black
              />
              <AvomaButton
                onClick={
                  isUnifiedFlow
                    ? handleRemoveCustomPhrase
                    : handleDeleteCustomPhrase
                }
                label={L10n.general.delete}
                small
                destructive
                loading={showButtonLoader}
              />
            </div>
          }
        >
          Do you want to delete the phrase "
          {(isUnifiedFlow ? trackers : category?.trackers)[phraseUuid]?.label}"?
          This will also delete all of the example sentences associated with
          this phrase.
        </AvomaDialog>
      )}
    </CardSection>
  );
};

const styles = {
  inputContainer: {
    flex: 1,
    marginRight: Tokens.spacing.two
  },
  input: {
    ...Tokens.type.bodyS,
    flex: 1,
    fontWeight: 'normal',
    height: Tokens.spacing.four,
    padding: `${Tokens.spacing.half} ${Tokens.spacing.one}`
  },
  disabled: {
    opacity: '0.4',
    cursor: 'not-allowed'
  }
};

CustomPhrasesV2.propTypes = {
  category: PropTypes.object,
  loading: PropTypes.bool,
  handleAddOrModifyKeywordOrPhrase: PropTypes.func,
  isUnifiedFlow: PropTypes.bool,
  trackers: PropTypes.array,
  setTrackers: PropTypes.func,
  isShowCustomPhraseDetails: PropTypes.bool,
  setIsShowCustomPhraseDetails: PropTypes.func
};

export default CustomPhrasesV2;
