/*
    Motivation: Building a text input, with an Add Variable button attached to work as 
    templatised variables. For example: Meeting with {{contact.name}} on {{meeting.date}}
    should be rendered as Meeting with John Doe on 2021-08-21

    Separating the input and the popover that handles which variables are available to be added
*/
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as DownArrowIcon } from 'images/downArrow.svg';

import Tokens from 'styles/tokens';

import AvomaPopoverControlled from '../AvomaPopoverControlled';
import ButtonUnstyled from '../ButtonUnstyled';

const AvomaVariableTextArea = ({
  value = '',
  onChange,
  placeholder,
  variables,
  variableTitle,
  closePopoverOnAddVariable = true,
  preview = false,
  dataTestId,
  dropdownContentClassname = '',
  wrapperStyle = {},
  portalContainer = document.body
}) => {
  const [cursorPosition, setCursorPosition] = useState(0);
  const [isAddVariableDropdownOpen, setIsAddVariableDropDownOpen] =
    useState(false);

  const renderDisplayVariable = val => {
    const displayVal = variables.find(item => item.value === val.trim());
    return `${displayVal.label}`;
  };

  const removeCurlyBrackets = str => str.replace(/{{|}}/g, '');

  const addCurlyBrackets = (str, id) =>
    str.replace(new RegExp(id, 'g'), `{{${id}}}`);
  const findWordEnd = (str, startPos) => {
    let pos = startPos;

    // this just looks complicated. this checks if cursor is actually in a string, or at space, or line end and keeps searching till it reaches end of word

    while (
      pos < str.length &&
      str[pos] !== ' ' &&
      str[pos] !== '\n' &&
      str[pos] !== '\r'
    ) {
      pos += 1;
    }
    // if cursor is at end of line, we need to go back one step or insertion happens on next line

    return str[pos] === '\n' || str[pos] === '\r' ? pos - 1 : pos + 1;
  };

  const handleAddNewVariable = nameValue => {
    //  remove curly brackets from value, find the end of the word where cursor is, replace the word with the new variable and add curly brackets back
    const valueWithoutCurlyBrackets = removeCurlyBrackets(value);

    const wordEnd =
      valueWithoutCurlyBrackets[cursorPosition] !== ' '
        ? findWordEnd(valueWithoutCurlyBrackets, cursorPosition)
        : cursorPosition;

    const newValue = [
      valueWithoutCurlyBrackets.slice(0, wordEnd),
      removeCurlyBrackets(nameValue),
      valueWithoutCurlyBrackets.slice(wordEnd)
    ].join('');

    const newValueWithCurlyBrackets = variables.reduce(
      (str, item) => addCurlyBrackets(str, item.value),
      newValue
    );

    onChange(newValueWithCurlyBrackets);

    if (closePopoverOnAddVariable) {
      setIsAddVariableDropDownOpen(false);
    }
  };

  return (
    <div className='flex flex-col'>
      <MentionsInput
        style={{
          ...styles.textarea,
          ...(preview && { border: 'none' }),
          highlighter: {
            zIndex: 100,
            pointerEvents: 'none'
          },
          input: styles.textfield
        }}
        allowSpaceInQuery
        onKeyUp={e => {
          setCursorPosition(e.target.selectionStart);
        }}
        onMouseUp={e => {
          setCursorPosition(e.target.selectionStart);
        }}
        onBlur={e => {
          setCursorPosition(e.target.selectionEnd);
        }}
        onClick={e => {
          setCursorPosition(e.target.selectionEnd);
        }}
        value={value}
        placeholder={placeholder}
        autocomplete='off'
        onChange={e => onChange(e.target.value)}
        data-testid={dataTestId}
      >
        <Mention
          trigger='{{'
          markup='{{__id__}}'
          displayTransform={renderDisplayVariable}
          //   renderSuggestion={renderEventTitleSuggestion}
          className='box-border rounded bg-blue-lightest text-blue'
          appendSpaceOnAdd
          data={variables}
          style={{ opacity: '70%' }}
        />
      </MentionsInput>
      {!preview && (
        <div className='ml-auto'>
          <AvomaPopoverControlled
            controlled
            open={isAddVariableDropdownOpen}
            setOpen={setIsAddVariableDropDownOpen}
            dropdownTrigger={() => (
              <ButtonUnstyled
                noDefaultStyles
                className='rounded-r-0 flex h-10 w-[140px] flex-shrink-0 cursor-pointer items-center justify-center rounded-b border-l border-r border-b border-gainsboro bg-white text-sm font-bold text-blue outline-none hover:bg-smoke'
              >
                <span>Add Variable</span>
                <DownArrowIcon
                  className='relative ml-2 h-2 w-2'
                  style={{ filter: Tokens.filters.blue }}
                  alt='dropdown'
                />
              </ButtonUnstyled>
            )}
            contentClasses={twMerge(
              '!p-0 max-h-[300px] max-w-[350px] overflow-y-auto flex flex-col items-start box-border',
              dropdownContentClassname
            )}
            align='end'
            wrapperStyle={wrapperStyle}
            portalContainer={portalContainer}
          >
            {variableTitle && (
              <p className='px-4 py-3 text-sm font-bold text-gunmetal'>
                {variableTitle}
              </p>
            )}
            {variables.map(variable => (
              <ButtonUnstyled
                key={variable.value}
                noDefaultStyles
                className='box-border flex w-full cursor-pointer flex-col gap-0.5 rounded bg-white px-4 py-3 hover:bg-snow'
                onClick={() => {
                  handleAddNewVariable(variable.value);
                }}
              >
                <span className='text-left text-sm text-gunmetal'>
                  {variable.label}
                </span>
                <span className='text-left text-xs text-silver'>
                  {variable.description}
                </span>
              </ButtonUnstyled>
            ))}
          </AvomaPopoverControlled>
        </div>
      )}
    </div>
  );
};

const styles = {
  textarea: {
    ...Tokens.type.bodyS,
    boxSizing: 'border-box',
    backgroundColor: Tokens.colors.white,
    padding: `3px 7px`,
    width: '100%',
    minHeight: '100px',
    borderRadius: `${Tokens.spacing.borderRadius} ${Tokens.spacing.borderRadius} 0px ${Tokens.spacing.borderRadius}`,
    border: Tokens.borders.standard,
    fontSize: Tokens.type.body
    // flex: 2
  },

  textfield: {
    padding: `${Tokens.spacing.half} ${Tokens.spacing.one}`,
    color: Tokens.colors.gunmetal,
    borderRadius: `${Tokens.spacing.borderRadius} 0px 0px ${Tokens.spacing.borderRadius}`,
    // height: Tokens.spacing.five,
    outline: 'none',
    width: '100%',
    height: '100%'
  }
};

AvomaVariableTextArea.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  variableTitle: PropTypes.string,
  dropdownContentClassname: PropTypes.string,
  wrapperStyle: PropTypes.object,
  closePopoverOnAddVariable: PropTypes.bool,
  preview: PropTypes.bool,
  dataTestId: PropTypes.string,
  portalContainer: PropTypes.element
};

export default AvomaVariableTextArea;
