import { alertNotificationsActionTypes } from '../actionTypes/alertNotificationsActionTypes';

import { MEETING_ALERTS_TAB_KEY } from 'constants/alerts';

const initialArg = {
  currentStep: 0,
  isDrawerOpen: false,
  search: '',
  currentSelected: {},
  readOnly: false,
  tab: MEETING_ALERTS_TAB_KEY.standard
};
const alertNotifications = (localState = initialArg, action) => {
  switch (action.type) {
    case alertNotificationsActionTypes.currentStep:
      return {
        ...localState,
        currentStep: action.payload,
        ...(action.payload === 0 && { readOnly: false })
      };
    case alertNotificationsActionTypes.search:
      return {
        ...localState,
        search: action.payload
      };
    case alertNotificationsActionTypes.currentSelected: {
      return {
        ...localState,
        currentSelected: {
          ...action.payload
        }
      };
    }
    case alertNotificationsActionTypes.readOnly:
      return {
        ...localState,
        readOnly: action.payload
      };
    case alertNotificationsActionTypes.tab:
      return {
        ...localState,
        tab: action.payload
      };
    default:
      return localState;
  }
};

export default alertNotifications;
