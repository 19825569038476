import PropTypes from 'prop-types';
import React from 'react';
import stylePropType from 'react-style-proptype';
import { twMerge } from 'tailwind-merge';

import { isMobile } from 'helpers/deviceCutoffs';

import Tokens from 'styles/tokens';

const styles = {
  dropdownWrapper: {
    ...Tokens.shadows.medium,
    position: 'absolute',
    backgroundColor: Tokens.colors.white,
    border: `1px solid ${Tokens.colors.gainsboro}`,
    borderRadius: '3px',
    zIndex: '9999'
  },
  positionBottom: {
    marginTop: '6px'
  },
  positionTop: {
    marginBottom: Tokens.spacing.one,
    bottom: Tokens.spacing.three
  },
  dropdownInner: {
    padding: Tokens.spacing.two
  }
};

const AvomaPopover = ({
  children,
  mobileFullWidth,
  width,
  style,
  wrapperStyle,
  position,
  isRightAlign,
  className = '',
  innerDivClassname = '',
  ...otherProps
}) => (
  <div
    style={{
      ...styles.dropdownWrapper,
      ...(isRightAlign && { right: 0 }),
      ...(position === 'bottom' ? styles.positionBottom : styles.positionTop),
      width: isMobile() && mobileFullWidth ? '100vw' : `${width}px`,
      ...wrapperStyle,
      ...(isMobile() && mobileFullWidth && { position: 'fixed' })
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...otherProps}
    className={twMerge('rounded', className)}
  >
    <div
      style={{
        ...styles.dropdownInner,
        ...style
      }}
      className={innerDivClassname}
    >
      {children}
    </div>
  </div>
);

AvomaPopover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  style: stylePropType,
  wrapperStyle: stylePropType,
  width: PropTypes.number,
  mobileFullWidth: PropTypes.bool,
  position: PropTypes.oneOf(['top', 'bottom']),
  isRightAlign: PropTypes.bool,
  className: PropTypes.string,
  innerDivClassname: PropTypes.string
};

AvomaPopover.defaultProps = {
  children: null,
  width: 256,
  position: 'bottom',
  isRightAlign: false
};

export default AvomaPopover;
