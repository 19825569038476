import { PublicActions } from 'actions/actionTypes';

const initialState = {
  data: {},
  code: null,
  redirectData: {}
};

const publics = (state = initialState, action) => {
  switch (action.type) {
    case PublicActions.FETCH_PUBLIC_HIGHLIGHT_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.token]: {
            ...state.data[action.token],
            tokenRequested: false,
            loading: true,
            isReady: false,
            error: null
          }
        }
      };
    case PublicActions.FETCH_PUBLIC_HIGHLIGHT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.token]: {
            ...action.share,
            tokenRequested: false,
            loading: false,
            error: null
          }
        }
      };
    case PublicActions.FETCH_PUBLIC_HIGHLIGHT_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.token]: {
            ...state.data[action.token],
            tokenRequested: false,
            isReady: false,
            loading: false,
            error: action.error
          }
        },
        code: null
      };
    case PublicActions.ACCESS_CODE_REQUESTED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.token]: {
            ...state.data[action.token],
            tokenRequested: action.email,
            tokenRequestedTime: new Date(),
            codeError: null,
            error: null
          }
        }
      };
    case PublicActions.ACCESS_CODE_REQUEST_FAILED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.token]: {
            ...state.data[action.token],
            codeError: action.error
          }
        },
        code: null
      };
    case PublicActions.SUBMIT_ACCESS_CODE:
      return {
        ...state,
        code: action.code
      };
    // performs similarly to the SEEK_TO_TIME, however stores it in the publics object
    // in the store for public views
    case PublicActions.PUBLIC_SEEK_TO_TIME:
      return {
        ...state,
        data: {
          ...state.data,
          [action.token]: {
            ...state.data[action.token],
            recordingData: {
              ...state.data[action.token]?.recordingData,
              lastSeek: action.time
            }
          }
        }
      };

    // Store the highlight videos returned from the highlightrecording url
    case PublicActions.FETCH_PUBLIC_HIGHLIGHT_VIDEO:
      return {
        ...state,
        data: {
          ...state.data,
          [action.snippetUuid]: {
            ...state.data[action.snippetUuid],
            highlightVideo: {
              ...state.data[action.snippetUuid].highlightVideo,
              [action.selectedSnippetUuid]: {
                ...action.highlightVideo
              }
            }
          }
        }
      };

    // For getting Legacy (/listen) links new redirects
    case PublicActions.FETCH_REDIRECT_LEGACY_LINK_REQUEST:
      return {
        ...state,
        redirectData: {
          ...action.redirectData,
          loading: true,
          error: false
        }
      };
    case PublicActions.FETCH_REDIRECT_LEGACY_LINK_SUCCESS:
      return {
        ...state,
        redirectData: {
          ...action.redirectData,
          loading: false,
          error: false
        }
      };
    case PublicActions.FETCH_REDIRECT_LEGACY_LINK_FAILURE:
      return {
        ...state,
        redirectData: {
          ...action.redirectData,
          loading: false,
          error: true
        }
      };

    case PublicActions.SET_PUBLIC_CODE:
      return {
        ...state,
        code: action.code
      };

    default:
      return state;
  }
};

export default publics;
