import { useState } from 'react';

const useHover = (mouseEnterDelayMS = 0, mouseLeaveDelayMS = 0) => {
  const [hovered, setHovered] = useState();
  let mouseEnterTimer;
  let mouseOutTimer;
  return [
    hovered,
    {
      onMouseEnter: () => {
        clearTimeout(mouseOutTimer);
        mouseEnterTimer = setTimeout(() => setHovered(true), mouseEnterDelayMS);
      },
      onMouseLeave: () => {
        clearTimeout(mouseEnterTimer);
        mouseOutTimer = setTimeout(() => setHovered(false), mouseLeaveDelayMS);
      }
    }
  ];
};

export default useHover;
