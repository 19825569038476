import { CompanyActions } from 'actions/actionTypes';

const initialState = {
  data: {},
  dashboardColumns: {},
  dashboardDetails: {}
};

const getLoadingEngagement = (state, action, value) => {
  const data = state.data[action.provider];
  return {
    ...(data && data.loadingEngagement),
    [action.dealUuid]: {
      ...(data &&
        data.loadingEngagement &&
        data.loadingEngagement[action.dealUuid]),
      [action.interval]: {
        ...(data &&
          data.loadingEngagement &&
          data.loadingEngagement[action.dealUuid] &&
          data.loadingEngagement[action.dealUuid][action.interval]),
        [action.offset]: value
      }
    }
  };
};

const getErrorEngagement = (state, action, error) => {
  const data = state.data[action.provider];
  return {
    ...(data && data.errorEngagement),
    [action.dealUuid]: {
      ...(data &&
        data.errorEngagement &&
        data.errorEngagement[action.dealUuid]),
      [action.interval]: {
        ...(data &&
          data.errorEngagement &&
          data.errorEngagement[action.dealUuid] &&
          data.errorEngagement[action.dealUuid][action.interval]),
        [action.offset]: error
      }
    }
  };
};

const companies = (state = initialState, action) => {
  switch (action.type) {
    case CompanyActions.FETCH_ENGAGEMENT_REQUEST: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            loadingEngagement: getLoadingEngagement(state, action, true),
            engagementDetails: {
              ...state.data[action.provider]?.engagementDetails
            }
          }
        }
      };
    }
    case CompanyActions.FETCH_ENGAGEMENT_SUCCESS: {
      const data = state.data[action.provider];
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...data,
            engagement: {
              ...(data && data.engagement),
              [action.dealUuid]: {
                ...(data &&
                  data.engagement &&
                  data.engagement[action.dealUuid]),
                [action.interval]: {
                  ...(data &&
                    data.engagement &&
                    data.engagement[action.dealUuid] &&
                    data.engagement[action.dealUuid][action.interval]),
                  ...action.engagement
                }
              }
            },
            loadingEngagement: getLoadingEngagement(state, action, false),
            engagementDetails: {
              ...state.data[action.provider].engagementDetails
            }
          }
        }
      };
    }
    case CompanyActions.FETCH_ENGAGEMENT_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            loadingEngagement: getLoadingEngagement(state, action, false),
            errorEngagement: getErrorEngagement(state, action, action.error),
            engagementDetails: {}
          }
        }
      };
    case CompanyActions.FETCH_TIMELINE_DETAILS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            engagementDetails: {
              ...state.data[action.provider].engagementDetails,
              [action.bubbleUniqueKey]: {
                loading: true
              }
            }
          }
        }
      };
    case CompanyActions.FETCH_TIMELINE_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            engagementDetails: {
              ...state.data[action.provider].engagementDetails,
              [action.bubbleUniqueKey]: {
                loading: false,
                data: action.response
              }
            }
          }
        }
      };
    case CompanyActions.FETCH_TIMELINE_DETAILS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            engagementDetails: {
              ...(state.data[action.provider]?.engagementDetails || {}),
              [action.bubbleUniqueKey]: {
                loading: false,
                error: action.error
              }
            }
          }
        }
      };
    case CompanyActions.SET_TIMELINE_DETAILS_HOVER: {
      if (state.data[action.provider].bubbleHover)
        delete state.data[action.provider].bubbleHover;
      if (action.shouldDelete) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.provider]: {
              ...state.data[action.provider],
              bubbleHover: {}
            }
          }
        };
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            bubbleHover: {
              [action.uniqueHoverKey]: true
            }
          }
        }
      };
    }

    case CompanyActions.FETCH_COMPANY_ENGAGEMENT_DETAILS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            dealEngDetailsLoading: true
          }
        }
      };

    case CompanyActions.FETCH_COMPANY_ENGAGEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            dealEngDetails: {
              ...state.data[action.provider].dealEngDetails,
              [action.uuid]: action.response
            },
            dealEngDetailsLoading: false
          }
        }
      };
    case CompanyActions.FETCH_COMPANY_ENGAGEMENT_DETAILS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            dealEngDetailsLoading: false,
            dealEngDetailsError: true
          }
        }
      };

    case CompanyActions.UPDATE_COMPANY_PIVOT_DETAILS_IN_CRM_REQUEST: {
      if (!action.controller) {
        return state;
      }
      const previousController =
        state.data?.[action.provider]?.[action.dashboardUuid]?.results?.[
          action.companyId
        ].metadata?.controller;
      if (previousController) {
        previousController.abort();
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              ...state.data[action.provider][action.dashboardUuid],
              results: {
                ...state.data[action.provider][action.dashboardUuid].results,
                [action.companyId]: {
                  ...state.data[action.provider][action.dashboardUuid].results[
                    action.companyId
                  ],
                  metadata: { controller: action.controller }
                }
              }
            }
          }
        }
      };
    }
    case CompanyActions.UPDATE_COMPANY_PIVOT_DETAILS_IN_CRM_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              ...state.data[action.provider][action.dashboardUuid],
              results: {
                ...state.data[action.provider][action.dashboardUuid].results,
                [action.companyId]: {
                  ...state.data[action.provider][action.dashboardUuid].results[
                    action.companyId
                  ],
                  ...action.response
                }
              }
            }
          }
        }
      };
    }

    case CompanyActions.FETCH_CURRENT_COMPANY_DASHBOARD_COLUMN_HEADERS_REQUEST: {
      return {
        ...state,
        dashboardColumns: {
          [action.providerUuid]: {
            ...state.dashboardColumns[action.providerUuid],
            [action.dashboardUuid]: {
              ...state.dashboardColumns?.[action.providerUuid]?.[
                action.dashboardUuid
              ],
              fetching: true
            }
          }
        }
      };
    }

    case CompanyActions.FETCH_CURRENT_COMPANY_DASHBOARD_COLUMN_HEADERS_SUCCESS: {
      return {
        ...state,
        dashboardColumns: {
          [action.providerUuid]: {
            ...state.dashboardColumns[action.providerUuid],
            [action.dashboardUuid]: {
              fetching: false,
              data: action.response
            }
          }
        }
      };
    }

    case CompanyActions.FETCH_CURRENT_COMPANY_DASHBOARD_COLUMN_HEADERS_FAILURE: {
      return {
        ...state,
        dashboardColumns: {
          [action.providerUuid]: {
            ...state.dashboardColumns[action.providerUuid],
            [action.dashboardUuid]: {
              fetching: false,
              error: action.error
            }
          }
        }
      };
    }

    case CompanyActions.FETCH_COMPANIES_REQUEST: {
      const previousController =
        state.data?.[action.provider]?.[action.dashboardUuid]?.controller;
      if (previousController) {
        previousController.abort();
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              loading: true,
              requestId: action.requestId,
              engagementDetails: {},
              controller: action.controller
            }
          }
        }
      };
    }
    case CompanyActions.FETCH_COMPANIES_SUCCESS: {
      if (
        state.data[action.provider][action.dashboardUuid].requestId !==
        action.requestId
      ) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              results: action.results,
              next: action.next,
              count: action.count,
              loading: false,
              requestId: null,
              engagementDetails: {
                ...state.data[action.provider].engagementDetails
              }
            }
          }
        }
      };
    }
    case CompanyActions.FETCH_COMPANIES_FAILURE: {
      if (
        state.data[action.provider][action.dashboardUuid].requestId !==
        action.requestId
      ) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              loading: false,
              requestId: null,
              engagementDetails: {
                ...state.data[action.provider].engagementDetails
              }
            }
          }
        }
      };
    }

    case CompanyActions.FETCH_MORE_COMPANIES_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              ...state.data[action.provider][action.dashboardUuid],
              loadingMore: true,
              engagementDetails: {
                ...state.data[action.provider][action.dashboardUuid]
                  .engagementDetails
              }
            }
          }
        }
      };

    case CompanyActions.FETCH_MORE_COMPANIES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              ...state.data[action.provider][action.dashboardUuid],
              results: {
                ...state.data[action.provider][action.dashboardUuid].results,
                ...action.results
              },
              next: action.next,
              loadingMore: false,
              engagementDetails: {
                ...state.data[action.provider][action.dashboardUuid]
                  .engagementDetails
              }
            }
          }
        }
      };

    case CompanyActions.FETCH_MORE_COMPANIES_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              ...state.data[action.provider][action.dashboardUuid],
              loadingMore: false,
              engagementDetails: {
                ...state.data[action.provider][action.dashboardUuid]
                  .engagementDetails
              }
            }
          }
        }
      };

    case CompanyActions.UPDATE_COMPANY_DASHBOARD_COLUMNS_ORDER_REQUEST: {
      const { dashboardUuid, provider, newColumnOrder } = action;

      return {
        ...state,
        dashboardColumns: {
          [provider]: {
            ...state.dashboardColumns[provider],
            [dashboardUuid]: {
              ...state.dashboardColumns[provider][dashboardUuid],
              data: newColumnOrder
            }
          }
        }
      };
    }
    case CompanyActions.UPDATE_COMPANY_DASHBOARD_COLUMNS_ORDER_SUCCESS: {
      return {
        ...state
      };
    }
    case CompanyActions.UPDATE_COMPANY_DASHBOARD_COLUMNS_ORDER_FAILURE: {
      const { dashboardUuid, provider } = action;

      return {
        ...state,
        dashboardColumns: {
          [provider]: {
            ...state.dashboardColumns[provider],
            [dashboardUuid]: {
              ...state.dashboardColumns[provider][dashboardUuid],
              updatingColumnOrder: {
                loading: false,
                error: true
              }
            }
          }
        }
      };
    }

    case CompanyActions.FETCH_DASHBOARD_VIEW_SUMMARY_REQUEST: {
      return {
        ...state,
        dashboardDetails: {
          ...state.dashboardDetails,
          [action.providerUuid]: {
            ...(state.dashboardDetails?.[action.providerUuid] || {}),
            [action.dashboardUuid]: {
              ...(state.dashboardDetails?.[action.providerUuid]?.[
                action.dashboardUuid
              ] || {}),
              fetching: true
            }
          }
        }
      };
    }
    case CompanyActions.FETCH_DASHBOARD_VIEW_SUMMARY_SUCCESS: {
      return {
        ...state,
        dashboardDetails: {
          ...state.dashboardDetails,
          [action.providerUuid]: {
            ...state.dashboardDetails[action.providerUuid],
            [action.dashboardUuid]: {
              ...state.dashboardDetails[action.providerUuid][
                action.dashboardUuid
              ],
              fetching: false,
              data: action.response
            }
          }
        }
      };
    }
    case CompanyActions.FETCH_DASHBOARD_VIEW_SUMMARY_FAILURE: {
      return {
        ...state,
        dashboardDetails: {
          ...state.dashboardDetails,
          [action.providerUuid]: {
            ...state.dashboardDetails[action.providerUuid],
            [action.dashboardUuid]: {
              ...state.dashboardDetails[action.providerUuid][
                action.dashboardUuid
              ],
              fetching: false,
              data: null,
              error: action.err
            }
          }
        }
      };
    }

    case CompanyActions.FETCH_COMPANY_ENGAGEMENT_BUBBLE_DETAILS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            dealEngBubbleDetails: {
              ...state.data[action.provider].dealEngBubbleDetails,
              [action.bubbleUniqueKey]: {
                loading: true
              }
            }
          }
        }
      };
    case CompanyActions.FETCH_COMPANY_ENGAGEMENT_BUBBLE_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            dealEngBubbleDetails: {
              ...state.data[action.provider].dealEngBubbleDetails,
              [action.bubbleUniqueKey]: {
                loading: false,
                data: action.response
              }
            }
          }
        }
      };
    case CompanyActions.FETCH_COMPANY_ENGAGEMENT_BUBBLE_DETAILS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            dealEngBubbleDetails: {
              ...state.data[action.provider].dealEngBubbleDetails,
              [action.bubbleUniqueKey]: {
                loading: false,
                error: action.error
              }
            }
          }
        }
      };

    default:
      return state;
  }
};

export default companies;
