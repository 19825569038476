export const CHAT_CONTEXT_TYPES = {
  MEETING: 0,
  CALL: 0,
  DEAL: 1,
  GLOBAL: 2
};

export const CHAT_CONTEXT_TYPE_NAMES = {
  MEETING: 'meeting',
  CALL: 'call',
  DEAL: 'deal',
  GLOBAL: 'global'
};

export const MESSAGE_AUTHOR_TYPES = {
  COPILOT: 0,
  USER: 1,
  SYSTEM: 2
};

export const MESSAGE_STATUS_OPTIONS = {
  IN_PROGRESS: 'in_progress',
  FINISHED_SUCCESSFULLY: 'finished_successfully'
};

export const COPILOT_ERROR_MESSAGE = {
  author: MESSAGE_AUTHOR_TYPES.SYSTEM,
  message:
    'An unexpected error occurred. Please try submitting your message again.'
};

export const SUGGESTED_PROMPTS_TYPES = {
  AI: '0',
  DEFAULT: '1'
};

export const MEETING_SUGGESTED_PROMPTS = [
  {
    text: 'Can you summarize the key discussion points from this conversation?',
    type: SUGGESTED_PROMPTS_TYPES.DEFAULT
  },
  {
    text: 'What action items were discussed during this conversation?',
    type: SUGGESTED_PROMPTS_TYPES.DEFAULT
  },
  {
    text: 'What were the main topics covered in this conversation?',
    type: SUGGESTED_PROMPTS_TYPES.DEFAULT
  },
  {
    text: 'Can you list the pain points the client is currently facing?',
    type: SUGGESTED_PROMPTS_TYPES.DEFAULT
  }
];

export const DEAL_SUGGESTED_PROMPTS = [
  {
    text: 'Summarize the deal: focus on objectives, challenges, timeline, and next steps.',
    type: SUGGESTED_PROMPTS_TYPES.DEFAULT
  },
  {
    text: 'Outline a timeline of significant events related to this deal.',
    type: SUGGESTED_PROMPTS_TYPES.DEFAULT
  },
  {
    text: 'Identify the primary concerns of the decision-makers involved in this deal.',
    type: SUGGESTED_PROMPTS_TYPES.DEFAULT
  },
  {
    text: 'Describe the customer’s current sentiment towards this deal.',
    type: SUGGESTED_PROMPTS_TYPES.DEFAULT
  }
];

export const GLOBAL_SUGGESTED_PROMPTS = [
  {
    text: 'Summarize the latest updates from my meetings this week.',
    type: SUGGESTED_PROMPTS_TYPES.DEFAULT
  },
  {
    text: 'Draft a follow-up email based on my latest meeting notes.',
    type: SUGGESTED_PROMPTS_TYPES.DEFAULT
  },
  {
    text: 'Provide an overview of the most recent three deals and their current status.',
    type: SUGGESTED_PROMPTS_TYPES.DEFAULT
  },
  {
    text: 'Provide my key action items from my last three meetings.',
    type: SUGGESTED_PROMPTS_TYPES.DEFAULT
  }
];

export const ENGAGEMENT_TYPES = {
  GLOBAL_MEETING: 'meeting',
  DEAL_MEETING: 'meeting_engagement',
  EMAIL: 'email_engagement'
};

export const GLOBAL_LOADING_MESSAGES = [
  'Generating a plan to answer your question',
  'Searching for relevant information',
  'Analyzing data to provide accurate results',
  'Preparing insights and summarizing findings',
  'Almost there'
];

export const COPILOT_CONCISE_MODE_OPTIONS = [
  {
    label: 'Concise',
    value: 'concise'
  },
  {
    label: 'Detailed',
    value: 'detailed'
  }
];
