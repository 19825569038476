import { DownloadItem } from 'types/downloads';

import { DownloadActions } from '../actions/actionTypes';
import { PayloadAction } from '@reduxjs/toolkit';

type State = {
  abortController: AbortController | null;
  active: Array<DownloadItem>;
  completed: Array<DownloadItem>;
  failed: Array<DownloadItem>;
};

const initialState: State = {
  abortController: null,
  active: [],
  completed: [],
  failed: []
};

const downloads = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: State = initialState,
  action: PayloadAction<
    Array<DownloadItem> | DownloadItem,
    string,
    { controller: AbortController | null }
  >
): State => {
  switch (action.type) {
    case DownloadActions.ADD_NEW_DOWNLOADS:
      return {
        ...state,
        abortController:
          state.abortController || action.meta?.controller || null,
        active: [...(action.payload as Array<DownloadItem>)]
      };
    case DownloadActions.DOWNLOAD_SUCCESS:
      return {
        ...state,
        active: state.active.filter(
          (download: DownloadItem) =>
            download.url !== (action.payload as DownloadItem).url
        ),
        completed: [...state.completed, action.payload as DownloadItem]
      };
    case DownloadActions.DOWNLOAD_FAILURE:
      return {
        ...state,
        active: state.active.filter(
          (download: DownloadItem) =>
            download.url !== (action.payload as DownloadItem).url
        ),
        failed: [...state.failed, action.payload as DownloadItem]
      };
    case DownloadActions.CANCEL_DOWNLOADS:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default downloads;
