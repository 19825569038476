import { BookmarkActions } from 'actions/actionTypes';

export const bookmarksInitialState = {
  data: {}
};

const bookmarks = (state = bookmarksInitialState, action) => {
  switch (action.type) {
    case BookmarkActions.CREATE_BOOKMARK_SUCCESS:
    case BookmarkActions.FETCH_BOOKMARK_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.bookmark.meeting]: {
            ...state.data[action.bookmark.meeting],
            [action.bookmark.uuid]: {
              ...action.bookmark
            }
          }
        }
      };
    case BookmarkActions.FETCH_BOOKMARKS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...action.bookmarks.reduce((map, obj) => {
              // eslint-disable-next-line no-param-reassign
              map[obj.uuid] = obj;
              return map;
            }, {})
          }
        }
      };
    case BookmarkActions.DELETE_BOOKMARK_SUCCESS: {
      const remainingData = state.data;
      delete remainingData[action.meetingId][action.uuid];
      return {
        ...state,
        data: remainingData
      };
    }
    default:
      return state;
  }
};

export default bookmarks;
