import PropTypes from 'prop-types';

import Caret from './SlateYjs/Caret';

const Leaf = ({ attributes, children, leaf }) => {
  let nodes = children;
  if (leaf.bold) {
    nodes = <strong>{nodes}</strong>;
  }

  if (leaf.code) {
    nodes = <code>{nodes}</code>;
  }

  if (leaf.italic) {
    nodes = <em>{nodes}</em>;
  }

  if (leaf.underline) {
    nodes = <u>{nodes}</u>;
  }

  if (leaf.strikethrough) {
    nodes = <del>{nodes}</del>;
  }

  const data = leaf?.data;

  return (
    <span
      {...attributes}
      style={{
        position: 'relative',
        backgroundColor: data?.alphaColor
      }}
    >
      {nodes}
      {leaf.isCaret ? <Caret {...data} /> : null}
    </span>
  );
};

Leaf.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node,
  leaf: PropTypes.shape({
    bold: PropTypes.bool,
    italic: PropTypes.bool,
    underline: PropTypes.bool,
    strikethrough: PropTypes.bool,
    isCaret: PropTypes.bool,
    code: PropTypes.bool,
    data: PropTypes.shape({
      name: PropTypes.string,
      alphaColor: PropTypes.string
    })
  })
};

export default Leaf;
