/* eslint-disable jsx-a11y/no-static-element-interactions */
import isNan from 'lodash/isNaN';
import PropTypes from 'prop-types';
import Radium from 'radium';
import React, { useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import AvomaCount from 'components/Common/AvomaCount';
import AvomaPopover from 'components/Common/AvomaPopover';
import ButtonUnstyled from 'components/Common/ButtonUnstyled';
import ConfirmModal from 'components/Common/ConfirmModal';
import CrmIcon from 'components/Common/CrmIcon';
import VariationPopover from 'components/SmartCategories/Common/VariationPopover';

import L10n from 'helpers/L10n';

import { ReactComponent as DeleteIcon } from 'images/ic_delete_red.svg';
import { ReactComponent as EditIcon } from 'images/ic_edit_pen.svg';
import { ReactComponent as MoreIcon } from 'images/ic_more_vertical.svg';
import { ReactComponent as PlusIcon } from 'images/ic_plus.svg';

import Tokens from 'styles/tokens';

const Keyword = ({
  onDeleteKeyword,
  onDeleteKeywordVariation,
  keyword,
  variations,
  onCreateKeywordVariation,
  variationExistsError,
  onUpdateLabel,
  onClickMakePrimary,
  containerWidth,
  variationPopoverTitle,
  addMoreVariationsTooltip,
  clearVariationErrorMessage,
  variationExistsMessage,
  disabled,
  isCRMKeyword = false,
  isUnifiedFlow = false,
  hasDuplicateKeyword = false,
  hasDuplicateVariation = false,
  isAdminOrTopicOwner = false
}) => {
  const variationsCount = Object.keys(variations)?.length;
  // total count to be shown on the keyword or popover would be variations count in addition to the base keyword
  const totalCount = variationsCount + 1;
  const [showPopover, setShowPopover] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [showDeleteKeywordModal, setShowDeleteKeywordModal] = useState(false);
  const crmProvider = useSelector(state => state.user.crmProvider);
  const keywordRef = useRef();

  // Determine if the popover is to be shown right/left aligned (left being default) based on client width to avoid cutoffs
  const calculatePopoverPosition = () => {
    // If for some reason the container width is 0, return out else the popover default to right and goes out of bounds
    if (
      !containerWidth ||
      containerWidth === 0 ||
      isNan(keywordRef?.current?.offsetLeft)
    ) {
      return;
    }

    if (containerWidth - keywordRef?.current?.offsetLeft < 200) {
      return { right: 0 };
    }
  };

  const handleClosePopover = () => {
    setShowPopover(false);
    if (variationExistsError) {
      clearVariationErrorMessage();
    }
  };

  const handleClickPopover = () => {
    setShowPopover(true);
    setShowMoreDetails(false);
  };

  const handleDeleteConfirm = () => {
    onDeleteKeyword(keyword.uuid);
  };

  const handleDeleteKeyword = keywordUuid => {
    if (variationsCount > 0) {
      setShowDeleteKeywordModal(true);
    } else {
      onDeleteKeyword(keywordUuid);
    }
  };

  const keywordTooltip = () => {
    if (variationsCount === 0) {
      return 'Click to add variations';
    }
    if (variationsCount === 1) {
      return 'Click to view & edit 1 variation';
    }
    return `Click to view & edit ${variationsCount} variations`;
  };

  const renderMoreDetailsPopover = () => (
    <OutsideClickHandler onOutsideClick={() => setShowMoreDetails(false)}>
      <AvomaPopover
        width={100}
        style={styles.moreDetailsPopover}
        wrapperStyle={{ top: Tokens.spacing.five, right: 0 }}
      >
        <ButtonUnstyled
          type='button'
          style={{ ...styles.menuButton, color: Tokens.colors.gunmetal }}
          onClick={handleClickPopover}
        >
          <EditIcon style={styles.deleteIcon} />
          {L10n.general.edit}
        </ButtonUnstyled>
        {!isCRMKeyword && (
          <ButtonUnstyled
            type='button'
            style={{ ...styles.menuButton, color: Tokens.colors.avomaRed }}
            onClick={() => handleDeleteKeyword(keyword.uuid)}
          >
            <DeleteIcon style={styles.deleteIcon} />
            {L10n.general.delete}
          </ButtonUnstyled>
        )}
      </AvomaPopover>
    </OutsideClickHandler>
  );

  const deleteTooltip =
    totalCount > 1
      ? L10n.customCategory.deleteKeywordWithVariations
      : L10n.customCategory.deleteKeyword;

  return (
    <OutsideClickHandler onOutsideClick={handleClosePopover}>
      <div
        className={twMerge(
          'group relative float-left flex items-center rounded border border-blue-light text-blue hover:border-blue-dark hover:text-blue-dark',
          disabled && 'cursor-not-allowed opacity-50'
        )}
        key={keyword.uuid}
        ref={keywordRef}
      >
        <div className='flex rounded-l border-r border-blue-light bg-blue-white p-2 group-hover:border-blue-dark group-hover:bg-blue-whiter'>
          {isCRMKeyword && (
            <CrmIcon
              crm={crmProvider}
              style={{ marginRight: Tokens.spacing.one }}
            />
          )}
          <ButtonUnstyled
            style={styles.word}
            onClick={handleClickPopover}
            disabled={disabled}
            tooltip={
              !(showMoreDetails || showPopover) &&
              isAdminOrTopicOwner &&
              keywordTooltip()
            }
            tooltipStyle={styles.tooltip}
            tooltipPosition='bottom'
          >
            {keyword.label}
          </ButtonUnstyled>

          {variationsCount > 0 && (
            <AvomaCount count={totalCount} style={styles.variationsCount} />
          )}
        </div>

        <div className='flex py-2 pr-2'>
          <ButtonUnstyled
            className='relative ml-1.5 flex w-full cursor-pointer items-center justify-center rounded bg-transparent p-0 hover:bg-f1 disabled:cursor-not-allowed disabled:bg-transparent disabled:opacity-50'
            onClick={handleClickPopover}
            tooltip={
              !(showMoreDetails || showPopover) && addMoreVariationsTooltip
            }
            tooltipStyle={styles.tooltip}
            tooltipPosition='bottom'
            disabled={showPopover || disabled}
            noDefaultStyles
          >
            <PlusIcon
              style={{
                ...(showPopover
                  ? { ...styles.addIcon }
                  : { ...styles.iconHover })
              }}
            />
          </ButtonUnstyled>

          {!isCRMKeyword && (
            <ButtonUnstyled
              style={styles.moreButton}
              onClick={() => setShowMoreDetails(true)}
              tooltip={
                !(showMoreDetails || showPopover) &&
                L10n.moreDetailsMenu.moreDetails
              }
              tooltipStyle={styles.tooltip}
              tooltipPosition='bottom'
              key={`more-button-${keyword.uuid}`}
              disabled={showPopover || disabled}
            >
              <MoreIcon
                style={{
                  ...styles.moreIcon,
                  ...(!showPopover && { ...styles.iconHover })
                }}
              />
            </ButtonUnstyled>
          )}
        </div>

        {showMoreDetails && renderMoreDetailsPopover()}

        <VariationPopover
          showPopover={showPopover}
          handleClosePopover={handleClosePopover}
          variationCount={totalCount}
          variations={keyword.variations}
          word={keyword}
          onClickDelete={handleDeleteKeyword}
          onClickDeleteVariation={onDeleteKeywordVariation}
          onCreateVariation={onCreateKeywordVariation}
          variationExistsError={variationExistsError || hasDuplicateKeyword}
          onUpdateLabel={onUpdateLabel}
          onClickMakePrimary={onClickMakePrimary}
          popoverPositionStyles={calculatePopoverPosition()}
          variationPopoverTitle={variationPopoverTitle}
          deleteTooltip={deleteTooltip}
          isKeyword
          variationExistsMessage={variationExistsMessage}
          isMakePrimaryActionAllowed={!isCRMKeyword && !isUnifiedFlow}
          isUnifiedFlow={isUnifiedFlow}
          hasDuplicateKeyword={hasDuplicateKeyword}
          hasDuplicateVariation={hasDuplicateVariation}
        />
      </div>
      <ConfirmModal
        title={`Delete keyword "${keyword.label}" and its variations?`}
        text={`Do you want to delete keyword "${keyword.label}"? This will also delete all the keyword variations associated with this keyword.`}
        isOpen={showDeleteKeywordModal}
        confirmText={L10n.general.delete}
        onCancel={() => setShowDeleteKeywordModal(false)}
        onConfirm={handleDeleteConfirm}
      />
    </OutsideClickHandler>
  );
};

Keyword.propTypes = {
  onDeleteKeyword: PropTypes.func.isRequired,
  onDeleteKeywordVariation: PropTypes.func.isRequired,
  variations: PropTypes.array,
  keyword: PropTypes.object,
  onCreateKeywordVariation: PropTypes.func,
  variationExistsError: PropTypes.bool,
  onUpdateLabel: PropTypes.func,
  onClickMakePrimary: PropTypes.func,
  containerWidth: PropTypes.number,
  variationPopoverTitle: PropTypes.string,
  addMoreVariationsTooltip: PropTypes.string,
  clearVariationErrorMessage: PropTypes.func,
  variationExistsMessage: PropTypes.string,
  disabled: PropTypes.bool,
  isCRMKeyword: PropTypes.bool,
  isUnifiedFlow: PropTypes.bool,
  hasDuplicateKeyword: PropTypes.bool,
  hasDuplicateVariation: PropTypes.bool,
  isAdminOrTopicOwner: PropTypes.bool
};

const styles = {
  container: {
    background: Tokens.colors.white,
    borderRadius: Tokens.spacing.borderRadius,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Tokens.colors.blue,
    color: Tokens.colors.blue,
    padding: `${Tokens.spacing.one} ${Tokens.spacing.one}`,
    marginRight: Tokens.spacing.two,
    float: 'left',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    ':hover': {
      background: Tokens.colors.snow,
      color: Tokens.colors.blueDark,
      borderColor: Tokens.colors.blueDark
    }
  },
  word: {
    ...Tokens.type.bodyS,
    color: 'inherit',
    lineHeight: '22px',
    fontWeight: 'bold'
  },
  iconButton: {
    padding: 0,
    marginLeft: '6px',
    background: 'none',
    display: 'flex',
    borderRadius: Tokens.spacing.borderRadius,
    ':hover': {
      background: Tokens.colors.f1
    },
    ':disabled': {
      cursor: 'not-allowed',
      opacity: 0.5
    }
  },
  menuButton: {
    padding: Tokens.spacing.one,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: Tokens.colors.gunmetal,
    background: 'none',
    ...Tokens.type.bodyS,
    fontWeight: 700,
    ':hover': {
      background: Tokens.colors.f1
    }
  },
  moreDetailsPopover: {
    padding: 0
  },
  variationsCount: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: Tokens.spacing.one,
    padding: `0 ${Tokens.spacing.half}`,
    minWidth: Tokens.spacing.two
  },
  moreButton: {
    marginLeft: '6px',
    display: 'flex',
    borderRadius: Tokens.spacing.borderRadius,
    ':hover': {
      backgroundColor: Tokens.colors.lightGray
    },
    ':disabled': {
      cursor: 'not-allowed',
      opacity: 0.5
    }
  },
  moreIcon: {
    filter: Tokens.filters.silver,
    padding: Tokens.spacing.half,
    ':hover': {
      filter: Tokens.filters.silverDark
    }
  },
  addIcon: {
    ':hover': {
      filter: Tokens.filters.silver
    }
  },
  iconHover: {
    ':hover': {
      filter: Tokens.filters.silverDark
    }
  },
  deleteIcon: {
    marginRight: Tokens.spacing.one,
    padding: Tokens.spacing.half,
    height: '14px',
    width: '14px'
  },
  tooltip: {
    bottom: `-${Tokens.spacing.five}`
  }
};

export default Radium(Keyword);
