export default url => {
  if (!url) throw Error('[avoma] no url provided to getLastUrlParam');

  const splitUrl = url.split('/');

  if (splitUrl[splitUrl.length - 1] === '') {
    // url has a trailing slash
    return splitUrl[splitUrl.length - 2];
  }

  // url does not have a trailing slash
  return splitUrl[splitUrl.length - 1];
};
