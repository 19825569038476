import { SetStateAction, useEffect, useState } from 'react';
import { AiFillCaretDown as CaretIcon } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { EditorBlock } from 'types/notes';

import { sendRequestAdminEmail } from 'actions/licenses';

import ModalNoteEditor from 'components/Notes/EmailAgenda/ModalNoteEditor';
import { requestLicenseIntro } from 'components/Notes/EmailAgenda/helpers/emailIntroductions';
import AdminMembersPopover from 'components/RequestAdmin/AdminMembersPopover';
import { successNotification } from 'components/ToastNotifications';

import L10n from 'helpers/L10n';

import { ADMIN_REQUEST_TYPE } from 'constants/requestAdmin';

import Tokens from 'styles/tokens';

import AvomaButton from '../AvomaButton';
import TextField from '../TextField';

type EmailStepProps = {
  requestedFeature: string;
  handleCancel: () => void;
};

const EmailStep = ({ requestedFeature, handleCancel }: EmailStepProps) => {
  const dispatch = useDispatch();

  const members = useSelector((state: RootState) => state.members.data);
  const { userLicense, firstName: currentUserName } = useSelector(
    (state: RootState) => state.user
  );

  const [editorValue, setEditorValue] = useState<EditorBlock[]>([]);
  const [subjectLine, setSubjectLine] = useState<string>(
    L10n.requestAdmin.emailModalSubject
  );
  const [selectedAdmins, setSelectedAdmins] = useState<string[]>([]);
  const [adminMembers, setAdminMembers] = useState<
    Array<{
      personName: string;
      profilePic: string;
      email: string;
    }>
  >([]);
  const [adminPopoverOpen, setAdminPopoverOpen] = useState(false);
  const [agendaAvailable, setAgendaAvailable] = useState(false);

  const noteValue = requestLicenseIntro('All');
  const salutation = 'All';

  const initialNoteValue = requestLicenseIntro(
    salutation,
    requestedFeature,
    userLicense?.subscription?.planName
  );

  useEffect(() => {
    setAllAdminMembers();
    selectAllAdmins();
  }, [members]);

  const filterActiveAdmins = (members = []) => {
    return members.filter(
      (member: { role: { name: string }; user: { isActive: boolean } }) =>
        member?.role?.name === 'admin' && member?.user?.isActive
    );
  };

  const setAllAdminMembers = (): void => {
    const adminData = filterActiveAdmins(members)
      .map(
        (member: {
          user: {
            firstName: string;
            lastName: string;
            profilePic: string;
            email: string;
          };
        }) => ({
          personName: `${member?.user?.firstName || ''} ${
            member?.user?.lastName || ''
          }`,
          profilePic: member?.user?.profilePic,
          email: member?.user?.email
        })
      )
      .sort((a: { personName: string }, b: { personName: string }) =>
        a.personName.localeCompare(b.personName)
      );

    setAdminMembers(adminData);
  };

  const selectAllAdmins = (): void => {
    const adminEmails = filterActiveAdmins(members).map(
      (member: { user: { email: string } }) => member?.user?.email
    );

    setSelectedAdmins(adminEmails);
  };

  const hideAdminPopover = (): void => {
    setAdminPopoverOpen(false);
  };

  const onChangeSubjectValue = (event: {
    target: {
      value: string;
    };
  }): void => {
    setSubjectLine(event.target.value);
  };

  const onChangeNoteValue = (value: SetStateAction<EditorBlock[]>): void => {
    setEditorValue(value);
  };

  const onCloseModal = (): void => {
    handleCancel();
  };

  const handleAdminsChecked = (clickEvent: {
    target: { name: string; checked: boolean };
  }): void => {
    const email = clickEvent.target.name;
    const isChecked = clickEvent.target.checked;

    if (isChecked) {
      setSelectedAdmins([...selectedAdmins, email]);
    } else {
      const filteredItems = selectedAdmins.filter(
        participantEmail => participantEmail !== email
      );
      setSelectedAdmins(filteredItems);
    }
  };

  const onClickSendEmail = async (): Promise<void> => {
    type EmailPayload = {
      emailType: number;
      toEmails: string[];
      subject: string;
      body: EditorBlock[];
    };

    const emailType = ADMIN_REQUEST_TYPE.LICENSE;

    const payload: EmailPayload = {
      emailType,
      toEmails: selectedAdmins,
      subject: subjectLine,
      body: editorValue
    };

    await dispatch(sendRequestAdminEmail(payload));

    successNotification({ message: L10n.requestAdmin.mailSentSuccess });

    onCloseModal();
  };

  const handleSelectAllAdmins = (event: {
    target: { checked: boolean };
  }): void => {
    if (event.target.checked) {
      selectAllAdmins();
    } else {
      setSelectedAdmins([]);
    }
  };

  const handleAgendaAvailable = (condition: boolean): void => {
    setAgendaAvailable(condition);
  };

  const renderRecipientsDropdown = (): JSX.Element => (
    <div className='flex flex-col'>
      <div className='flex items-center relative'>
        <div
          className='border border-gainsboro rounded cursor-pointer hover:bg-snow'
          onClick={() => {
            setAdminPopoverOpen(true);
          }}
          aria-hidden='true'
        >
          <div className='flex items-center text-sm font-bold p-2'>
            All Admins ({selectedAdmins.length})
            <CaretIcon
              style={{
                marginLeft: Tokens.spacing.one,
                cursor: 'pointer',
                transform: adminPopoverOpen ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            />
          </div>
        </div>

        {adminPopoverOpen && (
          <AdminMembersPopover
            admins={adminMembers}
            handleAdminsChecked={handleAdminsChecked}
            selectedAdmins={selectedAdmins}
            hidePopover={hideAdminPopover}
            selectedAdminsNumber={selectedAdmins?.length}
            totalAdmins={adminMembers?.length}
            handleSelectAllAdmins={handleSelectAllAdmins}
          />
        )}
      </div>

      <div className='text-silver text-xs mt-1'>
        {selectedAdmins.length}/{adminMembers.length} selected
      </div>
    </div>
  );

  const renderSubjectField = (): JSX.Element => (
    <TextField
      value={subjectLine}
      onChange={onChangeSubjectValue}
      containerStyles={styles.textInput}
      userStyles={{
        ...styles.input,
        ...styles.textInput,
        padding: Tokens.spacing.one
      }}
    />
  );

  const renderFooter = (): JSX.Element => (
    <div className='flex w-full items-center justify-end gap-x-4'>
      <AvomaButton
        label={L10n.general.cancel}
        onClick={handleCancel}
        secondary
        small
      />
      <AvomaButton
        label={L10n.emailAgenda.sendEmailCta}
        onClick={onClickSendEmail}
        primary
        small
      />
    </div>
  );

  return (
    <div className='flex flex-col gap-y-4'>
      <section className='flex items-baseline gap-x-4'>
        <p className='font-bold text-md'>{L10n.emailAgenda.sendToText}</p>
        {renderRecipientsDropdown()}
      </section>
      <section className='flex items-baseline gap-x-4'>
        <p className='font-bold text-md'>{L10n.emailAgenda.subject}</p>
        {renderSubjectField()}
      </section>

      <div
        className='relative my-2 flex flex-col overflow-y-auto h-[300px] w-full box-border border border-gainsboro rounded'
        id='request-license-notes-container'
      >
        <ModalNoteEditor
          editorId='request-license-notes-container'
          initialValue={initialNoteValue}
          noteValue={noteValue}
          onChangeNoteValue={onChangeNoteValue}
          currentUserName={currentUserName}
          handleAgendaAvailable={handleAgendaAvailable}
          editorStyle={{
            padding: `${Tokens.spacing.three} ${Tokens.spacing.two}`
          }}
        />
      </div>

      {renderFooter()}
    </div>
  );
};

const styles = {
  textInput: {
    height: Tokens.spacing.five,
    width: '100%'
  },
  input: {
    width: '100%',
    fontWeight: 'normal'
  }
};

export default EmailStep;
