import _ from 'lodash';

import { savedViewsActions } from 'actions/actionTypes';

import { DASHBOARD_NAME } from 'constants/savedviews';

export const initialState = {
  meeting: {
    currentViewUuid: null, // UUID of the current pinnedview tab which is active
    selectedSavedView: null, // selected to delete/edit
    savedViewSearchText: '',
    dashboardName: DASHBOARD_NAME.meeting,
    pinnedViews: {
      data: [],
      error: null,
      fetching: false
    },
    standardViews: { data: [], error: null, fetching: false, count: 0 },
    myViews: { data: [], error: null, fetching: false, count: 0 },
    teammateViews: { data: [], error: null, fetching: false, count: 0 },
    savedViewHelpOpen: false,
    alertsModalOpen: false,
    error: null
  },
  calls: {
    currentViewUuid: null, // UUID of the current pinnedview tab which is active
    selectedSavedView: null, // selected to delete/edit
    savedViewSearchText: '',
    dashboardName: DASHBOARD_NAME.calls,
    pinnedViews: {
      data: [],
      error: null,
      fetching: false
    },
    standardViews: { data: [], error: null, fetching: false, count: 0 },
    myViews: { data: [], error: null, fetching: false, count: 0 },
    teammateViews: { data: [], error: null, fetching: false, count: 0 },
    savedViewHelpOpen: false,
    alertsModalOpen: false,
    error: null
  },
  deal: {
    currentViewUuid: null, // UUID of the current pinnedview tab which is active
    currentDashboardViewUuid: null, // UUID of the current dashboard view
    selectedSavedView: null, // selected to delete/edit
    savedViewSearchText: '',
    dashboardName: DASHBOARD_NAME.deal,
    pinnedViews: {
      data: [],
      error: null,
      fetching: false
    },
    standardViews: { data: [], error: null, fetching: false, count: 0 },
    myViews: { data: [], error: null, fetching: false, count: 0 },
    teammateViews: { data: [], error: null, fetching: false, count: 0 },
    savedViewHelpOpen: false,
    alertsModalOpen: false,
    error: null
  },
  trackers: {
    currentViewUuid: null, // UUID of the current pinnedview tab which is active
    currentDashboardViewUuid: null, // UUID of the current dashboard view
    selectedSavedView: null, // selected to delete/edit
    savedViewSearchText: '',
    dashboardName: DASHBOARD_NAME.trackers,
    pinnedViews: {
      data: [],
      error: null,
      fetching: false
    },
    standardViews: { data: [], error: null, fetching: false, count: 0 },
    myViews: { data: [], error: null, fetching: false, count: 0 },
    teammateViews: { data: [], error: null, fetching: false, count: 0 },
    savedViewHelpOpen: false,
    alertsModalOpen: false,
    error: null
  },
  company: {
    currentViewUuid: null, // UUID of the current pinnedview tab which is active
    currentDashboardViewUuid: null, // UUID of the current dashboard view
    selectedSavedView: null, // selected to delete/edit
    savedViewSearchText: '',
    dashboardName: DASHBOARD_NAME.company,
    pinnedViews: {
      data: [],
      error: null,
      fetching: false
    },
    standardViews: { data: [], error: null, fetching: false, count: 0 },
    myViews: { data: [], error: null, fetching: false, count: 0 },
    teammateViews: { data: [], error: null, fetching: false, count: 0 },
    savedViewHelpOpen: false,
    alertsModalOpen: false,
    error: null
  },
  scoredConversations: {
    currentViewUuid: null, // UUID of the current pinnedview tab which is active
    selectedSavedView: null, // selected to delete/edit
    savedViewSearchText: '',
    dashboardName: DASHBOARD_NAME.scoredConversations,
    pinnedViews: {
      data: [],
      error: null,
      fetching: false
    },
    standardViews: { data: [], error: null, fetching: false, count: 0 },
    myViews: { data: [], error: null, fetching: false, count: 0 },
    teammateViews: { data: [], error: null, fetching: false, count: 0 },
    savedViewHelpOpen: false,
    alertsModalOpen: false,
    error: null
  },
  scorecardsRequests: {
    currentViewUuid: null, // UUID of the current pinnedview tab which is active
    selectedSavedView: null, // selected to delete/edit
    savedViewSearchText: '',
    dashboardName: DASHBOARD_NAME.scorecardsRequests,
    pinnedViews: {
      data: [],
      error: null,
      fetching: false
    },
    standardViews: { data: [], error: null, fetching: false, count: 0 },
    myViews: { data: [], error: null, fetching: false, count: 0 },
    teammateViews: { data: [], error: null, fetching: false, count: 0 },
    savedViewHelpOpen: false,
    alertsModalOpen: false,
    error: null
  }
};

const savedViews = (
  state = initialState,
  { type, data, error, dashboardName, uuid, shouldResetTeammateViews }
) => {
  switch (type) {
    case savedViewsActions.CREATE_SAVED_VIEW_REQUEST:
      return state;
    case savedViewsActions.CREATE_SAVED_VIEW_SUCCESS: {
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          myViews: {
            ...state[dashboardName].myViews,
            data: [data, ...state[dashboardName].myViews.data]
          }
        }
      };
    }
    case savedViewsActions.CREATE_SAVED_VIEW_FAILURE:
      return state;
    case savedViewsActions.UPDATE_SAVED_VIEW_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case savedViewsActions.UPDATE_SAVED_VIEW_SUCCESS: {
      const initialPinnedViews = state[dashboardName].pinnedViews.data;
      const initialMyViews = state[dashboardName].myViews.data;
      const finalPinnedViews = [];
      const finalMyViews = [];
      initialPinnedViews.forEach(view => {
        if (view.dashboardView.uuid === data.uuid) {
          finalPinnedViews.push({ ...view, dashboardView: data });
        } else {
          finalPinnedViews.push(view);
        }
      });
      initialMyViews.forEach(view => {
        if (view.uuid === data.uuid) {
          finalMyViews.push(data);
        } else {
          finalMyViews.push(view);
        }
      });

      return {
        ...state,
        fetching: false,
        [dashboardName]: {
          ...state[dashboardName],
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            data: finalPinnedViews
          },
          myViews: {
            ...state[dashboardName].myViews,
            data: finalMyViews
          },
          selectedSavedView: null
        }
      };
    }
    case savedViewsActions.UPDATE_SAVED_VIEW_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case savedViewsActions.SUBSCRIBE_SAVED_VIEW_REQUEST:
      return {
        ...state
      };
    case savedViewsActions.SUBSCRIBE_SAVED_VIEW_SUCCESS: {
      const initialMyViews = state[dashboardName].myViews.data;
      const initialTeammatesViews = state[dashboardName].teammateViews.data;
      const initialStandardView = state[dashboardName].standardViews.data;
      const initialPinnedViews = state[dashboardName].pinnedViews.data;
      const finalMyViews = [];
      const finalTeammateViews = [];
      const finalStandardViews = [];

      initialMyViews.forEach(view => {
        if (view.uuid === data.uuid) {
          finalMyViews.push(data);
        } else {
          finalMyViews.push(view);
        }
      });

      initialTeammatesViews.forEach(view => {
        if (view.uuid === data.uuid) {
          finalTeammateViews.push(data);
        } else {
          finalTeammateViews.push(view);
        }
      });

      initialStandardView.forEach(view => {
        if (view.uuid === data.uuid) {
          finalStandardViews.push(data);
        } else {
          finalStandardViews.push(view);
        }
      });

      const updatedPinnedViews = initialPinnedViews.map(pinnedView => {
        if (pinnedView.dashboardView.uuid === data.uuid) {
          pinnedView.dashboardView = data;
        }
        return pinnedView;
      });

      return {
        ...state,
        fetching: false,
        [dashboardName]: {
          ...state[dashboardName],
          selectedSavedView: data,
          myViews: {
            ...state[dashboardName].myViews,
            data: finalMyViews
          },
          teammateViews: {
            ...state[dashboardName].teammateViews,
            data: finalTeammateViews
          },
          standardViews: {
            ...state[dashboardName].standardViews,
            data: finalStandardViews
          },
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            data: updatedPinnedViews
          }
        }
      };
    }
    case savedViewsActions.SUBSCRIBE_SAVED_VIEW_FAILURE:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          error
        }
      };

    case savedViewsActions.UNSUBSCRIBE_SAVED_VIEW_REQUEST:
      return {
        ...state
      };
    case savedViewsActions.UNSUBSCRIBE_SAVED_VIEW_SUCCESS: {
      const initialMyViews = state[dashboardName].myViews.data;
      const initialTeammatesViews = state[dashboardName].teammateViews.data;
      const initialStandardView = state[dashboardName].standardViews.data;
      const initialPinnedViews = state[dashboardName].pinnedViews.data;

      const finalMyViews = [];
      const finalTeammateViews = [];
      const finalStandardViews = [];

      initialMyViews.forEach(view => {
        if (view.uuid === data.uuid) {
          finalMyViews.push(data);
        } else {
          finalMyViews.push(view);
        }
      });

      initialTeammatesViews.forEach(view => {
        if (view.uuid === data.uuid) {
          finalTeammateViews.push(data);
        } else {
          finalTeammateViews.push(view);
        }
      });

      initialStandardView.forEach(view => {
        if (view.uuid === data.uuid) {
          finalStandardViews.push(data);
        } else {
          finalStandardViews.push(view);
        }
      });

      const updatedPinnedViews = initialPinnedViews.map(pinnedView => {
        if (pinnedView.dashboardView.uuid === data.uuid) {
          pinnedView.dashboardView = data;
        }
        return pinnedView;
      });

      return {
        ...state,
        fetching: false,
        [dashboardName]: {
          ...state[dashboardName],
          selectedSavedView: data,
          myViews: {
            ...state[dashboardName].myViews,
            data: finalMyViews
          },
          teammateViews: {
            ...state[dashboardName].teammateViews,
            data: finalTeammateViews
          },
          standardViews: {
            ...state[dashboardName].standardViews,
            data: finalStandardViews
          },
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            data: updatedPinnedViews
          }
        }
      };
    }
    case savedViewsActions.UNSUBSCRIBE_SAVED_VIEW_FAILURE:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          error
        }
      };

    case savedViewsActions.UPDATE_SAVED_VIEW_HELP_OPEN:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          savedViewHelpOpen: data
        }
      };

    case savedViewsActions.SET_ALERTS_MODAL_STATE:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          alertsModalOpen: data
        }
      };

    case savedViewsActions.DELETE_SAVED_VIEW_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case savedViewsActions.DELETE_SAVED_VIEW_SUCCESS: {
      const viewUuid = data;
      const { currentViewUuid, pinnedViews, myViews, teammateViews } =
        state[dashboardName];

      // determine new currentViewUUid
      const pinnedView = pinnedViews.data.find(
        view => view.dashboardView.uuid === viewUuid
      );
      const unpinnedTabIndex = pinnedViews.data.findIndex(
        view => view.dashboardView.uuid === viewUuid
      );
      let newCurrentViewUuid;
      if (pinnedView?.uuid !== currentViewUuid) {
        newCurrentViewUuid = currentViewUuid;
      } else {
        const newIndex = unpinnedTabIndex === 0 ? 1 : unpinnedTabIndex - 1;
        newCurrentViewUuid = pinnedViews.data[newIndex].uuid;
      }

      const newMyViews = myViews.data.filter(view => view.uuid !== viewUuid);
      const newTeammateViews = teammateViews.data.filter(
        view => view.uuid !== viewUuid
      );
      const newPinnedViews = pinnedViews.data.filter(
        view => view.dashboardView.uuid !== viewUuid
      );
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          currentViewUuid: newCurrentViewUuid,
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            data: newPinnedViews
          },
          myViews: {
            ...state[dashboardName].myViews,
            data: newMyViews,
            count: state[dashboardName].myViews.count - 1
          },
          teammateViews: {
            ...state[dashboardName].teammateViews,
            data: newTeammateViews
          },
          selectedSavedView: null
        }
      };
    }
    case savedViewsActions.DELETE_SAVED_VIEW_FAILURE:
      return {
        ...state,
        fetching: false
      };
    case savedViewsActions.FETCH_PINNED_SAVED_VIEWS_REQUEST:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            fetching: true
          }
        }
      };
    case savedViewsActions.FETCH_PINNED_SAVED_VIEWS_SUCCESS:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            data,
            fetching: false
          }
        }
      };
    case savedViewsActions.FETCH_PINNED_SAVED_VIEWS_FAILURE:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            error,
            fetching: false
          }
        }
      };
    case savedViewsActions.FETCH_STANDARD_SAVED_VIEWS_REQUEST:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          standardViews: {
            ...state[dashboardName].standardViews,
            fetching: true
          }
        }
      };
    case savedViewsActions.FETCH_STANDARD_SAVED_VIEWS_SUCCESS:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          standardViews: {
            ...state[dashboardName].standardViews,
            data,
            fetching: false
          }
        }
      };
    case savedViewsActions.FETCH_STANDARD_SAVED_VIEWS_FAILURE:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          standardViews: {
            ...state[dashboardName].standardViews,
            error,
            fetching: false
          }
        }
      };
    case savedViewsActions.CLEAR_SAVED_VIEWS_REQUEST:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          teammateViews: initialState[dashboardName].teammateViews,
          myViews: initialState[dashboardName].myViews,
          standardViews: initialState[dashboardName].standardViews
        }
      };
    case savedViewsActions.FETCH_MY_SAVED_VIEWS_REQUEST:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          myViews: {
            ...state[dashboardName].myViews,
            fetching: true
          }
        }
      };
    case savedViewsActions.FETCH_MY_SAVED_VIEWS_SUCCESS:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          myViews: {
            ...state[dashboardName].myViews,
            fetching: false,
            data: _(state[dashboardName].myViews.data)
              .concat(data.results)
              .groupBy('uuid')
              .map(_.spread(_.merge))
              .value(),
            next: data.next,
            count: data.count
          }
        }
      };
    case savedViewsActions.FETCH_MY_SAVED_VIEWS_FAILURE:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          myViews: {
            ...state[dashboardName].myViews,
            error,
            fetching: false
          }
        }
      };
    case savedViewsActions.FETCH_TEAMMATE_SAVED_VIEWS_REQUEST:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          teammateViews: {
            ...state[dashboardName].teammateViews,
            fetching: true
          }
        }
      };
    case savedViewsActions.FETCH_TEAMMATE_SAVED_VIEWS_SUCCESS: {
      const newData = shouldResetTeammateViews
        ? data.results.groupBy('uuid').map(_.spread(_.merge)).value()
        : [
            ..._(state[dashboardName].teammateViews.data)
              .concat(data.results)
              .groupBy('uuid')
              .map(_.spread(_.merge))
              .value()
          ];
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          teammateViews: {
            ...state[dashboardName].teammateViews,
            fetching: false,
            data: newData,
            next:
              (state[dashboardName].teammateViews.count !== newData.length &&
                data.next) ||
              null,
            count: data.count
          }
        }
      };
    }
    case savedViewsActions.FETCH_TEAMMATE_SAVED_VIEWS_FAILURE:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          teammateViews: {
            ...state[dashboardName].teammateViews,
            error,
            fetching: false
          }
        }
      };
    case savedViewsActions.PIN_VIEW_REQUEST: {
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            fetching: true
          }
        }
      };
    }
    case savedViewsActions.PIN_VIEW_SUCCESS: {
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          currentViewUuid: data[0].uuid,
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            fetching: false,
            data: [...state[dashboardName].pinnedViews.data, ...data]
          }
        }
      };
    }
    case savedViewsActions.PIN_VIEW_FAILURE: {
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            fetching: false,
            error
          }
        }
      };
    }
    case savedViewsActions.UNPIN_VIEW_REQUEST: {
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            fetching: true
          }
        }
      };
    }
    case savedViewsActions.UNPIN_VIEW_SUCCESS: {
      const { savedViewUuid } = data;
      const { currentViewUuid } = state[dashboardName];
      const { data: pinnedViews } = state[dashboardName].pinnedViews;

      // determine new currentViewUUid
      const removedTabIndex = pinnedViews.findIndex(
        view => view.uuid === savedViewUuid
      );
      let newCurrentViewUuid;
      if (currentViewUuid !== savedViewUuid) {
        newCurrentViewUuid = currentViewUuid;
      } else {
        const newIndex = removedTabIndex === 0 ? 1 : removedTabIndex - 1;
        newCurrentViewUuid = pinnedViews[newIndex].uuid;
      }

      const remainingPinnedViews = pinnedViews.filter(
        view => view.uuid !== savedViewUuid
      );
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          currentViewUuid: newCurrentViewUuid,
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            data: remainingPinnedViews
          }
        }
      };
    }
    case savedViewsActions.UNPIN_VIEW_FAILURE: {
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            fetching: false,
            error
          }
        }
      };
    }
    case savedViewsActions.REORDER_PINNED_VIEWS_REQUEST: {
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            fetching: true
          }
        }
      };
    }
    case savedViewsActions.REORDER_PINNED_VIEWS_SUCCESS: {
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            data,
            fetching: false
          }
        }
      };
    }
    case savedViewsActions.REORDER_PINNED_VIEWS_FAILURE: {
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            fetching: false,
            error
          }
        }
      };
    }
    case savedViewsActions.UPDATE_CURRENT_VIEW_UUID:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          currentViewUuid: data.currentViewUuid
        }
      };
    case savedViewsActions.UPDATE_CURRENT_DASHBOARD_VIEW_UUID:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          currentDashboardViewUuid: data.currentDashboardViewUuid
        }
      };
    case savedViewsActions.SET_SELECTED_SAVED_VIEW:
      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          selectedSavedView: data.selectedSavedView
        }
      };

    case savedViewsActions.CREATE_SLACK_ALERT_VIEW: {
      const pinnedViews = state.meeting.pinnedViews.data;
      const updatedPinnedViews = pinnedViews.map(pinnedView => {
        if (pinnedView.dashboardView.uuid === data.dashboardViewUuid) {
          pinnedView.dashboardView.slackAlert = data.slackAlert;
        }
        return pinnedView;
      });

      return {
        ...state,
        meeting: {
          ...state.meeting,
          pinnedViews: {
            ...state.meeting.pinnedViews,
            data: updatedPinnedViews
          }
        }
      };
    }
    case savedViewsActions.REMOVE_SLACK_ALERT_VIEW: {
      const pinnedViews = state.meeting.pinnedViews.data;
      const updatedPinnedViews = pinnedViews.map(pinnedView => {
        if (pinnedView.dashboardView.uuid === data.dashboardViewUuid) {
          pinnedView.dashboardView.slackAlert = null;
        }
        return pinnedView;
      });

      return {
        ...state,
        meeting: {
          ...state.meeting,
          pinnedViews: {
            ...state.meeting.pinnedViews,
            data: updatedPinnedViews
          }
        }
      };
    }

    case savedViewsActions.UPDATE_SAVED_VIEW_SUB_VIEWS_REQUEST: {
      const pinnedViews = state[dashboardName].pinnedViews.data;

      const updatedPinnedViews = pinnedViews.map(pinnedView => {
        if (pinnedView.uuid === uuid) {
          return {
            ...pinnedView,
            dashboardView: {
              ...pinnedView.dashboardView,
              subViews: data
            }
          };
        }
        return pinnedView;
      });

      return {
        ...state,
        [dashboardName]: {
          ...state[dashboardName],
          pinnedViews: {
            ...state[dashboardName].pinnedViews,
            data: updatedPinnedViews
          }
        }
      };
    }
    default:
      return state;
  }
};

export default savedViews;
