import isEmpty from 'lodash/isEmpty';

import { PlaylistActions } from '../actions/actionTypes';

// import env from 'helpers/env';

const initialState = {
  createPublicShares: {},
  addEmailToPublicShares: {},
  addTeamToPublicShares: {},
  removeTeamFromPublicShares: {},
  removeEmailToPublicShares: {},
  updatePlaylistOrder: {},
  fetchPlaylistRecording: {},
  deletePlaylistContent: {},
  createPlaylistContent: {},
  fetchPlaylistContent: {},
  updatingPlaylist: {},
  deletePlaylist: {},
  createPlaylist: {},
  fetchPlaylist: {},
  subscribe: {},
  unsubscribe: {},
  data: {},
  playlistContent: null,
  changePermissionInfo: null,
  addToPlaylistInfo: null,
  updatePlaylistShare: {},
  playlistSendOtp: {},
  shareData: {},
  searchPlaylistsText: ''
};

const fetchingPlaylist = (state, action) => {
  switch (action.type) {
    case PlaylistActions.FETCH_PLAYLIST_REQUEST:
      return {
        ...state,
        fetchPlaylist: {
          loading: true,
          error: false,
          errorDetail: ''
        }
      };
    case PlaylistActions.FETCH_PLAYLIST_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.playlist.uuid]: action.playlist
        },
        fetchPlaylist: {
          loading: false,
          error: false,
          errorDetail: ''
        }
      };
    }
    case PlaylistActions.FETCH_PLAYLIST_FAILURE:
      return {
        ...state,
        fetchPlaylist: {
          loading: false,
          error: true,
          errorDetail: action.errorDetail?.detail
        }
      };
    default:
      return state;
  }
};

const creatingPlaylist = (state, action) => {
  switch (action.type) {
    case PlaylistActions.CREATE_PLAYLIST_REQUEST:
      return {
        ...state,
        createPlaylist: {
          loading: true
        }
      };
    case PlaylistActions.CREATE_PLAYLIST_SUCCESS: {
      const { view } = state;
      if (view === 'subscribedPlaylists') {
        return state;
      }

      let count = 1;
      if (state[view].data.count) {
        count = state[view].data.count + 1;
      }

      return {
        ...state,
        [view]: {
          ...state[view],
          data: {
            ...state[view].data,
            count,
            results: [action.newPlaylist.uuid, ...state[view].data.results]
          }
        },
        data: {
          ...state.data,
          [action.newPlaylist.uuid]: action.newPlaylist
        },
        createPlaylist: {
          loading: false
        }
      };
    }
    case PlaylistActions.CREATE_PLAYLIST_FAILURE:
      return {
        ...state,
        createPlaylist: {
          loading: false,
          error: true
        }
      };
    default:
      return state;
  }
};

const deletingPlaylist = (state, action) => {
  switch (action.type) {
    case PlaylistActions.DELETE_PLAYLIST_REQUEST:
      return {
        ...state,
        deletePlaylist: {
          loading: true
        }
      };
    case PlaylistActions.DELETE_PLAYLIST_SUCCESS: {
      const { view } = state;
      const results = state[view].data.results.filter(
        playlistUuid => playlistUuid !== action.playlistUuid
      );
      if (state.allPlaylists) {
        const allPlaylistsData = state.allPlaylists.data.results.filter(
          playlistUuid => playlistUuid !== action.playlistUuid
        );

        return {
          ...state,
          [view]: {
            ...state[view],
            data: {
              ...state[view].data,
              count: state[view].data.count - 1,
              results
            }
          },
          allPlaylists: {
            ...state.allPlaylists,
            data: {
              ...state.allPlaylists.data,
              count: state.allPlaylists.data.count - 1,
              results: allPlaylistsData
            }
          },
          deletePlaylist: {
            loading: false
          }
        };
      }
      return {
        ...state,
        [view]: {
          ...state[view],
          data: {
            ...state[view].data,
            count: state[view].data.count - 1,
            results
          }
        },
        deletePlaylist: {
          loading: false
        }
      };
    }
    case PlaylistActions.DELETE_PLAYLIST_FAILURE:
      return {
        ...state,
        deletePlaylist: {
          loading: false,
          error: true
        }
      };
    default:
      return state;
  }
};

const updatingPlaylist = (state, action) => {
  switch (action.type) {
    case PlaylistActions.UPDATE_PLAYLIST_REQUEST:
      return {
        ...state,
        updatingPlaylist: {
          loading: true
        }
      };
    case PlaylistActions.UPDATE_PLAYLIST_SUCCESS: {
      const { playlistUuid, updatedPlaylist } = action;
      return {
        ...state,
        data: {
          ...state.data,
          [playlistUuid]: updatedPlaylist
        },
        updatingPlaylist: {
          loading: false
        }
      };
    }
    case PlaylistActions.UPDATE_PLAYLIST_FAILURE:
      return {
        ...state,
        updatingPlaylist: {
          loading: false,
          error: true
        }
      };
    default:
      return state;
  }
};

const updatingPlaylistPrivacy = (state, action) => {
  switch (action.type) {
    case PlaylistActions.UPDATE_PLAYLIST_PRIVACY_REQUEST:
      return {
        ...state,
        updatingPlaylistPrivacy: {
          loading: true
        }
      };
    case PlaylistActions.UPDATE_PLAYLIST_PRIVACY_SUCCESS: {
      const { playlistUuid } = action;
      return {
        ...state,
        data: {
          ...state.data,
          [playlistUuid]: {
            ...state.data[playlistUuid],
            privacy: action.privacy,
            privacySettings: { ...action.privacySettings }
          }
        },
        updatingPlaylistPrivacy: {
          loading: false
        }
      };
    }
    case PlaylistActions.UPDATE_PLAYLIST_PRIVACY_ERROR:
      return {
        ...state,
        updatingPlaylistPrivacy: {
          loading: false,
          error: true
        }
      };
    default:
      return state;
  }
};

const fetchingPlaylistContent = (state, action) => {
  switch (action.type) {
    case PlaylistActions.FETCH_PLAYLISTCONTENT_REQUEST:
    case PlaylistActions.FETCH_PUBLIC_PLAYLIST_CONTENT_REQUEST: {
      const hasPlaylistContent = Boolean(state.playlistContent);

      if (hasPlaylistContent && !action.query) {
        return {
          ...state,
          playlistContent: {
            ...state.playlistContent,
            [action.playlistUuid]: {
              ...state.playlistContent[action.playlistUuid],
              loadingMore: true
            }
          }
        };
      }

      return {
        ...state,
        fetchPlaylistContent: {
          loading: true,
          error: false
        },
        playlistContent: {}
      };
    }
    case PlaylistActions.FETCH_PLAYLISTCONTENT_SUCCESS:
    case PlaylistActions.FETCH_PUBLIC_PLAYLIST_CONTENT_SUCCESS: {
      const hasPlaylistContent = Boolean(
        state.playlistContent?.[action.playlistUuid]
      );

      if (hasPlaylistContent && !action.query) {
        return {
          ...state,
          playlistContent: {
            ...state.playlistContent,
            [action.playlistUuid]: {
              ...state.playlistContent[action.playlistUuid],
              loadingMore: false,
              ...action.response,
              results: {
                ...state.playlistContent[action.playlistUuid].results,
                ...action.response.results
              }
            }
          },
          fetchPlaylistContent: {
            loading: false,
            error: false
          }
        };
      }

      return {
        ...state,
        playlistContent: {
          ...state.playlistContent,
          [action.playlistUuid]: action.response
        },
        fetchPlaylistContent: {
          loading: false,
          error: false
        }
      };
    }
    case PlaylistActions.FETCH_PLAYLISTCONTENT_FAILURE:
    case PlaylistActions.FETCH_PUBLIC_PLAYLIST_CONTENT_FAILURE:
      return {
        ...state,
        fetchPlaylistContent: {
          loading: false,
          error: true
        }
      };
    default:
      return state;
  }
};

const creatingPlaylistContent = (state, action) => {
  switch (action.type) {
    case PlaylistActions.CREATE_PLAYLISTCONTENT_REQUEST:
      return {
        ...state,
        createPlaylistContent: {
          loading: true
        }
      };
    case PlaylistActions.CREATE_PLAYLISTCONTENT_SUCCESS: {
      const { playlistUuid, response } = action;
      const playlistContentArr = response.map(content => ({
        order: content.order,
        uuid: content.uuid,
        highlightUuid: content.highlight ? content.highlight.uuid : null,
        meetingUuid: content.meeting.uuid
      }));

      return {
        ...state,
        data: {
          ...state.data,
          [playlistUuid]: {
            ...state.data[playlistUuid],
            playlistContent: [
              ...(state.data[playlistUuid]?.playlistContent || []),
              ...playlistContentArr
            ]
          }
        },
        createPlaylistContent: {
          loading: false
        }
      };
    }
    case PlaylistActions.CREATE_PLAYLISTCONTENT_FAILURE:
      return {
        ...state,
        createPlaylistContent: {
          loading: false,
          error: true
        }
      };

    default:
      return state;
  }
};

// TODO need to check with BE team if we can get the playlist content in
// TODO playlist API itself instead of calling another API
const deletingPlaylistContent = (state, action) => {
  switch (action.type) {
    case PlaylistActions.DELETE_PLAYLISTCONTENT_REQUEST:
      return {
        ...state,
        deletePlaylistContent: {
          loading: true
        }
      };
    case PlaylistActions.DELETE_PLAYLISTCONTENT_SUCCESS: {
      const { playlistUuid, playlistContentUuids, contentType } = action;

      if (state.playlistContent) {
        // Updating the playlistContent header counts
        const stateData = state.data[playlistUuid];
        const playlistContent =
          state.playlistContent[playlistUuid].results[playlistContentUuids];
        const headerCounts = {
          totalMeeting: stateData.totalMeeting,
          totalHighlight: stateData.totalHighlight,
          totalPlayTime: stateData.totalPlayTime
        };

        if (contentType === 'highlight') {
          headerCounts.totalHighlight = stateData.totalHighlight - 1;
          headerCounts.totalPlayTime =
            stateData.totalPlayTime -
            (playlistContent.highlight.endTime -
              playlistContent.highlight.startTime);
        } else if (contentType === 'meeting') {
          headerCounts.totalMeeting = stateData.totalMeeting - 1;
          headerCounts.totalPlayTime =
            stateData.totalPlayTime - playlistContent.meeting.duration;
        }

        const deletedPlaylistContentUuid = playlistContentUuids[0];
        const {
          [deletedPlaylistContentUuid]: value,
          ...remainingPlaylistContent
        } = state.playlistContent[playlistUuid].results;

        return {
          ...state,
          data: {
            ...state.data,
            [playlistUuid]: {
              ...state.data[playlistUuid],
              ...headerCounts
            }
          },
          playlistContent: {
            ...state.playlistContent,
            [playlistUuid]: {
              ...state.playlistContent[playlistUuid],
              count: state.playlistContent[playlistUuid].count - 1,
              results: remainingPlaylistContent
            }
          },
          deletePlaylistContent: {
            loading: false
          }
        };
      }

      return {
        ...state,
        deletePlaylistContent: {
          loading: false
        }
      };
    }
    case PlaylistActions.DELETE_PLAYLISTCONTENT_FAILURE:
      return {
        ...state,
        deletePlaylistContent: {
          loading: false,
          error: true
        }
      };
    default:
      return state;
  }
};

const updatingPlaylistContentOrder = (state, action) => {
  switch (action.type) {
    case PlaylistActions.UPDATE_PLAYLISTCONTENT_ORDER_REQUEST: {
      const { playlistUuid, newOrderPlaylistContent } = action;

      const data = {};
      newOrderPlaylistContent.forEach(content => {
        data[content.uuid] = content;
      });

      return {
        ...state,
        playlistContent: {
          [playlistUuid]: {
            ...state.playlistContent[playlistUuid],
            results: data
          }
        }
      };
    }
    case PlaylistActions.UPDATE_PLAYLISTCONTENT_ORDER_SUCCESS: {
      return {
        ...state
      };
    }
    case PlaylistActions.UPDATE_PLAYLISTCONTENT_ORDER_FAILURE:
      return {
        ...state,
        updatePlaylistOrder: {
          loading: false,
          error: true
        }
      };
    default:
      return state;
  }
};

const playlists = (state = initialState, action) => {
  switch (action.type) {
    case PlaylistActions.FETCH_PLAYLIST_REQUEST:
    case PlaylistActions.FETCH_PLAYLIST_SUCCESS:
    case PlaylistActions.FETCH_PLAYLIST_FAILURE:
      return fetchingPlaylist(state, action);
    case PlaylistActions.CREATE_PLAYLIST_REQUEST:
    case PlaylistActions.CREATE_PLAYLIST_SUCCESS:
    case PlaylistActions.CREATE_PLAYLIST_FAILURE:
      return creatingPlaylist(state, action);
    case PlaylistActions.DELETE_PLAYLIST_REQUEST:
    case PlaylistActions.DELETE_PLAYLIST_SUCCESS:
    case PlaylistActions.DELETE_PLAYLIST_FAILURE:
      return deletingPlaylist(state, action);
    case PlaylistActions.UPDATE_PLAYLIST_REQUEST:
    case PlaylistActions.UPDATE_PLAYLIST_SUCCESS:
    case PlaylistActions.UPDATE_PLAYLIST_FAILURE:
      return updatingPlaylist(state, action);
    case PlaylistActions.UPDATE_PLAYLIST_PRIVACY_REQUEST:
    case PlaylistActions.UPDATE_PLAYLIST_PRIVACY_SUCCESS:
    case PlaylistActions.UPDATE_PLAYLIST_PRIVACY_ERROR:
      return updatingPlaylistPrivacy(state, action);
    case PlaylistActions.FETCH_PLAYLISTCONTENT_REQUEST:
    case PlaylistActions.FETCH_PLAYLISTCONTENT_SUCCESS:
    case PlaylistActions.FETCH_PLAYLISTCONTENT_FAILURE:
    case PlaylistActions.FETCH_PUBLIC_PLAYLIST_CONTENT_REQUEST:
    case PlaylistActions.FETCH_PUBLIC_PLAYLIST_CONTENT_SUCCESS:
    case PlaylistActions.FETCH_PUBLIC_PLAYLIST_CONTENT_FAILURE:
      return fetchingPlaylistContent(state, action);
    case PlaylistActions.CREATE_PLAYLISTCONTENT_REQUEST:
    case PlaylistActions.CREATE_PLAYLISTCONTENT_SUCCESS:
    case PlaylistActions.CREATE_PLAYLISTCONTENT_FAILURE:
      return creatingPlaylistContent(state, action);
    case PlaylistActions.DELETE_PLAYLISTCONTENT_REQUEST:
    case PlaylistActions.DELETE_PLAYLISTCONTENT_SUCCESS:
    case PlaylistActions.DELETE_PLAYLISTCONTENT_FAILURE:
      return deletingPlaylistContent(state, action);
    case PlaylistActions.UPDATE_PLAYLISTCONTENT_ORDER_REQUEST:
    case PlaylistActions.UPDATE_PLAYLISTCONTENT_ORDER_SUCCESS:
    case PlaylistActions.UPDATE_PLAYLISTCONTENT_ORDER_FAILURE:
      return updatingPlaylistContentOrder(state, action);
    case PlaylistActions.FETCH_PLAYLIST_RECORDING_REQUEST:
      return {
        ...state,
        fetchPlaylistRecording: {
          loading: true,
          error: false,
          errorDetail: null
        }
      };
    case PlaylistActions.FETCH_PLAYLIST_RECORDING_SUCCESS: {
      const { recording, playlistUuid, playlistContentUuid } = action;

      if (!state.playlistContent) {
        state.playlistContent = {};
      }

      if (!state.playlistContent[playlistUuid]) {
        state.playlistContent[playlistUuid] = { results: {} };
      }

      const viewedPlaylistContent =
        state.playlistContent[playlistUuid].results[playlistContentUuid];
      viewedPlaylistContent.meeting.recordingData = { ...recording };

      return {
        ...state,
        fetchPlaylistRecording: {
          loading: false,
          error: false,
          errorDetail: null
        },
        playlistContent: {
          ...state.playlistContent,
          [playlistUuid]: {
            ...state.playlistContent[playlistUuid],
            results: {
              ...state.playlistContent[playlistUuid].results,
              [playlistContentUuid]: viewedPlaylistContent
            }
          }
        }
      };
    }

    case PlaylistActions.FETCH_PLAYLIST_RECORDING_FAILURE:
      return {
        ...state,
        fetchPlaylistRecording: {
          loading: false,
          error: true,
          errorDetail: action.error?.detail
        }
      };

    case PlaylistActions.SUBSCRIBE_PLAYLIST_REQUEST:
      return {
        ...state,
        subscribe: {
          loading: true
        }
      };
    case PlaylistActions.SUBSCRIBE_PLAYLIST_SUCCESS: {
      const { playlistUuid, subscriber } = action;
      return {
        ...state,
        subscribe: {
          loading: false
        },
        data: {
          ...state.data,
          [playlistUuid]: {
            ...state.data[playlistUuid],
            subscribers: [...state.data[playlistUuid].subscribers, subscriber]
          }
        }
      };
    }
    case PlaylistActions.SUBSCRIBE_PLAYLIST_ERROR:
      return {
        ...state,
        subscribe: {
          loading: false,
          error: true
        }
      };
    case PlaylistActions.UNSUBSCRIBE_PLAYLIST_REQUEST:
      return {
        ...state,
        unsubscribe: {
          loading: true
        }
      };
    case PlaylistActions.UNSUBSCRIBE_PLAYLIST_SUCCESS: {
      const { userEmail } = action;
      const { view } = state;

      if (view === 'subscribedPlaylists') {
        const results = state[view].data.results.filter(
          playlistUuid => playlistUuid !== action.playlistUuid
        );

        return {
          ...state,
          subscribe: {
            loading: false
          },
          [view]: {
            ...state[view],
            data: {
              ...state[view].data,
              count: state[view].data.count - 1,
              results
            }
          }
        };
      }

      const subscribers = state.data[action.playlistUuid].subscribers.filter(
        subscriber => subscriber.email !== userEmail
      );

      return {
        ...state,
        subscribe: {
          loading: false
        },
        data: {
          ...state.data,
          [action.playlistUuid]: {
            ...state.data[action.playlistUuid],
            subscribers
          }
        }
      };
    }
    case PlaylistActions.UNSUBSCRIBE_PLAYLIST_ERROR:
      return {
        ...state,
        unsubscribe: {
          loading: false,
          error: true
        }
      };

    case PlaylistActions.REMOVE_SUBSCRIBER_REQUEST:
      return {
        ...state,
        removeSubscribe: {
          loading: true
        }
      };

    case PlaylistActions.REMOVE_SUBSCRIBER_ERROR:
      return {
        ...state,
        removeSubscribe: {
          loading: false,
          error: true
        }
      };

    case PlaylistActions.FETCH_MY_PLAYLISTS_REQUEST: {
      const hasMyPlaylists = Boolean(state.myPlaylists);

      if (hasMyPlaylists && !action.query) {
        return {
          ...state,
          myPlaylists: {
            ...state.myPlaylists,
            loadingMore: true
          }
        };
      }

      return {
        ...state,
        myPlaylists: {
          loading: true
        }
      };
    }

    case PlaylistActions.FETCH_MY_PLAYLISTS_SUCCESS: {
      const hasMyPlaylists = Boolean(state.myPlaylists.data);

      if (hasMyPlaylists && !action.query) {
        return {
          ...state,
          myPlaylists: {
            ...state.myPlaylists,
            loadingMore: false,
            data: {
              ...state.myPlaylists.data,
              ...action.response,
              results: [
                ...state.myPlaylists.data.results,
                ...action.response.results
              ]
            }
          },
          view: 'myPlaylists',
          data: {
            ...state.data,
            ...action.data
          }
        };
      }

      return {
        ...state,
        myPlaylists: {
          loading: false,
          data: action.response
        },
        view: 'myPlaylists',
        data: {
          ...state.data,
          ...action.data
        }
      };
    }
    case PlaylistActions.FETCH_MY_PLAYLISTS_ERROR:
      return {
        ...state,
        myPlaylists: {
          loading: false,
          loadingMore: false,
          error: true
        }
      };

    case PlaylistActions.FETCH_ALL_PLAYLISTS_REQUEST: {
      const hasPlaylists = Boolean(state.allPlaylists);

      if (hasPlaylists && !action.query) {
        return {
          ...state,
          allPlaylists: {
            ...state.allPlaylists,
            loadingMore: true
          }
        };
      }
      return {
        ...state,
        allPlaylists: {
          loading: true
        }
      };
    }
    case PlaylistActions.FETCH_ALL_PLAYLISTS_SUCCESS: {
      const hasPlaylists = Boolean(state.allPlaylists.data);

      if (hasPlaylists && !action.query) {
        return {
          ...state,
          allPlaylists: {
            ...state.allPlaylists,
            loadingMore: false,
            data: {
              ...state.allPlaylists.data,
              ...action.response,
              results: [
                ...state.allPlaylists.data.results,
                ...action.response.results
              ]
            }
          },
          view: 'allPlaylists',
          data: {
            ...state.data,
            ...action.data
          }
        };
      }
      return {
        ...state,
        allPlaylists: {
          loading: false,
          data: action.response
        },
        view: 'allPlaylists',
        data: {
          ...state.data,
          ...action.data
        }
      };
    }
    case PlaylistActions.FETCH_ALL_PLAYLISTS_ERROR:
      return {
        ...state,
        allPlaylists: {
          loading: false,
          loadingMore: false,
          error: true
        }
      };

    case PlaylistActions.FETCH_SUBSCRIBED_PLAYLISTS_REQUEST: {
      const hasSubscribedPlaylists = Boolean(state.subscribedPlaylists);

      if (hasSubscribedPlaylists && !action.query) {
        return {
          ...state,
          subscribedPlaylists: {
            ...state.subscribedPlaylists,
            loadingMore: true
          }
        };
      }

      return {
        ...state,
        subscribedPlaylists: {
          loading: true
        }
      };
    }

    case PlaylistActions.FETCH_SUBSCRIBED_PLAYLISTS_SUCCESS: {
      const hasSubscribedPlaylists = Boolean(state.subscribedPlaylists.data);

      if (hasSubscribedPlaylists && !action.query) {
        return {
          ...state,
          subscribedPlaylists: {
            ...state.subscribedPlaylists,
            loadingMore: false,
            data: {
              ...state.subscribedPlaylists.data,
              ...action.response,
              results: [
                ...state.subscribedPlaylists.data.results,
                ...action.response.results
              ]
            }
          },
          view: 'subscribedPlaylists',
          data: {
            ...state.data,
            ...action.data
          }
        };
      }

      return {
        ...state,
        subscribedPlaylists: {
          loading: false,
          data: action.response
        },
        view: 'subscribedPlaylists',
        data: {
          ...state.data,
          ...action.data
        }
      };
    }
    case PlaylistActions.FETCH_SUBSCRIBED_PLAYLISTS_ERROR:
      return {
        ...state,
        subscribedPlaylists: {
          loading: false,
          loadingMore: false,
          error: true
        }
      };

    case PlaylistActions.FETCH_ORGANIZATION_PLAYLISTS_REQUEST: {
      const hasOrganizationPlaylists = Boolean(state.organizationPlaylists);

      if (hasOrganizationPlaylists && !action.query) {
        return {
          ...state,
          organizationPlaylists: {
            ...state.organizationPlaylists,
            loadingMore: true
          }
        };
      }

      return {
        ...state,
        organizationPlaylists: {
          loading: true
        }
      };
    }

    case PlaylistActions.FETCH_ORGANIZATION_PLAYLISTS_SUCCESS: {
      const hasOrganizationPlaylists = Boolean(
        state.organizationPlaylists.data
      );

      if (hasOrganizationPlaylists && !action.query) {
        return {
          ...state,
          organizationPlaylists: {
            ...state.organizationPlaylists,
            loadingMore: false,
            data: {
              ...state.organizationPlaylists.data,
              ...action.response,
              results: [
                ...state.organizationPlaylists.data.results,
                ...action.response.results
              ]
            }
          },
          view: 'organizationPlaylists',
          data: {
            ...state.data,
            ...action.data
          }
        };
      }

      return {
        ...state,
        organizationPlaylists: {
          loading: false,
          data: action.response
        },
        view: 'organizationPlaylists',
        data: {
          ...state.data,
          ...action.data
        }
      };
    }
    case PlaylistActions.FETCH_ORGANIZATION_PLAYLISTS_ERROR:
      return {
        ...state,
        organizationPlaylists: {
          loading: false,
          loadingMore: false,
          error: true
        }
      };
    case PlaylistActions.CREATE_PLAYLIST_SHARES_REQUEST:
      return {
        ...state,
        createPublicShares: {
          loading: true
        }
      };

    case PlaylistActions.CREATE_PLAYLIST_SHARES_SUCCESS: {
      const { playlistUuid, response } = action;
      return {
        ...state,
        data: {
          ...state.data,
          [playlistUuid]: {
            ...state.data[playlistUuid],
            share: response
          }
        }
      };
    }
    case PlaylistActions.CREATE_PLAYLIST_SHARES_ERROR:
      return {
        ...state,
        createPublicShares: {
          loading: false,
          error: true
        }
      };

    case PlaylistActions.ADD_EMAIL_PUBLIC_SHARE_PLAYLIST_REQUEST:
      return {
        ...state,
        addEmailToPublicShares: {
          loading: true
        }
      };

    case PlaylistActions.ADD_EMAIL_PUBLIC_SHARE_PLAYLIST_SUCCESS: {
      const { playlistUuid, share } = action;

      return {
        ...state,
        data: {
          ...state.data,
          [playlistUuid]: {
            ...state.data[playlistUuid],
            share,
            subscribers: share.subscribers
          }
        },
        addEmailToPublicShares: {
          loading: false
        }
      };
    }
    case PlaylistActions.ADD_EMAIL_PUBLIC_SHARE_PLAYLIST_ERROR:
      return {
        ...state,
        addEmailToPublicShares: {
          loading: false,
          error: true
        }
      };

    case PlaylistActions.REMOVE_PLAYLIST_SHARE_EMAIL_REQUEST:
      return {
        ...state,
        removeEmailToPublicShares: {
          loading: true
        }
      };

    case PlaylistActions.REMOVE_PLAYLIST_SHARE_EMAIL_SUCCESS: {
      const { playlistUuid, share } = action;

      return {
        ...state,
        data: {
          ...state.data,
          [playlistUuid]: {
            ...state.data[playlistUuid],
            share,
            subscribers: share.subscribers
          }
        },
        removeEmailToPublicShares: {
          loading: false
        }
      };
    }
    case PlaylistActions.REMOVE_PLAYLIST_SHARE_EMAIL_ERROR:
      return {
        ...state,
        removeEmailToPublicShares: {
          loading: false,
          error: true
        }
      };

    case PlaylistActions.ADD_TEAM_PUBLIC_SHARE_PLAYLIST_REQUEST:
      return {
        ...state,
        addTeamToPublicShares: {
          loading: true
        }
      };

    case PlaylistActions.ADD_TEAM_PUBLIC_SHARE_PLAYLIST_SUCCESS: {
      const { playlistUuid, share } = action;

      return {
        ...state,
        data: {
          ...state.data,
          [playlistUuid]: {
            ...state.data[playlistUuid],
            share,
            subscribersTeam: share.subscribersTeam
          }
        },
        addTeamToPublicShares: {
          loading: false
        }
      };
    }
    case PlaylistActions.ADD_TEAM_PUBLIC_SHARE_PLAYLIST_FAILURE:
      return {
        ...state,
        addTeamToPublicShares: {
          loading: false,
          error: true
        }
      };

    case PlaylistActions.REMOVE_PLAYLIST_SHARE_TEAM_REQUEST:
      return {
        ...state,
        removeTeamFromPublicShares: {
          loading: true
        }
      };

    case PlaylistActions.REMOVE_PLAYLIST_SHARE_TEAM_SUCCESS: {
      const { playlistUuid, share } = action;

      return {
        ...state,
        data: {
          ...state.data,
          [playlistUuid]: {
            ...state.data[playlistUuid],
            share,
            subscribersTeam: share.subscribersTeam
          }
        },
        removeTeamFromPublicShares: {
          loading: false
        }
      };
    }
    case PlaylistActions.REMOVE_PLAYLIST_SHARE_TEAM_ERROR:
      return {
        ...state,
        removeTeamFromPublicShares: {
          loading: false,
          error: true
        }
      };

    case PlaylistActions.RESET_PLAYLIST_CONTENT:
      return {
        ...state,
        playlistContent: {},
        fetchPlaylist: {}
      };

    case PlaylistActions.SET_CHANGE_PERMISSION_INFORMATION: {
      return {
        ...state,
        changePermissionInfo: action.response,
        createPlaylistContent: {
          loading: false
        }
      };
    }

    case PlaylistActions.SET_ADD_TO_PLAYLIST_INFORMATION: {
      return {
        ...state,
        addToPlaylistInfo: action.response
      };
    }

    case PlaylistActions.UPDATE_PLAYLIST_CONTENTS_PRIVACY: {
      const { playlistUuid, privacy } = action;
      if (!isEmpty(state.playlistContent)) {
        const playlistContent = (
          Object.values(state.playlistContent[playlistUuid].results) || []
        ).map(playlistContentData => {
          // means snippet
          if (playlistContentData.highlight) {
            const shouldUpdate = shouldUpdatePrivacy(
              playlistContentData.highlight.privacy,
              privacy
            );

            if (shouldUpdate) {
              return {
                ...playlistContentData,
                highlight: {
                  ...playlistContentData.highlight,
                  privacy
                }
              };
            }
            return playlistContentData;
          }

          // means meeting
          const shouldUpdateMeetingPrivacy = shouldUpdatePrivacy(
            playlistContentData.meeting.privacy,
            privacy
          );
          if (shouldUpdateMeetingPrivacy) {
            return {
              ...playlistContentData,
              meeting: {
                ...playlistContentData.meeting,
                privacy
              }
            };
          }
          return playlistContentData;
        });

        if (playlistContent.length > 0) {
          const data = {};
          playlistContent.forEach(content => {
            data[content.uuid] = content;
          });
          return {
            ...state,
            playlistContent: {
              ...state.playlistContent,
              [playlistUuid]: {
                ...state.playlistContent[playlistUuid],
                results: data
              }
            }
          };
        }
      }

      return state;
    }

    case PlaylistActions.UPDATE_PLAYLIST_CONTENT_PRIVACY: {
      const { playlistUuid, contentUuid, privacy } = action;

      let toBeUpdatedPlaylistContent =
        state.playlistContent[playlistUuid].results[contentUuid];

      if (toBeUpdatedPlaylistContent) {
        if (toBeUpdatedPlaylistContent.highlight) {
          toBeUpdatedPlaylistContent = {
            ...toBeUpdatedPlaylistContent,
            highlight: {
              ...toBeUpdatedPlaylistContent.highlight,
              privacy
            }
          };
        } else {
          toBeUpdatedPlaylistContent = {
            ...toBeUpdatedPlaylistContent,
            meeting: {
              ...toBeUpdatedPlaylistContent.meeting,
              privacy
            }
          };
        }
      }

      return {
        ...state,
        playlistContent: {
          ...state.playlistContent,
          [playlistUuid]: {
            ...state.playlistContent[playlistUuid],
            results: {
              ...state.playlistContent[playlistUuid].results,
              [contentUuid]: toBeUpdatedPlaylistContent
            }
          }
        }
      };
    }

    case PlaylistActions.UPDATE_PLAYLIST_SHARE_REQUEST: {
      return {
        ...state,
        updatePlaylistShare: {
          loading: true
        }
      };
    }

    case PlaylistActions.UPDATE_PLAYLIST_SHARE_SUCCESS: {
      const { playlistUuid, response } = action;
      return {
        ...state,
        updatePlaylistShare: {
          loading: false
        },
        data: {
          ...state.data,
          [playlistUuid]: {
            ...state.data[playlistUuid],
            share: response
          }
        }
      };
    }

    case PlaylistActions.UPDATE_PLAYLIST_SHARE_ERROR: {
      return {
        ...state,
        updatePlaylistShare: {
          loading: false,
          error: true
        }
      };
    }

    case PlaylistActions.FETCH_PLAYLIST_SHARE_DATA_REQUEST: {
      return {
        ...state,
        shareData: {
          loading: true
        }
      };
    }

    case PlaylistActions.FETCH_PLAYLIST_SHARE_DATA_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.playlistUuid]: action.response
        },
        playlistContent: {
          ...state.playlistContent,
          [action.playlistUuid]: {
            results: action.data
          }
        },
        shareData: {
          loading: false
        }
      };
    }

    case PlaylistActions.FETCH_PLAYLIST_SHARE_DATA_ERROR: {
      return {
        ...state,
        shareData: {
          loading: false,
          error: action.error
        }
      };
    }

    case PlaylistActions.PLAYLIST_SEND_OTP_REQUEST: {
      return {
        ...state,
        playlistSendOtp: {
          isLoading: true
        }
      };
    }

    case PlaylistActions.PLAYLIST_SEND_OTP_SUCCESS: {
      return {
        ...state,
        playlistSendOtp: {
          isLoading: false,
          email: action.email
        }
      };
    }

    case PlaylistActions.PLAYLIST_SEND_OTP_ERROR: {
      return {
        ...state,
        playlistSendOtp: {
          isLoading: false,
          error: action.error
        }
      };
    }

    case PlaylistActions.SET_PLAYLISTS_SEARCH_TEXT:
      return {
        ...state,
        searchPlaylistsText: action.searchPlaylistsText
      };

    default:
      return state;
  }
};

export default playlists;

const shouldUpdatePrivacy = (oldPrivacy, newPrivacy) => {
  const privacyOrdering = {
    private: 0,
    team: 1,
    organization: 2,
    public: 3
  };
  if (privacyOrdering[oldPrivacy] < privacyOrdering[newPrivacy]) {
    return true;
  }
  return false;
};
