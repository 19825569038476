import camelize from 'camelize';

import { CustomCategoryActions, InsightsActions } from 'actions/actionTypes';

const initialState = {
  data: {}, // fetch insights
  fetchingAInsights: 0, // fetching a insightss
  error: null,
  latestInsightsId: null,
  customCategories: {},
  fetchingCustomCategories: false,
  questions: {},
  variationExistsError: false,
  phraseVariationExistsError: false,
  variationExistsMessage: '',
  phraseVariationExistsMessage: '',
  loadingPurposes: false
};

const fetchingAInsights = (state, action) => {
  switch (action.type) {
    case 'FETCH_A_INSIGHTS_REQUEST':
      return {
        ...state,
        fetchingAInsights: state.fetchingAInsights + 1,
        error: null
      };
    case 'FETCH_A_INSIGHTS_SUCCESS':
      // here we need to know which category fetched insights belongs to
      return {
        ...state,
        fetchingAInsights: state.fetchingAInsights - 1,
        searching: 0,
        data: {
          ...state.data,
          [action.meetingId]: {
            ...state.data[action.meetingId],
            ...camelize(action.insights)
          }
        },
        latestInsightsId: action.insights.uuid
      };
    case 'FETCH_A_INSIGHTS_FAILURE': {
      return {
        ...state,
        fetchingAInsights: state.fetchingAInsights - 1,
        error: action.error
      };
    }
    default:
      return state;
  }
};

const fetchingQuestions = (state, action) => {
  switch (action.type) {
    case InsightsActions.FETCH_QUESTIONS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingId]: {
            ...state.data[action.meetingId],
            loadingQuestions: true,
            errorQuestions: null
          }
        }
      };
    case InsightsActions.FETCH_QUESTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingId]: {
            ...state.data[action.meetingId],
            questions: action.questions,
            loadingQuestions: false,
            errorQuestions: null
          }
        }
      };
    case InsightsActions.FETCH_QUESTIONS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingId]: {
            ...state.data[action.meetingId],
            loadingQuestions: false,
            errorQuestions: action.error
          }
        }
      };
    default:
      return state;
  }
};

const insights = (state = initialState, action) => {
  switch (action.type) {
    // fetch insights
    case 'FETCH_A_INSIGHTS_REQUEST':
    case 'FETCH_A_INSIGHTS_FAILURE':
    case 'FETCH_A_INSIGHTS_SUCCESS':
      return fetchingAInsights(state, action);
    case InsightsActions.FETCH_QUESTIONS_REQUEST:
    case InsightsActions.FETCH_QUESTIONS_SUCCESS:
    case InsightsActions.FETCH_QUESTIONS_FAILURE:
      return fetchingQuestions(state, action);
    case CustomCategoryActions.FETCH_CUSTOM_CATEGORY_REQUEST: {
      return {
        ...state,
        fetchingCustomCategories: true
      };
    }
    case CustomCategoryActions.FETCH_CUSTOM_CATEGORY_SUCCESS: {
      // Convert the data structure to an object with uuid keys for faster fetches/easier updates to redux
      const customCategoriesObject = {};
      action.customCategories.forEach(category => {
        customCategoriesObject[category.uuid] = category;
        const keywordsObject = {};
        const promptsObject = {};
        const trackersObject = {};
        // Create the keywords array and if any variations present into a hash for consistency
        category.keywords.forEach(keyword => {
          const keywordVariationsObject = {};
          keywordsObject[keyword.uuid] = keyword;
          keyword.variations.forEach(variation => {
            keywordVariationsObject[variation.uuid] = variation;
          });

          keywordsObject[keyword.uuid].variations = keywordVariationsObject;
        });
        // Do the same for prompts
        category.prompts.forEach(prompt => {
          const promptsVariationObject = {};
          promptsObject[prompt.uuid] = prompt;
          prompt.variations.forEach(variation => {
            promptsVariationObject[variation.uuid] = variation;
          });

          promptsObject[prompt.uuid].variations = promptsVariationObject;
        });
        // Do the same for trackers
        category.trackers.forEach(tracker => {
          const trackersSentencesObj = {};
          trackersObject[tracker.uuid] = tracker;
          tracker.sentences.forEach(sentence => {
            trackersSentencesObj[sentence.uuid] = sentence;
          });

          tracker.sentences = trackersSentencesObj;
        });
        category.keywords = keywordsObject;
        category.prompts = promptsObject;
        category.trackers = trackersObject;
      });

      return {
        ...state,
        customCategories: customCategoriesObject,
        fetchingCustomCategories: false
      };
    }
    case CustomCategoryActions.FETCH_CUSTOM_CATEGORY_FAILURE: {
      return {
        ...state,
        fetchingCustomCategories: false
      };
    }
    case CustomCategoryActions.PATCH_CUSTOM_CATEGORY_REQUEST: {
      return {
        ...state,
        fetchingCustomCategories: true
      };
    }

    case CustomCategoryActions.PATCH_CUSTOM_CATEGORY_SUCCESS: {
      const keywordsObject = {};
      const promptsObject = {};
      const { customCategory } = action;
      // Create the keywords array and if any variations present into a hash for consistency
      customCategory.keywords.forEach(keyword => {
        const variationsObject = {};
        keywordsObject[keyword.uuid] = keyword;
        keyword.variations.forEach(variation => {
          variationsObject[variation.uuid] = variation;
        });
        keywordsObject[keyword.uuid].variations = variationsObject;
      });

      customCategory.prompts.forEach(prompt => {
        const promptsVariationsObject = {};
        promptsObject[prompt.uuid] = prompt;
        prompt.variations.forEach(variation => {
          promptsVariationsObject[variation.uuid] = variation;
        });

        promptsObject[prompt.uuid].variations = promptsVariationsObject;
      });

      const patchedCustomCategory = {
        ...customCategory,
        keywords: keywordsObject,
        keywordCount: action.keywordCount,
        prompts: promptsObject
      };
      return {
        ...state,
        customCategories: {
          ...state.customCategories,
          [action.uuid]: patchedCustomCategory
        },
        fetchingCustomCategories: false
      };
    }

    case CustomCategoryActions.PATCH_CUSTOM_CATEGORY_ERROR: {
      return {
        ...state,
        fetchingCustomCategories: false
      };
    }
    case CustomCategoryActions.CREATE_CUSTOM_CATEGORY_SUCCESS: {
      const categoryUuid = action.category.uuid;
      return {
        ...state,
        customCategories: {
          ...state.customCategories,
          [categoryUuid]: action.category
        }
      };
    }

    case CustomCategoryActions.DELETE_CUSTOM_CATEGORY_SUCCESS: {
      const { [action.uuid]: value, ...remainingCategories } =
        state.customCategories;
      return {
        ...state,
        customCategories: remainingCategories
      };
    }

    case CustomCategoryActions.CREATE_CUSTOM_KEYWORD_SUCCESS: {
      const keywordUuid = action.result.uuid;
      return {
        ...state,
        variationExistsError: false,
        variationExistsMessage: '',
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            keywords: {
              ...state.customCategories[action.categoryUuid].keywords,
              [keywordUuid]: action.result
            },
            keywordCount:
              state.customCategories[action.categoryUuid].keywordCount + 1
          }
        }
      };
    }
    case CustomCategoryActions.CREATE_CUSTOM_KEYWORD_FAILURE:
      return {
        ...state,
        variationExistsError: false
      };

    case CustomCategoryActions.DELETE_CUSTOM_KEYWORD_SUCCESS: {
      const keywords = state.customCategories[action.categoryUuid]?.keywords;
      // Remove the complete keyword if the higher level keyword/isPrimary keyword is deleted
      const { [action.keywordUuid]: keywordValue, ...remainingKeywords } =
        keywords;
      return {
        ...state,
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            keywords: { ...remainingKeywords },
            keywordCount:
              state.customCategories[action.categoryUuid].keywordCount - 1
          }
        }
      };
    }

    case CustomCategoryActions.DELETE_KEYWORD_VARIATION_SUCCESS: {
      const keyword =
        state.customCategories[action.categoryUuid]?.keywords?.[
          action.parentKeywordUuid
        ];
      const {
        [action.variationUuid]: variationsValue,
        ...remainingVariations
      } = keyword.variations;

      return {
        ...state,
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            keywords: {
              ...state.customCategories[action.categoryUuid].keywords,
              [action.parentKeywordUuid]: {
                ...state.customCategories[action.categoryUuid].keywords[
                  action.parentKeywordUuid
                ],
                variations: { ...remainingVariations }
              }
            }
          }
        }
      };
    }

    case CustomCategoryActions.CREATE_KEYWORD_VARIATION_SUCCESS: {
      return {
        ...state,
        variationExistsError: false,
        variationExistsMessage: '',
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            keywords: {
              ...state.customCategories[action.categoryUuid].keywords,
              [action.parentKeywordUuid]: {
                ...state.customCategories[action.categoryUuid].keywords[
                  action.parentKeywordUuid
                ],
                variations: {
                  ...state.customCategories[action.categoryUuid].keywords[
                    action.parentKeywordUuid
                  ].variations,
                  [action.result.uuid]: action.result
                }
              }
            }
          }
        }
      };
    }

    case CustomCategoryActions.CREATE_KEYWORD_VARIATION_FAILURE: {
      const { err } = action;
      const errorMessage = err.message;
      const exisitingParentKeywordLabel = err.parent_keyword?.label;

      const variationExistsMessage = `${errorMessage} in "${exisitingParentKeywordLabel}"`;
      return {
        ...state,
        variationExistsError: true,
        variationExistsMessage
      };
    }

    case CustomCategoryActions.UPDATE_KEYWORD_LABEL_SUCCESS: {
      if (action.isPrimary) {
        // Change the incoming results variation array into a hash to follow the pattern
        const variationsObject = {};
        action.result.variations.forEach(variation => {
          variationsObject[variation.uuid] = variation;
        });

        return {
          ...state,
          variationExistsError: false,
          customCategories: {
            ...state.customCategories,
            [action.categoryUuid]: {
              ...state.customCategories[action.categoryUuid],
              keywords: {
                ...state.customCategories[action.categoryUuid].keywords,
                [action.keywordUuid]: {
                  ...action.result,
                  variations: variationsObject
                }
              }
            }
          }
        };
      }

      return {
        ...state,
        variationExistsError: false,
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            keywords: {
              ...state.customCategories[action.categoryUuid].keywords,
              [action.parentKeywordUuid]: {
                ...state.customCategories[action.categoryUuid].keywords[
                  action.parentKeywordUuid
                ],
                variations: {
                  ...state.customCategories[action.categoryUuid].keywords[
                    action.parentKeywordUuid
                  ].variations,
                  [action.keywordUuid]: {
                    ...action.result
                  }
                }
              }
            }
          }
        }
      };
    }

    case CustomCategoryActions.UPDATE_KEYWORD_LABEL_FAILURE:
      return {
        ...state,
        variationExistsError: true
      };

    case CustomCategoryActions.MAKE_KEYWORD_VARIATION_PRIMARY_SUCCESS: {
      // Find and remove the parent keyword uuid object from keywords, and replace it with the result
      // As the API takes care of the flip of variation to primary, and primary to variation
      const { [action.parentKeywordUuid]: value, ...remainingKeywords } =
        state.customCategories[action.categoryUuid].keywords;

      // Change the incoming results variation array into a hash to follow the pattern
      const variationsObject = {};
      action.result.variations.forEach(variation => {
        variationsObject[variation.uuid] = variation;
      });

      return {
        ...state,
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            keywords: {
              ...remainingKeywords,
              [action.keywordUuid]: {
                ...action.result,
                variations: variationsObject
              }
            }
          }
        }
      };
    }

    case CustomCategoryActions.CREATE_TRIGGER_PROMPT_SUCCESS: {
      const promptUuid = action.result.uuid;
      return {
        ...state,
        phraseVariationExistsError: false,
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            prompts: {
              ...state.customCategories[action.categoryUuid].prompts,
              [promptUuid]: action.result
            }
          }
        }
      };
    }
    case CustomCategoryActions.CREATE_TRIGGER_PROMPT_FAILURE:
      return {
        ...state,
        phraseVariationExistsError: true
      };

    case CustomCategoryActions.DELETE_TRIGGER_PROMPT_SUCCESS: {
      const triggerPrompts =
        state.customCategories[action.categoryUuid]?.prompts;
      // Remove the complete keyword if the higher level keyword/isPrimary keyword is deleted
      const { [action.promptUuid]: promptValue, ...remainingPrompts } =
        triggerPrompts;
      return {
        ...state,
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            prompts: { ...remainingPrompts }
          }
        }
      };
    }

    case CustomCategoryActions.DELETE_TRIGGER_PROMPT_VARIATION_SUCCESS: {
      const prompt =
        state.customCategories[action.categoryUuid]?.prompts?.[action.parent];
      const {
        [action.variationUuid]: variationsValue,
        ...remainingVariations
      } = prompt.variations;

      return {
        ...state,
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            prompts: {
              ...state.customCategories[action.categoryUuid].prompts,
              [action.parent]: {
                ...state.customCategories[action.categoryUuid].prompts[
                  action.parent
                ],
                variations: { ...remainingVariations }
              }
            }
          }
        }
      };
    }

    case CustomCategoryActions.CREATE_TRIGGER_PROMPT_VARIATION_SUCCESS: {
      return {
        ...state,
        phraseVariationExistsError: false,
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            prompts: {
              ...state.customCategories[action.categoryUuid].prompts,
              [action.parent]: {
                ...state.customCategories[action.categoryUuid].prompts[
                  action.parent
                ],
                variations: {
                  ...state.customCategories[action.categoryUuid].prompts[
                    action.parent
                  ].variations,
                  [action.result.uuid]: action.result
                }
              }
            }
          }
        }
      };
    }

    case CustomCategoryActions.CREATE_TRIGGER_PROMPT_VARIATION_FAILURE: {
      const { err } = action;
      const errorMessage = err.message;
      const exisitingParentPromptLabel = err.parent_prompt?.label;

      const phraseVariationExistsMessage = `${errorMessage} in "${exisitingParentPromptLabel}"`;
      return {
        ...state,
        phraseVariationExistsError: true,
        phraseVariationExistsMessage
      };
    }

    case CustomCategoryActions.UPDATE_TRIGGER_PROMPT_LABEL_SUCCESS: {
      if (action.isPrimary) {
        // Change the incoming results variation array into a hash to follow the pattern
        const variationsObject = {};
        action.result.variations.forEach(variation => {
          variationsObject[variation.uuid] = variation;
        });
        return {
          ...state,
          phraseVariationExistsError: false,
          customCategories: {
            ...state.customCategories,
            [action.categoryUuid]: {
              ...state.customCategories[action.categoryUuid],
              prompts: {
                ...state.customCategories[action.categoryUuid].prompts,
                [action.promptUuid]: {
                  ...action.result,
                  variations: variationsObject
                }
              }
            }
          }
        };
      }

      return {
        ...state,
        phraseVariationExistsError: false,
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            prompts: {
              ...state.customCategories[action.categoryUuid].prompts,
              [action.parent]: {
                ...state.customCategories[action.categoryUuid].prompts[
                  action.parent
                ],
                variations: {
                  ...state.customCategories[action.categoryUuid].prompts[
                    action.parent
                  ].variations,
                  [action.promptUuid]: {
                    ...action.result
                  }
                }
              }
            }
          }
        }
      };
    }

    case CustomCategoryActions.UPDATE_TRIGGER_PROMPT_LABEL_FAILURE:
      return {
        ...state,
        phraseVariationExistsError: true
      };

    case CustomCategoryActions.MAKE_TRIGGER_PROMPT_VARIATION_PRIMARY_SUCCESS: {
      // Find and remove the parent keyword uuid object from keywords, and replace it with the result
      // As the API takes care of the flip of variation to primary, and primary to variation
      const { [action.parent]: value, ...remainingPrompts } =
        state.customCategories[action.categoryUuid].prompts;

      // Change the incoming results variation array into a hash to follow the pattern
      const variationsObject = {};
      action.result.variations.forEach(variation => {
        variationsObject[variation.uuid] = variation;
      });

      return {
        ...state,
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            prompts: {
              ...remainingPrompts,
              [action.promptUuid]: {
                ...action.result,
                variations: variationsObject
              }
            }
          }
        }
      };
    }

    case CustomCategoryActions.CLEAR_VARIATION_ERROR_MESSAGE:
      return {
        ...state,
        variationExistsError: false,
        phraseVariationExistsError: false
      };

    case CustomCategoryActions.FETCH_SINGLE_CUSTOM_CATEGORY_SUCCESS: {
      const { categoryUuid, response } = action;
      return {
        ...state,
        customCategories: {
          ...state.customCategories,
          [categoryUuid]: {
            ...state.customCategories[categoryUuid],
            settings: {
              ...state.customCategories[categoryUuid].settings,
              meetingTypesWhitelisted:
                response.settings.meetingTypesWhitelisted,
              meetingTypesBlacklisted: response.settings.meetingTypesBlacklisted
            }
          }
        }
      };
    }

    case CustomCategoryActions.CREATE_PHRASE_SUCCESS: {
      const trackerUuid = action.result.uuid;

      return {
        ...state,
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            trackers: {
              ...state.customCategories[action.categoryUuid].trackers,
              [trackerUuid]: action.result
            }
          }
        }
      };
    }

    case CustomCategoryActions.UPDATE_PHRASE_SUCCESS: {
      return {
        ...state,
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            trackers: {
              ...state.customCategories[action.categoryUuid].trackers,
              [action.phraseUuid]: action.result
            }
          }
        }
      };
    }

    case CustomCategoryActions.DELETE_PHRASE_SUCCESS: {
      const phrases = state.customCategories[action.categoryUuid]?.trackers;
      const { [action.phraseUuid]: phraseValue, ...remainingPhrases } = phrases;

      return {
        ...state,
        customCategories: {
          ...state.customCategories,
          [action.categoryUuid]: {
            ...state.customCategories[action.categoryUuid],
            trackers: { ...remainingPhrases }
          }
        }
      };
    }

    case CustomCategoryActions.UPDATE_CUSTOM_CATEGORY_WHITELISTED_TYPES_REQUEST:
    case CustomCategoryActions.UPDATE_CUSTOM_CATEGORY_BLACKLISTED_TYPES_REQUEST: {
      return {
        ...state,
        loadingPurposes: true
      };
    }

    case CustomCategoryActions.UPDATE_CUSTOM_CATEGORY_WHITELISTED_TYPES_SUCCESS:
    case CustomCategoryActions.UPDATE_CUSTOM_CATEGORY_BLACKLISTED_TYPES_SUCCESS: {
      return {
        ...state,
        loadingPurposes: false
      };
    }

    default:
      return state;
  }
};

export default insights;
