import { useEffect } from 'react';
import PropTypes from 'prop-types';

const ThirdPartyScriptLoader = ({
  src,
  async = true,
  defer = false,
  id,
  delay = 5000,
  attributes = {},
  onloadCallback
}) => {
  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement('script');
      script.src = src;
      script.async = async;
      script.defer = defer;
      script.id = id;
      script.type = 'text/javascript';

      for (const [key, value] of Object.entries(attributes)) {
        script.setAttribute(key, value);
      }
      script.addEventListener('load', onloadCallback);
      document.body.appendChild(script);
    };
    try {
      if ('scheduler' in window) {
        window.scheduler?.postTask(loadScript, {
          priority: 'background',
          delay
        });
      } else if ('requestIdleCallback' in window) {
        setTimeout(() => {
          requestIdleCallback(loadScript);
        }, delay);
      } else {
        setTimeout(loadScript, delay);
      }
    } catch {
      loadScript();
    }
  }, []);

  return null;
};

ThirdPartyScriptLoader.propTypes = {
  src: PropTypes.string.isRequired,
  attributes: PropTypes.object,
  async: PropTypes.bool,
  defer: PropTypes.bool,
  id: PropTypes.string,
  delay: PropTypes.number,
  onloadCallback: PropTypes.func
};

export default ThirdPartyScriptLoader;
