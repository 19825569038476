import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { IAutomationActionRulesAPI } from 'types/automation';

import AvomaSelect from 'components/Common/AvomaSelect';

interface IProps {
  action: IAutomationActionRulesAPI;
  handleValueChange: (value: string) => void;
  clientValue: string;
  disabled?: boolean;
  isRequired?: boolean;
}

const AutomationFormGenericSelect = ({
  action,
  handleValueChange,
  clientValue,
  disabled = false,
  isRequired
}: IProps) => {
  const options = useMemo(
    () =>
      action.optionChoices.map(opt => ({
        label: opt.label,
        value: opt.value
      })),
    [action.optionChoices]
  );

  return (
    <div
      className={twMerge(
        'flex w-full flex-col gap-2',
        disabled && 'pointer-events-none opacity-40'
      )}
    >
      <div className='flex flex-col'>
        <p className='flex items-center text-sm font-bold text-gunmetal'>
          {action.label}{' '}
          {isRequired && <span className='ml-1 text-avoma-red'>*</span>}
        </p>
      </div>
      <div className='flex flex-col'>
        <AvomaSelect
          items={options}
          label={`Select ${action.label}`}
          triggerClassName='w-full bg-white rounded cursor-pointer'
          contentPosition='popper'
          onValueChange={handleValueChange}
          value={clientValue}
          placeholder={`Select ${action.label}`}
          selectItemContainerClassName='hover:bg-snow'
        />
      </div>
    </div>
  );
};

export default AutomationFormGenericSelect;
