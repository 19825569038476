import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FaTrashAlt as TrashIcon } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useSelected } from 'slate-react';

import AvomaIcon from 'components/Common/AvomaIcon';
import AvomaIconButton from 'components/Common/AvomaIconButton';
import ConfirmModal from 'components/Common/ConfirmModal';
import TimestampLink from 'components/Common/buttons/TimestampLink';

import L10n from 'helpers/L10n';

import AugmentedNoteIcon from 'images/ic_augmented_note.svg';
import BookmarkIcon from 'images/ic_bookmark.svg';
import { ReactComponent as ClickupIcon } from 'images/ic_clickup_integration.svg';
import { ReactComponent as HubspotIcon } from 'images/ic_hubspot_sprocket.svg';
import KeywordIcon from 'images/ic_keyword.svg';
import RobotIcon from 'images/ic_robot.svg';
import TriggerPromptIcon from 'images/ic_trigger_prompt.svg';

import Tokens from 'styles/tokens';

const ClickupTicketButtons = ({ ticketDetails }) => {
  if (ticketDetails && ticketDetails.length > 0) {
    return (
      <a
        href={ticketDetails[0].url}
        target='_blank'
        rel='noopener noreferrer'
        className='disable-select cursor-pointer rounded bg-transparent p-1 hover:bg-smoke'
      >
        <AvomaIcon
          RenderIcon={ClickupIcon}
          tooltip={L10n.automations.openIn.clickup}
          tooltipPosition='bottom-left'
          extraSmall
        />
      </a>
    );
  }

  return null;
};

ClickupTicketButtons.propTypes = {
  ticketDetails: PropTypes.arrayOf(PropTypes.object)
};

const TicketButtons = ({
  ticketInfo,
  clickupIntegration,
  hubspotIntegration
}) => {
  if (isEmpty(ticketInfo)) {
    return null;
  }

  return ticketInfo.map(ticket => {
    if (ticket.provider === 'clickup' && clickupIntegration) {
      return (
        <div className='flex items-center gap-2' key={ticket.provider}>
          <div className='flex items-center gap-2' key={ticket.provider}>
            {ticket.urls.map(url => (
              <AvomaIcon
                key={url}
                RenderIcon={ClickupIcon}
                tooltip={L10n.automations.openIn.clickup}
                tooltipPosition='bottom-left'
                extraSmall
                className='cursor-pointer z-[9999]'
                onClick={() =>
                  window.open(url, '_blank', 'noopener,noreferrer')
                }
              />
            ))}
          </div>
        </div>
      );
    }

    if (ticket.provider === 'hubspot' && hubspotIntegration) {
      return (
        <div className='flex items-center gap-2' key={ticket.provider}>
          {ticket.urls.map(url => (
            <AvomaIcon
              key={url}
              RenderIcon={HubspotIcon}
              tooltip={L10n.automations.openIn.hubspot}
              tooltipPosition='bottom-left'
              extraSmall
              className='cursor-pointer z-[9999]'
              onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
            />
          ))}
        </div>
      );
    }

    return null;
  });
};

TicketButtons.propTypes = {
  ticketInfo: PropTypes.arrayOf(PropTypes.object),
  clickupIntegration: PropTypes.bool,
  hubspotIntegration: PropTypes.bool
};

const Transcript = ({
  element,
  editor,
  children,
  bookmark,
  onDeleteTranscriptNode,
  onClickTranscriptTimestamp,
  linkedTime,
  linkedUuid,
  clearNoteAndTranscriptLinked,
  meetingHappening,
  readOnly
}) => {
  const [hovering, setHover] = useState();
  const [deleteConfirmModal, setShowDeleteModal] = useState();
  const clickupIntegration = useSelector(
    state => state.user?.ticketingProviders?.clickup?.clickupIntegration
  );
  const hubspotIntegration = useSelector(
    state => state.user?.ticketingProviders?.hubspot?.hubspotIntegration
  );
  const { selection } = editor;
  const selected =
    useSelected() && selection.anchor.path === selection.focus.path;

  const showHoverState = () => {
    setHover(true);
  };

  const hideHoverState = () => {
    setHover(false);
  };
  const { data } = element;
  const hasText =
    element &&
    element.children &&
    element.children.length > 0 &&
    element.children[0].text;

  const {
    time,
    uuid,
    turing_note: turingNote,
    machine_note: machineNote,
    trigger_prompt: triggerPrompt,
    augmented_note: augmentedNote,
    ticket_info: ticketInfo,
    ticket_details: ticketDetails, // We still need to keep this, since BE isn't migrating the older notes to the new format yet
    keyword
  } = data;

  const clickTranscriptTimestamp = useCallback(() => {
    if (time && onClickTranscriptTimestamp) {
      onClickTranscriptTimestamp(time, uuid);
    }
  }, [onClickTranscriptTimestamp, time, uuid]);

  const isLinked = time === linkedTime && data.uuid === linkedUuid;
  const shouldHighlight = hovering || selected;
  let icon = null;
  let iconTooltip = '';
  if (machineNote || turingNote) {
    icon = RobotIcon;
    iconTooltip = 'AI Note';
  } else if (triggerPrompt) {
    icon = TriggerPromptIcon;
    iconTooltip = 'Trigger Prompt';
  } else if (keyword) {
    icon = KeywordIcon;
    iconTooltip = 'Keyword';
  } else if (augmentedNote) {
    icon = AugmentedNoteIcon;
    iconTooltip = 'Smart Note';
  } else if (bookmark) {
    icon = BookmarkIcon;
    iconTooltip = 'Bookmark';
  }

  const showIcon =
    machineNote || triggerPrompt || keyword || augmentedNote || bookmark;
  const showDeleteConfirmModal = () => {
    setShowDeleteModal(true);
  };

  const hideDeleteConfirmModal = () => {
    setShowDeleteModal(false);
  };

  const deleteNote = () => {
    onDeleteTranscriptNode(element);
    hideDeleteConfirmModal();
  };

  return (
    <div
      className='transcript'
      onMouseEnter={showHoverState}
      onMouseLeave={hideHoverState}
      style={{
        ...styles.transcript,
        ...(showIcon ? styles.iconPadding : {}),
        ...(shouldHighlight && styles.showBackground),
        ...(isLinked && styles.linkedBackground)
      }}
    >
      <div style={styles.text}>
        {!hasText && bookmark && (
          <span
            style={styles.placeholder}
            className='disable-select'
            contentEditable={false}
          >
            {L10n.bookmarks.placeholder}
          </span>
        )}
        {children}
      </div>
      <div className='disable-select' contentEditable={false}>
        {element && onDeleteTranscriptNode && (
          <div style={styles.buttonContainer}>
            <AvomaIconButton
              style={{
                ...styles.deleteButton,
                ...(shouldHighlight && styles.show)
              }}
              className='disable-select'
              onClick={showDeleteConfirmModal}
              tooltip={L10n.slateEditor.deleteTranscript}
              tooltipPosition='bottom-right'
              destructive
              faIcon={<TrashIcon style={styles.actionButtonIcon} />}
            />
          </div>
        )}
        <TimestampLink
          time={time}
          svg={icon}
          onClick={meetingHappening ? null : clickTranscriptTimestamp}
          onHide={clearNoteAndTranscriptLinked}
          show={shouldHighlight}
          showHide={!meetingHappening && hovering}
          isLinked={isLinked}
          iconTooltip={iconTooltip}
          hideTimestamp={(machineNote || turingNote) && time === 0}
          style={
            (clickupIntegration || hubspotIntegration) &&
            !readOnly &&
            (ticketInfo?.length > 0 || ticketDetails?.length > 0)
              ? { right: Tokens.spacing.fourhalf }
              : {}
          }
        />
        <div className='absolute right-1 top-1 flex flex-row items-center gap-2'>
          {clickupIntegration && !readOnly && (
            <ClickupTicketButtons ticketDetails={ticketDetails} />
          )}
          {!readOnly && (
            <TicketButtons
              ticketInfo={ticketInfo}
              clickupIntegration={clickupIntegration}
              hubspotIntegration={hubspotIntegration}
            />
          )}
        </div>
      </div>
      {deleteConfirmModal && (
        <ConfirmModal
          isOpen={deleteConfirmModal}
          onCancel={hideDeleteConfirmModal}
          onConfirm={deleteNote}
          title={
            bookmark
              ? L10n.slateEditor.deleteBookmarkTitle
              : L10n.slateEditor.deleteTimestampTitle
          }
          text={bookmark ? null : L10n.slateEditor.deleteTimestampText}
          confirmText={L10n.slateEditor.deleteTranscript}
        />
      )}
    </div>
  );
};

const styles = {
  transcript: {
    position: 'relative',
    paddingRight: '54px',
    paddingLeft: Tokens.spacing.half,
    marginLeft: `-${Tokens.spacing.half}`,
    backgroundColor: Tokens.colors.transparent,
    borderRadius: Tokens.spacing.borderRadius,
    boxSizing: 'border-box',
    transition: 'backgroundColor 0s linear 300ms, opacity 100ms'
  },
  iconPadding: {
    paddingRight: '75px'
  },
  text: {
    position: 'relative'
  },
  placeholder: {
    color: Tokens.colors.silver,
    position: 'absolute',
    top: 0,
    pointerEvents: 'none'
  },
  buttonContainer: {
    position: 'absolute',
    top: '-1px',
    left: '-38px',
    paddingRight: Tokens.spacing.two,
    zIndex: '3'
  },
  deleteButton: {
    visibility: 'hidden',
    opacity: '0',
    transition: 'visibility 0s linear 300ms, opacity 100ms'
  },
  show: {
    visibility: 'visible',
    opacity: '1',
    transition: 'visibility 0s, opacity 0s'
  },
  showBackground: {
    backgroundColor: Tokens.colors.lightoverlay,
    transition: 'backgroundColor 0s, opacity 0s'
  },
  linkedBackground: {
    backgroundColor: Tokens.colors.azureLightest
  },
  actionButtonIcon: {
    width: '12px',
    height: '12px'
  }
};

Transcript.propTypes = {
  element: PropTypes.shape({
    data: PropTypes.object,
    text: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  editor: PropTypes.object,
  children: PropTypes.node,
  onDeleteTranscriptNode: PropTypes.func,
  onClickTranscriptTimestamp: PropTypes.func.isRequired,
  linkedTime: PropTypes.number.isRequired,
  linkedUuid: PropTypes.string.isRequired,
  clearNoteAndTranscriptLinked: PropTypes.func.isRequired,
  bookmark: PropTypes.bool,
  meetingHappening: PropTypes.bool,
  readOnly: PropTypes.bool
};

Transcript.defaultProps = {
  onDeleteTranscriptNode: null,
  bookmark: false,
  meetingHappening: false,
  readOnly: false
};

export default Transcript;
