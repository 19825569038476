import { WebsocketActions } from 'actions/actionTypes';

export const initialWebsocketState = {
  connected: false
};

const websocket = (state = initialWebsocketState, action) => {
  switch (action.type) {
    case WebsocketActions.CONNECTED:
      return {
        ...state,
        connected: true
      };
    case WebsocketActions.DISCONNECTED:
      return {
        connected: false
      };
    case WebsocketActions.SUBSCRIBED:
      return {
        ...state,
        [action.streamType]: {
          ...state[action.streamType],
          [action.uuid]: true
        }
      };
    case WebsocketActions.UNSUBSCRIBED:
      return {
        ...state,
        [action.streamType]: {
          ...state[action.streamType],
          [action.uuid]: false
        }
      };
    default:
      return state;
  }
};

export default websocket;
