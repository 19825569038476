import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useGetTicketingTeamsQuery } from 'services/ticketing';

import AvomaSelect from 'components/Common/AvomaSelect';

const ClickupIntegrationAutomationsWorkspaceList = ({
  action,
  handleValueChange,
  clientValue,
  isRequired
}) => {
  const { uuid: ticketingProviderUuid } = useSelector(
    state => state.user?.ticketingProviders?.clickup || {}
  );
  const { data: workspaces } = useGetTicketingTeamsQuery(
    {
      providerUuid: ticketingProviderUuid
    },
    {
      skip: !ticketingProviderUuid
    }
  );

  const workspaceOptions =
    workspaces?.map(workspace => ({
      label: workspace.name,
      value: workspace.uuid
    })) || [];

  return (
    <div className='flex w-full flex-col gap-2'>
      <p className='flex items-center text-sm font-bold text-gunmetal'>
        {action.label}{' '}
        {isRequired && <span className='ml-1 text-red-500'>*</span>}
      </p>
      <div className='flex flex-col'>
        <AvomaSelect
          items={workspaceOptions}
          label='Select Workspace'
          triggerClassName='w-full bg-white rounded cursor-pointer'
          contentPosition='popper'
          onValueChange={handleValueChange}
          value={clientValue}
          placeholder='Select a workspace'
          selectItemContainerClassName='hover:bg-snow'
        />
      </div>
    </div>
  );
};

ClickupIntegrationAutomationsWorkspaceList.propTypes = {
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    optionTitle: PropTypes.string.isRequired,
    optionChoices: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
      })
    ),
    apiUrl: PropTypes.string
  }),
  handleValueChange: PropTypes.func.isRequired,
  clientValue: PropTypes.string,
  isRequired: PropTypes.bool
};

export default ClickupIntegrationAutomationsWorkspaceList;
