import { isZoomContext } from './zoomApp';

export const TrackOnboarding = {
  PAGE_ZOOM_CONNECTED: 'Onboarding Zoom Connected',
  PAGE_ACCOUNT_INFO: 'Onboarding Account Info',
  PAGE_CALENDAR: 'Onboarding Calendar',
  PAGE_ONBOARDING_FINISHED: 'Onboarding Finished'
};

export const TrackAuth = {
  PAGE_LOGIN: 'Login',
  PAGE_SIGNUP: 'Signup'
};

export const TrackApp = {
  PAGE_PUBLIC: 'Public Share',
  PAGE_HOME: 'Home',
  PAGE_MEETING: 'Meeting',
  PAGE_QUICKSTART: 'Quick Start',
  PAGE_MEETING_OVERVIEW: 'Meeting Overview',
  PAGE_MY_MEETINGS: 'My Meetings',
  PAGE_ALL_MEETINGS: 'All Meetings',
  PAGE_SHARED_MEETING: 'Shared Meetings',
  PAGE_TEAM_MEETINGS: 'Team Meetings',
  PAGE_DASHBOARD_ACTIVITY: 'Activity Dashboard',
  PAGE_DASHBOARD_TTL: 'Talk-To-Listen Dashboard',
  PAGE_DASHBOARD_TRENDS: 'Trends Dashboard',
  PAGE_DASHBOARD_TRACKERS: 'Trackers Dashboard',
  PAGE_DASHBOARD_TOPICS: 'Topics Dashboard',
  PAGE_DASHBOARD_POPULAR_TOPICS: 'Popular Topics Dashboard',
  PAGE_DASHBOARD_CONVERSATION: 'Conversation Dashboard',
  PAGE_PLAYLISTS: 'Playlists',
  PAGE_DASHBOARD_DEAL_INTELLIGENCE: 'Deal Intelligence Dashboard',
  PAGE_DEAL_INTELLIGENCE: 'Deal Intelligence',
  PAGE_EMBED_RECORDING: 'Embedded Recording',
  PAGE_ZOOM_MEETING_CHOOSER: 'Zoom Meeting Chooser',
  EVENT_MEETING_CLICKED: 'Meeting Clicked',
  EVENT_MEETING_OVERVIEW_CLICKED: 'Meeting Overview Clicked',
  EVENT_SHARE_CLICKED: 'Share Clicked',
  EVENT_CREATE_HIGHLIGHT_CLICKED: 'Create Highlight Clicked',
  EVENT_SHARE_HIGHLIGHT_CLICKED: 'Share Highlight Clicked',
  EVENT_CREATE_HIGHLIGHT: 'Highlight Created',
  EVENT_DELETE_HIGHLIGHT: 'Highlight Deleted',
  EVENT_JOIN_CONFERENCE: 'Join Conference',
  EVENT_CREATE_URL: 'Created Public URL',
  EVENT_DELETE_URL: 'Deleted Public URL',
  EVENT_COPY_PUBLIC_URL: 'Copied Public URL',
  EVENT_SHARE_MEETING: 'Shared Meeting',
  EVENT_SHARE_HIGHLIGHT: 'Shared Highlight',
  EVENT_UPDATE_SHARE: 'Share Updated',
  EVENT_PLAYBACK_SPEED_CHANGED: 'Playback Speed Changed',
  EVENT_ONBOARDING_CARD_CLICKED: 'Onboarding Card Clicked',
  EVENT_ONBOARDING_CARD_DISMISSED: 'Onboarding Card Dismissed',
  EVENT_ONBOARDING_CARD_DISMISSED_ALL: 'Onboarding Cards Dismissed All',
  EVENT_ADD_TO_NOTES: 'Add Highlight to Notes',
  EVENT_PLAY_PLAYLIST: 'Playlist Item Played',
  EVENT_PLAY_SNIPPET: 'Snippet Item Played',
  EVENT_PLAY_PUBLIC: 'Public Recording Played',
  EVENT_OVERVIEW_MEETING: 'Meeting Overview Opened',
  EVENT_COPY_MEETING_URL: 'Copied Meeting URL',
  EVENT_MEETING_INDICATOR_CLICKED: 'Meeting Indicator Clicked',
  EVENT_MEETING_IN_NEW_TAB: 'Open Meeting in New Tab',
  EVENT_SHOW_MEETING_DETAILS: 'Show Meeting Details',
  EVENT_PLAYLIST_OPTION_CLICKED: 'Playlist Option Clicked',
  EVENT_MEETING_DELETED: 'Meeting Deleted',
  EVENT_RECORDING_STATUS_CLICKED: 'Recording Status Clicked',
  EVENT_SEARCH_MEETING: 'Search Meeting',
  EVENT_APPLY_SAVED_SEARCH: 'Apply Saved Search',
  TRACK_BOOKING_IN_PUBLIC_PAGE: 'Left scheduler public page without booking',
  COPY_LINK_FROM_DRAWER_VIEW: 'Copied scheduler page link from drawer view',
  COPY_LINK_FROM_SCHEDULER_VIEW: 'Copied scheduler page link from list view',
  VIEWED_TOP_QUESTIONS_DASHBOARD: 'VIEWED_TOP_QUESTIONS_DASHBOARD',
  SCORECARD_REQUEST_COACHING_CLICKED: 'SCORECARD_REQUEST_COACHING_CLICKED',
  SCORECARD_DRAFT_CREATED: 'SCORECARD_DRAFT_CREATED',
  SCORECARD_CONTINUE_FROM_DRAFT: 'SCORECARD_CONTINUE_FROM_DRAFT',
  SCORECARD_TAB_VIEWED: 'SCORECARD_TAB_VIEWED'
};

export const Categories = {
  MEETING: 'Meeting',
  SHARING: 'Sharing',
  MEETING_OVERVIEW: 'Meeting Overview',
  SEARCH: 'Search',
  SCHEDULER: 'Scheduler',
  DASHBOARD: 'Dashboard',
  SCORECARD: 'Scorecard'
};

export const sendTrackEvent = ({ name, category, label, ...rest }) => {
  window?.analytics?.track(name, {
    category,
    label,
    ...rest
  });
};

export const sendPageEvent = (name, properties) => {
  window?._hsq?.push(['setPath', window.location.pathname]);
  window?._hsq?.push(['trackPageView']);
  window?.analytics?.page(name, {
    ...properties,
    appType: isZoomContext() ? 'zoom' : 'web'
  });
};
