import { useCallback, useEffect, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

export function useOverflowDetector() {
  const elementRef = useRef();
  const [isOverflowing, setIsOverflowing] = useState(false);

  const updateState = useCallback(() => {
    const box = elementRef.current;

    if (box) {
      setIsOverflowing(
        box.clientWidth < box.scrollWidth || box.clientHeight < box.scrollHeight
      );
    }
  }, []);

  useEffect(() => {
    updateState();
  }, [updateState]);

  useResizeDetector({
    targetRef: elementRef,
    refreshMode: 'debounce',
    refreshRate: 100,
    onResize: updateState
  });

  return [elementRef, isOverflowing];
}
