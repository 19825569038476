/* eslint-disable sonarjs/no-duplicate-string */
import Tokens from 'styles/tokens';

export const planFeatureList = {
  'basic-monthly': [
    'Recording & Transcription',
    'Limited AI-generated Notes',
    'Collaborative Note-taking',
    'Agenda Preparation',
    'Unlimited 1:1 Scheduler'
  ],
  'lite-monthly': [
    'Automatic Video Recording',
    'Unlimited Transcription',
    'Advanced AI-generated Notes',
    'Group & Round-Robin Scheduler',
    'CRM & Dialer Integration'
  ],
  'lite-yearly': [
    'Automatic Video Recording',
    'Unlimited Transcription',
    'Advanced AI-generated Notes',
    'Group & Round-Robin Scheduler',
    'CRM & Dialer Integration'
  ],

  'plus-monthly': [
    <span key='plus-monthly'>
      Everything in{' '}
      <strong style={{ color: Tokens.colors.gunmetal }}>
        AI Meeting Assistant
      </strong>
    </span>,
    'Manual and AI Scorecards',
    'Talk Pattern & Topic Intelligence',
    'Smart Tracker Intelligence',
    'Engagement & ROI Intelligence'
  ],
  'plus-yearly': [
    <span key='plus-yearly'>
      Everything in{' '}
      <strong style={{ color: Tokens.colors.gunmetal }}>
        AI Meeting Assistant
      </strong>
    </span>,
    'Manual and AI Scorecards',
    'Talk Pattern & Topic Intelligence',
    'Smart Tracker Intelligence',
    'Engagement & ROI Intelligence'
  ],

  'premium-monthly': [
    <span key='premium-monthly'>
      Everything in{' '}
      <strong style={{ color: Tokens.colors.gunmetal }}>
        Conversation Intelligence
      </strong>
    </span>,
    'Automatic CRM field updates',
    'Custom Deal Boards, Risk Scores',
    'Roll-up Forecasting & Reports',
    'AI Win-Loss Analysis (Add-on)'
  ],
  'premium-yearly': [
    <span key='premium-monthly'>
      Everything in{' '}
      <strong style={{ color: Tokens.colors.gunmetal }}>
        Conversation Intelligence
      </strong>
    </span>,
    'Automatic CRM field updates',
    'Custom Deal Boards, Risk Scores',
    'Roll-up Forecasting & Reports',
    'AI Win-Loss Analysis (Add-on)'
  ]
};

export const PLAN_NAMES = {
  basic: 'Basic',
  lite: 'AI Meeting Assistant',
  plus: 'Conversation Intelligence',
  premium: 'Revenue Intelligence',
  enterprise: 'Enterprise'
};

export const BILLING_ADDONS = {
  aiWinLossAnalysis: 'AI Win-Loss Analysis'
};
