import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef
} from 'react';
import { twMerge } from 'tailwind-merge';

import Tokens from 'styles/tokens';

import AvomaAttribute from '../AvomaAttribute';
import AvomaCheckbox from './AvomaCheckbox';
import { CHECKBOX_STATES } from './constants';
import { IAvomaNestedCheckboxProps } from './types';

const AvomaNestedCheckbox: ForwardRefRenderFunction<
  HTMLInputElement,
  IAvomaNestedCheckboxProps
> = (props, ref) => {
  const {
    className,
    value,
    label,
    selectedVal = [],
    labelStyle = {},
    onChange = () => {},
    childrens = [],
    disabled
  } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  useImperativeHandle(ref, () => inputRef?.current as HTMLInputElement);

  const parentValues = childrens.map(child => child.value);

  const isIntermediate = selectedVal.some(val => parentValues.includes(val));

  const isCheckedParent = parentValues.every(val => selectedVal.includes(val));

  const onClickParent = () => onChange(parentValues);

  /*
    if all children value is present in the selected value, 
      then the parent checkbox should be checked
    if some children value is present in the selected value, 
      then the parent checkbox should be indeterminate
    else parent checkbox should be empty
  */
  let multiSelectState: keyof typeof CHECKBOX_STATES = CHECKBOX_STATES.empty;
  if (isCheckedParent) {
    multiSelectState = CHECKBOX_STATES.checked;
  } else if (isIntermediate) {
    multiSelectState = CHECKBOX_STATES.indeterminate;
  }

  return (
    <React.Fragment>
      <AvomaCheckbox
        className={twMerge(
          'whitespace-break-spaces break-words ml-0',
          className
        )}
        id={value}
        key={label}
        isMultiSelect
        ref={inputRef}
        checked={isCheckedParent}
        onChange={onClickParent}
        multiSelectState={multiSelectState}
        label={<span className='text-xxs'>{label}</span>}
        value={value}
        labelStyle={{ ...labelStyle, textTransform: 'none' }}
        disabled={disabled}
      />
      <div className='flex flex-col gap-3 whitespace-break-spaces break-words ml-6 mb-2'>
        {childrens.map(child => (
          <AvomaCheckbox
            key={child.label}
            id={child.value}
            label={
              <AvomaAttribute
                className='text-xxs'
                attribute={{ label: child.label }}
                style={{ ...labelStyle, border: 'none' }}
              />
            }
            labelStyle={{
              borderRadius: labelStyle?.borderRadius,
              backgroundColor: child?.bgColor || Tokens.colors.white,
              color: child?.textColor || Tokens.colors.black
            }}
            value={child.value}
            checked={selectedVal.includes(child.value)}
            onChange={() => child.value && onChange(child.value)}
            disabled={child.disabled}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default forwardRef(AvomaNestedCheckbox);
