import camelize from 'camelize';
import keyBy from 'lodash.keyby';

const initialState = {
  // teams data
  userTeams: {
    count: 0,
    next: null,
    previous: null,
    data: {}
  },
  allTeams: {
    count: 0,
    next: null,
    previous: null,
    data: {}
  },
  fetching: false,
  fetchingTeams: false,
  fetchingError: null,
  error: null
};

const mergeUserListToTeams = (state, teamArr) =>
  teamArr.map(el => {
    const team = el;
    team.users =
      state.allTeams && state.allTeams.data[el.uuid]
        ? state.allTeams.data[el.uuid].users
        : {};
    return team;
  });

const teams = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_TEAMS_REQUEST':
      return {
        ...state,
        fetchingTeams: true,
        fetchingError: null
      };
    case 'FETCH_TEAMS_SUCCESS': {
      const fetchedTs = camelize(mergeUserListToTeams(state, action.teams));
      return {
        ...state,
        fetchingTeams: false,
        userTeams: {
          next: action.next,
          previous: action.previous,
          count: action.count,
          data: {
            ...state.userTeams.data,
            ...keyBy(fetchedTs, 'uuid')
          }
        },
        allTeams: {
          ...state.allTeams,
          data: {
            ...state.allTeams.data,
            ...keyBy(fetchedTs, 'uuid')
          }
        },
        fetchingError: null
      };
    }
    case 'FETCH_TEAMS_FAILURE':
      return {
        ...state,
        fetchingTeams: false,
        fetchingError: action.error
      };
    case 'FETCH_ALL_TEAMS_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      };
    case 'FETCH_ALL_TEAMS_SUCCESS': {
      const fetchedTeams = camelize(mergeUserListToTeams(state, action.teams));
      const responseTeamsData = keyBy(fetchedTeams, 'uuid');
      const { data } = state.allTeams;
      const hasNewTeams = Object.keys(responseTeamsData).find(
        key => !data[key]
      );
      const teamData = hasNewTeams
        ? {
            ...state.allTeams.data,
            ...responseTeamsData
          }
        : state.allTeams.data;
      return {
        ...state,
        fetching: false,
        allTeams: {
          next: action.next,
          previous: action.previous,
          count: action.count,
          data: teamData
        },
        error: null
      };
    }

    case 'FETCH_ALL_TEAMS_FAILURE':
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case 'FETCH_TEAM_USERS_REQUEST':
      return {
        ...state,
        allTeams: {
          ...state.allTeams,
          data: {
            ...state.allTeams.data,
            [action.uuid]: {
              ...state.allTeams.data[action.uuid],
              fetching: true,
              error: null
            }
          }
        }
      };
    case 'FETCH_TEAM_USERS_SUCCESS':
      return {
        ...state,
        allTeams: {
          ...state.allTeams,
          data: {
            ...state.allTeams.data,
            [action.uuid]: {
              ...state.allTeams.data[action.uuid],
              fetching: false,
              error: null,
              users: {
                next: action.next,
                previous: action.previous,
                count: action.count,
                data: {
                  ...keyBy(camelize(action.team), 'uuid')
                }
              }
            }
          }
        }
      };
    case 'FETCH_TEAM_USERS_FAILURE':
      return {
        ...state,
        allTeams: {
          ...state.allTeams,
          data: {
            ...state.allTeams.data,
            [action.uuid]: {
              ...state.allTeams.data[action.uuid],
              fetching: false,
              error: action.error
            }
          }
        }
      };
    case 'FETCH_TEAM_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      };
    case 'FETCH_TEAM_SUCCESS':
      return {
        ...state,
        fetching: false,
        allTeams: {
          ...state.allTeams,
          data: {
            ...state.allTeams.data,
            [action.team.uuid]: {
              ...state.allTeams.data[action.team.uuid],
              ...camelize(action.team)
            }
          }
        },
        error: null
      };
    case 'FETCH_TEAM_FAILURE':
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case 'CREATE_TEAM_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      };
    case 'CREATE_TEAM_SUCCESS': {
      const newTeam = {
        ...state,
        fetching: false,
        allTeams: {
          ...state.allTeams,
          data: {
            ...state.allTeams.data,
            [action.team.uuid]: {
              ...state.allTeams.data[action.team.uuid],
              ...camelize(action.team),
              users: {}
            }
          }
        },
        error: null
      };
      if (action.isManager) {
        newTeam.userTeams = {
          ...state.userTeams,
          data: {
            ...state.userTeams.data,
            [action.team.uuid]: {
              ...state.userTeams.data[action.team.uuid],
              ...camelize(action.team)
            }
          }
        };
      }
      return newTeam;
    }
    case 'CREATE_TEAM_FAILURE':
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case 'UPDATE_TEAM_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      };
    case 'UPDATE_TEAM_SUCCESS': {
      const updatedTeam = {
        ...state,
        fetching: false,
        allTeams: {
          ...state.allTeams,
          data: {
            ...state.allTeams.data,
            [action.team.uuid]: {
              ...state.allTeams.data[action.team.uuid],
              ...camelize(action.team)
            }
          }
        },
        error: null
      };
      if (action.isManager) {
        updatedTeam.userTeams = {
          ...state.userTeams,
          data: {
            ...state.userTeams.data,
            [action.team.uuid]: {
              ...state.userTeams.data[action.team.uuid],
              ...camelize(action.team)
            }
          }
        };
      }
      return updatedTeam;
    }
    case 'UPDATE_TEAM_FAILURE':
      return {
        ...state,
        fetching: true,
        error: null
      };
    case 'DELETE_TEAM_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      };
    case 'DELETE_TEAM_SUCCESS': {
      const remainingData = state;
      delete remainingData.allTeams.data[action.teamId];
      if (remainingData.userTeams.data[action.teamId]) {
        delete remainingData.userTeams.data[action.teamId];
      }
      return {
        ...remainingData,
        fetching: false,
        error: null
      };
    }
    case 'DELETE_TEAM_FAILURE':
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case 'ADD_TEAM_USER_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      };
    case 'ADD_TEAM_USER_SUCCESS':
      return {
        ...state,
        fetching: false,
        allTeams: {
          ...state.allTeams,
          data: {
            ...state.allTeams.data,
            [action.uuid]: {
              ...state.allTeams.data[action.uuid],
              fetching: false,
              error: null,
              users: {
                ...state.allTeams.data[action.uuid].users,
                data: {
                  ...state.allTeams.data[action.uuid].users.data,
                  ...keyBy(camelize(action.team), 'uuid')
                }
              }
            }
          }
        },
        error: null
      };
    case 'ADD_TEAM_USER_FAILURE':
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case 'REMOVE_TEAM_USER_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      };
    case 'REMOVE_TEAM_USER_SUCCESS': {
      const remainingData = state;
      delete remainingData.allTeams.data[action.teamId].users.data[
        action.userId
      ];
      return {
        ...remainingData,
        fetching: false,
        error: null
      };
    }
    case 'REMOVE_TEAM_USER_FAILURE':
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default teams;
