import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import stylePropType from 'react-style-proptype';
import { twMerge } from 'tailwind-merge';

import {
  gainsboro,
  ghost,
  linkBlue,
  primaryBlack,
  silver
} from 'helpers/colors';

import Tokens from 'styles/tokens';

const AvomaTextArea = props => {
  const {
    placeholder,
    label,
    name,
    type,
    rows,
    value,
    userStyles,
    optional,
    containerStyles,
    maxlength,
    helperComponent,
    labelStyle,
    disabled,
    noAsterisk = false,
    textareaClassName,
    noOptionalText = false,
    ...rest
  } = props;
  const newVal = value !== null ? value : '';
  return (
    <div className='field' style={{ ...styles.container, ...containerStyles }}>
      {label && (
        <label htmlFor={name} className='textfield-label' style={labelStyle}>
          {label}
          {!optional && (
            <span style={{ color: Tokens.colors.avomaRed }}>
              {!noAsterisk && '*'}
            </span>
          )}
          {optional && !noOptionalText && (
            <span style={styles.optional}>(optional)</span>
          )}
        </label>
      )}
      {helperComponent}
      <textarea
        disabled={disabled}
        type={type}
        style={{ ...styles.textarea, ...userStyles }}
        placeholder={placeholder}
        className={twMerge(
          'avoma-input avoma-textarea textarea-avoma',
          textareaClassName
        )}
        rows={rows}
        name={name}
        value={newVal}
        maxLength={maxlength}
        {...rest}
      />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  textarea: {
    boxSizing: 'border-box',
    padding: `${Tokens.spacing.one} ${Tokens.spacing.onehalf}`,
    color: primaryBlack,
    backgroundColor: ghost,
    ...Tokens.type.bodyS,
    letterSpacing: '0.3px',
    borderRadius: '3px',
    border: `1px solid ${gainsboro}`,
    ':focus': {
      outline: 'none',
      border: `1px solid ${linkBlue}`
    },
    ':hover': {
      border: `1px solid ${Tokens.colors.silverLight}`
    },
    ':disabled': {
      cursor: 'not-allowed',
      opacity: '0.4'
    },
    resize: 'none'
  },
  optional: {
    marginLeft: Tokens.spacing.half,
    color: silver,
    fontWeight: 'normal'
  }
};

AvomaTextArea.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  userStyles: PropTypes.objectOf(PropTypes.string),
  containerStyles: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string,
  optional: PropTypes.bool,
  rows: PropTypes.number,
  maxlength: PropTypes.number,
  helperComponent: PropTypes.node,
  labelStyle: stylePropType,
  disabled: PropTypes.bool,
  noAsterisk: PropTypes.bool,
  textareaClassName: PropTypes.string,
  noOptionalText: PropTypes.bool
};

AvomaTextArea.defaultProps = {
  placeholder: null,
  label: '',
  name: '',
  containerStyles: {},
  userStyles: {},
  value: null,
  type: 'text',
  optional: false,
  rows: 3,
  maxlength: 1000,
  helperComponent: false,
  disabled: false
};

export default Radium(AvomaTextArea);
