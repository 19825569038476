import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import omit from 'lodash/omit';
import reject from 'lodash/reject';

import { HighlightActions } from 'actions/actionTypes';

const initialState = {
  data: {}, // keyed by meeting id?
  fetching: false,
  creating: false,
  error: null,
  highlightCategoryOverrides: [],
  highlightCategoriesForUser: [],
  highlightCategoriesUserMap: {},
  highlightRecordings: {},
  fetchingCategoryOverrides: false,
  shares: {
    loading: false,
    error: false,
    data: null
  },
  shareHighlight: {
    loading: false,
    error: false
  },
  unshareHighlight: {
    loading: false,
    error: false
  },
  updateHighlightShares: {
    loading: false,
    error: false
  },
  updateHighlight: {
    loading: false,
    error: false
  },
  deleteHighlight: {
    loading: false
  },
  downloadHighlight: {
    loading: false,
    error: false
  },
  addHighlightToPlaylist: {
    loading: false,
    error: false
  },
  removeHighlightFromPlaylist: {
    loading: false,
    error: false
  },
  orderedBy: 'created',
  highlightSummarization: {}
};

const arrToMap = highlightArr => {
  const highlightMap = {};
  highlightArr.forEach(hh => {
    highlightMap[hh.uuid] = hh;
  });
  return highlightMap;
};

const creatingHighlight = (state, action) => {
  switch (action.type) {
    case HighlightActions.CREATE_HIGHLIGHT_REQUEST:
      return { ...state, creating: true, error: null };
    case HighlightActions.CREATE_HIGHLIGHT_SUCCESS: {
      const { data } = state;
      const highlightList = [...(data[action.newHighlight.meeting] || [])];
      highlightList.push(action.newHighlight);
      return {
        ...state,
        data: {
          ...state.data,
          [action.newHighlight.meeting]: highlightList
        },
        creating: false
      };
    }
    case HighlightActions.CREATE_HIGHLIGHT_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.error
      };
    default:
      return state;
  }
};

const updatingHighlight = (state, action) => {
  switch (action.type) {
    case HighlightActions.UPDATE_HIGHLIGHT_REQUEST:
      return {
        ...state,
        updatingHighlight: {
          loading: true
        }
      };
    case HighlightActions.UPDATE_HIGHLIGHT_PRIVACY_REQUEST:
      return {
        ...state,
        updatingHighlightPrivacy: {
          loading: true
        }
      };
    case HighlightActions.UPDATE_HIGHLIGHT_SUCCESS: {
      const { meetingUuid, highlightUuid } = action;
      const meetingHighlights = state.data[meetingUuid];
      const updatedHighlights = meetingHighlights.map(highlight => {
        if (highlight.uuid === highlightUuid) {
          return {
            ...highlight,
            ...action.response
          };
        }
        return highlight;
      });
      return {
        ...state,
        data: {
          ...state.data,
          [meetingUuid]: updatedHighlights
        },
        updatingHighlight: {
          loading: false
        }
      };
    }
    case HighlightActions.UPDATE_HIGHLIGHT_PRIVACY_SUCCESS: {
      const { meetingUuid, highlightUuid, privacy, privacySettings } = action;
      const meetingHighlights = state.data[meetingUuid];
      const updatedHighlights = meetingHighlights.map(highlight => {
        if (highlight.uuid === highlightUuid) {
          return {
            ...highlight,
            privacy,
            privacySettings: { ...privacySettings }
          };
        }
        return highlight;
      });
      return {
        ...state,
        data: {
          ...state.data,
          [meetingUuid]: updatedHighlights
        },
        updatingHighlightPrivacy: {
          loading: false
        }
      };
    }
    case HighlightActions.UPDATE_HIGHLIGHT_FAILURE:
      return {
        ...state,
        updatingHighlight: {
          loading: false,
          error: true
        }
      };
    case HighlightActions.UPDATE_HIGHLIGHT_PRIVACY_FAILURE:
      return {
        ...state,
        updatingHighlightPrivacy: {
          loading: false,
          error: true
        }
      };
    case HighlightActions.UPDATE_HIGHLIGHTS_SUCCESS: {
      const { meetingUuid, highlightUuids } = action;
      const meetingHighlights = state.data[meetingUuid];
      const updatedHighlights = meetingHighlights.map(highlight => {
        if (highlightUuids.includes(highlight.uuid)) {
          return {
            ...highlight,
            ...action.response
          };
        }
        return highlight;
      });
      return {
        ...state,
        data: {
          ...state.data,
          [meetingUuid]: updatedHighlights
        },
        updatingHighlight: {
          loading: false
        }
      };
    }
    case HighlightActions.ADD_HIGHLIGHT_TO_PLAYLIST_REQUEST:
      return {
        ...state,
        addHighlightToPlaylist: {
          loading: true
        }
      };
    case HighlightActions.ADD_HIGHLIGHT_TO_PLAYLIST_SUCCESS: {
      const { meetingUuid, highlightUuid, playlist, playlistContentUuid } =
        action;

      if (!playlist?.uuid) {
        return;
      }

      const playlistObj = {
        uuid: playlist.uuid,
        name: playlist.name,
        privacy: playlist.privacy,
        playlistContentUuid
      };

      const meetingHighlights = state.data[meetingUuid];
      const updatedHighlights = meetingHighlights.map(highlight => {
        if (highlight.uuid === highlightUuid) {
          return {
            ...highlight,
            playlists: [...highlight.playlists, playlistObj]
          };
        }
        return highlight;
      });

      return {
        ...state,
        data: {
          ...state.data,
          [meetingUuid]: updatedHighlights
        }
      };
    }
    case HighlightActions.ADD_HIGHLIGHT_TO_PLAYLIST_FAILURE:
      return {
        ...state,
        addHighlightToPlaylist: {
          loading: false,
          error: true
        }
      };
    case HighlightActions.REMOVE_HIGHLIGHT_FROM_PLAYLIST_REQUEST:
      return {
        ...state,
        removeHighlightFromPlaylist: {
          loading: true
        }
      };
    case HighlightActions.REMOVE_HIGHLIGHT_FROM_PLAYLIST_SUCCESS: {
      const { meetingUuid, highlightUuid, playlistUuid } = action;

      const meetingHighlights = state.data[meetingUuid];

      const highlightIndex = meetingHighlights.findIndex(
        highlight => highlight.uuid === highlightUuid
      );

      const updatedPlaylists = meetingHighlights[
        highlightIndex
      ].playlists.filter(playlist => playlist.uuid !== playlistUuid);

      meetingHighlights[highlightIndex].playlists = updatedPlaylists;

      return {
        ...state,
        data: {
          ...state.data,
          [meetingUuid]: meetingHighlights
        }
      };
    }
    case HighlightActions.REMOVE_HIGHLIGHT_FROM_PLAYLIST_FAILURE:
      return {
        ...state,
        removeHighlightFromPlaylist: {
          loading: false,
          error: true
        }
      };

    default:
      return state;
  }
};

const deletingHighlight = (state, action) => {
  switch (action.type) {
    case HighlightActions.DELETE_HIGHLIGHT_REQUEST:
      return {
        ...state,
        deleteHighlight: {
          ...state.deleteHighlight,
          loading: true
        }
      };
    case HighlightActions.DELETE_HIGHLIGHT_SUCCESS: {
      const { data } = state;
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: reject(data[action.meetingUuid], {
            uuid: action.highlightUuid
          })
        },
        deleteHighlight: {
          ...state.deleteHighlight,
          loading: false
        }
      };
    }
    case HighlightActions.DELETE_HIGHLIGHT_FAILURE:
      return {
        ...state,
        deleteHighlight: {
          ...state.deleteHighlight,
          loading: false
        },
        error: action.error
      };
    default:
      return state;
  }
};

const fetchHighlights = (state, action) => {
  switch (action.type) {
    case HighlightActions.FETCH_HIGHLIGHTS_REQUEST:
      return {
        ...state,
        orderedBy: action.orderedBy,
        fetching: true
      };
    case HighlightActions.FETCH_HIGHLIGHTS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: action.highlights
        },
        fetching: false
      };
    }
    case HighlightActions.FETCH_HIGHLIGHTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    default:
      return state;
  }
};

const fetchHighlightRecording = (state, action) => {
  switch (action.type) {
    case HighlightActions.FETCH_HIGHLIGHT_RECORDING_REQUEST:
      return {
        ...state,
        highlightRecordings: {
          ...(state.highlightRecordings || {}),
          [action.uuid]: {
            loading: true
          }
        }
      };
    case HighlightActions.FETCH_HIGHLIGHT_RECORDING_SUCCESS:
      return {
        ...state,
        highlightRecordings: {
          ...(state.highlightRecordings || {}),
          [action.highlightRecording.highlight]: {
            ...action.highlightRecording,
            loading: false
          }
        }
      };
    case HighlightActions.FETCH_HIGHLIGHT_RECORDING_FAILURE:
      return {
        ...state,
        [action.uuid]: {
          ...(state.highlightRecordings || {})[action.uuid],
          loading: true
        }
      };
    default:
      return state;
  }
};

const highlights = (state = initialState, action) => {
  switch (action.type) {
    // fetch members
    case HighlightActions.CREATE_HIGHLIGHT_REQUEST:
    case HighlightActions.CREATE_HIGHLIGHT_SUCCESS:
    case HighlightActions.CREATE_HIGHLIGHT_FAILURE:
      return creatingHighlight(state, action);
    case HighlightActions.UPDATE_HIGHLIGHT_REQUEST:
    case HighlightActions.UPDATE_HIGHLIGHT_SUCCESS:
    case HighlightActions.UPDATE_HIGHLIGHT_FAILURE:
    case HighlightActions.UPDATE_HIGHLIGHT_PRIVACY_REQUEST:
    case HighlightActions.UPDATE_HIGHLIGHT_PRIVACY_SUCCESS:
    case HighlightActions.UPDATE_HIGHLIGHT_PRIVACY_FAILURE:
    case HighlightActions.UPDATE_HIGHLIGHTS_SUCCESS:
    case HighlightActions.ADD_HIGHLIGHT_TO_PLAYLIST_REQUEST:
    case HighlightActions.ADD_HIGHLIGHT_TO_PLAYLIST_SUCCESS:
    case HighlightActions.ADD_HIGHLIGHT_TO_PLAYLIST_FAILURE:
    case HighlightActions.REMOVE_HIGHLIGHT_FROM_PLAYLIST_REQUEST:
    case HighlightActions.REMOVE_HIGHLIGHT_FROM_PLAYLIST_SUCCESS:
    case HighlightActions.REMOVE_HIGHLIGHT_FROM_PLAYLIST_FAILURE:
      return updatingHighlight(state, action);
    // fetch members
    case HighlightActions.DELETE_HIGHLIGHT_REQUEST:
    case HighlightActions.DELETE_HIGHLIGHT_SUCCESS:
    case HighlightActions.DELETE_HIGHLIGHT_FAILURE:
      return deletingHighlight(state, action);
    case HighlightActions.FETCH_HIGHLIGHTS_REQUEST:
    case HighlightActions.FETCH_HIGHLIGHTS_SUCCESS:
    case HighlightActions.FETCH_HIGHLIGHTS_FAILURE:
      return fetchHighlights(state, action);
    case HighlightActions.FETCH_HIGHLIGHT_CATEGORY_OVERRIDES:
      return {
        ...state,
        fetchingCategoryOverrides: true
      };
    case HighlightActions.FETCH_HIGHLIGHT_CATEGORY_OVERRIDES_SUCCESS: {
      const highlightCatForUser = map(
        action.highlightCategoryOverrides,
        highlightCategoryOverride => ({
          ...highlightCategoryOverride.category,
          ...omit(highlightCategoryOverride, ['category']),
          visible: highlightCategoryOverride.visible
        })
      );

      return {
        ...state,
        highlightCategoryOverrides: action.highlightCategoryOverrides,
        highlightCategoriesForUser: highlightCatForUser,
        highlightCategoriesUserMap: arrToMap(highlightCatForUser),
        fetchingCategoryOverrides: false
      };
    }
    case HighlightActions.FETCH_HIGHLIGHT_CATEGORY_OVERRIDES_FAILURE:
      return {
        ...state,
        fetchingCategoryOverrides: false
      };
    case HighlightActions.HIDE_HIGHLIGHT_CATEGORY_REQUEST:
    case HighlightActions.HIDE_HIGHLIGHT_CATEGORY_SUCCESS:
    case HighlightActions.SHOW_HIGHLIGHT_CATEGORY_FAILURE: {
      const updatedOverrides = map(
        state.highlightCategoryOverrides,
        highlightCategoryOverride => {
          if (highlightCategoryOverride.uuid === action.uuid) {
            return { ...highlightCategoryOverride, visible: false };
          }
          return highlightCategoryOverride;
        }
      );
      const highlightCatForUser = map(
        updatedOverrides,
        highlightCategoryOverride => ({
          ...highlightCategoryOverride.category,
          visible: highlightCategoryOverride.visible
        })
      );
      return {
        ...state,
        highlightCategoryOverrides: updatedOverrides,
        highlightCategoriesForUser: highlightCatForUser,
        highlightCategoriesUserMap: arrToMap(highlightCatForUser)
      };
    }
    case HighlightActions.HIDE_HIGHLIGHT_CATEGORY_FAILURE:
    case HighlightActions.SHOW_HIGHLIGHT_CATEGORY_REQUEST:
    case HighlightActions.SHOW_HIGHLIGHT_CATEGORY_SUCCESS: {
      const updatedOverrides = map(
        state.highlightCategoryOverrides,
        highlightCategoryOverride => {
          if (highlightCategoryOverride.uuid === action.uuid) {
            return { ...highlightCategoryOverride, visible: true };
          }
          return highlightCategoryOverride;
        }
      );
      const highlightCatForUser = map(
        updatedOverrides,
        highlightCategoryOverride => ({
          ...highlightCategoryOverride.category,
          visible: highlightCategoryOverride.visible
        })
      );
      return {
        ...state,
        highlightCategoryOverrides: updatedOverrides,
        highlightCategoriesForUser: highlightCatForUser,
        highlightCategoriesUserMap: arrToMap(highlightCatForUser)
      };
    }
    case HighlightActions.FETCH_HIGHLIGHT_RECORDING_REQUEST:
    case HighlightActions.FETCH_HIGHLIGHT_RECORDING_SUCCESS:
    case HighlightActions.FETCH_HIGHLIGHT_RECORDING_FAILURE:
      return fetchHighlightRecording(state, action);

    case HighlightActions.CREATE_HIGHLIGHT_SHARES_REQUEST:
      return {
        ...state,
        shares: {
          ...state.shares,
          loading: true
        }
      };

    case HighlightActions.CREATE_HIGHLIGHT_SHARES_SUCCESS: {
      const { response, step, shareMethod, highlightUuids } = action;
      return {
        ...state,
        shares: {
          ...state.shares,
          loading: false,
          data: response,
          step,
          shareMethod,
          highlightUuids
        }
      };
    }

    case HighlightActions.CREATE_HIGHLIGHT_SHARES_ERROR:
      return {
        ...state,
        shares: {
          ...state.shares,
          loading: false,
          error: true
        }
      };

    case HighlightActions.REMOVE_HIGHLIGHT_SHARES:
      return {
        ...state,
        shares: {
          loading: false,
          error: true,
          data: null
        },
        shareHighlight: {
          loading: false,
          error: false
        }
      };

    case HighlightActions.SHARE_HIGHLIGHT_REQUEST: {
      return {
        ...state,
        shareHighlight: {
          loading: true
        }
      };
    }

    case HighlightActions.SHARE_HIGHLIGHT_SUCCESS: {
      const { shares, meetingUuid, highlightUuid, highlightObj } = action;

      const meetingHighlights = state.data[meetingUuid];

      const updatedHighlights =
        meetingHighlights?.map(highlight => {
          if (highlight.uuid === highlightUuid) {
            return {
              ...highlight,
              share: shares
            };
          }
          return highlight;
        }) || [];

      if (isEmpty(updatedHighlights)) {
        updatedHighlights.push(highlightObj);
      }

      return {
        ...state,
        data: {
          ...state.data,
          [meetingUuid]: updatedHighlights
        },
        shares: {
          ...state.shares,
          data: shares
        },
        shareHighlight: {
          loading: false
        }
      };
    }

    case HighlightActions.SHARE_HIGHLIGHT_ERROR: {
      return {
        ...state,
        shareHighlight: {
          loading: false,
          error: true
        }
      };
    }

    case HighlightActions.UNSHARE_HIGHLIGHT_REQUEST:
      return {
        ...state,
        unshareHighlight: {
          loading: true
        }
      };

    case HighlightActions.UNSHARE_HIGHLIGHT_SUCCESS: {
      const { shares, meetingUuid, highlightUuid, highlightObj } = action;

      const meetingHighlights = state.data[meetingUuid];
      const updatedHighlights =
        meetingHighlights?.map(highlight => {
          if (highlight.uuid === highlightUuid) {
            return {
              ...highlight,
              share: shares
            };
          }
          return highlight;
        }) || [];

      if (isEmpty(updatedHighlights)) {
        updatedHighlights.push(highlightObj);
      }

      return {
        ...state,
        data: {
          ...state.data,
          [meetingUuid]: updatedHighlights
        },
        shares: {
          ...state.shares,
          data: shares
        },
        unshareHighlight: {
          loading: false
        }
      };
    }
    case HighlightActions.UNSHARE_HIGHLIGHT_ERROR:
      return {
        ...state,
        unshareHighlight: {
          loading: false,
          error: true
        }
      };

    case HighlightActions.UPDATE_HIGHLIGHT_SHARES_REQUEST:
      return {
        ...state,
        updateHighlightShares: {
          loading: true
        }
      };

    case HighlightActions.UPDATE_HIGHLIGHT_SHARES_SUCCESS: {
      const { response } = action;
      return {
        ...state,
        updateHighlightShares: {
          loading: false
        },
        shares: {
          ...state.shares,
          data: {
            ...state.shares.data,
            ...response
          }
        }
      };
    }

    case HighlightActions.UPDATE_HIGHLIGHT_SHARES_ERROR:
      return {
        ...state,
        updateHighlightShares: {
          loading: false,
          error: true
        }
      };
    case HighlightActions.DOWNLOAD_HIGHLIGHT_REQUEST:
      return {
        ...state,
        downloadHighlight: {
          loading: true,
          error: false
        }
      };
    case HighlightActions.DOWNLOAD_HIGHLIGHT_SUCCESS: {
      return {
        ...state,
        downloadHighlight: {
          loading: false,
          error: false
        }
      };
    }
    case HighlightActions.DOWNLOAD_HIGHLIGHT_FAILURE:
      return {
        ...state,
        downloadHighlight: {
          loading: false,
          error: true
        }
      };
    case HighlightActions.SET_HIGHLIGHT_SUMMARIZATION:
      return {
        ...state,
        highlightSummarization: action.response
      };
    case HighlightActions.EMPTY_HIGHLIGHT_SUMMARIZATION:
      return {
        ...state,
        highlightSummarization: {}
      };
    default:
      return state;
  }
};

export default highlights;
