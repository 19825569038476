import { ReactComponent as TickIcon } from 'images/tick.svg';
import { ReactComponent as WarningYellowIcon } from 'images/warning_yellow.svg';
import { L10nSettings } from 'helpers/l10n/L10nSettings';
import L10n from 'helpers/L10n';

export const cloudRecordingPoints = [
  {
    icon: TickIcon,
    info: (
      <span className='flex flex-col'>
        <span>
          {L10nSettings.recordingAndConferencing.botVsCloudModal.recordedBy}{' '}
          <strong>
            {
              L10nSettings.recordingAndConferencing.botVsCloudModal
                .cloudRecordingPoints.confAppCloud
            }
          </strong>
        </span>

        <span className='text-xs text-silver'>
          {
            L10nSettings.recordingAndConferencing.botVsCloudModal
              .cloudRecordingPoints.confAppCloudSubText
          }
        </span>
      </span>
    )
  },
  {
    icon: TickIcon,
    info: (
      <span>
        {L10nSettings.recordingAndConferencing.botVsCloudModal.no}{' '}
        {L10nSettings.recordingAndConferencing.botVsCloudModal.thirdPartyBot}
      </span>
    )
  },
  {
    icon: TickIcon,
    info: (
      <span>
        {
          L10nSettings.recordingAndConferencing.botVsCloudModal
            .recordingAnnouncement
        }{' '}
        {
          L10nSettings.recordingAndConferencing.botVsCloudModal
            .cloudRecordingPoints.forEachParticipant
        }
      </span>
    )
  },
  {
    icon: WarningYellowIcon,
    info: (
      <span className='flex flex-col'>
        {L10nSettings.recordingAndConferencing.botVsCloudModal.recordsOnlyWhen}{' '}
        <strong>{L10nSettings.recordingAndConferencing.host}</strong>
        <span className='text-xs text-silver'>
          <strong>{L10n.onboarding.avomaAssistantBot}</strong>{' '}
          {
            L10nSettings.recordingAndConferencing.botVsCloudModal
              .cloudRecordingPoints.willRecord
          }
        </span>
      </span>
    )
  },
  {
    icon: WarningYellowIcon,
    info: <span>{L10n.onboarding.zoomRecordingCon2}</span>
  }
];

export const avomaAssistantBotPoints = [
  {
    id: 'Avoma-Bot',
    icon: TickIcon,
    info: (
      <span>
        {L10nSettings.recordingAndConferencing.botVsCloudModal.recordedBy}{' '}
        <strong>
          {
            L10nSettings.recordingAndConferencing.botVsCloudModal.avomaBotPoints
              .avomaBot
          }
        </strong>
      </span>
    )
  },
  {
    id: 'Third-Party-Bot',
    icon: TickIcon,
    info: (
      <span>
        {L10nSettings.recordingAndConferencing.botVsCloudModal.thirdPartyBot}
      </span>
    )
  },
  {
    id: 'Each-Participant',
    icon: WarningYellowIcon,
    info: (
      <span className='flex flex-col'>
        {
          L10nSettings.recordingAndConferencing.botVsCloudModal
            .recordingAnnouncement
        }{' '}
        {
          L10nSettings.recordingAndConferencing.botVsCloudModal.avomaBotPoints
            .whenBotJoins
        }
        <span className='text-xs text-silver'>
          {
            L10nSettings.recordingAndConferencing.botVsCloudModal.avomaBotPoints
              .whenBotJoinsSubText
          }
        </span>
      </span>
    )
  },
  {
    icon: TickIcon,
    info: (
      <span>
        {L10nSettings.recordingAndConferencing.botVsCloudModal.recordsWhen}{' '}
        <strong>{L10nSettings.recordingAndConferencing.host}</strong>{' '}
        {
          L10nSettings.recordingAndConferencing.botVsCloudModal.avomaBotPoints
            .andA
        }{' '}
        <strong>{L10nSettings.recordingAndConferencing.participant}</strong>{' '}
        {L10nSettings.recordingAndConferencing.botVsCloudModal.both}
      </span>
    )
  },
  {
    icon: TickIcon,
    info: <span>{L10n.onboarding.avomaBotRecordingPro2}</span>
  }
];

export const smartCategoryTabs = {
  default: 'default',
  custom: 'custom',
  fillerWords: 'filler-words'
};

export const fillerWordsLabel = 'Filler Words';
