import { createSlice, createAsyncThunk } from '@reduxjs/toolkit/';
import camelize from 'camelize';
import { useSelector } from 'react-redux';

import env from 'helpers/env';
import { get, post, put, deleteMethod } from 'helpers/http2';
import { useActions } from 'hooks/useActions';

const BASE_SLACK_URL = `${env.avomaApiUrl}/api/v1/slack/`;

const fetchSlackAlerts = createAsyncThunk(
  'slack/fetchSlackAlerts',
  async (queryParams, thunkApi) => {
    const url = `${BASE_SLACK_URL}alert/?page_size=15${queryParams}`;
    const response = await get({
      url,
      thunkApi
    });
    return camelize(response);
  }
);

const fetchNextSlackAlerts = createAsyncThunk(
  'slack/fetchNextSlackAlerts',
  async (_queryParams, thunkApi) => {
    const { getState } = thunkApi;
    const { next = '' } = getState().slackData.alerts.data;
    const response = await get({ url: next, thunkApi });
    return camelize(response);
  }
);

const updateSlackAlert = createAsyncThunk(
  'slack/updateSlackAlert',
  async (params, thunkApi) => {
    const {
      slackChannel: { uuid },
      contentObject: { objectType, objectTypeUuid, alertUuid }
    } = params;
    const url = `${BASE_SLACK_URL}alert/${alertUuid}/`;
    return put({
      url,
      body: {
        content_object_type: objectType,
        content_object_uuid: objectTypeUuid,
        channel_uuid: uuid
      },
      thunkApi,
      options: {
        ignoreErrorNotification: true
      }
    });
  }
);

const addNewSlackAlert = createAsyncThunk(
  'slack/addNewSlackAlert',
  async (params, thunkApi) => {
    const { uuid, contentObjectType, contentObjectUuid } = params;
    const url = `${BASE_SLACK_URL}alert/`;
    return post({
      url,
      body: {
        content_object_type: contentObjectType,
        content_object_uuid: contentObjectUuid,
        channel_uuid: uuid
      },
      thunkApi,
      options: {
        ignoreErrorNotification: true
      }
    });
  }
);

const deleteSlackAlert = createAsyncThunk(
  'slack/deleteSlackAlert',
  async (alertUuid, thunkApi) => {
    const url = `${BASE_SLACK_URL}alert/${alertUuid}/`;
    await deleteMethod({
      url,
      thunkApi
    });
    return alertUuid;
  }
);

const slackAlerts = createSlice({
  name: 'alerts',
  initialState: {
    data: {},
    fetching: false,
    deleting: false,
    error: false,
    fetchingNext: false
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSlackAlerts.pending, state => {
      state.data = {};
      state.error = false;
      state.fetching = true;
    });

    builder.addCase(fetchSlackAlerts.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.fetching = false;
    });

    builder.addCase(fetchSlackAlerts.rejected, state => {
      state.error = true;
      state.fetching = false;
    });

    builder.addCase(fetchNextSlackAlerts.pending, state => {
      state.error = false;
      state.fetchingNext = true;
    });

    builder.addCase(fetchNextSlackAlerts.fulfilled, (state, action) => {
      state.data = {
        ...action.payload,
        results: [...state.data.results, ...action.payload.results]
      };
      state.error = false;
      state.fetchingNext = false;
    });

    builder.addCase(fetchNextSlackAlerts.rejected, state => {
      state.error = true;
      state.fetchingNext = false;
    });

    builder.addCase(updateSlackAlert.fulfilled, (state, action) => {
      const params = action.meta.arg;
      const currentAlert = state.data.results.find(
        ({ uuid }) => params.contentObject.alertUuid === uuid
      );
      currentAlert.channel = params.slackChannel;
      currentAlert.contentObject = {
        objectType: params.contentObject.objectType,
        uuid: params.contentObject.objectTypeUuid,
        name: params.contentObject.objectName
      };
      state.error = false;
    });

    builder.addCase(addNewSlackAlert.fulfilled, (state, action) => {
      state.data?.results?.unshift(camelize(action.payload));
    });

    builder.addCase(deleteSlackAlert.pending, state => {
      state.deleting = true;
    });

    builder.addCase(deleteSlackAlert.fulfilled, (state, action) => {
      state.data.results = state.data.results.filter(
        item => item.uuid !== action.payload
      );
      state.deleting = false;
    });

    builder.addCase(deleteSlackAlert.rejected, state => {
      state.deleting = false;
    });
  }
});

const useSlackAlerts = () => {
  const { fetching, fetchingNext, deleting, data, error } = useSelector(
    state => state.slackData.alerts
  );

  const allActions = useActions([
    fetchSlackAlerts,
    fetchNextSlackAlerts,
    updateSlackAlert,
    addNewSlackAlert,
    deleteSlackAlert
  ]);

  return {
    fetching,
    fetchingNext,
    data,
    error,
    deleting,
    fetchSlackAlerts: allActions[0],
    fetchNextSlackAlerts: allActions[1],
    updateSlackAlert: allActions[2],
    addNewSlackAlert: allActions[3],
    deleteSlackAlert: allActions[4]
  };
};

export {
  fetchSlackAlerts,
  fetchNextSlackAlerts,
  updateSlackAlert,
  addNewSlackAlert,
  useSlackAlerts
};

export default slackAlerts.reducer;
