import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';
import { twMerge } from 'tailwind-merge';

import { L10Common } from 'helpers/l10n/L10nCommon';

import * as Dialog from '@radix-ui/react-dialog';
import AvomaTooltip from './AvomaTooltip';

const AvomaDialog = ({
  trigger,
  title,
  children,
  footer,
  controlled,
  showCancel = true,
  open,
  setOpen,
  closeCleanupHandler,
  contentProps,
  contentClasses,
  childrenClasses,
  footerClasses,
  portalContainer = document.body
}) => {
  const [internalPopoverState, setInternalPopoverState] = useState(false);
  const popoverState = controlled ? open : internalPopoverState;
  const setPopoverState = controlled ? setOpen : setInternalPopoverState;

  return (
    <Dialog.Root
      open={popoverState}
      onOpenChange={() => {
        if (popoverState && typeof closeCleanupHandler === 'function') {
          closeCleanupHandler();
        }
        setPopoverState(!popoverState);
      }}
    >
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      {children && (
        <Dialog.Portal container={portalContainer}>
          <Dialog.Overlay className='fixed inset-0 z-[999] bg-black/30 data-[state=open]:animate-overlayShow' />
          <Dialog.Content
            className={twMerge(
              'fixed top-[50%] left-[50%] z-[1000] max-h-[85vh] w-[90vw] max-w-[640px] translate-x-[-50%] translate-y-[-50%] rounded bg-white shadow-md focus:outline-none data-[state=open]:animate-contentShow',
              contentClasses
            )}
            onCloseAutoFocus={e => e.preventDefault()}
            {...contentProps}
          >
            {title && (
              <section className='flex items-center justify-between rounded-t bg-snow py-4 px-6'>
                <Dialog.Title className='m-0 mr-4 max-w-full w-full truncate text-base font-bold text-black'>
                  {title}
                </Dialog.Title>
                <AvomaTooltip
                  tooltip={L10Common.close}
                  tooltipPosition='bottom-center'
                >
                  {showCancel && (
                    <Dialog.Close asChild>
                      <button
                        type='button'
                        className='flex cursor-pointer appearance-none items-center justify-center rounded bg-transparent p-2 hover:bg-gainsboro focus:outline-none'
                        aria-label={L10Common.close}
                      >
                        <CloseIcon className='h-4 w-4 text-gunmetal' />
                      </button>
                    </Dialog.Close>
                  )}
                </AvomaTooltip>
              </section>
            )}

            <div
              className={twMerge(
                'max-h-[calc(85vh-12rem)] overflow-y-auto px-8 pt-6 pb-8',
                childrenClasses
              )}
            >
              {children}
            </div>

            {footer && (
              <section
                className={twMerge(
                  'flex items-center bg-white px-8 py-5',
                  footerClasses
                )}
              >
                {footer}
              </section>
            )}
          </Dialog.Content>
        </Dialog.Portal>
      )}
    </Dialog.Root>
  );
};

AvomaDialog.propTypes = {
  trigger: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  controlled: PropTypes.bool,
  showCancel: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  closeCleanupHandler: PropTypes.func,
  contentProps: PropTypes.object,
  contentClasses: PropTypes.string,
  childrenClasses: PropTypes.string,
  footerClasses: PropTypes.string,
  portalContainer: PropTypes.element
};

export default AvomaDialog;
