import { OnboardingActions } from 'actions/actionTypes';

export const initialState = {
  onboardingFetched: false,
  onboardingShown: true,
  onboarded: false,
  numSteps: 4,
  currentStep: 0, // zero based index
  error: null,
  skippedCalSync: false,
  homeTiles: {
    calendar: false,
    recording: false,
    crm: false,
    keywords: false,
    templates: false,
    invites: false,
    completed: false
  },
  demoMeetingUuid: null,
  demoPlaylistUuid: null
};

/*
  IMPORTANT: this "onboading" redux key is persisted to localStorage. see reduxStore.js
*/
const onboarding = (state = initialState, action) => {
  let tiles = {};
  switch (action.type) {
    case 'SET_ONBOARDED': {
      if (action.homeTiles) {
        try {
          tiles = JSON.parse(action.homeTiles);
        } catch {
          tiles = {};
        }
      }
      return {
        ...state,
        onboarded: action.onboarded,
        homeTiles: tiles
      };
    }
    case 'SET_SKIP_CAL_SYNC': {
      return {
        ...state,
        skippedCalSync: action.skip
      };
    }
    case 'ONBOARDING_DONE': {
      return {
        ...state,
        onboarded: true
      };
    }
    case 'GO_TO_NEXT_ONBOARDING_STEP':
      if (state.currentStep === state.numSteps - 1) {
        return {
          ...state,
          onboarded: true,
          currentStep: state.numSteps,
          onboardingShown: true
        };
      }
      return {
        ...state,
        currentStep: action.currentStep + 1
      };
    case 'FETCH_ONBOARDING_STATE_SUCCESS':
      if (action.data.homeTiles) {
        try {
          tiles = JSON.parse(action.data.homeTiles);
        } catch {
          tiles = {};
        }
      }
      return {
        ...state,
        currentStep: action.data.currentStep,
        onboarded: action.data.onboarded,
        onboardingShown: action.data.onboardingShown,
        demoMeetingUuid: action.data.demoMeetingUuid,
        demoPlaylistUuid: action.data?.demoPlaylistUuid,
        homeTiles: tiles,
        onboardingFetched: true
      };
    case OnboardingActions.UPDATE_ONBOARDING_STATE:
      if (action.data.homeTiles) {
        tiles = JSON.parse(action.data.homeTiles);
      }
      return {
        ...state,
        ...action.data,
        homeTiles: tiles
      };
    case OnboardingActions.UPDATE_ONBOARDING_STATE_SUCCESS:
      if (action.data.homeTiles) {
        tiles = JSON.parse(action.data.homeTiles);
      }
      return {
        ...state,
        currentStep: action.data.currentStep,
        onboarded: action.data.onboarded,
        onboardingShown: action.data.onboardingShown,
        homeTiles: tiles
      };
    case OnboardingActions.UPDATE_ONBOARDING_STATE_FAILURE:
    case 'FETCH_ONBOARDING_STATE_FAILURE':
      return {
        ...state,
        error: action.error
      };
    case 'RESET_ONBOARDING':
      return {
        ...state,
        currentStep: 0,
        onboarded: false,
        onboardingShown: false
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};

export default onboarding;
