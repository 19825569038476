import { filterActions } from 'actions/actionTypes';

export const initialState = {};

const hookFilters = (state = initialState, { type, key, filters }) => {
  switch (type) {
    case filterActions.ADD_HOOK_FILTER:
      return {
        ...state,
        [key]: {
          ...filters
        }
      };
    case filterActions.UPDATE_HOOK_FILTER:
      return {
        ...state,
        [key]: {
          ...state[key],
          ...filters
        }
      };
    default:
      return state;
  }
};

export default hookFilters;
