export const DASHBOARD_NAME = {
  meeting: 1,
  conversation: 2,
  deal: 3,
  scoredConversations: 4,
  // scorecardsGiven: 5, // ! ID temporarily not used
  scorecardsRequests: 6,
  membersPerformance: 7,
  calls: 8,
  memberAnalytics: 11,
  scorecardAnalytics: 12,
  trackers: 10,
  company: 9
};

export const MY_VIEWS = 'myViews';
export const STANDARD_VIEWS = 'standardViews';
export const TEAMMATE_VIEWS = 'teammateViews';

export const SHARED_MEETINGS = 'Shared Meetings';
export const TEAM_MEETINGS = 'Team Meetings';
export const MY_MEETINGS = 'My Meetings';

export const SHARED_CALLS = 'Shared Calls';
export const TEAM_CALLS = 'Team Calls';
export const MY_CALLS = 'My Calls';
export const ALL_CALLS = 'All Calls';
export const OUTBOUND_CALLS = 'Outbound Calls';
export const INBOUND_CALLS = 'Inbound Calls';

export const SAVE_COPY = 'SAVE_COPY';
export const SAVE_AS_NEW = 'SAVE_AS_NEW';
export const CREATE_NEW = 'CREATE_NEW';
export const EDIT = 'EDIT';

export const FREQ_DAILY = '1';
export const FREQ_WEEKLY = '2';
export const FREQ_MONTHLY = '3';
export const FREQ_IMMEDIATE = '4';

export const alertsFrequencyChoices = {
  [FREQ_DAILY]: 'Daily',
  [FREQ_WEEKLY]: 'Weekly'
};

export const ORG_STANDARD_VIEWS = ['deal', 'company', 'trackers'];
