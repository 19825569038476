import camelize from 'camelize';
import _ from 'lodash';

import { CommentActions } from 'actions/actionTypes';

export const commentsOrderingOptions = [
  {
    value: 'created_at',
    label: 'Oldest comment first'
  },
  {
    value: '-created_at',
    label: 'Latest comment first'
  },
  {
    value: 'start_ts',
    label: 'Transcript Timeline'
  }
];

const sortingEvaluators = {
  startTs: a => a || 0,
  createdAt: a => (a && new Date(a)) || new Date()
};

const initialState = {
  data: {},
  orderedBy: 'created_at',
  recordingCommentTimestamp: null,
  fetching: false
};

const updateCommentByUuid = (state, meetingId, uuid, action) => {
  const meetingComments = [...state.data[meetingId]];
  for (let ii = 0; ii < meetingComments.length; ii += 1) {
    const comment = meetingComments[ii];
    if (comment.uuid === uuid) {
      action(meetingComments, ii);
      break;
    }
    if (comment.children) {
      let quit = false;
      for (let jj = 0; jj < comment.children.length; jj += 1) {
        const childComment = comment.children[jj];
        if (childComment.uuid === uuid) {
          action(comment.children, jj);
          quit = true;
          break;
        }
      }
      if (quit) {
        break;
      }
    }
  }
  return meetingComments;
};

const getUpdateCommentsState = (state, meetingId, commentList) => ({
  ...state,
  data: {
    ...state.data,
    [meetingId]: commentList
  }
});

const updateComment = (state, action) => {
  switch (action.type) {
    case CommentActions.UPDATE_COMMENT_REQUEST: {
      const meetingComments = updateCommentByUuid(
        state,
        action.meetingId,
        action.comment.uuid,
        (cl, ii) => {
          cl[ii].text = action.comment.text; // eslint-disable-line no-param-reassign
          cl[ii].privacy = action.comment.privacy;
          cl[ii].updating = true; // eslint-disable-line no-param-reassign
        }
      );
      return getUpdateCommentsState(state, action.meetingId, meetingComments);
    }
    case CommentActions.UPDATE_COMMENT_SUCCESS: {
      const meetingComments = updateCommentByUuid(
        state,
        action.meetingId,
        action.comment.uuid,
        (cl, ii) => {
          const newComment = {
            ...action.comment,
            privacy: action.comment.privacy,
            children: cl[ii].children.map(child => ({
              ...child,
              privacy: action.comment.privacy // Update privacy of all children based on parent
            })),
            updating: false
          };
          cl[ii] = newComment; // eslint-disable-line no-param-reassign
        }
      );
      return getUpdateCommentsState(state, action.meetingId, meetingComments);
    }
    case CommentActions.UPDATE_COMMENT_FAILURE:
    default:
      return state;
  }
};

const deleteComment = (state, action) => {
  switch (action.type) {
    case CommentActions.DELETE_COMMENT_REQUEST: {
      const meetingComments = updateCommentByUuid(
        state,
        action.meetingId,
        action.uuid,
        (commentList, ii) => {
          commentList[ii].deleting = true; // eslint-disable-line no-param-reassign
        }
      );
      return getUpdateCommentsState(state, action.meetingId, meetingComments);
    }
    case CommentActions.DELETE_COMMENT_SUCCESS: {
      const meetingComments = updateCommentByUuid(
        state,
        action.meetingId,
        action.uuid,
        (commentList, ii) => {
          commentList.splice(ii, 1);
        }
      );
      return getUpdateCommentsState(state, action.meetingId, meetingComments);
    }
    default:
      return state;
  }
};

const insertNewComment = (meetingComments, comment, order = 'created_at') => {
  // Insert a new comments by maintaining the existing order of the data.
  const desc = order.startsWith('-');
  const field = camelize(order.replace(/(^-+)/g, ''));
  const evaluate = sortingEvaluators[field];
  const val = evaluate(comment[field]);
  let matchingFunc;
  if (desc) {
    matchingFunc = m => evaluate(m[field]) < val;
  } else {
    matchingFunc = m => evaluate(m[field]) > val;
  }
  const index = _.findIndex(meetingComments, matchingFunc);
  if (index !== -1) {
    meetingComments.splice(index, 0, comment);
  } else {
    meetingComments.push(comment);
  }
};

const comments = (state = initialState, action) => {
  switch (action.type) {
    case CommentActions.NEW_RECORDING_COMMENT:
      return {
        ...state,
        recordingCommentTimestamp: action.timestamp
      };
    case CommentActions.CREATE_COMMENT_REQUEST: {
      return {
        ...state,
        creatingComment: {
          loading: true
        }
      };
    }
    case CommentActions.CREATE_COMMENT_SUCCESS: {
      const meetingComments = [...(state.data[action.meetingId] || [])];
      const createdIndex = _.findIndex(
        meetingComments,
        comment => comment.uuid === 'placeholder'
      );
      if (createdIndex >= 0) {
        meetingComments[createdIndex] = action.comment;
      } else {
        insertNewComment(meetingComments, action.comment, state.orderedBy);
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingId]: meetingComments
        },
        creatingComment: {
          loading: false
        }
      };
    }
    case CommentActions.REPLY_COMMENT_REQUEST: {
      return {
        ...state,
        creatingComment: {
          loading: true
        }
      };
    }
    case CommentActions.REPLY_COMMENT_SUCCESS: {
      const meetingComments = [...(state.data[action.meetingId] || [])];
      const parentComment = _.find(
        meetingComments,
        comment => comment.uuid === action.parentUuid
      );
      if (!parentComment.children) {
        parentComment.children = [];
      }
      const createdIndex = _.findIndex(
        parentComment.children,
        comment => comment.uuid === 'placeholder'
      );
      if (createdIndex >= 0) {
        parentComment.children[createdIndex] = action.comment;
      } else {
        parentComment.children.push(action.comment);
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingId]: meetingComments
        },
        creatingComment: {
          loading: false
        }
      };
    }
    case CommentActions.FETCH_COMMENT_REQUEST:
      return {
        ...state,
        orderedBy: action.orderedBy,
        fetching: true
      };
    case CommentActions.FETCH_COMMENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingId]: action.comments
        },
        fetching: false
      };
    case CommentActions.FETCH_COMMENT_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case CommentActions.UPDATE_COMMENT_REQUEST:
    case CommentActions.UPDATE_COMMENT_SUCCESS:
    case CommentActions.UPDATE_COMMENT_FAILURE:
      return updateComment(state, action);
    case CommentActions.DELETE_COMMENT_REQUEST:
    case CommentActions.DELETE_COMMENT_SUCCESS:
    case CommentActions.DELETE_COMMENT_FAILURE:
      return deleteComment(state, action);
    default:
      return state;
  }
};

export default comments;
