import { twMerge } from 'tailwind-merge';
import { IAutomationActionRulesAPI } from 'types/automation';

import AvomaSelect from 'components/Common/AvomaSelect';
import AutomationAssigneeClickupMembers from 'components/Tasks/Clickup/Members';
import AutomationAssigneeHubspotMembers from 'components/Tasks/Hubspot/Members';

import {
  EAutomationActionAssigneeOptions,
  EAutomationActionType
} from 'constants/automation';

interface IProps {
  action: IAutomationActionRulesAPI;
  clientValue: string[];
  handleValueChange: (value: string[]) => void;
  groupUid: EAutomationActionType;
  workspaceUuid?: string;
  isRequired?: boolean;
  showSelect?: boolean;
  selectTriggerClasses?: string;
}

const AutomationFormTaskAssignee = ({
  action,
  clientValue,
  handleValueChange,
  groupUid,
  workspaceUuid,
  isRequired,
  selectTriggerClasses,
  showSelect = true // For cases when we just want to show the member selector
}: IProps) => {
  const handleChange = (value: string[]) => {
    handleValueChange(value);
  };

  const showMemberSelector =
    clientValue &&
    ![
      EAutomationActionAssigneeOptions.MEETING_HOST,
      EAutomationActionAssigneeOptions.RESP_USER_FOR_NOTES_EXT
    ].includes(clientValue?.[0] as EAutomationActionAssigneeOptions);

  const renderMembers = () => {
    switch (groupUid) {
      case EAutomationActionType.CLICKUP_TASK:
        return (
          <AutomationAssigneeClickupMembers
            workspaceUuid={workspaceUuid as string}
            clientValue={clientValue}
            handleValueChange={handleValueChange}
          />
        );
      case EAutomationActionType.HUBSPOT_TASK:
        return (
          <AutomationAssigneeHubspotMembers
            clientValue={clientValue}
            handleValueChange={handleValueChange}
            action={action}
            triggerClasses={selectTriggerClasses}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className='flex w-full flex-col gap-2'>
      <p className='text-sm font-bold text-gunmetal'>
        {action.label}{' '}
        {isRequired && <span className='ml-1 text-avoma-red'>*</span>}
      </p>
      <div className='flex flex-col'>
        {showSelect && (
          <AvomaSelect
            items={action.optionChoices}
            triggerClassName={twMerge(
              'w-full bg-white',
              showMemberSelector ? 'rounded-b-none' : 'rounded'
            )}
            contentPosition='popper'
            onValueChange={handleChange}
            value={clientValue?.[0]}
          />
        )}
        {(showMemberSelector || !showSelect) && renderMembers()}
      </div>
    </div>
  );
};

export default AutomationFormTaskAssignee;
