import React from 'react';
import PropTypes from 'prop-types';
import SpinkitSpinner from 'react-spinkit';
import Tokens from 'styles/tokens';

const Spinner = ({ type, fadeIn, style, className }) => (
  <SpinkitSpinner
    data-testid='avoma-spinner'
    name='folding-cube'
    fadeIn={fadeIn}
    className={className}
    style={{
      color: Tokens.colors.gainsboro,
      transform: `${type === 'small' ? 'scale(0.4) ' : ''}rotate(45deg)`,
      ...style
    }}
  />
);

Spinner.propTypes = {
  type: PropTypes.oneOf(['default', 'small']),
  fadeIn: PropTypes.oneOf(['full', 'half', 'quarter', 'none']),
  style: PropTypes.object,
  className: PropTypes.string
};

Spinner.defaultProps = {
  type: 'default',
  fadeIn: 'none'
};

export default Spinner;
