import { findIndex } from 'lodash';
import { Path, Transforms, Node } from 'slate';

import { getParent } from '../../Queries/getParent';
import { isList } from '../Queries/isList';

/**
 * Move a list item next to its parent. The caller is responsible for checking if
 * the path in listItemPath is actually a list item
 */
export const moveListItemUp = (
  editor,
  listNode,
  listPath,
  listItemNode,
  listItemPath
) => {
  const listParentEntry = getParent(editor, listPath);
  if (!listParentEntry) return;
  let [listParentNode, listParentPath] = listParentEntry;
  if (isList(listParentNode)) {
    listParentNode = listNode;
    listParentPath = listPath;
  }
  if (!listParentNode.type) {
    // at the root
    return;
  }
  // if (listParentNode.type !== TYPE_LIST_ITEM) return;
  const newListItemPath = Path.next(listParentPath);
  // Move item one level up
  Transforms.moveNodes(editor, {
    at: listItemPath,
    to: newListItemPath
  });

  /**
   * Move the next siblings to a new list
   */
  const listItemIdx = listItemPath[listItemPath.length - 1];
  const siblingPath = [...listItemPath];
  const childListIndex = findIndex(listParentNode.children, nn => isList(nn));
  const newListPath = newListItemPath.concat(childListIndex);
  let siblingFound = false;
  let newSiblingIdx =
    listItemNode.children && listItemNode.children.length > 1
      ? listItemNode.children[1].children.length
      : 0;
  // let newSiblingIdx = 0;
  try {
    listNode.children.forEach((n, idx) => {
      if (listItemIdx < idx) {
        if (!siblingFound) {
          siblingFound = true;
          Transforms.insertNodes(
            editor,
            {
              type: listNode.type,
              children: []
            },
            { at: newListPath }
          );
        }
        siblingPath[siblingPath.length - 1] = listItemIdx;
        const newSiblingsPath = newListPath.concat(newSiblingIdx);
        newSiblingIdx += 1;
        Transforms.moveNodes(editor, {
          at: siblingPath,
          to: newSiblingsPath
        });
      }
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }

  let removed = 0;
  // Remove sublist if it was the first list item
  if (!listItemIdx) {
    Transforms.removeNodes(editor, {
      at: listPath
    });
    removed = -1;
  }
  if (listPath && listPath.length > 1) {
    listParentNode = Node.get(editor, listPath.slice(0, -1));
  }
  const fragment = [];
  for (
    let ii = listParentNode.children.length - 1;
    ii > listPath[listPath.length - 1] + removed;
    ii -= 1
  ) {
    const sourcePath = [...listPath.slice(0, -1), ii];
    try {
      const nn = Node.get(editor, sourcePath);
      fragment.push(nn);
      Transforms.removeNodes(editor, { at: sourcePath });
      // eslint-disable-next-line no-empty
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
  const targetNode = Node.get(editor, newListItemPath);
  Transforms.insertNodes(editor, fragment.reverse(), {
    at: [...newListItemPath, targetNode.children.length]
  });
  return true;
};
