import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import AvomaVariableTextInput from 'components/Common/AvomaVariableTextInput';

import { SMART_CATEGORY_EVENT } from 'constants/events';

const AutomationFormTaskTitle = ({
  action,
  handleValueChange,
  clientValue,
  selectedEvent,
  isEdit,
  isRequired = false
}) => {
  const [selectedStaticVariable, setSelectedStaticVariable] = useState();
  const [variables, setVariables] = useState([]);

  const handleChange = value => {
    handleValueChange(value);
  };

  useEffect(() => {
    // Add static variables to the start of the code
    if (selectedEvent !== SMART_CATEGORY_EVENT) {
      if (!isEdit) {
        handleChange('');
      }
      setVariables(action.optionChoices);
    } else {
      const smartCategoryVariable = {
        label: 'Each bullet point under smart category',
        value: selectedEvent
      };
      setSelectedStaticVariable(smartCategoryVariable);
      setVariables([...action.optionChoices, smartCategoryVariable]);
      // We only need to add the static variable during the create phase
      if (!isEdit) {
        handleChange(`//${selectedEvent}// `);
      }
    }
  }, [selectedEvent]);

  return (
    <div className='flex w-full flex-col gap-2'>
      <p className='flex items-center text-sm font-bold text-gunmetal'>
        {action.label} {isRequired && <span className='text-avoma-red'>*</span>}
      </p>
      <AvomaVariableTextInput
        placeholder={action.label}
        variables={variables}
        variableTitle={action.optionTitle}
        value={clientValue}
        onChange={handleChange}
        staticVariables={[selectedStaticVariable]}
        staticVariableClass='text-blue bg-blue-lightest'
      />
    </div>
  );
};

AutomationFormTaskTitle.propTypes = {
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    optionTitle: PropTypes.string.isRequired,
    optionChoices: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
      })
    ),
    apiUrl: PropTypes.string
  }),
  handleValueChange: PropTypes.func.isRequired,
  clientValue: PropTypes.string,
  selectedEvent: PropTypes.string,
  isEdit: PropTypes.bool,
  isRequired: PropTypes.bool
};

export default AutomationFormTaskTitle;
