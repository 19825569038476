import { isEmpty } from 'lodash';
import { RealtimeTranscript } from 'types/realtimeTranscriptions';

import { RealtimeTranscriptFetchActions } from 'actions/actionTypes';

import { mergeTranscript } from 'helpers/rtt';

type State = {
  data: Array<RealtimeTranscript>;
};

export const realtimeTranscriptionsInitialState: State = {
  data: []
};

const realtimeTranscriptions = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: State = realtimeTranscriptionsInitialState,
  action: {
    type: string;
    transcript: RealtimeTranscript;
    existingTranscripts?: Array<RealtimeTranscript>;
  }
) => {
  switch (action.type) {
    case RealtimeTranscriptFetchActions.FETCH_EXISTING_REALTIME_TRANSCRIPTION_SUCCESS:
      if (action.existingTranscripts) {
        const data: Array<RealtimeTranscript> = [];

        // The data from the api sends the same small batches that the websocket sends.
        // So this logic applies the same bunching logic as the websocket processing reducer below.
        action.existingTranscripts.forEach(transcript => {
          if (isEmpty(transcript)) {
            return;
          }

          // Start time of new transcript block is greater than the end time of the last block.
          // No splicing and ordering needed. Append at the end and return.
          if (
            (data.at(-1)?.timestamps?.at(-1) ?? 0) <
            (transcript.timestamps.at(0) ?? 0)
          ) {
            const current = data.at(-1);
            if (data.length > 1 && current?.speaker === transcript.speaker) {
              current.transcript += ` ${transcript.transcript ?? ''}`;
              current.timestamps.concat(transcript.timestamps);
            } else {
              data.push(transcript);
            }

            // Block in correct place, loop to the next block in the api list
            return;
          }

          // Loop over the blocks find the correct place to splice the new block
          // Loop from the end of the list because of very high probability that the new block is closer to the current time.
          // This will reduce the no of iterations needed per socket message and improve performance
          for (let i = data.length - 2; i >= 0; i -= 1) {
            const currentBlock = data[i];

            const newBlock = transcript;

            // New block exists entirely between current block, splice at word level
            if (
              newBlock.timestamps.at(0)! < currentBlock.timestamps.at(0)! &&
              newBlock.timestamps.at(0)! > currentBlock.timestamps.at(-1)!
            ) {
              const sortedArrays = mergeTranscript(currentBlock, newBlock);

              // Update the original transcript with the new value
              data[i].timestamps = sortedArrays[0] as number[];
              data[i].transcript = sortedArrays[1].join(' ');

              // Block in correct place, loop to the next block in the api list
              return;
            }

            // Matching block found that is not entirely under the current block, start splicing at the block level
            if (newBlock.timestamps.at(0)! > currentBlock.timestamps.at(0)!) {
              data.splice(i + 1, 0, transcript);

              // Block in correct place, loop to the next block in the api list
              return;
            }
          }

          // No conditions met, dump at the end.
          data.push(transcript);
        });

        return {
          data: [...data, ...state.data]
        };
      }

      return state;
    case RealtimeTranscriptFetchActions.FETCH_REALTIME_TRANSCRIPTION_SUCCESS:
      if (isEmpty(action.transcript)) {
        return state;
      }
      // Algorithm for splicing and ordering new transcript patches

      // Start time of new transcript block is greater than the end time of the last block.
      // No splicing and ordering needed. Append at the end and return.
      if (
        (state.data.at(-1)?.timestamps?.at(-1) ?? 0) <
        (action.transcript.timestamps.at(0) ?? 0)
      ) {
        const current = state.data.at(-1);
        if (
          action.transcript.speaker &&
          current?.speaker === action.transcript.speaker
        ) {
          current!.transcript += ` ${action.transcript.transcript ?? ''}`;
          current?.timestamps.concat(action.transcript.timestamps);

          return {
            data: [...state.data]
          };
        }

        return {
          data: [...state.data, action.transcript]
        };
      }

      // Loop over the blocks find the correct place to splice the new block
      // Loop from the end of the list because of very high probability that the new block is closer to the current time.
      // This will reduce the no of iterations needed per socket message and improve performance
      for (let i = state.data.length - 2; i >= 0; i -= 1) {
        const currentBlock = state.data[i];

        const newBlock = action.transcript;

        // New block exists entirely between current block, splice at word level
        if (
          newBlock.timestamps.at(0)! < currentBlock.timestamps.at(0)! &&
          newBlock.timestamps.at(0)! > currentBlock.timestamps.at(-1)!
        ) {
          const sortedArrays = mergeTranscript(currentBlock, newBlock);

          // Update the original transcript with the new value
          state.data[i].timestamps = sortedArrays[0] as number[];
          state.data[i].transcript = sortedArrays[1].join(' ');

          return {
            data: [...state.data]
          };
        }

        // Matching block found that is not entirely under the current block, start splicing at the block level
        if (newBlock.timestamps.at(0)! > currentBlock.timestamps.at(0)!) {
          state.data.splice(i + 1, 0, action.transcript);

          return {
            data: [...state.data]
          };
        }
      }

      // No conditions met, dump at the end.
      return {
        data: [...state.data, action.transcript]
      };
    case RealtimeTranscriptFetchActions.RESET_REALTIME_TRANSCRIPTION:
      return { ...realtimeTranscriptionsInitialState };
    default:
      return state;
  }
};

export default realtimeTranscriptions;
