import { WebexAppActions } from 'actions/actionTypes';

const initialState = {};

const webex = (state = initialState, action) => {
  switch (action.type) {
    case WebexAppActions.SET_WEBEX_MEETING_UUID: {
      return {
        ...state,
        meetingUuid: action.meetingUuid
      };
    }
    default:
      return state;
  }
};

export default webex;
