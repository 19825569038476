import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  IAutomationActionRulesAPI,
  IAutomationCRMMembersAPI
} from 'types/automation';

import { useGetTicketingCrmMembersQuery } from 'services/ticketing';

import AvomaSelect from 'components/Common/AvomaSelect';

import { getUserTitle } from 'helpers/user';

interface IProps {
  handleValueChange: (value: string[]) => void;
  clientValue: string[];
  triggerClasses?: string;
  action: IAutomationActionRulesAPI;
}

const AutomationAssigneeHubspotMembers = ({
  handleValueChange,
  clientValue,
  triggerClasses,
  action
}: IProps) => {
  const { data = [] } = useGetTicketingCrmMembersQuery<{
    data: IAutomationCRMMembersAPI[];
  }>(
    {
      url: action?.apiUrl
    },
    {
      skip: !action?.apiUrl
    }
  );

  const options = useMemo(
    () =>
      data.map(opt => ({
        label: getUserTitle(opt),
        value: opt.externalId
      })),
    [data]
  );

  return (
    <AvomaSelect
      items={options}
      label={`Select ${action.label}`}
      triggerClassName={twMerge(
        'w-full bg-white rounded cursor-pointer',
        triggerClasses
      )}
      contentPosition='popper'
      onValueChange={val => handleValueChange(['hubspot_member', val])}
      value={clientValue[1]}
      placeholder='Select Assignee'
      selectItemContainerClassName='hover:bg-snow'
    />
  );
};

export default AutomationAssigneeHubspotMembers;
