/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import Radium from 'radium';
import React, { Component } from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';
import Modal from 'react-modal';
import stylePropType from 'react-style-proptype';

import { silver, white } from 'helpers/colors';
import { isMobile, mobile } from 'helpers/deviceCutoffs';
import { L10Common } from 'helpers/l10n/L10nCommon';

import Tokens from 'styles/tokens';

import AvomaTooltip from './AvomaTooltip';

class AvomaModal extends Component {
  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  // this is to so we dont accidentally trigger click events below the modal
  stopPropagation = e => {
    e.stopPropagation();
  };

  render() {
    const {
      isOpen,
      containerStyles,
      contentStyles,
      headerStyles,
      childrenStyles,
      title,
      children,
      headerCta,
      headerCtaStyles,
      overlayStyles,
      id = 'avoma-modal',
      dataTestId = 'avoma-modal'
    } = this.props;
    // Set the width to be 100% if its mobile view to allow modals to fit the screen size instead of tearing off
    const mobileView = isMobile();
    return (
      <Modal
        style={{
          overlay: { ...styles.modalOverlay, ...overlayStyles },
          content: {
            ...styles.modalContainer,
            ...containerStyles,
            ...(mobileView && { width: '100%' })
          }
        }}
        ref={el => {
          this.modal = el;
        }}
        onRequestClose={this.handleCancel}
        isOpen={isOpen}
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        id={id}
      >
        {isOpen ? (
          <section
            style={{ ...styles.container, ...contentStyles }}
            onClick={this.stopPropagation}
            data-testid={dataTestId}
          >
            <section style={{ ...styles.header, ...headerStyles }}>
              <h2 style={styles.title}>{title}</h2>
              <div style={{ ...styles.headerCta, ...headerCtaStyles }}>
                {headerCta}
                <AvomaTooltip
                  tooltip={L10Common.close}
                  tooltipPosition='bottom-center'
                >
                  <button
                    type='button'
                    onClick={this.handleCancel}
                    className='flex cursor-pointer appearance-none items-center justify-center rounded bg-transparent p-2 hover:bg-gainsboro focus:outline-none'
                    aria-label={L10Common.close}
                  >
                    <CloseIcon className='h-4 w-4 text-gunmetal' />
                  </button>
                </AvomaTooltip>
              </div>
            </section>
            <section style={{ ...styles.content, ...childrenStyles }}>
              {children}
            </section>
          </section>
        ) : null}
      </Modal>
    );
  }
}

const styles = {
  modalOverlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(134, 142, 150, 0.35)',
    position: 'fixed',
    zIndex: '999999'
  },
  modalContainer: {
    position: 'relative',
    display: 'flex',
    boxSizing: 'border-box',
    borderRadius: '4px',
    backgroundColor: white,
    ...Tokens.shadows.large,
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    maxWidth: '480px',
    padding: 0,
    overflow: 'hidden'
  },
  container: {
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'auto'
  },
  content: {
    padding: `${Tokens.spacing.three} ${Tokens.spacing.four} ${Tokens.spacing.four} ${Tokens.spacing.four}`,
    [`@media (max-width: ${mobile}px)`]: {
      padding: `${Tokens.spacing.two} ${Tokens.spacing.three} ${Tokens.spacing.three} ${Tokens.spacing.three}`
    }
  },
  header: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${Tokens.spacing.two} ${Tokens.spacing.four}`,
    backgroundColor: Tokens.colors.snow
  },
  title: {
    ...Tokens.type.body,
    fontWeight: 700,
    color: Tokens.colors.black,
    margin: 0,
    maxWidth: '100%',
    wordBreak: 'break-word'
  },
  close: {
    color: silver,
    fontSize: '1.25rem'
  },
  headerCta: {
    display: 'flex',
    alignItems: 'center'
  }
};

AvomaModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  containerStyles: stylePropType,
  headerCtaStyles: stylePropType,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  contentStyles: stylePropType,
  overlayStyles: stylePropType,
  headerStyles: stylePropType,
  childrenStyles: stylePropType,
  headerCta: PropTypes.node,
  id: PropTypes.string,
  dataTestId: PropTypes.string
};

AvomaModal.defaultProps = {
  title: 'Title',
  onCancel: null,
  isOpen: false,
  children: null,
  containerStyles: null
};

export default Radium(AvomaModal);
