import slackData from 'modules/slack/';

import translations from 'reducers/translations';

import { baseCreateApi } from 'services/index';

import accounts from './accounts';
import alertNotifications from './alertNotifications';
import app from './app';
import audioPlayer from './audioPlayer';
import battlecards from './battlecards';
import bookmarks from './bookmarks';
import coaching from './coaching';
import comments from './comments';
import companies from './companies';
import copilot from './copilot';
import opportunities from './crm';
import dashboard from './dashboard';
import deals from './deals';
import downloads from './downloads';
import embed from './embed';
import filter from './filter';
import highlights from './highlights';
import hookFilters from './hookFilters';
import insights from './insights';
import licenses from './licenses';
import meetings from './meetings';
import members from './members';
import notes from './notes';
import onboarding from './onboarding';
import outcomes from './outcomes';
import playlists from './playlists';
import publics from './public';
import realtimeTranscriptions from './realtimeTranscriptions';
import recordings from './recordings';
import savedViews from './savedViews';
import scheduler from './scheduler';
import scorecards from './scorecards';
import tags from './tags';
import teams from './teams';
// eslint-disable-next-line import/no-cycle
import templates from './templates';
import transcriptions from './transcriptions';
// eslint-disable-next-line import/no-cycle
import user from './user';
import users from './users';
import webex from './webex';
import websocket from './websockets';
import zoom from './zoom';
import { combineReducers } from '@reduxjs/toolkit';

const appReducer = combineReducers({
  app,
  audioPlayer,
  meetings,
  recordings,
  transcriptions,
  notes,
  templates,
  user,
  onboarding,
  tags,
  opportunities,
  members,
  accounts,
  insights,
  embed,
  teams,
  users,
  dashboard,
  playlists,
  highlights,
  publics,
  licenses,
  outcomes,
  bookmarks,
  battlecards,
  realtimeTranscriptions,
  translations,
  websocket,
  comments,
  filter,
  deals,
  coaching,
  zoom,
  scheduler,
  savedViews,
  scorecards,
  webex,
  slackData,
  companies,
  [baseCreateApi.reducerPath]: baseCreateApi.reducer,
  hookFilters,
  alertNotifications,
  copilot,
  downloads
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
