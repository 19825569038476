import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactEditor, useSelected, useSlate } from 'slate-react';
import { twMerge } from 'tailwind-merge';

import AvomaHoverCard from 'components/Common/AvomaHoverCard';
import AvomaIcon from 'components/Common/AvomaIcon';
import AvomaTooltip from 'components/Common/AvomaTooltip';
import CrmIcon from 'components/Common/CrmIcon';

import L10n from 'helpers/L10n';
import { BLOCK_KEY, HIGHLIGHT_NOTES_TITLE } from 'helpers/SlateDataHelper';
import SlateHelper from 'helpers/SlateHelper';
import { isMobile } from 'helpers/deviceCutoffs';
import { isAdmin } from 'helpers/user';

import { KEY_TAKEAWAYS } from 'constants/notes';

import { ReactComponent as EditIcon } from 'images/edit.svg';
import helpIcon from 'images/ic_nav_feedback_dark.svg';

import { ColorMap } from 'styles/HighlightCategoryColors';
import Tokens from 'styles/tokens';

import AddBlockButton from '../Components/AddBlockButton';
import { getParentForNode } from '../Plugins/Queries/getParent';
import { TYPE_HEADER1, TYPE_HEADER2, TYPE_HEADER3 } from '../Plugins/Types';

const styles = {
  h: {
    margin: `${Tokens.spacing.two} 0 ${Tokens.spacing.one} 0`,
    position: 'relative'
  },
  first: {
    marginTop: '0px'
  },
  h1: {
    ...Tokens.type.header3
  },
  h2: {
    ...Tokens.type.header4
  },
  h3: {
    ...Tokens.type.header5
  },
  prompt: {
    ...Tokens.type.bodyS,
    display: 'inline',
    position: 'absolute',
    cursor: 'pointer',
    color: Tokens.colors.silverLight,
    marginLeft: Tokens.spacing.three
  },
  hoverContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none'
  },
  hoverContentListItem: {
    ...Tokens.type.bodyS,
    color: Tokens.colors.gunmetal,
    userSelect: 'none'
  }
};

const Header = props => {
  const editor = useSlate();
  const [hovering, setHover] = useState();
  const categories =
    useSelector(state => state.insights.customCategories) || {};
  const crmProvider = useSelector(state => state.user.crmProvider);
  const userEmail = useSelector(state => state.user.email);

  const [updateCategoryUuid, setUpdateCategoryUuid] = useState(false);

  const showHoverState = () => {
    setHover(true);
  };

  const hideHoverState = () => {
    setHover(false);
  };

  const onCategoriesShown = () => {
    Header.CAPTURE_KEYS = true;
  };

  const onCategoriesHidden = () => {
    Header.CAPTURE_KEYS = false;
  };

  const selected = useSelected();

  const {
    element,
    children,
    onCategorySelected,
    onNoneSelected,
    seenCategories,
    readOnly,
    hideBlockButton,
    showCategoryPrompt,
    colorCategoryBorders,
    showInsertAgendaButton,
    onInsertAgendaSelected,
    isTemplateFlow = false,
    mode = '',
    currentTemplate,
    meetingUuid
  } = props;
  const path = ReactEditor.findPath(editor, element);
  const isFirst = path[0] === 0;

  const { data } = element;
  const isCategory = data && data[HIGHLIGHT_NOTES_TITLE];
  const categoryStyle = {};
  let promptText = null;
  let categoryUuid = null;
  let promptVariations = null;
  let fieldMapping = null;
  let allowEdit = false;

  if (isCategory && colorCategoryBorders) {
    const categoryKey = data[BLOCK_KEY];
    const categoryName = SlateHelper.getTextFromNode(element);
    const category = find(categories, hh => hh.key === categoryKey);

    allowEdit = isAdmin() || userEmail === category?.createdBy?.email;

    if (category) {
      categoryUuid = category.uuid;
      const color =
        ColorMap?.[category?.backgroundColor]?.neutral ||
        Tokens.colors.silverDark;
      categoryStyle.borderBottom = `2px solid ${color}`;
      const prompts = Object.values(category?.prompts);
      const isMappedToCrmField = !isEmpty(category?.avFieldMapping);
      if (categoryName && prompts?.length > 0) {
        promptText = prompts[0].label;
        if (prompts[0].variations) {
          promptVariations = prompts[0].variations;
        }
      }
      if (categoryName && isMappedToCrmField) {
        fieldMapping = category.avFieldMapping;
      }
    }

    if (categoryKey === KEY_TAKEAWAYS) {
      categoryStyle.borderBottom = `2px solid ${Tokens.colors.yellowDark}`;
    }
  }

  const parent = getParentForNode(editor, element);
  const parentNode = parent[0];
  const query = element.children && element.children[0].text;
  const selectCategory = category => {
    if (onCategorySelected) {
      onCategorySelected(category, element);
    }
  };

  const selectAgenda = () => {
    if (onInsertAgendaSelected) {
      onInsertAgendaSelected(element);
    }
  };

  const addEditIcon = isAllowEditCategory => (
    <AvomaTooltip
      tooltip={
        !isAllowEditCategory
          ? 'Only an Admin or topic owner can edit this smart topic'
          : ''
      }
      tooltipPosition='top-left'
      userStyles={{ maxWidth: 250 }}
    >
      <div className='group-hover:visible invisible inline-block'>
        <AvomaIcon
          RenderIcon={EditIcon}
          extraSmall
          className={twMerge(
            'ml-2 cursor-pointer !inline-block',
            !isAdmin() && '!opacity-50',
            fieldMapping && !promptText && 'ml-7'
          )}
          onClick={() => {
            if (isAllowEditCategory) {
              setUpdateCategoryUuid(true);
            }
          }}
        />
      </div>
    </AvomaTooltip>
  );

  const renderAddButton = (isFocused, isCat, catUuid) => (
    <AddBlockButton
      parent={parentNode}
      show={!readOnly && hovering && !hideBlockButton}
      onCategorySelected={selectCategory}
      onCategoriesShown={onCategoriesShown}
      onCategoriesHidden={onCategoriesHidden}
      onNoneSelected={onNoneSelected}
      seenCategories={seenCategories}
      filter={query}
      isFocused={isFocused}
      skipSlash={!isCat}
      isCategory={isCat}
      selectedCategoryUuid={catUuid}
      style={{ marginTop: '3px' }}
      showInsertAgendaButton={showInsertAgendaButton}
      onInsertAgendaSelected={selectAgenda}
      isTemplateFlow={isTemplateFlow}
      updateCategoryUuid={updateCategoryUuid ? catUuid : undefined}
      setUpdateCategoryUuid={setUpdateCategoryUuid}
      currentTemplate={currentTemplate}
      meetingUuid={meetingUuid}
    />
  );
  const { type } = element;
  const mobile = isMobile();

  switch (type) {
    case TYPE_HEADER1:
      return (
        <h1
          style={{ ...styles.h, ...styles.h1, ...(isFirst && styles.first) }}
          onMouseEnter={showHoverState}
          onMouseLeave={hideHoverState}
        >
          {renderAddButton(selected, isCategory, categoryUuid)}
          <span style={categoryStyle}>{children}</span>
        </h1>
      );
    case TYPE_HEADER2:
      return (
        <h2
          style={{
            ...styles.h,
            ...styles.h2,
            ...(isFirst && styles.first)
          }}
          onMouseEnter={showHoverState}
          onMouseLeave={hideHoverState}
          className='group'
        >
          {data?.[BLOCK_KEY] === KEY_TAKEAWAYS ? (
            <AvomaTooltip tooltip={L10n.notes.aiGeneratedSummary}>
              <span
                className='disable-select'
                style={{
                  marginRight: Tokens.spacing.half,
                  marginLeft: `-${Tokens.spacing.three}`
                }}
              >
                🌟
              </span>
            </AvomaTooltip>
          ) : (
            renderAddButton(selected, isCategory, categoryUuid)
          )}
          <span style={categoryStyle}>{children}</span>
          {fieldMapping && (
            <AvomaTooltip
              tooltip={
                <React.Fragment>
                  Synced to "{fieldMapping.crmField.fieldLabel}" of "
                  {fieldMapping.groupName}" in{' '}
                  <span style={{ textTransform: 'capitalize' }}>
                    {crmProvider}
                  </span>
                </React.Fragment>
              }
              tooltipPosition='top-left'
              userStyles={{ maxWidth: 250, zIndex: 999999 }}
            >
              <span
                className='disable-select absolute ml-2 top-[30%] cursor-default'
                contentEditable={false}
              >
                <CrmIcon crm={crmProvider} className='h-5 w-5' />
              </span>
            </AvomaTooltip>
          )}
          {showCategoryPrompt && promptText && (
            <React.Fragment>
              <span className='disable-select' contentEditable={false}>
                <AvomaHoverCard
                  triggerComponent={
                    <div
                      style={{
                        ...styles.prompt,
                        marginLeft: fieldMapping
                          ? Tokens.spacing.threehalf
                          : Tokens.spacing.two,
                        top: mobile ? '30%' : '20%'
                      }}
                    >
                      {mobile ? (
                        <AvomaIcon icon={helpIcon} small />
                      ) : (
                        <React.Fragment>
                          {promptText}
                          {isTemplateFlow &&
                            mode === 'edit' &&
                            isCategory &&
                            addEditIcon(allowEdit)}
                        </React.Fragment>
                      )}
                    </div>
                  }
                  title={L10n.notes.triggerPrompts}
                  childrenStyles={{
                    maxHeight: '150px',
                    overflow: 'hidden auto'
                  }}
                >
                  <div
                    style={styles.hoverContentContainer}
                    className='disable-select'
                  >
                    <p
                      style={{
                        ...styles.hoverContentListItem,
                        marginBottom: Tokens.spacing.one
                      }}
                      className='disable-select'
                    >
                      {L10n.notes.triggerPromptTooltip}
                    </p>
                    <ul className='disable-select'>
                      <li style={styles.hoverContentListItem}>{promptText}</li>
                      {Object.values(promptVariations || {}).map(prompt => (
                        <li
                          style={styles.hoverContentListItem}
                          key={prompt.uuid}
                        >
                          {prompt.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                </AvomaHoverCard>
              </span>
            </React.Fragment>
          )}
          {isTemplateFlow &&
            mode === 'edit' &&
            !promptText &&
            isCategory &&
            addEditIcon(allowEdit)}
        </h2>
      );
    case TYPE_HEADER3:
    default:
      return (
        <h3
          style={{ ...styles.h, ...styles.h3, ...(isFirst && styles.first) }}
          onMouseEnter={showHoverState}
          onMouseLeave={hideHoverState}
        >
          {renderAddButton(selected, isCategory, categoryUuid)}
          <span style={categoryStyle}>{children}</span>
        </h3>
      );
  }
};

Header.propTypes = {
  element: PropTypes.shape({
    type: PropTypes.string,
    children: PropTypes.array,
    data: PropTypes.object
  }).isRequired,
  children: PropTypes.node,
  onCategorySelected: PropTypes.func,
  onNoneSelected: PropTypes.func,
  seenCategories: PropTypes.object,
  readOnly: PropTypes.bool,
  hideBlockButton: PropTypes.bool,
  showCategoryPrompt: PropTypes.bool,
  colorCategoryBorders: PropTypes.bool,
  showInsertAgendaButton: PropTypes.func,
  onInsertAgendaSelected: PropTypes.func,
  isTemplateFlow: PropTypes.bool,
  mode: PropTypes.string,
  currentTemplate: PropTypes.object,
  meetingUuid: PropTypes.string
};

Header.defaultProps = {
  children: null,
  readOnly: false,
  hideBlockButton: false,
  showCategoryPrompt: true,
  colorCategoryBorders: true
};

export default Header;
