import React from 'react';
import ButtonUnstyled from 'components/Common/ButtonUnstyled';
import { toast } from 'react-hot-toast';
import Tokens from 'styles/tokens';
import { MdClose as CloseIcon } from 'react-icons/md';
import CheckIcon from 'images/white_check_green_stroke.svg';
import InfoIcon from 'images/white_info.svg';

export const toastMessageSuccess = (title, message) => {
  toast(
    t => (
      <div style={{ ...styles.container, width: '400px' }}>
        <div style={{ ...styles.titleContainer, ...styles.successBackground }}>
          <div style={{ display: 'flex' }}>
            <img src={CheckIcon} alt='success' style={styles.icon} />{' '}
            <p style={styles.title}>{title}</p>
          </div>

          <ButtonUnstyled
            onClick={() => toast.dismiss(t.id)}
            type='button'
            tooltip='Close'
          >
            <CloseIcon style={styles.closeIcon} />
          </ButtonUnstyled>
        </div>
        <div style={styles.messageContainer}>{message}</div>
      </div>
    ),
    {
      position: 'bottom-left',
      duration: 15 * 1000,
      style: {
        padding: 0,
        background: 'none',
        maxWidth: '400px'
      },
      iconTheme: {
        marginLeft: '20px'
      }
    }
  );
};

export const toastMessageInfo = (title, message) => {
  toast(
    t => (
      <div style={{ ...styles.container, width: '500px' }}>
        <div style={{ ...styles.titleContainer, ...styles.infoBackground }}>
          <div style={{ display: 'flex' }}>
            <img src={InfoIcon} alt='info' style={styles.icon} />{' '}
            <p style={styles.title}>{title}</p>
          </div>

          <ButtonUnstyled
            onClick={() => toast.dismiss(t.id)}
            type='button'
            tooltip='Close'
          >
            <CloseIcon style={styles.closeIcon} />
          </ButtonUnstyled>
        </div>
        <div style={styles.messageContainer}>{message}</div>
      </div>
    ),
    {
      position: 'bottom-left',
      duration: 15 * 1000,
      style: {
        padding: 0,
        background: 'none',
        maxWidth: '500px'
      },
      iconTheme: {
        marginLeft: '20px'
      }
    }
  );
};

const styles = {
  success: {
    background: Tokens.colors.greenDark
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: Tokens.colors.white,
    borderRadius: Tokens.spacing.half,
    boxShadow: '0px 46px 100px rgba(0, 0, 0, 0.15)'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${Tokens.spacing.one} ${Tokens.spacing.two}`,
    borderTopLeftRadius: Tokens.spacing.half,
    borderTopRightRadius: Tokens.spacing.half
  },
  successBackground: {
    background: Tokens.colors.greenDark,
    color: Tokens.colors.white
  },
  infoBackground: {
    background: Tokens.colors.blue,
    color: Tokens.colors.white
  },
  title: {
    ...Tokens.type.body,
    fontWeight: 'bold'
  },
  icon: {
    marginRight: Tokens.spacing.one
  },
  closeIcon: {
    color: Tokens.colors.white,
    fontSize: '20px',
    strokeWidth: 1
  },
  messageContainer: {
    ...Tokens.type.body,
    padding: Tokens.spacing.two,
    paddingRight: Tokens.spacing.six,
    color: Tokens.colors.silver
  }
};
