import camelize from 'camelize';

const initialState = {
  data: {},
  fetching: false,
  error: null
};

const embed = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_RECORDINGS_REQUEST': {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case 'FETCH_RECORDINGS_SUCCESS': {
      // case for recordings in outreach iFrame
      // (JSON response object differs from original Recordings response)
      const { recording } = action;
      if (recording.count > 0 && recording.results.length > 0) {
        const recordingsObj = {};
        recording.results.forEach(result => {
          recordingsObj[result.uuid] = {
            ...state.data[result.uuid],
            ...camelize(result),
            lastSeekFromTranscript: null
          };
        });
        return {
          ...state,
          fetching: false,
          data: {
            ...state.data,
            ...recordingsObj
          },
          error: null
        };
      }

      return { ...state, fetching: false, error: null };
    }
    case 'FETCH_RECORDINGS_FAILURE':
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default embed;
