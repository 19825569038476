import React from 'react';
import PropTypes from 'prop-types';
import { MdCheck as CheckIcon } from 'react-icons/md';
import Tokens from 'styles/tokens';

const HighlightCategoryTag = ({ highlightCategory, disabled, isChecked }) => (
  <span
    style={{
      ...styles.categoryTag,
      ...(disabled
        ? styles.disabled
        : {
            backgroundColor:
              highlightCategory.backgroundColor || Tokens.colors.azureLighter
          })
    }}
  >
    {highlightCategory.name}{' '}
    {isChecked && <CheckIcon style={styles.check} size={20} />}
  </span>
);

const styles = {
  categoryTag: {
    ...Tokens.type.bodyS,
    textTransform: 'capitalize',
    color: Tokens.colors.gunmetal,
    padding: `${Tokens.spacing.one} ${Tokens.spacing.two}`,
    borderRadius: Tokens.spacing.borderRadius,
    backgroundColor: Tokens.colors.magentaLighter,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  disabled: {
    opacity: '0.6',
    backgroundColor: Tokens.colors.f1,
    color: Tokens.colors.silver
  },
  check: {
    fill: Tokens.colors.blue
  }
};

HighlightCategoryTag.propTypes = {
  highlightCategory: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isChecked: PropTypes.bool
};

HighlightCategoryTag.defaultProps = {
  disabled: false,
  isChecked: false
};

export default HighlightCategoryTag;
