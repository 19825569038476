// eslint-disable-next-line import/no-cycle
import { errorNotification } from 'components/ToastNotifications';

// eslint-disable-next-line import/no-cycle
import reduxStore from 'helpers/reduxStore';

import { IMPERSONATION_SETTING_ONLY } from 'constants/impersonation';

import { isPathInsideOutreach } from './outreachAuth';
import { isZoomContext } from './zoomApp';

export const API_FAILURE = 'API Failure';
export const BAD_REQUEST = 'Bad Request';
export const NOT_FOUND = 'Not Found';
export const FORBIDDEN = 'Forbidden';
export const CONFLICT = 'Conflict';
export const NOLICENSE = 'No License';
export const UNAUTHORIZED = 'Unauthorized';
export const JSON_PARSE_ERROR = 'Json Parse Error';
export const SERVER_ERROR = 'Internal Server Error';
export const RATE_LIMITED = 'Too Many Requests';

const LOGOUT_LIST = ['/api/v1/userprofile', '/api/v1/meetings/'];
const isUrlInLogoutList = url => {
  for (let ii = 0; ii < LOGOUT_LIST.length; ii += 1) {
    const uu = LOGOUT_LIST[ii];
    if (url.includes(uu)) {
      return true;
    }
  }
  return false;
};

const checkIfSettingsImpersonation = () => {
  const { impersonationActive } = reduxStore.getState().user;
  const { impersonationType } = reduxStore.getState().user;

  if (impersonationActive && impersonationType === IMPERSONATION_SETTING_ONLY) {
    errorNotification({
      message: 'Updates are not allowed in read-only impersonation of settings.'
    });
    return true;
  }
  return false;
};
async function handleResponse(response) {
  const logout = () => {
    // remove the redux state item so that there isn't a flicker glitch
    // in firefox when the token is expired
    localStorage.removeItem('reduxState');
    reduxStore.dispatch({ type: 'LOGOUT' });
    window.location.href = isZoomContext()
      ? '/zoom/signup?error=401'
      : '/signup?error=401';
  };
  let json = {};
  if (response.status === 401) {
    if (isUrlInLogoutList(response.url)) {
      reduxStore.dispatch({ type: 'LOGOUT' });
    }
    if (
      response.url.includes(
        '/public/' || '/api/v1/hubspot/visitor_identification_token'
      )
    ) {
      // window.location.href = '/signup';
    } else if (response.url.includes('/api/v1/zoom/app/decrypt_context/')) {
      const decryptContextRetries =
        parseInt(localStorage.getItem('decryptContextRetries')) || 0;
      if (decryptContextRetries < 3) {
        localStorage.setItem(
          'decryptContextRetries',
          decryptContextRetries + 1
        );
        return 'RELOGIN';
      }
      localStorage.removeItem('decryptContextRetries');
      logout();
    } else {
      logout();
    }
    const e = new Error(UNAUTHORIZED);
    e.name = UNAUTHORIZED;
    throw e;
  } else if (response.ok) {
    if (response.status !== 204) {
      try {
        json = await response.json();
      } catch (e) {
        const err = new Error(e);
        err.name = JSON_PARSE_ERROR;
        throw err;
      }
    }
    return json;
  } else {
    let statusText = API_FAILURE;
    if (response.status === 403) {
      statusText = FORBIDDEN;
    } else if (response.status === 402) {
      statusText = NOLICENSE;
    } else if (response.status === 400) {
      statusText = BAD_REQUEST;
    } else if (response.status === 404) {
      statusText = NOT_FOUND;
    } else if (response.status === 409) {
      statusText = CONFLICT;
    } else if (response.status === 500) {
      statusText = SERVER_ERROR;
    } else if (response.status === 429) {
      statusText = RATE_LIMITED;
    }
    const e = new Error(statusText);
    e.name = statusText;
    e.status = response.status;
    try {
      json = await response.json();
    } catch (error) {
      // Handle the error if parsing the JSON fails
      console.error('Failed to parse JSON response:', error);
    }

    // The finally was failing and resulting in Syntax error when the response is empty
    // Which in the case of errors was. This makes sure that the error is caught, and previous
    // code of setting status and name is not overwritten
    e.message = json;
    throw e;
  }
}

export const get = async (url, options) => {
  const headers = {};
  if (isPathInsideOutreach() && reduxStore.getState().user.accessToken) {
    headers.Authorization = `Bearer ${reduxStore.getState().user.accessToken}`;
  }
  const fetchOptions = {
    method: 'GET',
    headers,
    ...options
  };
  if (!isPathInsideOutreach()) {
    fetchOptions.credentials = 'include';
  }
  const response = await fetch(url, fetchOptions);
  return handleResponse(response);
};

export const rawGet = async (url, options) => {
  const headers = {};
  if (isPathInsideOutreach() && reduxStore.getState().user.accessToken) {
    headers.Authorization = `Bearer ${reduxStore.getState().user.accessToken}`;
  }
  const fetchOptions = {
    method: 'GET',
    headers,
    ...options
  };
  if (!isPathInsideOutreach()) {
    fetchOptions.credentials = 'include';
  }

  return fetch(url, fetchOptions);
};

export const getSameOrigin = async url => {
  const headers = {};
  const response = await fetch(url, {
    headers,
    credentials: 'include',
    method: 'GET'
  });
  return handleResponse(response);
};

export const post = async (url, body) => {
  if (checkIfSettingsImpersonation()) return;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  if (isPathInsideOutreach() && reduxStore.getState().user.accessToken) {
    headers.Authorization = `Bearer ${reduxStore.getState().user.accessToken}`;
  }
  const options = {
    body: body ? JSON.stringify(body) : '{}',
    method: 'POST',
    headers
  };
  if (!isPathInsideOutreach()) {
    options.credentials = 'include';
  }
  const response = await fetch(url, options);
  return handleResponse(response);
};

export const blobToBase64 = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const rawPost = async (url, body) => {
  if (checkIfSettingsImpersonation()) return;
  const response = await fetch(url, {
    body,
    method: 'POST'
  });
  return handleResponse(response);
};

export const postNoAuth = async (url, body) => {
  const response = await fetch(url, {
    body: body ? JSON.stringify(body) : '{}',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  return handleResponse(response);
};

export const deleteMethod = async (url, body) => {
  if (checkIfSettingsImpersonation()) return;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  if (isPathInsideOutreach() && reduxStore.getState().user.accessToken) {
    headers.Authorization = `Bearer ${reduxStore.getState().user.accessToken}`;
  }
  const options = {
    body: body ? JSON.stringify(body) : '{}',
    method: 'DELETE',
    headers
  };
  if (!isPathInsideOutreach()) {
    options.credentials = 'include';
  }
  const response = await fetch(url, options);
  return handleResponse(response);
};

export const put = async (url, body) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  if (isPathInsideOutreach() && reduxStore.getState().user.accessToken) {
    headers.Authorization = `Bearer ${reduxStore.getState().user.accessToken}`;
  }
  const options = {
    body: body ? JSON.stringify(body) : '{}',
    method: 'PUT',
    headers
  };
  if (!isPathInsideOutreach()) {
    options.credentials = 'include';
  }
  const response = await fetch(url, options);
  return handleResponse(response);
};

export const patch = async (url, body, options) => {
  if (checkIfSettingsImpersonation()) return;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  if (isPathInsideOutreach() && reduxStore.getState().user.accessToken) {
    headers.Authorization = `Bearer ${reduxStore.getState().user.accessToken}`;
  }
  const fetchOptions = {
    body: body ? JSON.stringify(body) : '{}',
    method: 'PATCH',
    headers,
    ...options
  };
  if (!isPathInsideOutreach()) {
    fetchOptions.credentials = 'include';
  }
  const response = await fetch(url, fetchOptions);
  return handleResponse(response);
};

export const auth = async (url, body, headers = {}, failureRedirect = null) => {
  const newHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers
  };

  const options = {
    body: body ? JSON.stringify(body) : '{}',
    method: 'POST',
    headers: newHeaders
  };
  if (!isPathInsideOutreach()) {
    options.credentials = 'include';
  }
  const response = await fetch(url, options);

  let json = {};
  try {
    json = await response.json();
  } catch (e) {
    const err = new Error(e);
    err.name = 'Json Parse Error';
    throw err;
  }

  if (!response.ok) {
    let urlValue;
    if (failureRedirect === false) {
      throw new Error('Something went wrong');
    } else if (failureRedirect !== 'null') {
      urlValue = failureRedirect;
    } else {
      urlValue = `/signup?error=${response.status}&avoma_error=${json.avoma_error}`;
    }
    const e = new Error(response.statusText);
    window.location.href = urlValue;
    throw e;
  } else if (response.status === 500) {
    const e = new Error(response.statusText);
    e.name = 'InternalServerError';
    throw e;
  } else {
    if (response.status === 204) {
      return {};
    }
    return json;
  }
};
