import { TranslatedMeeting } from 'types/translations';

import { MeetingTranslationActions } from 'actions/actionTypes';

type State = {
  data: Record<
    string,
    {
      fetchingNotes: boolean;
      fetchingTranscript: boolean;
      data: TranslatedMeeting | null;
    }
  >;
};

export const translationInitialState: State = {
  data: {}
};

const translations = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: State = translationInitialState,
  action: {
    type: string;
    meetingUuid: string;
    data: any;
  }
): State => {
  switch (action.type) {
    case MeetingTranslationActions.FETCH_MEETING_TRANSLATION:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            fetchingNotes: true,
            fetchingTranscript: true,
            data: null
          }
        }
      };
    case MeetingTranslationActions.FETCH_MEETING_TRANSLATION_NOTES_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data?.[action.meetingUuid],
            fetchingNotes: false,
            data: {
              ...state.data?.[action.meetingUuid]?.data,
              translatedNotes: action.data.translatedNotes,
              speakers: action.data.speaker
            }
          }
        }
      };
    }
    case MeetingTranslationActions.FETCH_MEETING_TRANSLATION_TRANSCRIPT_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            ...state.data?.[action.meetingUuid],
            fetchingTranscript: false,
            data: {
              ...state.data?.[action.meetingUuid]?.data,
              translatedTranscript: [
                ...(state.data?.[action.meetingUuid]?.data
                  ?.translatedTranscript ?? []),
                ...(action.data?.translatedTranscript ?? [])
              ]
            }
          }
        }
      };
    }
    case MeetingTranslationActions.FETCH_MEETING_TRANSLATION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meetingUuid]: {
            fetchingNotes: false,
            fetchingTranscript: false,
            data: null
          }
        }
      };
    default:
      return state;
  }
};

export default translations;
