import { Editor, Node, Transforms } from 'slate';

import getNodesByType from '../../Queries/getNodesByType';
import { wrapNodes } from '../../Transforms/wrapNodes';
import { TYPE_CHECKLIST_ITEM, TYPE_PARAGRAPH } from '../../Types';
import { getBlockType, isBlockActive } from '../Queries/getActiveListType';
import { TYPE_CHECKLIST, TYPE_LIST_ITEM } from '../types';
import { unwrapList } from './unwrapList';

export const toggleList = (editor, { typeList, ...options }) => {
  const { selection } = editor;

  if (!selection) {
    return;
  }

  Editor.normalize(editor);

  const isActive = isBlockActive(editor, typeList);
  const activeListType = getBlockType(editor);

  if (activeListType) {
    unwrapList(editor, options);
  }

  Transforms.setNodes(editor, {
    type: TYPE_PARAGRAPH
  });

  if (!isActive) {
    const list = { type: typeList, children: [] };

    wrapNodes(editor, list);

    const nodes = [...getNodesByType(editor, TYPE_PARAGRAPH)];

    let listItem = {
      type: TYPE_LIST_ITEM,
      children: []
    };

    if (typeList === TYPE_CHECKLIST) {
      listItem = {
        type: TYPE_CHECKLIST_ITEM,
        checked: false,
        children: []
      };
    }

    for (const [, path] of nodes) {
      if (Node.has(editor, path)) {
        try {
          Transforms.wrapNodes(editor, listItem, { at: path });
        } catch (error) {
          console.error(`Error wrapping nodes at path [${path}]:`, error);
        }
      } else {
        console.warn(`Invalid path skipped: [${path}]`);
      }
    }
  }
};
