export const AUTOMATION_EVENTS_MAP = {
  'notes.created': 'Meeting notes are available',
  'ai.action_items.created': 'Meeting notes have action items available',
  'ai.objections.created': 'Meeting notes have objections available'
};

export const EVENTS_STATIC_VARIABLES_MAP = {
  'notes.created': '',
  'ai.action_items.created': 'Each bullet point under action items',
  'ai.objections.created': 'Each bullet point under objections'
};

export const NOTES_CREATED_EVENT = 'notes.created';
export const MEETING_CREATED_EVENT = 'meeting.created';
export const SMART_CATEGORY_EVENT = 'ai.smart_category.created';

export const TEMPORARY_DISABLED_STRINGS = {
  action_details_outdated: 'Workspace or List has been deleted in ClickUp',
  related_smart_category_inactive:
    'Enable Bookmarks & Transcript Highlights for this smart category to make this automation active'
};
