/// <reference types="vite-plugin-svgr/client" />
import 'draft-js/dist/Draft.css';
import { StyleRoot } from 'radium';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import BeamerScriptLoader from 'components/BeamerScriptLoader';
import ThirdPartyScriptLoader from 'components/Common/ThirdPartyScriptLoader';

import env from 'helpers/env';
import { generateHubspotVisitorIdentificationId } from 'helpers/hubspotLiveChat';
import { isZoomAppAgent } from 'helpers/zoomApp';

import App from './components/App';
import reduxStore from './helpers/reduxStore';
import './index.css';
import './scripts/initSegment';
import './scripts/initSegmentV2';
import './scripts/thirdPartyScripts';

const notInIframe = window === window.parent;

ReactDOM.render(
  <Provider store={reduxStore}>
    <StyleRoot style={{ height: '100%' }}>
      {notInIframe &&
        ['prod', 'staging', 'int'].includes(env.name) &&
        !isZoomAppAgent() && (
          <ThirdPartyScriptLoader
            src={`//js-na1.hs-scripts.com/${env.hubspotTrackingCode}.js`}
            async
            delay={2000}
            id='hs-chat-script-loader'
            onloadCallback={generateHubspotVisitorIdentificationId}
          />
        )}
      {notInIframe && !isZoomAppAgent() && (
        <React.Fragment>
          <ThirdPartyScriptLoader
            src={`//js.hs-scripts.com/${env.hubspotTrackingCode}.js`}
            async
            delay={10000}
            id='hs-script-loader'
          />
          <ThirdPartyScriptLoader
            src={`https://cdn-cookieyes.com/client_data/${env.cookieYesId}/script.js`}
            async
            delay={5000}
            id='cookieyes'
          />
          <BeamerScriptLoader />
          <ThirdPartyScriptLoader
            src='https://js.chargebee.com/v2/chargebee.js'
            defer
            delay={1000}
            attributes={{
              'data-cb-site': env.chargebeeSiteId
            }}
            id='chargebee-loader'
          />
        </React.Fragment>
      )}
      <App />
    </StyleRoot>
  </Provider>,
  document.getElementById('root')
);
