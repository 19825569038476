/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useFocused, useSelected } from 'slate-react';

import Tokens from 'styles/tokens';

const Mention = ({ attributes, children, element, readOnly }) => {
  const selected = useSelected();
  const focused = useFocused();

  let mentionText = `@${element.data.name}`;
  if (!element.data.name) mentionText = `@${element.data.email}`;

  return (
    <span
      {...attributes}
      contentEditable={false}
      style={{
        ...styles.userMention,
        ...(readOnly && { color: Tokens.colors.gunmetal }),
        backgroundColor:
          selected && focused ? Tokens.colors.smoke : Tokens.colors.transparent
      }}
    >
      {!readOnly && mentionText}
      {children}
    </span>
  );
};

const styles = {
  userMention: {
    padding: '1px 2px',
    borderRadius: Tokens.spacing.borderRadius,
    color: Tokens.colors.blue
  }
};

Mention.propTypes = {
  attributes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  readOnly: PropTypes.bool
};

export default Mention;
