export const NOTE_TYPE = {
  AGENDA: 1,
  NOTE: 2
};

export const KEY_TAKEAWAYS = 'key_takeaways';

export const AI_STATE_SUCCESS_STATUSES = [
  'turing_notes_done',
  'process_transcription_complete_start',
  'transcription_email_ready',
  'notes_ready',
  'processing_complete',
  'collab_notes_done'
];
