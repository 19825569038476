import _ from 'lodash';

import Dashboard from './Dashboard';

class ConversationDashboardHelper {
  static SUPPORTED_CHARTS = {
    talkTime: [
      Dashboard.chartTypes.breakdown,
      Dashboard.chartTypes.leaderboard
    ],
    longestMonologue: [
      Dashboard.chartTypes.breakdown,
      Dashboard.chartTypes.leaderboard
    ],
    longestCustomerStory: [
      Dashboard.chartTypes.breakdown,
      Dashboard.chartTypes.leaderboard
    ],
    clientQuestions: [
      Dashboard.chartTypes.breakdown,
      Dashboard.chartTypes.leaderboard
    ],
    repQuestions: [
      Dashboard.chartTypes.breakdown,
      Dashboard.chartTypes.leaderboard
    ],
    fillers: [Dashboard.chartTypes.trends, Dashboard.chartTypes.leaderboard]
  };

  static TABS = {
    talkTime: 'talkTime',
    longestMonologue: 'longestMonologue',
    longestCustomerStory: 'longestCustomerStory',
    clientQuestions: 'clientQuestions',
    repQuestions: 'repQuestions',
    fillers: 'fillers'
  };

  static BREAKDOWN = {
    meeting: 'meeting',
    user: 'user'
  };

  static FACETS = {
    breakdown: {
      talkTime: 'talk_listen_ratio_per_user_per_meeting',
      longestMonologue: 'longest_monologue_per_user_per_meeting',
      longestCustomerStory: 'longest_customer_story_per_user_per_meeting',
      clientQuestions: 'non_rep_question_count_per_user_per_meeting',
      repQuestions: 'question_count_per_user_per_meeting',
      fillers: 'filler_words_count_per_user'
    },
    leaderboard: {
      talkTime: 'talk_listen_ratio_per_user',
      longestMonologue: 'longest_monologue_per_user',
      longestCustomerStory: 'longest_customer_story_per_user',
      clientQuestions: 'non_rep_question_count_per_user_per_meeting',
      repQuestions: 'question_count_per_user_per_meeting',
      fillers: 'filler_words_count_per_user'
    },
    trends: {
      fillers: 'filler_words_count_per_user_per_date',
      // Below are the placeholder until trends charts are available for all tabs
      talkTime: 'talk_listen_ratio_per_user',
      longestMonologue: 'longest_monologue_per_user',
      longestCustomerStory: 'longest_customer_story_per_user',
      clientQuestions: 'non_rep_question_count_per_user_per_meeting',
      repQuestions: 'question_count_per_user_per_meeting'
    }
  };

  static RECOMMENDED_MONOLOGUE_LENGTH = 2 * 60;

  static RECOMMENDED_CUSTOMER_STORY_LENGTH = 1 * 60;

  static RECOMMENDED_TALK_TIME_MIN = 40;

  static RECOMMENDED_TALK_TIME_MAX = 60;

  static RECOMMENDED_QUESTIONS_ASKED = 5;

  static unwrapMonologueData = ({ data, chartType }) => {
    const { agg_data: aggData, user_data: userData } = data;

    if (_.isEmpty(aggData) && _.isEmpty(userData)) {
      return null;
    }

    const queryData =
      aggData[ConversationDashboardHelper.FACETS[chartType].longestMonologue] &&
      aggData[ConversationDashboardHelper.FACETS[chartType].longestMonologue][0]
        .data;

    if (_.isEmpty(queryData)) {
      return null;
    }
    return queryData.speaker_info && queryData.speaker_info.buckets;
  };

  static unwrapCustomerStoryData = ({ data, chartType }) => {
    const { agg_data: aggData, user_data: userData } = data;

    if (_.isEmpty(aggData) && _.isEmpty(userData)) {
      return null;
    }

    return (
      aggData[
        ConversationDashboardHelper.FACETS[chartType].longestCustomerStory
      ] &&
      aggData[
        ConversationDashboardHelper.FACETS[chartType].longestCustomerStory
      ].user_data
    );
  };

  static unwrapLongestTalktimeData = ({ data, chartType }) => {
    const { agg_data: aggData, user_data: userData } = data;

    if (_.isEmpty(aggData) && _.isEmpty(userData)) {
      return null;
    }

    const key = ConversationDashboardHelper.FACETS[chartType].talkTime;
    switch (chartType) {
      case Dashboard.chartTypes.leaderboard:
        return aggData[key].user_data;
      case Dashboard.chartTypes.breakdown:
      default: {
        const queryData = aggData[key] && aggData[key][0].data;
        if (_.isEmpty(queryData)) {
          return null;
        }
        return queryData.speaker_info && queryData.speaker_info.buckets;
      }
    }
  };

  static unwrapRepQuestionsData = ({ data, chartType }) => {
    const { agg_data: aggData, user_data: userData } = data;

    if (_.isEmpty(aggData) && _.isEmpty(userData)) {
      return null;
    }

    const queryData =
      aggData[ConversationDashboardHelper.FACETS[chartType].repQuestions] &&
      aggData[ConversationDashboardHelper.FACETS[chartType].repQuestions][0]
        .data;

    if (_.isEmpty(queryData)) {
      return null;
    }
    return queryData.speaker_info && queryData.speaker_info.buckets;
  };

  static unwrapClientQuestionsData = ({ data, chartType }) => {
    const { agg_data: aggData, user_data: userData } = data;

    if (_.isEmpty(aggData) && _.isEmpty(userData)) {
      return null;
    }

    const queryData =
      aggData[ConversationDashboardHelper.FACETS[chartType].clientQuestions] &&
      aggData[ConversationDashboardHelper.FACETS[chartType].clientQuestions]
        .data &&
      aggData[ConversationDashboardHelper.FACETS[chartType].clientQuestions]
        .data.buckets;

    if (_.isEmpty(queryData)) {
      return null;
    }

    const longKey = 'non_rep_question_count_per_user_per_meeting';

    const resultData = {};

    queryData.forEach(obj => {
      resultData[obj.key] =
        (obj[longKey] &&
          obj[longKey].speaker_info &&
          obj[longKey].speaker_info.buckets &&
          obj[longKey].speaker_info.buckets.machine_notes) ||
        {};
    });
    return resultData;
  };

  static unwrapFillerWordsData = ({ data, chartType }) => {
    const { agg_data: aggData, user_data: userData } = data;

    if (_.isEmpty(aggData) && _.isEmpty(userData)) {
      return null;
    }

    const speakersData =
      aggData[ConversationDashboardHelper.FACETS[chartType].fillers] &&
      aggData[ConversationDashboardHelper.FACETS[chartType].fillers].speakers;

    if (_.isEmpty(speakersData)) {
      return null;
    }
    return _.keyBy(speakersData.buckets || [], 'key');
  };

  static unwrapFillerWordsTrends = ({ data, chartType }) => {
    const { agg_data: aggData = {} } = data;
    let { user_data: userData = {} } = data;
    userData = _.keyBy(userData, 'email');

    const trends =
      aggData[ConversationDashboardHelper.FACETS[chartType].fillers] &&
      aggData[ConversationDashboardHelper.FACETS[chartType].fillers].buckets;
    if (_.isEmpty(trends)) {
      return null;
    }

    const result = {};

    trends.forEach(obj => {
      const date = new Date(obj.key);
      const {
        speakers: { buckets = [] }
      } = obj.filler_words;
      buckets.forEach(spk => {
        const displayName = userData[spk.key]
          ? `${userData[spk.key].first_name} ${userData[spk.key].last_name}`
          : spk.key;
        if (!(displayName in result)) {
          result[displayName] = [];
        }
        const {
          keyword_filter: { buckets: fillerBuckets = {} }
        } = spk;
        const fillers = Object.entries(fillerBuckets).map(([key, value]) => ({
          key,
          docCount: value.avg_mentions
        }));
        result[displayName].push({
          x: date,
          y: Math.round(
            (spk.user_avg_mentions && spk.user_avg_mentions.value) || 0
          ),
          fillers
        });
      });
    });
    return result;
  };

  // static unwrapPopularFillerWords = ({ data, chartType }) => {};

  static unwrapFillerWordSpeakers = ({ data, chartType }) => {
    const { agg_data: aggData = {} } = data;
    let { user_data: userData = {} } = data;
    userData = _.keyBy(userData, 'email');

    const trends =
      aggData[ConversationDashboardHelper.FACETS[chartType].fillers] &&
      aggData[ConversationDashboardHelper.FACETS[chartType].fillers].buckets;
    if (_.isEmpty(trends)) {
      return [];
    }
    const speakers = {};
    trends.forEach(obj => {
      const {
        speakers: { buckets = [] }
      } = obj.filler_words;
      buckets.forEach(spk => {
        const displayName = userData[spk.key]
          ? `${userData[spk.key].first_name} ${userData[spk.key].last_name}`
          : spk.key;
        if (displayName in speakers) {
          speakers[displayName] += spk.user_avg_mentions
            ? spk.user_avg_mentions.value
            : 0;
        } else {
          speakers[displayName] = spk.user_avg_mentions
            ? spk.user_avg_mentions.value
            : 0;
        }
      });
    });
    return _.orderBy(Object.entries(speakers), arr => arr[1], 'desc').map(
      arr => arr[0]
    );
  };

  static getAggFillerWordsByDate = ({ data, aggOver }) => {
    let index = 0;
    const uniqueBuckets = {};
    const result = {};
    data.forEach(obj => {
      const buckets = obj[aggOver];
      buckets.forEach(b => {
        if (!b.key) {
          return;
        }
        if (b.docCount && !(b.key in uniqueBuckets)) {
          uniqueBuckets[b.key] = {
            label: b.key,
            color: Dashboard.colors[index % Dashboard.colors.length]
          };
          index += 1;
        }
        if (!(b.key in result)) {
          result[b.key] = [];
        }
        result[b.key].push({
          x: obj.x,
          y: Math.round(b.docCount)
        });
      });
    });
    return {
      buckets: uniqueBuckets,
      counts: _.pick(result, Object.keys(uniqueBuckets))
    };
  };

  static unwrapUserData = ({ data }) => {
    const { user_data: userData } = data;

    return _.zipObject(_.map(userData, 'email'), userData);
  };

  static averageLongestMonologue = ({ data }) => {
    const { agg_data: aggData, user_data: userData } = data;

    if (_.isEmpty(aggData) && _.isEmpty(userData)) {
      return null;
    }

    const meetingFacet =
      ConversationDashboardHelper.FACETS.breakdown.longestMonologue;
    const userFacet =
      ConversationDashboardHelper.FACETS.leaderboard.longestMonologue;

    return (
      (aggData[meetingFacet] &&
        aggData[meetingFacet][0] &&
        aggData[meetingFacet][0].data &&
        aggData[meetingFacet][0].data.avg_max_monologue_seconds &&
        aggData[meetingFacet][0].data.avg_max_monologue_seconds.value) ||
      (aggData[userFacet] &&
        aggData[userFacet][0] &&
        aggData[userFacet][0].data &&
        aggData[userFacet][0].data.avg_max_monologue_seconds &&
        aggData[userFacet][0].data.avg_max_monologue_seconds.value)
    );
  };

  static averageLongestCustomerStory = ({ data }) => {
    const { agg_data: aggData, user_data: userData } = data;

    if (_.isEmpty(aggData) && _.isEmpty(userData)) {
      return null;
    }

    const meetingFacet =
      ConversationDashboardHelper.FACETS.breakdown.longestCustomerStory;
    const userFacet =
      ConversationDashboardHelper.FACETS.leaderboard.longestCustomerStory;

    return (
      (aggData[meetingFacet] && aggData[meetingFacet].avg_max_customer_story) ||
      (aggData[userFacet] && aggData[userFacet].avg_max_customer_story)
    );
  };

  static averageTalktime = ({ data }) => {
    const { agg_data: aggData, user_data: userData } = data;

    if (_.isEmpty(aggData) && _.isEmpty(userData)) {
      return null;
    }

    const meetingFacet = ConversationDashboardHelper.FACETS.breakdown.talkTime;
    const userFacet = ConversationDashboardHelper.FACETS.leaderboard.talkTime;

    return (
      (aggData[meetingFacet] &&
        aggData[meetingFacet][0] &&
        aggData[meetingFacet][0].data &&
        aggData[meetingFacet][0].data.avg_talk_percent_across_all_users &&
        aggData[meetingFacet][0].data.avg_talk_percent_across_all_users
          .value) ||
      (aggData[userFacet] &&
        aggData[userFacet].avg_talk_percent_across_all_users)
    );
  };

  static averageRepQuestions = ({ data }) => {
    const { agg_data: aggData, user_data: userData } = data;

    if (_.isEmpty(aggData) && _.isEmpty(userData)) {
      return null;
    }

    const meetingFacet =
      ConversationDashboardHelper.FACETS.breakdown.repQuestions;
    const userFacet =
      ConversationDashboardHelper.FACETS.leaderboard.repQuestions;

    return (
      (aggData[meetingFacet] &&
        aggData[meetingFacet][0] &&
        aggData[meetingFacet][0].data &&
        aggData[meetingFacet][0].data.avg_question_count_per_user &&
        aggData[meetingFacet][0].data.avg_question_count_per_user.value) ||
      (aggData[userFacet] &&
        aggData[userFacet][0] &&
        aggData[userFacet][0].data &&
        aggData[userFacet][0].data.avg_question_count_per_user &&
        aggData[userFacet][0].data.avg_question_count_per_user.value)
    );
  };

  static averageClientQuestions = ({ data }) => {
    const { agg_data: aggData, user_data: userData } = data;

    if (_.isEmpty(aggData) && _.isEmpty(userData)) {
      return null;
    }

    const meetingFacet =
      ConversationDashboardHelper.FACETS.breakdown.clientQuestions;
    const userFacet =
      ConversationDashboardHelper.FACETS.leaderboard.clientQuestions;

    return (
      (aggData[meetingFacet] &&
        aggData[meetingFacet].data &&
        aggData[meetingFacet].data.avg_question_count_across_users) ||
      (aggData[userFacet] &&
        aggData[userFacet].data &&
        aggData[userFacet].data.avg_question_count_across_users)
    );
  };

  static averageTotalRepQuestions = ({ data }) => {
    const { agg_data: aggData, user_data: userData } = data;

    if (_.isEmpty(aggData) && _.isEmpty(userData)) {
      return null;
    }
    const meetingFacet =
      ConversationDashboardHelper.FACETS.breakdown.repQuestions;
    const userFacet =
      ConversationDashboardHelper.FACETS.leaderboard.repQuestions;

    return (
      (aggData[meetingFacet] &&
        aggData[meetingFacet][0] &&
        aggData[meetingFacet][0].data &&
        aggData[meetingFacet][0].data.avg_question_count &&
        aggData[meetingFacet][0].data.avg_question_count.value) ||
      (aggData[userFacet] &&
        aggData[userFacet][0] &&
        aggData[userFacet][0].data &&
        aggData[userFacet][0].data.avg_question_count &&
        aggData[userFacet][0].data.avg_question_count.value)
    );
  };

  static averageTotalClientQuestions = ({ data }) => {
    const { agg_data: aggData, user_data: userData } = data;

    if (_.isEmpty(aggData) && _.isEmpty(userData)) {
      return null;
    }
    const meetingFacet =
      ConversationDashboardHelper.FACETS.breakdown.clientQuestions;
    const userFacet =
      ConversationDashboardHelper.FACETS.leaderboard.clientQuestions;

    return (
      (aggData[meetingFacet] &&
        aggData[meetingFacet].data &&
        aggData[meetingFacet].data.avg_question_asked_per_user) ||
      (aggData[userFacet] &&
        aggData[userFacet].data &&
        aggData[userFacet].data.avg_question_asked_per_user)
    );
  };

  static averageFillerWords = ({ data }) => {
    const { agg_data: aggData, user_data: userData } = data;

    if (_.isEmpty(aggData) && _.isEmpty(userData)) {
      return null;
    }

    const userFacet = ConversationDashboardHelper.FACETS.leaderboard.fillers;
    const trendsFacet = ConversationDashboardHelper.FACETS.trends.fillers;

    return (
      (aggData[userFacet] &&
        aggData[userFacet].total_avg_mentions &&
        aggData[userFacet].total_avg_mentions.value) ||
      (aggData[trendsFacet] &&
        aggData[trendsFacet].total_avg_mentions &&
        aggData[trendsFacet].total_avg_mentions.value)
    );
  };

  static longestCustomerStory = ({ data, chartType }) => {
    let longest = 0;
    switch (chartType) {
      case Dashboard.chartTypes.leaderboard:
        _.forEach(data, user => {
          if (
            user &&
            user.longest_customer_story_seconds &&
            user.longest_customer_story_seconds >= longest
          ) {
            longest = user.longest_customer_story_seconds;
          }
        });
        break;
      case Dashboard.chartTypes.breakdown:
      default:
        _.forEach(data, user => {
          if (user && user.data) {
            _.forEach(user.data, meeting => {
              if (
                meeting &&
                meeting.longest_customer_story_seconds &&
                meeting.longest_customer_story_seconds >= longest
              ) {
                longest = meeting.longest_customer_story_seconds;
              }
            });
          }
        });
        break;
    }
    return longest;
  };

  static maxAvgQuestions = ({ data, chartType }) => {
    let maxCount = 0;
    switch (chartType) {
      case Dashboard.chartTypes.leaderboard:
        _.forEach(data, user => {
          if (
            user &&
            user.avg_questions_per_meeting &&
            user.avg_questions_per_meeting.value &&
            user.avg_questions_per_meeting.value >= maxCount
          ) {
            maxCount = user.avg_questions_per_meeting.value;
          }
        });
        break;
      case Dashboard.chartTypes.breakdown:
      default:
        _.forEach(data, user => {
          if (user && user.meeting_ids && user.meeting_ids.buckets) {
            _.forEach(user.meeting_ids.buckets, meeting => {
              if (
                meeting &&
                meeting.avg_questions_per_meeting &&
                meeting.avg_questions_per_meeting.value &&
                meeting.avg_questions_per_meeting.value >= maxCount
              ) {
                maxCount = meeting.avg_questions_per_meeting.value;
              }
            });
          }
        });
        break;
    }
    return maxCount;
  };

  static maxQuestions = ({ data, chartType }) => {
    let maxCount = 0;
    switch (chartType) {
      case Dashboard.chartTypes.leaderboard:
        _.forEach(data, user => {
          if (
            user &&
            user.question_count &&
            user.question_count.value &&
            user.question_count.value >= maxCount
          ) {
            maxCount = user.question_count.value;
          }
        });
        break;
      case Dashboard.chartTypes.breakdown:
      default:
        _.forEach(data, user => {
          if (user && user.meeting_ids && user.meeting_ids.buckets) {
            _.forEach(user.meeting_ids.buckets, meeting => {
              if (
                meeting &&
                meeting.question_count &&
                meeting.question_count.value &&
                meeting.question_count.value >= maxCount
              ) {
                maxCount = meeting.question_count.value;
              }
            });
          }
        });
        break;
    }
    return maxCount;
  };

  static maxAvgFillerWords = ({ data, chartType }) => {
    let maxCount = 0;
    switch (chartType) {
      case Dashboard.chartTypes.leaderboard:
        _.forEach(data, user => {
          if (
            user &&
            user.user_avg_mentions &&
            user.user_avg_mentions.value &&
            user.user_avg_mentions.value >= maxCount
          ) {
            maxCount = user.user_avg_mentions.value;
          }
        });
        break;
      default:
        break;
    }
    return maxCount;
  };

  static talkListenWithinRange(value) {
    return (
      ConversationDashboardHelper.RECOMMENDED_TALK_TIME_MIN <= value &&
      value <= ConversationDashboardHelper.RECOMMENDED_TALK_TIME_MAX
    );
  }
}

export default ConversationDashboardHelper;
