import castArray from 'lodash/castArray';
import { Transforms, Node } from 'slate';

/**
 * Unwrap nodes by type
 */
export const unwrapNodesByType = (editor, types, options = {}) => {
  const casted = castArray(types);
  if (options.at) {
    // If given a location. only unwrap if its the right type
    const node = Node.get(editor, options.at);
    if (casted.includes(node.type)) {
      Transforms.unwrapNodes(editor, options);
    }
  } else {
    Transforms.unwrapNodes(editor, {
      match: n => casted.includes(n.type),
      ...options
    });
  }
};
