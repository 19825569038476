import PropTypes from 'prop-types';
import React from 'react';

import Variation from './Variation';

const VariationGroup = ({
  word,
  isKeyword,
  onClickDeleteVariation,
  onUpdateLabel,
  disableAddInput,
  onClickMakePrimary,
  variations,
  isMakePrimaryActionAllowed = true,
  isUnifiedFlow = false
}) => (
  // Should include the primary keyword as well
  <React.Fragment>
    <Variation
      word={word}
      onUpdateLabel={onUpdateLabel}
      disableAddInput={disableAddInput}
      isKeyword={isKeyword}
      {...(isUnifiedFlow ? { isMakePrimaryActionAllowed: false } : {})}
    />
    {Object.values(variations)?.map(variation => (
      <Variation
        key={variation.uuid}
        word={variation}
        onClickDeleteVariation={onClickDeleteVariation}
        onUpdateLabel={onUpdateLabel}
        disableAddInput={disableAddInput}
        onClickMakePrimary={onClickMakePrimary}
        isKeyword={isKeyword}
        isMakePrimaryActionAllowed={isMakePrimaryActionAllowed}
      />
    ))}
  </React.Fragment>
);

VariationGroup.propTypes = {
  word: PropTypes.object,
  onClickDeleteVariation: PropTypes.func,
  onUpdateLabel: PropTypes.func,
  disableAddInput: PropTypes.func,
  onClickMakePrimary: PropTypes.func,
  variations: PropTypes.object,
  isKeyword: PropTypes.bool,
  isMakePrimaryActionAllowed: PropTypes.bool,
  isUnifiedFlow: PropTypes.bool
};

export default VariationGroup;
