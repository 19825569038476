import React from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import Tokens from 'styles/tokens.js';
import L10n from 'helpers/L10n';
import { twMerge } from 'tailwind-merge';
import { L10Common } from 'helpers/l10n/L10nCommon';

const Switch = ({
  toggled,
  className = '',
  circleClassName = '',
  small = false,
  medium = false,
  style = {},
  circleStyle = {},
  disabled = false,
  color = Tokens.colors.blue,
  onToggle = null,
  tooltip = '',
  tooltipPosition = 'bottom',
  loading = false,
  showOnOff = false,
  wrapperClassName = ''
}) => {
  let extraStyle = {};
  if (toggled) {
    extraStyle = {
      backgroundColor: color,
      justifyContent: 'flex-end'
    };
  }

  // Some use cases require a tooltip to be displayed on switches to better identify their state.
  const tooltipClasses = [className];
  if (tooltip && tooltip.trim().length > 0) {
    tooltipClasses.push('tooltip icon-button-tooltip');
    tooltipClasses.push(tooltipPosition);
  }
  const disabledToggle = disabled || loading;

  if (disabledToggle) {
    // extraStyle.opacity = 0.5;
    extraStyle.backgroundColor = toggled
      ? Tokens.colors.blueLighter
      : Tokens.colors.silverLighter;
    extraStyle.cursor = 'not-allowed';
  }

  return (
    <React.Fragment>
      {loading && <div className='mr-2 text-green'>{L10n.general.saving}</div>}
      <div
        className={twMerge(
          'relative flex w-fit items-center gap-3',
          wrapperClassName
        )}
      >
        {showOnOff && (
          <div
            className={twMerge('text-sm text-silver', toggled && 'text-blue')}
          >
            {toggled ? L10Common.on : L10Common.off}
          </div>
        )}
        <div
          className={twMerge(
            tooltipClasses,
            'relative flex h-5 w-10 cursor-pointer rounded-full bg-silver-lighter p-[2px]',
            loading && 'cursor-wait',
            small && 'h-3 w-6',
            medium && 'h-4 w-8'
          )}
          style={{
            ...extraStyle,
            ...style
          }}
          onClick={disabledToggle || loading ? null : onToggle}
          aria-label={tooltip}
        >
          <div
            className={twMerge(
              circleClassName,
              'h-5 w-5 rounded-full bg-white',
              small && 'h-3 w-3',
              medium && 'h-4 w-4'
            )}
            style={circleStyle}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

Switch.propTypes = {
  toggled: PropTypes.bool.isRequired,
  onToggle: PropTypes.func,
  style: stylePropType,
  disabled: PropTypes.bool,
  circleStyle: stylePropType,
  color: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  circleClassName: PropTypes.string,
  showOnOff: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  wrapperClassName: PropTypes.string
};

export default Switch;
