import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { useGetTicketingListQuery } from 'services/ticketing';

import AvomaNestedTree from 'components/Common/AvomaNestedTree';
import AvomaPopoverControlled from 'components/Common/AvomaPopoverControlled';
import AvomaTooltip from 'components/Common/AvomaTooltip';
import ButtonUnstyled from 'components/Common/ButtonUnstyled';

import L10n from 'helpers/L10n';

import { ReactComponent as DownArrowIcon } from 'images/downArrow.svg';
import { ReactComponent as FolderIcon } from 'images/ic_clickup_folder.svg';
import { ReactComponent as ListIcon } from 'images/ic_clickup_list.svg';
import { ReactComponent as SpacesIcon } from 'images/ic_clickup_spaces.svg';

const icons = {
  space: <SpacesIcon className='h-3 w-3' />,
  folder: <FolderIcon className='h-3 w-3' />,
  list: <ListIcon className='h-3 w-3' />
};

const ClickupIntegrationAutomationsSelectList = ({
  workspaceUuid,
  clientValue,
  handleValueChange,
  isEdit,
  portalContainer,
  isRequired
}) => {
  const { uuid: ticketingProviderUuid } = useSelector(
    state => state.user?.ticketingProviders?.clickup || {}
  );
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const mounted = useRef();
  const { data } = useGetTicketingListQuery(
    {
      providerUuid: ticketingProviderUuid,
      workspaceUuid
    },
    {
      skip: !ticketingProviderUuid || !workspaceUuid
    }
  );

  function addIconToList(list) {
    list.forEach(item => {
      item.icon = icons[item.collectionType];
      item.label = item.name;
      item.isSelectable = item.collectionType === 'list';
      if (item.children && item.children.length > 0) {
        addIconToList(item.children);
      }
    });
  }

  const cloneData = useMemo(
    () => JSON.parse(JSON.stringify(data || [])),
    [data]
  );

  addIconToList(cloneData);

  // Flatten the array to find selected list for label and display purposes
  const flattenArray = arr =>
    arr.reduce((result, obj) => {
      const { children, collectionType, uuid, ...rest } = obj;
      return result.concat(
        [{ collection_type: collectionType, uuid, ...rest }],
        flattenArray(children)
      );
    }, []);

  const flattenedArray = flattenArray(cloneData || []);
  const selectedList = flattenedArray.find(item => item.uuid === clientValue);

  useEffect(() => {
    if (mounted.current) {
      // If the selected list is not in the new list, reset the selected list
      if (!selectedList && isEdit) {
        handleValueChange('');
      }
    } else {
      mounted.current = true;
    }
  }, [selectedList]);

  const renderTicketListTrigger = () => (
    <AvomaTooltip tooltip={!data && L10n.clickupIntegration.workspaceTooltip}>
      <ButtonUnstyled
        noDefaultStyles
        disabled={!data}
        className={twMerge(
          'flex w-full items-center justify-between gap-2 rounded border border-gainsboro bg-white py-2 px-3',
          !data ? 'cursor-not-allowed opacity-40' : 'cursor-pointer opacity-100'
        )}
      >
        <span
          className={twMerge(
            'text-sm',
            selectedList ? 'text-gunmetal' : 'text-silver'
          )}
        >
          {selectedList ? selectedList.label : 'Select a list'}
        </span>
        <DownArrowIcon className='h-2 w-2' />
      </ButtonUnstyled>
    </AvomaTooltip>
  );

  const handleClick = item => {
    handleValueChange(item);
    setIsPopoverOpen(false);
  };

  return (
    <div className='flex w-full flex-col gap-2'>
      <p className='flex items-center text-sm font-bold text-gunmetal'>
        {L10n.clickupIntegration.list}{' '}
        {isRequired && <span className='ml-1 text-avoma-red'>*</span>}
      </p>
      <AvomaPopoverControlled
        dropdownTrigger={renderTicketListTrigger}
        contentClasses='w-[350px] h-[320px] !p-0 overflow-y-auto z-[999999]'
        portalContainer={portalContainer}
        controlled
        open={isPopoverOpen}
        setOpen={setIsPopoverOpen}
      >
        <p className='flex items-center p-2 text-sm font-bold text-gunmetal'>
          {L10n.clickupIntegration.selectList}
        </p>
        <AvomaNestedTree
          data={cloneData || []}
          showSearch
          handleClick={handleClick}
          selectedItem={clientValue}
          searchPlaceholder={L10n.clickupIntegration.searchPlaceholder}
        />
      </AvomaPopoverControlled>
    </div>
  );
};

ClickupIntegrationAutomationsSelectList.propTypes = {
  workspaceUuid: PropTypes.string.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  clientValue: PropTypes.string,
  isEdit: PropTypes.bool,
  isRequired: PropTypes.bool,
  portalContainer: PropTypes.element
};

export default ClickupIntegrationAutomationsSelectList;
