import { flattenMeetingAttendees } from './meetingsData';

export const getSortedUsers = (search, activeUsers) => {
  let remainingUsers = [];
  if (!search) remainingUsers = activeUsers;
  let exactMatchUsers = [];

  if (search) {
    const searchLength = search.length;
    exactMatchUsers = activeUsers.filter(activeUserObj => {
      const {
        user: { firstName }
      } = activeUserObj;
      const nameStr = firstName.substring(0, searchLength);
      if (nameStr.toLowerCase() === search.toLowerCase()) {
        return activeUserObj;
      }
      remainingUsers.push(activeUserObj);
      return null;
    });
  }

  const usersWhichHasSearchKeywords = remainingUsers.filter(activeUserObj => {
    const {
      user: { email, lastName, firstName }
    } = activeUserObj;
    const isSearchStringPresentInUser =
      email.toLowerCase().includes(search.toLowerCase()) ||
      `${firstName} ${lastName}`
        .trim()
        .toLowerCase()
        .includes(search.toLowerCase());

    if (isSearchStringPresentInUser) return activeUserObj;

    return null;
  });

  const sortedArray = [...exactMatchUsers, ...usersWhichHasSearchKeywords];
  return sortedArray.map(userObj => userObj.user);
};

export const shouldScrollToNextUser = (
  nextIndex,
  userEls,
  dropDownContainerRef
) => {
  /*
    offsetHeight - is the height of each element in user list
    offsetTop - is the height of element from top of its parent
    clientheight - is the total visible height in scroll

    so if offsetTop of el + offsetHeight of el > clientheight of list div
    it means we have to scroll down the list so that user can see next element
    and by how much, scrollTop + offsetHeight
  */
  const offsetTopOfEl = userEls[nextIndex].offsetTop;
  const offsetHeightOfEl = userEls[nextIndex].offsetHeight;
  const totalVisibleHeight = dropDownContainerRef.clientHeight;
  return offsetTopOfEl + offsetHeightOfEl > totalVisibleHeight;
};

export const shouldScrollToPrevUser = (
  prevIndex,
  userEls,
  dropDownContainerRef
) => {
  /*
    clientheight - is the total visible height in scroll
    offsetHeight - is the height of each element in user list
    scrollHeight - total height of user list div
    offsetTop - is the height of element from top of its parent

    offsetBottom, is not provided by javascript, but we can calculate it
    So if total height (scrollHeight) of user list div is 600 and offsetTop is 300 then
    offsetBottom is scrollHeight - offsetTop => which is 300

    so if offsetBottom > totalVisibleHeight, means scroll up to user
    and how much we have to scroll up scrollTop = scrollTop - offsetHeight
  */
  const totalHeightOfUsersList = dropDownContainerRef.scrollHeight;
  const offsetTopOfEle = userEls[prevIndex].offsetTop;
  const offsetBottom = totalHeightOfUsersList - offsetTopOfEle;
  const totalVisibleHeightOfList = dropDownContainerRef.clientHeight;

  return offsetBottom > totalVisibleHeightOfList;
};

// getting all shared user emails
export const getSharedUsersEmail = meeting => {
  const { share } = meeting;
  const attendees = flattenMeetingAttendees(meeting);
  const attendeesEmails = attendees.map(attendee => attendee.email);
  const shareEmails = share?.emails?.map(sharedUser => sharedUser.email) || [];
  return [...new Set([...attendeesEmails, ...shareEmails])];
};

export const getSharedTeams = meeting => {
  const { share } = meeting;

  return share.teams.map(team => team.team.uuid);
};

export const validURL = str => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const isEmail = str => {
  const pattern = new RegExp(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g
  );
  return !!pattern.test(str);
};
