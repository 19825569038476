import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import stylePropType from 'react-style-proptype';
import { twMerge } from 'tailwind-merge';

import useHover from 'hooks/useHover';

import AvomaPopover from 'components/Common/AvomaPopover';
import ButtonUnstyled from 'components/Common/ButtonUnstyled';

import { mobile } from 'helpers/deviceCutoffs';

// eslint-disable-next-line avoma/use-svgr-over-svg
import HelpIcon from 'images/ic_nav_feedback_dark.svg';

import Tokens from 'styles/tokens';

const HelpPopover = props => {
  const {
    title,
    buttonStyles,
    content,
    popoverStyles,
    showIcon,
    showLabel,
    label,
    popoverWidth,
    position,
    customIcon,
    labelClassName = '',
    iconClassName = '',
    buttonClassName = ''
  } = props;
  const [hovered, eventHandlers] = useHover();

  return (
    <ButtonUnstyled
      {...eventHandlers}
      style={{ ...styles.button, ...buttonStyles }}
      className={buttonClassName}
    >
      {showIcon &&
        (customIcon ?? (
          <img
            src={HelpIcon}
            alt='Help'
            className={twMerge('h-4 w-4', iconClassName)}
          />
        ))}
      {showLabel && <span className={labelClassName}>{label}</span>}

      <AvomaPopover
        width={popoverWidth}
        position={position}
        wrapperStyle={{
          ...styles.popover,
          ...popoverStyles,
          display: hovered ? 'block' : 'none'
        }}
        style={{ padding: 0 }}
      >
        {title && <div style={styles.title}>{title}</div>}
        <div style={styles.content}>{content}</div>
        <div style={styles.spacer} />
      </AvomaPopover>
    </ButtonUnstyled>
  );
};

const styles = {
  popover: {
    position: 'absolute',
    zIndex: 20,
    textAlign: 'left',
    marginTop: 0,
    [`@media (max-width: ${mobile}px)`]: {
      transform: 'translateX(-75%)'
    }
  },
  title: {
    ...Tokens.type.body,
    fontWeight: 'bold',
    color: Tokens.colors.black,
    padding: `${Tokens.spacing.one} ${Tokens.spacing.two}`,
    borderBottom: `1px solid ${Tokens.colors.gainsboro}`,
    backgroundColor: Tokens.colors.snow,
    zIndex: 10
  },
  content: {
    ...Tokens.type.bodyS,
    color: Tokens.colors.gunmetal,
    padding: `${Tokens.spacing.one} ${Tokens.spacing.two}`
  },
  button: {
    marginTop: Tokens.spacing.half,
    color: Tokens.colors.blue
  },
  img: {
    height: Tokens.spacing.two,
    width: Tokens.spacing.two
  },
  spacer: {
    height: Tokens.spacing.two,
    margin: `0 0 -${Tokens.spacing.two} 0`,
    background: 'transparent'
  }
};

HelpPopover.propTypes = {
  title: PropTypes.string,
  buttonStyles: stylePropType,
  popoverStyles: stylePropType,
  content: PropTypes.node,
  showIcon: PropTypes.bool,
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  popoverWidth: PropTypes.number,
  position: PropTypes.oneOf(['top', 'bottom']),
  customIcon: PropTypes.node,
  labelClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  buttonClassName: PropTypes.string
};

HelpPopover.defaultProps = {
  showIcon: true,
  showLabel: false,
  label: '',
  popoverWidth: 350,
  position: 'bottom'
};

export default Radium(HelpPopover);
