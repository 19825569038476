import { AudioPlayerActions } from 'actions/actionTypes';

export const initialPlayerState = {
  meetingUuid: null,
  recording: null,
  time: 0,
  playing: false,
  playbackRate: 1
  // ADD OTHERS AUDIO STUFF HERE LATER TOO: fullscreen/playbackspeed
};

/*

*/
const app = (state = initialPlayerState, action) => {
  switch (action.type) {
    case AudioPlayerActions.AUDIO_PLAYER_MOUNT:
      return {
        ...state,
        meetingUuid: action.meetingUuid,
        recording: action.recording,
        time: 0,
        playing: false
      };
    case AudioPlayerActions.AUDIO_PLAYER_PLAY:
      return {
        ...state,
        playing: true
      };
    case AudioPlayerActions.AUDIO_PLAYER_PAUSE:
      return {
        ...state,
        playing: false
      };
    case AudioPlayerActions.PLAYBACK_RATE:
      return {
        ...state,
        playbackRate: action.playbackRate
      };
    case AudioPlayerActions.AUDIO_PLAYER_UPDATE_TIME:
      return {
        ...state,
        time: action.time
      };
    case AudioPlayerActions.QUEUE_HIGHLIGHT:
      return {
        ...state,
        playingHighlight: {
          ...action.highlight,
          played: false
        }
      };
    case AudioPlayerActions.HIGHLIGHT_SEEKED:
      return {
        ...state,
        playingHighlight: {
          ...state.playingHighlight,
          seeked: true
        }
      };
    case AudioPlayerActions.REMOVE_PLAY_HIGHLIGHT:
      return {
        ...state,
        playingHighlight: null
      };
    case AudioPlayerActions.AUDIO_PLAYER_UNMOUNT:
      if (action.meetingUuid !== state.meetingUuid) {
        return state;
      }
      return {
        ...state,
        meetingUuid: null,
        recording: null,
        time: 0,
        playing: false
      };
    default:
      return state;
  }
};

export default app;
