import Radium from 'radium';
import { flash } from 'react-animations';

import { monsoon, primaryRed } from 'helpers/colors';

import Tokens from 'styles/tokens';

const MeetingState = {
  default: 'default',
  created: 'created',
  parsed: 'parsed',
  error: 'error',
  systemError: 'sys_error',
  disabled: 'disabled',
  infoMissing: 'info_missing',
  enabled: 'enabled',
  waitingForRecording: 'waiting_for_rec',
  taskNotReceived: 'task_not_received',
  taskReceived: 'task_received',
  recordingDownloaded: 'rec_downloaded',
  downloadError: 'download_error',
  silenceRemoved: 'silence_removed',
  audioReady: 'audio_ready',
  transStarted: 'trans_started',
  transReady: 'trans_ready',
  transError: 'trans_error',
  correctionDone: 'correction_done',
  speakerDiStart: 'speaker_di_start',
  speakerDiReady: 'speaker_di_ready',
  speakerDiFullReady: 'speaker_di_full_ready',
  speakerDiError: 'speaker_di_error',
  videoStart: 'video_start',
  videoReady: 'video_ready',
  videoError: 'video_error',
  botJoining: 'bot_joining',
  botRecStart: 'bot_rec_start',
  botRecPause: 'bot_rec_pause',
  botRecResume: 'bot_rec_resume',
  botRecStopping: 'bot_rec_stopping',
  botRecStop: 'bot_rec_stop',
  botKicked: 'bot_kicked',
  botDropping: 'bot_dropping',
  botPostProcessingStart: 'bot_post_processing_start',
  botPostProcessingDone: 'bot_post_processing_done',
  botDone: 'bot_done',
  zoomMeetingStart: 'zoom_meeting_start',
  zoomMeetingEnd: 'zoom_meeting_end',
  zoomRecStart: 'zoom_recording_start',
  zoomRecStop: 'zoom_recording_stop',
  zoomRecTooShort: 'rec_too_short',
  zoomDownloadFailed: 'download_failed',
  zoomInvalidVideo: 'invalid_video',
  silentRec: 'silent_rec',
  botWaitingForApproval: 'bot_waiting_for_approval',
  botWaitingForApprovalTimeout: 'bot_waiting_for_approval_timeout',
  botWaitingForHost: 'bot_waiting_for_host',
  botWaitingForHostTimeout: 'bot_waiting_for_host_timeout',
  botRecordingError: 'bot_recording_error',
  invalidDetails: 'bot_invalid_rec_details',
  deniedEntry: 'bot_denied_entry',
  noParticipant: 'bot_no_participant',
  zoomMeetingNotRecorded: 'zoom_meeting_not_recorded',
  botRecordingConsentDenied: 'bot_recording_consent_denied',
  zoomRecordingNotReceived: 'zoom_rec_not_recd',
  zoomMeetingDidNotStart: 'zoom_meeting_did_not_start',
  noAvomaUser: 'no_avoma_user',
  noTranscriptAvailable: 'no_transcript',
  botKickedBeforeRecording: 'bot_kicked_before_recording',
  botMeetingSilence: 'bot_meeting_silence',
  botHostInAnotherMeeting: 'bot_host_in_another_meeting',
  invalidRecordingDetails: 'bot_invalid_rec_details',
  noRecording: 'no_recording',
  limitExceeded: 'limit_exceeded',
  duplicateRecording: 'duplicate_recording',
  msTeamsRecordingStop: 'ms_teams_recording_stop',
  msTeamsRecordingStart: 'ms_teams_recording_start',
  msTeamsRecNotRecd: 'ms_teams_rec_not_recd',
  msTeamsMeetingStart: 'ms_teams_meeting_start',
  msTeamsMeetingNotRecorded: 'ms_teams_meeting_not_recorded',
  msTeamsMeetingEnd: 'ms_teams_meeting_end',
  msTeamsMeetingDidNotStart: 'ms_teams_meeting_did_not_start',
  googleMeetRecNotRecd: 'google_meet_rec_not_recd',
  googleMeetMeetingStart: 'google_meet_meeting_start',
  googleMeetMeetingNotRecorded: 'google_meet_meeting_not_recorded',
  googleMeetMeetingEnd: 'google_meet_meeting_end',
  googleMeetMeetingDidNotStart: 'google_meet_meeting_did_not_start',
  uploadError: 'upload_error'
};

const styles = {
  greenDot: {
    width: '5px',
    height: '5px',
    backgroundColor: '#7ed321',
    boxShadow: `0 0 0 1px #7ed321`,
    borderRadius: '50%',
    border: `2px solid ${Tokens.colors.white}`
  },
  yellowDot: {
    width: '5px',
    height: '5px',
    backgroundColor: Tokens.colors.yellow,
    boxShadow: `0 0 0 1px ${Tokens.colors.yellow}`,
    borderRadius: '50%',
    border: `2px solid ${Tokens.colors.white}`
  },
  redDot: {
    width: '5px',
    height: '5px',
    backgroundColor: primaryRed,
    boxShadow: `0 0 0 1px ${primaryRed}`,
    borderRadius: '50%',
    border: `2px solid ${Tokens.colors.white}`
  },
  blankDot: {
    width: '5px',
    height: '5px',
    backgroundColor: monsoon,
    boxShadow: `0 0 0 1px ${monsoon}`,
    borderRadius: '50%',
    border: `2px solid ${Tokens.colors.white}`
  },
  errorIndicator: {
    width: '5px',
    height: '5px',
    backgroundColor: Tokens.colors.red,
    boxShadow: `0 0 0 1px ${Tokens.colors.red}`,
    borderRadius: '50%',
    border: `2px solid ${Tokens.colors.white}`
  },
  blink: {
    animation: '5s infinite',
    animationName: Radium.keyframes(flash, 'flash')
  }
};
styles.emptyDot = styles.blankDot;

const getStyles = (states, recording = null, stylesMap) => {
  switch (states.meeting?.key) {
    case MeetingState.transError:
    case MeetingState.downloadError:
    case MeetingState.speakerDiError:
    case MeetingState.systemError:
    case MeetingState.videoError:
    case MeetingState.uploadError:
      return stylesMap.errorIndicator;

    case MeetingState.enabled:
    case MeetingState.created:
    case MeetingState.default:
    case MeetingState.parsed:
    case MeetingState.botJoining:
    case MeetingState.zoomMeetingStart:
    case MeetingState.msTeamsMeetingStart:
    case MeetingState.googleMeetMeetingStart:
      return stylesMap.greenDot;

    case MeetingState.botRecStart:
    case MeetingState.botRecResume:
    case MeetingState.botRecStopping:
    case MeetingState.botRecStop:
    case MeetingState.botDropping:
    case MeetingState.botKicked:
    case MeetingState.zoomRecStart:
    case MeetingState.zoomRecStop:
    case MeetingState.msTeamsRecordingStart:
    case MeetingState.msTeamsRecordingStop:
      return { ...stylesMap.redDot, ...stylesMap.blink };

    case MeetingState.transReady:
    case MeetingState.correctionDone:
      if (recording && recording.duration === 0) {
        return stylesMap.blankDot;
      }
      return stylesMap.greenDot;

    case MeetingState.disabled:
    case MeetingState.infoMissing:
    case MeetingState.error:
      return stylesMap.blankDot;

    case MeetingState.audioReady:
    case MeetingState.botDone:
    case MeetingState.botPostProcessingDone:
    case MeetingState.botPostProcessingStart:
    case MeetingState.waitingForRecording:
    case MeetingState.botRecPause:
    case MeetingState.recordingDownloaded:
    case MeetingState.silenceRemoved:
    case MeetingState.speakerDiReady:
    case MeetingState.speakerDiFullReady:
    case MeetingState.speakerDiStart:
    case MeetingState.taskNotReceived:
    case MeetingState.taskReceived:
    case MeetingState.transStarted:
    case MeetingState.videoStart:
    case MeetingState.zoomMeetingEnd:
    case MeetingState.msTeamsMeetingEnd:
    case MeetingState.googleMeetMeetingEnd:
      return stylesMap.yellowDot;
    default:
      return stylesMap.emptyDot;
  }
};

export const getStateStyles = (states, recording = null) =>
  getStyles(states, recording, styles);

export const isMeetingProcessing = states =>
  [
    MeetingState.botRecStop,
    MeetingState.botRecStopping,
    MeetingState.botDropping,
    MeetingState.botKicked,
    MeetingState.audioReady,
    MeetingState.botDone,
    MeetingState.botPostProcessingDone,
    MeetingState.botPostProcessingStart,
    MeetingState.waitingForRecording,
    MeetingState.recordingDownloaded,
    MeetingState.silenceRemoved,
    MeetingState.taskNotReceived,
    MeetingState.taskReceived,
    MeetingState.transStarted,
    MeetingState.zoomMeetingEnd,
    MeetingState.msTeamsMeetingEnd,
    MeetingState.googleMeetMeetingEnd
  ].includes(states.meeting?.key);

export const isMeetingEnded = states =>
  [
    MeetingState.audioReady,
    MeetingState.botDone,
    MeetingState.botPostProcessingDone,
    MeetingState.botPostProcessingStart,
    MeetingState.waitingForRecording,
    MeetingState.recordingDownloaded,
    MeetingState.silenceRemoved,
    MeetingState.taskReceived,
    MeetingState.transStarted,
    MeetingState.transReady,
    MeetingState.zoomMeetingEnd,
    MeetingState.msTeamsMeetingEnd,
    MeetingState.googleMeetMeetingEnd
  ].includes(states.meeting?.key);

export default MeetingState;
