import PropTypes from 'prop-types';

import AvomaVariableTextArea from 'components/Common/AvomaVariableTextArea';

const AutomationFormTaskDescription = ({
  action,
  handleValueChange,
  clientValue,
  isRequired = false
}) => {
  const handleChange = value => {
    handleValueChange(value);
  };

  return (
    <div className='flex flex-col gap-2'>
      <p className='text-sm font-bold text-gunmetal'>
        {action.label}{' '}
        {isRequired && <span className='ml-1 text-avoma-red'>*</span>}
      </p>
      <AvomaVariableTextArea
        placeholder={`Enter ${action.label}`}
        variables={action.optionChoices}
        variableTitle={action.optionTitle}
        value={clientValue}
        onChange={handleChange}
        wrapperStyle={{ padding: 0 }}
      />
    </div>
  );
};

AutomationFormTaskDescription.propTypes = {
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    optionTitle: PropTypes.string.isRequired,
    optionChoices: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
      })
    ),
    apiUrl: PropTypes.string
  }),
  handleValueChange: PropTypes.func.isRequired,
  clientValue: PropTypes.string,
  isRequired: PropTypes.bool
};

export default AutomationFormTaskDescription;
