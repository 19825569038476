// const CRM = {
//   salesforce: 'salesforce',
//   hubspot: 'hubspot',
// };
import reduxStore from 'helpers/reduxStore';

import { CUSTOM_CATEGORY, SMART_CATEGORY } from 'constants/deals';

import { isAvoma } from './user';

class Crm {
  static ENUM = {
    salesforce: 'salesforce',
    hubspot: 'hubspot',
    zsell: 'zsell',
    copper: 'copper',
    pipedrive: 'pipedrive',
    zoho: 'zoho',
    freshsales: 'freshsales'
  };

  static TAGS = {
    account: 'account',
    oppo: 'oppo',
    oppo_h: 'oppo_h',
    lead: 'lead',
    contact: 'contact'
  };

  static determine = ({
    crmIntegration,
    hubspotIntegration,
    zendesksellIntegration,
    copperIntegration,
    pipedriveIntegration,
    zohoIntegration,
    freshsalesIntegration
  }) => {
    let crm = null;
    if (zendesksellIntegration) {
      crm = Crm.ENUM.zsell;
    } else if (crmIntegration) {
      crm = Crm.ENUM.salesforce;
    } else if (hubspotIntegration) {
      crm = Crm.ENUM.hubspot;
    } else if (copperIntegration) {
      crm = Crm.ENUM.copper;
    } else if (pipedriveIntegration) {
      crm = Crm.ENUM.pipedrive;
    } else if (zohoIntegration) {
      crm = Crm.ENUM.zoho;
    } else if (freshsalesIntegration) {
      crm = Crm.ENUM.freshsales;
    }

    return crm;
  };

  static getCrmLeadName = crmObject => {
    if (crmObject.type && crmObject.type !== 'lead') {
      return null;
    }
    let { name, firstName, lastName, company } = crmObject;
    if (crmObject.data) {
      firstName = crmObject.data.firstName;
      lastName = crmObject.data.lastName;
      company = crmObject.data.company;
      name = crmObject.data.name;
    }
    const names = [firstName, lastName];
    let fullName = '';
    if (name) {
      fullName = name;
    } else {
      fullName = names.join(' ');
    }
    return company ? `${fullName}, ${company}` : fullName;
  };

  static getCrmLeadFullName = crmObject => {
    if (crmObject.type && crmObject.type !== 'lead') {
      return null;
    }

    const { name, firstName, lastName } = crmObject;
    return name || [firstName, lastName].join(' ');
  };
}

export const crmUserConnected = crmProvider => {
  const {
    hubspotUsername,
    zendesksellUsername,
    copperUsername,
    pipedriveUsername,
    zohoUsername,
    salesforceUsername
  } = reduxStore.getState().user;
  let crmUser = '';
  switch (crmProvider) {
    case 'salesforce':
      crmUser = salesforceUsername;
      break;
    case 'zsell':
      crmUser = zendesksellUsername;
      break;
    case 'hubspot':
      crmUser = hubspotUsername;
      break;
    case 'zoho':
      crmUser = zohoUsername;
      break;
    case 'pipedrive':
      crmUser = pipedriveUsername;
      break;
    case 'copper':
      crmUser = copperUsername;
      break;
    default:
      break;
  }

  return crmUser;
};

export const mergeCRMAndAVFields = fields =>
  fields?.map(field => ({
    uuid: field.crmField?.uuid ?? field.avField?.uuid,
    order: field.order,
    groupName:
      field.groupName === CUSTOM_CATEGORY ? SMART_CATEGORY : field.groupName,
    label: field.crmField?.fieldLabel ?? field.avField?.fieldLabel,
    isCrmData: !!field.crmField,
    dataType: field.crmField?.dataType ?? field.avField?.dataType,
    fieldType: field.crmField?.fieldType ?? field.avField?.fieldType,
    isEditable: field.isEditable,
    editableBy: field.crmField?.editableBy,
    sortKey: field.sortKey,
    options: field.crmField?.optionsJson ?? field.avField?.optionsJson ?? [],
    objectType: field.objectType,
    avomaField: field.avField,
    orderBy: field.orderBy,
    referenceKey: field.referenceKey,
    isSummarized: field.isSummarized
  })) ?? [];

export const canGenerateCrmApiKeys = crmProvider =>
  isAvoma() && crmProvider === Crm.ENUM.salesforce;

export default Crm;
