import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Range } from 'slate';
import { ReactEditor, useSelected } from 'slate-react';

import AvomaButton from 'components/Common/AvomaButton';
import TimestampLink from 'components/Common/buttons/TimestampLink';
import AddBlockButton from 'components/Notes/Slate/Components/AddBlockButton';

import L10n from 'helpers/L10n';
import { isEditorEmpty } from 'helpers/SlateHelper/isEditorEmpty';

import Tokens from 'styles/tokens';

import { TYPE_LIST_ITEM } from '../Plugins/List/types';
import { getParentForNode } from '../Plugins/Queries/getParent';
import { TYPE_CHECKLIST_ITEM } from '../Plugins/Types';

const styles = {
  paragraph: {
    fontSize: 'inherit',
    position: 'relative'
  },
  placeholderText: {
    ...Tokens.type.bodyS,
    color: Tokens.colors.silverLight,
    position: 'absolute',
    top: 0
  }
};

export const onKeyDown = event => {
  if (Paragraph.CAPTURE_KEYS) {
    if (event.keyCode === 40 || event.keyCode === 38 || event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
  }
  return true;
};

const Paragraph = ({
  element,
  editor,
  recordingStart,
  recordingDuration,
  onCategorySelected,
  onNoneSelected,
  seenCategories,
  transcriptionReady,
  onClickTranscriptTimestamp,
  children,
  readOnly,
  hideBlockButton,
  showInsertAgendaButton,
  onInsertAgendaSelected,
  placeholder,
  isCollab,
  isTemplateFlow = false,
  currentTemplate,
  meetingUuid
}) => {
  const [hovering, setHover] = useState();
  const { selection } = editor;

  const selected = useSelected();
  // Calculating if whether the timestamps need to be shown when multiple lines are highlighted
  const isPathSame =
    useSelected() && selection.anchor.path === selection.focus.path;

  const showHoverState = () => {
    setHover(true);
  };

  const hideHoverState = () => {
    setHover(false);
  };

  const onCategoriesShown = () => {
    Paragraph.CAPTURE_KEYS = true;
  };

  const onCategoriesHidden = () => {
    Paragraph.CAPTURE_KEYS = false;
  };

  let time = null;

  if (element.data) {
    const updated = element.data.block_updated;
    const updatedTime = Date.parse(updated);
    const delta = (updatedTime - Date.parse(recordingStart)) / 1000;
    if (
      delta > 0 &&
      ([undefined, null].includes(recordingDuration) ||
        delta < recordingDuration)
    ) {
      time = delta;
    }
  }

  const clickTranscriptTimestamp = useCallback(() => {
    if (time && onClickTranscriptTimestamp) {
      onClickTranscriptTimestamp(time);
    }
  }, [onClickTranscriptTimestamp, time]);

  // let focused = false;
  const shouldHighlight = hovering || isPathSame;
  const parent = getParentForNode(editor, element);
  const parentNode = parent?.[0];
  const query = element.children && element.children[0].text;

  let isSelectionCollapsed = true;

  if (selection !== null)
    isSelectionCollapsed = Range.isCollapsed(editor.selection);

  // Check if the new line is empty
  const isEmpty =
    children.length === 1 && children?.[0].props?.text?.text === '';

  const selectCategory = category => {
    if (onCategorySelected) {
      onCategorySelected(category, element);
    }
  };

  const selectAgenda = () => {
    if (onInsertAgendaSelected) {
      onInsertAgendaSelected(element);
    }
  };

  const isInList = () =>
    parentNode?.type === TYPE_LIST_ITEM ||
    parentNode?.type === TYPE_CHECKLIST_ITEM;

  const renderPlaceholder = () => {
    const placeholderText = L10n.notes.smartCategoryPlaceholer;

    const collabPlaceholder = isCollab
      ? `${placeholderText}. '@' to mention a team or member.`
      : placeholderText;

    /*  Display the generic placeholder here instead of through Editable, since this results
        in the placeholders being separate rather than overlay over one another
        The current slate version doesn't allow styling the default placeholder element of Editable
        thus it can't be manipulated to fix our multiple placeholder issue
        This does a basic check if the editor is empty, and if yes, displays the generic placeholder,
        else the Type / to add smart categories placeholder
    */
    if (isEditorEmpty(editor, children) && !ReactEditor.isFocused(editor)) {
      return (
        <span
          style={{ ...styles.placeholderText, pointerEvents: 'none' }}
          className='disable-select'
          contentEditable={false}
        >
          {collabPlaceholder}
        </span>
      );
    }

    // Do not show placeholder text if ModalEditor/Is in a list
    if (
      !hideBlockButton &&
      !isInList() &&
      selected &&
      isEmpty &&
      isSelectionCollapsed
    ) {
      return (
        <React.Fragment>
          <p
            style={styles.placeholderText}
            className='disable-select'
            contentEditable={false}
          >
            {collabPlaceholder}
          </p>
        </React.Fragment>
      );
    }
  };

  return (
    <div
      style={{
        ...styles.paragraph,
        ...(time ? { paddingRight: '54px' } : {})
      }}
      onMouseEnter={showHoverState}
      onMouseLeave={hideHoverState}
    >
      <AddBlockButton
        parent={parentNode}
        show={!readOnly && hovering && !hideBlockButton}
        onCategorySelected={selectCategory}
        onCategoriesShown={onCategoriesShown}
        onCategoriesHidden={onCategoriesHidden}
        onNoneSelected={onNoneSelected}
        seenCategories={seenCategories}
        filter={query}
        isFocused={selected}
        showInsertAgendaButton={showInsertAgendaButton}
        onInsertAgendaSelected={selectAgenda}
        isTemplateFlow={isTemplateFlow}
        currentTemplate={currentTemplate}
        meetingUuid={meetingUuid}
      />

      <p style={styles.paragraph}>
        {!readOnly && renderPlaceholder()}
        {children}
      </p>
      {isPathSame && time && (
        <TimestampLink
          time={time}
          show={shouldHighlight}
          onClick={transcriptionReady ? clickTranscriptTimestamp : undefined}
        />
      )}
    </div>
  );
};

Paragraph.propTypes = {
  editor: PropTypes.object,
  element: PropTypes.shape({
    text: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.object
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  recordingStart: PropTypes.string,
  recordingDuration: PropTypes.number,
  onCategorySelected: PropTypes.func,
  onNoneSelected: PropTypes.func,
  seenCategories: PropTypes.object,
  readOnly: PropTypes.bool,
  transcriptionReady: PropTypes.bool,
  onClickTranscriptTimestamp: PropTypes.func,
  hideBlockButton: PropTypes.bool,
  showInsertAgendaButton: PropTypes.func,
  onInsertAgendaSelected: PropTypes.func,
  placeholder: PropTypes.string,
  isCollab: PropTypes.bool,
  isTemplateFlow: PropTypes.bool,
  currentTemplate: PropTypes.object,
  meetingUuid: PropTypes.string
};

Paragraph.defaultProps = {
  recordingStart: '',
  onCategorySelected: null,
  readOnly: false,
  hideBlockButton: false
};

export default Paragraph;
