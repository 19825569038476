import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { fetchCustomCategories } from 'actions/insights';
import { trackSegmentEvents } from 'actions/segment';

import {
  useCreateUnifiedSmartCategoryMutation,
  useGetSmartCategoriesQuery,
  useUpdateUnifiedSmartCategoryMutation
} from 'services/smartCategories';

import AvomaButton from 'components/Common/AvomaButton';
import AvomaDialog from 'components/Common/AvomaDialog';
import AvomaIcon from 'components/Common/AvomaIcon';
import AvomaSkeleton from 'components/Common/AvomaSkeleton';
import ButtonUnstyled from 'components/Common/ButtonUnstyled';
import CategoryDetailsV2 from 'components/SmartCategories/CategoryDetails/CategoryDetailsV2';
import CategoryInformationV2 from 'components/SmartCategories/CategoryInformation/CategoryInformationV2';
import {
  errorNotification,
  successNotification
} from 'components/ToastNotifications';

import { DEFAULT_NEW_CATEGORY } from 'constants/smartCategories';
import { TOPIC_EVENTS } from 'constants/trackingEvents';

import { ReactComponent as LeftArrowIcon } from 'images/arrow_left_big.svg';

const CreateAndUpdateTopicModal = ({
  isOpen,
  handleOnClose,
  updateCategoryUuid
}) => {
  const dispatch = useDispatch();

  const [categoryDataToUpdate, setCategoryToUpdate] = useState();

  const [unifiedSmartCategoryDescription, setUnifiedSmartCategoryDescription] =
    useState(categoryDataToUpdate?.description ?? '');
  const [unifiedSmartCategoryName, setUnifiedSmartCategoryName] = useState(
    categoryDataToUpdate?.name ?? ''
  );
  const [unifiedSmartCategoryColor, setUnifiedSmartCategoryColor] =
    useState('#FBD2E0');
  const [prompts, setPrompts] = useState(categoryDataToUpdate?.prompts ?? {});
  const [keywords, setKeywords] = useState(
    categoryDataToUpdate?.keywords ?? {}
  );
  const [trackers, setTrackers] = useState(
    categoryDataToUpdate?.trackers ?? {}
  );
  const [isKeywordNotesEnabled, setIsKeywordNotesEnabled] = useState(
    categoryDataToUpdate?.settings?.keywordNotesEnabled ?? false
  );
  const [selectedDataTypeOption, setSelectedDataTypeOption] = useState(
    categoryDataToUpdate?.formattingRules?.dataType ?? 'text'
  );
  const [selectedFormatOption, setSelectedFormatOption] = useState(
    categoryDataToUpdate?.formattingRules?.format ?? 'bullet'
  );
  const [selectedLengthOption, setSelectedLengthOption] = useState(
    categoryDataToUpdate?.formattingRules?.length ?? 'medium'
  );
  const [isShowCustomPhraseDetails, setIsShowCustomPhraseDetails] =
    useState(false);

  const [createUnifiedSmartCategoryFn, { isLoading: isCreatingSmartTopic }] =
    useCreateUnifiedSmartCategoryMutation();

  const [updateUnifiedSmartCategoryFn, { isLoading: isUpdatingSmartTopic }] =
    useUpdateUnifiedSmartCategoryMutation();

  const { currentData: customCategoriesData, isFetching } =
    useGetSmartCategoriesQuery({});

  useEffect(() => {
    if (!updateCategoryUuid || isEmpty(customCategoriesData)) {
      return;
    }

    const data = customCategoriesData.find(
      category => category.uuid === updateCategoryUuid
    );

    setCategoryToUpdate(data);
    setUnifiedSmartCategoryName(data?.name);
    setUnifiedSmartCategoryDescription(data?.description);
    setUnifiedSmartCategoryColor(data?.color);
    setPrompts(data?.prompts);
    setKeywords(data?.keywords);
    setTrackers(data?.trackers);
    setIsKeywordNotesEnabled(data?.settings?.keywordNotesEnabled);
    setSelectedDataTypeOption(data?.formattingRules?.dataType);
    setSelectedFormatOption(data?.formattingRules?.format);
    setSelectedLengthOption(data?.formattingRules?.length);
  }, [
    customCategoriesData,
    updateCategoryUuid,
    isFetching,
    categoryDataToUpdate
  ]);

  const handleCreateAndAdd = async () => {
    const keywordsForPayload = [];

    Object.values(keywords || []).forEach(keyword => {
      keywordsForPayload.push({
        label: {
          label: keyword.label,
          uuid: null
        },
        variations: Object.values(keyword.variations || {}).map(variation => ({
          label: variation.label,
          uuid: null
        }))
      });
    });

    const promptForPayload = [];

    if (!isEmpty(prompts)) {
      promptForPayload.push({
        label: {
          label: prompts[0].label,
          uuid: null
        },
        variations: Object.values(prompts[0].variations || []).map(
          variation => ({
            label: variation.label,
            uuid: null
          })
        )
      });
    }

    const trackersForPayload = [];

    Object.values(trackers || []).forEach(tracker => {
      trackersForPayload.push({
        label: {
          label: tracker.label,
          uuid: null
        },
        sentences: (tracker.sentences || []).map(sentenceObj => ({
          sentence: sentenceObj.sentence,
          uuid: null
        }))
      });
    });

    await createUnifiedSmartCategoryFn({
      payload: {
        name: unifiedSmartCategoryName,
        description: unifiedSmartCategoryDescription,
        background_color: unifiedSmartCategoryColor,
        prompts: promptForPayload,
        keywords: keywordsForPayload,
        trackers: trackersForPayload,
        settings: {
          keyword_notes_enabled: isKeywordNotesEnabled
        },
        formatting_rules: {
          data_type: selectedDataTypeOption,
          format: selectedFormatOption,
          length: selectedLengthOption
        }
      }
    })
      .unwrap()
      .then(res => {
        successNotification({
          message: 'Smart Topic created successfully'
        });

        dispatch(
          trackSegmentEvents({
            type: TOPIC_EVENTS.CREATE_SMART_TOPIC_REQUEST
          })
        );

        dispatch(fetchCustomCategories());
        handleOnClose(res);
      });
  };

  const handleUpdateTopic = async () => {
    const keywordsForPayload = [];

    Object.values(keywords || []).forEach(keyword => {
      keywordsForPayload.push({
        label: {
          label: keyword.label,
          uuid: keyword.tempUuid ? null : keyword.uuid
        },
        variations: Object.values(keyword.variations || {}).map(variation => ({
          label: variation.label,
          uuid: variation.tempUuid ? null : variation.uuid
        }))
      });
    });

    const promptForPayload = [];

    if (!isEmpty(prompts)) {
      promptForPayload.push({
        label: {
          label: prompts[0].label,
          uuid: prompts[0].tempUuid ? null : prompts[0].uuid
        },
        variations: Object.values(prompts[0].variations || []).map(
          variation => ({
            label: variation.label,
            uuid: variation.tempUuid ? null : variation.uuid
          })
        )
      });
    }

    const trackersForPayload = [];

    Object.values(trackers || []).forEach(tracker => {
      trackersForPayload.push({
        label: {
          label: tracker.label,
          uuid: tracker.tempUuid ? null : tracker.uuid
        },
        sentences: (tracker.sentences || []).map(sentenceObj => ({
          sentence: sentenceObj.sentence,
          uuid: sentenceObj.tempUuid ? null : sentenceObj.uuid
        }))
      });
    });

    await updateUnifiedSmartCategoryFn({
      categoryUuid: updateCategoryUuid,
      payload: {
        name: unifiedSmartCategoryName,
        description: unifiedSmartCategoryDescription,
        background_color: unifiedSmartCategoryColor,
        prompts: promptForPayload,
        keywords: keywordsForPayload,
        trackers: trackersForPayload,
        settings: {
          keyword_notes_enabled: isKeywordNotesEnabled
        },
        formatting_rules: {
          data_type: selectedDataTypeOption,
          format: selectedFormatOption,
          length: selectedLengthOption
        }
      }
    })
      .unwrap()
      .then(res => {
        successNotification({
          message: 'Smart Topic updated successfully'
        });

        dispatch(
          trackSegmentEvents({
            type: TOPIC_EVENTS.UPDATE_SMART_TOPIC_REQUEST,
            category_uuid: updateCategoryUuid
          })
        );

        dispatch(fetchCustomCategories());
        handleOnClose(res);
      });
  };

  const getModalTitle = useCallback(() => {
    if (isShowCustomPhraseDetails) {
      return 'Smart Tracker Details';
    }

    if (updateCategoryUuid) {
      return 'Update Smart Topic';
    }

    return 'Create a new Smart Topic';
  }, [isShowCustomPhraseDetails, updateCategoryUuid]);

  const renderModalContent = useCallback(() => {
    if (updateCategoryUuid && isFetching) {
      return (
        <React.Fragment>
          <div className='w-[95%] m-auto flex flex-col gap-3 py-4'>
            <AvomaSkeleton />
            <AvomaSkeleton className='!w-[80%]' />
            <AvomaSkeleton className='!w-[60%]' />
            <AvomaSkeleton />
            <AvomaSkeleton />
            <AvomaSkeleton className='!w-[80%]' />
            <AvomaSkeleton className='!w-[60%]' />
            <AvomaSkeleton />
            <AvomaSkeleton />
            <AvomaSkeleton className='!w-[80%]' />
            <AvomaSkeleton className='!w-[60%]' />
            <AvomaSkeleton />
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div
          className={twMerge('', isShowCustomPhraseDetails && 'h-4 invisible')}
        >
          <CategoryInformationV2
            category={categoryDataToUpdate ?? DEFAULT_NEW_CATEGORY}
            setUnifiedSmartCategoryDescription={
              setUnifiedSmartCategoryDescription
            }
            unifiedSmartCategoryName={unifiedSmartCategoryName}
            unifiedSmartCategoryDescription={unifiedSmartCategoryDescription}
            setUnifiedSmartCategoryName={setUnifiedSmartCategoryName}
            setUnifiedSmartCategoryColor={setUnifiedSmartCategoryColor}
          />
        </div>
        <CategoryDetailsV2
          category={categoryDataToUpdate ?? DEFAULT_NEW_CATEGORY}
          keywords={keywords}
          prompts={prompts}
          trackers={trackers}
          keywordNotesEnabled={isKeywordNotesEnabled}
          selectedDataTypeOption={selectedDataTypeOption}
          selectedFormatOption={selectedFormatOption}
          selectedLengthOption={selectedLengthOption}
          setPrompts={setPrompts}
          setKeywords={setKeywords}
          setTrackers={setTrackers}
          setIsKeywordNotesEnabled={setIsKeywordNotesEnabled}
          setSelectedDataTypeOption={setSelectedDataTypeOption}
          setSelectedFormatOption={setSelectedFormatOption}
          setSelectedLengthOption={setSelectedLengthOption}
          isShowCustomPhraseDetails={isShowCustomPhraseDetails}
          setIsShowCustomPhraseDetails={setIsShowCustomPhraseDetails}
        />
      </React.Fragment>
    );
  }, [
    categoryDataToUpdate,
    isFetching,
    isKeywordNotesEnabled,
    isShowCustomPhraseDetails,
    keywords,
    prompts,
    selectedDataTypeOption,
    selectedFormatOption,
    selectedLengthOption,
    trackers,
    unifiedSmartCategoryDescription,
    unifiedSmartCategoryName,
    updateCategoryUuid
  ]);

  return (
    <AvomaDialog
      open={isOpen}
      setOpen={handleOnClose}
      controlled
      closeCleanupHandler={() => handleOnClose(undefined)}
      title={
        <div className='flex items-center gap-x-3'>
          {isShowCustomPhraseDetails && (
            <ButtonUnstyled
              onClick={() => {
                setIsShowCustomPhraseDetails(false);
              }}
            >
              <AvomaIcon RenderIcon={LeftArrowIcon} small />
            </ButtonUnstyled>
          )}
          <span>{getModalTitle()}</span>
        </div>
      }
      contentClasses='max-w-[900px] w-[900px] z-[9999] pb-2'
      childrenClasses={twMerge(
        'overflow-hidden p-0 h-[85vh]',
        !isShowCustomPhraseDetails && 'max-h-fit'
      )}
    >
      <div
        className={twMerge(
          'overflow-y-auto h-[58vh] w-[900px]',
          isShowCustomPhraseDetails && 'overflow-hidden h-full'
        )}
      >
        {renderModalContent()}
      </div>
      {!isShowCustomPhraseDetails && (
        <div className='flex justify-end px-4 pt-4 pb-2 gap-4 border-t border-lightGray border-solid'>
          <AvomaButton
            secondary
            small
            label='Cancel'
            onClick={() => handleOnClose(undefined)}
          />
          <AvomaButton
            primary
            small
            label={updateCategoryUuid ? 'Update' : 'Create & Add'}
            onClick={() => {
              if (isCreatingSmartTopic || isUpdatingSmartTopic) {
                return;
              }

              if (updateCategoryUuid) {
                handleUpdateTopic();
              } else {
                handleCreateAndAdd();
              }
            }}
            disabled={
              isEmpty(unifiedSmartCategoryName) ||
              isEmpty(unifiedSmartCategoryDescription)
            }
            loading={isCreatingSmartTopic || isUpdatingSmartTopic}
          />
        </div>
      )}
    </AvomaDialog>
  );
};

CreateAndUpdateTopicModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  updateCategoryUuid: PropTypes.string
};

export default CreateAndUpdateTopicModal;
