import navNextIcon from 'images/ic_calendar_nav_next.svg';
import navPreviousIcon from 'images/ic_calendar_nav_previous.svg';
import calendarIcon from 'images/ic_date_picker_from.svg';
import crossIcon from 'images/ic_cross.svg';
import Tokens from 'styles/tokens';

export const renderNavNextIcon = (isDisabled = false) => (
  <img
    src={navNextIcon}
    alt='next-month'
    style={{
      ...styles.icon,
      ...styles.next,
      ...(isDisabled && styles.disabled)
    }}
  />
);

export const renderNavPreviousIcon = (isDisabled = false) => (
  <img
    src={navPreviousIcon}
    alt='previous-month'
    style={{
      ...styles.icon,
      ...styles.prev,
      ...(isDisabled && styles.disabled)
    }}
  />
);

export const renderRemoveIcon = () => (
  <img src={crossIcon} alt='cross' width={16} height={16} />
);

export const renderCalendarIcon = () => (
  <img src={calendarIcon} alt='calendar' width={16} height={16} />
);

const styles = {
  icon: {
    width: Tokens.spacing.three,
    height: Tokens.spacing.three
  },
  prev: {
    position: 'absolute',
    left: Tokens.spacing.four,
    top: Tokens.spacing.three
  },
  next: {
    position: 'absolute',
    right: Tokens.spacing.two,
    top: Tokens.spacing.three
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.4'
  }
};
