import { DealActions } from 'actions/actionTypes';

const initialState = {
  data: {},
  pipelines: {},
  forecastCategories: {},
  crmAccounts: {
    data: [],
    loading: false,
    error: false
  },
  dashboardColumns: {},
  dashboardDetails: {}
};

const fetchDeals = (state, action) => {
  switch (action.type) {
    case DealActions.FETCH_DEALS_REQUEST: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            loading: true,
            requestId: action.requestId,
            engagementDetails: {}
          }
        }
      };
    }
    case DealActions.FETCH_DEALS_SUCCESS: {
      if (state.data[action.provider].requestId !== action.requestId) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            deals: action.deals,
            next: action.next,
            count: action.count,
            loading: false,
            requestId: null,
            engagementDetails: {
              ...state.data[action.provider].engagementDetails
            }
          }
        }
      };
    }
    case DealActions.FETCH_DEALS_FAILURE: {
      if (state.data[action.provider].requestId !== action.requestId) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            loading: false,
            requestId: null,
            engagementDetails: {
              ...state.data[action.provider].engagementDetails
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

const fetchMoreDeals = (state, action) => {
  switch (action.type) {
    case DealActions.FETCH_MORE_DEALS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            deals: {
              ...state.data[action.provider].deals,
              ...action.deals
            },
            next: action.next,
            loadingMore: false,
            engagementDetails: {
              ...state.data[action.provider].engagementDetails
            }
          }
        }
      };
    case DealActions.FETCH_MORE_DEALS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            loadingMore: true,
            engagementDetails: {
              ...state.data[action.provider].engagementDetails
            }
          }
        }
      };
    case DealActions.FETCH_MORE_DEALS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            loadingMore: false,
            engagementDetails: {
              ...state.data[action.provider].engagementDetails
            }
          }
        }
      };
    default:
      return state;
  }
};

const getLoadingEngagement = (state, action, value) => {
  const data = state.data[action.provider];
  return {
    ...(data && data.loadingEngagement),
    [action.dealUuid]: {
      ...(data &&
        data.loadingEngagement &&
        data.loadingEngagement[action.dealUuid]),
      [action.interval]: {
        ...(data &&
          data.loadingEngagement &&
          data.loadingEngagement[action.dealUuid] &&
          data.loadingEngagement[action.dealUuid][action.interval]),
        [action.offset]: value
      }
    }
  };
};

const getErrorEngagement = (state, action, error) => {
  const data = state.data[action.provider];
  return {
    ...(data && data.errorEngagement),
    [action.dealUuid]: {
      ...(data &&
        data.errorEngagement &&
        data.errorEngagement[action.dealUuid]),
      [action.interval]: {
        ...(data &&
          data.errorEngagement &&
          data.errorEngagement[action.dealUuid] &&
          data.errorEngagement[action.dealUuid][action.interval]),
        [action.offset]: error
      }
    }
  };
};

const fetchDealEngagement = (state, action) => {
  switch (action.type) {
    case DealActions.FETCH_ENGAGEMENT_REQUEST: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            loadingEngagement: getLoadingEngagement(state, action, true),
            engagementDetails: {
              ...state.data[action.provider]?.engagementDetails
            }
          }
        }
      };
    }
    case DealActions.FETCH_ENGAGEMENT_SUCCESS: {
      const data = state.data[action.provider];
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...data,
            engagement: {
              ...(data && data.engagement),
              [action.dealUuid]: {
                ...(data &&
                  data.engagement &&
                  data.engagement[action.dealUuid]),
                [action.interval]: {
                  ...(data &&
                    data.engagement &&
                    data.engagement[action.dealUuid] &&
                    data.engagement[action.dealUuid][action.interval]),
                  ...action.engagement
                }
              }
            },
            loadingEngagement: getLoadingEngagement(state, action, false),
            engagementDetails: {
              ...state.data[action.provider].engagementDetails
            }
          }
        }
      };
    }
    case DealActions.FETCH_ENGAGEMENT_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            loadingEngagement: getLoadingEngagement(state, action, false),
            errorEngagement: getErrorEngagement(state, action, action.error),
            engagementDetails: {}
          }
        }
      };
    default:
      return state;
  }
};

const deals = (state = initialState, action) => {
  switch (action.type) {
    case DealActions.FETCH_DEALS_REQUEST:
    case DealActions.FETCH_DEALS_SUCCESS:
    case DealActions.FETCH_DEALS_FAILURE:
      return fetchDeals(state, action);
    case DealActions.FETCH_MORE_DEALS_REQUEST:
    case DealActions.FETCH_MORE_DEALS_SUCCESS:
    case DealActions.FETCH_MORE_DEALS_FAILURE:
      return fetchMoreDeals(state, action);
    case DealActions.FETCH_ENGAGEMENT_REQUEST:
    case DealActions.FETCH_ENGAGEMENT_SUCCESS:
    case DealActions.FETCH_ENGAGEMENT_FAILURE:
      return fetchDealEngagement(state, action);
    case DealActions.SEARCH_CRM_ACCOUNTS_REQUEST:
      return {
        ...state,
        crmAccounts: {
          ...state.crmAccounts,
          loading: true
        }
      };
    case DealActions.SEARCH_CRM_ACCOUNTS_SUCCESS:
      return {
        ...state,
        crmAccounts: {
          data: action.data,
          loading: false
        }
      };
    case DealActions.SEARCH_CRM_ACCOUNTS_FAILURE:
      return {
        ...state,
        crmAccounts: {
          error: action.error,
          loading: false
        }
      };

    case DealActions.FETCH_TIMELINE_DETAILS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            engagementDetails: {
              ...state.data[action.provider].engagementDetails,
              [action.bubbleUniqueKey]: {
                loading: true
              }
            }
          }
        }
      };
    case DealActions.FETCH_TIMELINE_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            engagementDetails: {
              ...state.data[action.provider].engagementDetails,
              [action.bubbleUniqueKey]: {
                loading: false,
                data: action.response
              }
            }
          }
        }
      };
    case DealActions.FETCH_TIMELINE_DETAILS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            engagementDetails: {
              ...state.data[action.provider].engagementDetails,
              [action.bubbleUniqueKey]: {
                loading: false,
                error: action.error
              }
            }
          }
        }
      };
    case DealActions.SET_TIMELINE_DETAILS_HOVER: {
      if (state.data[action.provider].bubbleHover)
        delete state.data[action.provider].bubbleHover;
      if (action.shouldDelete) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.provider]: {
              ...state.data[action.provider],
              bubbleHover: {}
            }
          }
        };
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            bubbleHover: {
              [action.uniqueHoverKey]: true
            }
          }
        }
      };
    }
    case DealActions.FETCH_PIPELINE_REQUEST: {
      const hasPipelineForProvider = Boolean(state.pipelines[action.provider]);

      if (hasPipelineForProvider && !action.query) {
        return {
          ...state,
          pipelines: {
            ...state.pipelines,
            [action.provider]: {
              ...state.pipelines[action.provider],
              fetching: true
            }
          }
        };
      }

      return {
        ...state,
        pipelines: {
          ...state.pipelines,
          [action.provider]: {
            fetching: true
          }
        }
      };
    }

    case DealActions.FETCH_PIPELINE_SUCCESS: {
      const hasPipelineForProvider = Boolean(
        state.pipelines[action.provider].data
      );

      if (hasPipelineForProvider && !action.query) {
        return {
          ...state,
          pipelines: {
            ...state.pipelines,
            [action.provider]: {
              ...state.pipelines[action.provider],
              fetching: false,
              ...action.response,
              results: {
                ...state.pipelines[action.provider].results,
                ...action.data
              }
            }
          }
        };
      }

      return {
        ...state,
        pipelines: {
          ...state.pipelines,
          [action.provider]: {
            ...action.response,
            fetching: false,
            results: action.data
          }
        }
      };
    }

    case DealActions.FETCH_PIPELINE_ERROR:
      return {
        ...state,
        pipelines: {
          ...state.pipelines,
          [action.provider]: {
            fetching: false,
            error: action.error
          }
        }
      };

    case DealActions.FETCH_DEAL_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            deal: {
              loading: true
            }
          }
        }
      };
    case DealActions.FETCH_DEAL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            deal: {
              loading: false,
              data: action.response
            }
          }
        }
      };
    case DealActions.FETCH_DEAL_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            deal: {
              loading: false,
              error: action.error
            }
          }
        }
      };
    case DealActions.RESET_DEAL:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            deal: {}
          }
        }
      };
    case DealActions.FETCH_DEAL_ENGAGEMENT_DETAILS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            dealEngDetailsLoading: true
          }
        }
      };

    case DealActions.FETCH_DEAL_ENGAGEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            dealEngDetails: {
              ...state.data[action.provider].dealEngDetails,
              [action.dealUuid]: action.response
            },
            dealEngDetailsLoading: false
          }
        }
      };
    case DealActions.FETCH_DEAL_ENGAGEMENT_DETAILS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            dealEngDetailsLoading: false,
            dealEngDetailsError: true
          }
        }
      };

    case DealActions.FETCH_DEAL_STAGES_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            dealStages: {
              loading: true
            }
          }
        }
      };
    case DealActions.FETCH_DEAL_STAGES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            dealStages: {
              loading: false,
              data: action.response
            }
          }
        }
      };
    case DealActions.FETCH_DEAL_STAGES_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            deal: {
              loading: false,
              data: null,
              error: action.error
            }
          }
        }
      };
    case DealActions.FETCH_DEAL_ENGAGEMENT_BUBBLE_DETAILS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            dealEngBubbleDetails: {
              ...state.data[action.provider].dealEngBubbleDetails,
              [action.bubbleUniqueKey]: {
                loading: true
              }
            }
          }
        }
      };
    case DealActions.FETCH_DEAL_ENGAGEMENT_BUBBLE_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            dealEngBubbleDetails: {
              ...state.data[action.provider].dealEngBubbleDetails,
              [action.bubbleUniqueKey]: {
                loading: false,
                data: action.response
              }
            }
          }
        }
      };
    case DealActions.FETCH_DEAL_ENGAGEMENT_BUBBLE_DETAILS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            dealEngBubbleDetails: {
              ...state.data[action.provider].dealEngBubbleDetails,
              [action.bubbleUniqueKey]: {
                loading: false,
                error: action.error
              }
            }
          }
        }
      };
    case DealActions.FETCH_DEAL_COMMENTS_REQUEST:
      return {
        ...state,
        comments: {
          loading: true
        }
      };
    case DealActions.FETCH_DEAL_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: {
          loading: false,
          data: action.response
        }
      };
    case DealActions.FETCH_DEAL_COMMENTS_ERROR:
      return {
        ...state,
        comments: {
          error: true,
          loading: false
        }
      };
    case DealActions.REMOVE_DEAL_COMMENTS:
      return {
        ...state,
        comments: null
      };
    case DealActions.POST_COMMENT_REQUEST:
      return {
        ...state,
        postComment: {
          loading: true
        }
      };
    case DealActions.POST_COMMENT_SUCCESS: {
      if (action.parentCommentUuid) {
        const comments = state.comments.data.comments.map(comment => {
          if (comment.uuid === action.parentCommentUuid) {
            comment.children = [...comment.children, action.response];
          }
          return comment;
        });
        const count = state.comments.data.count + 1;
        const commentData = {
          comments,
          count
        };
        return {
          ...state,
          postComment: {
            loading: false,
            data: action.response
          },
          comments: {
            ...state.comments,
            data: commentData
          }
        };
      }
      return {
        ...state,
        postComment: {
          loading: false,
          data: action.response
        },
        comments: {
          ...state.comments,
          data: {
            ...state.comments.data,
            count: state.comments.data.count + 1,
            comments: [...state.comments.data.comments, action.response]
          }
        }
      };
    }
    case DealActions.POST_COMMENT_ERROR:
      return {
        ...state,
        postComment: {
          error: true,
          loading: false
        }
      };
    case DealActions.EDIT_COMMENT_REQUEST:
      return {
        ...state,
        editComment: {
          loading: true
        }
      };
    case DealActions.EDIT_COMMENT_SUCCESS: {
      const comments = state.comments.data.comments.map(comment => {
        if (comment.uuid === action.commentUuid) {
          action.response.children = comment.children;
          return action.response;
        }

        if (comment.children.length > 0) {
          const childrenComments = comment.children.map(childComment => {
            if (childComment.uuid === action.commentUuid) {
              action.response.children = childComment.children;
              return action.response;
            }

            return childComment;
          });

          comment.children = childrenComments;
          return comment;
        }

        return comment;
      });
      return {
        ...state,
        editComment: {
          loading: false
        },
        comments: {
          ...state.comments,
          data: {
            ...state.comments.data,
            comments
          }
        }
      };
    }
    case DealActions.EDIT_COMMENT_ERROR:
      return {
        ...state,
        editComment: {
          loading: false,
          error: true
        }
      };
    case DealActions.DELETE_COMMENT_REQUEST:
      return {
        ...state,
        deleteComment: {
          loading: true
        }
      };
    case DealActions.DELETE_COMMENT_SUCCESS: {
      let deletedCommentCount = 1;
      const comments = state.comments.data.comments.filter(comment => {
        if (comment.uuid === action.commentUuid) {
          deletedCommentCount += comment.children.length;
          return null;
        }

        if (comment.children.length > 0) {
          const childrenComments = comment.children.filter(childComment => {
            if (childComment.uuid === action.commentUuid) {
              return null;
            }
            return childComment;
          });
          comment.children = childrenComments;
        }
        return comment;
      });
      const count = state.comments.data.count - deletedCommentCount;

      return {
        ...state,
        deleteComment: {
          loading: false
        },
        comments: {
          ...state.comments,
          data: {
            ...state.comments.data,
            count,
            comments
          }
        }
      };
    }
    case DealActions.DELETE_COMMENT_ERROR:
      return {
        ...state,
        deleteComment: {
          loading: false,
          error: true
        }
      };

    case DealActions.FETCH_CURRENT_DASHBOARD_COLUMN_HEADERS_REQUEST: {
      return {
        ...state,
        dashboardColumns: {
          [action.providerUuid]: {
            ...state.dashboardColumns[action.providerUuid],
            [action.dashboardUuid]: {
              ...state.dashboardColumns?.[action.providerUuid]?.[
                action.dashboardUuid
              ],
              fetching: true
            }
          }
        }
      };
    }

    case DealActions.FETCH_CURRENT_DASHBOARD_COLUMN_HEADERS_SUCCESS: {
      return {
        ...state,
        dashboardColumns: {
          [action.providerUuid]: {
            ...state.dashboardColumns[action.providerUuid],
            [action.dashboardUuid]: {
              fetching: false,
              data: action.response
            }
          }
        }
      };
    }

    case DealActions.FETCH_CURRENT_DASHBOARD_COLUMN_HEADERS_FAILURE: {
      return {
        ...state,
        dashboardColumns: {
          [action.providerUuid]: {
            ...state.dashboardColumns[action.providerUuid],
            [action.dashboardUuid]: {
              fetching: false,
              error: action.error
            }
          }
        }
      };
    }

    case DealActions.FETCH_DEALS_V2_REQUEST: {
      const previousController =
        state.data?.[action.provider]?.[action.dashboardUuid]?.controller;
      if (previousController) {
        previousController.abort();
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              loading: true,
              requestId: action.requestId,
              engagementDetails: {},
              controller: action.controller
            }
          }
        }
      };
    }
    case DealActions.FETCH_DEALS_V2_SUCCESS: {
      if (
        state.data[action.provider][action.dashboardUuid].requestId !==
        action.requestId
      ) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              deals: action.deals,
              next: action.next,
              count: action.count,
              loading: false,
              requestId: null,
              engagementDetails: {
                ...state.data[action.provider].engagementDetails
              }
            }
          }
        }
      };
    }
    case DealActions.FETCH_DEALS_V2_FAILURE: {
      if (
        state.data[action.provider][action.dashboardUuid].requestId !==
        action.requestId
      ) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              loading: false,
              requestId: null,
              engagementDetails: {
                ...state.data[action.provider].engagementDetails
              }
            }
          }
        }
      };
    }

    case DealActions.FETCH_MORE_DEALS_V2_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              ...state.data[action.provider][action.dashboardUuid],
              loadingMore: true,
              engagementDetails: {
                ...state.data[action.provider][action.dashboardUuid]
                  .engagementDetails
              }
            }
          }
        }
      };

    case DealActions.FETCH_MORE_DEALS_V2_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              ...state.data[action.provider][action.dashboardUuid],
              deals: {
                ...state.data[action.provider][action.dashboardUuid].deals,
                ...action.deals
              },
              next: action.next,
              loadingMore: false,
              engagementDetails: {
                ...state.data[action.provider][action.dashboardUuid]
                  .engagementDetails
              }
            }
          }
        }
      };

    case DealActions.FETCH_MORE_DEALS_V2_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              ...state.data[action.provider][action.dashboardUuid],
              loadingMore: false,
              engagementDetails: {
                ...state.data[action.provider][action.dashboardUuid]
                  .engagementDetails
              }
            }
          }
        }
      };

    case DealActions.UPDATE_DEAL_DASHBOARD_COLUMNS_ORDER_REQUEST: {
      const { dashboardUuid, provider, newColumnOrder } = action;

      return {
        ...state,
        dashboardColumns: {
          [provider]: {
            ...state.dashboardColumns[provider],
            [dashboardUuid]: {
              ...state.dashboardColumns[provider][dashboardUuid],
              data: newColumnOrder
            }
          }
        }
      };
    }
    case DealActions.UPDATE_DEAL_DASHBOARD_COLUMNS_ORDER_SUCCESS: {
      return {
        ...state
      };
    }
    case DealActions.UPDATE_DEAL_DASHBOARD_COLUMNS_ORDER_FAILURE: {
      const { dashboardUuid, provider } = action;

      return {
        ...state,
        dashboardColumns: {
          [provider]: {
            ...state.dashboardColumns[provider],
            [dashboardUuid]: {
              ...state.dashboardColumns[provider][dashboardUuid],
              updatingColumnOrder: {
                loading: false,
                error: true
              }
            }
          }
        }
      };
    }

    case DealActions.FETCH_FORECAST_CATEGORIES_REQUEST: {
      return {
        ...state,
        forecastCategories: {
          fetching: true
        }
      };
    }
    case DealActions.FETCH_FORECAST_CATEGORIES_SUCCESS: {
      return {
        ...state,
        forecastCategories: {
          fetching: false,
          data: action.response
        }
      };
    }
    case DealActions.FETCH_FORECAST_CATEGORIES_FAILURE: {
      return {
        ...state,
        forecastCategories: {
          fetching: false,
          error: action.err
        }
      };
    }

    case DealActions.FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_REQUEST: {
      return {
        ...state,
        dashboardDetails: {
          ...state.dashboardDetails,
          [action.providerUuid]: {
            ...(state.dashboardDetails?.[action.providerUuid] || {}),
            [action.dashboardUuid]: {
              ...(state.dashboardDetails?.[action.providerUuid]?.[
                action.dashboardUuid
              ] || {}),
              fetching: true
            }
          }
        }
      };
    }
    case DealActions.FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_SUCCESS: {
      return {
        ...state,
        dashboardDetails: {
          ...state.dashboardDetails,
          [action.providerUuid]: {
            ...state.dashboardDetails[action.providerUuid],
            [action.dashboardUuid]: {
              ...state.dashboardDetails[action.providerUuid][
                action.dashboardUuid
              ],
              fetching: false,
              data: action.response
            }
          }
        }
      };
    }
    case DealActions.FETCH_DASHBOARD_VIEW_DEAL_AMOUNT_FAILURE: {
      return {
        ...state,
        dashboardDetails: {
          ...state.dashboardDetails,
          [action.providerUuid]: {
            ...state.dashboardDetails[action.providerUuid],
            [action.dashboardUuid]: {
              ...state.dashboardDetails[action.providerUuid][
                action.dashboardUuid
              ],
              fetching: false,
              data: null,
              error: action.err
            }
          }
        }
      };
    }

    case DealActions.REFRESH_DASHBOARD_DEAL_DETAILS: {
      return {
        ...state,
        dashboardDetails: {
          ...state.dashboardDetails,
          refetch: (state.dashboardDetails?.refetch || 0) + 1
        }
      };
    }

    case DealActions.FETCH_DEAL_HEALTH_SCORE_REQUEST: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              ...state.data[action.provider][action.dashboardUuid],
              dealHealth: {
                ...state.data[action.provider][action.dashboardUuid].dealHealth,
                [action.dealUuid]: { loading: true, data: null, error: null }
              }
            }
          }
        }
      };
    }

    case DealActions.FETCH_DEAL_HEALTH_SCORE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              ...state.data[action.provider][action.dashboardUuid],
              dealHealth: {
                ...state.data[action.provider][action.dashboardUuid].dealHealth,
                [action.dealUuid]: {
                  loading: false,
                  data: action.data,
                  error: null
                }
              }
            }
          }
        }
      };
    }

    case DealActions.FETCH_DEAL_HEALTH_SCORE_FAILURE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              ...state.data[action.provider][action.dashboardUuid],
              dealHealth: {
                ...state.data[action.provider][action.dashboardUuid].dealHealth,
                [action.dealUuid]: {
                  loading: false,
                  data: null,
                  error: action.error
                }
              }
            }
          }
        }
      };
    }

    case DealActions.FETCH_DEAL_CHANGE_INDICATOR_REQUEST: {
      return state;
    }

    case DealActions.FETCH_DEAL_CHANGE_INDICATOR_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.provider]: {
            ...state.data[action.provider],
            [action.dashboardUuid]: {
              ...state.data[action.provider][action.dashboardUuid],
              deals: {
                ...state.data[action.provider][action.dashboardUuid].deals,
                [action.dealUuid]: {
                  ...state.data[action.provider][action.dashboardUuid].deals[
                    action.dealUuid
                  ],
                  ...action.data
                }
              }
            }
          }
        }
      };
    }

    case DealActions.FETCH_DEAL_CHANGE_INDICATOR_FAILURE: {
      return state;
    }

    default:
      return state;
  }
};

export default deals;
