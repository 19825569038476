import camelize from 'camelize';

import { RecordingActions } from 'actions/actionTypes';

const initialState = {
  data: {},
  fetching: false,
  error: null,
  insightsAnalysis: {},
  insightsFetching: false,
  insightsError: null
};

const recordings = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_RECORDING_REQUEST':
      return {
        ...state,
        fetching: true,
        error: null
      };
    case 'FETCH_RECORDING_SUCCESS':
      return {
        ...state,
        fetching: false,
        data: {
          ...state.data,
          [action.recording.uuid]: {
            ...state.data[action.recording.uuid],
            ...camelize(action.recording)
          }
        },
        error: null
      };
    case 'FETCH_RECORDING_FAILURE':
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case 'SEEK_TO_TIME':
      return {
        ...state,
        data: {
          ...state.data,
          [action.recordingId]: {
            ...state.data[action.recordingId],
            lastSeek: action.time
          }
        }
      };
    case 'LOGOUT':
      return initialState;
    case RecordingActions.FETCH_SPEAKERS_SUCCESS:
    case RecordingActions.UPDATE_SPEAKERS_SUCCESS:
    case RecordingActions.DELETE_SPEAKERS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.recordingUuid]: {
            ...state.data[action.recordingUuid],
            speakers: camelize(action.speakerMap)
          }
        }
      };
    case RecordingActions.FETCH_SPEAKERS_FAILURE:
    case RecordingActions.UPDATE_SPEAKERS_FAILURE:
    case RecordingActions.DELETE_SPEAKERS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case 'FETCH_SPEAKER_INSIGHTS_REQUEST':
      return {
        ...state,
        insightsFetching: true,
        error: null
      };
    case 'FETCH_SPEAKER_INSIGHTS_SUCCESS':
      return {
        ...state,
        insightsFetching: false,
        insightsAnalysis: action.data
      };

    case 'FETCH_SPEAKER_INSIGHTS_FAILURE':
      return {
        ...state,
        insightsFetching: false,
        insightsError: action.error
      };

    default:
      return state;
  }
};

export default recordings;
