/*
    Motivation: Building a text input, with an Add Variable button attached to work as 
    templatised variables. For example: Meeting with {{contact.name}} on {{meeting.date}}
    should be rendered as Meeting with John Doe on 2021-08-21

    Separating the input and the popover that handles which variables are available to be added
*/
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as DownArrowIcon } from 'images/downArrow.svg';

import Tokens from 'styles/tokens';

import AvomaPopoverControlled from '../AvomaPopoverControlled';
import ButtonUnstyled from '../ButtonUnstyled';

const AvomaVariableTextInput = ({
  value = '',
  onChange,
  placeholder,
  variables = [],
  variableTitle,
  staticVariables = [],
  staticVariableClass,
  variableClass
}) => {
  let newCursorPosition = null;
  const [cursorPos, setCursorPos] = useState(0);

  const renderDisplayVariable = val => {
    const displayVal = variables.find(item => item.value === val.trim());
    if (displayVal) {
      return ` ${displayVal.label} `; // additional space is added to provide padding on either sides
    }

    return '';
  };

  const renderStaticVariable = val => {
    const displayVal =
      staticVariables.find(item => item?.value === val.trim()) || {};
    if (displayVal) {
      return ` ${displayVal.label} `; // additional space is added to provide padding on either sides
    }

    return '';
  };

  /**
   * let value = 'Demo with {{organizer_name}} from {{company_name}}'
   * matchingIndexes = [ { start: 10, end: 23 }, { start: 30, end: 45 } ]
   * if cursorPos = 11
   * rangeObj = { start: 10, end: 23 }
   * if cursorPos = 9
   * rangeObj = null
   */

  const setNewCursorPosition = async () => {
    const regex = /{{\s*\w+\s*}}|\/\/[^/]+\/\//g;
    const matchingIndexes = [];
    let match;
    let rangeObj = null;
    do {
      match = regex.exec(value);
      if (match) {
        const start = match.index;
        const end = match.index + match[0].length;
        matchingIndexes.push({ start, end });
      }
    } while (match);

    for (const item of matchingIndexes) {
      if (cursorPos >= item.start && cursorPos <= item.end) {
        rangeObj = item;
      }
    }
    if (matchingIndexes.length !== 0) {
      if (rangeObj) {
        newCursorPosition = rangeObj.end + 1;
      }
    }
  };

  return (
    <div className='flex items-center rounded border border-gainsboro bg-white'>
      <MentionsInput
        style={{
          ...styles.textarea,
          highlighter: {
            zIndex: 100,
            pointerEvents: 'none'
          },
          input: styles.textfield
        }}
        singleLine
        allowSpaceInQuery
        onKeyUp={e => {
          newCursorPosition = null;
          setCursorPos(e.target.selectionStart);
        }}
        onMouseUp={e => {
          newCursorPosition = null;
          setCursorPos(e.target.selectionStart);
        }}
        value={value}
        placeholder={placeholder}
        autocomplete='off'
        onChange={e => onChange(e.target.value)}
      >
        <Mention
          trigger='//'
          markup='//__id__//'
          displayTransform={renderStaticVariable}
          //   renderSuggestion={renderEventTitleSuggestion}
          className={twMerge(
            'box-border rounded bg-whiteSmoke font-bold text-silver-dark',
            staticVariableClass
          )}
          appendSpaceOnAdd
          // data={variables}
        />
        <Mention
          trigger='{{'
          markup='{{__id__}}'
          displayTransform={renderDisplayVariable}
          //   renderSuggestion={renderEventTitleSuggestion}
          className={twMerge(
            'box-border rounded bg-blue-lightest text-blue',
            variableClass
          )}
          appendSpaceOnAdd
          // data={variables}
        />
      </MentionsInput>
      <AvomaPopoverControlled
        dropdownTrigger={() => (
          <ButtonUnstyled
            noDefaultStyles
            className='ml-auto flex h-10 w-[140px] flex-shrink-0 cursor-pointer items-center justify-center rounded-r border-l border-gainsboro bg-white text-sm font-bold text-blue outline-none hover:bg-smoke'
          >
            Add Variable
            <DownArrowIcon
              className='relative ml-2 h-2 w-2'
              style={{ filter: Tokens.filters.blue }}
              alt='dropdown'
            />
          </ButtonUnstyled>
        )}
        contentClasses='!p-0 max-h-[300px] max-w-[350px] overflow-y-auto flex flex-col items-start box-border'
        align='end'
        triggerStyle={{
          marginLeft: 'auto'
        }}
      >
        {variableTitle && (
          <p className='px-4 py-3 text-sm font-bold text-gunmetal'>
            {variableTitle}
          </p>
        )}
        {variables.map(variable => (
          <ButtonUnstyled
            key={variable.value}
            noDefaultStyles
            className='box-border flex w-full cursor-pointer flex-col gap-0.5 rounded bg-white px-4 py-3 hover:bg-snow'
            onClick={async () => {
              await setNewCursorPosition();
              const title = value.split('');
              if (newCursorPosition) {
                title.splice(newCursorPosition, 0, ` {{${variable.value}}} `);
                setCursorPos(title.length);
                onChange(title.join(''));
              } else {
                title.splice(cursorPos + 2, 0, ` {{${variable.value}}} `);
                setCursorPos(title.length);
                onChange(title.join(''));
              }
            }}
          >
            <span className='text-left text-sm text-gunmetal'>
              {variable.label}
            </span>
            <span className='text-left text-xs text-silver'>
              {variable.description}
            </span>
          </ButtonUnstyled>
        ))}
      </AvomaPopoverControlled>
    </div>
  );
};

const styles = {
  textarea: {
    ...Tokens.type.bodyS,
    boxSizing: 'border-box',
    backgroundColor: Tokens.colors.white,
    padding: `${Tokens.spacing.half} ${Tokens.spacing.one}`,
    minWidth: '300px',
    width: '100%'
  },
  textfield: {
    padding: `${Tokens.spacing.half} ${Tokens.spacing.one}`,
    color: Tokens.colors.gunmetal,
    borderRadius: `${Tokens.spacing.borderRadius} 0px 0px ${Tokens.spacing.borderRadius}`,
    outline: 'none',
    width: '100%'
  }
};

AvomaVariableTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  variableTitle: PropTypes.string,
  staticVariables: PropTypes.arrayOf(PropTypes.string),
  staticVariableClass: PropTypes.string,
  variableClass: PropTypes.string
};

export default AvomaVariableTextInput;
