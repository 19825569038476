import { keyBy, map } from 'lodash';

import { SchedulerActions } from 'actions/actionTypes';

const INITIAL_STATE = {
  schedulerUser: {
    fetching: false,
    data: {},
    error: null
  },
  pages: {
    fetching: false,
    updating: false,
    data: {},
    publicPage: {},
    list: [],
    error: null
  },
  teamPages: {
    fetching: false,
    data: {},
    publicPage: {},
    list: [],
    error: null,
    nextPageUrl: null,
    previousPageUrl: null
  },
  allPages: {
    fetching: false,
    data: {},
    publicPage: {},
    list: [],
    error: null,
    nextPageUrl: null,
    previousPageUrl: null
  },
  sharedPages: {
    fetching: false,
    data: {},
    publicPage: {},
    list: [],
    error: null
  },
  routerPages: {
    sortBy: 'created',
    search: ''
  },
  managedPages: {
    fetching: false,
    data: {},
    publicPage: {},
    list: [],
    error: null
  },
  events: {
    data: {},
    fetching: false,
    error: null
  },
  routerPublicPage: {
    actionType: null,
    value: null,
    formSubmissionUuid: null
  },
  searchTerm: '',
  sortBy: 'created',
  selectedEventDate: '',
  publicLink: {
    publicLinkPrefix: '',
    slug: '',
    publicLink: '',
    publicSlug: '',
    error: null
  }
};
const schedulerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SchedulerActions.CAL_SCHEDULER_REQUEST:
      return {
        ...state,
        schedulerUser: {
          ...state.schedulerUser,
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.CAL_SCHEDULER_SUCCESS:
      return {
        ...state,
        schedulerUser: {
          ...state.schedulerUser,
          fetching: false,
          data: action.response
        }
      };
    case SchedulerActions.CAL_SCHEDULER_FAILURE:
      return {
        ...state,
        schedulerUser: {
          ...state.schedulerUser,
          fetching: false,
          error: action.error
        }
      };
    case SchedulerActions.FETCH_SCHEDULING_PAGES_REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.FETCH_SCHEDULING_PAGES_SUCCESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          data: {
            ...keyBy(action.response, 'uuid')
          },
          list: [...map(action.response, 'uuid')]
        }
      };
    case SchedulerActions.FETCH_SCHEDULING_PAGES_FAILURE:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          error: action.error
        }
      };

    case SchedulerActions.FETCH_TEAM_SCHEDULING_PAGES_REQUEST:
      if (action.isPaginate) {
        return {
          ...state,
          teamPages: {
            ...state.teamPages,
            fetching: true,
            error: null
          }
        };
      }
      return {
        ...state,
        teamPages: {
          ...state.teamPages,
          data: {},
          list: [],
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.FETCH_TEAM_SCHEDULING_PAGES_SUCCESS:
      if (action?.response?.isPaginate) {
        return {
          ...state,
          teamPages: {
            ...state.teamPages,
            fetching: false,
            data: {
              ...state.teamPages.data,
              ...keyBy(action.response?.data?.results, 'uuid')
            },
            count: action.response?.data?.count,
            list: [
              ...state.teamPages.list,
              ...map(action.response?.data?.results, 'uuid')
            ],
            nextPageUrl: action.response?.data?.next ?? null,
            previousPageUrl: action.response?.data?.previous ?? null
          }
        };
      }
      return {
        ...state,
        teamPages: {
          fetching: false,
          data: {
            ...keyBy(action.response?.data?.results, 'uuid')
          },
          count: action.response?.data?.count,
          list: [...map(action.response?.data?.results, 'uuid')],
          nextPageUrl: action.response?.data?.next ?? null,
          previousPageUrl: action.response?.data?.previous ?? null
        }
      };
    case SchedulerActions.FETCH_TEAM_SCHEDULING_PAGES_FAILURE:
      return {
        ...state,
        teamPages: {
          ...state.teamPages,
          fetching: false,
          error: action.error
        }
      };
    case SchedulerActions.FETCH_SHARED_SCHEDULING_PAGES_REQUEST:
      return {
        ...state,
        sharedPages: {
          ...state.sharedPages,
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.FETCH_SHARED_SCHEDULING_PAGES_SUCCESS:
      return {
        ...state,
        sharedPages: {
          ...state.sharedPages,
          fetching: false,
          data: {
            ...keyBy(action.response, 'uuid')
          },
          list: [...map(action.response, 'uuid')]
        }
      };
    case SchedulerActions.FETCH_SHARED_SCHEDULING_PAGES_FAILURE:
      return {
        ...state,
        sharedPages: {
          ...state.sharedPages,
          fetching: false,
          error: action.error
        }
      };
    case SchedulerActions.CREATE_SCHEDULING_PAGE_REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.CREATE_SCHEDULING_PAGE_SUCCESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          data: {
            ...state.pages.data,
            [action.response.uuid]: action.response
          },
          list: [action.response.uuid, ...state.pages.list]
        }
      };
    case SchedulerActions.CREATE_SCHEDULING_PAGE_FAILURE:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          error: action.error
        }
      };
    case SchedulerActions.RESCHEDULE_EVENT_REQUEST:
      return {
        ...state,
        events: {
          ...state.events,
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.RESCHEDULE_EVENT_SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          fetching: false,
          data: {
            ...state.events.data,
            [action.response.uuid]: action.response
          }
        }
      };
    case SchedulerActions.RESCHEDULE_EVENT_FAILURE:
      return {
        ...state,
        events: {
          ...state.events,
          fetching: false,
          error: action.error
        }
      };
    case SchedulerActions.DELETE_SCHEDULED_EVENT_REQUEST:
      return {
        ...state,
        events: {
          ...state.events,
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.DELETE_SCHEDULED_EVENT_FAILURE:
      return {
        ...state,
        events: {
          ...state.events,
          fetching: false,
          error: action.error
        }
      };
    case SchedulerActions.RETRIEVE_SCHEDULING_PAGE_REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.RETRIEVE_SCHEDULING_PAGE_SUCCESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          data: {
            ...state.pages.data,
            [action.response.uuid]: action.response
          }
        }
      };
    case SchedulerActions.RETRIEVE_SCHEDULING_PAGE_FAILURE:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          error: action.error
        }
      };
    case SchedulerActions.UPDATE_SCHEDULING_PAGE_REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          updating: true,
          error: null
        }
      };
    case SchedulerActions.UPDATE_SCHEDULING_PAGE_SUCCESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          updating: false,
          data: {
            ...state.pages.data,
            [action.response.uuid]: action.response
          }
        }
      };
    case SchedulerActions.UPDATE_SCHEDULING_PAGE_FAILURE:
      return {
        ...state,
        pages: {
          ...state.pages,
          updating: false,
          error: action.error
        }
      };
    case SchedulerActions.DELETE_MY_SCHEDULING_PAGE_REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.DELETE_MY_SCHEDULING_PAGE_SUCCESS: {
      const newData = { ...state.pages.data };
      delete newData[action.uuid];

      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          data: newData,
          list: state.pages.list.filter(u => u !== action.uuid)
        }
      };
    }
    case SchedulerActions.DELETE_MY_SCHEDULING_PAGE_FAILURE:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          error: action.error
        }
      };
    case SchedulerActions.DELETE_TEAM_SCHEDULING_PAGE_REQUEST:
      return {
        ...state,
        teamPages: {
          ...state.teamPages,
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.DELETE_TEAM_SCHEDULING_PAGE_SUCCESS: {
      const newData = { ...state.teamPages.data };
      delete newData[action.uuid];

      return {
        ...state,
        teamPages: {
          ...state.teamPages,
          fetching: false,
          data: newData,
          list: state.teamPages.list.filter(u => u !== action.uuid)
        }
      };
    }
    case SchedulerActions.DELETE_TEAM_SCHEDULING_PAGE_FAILURE:
      return {
        ...state,
        teamPages: {
          ...state.teamPages,
          fetching: false,
          error: action.error
        }
      };
    case SchedulerActions.DELETE_SHARED_SCHEDULING_PAGE_REQUEST:
      return {
        ...state,
        sharedPages: {
          ...state.sharedPages,
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.DELETE_SHARED_SCHEDULING_PAGE_SUCCESS: {
      const newData = { ...state.sharedPages.data };
      delete newData[action.uuid];

      return {
        ...state,
        sharedPages: {
          ...state.sharedPages,
          fetching: false,
          data: newData,
          list: state.sharedPages.list.filter(u => u !== action.uuid)
        }
      };
    }
    case SchedulerActions.DELETE_SHARED_SCHEDULING_PAGE_FAILURE:
      return {
        ...state,
        sharedPages: {
          ...state.sharedPages,
          fetching: false,
          error: action.error
        }
      };
    case SchedulerActions.CALL_SCH_ROUTER:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.CALL_SCH_ROUTER_SUCCESS:
      if (action.response.actionType === 1) {
        return {
          ...state,
          routerPublicPage: {
            ...state.routerPublicPage,
            actionType: action.response.actionType,
            formSubmissionUuid: action.response.formSubmissionUuid
          },
          pages: {
            ...state.pages,
            fetching: false,
            publicPage: action?.response?.page ?? {}
          }
        };
      }
      if (action.response.actionType === 2) {
        return {
          ...state,
          routerPublicPage: {
            ...state.routerPublicPage,
            actionType: action.response.actionType,
            value: action.response.url
          }
        };
      }
      if (action.response.actionType === 3) {
        return {
          ...state,
          routerPublicPage: {
            ...state.routerPublicPage,
            actionType: action.response.actionType,
            value: action.response.message
          }
        };
      }
      break;
    case SchedulerActions.CALL_SCH_ROUTER_FAILURE:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          error: action.error
        }
      };
    case SchedulerActions.RETRIEVE_PUBLIC_SCH_PAGE_REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.RETRIEVE_PUBLIC_SCH_PAGE_SUCCESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          publicPage: action.response
        }
      };
    case SchedulerActions.RETRIEVE_PUBLIC_SCH_PAGE_FAILURE:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          error: action.error
        }
      };
    case SchedulerActions.CREATE_SCHEDULED_EVENT_REQUEST:
      return {
        ...state,
        events: {
          ...state.events,
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.CREATE_SCHEDULED_EVENT_RESET:
      return {
        ...state,
        events: {
          data: {},
          fetching: false,
          error: null
        }
      };
    case SchedulerActions.CREATE_SCHEDULED_EVENT_SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          fetching: false,
          data: {
            ...state.events.data,
            [action.response.uuid]: action.response
          }
        }
      };
    case SchedulerActions.CREATE_SCHEDULED_EVENT_FAILURE:
      return {
        ...state,
        events: {
          ...state.events,
          fetching: false,
          error: action.error
        }
      };
    case SchedulerActions.RETRIEVE_SCHEDULED_EVENT_REQUEST:
      return {
        ...state,
        events: {
          ...state.events,
          fetching: true,
          error: null
        }
      };
    case SchedulerActions.RETRIEVE_SCHEDULED_EVENT_SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          fetching: false,
          data: {
            ...state.events.data,
            [action.response.uuid]: action.response
          }
        }
      };
    case SchedulerActions.RETRIEVE_SCHEDULED_EVENT_FAILURE:
      return {
        ...state,
        events: {
          ...state.events,
          fetching: false,
          error: action.error
        }
      };

    case SchedulerActions.CONNECTED_CALENDAR_AT_SCHEDULER:
      return {
        ...state
      };

    case SchedulerActions.UPDATE_SCHEDULING_PAGE_STATUS_SUCCESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          data: {
            ...state.pages.data,
            [action.response.uuid]: {
              ...state.pages.data[action.uuid],
              ...action.response
            }
          }
        }
      };
    case SchedulerActions.SET_SCHEDULER_PAGE_SEARCH_TEXT:
      return {
        ...state,
        searchTerm: action.searchSchedulerPageText
      };
    case SchedulerActions.SET_SCHEDULER_SORTING:
      return {
        ...state,
        sortBy: action.sortBy
      };
    case SchedulerActions.SET_ROUTER_SORTING:
      return {
        ...state,
        routerPages: { ...state.routerPages, sortBy: action.sortBy }
      };
    case SchedulerActions.SET_ROUTER_SEARCH:
      return {
        ...state,
        routerPages: { ...state.routerPages, search: action.search }
      };
    case SchedulerActions.UPDATE_EVENT_VIEW_COUNT_REQUEST:
      return {
        ...state
      };
    case SchedulerActions.UPDATE_EVENT_VIEW_COUNT_SUCCESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          data: {
            ...state.pages.data,
            views: action.response
          },
          error: null
        }
      };
    case SchedulerActions.UPDATE_EVENT_VIEW_COUNT_FAILURE:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          error: action.error
        }
      };
    case SchedulerActions.SET_SELECTED_EVENT_DATE:
      return {
        ...state,
        selectedEventDate: action.date
      };
    case SchedulerActions.FETCH_SCHEDULER_PUBLIC_LINK_REQUEST:
      return { ...state };
    case SchedulerActions.FETCH_SCHEDULER_PUBLIC_LINK_SUCCESS:
      return {
        ...state,
        publicLink: {
          ...action.response,
          error: null
        }
      };
    case SchedulerActions.FETCH_SCHEDULER_PUBLIC_LINK_FAILURE:
      return {
        ...state,
        publicLink: {
          publicLinkPrefix: action.error?.publicLinkPrefix,
          slug: action.error?.slug,
          publicSlug: state.publicLink.publicSlug,
          publicLink: state.publicLink.publicLink,
          error: action.error?.publicLink ?? [][0]
        }
      };
    case SchedulerActions.FETCH_ALL_SCHEDULING_PAGES_REQUEST:
      if (action.isPaginate) {
        return {
          ...state,
          allPages: {
            ...state.allPages,
            fetching: true,
            error: null
          }
        };
      }

      return {
        ...state,
        allPages: {
          ...state.allPages,
          data: {},
          list: [],
          fetching: true,
          error: null
        }
      };

    case SchedulerActions.FETCH_ALL_SCHEDULING_PAGES_SUCCESS:
      if (action.response.isPaginate) {
        return {
          ...state,
          allPages: {
            ...state.allPages,
            fetching: false,
            data: {
              ...state.allPages.data,
              ...keyBy(action.response.data.results, 'uuid')
            },
            list: [
              ...state.allPages.list,
              ...map(action.response.data.results, 'uuid')
            ],
            nextPageUrl: action.response.data.next,
            previousPageUrl: action.response.data.previous
          }
        };
      }
      return {
        ...state,
        allPages: {
          fetching: false,
          data: {
            ...keyBy(action.response.data.results, 'uuid')
          },
          list: [...map(action.response.data.results, 'uuid')],
          nextPageUrl: action.response.data.next,
          previousPageUrl: action.response.data.previous
        }
      };

    case SchedulerActions.FETCH_ALL_SCHEDULING_PAGES_FAILURE:
      return {
        ...state,
        pages: {
          ...state.pages,
          fetching: false,
          error: action.error
        }
      };

    case SchedulerActions.DELETE_ALL_SCHEDULING_PAGE_REQUEST:
      return {
        ...state,
        allPages: {
          ...state.allPages,
          fetching: true,
          error: null
        }
      };

    case SchedulerActions.DELETE_ALL_SCHEDULING_PAGE_SUCCESS: {
      const newData = { ...state.allPages.data };
      delete newData[action.uuid];
      const newDataList = state.allPages.list.filter(u => u !== action.uuid);

      return {
        ...state,
        allPages: {
          ...state.allPages,
          fetching: false,
          data: newData,
          list: newDataList
        }
      };
    }

    default:
      return state;
  }
};

export default schedulerReducer;
