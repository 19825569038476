import Tokens from 'styles/tokens';

const HighlightCategoryColors = {
  magenta: {
    foreground: Tokens.colors.magenta,
    neutral: Tokens.colors.magentaLight,
    background: Tokens.colors.magentaLighter
  },
  red: {
    foreground: Tokens.colors.red,
    neutral: Tokens.colors.redLight,
    background: Tokens.colors.redLighter
  },
  yellow: {
    foreground: Tokens.colors.yellow,
    neutral: Tokens.colors.yellowLight,
    background: Tokens.colors.yellowLighter
  },
  green: {
    foreground: Tokens.colors.green,
    neutral: Tokens.colors.greenLight,
    background: Tokens.colors.greenLighter
  },
  teal: {
    foreground: Tokens.colors.teal,
    neutral: Tokens.colors.tealLight,
    background: Tokens.colors.tealLighter
  },
  azure: {
    foreground: Tokens.colors.azure,
    neutral: Tokens.colors.azureLight,
    background: Tokens.colors.azureLighter
  },
  blue: {
    foreground: Tokens.colors.blue,
    neutral: Tokens.colors.blueLight,
    background: Tokens.colors.blueLighter
  },
  purple: {
    foreground: Tokens.colors.purple,
    neutral: Tokens.colors.purpleLight,
    background: Tokens.colors.purpleLighter
  },
  silver: {
    foreground: Tokens.colors.silver,
    neutral: Tokens.colors.silverLighter,
    background: Tokens.colors.f1
  },
  gunmetal: {
    foreground: Tokens.colors.gunmetal,
    neutral: Tokens.colors.gunmetalLighter,
    background: Tokens.colors.gainsboro
  }
};

const HighlightCategoryColorsLegacy = {
  magentaLegacy: {
    foreground: Tokens.colors.magenta,
    neutral: Tokens.colors.magentaLight,
    background: '#FDE7EF'
  },
  redLegacy: {
    foreground: Tokens.colors.red,
    neutral: Tokens.colors.redLight,
    background: '#FFF0F0'
  },
  greenLegacy: {
    foreground: Tokens.colors.green,
    neutral: Tokens.colors.greenLight,
    background: '#F6FDF9'
  },
  tealLegacy: {
    foreground: Tokens.colors.teal,
    neutral: Tokens.colors.tealLight,
    background: '#DCF5F4'
  },
  blueLegacy: {
    foreground: Tokens.colors.blue,
    neutral: Tokens.colors.blueLight,
    background: '#CCE2F6'
  },
  purpleLegacy: {
    foreground: Tokens.colors.purple,
    neutral: Tokens.colors.purpleLight,
    background: '#E1D8F1'
  }
};

export const ColorMap = {};
Object.keys(HighlightCategoryColors).forEach(key => {
  const colors = HighlightCategoryColors[key];
  ColorMap[colors.background] = {
    foreground: colors.foreground,
    neutral: colors.neutral
  };
});
Object.keys(HighlightCategoryColorsLegacy).forEach(key => {
  const colors = HighlightCategoryColorsLegacy[key];
  ColorMap[colors.background] = {
    foreground: colors.foreground,
    neutral: colors.neutral
  };
});

export const getBookmarkColor = (bookmark, highlightCategoriesMap) => {
  const cat = highlightCategoriesMap[bookmark.category];
  if (!cat) {
    return Tokens.colors.blue;
  }
  const color =
    (ColorMap[cat.backgroundColor] &&
      ColorMap[cat.backgroundColor].foreground) ||
    cat.backgroundColor;
  return color;
};

export default HighlightCategoryColors;
