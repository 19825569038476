import { ScorecardsActions } from 'actions/actionTypes';

export const SCORECARDS_INITIAL_STATE = {
  scorecardTemplates: {
    data: {},
    currentScorecardUuid: null,
    currentAutoGeneratedChoices: {
      data: {},
      fetching: false,
      status: 'idle',
      error: null
    },
    meetingTypes: {},
    fetching: false,
    status: 'idle',
    error: null
  },
  scorecardAiQuestionLibrary: {
    data: {},
    fetching: false,
    status: 'idle',
    error: null
  },
  scorecards: {
    data: {},
    fetching: false,
    status: 'idle',
    error: null,
    currentScorecard: null,
    scorecardUuidToFetch: null,
    questions: {
      data: {},
      fetching: false,
      error: null
    },
    answers: {
      data: {},
      fetching: false,
      error: null
    }
  },
  dashboard: {
    scorecardsByQuestionChoice: {
      data: {},
      fetching: false,
      status: 'idle',
      error: null
    },
    scores: {
      data: {},
      fetching: false,
      status: 'idle',
      error: null
    },
    requests: {
      data: {},
      fetching: false,
      status: 'idle',
      error: null
    },
    currentPage: null,
    currentMemberUuid: null,
    currentScorecardUuid: null,
    fetching: false,
    status: 'idle',
    error: null
  }
};

const scorecardsReducer = (state = SCORECARDS_INITIAL_STATE, action) => {
  switch (action.type) {
    case ScorecardsActions.FETCH_SCORECARD_TEMPLATES_REQUEST:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          fetching: true,
          status: 'loading',
          error: null
        }
      };
    case ScorecardsActions.FETCH_SCORECARD_TEMPLATES_SUCCESS:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...action.scorecards,
            ...state.scorecardTemplates.data
          },
          fetching: false,
          status: 'success',
          error: null
        }
      };
    case ScorecardsActions.FETCH_SCORECARD_TEMPLATES_FAILURE:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data
          },
          fetching: false,
          status: 'failed',
          error: action.error
        }
      };
    case ScorecardsActions.FETCH_SINGLE_SCORECARD_TEMPLATE_REQUEST:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          fetching: true,
          status: 'loading',
          error: null
        }
      };
    case ScorecardsActions.FETCH_SINGLE_SCORECARD_TEMPLATE_SUCCESS:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data,
            [action.response.uuid]: action.response
          },
          fetching: false,
          status: 'success',
          error: null
        }
      };
    case ScorecardsActions.FETCH_SINGLE_SCORECARD_TEMPLATE_FAILURE:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data
          },
          fetching: false,
          status: 'failed',
          error: action.error
        }
      };
    case ScorecardsActions.FETCH_UNUSED_MEETING_TYPES_REQUEST:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          fetching: true,
          status: 'loading',
          error: null
        }
      };
    case ScorecardsActions.FETCH_UNUSED_MEETING_TYPES_SUCCESS:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          meetingTypes: {
            ...action.meetingTypes
          },
          fetching: false,
          status: 'success',
          error: null
        }
      };
    case ScorecardsActions.FETCH_UNUSED_MEETING_TYPES_FAILURE:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          meetingTypes: {
            ...state.scorecardTemplates.meetingTypes
          },
          fetching: false,
          status: 'failed',
          error: action.error
        }
      };
    case ScorecardsActions.CREATE_SCORECARD_TEMPLATE_REQUEST:
    case ScorecardsActions.CREATE_AI_SCORECARD_TEMPLATE_REQUEST:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          fetching: true,
          status: 'loading',
          error: null
        }
      };
    case ScorecardsActions.CREATE_SCORECARD_TEMPLATE_SUCCESS:
    case ScorecardsActions.CREATE_AI_SCORECARD_TEMPLATE_SUCCESS:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data,
            [action.response.uuid]: action.response
          },
          currentScorecardUuid: action.response.uuid,
          fetching: false,
          status: 'success',
          error: null
        }
      };
    case ScorecardsActions.CREATE_SCORECARD_TEMPLATE_FAILURE:
    case ScorecardsActions.CREATE_AI_SCORECARD_TEMPLATE_FAILURE:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data
          },
          fetching: false,
          status: 'failed',
          error: action.error
        }
      };
    case ScorecardsActions.UPDATE_SCORECARD_TEMPLATE_REQUEST:
    case ScorecardsActions.UPDATE_AI_SCORECARD_TEMPLATE_REQUEST:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          fetching: true,
          status: 'loading',
          error: null
        }
      };
    case ScorecardsActions.UPDATE_SCORECARD_TEMPLATE_SUCCESS:
    case ScorecardsActions.UPDATE_AI_SCORECARD_TEMPLATE_SUCCESS:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data,
            [action.response.uuid]: action.response
          },
          currentScorecardUuid: action.response.uuid,
          fetching: false,
          status: 'success',
          error: null
        }
      };
    case ScorecardsActions.UPDATE_SCORECARD_TEMPLATE_FAILURE:
    case ScorecardsActions.UPDATE_AI_SCORECARD_TEMPLATE_FAILURE:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data
          },
          fetching: false,
          status: 'failed',
          error: action.error
        }
      };
    case ScorecardsActions.CLONE_SCORECARD_TEMPLATE_REQUEST:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          fetching: true,
          status: 'loading',
          error: null
        }
      };
    case ScorecardsActions.CLONE_SCORECARD_TEMPLATE_SUCCESS:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data,
            [action.response.uuid]: action.response
          },
          currentScorecardUuid: action.response.uuid,
          fetching: false,
          status: 'success',
          error: null
        }
      };
    case ScorecardsActions.CLONE_SCORECARD_TEMPLATE_FAILURE:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data
          },
          fetching: false,
          status: 'failed',
          error: action.error
        }
      };
    case ScorecardsActions.UPDATE_SCORECARD_TEMPLATE_PURPOSE_REQUEST:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          fetching: true,
          status: 'loading',
          error: null
        }
      };
    case ScorecardsActions.UPDATE_SCORECARD_TEMPLATE_PURPOSE_SUCCESS:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data,
            [action.response.uuid]: {
              ...state.scorecardTemplates.data[action.response.uuid],
              meetingTypes: action.response.meetingTypes
            }
          },
          currentScorecardUuid: action.response.uuid,
          fetching: false,
          status: 'success',
          error: null
        }
      };
    case ScorecardsActions.UPDATE_SCORECARD_TEMPLATE_PURPOSE_FAILURE:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data
          },
          fetching: false,
          status: 'failed',
          error: action.error
        }
      };
    case ScorecardsActions.UPDATE_SCORECARD_TEMPLATE_STATE_REQUEST:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          fetching: true,
          status: 'loading',
          error: null
        }
      };
    case ScorecardsActions.UPDATE_SCORECARD_TEMPLATE_STATE_SUCCESS:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data,
            [action.response.uuid]: {
              ...state.scorecardTemplates.data[action.response.uuid],
              state: action.response.state
            }
          },
          currentScorecardUuid: action.response.uuid,
          fetching: false,
          status: 'success',
          error: null
        }
      };
    case ScorecardsActions.UPDATE_SCORECARD_TEMPLATE_STATE_FAILURE:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data
          },
          fetching: false,
          status: 'failed',
          error: action.error
        }
      };
    case ScorecardsActions.UPDATE_SCORECARD_TEMPLATE_TEAM_REQUEST:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          fetching: true,
          status: 'loading',
          error: null
        }
      };
    case ScorecardsActions.UPDATE_SCORECARD_TEMPLATE_TEAM_SUCCESS:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data,
            [action.response.uuid]: {
              ...state.scorecardTemplates.data[action.response.uuid],
              teams: action.response.teams
            }
          },
          currentScorecardUuid: action.response.uuid,
          fetching: false,
          status: 'success',
          error: null
        }
      };
    case ScorecardsActions.UPDATE_SCORECARD_TEMPLATE_TEAM_FAILURE:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: {
            ...state.scorecardTemplates.data
          },
          fetching: false,
          status: 'failed',
          error: action.error
        }
      };
    case ScorecardsActions.DELETE_SCORECARD_TEMPLATE_REQUEST:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          fetching: true,
          status: 'loading',
          error: null
        }
      };
    case ScorecardsActions.DELETE_SCORECARD_TEMPLATE_SUCCESS: {
      const { [action.uuid]: removedScorecard, ...remainingScorecardData } =
        state.scorecardTemplates.data;

      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          data: remainingScorecardData,
          fetching: false,
          status: 'success',
          error: null
        }
      };
    }
    case ScorecardsActions.DELETE_SCORECARD_TEMPLATE_FAILURE:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          fetching: false,
          status: 'failed',
          error: action.error
        }
      };
    case ScorecardsActions.FETCH_AUTO_GENERATED_QUESTION_CHOICES_REQUEST:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          currentAutoGeneratedChoices: {
            ...state.scorecardTemplates.currentAutoGeneratedChoices,
            fetching: true,
            status: 'loading',
            error: null
          }
        }
      };
    case ScorecardsActions.FETCH_AUTO_GENERATED_QUESTION_CHOICES_SUCCESS:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          currentAutoGeneratedChoices: {
            ...state.scorecardTemplates.currentAutoGeneratedChoices,
            data: action.response,
            fetching: false,
            status: 'success',
            error: null
          }
        }
      };
    case ScorecardsActions.FETCH_AUTO_GENERATED_QUESTION_CHOICES_FAILURE:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          currentAutoGeneratedChoices: {
            ...state.scorecardTemplates.currentAutoGeneratedChoices,
            fetching: false,
            status: 'failed',
            error: action.error
          }
        }
      };
    case ScorecardsActions.EMPTY_AUTO_GENERATED_QUESTION_CHOICES:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          currentAutoGeneratedChoices: {
            ...state.scorecardTemplates.currentAutoGeneratedChoices,
            data: {},
            fetching: false,
            error: null
          }
        }
      };
    case ScorecardsActions.EMPTY_SCORECARD_TEMPLATE_ERRORS:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          error: null
        }
      };
    case ScorecardsActions.SET_CURRENT_SCORECARD_TEMPLATE_UUID:
      return {
        ...state,
        scorecardTemplates: {
          ...state.scorecardTemplates,
          currentScorecardUuid: action.response
        }
      };
    case ScorecardsActions.FETCH_SCORECARD_AI_QUESTION_LIBRARY_REQUEST:
      return {
        ...state,
        scorecardAiQuestionLibrary: {
          ...state.scorecardAiQuestionLibrary,
          fetching: true,
          status: 'loading',
          error: null
        }
      };
    case ScorecardsActions.FETCH_SCORECARD_AI_QUESTION_LIBRARY_SUCCESS:
      return {
        ...state,
        scorecardAiQuestionLibrary: {
          ...state.scorecardAiQuestionLibrary,
          fetching: true,
          status: 'loading',
          data: action.aiQuestionLibrary,
          error: null
        }
      };
    case ScorecardsActions.FETCH_SCORECARD_AI_QUESTION_LIBRARY_FAILURE:
      return {
        ...state,
        scorecardAiQuestionLibrary: {
          ...state.scorecardAiQuestionLibrary,
          fetching: false,
          status: 'failed',
          error: action.error
        }
      };
    case ScorecardsActions.FETCH_SCORECARDS_REQUEST:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          fetching: true,
          error: null
        }
      };
    case ScorecardsActions.FETCH_SCORECARDS_SUCCESS:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          fetching: false,
          data: action.response,
          error: null
        }
      };
    case ScorecardsActions.FETCH_SCORECARDS_FAILURE:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          fetching: false,
          error: action.error
        }
      };
    case ScorecardsActions.EMPTY_SCORECARDS:
      return {
        ...state,
        scorecards: {
          data: {},
          fetching: false,
          error: null
        }
      };
    case ScorecardsActions.FETCH_SINGLE_SCORECARD_REQUEST:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          fetching: true,
          error: null
        }
      };
    case ScorecardsActions.FETCH_SINGLE_SCORECARD_SUCCESS:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          data: {
            ...state.scorecards.data,
            [action.scorecardUuid]: action.response
          },
          fetching: false,
          error: null
        }
      };
    case ScorecardsActions.FETCH_SINGLE_SCORECARD_FAILURE:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          fetching: false,
          error: action.error
        }
      };
    case ScorecardsActions.CREATE_SCORECARD_REQUEST:
    case ScorecardsActions.CREATE_AI_SCORECARD_REQUEST:
    case ScorecardsActions.SCORECARD_FINAL_SUBMIT_REQUEST:
    case ScorecardsActions.EDIT_SCORECARD_REQUEST:
    case ScorecardsActions.POST_SCORE_OVERALL_FEEDBACK_REQUEST:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          fetching: true,
          error: null
        }
      };
    case ScorecardsActions.CREATE_SCORECARD_SUCCESS:
    case ScorecardsActions.SCORECARD_FINAL_SUBMIT_SUCCESS:
    case ScorecardsActions.EDIT_SCORECARD_SUCCESS:
    case ScorecardsActions.POST_SCORE_OVERALL_FEEDBACK_SUCCESS:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          data: {
            ...state.scorecards.data,
            [action.response.uuid]: action.response
          },
          currentScorecard: action.response,
          fetching: false,
          error: null
        }
      };
    case ScorecardsActions.CREATE_AI_SCORECARD_SUCCESS:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          data: {
            ...state.scorecards.data,
            [action.response.uuid]: action.response
          },
          fetching: false,
          error: null
        }
      };
    case ScorecardsActions.CREATE_SCORECARD_FAILURE:
    case ScorecardsActions.CREATE_AI_SCORECARD_FAILURE:
    case ScorecardsActions.SCORECARD_FINAL_SUBMIT_FAILURE:
    case ScorecardsActions.EDIT_SCORECARD_FAILURE:
    case ScorecardsActions.POST_SCORE_OVERALL_FEEDBACK_FAILURE:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          fetching: false,
          error: action.error
        }
      };
    case ScorecardsActions.FETCH_SCORECARD_TEMPLATES_QUESTIONS_REQUEST:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          questions: {
            ...state.scorecards.questions,
            fetching: true,
            error: null
          }
        }
      };
    case ScorecardsActions.FETCH_SCORECARD_TEMPLATES_QUESTIONS_SUCCESS:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          questions: {
            ...state.scorecards.questions,
            fetching: false,
            data: action.response,
            error: null
          }
        }
      };
    case ScorecardsActions.FETCH_SCORECARD_TEMPLATES_QUESTIONS_FAILURE:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          questions: {
            ...state.scorecards.questions,
            fetching: false,
            error: action.error
          }
        }
      };
    case ScorecardsActions.SET_CURRENT_SCORECARD:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          currentScorecard: action.response
        }
      };
    case ScorecardsActions.SET_SCORECARD_UUID_TO_FETCH:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          scorecardUuidToFetch: action.response
        }
      };
    case ScorecardsActions.EMPTY_SCORECARD_UUID_TO_FETCH:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          scorecardUuidToFetch: null
        }
      };
    case ScorecardsActions.FETCH_SCORECARD_ANSWERS_REQUEST:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            fetching: true,
            error: null
          }
        }
      };
    case ScorecardsActions.FETCH_SCORECARD_ANSWERS_SUCCESS:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            fetching: false,
            data: action.response,
            error: null
          }
        }
      };
    case ScorecardsActions.FETCH_SCORECARD_ANSWERS_FAILURE:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            fetching: false,
            error: action.error
          }
        }
      };
    case ScorecardsActions.ANSWER_SCORECARD_QUESTION_REQUEST:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            fetching: true,
            error: null
          }
        }
      };
    case ScorecardsActions.ANSWER_SCORECARD_QUESTION_SUCCESS:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            data: {
              ...state.scorecards.answers.data,
              [action.response.uuid]: action.response
            },
            fetching: false,
            error: null
          }
        }
      };
    case ScorecardsActions.ANSWER_SCORECARD_QUESTION_FAILURE:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            fetching: false,
            error: action.error
          }
        }
      };
    case ScorecardsActions.EMPTY_SCORECARD_QUESTIONS_ANSWERS:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            data: {},
            fetching: false,
            error: null
          },
          questions: {
            data: {},
            fetching: false,
            error: null
          }
        }
      };
    case ScorecardsActions.DELETE_SCORECARD_REQUEST:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          fetching: true,
          error: null
        }
      };
    case ScorecardsActions.DELETE_SCORECARD_SUCCESS: {
      const { [action.uuid]: removedScorecard, ...remainingScorecardData } =
        state.scorecards.data;

      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          data: remainingScorecardData,
          fetching: false,
          error: null
        }
      };
    }
    case ScorecardsActions.DELETE_SCORECARD_FAILURE:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          fetching: false,
          error: action.error
        }
      };
    case ScorecardsActions.REQUEST_SCORE_REQUEST:
      return state;
    case ScorecardsActions.REQUEST_SCORE_SUCCESS:
      return state;
    case ScorecardsActions.REQUEST_SCORE_FAILURE:
      return state;
    case ScorecardsActions.POST_SCORE_ANSWER_COMMENT_REQUEST:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            fetching: true,
            error: null
          }
        }
      };
    case ScorecardsActions.POST_SCORE_ANSWER_COMMENT_SUCCESS:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            data: {
              ...state.scorecards.answers.data,
              [action.response.uuid]: action.response
            },
            fetching: false,
            error: null
          }
        }
      };
    case ScorecardsActions.POST_SCORE_ANSWER_COMMENT_FAILURE:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            fetching: false,
            error: action.error
          }
        }
      };
    case ScorecardsActions.UPDATE_SCORE_OVERALL_FEEDBACK_REQUEST:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          fetching: true,
          error: null
        }
      };
    case ScorecardsActions.UPDATE_SCORE_OVERALL_FEEDBACK_SUCCESS: {
      const updatedOverallFeedbackComments = {
        ...state.scorecards.data[action.scorecardUuid].comments
      };

      if (
        Object.keys(updatedOverallFeedbackComments)[0] === action.commentUuid
      ) {
        updatedOverallFeedbackComments[action.commentUuid] = action.response;
      } else {
        Object.values(updatedOverallFeedbackComments)[0].children[
          action.commentUuid
        ] = action.response;
      }

      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          data: {
            ...state.scorecards.data,
            [action.scorecardUuid]: {
              ...state.scorecards.data[action.scorecardUuid],
              comments: updatedOverallFeedbackComments
            }
          },
          fetching: false,
          error: null
        }
      };
    }
    case ScorecardsActions.UPDATE_SCORE_OVERALL_FEEDBACK_FAILURE:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          fetching: false,
          error: action.error
        }
      };
    case ScorecardsActions.UPDATE_SCORE_ANSWER_COMMENT_REQUEST:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            fetching: true,
            error: null
          }
        }
      };
    case ScorecardsActions.UPDATE_SCORE_ANSWER_COMMENT_SUCCESS: {
      const updatedAnswersComments = {
        ...state.scorecards.answers.data[action.scorecardAnswerUuid].comments
      };

      if (Object.keys(updatedAnswersComments)[0] === action.commentUuid) {
        updatedAnswersComments[action.commentUuid] = action.response;
      } else {
        Object.values(updatedAnswersComments)[0].children[action.commentUuid] =
          action.response;
      }

      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            data: {
              ...state.scorecards.answers.data,
              [action.scorecardAnswerUuid]: {
                ...state.scorecards.answers.data[action.scorecardAnswerUuid],
                comments: updatedAnswersComments
              }
            },
            fetching: false,
            error: null
          }
        }
      };
    }
    case ScorecardsActions.UPDATE_SCORE_ANSWER_COMMENT_FAILURE:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            fetching: false,
            error: action.error
          }
        }
      };
    case ScorecardsActions.DELETE_SCORE_OVERALL_FEEDBACK_REQUEST:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          fetching: true,
          error: null
        }
      };
    case ScorecardsActions.DELETE_SCORE_OVERALL_FEEDBACK_SUCCESS: {
      const updatedOverallFeedbackComments = {
        ...state.scorecards.data[action.scorecardUuid].comments
      };

      if (
        Object.keys(updatedOverallFeedbackComments)[0] === action.commentUuid
      ) {
        delete updatedOverallFeedbackComments[action.commentUuid];
      } else {
        delete Object.values(updatedOverallFeedbackComments)[0].children[
          action.commentUuid
        ];
      }

      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          data: {
            ...state.scorecards.data,
            [action.scorecardUuid]: {
              ...state.scorecards.data[action.scorecardUuid],
              comments: updatedOverallFeedbackComments
            }
          },
          fetching: false,
          error: null
        }
      };
    }
    case ScorecardsActions.DELETE_SCORE_ANSWER_COMMENT_REQUEST:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            fetching: true,
            error: null
          }
        }
      };
    case ScorecardsActions.DELETE_SCORE_ANSWER_COMMENT_SUCCESS: {
      const updatedAnswersComments = {
        ...state.scorecards.answers.data[action.scorecardAnswerUuid].comments
      };

      if (Object.keys(updatedAnswersComments)[0] === action.commentUuid) {
        delete updatedAnswersComments[action.commentUuid];
      } else {
        delete Object.values(updatedAnswersComments)[0].children[
          action.commentUuid
        ];
      }

      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            data: {
              ...state.scorecards.answers.data,
              [action.scorecardAnswerUuid]: {
                ...state.scorecards.answers.data[action.scorecardAnswerUuid],
                comments: updatedAnswersComments
              }
            },
            fetching: false,
            error: null
          }
        }
      };
    }
    case ScorecardsActions.POST_OVERALL_FEEDBACK_REACTION_REQUEST: {
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          fetching: true,
          error: null
        }
      };
    }
    case ScorecardsActions.POST_OVERALL_FEEDBACK_REACTION_SUCCESS: {
      const updatedOverallFeedbackComments = [
        ...state.scorecards.data[action.scorecardUuid].comments
      ];

      const updateIndex = updatedOverallFeedbackComments.findIndex(
        comment => comment.uuid === action.commentUuid
      );

      if (updateIndex > -1) {
        updatedOverallFeedbackComments[updateIndex].emojis = action.response;
      } else {
        const childUpdateIndex =
          updatedOverallFeedbackComments[0].children.findIndex(
            comment => comment.uuid === action.commentUuid
          );
        updatedOverallFeedbackComments[0].children[childUpdateIndex].emojis =
          action.response;
      }

      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          data: {
            ...state.scorecards.data,
            [action.scorecardUuid]: {
              ...state.scorecards.data[action.scorecardUuid],
              comments: updatedOverallFeedbackComments
            }
          },
          fetching: false,
          error: null
        }
      };
    }
    case ScorecardsActions.POST_OVERALL_FEEDBACK_REACTION_FAILURE: {
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          fetching: false,
          error: action.error
        }
      };
    }
    case ScorecardsActions.POST_COMMENT_REACTION_REQUEST:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            fetching: true,
            error: null
          }
        }
      };
    case ScorecardsActions.POST_COMMENT_REACTION_SUCCESS: {
      const updatedAnswersComments = [
        ...state.scorecards.answers.data[action.answerUuid].comments
      ];

      const updateIndex = updatedAnswersComments.findIndex(
        comment => comment.uuid === action.commentUuid
      );

      if (updateIndex > -1) {
        updatedAnswersComments[updateIndex].emojis = action.response;
      }

      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            data: {
              ...state.scorecards.answers.data,
              [action.answerUuid]: {
                ...state.scorecards.answers.data[action.answerUuid],
                comments: updatedAnswersComments
              }
            },
            fetching: false,
            error: null
          }
        }
      };
    }
    case ScorecardsActions.POST_COMMENT_REACTION_FAILURE:
      return {
        ...state,
        scorecards: {
          ...state.scorecards,
          answers: {
            ...state.scorecards.answers,
            fetching: false,
            error: action.error
          }
        }
      };
    case ScorecardsActions.FETCH_USER_SCORES_REQUEST: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          scores: {
            ...state.dashboard.scores,
            fetching: true,
            status: 'loading',
            error: null
          },
          fetching: true,
          status: 'loading',
          error: null
        }
      };
    }
    case ScorecardsActions.FETCH_USER_SCORES_SUCCESS: {
      const hasScorecardsData = Boolean(
        state.dashboard.scores.data[action.dashboardUuid]
      );

      if (hasScorecardsData && !action.queryParams) {
        return {
          ...state,
          dashboard: {
            ...state.dashboard,
            scores: {
              ...state.dashboard.scores,
              data: {
                ...state.dashboard.scores.data,
                [action.dashboardUuid]: {
                  ...state.dashboard.scores.data[action.dashboardUuid],
                  scorecards: [
                    ...state.dashboard.scores.data[action.dashboardUuid]
                      .scorecards,
                    ...action.results
                  ],
                  next: action.next,
                  previous: action.previous,
                  count: action.count
                }
              },
              fetching: false,
              status: 'success',
              error: null
            },
            fetching: false,
            status: 'success',
            error: null
          }
        };
      }
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          scores: {
            ...state.dashboard.scores,
            data: {
              ...state.dashboard.scores.data,
              [action.dashboardUuid]: {
                ...state.dashboard.scores.data[action.dashboardUuid],
                scorecards: action.results,
                next: action.next,
                previous: action.previous,
                count: action.count
              }
            },
            fetching: false,
            status: 'success',
            error: null
          },
          fetching: false,
          status: 'success',
          error: null
        }
      };
    }
    case ScorecardsActions.FETCH_USER_SCORES_FAILURE: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          scores: {
            ...state.dashboard.scores,
            fetching: false,
            error: action.error
          },
          fetching: false,
          status: 'failed',
          error: action.error
        }
      };
    }
    case ScorecardsActions.FETCH_USER_REQUESTS_REQUEST: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          requests: {
            ...state.dashboard.requests,
            fetching: true,
            status: 'loading',
            error: null
          },
          fetching: true,
          status: 'loading',
          error: null
        }
      };
    }
    case ScorecardsActions.FETCH_USER_REQUESTS_SUCCESS: {
      const hasScorecardsData = Boolean(
        state.dashboard.requests.data[action.dashboardUuid]
      );

      if (hasScorecardsData && !action.queryParams) {
        return {
          ...state,
          dashboard: {
            ...state.dashboard,
            requests: {
              ...state.dashboard.requests,
              data: {
                ...state.dashboard.requests.data,
                [action.dashboardUuid]: {
                  ...state.dashboard.requests.data[action.dashboardUuid],
                  scorecards: [
                    ...state.dashboard.requests.data[action.dashboardUuid]
                      .scorecards,
                    ...action.results
                  ],
                  next: action.next,
                  previous: action.previous,
                  count: action.count
                }
              },
              fetching: false,
              status: 'success',
              error: null
            },
            fetching: false,
            status: 'success',
            error: null
          }
        };
      }
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          requests: {
            ...state.dashboard.requests,
            data: {
              ...state.dashboard.requests.data,
              [action.dashboardUuid]: {
                ...state.dashboard.requests.data[action.dashboardUuid],
                scorecards: action.results,
                next: action.next,
                previous: action.previous,
                count: action.count
              }
            },
            fetching: false,
            status: 'success',
            error: null
          },
          fetching: false,
          status: 'success',
          error: null
        }
      };
    }
    case ScorecardsActions.FETCH_USER_REQUESTS_FAILURE: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          requests: {
            ...state.dashboard.requests,
            fetching: false,
            error: action.error
          },
          fetching: false,
          status: 'failed',
          error: action.error
        }
      };
    }
    case ScorecardsActions.FETCH_SCORECARDS_BY_QUESTION_CHOICE_UUID_REQUEST: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          scorecardsByQuestionChoice: {
            ...state.dashboard.scorecardsByQuestionChoice,
            fetching: true,
            status: 'loading',
            error: null
          },
          fetching: true,
          status: 'loading',
          error: null
        }
      };
    }
    case ScorecardsActions.FETCH_SCORECARDS_BY_QUESTION_CHOICE_UUID_SUCCESS: {
      const hasScorecardsData = Boolean(
        state.dashboard.scorecardsByQuestionChoice.data[action.scorecardUuid]
      );

      if (hasScorecardsData && !action.queryParams) {
        return {
          ...state,
          dashboard: {
            ...state.dashboard,
            scorecardsByQuestionChoice: {
              ...state.dashboard.scorecardsByQuestionChoice,
              data: {
                ...state.dashboard.scorecardsByQuestionChoice.data,
                [action.scorecardUuid]: {
                  ...state.dashboard.scorecardsByQuestionChoice.data[
                    action.scorecardUuid
                  ],
                  scorecards: [
                    ...state.dashboard.scorecardsByQuestionChoice.data[
                      action.scorecardUuid
                    ].scorecards,
                    ...action.results
                  ],
                  next: action.next,
                  previous: action.previous,
                  count: action.count
                }
              },
              fetching: false,
              status: 'success',
              error: null
            },
            fetching: false,
            status: 'success',
            error: null
          }
        };
      }
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          scorecardsByQuestionChoice: {
            ...state.dashboard.scorecardsByQuestionChoice,
            data: {
              ...state.dashboard.scorecardsByQuestionChoice.data,
              [action.scorecardUuid]: {
                ...state.dashboard.scorecardsByQuestionChoice.data[
                  action.scorecardUuid
                ],
                scorecards: action.results,
                next: action.next,
                previous: action.previous,
                count: action.count
              }
            },
            fetching: false,
            status: 'success',
            error: null
          },
          fetching: false,
          status: 'success',
          error: null
        }
      };
    }
    case ScorecardsActions.FETCH_SCORECARDS_BY_QUESTION_CHOICE_UUID_FAILURE: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          scorecardsByQuestionChoice: {
            ...state.dashboard.scorecardsByQuestionChoice,
            fetching: false,
            status: 'failed',
            error: action.error
          },
          fetching: false,
          status: 'failed',
          error: action.error
        }
      };
    }
    case ScorecardsActions.EMPTY_SCORECARDS_BY_QUESTION_CHOICE_UUID:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          scorecardsByQuestionChoice: {
            data: {},
            fetching: false,
            status: 'idle',
            error: null
          },
          fetching: false,
          status: 'idle',
          error: null
        }
      };
    case ScorecardsActions.SET_CURRENT_PERFORMANCE_PAGE: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          currentPage: action.response
        }
      };
    }
    case ScorecardsActions.SET_CURRENT_PERFORMANCE_MEMBER_UUID: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          currentMemberUuid: action.response
        }
      };
    }
    case ScorecardsActions.SET_CURRENT_PERFORMANCE_SCORECARD_UUID: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          currentScorecardUuid: action.response
        }
      };
    }
    default:
      return state;
  }
};

export default scorecardsReducer;
