import PropTypes from 'prop-types';
import Radium from 'radium';
import React, { useState } from 'react';

import AvomaButton from 'components/Common/AvomaButton';
import ButtonUnstyled from 'components/Common/ButtonUnstyled';
import TextField from 'components/Common/TextField';

import L10n from 'helpers/L10n';

import deleteIcon from 'images/ic_delete.svg';
import editIcon from 'images/ic_edit_pen.svg';

import Tokens from 'styles/tokens';

const Variation = ({
  word,
  onClickDeleteVariation,
  onUpdateLabel,
  disableAddInput,
  onClickMakePrimary,
  isMakePrimaryActionAllowed = true
}) => {
  const isPrimary = word?.isPrimary;
  const [currentRowUuid, setCurrentRowUuid] = useState('');
  const [editing, setEditing] = useState(false);
  const [inlineEditText, setInlineEditText] = useState('');
  const wordLabel = word.label;

  const style = {};

  // For changes to the row based on hover
  const onMouseEnter = () => {
    setCurrentRowUuid(word.uuid);
  };

  const onMouseLeave = () => {
    setCurrentRowUuid('');
  };

  const handleKeyDownAddButton = event => {
    switch (event.keyCode) {
      case 13: // Enter key
        handleOnClickSave();
        break;
      default:
        break;
    }
  };

  const handleInlineEditing = () => {
    setEditing(true);
    disableAddInput(true);
    setInlineEditText(wordLabel);
  };

  const onChangeInlineText = event => {
    setInlineEditText(event.target.value);
  };

  const handleOnClickSave = async () => {
    await onUpdateLabel({
      word,
      label: inlineEditText.trim()
    });

    setEditing(false);
    disableAddInput(false);
  };

  const handleOnClickCancel = () => {
    setEditing(false);
    disableAddInput(false);
  };

  const handleDeleteVariation = () => {
    onClickDeleteVariation({
      parentUuid: word.parent,
      variationUuid: word.uuid
    });
  };

  const handleClickMakePrimary = () => {
    onClickMakePrimary({
      uuid: word.uuid,
      parentUuid: word.parent
    });
  };

  // Show/Hide based on hover states
  const hoveredRow = word.uuid === currentRowUuid;
  if (hoveredRow) {
    style.background = Tokens.colors.snow;
    styles.variationContainer.display = 'flex';
  } else {
    style.background = 'none';
    styles.variationContainer.display = 'none';
  }

  const renderEditableActions = () => (
    <React.Fragment>
      <AvomaButton
        label={L10n.general.save}
        small
        style={{ marginRight: Tokens.spacing.half }}
        onClick={handleOnClickSave}
        disabled={!inlineEditText}
      />
      <AvomaButton
        label={L10n.general.cancel}
        minimal
        small
        onClick={handleOnClickCancel}
        style={{ marginLeft: Tokens.spacing.half, padding: 0 }}
      />
    </React.Fragment>
  );

  const renderPrimaryActions = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {editing ? (
        renderEditableActions()
      ) : (
        <React.Fragment>
          <span style={styles.primaryText}>
            {L10n.customCategory.primaryKeyword}
          </span>
          {hoveredRow && (
            <ButtonUnstyled
              style={styles.editBtn}
              onClick={handleInlineEditing}
              key={`edit-btn-${word.uuid}`}
              tooltip={L10n.general.rename}
              tooltipPosition='top-left'
            >
              <img
                src={editIcon}
                alt='edit'
                style={styles.editIcon}
                key={`edit-icon-${word.uuid}`}
              />
            </ButtonUnstyled>
          )}
        </React.Fragment>
      )}
    </div>
  );

  const renderVariationActions = () => (
    <React.Fragment>
      {editing ? (
        renderEditableActions()
      ) : (
        <div style={styles.variationContainer}>
          {isMakePrimaryActionAllowed && (
            <AvomaButton
              style={{ padding: `0 ${Tokens.spacing.one}`, height: '28px' }}
              secondary
              small
              label={L10n.customCategory.makeItPrimary}
              onClick={handleClickMakePrimary}
            />
          )}
          <ButtonUnstyled
            style={styles.editBtn}
            onClick={handleInlineEditing}
            key={`edit-btn-${word.uuid}`}
            tooltip={L10n.general.rename}
            tooltipPosition='top-left'
          >
            <img
              src={editIcon}
              alt='edit'
              style={styles.editIcon}
              key={`edit-icon-${word.uuid}`}
            />
          </ButtonUnstyled>
          <ButtonUnstyled
            type='button'
            style={styles.deleteBtn}
            onClick={handleDeleteVariation}
            key={`delete-btn-${word.uuid}`}
            tooltip={L10n.general.delete}
            tooltipPosition='top-left'
          >
            <img
              src={deleteIcon}
              alt='delete'
              style={styles.deleteIcon}
              key={`delete-icon-${word.uuid}`}
            />
          </ButtonUnstyled>
        </div>
      )}
    </React.Fragment>
  );

  return (
    <div
      style={{ ...styles.container, ...style }}
      key={word.uuid}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {editing ? (
        <TextField
          value={inlineEditText}
          onChange={onChangeInlineText}
          onKeyDown={handleKeyDownAddButton}
          containerStyles={styles.textInput}
          userStyles={{ ...styles.input, ...styles.textInput }}
        />
      ) : (
        <p style={styles.word}>{wordLabel}</p>
      )}
      {isPrimary ? renderPrimaryActions() : renderVariationActions()}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `6px ${Tokens.spacing.two}`,
    height: Tokens.spacing.four
  },
  word: {
    ...Tokens.type.bodyS,
    fontWeight: 'bold',
    color: Tokens.colors.blue,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: Tokens.spacing.one
  },
  primaryText: {
    ...Tokens.type.bodyS,
    color: Tokens.colors.silver
  },
  editIcon: {
    background: 'none',
    padding: Tokens.spacing.half,
    ':hover': {
      filter: 'brightness(10%)'
    }
  },
  editBtn: {
    display: 'flex',
    marginLeft: Tokens.spacing.half,
    background: 'none',
    borderRadius: Tokens.spacing.borderRadius,
    cursor: 'pointer',
    ':hover': {
      background: Tokens.colors.gainsboro
    }
  },
  deleteBtn: {
    display: 'flex',
    marginLeft: Tokens.spacing.half,
    background: 'none',
    borderRadius: Tokens.spacing.borderRadius,
    cursor: 'pointer',
    ':hover': {
      background: Tokens.colors.gainsboro
    }
  },
  deleteIcon: {
    background: 'none',
    height: '16px',
    width: '16px',
    padding: Tokens.spacing.half,
    filter: Tokens.filters.silver,
    ':hover': {
      filter: 'brightness(10%)'
    }
  },
  variationContainer: {
    display: 'none',
    alignItems: 'center'
  },
  textInput: {
    height: Tokens.spacing.four,
    width: '100%',
    marginRight: Tokens.spacing.one
  },
  input: {
    ...Tokens.type.bodyS,
    width: '100%',
    fontWeight: 'normal',
    padding: Tokens.spacing.one
  }
};

Variation.propTypes = {
  word: PropTypes.shape({
    isPrimary: PropTypes.bool,
    uuid: PropTypes.string,
    parent: PropTypes.string,
    label: PropTypes.string
  }),
  onUpdateLabel: PropTypes.func,
  onClickDeleteVariation: PropTypes.func,
  disableAddInput: PropTypes.func,
  onClickMakePrimary: PropTypes.func,
  isMakePrimaryActionAllowed: PropTypes.bool
};

export default Radium(Variation);
