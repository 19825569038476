import _ from 'lodash';

export const getManagedMembers = ({ email, members, teams }) => {
  let managedMembers = [];
  if (teams && teams.userTeams) {
    const managedTeams = _.filter(teams.userTeams.data, team =>
      _.find(team.managers, { email })
    );
    const managedTeamUuids = _.map(managedTeams, 'uuid');
    if (_.size(managedTeamUuids)) {
      managedMembers = _.filter(members, member => {
        const teamUuids = _.map(member.teams, 'team.uuid');

        if (member.user.email === email) {
          return false;
        }
        // true if intersection of my teams and user teams is > 0
        return _.size(_.intersection(managedTeamUuids, teamUuids));
      });
    }
  }

  return managedMembers;
};
