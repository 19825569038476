import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import moment from 'moment';

import { OutcomeActions } from 'actions/actionTypes';

const initialState = {
  types: {},
  fetchingTypes: false,
  outcomes: {},
  fetchingOutcomes: false,
  fetchAttributesTime: null,
  orgMeetingtypes: {},
  fetchingOrgMeetingtypes: false
};

const outcomes = (state = initialState, action) => {
  switch (action.type) {
    case OutcomeActions.FETCH_TYPES_REQUEST:
      return {
        ...state,
        fetchingTypes: true
      };
    case OutcomeActions.FETCH_TYPES_SUCCESS:
      return {
        ...state,
        types: keyBy(action.meetingTypes, 'uuid'),
        fetchingTypes: false
      };
    case OutcomeActions.FETCH_TYPES_FAILURE:
      return {
        ...state,
        fetchingTypes: false
      };

    case OutcomeActions.FETCH_TYPES_FOR_ORG_REQUEST:
      return {
        ...state,
        fetchingOrgMeetingtypes: true
      };
    case OutcomeActions.FETCH_TYPES_FOR_ORG_SUCCESS:
      return {
        ...state,
        orgMeetingtypes: keyBy(action.meetingTypes, 'uuid'),
        fetchingOrgMeetingtypes: false
      };
    case OutcomeActions.FETCH_TYPES_FOR_ORG_FAILURE:
      return {
        ...state,
        fetchingOrgMeetingtypes: false
      };
    case OutcomeActions.CREATE_TYPES_SUCCESS:
      return {
        ...state,
        types: {
          ...state.types,
          [action.meetingType.uuid]: action.meetingType
        },
        orgMeetingtypes: {
          ...state.orgMeetingtypes,
          [action.meetingType.uuid]: action.meetingType
        }
      };

    case OutcomeActions.UPDATE_TYPES_SUCCESS:
      return {
        ...state,
        types: {
          ...state.types,
          [action.uuid]: action.meetingType
        },
        orgMeetingtypes: {
          ...state.orgMeetingtypes,
          [action.meetingType.uuid]: action.meetingType
        }
      };
    case OutcomeActions.UPDATE_OUTCOMES_SUCCESS:
      return {
        ...state,
        outcomes: {
          ...state.outcomes,
          [action.uuid]: action.meetingOutcome
        }
      };

    case OutcomeActions.DELETE_TYPES_SUCCESS: {
      return {
        ...state,
        types: omit(state.types, action.uuid),
        orgMeetingtypes: omit(state.orgMeetingtypes, action.uuid)
      };
    }

    case OutcomeActions.FETCH_OUTCOMES_REQUEST:
      return {
        ...state,
        fetchingOutcomes: true
      };
    case OutcomeActions.FETCH_OUTCOMES_SUCCESS:
      return {
        ...state,
        outcomes: keyBy(action.meetingOutcomes, 'uuid'),
        fetchingOutcomes: false
      };
    case OutcomeActions.FETCH_OUTCOMES_FAILURE:
      return {
        ...state,
        fetchingOutcomes: false
      };

    case OutcomeActions.CREATE_OUTCOMES_SUCCESS:
      return {
        ...state,
        outcomes: {
          ...state.outcomes,
          [action.meetingOutcome.uuid]: action.meetingOutcome
        }
      };

    case OutcomeActions.DELETE_OUTCOMES_SUCCESS: {
      return {
        ...state,
        outcomes: omit(state.outcomes, action.uuid)
      };
    }

    case OutcomeActions.FETCH_ATTRIBUTES_REQUEST: {
      return {
        ...state,
        fetchingOutcomes: true
      };
    }

    case OutcomeActions.FETCH_ATTRIBUTES_SUCCESS: {
      return {
        ...state,
        fetchingOutcomes: false,
        fetchAttributesTime: moment(),
        outcomes: keyBy(action.outcomes, 'uuid'),
        types: keyBy(action.types, 'uuid')
      };
    }

    case OutcomeActions.FETCH_ATTRIBUTES_FAILURE: {
      return {
        ...state,
        fetchAttributesTime: moment(),
        fetchingOutcomes: false
      };
    }

    // case OutcomeActions.CREATE_TYPES_REQUEST:
    // case OutcomeActions.CREATE_TYPES_FAILURE:
    // case OutcomeActions.DELETE_TYPES_REQUEST:
    // case OutcomeActions.DELETE_TYPES_FAILURE:
    // case OutcomeActions.CREATE_OUTCOMES_REQUEST:
    // case OutcomeActions.CREATE_OUTCOMES_FAILURE:
    default:
      return state;
  }
};

export default outcomes;
