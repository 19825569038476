import { Editor, Node, Point, Range, Transforms } from 'slate';

import { toggleList } from './List/Transforms/toggleList';
import {
  TYPE_CHECKLIST,
  TYPE_LIST_ITEM,
  TYPE_ORDERED_LIST,
  TYPE_UNORDERED_LIST
} from './List/types';
import { isFirstChild } from './Queries/isFirstChild';
import {
  TYPE_CHECKLIST_ITEM,
  TYPE_HEADER1,
  TYPE_HEADER2,
  TYPE_HEADER3,
  TYPE_PARAGRAPH
} from './Types';

const SHORTCUTS = {
  '*': TYPE_LIST_ITEM,
  '-': TYPE_LIST_ITEM,
  '+': TYPE_LIST_ITEM,
  '1.': TYPE_LIST_ITEM,
  '>': 'block-quote',
  '#': TYPE_HEADER1,
  '##': TYPE_HEADER2,
  '###': TYPE_HEADER3,
  '[]': TYPE_CHECKLIST_ITEM
};

const withMarkdown = editor => {
  const { deleteBackward, insertText } = editor;

  editor.setType = type => {
    Transforms.setNodes(
      editor,
      { type },
      { match: n => Editor.isBlock(editor, n) }
    );
  };

  editor.toggleList = listType => {
    toggleList(editor, {
      typeList: listType
    });
  };

  editor.insertText = text => {
    const { selection } = editor;
    if (text === ' ' && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      const block = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n)
      });
      const path = block ? block[1] : [];
      const start = Editor.start(editor, path);
      const range = { anchor, focus: start };
      const beforeText = Editor.string(editor, range);
      const type = SHORTCUTS[beforeText];
      const parentType = Node.parent(editor, path).type;
      const nestedList =
        parentType === TYPE_LIST_ITEM &&
        type === TYPE_LIST_ITEM &&
        !isFirstChild(path);
      const isOrdered = btext => btext === '1.';
      if (type && !parentType) {
        Transforms.select(editor, range);
        Transforms.delete(editor);
        if (type === TYPE_LIST_ITEM) {
          editor.toggleList(
            isOrdered(beforeText) ? TYPE_ORDERED_LIST : TYPE_UNORDERED_LIST
          );
        } else if (type === TYPE_CHECKLIST_ITEM) {
          editor.toggleList(TYPE_CHECKLIST);
        } else {
          editor.setType(type);
        }
        return;
      }
      if (nestedList) {
        // We can turn markdown into list items if in a nested list
        // but not the first paragraph in a list item since that is the
        // same line as the bullet
        Transforms.select(editor, range);
        Transforms.delete(editor);
        const listItem = { type: TYPE_LIST_ITEM, chidren: [] };
        Transforms.wrapNodes(editor, listItem, { at: path });
        const list = {
          type: isOrdered(beforeText) ? TYPE_ORDERED_LIST : TYPE_UNORDERED_LIST,
          children: []
        };
        Transforms.wrapNodes(editor, list, { at: path });
      }
    }

    insertText(text);
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n)
      });
      if (match) {
        const [block, path] = match;
        const start = Editor.start(editor, path);
        if (
          block.type !== TYPE_PARAGRAPH &&
          block.type !== TYPE_LIST_ITEM &&
          block.type !== TYPE_CHECKLIST_ITEM &&
          Point.equals(selection.anchor, start)
        ) {
          Transforms.setNodes(editor, { type: TYPE_PARAGRAPH });
          if (editor.onUnwrap) {
            editor.onUnwrap(block);
          }
          return;
        }
      }

      deleteBackward(...args);
    }
  };

  return editor;
};

export default withMarkdown;
