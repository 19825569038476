import camelize from 'camelize';
import decamelizeKeys from 'decamelize-keys';
import { EventTypes } from 'redux-segment';

import env from 'helpers/env';
import { get, post } from 'helpers/http';

import { LicenseActions } from './actionTypes';

export const fetchChargebeeSession = () => async () => {
  const result = await get(
    `${env.avomaApiUrl}/api/v1/subscriptions/customer_portal/`
  );
  if (result && result.portal_session) {
    const portalSession = result.portal_session;
    return portalSession;
  }
  return null;
};

export const fetchCheckout = (planId, subscriptionId, addonId) => async () => {
  const result = await post(
    `${env.avomaApiUrl}/api/v1/subscriptions/checkout/`,
    {
      plan_id: planId,
      subscription_id: subscriptionId,
      ...(addonId && { addon_id: addonId })
    }
  );
  if (result && result.hosted_page) {
    const hostedPage = result.hosted_page;
    return hostedPage;
  }
  return null;
};

export const fetchPlans = () => async dispatch => {
  dispatch({ type: LicenseActions.FETCH_PLANS_REQUEST });
  try {
    const result = await get(`${env.avomaApiUrl}/api/v1/subscriptions/plans/`);
    dispatch({
      type: LicenseActions.FETCH_PLANS_SUCCESS,
      plans: camelize(result)
    });
  } catch (err) {
    dispatch({
      type: LicenseActions.FETCH_PLANS_FAILURE,
      err
    });
  }
};

export const fetchLicenses = () => async dispatch => {
  dispatch({ type: LicenseActions.FETCH_LICENSES_REQUEST });
  try {
    const result = await get(`${env.avomaApiUrl}/api/v1/licenses/`);
    dispatch({
      type: LicenseActions.FETCH_LICENSES_SUCCESS,
      licenses: camelize(result.results)
    });
  } catch (err) {
    dispatch({
      type: LicenseActions.FETCH_LICENSES_FAILURE,
      err
    });
  }
};

export const assignLicense = (planId, email) => async () => {
  const result = await post(`${env.avomaApiUrl}/api/v1/licenses/activate/`, {
    plan_id: planId,
    user_email: email
  });
  return result;
};

export const deactivateLicense = email => async () => {
  const result = await post(`${env.avomaApiUrl}/api/v1/licenses/deactivate/`, {
    user_email: email
  });
  return result;
};

export const syncPlans = subscriptionId => async () => {
  const result = await get(
    `${env.avomaApiUrl}/api/v1/subscriptions/sync/?subscription_id=${subscriptionId}`
  );
  return result;
};

export const confirmPayment = hostedPageId => async () => {
  const result = await get(
    `${env.avomaApiUrl}/api/v1/subscriptions/confirm_payment/?id=${hostedPageId}`
  );
  return result;
};

export const sendRequestAdminEmail = payload => async dispatch => {
  dispatch({
    type: LicenseActions.SEND_REQUEST_ADMIN_EMAIL_REQUEST,
    meta: {
      analytics: {
        eventType: EventTypes.track
      }
    }
  });

  try {
    await post(
      `${env.avomaApiUrl}/api/v1/subscriptions/request-admin-email/`,
      decamelizeKeys(payload)
    );
    dispatch({
      type: LicenseActions.SEND_REQUEST_ADMIN_EMAIL_SUCCESS,
      meta: {
        analytics: {
          eventType: EventTypes.track
        }
      }
    });
  } catch (err) {
    dispatch({ type: LicenseActions.SEND_REQUEST_ADMIN_EMAIL_FAILURE, err });
  }
};
