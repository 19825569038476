import camelize from 'camelize';
import { EditorState, convertFromRaw } from 'draft-js';
import moment from 'moment';

import { NotesActions } from 'actions/actionTypes';

import { cleanUUID } from 'helpers/draft-js/machineNotes';

const initialState = {
  data: {},
  fetching: false,
  errorFetching: null,
  cursorLocations: {},
  showPrivateNotes: false,
  sentEmails: {}
};

const cleanup = editorState => {
  const { blocks } = editorState;
  blocks.forEach(block => {
    // eslint-disable-next-line no-param-reassign
    block.key = cleanUUID(block.key);
  });
  return editorState;
};

const fetchingNote = (state, action) => {
  switch (action.type) {
    case 'FETCH_NOTE_REQUEST':
      return {
        ...state,
        data: {
          ...state.data,
          [action.noteUuid]: {
            ...state.data[action.noteUuid],
            fetching: true,
            errorFetching: null
          }
        }
      };
    case 'FETCH_NOTE_SUCCESS': {
      return {
        ...state,
        data: {
          ...state.data,
          [action.note.uuid]: {
            ...camelize(action.note),
            editorState: EditorState.createWithContent(
              convertFromRaw(cleanup(JSON.parse(action.note.text)) || {})
            ),
            updating: false,
            errorUpdating: null,
            __fetchTime: moment()
          }
        }
      };
    }
    case 'FETCH_NOTE_FAILURE': {
      return {
        ...state,
        data: {
          ...state.data,
          [action.noteId]: {
            ...state.data[action.noteId],
            fetching: false,
            errorFetching: action.err
          }
        }
      };
    }
    default:
      return state;
  }
};

const updatingNote = (state, action) => {
  switch (action.type) {
    case NotesActions.UPDATE_SLATE_NOTE_REQUEST:
    case 'UPDATE_NOTE_REQUEST':
      return {
        ...state,
        data: {
          ...state.data,
          [action.noteUuid]: {
            ...state.data[action.noteUuid],
            updating: true,
            errorUpdating: null
          }
        }
      };
    case NotesActions.UPDATE_SLATE_NOTE_SUCCESS:
    case 'UPDATE_NOTE_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          [action.note.uuid]: {
            ...camelize(action.note),
            __fetchTime: moment(),
            updating: false,
            errorUpdating: null,
            editorState: state.data[action.note.uuid].editorState
          }
        },
        errorUpdating: null
      };
    case NotesActions.UPDATE_SLATE_NOTE_FAILURE:
    case 'UPDATE_NOTE_FAILURE':
      return {
        ...state,
        data: {
          ...state.data,
          [action.noteUuid]: {
            ...state.data[action.noteUuid],
            updating: false,
            errorUpdating: action.error
          }
        }
      };
    default:
      return state;
  }
};

const creatingNote = (state, action) => {
  switch (action.type) {
    case 'CREATE_NOTE_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          [action.note.uuid]: {
            ...camelize(action.note),
            __fetchTime: moment(),
            editorState: EditorState.createEmpty()
          }
        }
      };
    case 'CREATE_NOTE_FAILURE':
      return {
        ...state,
        data: {
          ...state.data,
          errorFetching: action.err
        }
      };
    default:
      return state;
  }
};

const notes = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_NOTE_REQUEST':
    case 'FETCH_NOTE_SUCCESS':
    case 'FETCH_NOTE_FAILURE':
      return fetchingNote(state, action);
    case NotesActions.UPDATE_SLATE_NOTE_REQUEST:
    case NotesActions.UPDATE_SLATE_NOTE_SUCCESS:
    case NotesActions.UPDATE_SLATE_NOTE_FAILURE:
      return updatingNote(state, action);
    case 'CREATE_NOTE_SUCCESS':
      return creatingNote(state, action);
    case 'SET_NOTE_EDITOR_STATE':
      return {
        ...state,
        data: {
          ...state.data,
          [action.noteId]: {
            ...state.data[action.noteId],
            editorState: action.editorState
          }
        }
      };
    case NotesActions.LOCAL_UPDATE_SLATE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.noteUuid]: {
            ...state.data[action.noteUuid],
            textSlate: action.textSlate,
            focusPoint: action.focusPoint,
            __dirty: true
          }
        }
      };
    case NotesActions.SET_NOTE_DIRTY:
      return {
        ...state,
        data: {
          ...state.data,
          [action.noteUuid]: {
            ...state.data[action.noteUuid],
            __dirty: true
          }
        }
      };
    case NotesActions.UNSET_NOTE_DIRTY:
      return {
        ...state,
        data: {
          ...state.data,
          [action.noteUuid]: {
            ...state.data[action.noteUuid],
            __dirty: null
          }
        }
      };
    case NotesActions.SET_NOTE_CURSOR_LOCATION:
      return {
        ...state,
        cursorLocations: {
          ...state.cursorLocations,
          [action.noteUuid]: action.point
        }
      };
    case NotesActions.BLUR_NOTES:
      return {
        ...state,
        blur: true
      };
    case NotesActions.BLUR_NOTES_DONE:
      return {
        ...state,
        blur: false
      };

    case NotesActions.SET_SHOW_PRIVATE_NOTES:
      return {
        ...state,
        showPrivateNotes: action.data
      };
    case NotesActions.SET_REGENERATED_SLATE_NOTE:
      return {
        ...state,
        regeneratedData: {
          ...state.regeneratedData,
          [action.noteUuid]: {
            ...state.data[action.noteUuid],
            textSlate: action.textSlate
          }
        }
      };
    case NotesActions.CLEAR_REGENERATED_SLATE_NOTE:
      return {
        ...state,
        regeneratedData: {
          ...state.regeneratedData,
          [action.noteUuid]: null
        }
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};

export default notes;
