import PropTypes from 'prop-types';
import { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { twMerge } from 'tailwind-merge';

import AvomaSelect from 'components/Common/AvomaSelect';
import getDueDateDaysOptions from 'components/Tasks/generic/DueDate/utils';

import L10n from 'helpers/L10n';
import {
  renderCalendarIcon,
  renderNavNextIcon,
  renderNavPreviousIcon,
  renderRemoveIcon
} from 'helpers/calendar';

const ManualFormTaskDueDate = ({
  dueDateOption,
  dueDateDaysInFuture,
  dueDateCalendarValue,
  handleDueDateOptionChange,
  handleDueDateDaysInFutureChange,
  handleDueDateCalendarValueChange,
  disabled = false,
  datePickerClasses = ''
}) => {
  const [focused, setFocused] = useState(false);
  const dateFormat = 'MMM Do, YYYY';

  const daysOptions = getDueDateDaysOptions();

  const dueDateOptions = [
    { label: L10n.clickupIntegration.daysInFuture, value: '1' },
    { label: L10n.clickupIntegration.calendarDate, value: '2' }
  ];

  const onFocusChange = event => {
    setFocused(event.focused);
  };

  return (
    <div className='flex flex-1 flex-col'>
      <p
        className={twMerge(
          'mb-2 flex items-center text-sm font-bold text-gunmetal',
          disabled && 'pointer-events-none opacity-40'
        )}
      >
        {L10n.clickupIntegration.dueDate}
      </p>
      <div className='flex items-center justify-between gap-4'>
        <div className='flex-1'>
          <AvomaSelect
            items={dueDateOptions}
            label={L10n.shareMeeting.select}
            triggerClassName='w-full bg-white rounded cursor-pointer'
            contentPosition='popper'
            onValueChange={handleDueDateOptionChange}
            value={dueDateOption}
            disabled={disabled}
            selectItemContainerClassName='hover:bg-snow'
            _key='dueDateOption'
          />
        </div>
        <div className='flex-1'>
          {dueDateOption === '1' ? (
            <AvomaSelect
              items={daysOptions}
              label={L10n.clickupIntegration.selectDays}
              triggerClassName='w-full bg-white rounded cursor-pointer'
              contentPosition='popper'
              onValueChange={handleDueDateDaysInFutureChange}
              value={dueDateDaysInFuture}
              placeholder={L10n.clickupIntegration.daysSelectPlaceholder}
              contentClassName='w-[100px] min-w-[100px]'
              disabled={disabled}
              selectItemContainerClassName='hover:bg-snow'
              _key='dueDateDaysInFuture'
            />
          ) : (
            <div
              className={twMerge(
                'share_expiry_settings_datepicker_clickup fixed top-[290px] z-50',
                datePickerClasses
              )}
            >
              <SingleDatePicker
                numberOfMonths={1}
                onDateChange={handleDueDateCalendarValueChange}
                onFocusChange={onFocusChange}
                focused={focused}
                date={dueDateCalendarValue}
                showDefaultInputIcon
                anchorDirection='left'
                hideKeyboardShortcutsPanel
                placeholder={L10n.clickupIntegration.selectDate}
                navNext={renderNavNextIcon()}
                navPrev={renderNavPreviousIcon()}
                customCloseIcon={renderRemoveIcon()}
                customInputIcon={renderCalendarIcon()}
                displayFormat={dateFormat}
                transitionDuration={0}
                disabled={disabled}
                verticalSpacing={20}
                small
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ManualFormTaskDueDate.propTypes = {
  dueDateOption: PropTypes.string.isRequired,
  dueDateDaysInFuture: PropTypes.string.isRequired,
  dueDateCalendarValue: PropTypes.string.isRequired,
  handleDueDateOptionChange: PropTypes.func.isRequired,
  handleDueDateDaysInFutureChange: PropTypes.func.isRequired,
  handleDueDateCalendarValueChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  datePickerClasses: PropTypes.string
};

export default ManualFormTaskDueDate;
