import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { twMerge } from 'tailwind-merge';

import AvomaHoverCard from 'components/Common/AvomaHoverCard';
import AvomaPopover from 'components/Common/AvomaPopover';
import ButtonUnstyled from 'components/Common/ButtonUnstyled';

import L10n from 'helpers/L10n';

import { ReactComponent as DeleteIcon } from 'images/ic_delete_red.svg';
import { ReactComponent as EditIcon } from 'images/ic_edit_pen.svg';
import { ReactComponent as MoreIcon } from 'images/ic_more_vertical.svg';
import { ReactComponent as PlusIcon } from 'images/ic_plus.svg';

import Tokens from 'styles/tokens';

const Phrase = ({
  phraseObj,
  handleToggleCreateUpdateDialog,
  handleToggleDeleteDialog,
  isAdminOrTopicOwner
}) => {
  const { uuid, label, sentences } = phraseObj || {};

  const [showMoreDetails, setShowMoreDetails] = useState(false);

  const handeClickEdit = () => {
    if (uuid) {
      setShowMoreDetails(false);
      handleToggleCreateUpdateDialog(uuid);
    }
  };

  const handleClickDelete = () => {
    if (uuid) {
      setShowMoreDetails(false);
      handleToggleDeleteDialog(uuid);
    }
  };

  const renderMoreDetailsPopover = () => (
    <OutsideClickHandler onOutsideClick={() => setShowMoreDetails(false)}>
      <AvomaPopover
        width={100}
        style={styles.moreDetailsPopover}
        wrapperStyle={{ top: Tokens.spacing.five, right: 0 }}
      >
        <ButtonUnstyled
          type='button'
          style={{ ...styles.menuButton, color: Tokens.colors.gunmetal }}
          onClick={handeClickEdit}
        >
          <EditIcon style={styles.deleteIcon} />
          {L10n.general.edit}
        </ButtonUnstyled>

        <ButtonUnstyled
          type='button'
          style={{ ...styles.menuButton, color: Tokens.colors.avomaRed }}
          onClick={handleClickDelete}
        >
          <DeleteIcon style={styles.deleteIcon} />
          {L10n.general.delete}
        </ButtonUnstyled>
      </AvomaPopover>
    </OutsideClickHandler>
  );

  const renderExampleSentences = () => (
    <div className='max-h-80 overflow-auto px-3 py-2 text-sm'>
      <ul className='flex flex-col'>
        {!isEmpty(sentences) &&
          Object.values(sentences).map(sentenceObj => (
            <li
              key={sentenceObj.uuid}
              className='mb-4 text-sm text-silver-darker last:mb-1'
            >
              {sentenceObj.sentence}
            </li>
          ))}
      </ul>
    </div>
  );

  return (
    <div
      className={twMerge(
        'group relative float-left flex items-center rounded border border-blue-light text-blue hover:border-blue-dark hover:text-blue-dark'
      )}
    >
      <div className='flex rounded-l border-r border-blue-light bg-blue-white p-2 group-hover:border-blue-dark group-hover:bg-blue-whiter'>
        {label && (
          <ButtonUnstyled
            className='whitespace-nowrap text-sm font-bold text-inherit'
            onClick={() => handleToggleCreateUpdateDialog(uuid)}
            tooltip={L10n.customCategory.viewAndEditPhrase}
            tooltipPosition='bottom-right'
            disabled={!isAdminOrTopicOwner}
          >
            {label}
          </ButtonUnstyled>
        )}
      </div>
      <div className='flex h-full w-full items-center pr-2'>
        <AvomaHoverCard
          contentStyles={{
            zIndex: 10000,
            width: 340
          }}
          childrenStyles={{
            padding: '0px',
            display: 'flex',
            flexDirection: 'column'
          }}
          contentProps={{
            align: 'start',
            sideOffset: 14
          }}
          triggerComponent={
            <div
              className='flex max-w-[380px] cursor-pointer'
              onClick={() => {
                if (isAdminOrTopicOwner) {
                  handleToggleCreateUpdateDialog(uuid);
                }
              }}
              role='button'
              tabIndex='0'
            >
              <span className='ml-2 truncate text-sm text-gunmetal'>
                {Object.values(sentences)[0].sentence}
              </span>

              {Object.values(sentences).slice(1).length >= 1 && (
                <span className='ml-1.5 flex items-center text-sm font-bold text-blue'>
                  +{Object.values(sentences).slice(1).length}
                </span>
              )}
            </div>
          }
        >
          <strong className='bg-snow px-4 py-2 font-bold text-silver-darker'>
            {L10n.customCategory.exampleSentencesTitle}
          </strong>

          {renderExampleSentences()}
        </AvomaHoverCard>

        <ButtonUnstyled
          className='relative ml-1.5 flex w-full cursor-pointer items-center justify-center rounded bg-transparent p-0 hover:bg-f1 disabled:cursor-not-allowed disabled:bg-transparent disabled:opacity-50'
          onClick={() => handleToggleCreateUpdateDialog(phraseObj.uuid)}
          tooltip={L10n.customCategory.addExampleSentences}
          noDefaultStyles
          disabled={!isAdminOrTopicOwner}
        >
          <PlusIcon />
        </ButtonUnstyled>

        <ButtonUnstyled
          style={styles.moreButton}
          tooltip={!showMoreDetails && L10n.moreDetailsMenu.moreDetails}
          onClick={() => setShowMoreDetails(true)}
          tooltipPosition='bottom'
          tooltipStyle={styles.tooltip}
          key={`more-button-${uuid}`}
          disabled={!isAdminOrTopicOwner}
        >
          <MoreIcon
            style={{
              ...styles.moreIcon,
              ...(!showMoreDetails && { ...styles.iconHover })
            }}
          />
        </ButtonUnstyled>

        {showMoreDetails && renderMoreDetailsPopover()}
      </div>
    </div>
  );
};

const styles = {
  addIcon: {
    ':hover': {
      filter: Tokens.filters.silver
    }
  },
  moreButton: {
    marginLeft: '6px',
    display: 'flex',
    borderRadius: Tokens.spacing.borderRadius,
    ':hover': {
      backgroundColor: Tokens.colors.lightGray
    },
    ':disabled': {
      cursor: 'not-allowed',
      opacity: 0.5
    }
  },
  moreIcon: {
    filter: Tokens.filters.silver,
    padding: Tokens.spacing.half,
    ':hover': {
      filter: Tokens.filters.silverDark
    }
  },
  iconHover: {
    ':hover': {
      filter: Tokens.filters.silverDark
    }
  },
  menuButton: {
    padding: Tokens.spacing.one,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: Tokens.colors.gunmetal,
    background: 'none',
    ...Tokens.type.bodyS,
    fontWeight: 700,
    ':hover': {
      background: Tokens.colors.f1
    }
  },
  moreDetailsPopover: {
    padding: 0
  },
  deleteIcon: {
    marginRight: Tokens.spacing.one,
    padding: Tokens.spacing.half,
    height: '14px',
    width: '14px'
  }
};

Phrase.propTypes = {
  phraseObj: PropTypes.object.isRequired,
  handleToggleCreateUpdateDialog: PropTypes.func.isRequired,
  handleToggleDeleteDialog: PropTypes.func.isRequired,
  isAdminOrTopicOwner: PropTypes.bool.isRequired
};

export default Phrase;
