import isHotkey from 'is-hotkey';
import { findIndex } from 'lodash';
import moment from 'moment';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Editor, Node, Path, Transforms } from 'slate';
import { jsx } from 'slate-hyperscript';
import { ReactEditor } from 'slate-react';

import {
  BLOCK_KEY,
  HIGHLIGHT_NOTES_TITLE,
  TEXT
} from 'helpers/SlateDataHelper';

import { isList } from './List/Queries/isList';
import { isSelectionInListItem } from './List/Queries/isSelectionInListItem';
import { unwrapList } from './List/Transforms/unwrapList';
import {
  TYPE_CHECKLIST,
  TYPE_LIST_ITEM,
  TYPE_ORDERED_LIST,
  TYPE_UNORDERED_LIST
} from './List/types';
import isNodeTypeIn from './Queries/isNodeTypeIn';
import { TYPE_BOOKMARK, TYPE_TRANSCRIPT } from './Transcript/types';
import {
  TYPE_HEADER1,
  TYPE_HEADER2,
  TYPE_HEADER3,
  TYPE_PARAGRAPH
} from './Types';
import { createId } from '@paralleldrive/cuid2';

const HIGHLIGHT_NOTES_LIST = 'highlight_notes_list';
const HIGHLIGHT_NOTE_LIST_ITEM = 'highlight_note_list_item';
export const HIGHLIGHT_NOTE = 'highlight_note';
export const BOOKMARK_NOTE = 'bookmark_note';
const BOOKMARK_LIST_ITEM = 'bookmark_list_item';
const HIGHLIGHT_CATEGORY = 'highlight_category';
const BOOKMARK_DATETIME = 'bookmark_time';
export const TIME = 'time';
const TIME_END = 'time_end';
export const UUID = 'uuid';
const AUGMENTED_UUID = 'augmented_uuid';
const AUGMENTED_NOTE = 'augmented_note';
export const BLOCK_UPDATED = 'block_updated';

/* Code needed for parseing from paste */
const ELEMENT_TAGS = {
  A: el => ({ type: 'link', url: el.getAttribute('href') }),
  H1: () => ({ type: TYPE_HEADER1 }),
  H2: () => ({ type: TYPE_HEADER2 }),
  H3: () => ({ type: TYPE_HEADER3 }),
  H4: () => ({ type: TYPE_HEADER3 }),
  H5: () => ({ type: TYPE_HEADER3 }),
  H6: () => ({ type: TYPE_HEADER3 }),
  LI: el => {
    const isChecklistItem = el.querySelector('input[type="checkbox"]');

    if (isChecklistItem) {
      return {
        type: TYPE_CHECKLIST,
        checked: isChecklistItem.checked // Extract the checkbox state
      };
    }

    return { type: TYPE_LIST_ITEM }; // Default to a regular list item
  },
  OL: () => ({ type: TYPE_ORDERED_LIST }),
  P: () => ({ type: TYPE_PARAGRAPH }),
  UL: () => ({ type: TYPE_UNORDERED_LIST })
  // BLOCKQUOTE: () => ({ type: 'quote' }),
  // IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
  // PRE: () => ({ type: 'code' }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  B: () => ({ bold: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true })
};

export const deserialize = el => {
  if (el.nodeType === 3) {
    return el.textContent;
  }

  if (el.nodeType !== 1) {
    return null;
  }

  // This is our class for UI elements in the notes like timestamp and buttons we want to ignore those on paste
  if (el.className === 'disable-select') {
    return null;
  }

  let { nodeName } = el;
  const parent = el;

  if (nodeName === 'BR') {
    nodeName = 'P';
  }

  // Process child nodes recursively
  let children = Array.from(parent.childNodes).map(deserialize).flat();

  // Apple Notes messes up the DOM by sending line breaks
  children = children.filter(child => {
    if (typeof child === 'string' && child.includes('\n')) return false;
    return child;
  });

  if (children.length === 0) {
    children = [{ text: '' }];
  }

  // Handle <a> tags (links) and group them with their children
  if (nodeName === 'A') {
    const attrs = {
      href: el.href,
      url: el.url,
      target: el.target,
      rel: el.rel
    };

    // Combine all children (texts and nested elements) inside the link tag
    const linkContent = children.map(child =>
      typeof child === 'string' ? { text: child } : child
    );

    return jsx('element', { type: 'link', ...attrs }, linkContent);
  }

  // Handle <code> elements
  if (nodeName === 'CODE') {
    return children;
  }

  // Special handling for unordered lists
  if (
    nodeName === 'UL' &&
    el.getAttribute('data-stringify-type') === 'unordered-list'
  ) {
    return {
      type: 'unordered-list',
      children
    };
  }

  // Handle list items and their contents
  if (nodeName === 'LI') {
    const nestedLists = children.filter(
      child => typeof child === 'object' && child.type === 'unordered-list'
    );

    const content = children.filter(
      child => !(typeof child === 'object' && child.type === 'unordered-list')
    );

    // Wrap list item content in a paragraph
    const paragraph = {
      type: 'paragraph',
      children: content.map(child =>
        typeof child === 'string' ? { text: child } : child
      )
    };

    return {
      type: 'list-item',
      children: [paragraph, ...nestedLists]
    };
  }

  // Handle body as a fragment
  if (nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }

  // Handle custom element tags
  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    return jsx('element', attrs, children);
  }

  // Handle custom text tags
  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    if (children.some(child => typeof child === 'object' && child.type)) {
      return children; // Skip wrapping if children are already elements
    }

    return children.map(child => jsx('text', attrs, child));
  }

  return children;
};

const removeInlineStyles = html => {
  const tempDiv = document.createElement('div');

  tempDiv.innerHTML = html;

  const elementsWithStyles = tempDiv.querySelectorAll('*');

  // Remove inline styles from all elements
  elementsWithStyles.forEach(element => {
    element.removeAttribute('style');
  });

  return tempDiv.innerHTML;
};

const parseAndCopyHtmlToPlainText = htmlString => {
  // Create a temporary DOM element to parse the HTML string
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  // Extract text content from all child nodes
  const extractTextWithNewLines = node => {
    let result = '';

    node.childNodes.forEach(child => {
      const { nodeName, tagName, textContent } = child;

      // Process if it's a list item or an element inside a list
      if (['UL', 'OL', 'LI'].includes(nodeName)) {
        // If it's a list (UL, OL), recursively process its children
        if (['UL', 'OL', 'LI'].includes(tagName)) {
          result += extractTextWithNewLines(child);
        } else {
          result += `${textContent.trim()}\n`;
        }
      } else {
        result += `${textContent.trim()}\n`;
      }
    });

    return result;
  };

  return extractTextWithNewLines(tempDiv).trim();
};

const withAvoma = (editor, keyDowns) => {
  /**
   * Plugin for all Avoma related note features like:
   *  - Highlight Categories
   *  - Machine Notes
   *  - Bookmarks
   *
   * IMPORTANT NOTE: null values in any part of the notes object will break
   * collaborative note syncing for some reason!
   */
  const {
    insertBreak,
    insertData,
    setFragmentData,
    deleteBackward,
    onKeyDown
  } = editor;

  editor.onKeyDown = event => {
    for (let ii = 0; ii < keyDowns.length; ii += 1) {
      const keyDown = keyDowns[ii];

      if (!keyDown(event, editor)) {
        return false;
      }
    }

    if (isHotkey('enter', event)) {
      event.preventDefault();
      editor.insertBreak();

      return;
    }

    if (isHotkey('mod+a', event)) {
      // Manually handle cmd/ctrl - a to select the entire note
      // because by default it selects more than the slate editor
      // and setFragmentData does not get called.
      const firstEntry = Node.first(editor, [0]);
      const secondEntry = Node.last(editor, [editor.children.length - 1]);

      event.preventDefault();
      Transforms.select(editor, {
        anchor: { path: firstEntry[1], offset: 0 },
        focus: {
          path: secondEntry[1],
          offset: secondEntry[0].text ? secondEntry[0].text.length : 0
        }
      });
      return false;
    }

    // Run single characters typed without CTRL/CMD being pressed
    if (
      event.key.length === 1 &&
      !event.ctrlKey &&
      !event.metaKey &&
      editor.selection &&
      editor.selection.anchor
    ) {
      const { path } = editor.selection.anchor;
      const curNode = Node.get(editor, path);
      const parent = Node.get(editor, path.slice(0, path.length - 1));

      if (!curNode.text) {
        Transforms.setNodes(editor, {
          data: {
            ...parent.data, // dont clobber the parent data
            [BLOCK_UPDATED]: moment().format(),
            block_key: `manual_note_${createId()}`, // unique key for manual note block
            is_manual_note: true
          }
        });
      }
    }

    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  editor.onUnwrap = block => {
    Transforms.setNodes(editor, {
      data: {
        ...block.data,
        [HIGHLIGHT_NOTES_TITLE]: false,
        [BLOCK_KEY]: null
      }
    });
  };

  editor.deleteBackward = unit => {
    deleteBackward(unit);
  };

  editor.isSelectionPoint = () => {
    /* Returns true if the selection is a cursor, not a highlighted selection range */
    const { selection } = editor;
    const { anchor, focus } = selection || {};

    if (anchor && focus) {
      if (anchor.offset !== focus.offset) {
        return false;
      }

      if (anchor.path.length !== focus.path.length) {
        return false;
      }

      for (let ii = 0; ii < anchor.path.lenght; ii += 1) {
        if (anchor.path[ii] !== focus.path[ii]) {
          return false;
        }
      }
    }
    return true;
  };

  editor.scrollIntoView = () => {
    setTimeout(() => {
      const newDomRange =
        editor.selection && ReactEditor.toDOMRange(editor, editor.selection);
      if (newDomRange) {
        const leafEl = newDomRange.startContainer.parentElement;

        scrollIntoView(leafEl, {
          scrollMode: 'if-needed'
        });
      }
    }, 200);
  };

  editor.insertBreak = () => {
    if (
      editor.isSelectionPoint() &&
      editor.selection &&
      editor.selection.anchor.offset === 0
    ) {
      const node = {
        type: TYPE_PARAGRAPH,
        children: [createTextNode('')]
      };

      Transforms.insertNodes(editor, node, {
        at: [...editor.selection.anchor.path.slice(0, -1)]
      });
    } else {
      insertBreak();

      // All newlines should be a paragraph unless handled by another plugin
      Transforms.setNodes(editor, {
        type: TYPE_PARAGRAPH,
        data: {
          block_created: moment().format()
        }
      });
    }

    // Need to wait for the node to appear
    editor.scrollIntoView();
  };

  editor.getTitleIndex = (nodes, key) =>
    findIndex(
      nodes,
      node =>
        node.data &&
        node.data[BLOCK_KEY] === key &&
        node.data[HIGHLIGHT_NOTES_TITLE] === true
    );

  const createTextNode = text => ({
    type: TEXT,
    text
  });

  const createHighlightTitleNode = highlightCategory => ({
    type: TYPE_HEADER2,
    data: {
      [BLOCK_KEY]: highlightCategory.key,
      [HIGHLIGHT_NOTES_TITLE]: true
    },
    children: [createTextNode(highlightCategory.name)]
  });

  const createHighlightNoteList = (highlightCategoryUuid, node) => ({
    type: TYPE_UNORDERED_LIST,
    data: {
      [BLOCK_KEY]: highlightCategoryUuid,
      [HIGHLIGHT_NOTES_LIST]: true
    },
    children: [node]
  });

  const createTranscriptListItem = highlight => {
    const transcript = highlight.augmentedNote
      ? highlight.augmentedNote.originalText
      : highlight.preview;
    const transcriptNode = {
      type: TYPE_TRANSCRIPT,
      [UUID]: highlight.uuid,
      data: {
        [AUGMENTED_UUID]: highlight.augmentedNote.uuid,
        [TIME]: highlight.startTime,
        [TIME_END]: highlight.endTime,
        [HIGHLIGHT_NOTE]: true,
        [AUGMENTED_NOTE]: true
      },
      children: [createTextNode(transcript)]
    };

    return {
      type: TYPE_LIST_ITEM,
      [UUID]: highlight.uuid,
      data: {
        [AUGMENTED_UUID]: highlight.augmentedNote.uuid,
        [HIGHLIGHT_NOTE_LIST_ITEM]: true
      },
      children: [transcriptNode]
    };
  };

  const createBookmarkListItem = (bookmark, highlightCategory) => {
    const timeDelta =
      (bookmark.time.getTime() - Date.parse(bookmark.meetingStart)) / 1000;
    const transcriptNode = {
      type: TYPE_BOOKMARK,
      data: {
        [HIGHLIGHT_CATEGORY]: highlightCategory,
        [BOOKMARK_NOTE]: true,
        [BOOKMARK_DATETIME]: bookmark.time.getTime(),
        [TIME]: timeDelta
      },
      children: [createTextNode('')]
    };

    return {
      type: TYPE_LIST_ITEM,
      data: {
        [BOOKMARK_LIST_ITEM]: true
      },
      children: [transcriptNode]
    };
  };

  const addCategoryIfNotAdded = ({
    node,
    category,
    moveSelection = false,
    shouldInsertRawNode = false
  }) => {
    /* Helper for adding a Custom Category header if not already in the notes */
    let listIndex = findIndex(
      editor.children,
      nn =>
        nn.data &&
        nn.data[BLOCK_KEY] === category.key &&
        nn.data[HIGHLIGHT_NOTES_LIST] === true
    );

    const titleIndex = editor.getTitleIndex(editor.children, category.key);

    let endOfList = 0;

    if (listIndex === -1) {
      if (titleIndex >= 0) {
        listIndex = titleIndex + 1;
      } else {
        const titleNode = createHighlightTitleNode(category);
        listIndex = editor.children.length;
        const last = Node.get(editor, [listIndex - 1]);
        const { text } = last.children[0];

        if (last.type === TYPE_PARAGRAPH && !text) {
          // if the last block is empty, insert there
          listIndex -= 1;
          Transforms.removeNodes(editor, { at: [listIndex] });
        }
        Transforms.insertNodes(editor, titleNode, { at: [listIndex] });
        listIndex += 1;
      }

      if (shouldInsertRawNode) {
        Transforms.insertNodes(editor, node, { at: [listIndex] });
      } else {
        const noteList = createHighlightNoteList(category.key, node);
        Transforms.insertNodes(editor, noteList, { at: [listIndex] });
      }
    } else {
      endOfList = editor.children[listIndex].children.length || 0;
      Transforms.insertNodes(editor, node, { at: [listIndex, endOfList] });
    }

    if (moveSelection) {
      Transforms.select(editor, {
        anchor: { path: [listIndex, endOfList, 0, 0], offset: 0 },
        focus: { path: [listIndex, endOfList, 0, 0], offset: 0 }
      });
    }
  };

  const cleanNestedCheckList = node => {
    if (node.type === 'check-list') {
      node.children = node.children.flatMap(child => {
        if (child.type === 'check-list') {
          // Flatten nested check-lists
          return cleanNestedCheckList(child).children;
        }

        if (child.type !== 'check-list-item') {
          // Wrap non-check-list-item nodes in check-list-item
          return {
            type: 'check-list-item',
            checked: false, // Default to unchecked
            children: [child]
          };
        }

        return cleanNestedCheckList(child); // Recurse for nested nodes
      });
    }

    return node;
  };

  editor.clearSlate = () => {
    // Select the entire content of the editor
    const start = Editor.start(editor, []);
    const end = Editor.end(editor, []);
    const range = { anchor: start, focus: end };

    // Delete the entire content
    Transforms.delete(editor, { at: range });

    // Insert a single empty paragraph
    Transforms.insertNodes(editor, {
      type: TYPE_PARAGRAPH,
      children: [{ text: '' }]
    });

    // Reset the selection to the start of the new content
    const point = { path: [0, 0], offset: 0 };
    editor.selection = { anchor: point, focus: point };
  };

  editor.findNodePath = matcher => {
    /* Returns the first path of a note where the matcher function returns true */
    const helper = node => {
      if (matcher(node)) {
        return [];
      }

      if (node.children) {
        for (let ii = 0; ii < node.children.length; ii += 1) {
          const matches = helper(node.children[ii]);
          if (matches) {
            matches.push(ii);
            return matches;
          }
        }
      }

      return false;
    };

    const path = helper(editor);

    if (path) {
      path.reverse();
    }

    return path;
  };

  editor.removeNode = path => {
    /* Removes a node at the path and also removes the parent list if
    it was the only list item in that list */
    const parentPath = path.slice(0, path.length - 1);
    Transforms.removeNodes(editor, {
      at: path
    });
    const parent = Node.get(editor, parentPath);

    let parent2Path = parentPath;
    let parent2 = parent;
    let removedList = false;

    if (
      parent.type === TYPE_LIST_ITEM ||
      (parent.type === TYPE_UNORDERED_LIST &&
        parent.children[0].type !== TYPE_LIST_ITEM)
    ) {
      if (parent.type === TYPE_UNORDERED_LIST) {
        removedList = true;
      }

      Transforms.removeNodes(editor, {
        at: parentPath
      });

      parent2Path = parentPath.slice(0, parentPath.length - 1);
      parent2 = Node.get(editor, parent2Path);
    }

    if (
      parent2.type === TYPE_UNORDERED_LIST &&
      parent2.children[0].type !== TYPE_LIST_ITEM
    ) {
      Transforms.removeNodes(editor, {
        at: parent2Path
      });
    }

    if (removedList && parentPath[0] > 0) {
      const prevPath = [parentPath[0] - 1];
      const prevNode = Node.get(editor, prevPath);

      if (prevNode && prevNode.data && prevNode.data[HIGHLIGHT_NOTES_TITLE]) {
        Transforms.removeNodes(editor, {
          at: prevPath
        });
      }
    }

    if (editor.children.length === 0) {
      Transforms.insertNodes(
        editor,
        {
          type: TYPE_PARAGRAPH,
          children: [createTextNode('')]
        },
        {
          at: [0]
        }
      );
    }
  };

  editor.addSnippet = (highlight, highlightCategory) => {
    const transcriptNode = createTranscriptListItem(highlight);

    addCategoryIfNotAdded({
      node: transcriptNode,
      category: highlightCategory
    });
  };

  editor.addBookmark = (bookmark, highlightCategory) => {
    const bookmarkNode = createBookmarkListItem(bookmark, highlightCategory);

    ReactEditor.focus(editor);

    addCategoryIfNotAdded({
      node: bookmarkNode,
      category: highlightCategory,
      moveSelection: true
    });
  };

  editor.addCopilotMessage = (copilotMessageNode, highlightCategory) => {
    addCategoryIfNotAdded({
      node: copilotMessageNode,
      category: highlightCategory,
      shouldInsertRawNode: true
    });
  };

  editor.removeSnippet = highlight => {
    const path = editor.findNodePath(n => n.uuid === highlight.uuid);

    if (path) {
      editor.removeNode(path);
    }
  };

  editor.setBookmarkUuid = (bookmarkTime, bookmarkUuid) => {
    const path = editor.findNodePath(
      n => n.data && n.data[BOOKMARK_DATETIME] === bookmarkTime
    );

    Transforms.setNodes(
      editor,
      { [UUID]: bookmarkUuid },
      {
        at: path
      }
    );
  };

  editor.setBookmarkText = (bookmarkTime, bookmarkUuid, bookmarkText) => {
    const path = editor.findNodePath(
      n =>
        n[UUID] === bookmarkUuid ||
        (n.data && n.data[BOOKMARK_DATETIME] === bookmarkTime)
    );

    const node = Node.get(editor, path);

    if (node) {
      const { text } = node.children[0];

      if (!text) {
        Transforms.insertText(editor, bookmarkText, { at: path });
      }
    }
  };

  const pathStartsWith = (starter, target) => {
    if (starter.length > target.length) {
      return false;
    }

    for (let ii = 0; ii < starter.length; ii += 1) {
      if (starter[ii] !== target[ii]) {
        return false;
      }
    }

    return true;
  };

  editor.addCategory = (category, selectedNode) => {
    /* Adds a Custom Category (AKA Augmented Note) to the selected node */
    const isHeader = isNodeTypeIn(editor, [
      TYPE_HEADER1,
      TYPE_HEADER2,
      TYPE_HEADER3
    ]);

    ReactEditor.focus(editor);
    const path = ReactEditor.findPath(editor, selectedNode);

    if (
      !editor.selection ||
      !pathStartsWith(path, editor.selection.anchor.path) ||
      !pathStartsWith(path, editor.selection.focus.path)
    ) {
      // If there is no selection or the current selection is not selecting
      // the node where the add block button was pressed
      Transforms.select(editor, {
        anchor: { path: [...path, 0], offset: 0 },
        focus: { path: [...path, 0], offset: 0 }
      });
    }

    const anchorPath = editor.selection.anchor.path;
    const node = Node.get(editor, [anchorPath[0]]);
    const isCategory = node.data && node.data[HIGHLIGHT_NOTES_TITLE];
    const { text } = node.children[0];

    if (isHeader && isCategory) {
      // Already a Category Header, just change type
      const block = {
        data: {
          ...node.data,
          [BLOCK_KEY]: category.key
        }
      };
      Transforms.setNodes(editor, block);
    } else {
      if (!text || text.indexOf('/') >= 0) {
        // If emoty line or user is entering a category using a /
        if (text && !text.startsWith('/')) {
          const index = text.lastIndexOf('/');
          if (index >= 0) {
            Transforms.delete(editor, {
              distance: text.length - index,
              reverse: true
            });
            editor.insertBreak(editor);
          }
        }
      } else {
        if (text) {
          // Move cursor to end of the line
          Transforms.select(editor, {
            anchor: { path: editor.selection.anchor.path, offset: text.length },
            focus: { path: editor.selection.anchor.path, offset: text.length }
          });
        }
        editor.insertBreak(editor);
      }
      const block = {
        type: TYPE_HEADER2,
        data: {
          block_created: moment().format(),
          [HIGHLIGHT_NOTES_TITLE]: true,
          [BLOCK_KEY]: category.key
        }
      };
      Transforms.setNodes(editor, block);
    }

    // Editor.removeMark(editor, 'underline');
    // Editor.addMark(editor, 'bold');
    const chars = editor.selection.anchor.offset;

    if (chars) {
      Transforms.delete(editor, { distance: chars, reverse: true });
    }

    Transforms.insertText(editor, category.name);
  };

  editor.insertTemplate = template => {
    /* Inserts a template! */
    const hasSelection = !!editor.selection;

    if (editor.selection && editor.selection.focus.offset > 0) {
      editor.insertBreak(editor);
    }

    unwrapList(editor);

    if (hasSelection) {
      Transforms.insertFragment(editor, template);
    } else {
      Transforms.insertNodes(editor, template, {
        at: [editor.children.length]
      });
    }
  };

  // Helper function to add a header called Agenda, and an unordered list to it.
  // Can be invoked from the HighlightNotesPopover and the / command, so kinda behaves similar to editor.addCategory,
  // but needed to be its own function due to following different formats compared to category.
  editor.insertAgenda = (selectedNode, title = 'Agenda') => {
    ReactEditor.focus(editor);
    const path = ReactEditor.findPath(editor, selectedNode);

    if (
      !editor.selection ||
      !pathStartsWith(path, editor.selection.anchor.path) ||
      !pathStartsWith(path, editor.selection.focus.path)
    ) {
      // If there is no selection or the current selection is not selecting
      // the node where the add block button was pressed
      Transforms.select(editor, {
        anchor: { path: [...path, 0], offset: 0 },
        focus: { path: [...path, 0], offset: 0 }
      });
    }

    const anchorPath = editor.selection.anchor.path;
    const node = Node.get(editor, [anchorPath[0]]);
    const { text } = node.children[0];

    if (!text || text.indexOf('/') >= 0) {
      // If empty line or user is entering agenda using a /
      if (text && !text.startsWith('/')) {
        const index = text.lastIndexOf('/');
        if (index >= 0) {
          Transforms.delete(editor, {
            distance: text.length - index,
            reverse: true
          });
        }
      }
    } else {
      // Move cursor to end of the line
      Transforms.select(editor, {
        anchor: { path: editor.selection.anchor.path, offset: text.length },
        focus: { path: editor.selection.anchor.path, offset: text.length }
      });
    }
    /*
    results in a block similar to
    Agenda
    -
    */
    const block = [
      {
        type: TYPE_HEADER2,
        children: [
          {
            object: 'text',
            text: title
          }
        ]
      },
      {
        type: TYPE_UNORDERED_LIST,
        children: [
          {
            type: 'list-item',
            children: [
              {
                type: 'paragraph',
                children: [
                  {
                    object: 'text',
                    text: ''
                  }
                ]
              }
            ]
          }
        ]
      }
    ];

    const chars = editor.selection.anchor.offset;

    if (chars) {
      Transforms.delete(editor, { distance: chars, reverse: true });
    }

    Transforms.insertNodes(editor, block, { at: [path] });

    // So that the cursor is placed at the list item, rather than after it
    Transforms.move(editor, { distance: 1, unit: 'character', reverse: true });
  };

  const stripUneditables = node => {
    /* Strip out nodes that are not editable/selectable since
    those are actually UI elements, not notes. We use the className
    'disable-select' to determine this and do a breadth-first traversal
    to remove them
    */
    const childrenToRemove = [];
    if (node.childNodes) {
      node.childNodes.forEach(nn => {
        if (nn.className && nn.className.includes('disable-select')) {
          childrenToRemove.push(nn);
        }
      });
    }

    childrenToRemove.forEach(nn => {
      node.removeChild(nn);
    });

    if (node.childNodes) {
      node.childNodes.forEach(nn => stripUneditables(nn));
    }

    return node;
  };

  editor.setFragmentData = data => {
    /* This is called when we are copying notes to the clipboard */
    setFragmentData(data);

    const html = data.getData('text/html');

    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html');
      const cleaned = stripUneditables(parsed);

      const parsedPlainText = parseAndCopyHtmlToPlainText(
        cleaned.documentElement.outerHTML
      );

      // Update text/html and text/plain data
      data.setData('text/html', cleaned.documentElement.outerHTML);
      data.setData('text/plain', parsedPlainText);
    }
  };

  editor.insertData = data => {
    const html = data.getData('text/html');
    const frag = data.getData('application/x-slate-fragment');

    let fragment = null;

    if (frag) {
      // Decode Slate fragment if available
      const decoded = decodeURIComponent(window.atob(frag));
      const parsed = JSON.parse(decoded);

      fragment = parsed;
    }

    if (!fragment && html) {
      const cleanedHtml = removeInlineStyles(html);
      const parsed = new DOMParser().parseFromString(cleanedHtml, 'text/html');

      fragment = deserialize(parsed.body);

      // Check if the node is a plain text node or a link node
      fragment = fragment.map(node => {
        // If the node is a plain text node (has `text`) or a `link` node,
        // wrap it in a paragraph to maintain a consistent block-level structure.
        // This ensures the editor's content adheres to the expected document structure,
        // as plain text or inline nodes like `link` typically need a block container.
        if (node.text || node.type === 'link') {
          // Wrap it in a paragraph node
          return { type: TYPE_PARAGRAPH, children: [node] };
        }

        return node; // Preserve non-text nodes
      });

      Transforms.insertFragment(editor, fragment);

      return;
    }

    if (fragment) {
      // Clean and normalize the fragment for lists or nested structures
      fragment = fragment.map(cleanNestedCheckList);

      const blank = { type: TYPE_PARAGRAPH, children: [{ text: '' }] };
      const isListItem = isSelectionInListItem(editor);

      if (isListItem && isList(fragment[0])) {
        // Handle pasting a list into a list item
        const { listItemPath } = isListItem;

        const insertPath = [
          ...listItemPath.slice(0, -1),
          listItemPath[listItemPath.length - 1] + 1
        ];

        const restOfTheNodes = fragment.length > 1 ? fragment.slice(1) : [];
        Transforms.insertNodes(editor, fragment[0].children, {
          at: insertPath
        });

        if (restOfTheNodes.length > 0) {
          Transforms.insertNodes(editor, restOfTheNodes, {
            at: Path.next(insertPath.slice(0, -1))
          });
        }

        return;
      }

      if (isList(fragment[0])) {
        // Add a blank paragraph before inserting the list
        fragment = [blank, ...fragment];
      }

      // Check for inline links and ensure proper order
      const normalizedFragment = fragment.map(node => {
        if (
          node.type === 'link' &&
          node.children &&
          node.children.length === 1
        ) {
          // Ensure single link nodes are properly structured
          const child = node.children[0];

          // If the child is a text node (has `text` and no `type`), the link is valid
          if (child.text && !child.type) {
            return node;
          }
        }

        return node; // Preserve other nodes
      });

      Transforms.insertFragment(editor, normalizedFragment);

      return;
    }

    insertData(data);
  };

  editor.insertRegeneratedNote = textSlate => {
    Editor.withoutNormalizing(editor, () => {
      // Delete content safely using a valid range
      try {
        const range = {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, [])
        };
        Transforms.delete(editor, { at: range });
      } catch (e) {
        console.warn('Failed to delete content:', e);
      }

      // Insert a blank paragraph to reset structure
      Transforms.insertNodes(editor, {
        type: 'paragraph',
        children: [{ text: '' }]
      });

      Editor.normalize(editor, { force: true });
    });

    // Insert new content at a valid location
    try {
      Transforms.insertNodes(editor, textSlate, { at: [0] });
    } catch (e) {
      console.warn('Failed to insert content:', e);
    }
  };

  return editor;
};

export default withAvoma;
