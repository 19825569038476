import * as Sentry from '@sentry/react';
import env from './env';
import { get } from './http';
import isLoggedIn from './requireAuth';

// Returns a function that will show the widget when called
// This wrapper function hilds the closeTimeOut and listenerAdded variables
// to make sure the listener is only added once
// and the timeout is cleared only when the widget is closed

const addWidgetClass = () => {
  const hubspotContainer = document.getElementById(
    'hubspot-messages-iframe-container'
  );

  if (!hubspotContainer) {
    requestAnimationFrame(addWidgetClass);
    return;
  }

  hubspotContainer?.classList?.add('hide');
};

const loadWidget = () => {
  /*
     If external API methods are already available, use them.
    */
  if (window.HubSpotConversations) {
    window.HubSpotConversations?.widget?.load();
    addWidgetClass();
  } else {
    /*
          Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
          These callbacks will be called once the external API has been initialized.
        */
    window.hsConversationsOnReady = [loadWidget];
  }
};

window.hsConversationsSettings = {
  loadImmediately: false
};

const widgetVisibilityHandler = () => {
  let closeTimeOut;
  let listenerAdded = false;
  return () => {
    const hubspotContainer = document.getElementById(
      'hubspot-messages-iframe-container'
    );
    if (!hubspotContainer) return;
    if (closeTimeOut) {
      clearTimeout(closeTimeOut);
    }
    hubspotContainer?.classList?.add('show');
    if (listenerAdded) return;

    listenerAdded = true;
    // Show the widget when widget is closed and conversation is started by Avoma team.
    window.HubSpotConversations.on('unreadConversationCountChanged', () => {
      hubspotContainer?.classList?.add('show');
    });

    // Hiding the widget when the user closes it after 3 seconds basically graceful exit
    window.HubSpotConversations.on('widgetClosed', () => {
      closeTimeOut = setTimeout(() => {
        const [chatContainer] = document.getElementsByClassName(
          'hs-shadow-container'
        );
        if (!(chatContainer && chatContainer?.classList.contains('active'))) {
          hubspotContainer?.classList?.remove('show');
        }
      }, 5000);
    });
  };
};

const widgetVisibilityHandlerWrapper = widgetVisibilityHandler();

export const openHubspotChat = () => {
  function onConversationsAPIReady() {
    // Needs to reset and reload since clicking on the button for Contact Us again
    // hides the chat for an amount of time, requiring a refresh to happen before
    // its accessible
    // window.HubSpotConversations.resetAndReloadWidget();
    try {
      loadWidget();
      window.HubSpotConversations.widget.open();

      // Hubspot widget is always visible, so we need to hide it
      // and show it when the user clicks on the button
      widgetVisibilityHandlerWrapper();
    } catch (e) {
      window.open(`mailto:help@avoma.com`, '_blank');
      Sentry.captureException(e, 'Hubspot Chat loading failed');
    }
  }
  /*
     If external API methods are already available, use them.
    */
  if (window.HubSpotConversations) {
    onConversationsAPIReady();
  } else {
    /*
        Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
        These callbacks will be called once the external API has been initialized.
      */
    window.hsConversationsOnReady = [onConversationsAPIReady];
  }
};

export const generateHubspotVisitorIdentificationId = async () => {
  if (isLoggedIn() && !window?.HubSpotConversations?.widget?.status()?.loaded) {
    try {
      const response = await get(
        `${env.avomaApiUrl}/api/v1/hubspot/visitor_identification_token/`
      );
      window.hsConversationsSettings = {
        identificationEmail: response?.user?.email ?? '',
        identificationToken: response?.token ?? '',
        loadImmediately: false
      };
      loadWidget();
    } catch (error) {
      Sentry.captureException('Error creating Hubspot visitor token:', error);
    }
  }
};

export const removeHubspotChatOnLogout = () => {
  window?.HubSpotConversations?.widget?.remove();
};
