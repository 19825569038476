/* eslint-disable no-case-declarations */
import camelize from 'camelize';
import { EditorState, convertFromRaw } from 'draft-js';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

// eslint-disable-next-line import/no-cycle
import { TemplateActions } from 'actions/actionTypes';
// eslint-disable-next-line import/no-cycle
import { NEW_TEMPLATE } from 'actions/templates';

const initialState = {
  data: {}, // fetch templates
  fetching: 0,
  creating: 0,
  deleting: 0,
  error: null,
  errorFetching: null,
  nextPageUrl: null,
  nextUpcomingPageUrl: null,
  latestTemplateId: null,
  templateTypeFetching: false,
  templateTypeError: false,
  updatingTemplateType: null,
  updatingTemplateError: false,
  searchTemplatesText: ''
};

// fetch all templates
const fetchingTemplates = (state, action) => {
  switch (action.type) {
    case 'FETCH_TEMPLATES_REQUEST': {
      const {
        myTemplates,
        organizationTemplates,
        avomaTemplates,
        ...newState
      } = state;
      return {
        ...newState,
        data: {},
        fetching: state.fetching + 1,
        searchTitle: '',
        nextPageUrl: null
      };
    }
    case 'FETCH_TEMPLATES_SUCCESS':
      return {
        ...state,
        fetching: state.fetching - 1,
        searching: 0,
        data: {
          ...state.data,
          ...keyBy(camelize(action.templates), 'uuid')
        },
        nextPageUrl: action.nextPageUrl
      };
    case 'FETCH_TEMPLATES_FAILURE': {
      return {
        ...state,
        fetching: state.fetching - 1,
        searching: 0,
        error: action.error
      };
    }
    case 'FETCH_TEMPLATE_SUCCESS':
      return {
        ...state,
        fetching: state.fetching - 1,
        searching: 0,
        data: {
          ...state.data,
          [action.template.uuid]: {
            ...camelize(action.template)
          }
        }
      };
    default:
      return state;
  }
};

// fetch single template
const fetchingTemplate = (state, action) => {
  switch (action.type) {
    case 'FETCH_TEMPLATE_REQUEST':
      return {
        ...state,
        data: {
          ...state.data,
          [action.uuid]: {
            ...state.data[action.uuid],
            fetching: true,
            errorFetching: null
          }
        }
      };
    case 'FETCH_TEMPLATE_SUCCESS': {
      const json = JSON.parse(action.template.text);
      let editorState;
      if (!isEmpty(json)) {
        editorState = EditorState.createWithContent(convertFromRaw(json));
      } else {
        editorState = EditorState.createEmpty();
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.template.uuid]: {
            ...camelize(action.template),
            editorState,
            updating: false,
            fetching: false,
            errorUpdating: null
          }
        }
      };
    }
    case 'FETCH_TEMPLATE_FAILURE': {
      return {
        ...state,
        data: {
          ...state.data,
          [action.uuid]: {
            ...state.data[action.uuid],
            fetching: false,
            errorFetching: action.error
          }
        }
      };
    }
    default:
      return state;
  }
};

const updatingTemplate = (state, action) => {
  switch (action.type) {
    case TemplateActions.UPDATE_TEMPLATE_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.uuid]: {
            ...state.data[action.uuid],
            updating: true,
            errorUpdating: null
          }
        }
      };
    case TemplateActions.UPDATE_TEMPLATE_SUCCESS: {
      const isOwner =
        action.template?.user.email === action.user?.email || false;
      // on privacy change remove template from team templates
      if (
        !isOwner &&
        action.template?.privacy === 'private' &&
        state.data[action.template.uuid] &&
        state.data[action.template.uuid].privacy === 'organization'
      ) {
        const newData = omit(state.data, action.template.uuid);

        return {
          ...state,
          data: newData,
          errorUpdating: null
        };
      }

      const updatedData = action.template?.uuid
        ? {
            [action.template.uuid]: {
              ...camelize(action.template),
              updating: false,
              errorUpdating: null,
              editorState: state.data[action.template.uuid].editorState
            }
          }
        : {};

      return {
        ...state,
        data: {
          ...state.data,
          ...updatedData
        },
        errorUpdating: null
      };
    }
    case TemplateActions.UPDATE_TEMPLATE_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.uuid]: {
            ...state.data[action.uuid],
            updating: false,
            errorUpdating: action.error
          }
        }
      };
    default:
      return state;
  }
};

const deleteTemplate = (state, action) => {
  switch (action.type) {
    case 'DELETE_TEMPLATE_REQUEST':
      return {
        ...state,
        deleting: 1
      };
    case 'DELETE_TEMPLATE_SUCCESS':
      const { view } = state;

      const results = state[view].data.results.filter(
        templateUuid => templateUuid !== action.templateUuid
      );

      return {
        ...state,
        [view]: {
          ...state[view],
          data: {
            ...state[view].data,
            count: state[view].data.count - 1,
            results
          }
        },
        deleting: 0
      };

    case 'DELETE_TEMPLATE_FAILURE':
      return {
        ...state,
        latestTemplateId: action.templateUuid,
        error: action.error,
        deleting: 0
      };
    default:
      return state;
  }
};

const creatingTemplate = (state, action) => {
  switch (action.type) {
    case 'CREATE_TEMPLATE_REQUEST':
      return {
        ...state,
        creating: 1
      };
    case 'CREATE_TEMPLATE_SUCCESS': {
      const { [NEW_TEMPLATE]: value, ...withoutNew } = state.data;

      let editorState;
      const json = JSON.parse(action.template.text);
      if (action.hadContent && !isEmpty(json)) {
        editorState = EditorState.createWithContent(convertFromRaw(json));
      } else {
        editorState = EditorState.createEmpty();
      }

      return {
        ...state,
        latestTemplateId: action.template.uuid,
        creating: 0,
        data: {
          [action.template.uuid]: {
            ...camelize(action.template),
            editorState,
            name: action.template.name,
            isNew: true
          },
          ...withoutNew
        },
        myTemplates: {
          ...state.myTemplates,
          data: {
            ...state.myTemplates.data,
            count: state.myTemplates.data.count + 1,
            results: [action.template.uuid, ...state.myTemplates.data.results]
          }
        }
      };
    }
    case 'CREATE_TEMPLATE_FAILURE':
      return {
        ...state,
        creating: 0
      };
    default:
      return state;
  }
};

const creatingSlateTemplate = (state, action) => {
  switch (action.type) {
    case TemplateActions.CREATE_SLATE_TEMPLATE_REQUEST:
      return {
        ...state,
        creating: 1
      };
    case TemplateActions.CREATE_SLATE_TEMPLATE_SUCCESS: {
      const { [NEW_TEMPLATE]: value, ...withoutNew } = state.data;
      return {
        ...state,
        latestTemplateId: action.template.uuid,
        creating: 0,
        data: {
          ...withoutNew,
          [action.template.uuid]: {
            ...camelize(action.template)
          }
        }
      };
    }
    case TemplateActions.CREATE_SLATE_TEMPLATE_FAILURE:
      return {
        ...state,
        creating: 0
      };
    default:
      return state;
  }
};

const templates = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_TEMPLATE_ID': {
      let { data } = state;
      if (!action.uuid) {
        const { [NEW_TEMPLATE]: value, ...withoutNew } = state.data;
        data = withoutNew;
      }
      return {
        ...state,
        data,
        latestTemplateId: action.uuid
      };
    }
    // fetch templates
    case 'FETCH_TEMPLATES_REQUEST':
    case 'FETCH_TEMPLATES_FAILURE':
    case 'FETCH_TEMPLATES_SUCCESS':
      return fetchingTemplates(state, action);
    case 'FETCH_TEMPLATE_REQUEST':
    case 'FETCH_TEMPLATE_SUCCESS':
    case 'FETCH_TEMPLATE_FAILURE':
      return fetchingTemplate(state, action);
    case TemplateActions.UPDATE_TEMPLATE_SUCCESS:
    case TemplateActions.UPDATE_TEMPLATE_REQUEST:
    case TemplateActions.UPDATE_TEMPLATE_FAILURE:
      return updatingTemplate(state, action);
    case 'DELETE_TEMPLATE_SUCCESS':
    case 'DELETE_TEMPLATE_REQUEST':
    case 'DELETE_TEMPLATE_FAILURE':
      return deleteTemplate(state, action);
    case 'CREATE_TEMPLATE_SUCCESS':
    case 'CREATE_TEMPLATE_REQUEST':
    case 'CREATE_TEMPLATE_FAILURE':
      return creatingTemplate(state, action);
    case TemplateActions.CREATE_SLATE_TEMPLATE_REQUEST:
    case TemplateActions.CREATE_SLATE_TEMPLATE_SUCCESS:
    case TemplateActions.CREATE_SLATE_TEMPLATE_FAILURE:
      return creatingSlateTemplate(state, action);
    case TemplateActions.MIGRATE_DRAFT_TEMPLATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.uuid]: {
            ...state.data[action.uuid],
            uuid: action.uuid,
            textSlate: action.textSlate
          }
        }
      };
    case TemplateActions.SET_TEMPLATE_METADATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.uuid]: {
            ...state.data[action.uuid],
            name: action.name,
            description: action.description,
            privacy: action.privacy,
            meeting_type_uuids: action.meeting_type_uuids
          }
        }
      };
    case 'SET_TEMPLATE_EDITOR_STATE':
      return {
        ...state,
        data: {
          ...state.data,
          [action.uuid]: {
            ...state.data[action.uuid],
            uuid: action.uuid,
            editorState: action.editorState
          }
        }
      };

    // Fetch TemplateTypes that have been used by the existing templates
    // Would be called once when the TemplateDashboard is mounted, and then every time a
    // POST to attach_type happens on purpose Select
    case TemplateActions.FETCH_TEMPLATE_TYPES_REQUEST:
      return {
        ...state,
        templateTypeFetching: true,
        templateTypeError: false
      };
    case TemplateActions.FETCH_TEMPLATE_TYPES_SUCCESS:
      return {
        ...state,
        templateTypeFetching: false,
        templateTypes: action.templateTypes
      };
    case TemplateActions.FETCH_TEMPLATE_TYPES_FAILURE:
      return {
        ...state,
        templateTypeFetching: false,
        templateTypeError: true
      };
    case TemplateActions.UPDATE_TEMPLATE_TYPE_REQUEST:
      return {
        ...state,
        updatingTemplateType: true
      };
    case TemplateActions.UPDATE_TEMPLATE_TYPE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.templateUuid]: {
            ...state.data[action.templateUuid],
            meetingTypes: action.meetingTypes
          }
        },
        updatingTemplateType: false
      };
    case TemplateActions.UPDATE_TEMPLATE_TYPE_FAILURE:
      return {
        ...state,
        updatingTemplateType: false,
        updatingTemplateError: true
      };
    case 'LOGOUT':
      return initialState;

    case TemplateActions.FETCH_ALL_TEMPLATES_REQUEST: {
      const hasAllTemplates = Boolean(state.allTemplates);

      if (hasAllTemplates && !action.query) {
        return {
          ...state,
          allTemplates: {
            ...state.allTemplates,
            loadingMore: true,
            loading: false
          }
        };
      }

      return {
        ...state,
        allTemplates: {
          loading: true,
          loadingMore: false
        }
      };
    }

    case TemplateActions.FETCH_ALL_TEMPLATES_SUCCESS: {
      const hasAllTemplates = Boolean(state.allTemplates.data);
      if (hasAllTemplates && !action.query) {
        return {
          ...state,
          allTemplates: {
            ...state.allTemplates,
            loading: false,
            loadingMore: false,
            data: {
              ...state.allTemplates.data,
              ...action.response,
              results: [
                ...state.allTemplates.data.results,
                ...action.response.results
              ]
            }
          },
          data: {
            ...state.data,
            ...action.data
          }
        };
      }

      return {
        ...state,
        allTemplates: {
          loading: false,
          data: action.response
        },
        data: {
          ...state.data,
          ...action.data
        }
      };
    }
    case TemplateActions.FETCH_ALL_TEMPLATES_FAILURE: {
      return {
        ...state,
        allTemplates: {
          loading: false,
          error: true,
          loadingMore: false
        }
      };
    }
    case TemplateActions.FETCH_MY_TEMPLATES_REQUEST: {
      const hasMyTemplates = Boolean(state.myTemplates);

      if (hasMyTemplates && !action.query) {
        return {
          ...state,
          myTemplates: {
            ...state.myTemplates,
            loadingMore: true
          }
        };
      }

      return {
        ...state,
        myTemplates: {
          loading: true
        }
      };
    }

    case TemplateActions.FETCH_MY_TEMPLATES_SUCCESS: {
      const hasMyTemplates = Boolean(state.myTemplates.data);

      if (hasMyTemplates && !action.query) {
        return {
          ...state,
          myTemplates: {
            ...state.myTemplates,
            loadingMore: false,
            data: {
              ...state.myTemplates.data,
              ...action.response,
              results: [
                ...state.myTemplates.data.results,
                ...action.response.results
              ]
            }
          },
          data: {
            ...state.data,
            ...action.data
          }
        };
      }

      return {
        ...state,
        myTemplates: {
          loading: false,
          data: action.response
        },
        data: {
          ...state.data,
          ...action.data
        }
      };
    }
    case TemplateActions.FETCH_MY_TEMPLATES_FAILURE: {
      return {
        ...state,
        myTemplates: {
          loading: false,
          error: true,
          loadingMore: false
        }
      };
    }
    case TemplateActions.FETCH_ORGANIZATION_TEMPLATES_REQUEST: {
      const hasOrganizationTemplates = Boolean(state.organizationTemplates);

      if (hasOrganizationTemplates && !action.query) {
        return {
          ...state,
          organizationTemplates: {
            ...state.organizationTemplates,
            loadingMore: true
          }
        };
      }

      return {
        ...state,
        organizationTemplates: {
          loading: true
        }
      };
    }

    case TemplateActions.FETCH_ORGANIZATION_TEMPLATES_SUCCESS: {
      const hasOrganizationTemplates = Boolean(
        state.organizationTemplates.data
      );

      if (hasOrganizationTemplates && !action.query) {
        return {
          ...state,
          organizationTemplates: {
            ...state.organizationTemplates,
            loadingMore: false,
            data: {
              ...state.organizationTemplates.data,
              ...action.response,
              results: [
                ...state.organizationTemplates.data.results,
                ...action.response.results
              ]
            }
          },
          data: {
            ...state.data,
            ...action.data
          }
        };
      }

      return {
        ...state,
        organizationTemplates: {
          loading: false,
          data: action.response
        },
        data: {
          ...state.data,
          ...action.data
        }
      };
    }

    case TemplateActions.FETCH_ORGANIZATION_TEMPLATES_FAILURE: {
      return {
        ...state,
        organizationTemplates: {
          loading: false,
          loadingMore: false,
          error: true
        }
      };
    }

    case TemplateActions.FETCH_AVOMA_TEMPLATES_REQUEST: {
      const hasAvomaTemplates = Boolean(state.avomaTemplates);

      if (hasAvomaTemplates && !action.query) {
        return {
          ...state,
          avomaTemplates: {
            ...state.avomaTemplates,
            loadingMore: true
          }
        };
      }

      return {
        ...state,
        avomaTemplates: {
          loading: true
        }
      };
    }

    case TemplateActions.FETCH_AVOMA_TEMPLATES_SUCCESS: {
      const hasAvomaTemplates = Boolean(state.avomaTemplates.data);

      if (hasAvomaTemplates && !action.query) {
        return {
          ...state,
          avomaTemplates: {
            ...state.avomaTemplates,
            loading: false,
            loadingMore: false,
            data: {
              ...state.avomaTemplates.data,
              ...action.response,
              results: [
                ...state.avomaTemplates.data.results,
                ...action.response.results
              ]
            }
          },
          data: {
            ...state.data,
            ...action.data
          }
        };
      }

      return {
        ...state,
        avomaTemplates: {
          loading: false,
          data: action.response
        },
        data: {
          ...state.data,
          ...action.data
        }
      };
    }

    case TemplateActions.FETCH_AVOMA_TEMPLATES_FAILURE: {
      return {
        ...state,
        avomaTemplates: {
          loading: false,
          error: true,
          loadingMore: false
        }
      };
    }

    case TemplateActions.SET_TEMPLATES_SEARCH_TEXT:
      return {
        ...state,
        searchTemplatesText: action.searchTemplatesText
      };

    case TemplateActions.SET_TEMPLATES_VIEW: {
      return {
        ...state,
        view: action.view
      };
    }

    default:
      return state;
  }
};

export default templates;
