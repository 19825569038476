import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';

import Tokens from 'styles/tokens';

import * as HoverCard from '@radix-ui/react-hover-card';

interface Props {
  children?: ReactNode;
  triggerComponent: ReactNode;
  childrenStyles?: CSSProperties;
  contentStyles?: CSSProperties;
  contentProps?: object;
  title?: string | null;
  titleActions?: ReactNode;
  openDelay?: number;
  closeDelay?: number;
  portalContainer?: HTMLElement;
  contentClasses?: string;
  isDisabled?: boolean;
  closeOnScroll?: boolean;
  childrenClasses?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const AvomaHoverCard = ({
  triggerComponent,
  children,
  childrenStyles,
  contentStyles,
  contentProps,
  title = null,
  titleActions = null,
  openDelay = 100,
  closeDelay = 100,
  contentClasses = '',
  childrenClasses = '',
  portalContainer = document.body, // Use when the hover card needs to be rendered inside another portal like a Modal,
  closeOnScroll = false, // Close the hover card when the user scrolls outside the hover card
  isDisabled = false,
  open, // open and onOpenChange for when the hover card needs to be controlled
  onOpenChange
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const handleOpen = (newOpen: boolean) => {
    if (onOpenChange) {
      onOpenChange(newOpen);
    }
    setIsOpen(newOpen);
  };

  useEffect(() => {
    if (!isNil(open)) {
      setIsOpen(open);
    }
  }, [open]);

  useEffect(() => {
    if (!closeOnScroll) return;

    // Detect scroll outside portal container and close hover card
    const handleScroll = (e: Event) => {
      if (
        contentRef.current &&
        !contentRef.current.contains(e.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('scroll', handleScroll, true);

    return () => {
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [closeOnScroll]);

  return (
    <HoverCard.Root
      openDelay={openDelay}
      closeDelay={closeDelay}
      open={isOpen}
      onOpenChange={handleOpen}
    >
      <HoverCard.Trigger asChild>{triggerComponent}</HoverCard.Trigger>
      {!isDisabled && children && (
        <HoverCard.Portal container={portalContainer}>
          <HoverCard.Content
            sideOffset={2}
            style={{ ...styles.content, ...contentStyles }}
            className={contentClasses}
            collisionPadding={{ top: 25, right: 25, bottom: 25, left: 25 }}
            collisionBoundary={portalContainer}
            {...contentProps}
            ref={contentRef}
          >
            {(title || titleActions) && (
              <div className='sticky top-0 border-b border-b-gainsboro rounded-tl rounded-tr bg-snow py-2 px-4 flex items-center justify-between gap-2'>
                {title && (
                  <p className='font-bold text-black text-base'>{title}</p>
                )}
                {titleActions}
              </div>
            )}
            <div
              style={{ ...styles.children, ...childrenStyles }}
              className={childrenClasses}
            >
              {children}
            </div>
          </HoverCard.Content>
        </HoverCard.Portal>
      )}
    </HoverCard.Root>
  );
};

const styles = {
  content: {
    ...Tokens.shadows.medium,
    width: 300,
    borderRadius: Tokens.spacing.borderRadius,
    border: Tokens.borders.standard
  },
  children: {
    background: Tokens.colors.white,
    padding: `${Tokens.spacing.one} ${Tokens.spacing.two}`,
    borderRadius: Tokens.spacing.borderRadius
  }
};

AvomaHoverCard.propTypes = {
  children: PropTypes.node,
  triggerComponent: PropTypes.node,
  childrenStyles: PropTypes.object,
  contentStyles: PropTypes.object,
  contentProps: PropTypes.object,
  title: PropTypes.string,
  openDelay: PropTypes.number,
  closeDelay: PropTypes.number,
  portalContainer: PropTypes.instanceOf(Element),
  contentClasses: PropTypes.string,
  childrenClasses: PropTypes.string,
  open: PropTypes.bool,
  onOpenChange: PropTypes.func
};

export default AvomaHoverCard;
