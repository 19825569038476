import { toast } from 'react-hot-toast';

import { ReactComponent as WarningIcon } from 'images/warningFilledWhite.svg';
import Tokens from 'styles/tokens';

export const successNotification = ({ message, duration = 10000 }) =>
  toast.success(message, {
    position: 'bottom-left',
    duration,
    style: {
      ...styles.success,
      ...styles.toastStyles
    },
    iconTheme: {
      primary: Tokens.colors.white,
      secondary: Tokens.colors.greenDark
    }
  });

export const errorNotification = ({ message, duration = 10000 }) => {
  const toastId = toast.error(message, {
    position: 'bottom-left',
    duration,
    style: {
      ...styles.error,
      ...styles.toastStyles
    },
    iconTheme: {
      primary: Tokens.colors.white,
      secondary: Tokens.colors.redDark
    }
  });
  return () => toast.dismiss(toastId);
};

export const warningNotification = ({ message, duration = 10000 }) => {
  const toastId = toast.error(message, {
    position: 'bottom-left',
    duration,
    style: {
      ...styles.warning,
      ...styles.toastStyles
    },
    icon: <WarningIcon className='h-12 w-12' />
  });
  return () => toast.dismiss(toastId);
};

const styles = {
  toastStyles: {
    ...Tokens.type.bodyS,
    borderRadius: Tokens.spacing.borderRadius,
    padding: `${Tokens.spacing.one} ${Tokens.spacing.two}`,
    color: Tokens.colors.white,
    fontWeight: 'bold'
  },
  success: {
    background: Tokens.colors.greenDark
  },
  error: {
    background: Tokens.colors.redDark
  },
  warning: {
    background: Tokens.colors.yellowMoreDark
  }
};
