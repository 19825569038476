/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import 'styles/AvomaIconButton.css';

class AvomaIconButton extends Component {
  state = {
    hovered: false
  };

  onMouseEnter = () => {
    const { onMouseEnter } = this.props;
    this.setState({ hovered: true });
    onMouseEnter();
  };

  onMouseLeave = () => {
    const { onMouseLeave } = this.props;
    this.setState({ hovered: false });
    onMouseLeave();
  };

  styleButton() {
    const classNames = ['avoma-icon-button'];
    const { destructive } = this.props;
    if (destructive) {
      classNames.push('destructive');
    }
    return classNames;
  }

  render() {
    const {
      className,
      icon,
      hoverIcon,
      faIcon,
      iconStyles,
      iconClasses = '',
      iconHoverStyles,
      tooltip,
      tooltipPosition,
      id
    } = this.props;

    const { hovered } = this.state;
    const buttonClasses = this.styleButton();
    buttonClasses.push(className);

    if (tooltip && tooltip.trim().length > 0) {
      buttonClasses.push('tooltip icon-button-tooltip');
      buttonClasses.push(tooltipPosition);
    }

    return (
      <button
        key={id}
        type='button'
        {...this.props}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        className={buttonClasses.join(' ')}
        aria-label={tooltip}
      >
        {faIcon && faIcon}
        {!faIcon && icon && !(hoverIcon && hovered) && (
          <img
            className={`icon ${iconClasses}`}
            src={icon}
            alt=''
            style={{ ...iconStyles, ...(hovered && iconHoverStyles) }}
          />
        )}
        {!faIcon && hoverIcon && hovered && (
          <img
            className='icon'
            src={hoverIcon}
            alt=''
            style={{ ...iconStyles, ...(hovered && iconHoverStyles) }}
          />
        )}
      </button>
    );
  }
}

AvomaIconButton.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  hoverIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  faIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  disabled: PropTypes.bool,
  destructive: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  iconStyles: stylePropType,
  iconHoverStyles: stylePropType,
  iconClasses: PropTypes.string,
  tooltipPosition: PropTypes.oneOf([
    'bottom',
    'top',
    'left',
    'right',
    'bottom-right',
    'bottom-left',
    'top-right',
    'top-left'
  ])
};

AvomaIconButton.defaultProps = {
  destructive: false,
  disabled: false,
  icon: null,
  hoverIcon: null,
  faIcon: null,
  tooltipPosition: 'bottom',
  onClick: null,
  className: '',
  tooltip: '',
  iconStyles: {},
  onMouseEnter: () => {},
  onMouseLeave: () => {}
};

export default Radium(AvomaIconButton);
