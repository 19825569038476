import camelize from 'camelize';

import { ForecastActions, MemberActions } from 'actions/actionTypes';

// import env from 'helpers/env';

const initialState = {
  data: [], // members data
  organization: null,
  fetchingData: false,
  organizationPolicy: null,
  fetchingOrganizationPolicy: false,
  fetchOrgPolicyError: false,
  updatingOrganizationPolicy: false,
  updatingOrgSettingsData: false,
  fetching: false,
  inviting: 0,
  updating: 0,
  error: null,
  inviteError: null,
  orgPolicyLoading: false,
  customReminders: { allowUserLevelSettings: true },
  organizationRoles: null,
  disabledSharingEmails: [],
  forecastAccessControlSettings: [],
  forecastAccessControlSettingsLoading: false,
  forecastAccessControlSettingsUpdating: false,
  forecastAccessControlTeamsUpdating: false,
  fetchingOrgRoles: false
};

const fetchingMembers = (state, action) => {
  switch (action.type) {
    case MemberActions.FETCH_MEMBERS_REQUEST:
      return {
        ...state,
        fetchingData: true
      };
    case MemberActions.FETCH_MEMBERS_SUCCESS:
      return {
        ...state,
        fetchingData: false,
        data: [...state.data, ...camelize(action.members)]
      };
    case 'FETCH_MEMBER_SUCCESS':
      return {
        ...state,
        fetchingData: false,
        data: [...state.data, ...camelize(action.member)]
      };
    case MemberActions.FETCH_MEMBERS_FAILURE: {
      return {
        ...state,
        fetchingData: false,
        error: action.error
      };
    }
    default:
      return state;
  }
};

const fetchingOrganization = (state, action) => {
  switch (action.type) {
    case 'FETCH_ORGANIZATION_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false
      };
    case 'FETCH_ORGANIZATION_SUCCESS':
      return {
        ...state,
        fetching: false,
        organization: {
          ...state.organization,
          ...action.organization
        }
      };
    case 'FETCH_ORGANIZATION_FAILURE': {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }
    default:
      return state;
  }
};

const fetchingOrgSettings = (state, action) => {
  switch (action.type) {
    case MemberActions.FETCH_ORG_SETTINGS_REQUEST:
      return {
        ...state,
        fetching: true,
        error: false
      };
    case MemberActions.FETCH_ORG_SETTINGS_SUCCESS: {
      // Extract name and domain from this to be placed in the organization level
      const { name, domain, ...orgSettingsData } = action.response;
      return {
        ...state,
        fetching: false,
        organization: {
          ...state.organization,
          name,
          domain,
          orgSettingsData
        }
      };
    }
    case MemberActions.FETCH_ORG_SETTINGS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }
    default:
      return state;
  }
};

const fetchingOrganizationRoles = (state, action) => {
  switch (action.type) {
    case 'FETCH_ORGANIZATION_ROLES_REQUEST':
      return {
        ...state,
        fetchingOrgRoles: true,
        error: false
      };
    case 'FETCH_ORGANIZATION_ROLES_SUCCESS':
      return {
        ...state,
        fetchingOrgRoles: false,
        organizationRoles: action.roles
      };
    case 'FETCH_ORGANIZATION_ROLES_FAILURE': {
      return {
        ...state,
        fetchingOrgRoles: false,
        error: true
      };
    }
    default:
      return state;
  }
};

const updatingOrganization = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ORGANIZATION_REQUEST':
      return {
        ...state,
        updating: true
      };
    case 'UPDATE_ORGANIZATION_SUCCESS':
      return {
        ...state,
        updating: false,
        organization: action.organization
      };
    case 'UPDATE_ORGANIZATION_FAILURE': {
      return {
        ...state,
        updating: false,
        error: action.error
      };
    }

    default:
      return state;
  }
};

const fetchingOrganizationPolicy = (state, action) => {
  switch (action.type) {
    case MemberActions.FETCH_ORGANIZATION_POLICY_REQUEST:
      return {
        ...state,
        fetchingOrganizationPolicy: true,
        fetchOrgPolicyError: false
      };
    case MemberActions.FETCH_ORGANIZATION_POLICY_SUCCESS:
      return {
        ...state,
        organizationPolicy: action.organizationPolicy,
        fetchingOrganizationPolicy: false
      };
    case MemberActions.FETCH_ORGANIZATION_POLICY_FAILURE:
      return {
        ...state,
        fetchingOrganizationPolicy: false,
        fetchOrgPolicyError: true
      };
    default:
      return state;
  }
};

const settingOrganizationPolicy = (state, action) => {
  switch (action.type) {
    case MemberActions.SET_ORGANIZATION_POLICY_REQUEST:
      return {
        ...state,
        orgPolicyLoading: true
      };
    case MemberActions.SET_ORGANIZATION_POLICY_SUCCESS:
      return {
        ...state,
        orgPolicyLoading: false,
        organizationPolicy: action.organizationPolicy
      };
    case MemberActions.SET_ORGANIZATION_POLICY_FAILURE:
      return {
        ...state,
        orgPolicyLoading: false
      };
    default:
      return state;
  }
};

const fetchAudioPrompt = (state, action) => {
  switch (action.type) {
    case MemberActions.FETCH_AUDIO_PROMPT_SUCCESS:
      return {
        ...state,
        audioPrompts: action.audioPrompts
      };
    case MemberActions.FETCH_AUDIO_PROMPT_REQUEST:
    case MemberActions.SET_ORGANIZATION_POLICY_FAILURE:
    default:
      return state;
  }
};

const updateAudioPrompt = (state, action) => {
  switch (action.type) {
    case MemberActions.UPDATE_AUDIO_PROMPT_SUCCESS:
      return {
        ...state,
        audioPrompts: action.audioPrompts
      };
    case MemberActions.UPDATE_AUDIO_PROMPT_REQUEST:
    case MemberActions.UPDATE_ORGANIZATION_POLICY_FAILURE:
    default:
      return state;
  }
};

const fetchAssistantName = (state, action) => {
  switch (action.type) {
    case MemberActions.UPDATE_ASSISTANT_NAME_SUCCESS:
    case MemberActions.FETCH_ASSISTANT_NAME_SUCCESS:
      return {
        ...state,
        orgSettingsUuid: action.uuid,
        assistantName: action.assistantName,
        assistantNameAudit: action.assistantNameAudit
      };
    case MemberActions.UPDATE_ASSISTANT_NAME_REQUEST:
    case MemberActions.FETCH_ASSISTANT_NAME_REQUEST:
    case MemberActions.FETCH_ASSISTANT_NAME_FAILURE:
    case MemberActions.UPDATE_ASSISTANT_NAME_FAILURE:
    default:
      return state;
  }
};

const updateImpersonationConsent = (state, action) => {
  switch (action.type) {
    case MemberActions.UPDATE_IMPERSONATION_CONSENT_REQUEST:
      return {
        ...state,
        organization: {
          ...state.organization,
          orgSettingsDataLoading: true
        }
      };
    case MemberActions.UPDATE_IMPERSONATION_CONSENT_SUCCESS:
      return {
        ...state,
        organization: {
          ...state.organization,
          orgSettingsData: {
            ...state.organization.orgSettingsData,
            ...action.payload
          },
          orgSettingsDataLoading: false
        }
      };
    case MemberActions.UPDATE_IMPERSONATION_CONSENT_FAILURE:
      return {
        ...state,
        organization: {
          ...state.organization,
          orgSettingsDataLoading: false
        }
      };
    default:
      return state;
  }
};

const forecastAccessControlSettingsActions = (state, action) => {
  switch (action.type) {
    case ForecastActions.FETCH_FORECAST_ACCESS_CONTROL_SETTINGS_REQUEST:
      return {
        ...state,
        forecastAccessControlSettingsLoading: true,
        forecastAccessControlSettingsUpdating: false
      };
    case ForecastActions.FETCH_FORECAST_ACCESS_CONTROL_SETTINGS_SUCCESS:
      return {
        ...state,
        forecastAccessControlSettings: action.payload,
        forecastAccessControlSettingsLoading: false
      };
    case ForecastActions.FETCH_FORECAST_ACCESS_CONTROL_SETTINGS_FAILURE:
      return {
        ...state,
        forecastAccessControlSettingsLoading: false
      };

    case ForecastActions.UPDATE_FORECAST_ACCESS_CONTROL_SETTINGS_REQUEST:
      return {
        ...state,
        forecastAccessControlSettingsUpdating: true
      };

    case ForecastActions.UPDATE_FORECAST_ACCESS_CONTROL_SETTINGS_SUCCESS: {
      return {
        ...state,
        forecastAccessControlSettings: action.payload,
        forecastAccessControlSettingsUpdating: false
      };
    }

    case ForecastActions.UPDATE_FORECAST_ACCESS_CONTROL_SETTINGS_FAILURE:
      return {
        ...state,
        forecastAccessControlSettingsUpdating: false
      };

    case ForecastActions.UPDATE_FORECAST_ACCESS_CONTROL_SETTING_TEAMS_REQUEST:
      return {
        ...state,
        forecastAccessControlTeamsUpdating: true
      };

    case ForecastActions.UPDATE_FORECAST_ACCESS_CONTROL_SETTING_TEAMS_SUCCESS:
      return {
        ...state,
        forecastAccessControlSettings: action.payload,
        forecastAccessControlTeamsUpdating: false
      };

    case ForecastActions.UPDATE_FORECAST_ACCESS_CONTROL_SETTING_TEAMS_FAILURE:
      return {
        ...state,
        forecastAccessControlTeamsUpdating: false
      };

    default:
      return state;
  }
};

const members = (state = initialState, action) => {
  switch (action.type) {
    // fetch members
    case 'FETCH_MEMBERS_REQUEST':
    case 'FETCH_MEMBERS_SUCCESS':
    case 'FETCH_MEMBERS_FAILURE':
    case 'FETCH_MEMBER_SUCCESS':
      return fetchingMembers(state, action);
    // fetch organization
    case 'FETCH_ORGANIZATION_REQUEST':
    case 'FETCH_ORGANIZATION_SUCCESS':
    case 'FETCH_ORGANIZATION_FAILURE':
      return fetchingOrganization(state, action);
    // fetch org settings
    case MemberActions.FETCH_ORG_SETTINGS_REQUEST:
    case MemberActions.FETCH_ORG_SETTINGS_SUCCESS:
    case MemberActions.FETCH_ORG_SETTINGS_FAILURE:
      return fetchingOrgSettings(state, action);
    // fetch organization roles
    case 'FETCH_ORGANIZATION_ROLES_REQUEST':
    case 'FETCH_ORGANIZATION_ROLES_SUCCESS':
    case 'FETCH_ORGANIZATION_ROLES_FAILURE':
      return fetchingOrganizationRoles(state, action);
    case 'UPDATE_ORGANIZATION_REQUEST':
    case 'UPDATE_ORGANIZATION_SUCCESS':
    case 'UPDATE_ORGANIZATION_FAILURE':
      return updatingOrganization(state, action);

    case MemberActions.FETCH_ORGANIZATION_POLICY_REQUEST:
    case MemberActions.FETCH_ORGANIZATION_POLICY_SUCCESS:
    case MemberActions.FETCH_ORGANIZATION_POLICY_FAILURE:
      return fetchingOrganizationPolicy(state, action);
    case MemberActions.SET_ORGANIZATION_POLICY_REQUEST:
    case MemberActions.SET_ORGANIZATION_POLICY_SUCCESS:
    case MemberActions.SET_ORGANIZATION_POLICY_FAILURE:
      return settingOrganizationPolicy(state, action);
    case MemberActions.FETCH_AUDIO_PROMPT_REQUEST:
    case MemberActions.FETCH_AUDIO_PROMPT_SUCCESS:
    case MemberActions.FETCH_AUDIO_PROMPT_FAILURE:
      return fetchAudioPrompt(state, action);
    case MemberActions.UPDATE_AUDIO_PROMPT_REQUEST:
    case MemberActions.UPDATE_AUDIO_PROMPT_SUCCESS:
    case MemberActions.UPDATE_AUDIO_PROMPT_FAILURE:
      return updateAudioPrompt(state, action);
    case MemberActions.FETCH_ASSISTANT_NAME_REQUEST:
    case MemberActions.FETCH_ASSISTANT_NAME_SUCCESS:
    case MemberActions.FETCH_ASSISTANT_NAME_FAILURE:
    case MemberActions.UPDATE_ASSISTANT_NAME_REQUEST:
    case MemberActions.UPDATE_ASSISTANT_NAME_SUCCESS:
    case MemberActions.UPDATE_ASSISTANT_NAME_FAILURE:
      return fetchAssistantName(state, action);
    case MemberActions.UPDATE_IMPERSONATION_CONSENT_REQUEST:
    case MemberActions.UPDATE_IMPERSONATION_CONSENT_SUCCESS:
    case MemberActions.UPDATE_IMPERSONATION_CONSENT_FAILURE:
      return updateImpersonationConsent(state, action);

    case ForecastActions.FETCH_FORECAST_ACCESS_CONTROL_SETTINGS_REQUEST:
    case ForecastActions.FETCH_FORECAST_ACCESS_CONTROL_SETTINGS_SUCCESS:
    case ForecastActions.FETCH_FORECAST_ACCESS_CONTROL_SETTINGS_FAILURE:
    case ForecastActions.UPDATE_FORECAST_ACCESS_CONTROL_SETTINGS_REQUEST:
    case ForecastActions.UPDATE_FORECAST_ACCESS_CONTROL_SETTINGS_SUCCESS:
    case ForecastActions.UPDATE_FORECAST_ACCESS_CONTROL_SETTINGS_FAILURE:
    case ForecastActions.UPDATE_FORECAST_ACCESS_CONTROL_SETTING_TEAMS_REQUEST:
    case ForecastActions.UPDATE_FORECAST_ACCESS_CONTROL_SETTING_TEAMS_SUCCESS:
    case ForecastActions.UPDATE_FORECAST_ACCESS_CONTROL_SETTING_TEAMS_FAILURE:
      return forecastAccessControlSettingsActions(state, action);

    case MemberActions.UPDATE_ORG_POLICIES_REQUEST: {
      return {
        ...state,
        updatingOrganizationPolicy: true,
        error: null
      };
    }
    case MemberActions.UPDATE_ORG_POLICIES_SUCCESS: {
      return {
        ...state,
        organizationPolicy: {
          ...state.organizationPolicy,
          ...action.payload,
          meetingPrivacySettings: {
            ...state.organizationPolicy.meetingPrivacySettings,
            ...action.payload.meetingPrivacySettings
          },
          chatPrompt: {
            allowChatPrompt:
              state?.organizationPolicy?.chatPrompt?.allowChatPrompt ?? false,
            message: state?.organizationPolicy?.chatPrompt?.message ?? ''
          }
        },
        updatingOrganizationPolicy: false,
        error: null
      };
    }
    case MemberActions.UPDATE_ORG_POLICIES_FAILURE: {
      return {
        ...state,
        updatingOrganizationPolicy: false,
        error: action.error
      };
    }
    case MemberActions.UPDATE_ORG_SETTINGS_REQUEST: {
      return {
        ...state,
        updatingOrgSettingsData: true,
        error: null
      };
    }
    case MemberActions.UPDATE_ORG_SETTINGS_SUCCESS: {
      return {
        ...state,
        organization: {
          ...state.organization,
          orgSettingsData: {
            ...state.organization.orgSettingsData,
            ...action.payload
          }
        },
        updatingOrgSettingsData: false,
        error: null
      };
    }
    case MemberActions.UPDATE_ORG_SETTINGS_FAILURE: {
      return {
        ...state,
        updatingOrgSettingsData: false,
        error: action.error
      };
    }
    case MemberActions.FETCH_ADMIN_CUSTOM_REMINDERS_REQUEST:
      return {
        ...state,
        customReminders: {
          ...state.customReminders,
          loading: true,
          error: false
        }
      };
    case MemberActions.FETCH_ADMIN_CUSTOM_REMINDERS_SUCCESS:
      return {
        ...state,
        customReminders: {
          ...state.customReminders,
          reminders: action.reminders,
          loading: false,
          error: false
        }
      };
    case MemberActions.FETCH_ADMIN_CUSTOM_REMINDERS_FAILURE:
      return {
        ...state,
        customReminders: {
          ...state.customReminders,
          loading: false,
          error: true
        }
      };
    case MemberActions.GET_LOCK_ADMIN_REMINDER_SUCCESS: {
      return {
        ...state,
        customReminders: {
          ...state.customReminders,
          ...action.response
        }
      };
    }
    case MemberActions.SET_LOCK_ADMIN_REMINDER_SUCCESS: {
      return {
        ...state,
        customReminders: {
          ...state.customReminders,
          ...action.payload
        }
      };
    }
    case MemberActions.REMOVE_INVITED_MEMBER: {
      const membersData = state.data;
      const filteredMembers = membersData.filter(
        member => member.user.email !== action.email
      );

      return {
        ...state,
        data: [...filteredMembers]
      };
    }

    case MemberActions.DISABLE_SLACK_INT_SUCCESS: {
      return {
        ...state,
        organization: {
          ...state.organization,
          orgSettingsData: {
            ...state.organization.orgSettingsData,
            slackIntegration: false
          }
        }
      };
    }

    case MemberActions.FETCH_ORGANIZATION_DISABLED_SHARING_EMAILS_SUCCESS: {
      return {
        ...state,
        disabledSharingEmails: action.disabledSharingEmails?.emails || []
      };
    }
    default:
      return state;
  }
};

export default members;
