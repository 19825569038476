import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { useGetTicketingTaskTemplatesQuery } from 'services/ticketing';

import AvomaLink from 'components/Common/AvomaLink';
import AvomaSelect from 'components/Common/AvomaSelect';
import AvomaTooltip from 'components/Common/AvomaTooltip';

import L10n from 'helpers/L10n';

const ClickupIntegrationAutomationsTaskTemplate = ({
  action,
  handleValueChange,
  clientValue,
  workspaceUuid,
  containerClassName,
  isRequired
}) => {
  const { uuid: ticketingProviderUuid } = useSelector(
    state => state.user?.ticketingProviders?.clickup || {}
  );

  const { data: templates } = useGetTicketingTaskTemplatesQuery(
    {
      providerUuid: ticketingProviderUuid,
      workspaceUuid
    },
    {
      skip: !ticketingProviderUuid || !workspaceUuid
    }
  );

  const templateOptions =
    templates?.map(template => ({
      label: template.name,
      value: template.uuid
    })) || [];

  const getDropdownLabel = () => (
    <div className='!flex w-full !justify-between'>
      <div>
        <p>{L10n.clickupIntegration.selectTemplate}</p>
      </div>
      {clientValue && (
        <div className='ml-2'>
          <AvomaLink
            onClick={() => {
              handleValueChange(undefined);
            }}
          >
            {L10n.filters.clear}
          </AvomaLink>
        </div>
      )}
    </div>
  );

  return (
    <div className={twMerge('flex w-full flex-col gap-2', containerClassName)}>
      <p className='flex items-center text-sm font-bold text-gunmetal'>
        {action.label}{' '}
        {isRequired && <span className='ml-1 text-avoma-red'>*</span>}
      </p>
      <AvomaTooltip
        tooltip={!templates && L10n.clickupIntegration.workspaceTooltip}
      >
        <div className='flex flex-col'>
          <AvomaSelect
            items={templateOptions}
            label={getDropdownLabel()}
            triggerClassName='w-full bg-white rounded cursor-pointer max-h-[40px]'
            contentPosition='popper'
            onValueChange={handleValueChange}
            value={clientValue}
            placeholder={L10n.clickupIntegration.selectTemplatePlaceholder}
            disabled={!templates}
            selectItemContainerClassName='hover:bg-snow'
            showClearCta
            _key={`taskTemplate-${clientValue}-${new Date().getTime()}`}
          />
        </div>
      </AvomaTooltip>
    </div>
  );
};

ClickupIntegrationAutomationsTaskTemplate.propTypes = {
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    optionTitle: PropTypes.string.isRequired,
    optionChoices: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
      })
    ),
    apiUrl: PropTypes.string
  }),
  handleValueChange: PropTypes.func.isRequired,
  clientValue: PropTypes.string.isRequired,
  workspaceUuid: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  isRequired: PropTypes.bool
};

export default ClickupIntegrationAutomationsTaskTemplate;
