import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

const useFeature = (...features: string[]) => {
  const userFeatures = useSelector((state: RootState) => state.user?.features || []);

  if (features.length === 1) {
    return userFeatures.includes(features[0]);
  }

  return features.map(feature => userFeatures.includes(feature));
};

export default useFeature;
