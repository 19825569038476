const ATTRIBUTE_PROVIDERS = {
  salesforce: 'salesforce',
  hubspot: 'hubspot',
  zoom: 'zoom',
  outreach: 'outreach',
  aircall: 'aircall',
  ringcentral: 'ringcentrl', // Backend has it as ringcentrl. Harder to change backend than frontend 🤷🏻‍♂️
  zendesksell: 'zendesksell',
  salesloft: 'salesloft',
  kixie: 'kixie'
};

export default ATTRIBUTE_PROVIDERS;
